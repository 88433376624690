import { Component, ViewChild, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { MessageService } from "fugu-components";
import { FilterValue, Filterer, SessionPagination, PaginableComponent, SubscriptionService } from "fugu-common";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { FilteredTableListComponent } from "generic-pages";
import { Light, CaraUserService, LightService } from "center-services";
import { DatatableComponent } from "@siemens/ngx-datatable";

@Component({
  selector: "app-order-brands",
  templateUrl: "./order-brands.component.html",
  styleUrls: ["./order-brands.component.scss"],
  providers: [SubscriptionService],
})
export class OrderBrandsComponent extends FilteredTableListComponent implements OnInit, OnChanges, PaginableComponent {
  public static LIST_ID: string = "app-order-brands.brands-table";

  @Input() orderBrandsList: number[];

  @Output() orderBrandsListChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @ViewChild("table") table: DatatableComponent;

  public tableControl: UntypedFormGroup;
  public rows: any[] = [];
  public allRows: any[] = [];
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "name",
      dir: "asc",
    },
  ];

  public pageNumber: number = 0;

  public brandsList: Light[];
  public filterer: Filterer;
  private sessionPagination: SessionPagination;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private lightService: LightService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  getPageNumber(_listId: string): number {
    return this.pageNumber;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pageNumber = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
    this.applyFilters();
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(OrderBrandsComponent.LIST_ID);
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  ngOnInit(): void {
    if (!this.tableControl) {
      this.tableControl = new UntypedFormGroup({});
    }
    this.initFilters();
    this.fetchBrandList();
    this.sessionPagination.loadFromSession(OrderBrandsComponent.LIST_ID);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderBrandsList && !changes.orderBrandsList.firstChange) {
      this.fetchBrandList();
      this.applyFilters();
    }
  }

  addRow(brand: Light): void {
    const isBrandIdPresent = this.orderBrandsList.some(brandId => brandId === brand.id);
    this.allRows.push({
      id: brand.id,
      name: brand.name,
      activated: isBrandIdPresent,
    });

    // Table formControl management
    if (this.tableControl.controls[`activated_${brand.id}`]) {
      this.tableControl.controls[`activated_${brand.id}`].patchValue(isBrandIdPresent);
    } else {
      this.tableControl.addControl(`activated_${brand.id}`, new UntypedFormControl(isBrandIdPresent));
    }
    this.allRows = [...this.allRows];
  }

  fetchBrandList(): void {
    this.allRows = [];
    this.rows = [];

    this.subscriptionService.subs.push(
      this.lightService.getBrands().subscribe(
        (brands: Light[]) => {
          // handle archived brands
          brands.forEach((brand: Light) => {
            if (brand.archived && this.orderBrandsList.includes(brand.id)) {
              this.orderBrandsList.splice(this.orderBrandsList.indexOf(brand.id), 1);
              this.orderBrandsList = [...this.orderBrandsList];
              this.orderBrandsListChange.emit(this.orderBrandsList);
            }
          });

          this.brandsList = brands.filter(brand => !brand.archived);
          this.brandsList.forEach((brand: Light) => {
            this.addRow(brand);
          });
          this.rows = [...this.allRows];
          if (this.filterer.filterValues.length > 0) {
            this.applyFilters();
          }
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("order-brands.errors.get-brands", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  checkboxOnChanges(id: number): void {
    const brandToChange = this.orderBrandsList.some(brandId => brandId === id);
    if (this.tableControl.controls[`activated_${id}`].value && !brandToChange) {
      this.orderBrandsList.push(id);
    } else if (brandToChange) {
      this.orderBrandsList = this.orderBrandsList.filter(brandId => brandId !== id);
    }
    this.orderBrandsList = [...this.orderBrandsList];
    this.orderBrandsListChange.emit(this.orderBrandsList);
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === OrderBrandsComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter("name", this.translateService.instant("order-brands.datatable.columns.name"), "string");

    this.filterer.addBooleanFilter(
      "activated",
      this.translateService.instant("order-brands.datatable.columns.activated"),
      false,
      false,
      true
    );
  }

  applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        OrderBrandsComponent.LIST_ID
      ).subscribe()
    );
  }
}
