import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  PricingGroup,
  ItemCategory,
  PricingGroupService,
  ItemCategoryService,
  CaraUserService,
  CaraUser,
  CategoryType,
  PricingGroupCategoryLink,
} from "center-services";
import { CommonValidatorsUtil, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { BaseListPopupComponent, ErrorUtil, PrecisionUtil } from "generic-pages";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-pricing-group-list",
  templateUrl: "./pricing-groups-list.component.html",
  styleUrls: ["./pricing-groups-list.component.scss"],
  providers: [SubscriptionService],
})
export class PricingGroupsListComponent extends BaseListPopupComponent<PricingGroup> implements OnInit {
  public readonly decimalDigit: string = `separator.${PrecisionUtil.LOW_DECIMAL}`;
  public allItemCategoryList: ItemCategory[] = [];
  public codeLanguage: string;
  private initObservables: Observable<any>[];

  constructor(
    pricingGroupService: PricingGroupService,
    translateService: TranslateService,
    messageService: MessageService,
    protected itemCategoryService: ItemCategoryService,
    private userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    super(pricingGroupService, translateService, messageService);
  }

  ngOnInit(): void {
    this.tableControl = new UntypedFormGroup({});
    this.sorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "name",
        dir: "asc",
      },
    ];
    this.initObservables = [];
    if (this.userService.connectedUser.value) {
      this.codeLanguage = this.userService.connectedUser.value.codeLanguage;
    } else {
      this.initObservables.push(this.fetchConnectedUser());
    }
    this.initObservables.push(this.fetchItemCategories());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.fetchEntities();
      })
    );
  }

  fetchConnectedUser(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.codeLanguage = connectedUser.codeLanguage;
      })
    );
  }

  fetchItemCategories(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (itemCategories: ItemCategory[]) => {
          this.allItemCategoryList = itemCategories.filter(
            (itemCategory: ItemCategory) => !itemCategory.archived && itemCategory.type === CategoryType.STANDARD
          );
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("item-categories-list.errors.get-item-categories", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  onNewEntityClick(): any {
    this.selectedEntity = new PricingGroup({
      name: null,
      archived: false,
      pricingGroupCategoryLinks: [],
    });
    this.allItemCategoryList.forEach(cat => {
      this.selectedEntity.pricingGroupCategoryLinks.push(
        new PricingGroupCategoryLink({
          itemCategoryId: cat.id,
          marginPercent: 0,
        })
      );
    });
    this.popupTitle = `${this.getTranslationPrefix()}.popup.title-new-pricing-group`;
    this.initializePopup();
  }

  onTableActivate(event: any): any {
    if (event.type === "click") {
      if (!this.userService.canDo("PRICING_GROUP_UPDATE")) {
        return;
      }
      const filteredList = this.entityList.filter(pricingGroup => pricingGroup.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find pricingGroup with id ${event.row.id}`);
        return;
      }
      this.selectedEntity = new PricingGroup(filteredList[0]);
      this.popupTitle = `${this.getTranslationPrefix()}.popup.title-update-pricing-group`;
      this.initializePopup();
    }
  }

  initializePopup(): void {
    super.initializePopup();

    this.popupForm.controls.name.setValue(this.selectedEntity.name);
    this.allItemCategoryList.forEach(cat => {
      const marginValue = this.selectedEntity.pricingGroupCategoryLinks.find(
        link => link.itemCategoryId === cat.id
      )?.marginPercent;
      this.popupForm.controls[cat.name].setValue(marginValue ? marginValue : 0);
    });

    this.allItemCategoryList.forEach(cat => {
      const linkFound = this.selectedEntity.pricingGroupCategoryLinks.find(link => link.itemCategoryId === cat.id);
      if (linkFound) {
        linkFound.marginPercent = this.popupForm.value[cat.name];
      } else {
        this.selectedEntity.pricingGroupCategoryLinks.push(
          new PricingGroupCategoryLink({
            itemCategoryId: cat.id,
            marginPercent: this.popupForm.value[cat.name],
          })
        );
      }
    });

    this.initialEntity = this.cloneEntity(this.selectedEntity);
  }

  applyModifications(): void {
    this.selectedEntity.name = this.popupForm.value.name;

    this.allItemCategoryList.forEach(cat => {
      this.selectedEntity.pricingGroupCategoryLinks.find(link => link.itemCategoryId === cat.id).marginPercent =
        +this.popupForm.value[cat.name];
    });
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "pricing-groups-list.datatable.columns.name",
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  protected addRow(pricingGroup: PricingGroup): void {
    const row = {
      id: pricingGroup.id,
      name: pricingGroup.name,
      activated: !pricingGroup.archived,
    };

    this.allItemCategoryList.forEach(cat => {
      const marginPercent = pricingGroup.pricingGroupCategoryLinks.find(
        link => link.itemCategoryId === cat.id
      )?.marginPercent;
      row[cat.name] = marginPercent ? marginPercent : 0;
    });
    this.rows.push(row);
  }

  protected cloneEntity(pricingGroup: PricingGroup): PricingGroup {
    return new PricingGroup(pricingGroup);
  }

  protected canArchive(): boolean {
    return this.userService.canDo("PRICING_GROUP_ARCHIVE");
  }

  protected preparePopupForm(): void {
    this.popupForm = this.fb.group({
      name: [null, [Validators.required]],
    });

    this.allItemCategoryList.forEach(cat => {
      this.popupForm.addControl(
        cat.name,
        new UntypedFormControl(0, [
          Validators.required,
          Validators.min(0),
          CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.LOW_INTEGER),
        ])
      );
    });
  }

  protected getTranslationPrefix(): string {
    return "pricing-groups-list";
  }
}
