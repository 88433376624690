<ng-container>
  <div class="opening-times">
    <div class="title">{{ "opening-times.title" | translate }}</div>
    <div class="form-content">
      <form [formGroup]="openingTimesForm" novalidate>
        <div class="days" *ngFor="let day of days; let i = index">
          <div class="day-checkbox">
            <ui-checkbox
              customClass="no-margin"
              [formControl]="openingTimesForm.controls[i].get('activated')"
            ></ui-checkbox>
          </div>
          <div class="day-label">
            {{ this.dayLabels[i] }}
          </div>
          <div class="day-times" *ngIf="openingTimesForm.controls[i].get('activated').value">
            <ui-date-picker
              [locale]="locale"
              [format]="dateFormat"
              mode="time"
              customClass="no-margin"
              [formControl]="openingTimesForm.controls[i].get('startTime1')"
              status="{{ openingTimesForm.controls[i].get('startTime1') | formStatus }}"
              errorMessage="{{
                openingTimesForm.controls[i].get('startTime1')
                  | formErrorMessage
                    : { customPrefix: 'opening-times.errors.', useCustomPrefixFor: ['missingTime', 'invalidTime'] }
                  | translate
              }}"
            ></ui-date-picker>
            <ui-date-picker
              [locale]="locale"
              [format]="dateFormat"
              mode="time"
              customClass="no-margin"
              [formControl]="openingTimesForm.controls[i].get('endTime1')"
              status="{{ openingTimesForm.controls[i].get('endTime1') | formStatus }}"
              errorMessage="{{
                openingTimesForm.controls[i].get('endTime1')
                  | formErrorMessage
                    : { customPrefix: 'opening-times.errors.', useCustomPrefixFor: ['missingTime', 'invalidTime'] }
                  | translate
              }}"
            ></ui-date-picker>
            <ui-date-picker
              [locale]="locale"
              [format]="dateFormat"
              mode="time"
              customClass="no-margin"
              [formControl]="openingTimesForm.controls[i].get('startTime2')"
              status="{{ openingTimesForm.controls[i].get('startTime2') | formStatus }}"
              errorMessage="{{
                openingTimesForm.controls[i].get('startTime2')
                  | formErrorMessage
                    : { customPrefix: 'opening-times.errors.', useCustomPrefixFor: ['missingTime', 'invalidTime'] }
                  | translate
              }}"
            ></ui-date-picker>
            <ui-date-picker
              [locale]="locale"
              [format]="dateFormat"
              mode="time"
              customClass="no-margin"
              [formControl]="openingTimesForm.controls[i].get('endTime2')"
              status="{{ openingTimesForm.controls[i].get('endTime2') | formStatus }}"
              errorMessage="{{
                openingTimesForm.controls[i].get('endTime2')
                  | formErrorMessage
                    : { customPrefix: 'opening-times.errors.', useCustomPrefixFor: ['missingTime', 'invalidTime'] }
                  | translate
              }}"
            ></ui-date-picker>
            <div class="total">
              {{ (openingTimesForm.controls[i].valid ? "opening-times.total" : undefined) | translate: this.totals[i] }}
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
