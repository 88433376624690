<ng-container>
  <div class="main-container">
    <div class="header">
      <div class="return-link">
        <ui-link
          text="{{ 'shipment-form.buttons.back-to-list' | translate }}"
          (click)="redirectToList()"
          [linkIcon]="faChevronLeft"
        ></ui-link>
      </div>

      <div class="title">
        <div class="title-text">
          <ui-title titleText="{{ 'multi-shipment-form.title' | translate }}"></ui-title>
        </div>
      </div>
    </div>

    <div class="top-buttons">
      <ui-button
        text="{{ 'multi-shipment-form.top-btn' | translate }}"
        [primary]="true"
        (click)="openValidationPopup()"
        type="validate"
      >
      </ui-button>
    </div>

    <div class="multi-shipment-form-body">
      <ui-container>
        <div content>
          <div class="global-container">
            <div class="recipient-info-container" [formGroup]="generalForm" novalidate>
              <div class="recipient-container">
                <div class="container-title">
                  {{ "multi-shipment-form.recipient-info-container.recipient-title" | translate }}
                </div>

                <div class="recipient-label">
                  {{ "multi-shipment-form.recipient-info-container.recipient" | translate }}<span> *</span>
                </div>
                <ui-radio-button
                  [options]="this.recipientOptions"
                  name="recipient"
                  formControlName="recipient"
                  mandatory="true"
                  [status]="generalForm.get('recipient') | formStatus"
                  [errorMessage]="generalForm.get('recipient') | formErrorMessage | translate"
                ></ui-radio-button>

                <div class="supplier" *ngIf="this.generalForm.controls.recipient.value === 0">
                  <ui-list
                    label="{{ 'shipment-initiator-popup.options.supplier' | translate }}"
                    [status]="generalForm.get('supplier') | formStatus"
                    [errorMessage]="generalForm.get('supplier') | formErrorMessage | translate"
                    [options]="supplierOptions"
                    formControlName="supplier"
                    [mandatory]="true"
                    [search]="true"
                  ></ui-list>
                </div>

                <div class="customer" *ngIf="this.generalForm.controls.recipient.value === 1">
                  <ui-list
                    label="{{ 'shipment-initiator-popup.options.customer' | translate }}"
                    [status]="generalForm.get('customer') | formStatus"
                    [errorMessage]="generalForm.get('customer') | formErrorMessage | translate"
                    [options]="customerOptions"
                    formControlName="customer"
                    [mandatory]="true"
                    [search]="true"
                  ></ui-list>
                </div>

                <div class="store" *ngIf="this.generalForm.controls.recipient.value === 2">
                  <ui-list
                    label="{{ 'shipment-initiator-popup.options.store' | translate }}"
                    [status]="generalForm.get('store') | formStatus"
                    [errorMessage]="generalForm.get('store') | formErrorMessage | translate"
                    [options]="storeOptions"
                    formControlName="store"
                    [mandatory]="true"
                    [search]="true"
                  ></ui-list>
                </div>
              </div>
              <div class="information-container">
                <div class="container-title">
                  {{ "multi-shipment-form.recipient-info-container.info-title" | translate }}
                </div>
                <ui-input-text
                  type="text"
                  formControlName="forwardingReference"
                  label="{{ 'shipment-form.header.fields.forwarding-reference' | translate }}"
                >
                </ui-input-text>

                <ui-input-text
                  type="text"
                  formControlName="packageReference"
                  label="{{ 'shipment-form.header.fields.package-reference' | translate }}"
                >
                </ui-input-text>

                <ui-textarea
                  type="text"
                  maxLength="1024"
                  formControlName="packagingComment"
                  label="{{ 'shipment-form.header.fields.comment' | translate }}"
                >
                </ui-textarea>
              </div>
            </div>

            <div class="shipment-container" *ngIf="isLoaded">
              <div class="container-title">{{ "multi-shipment-form.shipment-container.title" | translate }}</div>

              <div [formGroup]="scanForm" class="scan-field">
                <ui-input-text
                  [label]="'multi-shipment-form.shipment-container.shipment-form-id' | translate"
                  (keydown.enter)="addShipmentForm()"
                  formControlName="scanId"
                  [icon]="faScannerGun"
                  #scanIdElement
                  class="scan-input"
                  [type]="'text'"
                ></ui-input-text>

                <ui-button
                  [text]="'shipment-form.buttons.scan-validate' | translate"
                  (click)="addShipmentForm()"
                  class="scan-button"
                  type="validate"
                ></ui-button>
              </div>

              <ngx-datatable
                #table
                [rows]="rows"
                [sorts]="sorts"
                [rowHeight]="40"
                columnMode="flex"
                [footerHeight]="50"
                headerHeight="auto"
                [scrollbarH]="true"
                [summaryRow]="true"
                [summaryHeight]="45"
                [trackByProp]="'id'"
                [rowClass]="getRowClass"
                [summaryPosition]="'bottom'"
                class="material vertical-scroll"
                [cssClasses]="{
                  sortAscending: 'fas fa-chevron-up',
                  sortDescending: 'fas fa-chevron-down'
                }"
                [count]="pager.totalElements"
                [offset]="pager.number"
                [limit]="pager.size"
                [messages]="{ emptyMessage: 'global.no-data' | translate }"
              >
                <ngx-datatable-column
                  [name]="'multi-shipment-form.shipment-container.datatable.delivery-ref' | translate"
                  cellClass="left-align"
                  [summaryTemplate]="totalSummary"
                  headerClass="left-align"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="deliveryRef"
                  [flexGrow]="0.5"
                >
                </ngx-datatable-column>

                <ngx-datatable-column
                  name=""
                  cellClass="center-align error-icon"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="inError"
                  [flexGrow]="0.2"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="error-container" *ngIf="row.inError">
                      <div #error><fa-icon [icon]="faError" class="error-icon"></fa-icon></div>
                      <ui-tooltip maxWidth="150px" [element]="error" customClass="error">
                        {{ row.errorMessage | translate: { deliveryRef: row.deliveryRef ? row.deliveryRef : "BL" } }}
                      </ui-tooltip>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name=""
                  cellClass="error-message"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="errorMessage"
                  [flexGrow]="2"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div class="ellipsis-container">
                      <div class="ellipsis" *ngIf="row.inError">
                        {{ value | translate: { deliveryRef: row.deliveryRef ? row.deliveryRef : "BL" } }}
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [name]="'multi-shipment-form.shipment-container.datatable.quantity' | translate"
                  cellClass="center-align quantity"
                  headerClass="center-align"
                  [summaryTemplate]="quantitySummary"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="quantity"
                  [flexGrow]="1"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div class="total-quantity">
                      <div class="quantity-value">{{ value }}</div>

                      <div class="quantity-unit" *ngIf="!row.quantityUnit; else quantity_unit" #warnIcon>
                        <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>

                        <ui-tooltip maxWidth="125px" [element]="warnIcon">
                          <span style="font-weight: normal !important">
                            {{ "multi-shipment-form.warning.quantity-unit" | translate }}
                          </span>
                        </ui-tooltip>
                      </div>

                      <ng-template #quantity_unit>
                        <div class="quantity-unit ellipsis" #ellipsis>
                          {{ row.quantityUnit }}
                        </div>
                        <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                          <span style="font-weight: normal !important; white-space: break-spaces; padding-top: 0">
                            {{ row.quantityUnit }}
                          </span>
                        </ui-tooltip>
                      </ng-template>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [name]="'multi-shipment-form.shipment-container.datatable.price' | translate"
                  cellClass="center-align"
                  headerClass="center-align"
                  [summaryTemplate]="priceSummary"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="price"
                  [flexGrow]="1"
                >
                  <ng-template ngx-datatable-cell-template let-price="row.price">
                    <span *ngIf="price !== null && price !== undefined">
                      {{ price | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-2":locale }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [name]="'multi-shipment-form.shipment-container.datatable.actions' | translate"
                  cellClass="center-align"
                  headerClass="center-align"
                  [resizeable]="false"
                  [sortable]="false"
                  [flexGrow]="1"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="green-zone centered" (click)="$event.stopPropagation()">
                      <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)">
                      </ui-menu-actions>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-footer>
                  <ng-template
                    let-selectedCount="selectedCount"
                    ngx-datatable-footer-template
                    let-isVisible="isVisible"
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-curPage="curPage"
                    let-offset="offset"
                  >
                    <div class="page-count" *ngIf="rowCount">
                      {{
                        rowCount
                          | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                          | translate: { rowCount: rowCount }
                      }}
                    </div>
                    <datatable-pager
                      [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                      [pagerRightArrowIcon]="'fas fa-chevron-right'"
                      [pagerNextIcon]="'fas fa-chevron-double-right'"
                      [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                      [page]="curPage"
                      [size]="pageSize"
                      [count]="rowCount"
                      [hidden]="!(rowCount / pageSize > 1)"
                      (change)="changePage($event)"
                    >
                    </datatable-pager>
                  </ng-template>
                </ngx-datatable-footer>
              </ngx-datatable>

              <ng-template #totalSummary>
                <div class="total-summary summary-fields">
                  {{ "multi-shipment-form.shipment-container.datatable.total" | translate }}
                </div>
              </ng-template>

              <ng-template #priceSummary let-row="row" let-value="value">
                <div class="total-price summary-fields">
                  {{ getTotalSummary("price") | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-2":locale }}
                </div>
              </ng-template>

              <ng-template #quantitySummary let-row="row" let-value="value">
                <div class="total-quantity summary-fields">
                  <div class="quantity-value">
                    {{ getTotalSummary("quantity") }}
                  </div>

                  <div class="quantity-unit" *ngIf="!hasSameQuantityUnit(); else quantity_unit" #warnIcon>
                    <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>

                    <ui-tooltip maxWidth="125px" [element]="warnIcon">
                      <span style="font-weight: normal !important">
                        {{ "shipment-form.warning.quantity-unit" | translate }}
                      </span>
                    </ui-tooltip>
                  </div>

                  <ng-template #quantity_unit>
                    <div class="quantity-unit ellipsis" #ellipsis>
                      {{ this.rows[0].quantityUnit }}
                    </div>
                    <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                      <span style="font-weight: normal !important; white-space: break-spaces; padding-top: 0">
                        {{ this.rows[0].quantityUnit }}
                      </span>
                    </ui-tooltip>
                  </ng-template>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ui-container>
    </div>

    <div class="shipment-form-validation-popup">
      <app-shipment-form-validation-popup
        *ngIf="validationPopupVisible"
        [multiple]="true"
        (close)="closeValidationPopup()"
        (validate)="submitAndValidateMultiShipmentForm($event)"
      >
      </app-shipment-form-validation-popup>
    </div>
  </div>
</ng-container>
