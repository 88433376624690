import { LinkedObject, UserAction, UserHistorization } from "center-services";
import { FluxRow } from "./flux-row";
import dayjs from "dayjs";

class LinkFactory {
  public static map: Map<string, string> = new Map([
    ["STORE", "/store/update/"],
    ["METAL", "/settings/metals-list"],
    ["ALLOY", "/settings/alloys-list"],
    ["ITEMCATEGORY", "/settings/item-categories-list"],
    ["ITEMUNIVERSE", "/settings/item-universes-list"],
    ["CLASSIFICATION", "/settings/classifications-list"],
    ["JOBEMPLOYEE", "/settings/jobs-list-employee"],
    ["JOBEXTERNALCONTACT", "/settings/jobs-list-external-contact"],
    ["UNITOFMEASURE", "/settings/uoms-list"],
    ["PRICINGGROUP", "/settings/pricing-groups-list"],
    ["PROMOTIONPERIOD", "/settings/promotions-list"],
    ["STOREBRAND", "/settings/store-brand"],
    ["TAG", "/settings/tags-list"],
    ["STOCKLOCATION", "/settings/stock-location-list"],
    ["SIZECATEGORY", "/settings/size-categories-list"],
    ["PDFGENERATORTEMPLATE", "/settings/app-config"],
    ["APPCONFIG", "/settings/app-config"],
    ["COMPANYCONFIG", "/settings/company-config"],
    ["CARAUSER", "/settings/user/update/"],
    ["STORELINK", "/settings/user/udpate/"],
    ["APPPROFILE", "/settings/profile/update/"],
    ["BRAND", "/brand/update/"],
    ["CUSTOMER", "/customer/update/"],
    ["RETAILITEM", "/retail-item/update/"],
    ["RETAILSUPPLIER", "/supplier/update/"],
    ["RECEPTION", "/receiving-form-detail/"],
    ["INVOICESUPPLIER", "/invoice-supplier/update/"],
    ["ITEMGROUP", "/settings/item-group/update/"],
    ["INVOICECUSTOMER", "/invoice-customer/update/"],
    ["CREDITNOTE", "/credit-note-customer/update/"],
    ["SHIPMENT", "/shipment-form-detail/"],
  ]);

  public static buildLink(linkedObject: LinkedObject): string {
    if (!linkedObject) {
      return null;
    }
    const base: string = LinkFactory.map.get(linkedObject.type);
    const link: string =
      base && base.charAt(base.length - 1) === "/" ? LinkFactory.map.get(linkedObject.type) + linkedObject.id : base;
    return link;
  }
}

export class HistorizationRow {
  static properties: Map<string, string> = new Map([
    ["date", "createdAt"],
    ["action", "actionType"],
  ]);
  static readonly PADNUMBER: number = 2;
  id: number;
  date: string;
  type: string;
  store: string;
  reference: string;
  user: string;
  mainObjectLink: string;
  linkToObject: string;
  action: string;
  typeLinkedObjectTranslate: string;
  typeLinkedObject: string;
  listLinkedIds: string[];
  mapLinkedObject: Map<string, string> = new Map();
  flux: FluxRow[] = [];

  constructor(userAction: UserAction, format: string) {
    this.id = userAction?.id;
    this.date = HistorizationRow.formatDate(userAction?.createdAt, format);
    this.type = userAction?.type;
    this.reference = userAction.mainObject?.reference;
    this.mainObjectLink = LinkFactory.buildLink(userAction?.mainObject);
    this.action = userAction?.actionType;
    this.store = userAction?.store?.name;
    this.flux = userAction?.flux?.map(f => new FluxRow(f));
    this.typeLinkedObject = userAction?.linkedObjects?.length > 0 ? userAction.linkedObjects[0].type : undefined;
    this.listLinkedIds = userAction?.linkedObjects?.map(element => element.reference);
    this.mapLinkedObject = new Map(
      userAction?.linkedObjects?.map(element => [element.reference, LinkFactory.buildLink(element)])
    );
    this.typeLinkedObjectTranslate =
      userAction?.linkedObjects?.length > 0 ? userAction.linkedObjects[0].typeTranslate : undefined;
    this.user = HistorizationRow.buildUser(userAction?.user);
  }

  public static transformToBackProperties(prop: string): string {
    return HistorizationRow.properties.get(prop);
  }

  private static formatDate(date: Date, format: string): string {
    if (!date) {
      return "";
    }
    const formatedDate = dayjs(date).format(format);
    return `${formatedDate}`;
  }

  private static buildUser(user: UserHistorization): string {
    if (!user) {
      return null;
    }
    return `N°${user?.id} (${user?.lastName} ${user?.firstName})`;
  }
}
