import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
  Renderer2,
  AfterViewChecked,
} from "@angular/core";
import { faInfoCircle, faTrash, faExternalLink, IconDefinition, faGripLines } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  Nomenclature,
  NomenclatureItem,
  NomenclatureItemSizeValue,
  RetailItemService,
  SizeCategory,
  SizeCategoryService,
  SizeValue,
  OverrideSizeCategory,
  OverrideSizeValue,
  StandardItem,
  UomService,
  Uom,
  ThemeService,
} from "center-services";
import { MenuAction, MessageService } from "fugu-components";
import { combineLatest, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { NgxTreeDatatableDragger } from "app/util/ngx-tree-datatable-dragger";
import { Router } from "@angular/router";
import { v4 as uuidv4 } from "uuid";

@Component({
  selector: "app-retail-item-nomenclature-lines-form",
  templateUrl: "./retail-item-nomenclature-lines-form.component.html",
  styleUrls: ["./retail-item-nomenclature-lines-form.component.scss"],
})
export class RetailItemNomenclatureLinesFormComponent implements OnInit, AfterViewChecked {
  @ViewChildren("onDragIconViewChild") divStockBoxIcon: QueryList<ElementRef>;
  @ViewChildren("onDragViewChild") divStockBox: QueryList<ElementRef>;

  @Input() rootItemSizeCategory: OverrideSizeCategory;
  @Input() rootItemId: number;
  @Input() nomenclature: Nomenclature;

  @Output() returnToList: EventEmitter<void> = new EventEmitter();

  public readonly COLLAPSED_TREE: string = "collapsed";
  public readonly EXPANDED_TREE: string = "expanded";
  public readonly DISABLED_TREE: string = "disabled";

  public popupVisible: boolean = false;
  public deletePopupVisible: boolean = false;

  public tableRows: NomenclatureItem[] = [];

  public statusLabel: string;
  public activeMenuAction: MenuAction[] = [];
  public inactiveMenuAction: MenuAction[] = [];

  public faInfo: IconDefinition = faInfoCircle;
  public faGrip: IconDefinition = faGripLines;

  public sizeCategory: SizeCategory = new SizeCategory();
  public activeSizeValues: SizeValue[];

  private deletingRow: NomenclatureItem;

  private initObservables: Observable<any>[] = [];
  private uoms: Uom[] = [];

  private fetchedNomenclatureItems: Map<number, NomenclatureItem[]> = new Map();
  private alreadyExpandedRows: number[] = [];

  private readonly DELETE_ITEM_ACTION: number = 0;
  private readonly GO_TO_ITEM_FORM_ACTION: number = 1;

  // eslint-disable-next-line no-magic-numbers
  private readonly GRADIENT_LAST_LEVEL: number = 4;

  constructor(
    public translateService: TranslateService,
    private messageService: MessageService,
    private sizeCategoryService: SizeCategoryService,
    private el: ElementRef,
    private dragger: NgxTreeDatatableDragger<NomenclatureItem>,
    private retailItemService: RetailItemService,
    private renderer: Renderer2,
    private uomService: UomService,
    private themeService: ThemeService,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.initObservables.push(this.fetchRootItemSizeCategory());
    this.initObservables.push(this.fetchUoms());

    combineLatest(this.initObservables).subscribe(() => {
      this.activeSizeValues = this.rootItemSizeCategory
        ? (this.activeSizeValues = this.sizeCategory.elements.filter((elt: SizeValue) => {
          return this.rootItemSizeCategory.elements.some(
            (itemElt: OverrideSizeValue) => itemElt.sizeValueId === elt.id
          );
        }))
        : [];

      this.buildRows(
        this.nomenclature.nomenclatureItems.sort((a, b) => a.position - b.position),
        0
      );
      this.refreshTableRows();

      this.dragger.init(
        this.el.nativeElement.querySelector("#nomenclature-items-table-ngx-datatable"),
        this.nomenclature.nomenclatureItems,
        (updatingRow: NomenclatureItem) => of(updatingRow),
        () => {
          this.refreshTableRows();
        }
      );
    });

    this.fillActionMenus();
    this.statusLabel = this.translateService.instant(
      `item.nomenclatures.nomenclature-form.status.${this.nomenclature.archived ? "in" : ""}active`
    );
  }

  ngAfterViewChecked(): void {
    this.dragger.refreshDragRefs();

    if (this.themeService.getValue()) {
      // Light theme
      this.manageStockBoxThemeColor("#ECF0F3", "#012B5D");
    } else {
      // Dark theme
      this.manageStockBoxThemeColor("#32394B", "#DFE3EE");
    }
  }

  public mainQuantityChanged(currentRow: NomenclatureItem): void {
    if ((currentRow.quantity as any) === "") {
      currentRow.quantity = null;
    }

    currentRow.nomenclatureItemSizeValues.forEach((nisv: NomenclatureItemSizeValue) => {
      nisv.quantity = currentRow.quantity;
      this.quantityChanged(nisv, currentRow);
    });

    this.updateMainQuantityFactorisation(currentRow, currentRow.quantity ?? 0);
  }

  public quantityChanged(sizeValue: NomenclatureItemSizeValue, row: NomenclatureItem): void {
    let currentQuantity: number;

    if (row.quantity) {
      const divisor =
        row.sizeValuesBaseQuantities.get(sizeValue.sizeValueId) === 0
          ? 1
          : row.sizeValuesBaseQuantities.get(sizeValue.sizeValueId);

      currentQuantity = +row.quantity / divisor;
    } else {
      currentQuantity = null;
    }

    this.updateQuantityFactorisation(sizeValue.sizeValueId, row, currentQuantity);
  }

  public getCurrentSizeValue(sizeValues: NomenclatureItemSizeValue[], index: number): NomenclatureItemSizeValue {
    return sizeValues.find((sv: NomenclatureItemSizeValue) => sv.sizeValueId === this.activeSizeValues[index].id);
  }

  public buildRows(items: NomenclatureItem[], fromIndex: number, parent: NomenclatureItem = null): void {
    if (fromIndex === 0 && !parent) {
      this.nomenclature.nomenclatureItems = [];
    }

    items.forEach((item: NomenclatureItem, step: number) => {
      const baseQuantity: number = item.quantity;
      const sizeValuesBaseQuantities = new Map<number, number>(
        item.nomenclatureItemSizeValues.map(
          (sizeValue: NomenclatureItemSizeValue) => [sizeValue.sizeValueId, sizeValue.quantity] as [number, number]
        )
      );

      item.elementId = uuidv4();
      item.parentId = parent?.elementId ?? 0;
      item.treeStatus = item.itemHasNomenclature ? this.COLLAPSED_TREE : this.DISABLED_TREE;
      item.childrenIds = [];
      item.baseQuantity = baseQuantity;
      item.sizeValuesBaseQuantities = sizeValuesBaseQuantities;

      if (parent) {
        parent.childrenIds.push(item.elementId);

        item.nomenclatureItemSizeValues.forEach((nisv: NomenclatureItemSizeValue) => {
          const parentSizeValue = parent.nomenclatureItemSizeValues.find(
            (psv: NomenclatureItemSizeValue) => psv.sizeValueId === nisv.sizeValueId
          );
          nisv.quantity = nisv.quantity * parentSizeValue?.quantity;
        });
        item.quantity = item.quantity * parent.quantity;
      }

      this.nomenclature.nomenclatureItems.splice(fromIndex + step, 0, item);
    });
  }

  public manageStockBoxThemeColor(borderBoxColor: string, textBoxColor: string): void {
    for (const box of this.divStockBox) {
      this.renderer.setStyle(box.nativeElement, "borderColor", borderBoxColor);
      this.renderer.setStyle(box.nativeElement, "color", textBoxColor);
    }
  }

  public isRowActive(row: NomenclatureItem): boolean {
    return row.level === 0;
  }

  public getRowClass(row: NomenclatureItem): string {
    return `level-${row.level > this.GRADIENT_LAST_LEVEL ? "end" : row.level}`;
  }

  public onTreeAction(currentRow: NomenclatureItem): void {
    if (this.fetchedNomenclatureItems.has(currentRow.itemId)) {
      if (this.alreadyExpandedRows.includes(currentRow.elementId)) {
        this.updateTreeStatus(currentRow);
        return;
      }

      this.addRowsToTree(
        this.fetchedNomenclatureItems
          .get(currentRow.itemId)
          .map((item: NomenclatureItem) => new NomenclatureItem(item)),
        currentRow
      );
      this.alreadyExpandedRows.push(currentRow.elementId);
      return;
    }

    this.retailItemService.getItemActiveNomenclature(currentRow.itemId).subscribe(
      (nomenclature: Nomenclature) => {
        if (parent && !this.fetchedNomenclatureItems.has(currentRow.itemId)) {
          this.fetchedNomenclatureItems.set(
            currentRow.itemId,
            nomenclature.nomenclatureItems.map((item: NomenclatureItem) => new NomenclatureItem(item))
          );
        }
        this.addRowsToTree(nomenclature.nomenclatureItems, currentRow);
        this.alreadyExpandedRows.push(currentRow.elementId);
      },
      error => {
        this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
      }
    );
  }

  public getTreeClasses(i: number, row: NomenclatureItem): string {
    let classes = `drawing level-${i} `;

    if (i === 0) {
      return classes;
    }

    let parentId = row.parentId;
    let childId = row.elementId;
    for (let nb = 0; nb < i; nb++) {
      const rowParent = this.nomenclature.nomenclatureItems.find(r => r.elementId === parentId);
      parentId = rowParent.parentId;
      childId = rowParent.elementId;
    }

    const parent: NomenclatureItem = this.nomenclature.nomenclatureItems.find(elt => elt.elementId === parentId);
    const currentChildIdx: number = parent.childrenIds.findIndex(id => id === childId);

    if (currentChildIdx < parent.childrenIds.length - 1) {
      classes += "line";
    }

    return classes;
  }

  public counter(i: number): number[] {
    return new Array(i).fill(1).map((_X, j) => j);
  }

  public getStatusClass(archived: boolean): string {
    return `status-${archived ? "inactive" : "active"}`;
  }

  public openPopup(): void {
    this.popupVisible = true;
  }

  public closePopup(): void {
    this.popupVisible = false;
  }

  public addComponents(items: StandardItem[]): void {
    const newNomenclatureItems: NomenclatureItem[] = items.map((item: StandardItem, index: number) => {
      return new NomenclatureItem({
        toSupply: true,
        position: this.nomenclature.nomenclatureItems.filter(row => row.level === 0).length + (index + 1),
        itemId: item.id,
        itemName: item.name,
        itemRef: item.reference,
        itemCategoryName: item.categoryName,
        itemUnitName: this.uoms.find(uom => uom.id === item.mainUnitId).shortName,
        itemHasNomenclature: item.nomenclatures.some(
          (nom: Nomenclature) => !nom.archived && nom.nomenclatureItems.length > 0
        ),
        quantity: 1,
        nomenclatureItemSizeValues: this.buildNomenclatureItemSizeValues(item.sizeCategory),
      });
    });

    this.buildRows(newNomenclatureItems, this.nomenclature.nomenclatureItems.length);
    this.refreshTableRows();
    this.closePopup();
  }

  public clickTowardList(): void {
    this.returnToList.emit();
  }

  public manageActions(actionId: number, row: NomenclatureItem): void {
    switch (actionId) {
      case this.DELETE_ITEM_ACTION: {
        this.deletingRow = row;
        this.deletePopupVisible = true;
        break;
      }

      case this.GO_TO_ITEM_FORM_ACTION: {
        window.open(
          this.router.serializeUrl(this.router.createUrlTree([`retail-item/update/${row.itemId}`])),
          "_blank"
        );
        break;
      }

      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  public closeDeletePopup(): void {
    this.deletePopupVisible = false;
  }

  public deleteRow(): void {
    this.removeLowerTree(this.deletingRow);
    this.refreshTableRows();

    this.nomenclature.nomenclatureItems
      .filter((row: NomenclatureItem) => row.level === 0)
      .forEach((row: NomenclatureItem, index: number) => {
        row.position = index + 1;
      });

    this.closeDeletePopup();
  }

  public trackById(elt: any): number {
    return elt.id;
  }

  private refreshTableRows(): void {
    this.tableRows = [...this.nomenclature.nomenclatureItems];
  }

  private removeLowerTree(currentRow: NomenclatureItem): void {
    const index: number = this.nomenclature.nomenclatureItems.findIndex(
      (row: NomenclatureItem) => row.elementId === currentRow.elementId
    );
    const childs: NomenclatureItem[] = this.nomenclature.nomenclatureItems.filter(
      (child: NomenclatureItem) => child.parentId === currentRow.elementId
    );

    this.alreadyExpandedRows.splice(this.alreadyExpandedRows.indexOf(this.deletingRow.elementId), 1);
    this.nomenclature.nomenclatureItems.splice(index, 1);
    childs.forEach((row: NomenclatureItem) => this.removeLowerTree(row));
  }

  private addRowsToTree(items: NomenclatureItem[], parentRow: NomenclatureItem): void {
    this.buildRows(items, this.nomenclature.nomenclatureItems.indexOf(parentRow) + 1, parentRow);
    this.updateTreeStatus(parentRow);
  }

  private updateMainQuantityFactorisation(row: NomenclatureItem, parentQuantity: number): void {
    if (row.parentId !== 0) {
      row.quantity = row.baseQuantity * parentQuantity;
    }

    const childs: NomenclatureItem[] = this.nomenclature.nomenclatureItems.filter(
      (child: NomenclatureItem) => child.parentId === row.elementId
    );

    childs.forEach((child: NomenclatureItem) => this.updateMainQuantityFactorisation(child, row.quantity));
  }

  private updateQuantityFactorisation(sizeValueId: number, row: NomenclatureItem, parentQuantity: number): void {
    const sizeValue = row.nomenclatureItemSizeValues.find(nisv => nisv.sizeValueId === sizeValueId);
    if (sizeValue) {
      if (row.parentId !== 0) {
        sizeValue.quantity = row.sizeValuesBaseQuantities.get(sizeValueId) * parentQuantity;
      }

      const childs: NomenclatureItem[] = this.nomenclature.nomenclatureItems.filter(
        (child: NomenclatureItem) => child.parentId === row.elementId
      );

      childs.forEach((child: NomenclatureItem) =>
        this.updateQuantityFactorisation(sizeValueId, child, sizeValue.quantity)
      );
    }
  }

  private updateTreeStatus(row: NomenclatureItem): void {
    const isExpanding: boolean = row.treeStatus === this.COLLAPSED_TREE;
    row.treeStatus = isExpanding ? this.EXPANDED_TREE : this.COLLAPSED_TREE;
    this.refreshTableRows();
  }

  private buildNomenclatureItemSizeValues(sizeCategory: OverrideSizeCategory): NomenclatureItemSizeValue[] {
    if (!sizeCategory) {
      return [];
    }

    return this.rootItemSizeCategory?.elements
      .filter((elt: OverrideSizeValue) => {
        return sizeCategory.elements.find(sv => sv.sizeValueId === elt.sizeValueId);
      })
      .map((elt: OverrideSizeValue) => {
        return new NomenclatureItemSizeValue({
          sizeValueId: elt.sizeValueId,
          quantity: 1,
        });
      });
  }

  private fillActionMenus(): void {
    this.activeMenuAction.push(
      new MenuAction(
        this.DELETE_ITEM_ACTION,
        this.translateService.instant("item.nomenclatures.nomenclature-form.datatable.actions.delete"),
        faTrash
      )
    );
    this.activeMenuAction.push(
      new MenuAction(
        this.GO_TO_ITEM_FORM_ACTION,
        this.translateService.instant("item.nomenclatures.nomenclature-form.datatable.actions.go-to-item-form"),
        faExternalLink
      )
    );

    this.inactiveMenuAction.push(
      new MenuAction(
        this.GO_TO_ITEM_FORM_ACTION,
        this.translateService.instant("item.nomenclatures.nomenclature-form.datatable.actions.go-to-item-form"),
        faExternalLink
      )
    );
  }

  private fetchRootItemSizeCategory(): Observable<SizeCategory> {
    if (!this.rootItemSizeCategory) {
      return of(null);
    }

    return this.sizeCategoryService.get(this.rootItemSizeCategory?.sizeCategoryId).pipe(
      tap(
        (sizeCategory: SizeCategory) => {
          this.sizeCategory = sizeCategory;
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  private fetchUoms(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.uoms = uoms;
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }
}
