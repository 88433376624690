<ui-container>
  <div class="filters" *ngIf="filterer">
    <ui-dynamic-filters
      label="{{ 'filters.label' | translate }}"
      [filters]="filterer.getFilters()"
      [(filterValues)]="filterer.filterValues"
      applyLabel="{{ 'filters.apply' | translate }}"
      (apply)="applyFilters()"
      minLabel="{{ 'filters.min' | translate }}"
      maxLabel="{{ 'filters.max' | translate }}"
      deleteLabel="{{ 'filters.delete' | translate }}"
      placeholderPrefix="{{ 'filters.prefix' | translate }} "
    >
    </ui-dynamic-filters>
  </div>
  <div class="retail-item-table">
    <div>
      <ngx-datatable
        #table
        [rows]="rows"
        [trackByProp]="'id'"
        class="material"
        columnMode="flex"
        headerHeight="auto"
        footerHeight="50"
        rowHeight="40"
        (activate)="catchEvent($event)"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down'
        }"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        [externalSorting]="true"
        [sorts]="sorts"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
        [externalPaging]="true"
        [count]="pager.totalElements"
        [offset]="pager.number"
        [limit]="pager.size"
      >
        <ngx-datatable-column *ngIf="selection && itemForm" [resizeable]="false" [sortable]="false" [flexGrow]="0.2">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="row-radio">
              <ui-radio-button
                [options]="row.selectOption"
                [formControl]="itemForm"
                customClass="no-margin"
                direction="vertical"
                name="account"
              ></ui-radio-button>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'retail-item-list.datatable.columns.category' | translate }}"
          prop="category"
          cellClass="category"
          [flexGrow]="0.8"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'retail-item-list.datatable.columns.code' | translate }}"
          prop="code"
          cellClass="code"
          [flexGrow]="0.5"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'retail-item-list.datatable.columns.designation' | translate }}"
          prop="designation"
          cellClass="designation "
          [flexGrow]="0.7"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'retail-item-list.datatable.columns.references' | translate }}"
          cellClass="references ellipsis-counter"
          [resizeable]="false"
          [sortable]="false"
          prop="references"
          [flexGrow]="1"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-references="row.references">
            <span class="ellipsis references" title="{{ references.join(', ') }}">{{ references.join(", ") }}</span>
            <div *ngIf="references.length >= 2" title="{{ row.counter }}">
              <div #referencesCounter>
                <ui-counter [number]="references.length"></ui-counter>
              </div>
              <ui-tooltip maxWidth="300px" [element]="referencesCounter">
                <span style="font-weight: normal !important">{{ references.join(", ") }}</span>
              </ui-tooltip>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'retail-item-list.datatable.columns.brand' | translate }}"
          prop="brand"
          cellClass="brand"
          [flexGrow]="0.5"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'retail-item-list.datatable.columns.purchase-price' | translate }}"
          cellClass="purchase-price ellipsis-counter"
          prop="purchasePrice"
          [resizeable]="false"
          [sortable]="false"
          [flexGrow]="0.7"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-purchasePrice="row.purchasePrice">
            <span class="ellipsis purchasePrice" title="{{ purchasePrice.join(' | ') }}">
              {{ purchasePrice.join(" | ") }}
            </span>
            <div *ngIf="purchasePrice.length >= 2" title="{{ row.counter }}">
              <div #purchasePriceCounter>
                <ui-counter [number]="purchasePrice.length"></ui-counter>
              </div>
              <ui-tooltip maxWidth="300px" [element]="purchasePriceCounter">
                <span style="font-weight: normal !important; white-space: break-spaces">{{
                  purchasePrice.join("\n")
                }}</span>
              </ui-tooltip>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'retail-item-list.datatable.columns.sell-price' | translate }}"
          prop="sellPrice"
          cellClass="sell-price"
          [flexGrow]="0.7"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
            <span class="sell-price">
              {{ value | formatCurrency: this.getCurrency(row).codeISO:"symbol":"0.2-5":locale }}
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'retail-item-list.datatable.columns.status' | translate }}"
          prop="status"
          cellClass="status"
          [flexGrow]="0.5"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
            <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"></ui-status-label>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="rowCount">
              {{
                rowCount
                  | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                  | translate: { rowCount: rowCount }
              }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="changePage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</ui-container>
