import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import * as HttpStatus from "http-status-codes";
import { PasswordUtil } from "generic-pages";
import { CaraUser, AuthService, CaraUserService, ThemeService, StoreLink } from "center-services";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "gen-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
  providers: [SubscriptionService],
})
export class LoginFormComponent implements OnInit {
  @Input() id: string;
  loginForm: UntypedFormGroup;
  submitted: boolean = false;
  forgottenPasswordPopupVisible: boolean = false;
  loginImgScr: string;
  public popupVisible: boolean = false;
  storeSelectionPopupVisible: boolean = false;
  currentUser: CaraUser;
  public pattern: any = {
    Q: {
      pattern: new RegExp("[^~]+"),
    },
  };
  private isBlurred: any = {};

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private caraUserService: CaraUserService,
    private messageService: MessageService,
    private router: Router,
    private translateService: TranslateService,
    private themeService: ThemeService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      if (
        this.authService.getAuthorities().length === 1 &&
        this.authService.getAuthorities()[0] === "USER_PASSWORD_PIN_CODE_UPDATE"
      ) {
        this.popupVisible = true;
      } else {
        this.router.navigateByUrl("/");
      }
    }
    this.loginImgThemeManagement();
    this.prepareForm();
  }

  prepareForm(): void {
    this.loginForm = this.fb.group({
      user: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    const encryptedPassword = PasswordUtil.encrypt(this.loginForm.value.password);
    this.subscriptionService.subs.push(
      this.authService.authenticate(this.loginForm.value.user, encryptedPassword.toString()).subscribe(
        () => {
          this.subscriptionService.subs.push(
            this.caraUserService.fetchConnectedUser().subscribe(
              connectedUser => {
                this.currentUser = connectedUser;
                const selectedStoreId = this.authService.getContextStoreId();
                if (
                  connectedUser.tempPassword &&
                  this.authService.getAuthorities().length === 1 &&
                  this.authService.getAuthorities()[0] === "USER_PASSWORD_PIN_CODE_UPDATE"
                ) {
                  this.popupVisible = true;
                } else {
                  this.openStorePopup(selectedStoreId);
                }
              },
              error => {
                console.error(error);
                const title = this.translateService.instant("message.title.api-errors");
                this.messageService.warn("Cannot fetch logged user", { title });
              }
            )
          );
        },
        error => {
          if (error.status === HttpStatus.UNAUTHORIZED) {
            error.error?.exception?.name === "UserHandlingException"
              ? this.handleUnauthorizedStatus("login-form.error.user-title", "login-form.error.user-message")
              : this.handleUnauthorizedStatus("message.title.form-errors", "login-form.error.unauthorized");
          } else {
            const title = this.translateService.instant("message.title.api-errors");
            const content = this.translateService.instant("login-form.error.submit", { message: error.message });
            this.messageService.error(content, { title });
          }
        }
      )
    );
  }

  handleUnauthorizedStatus(header: string, msg: string): void {
    const title = this.translateService.instant(header);
    const content = this.translateService.instant(msg);
    this.messageService.error(content, { title });
  }

  isSelectedStoreAvailable(selectedStoreId: number): boolean {
    const store = this.currentUser.stores?.find(sto => sto.storeId === selectedStoreId);

    return !!store && store.status !== "INACTIVE" && !(store.archived && !store.byDefault);
  }

  getAvailableStores(): StoreLink[] {
    return this.currentUser.stores?.filter(
      store => store.status !== "INACTIVE" && ((store.archived && store.byDefault) || !store.archived)
    );
  }

  openStorePopup(selectedStoreId: number = null): void {
    if (!this.isSelectedStoreAvailable(selectedStoreId)) {
      const availableStores = this.getAvailableStores() ?? [];

      if (availableStores.length === 0) {
        this.handleUnauthorizedStatus("login-form.error.user-title", "login-form.error.user-message");
        this.authService.logout();
        return;
      }

      this.subscriptionService.subs.push(
        this.caraUserService.changeContext(availableStores[0].storeId).subscribe(result => {
          this.authService.saveToken(result);
          this.storeSelectionPopupVisible = true;
        })
      );
    } else {
      this.router.navigateByUrl("/");
    }
  }

  onBlur(controlName: string | number): void {
    this.isBlurred[controlName] = true;
  }

  onFocus(controlName: string | number): void {
    this.isBlurred[controlName] = false;
  }

  getStatus(controlName: string): string {
    if ((this.submitted || this.isBlurred[controlName]) && this.hasError(controlName)) {
      return "error";
    } else {
      return undefined;
    }
  }

  getErrorMessage(controlName: string): string {
    if (this.submitted || this.isBlurred[controlName]) {
      return this.getError(controlName);
    } else {
      return undefined;
    }
  }

  // Errors
  hasError(controlName: string): boolean {
    const control: AbstractControl = this.loginForm.get(controlName);
    return control.errors !== null;
  }

  getError(controlName: string): string {
    const control: AbstractControl = this.loginForm.get(controlName);

    if (control.hasError("required")) {
      switch (controlName) {
        case "user":
          return "login-form.error.user";
        case "password":
          return "login-form.error.password";
        default:
          return "global.errors.empty";
      }
    }
    return null;
  }

  onValidatePassword(): void {
    this.popupVisible = false;
    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
    const selectedStoreId = this.authService.getContextStoreId();
    this.openStorePopup(selectedStoreId);
  }

  onCloseStoreSelectionPopup(): void {
    this.storeSelectionPopupVisible = false;
  }

  openForgottenPasswordPopup(): void {
    this.forgottenPasswordPopupVisible = true;
  }

  onCloseForgottenPasswordPopup(): void {
    this.forgottenPasswordPopupVisible = false;
  }

  loginImgThemeManagement(): void {
    this.loadLoginImgByTheme();
    this.subscriptionService.subs.push(
      this.themeService.themeChange.subscribe(() => {
        this.loadLoginImgByTheme();
      })
    );
  }

  loadLoginImgByTheme(): void {
    if (this.themeService.getValue()) {
      this.loginImgScr = "./assets/images/light_login_window.png";
    } else {
      this.loginImgScr = "./assets/images/dark_login_window.png";
    }
  }
}
