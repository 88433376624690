import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationHandlerService {
  public isVisible: Subject<boolean> = new Subject<boolean>();

  public showHandler(): void {
    this.isVisible.next(true);
  }

  public removeHandler(): void {
    this.isVisible.next(false);
  }

  public getHandlerVisibility(): Observable<boolean> {
    return this.isVisible.asObservable();
  }
}
