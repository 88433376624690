import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  AbstractCustomer,
  Address,
  CaraUser,
  Country,
  CountryService,
  Light,
  LightService,
  PersonTitleType,
  ReceiptCommercialOfferType,
  Store,
  StoreService,
  Tag,
  TagService,
  UserService,
} from "center-services";
import { MessageService, Tag as TagComponent } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { DayjsUtil, Option, SubscriptionService } from "fugu-common";
import { TranslateService } from "@ngx-translate/core";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-customer-individual-general",
  templateUrl: "./customer-individual-general-datas.html",
  styleUrls: ["./customer-individual-general-datas.scss"],
  providers: [SubscriptionService],
})
export class CustomerIndividualGeneralDatasComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() editedCustomer: AbstractCustomer;
  public pattern: any = {
    P: {
      pattern: new RegExp("[+]|[0-9]"),
      optional: true,
    },
    N: {
      pattern: new RegExp("\\d"),
    },
    Q: {
      pattern: new RegExp("[^~]+"),
    },
  };
  public countries: Country[];
  public countryOptions: Option[] = [];
  public receiptCommercialOfferOptions: Option[] = [];
  public storeList: Light[] = [];
  public typeOptions: Option[] = [];
  public allCustomerTags: Tag[];
  public availableTags: TagComponent[] = [];
  public customerForm: UntypedFormGroup;
  public selectedFile: any;
  public previousFile: any;
  public mandatoryArray: Set<string> = new Set<string>();
  public mainStore: Store;
  public locale: string;
  public dateFormat: string;
  public appendTo: any = document.body;
  private initObservables: Observable<any>[];

  constructor(
    private translateService: TranslateService,
    private countryService: CountryService,
    private messageService: MessageService,
    private tagService: TagService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private storeService: StoreService,
    private lightService: LightService,
    private userService: UserService<CaraUser>,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    this.buildReceiptCommercialOfferOptions();
    this.buildTypeOptions();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildReceiptCommercialOfferOptions();
        this.buildTypeOptions();
      })
    );

    this.initObservables = [];
    this.initObservables.push(this.fetchAllCustomerTags());
    this.initObservables.push(this.fetchCountries());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchMainStore());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedCustomer) {
          this.loadEditedData();
        }
      })
    );
  }

  ngOnChanges(): void {
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedCustomer) {
          this.loadEditedData();
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  prepareForm(): void {
    this.customerForm = this.fb.group({
      personTitle: [null],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      lines: [null],
      cityCode: [null],
      city: [null],
      country: [null],
      email: [null, [Validators.email]],
      phone: [null],
      mobilePhone: [null],
      color: [null],
      comment: [null],
      tags: [[]],
      customerType: [null],
      receiptCommercialOfferType: [null],
      birthDate: [null],
    });

    this.subscriptionService.subs.push(
      this.customerForm.controls.receiptCommercialOfferType.valueChanges.subscribe(value => {
        this.editedCustomer.receiptCommercialOffers = value.map(
          (index: number) => Object.values(ReceiptCommercialOfferType)[index]
        );
        this.manageCommercialOffersValidators();
      })
    );
  }

  buildReceiptCommercialOfferOptions(): void {
    this.receiptCommercialOfferOptions = Object.keys(ReceiptCommercialOfferType).map(
      (key, index) => new Option(index, this.translateService.instant(`receipt-commercial-offer-options.${key}`))
    );
  }

  fetchAllCustomerTags(): Observable<Tag[]> {
    return this.tagService.getAllTagsOfCategoryByName("Customer").pipe(
      tap(
        (tags: Tag[]) => {
          this.allCustomerTags = tags;
          this.availableTags = tags
            .filter((obj: Tag) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Tag) => new TagComponent(obj.id, obj.name, obj.color));
        },
        () => {
          const content = this.translateService.instant("tags-list.errors.get-tags");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCountries(): Observable<Country[]> {
    return this.countryService.getAll().pipe(
      tap(
        (countries: Country[]) => {
          this.countries = countries;
          countries.forEach((country: Country) => {
            if (country.byDefault && (!this.editedCustomer || !this.editedCustomer.id)) {
              this.customerForm.controls.country.setValue(country.id);
            }
          });
          this.countryOptions = countries
            .filter((obj: Country) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Country) => new Option(obj.id, obj.name));
        },
        () => {
          const content = this.translateService.instant("countries-list.errors.get-countries");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-main-store", error.message);
        }
      )
    );
  }

  loadEditedData(): void {
    this.customerForm.patchValue({
      firstName: this.editedCustomer.firstName,
      lastName: this.editedCustomer.lastName,
      email: this.editedCustomer.email,
      phone: this.editedCustomer.phone,
      mobilePhone: this.editedCustomer.mobilePhone,
      color: this.editedCustomer.color,
      comment: this.editedCustomer.comment,
      tags: this.editedCustomer.tagIds,
      customerType: this.editedCustomer.customerType,
      birthDate: DayjsUtil.dayjsOrNull(this.editedCustomer.birthDate, true),
    });

    // handle type
    if (this.editedCustomer.personTitle) {
      const index = Object.keys(PersonTitleType).indexOf(this.editedCustomer.personTitle);
      this.customerForm.controls.personTitle.setValue(index);
    }

    if (this.editedCustomer.address) {
      this.customerForm.patchValue({
        lines: this.editedCustomer.address.lines,
        cityCode: this.editedCustomer.address.cityCode,
        city: this.editedCustomer.address.city,
      });
    }

    if (this.editedCustomer.address?.countryId) {
      this.customerForm.patchValue({ country: this.editedCustomer.address.countryId });
    }

    if (this.editedCustomer.receiptCommercialOffers.length > 0) {
      const indexArray = this.editedCustomer.receiptCommercialOffers.map(receipt =>
        Object.keys(ReceiptCommercialOfferType).indexOf(receipt)
      );
      this.customerForm.controls.receiptCommercialOfferType.setValue(indexArray);
    }
  }

  applyModifications(): void {
    const cloneAddress = new Address(this.editedCustomer.address);
    cloneAddress.lines = this.customerForm.controls.lines.value;
    cloneAddress.city = this.customerForm.controls.city.value;
    cloneAddress.cityCode = this.customerForm.controls.cityCode.value;
    cloneAddress.countryId = this.customerForm.controls.country.value;
    cloneAddress.byDefault = true;

    this.editedCustomer.personTitle = Object.values(PersonTitleType)[this.customerForm.value.personTitle];
    this.editedCustomer.lastName = this.customerForm.value.lastName;
    this.editedCustomer.firstName = this.customerForm.value.firstName;
    this.editedCustomer.birthDate = this.customerForm.value.birthDate
      ? this.customerForm.value.birthDate.toDate()
      : null;
    this.editedCustomer.email = this.customerForm.value.email;
    this.editedCustomer.phone = this.customerForm.value.phone;
    this.editedCustomer.mobilePhone = this.customerForm.value.mobilePhone;
    this.editedCustomer.address = cloneAddress;
    this.editedCustomer.color = this.customerForm.value.color;
    this.editedCustomer.comment = this.customerForm.value.comment;
    this.editedCustomer.tagIds = this.customerForm.value.tags;
    this.editedCustomer.receiptCommercialOffers = this.customerForm.value.receiptCommercialOfferType
      ? this.customerForm.value.receiptCommercialOfferType.map(
        (index: number) => Object.values(ReceiptCommercialOfferType)[index]
      )
      : [];
  }

  updateCustomer(): string {
    // classic fields
    if (this.customerForm.invalid) {
      this.customerForm.markAllAsTouched();
      if (this.findOffersRequiredField()) {
        return "commercialOffersErrors";
      }
      return "defaultErrors";
    }

    this.applyModifications();
    return "valid";
  }

  getCustomerTypeLabel(): string {
    return `customer.general-datas.customer-management.${this.editedCustomer.customerType}`;
  }

  getStoreNameLabel(): string {
    let storeNameLabel =
      this.storeList && this.storeList.length > 0
        ? this.storeList.find(store => store.id === this.editedCustomer.createdByStoreId)?.name
        : "";
    if (storeNameLabel === undefined) {
      storeNameLabel = this.mainStore?.name;
    }
    return storeNameLabel;
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  setAndUpdateValidator(controlName: string, validators: any): void {
    this.customerForm.controls[controlName].setValidators(validators);
    this.customerForm.controls[controlName].updateValueAndValidity();
  }

  clearAndUpdateValidator(controlName: string): void {
    this.customerForm.controls[controlName].clearValidators();
    this.customerForm.controls[controlName].updateValueAndValidity();
  }

  findOffersRequiredField(): boolean {
    return [...this.mandatoryArray].some(controlName => this.hasValidatorRequired(controlName));
  }

  hasValidatorRequired(controlName: string): boolean {
    return this.customerForm.controls[controlName]?.errors?.required ? true : false;
  }

  // Add or remove controlName from 'mandatoryArray' depending of selected commercial offers
  manageCommercialOffersValidators(): void {
    this.manageAddressValidator();

    if (this.editedCustomer.receiptCommercialOffers.includes(ReceiptCommercialOfferType.BY_EMAIL)) {
      this.setAndUpdateValidator("email", [Validators.email, Validators.required]);
      this.mandatoryArray.add("email");
    } else {
      this.setAndUpdateValidator("email", Validators.email);
      if (this.mandatoryArray.has("email")) {
        this.mandatoryArray.delete("email");
      }
    }

    if (this.editedCustomer.receiptCommercialOffers.includes(ReceiptCommercialOfferType.BY_SMS)) {
      this.setAndUpdateValidator("mobilePhone", Validators.required);
      this.mandatoryArray.add("mobilePhone");
    } else {
      this.clearAndUpdateValidator("mobilePhone");
      if (this.mandatoryArray.has("mobilePhone")) {
        this.mandatoryArray.delete("mobilePhone");
      }
    }

    if (this.editedCustomer.receiptCommercialOffers.includes(ReceiptCommercialOfferType.BY_PHONE)) {
      this.setAndUpdateValidator("phone", Validators.required);
      this.mandatoryArray.add("phone");
    } else {
      this.clearAndUpdateValidator("phone");
      if (this.mandatoryArray.has("phone")) {
        this.mandatoryArray.delete("phone");
      }
    }
  }

  manageAddressValidator(): void {
    if (this.editedCustomer.customerType === "INDIVIDUAL") {
      if (this.editedCustomer.receiptCommercialOffers.includes(ReceiptCommercialOfferType.BY_MAIL)) {
        this.setAndUpdateValidator("lines", Validators.required);
        this.setAndUpdateValidator("cityCode", Validators.required);
        this.setAndUpdateValidator("city", Validators.required);
        this.setAndUpdateValidator("country", Validators.required);
        this.mandatoryArray.add("lines");
      } else {
        this.clearAndUpdateValidator("lines");
        this.clearAndUpdateValidator("cityCode");
        this.clearAndUpdateValidator("city");
        this.clearAndUpdateValidator("country");
        if (this.mandatoryArray.has("lines")) {
          this.mandatoryArray.delete("lines");
        }
      }
    }
  }

  buildTypeOptions(): void {
    this.typeOptions = Object.keys(PersonTitleType).map(
      (key, index) => new Option(index, this.translateService.instant(`employees-popup.fields.type-options.${key}`))
    );
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeList = lightStores;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("delivery-initiator-popup.errors.get-stores");
          this.messageService.warn(content, { title });
        }
      )
    );
  }
}
