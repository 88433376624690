<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ 'metal-account-detail.buttons.back-button' | translate }}"
        (click)="backToGeneralMetalAccounts()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
    </div>
    <div class="title">
      <ui-title
        titleText="{{ 'metal-account-detail.title' | translate }}"
        subTitleText="{{ subTitle ? subTitle : undefined }}"
      ></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-print">
        <ui-button
          text="{{ 'metal-account-detail.buttons.print' | translate }}"
          (click)="printMetalAccountMovements()"
          [disabled]="pager.totalElements === 0"
          [btnIcon]="faPrint"
        ></ui-button>
      </div>

      <div class="button-metal-management">
        <ui-button
          text="{{ getButtonText() | translate }}"
          (click)="goToContactMetalManagement()"
          [btnIcon]="faLongArrowRight"
        ></ui-button>
      </div>
    </div>

    <div class="metal-account-body">
      <ui-container>
        <div class="account-table">
          <ngx-datatable
            #table
            [trackByProp]="'index'"
            [rows]="accountRows"
            headerHeight="auto"
            columnMode="flex"
            footerHeight="30"
            class="material"
            rowHeight="40"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            (activate)="selectMetalAccount($event)"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
          >
            <ngx-datatable-column
              name="{{ 'metal-account-detail.account-datatable.columns.selection' | translate }}"
              [resizeable]="false"
              [sortable]="false"
              [flexGrow]="0.2"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="row-radio">
                  <ui-radio-button
                    [options]="row.selectOption"
                    [formControl]="accountForm"
                    customClass="no-margin"
                    direction="vertical"
                    name="account"
                  ></ui-radio-button>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'metal-account-detail.account-datatable.columns.metal' | translate }}"
              cellClass="metal"
              prop="metalAccount.metalName"
              [resizeable]="false"
              [sortable]="false"
              [flexGrow]="1"
            >
              <ng-template
                ngx-datatable-cell-template
                let-metalName="row.metalAccount.metalName"
                let-activated="row.metalAccount.activated"
              >
                <div class="{{ this.getCellClass(activated) }}">
                  {{ metalName }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'metal-account-detail.account-datatable.columns.balance' | translate }}"
              cellClass="balance"
              prop="metalAccount.balance"
              [resizeable]="false"
              [sortable]="false"
              [flexGrow]="0.2"
            >
              <ng-template
                ngx-datatable-cell-template
                let-metalBalance="row.metalAccount.balance"
                let-activated="row.metalAccount.activated"
              >
                <div class="{{ this.getCellClass(activated, metalBalance) }}">
                  {{
                    metalBalance !== null
                      ? (metalBalance | number: "1.0-3":this.locale) + "g"
                      : (null | number: "1.0-3":this.locale)
                  }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer> </ngx-datatable-footer>
          </ngx-datatable>
        </div>

        <div class="movement-table">
          <div class="filters border-bottom-filters" *ngIf="filters">
            <ui-dynamic-filters
              [filters]="filters"
              [(filterValues)]="filterValues"
              (apply)="applyFilters()"
              dateFormat="{{ dateFormat }}"
              label="{{ 'filters.label' | translate }}"
              minLabel="{{ 'filters.min' | translate }}"
              maxLabel="{{ 'filters.max' | translate }}"
              applyLabel="{{ 'filters.apply' | translate }}"
              deleteLabel="{{ 'filters.delete' | translate }}"
              placeholderPrefix="{{ 'filters.prefix' | translate }} "
              mandatoryErrorMessage="{{ 'metal-account-detail.errors.filters' | translate }}"
            >
            </ui-dynamic-filters>
          </div>
          <div class="top-table-buttons" *ngIf="selectedMetalAccount">
            <ui-button
              text="{{ 'metal-account-detail.buttons.add' | translate }}"
              *hasPermission="['METAL_ACCOUNT_MOVEMENT_CREATE']"
              [disabled]="!selectedMetalAccount.activated"
              (click)="openMetalAccountPopup()"
              type="add"
              primary="true"
            >
            </ui-button>
          </div>
          <ngx-datatable
            #movementTable
            [count]="pager.totalElements"
            [externalSorting]="true"
            [externalPaging]="true"
            [offset]="pager.number"
            [rows]="movementRows"
            [trackByProp]="'id'"
            [rowClass]="getRowClass"
            [limit]="pager.size"
            headerHeight="auto"
            footerHeight="50"
            columnMode="flex"
            rowHeight="40"
            class="material"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'metal-account-detail.movement-datatable.columns.date' | translate }}"
              [resizeable]="false"
              cellClass="date"
              [flexGrow]="0.8"
              prop="date"
            >
              <ng-template ngx-datatable-cell-template let-date="row.date">
                <span>{{ date | dateTimeFormat: false }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'metal-account-detail.movement-datatable.columns.type' | translate }}"
              [resizeable]="false"
              cellClass="type"
              [flexGrow]="1"
              prop="type"
            >
              <ng-template ngx-datatable-cell-template let-quantity="row.quantity" let-type="row.type">
                <div class="type-content">
                  <ui-tag [label]="type.label" [customClass]="type.className"></ui-tag>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'metal-account-detail.movement-datatable.columns.quantity' | translate }}"
              cellClass="quantity"
              [resizeable]="false"
              [flexGrow]="0.6"
              prop="quantity"
            >
              <ng-template ngx-datatable-cell-template let-quantity="row.quantity">
                <div class="{{ this.getCellClass(true, quantity) }}">
                  {{
                    quantity !== null
                      ? (quantity | number: "1.0-3":this.locale) + "g"
                      : (null | number: "1.0-3":this.locale)
                  }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'metal-account-detail.movement-datatable.columns.order-number' | translate }}"
              cellClass="order-number"
              [resizeable]="false"
              prop="orderNumber"
              [flexGrow]="0.8"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'metal-account-detail.movement-datatable.columns.document-number' | translate }}"
              cellClass="document-number"
              [resizeable]="false"
              prop="documentNumber"
              [flexGrow]="0.8"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'metal-account-detail.movement-datatable.columns.comment' | translate }}"
              [resizeable]="false"
              prop="comment"
              [flexGrow]="2"
            >
              <ng-template ngx-datatable-cell-template let-comment="row.comment">
                <p #commentText class="comment">{{ comment }}</p>

                <ui-tooltip maxWidth="300px" [element]="commentText">
                  <span style="font-weight: normal !important; text-align: justify; display: flex">{{ comment }}</span>
                </ui-tooltip>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'metal-account-detail.movement-datatable.columns.balance' | translate }}"
              cellClass="balance"
              [resizeable]="false"
              [sortable]="false"
              [flexGrow]="0.9"
              prop="balance"
            >
              <ng-template ngx-datatable-cell-template let-balance="row.balance">
                <div class="{{ this.getCellClass(true, balance) }}">
                  {{
                    balance !== null
                      ? (balance | number: "1.0-3":this.locale) + "g"
                      : (null | number: "1.0-3":this.locale)
                  }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-selectedCount="selectedCount"
                let-isVisible="isVisible"
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-curPage="curPage"
                let-offset="offset"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  (change)="changePage($event)"
                  [hidden]="!(rowCount / pageSize > 1)"
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>

    <!-- Popup -->
    <app-metal-account-popup
      *ngIf="popupVisible"
      (close)="onClosePopup()"
      [contactId]="contact.id"
      [contactType]="contactType"
      (validate)="onValidatePopup()"
      [metalAccountId]="selectedMetalAccount.id"
      [contactMetalAccountList]="activeMetalAccounts"
    ></app-metal-account-popup>
  </div>
</ng-container>
