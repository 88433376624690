<ng-container>
  <app-retail-item-nomenclatures-list
    *ngIf="this.item && this.listVisible"
    [(nomenclaturesList)]="this.item.nomenclatures"
    (changeNomenclature)="changeCurrentNomenclature($event)"
  >
  </app-retail-item-nomenclatures-list>

  <app-retail-item-nomenclature-lines-form
    *ngIf="this.item && this.currentNomenclature && !this.listVisible"
    [rootItemSizeCategory]="item?.sizeCategory"
    [rootItemId]="item?.id"
    [(nomenclature)]="currentNomenclature"
    (returnToList)="switch()"
  >
  </app-retail-item-nomenclature-lines-form>
</ng-container>
