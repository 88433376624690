import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import { AuthService, Store, StoreService, User, UserService } from "center-services";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MainStoreGuard implements CanActivate {
  public connectedUser: User;
  public mainStore: Store = undefined;

  constructor(
    public authService: AuthService,
    public userService: UserService<User>,
    public storeService: StoreService,
    public translateService: TranslateService,
    public messageService: MessageService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    const selectedStoreId = this.authService.getContextStoreId();

    return this.fetchMainStore().pipe(
      map(mainStore => selectedStoreId === mainStore.id),
      tap(isMainStore => {
        if (!isMainStore) {
          this.router.navigate(["/"]);
        }
        return of(isMainStore);
      }),
      catchError(error => {
        const title = this.translateService.instant("message.title.api-errors");
        const res = this.translateService.instant("main-store-guard.errors.get-main-store", {
          message: error.message,
        });
        this.messageService.warn(res, { title });
        return of(false);
      })
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.mainStore
      ? of(this.mainStore)
      : this.storeService.getMain().pipe(tap(store => (this.mainStore = store)));
  }
}
