import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faPlus, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { ComparablePeriod, CaraUserService } from "center-services";
import { CommonValidatorsUtil, DayjsUtil, SubscriptionService } from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import dayjs from "dayjs";

@Component({
  selector: "app-comparable-surface",
  templateUrl: "./comparable-surface.component.html",
  styleUrls: ["./comparable-surface.component.scss"],
  providers: [SubscriptionService],
})
export class ComparableSurfaceComponent implements OnInit, OnChanges {
  @Input() periodList: ComparablePeriod[];
  @Output() periodListChange: EventEmitter<ComparablePeriod[]> = new EventEmitter<ComparablePeriod[]>();

  @ViewChild("table") table: DatatableComponent;

  faPlus: IconDefinition = faPlus;
  faTrash: IconDefinition = faTrashAlt;
  public rows: any[] = [];

  public popupTitle: any;
  public popupVisible: boolean = false;

  public locale: string;
  public menuActions: MenuAction[] = [];
  public dateFormat: string = "MMMM YYYY";

  public shouldClose: boolean = false;

  public periodIndex: number;
  public periodForm: UntypedFormGroup;
  public unsavedPeriod: ComparablePeriod;
  public initialPeriod: ComparablePeriod;
  public selectedPeriod: ComparablePeriod;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.addMenuActions();
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.periodList && !changes.periodList.firstChange) {
      this.refresh();
    }
  }

  refresh(): void {
    this.prepareForm();
    this.fetchPeriodList();

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
      })
    );
  }

  fetchPeriodList(): void {
    this.rows = [];

    this.periodList.forEach((period: ComparablePeriod) => {
      const index = this.periodList.indexOf(period);
      this.rows.push({
        index,
        beginDate: this.capitalizeFirstLetter(dayjs(period.beginDate).format(this.dateFormat)),
        endDate: this.capitalizeFirstLetter(dayjs(period.endDate).format(this.dateFormat)),
        comment: period.comment,
      });
    });
  }

  capitalizeFirstLetter(stringToCapitalize: string): string {
    return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
  }

  addMenuActions(): void {
    this.menuActions = [];

    this.menuActions.push(
      new MenuAction(0, this.translateService.instant("comparable-surface.actions.remove"), faTrashAlt)
    );
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case 0:
        this.removePeriod(row);
        break;
      default:
        break;
    }
  }

  addPeriod(): void {
    this.selectedPeriod = new ComparablePeriod({
      beginDate: null,
      endDate: null,
      comment: null,
    });

    this.popupTitle = "comparable-surface.popup.title-new-period";
    this.periodIndex = null;
    this.initializePopup();
  }

  updatePeriod(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("STORE_UPDATE")) {
        return;
      }

      this.selectedPeriod = new ComparablePeriod(this.periodList[event.row.index]);
      this.popupTitle = "comparable-surface.popup.title-update-period";
      this.periodIndex = event.row.index;
      this.initializePopup();
    }
  }

  removePeriod(row: any): void {
    this.periodList.splice(row.index, 1);
    this.fetchPeriodList();
    this.periodListChange.emit(this.periodList);
  }

  initializePopup(): void {
    this.periodForm.controls.beginDate.setValue(DayjsUtil.dayjsOrNull(this.selectedPeriod.beginDate, true));
    this.periodForm.controls.endDate.setValue(DayjsUtil.dayjsOrNull(this.selectedPeriod.endDate, true));
    this.periodForm.controls.comment.setValue(this.selectedPeriod.comment);
    this.initialPeriod = new ComparablePeriod(this.selectedPeriod);
    // Show popup after all data are ready
    this.popupVisible = true;
  }

  closePopup(): void {
    this.unsavedPeriod = null;
    this.selectedPeriod = null;
    this.popupVisible = false;
    this.shouldClose = false;
    this.periodForm.markAsUntouched();
  }

  canClose(): void {
    this.applyModifications();
    if (this.unsavedPeriod && !this.unsavedPeriod.equals(this.selectedPeriod)) {
      this.shouldClose = false;
    }

    if (!this.initialPeriod.equals(this.selectedPeriod) && !this.shouldClose) {
      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedPeriod = new ComparablePeriod(this.selectedPeriod);
      this.shouldClose = true;
    } else {
      this.closePopup();
    }
  }

  prepareForm(): void {
    this.periodForm = this.fb.group({
      beginDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      comment: [null, []],
    });

    this.periodForm.setValidators([
      CommonValidatorsUtil.dateValidator(this.periodForm.controls.beginDate, this.periodForm.controls.endDate),
    ]);
  }

  applyModifications(): void {
    this.selectedPeriod.beginDate = this.periodForm.value.beginDate ? this.periodForm.value.beginDate.toDate() : null;
    this.selectedPeriod.endDate = this.periodForm.value.endDate ? this.periodForm.value.endDate.toDate() : null;
    this.selectedPeriod.comment = this.periodForm.value.comment;
  }

  submitPeriod(): void {
    if (this.periodForm.invalid) {
      this.periodForm.markAllAsTouched();
      return;
    }

    this.applyModifications();

    if (this.periodIndex !== null) {
      this.periodList[this.periodIndex] = this.selectedPeriod;
    } else {
      this.periodList.push(this.selectedPeriod);
    }

    this.fetchPeriodList();
    this.periodListChange.emit(this.periodList);
    this.closePopup();
  }
}
