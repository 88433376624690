import { StandardItem } from "center-services";
import { Comparator } from "fugu-common";

export class NomenclatureItemSelectionOutput extends Comparator {
  selectedItems: StandardItem[] = [];

  constructor(values: any = {}) {
    super();
    if (values.selectedItems) {
      this.selectedItems = values.selectedItems.map((item: StandardItem) => {
        return new StandardItem(item);
      });
    }
  }

  protected compareValues(attrName: string, thisValue: any[], otherValue: any[]): boolean {
    if (attrName === "selectedItems") {
      const thisIds = thisValue.map((item: StandardItem) => item.id);
      const otherIds = otherValue.map((item: StandardItem) => item.id);
      return super.compareValues("selectedItemsIds", thisIds, otherIds);
    } else {
      return super.compareValues(attrName, thisValue, otherValue);
    }
  }
}
