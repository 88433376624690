<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'customization.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <ui-button
        text="{{ 'global.validate' | translate }}"
        type="save"
        [primary]="true"
        (click)="submit()"
        *hasPermission="['CUSTOMIZATION_UPDATE']"
      >
      </ui-button>
    </div>
    <div class="form-content">
      <form class="customization-form" [formGroup]="form" novalidate>
        <ui-container>
          <div class="bottom-bordered">
            <div class="paragraph-content">
              <div class="paragraph-title">{{ "customization.sub-title" | translate }}</div>
            </div>
            <div class="paragraph-form">
              <ui-textarea
                label="{{ 'customization.invoice-footer' | translate }}"
                formControlName="invoiceFooter"
                infoLabel="{{ 'customization.info-label' | translate }}"
                customClass="no-margin"
                maxLength="2048"
              >
              </ui-textarea>
            </div>
          </div>
        </ui-container>
      </form>
    </div>
  </div>
</ng-container>
