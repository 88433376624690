import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { Nomenclature } from "center-services";
import { MenuAction } from "fugu-components";

@Component({
  selector: "app-retail-item-nomenclatures-list",
  templateUrl: "./retail-item-nomenclatures-list.component.html",
  styleUrls: ["./retail-item-nomenclatures-list.component.scss"],
})
export class RetailItemNomenclaturesListComponent implements OnInit {
  @Input() nomenclaturesList: Nomenclature[];

  @Output() changeNomenclature: EventEmitter<{ nomenclature: Nomenclature; toForm: boolean }> = new EventEmitter();

  @ViewChild("table") table: DatatableComponent;

  public sorts: any[] = [
    {
      prop: "archived",
      dir: "asc",
    },
    {
      prop: "createdAt",
      dir: "desc",
    },
  ];
  public menuAction: MenuAction[] = [];
  public popupVisible: boolean = false;
  public changingNomenclature: Nomenclature = new Nomenclature();

  private readonly UPDATE_DETAILS_ACTION_ID: number = 0;

  constructor(protected translateService: TranslateService) {}

  ngOnInit(): void {
    this.fillMenuAction();
  }

  public openPopup(nomenclatureId: number = null): void {
    this.changingNomenclature = this.nomenclaturesList.find(nom => nom.id === nomenclatureId) ?? new Nomenclature();
    this.popupVisible = true;
  }

  public closePopup(): void {
    this.popupVisible = false;
  }

  public validatePopup(): void {
    if (!this.changingNomenclature.id) {
      this.changingNomenclature.archived = true;
      this.nomenclaturesList.push(this.changingNomenclature);
    }

    this.changeNomenclature.emit({ nomenclature: this.changingNomenclature, toForm: !this.changingNomenclature.id });
    this.closePopup();
  }

  public updateNomenclature(event: { type: any; row: Nomenclature }): void {
    if (event.type === "click") {
      this.changeNomenclature.emit({ nomenclature: event.row, toForm: true });
    }
  }

  public archiving($event: boolean, id: number): void {
    this.nomenclaturesList.forEach(nom => {
      nom.archived = nom.id !== id || (nom.id === id && !$event);
    });

    this.nomenclaturesList = [...this.nomenclaturesList];
  }

  public manageActions(actionId: number, nomenclatureId: number): void {
    switch (actionId) {
      case this.UPDATE_DETAILS_ACTION_ID:
        this.openPopup(nomenclatureId);
        break;

      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  public changeSortSettings(prop: string, direction: any): void {
    if (prop === "active") {
      this.sorts = [
        {
          prop: "archived",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "archived",
          dir: "asc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.table.sorts = this.sorts;
  }

  private fillMenuAction(): void {
    this.menuAction.push(
      new MenuAction(
        this.UPDATE_DETAILS_ACTION_ID,
        this.translateService.instant("item.nomenclatures.nomenclatures-list.datatable.actions.update-details"),
        faPen
      )
    );
  }
}
