import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { IconDefinition, faTrashAlt, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { StockEntryLabel } from "center-services";

@Component({
  selector: "app-stock-entry-label-delete-popup",
  templateUrl: "./stock-entry-label-delete-popup.component.html",
  styleUrls: ["./stock-entry-label-delete-popup.component.scss"],
})
export class StockEntryLabelDeletePopupComponent implements OnChanges {
  @Input() stockEntryLabelList: StockEntryLabel[];
  @Input() afterPrint: boolean = false;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public shouldClose: boolean = false;
  public popupTitle: string;
  public bodyMessage: string;
  public infoMessage: string;
  public validateButton: string;
  public refuseButton: string;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public faTrash: IconDefinition = faTrashAlt;

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {
    this.popupTitle = this.translateService.instant("stock-entry-label-list.delete-popup.title");
    this.bodyMessage = this.translateService.instant("stock-entry-label-list.delete-popup.body-message");
    this.validateButton = this.translateService.instant("stock-entry-label-list.delete-popup.validate");
    this.refuseButton = this.translateService.instant("stock-entry-label-list.delete-popup.refuse");

    if (this.afterPrint) {
      this.popupTitle = this.translateService.instant("stock-entry-label-list.delete-popup.title-print");
      this.bodyMessage = this.translateService.instant("stock-entry-label-list.delete-popup.body-message-print");
      this.infoMessage = this.translateService.instant("stock-entry-label-list.delete-popup.info-message-print");
      this.validateButton = this.translateService.instant("stock-entry-label-list.delete-popup.validate-print");
      this.refuseButton = this.translateService.instant("stock-entry-label-list.delete-popup.refuse-print");
    }
  }

  closePopup(): void {
    this.close.emit();
  }

  submitDeletePopup(deleteLabels: boolean): void {
    this.validate.emit(deleteLabels);
  }
}
