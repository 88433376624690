<ng-container>
  <form class="item-general" [formGroup]="itemForm" novalidate>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'item.general-datas.item-name' | translate }}"
        formControlName="name"
        [status]="itemForm.get('name') | formStatus"
        [errorMessage]="itemForm.get('name') | formErrorMessage | translate"
        mandatory="true"
      >
      </ui-input-text>
      <ui-input-text
        type="text"
        label="{{ 'item.general-datas.reference' | translate }}"
        formControlName="reference"
        [status]="itemForm.get('reference') | formStatus"
        [errorMessage]="itemForm.get('reference') | formErrorMessage | translate"
        mandatory="true"
        infoLabel="{{ 'global.unique' | translate }}"
      >
      </ui-input-text>
      <div class="category">
        <div class="category-list" *ngIf="!this.isUpdated">
          <ui-list
            label="{{ 'item.general-datas.category' | translate }}"
            [options]="this.categoryOptions"
            formControlName="categoryId"
            [status]="itemForm.get('categoryId') | formStatus"
            [errorMessage]="itemForm.get('categoryId') | formErrorMessage | translate"
            mandatory="true"
          ></ui-list>
        </div>
        <div class="category-info-field" *ngIf="this.isUpdated">
          <ui-info-field [label]="'item.general-datas.category' | translate" [value]="this.editedItem?.categoryName">
          </ui-info-field>
        </div>
      </div>
      <ui-textarea
        label="{{ 'item.general-datas.description' | translate }}"
        formControlName="description"
        [status]="itemForm.get('description') | formStatus"
        [errorMessage]="itemForm.get('description') | formErrorMessage | translate"
        mandatory="true"
      >
      </ui-textarea>
      <div class="custom">
        <div class="weight-and-unit" *ngIf="itemForm.get('weight')">
          <div class="weight">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              label="{{ 'item.general-datas.weight' | translate }}"
              formControlName="weight"
              [status]="itemForm.get('weight') | formStatus"
              [errorMessage]="
                itemForm.get('weight')
                  | formErrorMessage: { customPrefix: 'item.general-datas.errors.', useCustomPrefixFor: ['low'] }
                  | translate: { digit: HIGH_INTEGER }
              "
            >
            </ui-input-text>
          </div>
          <div class="weight-unit">
            <ui-list
              [options]="this.weightUnitOptions"
              [search]="true"
              formControlName="weightUnitId"
              [status]="itemForm.get('weightUnitId') | formStatus"
              [errorMessage]="itemForm.get('weightUnitId') | formErrorMessage | translate"
            ></ui-list>
          </div>
        </div>
        <div class="tare-and-unit" *ngIf="itemForm.get('tare')">
          <div class="tare">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              label="{{ 'item.general-datas.tare' | translate }}"
              formControlName="tare"
              [status]="itemForm.get('tare') | formStatus"
              [errorMessage]="
                itemForm.get('tare')
                  | formErrorMessage: { customPrefix: 'item.general-datas.errors.', useCustomPrefixFor: ['min'] }
                  | translate: { digit: HIGH_INTEGER }
              "
            >
            </ui-input-text>
          </div>
          <div class="tare-unit">
            <ui-list
              [options]="this.weightUnitOptions"
              [search]="true"
              formControlName="tareUnitId"
              [status]="itemForm.get('tareUnitId') | formStatus"
              [errorMessage]="itemForm.get('tareUnitId') | formErrorMessage | translate"
            ></ui-list>
          </div>
        </div>
      </div>
      <div class="{{ this.getNextReferenceParentClass() }}" *ngIf="this.editedItem.type === 'STANDARD'">
        <div class="{{ this.getNextReferenceClass() }}">
          <ui-list
            label="{{ 'item.general-datas.next-reference' | translate }}"
            [options]="this.itemOptions"
            [search]="true"
            formControlName="nextItemId"
            [status]="itemForm.get('nextItemId') | formStatus"
            [errorMessage]="
              itemForm.get('nextItemId')
                | formErrorMessage
                  : { customPrefix: 'item.general-datas.errors.', useCustomPrefixFor: ['duplicateReference'] }
                | translate
            "
          ></ui-list>
        </div>
        <div class="last-reference" *ngIf="this.editedItem && this.editedItem.lastReference">
          <div class="last-reference-label">{{ "item.general-datas.last-reference" | translate }}</div>
          <div class="last-reference-value">
            <ui-link
              href="retail-item/update/{{ this.editedItem.lastReferenceId }}"
              text="{{ this.editedItem.lastReference }}"
              target="_blank"
            ></ui-link>
          </div>
        </div>
      </div>
      <ui-list
        *ngIf="this.editedItem.type === 'STANDARD'"
        label="{{ 'item.general-datas.previous-reference' | translate }}"
        [options]="this.itemOptions"
        [search]="true"
        formControlName="previousItemId"
        [status]="itemForm.get('previousItemId') | formStatus"
        [errorMessage]="
          itemForm.get('previousItemId')
            | formErrorMessage
              : { customPrefix: 'item.general-datas.errors.', useCustomPrefixFor: ['duplicateReference'] }
            | translate
        "
      ></ui-list>
    </div>

    <div class="grid-item">
      <ui-list
        *ngIf="itemForm.get('brandId')"
        label="{{ 'item.general-datas.brand' | translate }}"
        [options]="this.brandOptions"
        [search]="true"
        formControlName="brandId"
        [status]="itemForm.get('brandId') | formStatus"
        [errorMessage]="itemForm.get('brandId') | formErrorMessage | translate"
      ></ui-list>
      <ui-list
        *ngIf="itemForm.get('mainUnitId')"
        label="{{ 'item.stock.main-unit-label' | translate }}"
        [options]="this.uomOptions"
        [search]="true"
        formControlName="mainUnitId"
        mandatory="true"
        [status]="itemForm.get('mainUnitId') | formStatus"
        [errorMessage]="itemForm.get('mainUnitId') | formErrorMessage | translate"
      >
      </ui-list>
      <ui-list
        *ngIf="itemForm.get('brandCollectionId')"
        label="{{ 'item.general-datas.brand-collection' | translate }}"
        [options]="this.brandCollectionOptions"
        [search]="true"
        formControlName="brandCollectionId"
        [status]="itemForm.get('brandCollectionId') | formStatus"
        [errorMessage]="itemForm.get('brandCollectionId') | formErrorMessage | translate"
      ></ui-list>
      <ui-input-text
        *ngIf="itemForm.get('ean')"
        type="text"
        label="{{ 'item.general-datas.ean' | translate }}"
        formControlName="ean"
      >
      </ui-input-text>

      <div class="multi-col" *ngIf="itemForm.get('caseReference') && itemForm.get('bagReference')">
        <div class="case-reference" *ngIf="itemForm.get('caseReference')">
          <ui-input-text
            type="text"
            label="{{ 'item.general-datas.case-reference' | translate }}"
            formControlName="caseReference"
            infoLabel="{{ 'item.general-datas.case-tooltip' | translate }}"
          >
          </ui-input-text>
        </div>
        <div class="bag-reference" *ngIf="itemForm.get('bagReference')">
          <ui-input-text
            type="text"
            label="{{ 'item.general-datas.bag-reference' | translate }}"
            formControlName="bagReference"
            infoLabel="{{ 'item.general-datas.bag-tooltip' | translate }}"
          >
          </ui-input-text>
        </div>
      </div>
      <div class="{{ this.getNextReferenceParentClass() }}" *ngIf="this.editedItem.type === 'SERVICE'">
        <div class="{{ this.getNextReferenceClass() }}">
          <ui-list
            label="{{ 'item.general-datas.next-reference' | translate }}"
            [options]="this.itemOptions"
            [search]="true"
            formControlName="nextItemId"
            [status]="itemForm.get('nextItemId') | formStatus"
            [errorMessage]="
              itemForm.get('nextItemId')
                | formErrorMessage
                  : { customPrefix: 'item.general-datas.errors.', useCustomPrefixFor: ['duplicateReference'] }
                | translate
            "
          ></ui-list>
        </div>
        <div class="last-reference" *ngIf="this.editedItem && this.editedItem.lastReference">
          <div class="last-reference-label">{{ "item.general-datas.last-reference" | translate }}</div>
          <div class="last-reference-value">
            <ui-link
              href="retail-item/update/{{ this.editedItem.lastReferenceId }}"
              text="{{ this.editedItem.lastReference }}"
              target="_blank"
            ></ui-link>
          </div>
        </div>
      </div>
      <ui-list
        *ngIf="this.editedItem.type === 'SERVICE'"
        label="{{ 'item.general-datas.previous-reference' | translate }}"
        [options]="this.itemOptions"
        [search]="true"
        formControlName="previousItemId"
        [status]="itemForm.get('previousItemId') | formStatus"
        [errorMessage]="
          itemForm.get('previousItemId')
            | formErrorMessage
              : { customPrefix: 'item.general-datas.errors.', useCustomPrefixFor: ['duplicateReference'] }
            | translate
        "
      ></ui-list>
      <ui-list
        *ngIf="itemForm.get('sizeCategory')"
        label="{{ 'item.general-datas.size-category' | translate }}"
        [options]="this.sizeCategoryOptions"
        [infoLabel]="sizeCategoryNomenclatureMessage"
        [disabled]="sizeCategoryNomenclatureMessage"
        formControlName="sizeCategory"
      ></ui-list>
      <div class="size-values-table" *ngIf="itemForm.get('sizeCategory')">
        <ngx-datatable
          #table
          [rows]="rows"
          [trackByProp]="'id'"
          class="material"
          columnMode="flex"
          headerHeight="auto"
          footerHeight="50"
          rowHeight="40"
          [limit]="7"
          [rowClass]="getRowClass"
          [cssClasses]="{
            sortAscending: 'fas fa-chevron-up',
            sortDescending: 'fas fa-chevron-down'
          }"
          [messages]="{ emptyMessage: 'global.no-data' | translate }"
          [sorts]="sorts"
          (sort)="changeSortSettings($event.column.prop, $event.newValue)"
        >
          <ngx-datatable-column
            name="{{ 'item.general-datas.size-category-table.values' | translate }}"
            prop="value"
            [flexGrow]="3"
            [resizeable]="false"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'item.general-datas.size-category-table.by-default' | translate }}"
            prop="byDefault"
            headerClass="center-align"
            cellClass="center-align top-align"
            [flexGrow]="1"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row">
              <ui-checkbox
                customClass="no-margin"
                [formControl]="tableControl.get('sizeValue' + row.id).get('byDefault')"
                customClass="by-default-checkbox"
              >
              </ui-checkbox>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'item.general-datas.size-category-table.active' | translate }}"
            prop="activated"
            headerClass="center-align"
            cellClass="center-align"
            [flexGrow]="1"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
              <ui-switch
                customClass="no-margin activated-switch"
                [formControl]="tableControl.get('sizeValue' + id).get('activated')"
              >
              </ui-switch>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="rowCount > 0">
                {{
                  rowCount
                    | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                    | translate: { rowCount: rowCount }
                }}
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                [pagerRightArrowIcon]="'fas fa-chevron-right'"
                [pagerNextIcon]="'fas fa-chevron-double-right'"
                [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="table.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>

    <div class="grid-item">
      <div class="resizing" *ngIf="this.itemForm.controls.sizeCategory?.value">
        <div class="resizing-label">{{ "item.general-datas.resizing" | translate }}</div>
        <div class="multi-col adjust">
          <div class="resizing-icon">
            <fa-icon [icon]="faMinus"></fa-icon>
          </div>
          <div class="resizing-min">
            <ui-input-text
              type="text"
              maskPattern="0*.0*"
              formControlName="resizingMin"
              [status]="itemForm.get('resizingMin') | formStatus"
              [errorMessage]="
                itemForm.get('resizingMin')
                  | formErrorMessage: { customPrefix: 'item.general-datas.errors.', useCustomPrefixFor: ['min'] }
                  | translate
              "
            >
            </ui-input-text>
          </div>
          <div class="resizing-icon">
            <fa-icon [icon]="faPlus"></fa-icon>
          </div>
          <div class="resizing-max">
            <ui-input-text
              type="text"
              maskPattern="0*.0*"
              formControlName="resizingMax"
              [status]="itemForm.get('resizingMax') | formStatus"
              [errorMessage]="
                itemForm.get('resizingMax')
                  | formErrorMessage: { customPrefix: 'item.general-datas.errors.', useCustomPrefixFor: ['min'] }
                  | translate
              "
            >
            </ui-input-text>
          </div>
        </div>
      </div>
      <div class="multi-col" *ngIf="this.itemForm.get('vatRate')">
        <div class="vat-rate-switch">
          <ui-switch
            label="{{ 'item.general-datas.vat-rate-switch' | translate }}"
            class="vat-rate-switch"
            customClass="no-margin"
            formControlName="vatRateActivated"
            (change)="conditionalSwitchOnChanges('vatRateActivated', 'vatRate')"
          >
          </ui-switch>
        </div>
        <div class="input-amount">
          <ui-input-text
            *ngIf="this.itemForm.get('vatRateActivated').value"
            label="{{ 'item.general-datas.vat-rate' | translate }}"
            mandatory="true"
            class="tva-rate-input"
            type="text"
            [maskPattern]="decimalDigit"
            maskPattern="separator.2"
            formControlName="vatRate"
            [status]="itemForm.get('vatRate') | formStatus"
            [errorMessage]="itemForm.get('vatRate') | formErrorMessage | translate"
          >
          </ui-input-text>
        </div>
      </div>

      <div class="multi-col" *ngIf="this.itemForm.get('engravingLength')">
        <div class="engraving-switch">
          <ui-switch
            label="{{ 'item.general-datas.engraving' | translate }}"
            class="engraving-switch"
            customClass="no-margin"
            formControlName="engravingLengthActivated"
            (change)="conditionalSwitchOnChanges('engravingLengthActivated', 'engravingLength')"
          >
          </ui-switch>
        </div>
        <div class="input-amount">
          <ui-input-text
            *ngIf="this.itemForm.get('engravingLengthActivated').value"
            label="{{ 'item.general-datas.digit-number' | translate }}"
            mandatory="true"
            class="engraving-input"
            type="text"
            maskPattern="00000000"
            formControlName="engravingLength"
            [status]="itemForm.get('engravingLength') | formStatus"
            [errorMessage]="itemForm.get('engravingLength') | formErrorMessage | translate"
          >
          </ui-input-text>
        </div>
      </div>

      <div class="multi-col" *ngIf="this.itemForm.get('ecoContribution')">
        <div class="eco-contribution-switch">
          <ui-switch
            label="{{ 'item.general-datas.eco-contribution' | translate }}"
            class="eco-switch"
            customClass="no-margin"
            formControlName="ecoContributionActivated"
            (change)="conditionalSwitchOnChanges('ecoContributionActivated', 'ecoContribution')"
          >
          </ui-switch>
        </div>
        <div class="input-amount">
          <ui-input-text
            *ngIf="this.itemForm.controls.ecoContributionActivated.value"
            label="{{ 'item.general-datas.amount' | translate: { unit: this.getCurrencySuffix() } }}"
            mandatory="true"
            class="eco-input"
            type="text"
            [maskPattern]="decimalDigit"
            formControlName="ecoContribution"
            maskPattern="separator.2"
            [suffix]="' ' + this.getCurrencySuffix()"
            [thousandSeparator]="' '"
            [status]="itemForm.get('ecoContribution') | formStatus"
            [errorMessage]="itemForm.get('ecoContribution') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
          >
          </ui-input-text>
        </div>
      </div>

      <div class="e-commerce-switch" *ngIf="this.itemForm.get('ecommerce')">
        <ui-switch
          label="{{ 'item.general-datas.e-commerce' | translate }}"
          class="e-commerce-switch"
          customClass="no-margin"
          mandatory="true"
          formControlName="ecommerce"
          [status]="itemForm.get('ecommerce') | formStatus"
          [errorMessage]="itemForm.get('ecommerce') | formErrorMessage | translate"
        >
        </ui-switch>
      </div>

      <div *ngIf="itemForm.get('canBuy') || itemForm.get('canSold')" class="{{ this.getTypeLabelClass() }}">
        {{ "item.general-datas.type" | translate }}<span>*</span>
      </div>
      <div class="type" *ngIf="itemForm.get('canBuy') || itemForm.get('canSold')">
        <div class="can-buy">
          <ui-checkbox type="checkbox" formControlName="canBuy"></ui-checkbox>
          <div class="label-text">{{ "item.general-datas.buy" | translate }}</div>
        </div>
        <div class="can-sold">
          <ui-checkbox type="checkbox" formControlName="canSold"></ui-checkbox>
          <div class="label-text">{{ "item.general-datas.sold" | translate }}</div>
        </div>
      </div>
      <div class="type-error">
        <ui-error-message
          message="{{ this.typeErrorMessage | translate }}"
          customClass="absolute"
          *ngIf="this.showTypeErrorMessage()"
        ></ui-error-message>
      </div>

      <ui-radio-button
        label="{{ 'item.general-datas.status' | translate }}"
        [options]="this.statusOptions"
        name="status"
        direction="horizontal"
        formControlName="status"
        mandatory="true"
      ></ui-radio-button>
      <div class="logo-container">
        <ui-input-file
          id="'file'"
          accept=".png, .jpeg, .jpg, .JPG, .JPEG, .PNG"
          smallSize="true"
          label="{{ 'item.general-datas.logo.label' | translate }}"
          buttonText="{{ 'global.input-file.btn' | translate }}"
          descriptionText="{{ 'global.input-file.drag-drop' | translate }}"
          formControlName="logoFile"
        ></ui-input-file>
      </div>
    </div>
  </form>
</ng-container>
