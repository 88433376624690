import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorUtil, ComponentDirty, PopupSaveUtil } from "generic-pages";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { SessionPagination, SubscriptionService } from "fugu-common";
import { OrderBrandsComponent } from "../order-brands/order-brands.component";
import {
  Store,
  JobType,
  StoreService,
  DocumentService,
  CaraUserService,
  FormErrorService,
  CompanyConfig,
  StoreBrand,
  PricingGroup,
  Address,
  BaseEntity,
  StoreStatusType,
} from "center-services";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-store-form",
  templateUrl: "./store-form.component.html",
  styleUrls: ["./store-form.component.scss"],
  providers: [SubscriptionService],
})
export class StoreFormComponent implements OnInit, ComponentDirty {
  @ViewChild("generalDatas") generalDatas: any;

  @ViewChild("finance") finance: any;
  @ViewChild("tabHandler") tabHandler: any;
  @ViewChild("characteristics") characteristics: any;
  @ViewChild("orderBrands") orderBrands: any;

  public title: string;
  public subTitle: string;
  public unsavedFile: any;
  public editedStore: Store;
  public updatedStore: Store;
  public unsavedStore: Store;
  public faChevronLeft: IconDefinition = faChevronLeft;

  public shouldClose: boolean = false;

  public hasError: boolean = false;
  public jobTypeExternalContact: JobType = JobType.EXTERNAL_CONTACT;

  // manage document
  public selectedFile: any;

  public isGod: boolean = false;
  public popupVisible: boolean = false;

  // eslint-disable-next-line no-magic-numbers
  protected readonly PAYLOAD_TOO_LARGE: number = 413;

  constructor(
    private storeService: StoreService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private documentService: DocumentService,
    private userService: CaraUserService,
    private formErrorService: FormErrorService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.isGod = this.userService.connectedUser.value.rights.includes("GOD");
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.isGod = user.rights.includes("GOD");
      })
    );

    const storeId = this.route.snapshot.params.id;
    this.title = storeId ? "store.title.update" : "store.title.new";
    if (storeId) {
      this.subscriptionService.subs.push(
        this.storeService.get(storeId).subscribe(
          (store: Store) => {
            this.editedStore = store;
            this.updatedStore = new Store(this.editedStore);
            this.subTitle = this.getSubtitle(store);
          },
          () => {
            this.updatedStore = null;
            this.router.navigateByUrl("/stores-list");
          }
        )
      );
    } else {
      this.updatedStore = new Store({
        name: null,
        reference: null,
        coordinates: null,
        phone: null,
        email: null,
        siret: null,
        comment: null,
        companyConfig: new CompanyConfig({ id: null }),
        storeBrand: new StoreBrand({ id: null }),
        pricingGroup: new PricingGroup({ id: null }),
        archived: false,
        photoId: null,
        renovations: [],
        address: new Address({ byDefault: true }),
        tagIds: [],
      });

      this.editedStore = new Store(this.updatedStore);
    }
  }

  isDirty(): boolean {
    if (!this.updatedStore) {
      return false;
    }

    let initialFile = null;
    let newFile = null;

    if (this.characteristics) {
      initialFile = this.characteristics.previousFile;
      newFile = this.characteristics.selectedFile;
      this.characteristics.applyModifications();
    }
    if (this.generalDatas) {
      this.generalDatas.applyModifications();
    }
    if (this.finance) {
      this.finance.applyModifications();
    }

    if (
      (this.unsavedStore && !this.unsavedStore.equals(this.updatedStore)) ||
      (this.unsavedFile && newFile && this.unsavedFile !== newFile)
    ) {
      this.shouldClose = false;
    }

    if (
      ((this.editedStore && !this.editedStore.equals(this.updatedStore)) || initialFile !== newFile) &&
      !this.shouldClose
    ) {
      this.recordDatas();
      return true;
    } else if (!this.editedStore && !this.shouldClose) {
      this.recordDatas();
      return true;
    }

    this.unsavedStore = null;
    this.shouldClose = false;
    this.unsavedFile = null;
    return false;
  }

  recordDatas(): void {
    if (this.characteristics) {
      this.unsavedFile = this.characteristics.selectedFile;
    }
    this.unsavedStore = new Store(this.updatedStore);
    this.shouldClose = true;
  }

  getComponentError(entities: BaseEntity[], componentName: string): void {
    const defaultElements = entities.filter((entity: any) => entity.byDefault);
    let componentError = null;

    if (!defaultElements.length) {
      componentError = "no-default";
    } else if (defaultElements.length > 1) {
      componentError = "multiple-default";
    } else if (defaultElements[0].archived) {
      componentError = "archived-default";
    } else {
      return;
    }
    this.hasError = true;
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(`store.errors.${componentName}.${componentError}`);
    this.messageService.error(content, { title });
  }

  onTabClick(tab: any): void {
    if (this.orderBrands) {
      this.orderBrands.savePaginationToSession();
    }
    if (this.updatedStore) {
      this.submitStore(tab);
    }
  }

  catchEvent(source: string, object: any): void {
    PopupSaveUtil.manageEvent(this.editedStore, this.updatedStore, object, source, this.saveStoreElement.bind(this));
  }

  submitStore(nextTab: any = null): void {
    // handle finance tab
    if (this.finance) {
      if (!this.finance.updateStore()) {
        this.formErrorService.handleFormError();
        return;
      }
    }

    this.hasError = false;
    let fileUpdated = false;

    // handle characteristics tab
    if (this.characteristics && !this.characteristics.updateStore()) {
      this.formErrorService.handleFormError();
      return;
    }

    if (this.characteristics) {
      fileUpdated = this.characteristics.hasFileChanged();
    }

    // handle generalDatas tab
    if (this.generalDatas) {
      if (!this.generalDatas.updateStore()) {
        this.formErrorService.handleFormError();
        return;
      }
      if (this.openWarningPopupIfInactiveStore()) {
        return;
      }
    }

    if (this.updatedStore.banks && this.updatedStore.banks.length > 0) {
      this.getComponentError(this.updatedStore.banks, "banks");
    }
    if (this.updatedStore.deliveryAddresses && this.updatedStore.deliveryAddresses.length > 0) {
      this.getComponentError(this.updatedStore.deliveryAddresses, "delivery-addresses");
    }

    if (this.hasError) {
      return;
    }

    if (this.editedStore) {
      // if nothing has changed, no need to call the API to save the store
      if (this.editedStore.equals(this.updatedStore) && !fileUpdated) {
        if (nextTab && this.tabHandler) {
          this.tabHandler.changeTab(nextTab);
        }
        return;
      }
    }
    this.callApi(nextTab);
  }

  callApi(nextTab: any = null): void {
    if (!this.updatedStore) {
      return;
    }

    // when form is valid, create or update store
    const action = this.editedStore && this.editedStore.id ? "update" : "create";
    this.saveStore(action, nextTab);
  }

  saveStore(action: string, nextTab: any): void {
    this.subscriptionService.subs.push(
      this.storeService[action].call(this.storeService, this.updatedStore).subscribe(
        responseStore => {
          this.savePhoto(nextTab, responseStore);
        },
        error => {
          this.hasError = true;
          this.handleApiError(error);
        }
      )
    );
  }

  saveStoreElement(action: any, propName: string): void {
    return this.storeService[action]
      .call(this.storeService, this.editedStore)
      .toPromise()
      .then(responseStore => {
        const title = this.translateService.instant("message.title.save-success");
        const content = this.translateService.instant("message.content.save-success");
        this.messageService.success(content, { title });
        return responseStore[propName];
      })
      .catch(error => {
        this.hasError = true;
        this.handleApiError(error);
      });
  }

  savePhoto(nextTab: any, responseStore: Store): void {
    if (!this.characteristics || !this.characteristics.hasFileChanged()) {
      this.finishSave(nextTab, responseStore);
      return;
    }

    const formData = new FormData();
    formData.set("file", this.selectedFile);
    this.subscriptionService.subs.push(
      this.documentService.uploadFile(formData, responseStore.photoId).subscribe(
        () => {
          this.finishSave(nextTab, responseStore);
        },
        (error: HttpErrorResponse) => {
          this.hasError = true;
          const res =
            error.status === this.PAYLOAD_TOO_LARGE
              ? this.translateService.instant("global.form.error.payload-too-large")
              : this.translateService.instant("store.errors.upload-file");
          const title = this.translateService.instant("message.title.api-errors");
          this.messageService.warn(res, { title });

          this.finishSave(nextTab, responseStore);
        }
      )
    );
  }

  finishSave(nextTab: any, responseStore: Store): void {
    this.editedStore = responseStore;
    this.updatedStore = new Store(this.editedStore);
    if (this.route.snapshot.params.id) {
      this.subTitle = this.getSubtitle(responseStore);
    }

    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
    if (nextTab) {
      this.tabHandler.changeTab(nextTab);
    }
  }

  backToStoreList(): void {
    SessionPagination.clear(OrderBrandsComponent.LIST_ID);

    this.router.navigateByUrl("/stores-list");
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "store.general-datas.store-name",
      reference: "store.general-datas.reference",
      siret: "store.general-datas.siret",
    };

    const title = this.translateService.instant("message.title.form-errors");

    if (
      error.error.exception &&
      error.error.exception.name === "InvalidDataException" &&
      error.error.message === "Invalid recipient"
    ) {
      const content = this.translateService.instant("revenue-disclosure-param.errors.set-recipient");
      this.messageService.error(content, { title });
    } else {
      const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
      const content = this.translateService.instant(result.message, result.params);
      this.messageService.error(content, { title });
    }
  }

  getSubtitle(store: Store): string {
    const subtitleList = [];
    if (store.reference) {
      subtitleList.push(store.reference);
    }
    subtitleList.push(store.name);

    return subtitleList.join(" - ");
  }

  openWarningPopupIfInactiveStore(): boolean {
    if (
      !this.popupVisible &&
      this.updatedStore.status === StoreStatusType.INACTIVE &&
      this.editedStore.status !== StoreStatusType.INACTIVE
    ) {
      this.popupVisible = true;
      return true;
    }
    return false;
  }

  validateWarningPopup(): void {
    this.submitStore();
    this.closeWarningPopup();
  }

  closeWarningPopup(): void {
    this.popupVisible = false;
  }
}
