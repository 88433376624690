<ng-container>
  <div class="commercial-modality">
    <div class="bottom-bordered">
      <gen-commercial-general-modality
        #general
        *ngIf="commercialModality"
        [(commercialModality)]="commercialModality"
        (commercialModalityChange)="commercialModalityUpdated()"
        [currency]="currency"
      >
      </gen-commercial-general-modality>
    </div>
    <div class="bottom-bordered">
      <gen-commercial-payment-period
        #paymentPeriod
        *ngIf="commercialModality"
        [(commercialModality)]="commercialModality"
        (commercialModalityChange)="commercialModalityUpdated()"
      >
      </gen-commercial-payment-period>
    </div>
    <div class="bottom-bordered" *ngIf="displayImportation">
      <gen-commercial-importation
        #import
        *ngIf="commercialModality"
        [(commercialModality)]="commercialModality"
        (commercialModalityChange)="commercialModalityUpdated()"
        [personsList]="contacts"
        [address]="address"
        [currencies]="currencies"
        [currency]="currency"
      >
      </gen-commercial-importation>
    </div>
    <div class="grid-container">
      <div class="grid-item">
        <gen-commercial-shipping-fee
          *ngIf="commercialModality"
          [(shippingFeeRanges)]="commercialModality.shippingFeeRanges"
          [(shippingFeeType)]="commercialModality.shippingFeeType"
          (shippingFeeTypeChange)="commercialModalityUpdated()"
          [currency]="currency"
          [codeLanguage]="codeLanguage"
        >
        </gen-commercial-shipping-fee>
      </div>
      <div class="grid-item">
        <gen-commercial-happy-new-year
          *ngIf="commercialModality"
          [(happyNewYearDiscounts)]="commercialModality.happyNewYearDiscounts"
          [currency]="currency"
          [codeLanguage]="codeLanguage"
        >
        </gen-commercial-happy-new-year>
      </div>
    </div>
  </div>
</ng-container>
