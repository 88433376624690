<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'classifications-list.title' | translate }}"></ui-title>
    </div>
    <div class="button">
      <ui-button
        text="{{ 'global.new' | translate }}"
        type="add"
        [primary]="true"
        (click)="onNewEntityClick()"
        *hasPermission="['CLASSIFICATION_CREATE']"
      >
      </ui-button>
    </div>
    <div class="classifications-table">
      <ui-container>
        <div [formGroup]="tableControl">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            class="material"
            columnMode="flex"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [limit]="15"
            (activate)="onTableActivate($event)"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'classifications-list.datatable.columns.name' | translate }}"
              prop="name"
              cellClass="name"
              [flexGrow]="1"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'classifications-list.datatable.columns.values' | translate }}"
              prop="values"
              cellClass="ellipsis-counter"
              [flexGrow]="1"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-values="row.values">
                <p class="ellipsis">{{ values.join(", ") }}</p>
                <div *ngIf="values.length >= 2">
                  <div #valuesCounter>
                    <ui-counter [number]="values.length"></ui-counter>
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="valuesCounter">
                    <span style="font-weight: normal !important; white-space: break-spaces">{{
                      values.join(", ")
                    }}</span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'classifications-list.datatable.columns.item-categories' | translate }}"
              prop="categories"
              cellClass="ellipsis-counter"
              [flexGrow]="1"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-categories="row.categories">
                <p class="ellipsis">{{ categories.join(", ") }}</p>
                <div *ngIf="categories.length >= 2">
                  <div #categoryCounter>
                    <ui-counter [number]="categories.length"></ui-counter>
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="categoryCounter">
                    <span style="font-weight: normal !important; white-space: break-spaces">{{
                      categories.join(", ")
                    }}</span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'classifications-list.datatable.columns.activated' | translate }}"
              prop="activated"
              headerClass="center-align"
              cellClass="center-align"
              [flexGrow]="1"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-switch
                    customClass="no-margin"
                    (change)="changeEntityActivation(id)"
                    [formControl]="tableControl.get('activated_' + id)"
                  >
                  </ui-switch>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>

    <form class="classification-form" [formGroup]="popupForm" novalidate (keydown.enter)="$event.preventDefault()">
      <div class="popup-container">
        <ui-popup title="{{ popupTitle | translate }}" *ngIf="popupVisible" (close)="canClosePopup()">
          <div content>
            <div class="grid-item">
              <ui-input-text
                type="text"
                label="{{ 'classifications-list.popup.name' | translate }}"
                formControlName="name"
                [status]="popupForm.get('name') | formStatus"
                [errorMessage]="popupForm.get('name') | formErrorMessage | translate"
                mandatory="true"
                infoLabel="{{ 'global.unique' | translate }}"
              >
              </ui-input-text>
            </div>
            <div class="grid-item item-category">
              <ui-list
                label="{{ 'classifications-list.datatable.columns.item-categories' | translate }}"
                selectLabel="{{ 'classifications-list.datatable.columns.item-categories' | translate }}"
                [options]="this.itemCategoryOptions"
                [multiple]="true"
                formControlName="categories"
                [status]="popupForm.get('categories') | formStatus"
                [errorMessage]="popupForm.get('categories') | formErrorMessage | translate"
              >
              </ui-list>
            </div>
            <div class="grid-item table-btn">
              <ui-button
                text="{{ 'classifications-list.popup.add-btn-text' | translate }}"
                type="add"
                (click)="addValue()"
              >
              </ui-button>
            </div>

            <div class="value-table">
              <ngx-datatable
                #valueTable
                [rows]="valueRows"
                [trackByProp]="'index'"
                class="material"
                columnMode="flex"
                headerHeight="auto"
                rowHeight="40"
                [rowClass]="getPopupRowClass"
                [messages]="{ emptyMessage: 'global.no-data' | translate }"
                [cssClasses]="{
                  sortAscending: 'fas fa-chevron-up',
                  sortDescending: 'fas fa-chevron-down'
                }"
                [messages]="{ emptyMessage: 'global.no-data' | translate }"
                [sorts]="popupSorts"
                (sort)="changePopupSortSettings($event.column.prop, $event.newValue)"
              >
                <ngx-datatable-column
                  name="{{ 'classifications-list.datatable.columns.values' | translate }}"
                  prop="value"
                  [flexGrow]="2"
                  [resizeable]="false"
                  cellClass="hide-overflow"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <ui-input-text
                      [ngClass]="{
                        'margin-bottom-modulable':
                          (popupForm.get('values.' + row.index + '.name') | formErrorMessage) === null
                      }"
                      customClass="datatable-row"
                      class="input-fields"
                      type="text"
                      [formControl]="valueForm.controls[row.index].get('name')"
                      [status]="popupForm.get('values.' + row.index + '.name') | formStatus"
                      [errorMessage]="popupForm.get('values.' + row.index + '.name') | formErrorMessage | translate"
                      sizeMode="small"
                    >
                    </ui-input-text>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'classifications-list.datatable.columns.activated' | translate }}"
                  prop="activated"
                  headerClass="center-align"
                  cellClass="center-align"
                  [flexGrow]="0.5"
                  [resizeable]="false"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <ui-switch
                      customClass="no-margin"
                      (change)="valueCheckboxOnChanges(row.index)"
                      [formControl]="valueForm.controls[row.index].get('activated')"
                    >
                    </ui-switch>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name=""
                  prop="compositionDump"
                  width="110"
                  headerClass="center-align"
                  cellClass="center-align"
                  [resizeable]="false"
                  [flexGrow]="0.5"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <ui-menu-actions
                      *ngIf="!row.id"
                      [menuActions]="menuActions"
                      (actionCalled)="manageActions(row)"
                    ></ui-menu-actions>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
          <div footer>
            <div class="align-buttons">
              <div class="button-popup-validate">
                <div class="button">
                  <ui-button
                    text="{{ 'global.validate' | translate }}"
                    type="save"
                    [primary]="true"
                    (click)="submitEntity()"
                  ></ui-button>
                </div>
              </div>
            </div>
          </div>
        </ui-popup>
      </div>
    </form>
  </div>
</ng-container>
