import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { IconDefinition, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  MetalAccount,
  Light,
  LightService,
  SupplierService,
  UserService,
  User,
  CustomerService,
  ContactType,
} from "center-services";
import { Filterer, FilterValue, PaginableComponent, SessionPagination, SubscriptionService } from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { Observable } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import hash from "string-hash";

type LightMetal = {
  id: number;
  name: string;
};

@Component({
  selector: "app-metal-account-tab",
  templateUrl: "./metal-account-tab.component.html",
  styleUrls: ["./metal-account-tab.component.scss"],
  providers: [SubscriptionService],
})
export class MetalAccountTabComponent extends FilteredTableListComponent implements OnInit, PaginableComponent {
  @ViewChild("generalTable") generalTable: any;
  @ViewChild("dataTable") dataTable: any;
  @Input() contactType: ContactType;

  public LIST_ID: string;
  public datatableTitle: string;
  public metals: LightMetal[] = [];
  public rows: any[] = [];
  public allRows: any[] = [];
  public generalRow: any;
  public metalAccountList: any[] = [];
  public menuActions: MenuAction[] = [];
  public metalAccounts: MetalAccount[];
  public canCreate: boolean = false;
  public locale: string;
  public sorts: any[] = [];
  public popupVisible: boolean = false;
  public contactId: number = null;
  public contactMetalAccountList: MetalAccount[] = [];
  public lightSupplierList: Light[] = [];
  public lightCustomerList: Light[] = [];
  public pageNumber: number = 0;
  public filterer: Filterer;
  public faPlus: IconDefinition = faPlus;
  private sessionPagination: SessionPagination;
  private readonly ADD_ACTION_ID: number = 0;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: UserService<User>,
    private lightService: LightService,
    private supplierService: SupplierService,
    private customerService: CustomerService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  public ngOnInit(): void {
    this.setListId();
    this.setDatatableTitle();
    this.canCreate = this.userService.canDo("METAL_ACCOUNT_MOVEMENT_CREATE");
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
      })
    );

    this.subscriptionService.subs.push(
      this.fetchContext().subscribe(() => {
        this.buildTable();
        this.addRows(this.metalAccountList);
        this.initFilters();
        this.addMenuActions();
        this.sessionPagination.loadFromSession(this.LIST_ID);
      })
    );
  }

  public getPageNumber(_listId: string): number {
    return this.pageNumber;
  }

  public getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  public getSorts(_listId: string): any[] {
    return this.sorts;
  }

  public setPageNumber(_listId: string, pageNumber: number): void {
    this.pageNumber = pageNumber;
  }

  public setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
    this.applyFilters();
  }

  public setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  public savePaginationToSession(): void {
    this.sessionPagination.saveToSession(this.LIST_ID);
  }

  public getRowClass: any = (): any => ({ "not-clickable": true });

  public getCellClass(row: any, metalName: string): string {
    if (!row[`${metalName}_activated`] && row.contactId) {
      return "deactivated";
    }
    if (row[metalName] < 0) {
      return "negative";
    }
    return "";
  }

  public viewContactMetalAccount(event: any): void {
    if (event.type === "click") {
      if (this.userService.canDo("METAL_ACCOUNT_READ") && this.userService.canDo("METAL_ACCOUNT_MOVEMENT_READ")) {
        this.savePaginationToSession();
        this.router.navigate(["metal-account-detail"], {
          queryParams: { contactId: event.row.contactId, contactType: this.contactType },
        });
      }
    }
  }

  public applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);

    this.filterer.filterValues.forEach((fv: FilterValue) => {
      const metalName = this.metals.find(metal => hash(metal.name) === fv.filterId)?.name;
      if (metalName && (fv.min || fv.max || fv.min === 0 || fv.max === 0)) {
        this.rows = this.rows.filter(row => row[`${metalName}_hasMovements`]);
      }
    });

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe()
    );
  }

  public manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case 0:
        this.contactId = row.contactId;
        this.contactMetalAccountList = this.getContactMetalAccounts(row.contactId);
        this.popupVisible = true;
        break;
      default:
        break;
    }
  }

  public onClosePopup(): void {
    this.popupVisible = false;
    this.contactId = null;
  }

  public onValidatePopup(): void {
    this.fetchMetalAccounts();

    this.subscriptionService.subs.push(
      this.fetchContext().subscribe(() => {
        this.buildTable();
        this.addRows(this.metalAccountList);
        this.initFilters();
        this.addMenuActions();
        this.applyFilters();
      })
    );

    this.onClosePopup();
  }

  private buildTable(): void {
    this.metals = [];
    this.metalAccountList = [];
    const globalBalance = {
      entity: this.translateService.instant("metal-account-tab.general-datatable.columns.entity-data"),
    };

    this.metalAccounts.forEach((account: MetalAccount) => {
      const metalName = account.metalName;
      // add columns and calculate global balances
      if (!this.metals.find(lm => lm.id === account.metalId)) {
        this.metals.push({ id: account.metalId, name: metalName });
        globalBalance[metalName] = account.balance ? account.balance : 0;
      } else {
        if (globalBalance[metalName]) {
          globalBalance[metalName] = account.balance
            ? globalBalance[metalName] + account.balance
            : globalBalance[metalName];
        } else {
          globalBalance[metalName] = account.balance ? account.balance : 0;
        }
      }
      // add rows (or add values to existing rows)
      const rowFound = this.metalAccountList.find(sma => sma.contactId === account.contactId);

      rowFound ? this.updateSMA(account, rowFound) : this.addSMA(account);
    });
    this.metals.sort((a, b) => a.name.localeCompare(b.name));
    this.generalRow = [globalBalance];
  }

  private addSMA(account: MetalAccount): void {
    const sma = {
      contactId: account.contactId,
      contactName: account.contactName,
    };
    sma[account.metalName] = account.balance;
    sma[`${account.metalName}_activated`] = account.activated;
    sma[`${account.metalName}_hasMovements`] = account.hasMovements;
    this.metalAccountList.push(sma);
  }

  private updateSMA(account: MetalAccount, sma: any): void {
    sma[account.metalName] = account.balance;
    sma[`${account.metalName}_activated`] = account.activated;
    sma[`${account.metalName}_hasMovements`] = account.hasMovements;
  }

  private addRows(list: any[]): void {
    this.rows = [...list];
    this.allRows = [...list];
  }

  private addMenuActions(): void {
    this.menuActions = [];

    this.menuActions.push(
      new MenuAction(this.ADD_ACTION_ID, this.translateService.instant("metal-account-tab.actions.add"), faPlus)
    );
  }

  private getContactMetalAccounts(contactId: number): MetalAccount[] {
    return this.metalAccounts.filter(metalAccount => metalAccount.contactId === contactId && metalAccount.activated);
  }

  private fetchSupplierMetalAccounts(): Observable<MetalAccount[]> {
    return this.supplierService.getAllMetalAccounts().pipe(
      tap(
        (metalAccounts: MetalAccount[]) => {
          this.metalAccounts = metalAccounts
            .filter((ma: MetalAccount) => ma.activated || ma.hasMovements)
            .filter((ma: MetalAccount) => this.lightSupplierList.find(supplier => supplier.id === ma.contactId));
        },
        () => {
          const res = this.translateService.instant("metal-account-tab.errors.get-metal-accounts");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private fetchCustomerMetalAccounts(): Observable<MetalAccount[]> {
    return this.customerService.getAllMetalAccounts().pipe(
      tap(
        (metalAccounts: MetalAccount[]) => {
          this.metalAccounts = metalAccounts
            .filter((ma: MetalAccount) => ma.activated || ma.hasMovements)
            .filter((ma: MetalAccount) => this.lightCustomerList.find(customer => customer.id === ma.contactId));
        },
        () => {
          const res = this.translateService.instant("metal-account-tab.errors.get-metal-accounts");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private fetchSuppliers(): Observable<MetalAccount[]> {
    return this.lightService.getSuppliers().pipe(
      tap({
        error: () => {
          const res = this.translateService.instant("metal-account-tab.errors.get-suppliers");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        },
      }),
      mergeMap(suppliers => {
        this.lightSupplierList = suppliers.filter((supplier: Light) => !supplier.archived);
        return this.fetchMetalAccounts();
      })
    );
  }

  private fetchCustomers(): Observable<MetalAccount[]> {
    return this.lightService.getCustomers().pipe(
      tap({
        error: () => {
          const res = this.translateService.instant("metal-account-tab.errors.get-customers");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        },
      }),
      mergeMap(customers => {
        this.lightCustomerList = customers.filter((customer: Light) => !customer.archived);
        return this.fetchMetalAccounts();
      })
    );
  }

  private fetchContext(): Observable<MetalAccount[]> {
    switch (this.contactType) {
      case ContactType.SUPPLIER:
        return this.fetchSuppliers();
      case ContactType.CUSTOMER:
        return this.fetchCustomers();
      default:
        console.error("Unknown contact type");
        return new Observable();
    }
  }

  private fetchMetalAccounts(): Observable<MetalAccount[]> {
    switch (this.contactType) {
      case ContactType.SUPPLIER:
        return this.fetchSupplierMetalAccounts();
      case ContactType.CUSTOMER:
        return this.fetchCustomerMetalAccounts();
      default:
        console.error("Unknown contact type");
        return new Observable();
    }
  }

  private setListId(): void {
    switch (this.contactType) {
      case ContactType.SUPPLIER:
        this.LIST_ID = "app-metal-account-tab.metal-account-supplier-table";
        break;
      case ContactType.CUSTOMER:
        this.LIST_ID = "app-metal-account-tab.metal-account-customer-table";
        break;
      default:
        console.error("Unknown contact type");
    }
  }

  private setDatatableTitle(): void {
    switch (this.contactType) {
      case ContactType.SUPPLIER:
        this.datatableTitle = this.translateService.instant("metal-account-tab.datatable.columns.supplier");
        break;
      case ContactType.CUSTOMER:
        this.datatableTitle = this.translateService.instant("metal-account-tab.datatable.columns.customer");
        break;
      default:
        console.error("Unknown contact type");
    }
  }

  private initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.initContextFilters();

    for (const metal of this.metals) {
      this.filterer.addFilter(metal.name, metal.name, "range");
    }
  }

  private initContextFilters(): void {
    switch (this.contactType) {
      case ContactType.SUPPLIER:
        this.filterer.addListFilter(
          "contactId",
          this.translateService.instant("metal-account-tab.datatable.columns.supplier"),
          this.metalAccountList
            .map(obj => {
              return { value: obj.contactId, displayValue: obj.contactName };
            })
            .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
          null,
          null,
          null,
          null,
          true
        );
        break;
      case ContactType.CUSTOMER:
        this.filterer.addListFilter(
          "contactId",
          this.translateService.instant("metal-account-tab.datatable.columns.customer"),
          this.metalAccountList
            .map(obj => {
              return { value: obj.contactId, displayValue: obj.contactName };
            })
            .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
          null,
          null,
          null,
          null,
          true
        );
        break;
      default:
        console.error("Unknown contact type");
    }
  }
}
