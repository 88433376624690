import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { ItemGroupLink, StoreModule, CaraUserService, StoreModuleService } from "center-services";
import { CommonValidatorsUtil, SubscriptionService } from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";

@Component({
  selector: "app-retail-item-group-list",
  templateUrl: "./retail-item-group-list.component.html",
  styleUrls: ["./retail-item-group-list.component.scss"],
  providers: [SubscriptionService],
})
export class RetailItemGroupListComponent implements OnInit {
  @Input() groupList: ItemGroupLink[];

  @Output() groupListChange: EventEmitter<ItemGroupLink[]> = new EventEmitter<ItemGroupLink[]>();
  @ViewChild("table") table: DatatableComponent;
  public menuActions: MenuAction[] = [];
  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "name",
      dir: "asc",
    },
  ];
  public oldItemGroupsForm: UntypedFormGroup = new UntypedFormGroup({});
  public itemGroupsForm: UntypedFormGroup;
  public storeModules: StoreModule[];
  private readonly DELETE_ACTION_ID: number = 1;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private userService: CaraUserService,
    private storeModuleService: StoreModuleService,
    private subscriptionService: SubscriptionService
  ) {}

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  ngOnInit(): void {
    if (!this.itemGroupsForm) {
      this.itemGroupsForm = new UntypedFormGroup({});
    }
    this.addMenuActions();
    this.refresh();
  }

  refresh(): void {
    this.fetchStoreModule();
  }

  addRow(itemGroup: ItemGroupLink): void {
    this.rows.push({
      id: itemGroup.id,
      name: itemGroup.itemGroupName,
      universe: itemGroup.universeName,
    });
  }

  addRetailItemsRows(): void {
    this.rows = [];
    if (this.groupList.length > 0) {
      this.groupList.forEach((itemGroup: ItemGroupLink) => {
        this.addRow(itemGroup);
      });
    }
    // add Row controls
    if (this.itemGroupsForm) {
      this.oldItemGroupsForm = this.itemGroupsForm;
    }
    this.addRowControls();
    this.rows = [...this.rows];
  }

  addRowControls(): void {
    if (this.groupList.length > 0) {
      this.groupList.forEach((itemGroup: ItemGroupLink) => {
        this.storeModules.forEach(module => {
          const itemLinkFound = itemGroup.modules.filter(itemLink => itemLink.storeModuleId === module.id);
          // Case of already existing Form, use the old value
          if (this.oldItemGroupsForm.contains(`${itemGroup.id}_${module.id}`)) {
            this.itemGroupsForm.addControl(
              `${itemGroup.id}_${module.id}`,
              new UntypedFormControl(this.itemGroupsForm.controls[`${itemGroup.id}_${module.id}`].value)
            );
          } else if (
            // Case of first creation in update, use the value in the itemGroup.itemLinks
            itemLinkFound.length > 0
          ) {
            this.itemGroupsForm.addControl(
              `${itemGroup.id}_${module.id}`,
              new UntypedFormControl(itemLinkFound[0].optimalQuantity)
            );
          } else {
            // Case of completely new ItemLink added via the Item Retail Popup
            this.itemGroupsForm.addControl(`${itemGroup.id}_${module.id}`, new UntypedFormControl(0));
          }
          this.itemGroupsForm.controls[`${itemGroup.id}_${module.id}`].setValidators(
            CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER)
          );
        });
      });
    }
  }

  getItemGroupIndexById(itemGroupId: number): number {
    for (const [index, itemGroup] of this.groupList.entries()) {
      if (itemGroup.id === itemGroupId) {
        return index;
      }
    }
    return -1;
  }

  fetchStoreModule(): void {
    this.subscriptionService.subs.push(
      this.storeModuleService.getAll().subscribe(
        (storeModules: StoreModule[]) => {
          this.storeModules = storeModules.sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("retail-item-group-list.errors.get-store-module", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        },
        () => {
          this.addRetailItemsRows();
        }
      )
    );
  }

  saveInputValue(controlName: string): void {
    const itemGroupId = Number(controlName.split("_")[0]);
    const storeModuleId = Number(controlName.split("_")[1]);
    let inputValue = this.itemGroupsForm.get(controlName).value;
    if (inputValue === null || inputValue === undefined || inputValue === "") {
      inputValue = "0";
    }
    const itemGroupIndex = this.groupList.findIndex(link => link.id === itemGroupId);

    if (itemGroupIndex >= 0) {
      const moduleIndex = this.groupList[itemGroupIndex].modules.findIndex(
        module => module.storeModuleId === storeModuleId
      );

      this.groupList[itemGroupIndex].modules[moduleIndex].optimalQuantity = parseInt(inputValue, 10);
      this.groupListChange.emit(this.groupList);
    }
  }

  removeGroup(rowId: number): void {
    if (!this.userService.canDo("ITEM_GROUP_UPDATE")) {
      return;
    }
    this.groupList.splice(this.getIndexFromList(rowId, this.groupList), 1);
    this.addRetailItemsRows();
  }

  getIndexFromList(id: number, itemGroupList: any[]): number {
    return itemGroupList.indexOf(this.getItemGroupFromList(id, itemGroupList));
  }

  getItemGroupFromList(id: number, itemGroupList: any[]): any {
    const itemGroup = itemGroupList.find(item => item.id === id);
    return itemGroup;
  }

  addMenuActions(): void {
    this.menuActions = [];
    this.menuActions.push(
      new MenuAction(
        this.DELETE_ACTION_ID,
        this.translateService.instant("retail-item-group-list.actions.remove"),
        faTrashAlt
      )
    );
  }

  manageActions(actionId: number, rowId: number): void {
    switch (actionId) {
      case this.DELETE_ACTION_ID:
        this.removeGroup(rowId);
        break;
      default:
        console.error(`Unhandled action : ${actionId}`);
        break;
    }
  }

  changeSortSettings(prop: string, direction: string): void {
    this.sorts = [{ prop, dir: direction }];
    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }
}
