import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { StockEntryCreation, AbstractItem, Stage, StockEntryService } from "center-services";
import { SearchFilter, SessionPagination, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { ComponentDirty, ErrorUtil } from "generic-pages";

@Component({
  selector: "app-manual-stock-entry-form",
  templateUrl: "./manual-stock-entry-form.component.html",
  styleUrls: ["./manual-stock-entry-form.component.scss"],
  providers: [SubscriptionService],
})
export class ManualStockEntryFormComponent implements OnInit, ComponentDirty {
  @ViewChild("manualStockEntryData") manualStockEntryData: any;
  @ViewChild("itemList") itemList: any;

  public unsavedStockEntry: StockEntryCreation;
  public editedStockEntry: StockEntryCreation;
  public activeFilters: SearchFilter[] = [];
  public faChevronLeft: IconDefinition = faChevronLeft;
  public shouldClose: boolean = false;
  public selectedItem: AbstractItem;
  public activeStage: Stage;
  public stages: Stage[];
  public popupVisible: boolean = false;
  public ITEM_LIST_ID: string = "app-manual-se-item-list.item-table";
  public sorts: any[] = [
    {
      prop: "status",
      dir: "asc",
    },
    {
      prop: "designation",
      dir: "asc",
    },
  ];

  protected readonly ONE: number = 1;
  protected readonly TWO: number = 2;

  constructor(
    private stockEntryService: StockEntryService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    this.initStages();
  }

  public ngOnInit(): void {
    this.initManualStockEntry();
    SessionPagination.clear(this.ITEM_LIST_ID);
  }

  public isDirty(): boolean {
    if (this.manualStockEntryData) {
      this.manualStockEntryData.applyModifications();
    }
    if (!this.editedStockEntry.equals(this.unsavedStockEntry) && !this.shouldClose) {
      this.unsavedStockEntry = new StockEntryCreation(this.editedStockEntry);
      this.shouldClose = true;
      return true;
    }

    this.shouldClose = false;
    return false;
  }

  public changeStage(stage: Stage): void {
    if (this.activeStage.id === this.TWO) {
      if (this.isDirty()) {
        const title = this.translateService.instant("global.errors.unsaved-title");
        const content = this.translateService.instant("global.errors.unsaved-page-content");
        this.messageService.info(content, { title });
        return;
      }
      this.initManualStockEntry();
    }

    if (!this.selectedItem) {
      const title = this.translateService.instant("manual-stock-entry.errors.error-title");
      const message = this.translateService.instant("manual-stock-entry.errors.get-item");
      this.messageService.error(message, { title });
      return;
    }

    this.itemList?.sessionPagination?.saveToSession(this.itemList.listId);

    this.stages.find(elem => elem.id === this.activeStage.id).validated = true;
    this.stages.forEach(element => (element.active = false));

    stage.active = true;
    this.activeStage = stage;
  }

  public changeSelectedItem(item: AbstractItem): void {
    this.selectedItem = item;
  }

  public backToPrevious(): void {
    switch (this.activeStage.id) {
      case this.TWO:
        this.changeStage(this.stages.find(stage => stage.id === this.ONE));
        break;
      case this.ONE:
        SessionPagination.clear(this.itemList.listId);
        this.router.navigateByUrl("/stocks-list");
        break;
      default:
        break;
    }
  }

  public getBackButtonTranslation(): string {
    if (this.activeStage.id !== this.ONE) {
      return "manual-stock-entry.buttons.back-to-step-button";
    }
    return "manual-stock-entry.buttons.back-to-list-button";
  }

  public getStageClass(stage: Stage): string {
    if (stage.validated) {
      return "stage validated";
    }
    return "stage";
  }

  public submitManualStockEntry(): void {
    if (!this.manualStockEntryData.updateManualStockEntryForm()) {
      return;
    }
    this.callSaveAPI();
  }

  public callSaveAPI(): void {
    if (!this.editedStockEntry) {
      return;
    }

    this.subscriptionService.subs.push(
      this.stockEntryService.create(this.editedStockEntry).subscribe(
        () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          this.unsavedStockEntry = new StockEntryCreation(this.editedStockEntry);
          this.popupVisible = true;
        },
        error => {
          this.handleApiError(error);
        }
      )
    );
  }

  public closePopup(): void {
    this.popupVisible = false;
    this.router.navigateByUrl("/stocks-list");
  }

  goToStockEntryLabelList(): void {
    this.router.navigateByUrl("/stock-entry-label-list");
  }

  private initStages(): void {
    this.stages = [];
    this.activeStage = new Stage(this.ONE, "1", "manual-stock-entry.step-one", true, false);
    this.stages.push(this.activeStage);
    this.stages.push(new Stage(this.TWO, "2", "manual-stock-entry.step-two", false, false));
  }

  private handleApiError(error: any): void {
    const title = this.translateService.instant("message.title.form-errors");

    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  private initManualStockEntry(): void {
    this.editedStockEntry = new StockEntryCreation();
    this.unsavedStockEntry = new StockEntryCreation(this.editedStockEntry);
  }
}
