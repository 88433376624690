import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IconDefinition, faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  LightCustomer,
  Light,
  ReceivingForm,
  Currency,
  LightService,
  CurrencyService,
  Store,
  Pagination,
  Sort,
  LightStore,
} from "center-services";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { InvoiceCustomerInitiatorOutput } from "./invoice-customer-initiator-output";
import { SearchFilter, SubscriptionService } from "fugu-common";

@Component({
  selector: "app-invoice-customer-initiator-popup",
  templateUrl: "./invoice-customer-initiator-popup.component.html",
  styleUrls: ["./invoice-customer-initiator-popup.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceCustomerInitiatorPopupComponent implements OnInit {
  @Input() tabNameList: string[] = [];
  @Input() contactId: number = null;
  @Input() deliveryFormList: ReceivingForm[] = [];
  @Input() isOneInvoicePerStoreEnabled: boolean;

  @Output() validate: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  public customerList: LightCustomer[] = [];

  public unModifiedStoreList: LightStore[] = [];
  public storeList: LightStore[] = [];
  public supplierList: Light[] = [];
  public shouldClose: boolean = false;
  public storeCustomerLink: Map<number, number> = new Map();
  public selectedDeliveryForms: ReceivingForm[] = [];
  public currency: Currency;
  public oneInvoicePerStore: boolean;

  public initialInvoiceCustomerInitiatorOutput: InvoiceCustomerInitiatorOutput;
  public unsavedInvoiceCustomerInitiatorOutput: InvoiceCustomerInitiatorOutput;
  public selectedInvoiceCustomerInitiatorOutput: InvoiceCustomerInitiatorOutput;
  faArrowCircleRight: IconDefinition = faArrowCircleRight;
  public storeSorts: any[] = [];
  public activeFilters: SearchFilter[] = [];
  private initObservables: Observable<any>[] = [];
  // eslint-disable-next-line no-magic-numbers
  private readonly DEFAULT_PAGE_SIZE: number = 7;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public storePager: Pagination = new Pagination({
    size: this.DEFAULT_PAGE_SIZE,
    number: 0,
  });

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private lightService: LightService,
    private currencyService: CurrencyService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.initObservables.push(this.fetchCustomers());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchCurrency());

    this.selectedDeliveryForms = this.deliveryFormList;
    this.oneInvoicePerStore = this.isOneInvoicePerStoreEnabled;

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initializePopup();
      })
    );
  }

  initializePopup(): void {
    this.selectedInvoiceCustomerInitiatorOutput = new InvoiceCustomerInitiatorOutput({
      deliveryForms: [],
      multipleStoreSelection: this.oneInvoicePerStore,
      batch: false,
    });

    this.initialInvoiceCustomerInitiatorOutput = new InvoiceCustomerInitiatorOutput(
      this.selectedInvoiceCustomerInitiatorOutput
    );
  }

  closePopup(): void {
    this.applyModifications();

    if (
      this.unsavedInvoiceCustomerInitiatorOutput &&
      !this.unsavedInvoiceCustomerInitiatorOutput.equals(this.selectedInvoiceCustomerInitiatorOutput)
    ) {
      this.shouldClose = false;
    }

    if (
      !this.initialInvoiceCustomerInitiatorOutput.equals(this.selectedInvoiceCustomerInitiatorOutput) &&
      !this.shouldClose
    ) {
      const message = this.translateService.instant("global.errors.unsaved-popin-content");
      const title = this.translateService.instant("global.errors.unsaved-title");
      this.messageService.info(message, { title });

      this.unsavedInvoiceCustomerInitiatorOutput = new InvoiceCustomerInitiatorOutput(
        this.selectedInvoiceCustomerInitiatorOutput
      );
      this.shouldClose = true;
    } else {
      this.close.emit();
    }
  }

  submit(): void {
    this.applyModifications();
    if (this.selectedInvoiceCustomerInitiatorOutput.deliveryForms.length === 0 && this.contactId !== null) {
      this.closePopup();
      return;
    }
    this.validate.emit([this.selectedInvoiceCustomerInitiatorOutput]);
  }

  getCustomersIdsFromSelectedDeliveryForms(): number[] {
    const ids = this.selectedDeliveryForms.flatMap(df => df.receiverId);

    ids.forEach((id, index: number, array) => {
      if (this.storeList.some(store => store.id === id)) {
        array[index] = this.storeCustomerLink.get(id);
      }
    });

    return ids;
  }

  getIdsFromSelectedDeliveryForms(): number[] {
    return this.selectedDeliveryForms.flatMap(df => df.receiverId).filter((id, i, array) => array.indexOf(id) === i);
  }

  getNumberOfNonAffiliateCustomers(ids: number[]): number {
    return this.customerList.filter(cus => ids.includes(cus.id)).filter(cus => !cus.affiliate).length;
  }

  getNumberOfAffiliateCustomers(ids: number[]): number {
    return this.customerList.filter(cus => ids.includes(cus.id)).filter(cus => cus.affiliate).length;
  }

  getNumberOfStoresInSelectedDeliveryForm(ids: number[]): number {
    return ids.filter(id => this.storeList.some(store => store.id === id)).length;
  }

  isThereOnlyOneStoreSelectedDeliveyForm(): boolean {
    return this.getNumberOfStoresInSelectedDeliveryForm(this.getIdsFromSelectedDeliveryForms()) === 1;
  }

  getButtonText(): string {
    const customersIds = this.getCustomersIdsFromSelectedDeliveryForms();
    const numberOfNonAffiliateCustomers = this.getNumberOfNonAffiliateCustomers(customersIds);
    const numberOfAffilliateCustomers = this.getNumberOfAffiliateCustomers(customersIds);
    const unique = "invoice-customer-initiator-popup.buttons.unique-invoice";
    const multi = "invoice-customer-initiator-popup.buttons.multi-invoice";
    const addDocuments = "invoice-customer-initiator-popup.buttons.add-documents";

    let message = "invoice-customer-initiator-popup.buttons.free-invoice";

    if (this.contactId !== null) {
      return addDocuments;
    }

    if (numberOfNonAffiliateCustomers > 0) {
      message = numberOfNonAffiliateCustomers === 1 ? unique : multi;

      if (numberOfAffilliateCustomers > 0) {
        message = multi;
      }
    } else if (numberOfAffilliateCustomers > 0) {
      message = multi;

      if (numberOfAffilliateCustomers === 1) {
        if (this.isThereOnlyOneStoreSelectedDeliveyForm()) {
          message = unique;
        } else {
          message = this.oneInvoicePerStore ? multi : unique;
        }
      }
    }

    return message;
  }

  applyModifications(): void {
    this.selectedInvoiceCustomerInitiatorOutput.deliveryForms = this.selectedDeliveryForms;
    this.selectedInvoiceCustomerInitiatorOutput.multipleStoreSelection = this.oneInvoicePerStore;
    this.selectedInvoiceCustomerInitiatorOutput.batch =
      this.getButtonText() === "invoice-customer-initiator-popup.buttons.multi-invoice";
  }

  onSelectedDeliveryFormsChange(selectedDeliveryForms: ReceivingForm[]): void {
    this.selectedDeliveryForms = selectedDeliveryForms;
  }

  onOneInvoicePerStoreCheckboxChange(oneInvoicePerStore: boolean): void {
    this.oneInvoicePerStore = oneInvoicePerStore;
  }

  fetchCustomers(): Observable<Light[]> {
    return this.lightService.getCustomers().pipe(
      tap(
        (lightCustomers: LightCustomer[]) => {
          this.customerList = lightCustomers;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-customer-initiator-popup.errors.get-customers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchStores(): Observable<LightStore[]> {
    return this.lightService.getStores().pipe(
      tap(
        (stores: LightStore[]) => {
          this.unModifiedStoreList = [...stores];
          this.storeList = stores.filter(store => store.id === this.contactId);
          if (this.storeList.length === 0) {
            const customerId = stores.find(store => store.id === this.contactId)?.customerId;
            customerId === undefined
              ? (this.storeList = stores.filter(store =>
                this.contactId ? store.customerId === this.contactId : store.customerId !== null
              ))
              : (this.storeList = stores.filter(store => store.customerId === customerId));
          }

          this.storeList.forEach((store: Store) => {
            this.storeCustomerLink.set(store.id, store.customerId);
          });
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("stores-list.errors.get-stores");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (suppliers: Light[]) => {
          this.supplierList = suppliers;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-customer-initiator-popup.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCurrency(): Observable<Currency> {
    return this.currencyService.getDefault().pipe(
      tap(
        (currency: Currency) => {
          this.currency = currency;
        },
        () => {
          const title = this.translateService.instant("invoice-customer-initiator-popup.errors.get-stores");
          const content = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.storeSorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "storeName":
        return "name";
      case "city":
        return "address.city";
      default:
        return prop;
    }
  }
}
