<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ 'item-group-form.buttons.back-button' | translate }}"
        [linkIcon]="faChevronLeft"
        (click)="back()"
      ></ui-link>
    </div>
    <div class="top-buttons">
      <div class="validate-button">
        <div class="button">
          <ui-button
            text="{{ 'global.validate' | translate }}"
            [primary]="true"
            type="save"
            (click)="submitItemGroup().subscribe()"
          ></ui-button>
        </div>
      </div>
    </div>
    <div class="title">
      <ui-title titleText="{{ 'item-group-form.title.' + title | translate }}"></ui-title>
    </div>

    <div class="form-table-container">
      <ui-container>
        <div class="form-content">
          <form class="item-group-form" [formGroup]="form" novalidate>
            <div class="grid-container">
              <div class="grid-item name">
                <ui-input-text
                  type="text"
                  label="{{ 'item-group-form.fields.name' | translate }}"
                  formControlName="name"
                  [status]="form.get('name') | formStatus"
                  [errorMessage]="form.get('name') | formErrorMessage | translate"
                  mandatory="true"
                  infoLabel="{{ 'global.unique' | translate }}"
                >
                </ui-input-text>
              </div>

              <div class="grid-item universe">
                <ui-list
                  label="{{ 'item-group-form.fields.universe' | translate }}"
                  selectLabel="{{ 'item-group-form.fields.universe' | translate }}"
                  [options]="this.itemUniverseOptions"
                  [search]="true"
                  formControlName="universe"
                  [status]="form.get('universe') | formStatus"
                  [errorMessage]="form.get('universe') | formErrorMessage | translate"
                >
                </ui-list>
              </div>
            </div>
          </form>
        </div>

        <div class="grid-container">
          <div class="grid-item">
            <div class="align-buttons">
              <div class="button retail-items-button">
                <ui-button
                  text="{{ 'item-group-form.button.retail-items' | translate }}"
                  type="settings"
                  customClass="button-color"
                  (click)="this.showItemSelectionMethod()"
                >
                </ui-button>
              </div>

              <div class="button store-module-entries-button second-button">
                <ui-button
                  text="{{ 'item-group-form.button.store-module-entries' | translate }}"
                  type="settings"
                  customClass="button-color"
                  (click)="this.showStoreModuleEntriesMethod()"
                >
                </ui-button>
              </div>
            </div>
          </div>
        </div>

        <div class="item-group-table">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            class="material"
            columnMode="force"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40px"
            [limit]="15"
            [rowClass]="getRowClass"
            [scrollbarH]="true"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [externalSorting]="true"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            [externalPaging]="true"
            [count]="pager.totalElements"
            [offset]="pager.number"
            [limit]="pager.size"
          >
            <ngx-datatable-column
              name="{{ 'item-group-form.datatable.columns.reference' | translate }}"
              cellClass="reference"
              prop="reference"
              [resizeable]="false"
              [flexGrow]="1"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item-group-form.datatable.columns.name' | translate }}"
              cellClass="name"
              prop="name"
              [resizeable]="false"
              [flexGrow]="1"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item-group-form.datatable.columns.supplier' | translate }}"
              cellClass="supplier"
              prop="supplier"
              [resizeable]="false"
              [flexGrow]="1"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item-group-form.datatable.columns.brand' | translate }}"
              cellClass="brand"
              prop="brand"
              [resizeable]="false"
              [flexGrow]="1"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item-group-form.datatable.columns.collection' | translate }}"
              cellClass="collection"
              prop="collection"
              [resizeable]="false"
              [flexGrow]="1"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item-group-form.datatable.columns.status' | translate }}"
              prop="status"
              cellClass="status"
              [flexGrow]="0.7"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <ui-status-label [label]="row.status" [customClass]="row.statusClass"></ui-status-label>
              </ng-template>
            </ngx-datatable-column>

            <!-- Loop of store module column -->

            <ngx-datatable-column
              *ngFor="let storeModule of storeModules"
              name="{{ storeModule.name }}"
              cellClass="modules"
              width="40"
              [resizeable]="false"
              [flexGrow]="0.3"
              [sortable]="false"
            >
              <ng-template ngx-datatable-cell-template let-rowId="row.id">
                <ui-input-text
                  *ngIf="form.get('itemLinks.' + rowId + '_' + storeModule.id)"
                  id="{{ rowId + '_' + storeModule.name }}"
                  customClass="datatable-row"
                  class="input-fields"
                  type="text"
                  [sizeMode]="'small'"
                  [formControl]="form.get('itemLinks.' + rowId + '_' + storeModule.id)"
                  [status]="form.get('itemLinks.' + rowId + '_' + storeModule.id) | formStatus"
                  [errorMessage]="
                    form.get('itemLinks.' + rowId + '_' + storeModule.id)
                      | formErrorMessage
                      | translate: { digit: HIGH_INTEGER }
                  "
                  [maskPattern]="decimalDigit"
                >
                </ui-input-text>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="changePage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>
  </div>

  <div *ngIf="showItemSelection" class="item-selection-popup">
    <app-retail-item-selection
      [columns]="columns"
      [(selection)]="itemsSelection"
      (selectionChange)="validateItemSelection()"
      [unique]="false"
      [popupWidth]="'90vw'"
      (closePopup)="closeRetailSelection()"
      [limit]="15"
    ></app-retail-item-selection>
  </div>

  <div *ngIf="showStoreModuleEntries">
    <app-store-module-entry-popup
      [(storeModuleEntries)]="storeModuleEntries"
      (storeModuleEntriesChange)="validateStoreModuleEntries()"
      [itemGroupId]="this.itemGroup.id"
      (closePopup)="closeStoreModulesPopup()"
    >
    </app-store-module-entry-popup>
  </div>
</ng-container>
