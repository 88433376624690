<ng-container>
  <form class="brand-general" [formGroup]="brandForm" novalidate>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'brand.general-datas.name' | translate }}"
        formControlName="name"
        status="{{ brandForm.get('name') | formStatus }}"
        errorMessage="{{ brandForm.get('name') | formErrorMessage | translate }}"
        mandatory="true"
        infoLabel="{{ 'global.unique' | translate }}"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'brand.general-datas.reference' | translate }}"
        formControlName="reference"
        status="{{ brandForm.get('reference') | formStatus }}"
        errorMessage="{{ brandForm.get('reference') | formErrorMessage | translate }}"
        mandatory="true"
        infoLabel="{{ 'global.unique' | translate }}"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-textarea label="{{ 'brand.general-datas.address' | translate }}" formControlName="lines"> </ui-textarea>
    </div>
    <div class="grid-item">
      <div class="multi-col">
        <div class="city-code">
          <ui-input-text
            type="text"
            label="{{ 'brand.general-datas.city-code' | translate }}"
            formControlName="cityCode"
          >
          </ui-input-text>
        </div>
        <div class="city">
          <ui-input-text type="text" label="{{ 'brand.general-datas.city' | translate }}" formControlName="city">
          </ui-input-text>
        </div>
      </div>
    </div>
    <div class="grid-item">
      <ui-list
        label="{{ 'brand.general-datas.country' | translate }}"
        [options]="this.countryOptions"
        formControlName="country"
        mandatory="true"
        [search]="true"
        [status]="brandForm.get('country') | formStatus"
        [errorMessage]="brandForm.get('country') | formErrorMessage | translate"
      ></ui-list>
    </div>

    <div class="grid-item">
      <ui-list
        label="{{ 'brand.general-datas.origin-country' | translate }}"
        [options]="this.countryOptions"
        formControlName="originCountry"
        [status]="brandForm.get('originCountry') | formStatus"
        [errorMessage]="brandForm.get('originCountry') | formErrorMessage | translate"
        mandatory="true"
      ></ui-list>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'brand.general-datas.email' | translate }}"
        formControlName="email"
        status="{{ brandForm.get('email') | formStatus }}"
        errorMessage="{{ brandForm.get('email') | formErrorMessage | translate }}"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'brand.general-datas.phone' | translate }}"
        formControlName="phone"
        maskPattern="PN NN NN NN NN NN"
        [customPattern]="pattern"
        [specialCharacters]="[' ', '+']"
        [dropSpecialCharacters]="[' ']"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-input-text type="text" label="{{ 'brand.general-datas.website' | translate }}" formControlName="website">
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-input-text type="text" label="{{ 'brand.general-datas.codeRCS' | translate }}" formControlName="codeRCS">
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-list
        label="{{ 'brand.general-datas.currency' | translate }}"
        [options]="this.currencyOptions"
        formControlName="currency"
        [status]="brandForm.get('currency') | formStatus"
        [errorMessage]="brandForm.get('currency') | formErrorMessage | translate"
        mandatory="true"
      ></ui-list>
    </div>

    <div class="grid-item logo-container">
      <ui-input-file
        id="'file'"
        accept=".png, .jpeg, .jpg, .JPG, .JPEG, .PNG"
        smallSize="true"
        label="{{ 'brand.general-datas.logo-label' | translate }}"
        buttonText="{{ 'global.input-file.btn' | translate }}"
        descriptionText="{{ 'global.input-file.drag-drop' | translate }}"
        formControlName="logoFile"
      ></ui-input-file>
    </div>
    <div class="grid-item color">
      <ui-color-picker
        label="{{ 'brand.general-datas.color' | translate }}"
        format="hex"
        [formControl]="brandForm.get('color')"
      >
      </ui-color-picker>
    </div>
    <div class="grid-item">
      <ui-textarea label="{{ 'brand.general-datas.comment' | translate }}" formControlName="comment"> </ui-textarea>
    </div>
    <div class="grid-item tag">
      <ui-tag-handler
        label="{{ 'brand.general-datas.tag' | translate }}"
        [availableTags]="this.availableTags"
        formControlName="tags"
      >
      </ui-tag-handler>
    </div>
  </form>
</ng-container>
