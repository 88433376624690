<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'invoice-customer-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-create">
        <ui-button
          text="{{ 'invoice-customer-list.button.new' | translate }}"
          type="add"
          [primary]="true"
          *hasPermission="['INVOICE_CUSTOMER_CREATE']"
          (click)="createInvoiceCustomer()"
        ></ui-button>
      </div>
      <div class="button-create-credit-note">
        <ui-button
          text="{{ 'invoice-customer-list.button.new-credit-note' | translate }}"
          type="add"
          [primary]="false"
          *hasPermission="['CREDIT_NOTE_CUSTOMER_CREATE']"
          (click)="createCreditNote()"
        ></ui-button>
      </div>
    </div>
    <div class="invoice-customer-list" *ngIf="rows">
      <ui-container>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            [locale]="locale"
            dateFormat="{{ dateFormat }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div
          class="headband"
          *ngIf="hasRights(['INVOICE_CUSTOMER_UPDATE']) || hasRights(['CREDIT_NOTE_CUSTOMER_UPDATE'])"
        >
          <div class="selected-count">
            <span
              [innerHTML]="
                selectedInvoiceList.length === 0
                  ? ('global.datatable.no-items' | translate)
                  : (selectedInvoiceList.length
                    | i18nPlural
                      : {
                          '=1': 'global.datatable.n-selected-items',
                          other: 'global.datatable.n-selected-items_plural',
                        }
                    | translate: { selectedElements: selectedInvoiceList.length })
              "
            >
            </span>
          </div>
          <div class="button">
            <ui-button
              text="{{ 'invoice-customer-list.datatable.print-invoices-button' | translate }}"
              (click)="printSelectedInvoices()"
              [disabled]="selectedInvoiceList.length === 0 || !hasPrintableStatus"
              [btnIcon]="faPrint"
            >
            </ui-button>
          </div>
          <div class="tooltip" *ngIf="selectedInvoiceList.length === 0 || !hasPrintableStatus">
            <span class="info-icon" #info>
              <fa-icon [icon]="faInfoCircle"></fa-icon>
            </span>
            <ui-tooltip maxWidth="200px" [element]="info">
              <div>{{ "invoice-customer-list.datatable.print-tooltip" | translate }}</div>
            </ui-tooltip>
          </div>

          <div class="button">
            <ui-button
              text="{{ 'invoice-customer-list.datatable.validate-invoices-button' | translate }}"
              type="validate"
              (click)="openValidatePopup()"
              [disabled]="selectedInvoiceList.length === 0 || !hasValidableStatus"
            >
            </ui-button>
          </div>
          <div class="tooltip" *ngIf="selectedInvoiceList.length === 0 || !hasValidableStatus">
            <span class="info-icon" #info>
              <fa-icon [icon]="faInfoCircle"></fa-icon>
            </span>
            <ui-tooltip maxWidth="200px" [element]="info">
              <div>{{ "invoice-customer-list.datatable.validate-tooltip" | translate }}</div>
            </ui-tooltip>
          </div>
        </div>

        <div
          class="separator"
          *ngIf="hasRights(['INVOICE_CUSTOMER_UPDATE']) || hasRights(['CREDIT_NOTE_CUSTOMER_UPDATE'])"
        >
          <div class="pointer">
            <div class="arrow"></div>
          </div>
          <div class="line"></div>
        </div>

        <div class="invoice-customer-table frozen-right">
          <div [formGroup]="tableControl">
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material"
              columnMode="force"
              headerHeight="auto"
              footerHeight="50"
              rowHeight="40"
              (activate)="checkInvoiceDetail($event)"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down',
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [externalSorting]="true"
              [externalPaging]="true"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              scrollbarH="true"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                width="50"
                [sortable]="false"
                [frozenLeft]="true"
                [draggable]="false"
                [resizeable]="false"
                [canAutoResize]="false"
                cellClass="center-align frozen-style"
              >
                <ng-template ngx-datatable-header-template>
                  <div class="green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onHeaderCheckboxChange()"
                      [formControlName]="'headerCheckbox'"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-id="row.id" let-row="row">
                  <div class="adjust green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onRowCheckboxChange()"
                      [formControlName]="getRowControlName(id)"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.document-type' | translate }}"
                prop="documentType"
                cellClass="documentType"
                [resizeable]="false"
                [width]="180"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.reference' | translate }}"
                prop="reference"
                cellClass="reference"
                [resizeable]="false"
                [width]="180"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.customer-ref' | translate }}"
                prop="customerRef"
                cellClass="customerRef"
                [sortable]="true"
                [resizeable]="false"
                [width]="180"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.stores' | translate }}"
                cellClass="stores top-align ellipsis-counter"
                [resizeable]="false"
                [sortable]="false"
                prop="storeNames"
                [width]="180"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-stores="row.storeNames">
                  <p class="ellipsis" [title]="stores.join(', ')">{{ stores.join(", ") }}</p>
                  <div *ngIf="stores.length >= 2">
                    <div #storeCounter>
                      <ui-counter [number]="stores.length"></ui-counter>
                    </div>
                    <ui-tooltip maxWidth="300px" [element]="storeCounter">
                      <span style="font-weight: normal !important">{{ stores.join(", ") }}</span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.date' | translate }}"
                prop="date"
                cellClass="date"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.date">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.max-payment-date' | translate }}"
                prop="maxPaymentDate"
                cellClass="max-payment-date"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.maxPaymentDate">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.total-gross-price' | translate }}"
                prop="totalGrossPrice"
                cellClass="total-gross-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO : "symbol" : "0.2-2" : locale }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.tax-price' | translate }}"
                prop="taxPrice"
                cellClass="tax-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO : "symbol" : "0.2-2" : locale }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.total-price' | translate }}"
                prop="totalPrice"
                cellClass="total-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO : "symbol" : "0.2-2" : locale }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.deliveries' | translate }}"
                cellClass="deliveries top-align ellipsis-counter"
                [resizeable]="false"
                [sortable]="false"
                prop="deliveries"
                [width]="180"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-deliveries="row.deliveries">
                  <p class="ellipsis" [title]="deliveries.join(', ')">{{ deliveries.join(", ") }}</p>
                  <div *ngIf="deliveries.length >= 2">
                    <div #deliveryCounter>
                      <ui-counter [number]="deliveries.length"></ui-counter>
                    </div>
                    <ui-tooltip maxWidth="300px" [element]="deliveryCounter">
                      <span style="font-weight: normal !important">{{ deliveries.join(", ") }}</span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.invoice-references' | translate }}"
                cellClass="invoice-references top-align ellipsis-counter"
                [resizeable]="false"
                [sortable]="false"
                prop="invoiceReferences"
                [width]="180"
              >
                <ng-template
                  ngx-datatable-cell-template
                  let-row="row"
                  let-id="row.id"
                  let-invoiceReferences="row.invoiceReferences"
                >
                  <p
                    *ngIf="invoiceReferences && invoiceReferences.length > 0"
                    class="ellipsis"
                    [title]="invoiceReferences.join(', ')"
                  >
                    {{ invoiceReferences.join(", ") }}
                  </p>
                  <div *ngIf="invoiceReferences && invoiceReferences.length >= 2">
                    <div #invoiceReferenceCounter>
                      <ui-counter [number]="invoiceReferences.length"></ui-counter>
                    </div>
                    <ui-tooltip maxWidth="300px" [element]="invoiceReferenceCounter">
                      <span style="font-weight: normal !important">{{ invoiceReferences.join(", ") }}</span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.status' | translate }}"
                prop="statusLabel"
                cellClass="status frozen-style"
                headerClass="left-align"
                [width]="120"
                [resizeable]="false"
                [frozenRight]="true"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"></ui-status-label>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-customer-list.datatable.columns.actions' | translate }}"
                headerClass="center-align"
                cellClass="center-align frozen-style"
                [resizeable]="false"
                [frozenRight]="true"
                [sortable]="false"
                [width]="80"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div *ngIf="row.actionnable" class="green-zone" (click)="$event.stopPropagation()">
                    <ui-menu-actions
                      [menuActions]="allocateMenuActions(row)"
                      (actionCalled)="manageActions($event, row)"
                    >
                    </ui-menu-actions>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>

      <ui-popup
        *ngIf="popupVisible"
        title="{{ 'invoice-customer-list.popup.title' | translate }}"
        (close)="closePopup()"
      >
        <div content>
          <div class="popup-content" [innerHTML]="'invoice-customer-list.popup.content' | translate"></div>
        </div>
        <div footer>
          <div class="popup-buttons">
            <div class="confirm-button">
              <ui-button
                text="{{ 'invoice-customer-list.popup.buttons.validate' | translate }}"
                (click)="deleteLine()"
                [primary]="true"
                type="validate"
              ></ui-button>
            </div>

            <div class="cancel-button">
              <ui-button
                text="{{ 'invoice-customer-list.popup.buttons.cancel' | translate }}"
                (click)="closePopup()"
                type="cancel"
              ></ui-button>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </div>

  <div class="popup" *ngIf="validatePopupVisible">
    <app-invoice-customer-list-validation
      warningMessage="{{ 'invoice-customer-list.popup.warning' | translate }}"
      [subWarningMessage]="showSubWarningMessage ? ('invoice-customer-list.popup.sub-warning' | translate) : null"
      (validate)="validateInvoiceCustomerEvent($event)"
      (close)="closeInvoiceCustomerValidation()"
    >
    </app-invoice-customer-list-validation>
  </div>

  <div class="popup" *ngIf="dateWarningPopupVisible">
    <app-invoice-date-warning-popup (validate)="validateInvoiceCustomerEvent($event)" (close)="closeDateWarningPopup()">
    </app-invoice-date-warning-popup>
  </div>
</ng-container>
