<ng-container>
  <div class="status-popup-container" [formGroup]="popupForm" novalidate>
    <ui-popup title="{{ 'shipment-form-list.add-shipment-infos-popin.title' | translate }}" (close)="closeInfosPopup()">
      <div content>
        <div class="grid-container">
          <div class="grid-item">
            <ui-input-text
              type="text"
              label="{{ 'shipment-form-list.informations.forwardingReference' | translate }}"
              formControlName="forwardingReference"
            >
            </ui-input-text>
          </div>
          <div class="grid-item">
            <ui-input-text
              type="text"
              label="{{ 'shipment-form-list.informations.packageReference' | translate }}"
              formControlName="packageReference"
            >
            </ui-input-text>
          </div>
          <div class="grid-item">
            <ui-textarea
              label="{{ 'shipment-form-list.informations.packagingComment' | translate }}"
              formControlName="packagingComment"
              customClass="no-margin"
              maxLength="1024"
            >
            </ui-textarea>
          </div>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'purchase-order.status-popup.validate' | translate }}"
                [primary]="true"
                type="validate"
                (click)="submitInfosPopup()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
