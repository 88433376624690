import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
} from "@angular/core";

import { IconDefinition, faGripLines } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { ThemeService } from "center-services";
import { Option } from "fugu-common";
import { MessageService } from "fugu-components";

@Component({
  selector: "app-stock-priorization",
  templateUrl: "./stock-priorization.component.html",
  styleUrls: ["./stock-priorization.component.scss"],
})
export class StockPriorizationComponent implements OnInit, AfterViewInit {
  @Output() validate: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input() rootLocationOptions: Option[] = [];
  @Input() rootLocationIds: number[] = [];
  @ViewChildren("onDragCss") divStockBox: QueryList<ElementRef>;
  @ViewChildren("onDragIconCss") divStockBoxIcon: QueryList<ElementRef>;

  public shouldClose: boolean = false;
  public rootLocations: Option[];
  public faGrip: IconDefinition = faGripLines;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private renderer: Renderer2,
    private themeService: ThemeService
  ) {}

  ngAfterViewInit(): void {
    if (this.themeService.getValue()) {
      // Light theme
      this.manageStockBoxThemeColor("#FFFFFF", "#D8E5E8", "#012B5D", "#0D79C2");
    } else {
      // Dark theme
      this.manageStockBoxThemeColor("#2A3042", "#32394B", "#DFE3EE", "#03BEFF");
    }
  }

  manageStockBoxThemeColor(
    backgroundBoxColor: string,
    borderBoxColor: string,
    textBoxColor: string,
    iconColor: string
  ): void {
    for (const box of this.divStockBox) {
      this.renderer.setStyle(box.nativeElement, "backgroundColor", backgroundBoxColor);
      this.renderer.setStyle(box.nativeElement, "borderColor", borderBoxColor);
      this.renderer.setStyle(box.nativeElement, "color", textBoxColor);
    }
    for (const icon of this.divStockBoxIcon) {
      this.renderer.setStyle(icon.nativeElement, "color", iconColor);
    }
  }

  ngOnInit(): void {
    this.rootLocations = this.rootLocationOptions
      .filter((option: Option) => this.rootLocationIds.includes(option.id as number))
      .sort((a: Option, b: Option) => {
        return this.rootLocationIds.indexOf(a.id as number) - this.rootLocationIds.indexOf(b.id as number);
      });
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.rootLocations, event.previousIndex, event.currentIndex);
    this.shouldClose = false;
  }

  public submitStockPopup(): void {
    this.rootLocationIds = this.rootLocations.map(value => value.id as number);
    this.validate.emit(this.rootLocationIds);
  }

  public canClosePopup(): void {
    if (
      !this.rootLocations.map(value => value.id).every((element, index) => element === this.rootLocationIds[index]) &&
      !this.shouldClose
    ) {
      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.shouldClose = true;
    } else {
      this.closeStockPopup();
    }
  }

  private closeStockPopup(): void {
    this.close.emit();
  }
}
