import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, NgForm, Validators, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IconDefinition, faChevronLeft, faPen, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { InvoiceFormFetcher } from "app/invoice/util/invoice-form-fetcher";
import {
  CaraUserService,
  DiscountType,
  InvoiceCustomer,
  InvoiceCustomerLine,
  InvoiceCustomerService,
  InvoiceStatus,
} from "center-services";
import { DayjsUtil, SubscriptionService,RoundingUtil } from "fugu-common";
import { MenuAction, MessageService, TabComponent } from "fugu-components";
import { ErrorUtil, PrecisionUtil } from "generic-pages";
import { Observable } from "rxjs";
import { CreditNoteCustomerFront } from "./credit-note-customer-front";
import { CreditNoteCustomerTab } from "./credit-note-customer-tab";
import { CreditNoteBase } from "../util/credit-note-base.directive";
import { CreditNoteCustomerLine } from "./credit-note-customer-line";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-credit-note-customer-form",
  templateUrl: "./credit-note-customer-form.component.html",
  styleUrls: ["./credit-note-customer-form.component.scss"],
  providers: [InvoiceFormFetcher, SubscriptionService],
})
export class CreditNoteCustomerFormComponent extends CreditNoteBase implements OnInit, AfterViewChecked {
  private static CARASQL_EXCEPTION: string = "CaraSQLException";
  private static EXCEPTION_CONSTRAINT: string = "invoiceCustomerAnteriorDate";
  @ViewChild("tableForm") tableForm: NgForm;
  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public LIST_ID: string = "credit-note.credit-note-customer-form";
  public HIGH_INTEGER: number = PrecisionUtil.HIGH_INTEGER;
  public invoiceCustomerSelectorPopupVisible: boolean = false;
  public commentPopupVisible: boolean = false;
  public hasError: boolean = false;
  public shouldValidate: boolean = false;
  public title: string;
  public unsavedCreditNoteCustomer: CreditNoteCustomerFront;
  public initialCreditNoteCustomer: CreditNoteCustomerFront;
  public selectedRows: CreditNoteCustomerLine[] = [];
  public invoiceCustomerMap: Map<number, InvoiceCustomer> = new Map<number, InvoiceCustomer>();
  public selectedInvoices: InvoiceCustomer[];
  public selectedTabId: number;

  public headerForm: UntypedFormGroup;

  public initObservables: Observable<any>[] = [];
  public faPlus: IconDefinition = faPlus;
  public faPen: IconDefinition = faPen;
  public faChevronLeft: IconDefinition = faChevronLeft;
  public faTrash: IconDefinition = faTrash;
  public tabToDelete: TabComponent;
  public deleteTabConfirmationPopupVisible: boolean = false;
  public menuActions: MenuAction[] = [];
  public readOnly: boolean;
  public validatePopupVisible: boolean = false;
  public dateFormat: string;
  public locale: string;
  private readonly DELETE_ACTION_ID: number = 0;
  private shouldClose: boolean = false;

  constructor(
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private fb: UntypedFormBuilder,
    protected route: ActivatedRoute,
    protected invoiceCustomerService: InvoiceCustomerService,
    protected router: Router,
    public fetcher: InvoiceFormFetcher,
    private changeDetector: ChangeDetectorRef,
    protected subscriptionService: SubscriptionService
  ) {
    super(
      userService,
      translateService,
      messageService,
      invoiceCustomerService,
      fetcher,
      router,
      route,
      subscriptionService
    );
    this.listId = this.LIST_ID;
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  public ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.locale = this.userService.connectedUser.value.codeLanguage;
    } else {
      this.userService.connectedUser.subscribe(user => {
        this.dateFormat = user.dateFormat;
        this.locale = user.codeLanguage;
      });
    }
    this.sorts = [
      {
        prop: CreditNoteBase.LINE_NUMBER,
        dir: CreditNoteBase.ASC,
      },
    ];
    this.addMenuActions();
    this.headerForm = this.fb.group({
      date: [null, [Validators.required]],
      comment: [null],
    });

    const creditNoteCustomerId = this.route.snapshot?.params?.id;
    const invoiceCustomerId = this.route.snapshot?.queryParams?.invoiceId;

    this.subscriptionService.subs.push(
      this.fetcher.fetchInitialData().subscribe(() => {
        if (creditNoteCustomerId) {
          this.subscriptionService.subs.push(
            this.fetchCreditNoteCustomer(creditNoteCustomerId).subscribe(() => {
              if (!this.fetcher.isConform) {
                this.headerForm.controls.date.patchValue(DayjsUtil.dayjsOrNull(this.creditNoteCustomer.date));
              } else {
                this.headerForm.controls.date.patchValue(DayjsUtil.dayjsOrNull(new Date()));
                this.creditNoteCustomer.date = new Date();
                this.initialCreditNoteCustomer.date = this.creditNoteCustomer.date;
              }
            })
          );
        } else if (invoiceCustomerId) {
          this.fetchInvoiceCustomer(invoiceCustomerId).subscribe(() => {
            const invoice = this.invoiceCustomerMap.get(+invoiceCustomerId);
            if (invoice.invoiceStatus !== InvoiceStatus.VALIDATED || invoice.fullyCreditNoted) {
              this.backToPrevious();
            }

            this.selectedInvoices = [invoice];
            this.initView(this.selectedInvoices);
            this.creditNoteCustomer.date = new Date();
            this.initialCreditNoteCustomer.date = this.creditNoteCustomer.date;
            this.headerForm.controls.date.patchValue(DayjsUtil.dayjsOrNull(this.creditNoteCustomer.date));
          });
        } else {
          this.openInvoiceCustomerSelectorPopup();
        }
      })
    );
  }

  public openInvoiceCustomerSelectorPopup(): void {
    if (this.invoiceCustomerMap && this.invoiceCustomerMap.values()) {
      this.selectedInvoices = Array.from(this.invoiceCustomerMap.values());
    }

    this.invoiceCustomerSelectorPopupVisible = true;
  }

  public closeInvoiceCustomerSelectorPopup(redirect: boolean = false): void {
    if (redirect) {
      this.backToPrevious();
    }
    this.invoiceCustomerSelectorPopupVisible = false;
  }

  public validateInvoiceCustomerSelectorPopup(invoiceCustomers: InvoiceCustomer[]): void {
    if (invoiceCustomers.length > 0) {
      this.initView(invoiceCustomers);
    }
    this.closeInvoiceCustomerSelectorPopup(invoiceCustomers.length === 0 && !!this.defaultCustomer);
  }

  public openCommentPopup(): void {
    this.headerForm.controls.comment.patchValue(this.creditNoteCustomer.comment);
    this.commentPopupVisible = true;
  }

  public saveComment(): void {
    this.creditNoteCustomer.comment = this.headerForm.controls.comment.value;
    this.closeCommentPopup();
  }

  public closeCommentPopup(): void {
    this.headerForm.controls.comment.patchValue("");
    this.commentPopupVisible = false;
  }

  public updateValidForm(): void {
    if (this.tableForm) {
      this.creditNoteCustomer.tabs.get(this.selectedTabId).valid = this.tableForm.valid;
    }
  }

  initCreditNoteCustomerVariables(creditNoteCustomer: CreditNoteCustomerFront): void {
    super.initCreditNoteCustomerVariables(creditNoteCustomer);
    this.initTabs();
    this.initialCreditNoteCustomer = new CreditNoteCustomerFront(this.creditNoteCustomer);
  }

  public submitCreditNoteCustomer(validate: boolean, print: boolean): void {
    const hasError = this.errorsCheck();

    if (hasError || !this.tableForm?.form?.valid) {
      this.tableForm.form.markAllAsTouched();
      return;
    }

    if (this.hasError) {
      return;
    }

    this.creditNoteCustomer.date = this.headerForm.value.date ? this.headerForm.value.date.toDate() : null;

    if (
      this.creditNoteCustomer?.lines?.size === 0 ||
      !this.isMapHasValue() ||
      this.creditNoteCustomer.totalPrice <= 0
    ) {
      this.generateNullOrNegativeErrorMessage();
      return;
    }

    if (
      this.initialCreditNoteCustomer &&
      this.initialCreditNoteCustomer.equals(this.creditNoteCustomer) &&
      this.router.url.includes("/update/") &&
      !validate
    ) {
      return;
    }

    this.callApi(validate, print);
  }

  isMapHasValue(): boolean {
    if (this.creditNoteCustomer.lines) {
      for (const value of this.creditNoteCustomer.lines.values() ?? []) {
        if (value.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  public isDirty(): boolean {
    if (!this.creditNoteCustomer) {
      return false;
    }
    this.creditNoteCustomer.date = this.headerForm.value.date.toDate();

    if (this.unsavedCreditNoteCustomer && !this.unsavedCreditNoteCustomer.equals(this.creditNoteCustomer)) {
      this.shouldClose = false;
    }

    if (
      (this.initialCreditNoteCustomer &&
        !this.initialCreditNoteCustomer.equals(this.creditNoteCustomer) &&
        !this.shouldClose) ||
      (!this.initialCreditNoteCustomer && !this.shouldClose)
    ) {
      this.unsavedCreditNoteCustomer = new CreditNoteCustomerFront(this.creditNoteCustomer);
      this.shouldClose = true;
      return true;
    }
    this.unsavedCreditNoteCustomer = null;
    this.shouldClose = false;
    return false;
  }

  getRealDiscountValue(row: CreditNoteCustomerLine): number | undefined {
    const invoiceCustomerLine: InvoiceCustomerLine = this.invoiceCustomerMap
      .get(this.selectedTabId)
      .lines[
        row.newDeliveryFormId ? row.newDeliveryFormId : 0
      ].find((line: InvoiceCustomerLine) => line.id === row.invoiceCustomerLineId);
    if (invoiceCustomerLine.discountType === DiscountType.VALUE) {
      return RoundingUtil.roundHigh(invoiceCustomerLine.discount);
    }
    return undefined;
  }

  getMaxValue(row: CreditNoteCustomerLine, property: string): number {
    return this.invoiceCustomerMap
      .get(this.selectedTabId)
      .lines[
        row.newDeliveryFormId ? row.newDeliveryFormId : 0
      ].find((line: InvoiceCustomerLine) => line.id === row.invoiceCustomerLineId)[property];
  }

  onTabClick(event?: any): void {
    if (event) {
      this.resetSelectedRows();
      this.selectedTabId = event.id;
      if (this.tabHandler) {
        this.tabHandler.changeTab(event);
      }
    }
    this.updateValidForm();
    this.buildOptions();
    this.initFilters();

    this.sessionPagination.loadFromSession(`${this.listId}-tab-${this.selectedTabId}`);
    this.applyFilters();
  }

  public onCloseTab(tabToDelete: TabComponent): void {
    this.deleteTabConfirmationPopupVisible = true;
    this.tabToDelete = tabToDelete;
  }

  public closeDeleteTabConfirmationPopup(): void {
    this.deleteTabConfirmationPopupVisible = false;
    this.tabToDelete = null;
  }

  public deleteTab(): void {
    this.deleteTabConfirmationPopupVisible = false;
    this.creditNoteCustomer.tabs.delete(+this.tabToDelete?.id);
    this.creditNoteCustomer.lines.delete(+this.tabToDelete?.id);
    this.invoiceCustomerMap.delete(+this.tabToDelete?.id);
    if (this.invoiceCustomers) {
      const index = this.invoiceCustomers.findIndex(
        (invoiceCustomer: InvoiceCustomer) => invoiceCustomer.id === +this.tabToDelete?.id,
        0
      );
      if (index > -1) {
        this.invoiceCustomers.splice(index, 1);
      }
    }
    if (this.creditNoteCustomer.tabs.size > 0 && this.tabToDelete?.active) {
      this.selectedTabId = Array.from(this.creditNoteCustomer.lines.keys()).sort(
        (a: number, b: number) => a - b
      )[0] as number;

      this.onTabClick();
    }
    this.computeTotals();
    this.resetSelectedRows();
  }

  public manageActions(actionId: number, row: CreditNoteCustomerLine): void {
    switch (actionId) {
      case this.DELETE_ACTION_ID:
        this.selectedRows = this.selectedRows.filter((rowSelected: CreditNoteCustomerLine) => {
          return rowSelected.lineNumber !== row.lineNumber;
        });
        this.removeLine(row);
        break;
      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  public removeLine(row: CreditNoteCustomerLine): void {
    this.creditNoteCustomer.lines.get(this.selectedTabId).splice(row.lineNumber - 1, 1);

    this.applyFilters();
    this.computeTotals();
    this.creditNoteCustomer.lines
      .get(this.selectedTabId)
      .forEach((element: CreditNoteCustomerLine, index: number) => (element.lineNumber = index + 1));
  }

  public onHeaderCheckboxChange(): void {
    const headerValue = this.creditNoteCustomer.tabs.get(this.selectedTabId).headerCheck;
    this.creditNoteCustomer.tabs.get(this.selectedTabId).filteredLines.forEach((row: any) => {
      row.actionCheckboxChecked = headerValue;
    });
    this.selectedRows = headerValue
      ? [...this.creditNoteCustomer.tabs.get(this.selectedTabId).filteredLines]
      : this.selectedRows.filter((row: any) => {
        return !this.creditNoteCustomer.tabs.get(this.selectedTabId).filteredLines.includes(row);
      });
  }

  public onRowCheckboxChange(): void {
    this.selectedRows = this.creditNoteCustomer.lines
      .get(this.selectedTabId)
      .filter((row: CreditNoteCustomerLine) => row.actionCheckboxChecked);

    this.creditNoteCustomer.tabs.get(this.selectedTabId).headerCheck =
      this.selectedRows.length === this.creditNoteCustomer.lines.get(this.selectedTabId).length;
  }

  public removeLines(): void {
    this.selectedRows.forEach((row: any) => this.removeLine(row));
    this.resetSelectedRows();
  }

  public callApi(validate: boolean, print: boolean): void {
    if (!this.creditNoteCustomer) {
      return;
    }
    const invoiceCustomer: InvoiceCustomer = this.creditNoteCustomer.toInvoiceCustomer();
    const action = invoiceCustomer && invoiceCustomer.id ? "update" : "create";
    this.subscriptionService.subs.push(
      this.invoiceCustomerService[action].call(this.invoiceCustomerService, invoiceCustomer).subscribe(
        responseInvoiceCustomer => {
          this.sessionPagination.saveToSession(this.listId);
          if (validate) {
            this.validateCreditNote(print, responseInvoiceCustomer.id);
          } else {
            this.successMsg();
            this.initialCreditNoteCustomer = new CreditNoteCustomerFront(this.creditNoteCustomer);
            if (action === "create") {
              this.router.navigateByUrl(`/credit-note-customer/update/${responseInvoiceCustomer.id}`);
            } else if (responseInvoiceCustomer.invoiceStatus === InvoiceStatus.VALIDATED) {
              this.router.navigateByUrl(`/credit-note-customer-detail/${responseInvoiceCustomer.id}`);
            }
          }
        },
        (error: any) => {
          this.handleApiError(error);
        }
      )
    );
  }

  public handleApiError(error: any): void {
    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  openCreditNoteValidationPopup(): void {
    const hasError = this.errorsCheck();

    if (this.creditNoteCustomer.totalPrice <= 0) {
      this.sendErrorValidate(
        this.translateService.instant("invoice-customer-form.errors.open-validate-pop-up"),
        "error"
      );
      return;
    }
    if (hasError || !this.tableForm?.form?.valid) {
      return;
    }
    this.validatePopupVisible = true;
  }

  closeCreditNoteValidation(): void {
    this.validatePopupVisible = false;
  }

  validateCreditNoteEvent(print: boolean): void {
    this.submitCreditNoteCustomer(true, print);
  }

  validateCreditNote(_print: boolean, invoiceId: number): void {
    this.invoiceCustomerService.validate(invoiceId).subscribe(
      () => {
        const content = this.translateService.instant("message.content.save-success");
        const title = this.translateService.instant("message.title.save-success");
        this.messageService.success(content, { title });
        this.initialCreditNoteCustomer = new CreditNoteCustomerFront(this.creditNoteCustomer);
        this.backToPrevious();
      },
      error => {
        if (
          error?.error?.exception.name === CreditNoteCustomerFormComponent.CARASQL_EXCEPTION &&
          error?.error?.exception.parameters.constraint === CreditNoteCustomerFormComponent.EXCEPTION_CONSTRAINT
        ) {
          if (this.router.url.includes("/credit-note-customer/add")) {
            this.router.navigate([`/credit-note-customer/update/${this.creditNoteCustomer.id}`]);
          }
          return;
        }
        this.hasError = true;
        this.handleApiError(error);
      }
    );
  }

  private fetchInvoiceCustomer(id: number): Observable<InvoiceCustomer> {
    return this.invoiceCustomerService.get(id).pipe(
      tap(
        (invoiceCustomer: InvoiceCustomer) => {
          if (!this.invoiceCustomerMap.get(invoiceCustomer.id)) {
            this.invoiceCustomerMap.set(invoiceCustomer.id, invoiceCustomer);
          }
        },
        error => {
          this.fetcher.sendErrorAlert("invoice-customer-form.errors.get-delivery-forms", error.message);
        }
      )
    );
  }

  private initView(invoiceCustomers: InvoiceCustomer[]): void {
    this.initInvoiceCustomersVariables(invoiceCustomers);
    this.setDefaultCustomer();
    if (!this.creditNoteCustomer) {
      const newCreditNoteCustomer: CreditNoteCustomerFront = new CreditNoteCustomerFront(invoiceCustomers);
      this.initCreditNoteCustomerVariables(newCreditNoteCustomer);
    } else {
      this.creditNoteCustomer.addInvoiceCustomers(invoiceCustomers);
      this.initTabs();
    }

    this.headerForm.controls.date.patchValue(
      this.creditNoteCustomer.date
        ? DayjsUtil.dayjsOrNull(this.creditNoteCustomer.date)
        : DayjsUtil.dayjsOrNull(new Date())
    );
  }

  // no lines present, cannot save or validate
  private generateNullOrNegativeErrorMessage(): void {
    const title = this.translateService.instant("invoice-customer-form.errors.saving-invoice");
    const currency = this.currency.symbol;
    const content = this.translateService.instant("invoice-customer-form.errors.null-or-negative", { currency });
    this.messageService.error(content, { title });
  }

  private addMenuActions(): void {
    this.menuActions = [];
    this.menuActions.push(
      new MenuAction(
        this.DELETE_ACTION_ID,
        this.translateService.instant("invoice-customer-form.actions.delete"),
        this.faTrash
      )
    );
  }

  private resetSelectedRows(): void {
    this.creditNoteCustomer.tabs.get(this.selectedTabId).headerCheck = false;
    this.onHeaderCheckboxChange();
    this.creditNoteCustomer.lines.get(this.selectedTabId).forEach((row: any) => {
      row.actionCheckboxChecked = false;
    });
    this.selectedRows = [];
  }

  private successMsg(): void {
    const content = this.translateService.instant("message.content.save-success");
    const title = this.translateService.instant("message.title.save-success");
    this.messageService.success(content, { title });
  }

  private sendErrorValidate(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("invoice-customer-form.errors.title");
    this.messageService.error(content, { title });
  }

  private errorsCheck(): boolean {
    let hasError = false;
    this.creditNoteCustomer.tabs.forEach((tab: CreditNoteCustomerTab) => {
      if (tab.valid !== undefined && !tab.valid) {
        hasError = true;
        return;
      }
    });
    return hasError;
  }
}
