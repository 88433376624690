<ng-container>
  <div class="pending-delivery-choice-popup-container" [formGroup]="popupForm" novalidate>
    <ui-popup title="{{ 'pending-delivery-choice-popup.title' | translate }}" (close)="closePopup()">
      <div content>
        <div class="choice-label">{{ "pending-delivery-choice-popup.labels.choice" | translate }}</div>
        <ui-radio-button [options]="this.choiceOptions" name="choice" formControlName="choice"></ui-radio-button>
      </div>
      <div footer>
        <div class="button-popup-to-receiving-form">
          <ui-button
            text="{{ 'pending-delivery-choice-popup.labels.button' | translate }}"
            [primary]="true"
            (click)="submit()"
            type="validate"
          ></ui-button>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
