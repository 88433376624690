import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService, Tag as TagComponent } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import {
  Store,
  Country,
  StoreBrand,
  PricingGroup,
  StoreStatusType,
  TagService,
  CountryService,
  CaraUserService,
  CompanyConfigService,
  LightService,
  StoreBrandService,
  PricingGroupService,
  CaraUser,
  LightCustomer,
  CompanyConfig,
  Address,
  Tag,
} from "center-services";

@Component({
  selector: "app-general-datas",
  templateUrl: "./general-datas.component.html",
  styleUrls: ["./general-datas.component.scss"],
  providers: [SubscriptionService],
})
export class GeneralDatasComponent implements OnInit, OnChanges {
  @Input() editedStore: Store;

  public sectorSupervisorOptions: Option[] = [];

  public allStoreTags: Tag[];
  public availableTags: TagComponent[] = [];
  public tagListStatus: string;
  public tagListErrorMessage: string;

  public countries: Country[];
  public countryOptions: Option[] = [];

  public defaultCustomerId: string;
  public defaultPricingGroupId: string;
  public preselectedCustomer: Option;
  public customerOptions: Option[] = [];
  public customerListErrorMessage: string;
  public customerListStatus: string;

  public storeBrands: StoreBrand[];
  public storeBrandOptions: Option[] = [];
  public storeBrandListErrorMessage: string;
  public storeBrandListStatus: string;

  public pricingGroups: PricingGroup[];
  public pricingGroupOptions: Option[] = [];
  public pricingGroupListErrorMessage: string;
  public pricingGroupListStatus: string;

  public storeStatusType: StoreStatusType;
  public storeStatusTypeOptions: Option[] = [];

  public storeForm: UntypedFormGroup;
  public pattern: any = {
    P: {
      pattern: new RegExp("[+]|[0-9]"),
      optional: true,
    },
    N: {
      pattern: new RegExp("\\d"),
    },
  };
  private initObservables: Observable<any>[];

  constructor(
    private translateService: TranslateService,
    private tagService: TagService,
    private countryService: CountryService,
    private caraUserService: CaraUserService,
    private companyConfigService: CompanyConfigService,
    private lightService: LightService,
    private storeBrandService: StoreBrandService,
    private pricingGroupService: PricingGroupService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) {
    this.defaultCustomerId = this.route.snapshot.queryParams.defaultCustomerId;
    this.defaultPricingGroupId = this.route.snapshot.queryParams.defaultPricingGroupId;
    this.prepareForm();
  }

  ngOnInit(): void {
    this.initObservables = [];

    this.initObservables.push(this.fetchTags());
    this.initObservables.push(this.fetchUsers());
    this.initObservables.push(this.fetchCountries());
    this.initObservables.push(this.fetchCustomers());
    this.initObservables.push(this.fetchStoreBrands());
    this.initObservables.push(this.fetchPricingGroups());

    this.buildStatusOptions();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildStatusOptions();
      })
    );

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedStore) {
          this.loadEditedData();
        }
      })
    );
  }

  ngOnChanges(): void {
    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedStore) {
          this.loadEditedData();
        }
      })
    );
  }

  prepareForm(): void {
    this.storeForm = this.fb.group({
      name: [null, [Validators.required]],
      reference: [null, [Validators.required]],
      coordinates: [null],
      phone: [null],
      email: [null, [Validators.email]],
      status: [null, [Validators.required]],
      siret: [null, [Validators.required, Validators.pattern("[0-9]{14}")]],
      comment: [null],
      lines: [null, [Validators.required]],
      city: [null, [Validators.required]],
      cityCode: [null, [Validators.required]],
      country: [null, [Validators.required]],
      storeBrand: [null, [Validators.required]],
      pricingGroup: [this.defaultPricingGroupId ? this.defaultPricingGroupId : null, [Validators.required]],
      customerId: [null],
      sectorSupervisorId: [null],
      tags: [[]],
    });
  }

  fetchTags(): Observable<Tag[]> {
    return this.tagService.getAllTagsOfCategoryByName("Store").pipe(
      tap(
        (tags: Tag[]) => {
          this.allStoreTags = tags;
          this.availableTags = tags
            .filter((obj: Tag) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Tag) => new TagComponent(obj.id, obj.name, obj.color));
        },
        () => {
          const content = this.translateService.instant("tags-list.errors.get-tags");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchUsers(): Observable<CaraUser[]> {
    return this.caraUserService.getAll().pipe(
      tap(
        (users: CaraUser[]) => {
          this.sectorSupervisorOptions = users
            .filter((obj: CaraUser) => !obj.archived)
            .sort((a, b) => a.firstName.localeCompare(b.firstName) && a.lastName.localeCompare(b.lastName))
            .map((obj: CaraUser) => new Option(obj.id, `${obj.firstName} ${obj.lastName}`));

          if (this.editedStore && this.editedStore.sectorSupervisorId) {
            this.setDefaultSectorSupervisor();
          }
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("cara-users-list.errors.get-cara-users");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCountries(): Observable<Country[]> {
    return this.countryService.getAll().pipe(
      tap(
        (countries: Country[]) => {
          this.countries = countries;
          countries.forEach((country: Country) => {
            if (country.byDefault && (!this.editedStore || !this.editedStore.id)) {
              this.storeForm.controls.country.setValue(country.id);
            }
          });
          this.countryOptions = countries
            .filter((obj: Country) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Country) => new Option(obj.id, obj.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("countries-list.errors.get-countries");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  addCompany(): void {
    this.subscriptionService.subs.push(
      this.companyConfigService.get().subscribe(
        (companyConfig: CompanyConfig) => {
          this.customerOptions.unshift(new Option(null, `${companyConfig.name}`));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("company-config-list.errors.get-company-config");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchStoreBrands(): Observable<StoreBrand[]> {
    return this.storeBrandService.getAll().pipe(
      tap(
        (storeBrands: StoreBrand[]) => {
          this.storeBrands = storeBrands;
          this.storeBrandOptions = storeBrands
            .filter((obj: StoreBrand) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: StoreBrand) => new Option(obj.id, obj.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("store-brands-list.errors.get-store-brands");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchPricingGroups(): Observable<PricingGroup[]> {
    return this.pricingGroupService.getAll().pipe(
      tap(
        (pricingGroups: PricingGroup[]) => {
          this.pricingGroups = pricingGroups;
          this.pricingGroupOptions = pricingGroups
            .filter((obj: PricingGroup) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: PricingGroup) => new Option(obj.id, obj.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("store-brands-list.errors.get-pricing-groups");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  buildStatusOptions(): void {
    this.storeStatusTypeOptions = Object.keys(StoreStatusType).map(
      (key, index) => new Option(index, this.translateService.instant(`store.general-datas.status-options.${key}`))
    );
  }

  setDefaultSectorSupervisor(): void {
    const opt = this.sectorSupervisorOptions.find((userOption: Option) => {
      return userOption.id === this.editedStore.sectorSupervisorId;
    });
    if (opt) {
      this.storeForm.controls.sectorSupervisorId.setValue(this.editedStore.sectorSupervisorId);
    }
  }

  loadEditedData(): void {
    this.storeForm.controls.name.setValue(this.editedStore.name);
    this.storeForm.controls.reference.setValue(this.editedStore.reference);
    this.storeForm.controls.coordinates.setValue(this.editedStore.coordinates);
    this.storeForm.controls.phone.setValue(this.editedStore.phone);
    this.storeForm.controls.email.setValue(this.editedStore.email);
    this.storeForm.controls.siret.setValue(this.editedStore.siret);
    this.storeForm.controls.comment.setValue(this.editedStore.comment);
    this.storeForm.controls.customerId.setValue(this.editedStore.customerId);
    if (this.editedStore.address) {
      this.storeForm.controls.lines.setValue(this.editedStore.address.lines);
      this.storeForm.controls.city.setValue(this.editedStore.address.city);
      this.storeForm.controls.cityCode.setValue(this.editedStore.address.cityCode);
      if (this.editedStore.address.countryId) {
        this.storeForm.controls.country.setValue(this.editedStore.address.countryId);
      }
    }
    if (this.editedStore.sectorSupervisorId && this.sectorSupervisorOptions) {
      this.setDefaultSectorSupervisor();
    }
    const selectedStoreBrand = this.storeBrands.find(storeBrand => storeBrand.id === this.editedStore.storeBrandId);
    if (selectedStoreBrand && !selectedStoreBrand.archived) {
      this.storeForm.controls.storeBrand.setValue(selectedStoreBrand.id);
    }
    const selectedPricingGroup = this.pricingGroups.find(
      pricingGroup => pricingGroup.id === this.editedStore.pricingGroupId
    );
    if (selectedPricingGroup && !selectedPricingGroup.archived) {
      this.storeForm.controls.pricingGroup.setValue(selectedPricingGroup.id);
    }

    // handle status
    if (this.editedStore.status) {
      const index = Object.keys(StoreStatusType).indexOf(this.editedStore.status);
      this.storeForm.controls.status.setValue(index);
      if (this.editedStore && this.editedStore.mainStore) {
        this.storeForm.controls.status.disable();
      }
    }

    this.storeForm.controls.tags.setValue([...this.editedStore.tagIds]);
  }

  applyModifications(): void {
    // edit store with form controls
    const cloneAddress = new Address(this.editedStore.address);
    cloneAddress.lines = this.storeForm.value.lines;
    cloneAddress.city = this.storeForm.value.city;
    cloneAddress.cityCode = this.storeForm.value.cityCode;
    cloneAddress.countryId = this.storeForm.value.country;
    cloneAddress.byDefault = true;

    this.editedStore.name = this.storeForm.value.name;
    this.editedStore.reference = this.storeForm.value.reference;
    this.editedStore.coordinates = this.storeForm.value.coordinates;
    this.editedStore.phone = this.storeForm.value.phone;
    this.editedStore.email = this.storeForm.value.email;
    this.editedStore.siret = this.storeForm.value.siret;
    this.editedStore.comment = this.storeForm.value.comment;
    this.editedStore.address = cloneAddress;
    this.editedStore.sectorSupervisorId = this.storeForm.value.sectorSupervisorId;
    this.editedStore.customerId = this.storeForm.value.customerId;
    this.editedStore.storeBrandId = this.storeForm.value.storeBrand;
    this.editedStore.pricingGroupId = this.storeForm.value.pricingGroup;
    this.editedStore.status = Object.values(StoreStatusType)[this.storeForm.getRawValue().status];
    this.editedStore.tagIds = this.storeForm.value.tags;
  }

  updateStore(): boolean {
    if (this.storeForm.invalid) {
      this.storeForm.markAllAsTouched();
      return false;
    }

    this.applyModifications();
    return true;
  }

  private fetchCustomers(): Observable<LightCustomer[]> {
    return this.lightService.getCustomers().pipe(
      tap(
        (lightCustomers: LightCustomer[]) => {
          this.customerOptions = lightCustomers
            .filter(obj => !obj.archived && obj.affiliate)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.name}`));

          if (this.editedStore && !this.editedStore.id) {
            const customerId = this.defaultCustomerId
              ? (this.customerOptions.find(c => c.id === +this.defaultCustomerId).id as number)
              : null;
            this.storeForm.controls.customerId.setValue(customerId);
            this.editedStore.customerId = customerId;
          }
          this.addCompany();
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-initiator-popup.errors.get-customers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }
}
