import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  AbstractItem,
  Pagination,
  ItemCategory,
  Currency,
  Light,
  LightService,
  CurrencyService,
  CaraUserService,
  RetailItemService,
  ItemCategoryService,
  ItemStatusType,
  CategoryType,
  PaginatedList,
  PurchaseModality,
  Sort,
} from "center-services";
import {
  Filterer,
  FilterValue,
  Option,
  PaginableComponent,
  RoundingUtil,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-item-list",
  templateUrl: "./item-list.component.html",
  styleUrls: ["./item-list.component.scss"],
  providers: [SubscriptionService],
})
export class ItemListComponent extends FilteredTableListComponent implements OnInit, OnDestroy, PaginableComponent {
  @ViewChild("table") table: any;

  @Input() listId: string;
  @Input() activeFilters: SearchFilter[] = [];
  @Input() selection: boolean = false;
  @Input() defaultSelection: AbstractItem = null;
  @Input() sorts: any[] = [];
  @Input() isStandardItemFilterOn: boolean = false;

  @Output() activeFiltersChange: EventEmitter<SearchFilter[]> = new EventEmitter<SearchFilter[]>();
  @Output() itemSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();

  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public rows: any[] = [];
  public retailItemList: AbstractItem[] = [];
  public filterer: Filterer;
  public itemForm: UntypedFormControl;
  public locale: string;
  private initObservables: Observable<any>[];
  private statusTranslations: { [key: string]: string } = {};
  private itemCategoryList: ItemCategory[] = [];
  private currencyList: Currency[] = [];
  private selectedItem: AbstractItem;
  private brandList: Light[] = [];
  private sessionPagination: SessionPagination;

  private currency: Currency;

  constructor(
    private lightService: LightService,
    protected messageService: MessageService,
    private currencyService: CurrencyService,
    protected translateService: TranslateService,
    protected userService: CaraUserService,
    private retailItemService: RetailItemService,
    private itemCategoryService: ItemCategoryService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.itemForm = new UntypedFormControl(null);
    EnumTranslationUtil.buildTranslations(
      this.translateService,
      ItemStatusType,
      "retail-item-list.datatable.status",
      this.statusTranslations
    );

    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  public ngOnInit(): void {
    this.initObservables = [];
    if (this.selection) {
      this.prepareItemForm();
    }
    this.initObservables.push(this.fetchBrandList());
    this.initObservables.push(this.fetchCurrencyList());
    this.initObservables.push(this.fetchItemCategoryList());

    this.locale = this.userService.connectedUser.value.codeLanguage;

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.currency = this.currencyList.find(currency => {
          return currency.byDefault;
        });
        this.initFilters();
        this.sessionPagination.loadFromSession(this.listId);
        this.computeSearchFilters();
        this.fetchRetailItemList();
      })
    );

    if (!this.selectedItem && this.defaultSelection) {
      this.selectedItem = this.defaultSelection;
      this.itemForm.patchValue(this.selectedItem.id);
    }
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      this.sessionPagination.saveToSession(this.listId);
    }
  }

  public applyFilters(): void {
    this.activeFilters = [];
    // reset current page
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.listId
      ).subscribe(() => {
        this.fetchRetailItemList();
        this.activeFiltersChange.emit(this.activeFilters);
      })
    );
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchRetailItemList();
  }

  public changeSortSettings(prop: string, dir: string): void {
    switch (prop) {
      case "status":
        this.sorts = [{ prop: "status", dir }];
        break;
      default:
        this.sorts = [
          { prop: "status", dir: "asc" },
          { prop, dir },
        ];
        break;
    }

    this.fetchRetailItemList();
  }

  public catchEvent(event: any): void {
    if (event.type === "click") {
      const filteredList = this.retailItemList.filter(retailItem => retailItem.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find item with id ${event.row.id}`);
        return;
      }
      this.selectedItem = this.retailItemList.find(item => item.id === event.row.id);
      this.itemClick.emit(this.selectedItem);
      this.itemForm.patchValue(event.row.id);
    }
  }

  public getCurrency(row: any): Currency {
    return this.currencyList.find(currency => currency.id === row.currencyId);
  }

  private prepareItemForm(): void {
    this.subscriptionService.subs.push(
      this.itemForm.valueChanges.subscribe(() => {
        this.itemSelect.emit(this.selectedItem);
      })
    );
  }

  private fetchBrandList(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (lightBrands: Light[]) => (this.brandList = lightBrands),
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-brands", error.message);
        }
      )
    );
  }

  private fetchCurrencyList(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => (this.currencyList = currencies),
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-currencies", error.message);
        }
      )
    );
  }

  private fetchItemCategoryList(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll(["withRetailItem"]).pipe(
      tap(
        (itemCategories: ItemCategory[]) => (this.itemCategoryList = itemCategories),
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-item-categories", error.message);
        }
      )
    );
  }

  private fetchRetailItemList(): void {
    // choose only standardItem or abstractItem depending on where the component is used
    const cloneActiveFilters = [...this.activeFilters];
    if (this.isStandardItemFilterOn) {
      cloneActiveFilters.push(new SearchFilter("category.type", SearchFilterOperator.EQUAL, CategoryType.STANDARD));
    }

    this.subscriptionService.subs.push(
      this.retailItemService.getAll(this.pager, this.getSorter(), cloneActiveFilters).subscribe(
        (result: PaginatedList<AbstractItem>) => {
          this.rows = [];
          this.pager = result.page;
          this.retailItemList = result.data;

          result.data.forEach((item: AbstractItem) => this.addRow(item));
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
        }
      )
    );
  }

  private addRow(retailItem: AbstractItem): void {
    let statusLabel;
    if (retailItem && retailItem.status) {
      statusLabel = this.translateService.instant(`retail-item-list.datatable.status.${retailItem.status}`);
    }
    const selectOption = [new Option(retailItem.id, null)];

    const row = {
      statusLabel,
      selectOption,
      references: [],
      purchasePrice: [],
      id: retailItem.id,
      code: retailItem.reference,
      brand: retailItem.brandName,
      brandId: retailItem.brandId,
      designation: retailItem.name,
      category: retailItem.categoryName,
      categoryId: retailItem.categoryId,
      sellPrice: retailItem.sellPrice ?? 0,
      currencyId: retailItem.sellCurrencyId,
      statusClass: retailItem.status ? this.getStatusClass(retailItem.status) : null,
    };
    if (retailItem.purchaseModalities && retailItem.purchaseModalities.length !== 0) {
      this.getPurchaseModalitiesData(row, retailItem.purchaseModalities);
    }
    this.rows.push(row);
  }

  private getPurchaseModalitiesData(row: any, purchaseModalities: PurchaseModality[]): void {
    const supplierRefPrices: Map<string, string[]> = new Map();

    purchaseModalities.forEach((pm: PurchaseModality) => {
      let price = null;
      if (pm.defaultCurrencyComputedPrice !== null) {
        price = `${pm.defaultCurrencyComputedPrice.toFixed(RoundingUtil.TWO_DIGITS)} ${this.currency.symbol}`;
      } else {
        price = `${pm.unitPrice.toFixed(RoundingUtil.TWO_DIGITS)} ${this.currency.symbol}`;
      }
      const ref = pm.supplierRef ? pm.supplierRef : "[Ø]";
      if (!row.references.includes(ref) && pm.supplierRef) {
        row.references.push(ref);
      }
      supplierRefPrices.has(ref) ? supplierRefPrices.get(ref).push(price) : supplierRefPrices.set(ref, [price]);
    });
    supplierRefPrices.forEach((value, key) => {
      row.purchasePrice.push(`${key}:  ${value.join(", ")}`);
    });
    row.purchasePrice.sort((a, b) => a.localeCompare(b));
    row.references.sort((a, b) => a.localeCompare(b));
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  private getStatusClass(status: ItemStatusType): string {
    return `status-${status.toLowerCase()}`;
  }

  private propToDto(prop: string): string {
    switch (prop) {
      case "category":
        return "categoryName";
      case "brand":
        return "brandName";
      case "code":
        return "reference";
      case "designation":
        return "name";
      default:
        return prop;
    }
  }

  private initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.listId
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    // filter only on standarItem or not
    if (this.isStandardItemFilterOn) {
      this.filterer.addListFilter(
        "category.id",
        this.translateService.instant("retail-item-list.datatable.columns.category"),
        this.itemCategoryList
          .filter(cat => cat.type === CategoryType.STANDARD)
          .map(categ => {
            return { value: categ.id.toString(), displayValue: categ.name };
          })
      );
    } else {
      this.filterer.addListFilter(
        "category.id",
        this.translateService.instant("retail-item-list.datatable.columns.category"),
        this.itemCategoryList.map(categ => {
          return { value: categ.id.toString(), displayValue: categ.name };
        })
      );
    }

    this.filterer.addFilter(
      "reference",
      this.translateService.instant("retail-item-list.datatable.columns.code"),
      "string"
    );
    this.filterer.addFilter(
      "name",
      this.translateService.instant("retail-item-list.datatable.columns.designation"),
      "string"
    );
    this.filterer.addFilter(
      "purchaseModalities.supplierRef",
      this.translateService.instant("retail-item-list.datatable.columns.references"),
      "string"
    );
    this.filterer.addListFilter(
      "brand.id",
      this.translateService.instant("retail-item-list.datatable.columns.brand"),
      this.brandList
        .map(brand => {
          return { value: brand.id.toString(), displayValue: brand.name };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      null,
      null,
      null,
      null,
      true
    );
    this.filterer.addFilter(
      "sellPrice",
      this.translateService.instant("retail-item-list.datatable.columns.sell-price"),
      "range"
    );
    this.filterer.addListFilter(
      "status",
      this.translateService.instant("retail-item-list.datatable.columns.status"),
      Object.keys(ItemStatusType).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }
}
