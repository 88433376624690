import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IconDefinition,
  faArrowCircleRight,
  faExclamationCircle,
  faSearch,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  PurchaseOrder,
  Light,
  Pagination,
  ItemPurchaseModality,
  Currency,
  ItemCategory,
  LightService,
  PurchaseOrderService,
  CaraUserService,
  ItemPurchaseModalityService,
  ItemCategoryService,
  CurrencyService,
  SupplierService,
  AppConfigService,
  PurchaseOrderType,
  PurchaseType,
  ItemStatusType,
  Supplier,
  CaraUser,
  PaginatedList,
  Sort,
  PurchaseOrderStatus,
  AppConfig,
  ReceptionType,
  AuthService,
} from "center-services";
import {
  Filter,
  Filterer,
  FilterValue,
  Option,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { combineLatest, merge, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { DeliveryPopup } from "./delivery-popup";

@Component({
  selector: "app-delivery-initiator-popup",
  templateUrl: "./delivery-initiator-popup.component.html",
  styleUrls: ["./delivery-initiator-popup.component.scss"],
  providers: [SubscriptionService],
})
export class DeliveryInitiatorPopupComponent extends FilteredTableListComponent implements OnInit, PaginableComponent {
  @ViewChild("table") table: any;
  @ViewChild("freeTable") freeTable: any;

  @Output() close: EventEmitter<any> = new EventEmitter();

  public LIST_MANUAL_ID: string = "app-delivery-initiator-popup.manual-table";
  public LIST_FREE_ID: string = "app-delivery-initiator-popup.free-table";

  public isNew: boolean;
  public popupTitle: string;
  public buttonLabel: string;
  public deliveryTypeLabel: string;
  public popupForm: UntypedFormGroup;
  public orderLinkedOptions: Option[] = [];
  public orderKnownOptions: Option[] = [];
  public currencyOptions: Option[];
  public senderOptions: Option[];
  public receiverOptions: Option[];
  public orderRefForm: UntypedFormArray;
  public purchaseOrderList: PurchaseOrder[] = [];
  public submitedPurchaseOrderList: PurchaseOrder[] = [];
  public fetchedPurchaseOrderList: PurchaseOrder[] = [];
  public shouldClose: boolean = false;
  public supplierList: Light[] = [];
  public storeList: Light[] = [];
  public brandList: Light[] = [];
  public orderTypeOptions: Option[] = [];
  public statusOptions: Option[] = [];
  public contextStoreId: number;
  public rows: any[] = [];
  public sorts: any[] = [{ prop: "createdAt", dir: "desc" }];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public initialDeliveryPopup: DeliveryPopup;
  public unsavedDeliveryPopup: DeliveryPopup;
  public selectedDeliveryPopup: DeliveryPopup;
  public locale: string;
  public dateFormat: string;
  public selectedPurchaseOrderList: PurchaseOrder[] = [];
  faTrashAlt: IconDefinition = faTrashAlt;
  faArrowCircleRight: IconDefinition = faArrowCircleRight;
  faExclamationCircle: IconDefinition = faExclamationCircle;
  faSearch: IconDefinition = faSearch;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public itemPurchaseModalityList: ItemPurchaseModality[] = [];
  public submitedItemPurchaseModalityList: ItemPurchaseModality[] = [];
  public freePager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });

  // --------------------------- FREE DELIVERY ATTRIBUTES --------------------------------
  public freeActiveFilters: SearchFilter[] = [];
  public selectedElementsNumber: number = 0;
  public currencyList: Currency[];
  public codeLanguage: string;
  public receptionType: string;
  public freeSorts: any[] = [
    {
      prop: "statusLabel",
      dir: "asc",
    },
    {
      prop: "supplierRef",
      dir: "asc",
    },
  ];
  public filters: Filter[];
  public freeFilterer: Filterer;
  public freeRows: any[] = [];
  public form: UntypedFormGroup;
  public selectedItemPurchaseModalityList: ItemPurchaseModality[] = [];
  private orderTypeTranslations: { [key: string]: string } = {};
  private statusTranslations: { [key: string]: string } = {};
  private initObservables: Observable<any>[] = [];
  private currentRowsId: number[] = [];
  private categoryList: ItemCategory[];
  private purchaseTypeTranslations: { [key: string]: string } = {};
  private itemStatusTranslations: { [key: string]: string } = {};

  // ----------------------- END OF FREE DELIVERY ATTRIBUTES ---------------------------

  constructor(
    private fb: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private lightService: LightService,
    private purchaseOrderService: PurchaseOrderService,
    private router: Router,
    protected userService: CaraUserService,
    private itemPurchaseModalityService: ItemPurchaseModalityService,
    private itemCategoryService: ItemCategoryService,
    private currencyService: CurrencyService,
    private supplierService: SupplierService,
    private appConfigService: AppConfigService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);

    // to remove whith the big cleaning
    this.isNew = this.route.snapshot.routeConfig.path === "receiving-list";

    this.buildOrderTypeTranslations();
    this.buildStatusTranslations();
    this.buildPurchaseTypeTranslations();
    this.buildItemStatusTranslations();
    this.prepareForm();
  }

  buildOrderTypeTranslations(): void {
    EnumTranslationUtil.buildTranslations(
      this.translateService,
      PurchaseOrderType,
      "delivery-form-list.order-type-options",
      this.orderTypeTranslations
    );
  }

  buildStatusTranslations(): void {
    this.statusTranslations.SENT = this.translateService.instant("delivery-initiator-popup.status-options.SENT");
    this.statusTranslations.PARTIALLY_RECEIVED = this.translateService.instant(
      "delivery-initiator-popup.status-options.PARTIALLY_RECEIVED"
    );
  }

  buildPurchaseTypeTranslations(): void {
    EnumTranslationUtil.buildTranslations(
      this.translateService,
      PurchaseType,
      "purchase-modality-selection.datatable.purchase-type",
      this.purchaseTypeTranslations
    );
  }

  buildItemStatusTranslations(): void {
    EnumTranslationUtil.buildTranslations(
      this.translateService,
      ItemStatusType,
      "purchase-modality-selection.status-options",
      this.itemStatusTranslations
    );
  }

  getPageNumber(_listId: string): number {
    return _listId === this.LIST_MANUAL_ID ? this.pager.number : this.freePager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return _listId === this.LIST_MANUAL_ID ? this.filterer.filterValues : this.freeFilterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return _listId === this.LIST_MANUAL_ID ? this.sorts : this.freeSorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    _listId === this.LIST_MANUAL_ID ? (this.pager.number = pageNumber) : (this.freePager.number = pageNumber);
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    _listId === this.LIST_MANUAL_ID
      ? (this.filterer.filterValues = [...filters])
      : (this.freeFilterer.filterValues = [...filters]);
  }

  setSorts(_listId: string, sorts: any[]): void {
    _listId === this.LIST_MANUAL_ID ? (this.sorts = [...sorts]) : (this.freeSorts = [...sorts]);
  }

  ngOnInit(): void {
    this.initSelectFormControl();

    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchBrands());
    this.initObservables.push(this.fetchCurrencies());
    this.initObservables.push(this.fetchCategories());
    this.initObservables.push(this.fetchCodeLanguage());
    this.initObservables.push(this.fetchReceptionType());
    this.contextStoreId = this.authService.getContextStoreId();

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.manageReceptionTypeTrad();

        this.buildOrderLinkedOptions();
        this.buildOrderKnownOptions();
        this.subscriptionService.subs.push(
          this.translateService.onLangChange.subscribe(() => {
            this.buildOrderLinkedOptions();
            this.buildOrderKnownOptions();
          })
        );
        this.initializePopup();
      })
    );
  }

  prepareOrderKnownForm(): void {
    if (this.orderRefForm.controls.length === 0) {
      this.addOrderRef();
    }
  }

  prepareOrderUnknownForm(): void {
    this.removeAllOrderRef();
  }

  prepareFreeForm(): void {
    this.removeAllOrderRef();
  }

  prepareForm(): void {
    this.orderRefForm = new UntypedFormArray([]);
    this.popupForm = this.fb.group({
      orderLinked: [0, [Validators.required]],
      orderKnown: [0, [Validators.required]],
      currency: [null, [Validators.required]],
      sender: [null, [Validators.required]],
      receiver: [null, [Validators.required]],
      refs: this.orderRefForm,
    });

    this.subscriptionService.subs.push(
      merge(
        this.popupForm.controls.orderKnown.valueChanges,
        this.popupForm.controls.orderLinked.valueChanges
      ).subscribe(() => {
        this.resetFormControlErrors();
        if (this.popupForm.controls.orderLinked.value === 1) {
          this.prepareFreeForm();
          this.fetchItemPurchaseModalities();
        } else {
          if (this.popupForm.controls.orderKnown.value === 0) {
            this.prepareOrderKnownForm();
          } else {
            this.prepareOrderUnknownForm();
            if (this.popupForm.controls.sender.value && this.popupForm.controls.receiver.value) {
              this.fetchPurchaseOrderList();
            }
          }
        }
      })
    );

    this.subscriptionService.subs.push(
      this.popupForm.controls.sender.valueChanges.subscribe(() => {
        // set the supplier's currency when the supplier changes and the currency hasn't been set yet
        if (this.popupForm.controls.sender.value !== null && this.popupForm.controls.currency.value === null) {
          this.subscriptionService.subs.push(
            this.supplierService.get(this.popupForm.controls.sender.value).subscribe((supplier: Supplier) => {
              this.popupForm.controls.currency.setValue(supplier.currencyId);
            })
          );
        }
      })
    );

    this.subscriptionService.subs.push(
      merge(
        this.popupForm.controls.sender.valueChanges,
        this.popupForm.controls.receiver.valueChanges,
        this.popupForm.controls.currency.valueChanges
      ).subscribe(() => {
        if (this.popupForm.controls.orderLinked.value === 1) {
          this.fetchItemPurchaseModalities();
        } else if (this.popupForm.controls.orderKnown.value === 1) {
          this.fetchPurchaseOrderList();
        }
      })
    );

    this.subscriptionService.subs.push(
      merge(
        this.popupForm.controls.sender.valueChanges,
        this.popupForm.controls.receiver.valueChanges,
        this.popupForm.controls.currency.valueChanges
      ).subscribe(() => {
        this.resetFormControlErrors();
      })
    );
  }

  clearCheckedElements(): void {
    const controls = this.form.controls;

    this.selectedPurchaseOrderList.forEach(purchaseOrder => {
      controls[this.getRowControlName(purchaseOrder.id.toString())].patchValue(false);
    });
    this.selectedItemPurchaseModalityList.forEach(itemPurchaseModality => {
      controls[this.getFreeRowControlName(itemPurchaseModality.purchaseModalityId.toString())].patchValue(false);
    });

    this.selectedPurchaseOrderList = [];
    this.selectedItemPurchaseModalityList = [];
  }

  buildOrderLinkedOptions(): void {
    this.orderLinkedOptions = [];
    this.orderLinkedOptions.push(
      new Option(
        0,
        this.translateService.instant(`delivery-initiator-popup.options.order-linked.${this.receptionType}`)
      )
    );
    this.orderLinkedOptions.push(
      new Option(
        1,
        this.translateService.instant(`delivery-initiator-popup.options.not-order-linked.${this.receptionType}`)
      )
    );
  }

  buildOrderKnownOptions(): void {
    this.orderKnownOptions = [];
    this.orderKnownOptions.push(
      new Option(0, this.translateService.instant("delivery-initiator-popup.options.known-order"))
    );
    this.orderKnownOptions.push(
      new Option(1, this.translateService.instant("delivery-initiator-popup.options.unknown-order"))
    );
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  fetchPurchaseOrderList(): void {
    const cloneActiveFilters = [...this.activeFilters];
    cloneActiveFilters.push(new SearchFilter("status", SearchFilterOperator.IN, ["SENT", "PARTIALLY_RECEIVED"]));

    if (!this.popupForm.controls.sender.value || !this.popupForm.controls.receiver.value) {
      return;
    }

    cloneActiveFilters.push(
      new SearchFilter("lines.deliveryStore.id", SearchFilterOperator.IN, this.popupForm.controls.receiver.value)
    );
    cloneActiveFilters.push(
      new SearchFilter("supplier.id", SearchFilterOperator.EQUAL, this.popupForm.controls.sender.value.toString())
    );

    const currencyValue = this.popupForm.controls.currency.value;

    if (currencyValue) {
      // filter on supplier currency
      const currencyId = [...[this.popupForm.controls.currency.value.toString()]];
      cloneActiveFilters.push(new SearchFilter("currencyId", SearchFilterOperator.IN, currencyId));
    }

    this.subscriptionService.subs.push(
      this.purchaseOrderService.getAll(this.pager, this.getSorter(), cloneActiveFilters).subscribe(
        (result: PaginatedList<PurchaseOrder>) => {
          this.rows = [];
          this.purchaseOrderList = result.data;

          this.pager = result.page;
          this.purchaseOrderList.forEach((purchaseOrder: PurchaseOrder) => {
            this.addRow(purchaseOrder);
          });
        },
        error => {
          this.sendErrorAlert("purchase-order-list.errors.get-purchase-orders", error.message);
        },
        () => {
          this.rows = [...this.rows];
          if (this.table !== undefined) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
        }
      )
    );
  }

  addRow(purchaseOrder: PurchaseOrder): void {
    const statusLabel = this.translateService.instant(
      `purchase-order-list.order-status-options.${purchaseOrder.status}`
    );
    const statusClass = this.getStatusClass(purchaseOrder.status);
    const quantityClass = this.getQuantityClass(purchaseOrder);

    this.rows.push({
      id: purchaseOrder.id,
      orderRef: purchaseOrder.orderRef,
      supplierRef: this.supplierList.find(supplier => supplier.id === purchaseOrder.supplierId).reference,
      supplierName: this.supplierList.find(supplier => supplier.id === purchaseOrder.supplierId).name,
      storeRefs: [
        ...new Set(
          purchaseOrder.lines.map(line => this.storeList.find(store => store.id === line.deliveryStoreId).reference)
        ),
      ],
      storeRefsString: purchaseOrder.lines
        .map(line => this.storeList.find(store => store.id === line.deliveryStoreId).reference)
        .join(", "),
      brandRefs: [...new Set(purchaseOrder.lines.map(line => line.brandRef))],
      orderType: this.translateService.instant(`purchase-order.header.order-options.${purchaseOrder.orderType}`),
      createdAt: purchaseOrder.createdAt,
      quantity: this.getQuantity(purchaseOrder, true),
      receivedQuantity: this.getQuantity(purchaseOrder, false),
      deliveryDate: purchaseOrder.deliveryDate,
      statusLabel,
      statusClass,
      quantityClass,
    });
    this.form.addControl(this.getRowControlName(purchaseOrder.id.toString()), new UntypedFormControl(false));
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  public onFreeRowCheckboxChange(): void {
    this.updateFreeHeaderPageCheckbox();
    this.updateFreeSelection();
  }

  public onFreeHeaderCheckboxChange(): void {
    this.updateFreeRowsPageCheckbox();
    this.updateFreeSelection();
  }

  updateHeaderPageCheckbox(): void {
    const currentPageRowsCheckedIds = this.rows.filter(row => {
      return this.form.get(this.getRowControlName(row.id)).value;
    });
    const isSelected = this.rows.length > 0 && this.rows.length === currentPageRowsCheckedIds.length;
    this.form.controls.headerCheckbox.patchValue(isSelected);
  }

  updateRowsPageCheckbox(): void {
    const controls = this.form.controls;
    const isHeaderSelected = this.form.controls.headerCheckbox.value;
    this.rows.forEach(row => {
      controls[this.getRowControlName(row.id)].patchValue(isHeaderSelected);
    });
  }

  public updateSelection(): void {
    this.rows.forEach(row => {
      const rowChecked: boolean = this.form.controls[this.getRowControlName(row.id)].value;
      const purchaseOrder: PurchaseOrder = this.purchaseOrderList.find(elem => elem.id === row.id);
      const isPOSelected = !(this.selectedPurchaseOrderList.findIndex(elem => elem.id === row.id) === -1);

      if (!isPOSelected && rowChecked) {
        this.selectedPurchaseOrderList.push(purchaseOrder);
      } else if (isPOSelected && !rowChecked) {
        const poIndex = this.selectedPurchaseOrderList.findIndex(elem => elem.id === purchaseOrder.id);
        this.selectedPurchaseOrderList.splice(poIndex, 1);
      }
    });
    this.manageInputDisable();
  }

  public manageInputDisable(): void {
    // If at least one row is checked, disable supplier/store inputs
    if (this.selectedPurchaseOrderList.length > 0) {
      this.popupForm.controls.sender.disable({ emitEvent: false });
      this.popupForm.controls.receiver.disable({ emitEvent: false });
      this.popupForm.controls.currency.disable({ emitEvent: false });
    } else {
      this.popupForm.controls.currency.enable({ emitEvent: false });
      this.popupForm.controls.sender.enable({ emitEvent: false });
      if (this.receptionType === ReceptionType.WITH_REGISTERING) {
        this.popupForm.controls.receiver.enable({ emitEvent: false });
      }
    }
  }

  public manageFreeInputDisable(): void {
    // If at least one row is checked, disable supplier/store inputs
    if (this.selectedItemPurchaseModalityList.length > 0) {
      this.popupForm.controls.sender.disable({ emitEvent: false });
      this.popupForm.controls.currency.disable({ emitEvent: false });
    } else {
      this.popupForm.controls.sender.enable({ emitEvent: false });
      this.popupForm.controls.currency.enable({ emitEvent: false });
      if (this.receptionType === ReceptionType.WITH_REGISTERING) {
        this.popupForm.controls.receiver.enable({ emitEvent: false });
      }
    }
  }

  public getRowControlName(id: string): string {
    return `checked_${id}`;
  }

  getQuantity(purchaseOrder: PurchaseOrder, total: boolean): number {
    let quantitySum = 0;
    purchaseOrder.lines.forEach(line => {
      total ? (quantitySum += line.quantity) : (quantitySum += line.receivedQuantity);
    });
    return quantitySum;
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  public changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];
    this.fetchPurchaseOrderList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchPurchaseOrderList();
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "statusLabel":
        return "status";
      default:
        return prop;
    }
  }

  initializePopup(): void {
    this.popupForm.controls.orderLinked.setValue(0);
    this.popupForm.controls.orderKnown.setValue(0);
    this.popupForm.controls.sender.setValue(null);

    const receiverValue = this.receptionType === ReceptionType.DIRECT ? this.contextStoreId : null;
    this.popupForm.controls.receiver.setValue(receiverValue);

    if (receiverValue) {
      this.popupForm.controls.receiver.disable({ emitEvent: false });
    }

    this.selectedDeliveryPopup = new DeliveryPopup({
      orderLinked: 0,
      orderKnown: 0,
      senderId: null,
      receiverId: receiverValue,
      refs: [null],
    });
    this.initialDeliveryPopup = new DeliveryPopup(this.selectedDeliveryPopup);

    this.initFilters();
    this.initFreeFilters();
  }

  addOrderRef(): void {
    this.orderRefForm.push(new UntypedFormControl("", Validators.required));
    this.resetFormControlErrors();
  }

  removeOrderRef(rowIndex: number): void {
    this.orderRefForm.removeAt(rowIndex);
  }

  removeAllOrderRef(): void {
    while (this.orderRefForm.length !== 0) {
      this.removeOrderRef(0);
    }
  }

  submit(): void {
    this.popupForm.markAllAsTouched();
    this.submitedPurchaseOrderList = [];
    this.submitedItemPurchaseModalityList = [];
    const selectOrderWithTable =
      (this.popupForm.controls.orderLinked.value === 0 && this.popupForm.controls.orderKnown.value) ||
      this.popupForm.controls.orderLinked.value === 1;

    if (this.popupForm.invalid) {
      return;
    }
    this.resetFormControlErrors();

    // handle delivery linked to known orders
    if (!selectOrderWithTable) {
      const uniqueOrderRefList = [...new Set(this.popupForm.value.refs.map(ref => this.formatOrderRefValue(ref)))];
      this.subscriptionService.subs.push(
        this.fetchPurchaseOrders(uniqueOrderRefList).subscribe(() => {
          this.checkErrors();

          if (this.popupForm.invalid) {
            return;
          }
          this.submitNavigate(this.submitedPurchaseOrderList, this.submitedItemPurchaseModalityList);
        })
      );
    }

    // handle delivery linked to known orders
    if (selectOrderWithTable) {
      this.checkErrors();

      if (this.popupForm.invalid) {
        return;
      }

      if (this.popupForm.controls.orderLinked.value === 0 && this.selectedPurchaseOrderList.length === 0) {
        const title = this.translateService.instant("delivery-initiator-popup.errors.no-line-selected-title");
        const content = this.translateService.instant(
          "delivery-initiator-popup.errors.no-line-selected-purchase-order"
        );
        this.messageService.error(content, { title });
        return;
      } else if (
        this.popupForm.controls.orderLinked.value === 1 &&
        this.selectedItemPurchaseModalityList.length === 0
      ) {
        const title = this.translateService.instant("delivery-initiator-popup.errors.no-line-selected-title");
        const content = this.translateService.instant(
          "delivery-initiator-popup.errors.no-line-selected-purchase-modality"
        );
        this.messageService.error(content, { title });
        return;
      }

      this.submitNavigate(this.selectedPurchaseOrderList, this.selectedItemPurchaseModalityList);
    }
  }

  submitNavigate(purchaseOrderList: PurchaseOrder[], itemPurchaseModalityList: ItemPurchaseModality[]): void {
    // to modify with big cleaning
    let selectedPurchaseOrderList = [];
    let selectedItemPurchaseModality = [];
    let redirectURL = "";
    if (this.popupForm.controls.orderLinked.value === 0) {
      selectedPurchaseOrderList = [...new Set(purchaseOrderList)];
      redirectURL = "/delivery-form/add";
    }
    if (this.popupForm.controls.orderLinked.value === 1) {
      selectedItemPurchaseModality = [...new Set(itemPurchaseModalityList)];
      redirectURL = "/delivery-free-form/add";
    }
    if (this.isNew) {
      redirectURL = "/receiving-form/add";
    }

    this.router.navigate([redirectURL], {
      state: {
        purchaseOrderList: selectedPurchaseOrderList,
        itemPurchaseModalityList: selectedItemPurchaseModality,
        senderId: this.popupForm.controls.sender.value,
        receiverId: this.popupForm.controls.receiver.value,
        currencyId: this.popupForm.controls.currency.value !== 0 ? this.popupForm.controls.currency.value : null,
      },
    });
  }

  fetchPurchaseOrders(uniqueOrderRefList: any[]): Observable<PaginatedList<PurchaseOrder>> {
    const pager = new Pagination({
      size: uniqueOrderRefList.length,
      number: 0,
    });
    const filters = new SearchFilter("orderRef", SearchFilterOperator.IN, uniqueOrderRefList);

    return this.purchaseOrderService.getAll(pager, [], [filters]).pipe(
      tap((result: PaginatedList<PurchaseOrder>) => {
        this.fetchedPurchaseOrderList = result.data;
      })
    );
  }

  formatOrderRefValue(value: string): string {
    if (value === "") {
      return value;
    }
    if (value.toLowerCase().startsWith("cd")) {
      return value.toUpperCase();
    }
    return "CD".concat("", value);
  }

  checkErrors(): void {
    for (const control of this.orderRefForm.controls) {
      const index = this.orderRefForm.controls.findIndex(element => element === control);
      const formattedOrderRefValue = this.formatOrderRefValue(control.value);

      // check if purchase order exists
      if (this.fetchedPurchaseOrderList.filter(po => po.orderRef === formattedOrderRefValue).length === 0) {
        this.orderRefForm.controls[index].setErrors({ notFound: true });
        continue;
      }

      const purchaseOrder = this.fetchedPurchaseOrderList.find(po => po.orderRef === formattedOrderRefValue);
      // check purchase order status
      if (
        purchaseOrder.status !== PurchaseOrderStatus.SENT &&
        purchaseOrder.status !== PurchaseOrderStatus.PARTIALLY_RECEIVED
      ) {
        this.orderRefForm.controls[index].setErrors({ wrongStatus: true });
        continue;
      }

      // check if purchase order is related to selected supplier
      if (purchaseOrder.supplierId !== this.popupForm.controls.sender.value) {
        this.orderRefForm.controls[index].setErrors({ wrongSender: true });
        continue;
      }

      // check if at least one purchase order line is related to selected store
      let lineFound = false;

      if (purchaseOrder.lines && purchaseOrder.lines.length > 0) {
        for (const line of purchaseOrder.lines) {
          if (line.deliveryStoreId === this.popupForm.controls.receiver.value) {
            lineFound = true;
            continue;
          }
        }
      }
      if (!lineFound) {
        this.orderRefForm.controls[index].setErrors({ wrongReceiver: true });
        continue;
      }

      // check if selected currency matches po currency
      const currencyId = this.popupForm.controls.currency.value;

      if (purchaseOrder.currencyId !== currencyId) {
        this.orderRefForm.controls[index].setErrors({ wrongCurrency: true });
        continue;
      }

      this.submitedPurchaseOrderList.push(purchaseOrder);
    }
  }

  resetFormControlErrors(): void {
    for (const control of this.orderRefForm.controls) {
      const index = this.orderRefForm.controls.findIndex(element => element === control);

      if (!this.orderRefForm.controls[index].hasError("required")) {
        this.orderRefForm.controls[index].setErrors(null);
      }
    }
  }

  closePopup(): void {
    this.selectedDeliveryPopup.orderLinked = this.popupForm.controls.orderLinked.value;
    this.selectedDeliveryPopup.orderKnown = this.popupForm.controls.orderKnown.value;
    this.selectedDeliveryPopup.senderId = this.popupForm.controls.sender.value;
    this.selectedDeliveryPopup.receiverId = this.popupForm.controls.receiver.value;

    if (this.selectedDeliveryPopup.orderLinked === 0) {
      this.selectedDeliveryPopup.refs = this.popupForm.controls.refs.value.map(ref => this.formatOrderRefValue(ref));
    }

    if (this.unsavedDeliveryPopup && !this.unsavedDeliveryPopup.equals(this.selectedDeliveryPopup)) {
      this.shouldClose = false;
    }

    if (!this.initialDeliveryPopup.equals(this.selectedDeliveryPopup) && !this.shouldClose) {
      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedDeliveryPopup = new DeliveryPopup(this.selectedDeliveryPopup);
      this.shouldClose = true;
    } else {
      this.close.emit();
    }
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_MANUAL_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "orderRef",
      this.translateService.instant("delivery-initiator-popup.datatable.columns.order-ref"),
      "string"
    );

    this.filterer.addListFilter(
      "orderType",
      this.translateService.instant("delivery-initiator-popup.datatable.columns.order-type"),
      Object.keys(PurchaseOrderType).map(key => ({ value: key, displayValue: this.orderTypeTranslations[key] }))
    );

    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    const defaultValue = {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
    this.filterer.addFilter(
      "createdAt",
      this.translateService.instant("delivery-initiator-popup.datatable.columns.created-at"),
      "date",
      true,
      false,
      defaultValue
    );

    this.filterer.addFilter(
      "deliveryDate",
      this.translateService.instant("delivery-initiator-popup.datatable.columns.delivery-date"),
      "date",
      false,
      false,
      defaultValue
    );

    this.filterer.addListFilter(
      "status",
      this.translateService.instant("delivery-initiator-popup.datatable.columns.status"),
      [
        { value: "SENT", displayValue: this.statusTranslations.SENT },
        { value: "PARTIALLY_RECEIVED", displayValue: this.statusTranslations.PARTIALLY_RECEIVED },
      ]
    );
  }

  applyFilters(): void {
    this.activeFilters = [];
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_MANUAL_ID
      ).subscribe(() => {
        this.fetchPurchaseOrderList();
      })
    );
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  public changeFreeSortSettings(prop: string, dir: string): void {
    switch (prop) {
      case "statusLabel":
        this.freeSorts = [
          {
            prop: "statusLabel",
            dir,
          },
        ];
        break;
      default:
        this.freeSorts = [
          {
            dir: "asc",
            prop: "statusLabel",
          },
          {
            prop,
            dir,
          },
        ];
        break;
    }
    this.fetchItemPurchaseModalities();
  }

  getFreeSorter(): Sort[] {
    const sorter = [];
    for (const s of this.freeSorts) {
      sorter.push(new Sort(this.freePropToDto(s.prop), s.dir));
    }
    return sorter;
  }

  public applyFreeFilters(): void {
    this.freeActiveFilters = [];
    this.freePager.number = 0;

    if (!this.popupForm.controls.sender.value) {
      return;
    }

    this.computeFreeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.freeFilterer.filterValues.map(fv => fv.filterId),
        this.LIST_FREE_ID
      ).subscribe(() => {
        this.fetchItemPurchaseModalities();
      })
    );
  }

  public getFreeRowControlName(id: string | number): string {
    return `free_checked_${id}`;
  }

  public getItemStatusClass(status: ItemStatusType): string {
    return `item-status-${status.toLowerCase()}`;
  }

  public changeFreePage(pageInfo: any): void {
    this.freePager.number = pageInfo.page - 1;
    this.fetchItemPurchaseModalities();
  }

  getRowClass: any = (): any => ({ "not-clickable": true });

  protected getElementFromMap(event: number[], map: Map<number, string>): any {
    const list = [];
    if (event) {
      event.forEach(element => {
        list.push(map.get(element));
      });
    }

    return list;
  }

  // -------------------- FREE DELIVERY ---------------------

  protected getPurchaseTypeList(filterValue: FilterValue): any {
    const list = [];
    if (filterValue.equal) {
      (filterValue.equal as number[]).forEach(element => {
        list.push(Object.keys(PurchaseType)[element]);
      });
    }

    return list;
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.senderOptions = lightSuppliers
            .filter(obj => !obj.archived)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.supplierList = lightSuppliers;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("delivery-initiator-popup.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.receiverOptions = lightStores
            .filter(obj => !obj.archived)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.storeList = lightStores;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("delivery-initiator-popup.errors.get-stores");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  // handle select checkbox
  private initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.form = this.fb.group({
      headerCheckbox: control,
    });
  }

  private updateFreeRowsPageCheckbox(): void {
    const controls = this.form.controls;
    const isHeaderSelected = this.form.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      if (!controls[this.getFreeRowControlName(id)].disabled) {
        controls[this.getFreeRowControlName(id)].patchValue(isHeaderSelected);
      }
    });
  }

  private getStatusClass(status: string): string {
    return `status-${status.toLowerCase().replace("_", "-")}`;
  }

  private getQuantityClass(purchaseOrder: PurchaseOrder): string {
    const quantity = this.getQuantity(purchaseOrder, true);
    const receivedQuantity = this.getQuantity(purchaseOrder, false);
    if (receivedQuantity === 0) {
      return "quantity-wrapper none";
    } else if (receivedQuantity < quantity) {
      return "quantity-wrapper partial";
    } else {
      return "quantity-wrapper full";
    }
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  private fetchBrands(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Light[]) => {
          this.brandList = brands
            .filter((light: Light) => !light.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("delivery-initiator-popup.errors.get-brands");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencyList = currencies;

          this.currencyOptions = currencies
            .filter(obj => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(obj => new Option(obj.id, obj.symbol));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("delivery-initiator-popup.errors.get-currencies");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchCategories(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (categories: ItemCategory[]) => {
          this.categoryList = categories
            .filter((obj: ItemCategory) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("purchase-modality-selection.errors.get-categories");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchCodeLanguage(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.codeLanguage = connectedUser.codeLanguage;
      })
    );
  }

  private fetchReceptionType(): Observable<AppConfig> {
    return this.appConfigService.appConfig.pipe(
      tap(appConfig => {
        this.receptionType = appConfig?.receptionType ? appConfig.receptionType : ReceptionType.WITH_REGISTERING;
      })
    );
  }

  private fetchItemPurchaseModalities(): void {
    const cloneActiveFilters = [...this.freeActiveFilters];
    const senderValue = this.popupForm.controls.sender.value;
    const currencyValue = this.popupForm.controls.currency.value;

    cloneActiveFilters.push(new SearchFilter("archived", SearchFilterOperator.EQUAL, "false"));
    if (this.popupForm.controls.orderLinked.value === 1) {
      cloneActiveFilters.push(new SearchFilter("retailItem.canBuy", SearchFilterOperator.EQUAL, "true"));
    }

    if (senderValue && cloneActiveFilters.find(sFilter => sFilter.key === "supplier.id") === undefined) {
      const suppliersId = [...[this.popupForm.controls.sender.value.toString()], ...["NULL"]];
      cloneActiveFilters.push(new SearchFilter("supplier.id", SearchFilterOperator.IN, suppliersId));
    }

    if (currencyValue) {
      // filter on supplier currency
      const currencyId = [...[this.popupForm.controls.currency.value.toString()]];
      cloneActiveFilters.push(new SearchFilter("currencyId", SearchFilterOperator.IN, currencyId));
    }

    this.subscriptionService.subs.push(
      this.itemPurchaseModalityService.getAll(this.freePager, this.getFreeSorter(), cloneActiveFilters).subscribe(
        (result: PaginatedList<ItemPurchaseModality>) => {
          this.freeRows = [];
          if (this.popupForm.controls.sender.value !== null) {
            this.freePager = result.page;
            this.itemPurchaseModalityList = result.data;

            this.currentRowsId = result.data.map((itemPM: ItemPurchaseModality) => itemPM.purchaseModalityId);
            result.data.forEach((itemPM: ItemPurchaseModality) => {
              this.addFreeRow(itemPM);
            });
          }
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant(
            "purchase-modality-selection.errors.get-item-purchase-modalities"
          );
          this.messageService.warn(content, { title });
        },
        () => {
          this.freeRows = [...this.freeRows];
          if (this.freeTable !== undefined) {
            this.freeTable.sorts = this.freeSorts;
            this.freeTable.offset = this.freePager.number;
            this.updateFreeSelection();
            this.updateFreeHeaderPageCheckbox();
          }
        }
      )
    );
  }

  private initFreeFilters(): void {
    if (this.freeFilterer) {
      return;
    }
    const componentFreeFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_FREE_ID
    );
    this.freeFilterer = new Filterer(componentFreeFilterPref?.filters);

    this.freeFilterer.addListFilter(
      "supplier.id",
      this.translateService.instant("purchase-modality-selection.datatable.columns.suppliers"),
      this.supplierList.map(supplier => {
        return { value: supplier.id.toString(), displayValue: supplier.name };
      })
    );

    this.freeFilterer.addListFilter(
      "retailItem.categoryName",
      this.translateService.instant("purchase-modality-selection.datatable.columns.item-category"),
      this.categoryList.map(category => {
        return { value: category.name, displayValue: category.name };
      })
    );

    this.freeFilterer.addFilter(
      "retailItem.reference",
      this.translateService.instant("purchase-modality-selection.datatable.columns.item-reference"),
      "string"
    );

    this.freeFilterer.addFilter(
      "supplierRef",
      this.translateService.instant("purchase-modality-selection.datatable.columns.supplier-ref"),
      "string"
    );

    this.freeFilterer.addFilter(
      "retailItem.name",
      this.translateService.instant("purchase-modality-selection.datatable.columns.item-name"),
      "string"
    );

    this.freeFilterer.addListFilter(
      "purchaseType",
      this.translateService.instant("purchase-modality-selection.datatable.columns.purchase-type"),
      Object.keys(PurchaseType).map(key => ({ value: key, displayValue: this.purchaseTypeTranslations[key] }))
    );

    this.freeFilterer.addListFilter(
      "retailItem.brand.name",
      this.translateService.instant("purchase-modality-selection.datatable.columns.brand"),
      this.brandList.map(brand => {
        return { value: brand.name, displayValue: brand.name };
      })
    );

    this.freeFilterer.addFilter(
      "minQuantity",
      this.translateService.instant("purchase-modality-selection.datatable.columns.min-quantity"),
      "range"
    );

    this.freeFilterer.addFilter(
      "maxQuantity",
      this.translateService.instant("purchase-modality-selection.datatable.columns.max-quantity"),
      "range"
    );

    this.freeFilterer.addListFilter(
      "retailItem.status",
      this.translateService.instant("purchase-modality-selection.datatable.columns.status"),
      Object.keys(ItemStatusType).map(key => ({ value: key, displayValue: this.itemStatusTranslations[key] }))
    );
  }

  private computeFreeSearchFilters(): void {
    this.freeActiveFilters = this.freeFilterer.getSearchFilters();
  }

  private addFreeRow(itemPM: ItemPurchaseModality): void {
    const statusLabel = this.translateService.instant(
      `purchase-modality-selection.status-options.${itemPM.itemStatus}`
    );
    const statusClass = this.getItemStatusClass(itemPM.itemStatus);
    const currency = this.currencyList.find((cc: Currency) => cc.id === itemPM.currencyId && !cc.archived);

    this.freeRows.push({
      itemCategory: itemPM.itemCategoryName,
      supplierRef: itemPM.itemSupplierRef,
      purchaseType: itemPM.purchaseType,
      itemReference: itemPM.itemReference,
      computedPrice: itemPM.computedPrice,
      pmId: itemPM.purchaseModalityId,
      minQuantity: itemPM.minQuantity,
      maxQuantity: itemPM.maxQuantity,
      supplier: itemPM.supplierName,
      itemName: itemPM.itemName,
      brand: itemPM.brandName,
      itemId: itemPM.itemId,
      currencyIsoCode: currency.codeISO,
      statusLabel,
      statusClass,
    });

    this.form.addControl(this.getFreeRowControlName(itemPM.purchaseModalityId), new UntypedFormControl(false));
  }

  private freePropToDto(propName: string): string {
    switch (propName) {
      case "brand":
        return "retailItem.brand.name";
      case "itemReference":
        return "retailItem.reference";
      case "itemCategory":
        return "retailItem.category";
      case "statusLabel":
        return "retailItem.status";
      case "itemName":
        return "retailItem.name";
      case "supplier":
        return "supplier.name";
      default:
        return propName;
    }
  }

  private computeSelectedElementsNumber(): void {
    this.selectedElementsNumber = this.selectedItemPurchaseModalityList.length;
  }

  private updateFreeSelection(): void {
    this.freeRows.forEach((row: any) => {
      const pmChecked: boolean = this.form.controls[this.getFreeRowControlName(row.pmId)].value;
      const itemPurchaseModality: ItemPurchaseModality = this.itemPurchaseModalityList.find(
        elem => elem.purchaseModalityId === row.pmId
      );
      const isPOSelected = !(
        this.selectedItemPurchaseModalityList.findIndex(elem => elem.purchaseModalityId === row.pmId) === -1
      );

      if (!isPOSelected && pmChecked) {
        this.selectedItemPurchaseModalityList.push(itemPurchaseModality);
      } else if (isPOSelected && !pmChecked) {
        const poIndex = this.selectedItemPurchaseModalityList.findIndex(
          elem => elem.purchaseModalityId === itemPurchaseModality.purchaseModalityId
        );
        this.selectedItemPurchaseModalityList.splice(poIndex, 1);
      }
    });
    this.computeSelectedElementsNumber();
    this.manageFreeInputDisable();
  }

  private updateFreeHeaderPageCheckbox(): void {
    const currentPageRowsDisabledIds = this.currentRowsId.filter(id => {
      return (
        this.form.get(this.getFreeRowControlName(id)).disabled && !this.form.get(this.getFreeRowControlName(id)).value
      );
    });
    if (this.currentRowsId.length === currentPageRowsDisabledIds.length) {
      this.form.controls.headerCheckbox.patchValue(false);
      if (!this.form.controls.headerCheckbox.disabled) {
        this.form.controls.headerCheckbox.disable();
      }
      return;
    }
    this.form.controls.headerCheckbox.enable();

    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.form.get(this.getFreeRowControlName(id)).value || currentPageRowsDisabledIds.includes(id);
    });
    const isSelected = this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.form.controls.headerCheckbox.patchValue(isSelected);
  }

  private manageReceptionTypeTrad(): void {
    this.popupTitle = this.translateService.instant(`delivery-initiator-popup.title.${this.receptionType}`);
    this.buttonLabel = this.translateService.instant(
      `delivery-initiator-popup.buttons.to-delivery.${this.receptionType}`
    );
    this.deliveryTypeLabel = this.translateService.instant(
      `delivery-initiator-popup.labels.delivery-type.${this.receptionType}`
    );
  }
}
