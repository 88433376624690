<ng-container>
  <form class="purchase-modality-form" [formGroup]="popupForm" novalidate>
    <div class="popup-container">
      <ui-popup title="{{ popupTitle }}" (close)="closePopup()">
        <div content>
          <div class="grid-container">
            <div class="grid-item">
              <div class="supplier">
                <ui-list
                  label="{{ 'purchase-modalities-popup.fields.supplier' | translate }}"
                  [options]="this.suppliersOptions"
                  [search]="true"
                  formControlName="supplierId"
                >
                </ui-list>
              </div>

              <div class="supplier-ref">
                <ui-input-text
                  id="supplier-ref-id"
                  type="text"
                  label="{{ 'purchase-modalities-popup.fields.supplier-ref' | translate }}"
                  formControlName="supplierRef"
                >
                </ui-input-text>
              </div>

              <div class="currency">
                <ui-list
                  label="{{ 'purchase-modalities-popup.fields.currency' | translate }}"
                  [options]="currenciesOptions"
                  formControlName="currencyId"
                  mandatory="true"
                >
                </ui-list>
              </div>
              <div class="purchase-type">
                <ui-radio-button
                  label="{{ 'purchase-modalities-popup.fields.price-definition' | translate }}"
                  [options]="this.purchaseTypeOptions"
                  name="purchaseType"
                  direction="horizontal"
                  formControlName="purchaseType"
                  mandatory="true"
                ></ui-radio-button>
              </div>
            </div>
            <div class="grid-item">
              <div class="unit-price">
                <ui-input-text
                  id="unit-price-id"
                  type="text"
                  [maskPattern]="decimalDigit"
                  mandatory="true"
                  [thousandSeparator]="' '"
                  label="{{
                    'purchase-modalities-popup.fields.unit-price' | translate: { unit: this.defaultCurrency.symbol }
                  }}"
                  formControlName="unitPrice"
                  [status]="popupForm.get('unitPrice') | formStatus"
                  [errorMessage]="popupForm.get('unitPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
                  [suffix]="' ' + this.defaultCurrency.symbol"
                  infoLabel="{{ 'purchase-modalities-popup.fields.info-label.unit-price' | translate }}"
                >
                </ui-input-text>
              </div>

              <div *ngIf="!isDefaultCurrency()" class="unit-price-currency">
                <ui-input-text
                  id="unit-price-currency-id"
                  mandatory="true"
                  type="text"
                  [maskPattern]="decimalDigit"
                  [thousandSeparator]="' '"
                  label="{{
                    'purchase-modalities-popup.fields.unit-price-currency'
                      | translate: { unit: getSelectedCurrency().symbol }
                  }}"
                  formControlName="unitPriceCurrency"
                  [status]="popupForm.get('unitPriceCurrency') | formStatus"
                  [errorMessage]="
                    popupForm.get('unitPriceCurrency') | formErrorMessage | translate: { digit: HIGH_INTEGER }
                  "
                  [suffix]="' ' + getSelectedCurrency().symbol"
                  infoLabel="{{ 'purchase-modalities-popup.fields.info-label.unit-price' | translate }}"
                >
                </ui-input-text>
              </div>

              <div class="unit-price-per-weight">
                <ui-input-text
                  id="unit-price-per-weight-id"
                  type="text"
                  [maskPattern]="decimalDigit"
                  [thousandSeparator]="' '"
                  label="{{
                    'purchase-modalities-popup.fields.unit-price-per-weight'
                      | translate: { unit: this.defaultCurrency.symbol }
                  }}"
                  formControlName="unitPricePerWeight"
                  [status]="popupForm.get('unitPricePerWeight') | formStatus"
                  [errorMessage]="
                    popupForm.get('unitPricePerWeight') | formErrorMessage | translate: { digit: HIGH_INTEGER }
                  "
                  [suffix]="' ' + this.defaultCurrency.symbol"
                  infoLabel="{{ 'purchase-modalities-popup.fields.info-label.unit-price-weight' | translate }}"
                >
                </ui-input-text>
              </div>
              <div *ngIf="!isDefaultCurrency()" class="unit-price-per-weight-currency">
                <ui-input-text
                  id="unit-price-per-weight-currency-id"
                  type="text"
                  [maskPattern]="decimalDigit"
                  [thousandSeparator]="' '"
                  label="{{
                    'purchase-modalities-popup.fields.unit-price-per-weight'
                      | translate: { unit: getSelectedCurrency().symbol }
                  }}"
                  formControlName="unitPricePerWeightCurrency"
                  [status]="popupForm.get('unitPricePerWeightCurrency') | formStatus"
                  [errorMessage]="
                    popupForm.get('unitPricePerWeightCurrency') | formErrorMessage | translate: { digit: HIGH_INTEGER }
                  "
                  [suffix]="' ' + getSelectedCurrency().symbol"
                  infoLabel="{{ 'purchase-modalities-popup.fields.info-label.unit-price-weight' | translate }}"
                >
                </ui-input-text>
              </div>
              <div class="min-quantity">
                <ui-input-text
                  id="min-quantity-id"
                  type="text"
                  [maskPattern]="decimalDigit"
                  label="{{ 'purchase-modalities-popup.fields.min-quantity' | translate }}"
                  formControlName="minQuantity"
                  [status]="popupForm.get('minQuantity') | formStatus"
                  [errorMessage]="
                    popupForm.get('minQuantity')
                      | formErrorMessage
                        : { customPrefix: 'purchase-modalities-popup.errors.', useCustomPrefixFor: ['min'] }
                      | translate: { digit: HIGH_INTEGER }
                  "
                >
                </ui-input-text>
              </div>
              <div class="max-quantity">
                <ui-input-text
                  id="max-quantity-id"
                  type="text"
                  [maskPattern]="decimalDigit"
                  label="{{ 'purchase-modalities-popup.fields.max-quantity' | translate }}"
                  formControlName="maxQuantity"
                  [status]="popupForm.get('maxQuantity') | formStatus"
                  [errorMessage]="
                    popupForm.get('maxQuantity')
                      | formErrorMessage
                        : { customPrefix: 'purchase-modalities-popup.errors.', useCustomPrefixFor: ['min', 'badMax'] }
                      | translate: { digit: HIGH_INTEGER }
                  "
                >
                </ui-input-text>
              </div>
            </div>
            <div class="grid-item">
              <div class="purchase-unit">
                <div class="multi-col">
                  <ui-list
                    label="{{ 'purchase-modalities-popup.fields.purchase-unit' | translate }}"
                    [options]="this.uomsOptions"
                    formControlName="purchaseUnitId"
                    mandatory="true"
                    [status]="popupForm.get('purchaseUnitId') | formStatus"
                    [errorMessage]="popupForm.get('purchaseUnitId') | formErrorMessage | translate"
                  >
                  </ui-list>
                  <div class="equal-to-label">
                    <label> {{ "purchase-modalities-popup.fields.equal-to" | translate }}</label>
                  </div>
                </div>
              </div>
              <div class="conversion-factor">
                <div class="multi-col">
                  <ui-input-text
                    id="conversion-factor-id"
                    type="text"
                    [maskPattern]="decimalDigit"
                    mandatory="true"
                    label="{{ 'purchase-modalities-popup.fields.conversion-factor' | translate }}"
                    formControlName="conversionFactor"
                    [status]="popupForm.get('conversionFactor') | formStatus"
                    [errorMessage]="
                      popupForm.get('conversionFactor')
                        | formErrorMessage
                          : { customPrefix: 'purchase-modalities-popup.errors.', useCustomPrefixFor: ['min'] }
                        | translate: { digit: HIGH_INTEGER }
                    "
                  >
                  </ui-input-text>

                  <div class="main-unit" *ngIf="mainUnit">
                    <ui-info-field
                      [label]="'purchase-modalities-popup.fields.main-unit-label' | translate"
                      [value]="mainUnit.longName"
                      [customClass]="'main-unit'"
                    >
                    </ui-info-field>
                  </div>
                </div>
              </div>

              <ui-info-field
                [label]="'purchase-modalities-popup.fields.theoretical-weight' | translate"
                [value]="
                  itemWeight
                    ? (this.itemTheoreticalWeight | number: '0.0-2':codeLanguage) + 'g'
                    : ('purchase-modalities-popup.fields.undefined' | translate)
                "
                [customClass]="'theoretical-weight'"
              >
              </ui-info-field>
              <div class="computed-price" *ngIf="getSelectedCurrency()">
                <ui-info-field
                  [label]="'purchase-modalities-popup.fields.computed-price' | translate"
                  [value]="totalPrice | formatCurrency: getSelectedCurrency().codeISO:'symbol':'0.2-5':codeLanguage"
                  [customClass]="'computed-price'"
                >
                </ui-info-field>
              </div>
            </div>
          </div>
        </div>
        <div footer>
          <div class="align-buttons">
            <div class="button-popup-validate">
              <div class="button">
                <ui-button
                  text="{{ 'global.validate' | translate }}"
                  type="save"
                  [primary]="true"
                  (click)="submitPurchaseModality()"
                ></ui-button>
              </div>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </form>
</ng-container>
