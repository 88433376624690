<ng-container>
  <div class="main-container frozen-left frozen-right">
    <div class="title">
      <ui-title titleText="{{ 'purchase-order-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-create">
        <ui-button
          text="{{ 'global.new' | translate }}"
          type="add"
          [primary]="true"
          *hasPermission="['PURCHASE_ORDER_CREATE']"
          (click)="createPurchaseOrder()"
        ></ui-button>
      </div>
    </div>
    <div class="purchase-order-list">
      <ui-container>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            [locale]="locale"
            dateFormat="{{ dateFormat }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div class="headband">
          <div class="selected-count">
            <span
              [innerHTML]="
                selectedPurchaseOrderList.length === 0
                  ? ('global.datatable.no-items' | translate)
                  : (selectedPurchaseOrderList.length
                    | i18nPlural
                      : {
                          '=1': 'global.datatable.n-selected-items',
                          other: 'global.datatable.n-selected-items_plural'
                        }
                    | translate: { selectedElements: selectedPurchaseOrderList.length })
              "
            >
            </span>
          </div>
          <div class="print-button">
            <ui-button
              text="{{ 'purchase-order-list.datatable.buttons.print' | translate }}"
              (click)="printPurchaseOrder()"
              [disabled]="selectedPurchaseOrderList.length === 0"
              [btnIcon]="faPrint"
            >
            </ui-button>
          </div>
          <div class="buttons">
            <div class="button-change">
              <ui-button
                text="{{ 'purchase-order-list.datatable.buttons.status-change' | translate }}"
                (click)="openChangeStatusPopup()"
                [disabled]="!isStatusUnique || hasMixedLines"
                [btnIcon]="faSync"
              >
              </ui-button>
            </div>
          </div>
          <div class="tooltip" *ngIf="!isStatusUnique || hasMixedLines">
            <span class="info-icon" #info><fa-icon [icon]="faInfoCircle"></fa-icon></span>
            <ui-tooltip maxWidth="300px" [element]="info">
              <div [innerHTML]="infoLabel"></div>
            </ui-tooltip>
          </div>
        </div>

        <div class="separator">
          <div class="pointer">
            <div class="arrow"></div>
          </div>
          <div class="line"></div>
        </div>

        <div [formGroup]="tableControl" class="purchase-order-table">
          <div>
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material"
              columnMode="force"
              headerHeight="auto"
              footerHeight="50"
              rowHeight="40"
              (activate)="checkPurchaseOrder($event)"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down'
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [externalSorting]="true"
              [externalPaging]="true"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              scrollbarH="true"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                width="50"
                [sortable]="false"
                [frozenLeft]="true"
                [draggable]="false"
                [resizeable]="false"
                [canAutoResize]="false"
                cellClass="center-align frozen-style"
              >
                <ng-template ngx-datatable-header-template>
                  <div class="green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onHeaderCheckboxChange()"
                      [formControlName]="'headerCheckbox'"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-id="row.id">
                  <div class="adjust green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onRowCheckboxChange()"
                      [formControlName]="getRowControlName(id)"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.order-ref' | translate }}"
                prop="orderRef"
                cellClass="order-ref"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.supplier-ref' | translate }}"
                prop="supplierRef"
                cellClass="supplier-ref"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.brand-ref' | translate }}"
                prop="brandRef"
                cellClass="brand-ref ellipsis-counter"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-brandRefs="row.brandRefs">
                  <p class="ellipsis">{{ brandRefs.join(", ") }}</p>
                  <div *ngIf="brandRefs.length >= 2">
                    <div #brandRefsCounter>
                      <ui-counter [number]="brandRefs.length"></ui-counter>
                    </div>
                    <ui-tooltip maxWidth="300px" [element]="brandRefsCounter">
                      <span style="font-weight: normal !important">{{ brandRefs.join(", ") }}</span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.order-type' | translate }}"
                prop="orderType"
                cellClass="order-type"
                [resizeable]="false"
                [width]="200"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.created-at' | translate }}"
                prop="createdAt"
                cellClass="created-at "
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.createdAt">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.quantity' | translate }}"
                cellClass="quantity "
                [resizeable]="false"
                [sortable]="false"
                [width]="200"
              >
                <ng-template
                  ngx-datatable-cell-template
                  let-row="row"
                  let-receivedQuantity="row.receivedQuantity"
                  let-quantity="row.quantity"
                >
                  <div class="quantity-wrapper" *ngIf="quantity > 0">
                    <div class="{{ row.quantityClass }}">{{ receivedQuantity }}/{{ quantity }}</div>
                    <ui-progress-bar
                      value="{{ quantity === 0 ? 100 : (receivedQuantity / quantity) * 100 }}"
                      max="100"
                    ></ui-progress-bar>
                    <div *ngIf="receivedQuantity > quantity" class="warning">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.delivery-date' | translate }}"
                prop="deliveryDate"
                cellClass="delivery-date "
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.deliveryDate">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.total-gross-price' | translate }}"
                prop="totalPrice"
                cellClass="total-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO:"symbol":"0.2-2":locale }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.unreceived-price' | translate }}"
                prop="unreceivedPrice"
                cellClass="unreceived-price"
                [resizeable]="false"
              >
                <ng-template
                  ngx-datatable-cell-template
                  let-row="row"
                  let-value="value"
                  let-displayUnreceivedPrice="row.displayUnreceivedPrice"
                >
                  <div class="adjust" *ngIf="displayUnreceivedPrice">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO:"symbol":"0.2-2":locale }}
                  </div>
                  <div class="adjust" *ngIf="!displayUnreceivedPrice">- {{ this.getCurrency(row).symbol }}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.comment' | translate }}"
                prop="comment"
                cellClass="comment "
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div #commentText class="adjust ellipsis">
                    {{ row.comment }}
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="commentText">
                    <span style="font-weight: normal !important; white-space: pre-wrap !important">{{
                      row.comment
                    }}</span>
                  </ui-tooltip>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.status' | translate }}"
                prop="statusLabel"
                cellClass="status frozen-style"
                headerClass="left-align"
                [width]="80"
                [resizeable]="false"
                [frozenRight]="true"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"></ui-status-label>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-list.datatable.columns.actions' | translate }}"
                headerClass="center-align"
                cellClass="center-align frozen-style"
                [resizeable]="false"
                [frozenRight]="true"
                [sortable]="false"
                [width]="80"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div *ngIf="row.actionnable" class="green-zone" (click)="$event.stopPropagation()">
                    <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)">
                    </ui-menu-actions>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>
    </div>
  </div>

  <!-- change status popup -->
  <app-purchase-order-status-popup
    *ngIf="statusPopupVisible"
    [purchaseOrderList]="selectedPurchaseOrderList"
    (validate)="validateChangeStatusPopup()"
    (close)="closeChangeStatusPopup()"
  ></app-purchase-order-status-popup>
</ng-container>
