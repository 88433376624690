<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'brands-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-create">
        <ui-button
          text="{{ 'global.new' | translate }}"
          type="add"
          [primary]="true"
          *hasPermission="['BRAND_CREATE']"
          (click)="createBrand()"
        ></ui-button>
      </div>
      <div class="button-export">
        <ui-button
          type="export"
          text="{{ (rows.length === brandList.length ? 'global.export.all' : 'global.export.select') | translate }}"
          *hasPermission="['BRAND_READ']"
          (click)="exportBrands()"
        ></ui-button>
      </div>
      <div class="button-import">
        <ui-button
          type="import"
          text="{{ 'global.import' | translate }}"
          *hasPermission="['BRAND_CREATE']"
          (click)="importBrands()"
        ></ui-button>
      </div>
    </div>
    <div class="brands-list">
      <ui-container>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            applyLabel="{{ 'filters.apply' | translate }}"
            (apply)="applyFilters()"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div class="brands-table">
          <div [formGroup]="tableControl">
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material"
              columnMode="flex"
              headerHeight="auto"
              footerHeight="50"
              rowHeight="auto"
              [externalSorting]="true"
              [externalPaging]="true"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              (activate)="updateBrand($event)"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down'
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                name="{{ 'brands-list.datatable.columns.color' | translate }}"
                prop="color"
                headerClass="center-align"
                cellClass="center-align top-align hide-overflow"
                [sortable]="false"
                [flexGrow]="1"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-color="row.color">
                  <ui-color-sticker class="flex" [color]="row.color"></ui-color-sticker>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'brands-list.datatable.columns.name' | translate }}"
                prop="name"
                cellClass="name top-align hide-overflow"
                [flexGrow]="1"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'brands-list.datatable.columns.reference' | translate }}"
                prop="reference"
                cellClass="reference top-align"
                [flexGrow]="1"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'brands-list.datatable.columns.address' | translate }}"
                prop="address"
                cellClass="address top-align"
                [flexGrow]="1"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'brands-list.datatable.columns.phone' | translate }}"
                prop="phone"
                cellClass="phone top-align"
                [flexGrow]="1"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'brands-list.datatable.columns.email' | translate }}"
                prop="email"
                cellClass="email top-align"
                [flexGrow]="1"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'brands-list.datatable.columns.suppliers' | translate }}"
                cellClass="supplier top-align ellipsis-counter"
                [flexGrow]="1.4"
                [resizeable]="false"
                [sortable]="false"
                prop="suppliers"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-suppliers="row.suppliers">
                  <p class="ellipsis">{{ suppliers.join(", ") }}</p>
                  <div *ngIf="suppliers.length >= 2">
                    <div #supplierCounter>
                      <ui-counter [number]="suppliers.length"></ui-counter>
                    </div>
                    <ui-tooltip maxWidth="300px" [element]="supplierCounter">
                      <span style="font-weight: normal !important">{{ suppliers.join(", ") }}</span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'brands-list.datatable.columns.tags' | translate }}"
                cellClass="ellipsis-counter top-align"
                [flexGrow]="1"
                [resizeable]="false"
                [sortable]="false"
                prop="tags"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-tags="row.tags">
                  <div class="tags-content">
                    <div *ngFor="let tag of tags">
                      <ui-tag [label]="tag.label" [color]="tag.color" customClass="blackLabel"></ui-tag>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [flexGrow]="0.3" [resizeable]="false" [sortable]="false" cellClass="top-align">
                <ng-template ngx-datatable-cell-template let-row="row" let-tags="row.tags">
                  <div *ngIf="tags?.length >= 2">
                    <div #tagsCounter>
                      <ui-counter [number]="tags.length"></ui-counter>
                    </div>
                    <ui-tooltip [element]="tagsCounter">
                      <span *ngFor="let tag of tags" style="font-weight: normal !important"
                        ><ui-tag [label]="tag.label" [color]="tag.color" customClass="blackLabel"></ui-tag
                      ></span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'brands-list.datatable.columns.activated' | translate }}"
                prop="activated"
                headerClass="center-align"
                cellClass="center-align top-align"
                [flexGrow]="1"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                  <div class="green-zone" (click)="$event.stopPropagation()">
                    <ui-switch
                      customClass="no-margin"
                      (change)="onArchiveCheckboxChanges(id)"
                      [formControl]="tableControl.get('activated_' + id)"
                    >
                    </ui-switch>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>
    </div>
  </div>
</ng-container>
