<div class="popup-container">
  <ui-popup [title]="getPopupTitle()" (close)="closePopup()">
    <div content>
      <div class="fields-container">
        <form class="edit-stock-form" [formGroup]="popupForm" novalidate>
          <div class="size-value">
            <ui-input-text
              [errorMessage]="popupForm.get('sizeValue') | formErrorMessage | translate"
              formControlName="sizeValue"
              [label]="'edit-stock-popup.fields.size-value' | translate"
              [mandatory]="sizeValueMandatory()"
              [status]="popupForm.get('sizeValue') | formStatus"
              type="text"
            >
            </ui-input-text>
          </div>

          <div *ngIf="isBatchOrBulk()" class="warning-messages">
            <fa-icon [icon]="warnIcon" class="status-icon"></fa-icon>
            <div class="status-message">
              <p>
                <strong>{{ "edit-stock-popup.warning.title" | translate }}</strong>
              </p>
              <p>{{ "edit-stock-popup.warning.message" | translate }}</p>
            </div>
          </div>

          <div class="weight">
            <ui-input-text
              [type]="'text'"
              [suffix]="' g'"
              [label]="'edit-stock-popup.fields.total-weight' | translate"
              [maskPattern]="decimalDigitHigh"
              [mandatory]="weightMandatory()"
              formControlName="weight"
              [status]="popupForm.get('weight') | formStatus"
              [errorMessage]="
                popupForm.get('weight')
                  | formErrorMessage: { customPrefix: 'edit-stock-popup.errors.', useCustomPrefixFor: ['low'] }
                  | translate: { digit: integerDigitHigh }
              "
            ></ui-input-text>
          </div>
          <div class="tare">
            <ui-input-text
              [type]="'text'"
              [suffix]="' g'"
              [label]="'edit-stock-popup.fields.tare' | translate"
              [maskPattern]="decimalDigitHigh"
              [mandatory]="tareMandatory()"
              formControlName="tare"
              [status]="popupForm.get('tare') | formStatus"
              [errorMessage]="
                popupForm.get('tare')
                  | formErrorMessage
                    : { customPrefix: 'edit-stock-popup.errors.', useCustomPrefixFor: ['min', 'surpassWeight'] }
                  | translate: { digit: integerDigitHigh }
              "
            ></ui-input-text>
          </div>
          <div class="comment">
            <ui-textarea
              [label]="'edit-stock-popup.fields.comment' | translate"
              formControlName="comment"
              [mandatory]="commentMandatory()"
              [status]="popupForm.get('comment') | formStatus"
              [errorMessage]="popupForm.get('comment') | formErrorMessage | translate"
            ></ui-textarea>
          </div>
        </form>
      </div>
      <div class="footer">
        <div class="align-buttons">
          <div class="button-popup-validation">
            <ui-button
              [text]="'edit-stock-popup.button.validate' | translate"
              [customClass]="'fugu-button-save'"
              [btnIcon]="faCheck"
              [primary]="true"
              (click)="submitPopup()"
            ></ui-button>
          </div>
          <div class="button-popup-restore">
            <ui-button
              [text]="'edit-stock-popup.button.restore' | translate"
              [customClass]="'fugu-button-restore'"
              [btnIcon]="faRotateLeft"
              (click)="restoreData()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
