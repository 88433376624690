<div class="shipment-stock-entry-selection-popup-container popup-container frozen-left" novalidate>
  <ui-popup title="{{ 'shipment-stock-entry-selection.title' | translate }}" (close)="closePopup()">
    <div content>
      <div class="filters" *ngIf="filterer">
        <ui-dynamic-filters
          label="{{ 'filters.label' | translate }}"
          [filters]="filterer.getFilters()"
          [(filterValues)]="filterer.filterValues"
          (apply)="applyFilters()"
          applyLabel="{{ 'filters.apply' | translate }}"
          minLabel="{{ 'filters.min' | translate }}"
          maxLabel="{{ 'filters.max' | translate }}"
          deleteLabel="{{ 'filters.delete' | translate }}"
          placeholderPrefix="{{ 'filters.prefix' | translate }} "
        >
        </ui-dynamic-filters>
      </div>

      <div class="shipment-stock-entry-selection-table">
        <div [formGroup]="form">
          <ngx-datatable
            #table
            [rows]="rows"
            rowHeight="40"
            class="material"
            footerHeight="50"
            scrollbarH="true"
            columnMode="force"
            trackByProp="id"
            headerHeight="auto"
            [rowClass]="getRowClass"
            [limit]="pager.size"
            [offset]="pager.number"
            [externalPaging]="true"
            [externalSorting]="true"
            [count]="pager.totalElements"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
          >
            <ngx-datatable-column
              width="37"
              [sortable]="false"
              [frozenLeft]="true"
              [draggable]="false"
              [resizeable]="false"
              [canAutoResize]="false"
              cellClass="center-align frozen-style"
            >
              <ng-template ngx-datatable-header-template>
                <div class="green-zone">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="onHeaderCheckboxChange()"
                    [formControlName]="'headerCheckbox'"
                  >
                  </ui-checkbox>
                </div>
              </ng-template>

              <ng-template ngx-datatable-cell-template let-id="row.id">
                <div class="adjust green-zone">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="onRowCheckboxChange()"
                    [formControlName]="getRowControlName(id)"
                  >
                  </ui-checkbox>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ng-container>
              <ngx-datatable-column
                [cellClass]="'quantity-warning'"
                [sortable]="false"
                [resizeable]="false"
                [draggable]="false"
                width="37"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-shipmentRefs="row.shipmentRefs">
                  <ng-container *ngIf="shipmentRefs ? shipmentRefs.length > 0 : false">
                    <div #warnIcon>
                      <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>

                      <ui-tooltip maxWidth="125px" [element]="warnIcon">
                        <span style="font-weight: normal !important">
                          {{
                            "shipment-stock-entry-selection.datatable.warning.quantity"
                              | translate: { shipmentRefs: shipmentRefs }
                          }}
                        </span>
                      </ui-tooltip>
                    </div>
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.itemCategoryName' | translate }}"
              prop="stockEntry.retailItem.category.name"
              [resizeable]="false"
              [cellClass]="'item-category-name'"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-cell-template
                let-disabled="row.disabled"
                let-itemCategoryName="row.itemCategoryName"
              >
                <span [class.deactivated]="disabled">{{ itemCategoryName }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.serialNumber' | translate }}"
              prop="serial.stockEntry.id"
              [resizeable]="false"
              [cellClass]="'serial-number'"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-disabled="row.disabled" let-serialNumber="row.serialNumber">
                <span [class.deactivated]="disabled">{{ serialNumber }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.batchNumber' | translate }}"
              prop="batch.stockEntry.id"
              [resizeable]="false"
              [cellClass]="'batch-number'"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-disabled="row.disabled" let-batchNumber="row.batchNumber">
                <span [class.deactivated]="disabled">{{ batchNumber }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.supplierRef' | translate }}"
              prop="stockEntry.supplierRef"
              [resizeable]="false"
              [cellClass]="'supplier-ref'"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-disabled="row.disabled" let-supplierRef="row.supplierRef">
                <span [class.deactivated]="disabled">{{ supplierRef }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.supplierName' | translate }}"
              prop="stockEntry.supplierName"
              [resizeable]="false"
              [cellClass]="'supplier-name'"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-disabled="row.disabled" let-supplierName="row.supplierName">
                <span [class.deactivated]="disabled">{{ supplierName }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.itemReference' | translate }}"
              prop="stockEntry.retailItem.reference"
              [resizeable]="false"
              [cellClass]="'item-reference'"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-cell-template
                let-disabled="row.disabled"
                let-itemReference="row.itemReference"
              >
                <span [class.deactivated]="disabled">{{ itemReference }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.quantity' | translate }}"
              prop="quantity"
              [resizeable]="false"
              [cellClass]="'quantity'"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-cell-template
                let-disabled="row.disabled"
                let-quantity="row.quantity"
                let-uom="row.uom"
              >
                <span [class.deactivated]="disabled">{{ quantity + " " + uom }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.sizeValue' | translate }}"
              prop="stockEntry.sizeValue"
              [resizeable]="false"
              [cellClass]="'size-value'"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-disabled="row.disabled" let-sizeValue="row.sizeValue">
                <span [class.deactivated]="disabled">{{ sizeValue }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.itemName' | translate }}"
              prop="stockEntry.retailItem.name"
              [resizeable]="false"
              [cellClass]="'item-name'"
              [draggable]="false"
              [width]="150"
            >
              <ng-template ngx-datatable-cell-template let-disabled="row.disabled" let-itemName="row.itemName">
                <div [class.deactivated]="disabled" #itemNameElement class="ellipsis">
                  {{ itemName }}
                  <ui-tooltip [element]="itemNameElement" *ngIf="itemNameElement">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ itemName }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.itemBrandName' | translate }}"
              prop="stockEntry.retailItem.brand.id"
              [resizeable]="false"
              [cellClass]="'item-brand-name'"
              [draggable]="false"
            >
              <ng-template
                ngx-datatable-cell-template
                let-disabled="row.disabled"
                let-itemBrandName="row.itemBrandName"
              >
                <span [class.deactivated]="disabled">{{ itemBrandName }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.location' | translate }}"
              prop="location"
              [resizeable]="false"
              [cellClass]="'location'"
              [draggable]="false"
              [width]="150"
            >
              <ng-template ngx-datatable-cell-template let-disabled="row.disabled" let-location="row.location">
                <div [class.deactivated]="disabled" #locationElement class="ellipsis">
                  {{ location }}

                  <ui-tooltip [element]="locationElement" *ngIf="locationElement">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ location }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'shipment-stock-entry-selection.datatable.columns.sellPrice' | translate }}"
              prop="stockEntry.sellPrice"
              [resizeable]="false"
              [cellClass]="'sell-price'"
              [draggable]="false"
            >
              <ng-template let-sellPrice="row.sellPrice" ngx-datatable-cell-template let-disabled="row.disabled">
                <span *ngIf="sellPrice !== null" [class.deactivated]="disabled">
                  {{ sellPrice | formatCurrency: orderCurrency.codeISO:"symbol":"0.2-5":codeLanguage }}
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-offset="offset"
                let-curPage="curPage"
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-isVisible="isVisible"
                let-selectedCount="selectedCount"
              >
                <div class="page-count">
                  <span
                    [innerHTML]="
                      selectedElementsNumber === 0
                        ? ('global.datatable.no-items' | translate)
                        : (selectedElementsNumber
                          | i18nPlural
                            : {
                                '=1': 'global.datatable.n-selected-items',
                                other: 'global.datatable.n-selected-items_plural'
                              }
                          | translate: { selectedElements: selectedElementsNumber })
                    "
                  >
                  </span>
                </div>
                <datatable-pager
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="changePage($event)"
                  [count]="rowCount"
                  [size]="pageSize"
                  [page]="curPage"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button">
          <ui-button
            text="{{ 'shipment-stock-entry-selection.select-products' | translate }}"
            [primary]="true"
            customClass="fugu-button-save"
            (click)="submit()"
            [btnIcon]="faCheck"
            [disabled]="false"
          ></ui-button>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
