import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Classification, ClassificationService, ClassificationValue } from "center-services";

@Component({
  selector: "app-retail-item-classifications",
  templateUrl: "./retail-item-classifications.component.html",
  styleUrls: ["./retail-item-classifications.component.scss"],
  providers: [SubscriptionService],
})
export class RetailItemClassificationsComponent implements OnInit {
  @Output() classificationValueIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Input() classificationValueIds: number[];
  @Input() categoryId: number;

  public classificationOptions: Map<string, Option[]> = new Map();
  public classificationList: Classification[] = [];
  public form: UntypedFormGroup;

  constructor(
    private classificationService: ClassificationService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }
    this.fetchClassifications();
  }

  fetchClassifications(): void {
    this.subscriptionService.subs.push(
      this.classificationService.getByCategory(this.categoryId).subscribe(
        (classificationList: Classification[]) => {
          this.classificationList = classificationList.filter(classification => !classification.archived);

          this.classificationList.forEach((classification: Classification) => {
            let options = [new Option(null, "-")];

            options = options.concat(
              classification.elements
                .filter((obj: ClassificationValue) => !obj.archived)
                .map((obj: ClassificationValue) => new Option(obj.id, obj.value))
            );

            // search for used value in current elements
            const value = classification.elements.find(element => {
              return this.classificationValueIds.some((id: number) => element.id === id);
            });

            this.classificationOptions.set(classification.name, options);
            this.form.addControl(classification.name, new UntypedFormControl(value ? value.id : null));
          });
        },
        error => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("item.classifications.errors.get-classifications", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  updateValue(): void {
    this.classificationValueIds = [];

    Object.keys(this.form.controls).forEach((control: string) => {
      if (this.form.get(control).value) {
        this.classificationValueIds.push(this.form.get(control).value);
      }
    });
    this.classificationValueIdsChange.emit(this.classificationValueIds);
  }
}
