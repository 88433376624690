import { DragDrop, DragRef, DropListRef, moveItemInArray } from "@angular/cdk/drag-drop";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { Positionable } from "center-services";

@Injectable({
  providedIn: "root",
})
export class NgxDatatableDragger<T extends Positionable> {
  protected dropListRef: DropListRef;
  protected referenceItemList: T[];
  protected refreshRows: () => void;

  protected ngDatatableElement: HTMLImageElement;
  protected onPositionUpdate: (p: T) => Observable<any>;

  protected dragRefs: DragRef[] = [];

  protected readonly DATATABLE_BODY_SELECTOR: string = ".datatable-body";
  protected readonly DATATABLE_ROW_WRAPPER: string = "DATATABLE-ROW-WRAPPER";
  protected readonly DRAGGED_ITEM_SELECTOR: string = "ngx-datatable-dragger-dummy-witness";
  protected readonly GRIP_CELL_SELECTOR: string = ".grip-cell";

  public constructor(protected dragDropService: DragDrop) {}

  public init(
    ngDatatableElement: HTMLImageElement,
    referenceItemList: T[],
    onPositionUpdate: (p: T) => Observable<any>,
    refreshRows: () => void
  ): void {
    this.ngDatatableElement = ngDatatableElement;
    this.referenceItemList = referenceItemList;
    this.onPositionUpdate = onPositionUpdate;
    this.refreshRows = refreshRows;

    const ngxBody: HTMLImageElement = this.ngDatatableElement.querySelector(this.DATATABLE_BODY_SELECTOR);
    if (!ngxBody) {
      console.error("Could not init the NgxDatatable dragger");
      return;
    }

    this.dragRefs = [];
    this.dropListRef = this.dragDropService.createDropList(ngxBody);
    this.dropListRef.lockAxis = "y";

    this.setDragNDropBehavior();

    const observer = new MutationObserver(list => {
      this.detectNewRows(list);
    });

    observer.observe(ngxBody, {
      attributes: false,
      childList: true,
      subtree: true,
    });
  }

  /**
   * To use when you can delete rows from table. The function clean references of lines that has been destroyed.
   */
  public refreshDragRefs(): void {
    this.dragRefs = this.dragRefs.filter(ref => document.contains(ref.getRootElement()));
  }

  protected setDragNDropBehavior(): void {
    this.dropListRef.dropped.pipe(filter(event => event.previousIndex !== event.currentIndex)).subscribe(event => {
      this.updatePosition(event.previousIndex, event.currentIndex);
    });
  }

  protected updatePosition(currentIndex: number, newIndex: number): void {
    const updatingReferenceItem = this.referenceItemList[currentIndex];
    if (updatingReferenceItem.position !== currentIndex + 1) {
      console.error("Moving too fast !");
      return;
    }

    moveItemInArray(this.referenceItemList, currentIndex, newIndex);
    updatingReferenceItem.position = newIndex + 1;
    this.refreshRows();

    this.onPositionUpdate(updatingReferenceItem).subscribe({
      error: () => {
        // revert
        moveItemInArray(this.referenceItemList, newIndex, currentIndex);
        updatingReferenceItem.position = currentIndex + 1;
        this.refreshRows();
      },
      complete: () => {
        // update all other positions
        for (let i = currentIndex; i !== newIndex; i += currentIndex > newIndex ? -1 : 1) {
          this.referenceItemList[i].position += currentIndex > newIndex ? 1 : -1;
        }
      },
    });
  }

  protected customForbiddenElement(_element: HTMLElement): boolean {
    return false;
  }

  protected detectNewRows(records: MutationRecord[]): void {
    records.forEach((record: MutationRecord) =>
      record.addedNodes.forEach((addedNode: Node) => {
        if (addedNode.nodeName !== this.DATATABLE_ROW_WRAPPER || addedNode.nodeType !== Node.ELEMENT_NODE) {
          return;
        }

        const addedElement = addedNode as HTMLElement;

        if (addedElement.classList.contains(this.DRAGGED_ITEM_SELECTOR)) {
          return;
        }

        if (this.customForbiddenElement(addedElement)) {
          return;
        }

        addedElement.classList.add(this.DRAGGED_ITEM_SELECTOR);

        const dragRef = this.dragDropService.createDrag(addedElement);
        const handle: HTMLImageElement = addedElement.querySelector(this.GRIP_CELL_SELECTOR);
        dragRef.withHandles([handle]);
        this.dragRefs.push(dragRef);

        this.dropListRef.withItems(this.dragRefs);
      })
    );
  }
}
