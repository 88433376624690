import { Comparator } from "fugu-common";

export class FreeInvoiceCustomerInitiatorOutput extends Comparator {
  customerId: number;
  storeId: number;

  constructor(values: any = {}) {
    super();
    this.customerId = values?.customerId;
    this.storeId = values?.storeId;
  }
}
