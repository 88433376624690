import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PurchaseOrder, PurchaseOrderService, PurchaseOrderStatus } from "center-services";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-purchase-order-status-popup",
  templateUrl: "./purchase-order-status-popup.component.html",
  styleUrls: ["./purchase-order-status-popup.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderStatusPopupComponent implements OnInit, OnChanges {
  @Input() purchaseOrderList: PurchaseOrder[];

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public initialStatus: string = "";
  public unsavedStatus: string = "";
  public editedStatus: string = "";
  public popupForm: UntypedFormGroup;

  public shouldClose: boolean = false;
  public popupTitle: string;
  public statusQuestion: string;

  public statusOptions: Option[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService,
    private purchaseOrderService: PurchaseOrderService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnChanges(): void {
    this.prepareForm();
    this.editedStatus = "";
    this.initialStatus = "";
    if (this.purchaseOrderList) {
      this.editedStatus = this.purchaseOrderList[0].status;
      this.initialStatus = this.purchaseOrderList[0].status;
    }

    this.popupTitle = this.translateService.instant("purchase-order.status-popup.title");
    this.statusQuestion = this.translateService.instant("purchase-order.status-popup.question");

    this.refresh();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.buildStatusOptions();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildStatusOptions();
      })
    );
    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      status: [0, [Validators.required]],
    });
  }

  buildStatusOptions(): void {
    if (this.purchaseOrderList && this.purchaseOrderList.length > 0) {
      this.statusOptions = [];
      const emptyLinesOrders = this.purchaseOrderList.filter(po => po.lines.length === 0);
      const rootStr = "purchase-order.header.order-status-options";
      switch (this.editedStatus) {
        case "DRAFT":
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("DRAFT"),
              this.translateService.instant(`${rootStr}.DRAFT`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("CANCELED"),
              this.translateService.instant(`${rootStr}.CANCELED`)
            )
          );
          if (emptyLinesOrders.length === 0) {
            this.statusOptions.push(
              new Option(
                Object.keys(PurchaseOrderStatus).indexOf("CONFIRMED"),
                this.translateService.instant(`${rootStr}.CONFIRMED`)
              )
            );
          }
          break;
        case "CONFIRMED":
        case "CANCELED":
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("DRAFT"),
              this.translateService.instant(`${rootStr}.DRAFT`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("CANCELED"),
              this.translateService.instant(`${rootStr}.CANCELED`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("CONFIRMED"),
              this.translateService.instant(`${rootStr}.CONFIRMED`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("SENT"),
              this.translateService.instant(`${rootStr}.SENT`)
            )
          );
          break;
        case "SENT":
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("RECEIVED"),
              this.translateService.instant(`${rootStr}.RECEIVED`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("CANCELED"),
              this.translateService.instant(`${rootStr}.CANCELED`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("CONFIRMED"),
              this.translateService.instant(`${rootStr}.CONFIRMED`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("SENT"),
              this.translateService.instant(`${rootStr}.SENT`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("PARTIALLY_RECEIVED"),
              this.translateService.instant(`${rootStr}.PARTIALLY_RECEIVED`)
            )
          );
          break;
        case "PARTIALLY_RECEIVED":
        case "RECEIVED":
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("RECEIVED"),
              this.translateService.instant(`${rootStr}.RECEIVED`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("PARTIALLY_RECEIVED"),
              this.translateService.instant(`${rootStr}.PARTIALLY_RECEIVED`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("SETTLED"),
              this.translateService.instant(`${rootStr}.SETTLED`)
            )
          );
          break;
        case "SETTLED":
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("PARTIALLY_RECEIVED"),
              this.translateService.instant(`${rootStr}.PARTIALLY_RECEIVED`)
            )
          );
          this.statusOptions.push(
            new Option(
              Object.keys(PurchaseOrderStatus).indexOf("SETTLED"),
              this.translateService.instant(`${rootStr}.SETTLED`)
            )
          );
          break;
        default:
          console.error(`Cannot handle StatusType: ${this.editedStatus}`);
          break;
      }
    }
  }

  initializePopup(): void {
    if (this.purchaseOrderList && this.purchaseOrderList.length > 0) {
      const index = Object.keys(PurchaseOrderStatus).indexOf(this.editedStatus);
      this.popupForm.controls.status.setValue(index);
    }
  }

  closeStatusPopup(): void {
    this.editedStatus = Object.values(PurchaseOrderStatus)[this.popupForm.value.status];

    if (this.unsavedStatus) {
      const hasChangedForUnsaved = this.editedStatus === this.unsavedStatus ? false : true;
      if (hasChangedForUnsaved) {
        this.shouldClose = false;
      }
    }

    const hasChangedForInitial = this.editedStatus === this.initialStatus ? false : true;

    if (hasChangedForInitial && !this.shouldClose) {
      this.unsavedStatus = "";
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedStatus = this.editedStatus;
    } else {
      this.close.emit();
      this.unsavedStatus = null;
      this.shouldClose = false;
    }
  }

  submitStatusPopup(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    this.editedStatus = Object.values(PurchaseOrderStatus)[this.popupForm.value.status];
    const obs = [];

    if (this.editedStatus === this.initialStatus) {
      this.close.emit();
      return;
    }

    this.purchaseOrderList.forEach(purchaseOrder => {
      purchaseOrder.status = Object.values(PurchaseOrderStatus)[this.popupForm.value.status];
      obs.push(this.update(purchaseOrder));
    });

    this.subscriptionService.subs.push(
      combineLatest(obs).subscribe(
        () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          this.validate.emit();
        },
        () => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("purchase-order.status-popup.errors.update");
          this.messageService.error(content, { title });
        }
      )
    );
  }

  update(purchaseOrder: PurchaseOrder): Observable<PurchaseOrder> {
    return this.purchaseOrderService.update(purchaseOrder).pipe(
      tap(responsePurchaseOrder => {
        this.editedStatus = responsePurchaseOrder.status;
      })
    );
  }
}
