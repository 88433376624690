<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'app-config.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <ui-button
        text="{{ 'global.validate' | translate }}"
        [primary]="true"
        type="save"
        (click)="onSubmit()"
      ></ui-button>
    </div>
    <div class="form-content">
      <form class="app-config-form" [formGroup]="form" novalidate>
        <ui-container *ngIf="rights.includes('GOD')">
          <div class="bottom-bordered">
            <div class="item-title">{{ "app-config.reception-type.title" | translate }}</div>

            <div class="warning-container">
              <div class="warning-icon">
                <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
              </div>
              <div class="warning-message">
                <div class="warning-title">{{ "app-config.reception-type.warning.title" | translate }}</div>
                <div class="warning-text">{{ "app-config.reception-type.warning.text" | translate }}</div>
              </div>
            </div>

            <div class="item-subtitle">{{ "app-config.reception-type.subtitle" | translate }}</div>
            <div class="item-data">
              <ui-radio-button
                [options]="this.receptionTypeOptions"
                name="receptionType"
                formControlName="receptionType"
                mandatory="true"
                customClass="no-margin"
              ></ui-radio-button>
            </div>
          </div>
        </ui-container>
        <ui-container>
          <div class="bottom-bordered">
            <div class="item-title">{{ "app-config.conformity.title" | translate }}</div>
            <div class="warning-container">
              <div class="warning-icon">
                <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
              </div>
              <div class="warning-message">
                <div class="warning-title">{{ "app-config.conformity.warning.title" | translate }}</div>
                <div class="warning-text">
                  <ul>
                    <li>{{ "app-config.conformity.warning.one" | translate }}</li>
                    <li>{{ "app-config.conformity.warning.two" | translate }}</li>
                  </ul>
                </div>
                <ui-switch
                  type="checkbox"
                  label="{{ 'app-config.conformity.label' | translate }}"
                  formControlName="conformity"
                  (change)="open($event)"
                >
                </ui-switch>
                <p *ngIf="editedAppConfig?.isConform()">
                  {{ "app-config.conformity.message-date" | translate }}
                  {{ this.editedAppConfig.conformityActivationDate | dateTimeFormat: false }}
                </p>
              </div>
            </div>
          </div>
        </ui-container>
        <ui-container *ngIf="rights.includes('GOD')">
          <div class="bottom-bordered" *ngIf="this.stockEntryLabelOptions" style="margin-top: 0">
            <div class="item-title">{{ "app-config.stock-entry-label.title" | translate }}</div>
            <div style="width: 300px">
              <ui-list
                label="{{ 'app-config.stock-entry-label.input-label' | translate }}"
                [options]="this.stockEntryLabelOptions"
                formControlName="stockEntryLabel"
                [status]="form.get('stockEntryLabel') | formStatus"
                [errorMessage]="form.get('stockEntryLabel') | formErrorMessage | translate"
                [search]="true"
              ></ui-list>
            </div>
          </div>
        </ui-container>
      </form>
    </div>
  </div>

  <ui-popup
    title="{{ 'app-config.conformity.popup.title' | translate }}"
    *ngIf="conformityPopupVisible"
    (close)="cancelConformityPopup()"
  >
    <div content>
      <div class="warning-container">
        <div class="warning-icon">
          <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
        </div>
        <div class="warning-message">
          <div class="warning-title">{{ "app-config.conformity.popup.warning.title" | translate }}</div>
          <div class="warning-text">{{ "app-config.conformity.popup.warning.text" | translate }}</div>
        </div>
      </div>

      <strong>{{ "app-config.conformity.popup.question" | translate }}</strong>
    </div>
    <div footer>
      <div [class]="'align-buttons'">
        <div [class]="'button-popup-validate'">
          <div [class]="'button'">
            <ui-button
              [text]="'app-config.conformity.popup.yes' | translate"
              [primary]="true"
              type="validate"
              (click)="closeConformityPopup()"
            ></ui-button>
          </div>
        </div>
        <div [class]="'button-popup-cancel'">
          <div [class]="'button second-button'">
            <ui-button
              [text]="'app-config.conformity.popup.no' | translate"
              type="cancel"
              (click)="cancelConformityPopup()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</ng-container>
