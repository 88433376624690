<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'store-brands-list.title' | translate }}"></ui-title>
    </div>
    <div class="button">
      <ui-button
        text="{{ 'global.new' | translate }}"
        type="add"
        [primary]="true"
        (click)="onNewEntityClick()"
        *hasPermission="['STORE_BRAND_CREATE']"
      >
      </ui-button>
    </div>
    <div class="store-brands-table">
      <ui-container>
        <div [formGroup]="tableControl">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            class="material"
            columnMode="force"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [limit]="15"
            (activate)="onTableActivate($event)"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'store-brands-list.datatable.columns.name' | translate }}"
              prop="name"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'store-brands-list.datatable.columns.color' | translate }}"
              prop="color"
              headerClass="center-align"
              cellClass="center-align"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-color="row.color">
                <ui-color-sticker class="flex" [color]="row.color"></ui-color-sticker>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'store-brands-list.datatable.columns.activated' | translate }}"
              prop="activated"
              headerClass="center-align"
              cellClass="center-align"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-switch
                    customClass="no-margin"
                    (change)="changeEntityActivation(id)"
                    [formControl]="tableControl.get('activated_' + id)"
                  >
                  </ui-switch>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>
    <form class="store-brand-form" [formGroup]="popupForm" novalidate>
      <div class="popup-container">
        <ui-popup title="{{ popupTitle | translate }}" *ngIf="popupVisible" (close)="canClosePopup()" class="popup">
          <div content>
            <div class="grid-container">
              <div class="grid-item">
                <ui-input-text
                  type="text"
                  label="{{ 'store-brands-list.datatable.columns.name' | translate }}"
                  formControlName="name"
                  [status]="popupForm.get('name') | formStatus"
                  [errorMessage]="popupForm.get('name') | formErrorMessage | translate"
                  mandatory="true"
                  infoLabel="{{ 'global.unique' | translate }}"
                >
                </ui-input-text>
              </div>
              <div class="grid-item">
                <ui-color-picker
                  label="{{ 'store-brands-list.datatable.columns.color' | translate }}"
                  format="hex"
                  formControlName="color"
                  [errorMessage]="popupForm.get('color') | formErrorMessage | translate"
                  mandatory="true"
                >
                </ui-color-picker>
              </div>
              <div class="grid-item logo-general">
                <ui-input-file
                  [id]="'file'"
                  buttonText="{{ 'global.input-file.btn' | translate }}"
                  descriptionText="{{ 'global.input-file.drag-drop' | translate }}"
                  accept=".png, .jpeg, .jpg, .JPG, .JPEG, .PNG"
                  label="{{ 'store-brands-list.popup.logo-label' | translate }}"
                  formControlName="logoFile"
                >
                </ui-input-file>
              </div>
            </div>
          </div>
          <div footer>
            <div class="align-buttons">
              <div class="button-popup-validate">
                <div class="button">
                  <ui-button
                    text="{{ 'global.validate' | translate }}"
                    type="save"
                    [primary]="true"
                    (click)="submitStoreBrand()"
                  ></ui-button>
                </div>
              </div>
            </div>
          </div>
        </ui-popup>
      </div>
    </form>
  </div>
</ng-container>
