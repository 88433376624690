import { DocumentType, InvoiceCustomer, InvoiceCustomerLine, InvoiceStatus } from "center-services";
import { CreditNoteCustomerLine } from "./credit-note-customer-line";
import { CreditNoteCustomerTab } from "./credit-note-customer-tab";
import { Comparator } from "fugu-common";

export class CreditNoteCustomerFront extends Comparator {
  id: number;

  reference: string;
  documentType: DocumentType;
  comment: string;
  date: Date;
  invoiceStatus: InvoiceStatus;

  currencyId: number;
  taxPrice: number;
  totalGrossPrice: number;
  totalPrice: number;
  totalQuantity: number;

  lines: any | Map<number, CreditNoteCustomerLine[]> = new Map();
  tabs: Map<number, CreditNoteCustomerTab> = new Map();

  customerRef: string;
  contactId: number;

  constructor(values: InvoiceCustomer | CreditNoteCustomerFront | InvoiceCustomer[]) {
    super();
    if (values instanceof Array) {
      this.initFromInvoiceCustomers(values);
      return;
    }

    this.reference = values.reference;
    this.id = values.id;

    this.documentType = values.documentType;
    this.comment = values.comment;
    this.date = values.date instanceof Date ? new Date(values.date?.getTime()) : values.date;
    if (typeof values.date === "string") {
      this.date = new Date(values.date);
    }
    if (typeof values.invoiceStatus === "string") {
      this.invoiceStatus = this.parseInvoiceStatus(values.invoiceStatus);
    }

    this.currencyId = values.currencyId;
    this.taxPrice = values.taxPrice;
    this.totalGrossPrice = values.totalGrossPrice;
    this.totalPrice = values.totalPrice;

    if (!(values.lines instanceof Map)) {
      this.lines = values.lines;
    } else {
      values.lines.forEach((arrayOfLines: any, key: any) => {
        this.lines.set(
          key,
          arrayOfLines.map((line: CreditNoteCustomerLine) => new CreditNoteCustomerLine(line))
        );
      });
    }

    if (values?.tabs instanceof Map) {
      values.tabs.forEach((tab: any, key: any) => {
        this.tabs.set(key, new CreditNoteCustomerTab(tab));
      });
    }

    this.currencyId = values.currencyId;
    this.taxPrice = values.taxPrice;
    this.totalGrossPrice = values.totalGrossPrice;
    this.totalPrice = values.totalPrice;
    this.totalQuantity = values.totalQuantity;

    this.customerRef = values.customerRef;
    this.contactId = values.contactId;

    if (values instanceof InvoiceCustomer) {
      this.initFromInvoiceCustomer();
    }
  }

  converMapLinesToObject(lines: any): any {
    const obj: any = {};

    lines.forEach((arrayOfLines, key) => {
      if (!(key in obj)) {
        obj[key] = Array.from(arrayOfLines.values());
      }
    });

    return obj;
  }

  public toInvoiceCustomer(): InvoiceCustomer {
    const invoiceCustomer: InvoiceCustomer = new InvoiceCustomer({
      id: this.id,
      createdAt: this.createdAt,
      creator: this.creator,
      updatedAt: this.updatedAt,
      archived: this.archived,
      lastModifier: this.lastModifier,

      reference: this.reference,
      documentType: this.documentType,
      date: this.date,
      invoiceStatus: this.invoiceStatus,
      contactId: this.contactId,
      currencyId: this.currencyId,

      comment: this.comment,
      lines: this.converMapLinesToObject(this.lines),
      customerRef: this.customerRef,
    });
    return invoiceCustomer;
  }

  public addInvoiceCustomers(invoiceCustomers: InvoiceCustomer[]): void {
    invoiceCustomers.forEach((invoiceCustomer: InvoiceCustomer) => {
      const allLines: CreditNoteCustomerLine[] = [];
      Object.entries(invoiceCustomer?.lines).forEach(setOfLines => {
        const lines: InvoiceCustomerLine[] = setOfLines[1] as InvoiceCustomerLine[];
        lines.forEach((line: InvoiceCustomerLine) => {
          if (!line.creditNoteLineId) {
            const creditNoteCustomerLine: CreditNoteCustomerLine = new CreditNoteCustomerLine({
              ...line,
              id: undefined,
              lineNumber: allLines.length + 1,
              invoiceCustomerLineId: line.id,
            } as InvoiceCustomerLine);
            allLines.push(creditNoteCustomerLine);
          }
        });
      });
      this.lines.set(invoiceCustomer.id, allLines);
    });

    this.buildTabs(this.lines);
  }

  private convertObjectToMapLines(obj: any): void {
    const map = new Map<number, CreditNoteCustomerLine[]>();
    Object.entries(obj).forEach(([key, entry]: [string, any]) => {
      const mapKey = +key;
      const lines: CreditNoteCustomerLine[] = entry as CreditNoteCustomerLine[];
      if (!map.has(mapKey)) {
        map.set(mapKey, []);
      }
      lines.forEach((line: CreditNoteCustomerLine) => {
        const lineIndex: number = map.get(mapKey).findIndex((l: CreditNoteCustomerLine) => l.id === line.id);
        if (lineIndex !== -1) {
          map.get(mapKey)[lineIndex];
        } else {
          map.get(mapKey).push(line);
        }
      });
    });
    this.lines = map;
  }

  private buildTabs(lines: Map<number, CreditNoteCustomerLine[]>): void {
    lines.forEach((tab: any, key: any) => {
      if (!this.tabs.get(key)) {
        this.tabs.set(key, new CreditNoteCustomerTab(tab));
      }
    });
  }

  private parseInvoiceStatus(statusStr: string): InvoiceStatus {
    switch (statusStr) {
      case "EXPORTED":
        return InvoiceStatus.EXPORTED;
      case "DRAFT":
        return InvoiceStatus.DRAFT;
      case "VALIDATED":
        return InvoiceStatus.VALIDATED;
      default:
        console.error(`Cannot handle InvoiceStatus: ${statusStr}`);
        return undefined;
    }
  }

  private initFromInvoiceCustomer(): void {
    this.convertObjectToMapLines(this.lines);
    this.buildTabs(this.lines);
  }

  private initFromInvoiceCustomers(invoiceCustomers: InvoiceCustomer[]): void {
    this.documentType = DocumentType.CREDIT_NOTE;
    this.comment = "";
    this.invoiceStatus = InvoiceStatus.DRAFT;

    this.currencyId = invoiceCustomers[0].currencyId;
    this.taxPrice = invoiceCustomers[0].taxPrice;

    this.customerRef = invoiceCustomers[0].customerRef;
    this.contactId = invoiceCustomers[0].contactId;

    this.lines = new Map();
    invoiceCustomers.forEach((invoiceCustomer: InvoiceCustomer) => {
      const allLines: CreditNoteCustomerLine[] = [];
      Object.entries(invoiceCustomer?.lines).forEach(setOfLines => {
        const lines: InvoiceCustomerLine[] = setOfLines[1] as InvoiceCustomerLine[];
        lines.forEach((line: InvoiceCustomerLine) => {
          if (!line.creditNoteLineId) {
            const creditNoteCustomerLine: CreditNoteCustomerLine = new CreditNoteCustomerLine({
              ...line,
              id: undefined,
              lineNumber: allLines.length + 1,
              invoiceCustomerLineId: line.id,
            } as InvoiceCustomerLine);
            allLines.push(creditNoteCustomerLine);
          }
        });
      });
      this.lines.set(invoiceCustomer.id, allLines);
    });

    this.buildTabs(this.lines);
  }
}
