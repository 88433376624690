<ng-container>
  <div class="invoice-customer-initiator-popup-container" novalidate>
    <ui-popup title="{{ 'invoice-customer-initiator-popup.title.new' | translate }}" (close)="closePopup()">
      <div content>
        <div
          *ngIf="this.selectedInvoiceCustomerInitiatorOutput && this.storeCustomerLink.size === this.storeList.length"
        >
          <app-invoice-customer-delivery-selection
            [currency]="this.currency"
            [storeCustomerLink]="this.storeCustomerLink"
            [customerList]="this.customerList"
            [storeList]="this.storeList"
            [unModifiedStoreList]="this.unModifiedStoreList"
            [supplierList]="this.supplierList"
            [contactId]="this.contactId"
            [dfsAlreadySelected]="this.tabNameList"
            [(selectedDeliveryForms)]="this.selectedDeliveryForms"
            [isOneInvoicePerStoreEnabled]="this.oneInvoicePerStore"
            (checkedOneInvoicePerStoreCheckboxChange)="onOneInvoicePerStoreCheckboxChange($event)"
            (selectedDeliveryFormsChange)="onSelectedDeliveryFormsChange($event)"
          ></app-invoice-customer-delivery-selection>
        </div>
      </div>
      <div footer>
        <div class="button-popup-to-invoice">
          <ui-button
            text="{{ this.getButtonText() | translate }}"
            [primary]="true"
            (click)="submit()"
            [btnIcon]="faArrowCircleRight"
          ></ui-button>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
