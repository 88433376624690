<ng-container>
  <form class="supplier-general" [formGroup]="supplierForm" novalidate>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'supplier.general-datas.name' | translate }}"
        formControlName="name"
        mandatory="true"
        [status]="supplierForm.get('name') | formStatus"
        [errorMessage]="supplierForm.get('name') | formErrorMessage | translate"
        infoLabel="{{ 'global.unique' | translate }}"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'supplier.general-datas.reference' | translate }}"
        formControlName="reference"
        mandatory="true"
        [status]="supplierForm.get('reference') | formStatus"
        [errorMessage]="supplierForm.get('reference') | formErrorMessage | translate"
        infoLabel="{{ 'global.unique' | translate }}"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-textarea label="{{ 'supplier.general-datas.address' | translate }}" formControlName="lines"> </ui-textarea>
    </div>
    <div class="grid-item">
      <div class="multi-col">
        <div class="city-code">
          <ui-input-text
            type="text"
            label="{{ 'supplier.general-datas.city-code' | translate }}"
            formControlName="cityCode"
          >
          </ui-input-text>
        </div>
        <div class="city">
          <ui-input-text type="text" label="{{ 'supplier.general-datas.city' | translate }}" formControlName="city">
          </ui-input-text>
        </div>
      </div>
    </div>
    <div class="grid-item countries">
      <ui-list
        label="{{ 'supplier.general-datas.country' | translate }}"
        [options]="this.countryOptions"
        [search]="true"
        formControlName="country"
        mandatory="true"
        [status]="supplierForm.get('country') | formStatus"
        [errorMessage]="supplierForm.get('country') | formErrorMessage | translate"
      ></ui-list>
    </div>

    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'supplier.general-datas.email' | translate }}"
        formControlName="email"
        [status]="supplierForm.get('email') | formStatus"
        [errorMessage]="supplierForm.get('email') | formErrorMessage | translate"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'supplier.general-datas.phone' | translate }}"
        formControlName="phone"
        maskPattern="PN NN NN NN NN NN"
        [customPattern]="pattern"
        [specialCharacters]="[' ', '+']"
        [dropSpecialCharacters]="[' ']"
      >
      </ui-input-text>
    </div>

    <div class="grid-item">
      <ui-input-text type="text" label="{{ 'supplier.general-datas.website' | translate }}" formControlName="website">
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-input-text type="text" label="{{ 'supplier.general-datas.intranet' | translate }}" formControlName="intranet">
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-input-text type="text" label="{{ 'supplier.general-datas.codeRCS' | translate }}" formControlName="codeRCS">
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-list
        label="{{ 'supplier.general-datas.currency' | translate }}"
        [options]="this.currencyOptions"
        formControlName="currency"
        mandatory="true"
        [status]="supplierForm.get('currency') | formStatus"
        [errorMessage]="supplierForm.get('currency') | formErrorMessage | translate"
      ></ui-list>
    </div>
    <div class="grid-item">
      <div class="double-col">
        <div class="main-account">
          <ui-input-text
            type="text"
            label="{{ 'supplier.general-datas.accounting-number' | translate }}"
            formControlName="accountingNumber"
          >
          </ui-input-text>
        </div>
        <div class="secondary-account">
          <ui-input-text
            type="text"
            label="{{ 'supplier.general-datas.accounting-number-bis' | translate }}"
            formControlName="accountingNumberBis"
          >
          </ui-input-text>
        </div>
      </div>
    </div>
    <div class="grid-item">
      <ui-input-text type="text" label="{{ 'supplier.general-datas.codeVAT' | translate }}" formControlName="codeVAT">
      </ui-input-text>
    </div>

    <div class="grid-item">
      <div class="manage-remainder-switch">
        <ui-switch
          label="{{ 'supplier.general-datas.manage-remainder' | translate }}"
          class="manage-remainder-switch"
          customClass="no-margin"
          formControlName="manageRemainder"
          mandatory="true"
          [status]="supplierForm.get('manageRemainder') | formStatus"
          [errorMessage]="supplierForm.get('manageRemainder') | formErrorMessage | translate"
        >
        </ui-switch>
      </div>
    </div>

    <div class="grid-item white-and-color">
      <div class="double-col">
        <div class="white-label">
          <ui-radio-button
            label="{{ 'supplier.general-datas.white-label' | translate }}"
            name="whiteLabel"
            formControlName="whiteLabel"
            [options]="this.whiteLabelOptions"
            direction="horizontal"
            mandatory="true"
            [status]="supplierForm.get('whiteLabel') | formStatus"
            [errorMessage]="supplierForm.get('whiteLabel') | formErrorMessage | translate"
          ></ui-radio-button>
        </div>
        <div class="color">
          <ui-color-picker
            label="{{ 'supplier.general-datas.color' | translate }}"
            format="hex"
            [formControl]="supplierForm.get('color')"
          >
          </ui-color-picker>
        </div>
      </div>
    </div>

    <div class="grid-item logo-container">
      <ui-input-file
        id="'file'"
        accept=".png, .jpeg, .jpg, .JPG, .JPEG, .PNG"
        smallSize="true"
        label="{{ 'supplier.general-datas.logo-label' | translate }}"
        buttonText="{{ 'global.input-file.btn' | translate }}"
        descriptionText="{{ 'global.input-file.drag-drop' | translate }}"
        formControlName="logoFile"
      ></ui-input-file>
    </div>

    <div class="grid-item comment">
      <ui-textarea label="{{ 'supplier.general-datas.comment' | translate }}" formControlName="comment"> </ui-textarea>
    </div>

    <div class="grid-item">
      <ui-tag-handler
        label="{{ 'supplier.general-datas.tag' | translate }}"
        [availableTags]="this.availableTags"
        formControlName="tags"
      >
      </ui-tag-handler>
    </div>
  </form>
</ng-container>
