import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faChevronLeft, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  ShipmentForm,
  Currency,
  ShipmentFormService,
  CurrencyService,
  AuthService,
  CaraUserService,
  CreationDeliveryType,
  ShipmentStatus,
  DeliveryType,
} from "center-services";
import { MessageService } from "fugu-components";
import { ComponentDirty, ErrorUtil } from "generic-pages";
import dayjs from "dayjs";
import { combineLatest, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "app-shipment-form",
  templateUrl: "./shipment-form.component.html",
  styleUrls: ["./shipment-form.component.scss"],
  providers: [SubscriptionService],
})
export class ShipmentFormComponent implements OnInit, ComponentDirty {
  @ViewChild("shipmentFormHeader") shipmentFormHeader: any;
  @ViewChild("shipmentFormLines") shipmentFormLines: any;
  public updatedShipmentForm: ShipmentForm;
  public editedShipmentForm: ShipmentForm;
  public isLoaded: boolean = false;
  public validationPopupVisible: boolean;
  public pricingGroupId: number = null;
  public shipmentFormId: number;
  public contactType: string;
  public senderId: number;
  public readOnly: boolean;
  public title: string;
  public deliveryRef: string;
  public dateFormat: string;
  public forceQuit: boolean = false;
  public faChevronLeft: IconDefinition = faChevronLeft;
  private unsavedShipmentForm: ShipmentForm;
  private shouldClose: boolean = false;
  private defaultCurrency: Currency;
  private receiverId: number;

  constructor(
    private shipmentFormService: ShipmentFormService,
    private translateService: TranslateService,
    private currencyService: CurrencyService,
    private messageService: MessageService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    protected userService: CaraUserService,
    private subscriptionService: SubscriptionService
  ) {
    const currentNav = this.router.getCurrentNavigation();
    this.shipmentFormId = this.route.snapshot.params.id;
    this.readOnly = this.route.snapshot.data.readOnly;

    if (currentNav.extras.queryParams) {
      this.receiverId = currentNav.extras.queryParams.recipientId;
      this.contactType = currentNav.extras.queryParams.type;
    } else if (!this.shipmentFormId) {
      this.redirectToList();
    }
  }

  public ngOnInit(): void {
    this.senderId = this.authService.getContextStoreId();
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }

    this.subscriptionService.subs.push(
      this.fetchInitialData().subscribe(() => {
        this.subscriptionService.subs.push(
          this.manageShipmentForm().subscribe(() => {
            this.isLoaded = true;
            this.title = this.deliveryRef ? "shipment-form.title-detail" : "shipment-form.title";
          })
        );
      })
    );
  }

  public isDirty(): boolean {
    if (this.forceQuit) {
      return false;
    }
    if (!this.updatedShipmentForm) {
      return false;
    }
    this.shipmentFormLines.applyModifications();

    if (this.unsavedShipmentForm && !this.unsavedShipmentForm.equals(this.updatedShipmentForm)) {
      this.shouldClose = false;
    }
    if (this.editedShipmentForm && !this.editedShipmentForm.equals(this.updatedShipmentForm) && !this.shouldClose) {
      this.saveShipmentFormState();
      return true;
    } else if (!this.editedShipmentForm && !this.shouldClose) {
      this.saveShipmentFormState();
      return true;
    }
    this.unsavedShipmentForm = null;
    this.shouldClose = false;
    return false;
  }

  public redirectToList(): void {
    this.router.navigateByUrl("/shipment-form-list");
  }

  openValidationPopup(): void {
    if (!this.hasErrors() && this.updatedShipmentForm.lines.length > 0) {
      this.validationPopupVisible = true;
    }
  }

  closeValidationPopup(): void {
    this.validationPopupVisible = false;
  }

  // create or update shipmentform and possibly send/ship it
  public submitShipmentForm(): Observable<ShipmentForm> {
    if (
      this.hasErrors() ||
      (this.editedShipmentForm && this.editedShipmentForm.equals(this.updatedShipmentForm)) ||
      !this.updatedShipmentForm
    ) {
      return of(null);
    }

    const action = this.updatedShipmentForm.id ? "update" : "create";
    return this.shipmentFormService[action].call(this.shipmentFormService, this.updatedShipmentForm).pipe(
      tap(
        (ship: ShipmentForm) => {
          this.finishSave(ship, action);
        },
        (error: any) => {
          this.handleApiError(error);
        }
      )
    );
  }

  // also used for child component when calling parent methode to create or update shipmentForm
  subShipmentForm(): void {
    this.subscriptionService.subs.push(this.submitShipmentForm().subscribe());
  }

  // send/ship the shipmentForm
  public submitAndValidateShipmentForm(printPdf: boolean): void {
    // check if it's necessary to update or create shipmentForm
    this.subscriptionService.subs.push(
      this.submitShipmentForm().subscribe((shipmentForm: ShipmentForm) => {
        // send/ship
        this.subscriptionService.subs.push(
          this.validateShipmentForm(printPdf).subscribe(() => {
            // always saved so send/ship
            if (!shipmentForm) {
              this.displayMessageSuccess();
            }
            this.redirectToList();
          })
        );
      })
    );

    this.validationPopupVisible = false;
  }

  public validateShipmentForm(printPdf: boolean): Observable<ShipmentForm> {
    return this.shipmentFormService.status(this.updatedShipmentForm.id, ShipmentStatus.SENT).pipe(
      tap({
        // print the pdf if it's necessary
        next: () => {
          if (printPdf) {
            this.shipmentFormService.getPDF(this.updatedShipmentForm.id).subscribe(
              response => {
                const documentPrefix = this.translateService.instant("shipment-form.title");
                const currentDate = dayjs(new Date()).format(this.dateFormat).split(" ")[0];
                const blob = new Blob([response], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");

                // prepare file for download
                const documentName = `${documentPrefix}_${this.updatedShipmentForm.deliveryRef}_${currentDate}`;
                a.download = documentName.replace(/[^A-Z0-9]+/gi, "_").toLowerCase();
                a.href = url;
                a.click();
              },
              error => {
                const title = this.translateService.instant("message.title.data-errors");
                const content = this.translateService.instant("global.server-errors.pdf", { message: error.message });
                this.messageService.warn(content, { title });
              }
            );
          }
        },
        error: (error: any) => {
          this.sendErrorAlert("shipment-form-validation-popup.errors.ship", error.message);
        },
      })
    );
  }

  updateInstancesOfShipmentForm(response: ShipmentForm): void {
    this.editedShipmentForm = response;
    this.updatedShipmentForm = new ShipmentForm(this.editedShipmentForm);
  }

  public getStatusClass(): string {
    return `status-${this.updatedShipmentForm.shipmentStatus.toLowerCase().replace("_", "-")}`;
  }

  private createShipmentForm(): Observable<ShipmentForm> {
    this.updatedShipmentForm = new ShipmentForm({
      type: this.getDeliveryType(this.contactType),
      creationType: CreationDeliveryType.MANUAL,
      shipmentStatus: ShipmentStatus.DRAFT,
      currencyId: this.defaultCurrency.id,
      receiverId: this.receiverId,
      senderId: this.senderId,
      documentDate: new Date(),
      lines: [],
    });
    this.editedShipmentForm = new ShipmentForm(this.updatedShipmentForm);
    return of(this.updatedShipmentForm);
  }

  private fetchDefaultCurrency(): Observable<Currency> {
    return this.currencyService.getDefault().pipe(
      tap(
        (defaultCurrency: Currency) => (this.defaultCurrency = defaultCurrency),
        (error: any) => this.handleApiError(error)
      )
    );
  }

  private fetchInitialData(): Observable<any> {
    const observables = [];
    observables.push(this.fetchDefaultCurrency());
    return combineLatest(observables);
  }

  private fetchShipmentForm(): Observable<ShipmentForm> {
    return this.shipmentFormService.get(this.shipmentFormId).pipe(
      tap(
        (shipmentForm: ShipmentForm) => {
          if (
            !this.router.url.includes("shipment-form-detail") &&
            this.senderId !== shipmentForm.senderId &&
            shipmentForm.shipmentStatus === ShipmentStatus.DRAFT
          ) {
            this.router.navigateByUrl(`/shipment-form-detail/${shipmentForm.id}`);
            this.forceQuit = true;
          }
          this.updatedShipmentForm = shipmentForm;
          this.contactType = shipmentForm.contactType.toUpperCase();
          this.editedShipmentForm = new ShipmentForm(this.updatedShipmentForm);
          this.deliveryRef = shipmentForm.deliveryRef;
        },
        (error: any) => {
          this.handleApiError(error);
        }
      )
    );
  }

  private manageShipmentForm(): Observable<ShipmentForm> {
    return this.shipmentFormId ? this.fetchShipmentForm() : this.createShipmentForm();
  }

  private handleApiError(error: any): void {
    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const body = this.translateService.instant(result.message, result.params);
    const title = this.translateService.instant("message.title.form-errors");

    this.messageService.error(body, { title });
  }

  private hasErrors(): boolean {
    let isValidHeader = true;
    let isValidLines = true;

    if (this.shipmentFormLines) {
      isValidLines = this.shipmentFormLines.updateShipmentLines();
    }
    if (this.shipmentFormHeader) {
      isValidHeader = this.shipmentFormHeader.isFormValid();
    }
    return !isValidHeader || !isValidLines;
  }

  private saveShipmentFormState(): void {
    this.unsavedShipmentForm = new ShipmentForm(this.updatedShipmentForm);
    this.shouldClose = true;
  }

  private finishSave(response: ShipmentForm, action: string): void {
    this.updateInstancesOfShipmentForm(response);

    if (action === "create") {
      this.location.replaceState(`/shipment-form/update/${response.id}`);
    }
    this.displayMessageSuccess();
  }

  private displayMessageSuccess(): void {
    const content = this.translateService.instant("message.content.save-success");
    const title = this.translateService.instant("message.title.save-success");
    this.messageService.success(content, { title });
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }

  private getDeliveryType(contactType: string): string {
    switch (contactType) {
      case "STORE":
        return DeliveryType.INTERNAL;
      case "SUPPLIER":
        return DeliveryType.EXTERNAL_SUPPLIER;
      case "CUSTOMER":
        return DeliveryType.EXTERNAL_CUSTOMER;
      default:
        console.error(`Cannot handle DeliveryType: ${contactType}`);
        return undefined;
    }
  }
}
