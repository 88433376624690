import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-shipment-form-validation-popup",
  templateUrl: "./shipment-form-validation-popup.component.html",
  styleUrls: ["./shipment-form-validation-popup.component.scss"],
})
export class ShipmentFormValidationPopupComponent implements OnInit {
  @Input() multiple: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public print: UntypedFormControl = new UntypedFormControl();

  ngOnInit(): void {
    this.prepareForm();
  }

  closeValidationPopup(): void {
    this.close.emit();
  }

  validateShipmentForm(): void {
    // emit the boolean value of the control to print or not the pdf
    this.validate.emit(this.print.value);
  }

  private prepareForm(): void {
    this.print = new UntypedFormControl(true);
  }
}
