import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { CashRegister, Pagination } from "center-services";
import { MessageService } from "fugu-components";

@Component({
  selector: "app-cash-registers-list",
  templateUrl: "./cash-registers-list.component.html",
  styleUrls: ["./cash-registers-list.component.scss"],
})
export class CashRegistersListComponent implements OnInit, OnChanges {
  @Input() cashRegistersList: CashRegister[];
  @Output() cashRegistersListChange: EventEmitter<CashRegister[]> = new EventEmitter<CashRegister[]>();
  @Output() submitEvent: EventEmitter<CashRegister> = new EventEmitter<CashRegister>();

  @ViewChild("table") table: DatatableComponent;

  public selectedCashRegister: CashRegister = null;
  public popupVisible: boolean = false;
  public tableControl: UntypedFormGroup;
  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "reference",
      dir: "asc",
    },
  ];

  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cashRegistersList && !changes.cashRegistersList.firstChange) {
      this.refresh();
    }
  }

  refresh(): void {
    if (!this.tableControl) {
      this.tableControl = new UntypedFormGroup({});
    }
    if (this.cashRegistersList) {
      this.rows = [];
      this.cashRegistersList.forEach(cashRegister => this.addRow(cashRegister));
    }
  }

  addRow(cashRegister: CashRegister): void {
    const row = this.convertCashRegisterToRow(cashRegister);
    this.rows.push(row);

    // Table formControl management
    if (this.tableControl.controls[`activated_${cashRegister.id}`]) {
      this.tableControl.controls[`activated_${cashRegister.id}`].patchValue(!cashRegister.archived);
    } else {
      this.tableControl.addControl(`activated_${cashRegister.id}`, new UntypedFormControl(!cashRegister.archived));
    }
    this.rows = [...this.rows];
  }

  convertCashRegisterToRow(cashRegister: CashRegister): any {
    return {
      id: cashRegister.id,
      uniqueReference: cashRegister.uniqueReference,
      reference: cashRegister.reference,
      activated: !cashRegister.archived,
    };
  }

  addCashRegister(event: any): void {
    event.stopPropagation();
    this.popupVisible = true;
  }

  updateCashRegister(event: any): void {
    if (event.type === "click") {
      const updatedCashRegister = this.cashRegistersList.find(
        (cashRegister: CashRegister) => cashRegister.id === event.row.id
      );
      this.selectedCashRegister = updatedCashRegister;
      this.popupVisible = true;
    }
  }

  checkboxOnChanges(id: number): void {
    const rowIndex = this.cashRegistersList.findIndex((cashRegister: CashRegister) => cashRegister.id === id);
    this.cashRegistersList[rowIndex].archived = !this.tableControl.controls[`activated_${id}`].value;
    this.rows[rowIndex].activated = this.tableControl.controls[`activated_${id}`].value;
    this.rows = [...this.rows];
    this.cashRegistersListChange.emit(this.cashRegistersList);
  }

  onCloseCashRegisterPopup(): void {
    this.popupVisible = false;
    this.selectedCashRegister = null;
  }

  onValidateCashRegister(validatedCashRegister: CashRegister): void {
    for (const cashRegister of this.cashRegistersList) {
      if (cashRegister.reference === validatedCashRegister.reference) {
        const title = this.translateService.instant("message.title.form-errors");
        const res = this.translateService.instant("store.cash-registers-list.unique-error");
        this.messageService.error(res, { title });
        return;
      }
    }

    const index = this.cashRegistersList.findIndex(
      (cashRegister: CashRegister) => cashRegister.id === validatedCashRegister.id
    );

    if (index >= 0) {
      this.cashRegistersList[index] = validatedCashRegister;
      const rowIndex = this.rows.findIndex(
        (cashRegister: CashRegister) => cashRegister.id === validatedCashRegister.id
      );
      this.rows[rowIndex] = this.convertCashRegisterToRow(validatedCashRegister);
      this.rows = [...this.rows];
    } else {
      this.cashRegistersList.push(validatedCashRegister);
      this.addRow(validatedCashRegister);
    }
    this.onCloseCashRegisterPopup();

    this.cashRegistersListChange.emit(this.cashRegistersList);
    this.submitEvent.emit(validatedCashRegister);
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }
}
