import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { RoundingUtil, SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import {
  PurchaseOrder,
  Currency,
  Supplier,
  Metal,
  AbstractItem,
  CompanyConfigService,
  CurrencyService,
  SupplierService,
  CaraUserService,
  StoreService,
  RetailItemService,
  PurchaseOrderLine,
  CompanyConfig,
  Store,
  Address,
  PurchaseType,
  MetalWeight,
  PaginatedList,
  Pagination,
  PurchaseOrderUtil,
} from "center-services";
import { PurchaseOrderUtilService } from "app/service/purchase-order-util.service";
import { DatatableComponent } from "@siemens/ngx-datatable";
import Decimal from "decimal.js";

@Component({
  selector: "app-purchase-order-header-detail",
  templateUrl: "./purchase-order-header-detail.component.html",
  styleUrls: ["./purchase-order-header-detail.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderHeaderDetailComponent implements OnInit {
  @ViewChild("table") table: DatatableComponent;
  @Input() purchaseOrder: PurchaseOrder;

  stringifyMetalWeightList: any = PurchaseOrderUtil.stringifyMetalWeightList;
  public isLoaded: boolean = false;
  public quantityColorClass: string;
  public headerData: any = {};
  public currency: Currency;
  public orderSupplier: Supplier;
  public locale: string;
  public metalList: Metal[] = [];
  public purchaseModalityIdList: number[] = [];
  public retailItemList: AbstractItem[] = [];
  private initObservables: Observable<any>[] = [];

  constructor(
    private companyConfigService: CompanyConfigService,
    private currencyService: CurrencyService,
    private supplierService: SupplierService,
    private userService: CaraUserService,
    private storeService: StoreService,
    private retailItemService: RetailItemService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private purchaseOrderUtilService: PurchaseOrderUtilService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.locale = this.userService.connectedUser.value.codeLanguage;
    this.purchaseOrder.lines.forEach((line: PurchaseOrderLine) => {
      // get all the purchase modality ids from each line
      this.purchaseModalityIdList.push(line.purchaseModalityId);
    });

    this.initObservables = [];

    this.initObservables.push(this.fetchBillingCompany());
    this.initObservables.push(this.fetchDeliveryStore());
    this.initObservables.push(this.fetchOrderCurrency());
    this.initObservables.push(this.fetchSupplier());
    if (this.purchaseModalityIdList.length > 0) {
      this.initObservables.push(this.fetchItems(this.purchaseModalityIdList));
    }

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.computeOrderPrice();
        this.manageLinesData();
        this.isLoaded = true;
      })
    );
  }

  public checkOverflow(element: any): boolean {
    return element.offsetWidth < element.scrollWidth;
  }

  fetchItems(purchaseModalityIds: any[]): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: purchaseModalityIds.length,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, purchaseModalityIds);

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          // remove duplicates from item list
          const ids = result.data.map(obj => obj.id);
          this.retailItemList = result.data.filter(({ id }, index) => !ids.includes(id, index + 1));
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  private fetchBillingCompany(): Observable<CompanyConfig> {
    return this.companyConfigService.get().pipe(
      tap((companyConfig: CompanyConfig) => {
        this.headerData.billingCompany = this.formatEntityWithAddressData(companyConfig.name, companyConfig.address);
      })
    );
  }

  private fetchOrderCurrency(): Observable<Currency> {
    return this.currencyService.get(this.purchaseOrder.currencyId).pipe(
      tap((currency: Currency) => {
        this.currency = currency;
      })
    );
  }

  private fetchDeliveryStore(): Observable<Store> {
    return this.storeService.get(this.purchaseOrder.deliveryStoreId).pipe(
      tap((store: Store) => {
        const deliveryAddress = store.deliveryAddresses.find(
          (address: Address) => address.id === this.purchaseOrder.deliveryAddressId
        );
        this.headerData.deliveryStore = this.formatEntityWithAddressData(store.name, deliveryAddress);
      })
    );
  }

  private fetchSupplier(): Observable<Supplier> {
    return this.supplierService.get(this.purchaseOrder.supplierId).pipe(
      tap((supplier: Supplier) => {
        this.orderSupplier = supplier;
        this.headerData.supplier = `${supplier.reference} ${supplier.name}`;
      })
    );
  }

  private formatEntityWithAddressData(entityName: string, address: Address): string {
    return `${entityName}<br/>${address.lines}<br/>${address.cityCode} ${address.city}`;
  }

  private manageLinesData(): void {
    const brands = [];

    let totalWeight = 0;
    let theoreticalTotalWeight = 0;
    let quantity = 0;
    let receivedQuantity = 0;

    this.headerData.sumWeightAccountPrediction = [];
    this.purchaseOrder.lines.forEach((line: PurchaseOrderLine) => {
      if (!brands.find(brand => brand.includes(line.brandRef))) {
        brands.push(`${line.brandRef} ${line.brandName}`);
      }
      const polTheoreticalWeight = PurchaseOrderUtil.getMetalWeight(line);
      totalWeight += line.weight * line.quantity;
      theoreticalTotalWeight += polTheoreticalWeight;
      receivedQuantity += line.receivedQuantity;
      quantity += line.quantity;

      // set weightAccountPrediction value for PM WITH_METAL_ACCOUNT
      if (line.purchaseType === PurchaseType.WITH_METAL_ACCOUNT) {
        this.subscriptionService.subs.push(
          this.purchaseOrderUtilService
            .fetchWeightAccountPrediction(polTheoreticalWeight, this.getItemByName(line.itemName), this.orderSupplier)
            .subscribe((metalWeightList: MetalWeight[]) => {
              // update total
              this.headerData.sumWeightAccountPrediction = this.purchaseOrderUtilService.getMergedMetalWeights(
                this.headerData.sumWeightAccountPrediction,
                metalWeightList
              );
            })
        );
      }
    });

    this.headerData.receivedQuantity = receivedQuantity;
    this.headerData.quantity = quantity;
    this.headerData.brands = brands;
    this.headerData.totalWeight = totalWeight;
    this.headerData.theoreticalTotalWeight = theoreticalTotalWeight;

    this.getQuantityClass(receivedQuantity, quantity);
  }

  private getItemByName(name: string): AbstractItem {
    return this.retailItemList.find(item => item.name === name);
  }

  private computeOrderPrice(): void {
    const shippingFeePrice = +this.purchaseOrder.shippingFeePrice;
    const hbjoatTaxPrice = +this.purchaseOrder.hbjoatTaxPrice;
    const taxPrice = +this.purchaseOrder.taxPrice;
    const extraPrice = +this.purchaseOrder.extraPrice;

    const orderPrice = new Decimal(this.purchaseOrder.totalPrice ?? 0)
      .plus(shippingFeePrice ?? 0)
      .plus(taxPrice ?? 0)
      .plus(hbjoatTaxPrice ?? 0)
      .plus(extraPrice ?? 0)
      .toNumber();
    this.headerData.orderPrice = RoundingUtil.roundLow(orderPrice);
  }

  private getQuantityClass(receivedQuantity: number, quantity: number): void {
    if (receivedQuantity === 0) {
      this.quantityColorClass = "quantity-wrapper none";
    } else if (receivedQuantity < quantity) {
      this.quantityColorClass = "quantity-wrapper partial";
    } else {
      this.quantityColorClass = "quantity-wrapper full";
    }
  }
}
