<div class="main-container">
  <form class="shipment-header-form" [formGroup]="headerForm" novalidate>
    <ui-tab-handler #tabHandler [tabByUrl]="false" [independent]="false" (tabClick)="onTabClick($event)">
      <ui-tab active="true" id="general-datas" title="{{ 'shipment-form.header.tab.general-datas' | translate }}">
        <ng-template #tabTemplate>
          <div class="header-form-tab" *ngIf="!readonly">
            <div class="grid-item medium">
              <ui-list
                [search]="true"
                mandatory="true"
                formControlName="receiver"
                [options]="receiversOptions"
                [status]="headerForm.get('receiver') | formStatus"
                [errorMessage]="headerForm.get('receiver') | formErrorMessage | translate"
                label="{{ 'shipment-form.header.fields.receiver' | translate }}"
              ></ui-list>
            </div>

            <div class="grid-item medium">
              <ui-list
                [search]="true"
                [options]="personsOptions"
                formControlName="receiverPerson"
                label="{{ 'shipment-form.header.fields.receiver-person' | translate }}"
              ></ui-list>
            </div>

            <div class="grid-item large">
              <ui-list
                [search]="true"
                [options]="addressesOptions"
                mandatory="true"
                formControlName="receiverAddress"
                [status]="headerForm.get('receiverAddress') | formStatus"
                [errorMessage]="headerForm.get('receiverAddress') | formErrorMessage | translate"
                label="{{ 'shipment-form.header.fields.receiver-address' | translate }}"
              ></ui-list>
            </div>

            <div class="grid-item medium">
              <ui-date-picker
                [locale]="locale"
                [format]="dateFormat"
                formControlName="expectedDeliveryDate"
                label="{{ 'shipment-form.header.fields.expected-delivery-date' | translate }}"
              >
              </ui-date-picker>
            </div>
          </div>

          <div class="header-info-tab" *ngIf="readonly">
            <div class="grid-item">
              <div class="multi-col">
                <p class="title">{{ "shipment-form.header.fields.receiver" | translate | uppercase }}</p>
                <p>{{ getReceiverLabel(shipmentForm.receiverId) }}</p>
              </div>

              <div class="multi-col">
                <p class="title">{{ "shipment-form.header.fields.receiver-person" | translate | uppercase }}</p>
                <p>
                  {{
                    shipmentForm.receiverPersonName
                      ? shipmentForm.receiverPersonName
                      : ("shipment-form.header.fields.undefined" | translate)
                  }}
                </p>
              </div>
            </div>

            <div class="grid-item">
              <div class="multi-col">
                <p class="title">{{ "shipment-form.header.fields.receiver-address" | translate | uppercase }}</p>
                <p class="spaced-line" [innerHTML]="formatAddressData(shipmentForm.receiverAddress)"></p>
              </div>
              <div class="multi-col">
                <p class="title"></p>
              </div>
            </div>

            <div class="grid-item">
              <div class="multi-col">
                <p class="title">{{ "shipment-form.header.fields.expected-delivery-date" | translate | uppercase }}</p>
                <p>
                  {{
                    shipmentForm.expectedDeliveryDate
                      ? (shipmentForm.expectedDeliveryDate | dateTimeFormat: false)
                      : ("shipment-form.header.fields.undefined" | translate)
                  }}
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </ui-tab>

      <ui-tab id="shipment-datas" title="{{ 'shipment-form.header.tab.shipment-datas' | translate }}">
        <ng-template #tabTemplate>
          <div class="header-form-tab" *ngIf="!readonly">
            <div class="grid-item input medium">
              <ui-input-text
                type="text"
                formControlName="forwardingReference"
                label="{{ 'shipment-form.header.fields.forwarding-reference' | translate }}"
              >
              </ui-input-text>
            </div>

            <div class="grid-item input medium">
              <ui-input-text
                type="text"
                formControlName="packageReference"
                label="{{ 'shipment-form.header.fields.package-reference' | translate }}"
              >
              </ui-input-text>
            </div>

            <div class="grid-item input large">
              <ui-input-text
                type="text"
                maxLength="1024"
                formControlName="comment"
                label="{{ 'shipment-form.header.fields.comment' | translate }}"
              >
              </ui-input-text>
            </div>
          </div>

          <div class="header-info-tab" *ngIf="readonly">
            <div class="grid-item">
              <div class="multi-col">
                <p class="title">{{ "shipment-form.header.fields.forwarding-reference" | translate | uppercase }}</p>
                <p>
                  {{
                    shipmentForm.forwardingReference
                      ? shipmentForm.forwardingReference
                      : ("shipment-form.header.fields.undefined" | translate)
                  }}
                </p>
              </div>
            </div>

            <div class="grid-item">
              <div class="multi-col">
                <p class="title">{{ "shipment-form.header.fields.package-reference" | translate | uppercase }}</p>
                <p>
                  {{
                    shipmentForm.packageReference
                      ? shipmentForm.packageReference
                      : ("shipment-form.header.fields.undefined" | translate)
                  }}
                </p>
              </div>
            </div>

            <div class="grid-item">
              <div class="multi-col">
                <p class="title">{{ "shipment-form.header.fields.comment" | translate | uppercase }}</p>
                <p class="ellipsis" #commentElement>
                  {{
                    shipmentForm.packagingComment
                      ? shipmentForm.packagingComment
                      : ("shipment-form.header.fields.undefined" | translate)
                  }}
                </p>

                <ui-tooltip *ngIf="shipmentForm.packagingComment" [element]="commentElement" maxWidth="300px">
                  <span style="font-weight: normal !important; white-space: break-spaces">
                    {{ shipmentForm.packagingComment }}
                  </span>
                </ui-tooltip>
              </div>
            </div>
          </div>
        </ng-template>
      </ui-tab>
    </ui-tab-handler>
  </form>
</div>
