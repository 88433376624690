<ng-container>
  <div class="batch-generate-popup-container" novalidate [formGroup]="popupForm">
    <ui-popup title="{{ 'batch-generate-popup.title' | translate }}" (close)="closePopup(true)">
      <div content>
        <div class="return-link">
          <ui-link
            text="{{ 'batch-generate-popup.buttons.back-button' | translate }}"
            (click)="closePopup(false)"
            [linkIcon]="faChevronLeft"
          ></ui-link>
        </div>

        <ui-date-picker
          [locale]="locale"
          [format]="dateFormat"
          formControlName="date"
          mandatory="true"
          [status]="popupForm.get('date') | formStatus"
          [errorMessage]="
            popupForm.get('date')
              | formErrorMessage: { customPrefix: 'batch-generate-popup.errors.', useCustomPrefixFor: ['date'] }
              | translate
          "
          label="{{ 'batch-generate-popup.fields.date' | translate }}"
        >
        </ui-date-picker>
        <div class="one-invoice-per-store">
          <ui-switch
            customClass="no-margin"
            (change)="onSwitchChange()"
            [formControl]="popupForm.get('oneInvoicePerStore')"
          >
          </ui-switch>
          <div class="switch-label">{{ "batch-generate-popup.fields.one-invoice-per-store" | translate }}</div>
        </div>

        <div class="invoice-number">
          {{
            "batch-generate-popup.fields.invoice-number" | translate: { invoiceNumber: this.rows.length } | uppercase
          }}
        </div>

        <div class="total-gross-price" *ngIf="this.totalGrossPrice !== undefined">
          {{ "batch-generate-popup.fields.total-gross-price" | translate: { totalGrossPrice: this.totalGrossPrice } }}
        </div>

        <div class="info">{{ "batch-generate-popup.fields.info" | translate }}</div>

        <div class="table">
          <ngx-datatable
            #table
            [rows]="rows"
            class="material"
            columnMode="standard"
            headerHeight="auto"
            rowHeight="40"
            [sorts]="sorts"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [rowClass]="getRowClass"
          >
            <ngx-datatable-column
              name="{{ 'batch-generate-popup.datatable.columns.customer' | translate }}"
              prop="customer"
              cellClass="customer"
              [resizeable]="false"
              [sortable]="false"
              width="200"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ this.getStoreLabel() | translate }}"
              prop="store"
              cellClass="store"
              [resizeable]="false"
              [sortable]="false"
              width="200"
            >
              <ng-template ngx-datatable-cell-template *ngIf="this.oneInvoicePerStore" let-value="value">
                <div class="adjust">
                  {{ value }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'batch-generate-popup.datatable.columns.gross-price' | translate }}"
              prop="grossPrice"
              cellClass="gross-price"
              [resizeable]="false"
              [sortable]="false"
              width="200"
            >
              <ng-template ngx-datatable-cell-template let-currencyIsoCode="row.currencyIsoCode" let-value="value">
                <div class="adjust">
                  {{ value | formatCurrency: currencyIsoCode:"symbol":"0.2-2":locale }}
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>

        <div class="auto-validation">
          <ui-checkbox type="checkbox" formControlName="autoValidate" (change)="onCheckboxChange()"></ui-checkbox>
          <div class="label-text">{{ "batch-generate-popup.fields.auto-validate" | translate }}</div>
        </div>

        <div class="print">
          <ui-checkbox type="checkbox" formControlName="print"></ui-checkbox>
          <div class="label-text">{{ "batch-generate-popup.fields.print" | translate }}</div>
        </div>
      </div>

      <div footer>
        <div class="button-popup-to-invoice">
          <ui-button
            text="{{ 'batch-generate-popup.buttons.submit' | translate: { invoiceNumber: this.rows.length } }}"
            [primary]="true"
            (click)="submit()"
            [btnIcon]="faArrowCircleRight"
          ></ui-button>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
