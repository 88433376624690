import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
  CaraUserService,
  CurrencyService,
  CommercialModality,
  Person,
  Currency,
  CaraUser,
  Address,
} from "center-services";
import { SubscriptionService } from "fugu-common";
import { Observable, zip } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-commercial-modality",
  templateUrl: "./commercial-modality.component.html",
  styleUrls: ["./commercial-modality.component.scss"],
  providers: [SubscriptionService],
})
export class CommercialModalityComponent implements OnInit {
  @Input() commercialModality: CommercialModality;
  @Input() currencyId: number;
  @Input() contacts: Person[];
  @Input() address: Address;
  @Input() displayImportation: boolean = true;
  @Output() commercialModalityChange: EventEmitter<CommercialModality> = new EventEmitter<CommercialModality>();
  @ViewChild("general") general: any;
  @ViewChild("paymentPeriod") paymentPeriod: any;
  @ViewChild("import") import: any;
  public currency: Currency = new Currency();
  public currencies: Currency[] = [];
  public codeLanguage: string;

  constructor(
    private userService: CaraUserService,
    private currencyService: CurrencyService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.subscriptionService.subs.push(zip(this.initCurrencies(), this.initLanguage()).subscribe());
    this.contacts = this.contacts.filter((c: Person) => !c.archived);
  }

  initCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap((currencies: Currency[]) => {
        this.currencies = currencies;
        this.currency = this.currencies.find(currency => currency.id === this.currencyId);
      })
    );
  }

  initLanguage(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap((user: CaraUser) => {
        this.codeLanguage = user.codeLanguage;
      })
    );
  }

  commercialModalityUpdated(): void {
    if (this.isValid) {
      this.commercialModalityChange.emit(this.commercialModality);
    }
  }

  isValid(): boolean {
    if (this.general && !this.general.isValid()) {
      return false;
    }
    if (this.paymentPeriod && !this.paymentPeriod.isValid()) {
      return false;
    }
    if (this.import && !this.import.isValid()) {
      return false;
    }
    return true;
  }
}
