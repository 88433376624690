import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AuthService, UserService, User } from "center-services";
import { SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PasswordUtil } from "generic-pages";

@Component({
  selector: "gen-change-password-popup",
  templateUrl: "./change-password-popup.component.html",
  styleUrls: ["./change-password-popup.component.scss"],
  providers: [SubscriptionService],
})
export class ChangePasswordPopupComponent implements OnInit, AfterViewChecked {
  @Output() validate: EventEmitter<any> = new EventEmitter();
  public popupForm: UntypedFormGroup;
  public submitted: boolean = false;
  public popupTitle: string;
  public symbols: string = PasswordUtil.symbols.join(", ");
  public password: HTMLElement;
  public passwordConstraints: any;
  faTimes: IconDefinition = faTimes;
  faCheck: IconDefinition = faCheck;
  // eslint-disable-next-line no-magic-numbers
  readonly passwordLength: number = 8;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private userService: UserService<User>,
    private translateService: TranslateService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  @ViewChild("passwordField", { read: ElementRef }) set setPasswordField(elem: ElementRef) {
    this.password = elem ? elem.nativeElement.getElementsByTagName("input")[0] : undefined;
  }

  ngOnInit(): void {
    this.passwordConstraints = PasswordUtil.getValidators(null, null);
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      confirmPassword: [null, [Validators.required]],
      tempPassword: [null, [Validators.minLength(this.passwordLength), Validators.required]],
    });
    this.popupForm.setValidators([PasswordUtil.check("tempPassword", "confirmPassword")]);
    this.subscriptionService.subs.push(
      this.popupForm.controls.tempPassword.valueChanges.subscribe(value => {
        this.passwordConstraints = PasswordUtil.getValidators(value, this.passwordLength);
      })
    );
  }

  shouldCheckPassword(): void {
    if (!this.popupForm.controls.tempPassword.value && !this.popupForm.controls.confirmPassword.value) {
      this.popupForm.controls.confirmPassword.updateValueAndValidity();
      this.popupForm.controls.tempPassword.updateValueAndValidity();
    }
  }

  submitPassword(): void {
    this.submitted = true;

    if (this.popupForm.invalid) {
      return;
    }

    const encryptedPassword = PasswordUtil.encrypt(this.popupForm.value.tempPassword);
    this.subscriptionService.subs.push(
      this.userService.updateTempPassword(encryptedPassword.toString()).subscribe(
        result => {
          this.authService.saveToken(result);
          this.validate.emit();
        },
        () => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("login-form.error.change-password");
          this.messageService.error(content, { title });
        }
      )
    );
  }

  getValidatorClass(value: boolean): string {
    return value ? "valid" : "invalid";
  }
}
