import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  FilterValue,
  Filterer,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService, Tag as TagComponent } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import {
  IconDefinition,
  faAddressCard,
  faHouseUser,
  faUserHairLong,
  faUserTie,
} from "@fortawesome/pro-solid-svg-icons";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  AbstractCustomer,
  Pagination,
  CaraUserService,
  CustomerService,
  TagService,
  CustomerType,
  Sort,
  PaginatedList,
  Address,
  ProfessionalCustomer,
  Tag,
  Light,
  LightService,
  Store,
  StoreService,
  AuthService,
} from "center-services";
import hash from "string-hash";
import { DatatableComponent } from "@siemens/ngx-datatable";

class CustomerRow {
  id: number;
  color: string;
  affiliate: boolean;
  companyName: string;
  firstName: string;
  lastName: string;
  reference: string;
  customerType: string;
  address: string;
  phone: string;
  email: string;
  activated: boolean;
  createdByStore: string;
  tags: TagComponent[];
}

@Component({
  selector: "app-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.scss"],
  providers: [SubscriptionService],
})
export class CustomerListComponent extends FilteredTableListComponent implements OnInit, PaginableComponent, OnDestroy {
  private static FIRST_NAME: string = "firstName";
  private static LAST_NAME: string = "lastName";
  private static FULL_NAME: string = "fullName";
  private static COMPANY_NAME: string = "companyName";
  private static NAME: string = "name";
  private static CUSTOMER_TYPE: string = "customerType";
  private static AFFILIATE: string = "affiliate";
  private static ACTIVATED: string = "activated";
  private static ARCHIVED: string = "archived";
  private static ASC: string = "asc";
  private static DESC: string = "desc";

  @ViewChild("table") table: DatatableComponent;

  public LIST_ID: string = "app-customer-list.customers-table";
  public customerList: AbstractCustomer[];
  public activated: string[] = [];
  public rows: CustomerRow[] = [];
  public tableControl: UntypedFormGroup;
  public addresses: Address[] = [];
  public cities: string[] = [];
  public allCustomerTags: Tag[];
  public storeList: Light[] = [];
  public mainStore: Store;
  public isConnectedToMainStore: boolean;
  public filterer: Filterer;
  public activeFilters: SearchFilter[] = [];
  public sorts: any[] = [
    {
      prop: CustomerListComponent.ACTIVATED,
      dir: CustomerListComponent.ASC,
    },
    {
      prop: CustomerListComponent.NAME,
      dir: CustomerListComponent.ASC,
    },
    {
      prop: CustomerListComponent.AFFILIATE,
      dir: CustomerListComponent.ASC,
    },
  ];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  faAddressCard: IconDefinition = faAddressCard;
  faHouseUser: IconDefinition = faHouseUser;
  faUserHairLong: IconDefinition = faUserHairLong;
  faUserTie: IconDefinition = faUserTie;
  private sessionPagination: SessionPagination;
  private initObservables: Observable<any>[] = [];
  private customerTypeTranslations: { [key: string]: string } = {};

  constructor(
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private customerService: CustomerService,
    private lightService: LightService,
    private storeService: StoreService,
    protected tagService: TagService,
    private authService: AuthService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.tableControl = new UntypedFormGroup({});
    this.sessionPagination = new SessionPagination(this);
    EnumTranslationUtil.buildTranslations(
      this.translateService,
      CustomerType,
      "customers-list.filters.customer-type",
      this.customerTypeTranslations
    );
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    if (this.filterer) {
      this.filterer.filterValues = [...filters];
    }
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.initObservables.push(this.fetchAddressesList());
    this.initObservables.push(this.fetchAllCustomerTags());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchMainStore());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        const selectedStoreId = this.authService.getContextStoreId();
        this.isConnectedToMainStore = selectedStoreId === this.mainStore.id;
        this.initFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.computeSearchFilters();
        this.fetchCustomerList();
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      this.sessionPagination.saveToSession(this.LIST_ID);
    }
  }

  createCustomer(): void {
    if (!this.userService.canDo("CUSTOMER_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/customer/add");
  }

  updateCustomer(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("CUSTOMER_UPDATE")) {
        return;
      }
      const filteredList = this.customerList.filter(customer => customer.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find customer with id ${event.row.id}`);
        return;
      }
      this.router.navigateByUrl(`/customer/update/${event.row.id}`);
    }
  }

  fetchAddressesList(): Observable<Address[]> {
    return this.customerService.getAddresses().pipe(
      tap(
        (addresses: Address[]) => {
          addresses.forEach(address => {
            if (address.city && this.cities.indexOf(address.city) === -1) {
              this.cities.push(address.city);
            }
          });
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("customers-list.errors.get-addresses", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber ? phoneNumber.match(/.{1,2}/g).join(" ") : null;
  }

  onArchiveCheckboxChanges(id: number): void {
    if (!this.userService.canDo("CUSTOMER_ARCHIVE")) {
      return;
    }
    if (this.tableControl.controls[`activated_${id}`].value) {
      this.subscriptionService.subs.push(
        this.customerService.unarchive(id).subscribe(
          () => this.fetchCustomerList(),
          () => {
            const title = this.translateService.instant("message.title.archive-errors");
            const content = this.translateService.instant("customers-list.errors.unarchive");
            this.messageService.warn(content, { title });
          }
        )
      );
    } else {
      this.subscriptionService.subs.push(
        this.customerService.archive(id).subscribe(
          () => this.fetchCustomerList(),
          () => {
            const title = this.translateService.instant("message.title.archive-errors");
            const content = this.translateService.instant("customers-list.errors.archive");
            this.messageService.warn(content, { title });
          }
        )
      );
    }
  }

  applyFilters(): void {
    this.activeFilters = [];
    // reset current page
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchCustomerList();
      })
    );
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchCustomerList();
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === CustomerListComponent.ACTIVATED) {
      this.sorts = [
        {
          prop: CustomerListComponent.ACTIVATED,
          dir: direction,
        },
      ];
    } else if (prop === CustomerListComponent.COMPANY_NAME) {
      this.sorts = [
        {
          prop: CustomerListComponent.ACTIVATED,
          dir: CustomerListComponent.ASC,
        },
        {
          prop: CustomerListComponent.CUSTOMER_TYPE,
          dir: CustomerListComponent.DESC,
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else if (prop === CustomerListComponent.FULL_NAME) {
      this.sorts = [
        {
          prop: CustomerListComponent.ACTIVATED,
          dir: CustomerListComponent.ASC,
        },
        {
          prop: CustomerListComponent.CUSTOMER_TYPE,
          dir: CustomerListComponent.ASC,
        },
        {
          prop: CustomerListComponent.LAST_NAME,
          dir: direction,
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: CustomerListComponent.ACTIVATED,
          dir: CustomerListComponent.ASC,
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.fetchCustomerList();
  }

  private propToDto(prop: string): string {
    switch (prop) {
      case CustomerListComponent.ACTIVATED:
        return CustomerListComponent.ARCHIVED;
      case CustomerListComponent.COMPANY_NAME:
        return CustomerListComponent.NAME;
      case CustomerListComponent.FULL_NAME:
        return CustomerListComponent.FIRST_NAME;
      default:
        return prop;
    }
  }

  private initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    const affiliateTranslations = {
      true: this.translateService.instant("customers-list.filters.affiliate.true"),
      false: this.translateService.instant("customers-list.filters.affiliate.false"),
    };

    this.filterer.addListFilter(
      "createdByStoreId",
      this.translateService.instant("customers-list.datatable.columns.created-by-store"),
      this.storeList
        .map(store => {
          return { value: store.id.toString(), displayValue: store.name };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      true,
      true,
      [this.mainStore.id.toString()],
      null,
      true,
      null,
      null,
      true
    );

    this.filterer.addListFilter(
      "affiliate",
      this.translateService.instant("customers-list.datatable.columns.affiliate"),
      Object.keys(affiliateTranslations).map(key => ({ value: key, displayValue: affiliateTranslations[key] }))
    );

    this.filterer.addFilter(
      "companyName",
      this.translateService.instant("customers-list.datatable.columns.company-name"),
      "string"
    );
    this.filterer.addFilter(
      "lastName",
      this.translateService.instant("customers-list.datatable.columns.last-name"),
      "string"
    );
    this.filterer.addFilter(
      "firstName",
      this.translateService.instant("customers-list.datatable.columns.first-name"),
      "string"
    );

    this.filterer.addFilter(
      "reference",
      this.translateService.instant("customers-list.datatable.columns.reference"),
      "string"
    );

    this.filterer.addFilter("address.lines", this.translateService.instant("customers-list.filters.address"), "string");

    this.filterer.addListFilter(
      "address.city",
      this.translateService.instant("customers-list.filters.city"),
      this.cities.map(city => {
        return { value: city, displayValue: city };
      }),
      false,
      false,
      null,
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "address.cityCode",
      this.translateService.instant("customers-list.filters.city-code"),
      "string"
    );
    this.filterer.addFilter("phone", this.translateService.instant("customers-list.datatable.columns.phone"), "string");
    this.filterer.addFilter("email", this.translateService.instant("customers-list.datatable.columns.email"), "string");

    this.filterer.addListFilter(
      "tags.id",
      this.translateService.instant("stores-list.datatable.columns.tags"),
      this.allCustomerTags.map(tag => {
        return { value: tag.id.toString(), displayValue: tag.name };
      }),
      null,
      null,
      null,
      null,
      true
    );

    this.filterer.addBooleanFilter(
      "archived",
      this.translateService.instant("customers-list.datatable.columns.activated"),
      false,
      false,
      true,
      true
    );
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  private fetchCustomerList(): void {
    this.subscriptionService.subs.push(
      this.customerService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<AbstractCustomer>) => {
          this.rows = [];
          this.pager = result.page;
          this.customerList = result.data;
          result.data.forEach((customer: AbstractCustomer) => {
            // Archive management
            if (!this.tableControl.controls[`activated_${customer.id}`]) {
              this.tableControl.addControl(
                `activated_${customer.id}`,
                new UntypedFormControl({
                  value: !customer.archived,
                  disabled: !this.userService.canDo("CUSTOMER_ARCHIVE"),
                })
              );
            } else {
              this.tableControl.controls[`activated_${customer.id}`].patchValue(!customer.archived);
            }
            this.addRow(customer);
          });
        },
        error => {
          this.sendErrorAlert("customers-list.errors.get-customers", error.message);
        },
        () => {
          this.rows = [...this.rows];
          if (this.table) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
        }
      )
    );
  }

  private fetchAllCustomerTags(): Observable<Tag[]> {
    return this.tagService.getAllTagsOfCategoryByName("Customer").pipe(
      tap(
        (tags: Tag[]) => {
          this.allCustomerTags = tags.filter((obj: Tag) => !obj.archived).sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          const res = this.translateService.instant("stores-list.errors.get-tags", { message: error.message });
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeList = lightStores;
        },
        error => {
          this.sendErrorAlert("customer-list.errors.get-stores", error.message);
        }
      )
    );
  }

  private fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert("customer-list.errors.get-main-store", error.message);
        }
      )
    );
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }

  private addRow(customer: AbstractCustomer): void {
    let address = new Address();
    address = customer.address;

    let addressConcat = "";
    if (address) {
      if (address.lines) {
        addressConcat += `${address.lines.replace(/\n/g, "<br/>")}<br/>`;
      }
      if (address.cityCode) {
        addressConcat += `${address.cityCode} `;
      }
      if (address.city) {
        addressConcat += address.city;
      }
    }

    const tags = this.allCustomerTags
      .filter(tag => customer.tagIds.includes(tag.id))
      .map((obj: Tag) => new TagComponent(obj.id, obj.name, obj.color));

    const row: CustomerRow = {
      id: customer.id,
      color: customer.color,
      affiliate: customer instanceof ProfessionalCustomer ? customer.affiliate : false,
      companyName: customer.name,
      firstName: customer.firstName,
      lastName: customer.lastName,
      reference: customer.reference,
      customerType: customer.customerType,
      address: addressConcat,
      phone: this.formatPhoneNumber(customer.phone),
      email: customer.email,
      activated: !customer.archived,
      createdByStore: this.storeList.find(store => store.id === customer.createdByStoreId).name,
      tags,
    };
    this.rows.push(row);
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
    this.activeFilters.forEach((sf, index) => {
      if (sf.key === CustomerListComponent.COMPANY_NAME) {
        const filterValue = this.filterer.filterValues.find(
          fv => fv.filterId === hash(CustomerListComponent.COMPANY_NAME)
        );
        this.applyCompanyName(filterValue, index);
      }
    });
  }

  private applyCompanyName(filterValue: FilterValue, index: number): void {
    const companyNameSf = new SearchFilter(
      this.propToDto(CustomerListComponent.COMPANY_NAME),
      SearchFilterOperator.LIKE,
      filterValue.equal.toString(),
      filterValue.filterId
    );
    this.activeFilters.splice(index, 1, companyNameSf);
    this.activeFilters.push(
      new SearchFilter(
        CustomerListComponent.CUSTOMER_TYPE,
        SearchFilterOperator.EQUAL,
        CustomerType.PROFESSIONAL,
        filterValue.filterId
      )
    );
  }
}
