import { RoundingUtil, SubscriptionService } from "fugu-common";
import { Title } from "@angular/platform-browser";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { faChevronLeft, faPen, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  AbstractItem,
  AuthService,
  CaraUserService,
  CategoryType,
  Currency,
  DeliveryLineColumn,
  DeliveryLineRow,
  DeliveryLineStatus,
  DeliveryType,
  DiscountType,
  PurchaseModality,
  PurchaseOrder,
  PurchaseOrderLine,
  ReceiveStatus,
  ReceivingForm,
  ReceivingFormService,
  ReceivingFreeLine,
  ReceivingInternalLine,
  ReceivingPOLine,
  ServiceItem,
  StockType,
  TotalUnitPriceCalculator,
  Uom,
} from "center-services";
import { MessageService } from "fugu-components";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ReceivingFormColumnBuilder } from "../util/receiving-form-column-builder";
import { ReceivingFormFetcher } from "../util/receiving-form-fetcher";
import Decimal from "decimal.js";

@Component({
  selector: "app-receiving-details",
  templateUrl: "./receiving-details.component.html",
  styleUrls: ["./receiving-details.component.scss"],
  providers: [ReceivingFormFetcher, SubscriptionService],
})
export class ReceivingDetailsComponent implements OnInit {
  @ViewChild("tabHandler") tabHandler: any;
  public updatedReceivingForm: ReceivingForm;
  public shouldClose: boolean = false;
  public lineColumns: DeliveryLineColumn[];
  public rows: DeliveryLineRow[];
  public isLoaded: boolean = false;
  public faPen: IconDefinition = faPen;
  public faChevronLeft: IconDefinition = faChevronLeft;
  public editBtnVisible: boolean = false;
  public receivingFormId: number;
  public deliveryCurrency: Currency;
  public poLines: PurchaseOrderLine[] = [];
  private readonly PERCENT_VALUE: number = 100;
  private canCreateAndUpdateDirect: boolean;
  private canCreateAndUpdateWithRegistering: boolean;
  private canPrepareReceiving: boolean;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private receivingFormService: ReceivingFormService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
    public fetcher: ReceivingFormFetcher,
    private subscriptionService: SubscriptionService
  ) {
    this.receivingFormId = this.route.snapshot.params.id;
    if (!this.receivingFormId) {
      this.backToPrevious();
      return;
    }
  }

  public ngOnInit(): void {
    this.subscriptionService.subs.push(
      this.fetcher.fetchInitialData().subscribe(() => {
        this.subscriptionService.subs.push(
          this.fetchReceivingForm(this.receivingFormId).subscribe(() => {
            this.subscriptionService.subs.push(
              this.fetcher
                .fetchReceivingFormData(
                  this.updatedReceivingForm,
                  this.updatedReceivingForm.lines.flatMap(line => line.purchaseModalityId)
                )
                .subscribe(() => {
                  this.buildDatas();
                  const translatedMenuEntry = this.translateService.instant("sidebar.menu.receiving-list");
                  const translatedTitle: string = this.translateService.instant("receiving-form.title.detail-receipt");
                  this.titleService.setTitle(`${translatedTitle} - ${translatedMenuEntry} - Neo-Retail`);
                })
            );
          })
        );
      })
    );
  }

  public backToPrevious(): void {
    this.router.navigateByUrl("/receiving-list");
  }

  // Method used to give row ids to new-delivery-lines component
  public getRowIds(po: PurchaseOrder): number[] {
    return po.lines.map(line => line.id);
  }

  // Method used to navigate between purchase orders tabs
  public onTabClick(event: any): void {
    if (!event) {
      return;
    }
    if (this.tabHandler) {
      this.tabHandler.changeTab(event);
    }
  }

  editAllowedAsMainStore(): boolean {
    const selectedStoreId = this.authService.getContextStoreId();
    const storeForbiddenToModify =
      selectedStoreId === this.fetcher.mainStore.id && selectedStoreId !== this.updatedReceivingForm.receiverId;
    const receivingStatus = this.updatedReceivingForm.receiveStatus;
    const inProgress = receivingStatus === ReceiveStatus.RECEIVE_IN_PROGRESS;
    const toReceive = receivingStatus === ReceiveStatus.TO_RECEIVE;
    const received = receivingStatus === ReceiveStatus.RECEIVED;
    return !((inProgress || toReceive || received) && storeForbiddenToModify);
  }

  editReceivingForm(): void {
    if (!this.canCreateAndUpdateDirect && !this.canPrepareReceiving && !this.canCreateAndUpdateWithRegistering) {
      return;
    }
    const navigationExtras: NavigationExtras = {
      state: { backUrl: `/receiving-form-detail/${this.updatedReceivingForm.id}` },
    };
    this.router.navigate([`/receiving-form/update/${this.updatedReceivingForm.id}`], navigationExtras);
  }

  private buildDatas(): void {
    this.setRights();
    const columnBuilder = new ReceivingFormColumnBuilder(
      this.updatedReceivingForm,
      null,
      this.fetcher.receptionType,
      true,
      this.authService.getContextStoreId(),
      this.fetcher.mainStore.id,
      this.userService
    );

    this.lineColumns = columnBuilder.buildColumns();
    this.buildRows();
    this.manageReadOnly();
    this.isLoaded = true;
  }

  private setRights(): void {
    this.canCreateAndUpdateDirect = this.userService.canDo("RECEIVING_FORM_DIRECT_CREATE_UPDATE");
    this.canCreateAndUpdateWithRegistering = this.userService.canDo("RECEIVING_FORM_REGISTERING_CREATE_UPDATE");
    this.canPrepareReceiving = this.userService.canDo("RECEIVING_FORM_REGISTERING_PREPARE");
  }

  private fetchReceivingForm(id: number): Observable<ReceivingForm> {
    return this.receivingFormService.get(id).pipe(
      tap(receivingForm => {
        this.updatedReceivingForm = receivingForm;
      })
    );
  }

  private getItem(pmId: number = null, itemRef: string = null): AbstractItem {
    if (this.fetcher.retailItemList?.length > 0) {
      for (const item of this.fetcher.retailItemList) {
        if (itemRef && item.reference === itemRef) {
          return item;
        }
        if (pmId) {
          for (const pm of item.purchaseModalities) {
            if (pm.id === pmId) {
              return item;
            }
          }
        }
      }
    }
    return null;
  }

  private getPurchaseModality(pmId: number, item: AbstractItem): PurchaseModality {
    return item.purchaseModalities?.find(pm => pm.id === pmId);
  }

  private manageReadOnly(): void {
    const receivingStatus = this.updatedReceivingForm.receiveStatus;
    const receivedOrReceiveInProgress =
      receivingStatus === ReceiveStatus.RECEIVED || receivingStatus === ReceiveStatus.RECEIVE_IN_PROGRESS;
    const internal = this.updatedReceivingForm.type === DeliveryType.INTERNAL;

    this.editBtnVisible = receivedOrReceiveInProgress && !internal && this.editAllowedAsMainStore();

    if (
      (this.fetcher.withPreparation && !this.canCreateAndUpdateWithRegistering) ||
      (!this.fetcher.withPreparation && !this.canCreateAndUpdateDirect)
    ) {
      this.editBtnVisible = false;
    }
  }

  // Method used to build rows used by the new-delivery-lines component
  // eslint-disable-next-line complexity
  private buildRows(): void {
    this.deliveryCurrency = this.fetcher.currencies.find(
      (currency: Currency) => currency.id === this.updatedReceivingForm.currencyId
    );
    this.poLines =
      !this.fetcher.poList || !this.fetcher.poList.length
        ? []
        : this.fetcher.poList
          .flatMap((po: PurchaseOrder) => po.lines)
          .filter((poLine: PurchaseOrderLine) => {
            return poLine.deliveryStoreId === this.updatedReceivingForm.receiverId;
          });
    this.rows = [];
    for (const line of this.updatedReceivingForm.lines) {
      const row = new DeliveryLineRow();
      row.id = line.id;
      switch (line.type) {
        case "ReceivingFreeLine":
          this.populateFreeLineRow(row, line as ReceivingFreeLine);
          break;
        case "ReceivingPOLine":
          this.populatePOLineRow(row, line as ReceivingPOLine);
          break;
        case "ReceivingInternalLine":
          this.populateInternalLineRow(row, line as ReceivingInternalLine);
          break;
        default:
          break;
      }
      if (row.canceled) {
        continue;
      }
      // Fill row prices using ReceivingLine
      row.realTotalUnitPrice = TotalUnitPriceCalculator.computeTotalUnitPrice(
        line,
        row.purchaseType,
        row.discountType,
        row.realDiscount,
        this.PERCENT_VALUE
      );
      row.hasInvoice = line.invoiceSupplierLineId !== null && line.invoiceSupplierLineId !== undefined;
      row.realPriceWithoutDiscount = TotalUnitPriceCalculator.computeTotalUnitPrice(
        line,
        row.purchaseType,
        null,
        null,
        this.PERCENT_VALUE
      );
      row.realUnitPricePerWeight = line.unitPricePerWeight;
      row.useMetalAccount = line.useMetalAccount;
      row.realMetalPrice = line.metalPrice;
      row.realUnitPrice = line.unitPrice;

      // Fill row quantities using ReceivingLine
      if (line.status === DeliveryLineStatus.REFUSED) {
        row.receivedQuantity = 0;
      } else {
        row.receivedQuantity = line.receivedQuantity === null ? line.expectedQuantity : line.receivedQuantity;
      }
      row.expectedQuantity = line.expectedQuantity;

      // Fill row weights using ReceivingLine
      row.tare = this.isDefined(line.tare) ? line.tare : null;
      row.totalWeight = this.isDefined(line.weight) ? line.weight : null;

      if (this.isDefined(line.weight) || this.isDefined(line.tare)) {
        const weight = this.isDefined(row.totalWeight) ? row.totalWeight : 0;
        const tare = this.isDefined(row.tare) ? row.tare : 0;
        row.realMetalWeight = RoundingUtil.roundLow(new Decimal(weight ?? 0).minus(tare).toNumber());
      } else {
        row.realMetalWeight = null;
      }

      // Fill row size values using ReceivingLine
      row.expectedSizeValue = line.expectedSizeValue;
      row.receivedSizeValue = line.receivedSizeValue;

      // Fill others data using ReceivingLine
      row.supplierName = row.supplierName ? row.supplierName : this.fetcher.sender.name;
      row.status = line.status;
      row.frozen = line.frozen;

      // Compute total price based on receivedQuantity column visibility
      const receivedQuantityColumn = this.lineColumns.find(column => column.property === "receivedQuantity");
      row.totalPrice = RoundingUtil.roundLow(
        new Decimal(row.realTotalUnitPrice ?? 0)
          .times(receivedQuantityColumn.visible ? row.receivedQuantity : row.expectedQuantity)
          .toNumber()
      );

      this.rows.push(row);
    }
  }

  // Method used to fill the row using the linked PurchaseModality and RetailItem
  private populateFreeLineRow(row: DeliveryLineRow, line: ReceivingFreeLine): void {
    const item: AbstractItem = this.getItem((line as ReceivingFreeLine).purchaseModalityId);
    row.isStandardItem = item.type === CategoryType.STANDARD;

    const pm: PurchaseModality = this.getPurchaseModality(line.purchaseModalityId, item);
    const pmUnit = this.fetcher.purchaseUnitList.find(
      (purchaseUnit: Uom) => purchaseUnit.id === pm.purchaseUnitId
    ).longName;

    row.lineNumber = this.updatedReceivingForm.lines.indexOf(line) + 1;
    row.destLocationId = line.destLocationId ? line.destLocationId : this.getDefaultStockLocation(item);

    row.discountType = line.discountType ? line.discountType : DiscountType.PERCENT;
    row.realDiscount = line.discount ?? 0;

    // Fill row data using the PurchaseModality
    row.orderedTotalUnitPrice = pm.computedPrice;
    row.purchaseType = pm.purchaseType;
    row.supplierRef = pm.supplierRef;
    row.supplierTraceabilityNumber = line.supplierRef;
    row.purchaseUnit = pmUnit;

    // Fill row data using the RetailItem
    row.brandName = item.brandName;
    row.itemRef = item.reference;
    row.itemName = item.name;
  }

  // Method used to fill the row using the linked POLine
  private populatePOLineRow(row: DeliveryLineRow, line: ReceivingPOLine): void {
    const poLine = this.poLines.find((purchaseOrderLine: PurchaseOrderLine) => {
      return purchaseOrderLine.id === line.purchaseOrderLineId;
    });
    // find the PM through the POL
    const item: AbstractItem = this.getItem(poLine.purchaseModalityId, poLine.itemReference);
    if (item) {
      row.isStandardItem = item.type === CategoryType.STANDARD;
    }

    const purchaseUnit = this.fetcher.purchaseUnitList.find((unit: Uom) => {
      return unit.id === poLine.purchaseUnitId;
    }).longName;

    if (this.isDefined(line.discount)) {
      row.realDiscount = line.discount;
    } else if (this.isDefined(poLine.percentDiscount)) {
      row.realDiscount = poLine.percentDiscount;
    } else {
      row.realDiscount = 0;
    }

    row.discountType = line.discountType ? line.discountType : DiscountType.PERCENT;

    // Fill quantities data using POLine
    const unreceivedQuantity = poLine.quantity - poLine.receivedQuantity;
    row.unreceivedQuantity = unreceivedQuantity > 0 ? unreceivedQuantity : 0;
    row.orderedQuantity = poLine.quantity;

    // Fill prices data using POLine
    row.orderedUnitPricePerWeight = poLine.unitPricePerWeight ?? 0;
    row.orderedDiscount = poLine.percentDiscount ?? 0;
    row.orderedMetalPrice = poLine.metalPrice;
    row.orderedUnitPrice = poLine.unitPrice;
    row.orderedTotalUnitPrice = TotalUnitPriceCalculator.computeTotalUnitPrice(
      poLine,
      poLine.purchaseType,
      DiscountType.PERCENT,
      poLine.percentDiscount,
      this.PERCENT_VALUE
    );
    row.purchaseType = poLine.purchaseType;
    row.purchaseUnit = purchaseUnit;

    // Fill others data using POLine
    row.destLocationId = line.destLocationId;
    row.orderedMetalWeight = poLine.weight * poLine.quantity - poLine.tare * poLine.quantity;
    row.orderedSizeValue = poLine.sizeValue;
    row.supplierTraceabilityNumber = line.supplierRef;
    row.supplierRef = poLine.supplierRef;
    row.lineNumber = poLine.lineNumber;
    row.itemRef = poLine.itemReference;
    row.brandName = poLine.brandName;
    row.itemName = poLine.itemName;
    row.polId = poLine.id;
    row.canceled = poLine.canceled;
  }

  // Method used to fill the row using the linked and RetailItem
  private populateInternalLineRow(row: DeliveryLineRow, line: ReceivingInternalLine): void {
    const se = this.fetcher.stockEntries.find(stockEntry => stockEntry.id === line.stockEntryId);
    const pmUnit = this.fetcher.purchaseUnitList.find((purchaseUnit: Uom) => purchaseUnit.id === se.uomId).longName;

    row.lineNumber = this.updatedReceivingForm.lines.indexOf(line) + 1;
    row.destLocationId = line.destLocationId;

    // Fill row data using the PurchaseModality
    row.orderedTotalUnitPrice = se.computedUnitPrice;
    row.supplierRef = se.itemSupplierRef;
    row.supplierTraceabilityNumber = line.supplierRef;
    row.supplierName = se.supplierName;
    // Fill row data using the RetailItem
    row.brandName = se.itemBrandName;
    row.itemRef = se.itemReference;
    row.itemName = se.itemName;
    if (se.stockType === StockType.BATCH) {
      row.batchNumber = se.id;
    } else if (se.stockType === StockType.SERIAL_NUMBER) {
      row.serialNumber = se.id;
    }
    row.purchaseUnit = pmUnit;
  }

  // Method used to get the default stock location of a line
  private getDefaultStockLocation(item: AbstractItem): number {
    if (item instanceof ServiceItem) {
      return null;
    }

    return item && item.defaultLocationId ? item.defaultLocationId : this.fetcher.receiver.originLocationId;
  }

  private isDefined(value: number): boolean {
    return value !== null && value !== undefined;
  }
}
