<ng-container *ngIf="title">
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ 'item.buttons.back-button' | translate }}"
        (click)="backToItemList()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
    </div>
    <div class="title">
      <ui-title titleText="{{ title | translate }}" subTitleText="{{ subTitle ? subTitle : undefined }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="validate-button">
        <div class="button">
          <ui-button
            text="{{ 'global.validate' | translate }}"
            type="save"
            [primary]="true"
            (click)="submitItem()"
          ></ui-button>
        </div>
      </div>
    </div>
    <div class="item-body">
      <ui-tab-handler #tabHandler (tabClick)="onTabClick($event)" [independent]="false" *ngIf="this.updatedItem">
        <ui-tab
          title="{{ 'item.general-datas.tab-title' | translate }}"
          id="general-datas"
          active="true"
          *ngIf="this.updatedItem?.tabsVisible()?.includes('general')"
        >
          <ng-template #tabTemplate>
            <app-retail-item-general #generalDatas *ngIf="this.updatedItem" [(editedItem)]="this.updatedItem">
            </app-retail-item-general>
          </ng-template>
        </ui-tab>
        <ui-tab
          title="{{ 'item.nomenclatures.tab-title' | translate }}"
          id="nomenclatures"
          *ngIf="this.updatedItem?.tabsVisible()?.includes('nomenclatures')"
        >
          <ng-template #tabTemplate>
            <app-retail-item-nomenclature
              *ngIf="this.updatedItem"
              [(item)]="this.updatedItem"
              (nomenclatureEvent)="catchEvent('nomenclatures', $event)"
            >
            </app-retail-item-nomenclature>
          </ng-template>
        </ui-tab>
        <ui-tab
          title="{{ 'item.classifications.tab-title' | translate }}"
          id="classifications"
          *ngIf="this.updatedItem?.tabsVisible()?.includes('classifications')"
        >
          <ng-template #tabTemplate>
            <app-retail-item-classifications
              *ngIf="this.updatedItem"
              [(classificationValueIds)]="this.updatedItem['classificationValueIds']"
              [categoryId]="this.updatedItem?.categoryId"
            >
            </app-retail-item-classifications>
          </ng-template>
        </ui-tab>
        <ui-tab
          title="{{ 'item.groupings.tab-title' | translate }}"
          id="groupings"
          *ngIf="this.updatedItem?.tabsVisible()?.includes('groupings')"
        >
          <ng-template #tabTemplate>
            <app-retail-item-group-list *ngIf="this.updatedItem" [(groupList)]="this.updatedItem['groups']">
            </app-retail-item-group-list>
          </ng-template>
        </ui-tab>
        <ui-tab
          title="{{ 'item.purchase-terms.tab-title' | translate }}"
          id="purchase-terms"
          *ngIf="this.updatedItem?.tabsVisible()?.includes('purchase-terms')"
        >
          <ng-template #tabTemplate>
            <app-retail-item-purchase-modalities-list
              *ngIf="this.updatedItem"
              #purchaseModalities
              [mainUnitId]="this.updatedItem['mainUnitId']"
              [canBuy]="this.updatedItem.canBuy"
              [(purchaseModalitiesList)]="this.updatedItem['purchaseModalities']"
              [(accountingBuyRef)]="this.updatedItem['accountingBuyRef']"
              (submitEvent)="catchEvent('purchaseModalities', $event)"
              [itemTheoreticalWeight]="this.updatedItem['theoreticalWeight']"
              [itemComposition]="this.updatedItem['composition']"
              [itemWeight]="this.updatedItem['weight']"
              [itemCategoryType]="this.updatedItem['type']"
            >
            </app-retail-item-purchase-modalities-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'item.sale-terms.tab-title' | translate }}" id="sale-terms">
          <ng-template #tabTemplate>
            <app-retail-item-sale-terms
              #saleTerms
              *ngIf="this.updatedItem"
              [editedItem]="this.updatedItem"
            ></app-retail-item-sale-terms>
          </ng-template>
        </ui-tab>
        <ui-tab
          title="{{ 'item.metal-management.tab-title' | translate }}"
          id="metal-management"
          *ngIf="this.updatedItem?.tabsVisible()?.includes('metal-management')"
        >
          <ng-template #tabTemplate>
            <app-alloy-composition
              *ngIf="this.updatedItem"
              [(alloyCompositions)]="this.updatedItem['composition']"
              [purchaseModalities]="this.updatedItem['purchaseModalities']"
            ></app-alloy-composition>
          </ng-template>
        </ui-tab>
        <ui-tab
          title="{{ 'item.stock.tab-title' | translate }}"
          id="stock"
          *ngIf="this.updatedItem?.tabsVisible()?.includes('stock')"
        >
          <ng-template #tabTemplate>
            <app-retail-item-stock *ngIf="this.updatedItem" [(editedItem)]="this.updatedItem"></app-retail-item-stock>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>
    </div>
  </div>
</ng-container>
