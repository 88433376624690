import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  faExclamationCircle,
  faInfoCircle,
  faPrint,
  faSync,
  faTruckFast,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  ShipmentForm,
  Light,
  Store,
  Pagination,
  CaraUserService,
  ShipmentFormService,
  LightService,
  StoreService,
  AuthService,
  DeliveryType,
  CreationDeliveryType,
  ShipmentStatus,
  CaraUser,
  LightCustomer,
  PaginatedList,
  Sort,
  ShipmentLine,
  AsynchronousTaskCreation,
  AsynchronousTaskService,
} from "center-services";
import {
  Filterer,
  FilterValue,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService, ToastMessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-shipment-form-list",
  templateUrl: "./shipment-form-list.component.html",
  styleUrls: ["./shipment-form-list.component.scss"],
  providers: [SubscriptionService],
})
export class ShipmentFormListComponent extends FilteredTableListComponent implements OnInit, OnDestroy {
  @ViewChild("table") table: any;

  public shipmentFormList: ShipmentForm[] = [];

  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "shipmentStatus",
      dir: "asc",
    },
    {
      prop: "shipmentDate",
      dir: "desc",
    },
  ];

  public selectedShipmentFormList: ShipmentForm[] = [];
  public faPrint: IconDefinition = faPrint;
  public faSync: IconDefinition = faSync;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public faTruckFast: IconDefinition = faTruckFast;
  public faError: IconDefinition = faExclamationCircle;
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public receiverList: Light[] = [];
  public storesList: Light[] = [];
  public tableControl: UntypedFormGroup;
  public currentRowsId: number[] = [];
  public mainStore: Store;
  public contextStore: Light;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public locale: string;
  public dateFormat: string;
  public LIST_ID: string = "app-shipment-form-list.shipment-form-list";
  isStatusUnique: boolean = false;
  hasNoLines: boolean = false;
  statusPopupVisible: boolean = false;
  addShipmentInfoPopupVisible: boolean = false;
  hasPrintableStatus: boolean = false;
  initiatorPopupVisible: boolean = false;
  private sessionPagination: SessionPagination;
  private typeTranslations: { [key: string]: string } = {};
  private creationTypeTranslations: { [key: string]: string } = {};
  private shipmentStatusTranslations: { [key: string]: string } = {};
  private initObservables: Observable<any>[] = [];
  private currentSortStatusDirection: string = "asc";

  constructor(
    private notificationHandlerService: NotificationHandlerService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private shipmentFormService: ShipmentFormService,
    private lightService: LightService,
    private fb: UntypedFormBuilder,
    private storeService: StoreService,
    private router: Router,
    private authService: AuthService,
    private asynchronousTaskService: AsynchronousTaskService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      DeliveryType,
      "new-receipt-list.type-options",
      this.typeTranslations
    );

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      CreationDeliveryType,
      "new-receipt-list.creation-type-options",
      this.creationTypeTranslations
    );

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      ShipmentStatus,
      "shipment-form-list.shipment-status-options",
      this.shipmentStatusTranslations
    );
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.initSelectFormControl();

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }

    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchCustomers());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchMainStore());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        const selectedStoreId = this.authService.getContextStoreId();
        this.contextStore = this.storesList.find(store => store.id === selectedStoreId);
        this.initFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.computeSearchFilters();
        this.fetchShipmentFormList();
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      this.sessionPagination.saveToSession(this.LIST_ID);
    }
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.receiverList.push(...lightSuppliers);
        },
        error => {
          this.sendErrorAlert("shipment-form-list.errors.get-suppliers", error.message);
        }
      )
    );
  }

  fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.receiverList.push(...lightStores);
          this.storesList.push(...lightStores);
        },
        error => {
          this.sendErrorAlert("shipment-form-list.errors.get-stores", error.message);
        }
      )
    );
  }

  fetchCustomers(): Observable<LightCustomer[]> {
    return this.lightService.getCustomers().pipe(
      tap(
        (lightCustomers: LightCustomer[]) => {
          this.receiverList.push(...lightCustomers);
        },
        error => {
          this.sendErrorAlert("shipment-form-list.errors.get-customers", error.message);
        }
      )
    );
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "deliveryRef",
      this.translateService.instant("shipment-form-list.datatable.columns.reference"),
      "string"
    );
    this.filterer.addFilter(
      "createdAt",
      this.translateService.instant("shipment-form-list.datatable.columns.created-at"),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );
    this.filterer.addFilter(
      "shipmentDate",
      this.translateService.instant("shipment-form-list.datatable.columns.shipment-date"),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );
    this.filterer.addFilter(
      "expectedDeliveryDate",
      this.translateService.instant("shipment-form-list.datatable.columns.expected-delivery-date"),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );
    this.filterer.addFilter(
      "receiver.reference",
      this.translateService.instant("shipment-form-list.datatable.columns.receiver-reference"),
      "string"
    );
    this.filterer.addListFilter(
      "receiver.id",
      this.translateService.instant("shipment-form-list.datatable.columns.receiver"),
      this.receiverList.map(receiver => {
        return { value: receiver.id.toString(), displayValue: receiver.name };
      }),
      false,
      false,
      null,
      null,
      true,
      false
    );
    this.filterer.addListFilter(
      "type",
      this.translateService.instant("shipment-form-list.datatable.columns.type"),
      Object.keys(DeliveryType).map(key => ({ value: key, displayValue: this.typeTranslations[key] }))
    );
    this.filterer.addListFilter(
      "creationType",
      this.translateService.instant("shipment-form-list.datatable.columns.creation-type"),
      Object.keys(CreationDeliveryType).map(key => ({ value: key, displayValue: this.creationTypeTranslations[key] }))
    );
    if (this.userService.canDo("PURCHASING_PRICE")) {
      this.filterer.addFilter(
        "totalPrice",
        this.translateService.instant("shipment-form-list.datatable.columns.total-price"),
        "range"
      );
    }

    // Display only contextStore if contextStore.id if different from mainStoreId
    this.filterer.addListFilter(
      "sender.id",
      `${this.translateService.instant("shipment-form-list.datatable.columns.sender")}`,
      this.contextStore.id === this.mainStore.id
        ? this.storesList
          .map(store => ({
            value: store.id.toString(),
            displayValue: store.name,
          }))
          .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
        : [{ value: this.contextStore.id.toString(), displayValue: this.contextStore.name }],
      true,
      true,
      [this.contextStore.id.toString()],
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "forwardingReference",
      this.translateService.instant("shipment-form-list.informations.forwardingReference"),
      "string"
    );
    this.filterer.addFilter(
      "packageReference",
      this.translateService.instant("shipment-form-list.informations.packageReference"),
      "string"
    );
    this.filterer.addFilter(
      "packagingComment",
      this.translateService.instant("shipment-form-list.informations.packagingComment"),
      "string"
    );
    this.filterer.addListFilter(
      "shipmentStatus",
      this.translateService.instant("shipment-form-list.datatable.columns.shipment-status"),
      Object.keys(ShipmentStatus).map(key => ({ value: key, displayValue: this.shipmentStatusTranslations[key] }))
    );
  }

  buildDateDefaultValue(): any {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    return {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchShipmentFormList();
      })
    );
  }

  fetchShipmentFormList(): void {
    this.subscriptionService.subs.push(
      this.shipmentFormService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<ShipmentForm>) => {
          this.rows = [];
          this.shipmentFormList = result.data;
          this.pager = result.page;
          this.currentRowsId = result.data.map((delivery: ShipmentForm) => delivery.id);
          result.data.forEach((shipmentForm: ShipmentForm) => {
            this.addRow(shipmentForm);
          });
        },
        error => {
          this.sendErrorAlert("shipment-form-list.errors.get-delivery-forms", error.message);
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
          this.updateSelection();
          this.updateHeaderPageCheckbox();
        }
      )
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-main-store", error.message);
        }
      )
    );
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  changeSortSettings(prop: string, direction: string): void {
    switch (prop) {
      case "shipmentStatus":
        this.currentSortStatusDirection = direction;
        this.sorts = [
          {
            prop: "shipmentStatus",
            dir: this.currentSortStatusDirection,
          },
        ];
        break;
      default:
        this.sorts = [
          {
            prop: "shipmentStatus",
            dir: this.currentSortStatusDirection,
          },
          {
            prop,
            dir: direction,
          },
        ];
        break;
    }
    this.fetchShipmentFormList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchShipmentFormList();
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "reference":
        return "deliveryRef";
      default:
        return prop;
    }
  }

  addRow(shipmentForm: ShipmentForm): void {
    const infoList: string[] = [];
    if (shipmentForm.forwardingReference) {
      infoList.push(
        `${this.translateService.instant("shipment-form-list.informations.forwardingReference")} : ${
          shipmentForm.forwardingReference
        }`
      );
    }
    if (shipmentForm.packageReference) {
      infoList.push(
        `${this.translateService.instant("shipment-form-list.informations.packageReference")} : ${
          shipmentForm.packageReference
        }`
      );
    }
    if (shipmentForm.packagingComment) {
      infoList.push(
        `${this.translateService.instant("shipment-form-list.informations.packagingComment")} : ${
          shipmentForm.packagingComment
        }`
      );
    }
    this.rows.push({
      id: shipmentForm.id,
      reference: shipmentForm.deliveryRef,
      createdAt: shipmentForm.createdAt,
      shipmentDate: shipmentForm.shipmentDate,
      expectedDeliveryDate: shipmentForm.expectedDeliveryDate,
      receiverReference: this.receiverList.find(supplier => supplier.id === shipmentForm.receiverId).reference,
      receiver: this.receiverList.find(supplier => supplier.id === shipmentForm.receiverId).name,
      type: [
        ...new Set(
          shipmentForm.type
            ?.split("~")
            .map(type => this.translateService.instant(`shipment-form-list.type-options.${type}`))
        ),
      ],
      creationType: [
        ...new Set(
          shipmentForm.creationType
            ?.split("~")
            .map(creationType =>
              this.translateService.instant(`shipment-form-list.creation-type-options.${creationType}`)
            )
        ),
      ],
      totalPrice: shipmentForm.totalPrice,
      senderId: shipmentForm.senderId,
      sender: this.storesList.find(store => store.id === shipmentForm.senderId).name,
      shipmentInfo: infoList,
      receiveStatus: [
        ...new Set(
          shipmentForm.receiveStatus
            ?.split("~")
            .map(receiveStatus =>
              this.translateService.instant(`shipment-form-list.receive-status-options.${receiveStatus}`)
            )
        ),
      ],
      shipmentStatus: [
        ...new Set(
          shipmentForm.shipmentStatus
            ?.split("~")
            .map(shipmentStatus =>
              this.translateService.instant(`shipment-form-list.shipment-status-options.${shipmentStatus}`)
            )
        ),
      ],
      shipmentStatusClass: this.getStatusClass(shipmentForm.shipmentStatus),
      stockDepleted: shipmentForm.lines.some((line: ShipmentLine) => line.stockDepleted),
    });
    this.tableControl.addControl(this.getRowControlName(shipmentForm.id.toString()), new UntypedFormControl(false));
  }

  // the method bellow apply the 'not-clickable' css class only if the given satisfied is satisfied
  getRowClass(): any {
    return "";
  }

  shipDeliveryForm(): void {
    if (!this.userService.canDo("SHIPMENT_FORM_UPDATE")) {
      return;
    }
    this.router.navigateByUrl("/shipment-form/prepare-shipment");
  }

  newShipmentForm(): void {
    if (!this.userService.canDo("SHIPMENT_FORM_CREATE")) {
      return;
    }
    this.initiatorPopupVisible = true;
  }

  closeInitiatorPopup(): void {
    this.initiatorPopupVisible = false;
  }

  public getRowControlName(id: number | string): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  updateHeaderPageCheckbox(): void {
    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.tableControl.get(this.getRowControlName(id)).value;
    });
    const isSelected = this.currentRowsId.length > 0 && this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
  }

  updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
    });
  }

  public updateSelection(): void {
    this.rows.forEach(row => {
      const rowChecked: boolean = this.tableControl.controls[this.getRowControlName(row.id)].value;
      const shipmentForm: ShipmentForm = this.shipmentFormList.find(elem => elem.id === row.id);
      const isShipmentFormSelected: boolean =
        this.selectedShipmentFormList.findIndex(elem => elem.id === row.id) === -1 ? false : true;

      if (!isShipmentFormSelected && rowChecked) {
        this.selectedShipmentFormList.push(shipmentForm);
      } else if (isShipmentFormSelected && !rowChecked) {
        const poIndex = this.selectedShipmentFormList.findIndex(elem => elem.id === shipmentForm.id);
        this.selectedShipmentFormList.splice(poIndex, 1);
      }
    });
    this.checkShipmentFormListStatus();
  }

  checkShipmentFormListStatus(): void {
    this.isStatusUnique = [...new Set(this.selectedShipmentFormList.map(po => po.shipmentStatus))].length === 1;
    this.hasPrintableStatus = [...new Set(this.selectedShipmentFormList.map(po => po.shipmentStatus))].every(elem =>
      [ShipmentStatus.SENT, ShipmentStatus.SETTLED, ShipmentStatus.DRAFT].includes(elem)
    );
    this.hasNoLines =
      this.selectedShipmentFormList.some(po => po.lines.length === 0) &&
      !this.selectedShipmentFormList.every(po => po.lines.length === 0);
  }

  canChangeStatus(): boolean {
    return (
      !this.isStatusUnique ||
      this.hasNoLines ||
      this.selectedShipmentFormList.length === 0 ||
      this.selectedShipmentFormList[0]?.shipmentStatus === "SETTLED"
    );
  }

  openChangeStatusPopup(): void {
    this.statusPopupVisible = true;
  }

  closeChangeStatusPopup(): void {
    this.closeAndReset();
    this.isStatusUnique = false;
    this.hasNoLines = false;
  }

  validateChangeStatusPopup(): void {
    this.closeAndReset();
    this.fetchShipmentFormList();
  }

  closeAndReset(): void {
    this.statusPopupVisible = false;
    this.selectedShipmentFormList = [];
    for (const key in this.tableControl.controls) {
      if (this.tableControl.controls.hasOwnProperty(key)) {
        this.tableControl.controls[key].setValue(false);
      }
    }
  }

  openAddShipmentInfoPopup(): void {
    this.addShipmentInfoPopupVisible = true;
  }

  validateAddShipmentInfoPopup(): void {
    this.closeAndResetAddShipmentInfo();
    this.fetchShipmentFormList();
  }

  closeAndResetAddShipmentInfo(): void {
    this.addShipmentInfoPopupVisible = false;
    this.selectedShipmentFormList = [];
    for (const key in this.tableControl.controls) {
      if (this.tableControl.controls.hasOwnProperty(key)) {
        this.tableControl.controls[key].setValue(false);
      }
    }
  }

  updateShipmentForm(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("SHIPMENT_FORM_UPDATE")) {
        return;
      }
      const filteredList = this.shipmentFormList.filter(shipmentForm => shipmentForm.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find shipmentForm with id ${event.row.id}`);
        return;
      }
      this.sessionPagination.saveToSession(this.LIST_ID);
      if (event.row.senderId !== this.authService.getContextStoreId()) {
        this.router.navigateByUrl(`/shipment-form-detail/${event.row.id}`);
        return;
      }
      if (
        filteredList[0].shipmentStatus === ShipmentStatus.DRAFT ||
        filteredList[0].shipmentStatus === ShipmentStatus.TO_PROCESS
      ) {
        this.router.navigateByUrl(`/shipment-form/update/${event.row.id}`);
      } else {
        this.router.navigateByUrl(`/shipment-form-detail/${event.row.id}`);
      }
    }
  }

  printShipmentForm(): void {
    const selected = this.selectedShipmentFormList.map((shipmentForm: ShipmentForm) => shipmentForm.id);
    const search = new SearchFilter("id", SearchFilterOperator.IN, selected).toQuery();
    const asyncCreationTask = {
      type: "generatePdf",
      params: `shipment-form;${search};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  protected fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  protected computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  private initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.tableControl = this.fb.group({
      headerCheckbox: control,
    });
  }

  private getStatusClass(status: ShipmentStatus): string {
    return `status-${status.toLowerCase().replace("_", "-")}`;
  }
}
