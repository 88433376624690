import { Decimal } from "decimal.js";
import { RoundingUtil } from "fugu-common";
/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  AbstractReceivingLine,
  ReceivingInternalLine,
  StandardItem,
  PricingGroupCategoryLink,
  PricingGroup,
} from "center-services";

@Injectable({
  providedIn: "root",
})
export class InvoiceCustomerCommonService {
  private readonly HUNDRED: number = 100;

  computeMarginForUnitPrice(
    currentPricingGroup: PricingGroup,
    line: AbstractReceivingLine,
    retailItem?: StandardItem
  ): number {
    if (line instanceof ReceivingInternalLine || !currentPricingGroup) {
      return RoundingUtil.roundLow(line?.unitPrice);
    }
    let margin = 0;
    if (currentPricingGroup?.pricingGroupCategoryLinks?.length > 0) {
      if (retailItem !== null) {
        margin = currentPricingGroup.pricingGroupCategoryLinks.find((obj: PricingGroupCategoryLink) => {
          return obj.itemCategoryId === retailItem.categoryId;
        })?.marginPercent;
        margin = margin ? margin : 0;
        return RoundingUtil.roundLow(
          new Decimal(line?.unitPrice ?? 0)
            .add(new Decimal(line?.unitPrice ?? 0).times(new Decimal(margin ?? 0).div(this.HUNDRED)))
            .toNumber()
        );
      }
    }
    return null;
  }
}
