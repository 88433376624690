<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link
        [linkIcon]="faChevronLeft"
        (click)="backToPrevious()"
        text="{{ getBackButtonTranslation() | translate }}"
      ></ui-link>
    </div>
    <div class="title">
      <ui-title titleText="{{ 'manual-stock-entry.title' | translate }}"></ui-title>
    </div>

    <div class="top-buttons">
      <div class="save-and-action-button" *ngIf="activeStage.id === 1">
        <ui-button
          [primary]="true"
          type="save-and-action"
          (click)="changeStage(stages[activeStage.id])"
          text="{{ 'manual-stock-entry.buttons.start-entry' | translate }}"
        ></ui-button>
      </div>
      <div class="save-button" *ngIf="activeStage.id === 2">
        <ui-button
          type="validate"
          [primary]="true"
          (click)="submitManualStockEntry()"
          text="{{ 'manual-stock-entry.buttons.confirm-entry' | translate }}"
        ></ui-button>
      </div>
    </div>

    <div class="manual-stock-entry-body">
      <div class="stages-container">
        <ui-container>
          <div class="align-stages">
            <div
              (click)="changeStage(stage)"
              *ngFor="let stage of stages"
              [class.active]="stage.active"
              class="{{ getStageClass(stage) }}"
            >
              <div class="number-circle">{{ stage.index }}</div>
              <div class="panel-text">{{ stage.title | translate }}</div>
            </div>
          </div>
        </ui-container>
      </div>

      <div class="body-container">
        <app-item-list
          (itemSelect)="changeSelectedItem($event)"
          [(activeFilters)]="activeFilters"
          *ngIf="activeStage.id === 1"
          [selection]="true"
          [defaultSelection]="selectedItem"
          [sorts]="sorts"
          [listId]="ITEM_LIST_ID"
          [isStandardItemFilterOn]="true"
          #itemList
        ></app-item-list>

        <ui-container *ngIf="activeStage.id === 2 && selectedItem; instanceof: StandardItem">
          <app-manual-stock-entry-data
            [(editedManualStockEntry)]="editedStockEntry"
            [selectedItem]="selectedItem"
            #manualStockEntryData
          ></app-manual-stock-entry-data>
        </ui-container>
      </div>
    </div>
  </div>

  <ui-popup title="{{ 'manual-stock-entry.popup.title' | translate }}" *ngIf="popupVisible" (close)="closePopup()">
    <div content>
      <div class="message">
        {{ "manual-stock-entry.popup.message" | translate
        }}<span>
          <ui-link
            text="{{ 'manual-stock-entry.popup.link-text' | translate }}"
            (click)="goToStockEntryLabelList()"
          ></ui-link>
        </span>
      </div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              text="{{ 'manual-stock-entry.popup.validate-button' | translate }}"
              type="validate"
              [primary]="true"
              (click)="closePopup()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</ng-container>
