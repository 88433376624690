import { Decimal } from "decimal.js";
import { SearchFilterOperator, SearchFilter, Option, RoundingUtil, SubscriptionService } from "fugu-common";
import { ComponentDirty } from "generic-pages";
import { filter, tap } from "rxjs/operators";
import { MenuAction, MessageService, ToastMessageService } from "fugu-components";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Observable, of } from "rxjs";
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from "@angular/forms";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  faChevronLeft,
  faScannerGun,
  faTrash,
  IconDefinition,
  faExclamationTriangle,
  faCircleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { MultiShipmentFormState } from "./multi-shipment-form-state";
import { MultiShipmentRow } from "./multi-shipment-row";
import {
  BatchShip,
  Light,
  StockEntry,
  Uom,
  Currency,
  ShipmentFormService,
  StockEntryService,
  CurrencyService,
  CaraUserService,
  LightService,
  UomService,
  AuthService,
  ShipmentType,
  ShipmentForm,
  Pagination,
  PaginatedList,
  ShipmentLine,
  ShipmentStatus,
  CaraUser,
  AsynchronousTaskService,
  AsynchronousTaskCreation,
} from "center-services";
import { NotificationHandlerService } from "app/service/notification-handler.service";

@Component({
  selector: "app-multi-shipment-form",
  templateUrl: "./multi-shipment-form.component.html",
  styleUrls: ["./multi-shipment-form.component.scss"],
  providers: [SubscriptionService],
})
export class MultiShipmentFormComponent implements OnInit, AfterViewChecked, ComponentDirty {
  @ViewChild("scanIdElement", { read: ElementRef }) scanIdElement: ElementRef;

  public unsavedState: MultiShipmentFormState;
  public initialState: MultiShipmentFormState;
  public editedState: MultiShipmentFormState;
  public batchShip: BatchShip;
  public generalForm: UntypedFormGroup;
  public scanForm: UntypedFormGroup;
  public recipientOptions: Option[] = [];
  public supplierOptions: Option[];
  public customerOptions: Option[];
  public storeOptions: Option[];
  public supplierList: Light[] = [];
  public customerList: Light[] = [];
  public storeList: Light[] = [];
  public stockEntryList: StockEntry[] = [];
  public purchaseUnitList: Uom[] = [];
  public menuActions: MenuAction[];
  public senderId: number;
  public defaultCurrency: Currency;
  public locale: string;
  public dateFormat: string;
  public hiddenValue: string = "";
  public validationPopupVisible: boolean;
  public shouldClose: boolean = false;
  public isLoaded: boolean = false;

  public readonly SCAN_PREFIX: string = "DF";
  public rows: MultiShipmentRow[] = [];
  public sorts: any[] = [
    {
      prop: "lineNumber",
      dir: "desc",
    },
  ];
  public faChevronLeft: IconDefinition = faChevronLeft;
  public faScannerGun: IconDefinition = faScannerGun;
  public faWarn: IconDefinition = faExclamationTriangle;
  public faError: IconDefinition = faCircleExclamation;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });
  private readonly DELETE_ACTION_ID: number = 0;
  private readonly SINGLE_SET_LENGTH: number = 1;

  constructor(
    protected shipmentFormService: ShipmentFormService,
    protected translateService: TranslateService,
    private stockEntryService: StockEntryService,
    private currencyService: CurrencyService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private lightService: LightService,
    private uomService: UomService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private router: Router,
    private notificationHandlerService: NotificationHandlerService,
    private asynchronousTaskService: AsynchronousTaskService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    this.addMenuActions();
    this.prepareForms();
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  ngOnInit(): void {
    this.senderId = this.authService.getContextStoreId();
    const observables: Observable<any>[] = [];
    observables.push(this.fetchSuppliers());
    observables.push(this.fetchCustomers());
    observables.push(this.fetchStores());
    observables.push(this.fetchConnectedUserDetails());
    observables.push(this.fetchDefaultCurrency());
    observables.push(this.fetchUoms());

    this.subscriptionService.subs.push(
      combineLatest(observables).subscribe(() => {
        this.buildRecipientOptions();
        this.subscriptionService.subs.push(
          this.translateService.onLangChange.subscribe(() => {
            this.buildRecipientOptions();
          })
        );
        this.generalForm.controls.recipient.setValue(0);
        this.batchShip = new BatchShip({ shipmentFormIds: [] });

        this.initialState = new MultiShipmentFormState({
          recipient: 0,
          shipmentFormIds: [],
        });
        this.editedState = new MultiShipmentFormState(this.initialState);
        this.isLoaded = true;
      })
    );
  }

  public ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  public prepareForms(): void {
    this.generalForm = this.fb.group({
      recipient: [0, [Validators.required]],
      supplier: [null, [Validators.required]],
      packageReference: [null],
      forwardingReference: [null],
      packagingComment: [null],
    });

    this.subscriptionService.subs.push(
      this.generalForm.controls.recipient.valueChanges.subscribe(() => {
        switch (this.generalForm.controls.recipient.value) {
          case ShipmentType.SUPPLIER:
            this.generalForm.addControl("supplier", new UntypedFormControl(null, Validators.required));
            this.generalForm.removeControl("customer");
            this.generalForm.removeControl("store");
            break;
          case ShipmentType.CUSTOMER:
            this.generalForm.addControl("customer", new UntypedFormControl(null, Validators.required));
            this.generalForm.removeControl("supplier");
            this.generalForm.removeControl("store");
            break;
          case ShipmentType.STORE:
            this.generalForm.addControl("store", new UntypedFormControl(null, Validators.required));
            this.generalForm.removeControl("supplier");
            this.generalForm.removeControl("customer");
            break;
          default:
            break;
        }
      })
    );

    this.scanForm = new UntypedFormGroup({ scanId: new UntypedFormControl(null) });
  }

  public manageActions(actionId: number, row: MultiShipmentRow): void {
    switch (actionId) {
      case this.DELETE_ACTION_ID:
        this.deleteForm(row);
        break;
      default:
        break;
    }
  }

  public buildRecipientOptions(): void {
    this.recipientOptions = [];
    this.recipientOptions.push(
      new Option(ShipmentType.SUPPLIER, this.translateService.instant("shipment-initiator-popup.options.supplier"))
    );
    this.recipientOptions.push(
      new Option(ShipmentType.CUSTOMER, this.translateService.instant("shipment-initiator-popup.options.customer"))
    );
    this.recipientOptions.push(
      new Option(ShipmentType.STORE, this.translateService.instant("shipment-initiator-popup.options.store"))
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener("window:keypress", ["$event"])
  public addItemByScanner(event: KeyboardEvent): void {
    if (isNaN(Number(event.key))) {
      switch (event.key) {
        case "Enter": {
          if (!this.hiddenValue.startsWith(this.SCAN_PREFIX)) {
            return;
          }
          const scanId = this.hiddenValue.substring(this.SCAN_PREFIX.length);
          this.subscriptionService.subs.push(
            this.shipmentFormService.get(+scanId).subscribe(
              (shipmentForm: ShipmentForm) => {
                this.scanForm.get("scanId").setValue(shipmentForm.deliveryRef.substring(this.SCAN_PREFIX.length));
                this.scanForm.get("scanId").markAsTouched();
                this.handleFetchResult(shipmentForm);
              },
              () => {
                this.setInvalidNumberError();
              }
            )
          );
          this.hiddenValue = "";
          break;
        }
        case "F":
          if (this.hiddenValue !== "D") {
            return;
          }
          this.hiddenValue += event.key;
          break;
        case "D":
          if (this.hiddenValue !== "") {
            return;
          }
          this.hiddenValue += event.key;
          break;
        default:
          return;
      }
    } else if (this.hiddenValue.startsWith(this.SCAN_PREFIX)) {
      this.hiddenValue += event.key;
    }
  }

  public addShipmentForm(): void {
    const scanId = this.scanForm.get("scanId").value;

    if (scanId) {
      if (isNaN(scanId)) {
        return;
      }
      const pager = new Pagination({ number: 0, size: 1 });
      const deliveryRefFilter = [new SearchFilter("deliveryRef", SearchFilterOperator.EQUAL, `BL${scanId}`)];
      this.subscriptionService.subs.push(
        this.shipmentFormService.getAll(pager, null, deliveryRefFilter).subscribe(
          (result: PaginatedList<ShipmentForm>) => {
            if (result.data.length === 0) {
              this.setInvalidNumberError();
              return;
            }
            this.handleFetchResult(result.data[0]);
          },
          () => {
            this.setInvalidNumberError();
          }
        )
      );
    }
  }

  public isDirty(): boolean {
    if (!this.initialState) {
      return false;
    }
    this.applyModifications();

    if (this.unsavedState && !this.unsavedState.equals(this.initialState)) {
      this.shouldClose = false;
    }
    if (this.editedState && !this.editedState.equals(this.initialState) && !this.shouldClose) {
      this.saveState();
      return true;
    } else if (!this.editedState && !this.shouldClose) {
      this.saveState();
      return true;
    }
    this.unsavedState = null;
    this.shouldClose = false;
    return false;
  }

  public applyModifications(): void {
    this.initialState.recipient = this.generalForm.get("recipient").value;
    this.initialState.store = this.generalForm.get("store")?.value;
    this.initialState.customer = this.generalForm.get("customer")?.value;
    this.initialState.supplier = this.generalForm.get("supplier")?.value;
    this.initialState.forwardingReference = this.generalForm.get("forwardingReference").value;
    this.initialState.packageReference = this.generalForm.get("packageReference").value;
    this.initialState.packagingComment = this.generalForm.get("packagingComment").value;
    this.initialState.shipmentFormIds = [...this.batchShip.shipmentFormIds];

    this.batchShip.forwardingReference = this.generalForm.get("forwardingReference").value;
    this.batchShip.packageReference = this.generalForm.get("packageReference").value;
    this.batchShip.packagingComment = this.generalForm.get("packagingComment").value;
  }

  public redirectToList(): void {
    this.router.navigateByUrl("/shipment-form-list");
  }

  public openValidationPopup(): void {
    const title = this.translateService.instant("multi-shipment-form.shipment-container.errors.validate-error-title");
    if (this.rows.find(row => row.inError)) {
      const content = this.translateService.instant(
        "multi-shipment-form.shipment-container.errors.shipment-form-error"
      );
      this.messageService.error(content, { title });
      return;
    }
    if (this.rows.length === 0) {
      const content = this.translateService.instant("multi-shipment-form.shipment-container.errors.no-shipment-form");
      this.messageService.error(content, { title });
      return;
    }
    this.validationPopupVisible = true;
  }

  public closeValidationPopup(): void {
    this.validationPopupVisible = false;
  }

  public submitAndValidateMultiShipmentForm(printPdf: boolean): void {
    this.applyModifications();

    this.subscriptionService.subs.push(
      this.validateShipment(printPdf).subscribe(() => {
        this.applyModifications();
        this.displayMessageSuccess();
        this.redirectToList();
      })
    );

    this.validationPopupVisible = false;
  }

  public getTotalSummary(value: string): number {
    return this.rows.reduce((partialSum: number, row: MultiShipmentRow) => partialSum + row[value], 0);
  }

  public getRowClass(row: MultiShipmentRow): any {
    return { inError: row.inError, "not-clickable": true };
  }

  public hasSameQuantityUnit(): boolean {
    const allUnits = this.rows.map((row: MultiShipmentRow) => row.quantityUnit);
    if (new Set(allUnits).size === this.SINGLE_SET_LENGTH && !allUnits[0]) {
      return false;
    }
    return new Set(allUnits).size === this.SINGLE_SET_LENGTH;
  }

  public deleteForm(row: MultiShipmentRow): void {
    this.batchShip.shipmentFormIds.splice(this.batchShip.shipmentFormIds.indexOf(row.id), 1);
    this.rows.splice(this.rows.indexOf(row), 1);
    this.rows = [...this.rows];

    this.rows.forEach((r: MultiShipmentRow, index: number) => (r.lineNumber = index + 1));

    if (this.rows.length === 0) {
      this.generalForm.controls.recipient.setValue(0);
      this.generalForm.get("recipient").enable();
      this.generalForm.controls.supplier.setValue(null);
      this.generalForm.get("supplier").enable();
      this.generalForm.get("supplier").markAsUntouched();
    }
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }

  private handleFetchResult(shipmentForm: ShipmentForm): void {
    if (!shipmentForm) {
      return;
    }
    const correctSender = shipmentForm.senderId === this.authService.getContextStoreId();

    if (!correctSender) {
      this.setInvalidNumberError();
      return;
    }

    if (this.batchShip.shipmentFormIds.includes(shipmentForm.id)) {
      return;
    }

    const stockEntryIdList = shipmentForm.lines.map((line: ShipmentLine) => line.stockEntryId);
    this.subscriptionService.subs.push(
      this.fetchStockEntries(stockEntryIdList).subscribe(() => {
        this.manageShipmentForm(shipmentForm);
      })
    );

    this.scanIdElement.nativeElement.getElementsByTagName("input")[0].blur();
  }

  private setInvalidNumberError(): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant("multi-shipment-form.shipment-container.errors.invalid-number");
    this.messageService.warn(content, { title });
    this.scanForm.get("scanId").setValue(null);
    this.scanIdElement.nativeElement.getElementsByTagName("input")[0].blur();
  }

  private addMenuActions(): void {
    this.menuActions = [
      new MenuAction(this.DELETE_ACTION_ID, this.translateService.instant("shipment-form.buttons.delete"), faTrash),
    ];
  }

  private validateShipment(printPdf: boolean): Observable<ShipmentForm> {
    return this.shipmentFormService.batchShip(this.batchShip).pipe(
      tap({
        next: () => {
          if (printPdf) {
            this.handlePrintShipment(this.batchShip.shipmentFormIds);
          }
          this.editedState = new MultiShipmentFormState(this.initialState);
        },
        error: () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("multi-shipment-form.shipment-container.errors.batch-ship");
          this.messageService.warn(content, { title });
        },
      })
    );
  }

  private handlePrintShipment(ids: number[]): void {
    const filter: SearchFilter = new SearchFilter("id", SearchFilterOperator.IN, ids);
    const asyncCreationTask = {
      type: "generatePdf",
      params: `shipment-form;${filter.toQuery()};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  private displayMessageSuccess(): void {
    const content = this.translateService.instant("message.content.save-success");
    const title = this.translateService.instant("message.title.save-success");
    this.messageService.success(content, { title });
  }

  private getRecipientId(): any {
    if (this.generalForm.get("recipient").value === ShipmentType.SUPPLIER) {
      return this.generalForm.get("supplier").value;
    }
    if (this.generalForm.get("recipient").value === ShipmentType.CUSTOMER) {
      return this.generalForm.get("customer").value;
    }
    return this.generalForm.get("store").value;
  }

  private manageShipmentForm(shipmentForm: ShipmentForm): void {
    const recipientId = this.getRecipientId();

    if (this.rows.length === 0 && !recipientId) {
      const storeAsRecipient = this.storeList.find((store: Light) => store.id === shipmentForm.receiverId);
      const supplierAsRecipient = this.supplierList.find((supplier: Light) => supplier.id === shipmentForm.receiverId);
      const customerAsRecipient = this.customerList.find((customer: Light) => customer.id === shipmentForm.receiverId);

      if (storeAsRecipient) {
        this.generalForm.controls.recipient.setValue(ShipmentType.STORE);
        this.generalForm.controls.store.setValue(storeAsRecipient.id);
      }

      if (customerAsRecipient) {
        this.generalForm.controls.recipient.setValue(ShipmentType.CUSTOMER);
        this.generalForm.controls.customer.setValue(customerAsRecipient.id);
      }

      if (supplierAsRecipient) {
        this.generalForm.controls.recipient.setValue(ShipmentType.SUPPLIER);
        this.generalForm.controls.supplier.setValue(supplierAsRecipient.id);
      }
    }

    const row = this.buildRow(shipmentForm);
    this.rows.push(row);
    this.rows = [...this.rows];
    this.batchShip.shipmentFormIds.push(shipmentForm.id);

    if (this.rows.length > 0) {
      switch (this.generalForm.get("recipient").value) {
        case ShipmentType.SUPPLIER:
          this.generalForm.get("supplier").disable();
          break;

        case ShipmentType.CUSTOMER:
          this.generalForm.get("customer").disable();
          break;

        case ShipmentType.STORE:
          this.generalForm.get("store").disable();
          break;

        default:
          break;
      }
      this.generalForm.get("recipient").disable();
    }
  }

  private buildRow(shipmentForm: ShipmentForm): MultiShipmentRow {
    const recipientId = this.getRecipientId();

    const correctReceiver = shipmentForm.receiverId === recipientId || recipientId === null;

    const stockDepleted = shipmentForm.lines.some((line: ShipmentLine) => line.stockDepleted);

    const row: MultiShipmentRow = new MultiShipmentRow();
    (row.id = shipmentForm.id),
    (row.deliveryRef = shipmentForm.deliveryRef),
    (row.quantity = this.computeShipmentFormQuantity(shipmentForm)),
    (row.quantityUnit = this.getShipmentFormUniqueUnit(shipmentForm)),
    (row.price = this.computeShipmentFormPrice(shipmentForm)),
    (row.lineNumber = this.rows.length + 1),
    (row.inError = shipmentForm.shipmentStatus !== ShipmentStatus.DRAFT || !correctReceiver || stockDepleted),
    (row.errorMessage = this.getRowErrorMessage(shipmentForm, correctReceiver, stockDepleted));
    return row;
  }

  private getRowErrorMessage(shipmentForm: ShipmentForm, correctReceiver: boolean, stockDepleted: boolean): string {
    if (shipmentForm.shipmentStatus !== ShipmentStatus.DRAFT) {
      return "multi-shipment-form.shipment-container.errors.cannot-be-sent";
    } else if (!correctReceiver) {
      return "multi-shipment-form.shipment-container.errors.bad-recipient";
    } else if (stockDepleted) {
      return "shipment-form-list.errors.stock-depleted";
    }
    return null;
  }

  private computeShipmentFormQuantity(shipmentForm: ShipmentForm): number {
    return shipmentForm.lines.reduce((partialSum: number, line: ShipmentLine) => {
      return RoundingUtil.roundLow(new Decimal(partialSum ?? 0).add(new Decimal(line.quantity ?? 0)).toNumber());
    }, 0);
  }

  private computeShipmentFormPrice(shipmentForm: ShipmentForm): number {
    return shipmentForm.lines.reduce((partialSum: number, line: ShipmentLine) => {
      return RoundingUtil.roundLow(
        new Decimal(partialSum ?? 0)
          .add(new Decimal(line.quantity ?? 0).times(new Decimal(line.unitPrice ?? 0)))
          .toNumber()
      );
    }, 0);
  }

  private getShipmentFormUniqueUnit(shipmentForm: ShipmentForm): boolean | string {
    const allUnits = shipmentForm.lines.map((line: ShipmentLine) => {
      const stockEntry = this.stockEntryList.find((se: StockEntry) => line.stockEntryId === se.id);
      return this.purchaseUnitList.find((uom: Uom) => uom.id === stockEntry.uomId)?.longName;
    });
    return new Set(allUnits).size === this.SINGLE_SET_LENGTH ? allUnits[0] : false;
  }

  private fetchStockEntries(stockEntryIdList: number[]): Observable<PaginatedList<StockEntry>> {
    const pager = new Pagination({
      size: stockEntryIdList.length,
      number: 0,
    });
    const filters = new SearchFilter("id", SearchFilterOperator.IN, [...stockEntryIdList]);

    return this.stockEntryService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<StockEntry>) => {
          result.data.forEach(stockEntry => {
            if (!this.stockEntryList.includes(stockEntry)) {
              this.stockEntryList.push(stockEntry);
            }
          });
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant(
            "multi-shipment-form.shipment-container.errors.get-stock-entries"
          );
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierOptions = lightSuppliers
            .filter(obj => !obj.archived)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.supplierList = lightSuppliers;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-initiator-popup.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchCustomers(): Observable<Light[]> {
    return this.lightService.getCustomers().pipe(
      tap(
        (lightCustomers: Light[]) => {
          this.customerOptions = lightCustomers
            .filter(obj => !obj.archived)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.customerList = lightCustomers;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-initiator-popup.errors.get-customers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeOptions = lightStores
            .filter(obj => !obj.archived && obj.id !== this.senderId)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.storeList = lightStores;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-initiator-popup.errors.get-stores");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchDefaultCurrency(): Observable<Currency> {
    const observable = this.currencyService.fetchDefaultCurrency().pipe(filter(cur => cur !== undefined));
    this.subscriptionService.subs.push(
      observable.subscribe(currency => {
        this.defaultCurrency = currency;
      })
    );
    return observable;
  }

  private fetchConnectedUserDetails(): Observable<CaraUser> {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.locale = this.userService.connectedUser.value.codeLanguage;
      return of(this.userService.connectedUser.value);
    }
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.dateFormat = connectedUser.dateFormat;
        this.locale = connectedUser.codeLanguage;
      })
    );
  }

  private fetchUoms(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => (this.purchaseUnitList = uoms),
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("uoms-list.errors.get-entities");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private saveState(): void {
    this.unsavedState = new MultiShipmentFormState(this.initialState);
    this.shouldClose = true;
  }
}
