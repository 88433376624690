import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { ShipmentForm, ShipmentFormService, ReceiveStatus, ShipmentStatus, ShipmentLine } from "center-services";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";

@Component({
  selector: "app-shipment-form-status-popup",
  templateUrl: "./shipment-form-status-popup.component.html",
  styleUrls: ["./shipment-form-status-popup.component.scss"],
  providers: [SubscriptionService],
})
export class ShipmentFormStatusPopupComponent implements OnInit, OnChanges {
  @Input() shipmentFormList: ShipmentForm[];

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public initialStatus: string = "";
  public unsavedStatus: string = "";
  public editedStatus: string = "";
  public popupForm: UntypedFormGroup;

  public warnIcon: IconDefinition = faExclamationTriangle;

  public shouldClose: boolean = false;

  public statusOptions: Option[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService,
    private shipmentFormService: ShipmentFormService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnChanges(): void {
    this.prepareForm();
    this.editedStatus = "";
    this.initialStatus = "";
    if (this.shipmentFormList) {
      this.editedStatus = this.shipmentFormList[0].shipmentStatus;
      this.initialStatus = this.shipmentFormList[0].shipmentStatus;
    }

    this.refresh();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.buildStatusOptions();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildStatusOptions();
      })
    );
    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      status: [0, [Validators.required]],
    });
  }

  buildStatusOptions(): void {
    if (this.shipmentFormList && this.shipmentFormList.length > 0) {
      const received = !(
        this.shipmentFormList.filter(shipmentForm => shipmentForm.receiveStatus === ReceiveStatus.RECEIVED).length === 0
      );
      this.statusOptions = [];
      const rootStr = "shipment-form-list.shipment-status-options";
      this.statusOptions.push(
        new Option(
          Object.keys(ShipmentStatus).indexOf(this.initialStatus),
          this.translateService.instant(`${rootStr}.${this.initialStatus}`)
        )
      );
      switch (this.editedStatus) {
        case "DRAFT":
        case "TO_PROCESS":
          if (this.shipmentFormList.every(po => po.lines.length === 0)) {
            this.statusOptions.push(
              new Option(
                Object.keys(ShipmentStatus).indexOf("CANCELED"),
                this.translateService.instant(`${rootStr}.CANCELED`)
              )
            );
            break;
          }
          if (!received) {
            this.statusOptions.push(
              new Option(
                Object.keys(ShipmentStatus).indexOf("CANCELED"),
                this.translateService.instant(`${rootStr}.CANCELED`)
              )
            );
          }
          this.statusOptions.push(
            new Option(Object.keys(ShipmentStatus).indexOf("SENT"), this.translateService.instant(`${rootStr}.SENT`))
          );
          break;
        case "CANCELED":
          this.statusOptions.push(
            new Option(Object.keys(ShipmentStatus).indexOf("DRAFT"), this.translateService.instant(`${rootStr}.DRAFT`))
          );
          break;
        case "SENT":
          this.statusOptions.push(
            new Option(
              Object.keys(ShipmentStatus).indexOf("SETTLED"),
              this.translateService.instant(`${rootStr}.SETTLED`)
            )
          );
          if (!received) {
            this.statusOptions.push(
              new Option(
                Object.keys(ShipmentStatus).indexOf("CANCELED"),
                this.translateService.instant(`${rootStr}.CANCELED`)
              )
            );
          }
          break;
        default:
          console.error(`Cannot handle StatusType: ${this.editedStatus}`);
          break;
      }
    }
  }

  getStatusTranslated(id: number): string {
    return this.statusOptions.find(x => x.id === id).label;
  }

  initializePopup(): void {
    if (this.shipmentFormList && this.shipmentFormList.length > 0) {
      const index = Object.keys(ShipmentStatus).indexOf(this.editedStatus);
      this.popupForm.controls.status.setValue(index);
    }
  }

  closeStatusPopup(): void {
    this.editedStatus = Object.values(ShipmentStatus)[this.popupForm.value.status];

    if (this.unsavedStatus) {
      const hasChangedForUnsaved = this.editedStatus === this.unsavedStatus ? false : true;
      if (hasChangedForUnsaved) {
        this.shouldClose = false;
      }
    }

    const hasChangedForInitial = this.editedStatus === this.initialStatus ? false : true;

    if (hasChangedForInitial && !this.shouldClose) {
      this.unsavedStatus = "";
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedStatus = this.editedStatus;
    } else {
      this.close.emit();
      this.unsavedStatus = null;
      this.shouldClose = false;
    }
  }

  submitStatusPopup(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    this.editedStatus = Object.values(ShipmentStatus)[this.popupForm.value.status];
    const obs = [];

    if (this.editedStatus === this.initialStatus) {
      this.close.emit();
      return;
    }

    // handle stockDepleted if try to send shipment
    if (this.editedStatus === ShipmentStatus.SENT) {
      const depletedDeliveryRefList = [];
      this.shipmentFormList.forEach((shipmentForm: ShipmentForm) => {
        if (shipmentForm.lines.some((line: ShipmentLine) => line.stockDepleted)) {
          depletedDeliveryRefList.push(shipmentForm.deliveryRef);
        }
      });

      if (depletedDeliveryRefList.length === 1) {
        this.triggerStockDepletedError(false, depletedDeliveryRefList);
        return;
      }

      if (depletedDeliveryRefList.length > 1) {
        this.triggerStockDepletedError(true, depletedDeliveryRefList);
        return;
      }
    }

    this.shipmentFormList.forEach(shipmentForm => {
      shipmentForm.shipmentStatus = Object.values(ShipmentStatus)[this.popupForm.value.status];
      obs.push(this.statusAPICall(shipmentForm));
    });

    this.subscriptionService.subs.push(
      combineLatest(obs).subscribe(
        () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          this.validate.emit();
        },
        () => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("shipment-form-list.errors.update");
          this.messageService.error(content, { title });
        }
      )
    );
  }

  statusAPICall(shipmentForm: ShipmentForm): Observable<ShipmentForm> {
    return this.shipmentFormService.status(shipmentForm.id, shipmentForm.shipmentStatus);
  }

  private triggerStockDepletedError(plural: boolean, deliveryRefList: string[]): void {
    const title = this.translateService.instant("message.title.form-errors");
    const messageKey = plural
      ? "shipment-form-list.errors.stock-depleted-plural"
      : "shipment-form-list.errors.stock-depleted";
    const content = this.translateService.instant(messageKey, {
      deliveryRef: plural ? deliveryRefList.map(dr => ` ${dr}`) : deliveryRefList[0],
    });
    this.messageService.error(content, { title });
  }
}
