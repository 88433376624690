import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BaseListPopupComponent, ErrorUtil } from "generic-pages";
import { Option, SubscriptionService } from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import {
  Classification,
  ItemCategory,
  ClassificationService,
  CaraUserService,
  ItemCategoryService,
  ClassificationValue,
} from "center-services";

@Component({
  selector: "app-classifications-list",
  templateUrl: "./classifications-list.component.html",
  styleUrls: ["./classifications-list.component.scss"],
  providers: [SubscriptionService],
})
export class ClassificationsListComponent extends BaseListPopupComponent<Classification> implements OnInit {
  @ViewChild("valueTable") valueTable: any;
  public popupSorts: any[];
  public valueRows: any[] = [];
  public valueForm: UntypedFormArray;
  public allClassificationItemCategories: ItemCategory[];
  public classificationItemCategoryIds: number[];
  public itemCategoryOptions: Option[];
  public menuActions: MenuAction[] = [];
  public isBlurred: any = {};
  protected readonly VALUES: string = "values";
  private cpt: number = 0;

  constructor(
    classificationService: ClassificationService,
    translateService: TranslateService,
    messageService: MessageService,
    private fb: UntypedFormBuilder,
    private userService: CaraUserService,
    private itemCategoryService: ItemCategoryService,
    private subscriptionService: SubscriptionService
  ) {
    super(classificationService, translateService, messageService);
  }

  ngOnInit(): void {
    this.sorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "name",
        dir: "asc",
      },
    ];
    this.popupSorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "index",
        dir: "asc",
      },
    ];
    this.tableControl = new UntypedFormGroup({});
    this.prepareForm();
    this.fetchItemCategoryList();
    this.addMenuActions();
  }

  prepareForm(): void {
    this.valueForm = null;
    this.popupForm = this.fb.group({
      name: [null, [Validators.required]],
      categories: [null],
    });
  }

  onNewEntityClick(): void {
    this.selectedEntity = new Classification({
      name: null,
      elements: [],
      itemCategoryIds: this.classificationItemCategoryIds,
      archived: false,
    });
    this.popupTitle = `${this.getTranslationPrefix()}.popup.title-new-classification`;
    this.initializePopup();
  }

  onTableActivate(event: any): any {
    if (event.type === "click") {
      if (!this.userService.canDo("CLASSIFICATION_UPDATE")) {
        return;
      }
      const filteredList = this.entityList.filter(classification => classification.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`Can't find classification with id: ${event.row.id}`);
        return;
      }
      this.selectedEntity = this.cloneEntity(filteredList[0]);
      this.popupTitle = `${this.getTranslationPrefix()}.popup.title-update-classification`;
      this.initializePopup();
    }
  }

  changeSort(prop: string, direction: string, sorts: string): void {
    if (prop === "activated") {
      this[sorts] = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this[sorts] = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }
  }

  // the arrow function bellow is used to return the rows class
  getPopupRowClass: any = (): any => ({ "not-clickable": true });

  changeSortSettings(prop: string, direction: string): void {
    this.changeSort(prop, direction, "sorts");

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  changePopupSortSettings(prop: string, direction: string): void {
    this.changeSort(prop, direction, "popupSorts");

    this.valueRows = [...this.valueRows];
    this.valueTable.sorts = this.popupSorts;
  }

  fetchItemCategoryList(): void {
    this.subscriptionService.subs.push(
      this.itemCategoryService.getAll().subscribe(
        (itemCategories: ItemCategory[]) => {
          this.allClassificationItemCategories = itemCategories.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });

          this.classificationItemCategoryIds = this.allClassificationItemCategories
            .filter((cat: ItemCategory) => !cat.archived)
            .map(itemCategory => itemCategory.id);

          this.itemCategoryOptions = this.allClassificationItemCategories
            .filter((cat: ItemCategory) => !cat.archived)
            .map((obj: ItemCategory) => new Option(obj.id, obj.name));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("classifications-list.errors.get-item-categories", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        },
        () => {
          this.fetchEntities();
        }
      )
    );
  }

  fetchEntities(): void {
    this.rows = [];
    this.subscriptionService.subs.push(
      this.entityService.getAll().subscribe(
        (classifications: Classification[]) => {
          this.entityList = classifications;
          classifications.forEach((classification: Classification) => {
            const itemCategories = this.allClassificationItemCategories
              .filter(itemCategory => classification.itemCategoryIds.includes(itemCategory.id))
              .map(itemCategory => itemCategory.name);

            this.rows.push({
              id: classification.id,
              name: classification.name,
              values: classification.elements.filter(e => !e.archived).map(e => e.value),
              categories: itemCategories,
              activated: !classification.archived,
            });
            this.rows = [...this.rows];
            if (this.tableControl.controls[`activated_${classification.id}`]) {
              this.tableControl.controls[`activated_${classification.id}`].patchValue(!classification.archived);
            } else {
              this.tableControl.addControl(
                `activated_${classification.id}`,
                new UntypedFormControl({
                  value: !classification.archived,
                  disabled: !this.userService.canDo("CLASSIFICATION_ARCHIVE"),
                })
              );
            }
          });
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("classifications-list.errors.get-classifications", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  valueCheckboxOnChanges(rowIndex: string | number): void {
    this.valueRows[rowIndex].activated = this.valueForm.value[rowIndex].activated;
    this.valueRows = [...this.valueRows];
  }

  addValueRow(element: ClassificationValue): void {
    const newValueRow = {
      id: element.id,
      index: this.cpt,
      value: element.value,
      activated: !element.archived,
    };
    this.cpt++;
    this.valueRows.push(newValueRow);
    this.isBlurred[this.VALUES].push(false);
  }

  initializePopup(): void {
    this.isBlurred[this.VALUES] = [];

    super.initializePopup();
  }

  setItemCategories(): void {
    if (this.selectedEntity.itemCategoryIds) {
      const itemCategoryIds = this.selectedEntity.itemCategoryIds.filter(id =>
        this.classificationItemCategoryIds.includes(id)
      );
      this.popupForm.controls.categories.setValue(itemCategoryIds);
    } else {
      this.popupForm.controls.categories.setValue(this.classificationItemCategoryIds);
    }
  }

  addValue(): void {
    this.addValueRow(
      new ClassificationValue({
        id: null,
        value: null,
        archived: false,
      })
    );

    this.valueRows = [...this.valueRows];

    // add form controls within formGroup
    const rowForm = this.fb.group({
      name: [null, [Validators.required]],
      activated: true,
    });
    this.valueForm.push(rowForm);

    if (this.popupForm.controls.values) {
      this.popupForm.setControl("values", this.valueForm);
    } else {
      this.popupForm.addControl("values", this.valueForm);
    }
  }

  applyModifications(): void {
    this.selectedEntity.name = this.popupForm.value.name;
    this.selectedEntity.elements = this.valueRows.map(row => {
      return new ClassificationValue({
        id: row.id,
        value: this.valueForm.at(row.index).value.name,
        archived: !this.valueForm.at(row.index).value.activated,
      });
    });
    this.selectedEntity.itemCategoryIds = this.popupForm.value.categories;
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: `${this.getTranslationPrefix()}.popup.name`,
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  closePopup(): void {
    super.closePopup();
    this.cpt = 0;
    this.valueRows = [];
    this.popupSorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "index",
        dir: "asc",
      },
    ];
    this.popupForm.removeControl("values");
  }

  addMenuActions(): void {
    this.menuActions = [];

    this.menuActions.push(
      new MenuAction(0, this.translateService.instant("classifications-list.actions.remove"), faTrashAlt)
    );
  }

  manageActions(row: any): void {
    this.removeClassification(row);
  }

  removeClassification(selectedRow: any): void {
    // remove row from datatable
    this.valueRows.forEach(row => {
      if (selectedRow.index === row.index) {
        this.valueRows.splice(this.valueRows.indexOf(row), 1);
      }
    });

    this.valueForm.removeAt(selectedRow.index);
    this.isBlurred[this.VALUES].splice(selectedRow.index, 1);

    this.popupForm.setControl("values", this.valueForm);

    this.valueRows = [...this.valueRows];

    this.valueRows.forEach(row => {
      if (row.index > selectedRow.index) {
        row.index--;
      }
    });
    // Its needed because ValueForm is a FormArray and not a FormGroup
    this.cpt--;
  }

  onBlurValue(i: string | number): void {
    this.isBlurred[this.VALUES][i] = true;
  }

  onFocusValue(i: string | number): void {
    this.isBlurred[this.VALUES][i] = false;
  }

  protected cloneEntity(entity: Classification): Classification {
    return new Classification(entity);
  }

  protected canArchive(): boolean {
    return this.userService.canDo("CLASSIFICATION_ARCHIVE");
  }

  protected getTranslationPrefix(): string {
    return "classifications-list";
  }

  protected preparePopupForm(): any {
    this.popupForm.controls.name.setValue(this.selectedEntity.name);

    this.valueForm = new UntypedFormArray([]);
    if (this.selectedEntity.elements) {
      // populate values table
      this.valueRows = [];
      this.selectedEntity.elements.forEach((element: ClassificationValue) => {
        this.addValueRow(element);
      });
      // create form controls
      this.valueRows.forEach((row: any) => {
        const rowForm = this.fb.group({
          name: [row.value, [Validators.required]],
          activated: row.activated,
        });
        this.valueForm.push(rowForm);

        this.popupForm.addControl("values", this.valueForm);
      });
    }

    this.setItemCategories();
  }

  protected addRow(): void {
    console.error("Method should not have been called");
  }
}
