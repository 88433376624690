import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  public isLoading: Subject<boolean> = new Subject<boolean>();
  public httpMethod: Subject<string> = new Subject<string>();

  public showLoader(httpMethod: string): void {
    this.httpMethod.next(httpMethod);
    this.isLoading.next(true);
  }

  public hideLoader(): void {
    this.isLoading.next(false);
  }
}
