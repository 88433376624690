import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { IconDefinition, faChevronLeft, faPen, faPlus, faTrash, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  InvoiceSupplier,
  ItemPurchaseModality,
  Light,
  Currency,
  ReceivingForm,
  PurchaseOrder,
  AbstractItem,
  InvoiceSupplierTab,
  Uom,
  SizeCategory,
  Brand,
  Supplier,
  InvoiceSupplierService,
  PurchaseOrderService,
  RetailItemService,
  CurrencyService,
  SizeCategoryService,
  ReceivingFormService,
  BrandService,
  SupplierService,
  LightService,
  UomService,
  CaraUserService,
  InvoiceStatus,
  InvoiceSupplierLineRow,
  InvoiceSupplierLine,
  AbstractReceivingLine,
  ReceivingFreeLine,
  ReceivingPOLine,
  DiscountType,
  StandardItem,
  PurchaseOrderLine,
  PurchaseModality,
  CaraUser,
  PaginatedList,
  Pagination,
} from "center-services";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  RoundingUtil,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService, TabComponent } from "fugu-components";
import { ComponentDirty, ErrorUtil, FilteredTableListComponent, PrecisionUtil } from "generic-pages";
import { combineLatest, Observable, of } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { InvoiceSupplierDeliverySelectionComponent } from "../invoice-supplier-delivery-selection/invoice-supplier-delivery-selection.component";
import { InvoiceSupplierInitiatorOutput } from "../invoice-supplier-initiator-popup/invoice-supplier-initiator-output";
import { InvoiceSupplierPmSelectionComponent } from "../invoice-supplier-pm-selection/invoice-supplier-pm-selection.component";
import { DatatableComponent } from "@siemens/ngx-datatable";
import Decimal from "decimal.js";

@Component({
  selector: "app-supplier-invoice-supplier-form",
  templateUrl: "./invoice-supplier-form.component.html",
  styleUrls: ["./invoice-supplier-form.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceSupplierFormComponent
  extends FilteredTableListComponent
  implements OnInit, ComponentDirty, PaginableComponent {
  @ViewChild("invoiceHeader") invoiceHeader: any;
  @ViewChild("tabHandler") tabHandler: any;
  @ViewChild("table") table: DatatableComponent;

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;

  public LIST_ID: string = "app-invoice-supplier-form.invoice-supplier-form";
  public READ_LIST_ID: string = "app-invoice-supplier-form.invoice-supplier-form-detail";
  public listId: string;
  public menuActions: MenuAction[] = [];
  public faChevronLeft: IconDefinition = faChevronLeft;
  public faTrash: IconDefinition = faTrash;
  public faWarn: IconDefinition = faWarning;
  public faPlus: IconDefinition = faPlus;
  public faPen: IconDefinition = faPen;
  public initObservables: Observable<any>[] = [];
  public updatedInvoiceSupplier: InvoiceSupplier;
  public unsavedInvoiceSupplier: InvoiceSupplier;
  public editedInvoiceSupplier: InvoiceSupplier;
  public selectedPMs: ItemPurchaseModality[] = [];
  public invoiceSupplierInitiatorPopupVisible: boolean = false;
  public deleteTabConfirmationPopupVisible: boolean = false;
  public locale: string;
  public dateFormat: string;
  public supplierLightList: Light[] = [];
  public brandLightList: Light[] = [];
  public brandList: string[] = [];
  public sizeValueList: string[] = [];
  public subTitle: string;
  public supplierId: number;
  public currency: Currency;
  public deliveryFormIds: number[] = [];
  public deliveryFormList: ReceivingForm[] = [];
  public itemPurchaseModalityList: ItemPurchaseModality[] = [];
  public purchaseOrderList: PurchaseOrder[] = [];
  public retailItemList: AbstractItem[] = [];
  public currencyList: Currency[] = [];
  public invoiceSupplierTabs: InvoiceSupplierTab[] = [];
  public tabToDelete: TabComponent;
  public uomList: Uom[] = [];
  public sizeCategoryList: SizeCategory[];
  public uniqueBrand: Brand;
  public supplier: Supplier;
  public tabNameList: string[] = [];
  public activeTab: InvoiceSupplierTab;
  public totalQuantity: number;
  public totalGrossPrice: number;
  public totalQuickPaymentDiscount: number;
  public totalPrice: number;
  public shippingFeePrice: number;
  public forwardingPrice: number;
  public extraPrice: number;
  public taxPrice: number;
  public extraCost: number;
  public form: UntypedFormGroup;
  public sorts: any[] = [];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public shouldValidate: boolean = false;
  public isValidated: boolean = false;
  public shouldClose: boolean = false;
  public hasError: boolean = false;
  public readOnly: boolean;
  public title: string;
  public invoiceNumber: string;
  public editBtnVisible: boolean;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });
  private readonly backUrl: string = "/invoice-supplier-list";
  // eslint-disable-next-line no-magic-numbers
  private readonly vatDefaultValue: number = 0.2;
  private linesTaxPrice: number;
  private sessionPagination: SessionPagination;
  private readonly DELETE_ACTION_ID: number = 0;
  private readonly HUNDRED: number = 100;
  private readonly ZERO: number = 0;
  private tabId: number = 0;

  constructor(
    private invoiceSupplierService: InvoiceSupplierService,
    private purchaseOrderService: PurchaseOrderService,
    private retailItemService: RetailItemService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private currencyService: CurrencyService,
    private sizeCategoryService: SizeCategoryService,
    private receivingFormService: ReceivingFormService,
    private brandService: BrandService,
    private supplierService: SupplierService,
    private lightService: LightService,
    private uomService: UomService,
    private route: ActivatedRoute,
    private router: Router,
    protected userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.readOnly = this.route.snapshot.data.readOnly;
    this.listId = this.readOnly ? this.READ_LIST_ID : this.LIST_ID;
    this.sessionPagination = new SessionPagination(this);
    this.backUrl = this.router.getCurrentNavigation()?.extras?.state?.backUrl ?? "/invoice-supplier-list";
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    // Set value save in session for filter values save in preferences
    const activeFilters = this.filterer.filterValues.map(fv => {
      const sessionValue = filters.find(filter => filter.filterId === fv.filterId);
      return { ...fv, ...sessionValue };
    });
    this.filterer.filterValues = [...activeFilters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      shippingFeePrice: 0,
      forwardingPrice: 0,
      extraPrice: 0,
      extraCost: 0,
    });
    this.subscriptionService.subs.push(
      this.form.valueChanges.subscribe(() => {
        this.computeTaxPrice();
        this.computeTotalQuickPaymentDiscount();
        this.computeTotalPrice();
      })
    );
    this.addMenuActions();
    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.addMenuActions();
      })
    );
    this.tabId = 0;

    const invoiceSupplierId = this.route.snapshot.params.id;
    this.initObservables.push(this.fetchConnectedUserDetails());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchBrands());
    this.initObservables.push(this.fetchCurrencies());
    this.initObservables.push(this.fetchSizeCategories());
    this.initObservables.push(this.fetchUoms());
    if (invoiceSupplierId) {
      this.initObservables.push(this.fetchInvoiceSupplier(invoiceSupplierId));
    }

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (invoiceSupplierId) {
          this.currency = this.currencyList.find(cur => cur.id === this.updatedInvoiceSupplier.currencyId);
          this.subTitle = this.supplierLightList.find(elem => elem.id === this.supplierId).name;
          this.title = this.readOnly ? "invoice-supplier-form.detail.title" : "invoice-supplier-form.title";
          this.invoiceNumber = this.updatedInvoiceSupplier?.reference;
          this.editBtnVisible = this.readOnly && this.updatedInvoiceSupplier.invoiceStatus !== InvoiceStatus.EXPORTED;

          const data = this.fillIdsObjFromIS(this.updatedInvoiceSupplier);
          this.subscriptionService.subs.push(
            this.fetchLinkedItems(data).subscribe(() => {
              this.subscriptionService.subs.push(
                this.getSupplier(this.supplierId).subscribe(() => {
                  this.buildContent(this.updatedInvoiceSupplier);

                  const firstTab = this.invoiceSupplierTabs[0];

                  this.computeTotals();
                  this.buildOptions(firstTab);
                  this.initFilters();
                  this.sessionPagination.loadFromSession(`${this.listId}-tab-${firstTab.id}`);
                  this.applyFilters(firstTab);
                })
              );
            })
          );
        } else {
          this.invoiceSupplierInitiatorPopupVisible = true;
          this.title = "invoice-supplier-form.title";
        }
      })
    );
  }

  public changeSortSettings(prop: string, dir: string, tabId: number): void {
    this.sorts = [{ prop, dir }];
    this.sessionPagination.saveToSession(`${this.listId}-tab-${tabId}`);
  }

  buildOptions(tab: InvoiceSupplierTab): void {
    const numericSorter = new Intl.Collator(this.locale, { numeric: true });
    const rawSizeValueList = tab.allRows
      .filter((obj: InvoiceSupplierLineRow) => obj.sizeValue)
      .map((obj: InvoiceSupplierLineRow) => obj.sizeValue);
    this.sizeValueList = [...new Set(rawSizeValueList)].sort((a, b) => numericSorter.compare(a, b));

    const rawBrandList = tab.allRows
      .filter((obj: InvoiceSupplierLineRow) => obj.brandName)
      .map((obj: InvoiceSupplierLineRow) => obj.brandName);
    this.brandList = [...new Set(rawBrandList)].sort((a, b) => a.localeCompare(b));
  }

  editInvoiceSupplierForm(): void {
    if (!this.userService.canDo("INVOICE_SUPPLIER_UPDATE")) {
      return;
    }
    this.sessionPagination.saveToSession(this.listId);

    const navigationExtras: NavigationExtras = {
      state: { backUrl: `/invoice-supplier-detail/${this.editedInvoiceSupplier.id}` },
    };
    this.router.navigate([`/invoice-supplier/update/${this.editedInvoiceSupplier.id}`], navigationExtras);
  }

  openPopup(activeTab: InvoiceSupplierTab = null): void {
    this.invoiceSupplierInitiatorPopupVisible = true;
    this.activeTab = activeTab;
  }

  onValidateInvoiceSupplierInitiatorPopup(event: any): void {
    this.invoiceSupplierInitiatorPopupVisible = false;
    const invoiceSupplierInitiatorOutput = event[0];
    const activeTab = event[1];

    const data = this.fillIdsObj(invoiceSupplierInitiatorOutput);

    // if this method is called using the 'Add BL' button
    this.subscriptionService.subs.push(
      this.fetchLinkedItems(data).subscribe(() => {
        // handle updatedInvoiceSupplier exists or not
        if (!this.updatedInvoiceSupplier) {
          this.subscriptionService.subs.push(
            combineLatest([
              this.getUniqueBrand(),
              this.getSupplier(invoiceSupplierInitiatorOutput.supplierId),
            ]).subscribe(() => {
              this.updatedInvoiceSupplier = new InvoiceSupplier({
                reference: null,
                date: null,
                maxPaymentDate: null,
                quickPaymentDate: null,
                invoiceStatus: InvoiceStatus.DRAFT,
                supplierId: invoiceSupplierInitiatorOutput.supplierId,
                currencyId: invoiceSupplierInitiatorOutput.currency.id,
                shippingFeePrice: 0,
                forwardingPrice: 0,
                extraPrice: 0,
                extraCost: 0,
                taxPrice: 0,
                quickPaymentDiscount: this.getCommercialModalityWithAttribute("quickPaymentDiscount"),
                lines: this.createInvoiceSupplierLines(invoiceSupplierInitiatorOutput, activeTab),
              });

              this.subTitle = this.supplierLightList.find(
                elem => elem.id === invoiceSupplierInitiatorOutput.supplierId
              ).name;
              this.currency = this.currencyList.find(cur => cur.id === invoiceSupplierInitiatorOutput.currency.id);

              this.editedInvoiceSupplier = new InvoiceSupplier(this.updatedInvoiceSupplier);
              this.supplierId = invoiceSupplierInitiatorOutput.supplierId;

              this.buildContent(this.updatedInvoiceSupplier);
              const firstTab = this.invoiceSupplierTabs[0];

              this.computeTotals();
              this.buildOptions(firstTab);
              this.initFilters();
              this.sessionPagination.loadFromSession(`${this.listId}-tab-${firstTab.id}`);
              this.applyFilters(firstTab);
            })
          );
        } else {
          const newLines = this.createInvoiceSupplierLines(invoiceSupplierInitiatorOutput, activeTab);
          newLines.forEach((line: InvoiceSupplierLine) => {
            this.updatedInvoiceSupplier.lines.push(line);
            this.buildRow(line);
          });
          const firstTab = this.invoiceSupplierTabs[0];

          this.computeTotals();
          this.buildOptions(firstTab);
          this.initFilters();
          this.sessionPagination.loadFromSession(`${this.listId}-tab-${firstTab.id}`);
          this.applyFilters(firstTab);

          if (activeTab) {
            activeTab.rows = [...activeTab.rows];
          }
        }
      })
    );
  }

  getCommercialModalityWithAttribute(attribute: string): number {
    if (this.uniqueBrand && this.uniqueBrand.commercialModality) {
      if (this.uniqueBrand.commercialModality[attribute]) {
        return this.uniqueBrand.commercialModality[attribute];
      } else {
        if (attribute === "quickPaymentPeriod" && this.uniqueBrand.commercialModality.quickPaymentDiscountType) {
          return 0;
        }
        if (attribute === "maxPaymentPeriod") {
          return 0;
        }
      }
    }

    const supplier = this.supplier;
    if (supplier && supplier.commercialModality && supplier.commercialModality[attribute]) {
      return supplier.commercialModality[attribute];
    }
    return null;
  }

  getUniqueBrand(): Observable<Brand> {
    const unique = [...new Set(this.retailItemList.map(item => item.brandId))];
    if (unique.length === 1) {
      const brandName = this.retailItemList[0].brandName;
      const brandFound = this.brandLightList.find((brand: Light) => brand.name === brandName);
      if (brandFound) {
        return this.brandService.get(brandFound.id).pipe(
          tap(
            (brand: Brand) => {
              this.uniqueBrand = brand;
            },
            () => {
              const title = this.translateService.instant("message.title.data-errors");
              const content = this.translateService.instant("invoice-supplier-form.errors.get-brand");
              this.messageService.warn(content, { title });
            }
          )
        );
      }
    }
    return of(null);
  }

  getSupplier(id: number): Observable<Supplier> {
    return this.supplierService.get(id).pipe(
      tap(
        (supplier: Supplier) => {
          this.supplier = supplier;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-form.errors.get-supplier");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fillIdsObj(invoiceSupplierInitiatorOutput: InvoiceSupplierInitiatorOutput): {
    pmIds: Set<number>;
    polIds: Set<number>;
  } {
    const pmIds = new Set<number>();
    const polIds = new Set<number>();

    if (
      invoiceSupplierInitiatorOutput.itemPurchaseModalities &&
      invoiceSupplierInitiatorOutput.itemPurchaseModalities.length > 0
    ) {
      invoiceSupplierInitiatorOutput.itemPurchaseModalities.forEach((ipm: ItemPurchaseModality) => {
        pmIds.add(ipm.purchaseModalityId);
      });
    }
    if (invoiceSupplierInitiatorOutput.deliveryForms && invoiceSupplierInitiatorOutput.deliveryForms.length > 0) {
      invoiceSupplierInitiatorOutput.deliveryForms.forEach((deliveryForm: ReceivingForm) => {
        this.deliveryFormIds.push(deliveryForm.id);
        this.deliveryFormList.push(deliveryForm);

        deliveryForm.lines.forEach((deliveryLine: AbstractReceivingLine) => {
          if (deliveryLine instanceof ReceivingFreeLine) {
            pmIds.add(deliveryLine.purchaseModalityId);
          } else if (deliveryLine instanceof ReceivingPOLine) {
            polIds.add(deliveryLine.purchaseOrderLineId);
          }
        });
      });
    }
    return { pmIds, polIds };
  }

  fillIdsObjFromIS(invoiceSupplier: InvoiceSupplier): { pmIds: Set<number>; polIds: Set<number> } {
    const pmIds = new Set<number>();
    const polIds = new Set<number>();

    invoiceSupplier.lines.forEach((line: InvoiceSupplierLine) => {
      if (line.purchaseModalityId) {
        pmIds.add(line.purchaseModalityId);
      } else if (line.newDeliveryFormId) {
        const deliveryFormFound = this.deliveryFormList.find(df => df.id === line.newDeliveryFormId);
        deliveryFormFound.lines.forEach((deliveryLine: AbstractReceivingLine) => {
          if (deliveryLine instanceof ReceivingFreeLine) {
            pmIds.add(deliveryLine.purchaseModalityId);
          } else if (deliveryLine instanceof ReceivingPOLine) {
            polIds.add(deliveryLine.purchaseOrderLineId);
          }
        });
      }
    });
    return { pmIds, polIds };
  }

  createInvoiceSupplierLines(
    invoiceSupplierInitiatorOutput: InvoiceSupplierInitiatorOutput,
    activeTab: InvoiceSupplierTab
  ): InvoiceSupplierLine[] {
    const invoiceSupplierLines: InvoiceSupplierLine[] = [];
    const vatActivated = this.supplier.commercialModality.vatActivated;

    // comes with deliveryForms
    if (invoiceSupplierInitiatorOutput.deliveryForms?.length > 0) {
      invoiceSupplierInitiatorOutput.deliveryForms.forEach((deliveryForm: ReceivingForm) => {
        deliveryForm.lines
          .filter(line => !line.invoiceSupplierLineId)
          .forEach((deliveryLine: AbstractReceivingLine, index: number) => {
            const pm = this.getPMFromDL(deliveryLine);
            const item = this.getItemFromPM(pm.id);

            const line = new InvoiceSupplierLine({
              lineNumber: !activeTab ? index + 1 : activeTab.allRows.length + index + 1,
              quantity: deliveryLine.expectedQuantity,
              unitPrice: this.computeInvoiceSupplierLineUnitPrice(deliveryLine),
              sizeValue: deliveryLine.receivedSizeValue,
              deliveryRef: null,
              newDeliveryLineId: deliveryLine.id,
              newDeliveryFormId: deliveryForm.id,
              purchaseModalityId: null,
              vatRate: vatActivated ? item.vatRate || this.ZERO : this.ZERO,
            });
            invoiceSupplierLines.push(line);
          });
      });
    }
    // comes with purchaseModalities
    if (invoiceSupplierInitiatorOutput.itemPurchaseModalities?.length > 0) {
      invoiceSupplierInitiatorOutput.itemPurchaseModalities.forEach(
        (itemPurchaseModality: ItemPurchaseModality, index: number) => {
          this.itemPurchaseModalityList.push(itemPurchaseModality);
          const pm = this.getPMFromId(itemPurchaseModality.purchaseModalityId);
          const item = this.getItemFromPM(pm.id);

          const line = new InvoiceSupplierLine({
            lineNumber: !activeTab ? index + 1 : activeTab.allRows.length + index + 1,
            quantity: 1,
            unitPrice: itemPurchaseModality.computedPrice,
            sizeValue: this.getItemSizeValue(itemPurchaseModality.purchaseModalityId),
            deliveryRef: !activeTab ? invoiceSupplierInitiatorOutput.deliveryRef : activeTab.deliveryRef,
            newDeliveryLineId: null,
            newDeliveryFormId: !activeTab ? null : activeTab.deliveryFormId,
            purchaseModalityId: itemPurchaseModality.purchaseModalityId,
            vatRate: vatActivated ? item.vatRate || this.ZERO : this.ZERO,
          });
          invoiceSupplierLines.push(line);
        }
      );
    }
    return invoiceSupplierLines;
  }

  computeInvoiceSupplierLineUnitPrice(deliveryLine: AbstractReceivingLine): number {
    const quantity = deliveryLine.receivedQuantity ?? 0;
    const unitPrice = deliveryLine.unitPrice ?? 0;
    const unitPricePerWeight = deliveryLine.unitPricePerWeight ?? 0;
    const totalWeight = deliveryLine.weight ?? 0;
    const tare = deliveryLine.tare ?? 0;
    const metalPrice = deliveryLine.metalPrice ?? 0;
    const discount = deliveryLine.getDiscount();
    const discountType = deliveryLine.getDiscountType();

    let totalUnitPrice = deliveryLine.formulaForTotalUnitPrice(
      quantity,
      unitPrice,
      unitPricePerWeight,
      totalWeight,
      tare
    );
    totalUnitPrice = new Decimal(totalUnitPrice ?? 0).plus(metalPrice ?? 0).toNumber();

    if (discountType !== undefined && discountType !== null && discount !== undefined && discount !== null) {
      totalUnitPrice = new Decimal(totalUnitPrice ?? 0)
        .minus(
          discountType === DiscountType.PERCENT
            ? new Decimal(totalUnitPrice ?? 0).times(discount ?? 0).div(this.HUNDRED)
            : discount
        )
        .toNumber();
    }

    return RoundingUtil.roundLow(totalUnitPrice);
  }

  buildContent(invoiceSupplier: InvoiceSupplier): void {
    this.form.controls.shippingFeePrice.setValue(invoiceSupplier.shippingFeePrice);
    this.form.controls.forwardingPrice.setValue(invoiceSupplier.forwardingPrice);
    this.form.controls.extraPrice.setValue(invoiceSupplier.extraPrice);
    this.form.controls.extraCost.setValue(invoiceSupplier.extraCost);
    invoiceSupplier.lines.forEach((invoiceSupplierLine: InvoiceSupplierLine) => {
      this.buildRow(invoiceSupplierLine);
    });
  }

  buildRow(invoiceSupplierLine: InvoiceSupplierLine): void {
    const row = this.getLineRow(invoiceSupplierLine);
    this.buildTabs(invoiceSupplierLine.newDeliveryFormId !== null, invoiceSupplierLine, row);
  }

  buildTabs(withDF: boolean, invoiceSupplierLine: InvoiceSupplierLine, row: InvoiceSupplierLineRow): void {
    let tabFound = withDF
      ? this.invoiceSupplierTabs.find(tab => tab.deliveryFormId === invoiceSupplierLine.newDeliveryFormId)
      : this.invoiceSupplierTabs.find(tab => tab.deliveryRef === invoiceSupplierLine.deliveryRef);

    if (!tabFound) {
      tabFound = new InvoiceSupplierTab();
      tabFound.deliveryRef = invoiceSupplierLine.deliveryRef;
      tabFound.deliveryFormId = invoiceSupplierLine.newDeliveryFormId;
      tabFound.rows = [];
      tabFound.allRows = [];
      tabFound.id = this.tabId;
      tabFound.tabForm = new UntypedFormGroup({});
      this.invoiceSupplierTabs.push(tabFound);
      this.form.addControl((this.invoiceSupplierTabs.length - 1).toString(), tabFound.tabForm);
      this.tabNameList.push(this.getTabName(tabFound));

      this.tabId++;
    }

    tabFound.rows.push(row);
    tabFound.allRows.push(row);
    tabFound.tabForm.addControl(row.lineNumber.toString(), row.rowForm);
  }

  getLineRow(invoiceSupplierLine: InvoiceSupplierLine): InvoiceSupplierLineRow {
    const dl = this.getDL(invoiceSupplierLine);

    const pol = dl ? this.getPOL(dl) : null;
    const pm = dl ? this.getPMFromDL(dl) : this.getPMFromId(invoiceSupplierLine.purchaseModalityId);
    const item = this.getItemFromPM(pm.id);

    const row = new InvoiceSupplierLineRow();
    row.id = invoiceSupplierLine.id;
    row.lineNumber = invoiceSupplierLine.lineNumber;
    row.itemSupplierRef = pol ? pol.supplierRef : pm.supplierRef;
    row.itemRef = item.reference;
    row.itemName = item.name;
    row.brandName = item.brandName;
    row.quantity = invoiceSupplierLine.quantity;
    row.quantityUnit = this.getPurchaseUnitName(pol, pm);
    row.sizeValue = invoiceSupplierLine.sizeValue;
    row.unitPrice = invoiceSupplierLine.unitPrice;
    row.totalGrossPrice = RoundingUtil.roundLow(
      new Decimal(invoiceSupplierLine.unitPrice ?? 0).times(invoiceSupplierLine.quantity ?? 0).toNumber()
    );
    row.vatRate = invoiceSupplierLine.vatRate ?? 0;

    row.rowForm = this.fb.group({
      quantity: [invoiceSupplierLine.quantity, Validators.required],
      unitPrice: [invoiceSupplierLine.unitPrice, Validators.required],
    });
    if (invoiceSupplierLine.purchaseModalityId && this.getItemSizeValue(invoiceSupplierLine.purchaseModalityId)) {
      row.rowForm.addControl("sizeValue", new UntypedFormControl(invoiceSupplierLine.sizeValue));
    }

    this.subscriptionService.subs.push(
      row.rowForm.valueChanges.subscribe(() => {
        this.computeTotals();
      })
    );

    return row;
  }

  onCloseTab(tabToDelete: TabComponent): void {
    this.deleteTabConfirmationPopupVisible = true;
    this.tabToDelete = tabToDelete;
  }

  closeDeleteTabConfirmationPopup(): void {
    this.deleteTabConfirmationPopupVisible = false;
    this.tabToDelete = null;
  }

  deleteTab(): void {
    this.deleteTabConfirmationPopupVisible = false;
    const tabFound = this.invoiceSupplierTabs[+this.tabToDelete.id];

    // update deliveryFormIds list
    const deliveryFormIdIndex = this.deliveryFormIds.findIndex(id => id === tabFound.deliveryFormId);
    this.deliveryFormIds.splice(deliveryFormIdIndex, 1);
    this.deliveryFormIds = [...this.deliveryFormIds];

    // update invoice supplier lines
    const lineIndexList = [];
    this.updatedInvoiceSupplier.lines.forEach((line: InvoiceSupplierLine, idx: number) => {
      if (line.newDeliveryFormId === tabFound.deliveryFormId && line.deliveryRef === tabFound.deliveryRef) {
        lineIndexList.push(idx);
      }
    });
    for (let i = lineIndexList.length - 1; i >= 0; i--) {
      this.updatedInvoiceSupplier.lines.splice(lineIndexList[i], 1);
    }

    // remove filter preferences linked to the tab
    SessionPagination.clear(`${this.listId}-tab-${this.invoiceSupplierTabs[this.tabToDelete.id].id}`);

    // update tabs and form controls
    this.invoiceSupplierTabs.splice(+this.tabToDelete.id, 1);
    this.form.removeControl(this.tabToDelete.id);
    Object.keys(this.form.controls).forEach(key => {
      if (+key > +this.tabToDelete.id) {
        const form = this.form.get(key);
        this.form.setControl((+key - 1).toString(), form);
        this.form.removeControl(key);
      }
    });

    const nameIndex = this.tabNameList.findIndex(name => name === this.getTabName(tabFound));
    this.tabNameList.splice(nameIndex, 1);

    this.invoiceSupplierTabs = [...this.invoiceSupplierTabs];
    if (this.invoiceSupplierTabs?.length > 0 && this.tabToDelete.active) {
      this.onTabClick(this.tabHandler.tabs.first);
    } else {
      this.computeTotals();
    }
  }

  submitInvoiceSupplierForm(validate: boolean): void {
    this.shouldValidate = validate;
    this.hasError = false;

    this.invoiceSupplierTabs.forEach((tab: InvoiceSupplierTab) => {
      tab.allRows.forEach((row: InvoiceSupplierLineRow) => {
        row.inError = row.rowForm.invalid;
      });
      tab.allRows = [...tab.allRows];
      tab.rows = [...tab.rows];
    });

    if (this.checkFormErrors()) {
      return;
    }

    if (this.editedInvoiceSupplier && this.editedInvoiceSupplier.equals(this.updatedInvoiceSupplier) && !validate) {
      return;
    }

    this.callApi(validate);
  }

  public callApi(validate: boolean): void {
    if (!this.updatedInvoiceSupplier) {
      return;
    }

    const action = this.updatedInvoiceSupplier && this.updatedInvoiceSupplier.id ? "update" : "create";
    this.subscriptionService.subs.push(
      this.invoiceSupplierService[action].call(this.invoiceSupplierService, this.updatedInvoiceSupplier).subscribe(
        responseInvoiceSupplier => {
          if (validate && responseInvoiceSupplier.canBeValidated()) {
            this.subscriptionService.subs.push(
              this.invoiceSupplierService.validate(responseInvoiceSupplier).subscribe(
                () => {
                  responseInvoiceSupplier.invoiceStatus = InvoiceStatus.VALIDATED;
                  this.finishSave(responseInvoiceSupplier, action);
                },
                (error: any) => {
                  this.hasError = true;
                  this.handleApiError(error);
                }
              )
            );
          } else {
            this.finishSave(responseInvoiceSupplier, action);
          }
        },
        (error: any) => {
          this.hasError = true;
          this.handleApiError(error);
        }
      )
    );
  }

  public finishSave(responseInvoiceSupplier: InvoiceSupplier, action: string): void {
    this.editedInvoiceSupplier = responseInvoiceSupplier;
    this.updatedInvoiceSupplier = new InvoiceSupplier(this.editedInvoiceSupplier);

    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
    if (this.shouldValidate) {
      this.redirectToInvoiceSupplierDetails(this.editedInvoiceSupplier.id);
    }
    if (action === "create") {
      this.sessionPagination.saveToSession(this.listId);
      this.router.navigateByUrl(`/invoice-supplier/update/${this.editedInvoiceSupplier.id}`);

      // redirect only if invoice creation is done, not just save
      if (this.updatedInvoiceSupplier.invoiceStatus === InvoiceStatus.VALIDATED) {
        this.redirectToInvoiceSupplierDetails(this.editedInvoiceSupplier.id);
      }
    }
  }

  public handleApiError(error: any): void {
    const title = this.translateService.instant("message.title.form-errors");

    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  checkFormErrors(): boolean {
    const isValidTable = this.updateInvoiceSupplier();
    let isValidHeader = true;
    if (this.invoiceHeader) {
      isValidHeader = this.invoiceHeader.updateInvoiceHeader();
    }
    return !isValidTable || !isValidHeader;
  }

  public updateInvoiceSupplier(): boolean {
    if (!this.form.invalid) {
      this.applyModifications();
      return true;
    }
    this.form.markAllAsTouched();
    this.showInvalidFormMessage();
    return false;
  }

  public applyModifications(): void {
    this.updatedInvoiceSupplier.shippingFeePrice = +this.form.get("shippingFeePrice")?.value;
    this.updatedInvoiceSupplier.forwardingPrice = +this.form.get("forwardingPrice")?.value;
    this.updatedInvoiceSupplier.extraPrice = +this.form.get("extraPrice")?.value;
    this.updatedInvoiceSupplier.extraCost = +this.form.get("extraCost")?.value;

    this.updatedInvoiceSupplier.lines.forEach((line: InvoiceSupplierLine) => {
      const lineForm = this.getLineRowForm(line);
      line.quantity = +lineForm.get("quantity").value;
      line.unitPrice = +lineForm.get("unitPrice").value;
      if (lineForm.get("sizeValue")) {
        line.sizeValue = lineForm.get("sizeValue").value;
      }
    });
  }

  getLineRowForm(line: InvoiceSupplierLine): UntypedFormGroup {
    const tabFound = line.newDeliveryFormId
      ? this.invoiceSupplierTabs.find(tab => tab.deliveryFormId === line.newDeliveryFormId)
      : this.invoiceSupplierTabs.find(tab => tab.deliveryRef === line.deliveryRef);

    return tabFound.allRows.find(row => row.lineNumber === line.lineNumber).rowForm;
  }

  public showInvalidFormMessage(): void {
    const title = this.translateService.instant("message.title.form-errors");

    const tabNames = this.invoiceSupplierTabs
      .filter((tab: InvoiceSupplierTab) => tab.tabForm.invalid)
      .map((tab: InvoiceSupplierTab) => (tab.deliveryFormId ? this.getTabName(tab) : tab.deliveryRef));

    if (tabNames.length > 1) {
      tabNames.join(", ");
    }

    const content = this.translateService.instant("invoice-supplier-form.message.form-errors", { tabNames });

    this.messageService.error(content, { title });
  }

  onCloseInvoiceSupplierInitiatorPopup(): void {
    this.invoiceSupplierInitiatorPopupVisible = false;
  }

  computeTotals(): void {
    this.extraCost = this.form.get("extraCost")?.value ?? 0;
    this.computeTabQuantity();
    this.computeTotalQuantity();
    this.computeTabGrossPrices();
    this.computeTotalGrossPrice();
    this.computeTaxPrice();
    this.computeTotalQuickPaymentDiscount();
    this.computeTotalPrice();
  }

  computeTabQuantity(): void {
    this.invoiceSupplierTabs.forEach((tab: InvoiceSupplierTab) => {
      tab.totalQuantity = 0;
      tab.rows.forEach(row => {
        const quantity = row.rowForm && row.rowForm.get("quantity") ? +row.rowForm.get("quantity").value : 0;

        tab.totalQuantity += quantity;
      });
    });
  }

  computeTotalQuantity(): void {
    this.totalQuantity = 0;
    this.invoiceSupplierTabs.forEach((tab: InvoiceSupplierTab) => {
      let tabTotalQuantity = 0;
      tab.allRows.forEach(row => {
        const quantity = row.rowForm && row.rowForm.get("quantity") ? +row.rowForm.get("quantity").value : 0;

        tabTotalQuantity = new Decimal(tabTotalQuantity ?? 0).plus(quantity ?? 0).toNumber();
      });
      this.totalQuantity = new Decimal(this.totalQuantity ?? 0).plus(tabTotalQuantity ?? 0).toNumber();
    });
  }

  computeTabGrossPrices(): void {
    this.invoiceSupplierTabs.forEach((tab: InvoiceSupplierTab) => {
      tab.totalGrossPrice = 0;

      tab.rows.forEach(row => {
        const quantity = row.rowForm && row.rowForm.get("quantity") ? +row.rowForm.get("quantity").value : 0;
        const unitPrice = row.rowForm && row.rowForm.get("unitPrice") ? +row.rowForm.get("unitPrice").value : 0;
        const grossPrice = RoundingUtil.roundLow(new Decimal(quantity ?? 0).times(unitPrice ?? 0).toNumber());
        row.totalGrossPrice = grossPrice;

        tab.totalGrossPrice = new Decimal(tab.totalGrossPrice ?? 0).plus(grossPrice ?? 0).toNumber();
      });
    });
  }

  computeTotalGrossPrice(): void {
    this.totalGrossPrice = 0;
    this.linesTaxPrice = 0;
    this.invoiceSupplierTabs.forEach((tab: InvoiceSupplierTab) => {
      let tabTotalGrossPrice = 0;

      tab.allRows.forEach(row => {
        const quantity = row.rowForm && row.rowForm.get("quantity") ? +row.rowForm.get("quantity").value : 0;
        const unitPrice = row.rowForm && row.rowForm.get("unitPrice") ? +row.rowForm.get("unitPrice").value : 0;
        const grossPrice = RoundingUtil.roundLow(new Decimal(quantity ?? 0).times(unitPrice ?? 0).toNumber());
        const vatRatePercent = new Decimal(row.vatRate ?? 0).div(this.HUNDRED).toNumber();
        this.linesTaxPrice = RoundingUtil.roundLow(
          new Decimal(this.linesTaxPrice ?? 0).plus(new Decimal(grossPrice ?? 0).times(vatRatePercent ?? 0)).toNumber()
        );
        tabTotalGrossPrice = new Decimal(tabTotalGrossPrice ?? 0).plus(grossPrice ?? 0).toNumber();
      });
      this.totalGrossPrice = new Decimal(this.totalGrossPrice ?? 0).plus(tabTotalGrossPrice ?? 0).toNumber();
    });
  }

  computeTotalQuickPaymentDiscount(): void {
    this.totalQuickPaymentDiscount = 0;
    this.shippingFeePrice = +this.form?.get("shippingFeePrice")?.value;
    this.totalQuickPaymentDiscount = RoundingUtil.roundLow(
      new Decimal(this.totalGrossPrice ?? 0)
        .plus(this.taxPrice ?? 0)
        .times(new Decimal(this.updatedInvoiceSupplier.quickPaymentDiscount ?? 0).div(this.HUNDRED))
        .toNumber()
    );
  }

  computeTaxPrice(): void {
    this.shippingFeePrice = +this.form?.get("shippingFeePrice")?.value;
    this.extraPrice = +this.form?.get("extraPrice")?.value;

    this.taxPrice = this.linesTaxPrice ?? 0;
    this.taxPrice = RoundingUtil.roundLow(
      new Decimal(this.taxPrice ?? 0)
        .plus(new Decimal(this.vatDefaultValue ?? 0).times(this.shippingFeePrice ?? 0))
        .plus(new Decimal(this.vatDefaultValue ?? 0).times(this.extraPrice ?? 0))
        .toNumber()
    );
  }

  computeTotalPrice(): void {
    this.totalPrice = 0;
    this.shippingFeePrice = +this.form?.get("shippingFeePrice")?.value;
    this.forwardingPrice = +this.form?.get("forwardingPrice")?.value;
    this.extraPrice = +this.form?.get("extraPrice")?.value;
    this.totalPrice = RoundingUtil.roundLow(
      new Decimal(this.totalGrossPrice ?? 0)
        .plus(new Decimal(this.shippingFeePrice ?? 0))
        .plus(new Decimal(this.extraPrice ?? 0))
        .plus(new Decimal(this.taxPrice ?? 0))
        .minus(new Decimal(this.totalQuickPaymentDiscount ?? 0))
        .toNumber()
    );
  }

  public onTabClick(event: any): void {
    if (!event) {
      return;
    }
    if (this.tabHandler) {
      this.tabHandler.changeTab(event);
    }
    const currentTab = this.invoiceSupplierTabs[event.id];
    currentTab.rows = [...currentTab.allRows];
    this.sorts = [];

    this.computeTotals();
    this.buildOptions(currentTab);
    this.initFilters();
    this.sessionPagination.loadFromSession(`${this.listId}-tab-${currentTab.id}`);
    this.applyFilters(currentTab);
  }

  getDL(invoiceSupplierLine: InvoiceSupplierLine): AbstractReceivingLine {
    if (!invoiceSupplierLine.newDeliveryLineId) {
      return null;
    }
    for (const df of this.deliveryFormList) {
      for (const dl of df.lines) {
        if (dl.id === invoiceSupplierLine.newDeliveryLineId) {
          return dl;
        }
      }
    }
    return null;
  }

  getPOL(deliveryLine: AbstractReceivingLine): PurchaseOrderLine {
    if (!(deliveryLine instanceof ReceivingPOLine)) {
      return null;
    }
    for (const po of this.purchaseOrderList) {
      for (const pol of po.lines) {
        if (pol.id === deliveryLine.purchaseOrderLineId) {
          return pol;
        }
      }
    }
    return null;
  }

  getPMFromDL(deliveryLine: AbstractReceivingLine): PurchaseModality {
    if (!(deliveryLine instanceof ReceivingFreeLine)) {
      return this.getPMFromId(this.getPOL(deliveryLine).purchaseModalityId);
    }
    for (const item of this.retailItemList) {
      if (item.purchaseModalities) {
        for (const pm of item.purchaseModalities) {
          if (pm.id === deliveryLine.purchaseModalityId) {
            return pm;
          }
        }
      }
    }
    return null;
  }

  getPMFromId(purchaseModalityId: number): PurchaseModality {
    for (const item of this.retailItemList) {
      if (item.purchaseModalities) {
        for (const pm of item.purchaseModalities) {
          if (pm.id === purchaseModalityId) {
            return pm;
          }
        }
      }
    }
    return null;
  }

  getItemFromPM(pmId: number): AbstractItem {
    for (const item of this.retailItemList) {
      if (item.purchaseModalities) {
        for (const pm of item.purchaseModalities) {
          if (pm.id === pmId) {
            return item;
          }
        }
      }
    }
    return null;
  }

  getItemSizeValue(pmId: number): string {
    const item = this.getItemFromPM(pmId);
    if (item instanceof StandardItem && item.sizeCategory) {
      const byDefault = item.sizeCategory.elements.find(elem => elem.byDefault);
      if (byDefault) {
        return this.getSizeValue(byDefault.sizeValueId, item.sizeCategory.sizeCategoryId);
      }
    }
    return null;
  }

  getPurchaseUnitName(pol: PurchaseOrderLine, pm: PurchaseModality): string {
    const unitId = pol ? pol.purchaseUnitId : pm.purchaseUnitId;
    return this.uomList.find(unit => unit.id === unitId).longName;
  }

  getSizeValue(valueId: number, sizeCatId: number): string {
    return this.sizeCategoryList.find(sC => sC.id === sizeCatId).elements.find(e => e.id === valueId).value;
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  fetchInvoiceSupplier(id: number): Observable<PaginatedList<ReceivingForm>> {
    return this.invoiceSupplierService.get(id).pipe(
      tap({
        error: () => {
          this.router.navigateByUrl("/invoice-supplier-list");
        },
      }),
      mergeMap((invoiceSupplier: InvoiceSupplier) => {
        // set supplier, currency and subtitle
        this.supplierId = invoiceSupplier.supplierId;
        this.isValidated = invoiceSupplier.invoiceStatus === InvoiceStatus.VALIDATED;

        // set edited and updated invoicesupplier...
        this.editedInvoiceSupplier = invoiceSupplier;
        this.updatedInvoiceSupplier = new InvoiceSupplier(this.editedInvoiceSupplier);

        // ... and deliveryFormIds
        this.updatedInvoiceSupplier.lines.forEach((line: InvoiceSupplierLine) => {
          if (line.newDeliveryFormId && !this.deliveryFormIds.includes(line.newDeliveryFormId)) {
            this.deliveryFormIds.push(line.newDeliveryFormId);
          }
        });
        // let noDeliveryForms: Observable<PaginatedList<ReceivingForm>> = new Observable<PaginatedList<ReceivingForm>>();
        // return this.deliveryFormIds.length > 0 ? this.fetchDeliveryForms() : noDeliveryForms;
        return this.fetchDeliveryForms();
      })
    );
  }

  fetchDeliveryForms(): Observable<PaginatedList<ReceivingForm>> {
    const filters = [new SearchFilter("id", SearchFilterOperator.IN, [...this.deliveryFormIds])];
    const pager = new Pagination({
      size: this.deliveryFormIds.length,
      number: 0,
    });

    return this.receivingFormService.getAll(pager, [], filters).pipe(
      tap(
        (result: PaginatedList<ReceivingForm>) => {
          this.deliveryFormList = result.data;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-form.errors.get-delivery-forms");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (suppliers: Light[]) => {
          this.supplierLightList = suppliers;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-form.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchBrands(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Light[]) => {
          this.brandLightList = brands;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-form.errors.get-brands");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchLinkedItems(idsObj: { pmIds: Set<number>; polIds: Set<number> }): Observable<PaginatedList<AbstractItem>> {
    const polIdList = idsObj.polIds;
    const pmIdList = idsObj.pmIds;

    if (polIdList.size > 0) {
      const filters = [new SearchFilter("lines.id", SearchFilterOperator.IN, [...polIdList])];
      const pager = new Pagination({
        size: polIdList.size,
        number: 0,
      });

      return this.purchaseOrderService.getAll(pager, [], filters).pipe(
        tap({
          error: () => {
            const title = this.translateService.instant("message.title.data-errors");
            const content = this.translateService.instant("invoice-supplier-form.errors.get-purchase-orders");
            this.messageService.warn(content, { title });
          },
        }),
        mergeMap((result: PaginatedList<PurchaseOrder>) => {
          this.purchaseOrderList = result.data;
          this.purchaseOrderList.forEach((purchaseOrder: PurchaseOrder) => {
            purchaseOrder.lines.forEach((pol: PurchaseOrderLine) => {
              pmIdList.add(pol.purchaseModalityId);
            });
          });
          return this.fetchItemsFromPMs(pmIdList);
        })
      );
    } else {
      return this.fetchItemsFromPMs(pmIdList);
    }
  }

  fetchItemsFromPMs(pmIdList: Set<number>): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: pmIdList.size,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, [...pmIdList]);

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          result.data.forEach(item => {
            if (!this.retailItemList.includes(item)) {
              this.retailItemList.push(item);
            }
          });
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-form.errors.get-retail-items");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencyList = currencies;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-form.errors.get-currencies");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchSizeCategories(): Observable<SizeCategory[]> {
    return this.sizeCategoryService.getAll().pipe(
      tap(
        (sizeCategories: SizeCategory[]) => {
          this.sizeCategoryList = sizeCategories;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-form.errors.get-size-categories");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  addMenuActions(): void {
    this.menuActions = [];
    this.menuActions.push(
      new MenuAction(
        this.DELETE_ACTION_ID,
        this.translateService.instant("invoice-supplier-form.actions.delete"),
        this.faTrash
      )
    );
  }

  manageActions(actionId: number, row: InvoiceSupplierLineRow, tab: InvoiceSupplierTab): void {
    switch (actionId) {
      case this.DELETE_ACTION_ID:
        this.removeLine(row, tab);
        break;
      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  removeLine(selectedRow: InvoiceSupplierLineRow, activeTab: InvoiceSupplierTab): void {
    activeTab.allRows.splice(activeTab.allRows.indexOf(selectedRow), 1);

    const idx = this.updatedInvoiceSupplier.lines.findIndex(
      (line: InvoiceSupplierLine) =>
        line.newDeliveryFormId === activeTab.deliveryFormId &&
        line.deliveryRef === activeTab.deliveryRef &&
        line.lineNumber === selectedRow.lineNumber
    );

    this.updatedInvoiceSupplier.lines.splice(idx, 1);

    if (!this.route.snapshot.params.id) {
      activeTab.allRows.forEach(row => {
        if (row.lineNumber > selectedRow.lineNumber) {
          row.lineNumber -= 1;
        }
      });
      this.updatedInvoiceSupplier.lines.forEach(line => {
        if (
          line.lineNumber > selectedRow.lineNumber &&
          line.newDeliveryFormId === activeTab.deliveryFormId &&
          line.deliveryRef === activeTab.deliveryRef
        ) {
          line.lineNumber -= 1;
        }
      });
    }

    activeTab.rows.splice(activeTab.rows.indexOf(selectedRow), 1);

    activeTab.rows = [...activeTab.rows];

    activeTab.tabForm.removeControl(selectedRow.lineNumber.toString());
    if (!this.route.snapshot.params.id) {
      Object.keys(activeTab.tabForm.controls).forEach(key => {
        if (+key > selectedRow.lineNumber) {
          const form = activeTab.tabForm.get(key);
          activeTab.tabForm.setControl((+key - 1).toString(), form);
          activeTab.tabForm.removeControl(key);
        }
      });
    }
    this.invoiceSupplierTabs = [...this.invoiceSupplierTabs];

    this.computeTotals();
  }

  isDirty(): boolean {
    if (!this.updatedInvoiceSupplier) {
      return false;
    }
    if (this.invoiceHeader && !this.readOnly) {
      this.invoiceHeader.applyModifications();
    }
    this.applyModifications();

    if (this.unsavedInvoiceSupplier && !this.unsavedInvoiceSupplier.equals(this.updatedInvoiceSupplier)) {
      this.shouldClose = false;
    }
    if (
      (this.editedInvoiceSupplier &&
        !this.editedInvoiceSupplier.equals(this.updatedInvoiceSupplier) &&
        !this.shouldClose) ||
      (!this.editedInvoiceSupplier && !this.shouldClose)
    ) {
      this.saveInvoiceSupplierState();
      return true;
    }

    this.unsavedInvoiceSupplier = null;
    this.shouldClose = false;
    return false;
  }

  saveInvoiceSupplierState(): void {
    this.unsavedInvoiceSupplier = new InvoiceSupplier(this.updatedInvoiceSupplier);
    this.shouldClose = true;
  }

  redirectToInvoiceSupplierDetails(id: number): void {
    this.sessionPagination.saveToSession(this.listId);

    this.router.navigateByUrl(`/invoice-supplier-detail/${id}`);
  }

  getBackButtonTranslation(): string {
    if (this.backUrl && this.backUrl.startsWith("/invoice-supplier-list")) {
      return "invoice-supplier-form.buttons.back-to-list";
    }
    return "invoice-supplier-form.buttons.back-to-details";
  }

  backToPrevious(): void {
    if (this.backUrl === "/invoice-supplier-list") {
      SessionPagination.clear(this.LIST_ID);
      SessionPagination.clear(this.READ_LIST_ID);
      SessionPagination.clear(InvoiceSupplierDeliverySelectionComponent.LIST_ID);
      SessionPagination.clear(InvoiceSupplierPmSelectionComponent.LIST_ID);

      // delete filter values in detail page and update page
      this.invoiceSupplierTabs.forEach((tab: InvoiceSupplierTab) => {
        SessionPagination.clear(`${this.LIST_ID}-tab-${tab.id}`);
        SessionPagination.clear(`${this.READ_LIST_ID}-tab-${tab.id}`);
      });
    }
    this.router.navigateByUrl(this.backUrl);
  }

  multipleUnit(tab: InvoiceSupplierTab): boolean {
    const unique = [...new Set(tab.rows.map(row => row.quantityUnit))];
    return unique.length > 1;
  }

  totalMultipleUnit(): boolean {
    const unique = new Set();
    this.invoiceSupplierTabs.forEach((tab: InvoiceSupplierTab) => {
      const uniqueTab = [...new Set(tab.rows.map(row => row.quantityUnit))];
      uniqueTab.forEach(elem => unique.add(elem));
    });
    return unique.size > 1;
  }

  getTabName(tab: InvoiceSupplierTab): string {
    if (!tab) {
      return null;
    }
    return tab.deliveryRef
      ? tab.deliveryRef
      : this.deliveryFormList?.find(df => df.id === tab.deliveryFormId).deliveryRef;
  }

  getRowClass(row: any): any {
    return { inError: row.inError, "not-clickable": true };
  }

  getTableClass(): string {
    return this.readOnly ? "invoice-supplier-line-list" : "invoice-supplier-line-list frozen-right";
  }

  initFilters(): void {
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.listId
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "lineNumber",
      this.translateService.instant("invoice-supplier-form.datatable.columns.line-number"),
      "string"
    );

    this.filterer.addFilter(
      "itemSupplierRef",
      this.translateService.instant("invoice-supplier-form.datatable.columns.item-supplier-ref"),
      "string"
    );

    this.filterer.addFilter(
      "itemRef",
      this.translateService.instant("invoice-supplier-form.datatable.columns.item-reference"),
      "string"
    );

    this.filterer.addFilter(
      "itemName",
      this.translateService.instant("invoice-supplier-form.datatable.columns.item-name"),
      "string"
    );

    this.filterer.addListFilter(
      "brandName",
      this.translateService.instant("invoice-supplier-form.datatable.columns.brand-name"),
      this.brandList.map(brand => {
        return { value: brand, displayValue: brand };
      })
    );
    this.filterer.addFilter(
      "vatRate",
      this.translateService.instant("invoice-supplier-form.datatable.columns.vat-rate"),
      "range"
    );

    if (this.readOnly) {
      this.filterer.addFilter(
        "quantity",
        this.translateService.instant("invoice-supplier-form.datatable.columns.quantity"),
        "range"
      );

      this.filterer.addFilter(
        "unitPrice",
        this.translateService.instant("invoice-supplier-form.datatable.columns.unit-price"),
        "range"
      );

      this.filterer.addListFilter(
        "sizeValue",
        this.translateService.instant("invoice-supplier-form.datatable.columns.size-value"),
        this.sizeValueList.map(sizeValue => {
          return { value: sizeValue, displayValue: sizeValue };
        })
      );
    }

    this.filterer.addFilter(
      "totalGrossPrice",
      this.translateService.instant("invoice-supplier-form.datatable.columns.total-gross-price"),
      "range"
    );
  }

  public applyFilters(tab: InvoiceSupplierTab): void {
    tab.rows = this.filterer.filterList(tab.allRows);

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.listId
      ).subscribe(() => this.sessionPagination.saveToSession(`${this.listId}-tab-${tab.id}`))
    );
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }

  private fetchUoms(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uomList: Uom[]) => {
          this.uomList = uomList;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-form.errors.get-uoms");
          this.messageService.warn(content, { title });
        }
      )
    );
  }
}
