import { MultiShipmentFormComponent } from "./shipment-form/multi-shipment-form/multi-shipment-form.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ItemCategoriesListComponent } from "./settings/item-categories-list/item-categories-list.component";
import { StoreBrandComponent } from "./settings/store-brands-list/store-brands.list.component";
import { StoreFormComponent } from "./store/store-form/store-form.component";
import { SizeCategoriesListComponent } from "./settings/size-categories-list/size-categories-list.component";
import { ClassificationsListComponent } from "./settings/classifications-list/classifications-list.component";
import { PricingGroupsListComponent } from "./settings/pricing-groups-list/pricing-groups-list.component";

import {
  AlloysListComponent,
  CompanyConfigFormComponent,
  CountriesListComponent,
  CurrenciesListComponent,
  JobsListComponent,
  LogoutComponent,
  MetalsListComponent,
  NetworkConfigComponent,
  ProfileFormComponent,
  ProfilesListComponent,
  StockLocationListComponent,
  TagsListComponent,
  UomsListComponent,
} from "generic-pages";

import { StoreListComponent } from "./store/store-list/store-list.component";
import { PromotionsListComponent } from "./settings/promotion-list/promotions-list.component";
import { UserFormComponent } from "./settings/user-form/user-form.component";
import { CaraUsersListComponent } from "./settings/cara-users-list/cara-users-list.component";
import { SupplierListComponent } from "./supplier/supplier-list/supplier-list.component";
import { SupplierFormComponent } from "./supplier/supplier-form/supplier-form.component";
import { BrandFormComponent } from "./brand/brand-form/brand-form.component";
import { BrandListComponent } from "./brand/brand-list/brand-list.component";
import { ItemUniversesListComponent } from "./settings/item-universes-list/item-universes-list.component";
import { RetailItemFormComponent } from "./retail-item/retail-item-form/retail-item-form.component";
import { RetailItemListComponent } from "./retail-item/retail-item-list/retail-item-list.component";
import { ItemGroupsListComponent } from "./settings/item-groups/item-groups-list/item-groups-list.component";
import { ItemGroupFormComponent } from "./settings/item-groups/item-groups-form/item-groups-form.component";
import { MetalAccountDetailComponent } from "./metal-account/metal-account-detail/metal-account-detail.component";
import { PoliceBookMovementsListComponent } from "./police-book-movements-list/police-book-movements-list.component";
import { PurchaseOrderFormComponent } from "./purchase-order/purchase-order-form/purchase-order-form.component";
import { StockListComponent } from "./stock/stock-list/stock-list.component";
import { PurchaseOrderListComponent } from "./purchase-order/purchase-order-list/purchase-order-list.component";
import { PurchaseOrderDetailComponent } from "./purchase-order/purchase-order-detail/purchase-order-detail.component";
import { InventoryListComponent } from "./inventory/inventory-list/inventory-list.component";
import { ManualStockEntryFormComponent } from "./manual-stock-entry/manual-stock-entry-form/manual-stock-entry-form.component";
import { InventoryFormComponent } from "./inventory/inventory-form/inventory-form.component";
import { StockEntryLabelListComponent } from "./stock-entry-label/stock-entry-label-list/stock-entry-label-list.component";
import { PageNotFoundComponent } from "./common/page-not-found/page-not-found.component";
import { HomePageComponent } from "./common/home-page/home-page.component";
import { TechnicalErrorPageComponent } from "./common/technical-error-page/technical-error-page.component";
import { InvoiceSupplierFormComponent } from "./invoice/invoice-supplier/invoice-supplier-form/invoice-supplier-form.component";
import { InvoiceSupplierListComponent } from "./invoice/invoice-supplier/invoice-supplier-list/invoice-supplier-list.component";
import { AppConfigComponent } from "./settings/app-config/app-config.component";
import { ProfileManagementComponent } from "./menu-footer/profile-management/profile-management.component";
import { ImportFileComponent } from "./common/import-file/import-file.component";
import { NewReceiptListComponent } from "./new-receipt/new-receipt-list/new-receipt-list.component";
import { ReceivingFormComponent } from "./new-receipt/receiving-form/receiving-form.component";
import { ShipmentFormListComponent } from "./shipment-form/shipment-form-list/shipment-form-list/shipment-form-list.component";
import { ShipmentFormComponent } from "./shipment-form/shipment-form/shipment-form.component";
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { CustomerFormComponent } from "./customer/customer-form/customer-form.component";
import { AuthGuard } from "./util/auth.guard";
import { MainStoreGuard } from "./util/main-store.guard";
import { UnsavedChangesGuard } from "./util/unsaved-changes.guard";
import { CustomerListComponent } from "./customer/customer-list/customer-list.component";
import { CustomizationComponent } from "./customization/customization.component";
import { InvoiceCustomerListComponent } from "./invoice/invoice-customer/invoice-customer-list/invoice-customer-list.component";
import { StoreService, SupplierService, BrandService, RetailItemService, CaraUserService } from "center-services";
import { InvoiceCustomerFormComponent } from "./invoice/invoice-customer/invoice-customer-form/invoice-customer-form.component";
import { HistorizationListComponent } from "./historization/historization-list/historization-list.component";
import { ReceivingDetailsComponent } from "./new-receipt/receiving-details/receiving-details.component";
import { CreditNoteCustomerFormComponent } from "./credit-note/credit-note-customer-form/credit-note-customer-form.component";
import { CreditNoteCustomerDetailsComponent } from "./credit-note/credit-note-customer-details/credit-note-customer-details.component";
import { MetalAccountGeneralComponent } from "./metal-account/metal-account-general/metal-account-general.component";

const routes: Routes = [
  {
    path: "",
    component: HomePageComponent,
    canActivate: [AuthGuard],
    data: { pageTitleKey: "home-page.title" },
  },
  {
    path: "login",
    component: LoginFormComponent,
    data: { title: "CaraFugu", pageTitleKey: "login-form.title" },
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: "stores-list",
    component: StoreListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["STORE_READ"],
      pageTitleKey: "stores-list.title",
      menuEntryKey: "sidebar.menu.stores-list",
    },
  },
  {
    path: "stores-list/import",
    component: ImportFileComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["STORE_CREATE"],
      target: "store",
      service: StoreService,
      backUrl: "/stores-list",
      pageTitleKey: "import-page.title.store",
      menuEntryKey: "sidebar.menu.stores-list",
    },
  },
  {
    path: "store/add",
    component: StoreFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["STORE_CREATE"],
      pageTitleKey: "store.title.new",
      menuEntryKey: "sidebar.menu.stores-list",
    },
  },
  {
    path: "store/update/:id",
    component: StoreFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["STORE_UPDATE"],
      pageTitleKey: "store.title.update",
      menuEntryKey: "sidebar.menu.stores-list",
    },
  },
  {
    path: "store/update/:id/:tab-id",
    component: StoreFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["STORE_UPDATE"],
      pageTitleKey: "store.title.update",
      menuEntryKey: "sidebar.menu.stores-list",
    },
  },
  {
    path: "suppliers-list",
    component: SupplierListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["SUPPLIER_READ"],
      pageTitleKey: "suppliers-list.title",
      menuEntryKey: "sidebar.menu.suppliers-list",
    },
  },
  {
    path: "suppliers-list/import",
    component: ImportFileComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["SUPPLIER_CREATE"],
      target: "supplier",
      service: SupplierService,
      backUrl: "/suppliers-list",
      pageTitleKey: "import-page.title.supplier",
      menuEntryKey: "sidebar.menu.suppliers-list",
    },
  },
  {
    path: "supplier/add",
    component: SupplierFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["SUPPLIER_CREATE"],
      pageTitleKey: "supplier.title.new",
      menuEntryKey: "sidebar.menu.suppliers-list",
    },
  },
  {
    path: "supplier/update/:id",
    component: SupplierFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["SUPPLIER_UPDATE"],
      pageTitleKey: "supplier.title.update",
      menuEntryKey: "sidebar.menu.suppliers-list",
    },
  },
  {
    path: "supplier/update/:id/:tab-id",
    component: SupplierFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["SUPPLIER_UPDATE"],
      pageTitleKey: "supplier.title.update",
      menuEntryKey: "sidebar.menu.suppliers-list",
    },
  },
  {
    path: "customer-list",
    component: CustomerListComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["CUSTOMER_READ"],
      pageTitleKey: "customers-list.title",
      menuEntryKey: "sidebar.menu.customers-list",
    },
  },
  {
    path: "customer/add",
    component: CustomerFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["CUSTOMER_CREATE"],
      pageTitleKey: "customer.title.new",
      menuEntryKey: "sidebar.menu.customers-list",
    },
  },
  {
    path: "customer/update/:id",
    component: CustomerFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["CUSTOMER_UPDATE"],
      pageTitleKey: "customer.title.update",
      menuEntryKey: "sidebar.menu.customers-list",
    },
  },
  {
    path: "customer/update/:id/:tab-id",
    component: CustomerFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["CUSTOMER_UPDATE"],
      pageTitleKey: "customer.title.update",
      menuEntryKey: "sidebar.menu.customers-list",
    },
  },
  {
    path: "brands-list",
    component: BrandListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["BRAND_READ"],
      pageTitleKey: "brands-list.title",
      menuEntryKey: "sidebar.menu.brands-list",
    },
  },
  {
    path: "brands-list/import",
    component: ImportFileComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["BRAND_CREATE"],
      target: "brand",
      service: BrandService,
      backUrl: "/brands-list",
      pageTitleKey: "import-page.title.brand",
      menuEntryKey: "sidebar.menu.brands-list",
    },
  },
  {
    path: "brand/add",
    component: BrandFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["BRAND_CREATE"],
      pageTitleKey: "brand.title.new",
      menuEntryKey: "sidebar.menu.brands-list",
    },
  },
  {
    path: "brand/update/:id",
    component: BrandFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["BRAND_UPDATE"],
      pageTitleKey: "brand.title.update",
      menuEntryKey: "sidebar.menu.brands-list",
    },
  },
  {
    path: "brand/update/:id/:tab-id",
    component: BrandFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["BRAND_UPDATE"],
      pageTitleKey: "brand.title.update",
      menuEntryKey: "sidebar.menu.brands-list",
    },
  },
  {
    path: "retail-item/add",
    component: RetailItemFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["RETAIL_ITEM_CREATE"],
      type: "standard",
      pageTitleKey: "item.standard.new",
      menuEntryKey: "sidebar.menu.retail-item-list",
    },
  },
  {
    path: "retail-service/add",
    component: RetailItemFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["RETAIL_ITEM_CREATE"],
      type: "service",
      pageTitleKey: "item.service.new",
      menuEntryKey: "sidebar.menu.retail-item-list",
    },
  },
  {
    path: "retail-item/update/:id",
    component: RetailItemFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["RETAIL_ITEM_UPDATE"],
      pageTitleKey: "item.standard.update",
      menuEntryKey: "sidebar.menu.retail-item-list",
    },
  },
  {
    path: "retail-item/update/:id/:tab-id",
    component: RetailItemFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["RETAIL_ITEM_UPDATE"],
      pageTitleKey: "item.standard.update",
      menuEntryKey: "sidebar.menu.retail-item-list",
    },
  },
  {
    path: "retail-item-list",
    component: RetailItemListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["RETAIL_ITEM_READ"],
      pageTitleKey: "retail-item-list.title",
      menuEntryKey: "sidebar.menu.retail-item-list",
    },
  },
  {
    path: "retail-item/import",
    component: ImportFileComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["RETAIL_ITEM_CREATE"],
      target: "item",
      service: RetailItemService,
      backUrl: "/retail-item-list",
      pageTitleKey: "import-page.title.item",
      menuEntryKey: "sidebar.menu.retail-item-list",
    },
  },
  {
    path: "metal-account-general",
    component: MetalAccountGeneralComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["METAL_ACCOUNT_READ"],
      pageTitleKey: "metal-account-general.title",
      menuEntryKey: "sidebar.menu.metal-account-general",
    },
  },
  {
    path: "metal-account-general/:tab-id",
    component: MetalAccountGeneralComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["METAL_ACCOUNT_READ"],
      pageTitleKey: "metal-account-general.title",
      menuEntryKey: "sidebar.menu.metal-account-general",
    },
  },
  {
    path: "metal-account-detail",
    component: MetalAccountDetailComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["METAL_ACCOUNT_READ", "METAL_ACCOUNT_MOVEMENT_READ"],
      pageTitleKey: "metal-account-detail.title",
      menuEntryKey: "sidebar.menu.metal-account-general",
    },
  },
  {
    path: "police-book-movements-list",
    component: PoliceBookMovementsListComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["POLICE_BOOK_MOVEMENT_READ"],
      pageTitleKey: "police-book-movements-list.title",
      menuEntryKey: "sidebar.menu.police-book-movement-list",
    },
  },
  {
    path: "purchase-order-list",
    component: PurchaseOrderListComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["PURCHASE_ORDER_READ"],
      pageTitleKey: "purchase-order-list.title",
      menuEntryKey: "sidebar.menu.purchase-orders-list",
    },
  },
  {
    path: "purchase-order-detail/:id",
    component: PurchaseOrderDetailComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["PURCHASE_ORDER_READ"],
      pageTitleKey: "purchase-order.title.order",
      menuEntryKey: "sidebar.menu.purchase-orders-list",
    },
  },
  {
    path: "purchase-order/add",
    component: PurchaseOrderFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["PURCHASE_ORDER_CREATE"],
      pageTitleKey: "purchase-order.title.new",
      menuEntryKey: "sidebar.menu.purchase-orders-list",
    },
  },
  {
    path: "purchase-order/update/:id",
    component: PurchaseOrderFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["PURCHASE_ORDER_UPDATE"],
      pageTitleKey: "purchase-order.title.update",
      menuEntryKey: "sidebar.menu.purchase-orders-list",
    },
  },
  {
    path: "inventory/update/:id",
    component: InventoryFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["INVENTORY_UPDATE"],
      pageTitleKey: "inventory-form.title",
      menuEntryKey: "sidebar.menu.inventory-list",
    },
  },
  {
    path: "stocks-list",
    component: StockListComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["STOCK_ENTRY_READ"],
      pageTitleKey: "stocks-list.title",
      menuEntryKey: "sidebar.menu.stocks-list",
    },
  },
  {
    path: "manual-stock-entry/add",
    component: ManualStockEntryFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["STOCK_ENTRY_CREATE"],
      pageTitleKey: "manual-stock-entry.title",
      menuEntryKey: "sidebar.menu.stocks-list",
    },
  },
  {
    path: "inventory-list",
    component: InventoryListComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["INVENTORY_READ"],
      pageTitleKey: "inventory-list.title",
      menuEntryKey: "sidebar.menu.inventory-list",
    },
  },
  {
    path: "receiving-list",
    component: NewReceiptListComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["RECEIVING_FORM_GLOBAL"],
      pageTitleKey: "receipt-list.title",
      menuEntryKey: "sidebar.menu.receiving-list",
    },
  },
  {
    path: "receiving-form/add",
    component: ReceivingFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: {
        requireAllPermissions: false,
        permissions: ["RECEIVING_FORM_DIRECT_CREATE_UPDATE", "RECEIVING_FORM_REGISTERING_PREPARE"],
      },
    },
  },
  {
    path: "receiving-form/update/:id",
    component: ReceivingFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: [
        "RECEIVING_FORM_DIRECT_CREATE_UPDATE",
        "RECEIVING_FORM_REGISTERING_CREATE_UPDATE",
        "RECEIVING_FORM_REGISTERING_PREPARE",
      ],
    },
  },
  {
    path: "receiving-form-detail/:id",
    component: ReceivingDetailsComponent,
    canActivate: [AuthGuard],
    data: { authorizations: ["RECEIVING_FORM_GLOBAL"], readOnly: true },
  },
  {
    path: "shipment-form-list",
    component: ShipmentFormListComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["SHIPMENT_FORM_READ"],
      pageTitleKey: "shipment-form-list.title",
      menuEntryKey: "sidebar.menu.shipment-form-list",
    },
  },
  {
    path: "shipment-form/add",
    component: ShipmentFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["SHIPMENT_FORM_CREATE"],
      pageTitleKey: "shipment-form.title",
      menuEntryKey: "sidebar.menu.shipment-form-list",
    },
  },
  {
    path: "shipment-form/update/:id",
    component: ShipmentFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["SHIPMENT_FORM_UPDATE"],
      pageTitleKey: "shipment-form.title",
      menuEntryKey: "sidebar.menu.shipment-form-list",
    },
  },
  {
    path: "shipment-form-detail/:id",
    component: ShipmentFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["SHIPMENT_FORM_READ"],
      readOnly: true,
      pageTitleKey: "shipment-form.title",
      menuEntryKey: "sidebar.menu.shipment-form-list",
    },
  },
  {
    path: "shipment-form/prepare-shipment",
    component: MultiShipmentFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["SHIPMENT_FORM_UPDATE"],
      pageTitleKey: "multi-shipment-form.title",
      menuEntryKey: "sidebar.menu.shipment-form-list",
    },
  },
  {
    path: "stock-entry-label-list",
    component: StockEntryLabelListComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["STOCK_ENTRY_LABEL_READ"],
      pageTitleKey: "stock-entry-label-list.title",
      menuEntryKey: "sidebar.menu.stock-entry-label-list",
    },
  },
  {
    path: "invoice-customer/add",
    component: InvoiceCustomerFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["INVOICE_CUSTOMER_CREATE"],
      pageTitleKey: "invoice-customer-form.title",
      menuEntryKey: "sidebar.menu.invoice-customer-list",
    },
  },
  {
    path: "invoice-customer/update/:id",
    component: InvoiceCustomerFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["INVOICE_CUSTOMER_UPDATE"],
      pageTitleKey: "invoice-customer-form.title",
      menuEntryKey: "sidebar.menu.invoice-customer-list",
    },
  },
  {
    path: "invoice-customer-detail/:id",
    component: InvoiceCustomerFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["INVOICE_CUSTOMER_READ"],
      readOnly: true,
      pageTitleKey: "invoice-customer-form.detail.invoice",
      menuEntryKey: "sidebar.menu.invoice-customer-list",
    },
  },
  {
    path: "invoice-supplier/add",
    component: InvoiceSupplierFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["INVOICE_SUPPLIER_CREATE"],
      pageTitleKey: "invoice-supplier-initiator-popup.title.new",
      menuEntryKey: "sidebar.menu.invoice-supplier-list",
    },
  },
  {
    path: "invoice-supplier/update/:id",
    component: InvoiceSupplierFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["INVOICE_SUPPLIER_UPDATE"],
      pageTitleKey: "invoice-supplier-form.title",
      menuEntryKey: "sidebar.menu.invoice-supplier-list",
    },
  },
  {
    path: "invoice-supplier-detail/:id",
    component: InvoiceSupplierFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["INVOICE_SUPPLIER_READ"],
      readOnly: true,
      pageTitleKey: "invoice-supplier-form.detail.invoice",
      menuEntryKey: "sidebar.menu.invoice-supplier-list",
    },
  },
  {
    path: "invoice-supplier-list",
    component: InvoiceSupplierListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["INVOICE_SUPPLIER_READ"],
      pageTitleKey: "invoice-supplier-list.title",
      menuEntryKey: "sidebar.menu.invoice-supplier-list",
    },
  },
  {
    path: "invoice-customer-list",
    component: InvoiceCustomerListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["INVOICE_CUSTOMER_READ"],
      pageTitleKey: "invoice-customer-list.title",
      menuEntryKey: "sidebar.menu.invoice-customer-list",
    },
  },
  {
    path: "settings/alloys-list",
    component: AlloysListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["ALLOY_READ"],
      pageTitleKey: "alloys-list.title",
      menuEntryKey: "sidebar.footer.alloys-list",
    },
  },
  {
    path: "settings/metals-list",
    component: MetalsListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["METAL_READ"],
      pageTitleKey: "metals-list.title",
      menuEntryKey: "sidebar.footer.metals-list",
    },
  },
  {
    path: "settings/jobs-list-employee",
    component: JobsListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["JOB_READ"],
      type: "EMPLOYEE",
      pageTitleKey: "jobs-list.title",
      menuEntryKey: "sidebar.footer.jobs-list-employee",
    },
  },
  {
    path: "settings/jobs-list-external-contact",
    component: JobsListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["JOB_READ"],
      type: "EXTERNAL_CONTACT",
      pageTitleKey: "jobs-list.title",
      menuEntryKey: "sidebar.footer.jobs-list-external-contact",
    },
  },
  {
    path: "settings/network",
    component: NetworkConfigComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["GOD"],
      pageTitleKey: "network-config.title",
      menuEntryKey: "sidebar.footer.network-config",
    },
  },
  {
    path: "settings/profile/add",
    component: ProfileFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["PROFILE_CREATE"],
      pageTitleKey: "profile-form.title.new",
      menuEntryKey: "sidebar.footer.profiles-list",
    },
  },
  {
    path: "settings/profile/update/:id",
    component: ProfileFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["PROFILE_UPDATE"],
      pageTitleKey: "profile-form.title.update",
      menuEntryKey: "sidebar.footer.profiles-list",
    },
  },
  {
    path: "settings/profiles-list",
    component: ProfilesListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["PROFILE_READ"],
      pageTitleKey: "profiles-list.title",
      menuEntryKey: "sidebar.footer.profiles-list",
    },
  },
  {
    path: "settings/countries-list",
    component: CountriesListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["GOD"],
      pageTitleKey: "countries-list.title",
      menuEntryKey: "sidebar.footer.countries-list",
    },
  },
  {
    path: "settings/company-config",
    component: CompanyConfigFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["COMPANY_CONFIG_READ"],
      pageTitleKey: "sidebar.footer.company-config",
      menuEntryKey: "sidebar.footer.company-config",
    },
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: "settings/company-config/:tab-id",
    component: CompanyConfigFormComponent,
    canActivate: [AuthGuard],
    data: {
      authorizations: ["COMPANY_CONFIG_READ"],
      pageTitleKey: "sidebar.footer.company-config",
      menuEntryKey: "sidebar.footer.company-config",
    },
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: "settings/company-config/update",
    component: CompanyConfigFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["COMPANY_CONFIG_UPDATE"],
      pageTitleKey: "sidebar.footer.company-config",
      menuEntryKey: "sidebar.footer.company-config",
    },
  },
  {
    path: "settings/company-config/update/:tab-id",
    component: CompanyConfigFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["COMPANY_CONFIG_UPDATE"],
      pageTitleKey: "sidebar.footer.company-config",
      menuEntryKey: "sidebar.footer.company-config",
    },
  },
  {
    path: "settings/uoms-list",
    component: UomsListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: { authorizations: ["UOM_READ"], pageTitleKey: "uoms-list.title", menuEntryKey: "sidebar.footer.uoms-list" },
  },
  {
    path: "settings/store-brand",
    component: StoreBrandComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["STORE_BRAND_READ"],
      pageTitleKey: "store-brands-list.title",
      menuEntryKey: "sidebar.footer.store-brand",
    },
  },
  {
    path: "settings/tags-list",
    component: TagsListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: { authorizations: ["TAG_READ"], pageTitleKey: "tags-list.title", menuEntryKey: "sidebar.footer.tags-list" },
  },
  {
    path: "settings/user/add",
    component: UserFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["USER_CREATE"],
      pageTitleKey: "user-form.title.new",
      menuEntryKey: "sidebar.footer.users-list",
    },
  },
  {
    path: "settings/user/update/:id",
    component: UserFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["USER_UPDATE"],
      pageTitleKey: "user-form.title.update",
      menuEntryKey: "sidebar.footer.users-list",
    },
  },
  {
    path: "settings/users-list",
    component: CaraUsersListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["USER_READ"],
      pageTitleKey: "users-list.title",
      menuEntryKey: "sidebar.footer.users-list",
    },
  },
  {
    path: "users-list/import",
    component: ImportFileComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["USER_CREATE"],
      target: "user",
      service: CaraUserService,
      backUrl: "/settings/users-list",
      pageTitleKey: "import-page.title.user",
      menuEntryKey: "sidebar.footer.users-list",
    },
  },
  {
    path: "settings/currencies-list",
    component: CurrenciesListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["GOD"],
      pageTitleKey: "currencies-list.title",
      menuEntryKey: "sidebar.footer.currencies-list",
    },
  },
  {
    path: "settings/item-categories-list",
    component: ItemCategoriesListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["ITEM_CATEGORY_READ"],
      pageTitleKey: "item-categories-list.title",
      menuEntryKey: "sidebar.footer.item-categories-list",
    },
  },
  {
    path: "settings/item-universes-list",
    component: ItemUniversesListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["ITEM_UNIVERSE_READ"],
      pageTitleKey: "item-universes-list.title",
      menuEntryKey: "sidebar.footer.item-universes-list",
    },
  },
  {
    path: "settings/size-categories-list",
    component: SizeCategoriesListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["SIZE_CATEGORY_READ"],
      pageTitleKey: "size-categories-list.title",
      menuEntryKey: "sidebar.footer.size-categories-list",
    },
  },
  {
    path: "settings/classifications-list",
    component: ClassificationsListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["CLASSIFICATION_READ"],
      pageTitleKey: "classifications-list.title",
      menuEntryKey: "sidebar.footer.classifications-list",
    },
  },
  {
    path: "settings/pricing-groups-list",
    component: PricingGroupsListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["PRICING_GROUP_READ"],
      pageTitleKey: "pricing-groups-list.title",
      menuEntryKey: "sidebar.footer.pricing-groups-list",
    },
  },
  {
    path: "settings/promotions-list",
    component: PromotionsListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["PROMOTION_PERIOD_READ"],
      pageTitleKey: "promotions-list.title",
      menuEntryKey: "sidebar.footer.promotions-list",
    },
  },
  {
    path: "settings/stock-location-list",
    component: StockLocationListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["STOCK_LOCATION_READ"],
      pageTitleKey: "stock-locations-list.title",
      menuEntryKey: "sidebar.footer.stock-location-list",
    },
  },
  {
    path: "settings/item-groups-list",
    component: ItemGroupsListComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["ITEM_GROUP_READ"],
      pageTitleKey: "item-groups-list.title",
      menuEntryKey: "sidebar.footer.item-groups-list",
    },
  },
  {
    path: "settings/item-group/add",
    component: ItemGroupFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["ITEM_GROUP_CREATE"],
      pageTitleKey: "item-group-form.title.new",
      menuEntryKey: "sidebar.footer.item-groups-list",
    },
  },
  {
    path: "settings/item-group/update/:id",
    component: ItemGroupFormComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["ITEM_GROUP_UPDATE"],
      pageTitleKey: "item-group-form.title.update",
      menuEntryKey: "sidebar.footer.item-groups-list",
    },
  },
  {
    path: "settings/app-config",
    component: AppConfigComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: { authorizations: ["SALE_DOC_CONFORMITY"], pageTitleKey: "app-config.title" },
  },
  {
    path: "settings/customization",
    component: CustomizationComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: { authorizations: ["CUSTOMIZATION_READ"], pageTitleKey: "customization.title" },
  },
  {
    path: "profile-management",
    component: ProfileManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: { pageTitleKey: "profile-management.title" },
  },
  {
    path: "history-list",
    canActivate: [AuthGuard],
    component: HistorizationListComponent,
    data: {
      authorizations: ["BIGBROWSER"],
      pageTitleKey: "history-list.title",
      menuEntryKey: "sidebar.footer.history-list",
    },
  },
  {
    path: "credit-note-customer/create",
    component: CreditNoteCustomerFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["CREDIT_NOTE_CUSTOMER_CREATE"],
      pageTitleKey: "credit-note.credit-note-customer-form.page-title-create",
    },
  },
  {
    path: "credit-note-customer/update/:id",
    component: CreditNoteCustomerFormComponent,
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      authorizations: ["CREDIT_NOTE_CUSTOMER_UPDATE"],
      pageTitleKey: "credit-note.credit-note-customer-form.page-title-update",
    },
  },
  {
    path: "credit-note-customer-detail/:id",
    component: CreditNoteCustomerDetailsComponent,
    canActivate: [AuthGuard, MainStoreGuard],
    data: {
      authorizations: ["INVOICE_CUSTOMER_READ"],
      readOnly: true,
      pageTitleKey: "credit-note.credit-note-customer-details.page-title",
      menuEntryKey: "sidebar.menu.invoice-customer-list",
    },
  },
  {
    path: "technical-error",
    component: TechnicalErrorPageComponent,
    canActivate: [AuthGuard],
    data: { pageTitleKey: "technical-error-page.title" },
  },
  {
    path: "**",
    canActivate: [AuthGuard],
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
