/* eslint-disable no-magic-numbers */
/* tslint:disable:no-string-literal */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  RetailItemService,
  CaraUserService,
  BrandService,
  LightService,
  ItemStatusType,
  AbstractItem,
  Light,
  BrandCollection,
  ItemCategory,
  Pagination,
  Brand,
  CategoryType,
  PaginatedList,
  Sort,
} from "center-services";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-retail-item-selection",
  templateUrl: "./retail-item-selection.component.html",
  styleUrls: ["./retail-item-selection.component.scss"],
  providers: [SubscriptionService],
})
export class RetailItemSelectionComponent
  extends FilteredTableListComponent
  implements OnInit, OnChanges, AfterViewInit, PaginableComponent {
  public static LIST_ID: string = "app-retail-item-selection.retail-item-selection-table";
  @ViewChild("table") table: DatatableComponent;
  @Input() popupWidth: string = "70vw";
  @Input() columns: any[];
  @Input() unique: boolean = false;
  @Input() limit: number = 10;
  @Input() selection: number[];
  @Output() selectionChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "statusIndex",
      dir: "asc",
    },
    {
      prop: "designation",
      dir: "asc",
    },
  ];
  public retailItemList: AbstractItem[] = [];
  public allBrandsList: Light[] = [];
  public allBrandCollectionList: BrandCollection[] = [];
  public allItemCategoryList: ItemCategory[] = [];
  public popupVisible: boolean = true;
  public form: UntypedFormGroup;
  public offset: number = 0;
  public currentPageRowsIds: number[];
  public shouldClose: boolean = false;
  public initialSelection: Set<number>;
  public updatedSelection: Set<number>;
  public currentSelection: Set<number>;
  public filterer: Filterer;
  public activeFilters: SearchFilter[] = [];
  public filterValues: FilterValue[] = [];
  private readonly DEFAULT_PAGE_SIZE: number = 7;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public pager: Pagination = new Pagination({
    size: this.DEFAULT_PAGE_SIZE,
    number: 0,
  });
  private readonly STATUS_ACTIVE: number = 0;
  private readonly STATUS_OBSOLETE: number = 1;
  private readonly STATUS_DRAFT: number = 2;
  private readonly STATUS_INACTIVE: number = 3;
  private checkedRowsIds: number[] = [];
  private statusSortOrder: string[] = [];
  private sessionPagination: SessionPagination;
  private statusTranslations: { [key: string]: string } = {};
  private initObservables: Observable<any>[];

  constructor(
    private retailItemService: RetailItemService,
    translateService: TranslateService,
    private fb: UntypedFormBuilder,
    messageService: MessageService,
    userService: CaraUserService,
    private brandService: BrandService,
    private lightService: LightService,
    private changeDetector: ChangeDetectorRef,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.statusSortOrder = [
      this.translateService.instant("retail-item-list.datatable.status.ACTIVE"),
      this.translateService.instant("retail-item-list.datatable.status.OBSOLETE"),
      this.translateService.instant("retail-item-list.datatable.status.DRAFT"),
      this.translateService.instant("retail-item-list.datatable.status.INACTIVE"),
    ];

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      ItemStatusType,
      "retail-item-list.datatable.status",
      this.statusTranslations
    );
    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  public computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  ngOnInit(): void {
    this.checkedRowsIds = [...this.selection];
    this.handleInputsErrors();
    this.initObservables = [];

    this.initObservables.push(this.fetchBrandList());
    this.initObservables.push(this.fetchBrandCollectionList());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(RetailItemSelectionComponent.LIST_ID);
        this.computeSearchFilters();

        this.fetchRetailItemList();
      })
    );
  }

  ngAfterViewInit(): void {
    this.initialSelection = new Set(this.selection);
    this.updatedSelection = new Set(this.initialSelection);
  }

  ngOnChanges(): void {
    this.refresh();
  }

  refresh(): void {
    if (!this.form) {
      this.initHeaderFormControl();
    }
    this.fetchBrandList();
  }

  initHeaderFormControl(): void {
    const control = new UntypedFormControl({ value: false, disabled: this.unique });
    this.form = this.fb.group({
      headerCheckbox: control,
    });
  }

  getStatusClass(status: ItemStatusType): string {
    return `status-${status.toLowerCase()}`;
  }

  fetchBrandList(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Light[]) => {
          this.allBrandsList = brands.filter((obj: Brand) => !obj.archived);
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix("list")}.errors.get-brands`, error.message);
        }
      )
    );
  }

  fetchBrandCollectionList(): Observable<BrandCollection[]> {
    return this.brandService.getAllCollections().pipe(
      tap(
        (brandCollections: BrandCollection[]) => {
          this.allBrandCollectionList = brandCollections.filter((obj: BrandCollection) => !obj.archived);
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix("list")}.errors.get-brand-collections`, error.message);
        }
      )
    );
  }

  fetchRetailItemList(): void {
    this.activeFilters.push(new SearchFilter("category.type", SearchFilterOperator.EQUAL, CategoryType.STANDARD));
    this.subscriptionService.subs.push(
      this.retailItemService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<AbstractItem>) => {
          this.retailItemList = result.data;
          this.rows = [];
          this.pager = result.page;
          result.data.map((retailItem: AbstractItem) => {
            this.addRow(retailItem);
          });
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix("list")}.errors.get-retail-items`, error.message);
        },
        () => {
          this.preparePageDatatable();

          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
        }
      )
    );
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === RetailItemSelectionComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addListFilter(
      "brandId",
      this.translateService.instant(`${this.getTranslationPrefix("list")}.datatable.columns.brand`),
      this.allBrandsList.map(categ => {
        return { value: categ.id.toString(), displayValue: categ.name };
      })
    );

    this.filterer.addListFilter(
      "brandCollectionId",
      this.translateService.instant(`${this.getTranslationPrefix("list")}.datatable.columns.brand-collection`),
      this.allBrandCollectionList.map(categ => {
        return { value: categ.id.toString(), displayValue: categ.name };
      })
    );

    this.filterer.addListFilter(
      "status",
      this.translateService.instant(`${this.getTranslationPrefix("list")}.datatable.columns.status`),
      Object.keys(ItemStatusType).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );

    this.filterer.addFilter(
      "reference",
      this.translateService.instant(`${this.getTranslationPrefix("list")}.datatable.columns.code`),
      "string"
    );

    this.filterer.addFilter(
      "name",
      this.translateService.instant(`${this.getTranslationPrefix("list")}.datatable.columns.designation`),
      "string"
    );
  }

  applyFilters(): void {
    this.activeFilters = [];
    // reset current page
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        RetailItemSelectionComponent.LIST_ID
      ).subscribe(() => {
        this.computeSearchFilters();
        this.sessionPagination.saveToSession(RetailItemSelectionComponent.LIST_ID);

        this.fetchRetailItemList();
      })
    );
  }

  changeSortSettings(prop: string, direction: string): void {
    switch (prop) {
      case "statusIndex":
        this.sorts = [
          {
            prop: "statusIndex",
            dir: direction,
          },
        ];
        break;

      default:
        this.sorts = [
          {
            prop: "statusIndex",
            dir: "asc",
          },
          {
            prop,
            dir: direction,
          },
        ];
        break;
    }

    this.applyModifications();

    this.fetchRetailItemList();
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  toCamelCase(name: string): string {
    return name.replace(/-([a-z])/g, g => g[1].toUpperCase());
  }

  addRow(retailItem: AbstractItem): void {
    const row = {
      id: retailItem.id,
    };

    this.columns.forEach(col => {
      switch (col.name) {
        case "category":
          row["category"] = retailItem.categoryName;
          break;
        case "designation":
          row["designation"] = retailItem.name;
          break;
        case "references":
          row["references"] = "";
          break;
        case "brand":
          row["brand"] = retailItem.brandName;
          break;
        case "purchase-price":
          row["purchasePrice"] = "";
          break;
        case "selling-price":
          row["sellingPrice"] = "";
          break;
        case "status": {
          const statusLabel = this.translateService.instant(
            `${this.getTranslationPrefix("list")}.datatable.status.${retailItem.status}`
          );
          const statusClass = this.getStatusClass(retailItem.status);
          row["statusLabel"] = statusLabel;
          row["statusClass"] = statusClass;
          row["statusIndex"] = this.statusSortOrder.indexOf(statusLabel);
          break;
        }
        case "code":
          row["code"] = retailItem.reference;
          break;
        case "supplier":
          row["supplier"] = "";
          break;
        case "brand-collection":
          row["brandCollection"] = retailItem["brandCollectionId"] ? retailItem["brandCollectionName"] : "";
          break;
        case "reference":
          row["reference"] = retailItem.reference;
          break;
        default:
          console.warn(`${col.name} is an unknown column`);
      }
    });

    this.rows.push(row);

    this.initRowFormControl(retailItem.id);
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "statusIndex":
        return "status";
      case "designation":
        return "name";
      case "code":
        return "reference";
      case "category":
        return "categoryName";
      case "brand":
        return "brandName";
      case "brandCollection":
        return "brandCollectionName";
      default:
        return prop;
    }
  }

  toStatusEnum(value: number): string {
    switch (value) {
      case this.STATUS_ACTIVE:
        return "ACTIVE";
      case this.STATUS_OBSOLETE:
        return "OBSOLETE";
      case this.STATUS_DRAFT:
        return "DRAFT";
      case this.STATUS_INACTIVE:
        return "INACTIVE";

      default:
        return value.toString();
    }
  }

  initRowFormControl(id: number): void {
    // Init checked checkBoxes
    const isSelected = this.selection.includes(id);
    this.form.addControl(this.getRowControlName(id), new UntypedFormControl(isSelected));
  }

  getRowControlName(id: number): string {
    return `checked_${id}`;
  }

  preparePageDatatable(): void {
    this.currentPageRowsIds = this.getCurrentPageRowsIds();
    if (!this.unique) {
      this.updateHeaderPageCheckBox();
    }
  }

  changePage(pageInfo: any): void {
    this.applyModifications();

    this.pager.number = pageInfo.page - 1;
    this.fetchRetailItemList();
  }

  updateHeaderPageCheckBox(): void {
    const currentPageCheckedRowsIds = this.currentPageRowsIds.filter(
      id => this.form.get(this.getRowControlName(id)).value
    );
    const isSelected =
      this.currentPageRowsIds.length === currentPageCheckedRowsIds.length && currentPageCheckedRowsIds.length !== 0;
    this.form.controls["headerCheckbox"].patchValue(isSelected);
  }

  updateRowsPageCheckBoxes(): void {
    const controls = this.form.controls;
    const isHeaderSelected = this.form.controls["headerCheckbox"].value;
    this.currentPageRowsIds.map(id => controls[this.getRowControlName(id)].patchValue(isHeaderSelected));
  }

  getCurrentPageRowsIds(): any[] {
    this.changeDetector.detectChanges();
    const firstPageRowIndex = this.offset * this.limit;
    return this.table.bodyComponent._rows.slice(firstPageRowIndex, firstPageRowIndex + this.limit).map(row => row.id);
  }

  onRowCheckBoxChange(id: number): void {
    if (!this.unique) {
      this.updateHeaderPageCheckBox();
    } else {
      this.updateOtherRows(id);
    }
  }

  updateOtherRows(lastSelectedRowId: number): void {
    const controls = this.form.controls;

    for (const row of this.rows) {
      const isSelected = controls[this.getRowControlName(row.id)].value;

      // uncheck if other row is checked
      if (isSelected && row.id !== lastSelectedRowId) {
        controls[this.getRowControlName(row.id)].patchValue(false);
        break;
      }
    }
  }

  onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckBoxes();
  }

  handleInputsErrors(): void {
    // unique selection length violation
    if (this.unique && this.selection.length > 1) {
      console.error("inputsError: unique selection cannot exceed one row");
    }

    // TODO: Case when unknown row's id passed in selection list
  }

  applyModifications(): void {
    this.checkedRowsIds = this.checkedRowsIds.filter(id => {
      return this.retailItemList.find(item => item.id === id) === undefined;
    });

    for (const row of this.rows) {
      if (this.form.controls[this.getRowControlName(row.id)].value) {
        this.checkedRowsIds.push(row.id);
      }
    }
  }

  submitSelections(): void {
    this.applyModifications();
    this.selectionChange.emit(this.checkedRowsIds);

    this.close();
  }

  canClosePopup(): void {
    this.applyModifications();

    this.currentSelection = new Set(this.checkedRowsIds);

    if (
      this.isSameSet(this.initialSelection, this.currentSelection) ||
      this.isSameSet(this.currentSelection, this.updatedSelection)
    ) {
      this.close();
    } else {
      this.generateUnsavedPopinError();
    }

    // update updatedSelection anyway
    this.updatedSelection = new Set(this.currentSelection);
  }

  // close the popup
  close(): void {
    this.popupVisible = false;
    this.shouldClose = false;
    this.closePopup.emit();
  }

  generateUnsavedPopinError(): void {
    const content = this.translateService.instant("global.errors.unsaved-popin-content");
    const title = this.translateService.instant("global.errors.unsaved-title");
    this.messageService.info(content, { title });
  }

  isSameSet(s1: Set<number>, s2: Set<number>): boolean {
    if (s1.size !== s2.size) {
      return false;
    }
    return [...s1].every(i => s2.has(i));
  }

  getTranslationPrefix(c: string): string {
    return `retail-item-${c}`;
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  protected getStatusList(event: number[]): any {
    const list = [];
    if (event) {
      event.forEach(element => {
        list.push(this.toStatusEnum(element));
      });
    }
    return list;
  }
}
