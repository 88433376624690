<ng-container>
  <form class="engraving-form" [formGroup]="popupForm" novalidate>
    <div class="engraving-popup-container">
      <ui-popup title="{{ popupTitle | translate }}" (close)="closeEngravingPopup()">
        <div content>
          <ui-input-text
            *ngIf="this.purchaseOrderLine && this.purchaseOrderLine.engraving"
            type="text"
            label="{{ 'engraving-popup.fields.text' | translate }}"
            infoLabel="{{ 'engraving-popup.errors.maxlength' | translate: { maxEngravingLength: maxEngravingLength } }}"
            formControlName="text"
            [maxLength]="maxEngravingLength"
            [status]="popupForm.get('text') | formStatus"
            [errorMessage]="
              popupForm.get('text')
                | formErrorMessage: { customPrefix: 'engraving-popup.errors.' }
                | translate: { maxEngravingLength: maxEngravingLength }
            "
          >
          </ui-input-text>

          <ui-input-text
            *ngIf="this.purchaseOrderLine && this.purchaseOrderLine.engraving"
            type="text"
            label="{{ 'engraving-popup.fields.font' | translate }}"
            formControlName="font"
          >
          </ui-input-text>

          <ui-input-text
            *ngIf="this.purchaseOrderLine && this.purchaseOrderLine.engraving"
            type="text"
            label="{{ 'engraving-popup.fields.location' | translate }}"
            formControlName="location"
          >
          </ui-input-text>

          <ui-textarea label="{{ 'engraving-popup.fields.comment' | translate }}" formControlName="comment">
          </ui-textarea>
        </div>
        <div footer>
          <div class="align-buttons">
            <div class="button-popup-validate">
              <div class="button">
                <ui-button
                  text="{{ 'global.validate' | translate }}"
                  [primary]="true"
                  type="save"
                  (click)="submitEngravingPopup()"
                ></ui-button>
              </div>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </form>
</ng-container>
