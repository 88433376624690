import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { IconDefinition, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import {
  Currency,
  Supplier,
  ReceivingForm,
  Pagination,
  CaraUserService,
  ReceivingFormService,
  ReceiveStatus,
  PaginatedList,
  CaraUser,
  Sort,
} from "center-services";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-invoice-supplier-delivery-selection",
  templateUrl: "./invoice-supplier-delivery-selection.component.html",
  styleUrls: ["./invoice-supplier-delivery-selection.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceSupplierDeliverySelectionComponent
  extends FilteredTableListComponent
  implements OnInit, OnChanges, PaginableComponent {
  public static LIST_ID: string = "app-invoice-supplier-delivery-selection.delivery-selection";
  @Input() supplierId: number;
  @Input() currency: Currency;
  @Input() deliveryFormIds: number[];
  @Input() supplierList: Supplier[];
  @Input() selectedDeliveryForms: ReceivingForm[] = [];

  @Output() selectedDeliveryFormsChange: EventEmitter<ReceivingForm[]> = new EventEmitter<ReceivingForm[]>();

  @ViewChild("table") table: DatatableComponent;

  public rows: any[] = [];
  public sorts: any[] = [{ prop: "date", dir: "desc" }];
  public tableControl: UntypedFormGroup;
  public deliveryFormList: ReceivingForm[] = [];
  public activeFilters: SearchFilter[] = [];

  public currentRowsId: number[] = [];
  public locale: string;
  public dateFormat: string;

  public pager: Pagination = new Pagination({
    number: 0,
    size: 7,
  });

  public filterer: Filterer;
  faSearch: IconDefinition = faSearch;
  private sessionPagination: SessionPagination;

  constructor(
    private fb: UntypedFormBuilder,
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private receivingFormService: ReceivingFormService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  ngOnInit(): void {
    this.initSelectFormControl();
    this.selectedDeliveryForms = [];
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.fetchConnectedUserDetails();
    }
    this.initFilters();
    this.sessionPagination.loadFromSession(InvoiceSupplierDeliverySelectionComponent.LIST_ID);
    this.computeSearchFilters();

    if (this.supplierId && this.currency) {
      this.fetchDeliveryFormList();
    }
  }

  ngOnChanges(): void {
    if (this.supplierId && this.currency) {
      this.fetchDeliveryFormList();
    }
  }

  isSelectable(deliveryForm: ReceivingForm): boolean {
    if (deliveryForm.lines.length === 0) {
      return true;
    }
    for (const line of deliveryForm.lines) {
      if (!line.invoiceSupplierLineId) {
        return true;
      }
    }
    return false;
  }

  fetchDeliveryFormList(): void {
    const cloneActiveFilters = [...this.activeFilters];

    cloneActiveFilters.push(new SearchFilter("receiveStatus", SearchFilterOperator.EQUAL, ReceiveStatus.RECEIVED));

    cloneActiveFilters.push(new SearchFilter("sender.id", SearchFilterOperator.EQUAL, this.supplierId.toString()));

    cloneActiveFilters.push(new SearchFilter("currency.id", SearchFilterOperator.EQUAL, this.currency.id.toString()));

    cloneActiveFilters.push(new SearchFilter("invoicePending", SearchFilterOperator.EQUAL, "true"));

    this.subscriptionService.subs.push(
      this.receivingFormService.getAll(this.pager, this.getSorter(), cloneActiveFilters).subscribe(
        (result: PaginatedList<ReceivingForm>) => {
          this.rows = [];
          this.deliveryFormList = result.data;
          this.pager = result.page;

          this.currentRowsId = this.deliveryFormList
            .filter(
              (delivery: ReceivingForm) => !this.deliveryFormIds.includes(delivery.id) || this.isSelectable(delivery)
            )
            .map((delivery: ReceivingForm) => delivery.id);

          this.deliveryFormList.forEach((deliveryForm: ReceivingForm) => {
            this.addRow(deliveryForm);
          });
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-delivery-forms", error.message);
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
          this.updateSelection();
          this.updateHeaderPageCheckbox();
        }
      )
    );
  }

  addRow(deliveryForm: ReceivingForm): void {
    this.rows.push({
      id: deliveryForm.id,
      date: deliveryForm.documentDate,
      currencyIsoCode: this.currency.codeISO,
      totalPrice: deliveryForm.totalPrice,
      deliveryRef: deliveryForm.deliveryRef,
      supplierRef: this.supplierList.find(supplier => supplier.id === deliveryForm.senderId).reference,
      supplierName: this.supplierList.find(supplier => supplier.id === deliveryForm.senderId).name,
    });
    const isControlDisabled: boolean =
      this.deliveryFormIds.includes(deliveryForm.id) || !this.isSelectable(deliveryForm) ? true : false;
    this.tableControl.addControl(
      this.getRowControlName(deliveryForm.id.toString()),
      new UntypedFormControl({ value: isControlDisabled, disabled: isControlDisabled })
    );
  }

  public getRowControlName(id: string | number): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  updateHeaderPageCheckbox(): void {
    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.tableControl.get(this.getRowControlName(id)).value;
    });
    const isSelected = this.currentRowsId.length > 0 && this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
  }

  updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
    });
  }

  public updateSelection(): void {
    this.rows
      .filter(row => !this.tableControl.controls[this.getRowControlName(row.id)].disabled)
      .forEach(row => {
        const rowChecked: boolean = this.tableControl.controls[this.getRowControlName(row.id)].value;
        const deliveryForm: ReceivingForm = this.deliveryFormList.find(elem => elem.id === row.id);
        const isSelected: boolean =
          this.selectedDeliveryForms.findIndex(elem => elem.id === row.id) === -1 ? false : true;

        if (!isSelected && rowChecked) {
          this.selectedDeliveryForms.push(deliveryForm);
        } else if (isSelected && !rowChecked) {
          const index = this.selectedDeliveryForms.findIndex(elem => elem.id === deliveryForm.id);
          this.selectedDeliveryForms.splice(index, 1);
        }
      });
    this.sessionPagination.saveToSession(InvoiceSupplierDeliverySelectionComponent.LIST_ID);
    this.selectedDeliveryFormsChange.emit(this.selectedDeliveryForms);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "date":
        return "documentDate";
      default:
        return prop;
    }
  }

  changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];

    this.fetchDeliveryFormList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchDeliveryFormList();
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  getRowClass: any = (): any => ({ "not-clickable": true });

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === InvoiceSupplierDeliverySelectionComponent.LIST_ID
    );

    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "deliveryRef",
      this.translateService.instant("invoice-supplier-delivery-selection.datatable.columns.delivery-ref"),
      "string"
    );

    this.filterer.addFilter(
      "documentDate",
      this.translateService.instant("invoice-supplier-delivery-selection.datatable.columns.date"),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );

    this.filterer.addFilter(
      "totalPrice",
      this.translateService.instant("invoice-supplier-delivery-selection.datatable.columns.total-price"),
      "range"
    );
  }

  buildDateDefaultValue(): any {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    return {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        InvoiceSupplierDeliverySelectionComponent.LIST_ID
      ).subscribe(() => {
        this.fetchDeliveryFormList();
      })
    );
  }

  protected fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  private initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.tableControl = this.fb.group({
      headerCheckbox: control,
    });
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }
}
