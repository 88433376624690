import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IconDefinition, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: "app-receipt-validation-popup",
  templateUrl: "./receipt-validation-popup.component.html",
  styleUrls: ["./receipt-validation-popup.component.scss"],
})
export class ReceiptValidationPopupComponent {
  @Input() btnLabel: string;
  @Input() multiple: boolean;
  @Input() warn: boolean;
  @Input() withRegistering: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();
  public faWarn: IconDefinition = faExclamationTriangle;

  closeValidationPopup(): void {
    this.close.emit("close");
  }

  validateReceipt(): void {
    this.validate.emit();
  }
}
