import { Location } from "@angular/common";
import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { IconDefinition, faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import {
  AbstractItem,
  ItemCategory,
  Uom,
  Light,
  Brand,
  OverrideSizeCategory,
  SizeCategory,
  Currency,
  DocumentService,
  ItemCategoryService,
  UomService,
  CurrencyService,
  BrandService,
  LightService,
  SizeCategoryService,
  StandardItem,
  CategoryType,
  UomCategory,
  BrandCollection,
  OverrideSizeValue,
  ItemStatusType,
  ServiceItem,
} from "center-services";
import { CommonValidatorsUtil, Option } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";
import { Observable, combineLatest, merge, of } from "rxjs";
import { filter, map, mergeMap, pairwise, startWith, tap } from "rxjs/operators";

@Component({
  selector: "app-retail-item-general",
  templateUrl: "./retail-item-general.component.html",
  styleUrls: ["./retail-item-general.component.scss"],
})
export class RetailItemGeneralComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild("table") table: DatatableComponent;
  @Input() editedItem: AbstractItem;

  faPlus: IconDefinition = faPlus;
  faMinus: IconDefinition = faMinus;

  public itemForm: UntypedFormGroup;
  public tableControl: UntypedFormGroup;

  public categories: ItemCategory[] = [];
  public categoryOptions: Option[] = [];

  public weightUnits: Uom[] = [];
  public weightUnitOptions: Option[] = [];

  public brands: Light[] = [];
  public selectedBrand: Brand;
  public brandOptions: Option[] = [];

  public brandCollectionOptions: Option[] = [];

  public selectedSizeCategory: OverrideSizeCategory;
  public editedSizeCategory: OverrideSizeCategory;
  public sizeCategoryList: SizeCategory[] = [];
  public sizeCategoryOptions: Option[] = [];
  public uoms: Uom[];
  public uomOptions: Option[] = [];
  public itemOptions: Option[] = [];

  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "byDefault",
      dir: "desc",
    },
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "value",
      dir: "asc",
    },
  ];

  public statusOptions: Option[] = [];

  public typeErrorMessage: string;

  public sizeCategoryNomenclatureMessage: string;

  public selectedFile: any;
  public previousFile: any;
  public isFetched: boolean = false;
  public currency: Currency = null;
  public isUpdated: boolean;
  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  protected readonly CANBUY: string = "canBuy";
  protected readonly CANSOLD: string = "canSold";
  private initObservables: Observable<any>[];

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private documentService: DocumentService,
    private itemCategoryService: ItemCategoryService,
    private uomService: UomService,
    private currencyService: CurrencyService,
    private brandService: BrandService,
    private lightService: LightService,
    private sizeCategoryService: SizeCategoryService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private location: Location
  ) {}

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  ngOnInit(): void {
    this.prepareForm();
    this.buildStatusOptions();

    this.translateService.onLangChange.subscribe(() => {
      this.buildStatusOptions();
    });

    this.sizeCategoryNomenclatureMessage = this.getNomenclatureSizeCategoryMessage();
    if (this.sizeCategoryNomenclatureMessage && this.sizeCategoryNomenclatureMessage !== "") {
      this.itemForm.controls.sizeCategory.disable();
    }

    this.initObservables = [];
    this.initObservables.push(this.fetchUnitOfMeasure());
    this.initObservables.push(this.fetchCategories());
    this.initObservables.push(this.fetchWeightCategories());
    this.initObservables.push(this.fetchBrands());
    this.initObservables.push(this.fetchDefaultCurrency());
    this.initObservables.push(this.fetchSizeCategories());
    this.initObservables.push(this.fetchItems());

    if (this.editedItem && this.editedItem.brandId) {
      this.initObservables.push(this.onBrandChange(this.editedItem.brandId));
    }

    combineLatest(this.initObservables).subscribe(() => {
      if (this.editedItem) {
        this.loadEditedData();
        if (this.editedItem instanceof StandardItem && (this.editedItem as StandardItem).sizeCategory) {
          this.prepareSizeFormControls((this.editedItem as StandardItem).sizeCategory.sizeCategoryId);
        }
      }
    });
  }

  ngOnChanges(): void {
    if (this.initObservables) {
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedItem) {
          this.loadEditedData();
          if (this.editedItem instanceof StandardItem && (this.editedItem as StandardItem).sizeCategory) {
            this.prepareSizeFormControls((this.editedItem as StandardItem).sizeCategory.sizeCategoryId);
          }
        }
      });
    }
    this.isUpdated = this.location.path().includes("/retail-item/update/") ? true : false;
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  // tslint:disable-next-line
  prepareForm(): void {
    const digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);
    this.itemForm = this.fb.group({
      categoryId: [null, [Validators.required]],
      name: [null, [Validators.required]],
      reference: [null, [Validators.required]],
      description: [null, [Validators.required]],
      nextItemId: [null],
      previousItemId: [null],
      brandId: [null],
      engravingLengthActivated: [false],
      vatRateActivated: [true],
      ecommerce: [false, [Validators.required]],
      canSold: [false],
      canBuy: [true],
      status: [0, [Validators.required]],
      logoId: [null],
      logoFile: [[]],
    });

    this.itemForm.setValidators(this.typeValidator());
    this.itemForm.controls.nextItemId.setValidators(this.duplicateReferenceValidator());
    this.itemForm.controls.previousItemId.setValidators(this.duplicateReferenceValidator());
    merge(this.itemForm.controls.nextItemId.valueChanges, this.itemForm.controls.previousItemId.valueChanges).subscribe(
      () => {
        this.itemForm.controls.nextItemId.markAsTouched();
        this.itemForm.controls.nextItemId.updateValueAndValidity({ emitEvent: false });
        this.itemForm.controls.previousItemId.markAsTouched();
        this.itemForm.controls.previousItemId.updateValueAndValidity({ emitEvent: false });
      }
    );

    // tslint:disable-next-line
    switch (this.editedItem.type) {
      case CategoryType.SERVICE:
        this.itemForm.addControl("mainUnitId", new UntypedFormControl(null, Validators.required));
        break;
      case CategoryType.STANDARD:
        this.itemForm.addControl("weight", new UntypedFormControl(null));
        this.itemForm.addControl("tare", new UntypedFormControl(null));
        this.itemForm.addControl("weightUnitId", new UntypedFormControl(null));
        this.itemForm.addControl("tareUnitId", new UntypedFormControl(null));
        this.itemForm.addControl("ean", new UntypedFormControl(null));
        this.itemForm.addControl("sizeCategory", new UntypedFormControl(null));
        this.itemForm.addControl("resizingMax", new UntypedFormControl(null, [Validators.min(0)]));
        this.itemForm.addControl("caseReference", new UntypedFormControl(null));
        this.itemForm.addControl("bagReference", new UntypedFormControl(null));
        this.itemForm.addControl("resizingMin", new UntypedFormControl(null, [Validators.min(0)]));
        this.itemForm.addControl(
          "ecoContribution",
          new UntypedFormControl(null, [
            CommonValidatorsUtil.positiveNumberValidator(),
            Validators.required,
            digitValidator,
          ])
        );
        this.itemForm.addControl("ecoContributionActivated", new UntypedFormControl(false, []));
        this.itemForm.addControl("engravingLengthActivated", new UntypedFormControl(false, []));
        this.itemForm.addControl(
          "engravingLength",
          new UntypedFormControl(null, [
            Validators.required,
            CommonValidatorsUtil.positiveNumberValidator(),
            CommonValidatorsUtil.integerValidator(),
          ])
        );

        this.itemForm.addControl("brandCollectionId", new UntypedFormControl(null));
        this.itemForm.controls.weightUnitId.setValidators(
          CommonValidatorsUtil.weightUnitRequiredValidator(
            this.itemForm.controls.weight,
            this.itemForm.controls.weightUnitId
          )
        );
        this.itemForm.controls.weightUnitId.valueChanges.subscribe(() => {
          this.updateWeightAndTare();
        });
        this.itemForm.controls.tareUnitId.setValidators(
          CommonValidatorsUtil.tareUnitRequiredValidator(this.itemForm.controls.tare, this.itemForm.controls.tareUnitId)
        );
        this.itemForm.controls.tareUnitId.valueChanges.subscribe(() => {
          this.updateWeightAndTare();
        });
        this.itemForm.controls.weight.setValidators([
          CommonValidatorsUtil.weightRequiredIfTareValidator(
            this.itemForm.controls.tare,
            this.itemForm.controls.weight
          ),
          this.weightAboveTareValidator(),
          CommonValidatorsUtil.weightRequiredIfUnitValidator(
            this.itemForm.controls.weight,
            this.itemForm.controls.weightUnitId
          ),
          CommonValidatorsUtil.positiveNumberValidator(),
          digitValidator,
        ]);
        this.itemForm.controls.tare.setValidators([
          CommonValidatorsUtil.tareRequiredIfUnitValidator(
            this.itemForm.controls.tare,
            this.itemForm.controls.tareUnitId
          ),
          Validators.min(0),
          digitValidator,
        ]);
        // listener on sizeCategory change
        this.itemForm.controls.sizeCategory.valueChanges.subscribe(value => {
          this.onSizeCategoryChange(value);
        });

        merge(
          this.itemForm.controls.weightUnitId.valueChanges,
          this.itemForm.controls.tareUnitId.valueChanges,
          this.itemForm.controls.weight.valueChanges,
          this.itemForm.controls.tare.valueChanges
        ).subscribe(() => {
          this.itemForm.controls.weight.markAsTouched();
          this.itemForm.controls.weight.updateValueAndValidity({ emitEvent: false });
          this.itemForm.controls.tare.markAsTouched();
          this.itemForm.controls.tare.updateValueAndValidity({ emitEvent: false });
          this.itemForm.controls.weightUnitId.markAsTouched();
          this.itemForm.controls.weightUnitId.updateValueAndValidity({ emitEvent: false });
          this.itemForm.controls.tareUnitId.markAsTouched();
          this.itemForm.controls.tareUnitId.updateValueAndValidity({ emitEvent: false });
        });
        break;
      default:
        break;
    }

    // listener on brand change
    this.itemForm.controls.brandId.valueChanges.subscribe(brandId => {
      this.onBrandChange(brandId).subscribe();
    });
    // listener on file change
    this.itemForm.controls.logoFile.valueChanges.subscribe(logoFile => {
      this.selectFile(logoFile);
    });

    this.itemForm.addControl("vatRateActivated", new UntypedFormControl(false, []));
    this.itemForm.addControl(
      "vatRate",
      new UntypedFormControl(this.editedItem.vatRate, [
        Validators.required,
        CommonValidatorsUtil.positiveNumberValidator(),
      ])
    );
  }

  onBrandChange(brandId: number): Observable<Brand> {
    if (brandId) {
      return this.fetchBrand(brandId);
    } else {
      this.selectedBrand = null;
      this.brandCollectionOptions = [];
      this.itemForm.controls.brandCollectionId?.setValue(null);
      this.itemForm.controls.sizeCategory?.setValue(null);
      this.editedSizeCategory = null;
      this.fillSizeCategoryList();
      return of(null);
    }
  }

  fetchBrand(brandId: number): Observable<Brand> {
    return this.brandService.get(brandId).pipe(
      tap(
        (brand: Brand) => {
          this.selectedBrand = brand;
          this.fillBrandCollectionList();
          this.fillSizeCategoryList();

          // reset size category
          this.itemForm.controls.sizeCategory?.setValue(null);
          this.editedSizeCategory = null;
          // reset collection
          this.itemForm.controls.brandCollectionId?.setValue(null);
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("item.general-datas.errors.get-brand", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  onSizeCategoryChange(value: any): void {
    // reset resizing form controls
    this.itemForm.controls.resizingMin.setValue(null);
    this.itemForm.controls.resizingMax.setValue(null);
    // handle sizeValues datatable
    this.rows = [];

    this.editedSizeCategory = null;

    if (value) {
      this.prepareSizeFormControls(value);
    }
  }

  duplicateReferenceValidator(): ValidatorFn {
    return (): { [key: string]: any } | null =>
      this.itemForm.controls.nextItemId.value &&
      this.itemForm.controls.previousItemId.value &&
      this.itemForm.controls.nextItemId.value === this.itemForm.controls.previousItemId.value
        ? { duplicateReference: true }
        : null;
  }

  weightAboveTareValidator(): ValidatorFn {
    return (): { [key: string]: any } | null => {
      const weightValue = this.uomService.convertWeightTo(
        this.weightUnits,
        this.itemForm.controls.weight.value,
        this.itemForm.controls.weightUnitId.value,
        this.itemForm.controls.tareUnitId.value
      );

      return this.itemForm.controls.tare.value &&
        this.itemForm.controls.tare.value !== "" &&
        this.itemForm.controls.weight.value &&
        this.itemForm.controls.weight.value !== "" &&
        +weightValue < +this.itemForm.controls.tare.value
        ? { low: true }
        : null;
    };
  }

  typeValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      this.typeErrorMessage = null;
      const canBuy = group.controls.canBuy;
      const canSold = group.controls.canSold;

      if (!canBuy.value && !canSold.value) {
        this.typeErrorMessage = this.translateService.instant("item.general-datas.errors.set-type");
        return { type: true };
      }
      return {};
    };
  }

  getTypeLabelClass(): string {
    if (this.typeErrorMessage) {
      return "type-label error";
    }
    return "type-label";
  }

  getNextReferenceClass(): string {
    if (this.editedItem) {
      return !this.editedItem.lastReference ? "next-reference solo" : "next-reference";
    }
    return "";
  }

  getNextReferenceParentClass(): string {
    if (this.editedItem) {
      return !this.editedItem.lastReference ? "multi-col" : "double-col";
    }
    return "";
  }

  fetchDefaultCurrency(): Observable<Currency> {
    const observable = this.currencyService.getDefault();
    observable.subscribe((defaultCurrency: Currency) => {
      this.currency = defaultCurrency;
    });
    return observable;
  }

  fetchUnitOfMeasure(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.uoms = uoms;

          this.uomOptions = uoms
            .filter((obj: Uom) => !obj.archived)
            .sort((a, b) => a.longName.localeCompare(b.longName))
            .map((obj: Uom) => new Option(obj.id, obj.longName));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("uoms-list.errors.get-entities");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCategories(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (categories: ItemCategory[]) => {
          this.categories = categories;
          this.categoryOptions = categories
            .filter((obj: ItemCategory) => !obj.archived && obj.type === this.editedItem.type)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: ItemCategory) => new Option(obj.id, obj.name));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("item-categories-list.errors.get-items-categories", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchWeightCategories(): Observable<Uom[]> {
    return this.uomService.getAllUomCategory().pipe(
      tap({
        error: error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("uoms-list.errors.get-uoms-category", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        },
      }),
      mergeMap((uomCats: any) => {
        const weightCategoryId = uomCats.find((cat: UomCategory) => cat.name === "poids").id;
        return this.fetchWeightUnits(weightCategoryId);
      })
    );
  }

  fetchWeightUnits(weightCategoryId: number): Observable<Uom[]> {
    return this.uomService.getAllUomsOfCategory(weightCategoryId).pipe(
      tap(
        (uoms: Uom[]) => {
          this.weightUnits = uoms;
          this.weightUnitOptions = [new Option(null, "-")];
          uoms
            .filter((obj: Uom) => !obj.archived)
            .sort((a, b) => a.shortName.localeCompare(b.shortName))
            .forEach((obj: Uom) => this.weightUnitOptions.push(new Option(obj.id, obj.shortName)));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("uoms-list.errors.get-uoms-category", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchBrands(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Light[]) => {
          this.brands = brands;
          this.brandOptions = brands
            .filter((obj: Light) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Light) => new Option(obj.id, obj.name));
          this.brandOptions.unshift(new Option(0, "-"));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("brands-list.errors.get-brands", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchSizeCategories(): Observable<SizeCategory[]> {
    return this.sizeCategoryService.getAll().pipe(
      tap(
        (sizeCategories: SizeCategory[]) => {
          this.sizeCategoryList = sizeCategories;
          this.fillSizeCategoryList();

          // reset size category
          this.itemForm.controls.sizeCategory?.setValue(null);
          this.editedSizeCategory = null;
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("size-categories-list.errors.get-size-categories", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchItems(): Observable<Light[]> {
    return this.lightService.getItems().pipe(
      tap(
        (items: Light[]) => {
          this.itemOptions = items
            .filter((obj: Light) => obj.id !== this.editedItem.id)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Light) => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.itemOptions.unshift(new Option(0, "-"));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("retail-item-list.errors.get-retail-items", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fillBrandCollectionList(): void {
    this.brandCollectionOptions = [];
    if (this.selectedBrand?.collections.length > 0) {
      this.brandCollectionOptions = this.selectedBrand.collections
        .filter(collection => !collection.archived)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((obj: BrandCollection) => new Option(obj.id, obj.name));
      this.itemForm.controls.brandCollectionId?.setValue(null);
    }
  }

  fillSizeCategoryList(): void {
    this.sizeCategoryOptions = [];
    let optIds = [];

    if (!this.selectedBrand || this.selectedBrand?.sizeCategories.length === 0) {
      // no override size category, use size categories
      this.sizeCategoryOptions = this.sizeCategoryList
        .filter(sizeCat => !sizeCat.archived)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((obj: SizeCategory) => new Option(obj.id, obj.name));
      optIds = this.sizeCategoryOptions.map((option: Option) => option.id);
    } else {
      // use override size categories
      this.selectedBrand.sizeCategories.forEach((overrideSizeCat: OverrideSizeCategory) => {
        const foundSizeCategory = this.sizeCategoryList.find(
          cat => cat.id === overrideSizeCat.sizeCategoryId && !overrideSizeCat.archived
        );
        if (foundSizeCategory) {
          const option = new Option(overrideSizeCat.sizeCategoryId, foundSizeCategory.name);
          this.sizeCategoryOptions.push(option);
          optIds.push(option.id);
        }
        this.sizeCategoryOptions = [...this.sizeCategoryOptions];
      });
    }

    optIds.push(null);
    this.addArchivedOverrideOption(optIds);
    this.sizeCategoryOptions.unshift(new Option(null, "-"));
  }

  addArchivedOverrideOption(optIds: any[]): void {
    // add option when item has override metric whose parent is archived
    if ((this.editedItem as StandardItem).sizeCategory) {
      const foundSizeCategory = this.sizeCategoryList.find(
        cat => cat.id === (this.editedItem as StandardItem).sizeCategory.sizeCategoryId && !optIds.includes(cat.id)
      );
      if (foundSizeCategory) {
        const option = new Option(
          (this.editedItem as StandardItem).sizeCategory.sizeCategoryId,
          foundSizeCategory.name
        );
        this.sizeCategoryOptions.push(option);
        this.sizeCategoryOptions.sort((a, b) => a.label.localeCompare(b.label));
      }
    }
  }

  getSizeCategory(sizeCatId: number): SizeCategory | OverrideSizeCategory {
    if (this.selectedBrand && this.selectedBrand.sizeCategories.length > 0) {
      return (
        this.selectedBrand.sizeCategories.find(oC => oC.sizeCategoryId === sizeCatId) ??
        this.sizeCategoryList.find(sC => sC.id === sizeCatId)
      );
    } else {
      return this.sizeCategoryList.find(sC => sC.id === sizeCatId);
    }
  }

  prepareSizeFormControls(sizeCatId: number): void {
    this.tableControl = new UntypedFormGroup({});

    this.selectedSizeCategory = new OverrideSizeCategory(this.getSizeCategory(sizeCatId));

    if (!this.editedSizeCategory) {
      this.editedSizeCategory = new OverrideSizeCategory(this.getSizeCategory(sizeCatId));
    }

    const selectedElements = this.selectedSizeCategory.elements;
    const editedElements = this.editedSizeCategory.elements;

    selectedElements.forEach(selectedElement => {
      const foundElement = editedElements.find(elem => elem.sizeValueId === selectedElement.sizeValueId);
      const isActivated = foundElement ? true : false;
      const isByDefault = foundElement && foundElement.byDefault ? true : false;

      const sizeFormGroup = this.fb.group({
        byDefault: { value: isByDefault, disabled: !isActivated },
        activated: { value: isActivated, disabled: isByDefault },
      });
      sizeFormGroup.controls.byDefault.valueChanges
        .pipe(
          startWith(isByDefault),
          pairwise(),
          filter(values => values[0] !== values[1]),
          map(values => values[1]),
          tap(isChecked => {
            if (!isChecked) {
              sizeFormGroup.controls.activated.enable();

              const valueIndex: number = this.editedSizeCategory.elements.findIndex(
                elem => elem.sizeValueId === selectedElement.sizeValueId
              );

              this.editedSizeCategory.elements[valueIndex].byDefault = false;

              return;
            }

            for (const [name, controlGroup] of Object.entries(this.tableControl.controls)) {
              const valueIndex: number = this.editedSizeCategory.elements.findIndex(
                x => `sizeValue${x.sizeValueId}` === name
              );

              if (sizeFormGroup === (controlGroup as UntypedFormGroup)) {
                sizeFormGroup.controls.activated.disable();
                this.editedSizeCategory.elements[valueIndex].byDefault = true;

                continue;
              }

              (controlGroup as UntypedFormGroup).controls.byDefault.setValue(false);
            }
          })
        )
        .subscribe(() => {
          this.buildTablesRows(sizeCatId);
        });

      sizeFormGroup.controls.activated.valueChanges
        .pipe(
          startWith(isActivated),
          pairwise(),
          filter(values => values[0] !== values[1]),
          map(values => values[1]),
          tap(isChecked => {
            if (isChecked) {
              sizeFormGroup.controls.byDefault.enable();

              if (
                this.editedSizeCategory.elements.find(category => category.sizeValueId === selectedElement.sizeValueId)
              ) {
                return;
              }

              this.editedSizeCategory.elements.push(this.transformSizeValue(selectedElement.sizeValueId));

              return;
            }

            sizeFormGroup.controls.byDefault.disable();
            this.editedSizeCategory.elements = this.editedSizeCategory.elements.filter(
              (sizeValue: OverrideSizeValue) => sizeValue.sizeValueId !== selectedElement.sizeValueId
            );
          })
        )
        .subscribe(() => {
          this.buildTablesRows(sizeCatId);
        });
      this.tableControl.addControl(`sizeValue${selectedElement.sizeValueId}`, sizeFormGroup);
    });

    this.buildTablesRows(sizeCatId);
  }

  buildTablesRows(sizeCatId: number): void {
    this.rows = [];

    this.selectedSizeCategory.elements.forEach(selectedElement => {
      const foundElement = this.editedSizeCategory.elements.find(
        elem => elem.sizeValueId === selectedElement.sizeValueId
      );
      const isActivated = foundElement ? true : false;
      const isByDefault = foundElement && foundElement.byDefault ? true : false;

      this.rows.push({
        id: selectedElement.sizeValueId,
        value: this.getRowSizeValue(selectedElement, sizeCatId),
        byDefault: isByDefault,
        activated: isActivated,
      });
    });
  }

  getRowSizeValue(sizeValue: any, sizeCatId: number): string {
    if (sizeValue instanceof OverrideSizeValue) {
      return this.sizeCategoryList.find(sC => sC.id === sizeCatId).elements.find(e => e.id === sizeValue.sizeValueId)
        .value;
    }
    return sizeValue.value;
  }

  transformSizeValue(id: number): OverrideSizeValue {
    const sizeValue = this.selectedSizeCategory.elements.find(elem => elem.sizeValueId === id);
    return sizeValue ? new OverrideSizeValue(sizeValue) : null;
  }

  buildStatusOptions(): void {
    this.statusOptions = Object.keys(ItemStatusType).map(
      (key, index) => new Option(index, this.translateService.instant(`item.status-options.${key}`))
    );
  }

  getLogo(): void {
    // display the current logo
    this.documentService.downloadFile(this.editedItem.logoId).subscribe(
      data => {
        if (data.byteLength === 0) {
          this.previousFile = null;
          this.selectedFile = null;
        } else {
          this.previousFile = data;
          this.selectedFile = data;
          this.itemForm.value.logoFile[0] = data;
        }
      },
      error => {
        console.error(error.message);
      }
    );
  }

  selectFile(event: any): void {
    this.selectedFile = event[0];
  }

  hasFileChanged(): boolean {
    if (!this.selectedFile && !this.previousFile) {
      return false;
    }
    if ((!this.selectedFile && this.previousFile) || (!this.previousFile && this.selectedFile)) {
      return true;
    }
    return this.previousFile.name !== this.selectedFile.name;
  }

  getFile(): void {
    return this.selectedFile;
  }

  loadEditedData(): void {
    this.loadBasicFields();
    this.handleFile();
    this.handleDropdowns();
    this.handleRadioStatus();
    this.handleVatRate();
    this.handleItemTypeSpecificFields();
  }

  loadBasicFields(): void {
    const fields = [
      "reference",
      "name",
      "description",
      "nextItemId",
      "previousItemId",
      "logoId",
      "ecommerce",
      "canBuy",
      "canSold",
    ];
    fields.forEach(field => this.itemForm.controls[field]?.setValue(this.editedItem[field]));
  }

  handleFile(): void {
    if (this.editedItem.id && this.editedItem.logoId) {
      this.getLogo();
    } else {
      this.selectedFile = null;
      this.previousFile = null;
    }
  }

  handleDropdowns(): void {
    if (this.editedItem.categoryId) {
      this.setListValue(this.categoryOptions, this.editedItem.categoryId, this.itemForm.controls.categoryId);
    }
    if (this.editedItem.brandId) {
      this.setListValue(this.brandOptions, this.editedItem.brandId, this.itemForm.controls.brandId, false);
    }
  }

  handleRadioStatus(): void {
    if (this.editedItem.status) {
      const index = Object.keys(ItemStatusType).indexOf(this.editedItem.status);
      this.itemForm.controls.status.setValue(index);
    }
  }

  handleVatRate(): void {
    this.itemForm.controls.vatRateActivated.setValue(this.editedItem.vatRate);
    this.itemForm.controls.vatRate.setValue(this.editedItem.vatRate);
    if (!this.itemForm.controls.vatRateActivated.value) {
      this.itemForm.controls.vatRate.disable();
    }
  }

  handleItemTypeSpecificFields(): void {
    switch (this.editedItem.type) {
      case CategoryType.STANDARD:
        this.loadStandardItemFields();
        break;
      case CategoryType.SERVICE:
        this.itemForm.controls.mainUnitId.setValue((this.editedItem as ServiceItem).mainUnitId);
        break;
      default:
        break;
    }
  }

  loadStandardItemFields(): void {
    const standardItem = this.editedItem as StandardItem;
    this.setStandardItemValues(standardItem);
    this.handleOptionalStandardItemFields(standardItem);
  }

  setStandardItemValues(standardItem: StandardItem): void {
    const fields = [
      "caseReference",
      "bagReference",
      "ean",
      "tare",
      "weight",
      "engravingLength",
      "ecoContribution",
      "resizingMin",
      "resizingMax",
    ];
    fields.forEach(field => this.itemForm.controls[field].setValue(standardItem[field]));

    this.itemForm.controls.engravingLengthActivated.setValue(
      standardItem.engravingLength !== null && standardItem.engravingLength !== undefined
        ? standardItem.engravingLength
        : false
    );
    this.itemForm.controls.ecoContributionActivated.setValue(
      standardItem.ecoContribution !== null && standardItem.ecoContribution !== undefined ? true : false
    );

    if (!this.itemForm.controls.engravingLengthActivated.value) {
      this.itemForm.controls.engravingLength.disable();
    }
    if (!this.itemForm.controls.ecoContributionActivated.value) {
      this.itemForm.controls.ecoContribution.disable();
    }
  }

  handleOptionalStandardItemFields(standardItem: StandardItem): void {
    if (standardItem.sizeCategory) {
      this.itemForm.controls.sizeCategory.setValue(standardItem.sizeCategory.sizeCategoryId, { emitEvent: false });
      this.editedSizeCategory = new OverrideSizeCategory(standardItem.sizeCategory);
    }
    if (standardItem.weightUnitId) {
      this.setListValue(this.weightUnitOptions, standardItem.weightUnitId, this.itemForm.controls.weightUnitId);
    }
    if (standardItem.tareUnitId) {
      this.setListValue(this.weightUnitOptions, standardItem.tareUnitId, this.itemForm.controls.tareUnitId);
    }
    if (standardItem.brandCollectionId) {
      this.setListValue(
        this.brandCollectionOptions,
        standardItem.brandCollectionId,
        this.itemForm.controls.brandCollectionId
      );
    }
  }

  setListValue(elemOptions: any[], editedElem: number, editedControl: AbstractControl, emit: boolean = true): void {
    const opt = elemOptions.find((unitOpt: Option) => {
      return unitOpt.id === editedElem;
    });
    if (opt) {
      editedControl.setValue(editedElem, { emitEvent: emit });
    }
    if (!emit && !opt) {
      this.itemForm.controls.brandCollectionId?.setValue(null);
      this.itemForm.controls.sizeCategory?.setValue(null);
      this.brandCollectionOptions = [];
      this.sizeCategoryOptions = [];
    }
  }

  getCurrencySuffix(): string {
    if (!this.currency) {
      return "";
    }
    return `${this.currency.symbol}`;
  }

  conditionalSwitchOnChanges(controlActivated: any, control: any): void {
    if (!this.itemForm.get(controlActivated).value) {
      this.editedItem[control] = null;
      this.itemForm.get(control).setValue(null);
      this.itemForm.get(control).disable();
    } else {
      this.itemForm.get(control).enable();
    }
    this.itemForm.get(control).updateValueAndValidity();
  }

  applyModifications(): void {
    if (this.itemForm.pristine && this.tableControl?.pristine) {
      return;
    }
    this.editedItem.categoryId = this.itemForm.controls.categoryId.value;
    this.editedItem.reference = this.itemForm.value.reference;
    this.editedItem.name = this.itemForm.value.name;
    this.editedItem.description = this.itemForm.value.description;
    this.editedItem.nextItemId = this.itemForm.value.nextItemId ? this.itemForm.value.nextItemId : null;
    this.editedItem.previousItemId = this.itemForm.value.previousItemId ? this.itemForm.value.previousItemId : null;
    this.editedItem.brandId = this.itemForm.value.brandId === 0 ? null : this.itemForm.value.brandId;
    this.editedItem.canBuy = this.itemForm.value.canBuy;
    this.editedItem.canSold = this.itemForm.value.canSold;
    this.editedItem.logoId = this.itemForm.value.logoId;
    this.editedItem.ecommerce = this.itemForm.value.ecommerce;
    // status
    this.editedItem.status = Object.values(ItemStatusType)[this.itemForm.value.status];
    if (this.itemForm.value.vatRate && this.itemForm.value.vatRate !== "") {
      this.editedItem.vatRate = parseFloat(this.itemForm.value.vatRate);
    }
    // tslint:disable-next-line
    switch (this.editedItem.type) {
      case CategoryType.STANDARD:
        (this.editedItem as StandardItem).caseReference = this.itemForm.value.caseReference;
        (this.editedItem as StandardItem).bagReference = this.itemForm.value.bagReference;
        (this.editedItem as StandardItem).weight = this.itemForm.value.weight;
        (this.editedItem as StandardItem).weightUnitId = this.itemForm.value.weightUnitId;
        (this.editedItem as StandardItem).brandCollectionId = this.itemForm.value.brandCollectionId;
        (this.editedItem as StandardItem).ean = this.itemForm.value.ean;
        (this.editedItem as StandardItem).resizingMin = this.itemForm.value.resizingMin;
        (this.editedItem as StandardItem).resizingMax = this.itemForm.value.resizingMax;
        (this.editedItem as StandardItem).tare = this.itemForm.value.tare;
        (this.editedItem as StandardItem).tareUnitId = this.itemForm.value.tareUnitId;
        // sizeCategory
        (this.editedItem as StandardItem).sizeCategory = this.editedSizeCategory;
        // conditional switches
        if (this.itemForm.value.engravingLength && this.itemForm.value.engravingLength !== "") {
          (this.editedItem as StandardItem).engravingLength = parseInt(this.itemForm.value.engravingLength, 10);
        }
        if (this.itemForm.value.ecoContribution && this.itemForm.value.ecoContribution !== "") {
          (this.editedItem as StandardItem).ecoContribution = parseFloat(this.itemForm.value.ecoContribution);
        }
        break;
      case CategoryType.SERVICE:
        (this.editedItem as ServiceItem).mainUnitId = this.itemForm.value.mainUnitId;
        break;
      default:
        break;
    }
  }

  updateItem(): boolean {
    // classic fields
    if (this.itemForm.invalid) {
      this.itemForm.markAllAsTouched();
      return false;
    }
    this.applyModifications();
    return true;
  }

  showTypeErrorMessage(): string {
    return this.typeErrorMessage;
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "byDefault") {
      this.sorts = [
        {
          prop: "byDefault",
          dir: direction,
        },
        {
          prop: "activated",
          dir: "desc",
        },
      ];
    } else if (prop === "activated") {
      this.sorts = [
        {
          prop: "byDefault",
          dir: "desc",
        },
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "byDefault",
          dir: "desc",
        },
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  updateWeightAndTare(): void {
    this.itemForm.controls.weight.updateValueAndValidity();
    this.itemForm.controls.tare.updateValueAndValidity();
  }

  private getNomenclatureSizeCategoryMessage(): string {
    if (this.editedItem instanceof ServiceItem) {
      return "";
    }
    return this.editedItem.isInvolvedInNomenclature
      ? this.translateService.instant("item.general-datas.size-category-nomenclature-message")
      : "";
  }
}
