import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  Address,
  Brand,
  Country,
  CountryService,
  Currency,
  CurrencyService,
  DocumentService,
  Tag,
  TagService,
} from "center-services";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService, Tag as TagComponent } from "fugu-components";
import { PrecisionUtil } from "generic-pages";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-brand-general",
  templateUrl: "./brand-general.component.html",
  styleUrls: ["./brand-general.component.scss"],
  providers: [SubscriptionService],
})
export class BrandGeneralComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() editedBrand: Brand;

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;

  public allBrandTags: Tag[];
  public availableTags: TagComponent[] = [];

  public countries: Country[];
  public countryOptions: Option[] = [];

  public currencies: Currency[];
  public currencyOptions: Option[] = [];

  public brandForm: UntypedFormGroup;
  public selectedFile: any;
  public previousFile: any;

  public pattern: any = {
    P: {
      pattern: new RegExp("[+]|[0-9]"),
      optional: true,
    },
    N: {
      pattern: new RegExp("\\d"),
    },
  };
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  private initObservables: Observable<any>[];

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private tagService: TagService,
    private countryService: CountryService,
    private currencyService: CurrencyService,
    private documentService: DocumentService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    this.initObservables = [];

    this.initObservables.push(this.fetchAllBrandTags());
    this.initObservables.push(this.fetchCountries());
    this.initObservables.push(this.fetchCurrencies());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedBrand) {
          this.loadEditedData();
        }
      })
    );
  }

  ngOnChanges(): void {
    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedBrand) {
          this.loadEditedData();
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  prepareForm(): void {
    this.brandForm = this.fb.group({
      name: [null, [Validators.required]],
      reference: [null, [Validators.required]],
      lines: [null],
      cityCode: [null],
      city: [null],
      country: [null, [Validators.required]],
      currency: [null, [Validators.required]],
      originCountry: [null, [Validators.required]],
      email: [null, [Validators.email]],
      phone: [null],
      website: [null],
      codeRCS: [null],
      color: [null],
      logo: [null],
      comment: [null],
      tags: [[]],
      logoFile: [[]],
    });
    this.subscriptionService.subs.push(
      this.brandForm.controls.logoFile.valueChanges.subscribe(logoFile => {
        this.selectFile(logoFile);
      })
    );
  }

  fetchAllBrandTags(): Observable<Tag[]> {
    return this.tagService.getAllTagsOfCategoryByName("Brand").pipe(
      tap(
        (tags: Tag[]) => {
          this.allBrandTags = tags;
          this.availableTags = tags
            .filter((obj: Tag) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Tag) => new TagComponent(obj.id, obj.name, obj.color));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("tags-list.errors.get-tags");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCountries(): Observable<Country[]> {
    return this.countryService.getAll().pipe(
      tap(
        (countries: Country[]) => {
          this.countries = countries;
          countries.forEach((country: Country) => {
            if (country.byDefault && (!this.editedBrand || !this.editedBrand.id)) {
              this.brandForm.controls.country.setValue(country.id);
              this.brandForm.controls.originCountry.setValue(country.id);
            }
          });
          this.countryOptions = countries
            .filter((obj: Country) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Country) => new Option(obj.id, obj.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("countries-list.errors.get-countries");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencies = currencies;
          currencies.forEach((currency: Currency) => {
            if (currency.byDefault && (!this.editedBrand || !this.editedBrand.id)) {
              this.brandForm.controls.currency.setValue(currency.id);
            }
          });
          this.currencyOptions = currencies
            .filter((obj: Currency) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Currency) => new Option(obj.id, obj.symbol));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("currencies-list.errors.get-currencies");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  loadEditedData(): void {
    this.brandForm.controls.name.setValue(this.editedBrand.name);
    this.brandForm.controls.reference.setValue(this.editedBrand.reference);
    if (this.editedBrand.address) {
      this.brandForm.controls.lines.setValue(this.editedBrand.address.lines);
      this.brandForm.controls.city.setValue(this.editedBrand.address.city);
      this.brandForm.controls.cityCode.setValue(this.editedBrand.address.cityCode);
      if (this.editedBrand.address.countryId) {
        this.brandForm.controls.country.setValue(this.editedBrand.address.countryId);
      }
    }
    if (this.editedBrand.originCountryId) {
      this.brandForm.controls.originCountry.setValue(this.editedBrand.originCountryId);
    }
    this.brandForm.controls.email.setValue(this.editedBrand.email);
    this.brandForm.controls.phone.setValue(this.editedBrand.phone);
    this.brandForm.controls.website.setValue(this.editedBrand.website);
    this.brandForm.controls.codeRCS.setValue(this.editedBrand.codeRCS);
    this.brandForm.controls.color.setValue(this.editedBrand.color);
    this.brandForm.controls.logo.setValue(this.editedBrand.logoId);
    this.brandForm.controls.comment.setValue(this.editedBrand.comment);
    this.brandForm.controls.tags.setValue([...this.editedBrand.tagIds]);

    // handle file
    if (this.editedBrand.id && this.editedBrand.logoId) {
      this.getLogo();
    } else {
      this.selectedFile = null;
      this.previousFile = null;
    }

    if (this.editedBrand && this.editedBrand.currencyId) {
      const editedCurrency = this.currencies.find(
        (currency: Currency) => currency.id === this.editedBrand.currencyId && !currency.archived
      );
      if (editedCurrency) {
        this.brandForm.controls.currency.setValue(this.editedBrand.currencyId);
      } else {
        this.brandForm.controls.currency.setValue(null);
      }
    }
  }

  getLogo(): void {
    // display the current logo
    this.subscriptionService.subs.push(
      this.documentService.downloadFile(this.editedBrand.logoId).subscribe(
        data => {
          if (data.byteLength === 0) {
            this.previousFile = null;
            this.selectedFile = null;
          } else {
            this.previousFile = data;
            this.selectedFile = data;
            this.brandForm.value.logoFile[0] = data;
          }
        },
        error => {
          console.error(error.message);
        }
      )
    );
  }

  selectFile(event: any): void {
    this.selectedFile = event[0];
  }

  hasFileChanged(): boolean {
    if (!this.selectedFile && !this.previousFile) {
      return false;
    }
    if ((!this.selectedFile && this.previousFile) || (!this.previousFile && this.selectedFile)) {
      return true;
    }
    return this.previousFile.name !== this.selectedFile.name;
  }

  getFile(): any {
    return this.selectedFile;
  }

  applyModifications(): void {
    // edit brand with form controls
    const cloneAddress = new Address(this.editedBrand.address);
    cloneAddress.lines = this.brandForm.value.lines;
    cloneAddress.city = this.brandForm.value.city;
    cloneAddress.cityCode = this.brandForm.value.cityCode;
    cloneAddress.countryId = this.brandForm.value.country;
    cloneAddress.byDefault = true;

    this.editedBrand.originCountryId = this.brandForm.value.originCountry;
    this.editedBrand.name = this.brandForm.value.name;
    this.editedBrand.reference = this.brandForm.value.reference;
    this.editedBrand.email = this.brandForm.value.email;
    this.editedBrand.phone = this.brandForm.value.phone;
    this.editedBrand.website = this.brandForm.value.website;
    this.editedBrand.codeRCS = this.brandForm.value.codeRCS;
    this.editedBrand.color = this.brandForm.value.color;
    this.editedBrand.logoId = this.brandForm.value.logo;
    this.editedBrand.comment = this.brandForm.value.comment;
    this.editedBrand.address = cloneAddress;
    this.editedBrand.tagIds = this.brandForm.value.tags;
    this.editedBrand.currencyId = this.brandForm.value.currency;
  }

  updateBrand(): boolean {
    if (this.brandForm.invalid) {
      this.brandForm.markAllAsTouched();
      return false;
    }

    this.applyModifications();

    return true;
  }
}
