import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  IconDefinition,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  Action,
  AppConfig,
  AppConfigService,
  AuthService,
  CaraUser,
  CaraUserService,
  CreationDeliveryType,
  Currency,
  CurrencyService,
  DeliveryLineStatus,
  DeliveryType,
  Light,
  LightService,
  PaginatedList,
  Pagination,
  PurchaseOrderType,
  ReceiveStatus,
  ReceivingForm,
  ReceivingFormService,
  ReceptionType,
  ShipmentStatus,
  Sort,
  Store,
  StoreService,
} from "center-services";
import {
  FilterValue,
  Filterer,
  Option,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { Observable, combineLatest } from "rxjs";
import { filter, tap } from "rxjs/operators";

@Component({
  selector: "app-new-receipt-list",
  templateUrl: "./new-receipt-list.component.html",
  styleUrls: ["./new-receipt-list.component.scss"],
  providers: [SubscriptionService],
})
export class NewReceiptListComponent
  extends FilteredTableListComponent
  implements OnInit, OnDestroy, PaginableComponent {
  @ViewChild("table") table: any;
  public faInfoCircle: IconDefinition = faInfoCircle;

  public LIST_ID: string = "app-new-receipt-list.new-receipt-list";

  public statusIndexes: string[] = [];
  public storeOptions: Option[] = [];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public tableControl: UntypedFormGroup;
  public initiatorPopupVisible: boolean = false;
  public pendingDeliveryChoicePopupVisible: boolean = false;
  public receivingFormList: ReceivingForm[] = [];
  public selectedReceivingFormList: ReceivingForm[] = [];
  public pendingReceivingForm: ReceivingForm;
  public currentRowsId: number[] = [];
  public locale: string;
  public dateFormat: string;
  public withRegistering: boolean;
  public defaultCurrency: Currency;
  public currencyList: Currency[] = [];
  public supplierList: Light[] = [];
  public storeList: Light[] = [];
  public mainStore: Store;
  public contextStore: Light;
  public rows: any[] = [];
  public exclamationCircle: IconDefinition = faExclamationCircle;
  public warnIcon: IconDefinition = faExclamationTriangle;
  public checkIcon: IconDefinition = faCheckCircle;
  public faInfo: IconDefinition = faInfoCircle;
  public validationPopupVisible: boolean = false;
  public sorts: any[] = [
    { prop: "statusIndex", dir: "asc" },
    { prop: "documentDate", dir: "desc" },
  ];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  supplierAndStoreList: any[] = [];
  selectedStoreId: number;
  private statusTranslations: { [key: string]: string } = {};
  private typeTranslations: { [key: string]: string } = {};
  private creationTypeTranslations: { [key: string]: string } = {};
  private orderTypeTranslations: { [key: string]: string } = {};
  private sessionPagination: SessionPagination;
  private initObservables: Observable<any>[] = [];
  private canReadPrices: boolean;
  private canEditPrices: boolean;
  private canEditWeight: boolean;
  private canJustRead: boolean;
  private canCreateAndUpdateDirect: boolean;
  private canCreateAndUpdateWithRegistering: boolean;
  private canPrepareReceiving: boolean;
  private canUpdatePreparation: boolean;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private currencyService: CurrencyService,
    private storeService: StoreService,
    private lightService: LightService,
    private appConfigService: AppConfigService,
    private receivingFormService: ReceivingFormService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.statusIndexes = [
      this.translateService.instant("new-receipt-list.status-options.TO_PROCESS"),
      this.translateService.instant("new-receipt-list.status-options.TO_RECEIVE"),
      this.translateService.instant("new-receipt-list.status-options.PREPARATION_IN_PROGRESS"),
      this.translateService.instant("new-receipt-list.status-options.RECEIVE_IN_PROGRESS"),
      this.translateService.instant("new-receipt-list.status-options.PENDING"),
      this.translateService.instant("new-receipt-list.status-options.RECEIVED"),
      this.translateService.instant("new-receipt-list.status-options.REFUSED"),
    ];

    this.sessionPagination = new SessionPagination(this);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      DeliveryType,
      "new-receipt-list.type-options",
      this.typeTranslations
    );
    EnumTranslationUtil.buildTranslations(
      this.translateService,
      CreationDeliveryType,
      "new-receipt-list.creation-type-options",
      this.creationTypeTranslations
    );
    EnumTranslationUtil.buildTranslations(
      this.translateService,
      PurchaseOrderType,
      "new-receipt-list.order-type-options",
      this.orderTypeTranslations
    );
    EnumTranslationUtil.buildTranslations(
      this.translateService,
      ReceiveStatus,
      "new-receipt-list.status-options",
      this.statusTranslations
    );
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.selectedStoreId = this.authService.getContextStoreId();
    this.initSelectFormControl();
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.canCreateAndUpdateDirect = this.userService.canDo("RECEIVING_FORM_DIRECT_CREATE_UPDATE");
      this.canCreateAndUpdateWithRegistering = this.userService.canDo("RECEIVING_FORM_REGISTERING_CREATE_UPDATE");
      this.canJustRead = this.userService.canDo("RECEIVING_FORM_GLOBAL");
      this.canReadPrices = this.userService.canDo("PURCHASING_PRICE");
      this.canEditPrices = this.userService.canDo("RECEIVING_FORM_UPDATE_PRICE");
      this.canEditWeight = this.userService.canDo("RECEIVING_FORM_REGISTERING_UPDATE_WEIGHT");
      this.canPrepareReceiving = this.userService.canDo("RECEIVING_FORM_REGISTERING_PREPARE");
      this.canUpdatePreparation = this.userService.canDo("RECEIVING_FORM_REGISTERING_UPDATE_PREPARATION");
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }

    if (this.appConfigService.appConfig.value) {
      this.withRegistering = this.appConfigService.appConfig.value.receptionType === ReceptionType.WITH_REGISTERING;
    } else {
      this.initObservables.push(this.fetchReceptionType());
    }

    this.initObservables.push(this.fetchDefaultCurrency());
    this.initObservables.push(this.fetchCurrencies());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchMainStore());
    this.initObservables.push(this.fetchSuppliers());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        const selectedStoreId = this.authService.getContextStoreId();
        this.contextStore = this.storeList.find(store => store.id === selectedStoreId);
        this.initFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.computeSearchFilters();
        this.fetchReceivingFormList();
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      this.sessionPagination.saveToSession(this.LIST_ID);
    }
  }

  fetchReceivingFormList(): void {
    this.subscriptionService.subs.push(
      this.receivingFormService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<ReceivingForm>) => {
          this.rows = [];
          this.receivingFormList = result.data;
          this.pager = result.page;
          this.currentRowsId = result.data
            .filter(res => this.isCheckableRow(res))
            .map((receive: ReceivingForm) => receive.id);

          result.data.forEach((receivingForm: ReceivingForm) => {
            this.addRow(receivingForm);
          });
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-receiving-forms", error.message);
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
          this.updateSelection();
          this.updateHeaderPageCheckbox();
        }
      )
    );
  }

  updateHeaderPageCheckbox(): void {
    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.tableControl.get(this.getRowControlName(id)).value;
    });
    const isSelected = this.currentRowsId.length > 0 && this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
  }

  shouldShowWarningMessage(): boolean {
    return this.receivingFormList.some(
      rc =>
        rc.receiveStatus === ReceiveStatus.RECEIVE_IN_PROGRESS &&
        this.tableControl.get(this.getRowControlName(rc.id.toString())).value
    );
  }

  updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
    });
  }

  isCheckableRow(receivingForm: ReceivingForm): boolean {
    if (receivingForm.receiverId !== this.mainStore.id && receivingForm.receiverId !== this.selectedStoreId) {
      return false;
    }
    return receivingForm.canReceive(this.withRegistering);
  }

  addRow(receivingForm: ReceivingForm): void {
    const statusLabel = this.translateService.instant(`new-receipt-list.status-options.${receivingForm.receiveStatus}`);
    const quantityClass = this.getQuantityClass(receivingForm);
    const checkable = this.isCheckableRow(receivingForm);
    let supplier = this.supplierList.find(sup => sup.id === receivingForm.senderId);
    if (receivingForm.type === DeliveryType.INTERNAL && !supplier) {
      supplier = this.storeList.find(store => store.id === receivingForm.senderId);
    }

    this.rows.push({
      id: receivingForm.id,
      deliveryRef: receivingForm.deliveryRef,
      documentDate: receivingForm.documentDate,
      orderReferences: receivingForm.orderReferences?.split("~"),
      supplierRef: supplier.reference,
      supplierName: supplier.name,
      recipientName: this.storeList.find(store => store.id === receivingForm.receiverId).name,
      quantity: this.getQuantity(receivingForm),
      receivedQuantity: receivingForm.receivedQuantity,
      type: this.translateService.instant(`new-receipt-list.type-options.${receivingForm.type}`),
      creationType: this.translateService.instant(
        `new-receipt-list.creation-type-options.${receivingForm.creationType}`
      ),
      differencesNumber: receivingForm.differencesNumber,
      iconClass: receivingForm.differencesNumber > 0 ? "warn-icon" : "check-icon",
      icon: receivingForm.differencesNumber > 0 ? this.warnIcon : this.checkIcon,
      matchExpectation: receivingForm.matchExpectation,
      orderTypes: [
        ...new Set(
          receivingForm.orderTypes
            ?.split("~")
            .map(type => this.translateService.instant(`new-receipt-list.order-type-options.${type}`))
        ),
      ],
      totalPrice: this.currencyService.computeDeviseConversion(
        receivingForm.totalPrice,
        receivingForm.currencyId,
        this.defaultCurrency.id
      ),
      receiveStatus: statusLabel,
      statusIndex: this.statusIndexes.indexOf(statusLabel),
      statusClass: this.getStatusClass(receivingForm.receiveStatus),
      currencyIsoCode: this.defaultCurrency.codeISO,
      quantityClass,
      checkable,
    });

    if (checkable) {
      this.tableControl.addControl(this.getRowControlName(receivingForm.id.toString()), new UntypedFormControl(false));
    }
  }

  getQuantity(receivingForm: ReceivingForm): number {
    return receivingForm.lines.reduce((sum, line) => sum + (line.expectedQuantity || 0), 0);
  }

  getQuantityClass(receivingForm: ReceivingForm): string {
    const quantity = this.getQuantity(receivingForm);
    const receivedQuantity = receivingForm.receivedQuantity;
    if (!receivedQuantity || receivedQuantity === 0) {
      return "quantity-wrapper none";
    } else if (receivedQuantity < quantity) {
      return "quantity-wrapper partial";
    } else {
      return "quantity-wrapper full";
    }
  }

  getStatusClass(status: ReceiveStatus): string {
    return `status-${status.toLowerCase().replace(/_/g, "-")}`;
  }

  createReceivingForm(): void {
    if (!this.canCreateAndUpdateDirect && !this.canPrepareReceiving) {
      return;
    }
    this.initiatorPopupVisible = true;
  }

  updateReceivingForm(event: any): void {
    if (event.type !== "click") {
      return;
    }

    const receivingForm = this.receivingFormList.find(rf => rf.id === event.row.id);
    if (!receivingForm) {
      console.error(`can't find receiving form with id ${event.row.id}`);
      return;
    }
    if (this.canJustRead && this.shouldOpenDetailsPage(receivingForm)) {
      this.router.navigateByUrl(`/receiving-form-detail/${event.row.id}`);
      return;
    }
    this.sessionPagination.saveToSession(this.LIST_ID);

    if (this.shouldOpenChoicePopup(receivingForm)) {
      this.handlePopupNavigation(receivingForm);
    } else {
      this.handleDirectNavigation(receivingForm, event.row.id);
    }
  }

  handleDirectNavigation(receivingForm: ReceivingForm, rowId: string): void {
    if (receivingForm.receiveStatus === ReceiveStatus.RECEIVED || this.noRightsToUpdateLines([receivingForm])) {
      this.router.navigateByUrl(`/receiving-form-detail/${rowId}`);
    } else {
      this.router.navigateByUrl(`/receiving-form/update/${rowId}`);
    }
  }

  handlePopupNavigation(receivingForm: ReceivingForm): void {
    if (this.shouldNavigateForPendingLines(receivingForm)) {
      this.router.navigate([`/receiving-form/update/${receivingForm.id}`], {
        state: { action: Action.PREPARE },
      });
    } else if (this.shouldSkipPopup(receivingForm)) {
      const statuses: DeliveryLineStatus[] = receivingForm
        .getLinesUniqueStatus()
        .filter(s => s === DeliveryLineStatus.VALIDATED || s === DeliveryLineStatus.RECEIVED);
      if (statuses && statuses.length === 1) {
        this.router.navigate([`/receiving-form/update/${receivingForm.id}`], {
          state: {
            action: statuses[0] === DeliveryLineStatus.VALIDATED ? Action.RECEIVE : Action.UPDATE_RECEPTION,
          },
        });
      }
    } else if (this.shouldPrepareAllPending(receivingForm)) {
      const statuses: DeliveryLineStatus[] = receivingForm.getLinesUniqueStatus();
      if (statuses && statuses.length === 1 && statuses[0] === DeliveryLineStatus.PENDING) {
        this.router.navigate([`/receiving-form/update/${receivingForm.id}`], {
          state: {
            action: Action.PREPARE,
          },
        });
      }
    }
    this.pendingReceivingForm = receivingForm;
    this.pendingDeliveryChoicePopupVisible = true;
  }

  shouldNavigateForPendingLines(receivingForm: ReceivingForm): boolean {
    return (
      receivingForm.receiveStatus === ReceiveStatus.PENDING &&
      this.canPrepareReceiving &&
      !this.canCreateAndUpdateWithRegistering &&
      !this.canUpdatePreparation
    );
  }

  shouldSkipPopup(receivingForm: ReceivingForm): boolean {
    return (
      receivingForm.receiveStatus === ReceiveStatus.PENDING &&
      !this.canPrepareReceiving &&
      (this.canCreateAndUpdateWithRegistering || this.canUpdatePreparation)
    );
  }

  shouldPrepareAllPending(receivingForm: ReceivingForm): boolean {
    return (
      receivingForm.receiveStatus === ReceiveStatus.PENDING &&
      this.canPrepareReceiving &&
      (this.canCreateAndUpdateWithRegistering || this.canUpdatePreparation)
    );
  }

  noRightsToUpdateLines(filteredList: ReceivingForm[]): boolean {
    if (
      filteredList[0].receiveStatus === ReceiveStatus.PENDING &&
      !this.canPrepareReceiving &&
      this.canCreateAndUpdateWithRegistering
    ) {
      const statuses: DeliveryLineStatus[] = filteredList[0].getLinesUniqueStatus();
      if (statuses && statuses.length === 1 && statuses[0] === DeliveryLineStatus.PENDING) {
        return true;
      }
    }
    return false;
  }

  /**
   * Popup should open only for manual creation type and
   * when receiving form is pending and at least one line is not pending or refused
   * or receiving form status is to_receive and at least one line is already received
   * @param receivingForm passed as parameter
   * @returns if true: open else not
   */
  shouldOpenChoicePopup(receivingForm: ReceivingForm): boolean {
    return (
      (this.canPrepareReceiving || this.canCreateAndUpdateWithRegistering || this.canUpdatePreparation) &&
      receivingForm.creationType === CreationDeliveryType.MANUAL &&
      ((receivingForm.receiveStatus === ReceiveStatus.PENDING &&
        receivingForm.atLeastOneLineHasNot(DeliveryLineStatus.PENDING, DeliveryLineStatus.REFUSED)) ||
        (receivingForm.receiveStatus === ReceiveStatus.TO_RECEIVE &&
          receivingForm.atLeastOneLineHas(DeliveryLineStatus.RECEIVED)))
    );
  }

  closeInitiatorPopup(): void {
    this.initiatorPopupVisible = false;
  }

  closePendingDeliveryChoicePopup(): void {
    this.pendingDeliveryChoicePopupVisible = false;
    this.pendingReceivingForm = null;
  }

  fetchDefaultCurrency(): Observable<Currency> {
    const observable = this.currencyService.defaultCurrency.pipe(filter(cur => cur !== undefined));

    this.subscriptionService.subs.push(
      observable.subscribe(currency => {
        this.defaultCurrency = currency;
      })
    );
    return observable;
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencyList = currencies;
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-currencies", error.message);
        }
      )
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-main-store", error.message);
        }
      )
    );
  }

  fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeList = lightStores;
          this.supplierAndStoreList = this.supplierAndStoreList.concat(lightStores);
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-stores", error.message);
        }
      )
    );
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierList = lightSuppliers;
          this.supplierAndStoreList = this.supplierAndStoreList.concat(lightSuppliers);
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-suppliers", error.message);
        }
      )
    );
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
        this.canCreateAndUpdateDirect = this.userService.canDo("RECEIVING_FORM_DIRECT_CREATE_UPDATE");
        this.canCreateAndUpdateWithRegistering = this.userService.canDo("RECEIVING_FORM_REGISTERING_CREATE_UPDATE");
        this.canJustRead = this.userService.canDo("RECEIVING_FORM_GLOBAL");
        this.canReadPrices = this.userService.canDo("PURCHASING_PRICE");
        this.canEditPrices = this.userService.canDo("RECEIVING_FORM_UPDATE_PRICE");
        this.canEditWeight = this.userService.canDo("RECEIVING_FORM_REGISTERING_UPDATE_WEIGHT");
        this.canPrepareReceiving = this.userService.canDo("RECEIVING_FORM_REGISTERING_PREPARE");
        this.canUpdatePreparation = this.userService.canDo("RECEIVING_FORM_REGISTERING_UPDATE_PREPARATION");
      })
    );
  }

  fetchReceptionType(): Observable<AppConfig> {
    return this.appConfigService.appConfig.pipe(
      tap(appConfig => {
        this.withRegistering = appConfig?.receptionType === ReceptionType.WITH_REGISTERING;
      })
    );
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "statusIndex":
        return "receiveStatus";
      case "supplierRef":
        return "sender.reference";
      case "supplierName":
        return "sender.name";
      case "recipientName":
        return "receiver.name";
      default:
        return prop;
    }
  }

  changeSortSettings(prop: string, direction: string): void {
    switch (prop) {
      case "statusIndex":
        this.sorts = [
          {
            prop: "statusIndex",
            dir: direction,
          },
        ];
        break;
      default:
        this.sorts = [
          {
            prop: "statusIndex",
            dir: "asc",
          },
          {
            prop,
            dir: direction,
          },
        ];
        break;
    }

    this.fetchReceivingFormList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchReceivingFormList();
  }

  buildDateDefaultValue(): any {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    return {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "deliveryRef",
      this.translateService.instant("new-receipt-list.datatable.columns.delivery-reference"),
      "string"
    );

    this.filterer.addFilter(
      "documentDate",
      this.translateService.instant("new-receipt-list.datatable.columns.document-date"),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );

    this.filterer.addFilter(
      "orderReferences",
      this.translateService.instant("new-receipt-list.datatable.columns.order-references"),
      "string"
    );

    this.filterer.addListFilter(
      "sender.id",
      this.translateService.instant("new-receipt-list.datatable.columns.supplier"),
      this.supplierAndStoreList
        .map(sas => {
          return { value: sas.id.toString(), displayValue: `${sas.reference} - ${sas.name}` };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      false,
      false,
      [],
      null,
      true,
      false
    );

    // Display only contextStore if contextStore.id if different from mainStoreId
    this.filterer.addListFilter(
      "receiver.id",
      this.translateService.instant("new-receipt-list.datatable.columns.recipient-name"),
      this.contextStore.id === this.mainStore.id
        ? this.storeList
          .map(store => ({
            value: store.id.toString(),
            displayValue: store.name,
          }))
          .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
        : [{ value: this.contextStore.id.toString(), displayValue: this.contextStore.name }],
      true,
      true,
      [this.contextStore.id.toString()],
      null,
      true,
      false
    );

    if (this.withRegistering) {
      this.filterer.addFilter(
        "receivedQuantity",
        this.translateService.instant("new-receipt-list.datatable.columns.received-quantity"),
        "range"
      );
    }

    this.filterer.addListFilter(
      "type",
      this.translateService.instant("new-receipt-list.datatable.columns.type"),
      Object.keys(DeliveryType).map(key => ({ value: key, displayValue: this.typeTranslations[key] })),
      false,
      false
    );

    this.filterer.addListFilter(
      "creationType",
      this.translateService.instant("new-receipt-list.datatable.columns.creation-type"),
      Object.keys(CreationDeliveryType).map(key => ({ value: key, displayValue: this.creationTypeTranslations[key] })),
      false,
      false
    );

    if (this.withRegistering) {
      this.filterer.addFilter(
        "differencesNumber",
        this.translateService.instant("new-receipt-list.datatable.columns.differences-count"),
        "range"
      );
    }

    this.filterer.addBooleanFilter(
      "matchExpectation",
      this.translateService.instant("new-receipt-list.datatable.columns.compliant")
    );

    this.filterer.addListFilter(
      "lines.purchaseOrderLine.purchaseOrder.orderType",
      this.translateService.instant("new-receipt-list.datatable.columns.order-types"),
      Object.keys(PurchaseOrderType).map(key => ({ value: key, displayValue: this.orderTypeTranslations[key] }))
    );

    if (this.canReadPrices) {
      this.filterer.addFilter(
        "totalPrice",
        this.translateService.instant("new-receipt-list.datatable.columns.total-price"),
        "range"
      );
    }

    this.filterer.addListFilter(
      "receiveStatus",
      this.translateService.instant("new-receipt-list.datatable.columns.status"),
      Object.keys(ReceiveStatus).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchReceivingFormList();
      })
    );
  }

  openValidationPopup(): void {
    this.validationPopupVisible = true;
  }

  closeValidationPopup(): void {
    this.validationPopupVisible = false;
  }

  public getRowControlName(id: string | number): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  public updateSelection(): void {
    this.selectedReceivingFormList = [];
    this.rows
      .filter(row => row.checkable === true)
      .forEach(row => {
        const rowChecked: boolean = this.tableControl.controls[this.getRowControlName(row.id)].value;
        const receivingForm: ReceivingForm = this.receivingFormList.find(elem => elem.id === row.id);
        if (rowChecked) {
          this.selectedReceivingFormList.push(receivingForm);
        }
      });
  }

  initReceive(): void {
    const quickReceiveObservables: Observable<any>[] = [];

    this.selectedReceivingFormList.forEach(receivingForm => {
      quickReceiveObservables.push(this.receive(receivingForm.id));
    });

    this.subscriptionService.subs.push(
      combineLatest(quickReceiveObservables).subscribe(() => {
        this.fetchReceivingFormList();
        this.closeValidationPopup();

        const title = this.translateService.instant("message.title.save-success");
        const content = this.translateService.instant("message.content.save-success");
        this.messageService.success(content, { title });
      })
    );
  }

  receive(receivingFormId: number): Observable<ReceivingForm> {
    return this.receivingFormService.receive(receivingFormId).pipe(
      tap({
        error: () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("new-receipt-list.errors.receive");
          this.messageService.warn(content, { title });
        },
      })
    );
  }

  protected computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
    // always add filter on shipmentStatus to have only the sent/settled internal DFs
    this.activeFilters.push(
      new SearchFilter("shipmentStatus", SearchFilterOperator.IN, [ShipmentStatus.SENT, ShipmentStatus.SETTLED, "NULL"])
    );
  }

  // handle select checkbox
  private initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.tableControl = this.fb.group({
      headerCheckbox: control,
    });
  }

  private shouldOpenDetailsPage(receivingForm: ReceivingForm): boolean {
    const selectedStoreId = this.authService.getContextStoreId();
    const storeForbiddenToModify =
      selectedStoreId === this.mainStore.id && selectedStoreId !== receivingForm.receiverId;
    const receivingStatus = receivingForm.receiveStatus;

    switch (receivingStatus) {
      case ReceiveStatus.TO_PROCESS:
        return this.shouldOpenForToProcess();
      case ReceiveStatus.REFUSED:
        return true;
      case ReceiveStatus.RECEIVED:
        return this.shouldOpenForReceived(receivingStatus);
      case ReceiveStatus.RECEIVE_IN_PROGRESS:
        return this.shouldOpenForReceiveInProgress(storeForbiddenToModify);
      case ReceiveStatus.PREPARATION_IN_PROGRESS:
        return this.shouldOpenForPreparationInProgress();
      case ReceiveStatus.PENDING:
        return this.shouldOpenForPending();
      case ReceiveStatus.TO_RECEIVE:
        return this.shouldOpenForToReceive(storeForbiddenToModify);
      default:
        return false;
    }
  }

  private shouldOpenForToProcess(): boolean {
    return this.onlyTheGlobalRight() || (this.withRegistering && this.canJustRead && !this.canPrepareReceiving);
  }

  private shouldOpenForReceived(receivingStatus: ReceiveStatus): boolean {
    return !(
      (this.onlyTheGlobalRight() && this.canCreateAndUpdateWithRegistering && !this.withRegistering) ||
      (!this.onlyTheGlobalRight(receivingStatus) && this.canCreateAndUpdateWithRegistering)
    );
  }

  private shouldOpenForReceiveInProgress(storeForbiddenToModify: boolean): boolean {
    return (
      storeForbiddenToModify ||
      this.onlyTheGlobalRight(ReceiveStatus.RECEIVE_IN_PROGRESS) ||
      (this.withRegistering && !this.canCreateAndUpdateWithRegistering && !this.canUpdatePreparation)
    );
  }

  private shouldOpenForPreparationInProgress(): boolean {
    return this.onlyTheGlobalRight() || (this.withRegistering && this.canJustRead && !this.canPrepareReceiving);
  }

  private shouldOpenForPending(): boolean {
    return this.onlyTheGlobalRight() || (!this.canPrepareReceiving && !this.canCreateAndUpdateWithRegistering);
  }

  private shouldOpenForToReceive(storeForbiddenToModify: boolean): boolean {
    return (
      storeForbiddenToModify ||
      this.onlyTheGlobalRight() ||
      (this.withRegistering && !this.canCreateAndUpdateWithRegistering && !this.canUpdatePreparation)
    );
  }

  // only global right or specific update right without classic create/update right
  private onlyTheGlobalRight(status?: ReceiveStatus): boolean {
    if (!this.withRegistering) {
      if (this.canJustRead) {
        if (
          !this.canCreateAndUpdateDirect ||
          status === ReceiveStatus.RECEIVED
          // go first to details page and after update page
        ) {
          return true;
        }
        // no other rights exit
        if (!this.canReadPrices && !this.canEditPrices && !this.canEditWeight) {
          return true;
        }
      }
      return false;
    } else {
      // 2 steps
      if (this.canJustRead) {
        if (
          !this.canCreateAndUpdateWithRegistering &&
          !this.canPrepareReceiving &&
          status === ReceiveStatus.RECEIVE_IN_PROGRESS
        ) {
          return true;
        }
        // go first to details page and after update page
        if (this.canCreateAndUpdateDirect && status === ReceiveStatus.RECEIVED) {
          return true;
        }
      }
      return false;
    }
  }
}
