<div class="popup-container">
  <ui-popup *ngIf="popupVisible" [title]="getTranslationPrefix() + '.title' | translate" (close)="canClosePopup()">
    <div content>
      <div class="store-module-list">
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            [label]="'filters.label' | translate"
            [filters]="filterer.getFilters()"
            [applyLabel]="'filters.apply' | translate"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div class="store-module-table">
          <div [formGroup]="form">
            <ngx-datatable
              #table
              [class]="'material'"
              [rows]="rows"
              [trackByProp]="'id'"
              [columnMode]="'flex'"
              [rowClass]="getRowClass"
              [headerHeight]="'auto'"
              [footerHeight]="50"
              [rowHeight]="40"
              [externalSorting]="true"
              [externalPaging]="true"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down'
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                [name]="'stores-list.datatable.columns.reference' | translate"
                [prop]="'reference'"
                [cellClass]="'reference'"
                [flexGrow]="1.2"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                [name]="'stores-list.datatable.columns.name' | translate"
                [prop]="'name'"
                [cellClass]="'name'"
                [flexGrow]="1.2"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                [name]="'stores-list.datatable.columns.city' | translate"
                [prop]="'city'"
                [cellClass]="'city'"
                [flexGrow]="1"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                [name]="'stores-list.datatable.columns.status' | translate"
                [prop]="'statusIndex'"
                [flexGrow]="1"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-status="row.status">
                  <ui-status-label
                    [label]="'store.general-datas.status-options.' + status | translate"
                    [customClass]="'status-' + (status | lowercase)"
                  >
                  </ui-status-label>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [name]="'stores-list.datatable.columns.tags' | translate"
                [cellClass]="'ellipsis-counter'"
                [flexGrow]="1"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-tags="row.tags">
                  <div class="tags-content">
                    <div *ngFor="let tag of tags">
                      <ui-tag [label]="tag.label" [color]="tag.color" customClass="blackLabel"></ui-tag>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [flexGrow]="0.5" [resizeable]="false">
                <ng-template ngx-datatable-cell-template let-row="row" let-tags="row.tags">
                  <div *ngIf="tags?.length >= 2">
                    <div #tagsCounter>
                      <ui-counter [number]="tags.length"></ui-counter>
                    </div>
                    <ui-tooltip [element]="tagsCounter">
                      <span *ngFor="let tag of tags" style="font-weight: normal !important"
                        ><ui-tag [label]="tag.label" [color]="tag.color" customClass="blackLabel"></ui-tag
                      ></span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [name]="getTranslationPrefix() + '.datatable.columns.module' | translate"
                [flexGrow]="1"
                [cellClass]="'store-module'"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template ngx-datatable-cell-template let-id="row.id">
                  <ui-list
                    [options]="storeModuleOptions"
                    [customClass]="'no-margin'"
                    [formControlName]="getRowControlName(id)"
                    sizeMode="small"
                  >
                  </ui-list>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              [text]="getTranslationPrefix() + '.title-update-store-modules' | translate"
              [type]="'save'"
              [primary]="true"
              (click)="submitStoreModuleEntries()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
