import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { faInfoCircle, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { ItemCategory, ItemCategoryService, CaraUserService, CategoryType } from "center-services";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { BaseListPopupComponent, ErrorUtil } from "generic-pages";

@Component({
  selector: "app-item-categories-list",
  templateUrl: "./item-categories-list.component.html",
  styleUrls: ["./item-categories-list.component.scss"],
  providers: [SubscriptionService],
})
export class ItemCategoriesListComponent extends BaseListPopupComponent<ItemCategory> implements OnInit {
  public typeOptions: Option[] = [];
  public faInfoCircle: IconDefinition = faInfoCircle;

  constructor(
    itemCategoryService: ItemCategoryService,
    translateService: TranslateService,
    messageService: MessageService,
    private fb: UntypedFormBuilder,
    private userService: CaraUserService,
    private subscriptionService: SubscriptionService
  ) {
    super(itemCategoryService, translateService, messageService);
  }

  ngOnInit(): void {
    this.sorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "name",
        dir: "asc",
      },
    ];
    this.prepareForm();
    super.ngOnInit();

    this.buildTypeOptions();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildTypeOptions();
      })
    );
  }

  onNewEntityClick(): any {
    this.selectedEntity = new ItemCategory({
      name: null,
      type: null,
      archived: false,
      frozenType: false,
    });
    this.popupTitle = `${this.getTranslationPrefix()}.popup.title-new-item-category`;
    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      name: [null, [Validators.required]],
      type: [null, [Validators.required]],
    });
  }

  onTableActivate(event: any): any {
    if (event.type === "click") {
      if (!this.userService.canDo("ITEM_CATEGORY_UPDATE")) {
        return;
      }
      const filteredList = this.entityList.filter(itemCat => itemCat.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find item category with id ${event.row.id}`);
        return;
      }
      this.selectedEntity = this.cloneEntity(filteredList[0]);
      this.popupTitle = `${this.getTranslationPrefix()}.popup.title-update-item-category`;
      this.initializePopup();
    }
  }

  initializePopup(): void {
    super.initializePopup();
    if (this.selectedEntity?.type) {
      this.popupForm.controls.name.setValue(this.selectedEntity.name);
      const index = Object.keys(CategoryType).indexOf(this.selectedEntity.type);
      this.popupForm.controls.type.setValue(index);
    } else {
      this.initialEntity.type = CategoryType.STANDARD;
      this.popupForm.controls.type.setValue(0);
    }
  }

  applyModifications(): void {
    this.selectedEntity.name = this.popupForm.value.name;
    this.popupForm.controls.type.enable();
    this.selectedEntity.type = Object.values(CategoryType)[this.popupForm.value.type] as CategoryType;
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: `${this.getTranslationPrefix()}.datatable.columns.name`,
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  buildTypeOptions(): void {
    this.typeOptions = [];
    this.typeOptions = Object.keys(CategoryType).map(
      (key, index) =>
        new Option(index, this.translateService.instant(`${this.getTranslationPrefix()}.popup.type-options.${key}`))
    );
  }

  protected cloneEntity(entity: ItemCategory): ItemCategory {
    return new ItemCategory(entity);
  }

  protected addRow(itemCategory: ItemCategory): any {
    this.rows.push({
      id: itemCategory.id,
      name: itemCategory.name,
      type: itemCategory.type,
      activated: !itemCategory.archived,
    });
    this.rows = [...this.rows];
  }

  protected canArchive(): boolean {
    return this.userService.canDo("ITEM_CATEGORY_ARCHIVE");
  }

  protected getTranslationPrefix(): string {
    return "item-categories-list";
  }

  protected preparePopupForm(): any {
    this.popupForm = this.fb.group({
      name: [this.selectedEntity.name, [Validators.required]],
    });
    const isDisabled = this.selectedEntity.frozenType;
    this.popupForm.addControl("type", new UntypedFormControl({ value: 0 }));
    this.popupForm.controls.type.setValidators(Validators.required);
    if (isDisabled) {
      this.popupForm.controls.type.disable();
    }
  }
}
