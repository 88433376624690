<ng-container>
  <ui-right-pane
    *ngIf="paneVisible && this.loadedStockEntry"
    [minWidth]="minWidth"
    [maxWidth]="maxWidth"
    [title]="this.title"
    (close)="this.closePane()"
  >
    <div class="stock-entry-detail-container">
      <div class="stock-entry-detail-content">
        <div class="grid-item name">
          <span class="text-color label bolder">{{ "stock.stock-entry-detail.name" | translate | uppercase }}</span>
        </div>

        <div class="grid-item name-value">
          <div #itemElement class="adjust ellipsis">
            {{ this.loadedStockEntry.itemName }}

            <ui-tooltip [element]="itemElement" *ngIf="itemElement">
              <span style="font-weight: normal !important; white-space: break-spaces">
                {{ this.loadedStockEntry.itemName }}
              </span>
            </ui-tooltip>
          </div>
        </div>

        <div class="grid-item">
          <span class="label bolder">{{ "stock.stock-entry-detail.quantity" | translate | uppercase }}</span>
        </div>

        <div class="grid-item quantity-value">
          <span>{{ this.selectedStockEntryLocation.quantity }} {{ this.uomName }}</span>
        </div>

        <div class="grid-item">
          <span class="label bolder">{{ "stock.stock-entry-detail.size-value" | translate | uppercase }}</span>
        </div>

        <div class="grid-item size-value">
          <span>{{ this.selectedStockEntryLocation.stockEntry.sizeValue }}</span>
        </div>

        <div class="grid-item">
          <span class="weight bolder">{{ "stock.stock-entry-detail.weight" | translate | uppercase }}</span>
        </div>

        <div class="grid-item weight-value">
          <span *ngIf="this.selectedStockEntryLocation.weight !== null"
            >{{ this.selectedStockEntryLocation.weight }}g</span
          >
        </div>

        <div class="grid-item">
          <span class="label bolder">{{ "stock.stock-entry-detail.location" | translate | uppercase }}</span>
        </div>

        <div class="grid-item location-value">
          <span>{{ this.selectedStockEntryLocation.locationName }}</span>
        </div>

        <div class="grid-item">
          <span class="tare bolder">{{ "stock.stock-entry-detail.tare" | translate | uppercase }}</span>
        </div>

        <div class="grid-item tare-value">
          <span *ngIf="this.selectedStockEntryLocation.tare !== null">{{ this.selectedStockEntryLocation.tare }}g</span>
        </div>

        <div class="grid-item">
          <span class="label bolder">{{ "stock.stock-entry-detail.supplier-ref" | translate | uppercase }}</span>
        </div>

        <div class="grid-item supplier-ref-value">
          <span>{{ this.selectedStockEntryLocation.stockEntry.supplierRef }}</span>
        </div>

        <div class="grid-item">
          <span class="label bolder">{{ "stock.stock-entry-detail.creation-date" | translate | uppercase }}</span>
        </div>

        <div class="grid-item creation-date-value">
          <span>{{ this.selectedStockEntryLocation.firstMovementDate | dateTimeFormat: false }}</span>
        </div>

        <!-- Div to organize the elements in the grid the right way -->
        <div></div>
        <div></div>

        <div class="grid-item">
          <span class="label bolder">{{ "stock.stock-entry-detail.last-movement-date" | translate | uppercase }}</span>
        </div>

        <div class="grid-item last-movement-date">
          <span>{{ this.selectedStockEntryLocation.lastMovementDate | dateTimeFormat: false }}</span>
        </div>
      </div>

      <div class="counterparty">
        <div class="label">{{ "stock.stock-entry-detail.counterparty" | translate | uppercase }}</div>
        <ui-switch [formControl]="stockForm" customClass="no-margin"> </ui-switch>
      </div>

      <div class="stock-movement-table">
        <div>
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'index'"
            class="material"
            columnMode="force"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [rowClass]="getRowClass"
            [limit]="15"
            [scrollbarH]="true"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'stock.stock-entry-detail.datatable.columns.movement-type' | translate }}"
              prop="movementType"
              cellClass="movementType ellipsis"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <ui-tag
                  *ngIf="row.movementType !== null"
                  [label]="'stock-entry-list.datatable.last-movement-type.' + row.movementType | translate"
                  [customClass]="row.typeClass"
                >
                </ui-tag>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'stock.stock-entry-detail.datatable.columns.source-location' | translate }}"
              prop="sourceLocation"
              cellClass="sourceLocation ellipsis"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div #sourceLocation class="ellipsis">
                  {{ row.sourceLocation }}
                </div>
                <ui-tooltip maxWidth="300px" [element]="sourceLocation">
                  <span style="font-weight: normal !important; white-space: pre-wrap !important">{{
                    row.sourceLocation
                  }}</span>
                </ui-tooltip>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'stock.stock-entry-detail.datatable.columns.destination-location' | translate }}"
              prop="destinationLocation"
              cellClass="destinationLocation ellipsis"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div #destinationLocation class="ellipsis">
                  {{ row.destinationLocation }}
                </div>
                <ui-tooltip maxWidth="300px" [element]="destinationLocation">
                  <span style="font-weight: normal !important; white-space: pre-wrap !important">{{
                    row.destinationLocation
                  }}</span>
                </ui-tooltip>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'stock.stock-entry-detail.datatable.columns.quantity' | translate }}"
              prop="quantity"
              cellClass="quantity"
              [draggable]="false"
              width="100"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <span class="{{ row.quantityClass }}">{{ row.quantity }} {{ this.uomName }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'stock.stock-entry-detail.datatable.columns.weight' | translate }}"
              prop="weight"
              cellClass="weight"
              [draggable]="false"
              width="100"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <span *ngIf="row.weight !== null">{{ row.weight }}g</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'stock.stock-entry-detail.datatable.columns.tare' | translate }}"
              prop="tare"
              cellClass="tare"
              [draggable]="false"
              width="100"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <span *ngIf="row.tare !== null">{{ row.tare }}g</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'stock.stock-entry-detail.datatable.columns.date' | translate }}"
              prop="date"
              cellClass="date ellipsis"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div #date class="ellipsis">
                  <span *ngIf="row.date !== null">{{ row.date | dateTimeFormat: false }}</span>
                </div>
                <ui-tooltip maxWidth="300px" [element]="date">
                  <span style="font-weight: normal !important; white-space: pre-wrap !important">
                    {{ row.date | dateTimeFormat: false }}
                  </span>
                </ui-tooltip>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'stock.stock-entry-detail.datatable.columns.operator-code' | translate }}"
              prop="operatorCode"
              cellClass="operatorCode"
              [draggable]="false"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'stock.stock-entry-detail.datatable.columns.comment' | translate }}"
              prop="comment"
              cellClass="comment"
              [resizeable]="false"
              width="150"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div #commentText class="adjust ellipsis">
                  {{ row.comment }}
                </div>
                <ui-tooltip maxWidth="300px" [element]="commentText">
                  <span style="font-weight: normal !important; white-space: pre-wrap !important">{{
                    row.comment
                  }}</span>
                </ui-tooltip>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount > 0">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </ui-right-pane>
</ng-container>
