import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IconDefinition, faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  AuthService,
  CaraUser,
  CaraUserService,
  HistorizationService,
  Light,
  LightService,
  PaginatedList,
  Pagination,
  Sort,
  Store,
  StoreService,
  UserAction,
} from "center-services";
import { FilterValue, Filterer, PaginableComponent, SearchFilter, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";
import { HistorizationRow } from "../historization-row";

@Component({
  selector: "app-historization-list",
  templateUrl: "./historization-list.component.html",
  styleUrls: ["./historization-list.component.scss"],
  providers: [SubscriptionService],
})
export class HistorizationListComponent
  extends FilteredTableListComponent
  implements OnInit, AfterViewChecked, PaginableComponent {
  public static LIST_ID: string = "historization-list.historization-table";
  public faUpRightFromSquare: IconDefinition = faUpRightFromSquare;
  public selectedRow: HistorizationRow;
  public typesForUser: any[] = [];
  public types: any[] = [];
  public fields: any[] = [];
  public actions: any[] = [];
  public sorts: any[] = [
    {
      prop: "id",
      dir: "desc",
    },
  ];
  public rows: HistorizationRow[] = [];
  public showPanel: boolean = false;
  public activeFilters: SearchFilter[] = [];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public filterer: Filterer;
  public mainStore: Store;
  public contextStore: Light;
  public locale: string;
  public dateFormat: string;
  users: Light[];
  stores: Light[];
  private initObservables: Observable<any>[];
  private defaultValue: any;
  private cd: ChangeDetectorRef;

  constructor(
    cd: ChangeDetectorRef,
    private historizationService: HistorizationService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private lightService: LightService,
    private authService: AuthService,
    private storeService: StoreService,
    protected caraUserService: CaraUserService,
    public router: Router,
    private subscriptionService: SubscriptionService
  ) {
    super(caraUserService, translateService, messageService);
    this.cd = cd;
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();
    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    this.defaultValue = {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.initObservables = [];
    this.initObservables.push(this.fetchListActions());
    this.initObservables.push(this.fetchListField());
    this.initObservables.push(this.fetchListType());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchMainStore());
    this.initObservables.push(this.fetchUsers());
    this.initObservables.push(this.fetchTypes());
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        const selectedStoreId = this.authService.getContextStoreId();

        this.contextStore = this.stores.find(store => store.id === selectedStoreId);
        this.initFilters();
        this.fetchUserActionsAndTransformToRow();
      })
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert("history-list.errors.get-main-store", error.message);
        }
      )
    );
  }

  fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.stores = lightStores;
        },
        error => {
          this.sendErrorAlert("history-list.errors.get-stores", error.message);
        }
      )
    );
  }

  fetchUsers(): Observable<CaraUser[]> {
    return this.userService.getAll().pipe(
      tap(
        (users: CaraUser[]) => {
          this.users = users.map(user => new Light({ id: user.id, name: `${user.firstName} ${user.lastName}` }));
        },
        error => {
          this.sendErrorAlert("history-list.errors.get-stores", error.message);
        }
      )
    );
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === HistorizationListComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addListFilter(
      "contextStoreId",
      this.translateService.instant("history-list.datatable.columns.store"),
      this.contextStore.id === this.mainStore.id
        ? this.stores
          .map(store => ({
            value: store.id.toString(),
            displayValue: store.name,
          }))
          .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
        : [{ value: this.contextStore.id.toString(), displayValue: this.contextStore.name }],
      true,
      true,
      [this.contextStore.id.toString()],
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "createdAt",
      this.translateService.instant("history-list.datatable.columns.date"),
      "date",
      true,
      true,
      this.defaultValue
    );

    this.filterer.addListFilter(
      "type",
      this.translateService.instant("history-list.datatable.columns.type"),
      this.typesForUser,
      false,
      false,
      [],
      null,
      true,
      false
    );

    this.filterer.addListFilter(
      "userId",
      this.translateService.instant("history-list.datatable.columns.user"),
      this.users
        .map(store => ({
          value: store.id.toString(),
          displayValue: store.name,
        }))
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      false,
      false,
      [],
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "mainObject.reference",
      this.translateService.instant("history-list.datatable.columns.reference"),
      "string",
      false,
      false
    );
    this.filterer.addListFilter(
      "actionType",
      this.translateService.instant("history-list.datatable.columns.action"),
      this.actions,
      false,
      false,
      [],
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "linkedObjects.reference",

      this.translateService.instant("history-list.datatable.columns.list-linked-ids"),
      "string",
      false,
      false
    );
    this.filterer.addFilter(
      "flux.objectId",
      this.translateService.instant("flux-list.datatable.columns.id"),
      "string",
      false,
      false
    );
    this.filterer.addListFilter(
      "flux.data",

      this.translateService.instant("flux-list.datatable.columns.field"),
      this.fields,
      false,
      false,
      [],
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "flux.newValue",
      this.translateService.instant("flux-list.datatable.columns.new-value"),
      "string",
      false,
      false
    );
    this.filterer.addFilter(
      "flux.oldValue",
      this.translateService.instant("flux-list.datatable.columns.old-value"),
      "string",
      false,
      false
    );
  }

  fetchUserActionsAndTransformToRow(): void {
    this.subscriptionService.subs.push(
      this.historizationService.getAll(this.pager, this.getSorter(), this.filterer?.getSearchFilters()).subscribe(
        (result: PaginatedList<UserAction>) => {
          this.pager = result.page;
          this.rows = result.data.map(d => new HistorizationRow(d, this.dateFormat));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("history-list.errors.get-user-actions", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.caraUserService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser?.codeLanguage;
        this.dateFormat = connectedUser?.dateFormat;
      })
    );
  }

  fetchListActions(): Observable<string[]> {
    return this.historizationService.getAllActions().pipe(
      tap(
        (values: string[]) => {
          this.actions = values.map(value => ({ value, displayValue: value }));
        },

        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("history-list.errors.get-actions");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchListType(): Observable<string[]> {
    return this.historizationService.getAllTypesForUser().pipe(
      tap(
        (values: string[]) => {
          this.typesForUser = values.map(value => ({ value, displayValue: value }));
        },

        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("history-list.errors.get-types");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchTypes(): Observable<string[]> {
    return this.historizationService.getAllTypes().pipe(
      tap(
        (values: string[]) => {
          this.types = values.map(value => ({ value, displayValue: value }));
        },

        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("history-list.errors.get-types");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchListField(): Observable<string[]> {
    return this.historizationService.getAllFields().pipe(
      tap(
        (values: string[]) => {
          this.fields = values.map(value => ({ value, displayValue: value }));
        },

        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("history-list.errors.get-fields");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  applyFilters(): void {
    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        HistorizationListComponent.LIST_ID
      ).subscribe(() => this.fetchUserActionsAndTransformToRow())
    );
  }

  changeSortSettings(_prop: string, _direction: string, _event: any): void {
    this.sorts = [
      {
        prop: _prop,
        dir: _direction,
      },
    ];
    this.fetchUserActionsAndTransformToRow();
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(
        new Sort(HistorizationRow.properties.get(s.prop) ? HistorizationRow.properties.get(s.prop) : s.prop, s.dir)
      );
    }
    return sorter;
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchUserActionsAndTransformToRow();
  }

  showDetails(event: any): void {
    if (event.type === "click") {
      this.selectedRow = event.row;
      this.showPanel = true;
    }
  }

  closePanel($event: any): void {
    if ($event === "close") {
      this.showPanel = false;
      this.selectedRow = null;
    }
  }

  openNewWindow(uri: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([uri]));

    window.open(url, "_blank");
  }
}
