import { Component, OnInit } from "@angular/core";
import {
  IconDefinition,
  faBagShopping,
  faChartLine,
  faCloudQuestion,
  faCommentsQuestionCheck,
  faFileInvoice,
  faNewspaper,
  faPersonDolly,
} from "@fortawesome/pro-solid-svg-icons";
import { CaraUserService, ThemeService, CaraUser } from "center-services";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
  providers: [SubscriptionService],
})
export class HomePageComponent implements OnInit {
  public faCloudQuestion: IconDefinition = faCloudQuestion;
  public faCommentsQuestionCheck: IconDefinition = faCommentsQuestionCheck;

  public faChartLine: IconDefinition = faChartLine;
  public faBagShopping: IconDefinition = faBagShopping;
  public faFileInvoice: IconDefinition = faFileInvoice;
  public faPersonDolly: IconDefinition = faPersonDolly;
  public faNewspaper: IconDefinition = faNewspaper;
  public loginImgScr: string = "";
  public userName: string;
  public imageLink: string = "";

  constructor(
    private userService: CaraUserService,
    private themeService: ThemeService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.userName = this.userService.connectedUser.value.firstName;
      this.setImageLink(this.userService.connectedUser.value.codeLanguage);
      this.loadLoginImgByTheme();
    } else {
      this.fetchConnectedUser();
    }
  }

  public goToHelpSite(): void {
    window.open("https://monfugu.fr/");
  }

  public goToNewsSite(): void {
    window.open("https://www.neofugu.com/fr/actualites/");
  }

  loadLoginImgByTheme(): void {
    if (this.themeService.getValue()) {
      this.loginImgScr = "./assets/images/screenTuto-light.png";
    } else {
      this.loginImgScr = "./assets/images/screenTuto-dark.png";
    }
  }

  private fetchConnectedUser(): void {
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe((connectedUser: CaraUser) => {
        if (connectedUser) {
          this.userName = connectedUser.firstName;
          this.setImageLink(connectedUser.codeLanguage);
          this.loadLoginImgByTheme();
        }
      })
    );
  }

  private setImageLink(languageCode: string): void {
    // No dark theme image for now
    const darkTheme = this.themeService.getValue() ? "" : "";
    this.imageLink = `assets/images/illustration_home_${languageCode.toUpperCase()}${darkTheme}.png`;
  }
}
