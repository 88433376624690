<ng-container>
  <form [formGroup]="popupForm" novalidate>
    <div class="inventory-initiator-popup-container">
      <ui-popup title="{{ popupTitle | translate }}" (close)="closeInventoryInitiatorPopup()">
        <div content>
          <div class="name">
            <ui-input-text
              type="text"
              label="{{ 'inventory-initiator-popup.fields.name' | translate }}"
              mandatory="true"
              formControlName="name"
              [status]="popupForm.get('name') | formStatus"
              [errorMessage]="popupForm.get('name') | formErrorMessage | translate"
            >
            </ui-input-text>
          </div>

          <div class="dates">
            <div class="begin-date">
              <ui-date-picker
                [locale]="locale"
                [format]="dateFormat"
                formControlName="beginDate"
                mandatory="true"
                [status]="popupForm.get('beginDate') | formStatus"
                [errorMessage]="popupForm.get('beginDate') | formErrorMessage | translate"
                label="{{ 'inventory-initiator-popup.fields.begin-date' | translate }}"
              >
              </ui-date-picker>
            </div>

            <div class="end-date">
              <ui-date-picker
                [locale]="locale"
                [format]="dateFormat"
                formControlName="endDate"
                mandatory="true"
                [status]="popupForm.get('endDate') | formStatus"
                [errorMessage]="
                  popupForm.get('endDate')
                    | formErrorMessage
                      : { customPrefix: 'inventory-initiator-popup.errors.', useCustomPrefixFor: ['badEndDate'] }
                    | translate
                "
                label="{{ 'inventory-initiator-popup.fields.end-date' | translate }}"
              >
              </ui-date-picker>
            </div>
          </div>
        </div>
        <div footer>
          <div class="align-buttons">
            <div class="button-popup-validate">
              <div class="button">
                <ui-button
                  text="{{ 'inventory-initiator-popup.start-button' | translate }}"
                  [primary]="true"
                  customClass="fugu-button-add"
                  [btnIcon]="faCircleArrowRight"
                  (click)="submitInventoryInitiatorPopup()"
                ></ui-button>
              </div>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </form>
</ng-container>
