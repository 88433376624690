<ng-container>
  <div class="header">
    <div class="informations">
      <div class="nomenclature-base">
        <div class="nomenclature-name">{{ nomenclature.name }}</div>
        <div class="nomenclature-date" *ngIf="nomenclature.validityDate">
          &nbsp;-&nbsp; {{ nomenclature.validityDate | dateTimeFormat: false }}
        </div>
      </div>
      <ui-status-label
        class="nomenclature-status"
        [label]="statusLabel"
        [customClass]="getStatusClass(nomenclature.archived)"
      ></ui-status-label>
    </div>

    <div class="buttons">
      <ui-button
        type="add"
        [primary]="false"
        text="{{ 'item.nomenclatures.nomenclature-form.buttons.new-item' | translate }}"
        (click)="openPopup()"
      >
      </ui-button>

      <ui-button
        type="view"
        [primary]="false"
        text="{{ 'item.nomenclatures.nomenclature-form.buttons.return-to-list' | translate }}"
        (click)="clickTowardList()"
      >
      </ui-button>
    </div>
  </div>

  <div class="items-list frozen-right">
    <div>
      <ngx-datatable
        #table
        id="nomenclature-items-table-ngx-datatable"
        [reorderable]="false"
        [rows]="tableRows"
        [trackByProp]="'elementId'"
        class="material"
        columnMode="force"
        headerHeight="auto"
        rowHeight="40"
        [rowClass]="getRowClass"
        [scrollbarH]="true"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        [treeFromRelation]="'parentId'"
        [treeToRelation]="'elementId'"
      >
        <ngx-datatable-column
          cellClass="center-align top-align"
          width="50"
          [resizeable]="false"
          headerClass="center-align"
          cellClass="center-align top-align grip-cell"
        >
          <ng-template ngx-datatable-header-template>
            <div></div>
          </ng-template>

          <ng-template ngx-datatable-cell-template let-row="row">
            <div [class.grip-block]="isRowActive(row)" *ngIf="isRowActive(row)">
              <span #onDragIconViewChild>
                <fa-icon [icon]="faGrip"></fa-icon>
              </span>
            </div>
            <div *ngIf="!isRowActive(row)"></div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclature-form.datatable.column.item-name' | translate }}"
          prop="itemName"
          [isTreeColumn]="true"
          [flexGrow]="3"
          [width]="430"
          [resizeable]="false"
          [sortable]="false"
          cellClass="item-name-column"
        >
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span *ngFor="let i of counter(row.level); trackBy: trackById" [class]="getTreeClasses(i, row)"></span>
            <span
              class="{{ row.treeStatus === EXPANDED_TREE ? 'green-zone span-icon' : '' }}"
              (click)="$event.stopPropagation()"
            >
              <span
                *ngIf="row.treeStatus === EXPANDED_TREE"
                class="expand-icon fas fa-sort-down"
                (click)="onTreeAction(row)"
              ></span>
            </span>
            <span
              class="{{ row.treeStatus === COLLAPSED_TREE ? 'green-zone span-icon' : '' }}"
              (click)="$event.stopPropagation()"
            >
              <span
                *ngIf="row.treeStatus === COLLAPSED_TREE"
                class="expand-icon fas fa-sort-down rotate-90"
                (click)="onTreeAction(row)"
              ></span>
            </span>

            <span *ngIf="row.treeStatus === 'disabled'" class="expand-icon fas fa-sort-down disabled"></span>
            <span
              #onDragViewChild
              class="{{ row.treeStatus === 'disabled' ? 'item-name span-value' : 'item-name' }}"
              title="{{ value }}"
              >{{ value }}</span
            >
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclature-form.datatable.column.item-reference' | translate }}"
          prop="itemRef"
          [flexGrow]="1"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span #onDragViewChild>{{ value }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclature-form.datatable.column.item-category' | translate }}"
          prop="itemCategoryName"
          [flexGrow]="1"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span #onDragViewChild>{{ value }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclature-form.datatable.column.item-re-supply' | translate }}"
          prop="toSupply"
          headerClass="center-align"
          cellClass="center-align top-align to-supply-column"
          [width]="155"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="green-zone" (click)="$event.stopPropagation()">
              <ui-switch customClass="no-margin" [disabled]="!isRowActive(row)" [(ngModel)]="row.toSupply"></ui-switch>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [flexGrow]="1"
          [resizeable]="false"
          [sortable]="false"
          headerClass="center-align"
          cellClass="center-align top-align quantity-column"
        >
          <ng-template ngx-datatable-header-template>
            <span>{{ "item.nomenclatures.nomenclature-form.datatable.column.item-quantity" | translate }}&nbsp;</span>
            <div #info><fa-icon [icon]="faInfo" class="info-icon"></fa-icon></div>

            <ui-tooltip maxWidth="300px" [element]="info">
              <span style="font-weight: normal !important">
                {{ "item.nomenclatures.nomenclature-form.datatable.column.item-quantity-message" | translate }}
              </span>
            </ui-tooltip>
          </ng-template>

          <ng-template ngx-datatable-cell-template let-row="row">
            <ui-input-text
              type="text"
              sizeMode="small"
              *ngIf="isRowActive(row)"
              maskPattern="0*"
              [suffix]="' ' + row?.itemUnitName"
              [(ngModel)]="row.quantity"
              (ngModelChange)="mainQuantityChanged(row)"
            >
            </ui-input-text>

            <div *ngIf="!isRowActive(row)" class="readonly-quantity">
              {{ row.quantity + " " + row?.itemUnitName }}
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          *ngFor="let sizeValue of activeSizeValues; let i = index; trackBy: trackById"
          [flexGrow]="1"
          [resizeable]="false"
          [sortable]="false"
          headerClass="center-align"
          cellClass="center-align top-align quantity-column"
        >
          <ng-template ngx-datatable-header-template>
            <span class="size-value-header-label">{{
              "item.nomenclatures.nomenclature-form.datatable.column.item-quantity-metric"
                | translate: { value: sizeValue.value }
            }}</span>
          </ng-template>

          <ng-template ngx-datatable-cell-template let-row="row">
            <ng-container
              *ngTemplateOutlet="
                sizeValuesTemplate;
                context: { row: row, sizeValue: getCurrentSizeValue(row.nomenclatureItemSizeValues, i) }
              "
            ></ng-container>
          </ng-template>

          <ng-template #sizeValuesTemplate let-row="row" let-sizeValue="sizeValue">
            <div *ngIf="!sizeValue"></div>

            <ui-input-text
              *ngIf="sizeValue && isRowActive(row)"
              type="text"
              sizeMode="small"
              maskPattern="0*"
              [suffix]="' ' + row?.itemUnitName"
              [(ngModel)]="sizeValue.quantity"
              (ngModelChange)="quantityChanged(sizeValue, row)"
            >
            </ui-input-text>

            <div *ngIf="sizeValue && !isRowActive(row)" class="readonly-quantity">
              {{ sizeValue.quantity + " " + row?.itemUnitName }}
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclature-form.datatable.column.action' | translate }}"
          [frozenRight]="true"
          cellClass="center-align top-align frozen-style action-column"
          [width]="75"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="green-zone" (click)="$event.stopPropagation()">
              <ui-menu-actions
                [menuActions]="this.isRowActive(row) ? this.activeMenuAction : this.inactiveMenuAction"
                (actionCalled)="manageActions($event, row)"
              >
              </ui-menu-actions>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</ng-container>

<app-retail-item-nomenclature-item-selector
  *ngIf="popupVisible"
  [rootItemSizeCategory]="rootItemSizeCategory"
  [rootItemId]="rootItemId"
  [nomenclatureItems]="nomenclature.nomenclatureItems"
  (close)="closePopup()"
  (validate)="addComponents($event)"
>
</app-retail-item-nomenclature-item-selector>

<div class="delete-popup-container">
  <ui-popup
    title="{{ 'item.nomenclatures.nomenclature-form.delete-popup.title' | translate }}"
    *ngIf="deletePopupVisible"
    (close)="closeDeletePopup()"
  >
    <div content>
      <div
        class="popup-content"
        [innerHTML]="'item.nomenclatures.nomenclature-form.delete-popup.message' | translate"
      ></div>
    </div>

    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <ui-button
            text="{{ 'item.nomenclatures.nomenclature-form.delete-popup.confirm-button' | translate }}"
            (click)="deleteRow()"
            [primary]="true"
            type="validate"
          ></ui-button>
        </div>

        <div class="button-popup-validate">
          <ui-button
            text="{{ 'item.nomenclatures.nomenclature-form.delete-popup.cancel-button' | translate }}"
            (click)="closeDeletePopup()"
            type="cancel"
          ></ui-button>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
