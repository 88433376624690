import { Directive, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  StoreService,
  PricingGroupService,
  CaraUserService,
  TagService,
  Tag,
  StoreStatusType,
  Store,
  PricingGroup,
  Pagination,
  PaginatedList,
  Sort,
  Address,
} from "center-services";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService, Tag as TagComponent } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

/* tslint:disable:directive-class-suffix */
@Directive({
  providers: [SubscriptionService],
})
export abstract class BaseStoreListComponent extends FilteredTableListComponent implements OnInit, PaginableComponent {
  public static LIST_ID: string = "base-store-list";
  @ViewChild("table") table: any;
  public statusTranslations: { [key: string]: string } = {};
  public rows: any[] = [];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public sessionPagination: SessionPagination;
  public sorts: any[] = [
    {
      prop: "statusIndex",
      dir: "asc",
    },
    {
      prop: "name",
      dir: "asc",
    },
  ];
  public storeList: Store[] = [];
  public allStoreTags: Tag[];
  public allPricingGroups: PricingGroup[];
  public allCities: string[] = [];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public filteredList: Store[] = [];
  protected storeService: StoreService;
  protected pricingGroupService: PricingGroupService;
  private initObservables: Observable<any>[];
  private addresses: Address[] = [];
  private readonly STATUS_ACTIVE: number = 0;
  private readonly STATUS_LIQUIDATION: number = 1;
  private readonly STATUS_RENOVATION: number = 2;
  // eslint-disable-next-line no-magic-numbers
  private readonly STATUS_INACTIVE: number = 3;

  protected constructor(
    storeService: StoreService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    pricingGroupService: PricingGroupService,
    protected userService: CaraUserService,
    protected tagService: TagService,
    protected subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.storeService = storeService;
    this.translateService = translateService;
    this.messageService = messageService;
    this.pricingGroupService = pricingGroupService;
    this.tagService = tagService;
    this.sessionPagination = new SessionPagination(this);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      StoreStatusType,
      "store.general-datas.status-options",
      this.statusTranslations
    );
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.initObservables = [];

    this.initObservables.push(this.fetchPricingGroups());
    this.initObservables.push(this.fetchOtherEntities());
    this.initObservables.push(this.fetchAllStoreTags());
    this.initObservables.push(this.fetchAddressList());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(BaseStoreListComponent.LIST_ID);
        this.computeSearchFilters();

        this.fetchStoreList();
      })
    );
  }

  changePage(pageInfo: { page: number }): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchStoreList();
  }

  public fetchStoreList(): void {
    this.subscriptionService.subs.push(
      this.storeService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<Store>) => {
          this.rows = [];
          this.pager = result.page;
          this.storeList = result.data;
          result.data.map((store: Store) => {
            const tags = this.allStoreTags
              .filter(tag => store.tagIds.includes(tag.id))
              .map((obj: Tag) => new TagComponent(obj.id, obj.name, obj.color));

            this.addRow(store, tags);
          });
          this.rows = [...this.rows];
          if (this.table) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("stores-list.errors.get-stores", { message: error.message });
          this.messageService.warn(content, { title });
        },
        () => {
          this.afterStoresFetch();
        }
      )
    );
  }

  toStatusEnum(value: number): string {
    switch (value) {
      case this.STATUS_ACTIVE:
        return "ACTIVE";
      case this.STATUS_LIQUIDATION:
        return "LIQUIDATION";
      case this.STATUS_RENOVATION:
        return "RENOVATION";
      case this.STATUS_INACTIVE:
        return "INACTIVE";
      default:
        return value.toString();
    }
  }

  getStatusClass(status: StoreStatusType): string {
    return `status-${status.toLowerCase()}`;
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "statusIndex":
        return "status";
      case "activated":
        return "archived";
      case "city":
        return "address.city";
      default:
        return prop;
    }
  }

  changeSortSettings(prop: any, direction: any): void {
    switch (prop) {
      case "statusIndex":
        this.sorts = [
          {
            prop: "statusIndex",
            dir: direction,
          },
        ];
        break;

      case "type":
        this.sorts = [
          {
            prop: "statusIndex",
            dir: "asc",
          },
          {
            prop,
            dir: direction,
          },
        ];
        break;

      default:
        this.sorts = [
          {
            prop: "statusIndex",
            dir: "asc",
          },
          {
            prop,
            dir: direction,
          },
        ];
        break;
    }

    this.fetchStoreList();
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }

    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === BaseStoreListComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "reference",
      this.translateService.instant("stores-list.datatable.columns.reference"),
      "string"
    );

    this.filterer.addFilter("name", this.translateService.instant("stores-list.datatable.columns.name"), "string");

    this.filterer.addListFilter(
      "address.city",
      this.translateService.instant("stores-list.datatable.columns.city"),
      this.addresses.map((address: Address) => {
        return { value: address.city, displayValue: address.city };
      }),
      null,
      null,
      null,
      null,
      true
    );

    this.filterer.addListFilter(
      "status",
      this.translateService.instant("stores-list.datatable.columns.status"),
      this.getStatusOptions()
    );

    this.filterer.addListFilter(
      "tags.id",
      this.translateService.instant("stores-list.datatable.columns.tags"),
      this.allStoreTags.map(tag => {
        return { value: tag.id.toString(), displayValue: tag.name };
      }),
      null,
      null,
      null,
      null,
      true
    );
  }

  getCityOptions(): any[] {
    const citiesOptions = [];
    this.storeService.getAddresses().subscribe((addresses: Address[]) => {
      addresses.forEach((address: Address, index) => {
        if (!this.allCities.some(city => city === address.city)) {
          this.allCities[index] = address.city;
          citiesOptions.push({ value: index.toString(), displayValue: address.city });
        }
      });
    });

    return citiesOptions;
  }

  getStatusOptions(): any[] {
    return Object.keys(StoreStatusType).map(key => ({ value: key, displayValue: this.statusTranslations[key] }));
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.activeFilters = [];

    this.computeSearchFilters();
    this.updatePreferences(
      this.filterer.filterValues.map(fv => fv.filterId),
      BaseStoreListComponent.LIST_ID
    ).subscribe(() => {
      this.computeSearchFilters();
      this.fetchStoreList();
      this.sessionPagination.saveToSession(BaseStoreListComponent.LIST_ID);
    });
  }

  public computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  protected getCityFilteredList(event: number[]): any[] {
    const list = [];
    if (event) {
      event.forEach(element => {
        list.push(this.allCities[element]);
      });
    }

    return list;
  }

  protected getStatusList(event: number[]): any[] {
    const list = [];
    if (event) {
      event.forEach(element => {
        list.push(this.toStatusEnum(element));
      });
    }

    return list;
  }

  protected afterStoresFetch(): void {
    this.rows = [...this.rows];
    if (this.table) {
      this.table.sorts = this.sorts;
      this.table.offset = this.pager.number;
    }
  }

  private fetchPricingGroups(): Observable<PricingGroup[]> {
    return this.pricingGroupService.getAll(["withStore"]).pipe(
      tap(
        (pricingGroups: PricingGroup[]) => {
          this.allPricingGroups = pricingGroups;
        },
        (error: any) => {
          const res = this.translateService.instant("stores-list.errors.get-pricing-groups", {
            message: error.message,
          });
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private fetchAllStoreTags(): Observable<Tag[]> {
    return this.tagService.getAllTagsOfCategoryByName("Store").pipe(
      tap(
        (tags: Tag[]) => {
          this.allStoreTags = tags.filter((obj: Tag) => !obj.archived).sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          const res = this.translateService.instant("stores-list.errors.get-tags", { message: error.message });
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private fetchAddressList(): Observable<Address[]> {
    return this.storeService.getAddresses().pipe(
      tap(
        (addresses: Address[]) =>
          addresses.forEach((address: Address, index) => {
            if (!this.allCities.some(city => city === address.city)) {
              this.allCities[index] = address.city;
              this.addresses.push(address);
            }
          }),
        error => {
          const res = this.translateService.instant("stores-list.errors.get-addresses", { message: error.message });
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  /**
   * Add a new row in the datatable from the entity passed in parameter
   */
  protected abstract addRow(store: Store, tags: TagComponent[]);

  protected abstract fetchOtherEntities();
}
