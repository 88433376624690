import { BaseListComponent } from "generic-pages";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { tap } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import {
  ItemUniverse,
  AbstractItem,
  Pagination,
  ItemGroupService,
  CaraUserService,
  ItemUniverseService,
  RetailItemService,
  ItemStoreModuleLink,
  PaginatedList,
  CategoryType,
} from "center-services";
import { ItemGroup } from "center-services/lib/model/item-group";

@Component({
  selector: "app-item-groups-list",
  templateUrl: "./item-groups-list.component.html",
  styleUrls: ["./item-groups-list.component.scss"],
  providers: [SubscriptionService],
})
export class ItemGroupsListComponent extends BaseListComponent<ItemGroup> implements OnInit {
  public allItemUniverseList: ItemUniverse[] = [];
  public activeFilters: SearchFilter[] = [];
  public retailItemList: AbstractItem[] = [];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  private initObservables: Observable<any>[];

  constructor(
    itemGroupService: ItemGroupService,
    translateService: TranslateService,
    messageService: MessageService,
    private userService: CaraUserService,
    private router: Router,
    private itemUniverseService: ItemUniverseService,
    private retailItemService: RetailItemService,
    private subscriptionService: SubscriptionService
  ) {
    super(itemGroupService, translateService, messageService);
  }

  ngOnInit(): void {
    this.tableControl = new UntypedFormGroup({});
    this.sorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "name",
        dir: "asc",
      },
    ];
    this.refresh();
  }

  refresh(): void {
    this.initObservables = [];
    this.initObservables.push(this.fetchItemUniverse());
    this.initObservables.push(this.fetchItems());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.fetchEntities();
      })
    );
  }

  fetchEntities(): void {
    this.rows = [];
    this.subscriptionService.subs.push(
      this.entityService.getAll().subscribe(
        (itemGroupList: ItemGroup[]) => {
          this.entityList = itemGroupList;

          itemGroupList.forEach((itemGroup: ItemGroup) => {
            this.addRow(itemGroup);
            this.rows = [...this.rows];

            if (this.tableControl.controls[`activated_${itemGroup.id}`]) {
              this.tableControl.controls[`activated_${itemGroup.id}`].patchValue(!itemGroup.archived);
            } else {
              this.tableControl.addControl(
                `activated_${itemGroup.id}`,
                new UntypedFormControl({
                  value: !itemGroup.archived,
                  disabled: !this.userService.canDo("ITEM_GROUP_ARCHIVE"),
                })
              );
            }
          });
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant(`${this.getTranslationPrefix()}.errors.get-entities`, {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchItemUniverse(): Observable<ItemUniverse[]> {
    return this.itemUniverseService.getAll().pipe(
      tap(
        (itemUniverses: ItemUniverse[]) => {
          this.allItemUniverseList = itemUniverses;
        },
        error => {
          this.sendErrorAlert("item-groups-list.errors.get-item-universes", error.message);
        }
      )
    );
  }

  fetchItems(): Observable<PaginatedList<AbstractItem>> {
    this.activeFilters.push(new SearchFilter("category.type", SearchFilterOperator.EQUAL, CategoryType.STANDARD));
    return this.retailItemService.getAll(this.pager, [], this.activeFilters).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          this.pager = result.page;
          this.retailItemList = result.data;
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  getItemUniverse(itemUniverseId: number): ItemUniverse {
    const localItemUniverse = this.allItemUniverseList.filter(itemUniverse => itemUniverseId === itemUniverse.id)[0];
    if (localItemUniverse === undefined) {
      this.sendErrorAlert("item-groups-list.errors.get-item-universes", `Unknown item category id: ${itemUniverseId}`);
    }

    return localItemUniverse;
  }

  getTranslationPrefix(): string {
    return "item-groups-list";
  }

  onNewEntityClick(): void {
    if (!this.userService.canDo("ITEM_GROUP_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/settings/item-group/add");
  }

  onTableActivate(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("ITEM_GROUP_UPDATE")) {
        return;
      }
      this.router.navigateByUrl(`/settings/item-group/update/${event.row.id}`);
    }
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  protected addRow(itemGroup: ItemGroup): void {
    // filter only the itemlinks with standardItems id
    const standardItemId = this.retailItemList.map(item => item.id);
    const filterItemLinks = itemGroup.itemLinks.filter((itemLink: ItemStoreModuleLink) =>
      standardItemId.includes(itemLink.itemId)
    );

    let localItemUniverseName = null;
    if (itemGroup.universeId !== null && itemGroup.universeId !== undefined) {
      const localItemUniverse = this.getItemUniverse(itemGroup.universeId);
      if (localItemUniverse) {
        localItemUniverseName = localItemUniverse.name;
      }
    }

    this.rows.push({
      id: itemGroup.id,
      name: itemGroup.name,
      universe: localItemUniverseName,
      activated: !itemGroup.archived,
      itemLinks: filterItemLinks,
      storeModules: itemGroup.storeModules,
    });
    this.rows = [...this.rows];
  }

  protected canArchive(): boolean {
    return this.userService.canDo("ITEM_GROUP_ARCHIVE");
  }
}
