<div class="main-container">
  <div class="page-header">
    <div class="return-link">
      <ui-link
        text="{{ this.target + '.buttons.back-button' | translate }}"
        [linkIcon]="faChevronLeft"
        (click)="backToList()"
      ></ui-link>
    </div>
    <div class="title">
      <ui-title titleText="{{ 'import-page.title.' + target | translate }}"></ui-title>
    </div>
  </div>

  <div class="page-content">
    <div class="container">
      <ui-container>
        <div class="container-header">
          <div class="header-left-part">
            <div>
              <span class="step-number">1</span>
            </div>
            <div class="step-text">
              {{ "import-page.template-panel.title." + target | translate }}
            </div>
          </div>

          <div class="header-right-part">
            <div class="step-link">
              <span class="link-icon">
                <fa-icon (click)="getExportFile()" [icon]="faPaperclip" class="icon"></fa-icon>
              </span>

              <div class="link-text">
                <ui-link
                  text="{{ 'import-page.template-panel.template-file.' + target | translate }}"
                  (click)="getExportFile()"
                ></ui-link>
              </div>
            </div>
          </div>
        </div>
      </ui-container>
    </div>

    <div class="container">
      <ui-container>
        <div class="container-header">
          <div class="header-left-part">
            <div>
              <span class="step-number">2</span>
            </div>
            <div class="step-text" *ngIf="!file; else second_step_alt_title">
              {{ "import-page.upload-panel.title" | translate }}

              <span class="info">
                <fa-icon class="icon" [icon]="faInfoCircle"></fa-icon>
                {{ "import-page.upload-panel.warning" | translate }}
              </span>
              <span class="info import-export-warning">
                {{ "import-page.upload-panel.import-export-warning" | translate }}
              </span>
            </div>
          </div>

          <div *ngIf="file" class="header-right-part">
            <div class="step-link">
              <span class="link-icon">
                <fa-icon (click)="downloadFile(file, file.name)" [icon]="faFileExcel" class="icon"></fa-icon>
              </span>

              <div class="link-text reduce-margin" [title]="file.name">
                <ui-link (click)="downloadFile(file, file.name)" [text]="file.name"></ui-link>
              </div>

              <div class="link-delete">
                <fa-icon (click)="deleteFile()" [icon]="faTrashAlt" class="icon"></fa-icon>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!file" class="container-body">
          <form class="content" [formGroup]="importForm">
            <ui-input-file
              id="'file'"
              primary="true"
              accept=".xls, .odt, .xlsx"
              formControlName="importFile"
              buttonText="{{ 'global.input-file.btn' | translate }}"
              descriptionText="{{ 'global.input-file.drag-drop' | translate }}"
            ></ui-input-file>
          </form>
        </div>
      </ui-container>
    </div>

    <div class="container">
      <ui-container>
        <div class="container-body">
          <div class="title">
            {{ "import-page.details.title" | translate }}
          </div>

          <div class="content">
            <ngx-datatable
              #table
              [limit]="15"
              [rows]="rows"
              rowHeight="40"
              [sorts]="sorts"
              class="material"
              footerHeight="50"
              columnMode="flex"
              headerHeight="auto"
              [trackByProp]="'id'"
              [rowClass]="getRowClass"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down'
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
            >
              <ngx-datatable-column
                name="{{ 'import-page.datatable.column.date' | translate }}"
                [flexGrow]="2"
                prop="date"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <span *ngIf="row.date">{{ row.date | dateTimeFormat: true }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'import-page.datatable.column.status' | translate }}"
                [flexGrow]="1"
                prop="status"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ui-status-label
                    label="{{ 'import-page.status.' + row.status | translate }}"
                    [customClass]="getStatusClass(row.status)"
                  ></ui-status-label>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'import-page.datatable.column.document' | translate }}"
                [flexGrow]="2.5"
                prop="file"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ui-link (click)="getImportedFile(row.id)" text="{{ row.file }}"></ui-link>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'import-page.datatable.column.detail' | translate }}"
                [flexGrow]="7.5"
                prop="detail"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div *ngIf="row.status === 'ERROR' && row.errors" class="detail-element error">
                    <div class="ellipsis">
                      <ng-container
                        [ngTemplateOutletContext]="{ errors: row.errors }"
                        [ngTemplateOutlet]="import_errors"
                      ></ng-container>
                    </div>
                    <div class="detail-actions">
                      <div class="green-zone" (click)="$event.stopPropagation()">
                        <ui-menu-actions
                          (actionCalled)="manageActions($event, row)"
                          [menuActions]="menuActions"
                        ></ui-menu-actions>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="row.status === 'RUNNING'" class="detail-element">
                    <ui-progress-bar value="{{ row.progress }}" type="progress" max="100"></ui-progress-bar>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-selectedCount="selectedCount"
                  let-isVisible="isVisible"
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-curPage="curPage"
                  let-offset="offset"
                >
                  <div class="page-count" *ngIf="rowCount > 0">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="table.onFooterPage($event)"
                    [count]="rowCount"
                    [size]="pageSize"
                    [page]="curPage"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>
    </div>
  </div>
</div>

<div class="popup-container" *ngIf="popupVisible">
  <ui-popup title="{{ 'import-page.details.popup' | translate }}" (close)="closeErrorsPopup()">
    <div content>
      <div class="popup-content">
        <ng-container
          [ngTemplateOutletContext]="{ errors: this.errors }"
          [ngTemplateOutlet]="import_errors"
        ></ng-container>
      </div>
    </div>
  </ui-popup>
</div>

<ng-template #second_step_alt_title>
  <div class="step-text">
    {{ "import-page.upload-panel.title" | translate }}
    <span #importInfo class="icon-with-margin">
      <fa-icon [icon]="faInfoCircle" class="info-icon"></fa-icon>
    </span>
  </div>

  <ui-tooltip maxWidth="300px" [element]="importInfo">
    <span style="font-weight: normal !important">
      {{ "import-page.upload-panel.warning" | translate }}
    </span>
  </ui-tooltip>
</ng-template>

<ng-template #import_errors let-errors="errors">
  <span *ngFor="let error of errors" class="error-line">
    <span class="error-item" *ngIf="error.tab">
      {{ "import-page.result-panel.error-tab" | translate }}: {{ error.tab }},
    </span>
    <span class="error-item" *ngIf="error.cell">
      {{ "import-page.result-panel.error-column" | translate }}: {{ error.cell }},
    </span>
    <span class="error-item" *ngIf="error.line">
      {{ "import-page.result-panel.error-line" | translate }}: {{ error.line }}:
    </span>
    <span class="import-fail">{{ error.cause }}</span>
  </span>
</ng-template>
