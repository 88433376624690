import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { BrandCollection, CaraUserService } from "center-services";
import { Filterer, FilterValue, PaginableComponent, SessionPagination, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";

class BrandCollectionRow {
  id: number;
  index: number;
  name: string;
  date: Date;
  activated: boolean;
}

@Component({
  selector: "app-brand-collection-list",
  templateUrl: "./brand-collection-list.component.html",
  styleUrls: ["./brand-collection-list.component.scss"],
  providers: [SubscriptionService],
})
export class BrandCollectionListComponent
  extends FilteredTableListComponent
  implements OnInit, OnChanges, AfterViewChecked, PaginableComponent {
  public static LIST_ID: string = "app-brand-collection-list.collections-table";

  @ViewChild("table") table: DatatableComponent;

  @Input() collections: BrandCollection[];

  @Output() submitEvent: EventEmitter<BrandCollection> = new EventEmitter<BrandCollection>();
  @Output() collectionsChange: EventEmitter<BrandCollection[]> = new EventEmitter<BrandCollection[]>();

  public allRows: BrandCollectionRow[] = [];
  public rows: BrandCollectionRow[] = [];

  public sorts: any[] = [
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "name",
      dir: "asc",
    },
    {
      prop: "date",
      dir: "asc",
    },
  ];
  public pageNumber: number = 0;

  public tableControl: UntypedFormGroup;
  public popupVisible: boolean = false;
  public selectedBrandCollection: BrandCollection;
  public dateFormat: string;
  public filterer: Filterer;
  private selectedIdx: number = null;
  private sessionPagination: SessionPagination;

  constructor(
    private cd: ChangeDetectorRef,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pageNumber;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pageNumber = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
    this.applyFilters();
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(BrandCollectionListComponent.LIST_ID);
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collections && !changes.collections.firstChange) {
      this.fetchBrandCollections();
      this.applyFilters();
    }
  }

  ngOnInit(): void {
    if (!this.tableControl) {
      this.tableControl = new UntypedFormGroup({});
    }

    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.dateFormat = user.dateFormat;
      })
    );
    this.initFilters();
    this.fetchBrandCollections();
    this.sessionPagination.loadFromSession(BrandCollectionListComponent.LIST_ID);
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === BrandCollectionListComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "name",
      this.translateService.instant("brand-collection-list.datatable.columns.name"),
      "string"
    );
    this.filterer.addFilter(
      "date",
      this.translateService.instant("brand-collection-list.datatable.columns.date"),
      "date"
    );
    this.filterer.addBooleanFilter(
      "activated",
      this.translateService.instant("brand-collection-list.datatable.columns.active"),
      false,
      false,
      true
    );
  }

  applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);
    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        BrandCollectionListComponent.LIST_ID
      ).subscribe()
    );
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  fetchBrandCollections(): void {
    this.allRows = [];
    this.rows = [];
    this.collections.forEach(brandCollection => {
      this.addRow(brandCollection);
    });
    this.rows = [...this.allRows];
    if (this.filterer.filterValues.length > 0) {
      this.applyFilters();
    }
  }

  addRow(brandCollection: BrandCollection): void {
    const index = this.collections.indexOf(brandCollection);
    this.allRows.push({
      index,
      id: brandCollection.id,
      name: brandCollection.name,
      date: brandCollection.date,
      activated: !brandCollection.archived,
    });

    // Table formControl management
    if (this.tableControl.controls[`activated_${index}`]) {
      this.tableControl.controls[`activated_${index}`].patchValue(!brandCollection.archived);
    } else {
      this.tableControl.addControl(
        `activated_${index}`,
        new UntypedFormControl({ value: !brandCollection.archived, disabled: false })
      );
    }
  }

  addBrandCollection(): void {
    this.popupVisible = true;
  }

  updateBrandCollection(event: any): void {
    if (!this.userService.canDo("BRAND_UPDATE")) {
      return;
    }

    if (event.type === "click") {
      this.selectedBrandCollection = new BrandCollection(this.collections[event.row.index]);
      this.selectedIdx = event.row.index;
      this.popupVisible = true;
    }
  }

  onValidateBrandCollection(validatedBrandCollection: BrandCollection): void {
    if (this.selectedIdx !== null) {
      this.collections[this.selectedIdx] = validatedBrandCollection;
    } else {
      this.collections.push(validatedBrandCollection);
    }

    this.collections = [...this.collections];
    this.onClosePopup();

    this.collectionsChange.emit(this.collections);
    this.submitEvent.emit(validatedBrandCollection);
  }

  onClosePopup(): void {
    this.popupVisible = false;
    this.selectedBrandCollection = null;
  }

  activeCheckboxOnChanges(index: string | number): void {
    if (!this.userService.canDo("BRAND_UPDATE")) {
      return;
    }

    this.collections[index].archived = !this.tableControl.controls[`activated_${index}`].value;
    this.collections = [...this.collections];
    this.rows = [...this.rows];
    this.collectionsChange.emit(this.collections);
  }
}
