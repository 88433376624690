<ng-container>
  <div class="main-container">
    <div class="header">
      <div class="return-link">
        <ui-link
          text="{{ 'shipment-form.buttons.back-to-list' | translate }}"
          (click)="redirectToList()"
          [linkIcon]="faChevronLeft"
        ></ui-link>
      </div>

      <div class="title">
        <div class="title-text">
          <ui-title titleText="{{ title | translate: { deliveryRef: deliveryRef } }}"></ui-title>
        </div>

        <ui-status-label
          *ngIf="updatedShipmentForm"
          label="{{ 'shipment-form-list.shipment-status-options.' + updatedShipmentForm.shipmentStatus | translate }}"
          [customClass]="getStatusClass()"
        ></ui-status-label>
      </div>
    </div>

    <div class="top-buttons" *ngIf="!readOnly">
      <ui-button
        text="{{ 'shipment-form.buttons.validate' | translate }}"
        [primary]="true"
        (click)="openValidationPopup()"
        type="validate"
      >
      </ui-button>

      <ui-button text="{{ 'global.validate' | translate }}" (click)="subShipmentForm()" type="save"> </ui-button>
    </div>

    <div class="shipment-form-body" *ngIf="isLoaded">
      <app-shipment-form-header
        [shipmentForm]="updatedShipmentForm"
        [(pricingGroupId)]="pricingGroupId"
        [type]="contactType"
        #shipmentFormHeader
        [readonly]="readOnly"
      ></app-shipment-form-header>

      <app-shipment-form-lines
        [receiverId]="updatedShipmentForm.receiverId"
        [senderId]="updatedShipmentForm.senderId"
        [(lines)]="updatedShipmentForm.lines"
        (submitShipmentForm)="subShipmentForm()"
        [pricingGroupId]="pricingGroupId"
        [type]="contactType"
        [readOnly]="readOnly"
        [deliveryRef]="updatedShipmentForm.deliveryRef"
        #shipmentFormLines
      ></app-shipment-form-lines>
    </div>

    <div class="shipment-form-validation-popup">
      <app-shipment-form-validation-popup
        *ngIf="validationPopupVisible"
        (close)="closeValidationPopup()"
        (validate)="submitAndValidateShipmentForm($event)"
      >
      </app-shipment-form-validation-popup>
    </div>
  </div>
</ng-container>
