import { DiscountType, InvoiceCustomerLine, StockType, parseDiscountType, parseStockType } from "center-services";
import { Comparator } from "fugu-common";

export class CreditNoteCustomerLine extends Comparator {
  id: number;
  lineNumber: number;
  quantity: number;
  unitPrice: number;
  sizeValue: string;
  newDeliveryFormId: number;
  invoiceCustomerLineId: number;
  vatRate: number;
  creditNoteLineId: number;

  itemSupplierRef: string;
  itemRef: string;
  itemName: string;
  unitName: string;
  stockType: StockType;
  discountType: DiscountType;
  brandName: string;
  supplierName: string;
  supplierRef: string;
  newDeliveryLineId: number;
  stockEntryIds: string;
  discount: number;
  weight: number;
  tare: number;
  totalPrice: number;
  metalWeight: number;
  storeName: string;
  storeRef: string;
  store: string;
  deliveryRef: string;

  quantityUnit: string;
  totalGrossPrice: number;
  serialNumber: string;
  batchNumber: string;
  actionCheckboxChecked: boolean = false;

  constructor(values?: InvoiceCustomerLine | CreditNoteCustomerLine) {
    super();
    if (!values) {
      return;
    }

    this.id = values.id;
    this.invoiceCustomerLineId = values.invoiceCustomerLineId;
    this.lineNumber = values.lineNumber;
    this.quantity = values.quantity;
    this.quantityError = values.quantityError;
    this.unitPrice = values.unitPrice;
    this.unitPriceError = values.unitPriceError;
    this.sizeValue = values.sizeValue;
    this.newDeliveryFormId = values.newDeliveryFormId;
    this.vatRate = values.vatRate;
    this.creditNoteLineId = values.creditNoteLineId;

    this.itemSupplierRef = values.itemSupplierRef;
    this.itemRef = values.itemRef;
    this.itemName = values.itemName;
    this.unitName = values.unitName;
    this.stockType = parseStockType(values.stockType);
    this.discountType = parseDiscountType(values.discountType);
    this.brandName = values.brandName;
    this.supplierName = values.supplierName;
    this.supplierRef = values.supplierRef;
    this.newDeliveryLineId = values.newDeliveryLineId;
    this.stockEntryIds = values.stockEntryIds;
    this.serialNumber = StockType.SERIAL_NUMBER === this.stockType ? values?.stockEntryIds?.toString() : null;
    this.batchNumber = StockType.BATCH === this.stockType ? values?.stockEntryIds?.toString() : null;
    this.discount = values.discount;
    this.weight = values.weight;
    this.tare = values.tare;
    this.totalPrice = values.totalPrice;
    this.metalWeight = values.metalWeight;
    this.storeName = values.storeName;
    this.storeRef = values.storeRef;
    if (this.storeName && this.storeRef) {
      this.store = `${this.storeName} ${this.storeRef}`;
    }
    this.deliveryRef = values.deliveryRef;

    this.quantityUnit = values.quantityUnit;
    this.totalGrossPrice = values.totalGrossPrice;
    this.actionCheckboxChecked = values.actionCheckboxChecked;
  }

  protected compareAttribute(attrName: string): boolean {
    return attrName !== "actionCheckboxChecked";
  }
}
