<form class="brand-collection-form" [formGroup]="popupForm" novalidate>
  <div class="popup-container">
    <ui-popup title="{{ popupTitle }}" (close)="closePopup()">
      <div content>
        <ui-input-text
          type="text"
          label="{{ 'brand-collection-list.datatable.columns.name' | translate }}"
          formControlName="name"
          [status]="popupForm.get('name') | formStatus"
          [errorMessage]="popupForm.get('name') | formErrorMessage | translate"
          mandatory="true"
        ></ui-input-text>

        <ui-date-picker
          label="{{ 'brand-collection-list.datatable.columns.date' | translate }}"
          [locale]="locale"
          [format]="dateFormat"
          formControlName="date"
        >
        </ui-date-picker>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'global.validate' | translate }}"
                type="save"
                [primary]="true"
                (click)="submitBrandCollection()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</form>
