<ng-container>
  <div class="main-container frozen-right border-bottom-filters">
    <div class="filters border-bottom-filters" *ngIf="filterer">
      <ui-dynamic-filters
        label="{{ 'filters.label' | translate }}"
        [filters]="filterer.getFilters()"
        [(filterValues)]="filterer.filterValues"
        (apply)="applyFilters()"
        minLabel="{{ 'filters.min' | translate }}"
        maxLabel="{{ 'filters.max' | translate }}"
        applyLabel="{{ 'filters.apply' | translate }}"
        deleteLabel="{{ 'filters.delete' | translate }}"
        placeholderPrefix="{{ 'filters.prefix' | translate }} "
      >
      </ui-dynamic-filters>
    </div>
    <div [formGroup]="tableControl">
      <div class="top-table-buttons">
        <ui-button
          text="{{ 'purchase-order.lines.datatable.add-btn-text' | translate }}"
          type="add"
          (click)="addPurchaseModality()"
        >
        </ui-button>
      </div>
      <ngx-datatable
        #table
        [rows]="rows"
        [trackByProp]="'index'"
        class="material vertical-scroll"
        columnMode="force"
        headerHeight="auto"
        [footerHeight]="50"
        [rowHeight]="40"
        [scrollbarH]="true"
        [rowClass]="getRowClass"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        [count]="pager.totalElements"
        [offset]="pager.number"
        [limit]="pager.size"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down'
        }"
        [sorts]="sorts"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
      >
        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.line-number' | translate }}"
          prop="lineNumber"
          cellClass="line-number"
          [resizeable]="false"
          width="100"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div>{{ value }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.store-name' | translate }}"
          prop="storeName"
          cellClass="store-name"
          [resizeable]="false"
          [sortable]="false"
          width="250"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-number="row.lineNumber">
            <ui-list
              class="input-fields"
              [options]="storeOptions"
              [search]="true"
              [formControl]="tableControl.get(number + '.deliveryStore')"
              [status]="tableControl.get(number + '.deliveryStore') | formStatus"
              errorMessage="{{ tableControl.get(number + '.deliveryStore') | formErrorMessage | translate }}"
              sizeMode="small"
            ></ui-list>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.item-reference' | translate }}"
          prop="itemReference"
          cellClass="item-reference"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div>{{ value }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.supplier-ref' | translate }}"
          prop="supplierRef"
          cellClass="supplier-ref"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div>{{ value }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.item-name' | translate }}"
          prop="itemName"
          cellClass="item-name"
          [resizeable]="false"
          width="175"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div class="ellipsis item-name-cell" #itemName>{{ value }}</div>
            <ui-tooltip [element]="itemName" maxWidth="300px">
              <span style="font-weight: normal !important; white-space: break-spaces">
                {{ value }}
              </span>
            </ui-tooltip>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.brand-name' | translate }}"
          prop="brandName"
          cellClass="brand-name"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div>{{ value }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.size-value' | translate }}"
          prop="sizeValue"
          cellClass="size-value"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-number="row.lineNumber">
            <ui-list
              *ngIf="tableControl.get(number + '.sizeValue')"
              class="input-fields fix-width"
              [options]="sizeValueOptions[number]"
              [formControl]="tableControl.get(number + '.sizeValue')"
              [status]="tableControl.get(number + '.sizeValue') | formStatus"
              errorMessage="{{ tableControl.get(number + '.sizeValue') | formErrorMessage | translate }}"
              sizeMode="small"
            ></ui-list>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.quantity' | translate }}"
          prop="quantity"
          cellClass="quantity center-align"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-number="row.lineNumber">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              class="input-fields"
              [status]="tableControl.get(number + '.quantity') | formStatus"
              errorMessage="{{
                tableControl.get(number + '.quantity')
                  | formErrorMessage
                    : { customPrefix: 'purchase-order.lines.datatable.errors.', useCustomPrefixFor: ['outOfRange'] }
                  | translate: { digit: HIGH_INTEGER }
              }}"
              [formControl]="tableControl.get(number + '.quantity')"
              sizeMode="small"
            >
            </ui-input-text>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.purchase-unit' | translate }}"
          prop="purchaseUnitName"
          cellClass="purchase-unit"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div>{{ value }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.min-quantity' | translate }}"
          prop="minQuantity"
          cellClass="min-quantity"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-id="row.purchaseModalityId"
            let-number="row.lineNumber"
          >
            <ui-input-text
              *ngIf="displayQuantityRange(id)"
              type="text"
              [maskPattern]="decimalDigit"
              class="input-fields"
              [status]="tableControl.get(number + '.minQuantity') | formStatus"
              errorMessage="{{
                tableControl.get(number + '.minQuantity') | formErrorMessage | translate: { digit: HIGH_INTEGER }
              }}"
              [formControl]="tableControl.get(number + '.minQuantity')"
              sizeMode="small"
            >
            </ui-input-text>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.max-quantity' | translate }}"
          prop="maxQuantity"
          cellClass="max-quantity"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-id="row.purchaseModalityId"
            let-number="row.lineNumber"
          >
            <ui-input-text
              *ngIf="displayQuantityRange(id)"
              type="text"
              [maskPattern]="decimalDigit"
              class="input-fields"
              [status]="tableControl.get(number + '.maxQuantity') | formStatus"
              [errorMessage]="
                tableControl.get(number + '.maxQuantity')
                  | formErrorMessage
                    : { customPrefix: 'purchase-order.lines.datatable.errors.', useCustomPrefixFor: ['badMax'] }
                  | translate: { digit: HIGH_INTEGER }
              "
              [formControl]="tableControl.get(number + '.maxQuantity')"
              sizeMode="small"
            >
            </ui-input-text>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.unit-price' | translate }}"
          prop="unitPrice"
          cellClass="unit-price"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-number="row.lineNumber">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              [thousandSeparator]="' '"
              class="input-fields"
              [status]="tableControl.get(number + '.unitPrice') | formStatus"
              [errorMessage]="
                tableControl.get(number + '.unitPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }
              "
              [formControl]="tableControl.get(number + '.unitPrice')"
              [suffix]="' ' + purchaseOrderCurrency.symbol"
              sizeMode="small"
            >
            </ui-input-text>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.unit-price-per-weight' | translate }}"
          prop="unitPricePerWeight"
          cellClass="unit-price-per-weight"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-number="row.lineNumber">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              [thousandSeparator]="' '"
              class="input-fields"
              status="{{ tableControl.get(number + '.unitPricePerWeight') | formStatus }}"
              errorMessage="{{
                tableControl.get(number + '.unitPricePerWeight') | formErrorMessage | translate: { digit: HIGH_INTEGER }
              }}"
              [formControl]="tableControl.get(number + '.unitPricePerWeight')"
              [suffix]="' ' + purchaseOrderCurrency.symbol"
              sizeMode="small"
            >
            </ui-input-text>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.metal-price' | translate }}"
          prop="metalPrice"
          cellClass="metal-price"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-number="row.lineNumber">
            <ui-input-text
              *ngIf="row.purchaseType === 'WITH_METAL_PRICE'"
              type="text"
              [maskPattern]="decimalDigit"
              [thousandSeparator]="' '"
              class="input-fields"
              [status]="tableControl.get(number + '.metalPrice') | formStatus"
              errorMessage="{{
                tableControl.get(number + '.metalPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }
              }}"
              [formControl]="tableControl.get(number + '.metalPrice')"
              [suffix]="' ' + purchaseOrderCurrency.symbol"
              sizeMode="small"
            >
            </ui-input-text>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.unit-price-without-tax' | translate }}"
          prop="unitPriceWithoutTax"
          cellClass="unit-price-without-tax"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div>
              {{ value | formatCurrency: purchaseOrderCurrency.codeISO:"symbol":"0.2-5":locale }}
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.percent-discount' | translate }}"
          prop="percentDiscount"
          cellClass="percent-discount"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-number="row.lineNumber">
            <ui-input-text
              type="money"
              [thousandSeparator]="' '"
              class="input-fields"
              [status]="tableControl.get(number + '.percentDiscount') | formStatus"
              errorMessage="{{
                tableControl.get(number + '.percentDiscount')
                  | formErrorMessage: { customPrefix: 'purchase-order.lines.datatable.errors.' }
                  | translate
              }}"
              [formControl]="tableControl.get(number + '.percentDiscount')"
              [suffix]="' %'"
              sizeMode="small"
            >
            </ui-input-text>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.discount' | translate }}"
          prop="discount"
          cellClass="discount"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div>- {{ value | formatCurrency: purchaseOrderCurrency.codeISO:"symbol":"0.2-2":locale }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.total-gross-price' | translate }}"
          prop="totalGrossPrice"
          cellClass="total-gross-price"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div>
              {{ value | formatCurrency: purchaseOrderCurrency.codeISO:"symbol":"0.2-2":locale }}
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.delivery-date' | translate }}"
          prop="deliveryDate"
          cellClass="delivery-date"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-number="row.lineNumber">
            <ui-date-picker
              [locale]="locale"
              [format]="dateFormat"
              [formControl]="tableControl.get(number + '.deliveryDate')"
              class="input-fields"
              status="{{ tableControl.get(number + '.deliveryDate') | formStatus }}"
              errorMessage="{{ tableControl.get(number + '.deliveryDate') | formErrorMessage | translate }}"
              sizeMode="small"
            >
            </ui-date-picker>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.engraving' | translate }}"
          prop="engraving"
          headerClass="center-align"
          cellClass="engraving center-align"
          [resizeable]="false"
        >
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-number="row.lineNumber"
            let-engravingLength="row.engravingLength"
          >
            <ui-checkbox
              *ngIf="engravingLength"
              class="input-fields"
              (change)="onCheckboxOnChanges(number)"
              [formControl]="tableControl.get(number + '.engraving')"
            >
            </ui-checkbox>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.comment' | translate }}"
          prop="comment"
          headerClass="center-align"
          cellClass="comment center-align"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-number="row.lineNumber" let-value="value">
            <div class="comment-icon" *ngIf="!displayComment(number)">
              <fa-icon (click)="openEngravingPopup(number)" [icon]="faPen"></fa-icon>
            </div>

            <div *ngIf="displayComment(number)">
              <div #commentText class="ellipsis-with-icon" (click)="openEngravingPopup(number)">
                <p class="align-left">{{ getCommentToDisplay(number) }}</p>
                <fa-icon (click)="openEngravingPopup(number)" [icon]="faPen" class="align-right"></fa-icon>
              </div>
              <ui-tooltip maxWidth="300px" [element]="commentText">
                <span style="font-weight: normal !important; white-space: pre-wrap !important">{{
                  getTooltipToDisplay(number)
                }}</span>
              </ui-tooltip>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'purchase-order.lines.datatable.columns.actions' | translate }}"
          headerClass="center-align"
          cellClass="center-align frozen-style"
          [resizeable]="false"
          [frozenRight]="true"
          [sortable]="false"
          [width]="100"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)"> </ui-menu-actions>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="rowCount">
              {{
                rowCount
                  | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                  | translate: { rowCount: rowCount }
              }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="changePage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>

  <!-- engraving popup -->
  <app-engraving-popup
    *ngIf="engravingPopupVisible"
    [purchaseOrderLine]="selectedPurchaseOrderLine"
    [maxEngravingLength]="selectedMaxEngravingLength"
    (validate)="submitEngravingPopup($event)"
    (close)="closeEngravingPopup()"
  ></app-engraving-popup>

  <!-- selection popup -->
  <app-purchase-modality-selection
    #purchaseModalitySelection
    *ngIf="selectionPopupVisible"
    [currencyId]="editedPurchaseOrder.currencyId"
    [supplierId]="editedPurchaseOrder.supplierId"
    (selectedPurchaseModalities)="submitSelectionPopup($event)"
    (close)="closeSelectionPopup()"
  ></app-purchase-modality-selection>

  <!-- duplicate popup -->
  <app-po-line-duplication-popup
    *ngIf="duplicationPopupVisible"
    [storeList]="this.storeList"
    [sizeValueList]="this.popInSizeValueOptions"
    [purchaseOrderLine]="this.duplicatePOL"
    (validate)="submitDuplicationPopup($event)"
    (close)="closeDuplicationPopup()"
  ></app-po-line-duplication-popup>
</ng-container>
