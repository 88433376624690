<div class="popup-container">
  <ui-popup [title]="getPopupTitle()" (close)="closePopup()">
    <div content>
      <div class="recap-fields-container">
        <div class="recap-container">
          <div class="recap-title">{{ "create-stock-movement-popup.recap.title" | translate }}</div>

          <section class="recap-items">
            <section *ngIf="stockEntryLocation?.locationName" class="location-name">
              <ui-info-field
                [label]="'create-stock-movement-popup.recap.labels.origin-location' | translate"
                [value]="stockEntryLocation.locationName"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>

            <section class="stock-entry-id">
              <ui-info-field
                [label]="'create-stock-movement-popup.recap.labels.serialNumber-batchNumber' | translate"
                [value]="stockEntryLocation.stockEntry.id"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>

            <section *ngIf="stockEntrySupplier" class="supplier-name-reference">
              <ui-info-field
                [label]="'create-stock-movement-popup.recap.labels.supplier-ref' | translate"
                [value]="stockEntryLocation.stockEntry.supplierName + ' ' + stockEntrySupplier.reference"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>

            <section *ngIf="stockEntryLocation?.stockEntry?.itemBrandName" class="item-brand-name">
              <ui-info-field
                [label]="'create-stock-movement-popup.recap.labels.brand' | translate"
                [value]="stockEntryLocation.stockEntry.itemBrandName"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>

            <section *ngIf="stockEntryLocation?.quantity !== null" class="quantity-unit">
              <ui-info-field
                [label]="'create-stock-movement-popup.recap.labels.quantity' | translate"
                [value]="stockEntryLocation.quantity + ' ' + unit?.long"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>

            <section *ngIf="stockEntryLocation?.weight !== null" class="out-weight">
              <ui-info-field
                [label]="'create-stock-movement-popup.recap.labels.total-weight' | translate"
                [value]="stockEntryLocation.weight + ' g'"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>

            <section *ngIf="stockEntryLocation?.tare !== null" class="out-tare">
              <ui-info-field
                [label]="'create-stock-movement-popup.recap.labels.tare' | translate"
                [value]="stockEntryLocation.tare + ' g'"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>
          </section>
        </div>

        <div class="fields-container">
          <form class="manual-stock-movement-form" [formGroup]="popupForm" novalidate>
            <div class="grid-item dest-location">
              <ui-list
                [label]="'create-stock-movement-popup.fields.dest-location' | translate"
                [options]="stockLocationsOptions"
                [search]="true"
                [mandatory]="true"
                [formControlName]="'destLocation'"
                [status]="popupForm.get('destLocation') | formStatus"
                [errorMessage]="popupForm.get('destLocation') | formErrorMessage | translate"
              >
              </ui-list>
            </div>

            <div class="grid-item quantity">
              <ui-input-text
                [type]="'text'"
                [label]="'create-stock-movement-popup.fields.quantity' | translate"
                [mandatory]="true"
                [maskPattern]="decimalDigitHigh"
                [formControlName]="'quantity'"
                [status]="popupForm.get('quantity') | formStatus"
                [errorMessage]="
                  popupForm.get('quantity')
                    | formErrorMessage
                      : {
                          customPrefix: 'create-stock-movement-popup.errors.quantity.',
                          useCustomPrefixFor: ['max', 'triple']
                        }
                    | translate: { digit: HIGH_INTEGER }
                "
              >
              </ui-input-text>
              <div class="unit-label" *ngIf="unit">
                <label>{{ unit?.long }}</label>
              </div>
            </div>

            <div *ngIf="stockEntryLocation?.weight" class="grid-item prorata">
              <ui-button
                text="{{ 'create-stock-movement-popup.fields.prorata' | translate }}"
                [btnIcon]="faCalculate"
                (click)="computeProrata()"
              ></ui-button>
            </div>

            <div *ngIf="stockEntryLocation?.weight" class="grid-item weight">
              <ui-input-text
                [type]="'text'"
                label="{{ 'create-stock-movement-popup.fields.total-weight' | translate }} (g)"
                suffix=" g"
                [mandatory]="true"
                [maskPattern]="decimalDigitHigh"
                [formControlName]="'weight'"
                [status]="popupForm.get('weight') | formStatus"
                [errorMessage]="
                  popupForm.get('weight')
                    | formErrorMessage
                      : {
                          customPrefix: 'create-stock-movement-popup.errors.weight.',
                          useCustomPrefixFor: ['min', 'max', 'triple', 'remainingTareTooBig']
                        }
                    | translate: { digit: HIGH_INTEGER }
                "
              >
              </ui-input-text>
            </div>

            <div *ngIf="stockEntryLocation?.tare" class="grid-item tare">
              <ui-input-text
                [type]="'text'"
                label="{{ 'create-stock-movement-popup.fields.tare' | translate }} (g)"
                suffix=" g"
                [mandatory]="true"
                [maskPattern]="decimalDigitHigh"
                [formControlName]="'tare'"
                [status]="popupForm.get('tare') | formStatus"
                [errorMessage]="
                  popupForm.get('tare')
                    | formErrorMessage
                      : {
                          customPrefix: 'create-stock-movement-popup.errors.tare.',
                          useCustomPrefixFor: ['min', 'max', 'triple', 'surpassWeight', 'remainingTareTooBig']
                        }
                    | translate: { digit: HIGH_INTEGER }
                "
              >
              </ui-input-text>
            </div>

            <div class="grid-item comment">
              <ui-textarea
                [label]="'create-stock-movement-popup.fields.comment' | translate"
                [mandatory]="true"
                [formControlName]="'comment'"
                [status]="popupForm.get('comment') | formStatus"
                [errorMessage]="popupForm.get('comment') | formErrorMessage | translate"
              >
              </ui-textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              text="{{ 'create-stock-movement-popup.validate' | translate }}"
              customClass="fugu-button-save"
              [primary]="true"
              (click)="submitPopup()"
              [btnIcon]="faCheck"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
