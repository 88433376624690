<ng-container>
  <div class="{{ readOnly ? 'main-container' : 'main-container frozen-left frozen-right' }}">
    <ui-container>
      <div [formGroup]="scanForm" class="scan-field" *ngIf="!readOnly">
        <ui-input-text
          errorMessage="{{
            scanForm.get('stockEntryId') | formErrorMessage: { customPrefix: 'shipment-form.errors.' } | translate
          }}"
          [label]="'shipment-form.lines.fields.unique-number' | translate"
          [status]="scanForm.get('stockEntryId') | formStatus"
          formControlName="stockEntryId"
          [icon]="faScannerGun"
          #stockEntryIdElement
          class="scan-input"
          [type]="'text'"
        ></ui-input-text>

        <ui-button
          [text]="'shipment-form.buttons.scan-validate' | translate"
          (click)="addShipmentLine()"
          class="scan-button"
          type="validate"
        ></ui-button>
        <div class="or-separator or-separator-position">
          {{ "global.or" | translate }}
        </div>
        <div class="add-button-position">
          <ui-button
            text="{{ 'shipment-stock-entry-selection.title' | translate }}"
            type="add"
            [primary]="false"
            (click)="openShipmentStockEntrySelectionPopup()"
          ></ui-button>
        </div>
      </div>

      <div class="filters-field" *ngIf="filterer">
        <ui-dynamic-filters
          #stockFilters
          (apply)="applyFilters()"
          dateFormat="{{ dateFormat }}"
          [filters]="filterer.getFilters()"
          [label]="'filters.label' | translate"
          [(filterValues)]="filterer.filterValues"
          minLabel="{{ 'filters.min' | translate }}"
          maxLabel="{{ 'filters.max' | translate }}"
          applyLabel="{{ 'filters.apply' | translate }}"
          deleteLabel="{{ 'filters.delete' | translate }}"
          placeholderPrefix="{{ 'filters.prefix' | translate }} "
        >
        </ui-dynamic-filters>
      </div>

      <div class="headband" *ngIf="!readOnly">
        <div class="selected-count">
          <span
            [innerHTML]="
              selectedRows.length === 0
                ? ('shipment-form.datatable.no-items' | translate)
                : (selectedRows.length
                  | i18nPlural
                    : { '=1': 'shipment-form.datatable.n-items', other: 'shipment-form.datatable.n-items_plural' }
                  | translate: { lines: selectedRows.length })
            "
          >
          </span>
        </div>

        <div class="buttons">
          <ui-button
            text="{{ 'shipment-form.buttons.delete' | translate }}"
            [disabled]="!selectedRows.length"
            (click)="removeLines()"
            type="delete"
          >
          </ui-button>
        </div>
      </div>

      <div class="separator" *ngIf="!readOnly">
        <div class="pointer"><div class="arrow"></div></div>
        <div class="line"></div>
      </div>

      <ngx-datatable
        #table
        [rows]="rows"
        [sorts]="sorts"
        [rowHeight]="40"
        columnMode="force"
        [footerHeight]="50"
        headerHeight="auto"
        [scrollbarH]="true"
        [summaryRow]="true"
        [summaryHeight]="45"
        [rowClass]="getRowClass"
        [trackByProp]="'lineNumber'"
        [summaryPosition]="'bottom'"
        class="material vertical-scroll"
        [count]="pager.totalElements"
        [offset]="pager.number"
        [limit]="pager.size"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down'
        }"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
      >
        <ngx-datatable-column
          *ngIf="!readOnly"
          cellClass="center-align frozen-style"
          headerClass="center-align"
          [canAutoResize]="false"
          [resizeable]="false"
          [frozenLeft]="true"
          [draggable]="false"
          [sortable]="false"
          [width]="50"
        >
          <ng-template ngx-datatable-header-template>
            <div class="green-zone centered" (click)="$event.stopPropagation()">
              <ui-checkbox
                [formControl]="selectionForm.get('header')"
                (change)="onHeaderCheckboxChange()"
                customClass="no-margin"
              ></ui-checkbox>
            </div>
          </ng-template>

          <ng-template ngx-datatable-cell-template let-id="row.id">
            <div class="green-zone centered" (click)="$event.stopPropagation()">
              <ui-checkbox
                [formControl]="selectionForm.get(id)"
                (change)="onRowCheckboxChange()"
                customClass="no-margin"
              ></ui-checkbox>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ng-container>
          <ngx-datatable-column
            cellClass="center-align frozen-style"
            [sortable]="false"
            [resizeable]="false"
            [draggable]="false"
            width="37"
            [frozenLeft]="true"
            [canAutoResize]="false"
            *ngIf="!readOnly"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-shipmentRefs="row.shipmentRefs">
              <ng-container *ngIf="(row.stockDepleted && row.serialNumber) || row.noSEL">
                <div #errorIcon>
                  <fa-icon class="error-icon" [icon]="faError"></fa-icon>

                  <ui-tooltip maxWidth="200px" [element]="errorIcon" customClass="error">
                    <span style="font-weight: normal !important">
                      {{ "shipment-form.errors.stock-depleted" | translate }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-container>

              <ng-container *ngIf="shipmentRefs ? shipmentRefs.length > 0 && !row.stockDepleted : false">
                <div #warnIcon>
                  <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>

                  <ui-tooltip maxWidth="125px" [element]="warnIcon">
                    <span style="font-weight: normal !important">
                      {{
                        "shipment-stock-entry-selection.datatable.warning.quantity"
                          | translate: { shipmentRefs: shipmentRefs }
                      }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-container>
            </ng-template>
          </ngx-datatable-column>
        </ng-container>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.line-number' | translate"
          cellClass="{{ readOnly ? '' : 'frozen-style' }}"
          [summaryTemplate]="totalSummary"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          [frozenLeft]="!readOnly"
          prop="lineNumber"
          [width]="95"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.item-supplier-ref' | translate"
          [canAutoResize]="false"
          prop="itemSupplierRef"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          [width]="165"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.supplier-name' | translate"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="supplierName"
          [width]="145"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.serial-number' | translate"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="serialNumber"
          [width]="95"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.batch-number' | translate"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="batchNumber"
          [width]="95"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.quantity' | translate"
          [summaryTemplate]="quantitySummary"
          [sortable]="!isEditable"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="quantity"
          [width]="205"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div class="quantity-template" [class.with-fields]="rowsForm.get(row.id)">
              <div class="quantity-value" *ngIf="!rowsForm.get(row.id); else quantity_field">
                {{ value === "" ? ("shipment-form.header.fields.undefined" | translate) : value }}
              </div>
              <ng-template #quantity_field>
                <div class="quantity-value">
                  <ui-input-text
                    errorMessage="{{
                      rowsForm.get(row.id + '.quantity')
                        | formErrorMessage: { customPrefix: 'shipment-form.errors.' }
                        | translate: { max: row.maxQuantity }
                    }}"
                    [status]="rowsForm.get(row.id + '.quantity') | formStatus"
                    [formControl]="rowsForm.get(row.id + '.quantity')"
                    [maskPattern]="decimalDigit"
                    sizeMode="small"
                  ></ui-input-text>
                </div>
              </ng-template>

              <ng-container *ngIf="rowsForm.get(row.id) || value !== ''">
                <div class="quantity-unit ellipsis" #ellipsis>
                  {{ row.purchaseUnit }}
                </div>
                <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                  <span style="font-weight: normal !important; white-space: break-spaces; padding-top: 0">
                    {{ row.purchaseUnit }}
                  </span>
                </ui-tooltip>
              </ng-container>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.size-value' | translate"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="sizeValue"
          [width]="115"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.metal-weight' | translate"
          [sortable]="!isEditable"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="metalWeight"
          [width]="130"
        >
          <ng-template ngx-datatable-cell-template let-id="row.id" let-value="value">
            <div *ngIf="!rowsForm.get(id); else metal_weight_field">
              {{ value === 0 ? "" : value + " g" }}
            </div>

            <ng-template #metal_weight_field>
              <ui-input-text
                *ngIf="value"
                errorMessage="{{
                  rowsForm.get(id + '.metalWeight')
                    | formErrorMessage: { customPrefix: 'shipment-form.errors.' }
                    | translate
                }}"
                [status]="rowsForm.get(id + '.metalWeight') | formStatus"
                [formControl]="rowsForm.get(id + '.metalWeight')"
                [maskPattern]="decimalDigit"
                [thousandSeparator]="' '"
                class="input-fields"
                sizeMode="small"
                suffix=" g"
              ></ui-input-text>
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [canAutoResize]="false"
          [resizeable]="false"
          headerClass="narrow"
          [draggable]="false"
          [sortable]="false"
          width="23"
        >
          <ng-template ngx-datatable-header-template>
            <div #info><fa-icon [icon]="faInfo" class="info-icon"></fa-icon></div>

            <ui-tooltip maxWidth="300px" [element]="info">
              {{ "shipment-form.datatable.info.real-metal-weight-info" | translate }}
            </ui-tooltip>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.tare' | translate"
          [sortable]="!isEditable"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          [width]="130"
          prop="tare"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div *ngIf="!rowsForm.get(row.id); else tare_field">
              {{ value === 0 ? "" : value + " g" }}
            </div>

            <ng-template #tare_field>
              <ui-input-text
                *ngIf="value"
                errorMessage="{{
                  rowsForm.get(row.id + '.tare')
                    | formErrorMessage: { customPrefix: 'shipment-form.errors.' }
                    | translate: { max: row.maxTare }
                }}"
                [status]="rowsForm.get(row.id + '.tare') | formStatus"
                [formControl]="rowsForm.get(row.id + '.tare')"
                [maskPattern]="decimalDigit"
                [thousandSeparator]="' '"
                class="input-fields"
                sizeMode="small"
                suffix=" g"
              ></ui-input-text>
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.total-weight' | translate"
          [sortable]="!isEditable"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="weight"
          [width]="130"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div *ngIf="!rowsForm.get(row.id); else total_weight_field">
              {{ value === 0 ? "" : value + " g" }}
            </div>

            <ng-template #total_weight_field>
              <ui-input-text
                *ngIf="value"
                errorMessage="{{
                  rowsForm.get(row.id + '.weight')
                    | formErrorMessage: { customPrefix: 'shipment-form.errors.' }
                    | translate: { max: row.maxWeight }
                }}"
                [status]="rowsForm.get(row.id + '.weight') | formStatus"
                [formControl]="rowsForm.get(row.id + '.weight')"
                [maskPattern]="decimalDigit"
                [thousandSeparator]="' '"
                class="input-fields"
                sizeMode="small"
                suffix=" g"
              ></ui-input-text>
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.unit-price' | translate"
          [canAutoResize]="false"
          *ngIf="canReadPrices"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="unitPrice"
          [width]="115"
        >
          <ng-template ngx-datatable-cell-template let-unitPrice="row.unitPrice">
            <span *ngIf="unitPrice !== null && unitPrice !== undefined">
              {{ unitPrice | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-5":locale }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.total-price' | translate"
          [summaryTemplate]="priceSummary"
          [canAutoResize]="false"
          *ngIf="canReadPrices"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="totalPrice"
          [width]="145"
        >
          <ng-template ngx-datatable-cell-template let-totalPrice="row.totalPrice">
            <span *ngIf="totalPrice !== null && totalPrice !== undefined">
              {{ totalPrice | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-2":locale }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{
            readOnly
              ? ('shipment-form.datatable.columns.origin-location' | translate)
              : ('shipment-form.datatable.columns.location' | translate)
          }}"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="location"
          [width]="145"
        >
          <ng-template ngx-datatable-cell-template let-value="value">
            <div #ellipsis class="ellipsis">
              {{ value }}

              <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                <span style="font-weight: normal !important; white-space: break-spaces">
                  {{ value }}
                </span>
              </ui-tooltip>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.item-ref' | translate"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="itemRef"
          [width]="145"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.item-name' | translate"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="itemName"
          [width]="200"
        >
          <ng-template ngx-datatable-cell-template let-value="value">
            <div #ellipsis class="ellipsis">
              {{ value }}

              <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                <span style="font-weight: normal !important; white-space: break-spaces">
                  {{ value }}
                </span>
              </ui-tooltip>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.brand-name' | translate"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="brandName"
          [width]="145"
        >
          <ng-template ngx-datatable-cell-template let-value="value">
            <div #ellipsis class="ellipsis">
              {{ value }}

              <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                <span style="font-weight: normal !important; white-space: break-spaces">
                  {{ value }}
                </span>
              </ui-tooltip>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'shipment-form.datatable.columns.supplier-ref' | translate"
          [canAutoResize]="false"
          [summaryFunc]="null"
          [resizeable]="false"
          [draggable]="false"
          prop="supplierRef"
          [width]="165"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          *ngIf="!readOnly"
          [name]="'shipment-form.datatable.columns.actions' | translate"
          cellClass="center-align frozen-style"
          headerClass="center-align"
          [resizeable]="false"
          [frozenRight]="true"
          [sortable]="false"
          [width]="80"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="green-zone centered" (click)="$event.stopPropagation()">
              <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)">
              </ui-menu-actions>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            let-selectedCount="selectedCount"
            ngx-datatable-footer-template
            let-isVisible="isVisible"
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-curPage="curPage"
            let-offset="offset"
          >
            <div class="page-count" *ngIf="rowCount">
              {{
                rowCount
                  | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                  | translate: { rowCount: rowCount }
              }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="changePage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>

      <ng-template #totalSummary>
        <div class="total-summary summary-fields">
          {{ "shipment-form.datatable.summary.total" | translate }}
        </div>
      </ng-template>

      <ng-template #priceSummary let-row="row" let-value="value">
        <div class="total-price summary-fields">
          {{ getTotalPrice() | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-2":locale }}
        </div>
      </ng-template>

      <ng-template #quantitySummary let-row="row" let-value="value">
        <div class="total-quantity summary-fields">
          <div class="quantity-value">
            {{ getTotalQuantity() }}
          </div>

          <div class="quantity-unit" *ngIf="!hasSameQuantityUnit(); else quantity_unit" #warnIcon>
            <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>

            <ui-tooltip maxWidth="125px" [element]="warnIcon">
              <span style="font-weight: normal !important">
                {{ "shipment-form.warning.quantity-unit" | translate }}
              </span>
            </ui-tooltip>
          </div>

          <ng-template #quantity_unit>
            <div class="quantity-unit ellipsis" #ellipsis>
              {{ this.rows[0].purchaseUnit }}
            </div>
            <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
              <span style="font-weight: normal !important; white-space: break-spaces; padding-top: 0">
                {{ this.rows[0].purchaseUnit }}
              </span>
            </ui-tooltip>
          </ng-template>
        </div>
      </ng-template>
    </ui-container>
  </div>
  <app-multiple-location-selection-popup
    *ngIf="multipleLocationSelectionPopupVisible"
    [stockEntryLocations]="stockEntryLocations"
    (close)="closeMultipleLocationSelectionPopup()"
    (stockEntryLocationSelectionOutputE)="setSelectedLocation($event)"
  ></app-multiple-location-selection-popup>

  <app-shipment-stock-entry-selection-popup
    *ngIf="shipmentStockEntrySelectionPopupVisible"
    [senderId]="senderId"
    [disabledLines]="lines"
    [orderCurrency]="defaultCurrency"
    (close)="closeShipmentStockEntrySelectionPopup()"
    (selectedStockEntryLocation)="submitShipmentStockEntrySelectionPopup($event)"
  ></app-shipment-stock-entry-selection-popup>
</ng-container>
