import { RoundingUtil } from "fugu-common";
import { HttpErrorResponse } from "@angular/common/http";
/* tslint:disable */

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  Metal,
  Alloy,
  Uom,
  UomService,
  MetalService,
  AlloyService,
  PurchaseModality,
  TheoreticalMetalWeight,
  PurchaseType,
  AlloyComposition,
  ContactAlloy,
  ItemWeightData,
  MetalComposition,
} from "center-services";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Decimal } from "decimal.js";

const MINUS_ONE = -1;
const HUNDRED = 100;
const THOUSAND = 1000;

@Injectable({
  providedIn: "root",
})
export class PurchaseModalityService {
  public initObservables: Observable<any>[] = [];
  public metalsList: Metal[] = [];
  private alloysList: Alloy[];
  private uomsList: Uom[];

  constructor(
    private uomService: UomService,
    private metalService: MetalService,
    private alloyService: AlloyService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.initObservables.push(this.fetchUoms());
    this.initObservables.push(this.fetchMetals());
    this.initObservables.push(this.fetchAlloys());
  }

  computeModalityPrice(
    purchaseModality: PurchaseModality,
    itemTheoreticalWeight: number,
    theoreticalMetalWeight: TheoreticalMetalWeight[]
  ): number {
    let grossPrice =
      itemTheoreticalWeight && purchaseModality.unitPricePerWeight
        ? purchaseModality.unitPrice + purchaseModality.unitPricePerWeight * itemTheoreticalWeight
        : purchaseModality.unitPrice;

    if (purchaseModality.purchaseType === PurchaseType.WITH_METAL_PRICE) {
      grossPrice += this.computeMetalPrice(purchaseModality, theoreticalMetalWeight);
    }
    return RoundingUtil.roundHigh(grossPrice);
  }

  computeMetalPrice(purchaseModality: PurchaseModality, theoreticalMetalWeight: TheoreticalMetalWeight[]): number {
    let totalMetalPrice = 0;

    if (purchaseModality.purchaseType === PurchaseType.WITH_METAL_PRICE) {
      theoreticalMetalWeight.forEach((metalWeight: TheoreticalMetalWeight) => {
        const chosenMetal = this.metalsList.find((metal: Metal) => metal.id === metalWeight.metalId);

        const metalPrice = this.getMetalPriceToWeightUnit(
          this.metalService.getMetalPrice(chosenMetal, purchaseModality.currencyId)
        );
        totalMetalPrice = RoundingUtil.roundHigh(
          new Decimal(totalMetalPrice ?? 0)
            .add(new Decimal(metalPrice ?? 0).mul(new Decimal(metalWeight.weight ?? 0)))
            .toNumber()
        );
      });
    }
    return RoundingUtil.roundHigh(totalMetalPrice);
  }

  getTheoreticalMetalWeight(
    itemTheoreticalWeight: number = 0,
    itemComposition: AlloyComposition[] = [],
    supplierPurchaseAlloys: ContactAlloy[] = []
  ): Observable<TheoreticalMetalWeight[]> {
    return combineLatest(this.initObservables).pipe(
      map(() => {
        const theoreticalMetalWeight: TheoreticalMetalWeight[] = [];

        itemTheoreticalWeight = itemTheoreticalWeight !== null ? itemTheoreticalWeight : 0;
        if (itemComposition === null || itemComposition === undefined) {
          return [];
        }

        for (const itemAlloyComposition of itemComposition) {
          // get the current alloy based on the supplier or global params
          const currentAlloy = this.getAlloyComposition(itemAlloyComposition, supplierPurchaseAlloys);
          const currentAlloyMetalComposition = currentAlloy.composition;
          if (currentAlloyMetalComposition.length === 0) {
            continue;
          }

          const currentAlloyCompositionWeight = (itemAlloyComposition.rate / HUNDRED) * itemTheoreticalWeight;
          const currentAlloyCompositionLossRate =
            typeof itemAlloyComposition.lossRate === "number" ? itemAlloyComposition.lossRate : currentAlloy.lostRate;

          for (const metalComposition of currentAlloyMetalComposition) {
            const metalCompositionLossRate = this.getMetalCompositionLossRate(
              currentAlloyCompositionLossRate,
              metalComposition
            );

            const metalCompositionWeight =
              (metalComposition.rate / THOUSAND) *
              currentAlloyCompositionWeight *
              (1 + metalCompositionLossRate / HUNDRED);
            this.updateMetalWeightsList(metalComposition.metalId, metalCompositionWeight, theoreticalMetalWeight);
          }
        }

        return theoreticalMetalWeight;
      })
    );
  }

  public getMetalTotalWeight(itemWeightData: ItemWeightData): number {
    const destUnitId = this.uomsList.find(uom => uom.shortName === "g").id;

    // use convertWeightTo only if weight or tare of item is different of the destUnit
    const itemWeight =
      itemWeightData.weightUnitId !== destUnitId
        ? this.uomService.convertWeightTo(this.uomsList, itemWeightData.weight, itemWeightData.weightUnitId, destUnitId)
        : itemWeightData.weight;
    const itemTare =
      itemWeightData.tareUnitId !== destUnitId
        ? this.uomService.convertWeightTo(this.uomsList, itemWeightData.tare, itemWeightData.tareUnitId, destUnitId)
        : itemWeightData.tare;

    return itemWeight - itemTare;
  }

  public getMetalPriceToWeightUnit(metalPrice: number): number {
    if (!metalPrice || metalPrice === 0) {
      return 0;
    }

    const destUnitId = this.uomsList.find(uom => uom.shortName === "g").id;
    const srcUnitId = this.uomsList.find(uom => uom.shortName === "kg").id;

    const unitRate = this.uomService.convertWeightTo(this.uomsList, 1, srcUnitId, destUnitId);
    return RoundingUtil.roundHigh(new Decimal(metalPrice ?? 0).div(new Decimal(unitRate ?? 0)).toNumber());
  }

  private fetchAlloys(): Observable<Alloy[]> {
    return this.alloyService.getAll().pipe(
      tap(
        (alloysList: Alloy[]) => {
          this.alloysList = alloysList;
        },
        error => {
          this.generateErrors("alloys", error);
        }
      )
    );
  }

  private fetchMetals(): Observable<Metal[]> {
    return this.metalService.getAll().pipe(
      tap(
        (metalsList: Metal[]) => {
          this.metalsList = metalsList;
        },
        error => {
          this.generateErrors("metals", error);
        }
      )
    );
  }

  private fetchUoms(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uomList: Uom[]) => {
          this.uomsList = uomList;
        },
        error => {
          this.generateErrors("uoms", error);
        }
      )
    );
  }

  private getAlloyComposition(itemAlloyComposition: AlloyComposition, supplierAlloys: ContactAlloy[]): any {
    if (Array.isArray(supplierAlloys) && supplierAlloys.length) {
      const supplierAlloyFound = supplierAlloys.find(
        (supplierAlloy: ContactAlloy) => supplierAlloy.alloyId === itemAlloyComposition.alloyId
      );
      if (supplierAlloyFound !== undefined) {
        return supplierAlloyFound;
      }
    }
    // No need to check here if alloysList contains elements
    return this.alloysList.find((alloy: Alloy) => alloy.id === itemAlloyComposition.alloyId);
  }

  private getMetalCompositionLossRate(alloyCompositionLossRate: number, metalComposition: MetalComposition): number {
    if (alloyCompositionLossRate === null) {
      return typeof metalComposition.lostRate === "number" ? metalComposition.lostRate : 0;
    }
    return alloyCompositionLossRate;
  }

  private updateMetalWeightsList(
    metalId: number,
    metalWeight: number,
    metalWeightCompositions: TheoreticalMetalWeight[]
  ): void {
    const metalCompIdx = metalWeightCompositions.findIndex(mwc => mwc.metalId === metalId);
    if (metalCompIdx === MINUS_ONE) {
      metalWeightCompositions.push(new TheoreticalMetalWeight({ metalId, weight: metalWeight }));
    } else {
      metalWeightCompositions[metalCompIdx].weight += metalWeight;
    }
  }

  private generateErrors(entityName: string, error: HttpErrorResponse): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(`${entityName}-list.errors.get-entities`, { message: error.message });
    this.messageService.warn(content, { title });
  }
}
