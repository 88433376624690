import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  Store,
  Person,
  Country,
  Currency,
  CountryService,
  AccountingInfo,
  Address,
  RevenueDisclosureParam,
} from "center-services";
import { CommonValidatorsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";

@Component({
  selector: "app-finance",
  templateUrl: "./finance.component.html",
  styleUrls: ["./finance.component.scss"],
  providers: [SubscriptionService],
})
export class FinanceComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() editedStore: Store;

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public readonly decimalDigitLow: string = `separator.${PrecisionUtil.LOW_DECIMAL}`;
  public LOW_INTEGER: PrecisionUtil = PrecisionUtil.LOW_INTEGER;
  public recipientOptions: Option[] = [];

  public personsList: Person[] = [];
  public countries: Country[];
  public countryOptions: Option[] = [];

  public booleanOptions: Option[] = [];

  public financeForm: UntypedFormGroup;

  public currency: Currency = null;

  constructor(
    private translateService: TranslateService,
    private countryService: CountryService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.prepareForm();
    this.buildRadioOptions(this.booleanOptions);
    this.fetchCountries();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildRadioOptions(this.booleanOptions);
      })
    );
    if (this.editedStore) {
      this.loadEditedData();
    }

    this.subscriptionService.subs.push(
      this.financeForm.get("activated").valueChanges.subscribe(isCaBlocHidden => {
        if (isCaBlocHidden) {
          this.resetCaBloc();
        }
      })
    );
  }

  ngOnChanges(): void {
    if (this.editedStore) {
      this.loadEditedData();
    }
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  prepareForm(): void {
    this.financeForm = this.fb.group({
      yearly: [1],
      monthly: [1],
      withAS: [0],
      withTax: [false],
      withoutTax: [true],
      adjustmentPercentage: [null, CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.LOW_INTEGER)],
      recipient: [null],
      code: [null],
      bankDistance: [null, Validators.min(0)],
      bankName: [null],
      depositThreshold: [
        null,
        [Validators.min(0), CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER)],
      ],
      depositInterval: [null, [Validators.min(0), CommonValidatorsUtil.integerValidator()]],
      accountNumber: [null],
      lines: [null],
      city: [null],
      cityCode: [null],
      country: [null],
      addressByDefault: [true],
      activated: [0],
    });
  }

  buildRecipientOptions(): void {
    if (this.editedStore && this.editedStore.persons && this.editedStore.persons.length > 0) {
      this.personsList = this.editedStore.persons.filter(p => !p.archived);

      this.recipientOptions = this.personsList
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
        .map(obj => new Option(obj.id, `${obj.firstName} ${obj.lastName}`));
    }
  }

  buildRadioOptions(option: Option[]): void {
    option[0] = new Option(0, this.translateService.instant("revenue-disclosure-param.yes"));
    option[1] = new Option(1, this.translateService.instant("revenue-disclosure-param.no"));
  }

  fetchCountries(): void {
    this.subscriptionService.subs.push(
      this.countryService.getAll().subscribe(
        (countries: Country[]) => {
          this.countries = countries;
          this.countryOptions = countries
            .filter((obj: Country) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Country) => new Option(obj.id, obj.name));

          if (this.countries && this.editedStore && this.editedStore.address) {
            const newCountry = this.countries.find(c => c.id === this.editedStore.address.countryId);
            if (newCountry && newCountry.currencies) {
              this.currency = newCountry.currencies.find(curr => curr.byDefault);
            }
          }
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("countries-list.errors.get-countries");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  loadEditedData(): void {
    if (!this.financeForm) {
      this.prepareForm();
    }
    this.buildRecipientOptions();
    if (this.editedStore.revenueDisclosureParam) {
      this.financeForm.controls.activated.setValue(this.editedStore.revenueDisclosureParam.activated ? 0 : 1);
      this.financeForm.controls.yearly.setValue(this.editedStore.revenueDisclosureParam.yearly ? 0 : 1);
      this.financeForm.controls.monthly.setValue(this.editedStore.revenueDisclosureParam.monthly ? 0 : 1);
      this.financeForm.controls.withAS.setValue(this.editedStore.revenueDisclosureParam.withAS ? 0 : 1);
      this.financeForm.controls.withTax.setValue(this.editedStore.revenueDisclosureParam.withTax ? true : false);
      this.financeForm.controls.withoutTax.setValue(this.editedStore.revenueDisclosureParam.withoutTax ? true : false);
      this.financeForm.controls.adjustmentPercentage.setValue(
        this.editedStore.revenueDisclosureParam.adjustmentPercentage
      );

      const opt = this.recipientOptions.find((unitOpt: Option) => {
        return unitOpt.id === this.editedStore.revenueDisclosureParam.recipientId;
      });
      if (opt) {
        this.financeForm.controls.recipient.setValue(this.editedStore.revenueDisclosureParam.recipientId);
      }
    }

    if (this.editedStore.accountingInfo) {
      this.financeForm.controls.code.setValue(this.editedStore.accountingInfo.code);
      this.financeForm.controls.bankDistance.setValue(this.editedStore.accountingInfo.bankDistance);
      this.financeForm.controls.bankName.setValue(this.editedStore.accountingInfo.bankName);
      this.financeForm.controls.depositThreshold.setValue(this.editedStore.accountingInfo.depositThreshold);
      this.financeForm.controls.depositInterval.setValue(this.editedStore.accountingInfo.depositInterval);
      this.financeForm.controls.accountNumber.setValue(this.editedStore.accountingInfo.accountNumber);
      this.financeForm.controls.lines.setValue(this.editedStore.accountingInfo.bankAddress.lines);
      this.financeForm.controls.city.setValue(this.editedStore.accountingInfo.bankAddress.city);
      this.financeForm.controls.cityCode.setValue(this.editedStore.accountingInfo.bankAddress.cityCode);
      this.financeForm.controls.country.setValue(this.editedStore.accountingInfo.bankAddress.countryId);
      this.financeForm.controls.addressByDefault.setValue(this.editedStore.accountingInfo.bankAddress.byDefault);
    }
  }

  applyModifications(): void {
    // edit store with form controls
    if (!this.editedStore.accountingInfo) {
      const accountingInfo = new AccountingInfo({
        code: null,
        bankDistance: null,
        bankName: null,
        depositThreshold: null,
        depositInterval: null,
        accountNumber: null,
        bankAddress: new Address(),
      });
      this.editedStore.accountingInfo = accountingInfo;
    }

    if (!this.editedStore.revenueDisclosureParam) {
      const revenueDisclosureParam = new RevenueDisclosureParam({
        yearly: true,
        monthly: true,
        withTax: true,
        withoutTax: true,
        withAS: true,
        adjustmentPercentage: null,
        recipientId: null,
        activated: true,
      });
      this.editedStore.revenueDisclosureParam = revenueDisclosureParam;
    }

    // accounting info
    const cloneAddress = new Address(this.editedStore.accountingInfo.bankAddress);
    cloneAddress.lines = this.financeForm.value.lines;
    cloneAddress.city = this.financeForm.value.city;
    cloneAddress.cityCode = this.financeForm.value.cityCode;
    cloneAddress.countryId = this.financeForm.value.country;
    cloneAddress.byDefault = this.financeForm.value.addressByDefault;

    this.editedStore.accountingInfo.accountNumber = this.financeForm.value.accountNumber;
    this.editedStore.accountingInfo.bankDistance = this.financeForm.value.bankDistance;
    this.editedStore.accountingInfo.bankName = this.financeForm.value.bankName;
    this.editedStore.accountingInfo.code = this.financeForm.value.code;
    this.editedStore.accountingInfo.depositInterval = this.financeForm.value.depositInterval;
    this.editedStore.accountingInfo.depositThreshold = this.financeForm.value.depositThreshold;
    this.editedStore.accountingInfo.bankAddress = cloneAddress;

    // revenue disclosure param
    this.editedStore.revenueDisclosureParam.activated = this.financeForm.value.activated === 0 ? true : false;
    this.editedStore.revenueDisclosureParam.yearly = this.financeForm.value.yearly === 0 ? true : false;
    this.editedStore.revenueDisclosureParam.monthly = this.financeForm.value.monthly === 0 ? true : false;
    this.editedStore.revenueDisclosureParam.withAS = this.financeForm.value.withAS === 0 ? true : false;
    this.editedStore.revenueDisclosureParam.withTax = this.financeForm.value.withTax;
    this.editedStore.revenueDisclosureParam.withoutTax = this.financeForm.value.withoutTax;
    this.editedStore.revenueDisclosureParam.adjustmentPercentage = this.financeForm.value.adjustmentPercentage;
    this.editedStore.revenueDisclosureParam.recipientId = this.financeForm.value.recipient;
  }

  updateStore(): boolean {
    if (this.financeForm.invalid) {
      this.financeForm.markAllAsTouched();
      return false;
    }

    this.applyModifications();

    return true;
  }

  getCurrencySuffix(): string {
    if (!this.currency) {
      return "";
    }
    return ` ${this.currency.symbol}`;
  }

  private resetCaBloc(): void {
    this.financeForm.controls.yearly.setValue(1);
    this.financeForm.controls.monthly.setValue(1);
    this.financeForm.controls.withAS.setValue(1);
    this.financeForm.controls.withTax.setValue(false);
    this.financeForm.controls.withoutTax.setValue(false);
    this.financeForm.controls.adjustmentPercentage.setValue(null);
    this.financeForm.controls.recipient.setValue(null);
  }
}
