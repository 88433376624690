import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
  faCheck,
  faExclamationCircle,
  faInfoCircle,
  faTimesCircle,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  PurchaseOrder,
  Currency,
  Light,
  AbstractItem,
  LightService,
  RetailItemService,
  CurrencyService,
  CaraUserService,
  PurchaseOrderLine,
  PaginatedList,
  Pagination,
  CaraUser,
  StandardItem,
  PurchaseType,
  PurchaseOrderService,
  PurchaseOrderUtil,
} from "center-services";
import {
  Filterer,
  FilterValue,
  Option,
  PaginableComponent,
  RoundingUtil,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import Decimal from "decimal.js";

class PurchaseOrderLinesDetailRow {
  id: number;
  lineNumber: number;
  store: string;
  itemRef: string;
  supplierRef: string;
  itemName: string;
  brand: string;
  deliveryDate: Date;
  sizeValue: string;
  quantity: number;
  receivedQuantity: number;
  registeredQuantity: number;
  engraving: boolean;
  comment: string;
  tooltipComment: string;
  theoreticalMetalWeight: number;
  totalWeight: number;
  unitPrice: number;
  unitPricePerWeight: number;
  metalPrice: number;
  grossUnitPrice: number;
  discountPercent: number;
  discount: number;
  grossPriceAmount: number;
  canceled: boolean;
  actions: any;
  quantityClass: string;
  purchaseType: string;
  deliveryStoreId: number;
}

@Component({
  selector: "app-purchase-order-lines-detail",
  templateUrl: "./purchase-order-lines-detail.component.html",
  styleUrls: ["./purchase-order-lines-detail.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderLinesDetailComponent
  extends FilteredTableListComponent
  implements OnInit, PaginableComponent {
  public static LIST_ID: string = "app-purchase-order-lines-detail.purchase-order-lines-table";
  @ViewChild("table") table: any;
  @Input() purchaseOrder: PurchaseOrder;

  public allRows: PurchaseOrderLinesDetailRow[] = [];
  public rows: PurchaseOrderLinesDetailRow[] = [];
  public lineNumberOptions: Option[];
  public storeOptions: Option[] = [];
  public brandOptions: Option[] = [];
  public itemRefOptions: Option[] = [];
  public supplierRefOptions: Option[] = [];
  public sizeValueOptions: Option[] = [];

  public tableControl: UntypedFormGroup;

  public sorts: any[] = [{ prop: "lineNumber", dir: "asc" }];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public pageNumber: number = 0;
  public currencyList: Currency[] = [];
  public purchaseModalityIdList: Set<number> = new Set();
  public locale: string;
  public dateFormat: string;
  public purchaseOrderCurrency: Currency;
  public storesList: Light[] = [];
  public retailItemList: AbstractItem[] = [];
  public faExclamationCircle: IconDefinition = faExclamationCircle;
  public faCheck: IconDefinition = faCheck;
  public faTimesCircle: IconDefinition = faTimesCircle;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public quantityMap: any[] = [];
  public readonly cancelLabel: string = this.translateService.instant("purchase-order-list.datatable.cancel");
  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });
  private sessionPagination: SessionPagination;
  private initObservables: Observable<any>[] = [];
  private readonly CANCEL_ACTION_ID: number = 1;
  private readonly RESTORE_ACTION_ID: number = 2;
  private readonly MAX_PERCENT: number = 100;

  constructor(
    translateService: TranslateService,
    messageService: MessageService,
    private lightService: LightService,
    private purchaseOrderService: PurchaseOrderService,
    private retailItemService: RetailItemService,
    private currencyService: CurrencyService,
    protected userService: CaraUserService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer?.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(PurchaseOrderLinesDetailComponent.LIST_ID);
  }

  ngOnInit(): void {
    this.purchaseOrder.lines.forEach((line: PurchaseOrderLine) => {
      // get all the purchase modality ids from each line
      this.purchaseModalityIdList.add(line.purchaseModalityId);
    });

    // initiate all the fetches for necessary data
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }

    this.tableControl = new UntypedFormGroup({});
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchItems());
    this.initObservables.push(this.fetchPurchaseOrderCurrency(this.purchaseOrder.currencyId));

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.populateRows();
        this.initFilters();
        this.sessionPagination.loadFromSession(PurchaseOrderLinesDetailComponent.LIST_ID);
        this.applyFilters();
      })
    );
  }

  getMenuActions(canceled: boolean): MenuAction[] {
    const rowMenuActions: MenuAction[] = [];

    if (canceled) {
      rowMenuActions.push(
        new MenuAction(
          this.RESTORE_ACTION_ID,
          this.translateService.instant("purchase-order-lines-list.actions.restore"),
          this.faCheck
        )
      );
    } else {
      rowMenuActions.push(
        new MenuAction(
          this.CANCEL_ACTION_ID,
          this.translateService.instant("purchase-order-lines-list.actions.cancel"),
          this.faTimesCircle
        )
      );
    }
    return rowMenuActions;
  }

  getRowClass(row: any): any {
    return { "not-clickable": true, strikethrough: row.canceled };
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case this.CANCEL_ACTION_ID:
      case this.RESTORE_ACTION_ID:
        this.switchCancelState(row.id);
        break;
      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  populateRows(): void {
    if (this.purchaseOrder.lines) {
      this.allRows = [];
      this.purchaseOrder.lines.forEach(purchaseOrderLine => {
        this.addRow(purchaseOrderLine);
      });
      this.rows = [...this.allRows];
    }
  }

  addRow(purchaseOrderLine: PurchaseOrderLine): void {
    const quantityClass = this.getQuantityClass(purchaseOrderLine);

    this.allRows.push({
      id: purchaseOrderLine.id,
      lineNumber: purchaseOrderLine.lineNumber,
      store: this.computeSpecialColumnValue(purchaseOrderLine, "store"),
      itemRef: purchaseOrderLine.itemReference,
      supplierRef: purchaseOrderLine.supplierRef,
      itemName: purchaseOrderLine.itemName,
      brand: purchaseOrderLine.brandName,
      deliveryDate: purchaseOrderLine.deliveryDate,
      sizeValue: purchaseOrderLine.sizeValue,
      quantity: purchaseOrderLine.quantity,
      receivedQuantity: this.computeSpecialColumnValue(purchaseOrderLine, "receivedQuantity"),
      registeredQuantity: this.computeSpecialColumnValue(purchaseOrderLine, "registeredQuantity"),
      engraving: purchaseOrderLine.engraving,
      comment: this.getCommentToDisplay(purchaseOrderLine),
      tooltipComment: this.getTooltipToDisplay(purchaseOrderLine),
      theoreticalMetalWeight: this.computeSpecialColumnValue(purchaseOrderLine, "theoreticalMetalWeight"),
      totalWeight: purchaseOrderLine.weight * purchaseOrderLine.quantity,
      unitPrice: purchaseOrderLine.unitPrice ?? 0,
      unitPricePerWeight: purchaseOrderLine.unitPricePerWeight ?? 0,
      metalPrice: purchaseOrderLine.metalPrice ?? 0,
      grossUnitPrice: this.computeSpecialColumnValue(purchaseOrderLine, "grossUnitPrice"),
      discountPercent: purchaseOrderLine.percentDiscount ?? 0,
      discount: this.computeSpecialColumnValue(purchaseOrderLine, "discount"),
      grossPriceAmount: this.computeSpecialColumnValue(purchaseOrderLine, "grossPriceAmount"),
      canceled: purchaseOrderLine.canceled,
      actions: this.computeSpecialColumnValue(purchaseOrderLine, "actions"),
      quantityClass,
      purchaseType: purchaseOrderLine.purchaseType,
      deliveryStoreId: purchaseOrderLine.deliveryStoreId,
    });

    if (purchaseOrderLine.itemReference in this.quantityMap) {
      this.quantityMap[purchaseOrderLine.itemReference].total += purchaseOrderLine.quantity;
      if (purchaseOrderLine.deliveryStoreId in this.quantityMap[purchaseOrderLine.itemReference]) {
        this.quantityMap[purchaseOrderLine.itemReference][purchaseOrderLine.deliveryStoreId] +=
          purchaseOrderLine.quantity;
      } else {
        this.quantityMap[purchaseOrderLine.itemReference][purchaseOrderLine.deliveryStoreId] =
          purchaseOrderLine.quantity;
      }
    } else {
      this.quantityMap[purchaseOrderLine.itemReference] = [];
      this.quantityMap[purchaseOrderLine.itemReference].total = purchaseOrderLine.quantity;
      this.quantityMap[purchaseOrderLine.itemReference][purchaseOrderLine.deliveryStoreId] = purchaseOrderLine.quantity;
    }

    this.tableControl.addControl(
      `engraved_${purchaseOrderLine.id}`,
      new UntypedFormControl({ value: purchaseOrderLine.engraving, disabled: true })
    );
  }

  switchCancelState(polId: number): void {
    const pols = this.purchaseOrder.lines;
    const polIdx = pols.findIndex(pol => pol.id === polId);
    const purchaseOrderLine: PurchaseOrderLine = pols[polIdx];

    if ((purchaseOrderLine.registeredQuantity ?? 0) === 0 && (purchaseOrderLine.receivedQuantity ?? 0) === 0) {
      purchaseOrderLine.canceled = !purchaseOrderLine.canceled;

      this.subscriptionService.subs.push(
        this.purchaseOrderService.update(this.purchaseOrder).subscribe({
          error: error => {
            this.handleApiError(error);
          },
          complete: () => {
            this.confirmUpdate();
            // reload rows with the new changed POL
            this.populateRows();
            this.applyFilters();
          },
        })
      );
    }
  }

  fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storesList = lightStores;
        },
        error => {
          this.sendErrorAlert("purchase-order-lines-list.errors.get-stores", error.message);
        }
      )
    );
  }

  fetchItems(): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: this.purchaseModalityIdList.size,
      number: 0,
    });
    const filters = new SearchFilter(
      "purchaseModalities.id",
      SearchFilterOperator.IN,
      Array.from(this.purchaseModalityIdList)
    );

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          // remove duplicates from item list
          const ids = result.data.map(obj => obj.id);
          this.retailItemList = result.data.filter(({ id }, index) => !ids.includes(id, index + 1));
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  fetchPurchaseOrderCurrency(id: number): Observable<Currency> {
    return this.currencyService.get(id).pipe(
      tap(
        (currency: Currency) => {
          this.purchaseOrderCurrency = currency;
        },
        error => {
          this.sendErrorAlert("purchase-order.lines.datatable.errors.get-currency", error.message);
        }
      )
    );
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  public changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];
    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === PurchaseOrderLinesDetailComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "lineNumber",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.line-number"),
      "string"
    );

    this.filterer.addFilter(
      "itemName",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.name"),
      "string"
    );

    this.filterer.addListFilter(
      "store",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.store"),
      [...new Set(this.allRows.map(row => row.store))].map(storeName => {
        return { value: storeName, displayValue: storeName };
      })
    );

    this.filterer.addFilter(
      "itemRef",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.item-ref"),
      "string"
    );

    this.filterer.addFilter(
      "supplierRef",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.supplier-ref"),
      "string"
    );

    this.filterer.addListFilter(
      "brand",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.brand"),
      [...new Set(this.allRows.map(row => row.brand))]
        .map(brand => {
          return { value: brand, displayValue: brand };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      null,
      null,
      null,
      null,
      true
    );

    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    this.filterer.addFilter(
      "deliveryDate",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.delivery-date"),
      "date",
      false,
      false,
      {
        from: dayjs(firstDay),
        to: dayjs(lastDay),
      }
    );

    const numericSorter = new Intl.Collator(this.locale, { numeric: true });
    this.filterer.addListFilter(
      "sizeValue",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.size-value"),
      [...new Set(this.allRows.map(row => row.sizeValue).filter(val => val?.length > 0))]
        .sort((a, b) => numericSorter.compare(a, b))
        .map(sizeValue => {
          return { value: sizeValue, displayValue: sizeValue };
        })
    );

    this.filterer.addBooleanFilter(
      "engraving",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.engraving"),
      false,
      false,
      true
    );

    this.filterer.addFilter(
      "comment",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.comment"),
      "string"
    );

    this.filterer.addFilter(
      "totalWeight",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.total-weight"),
      "range"
    );

    this.filterer.addFilter(
      "theoreticalMetalWeight",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.theoretical-metal-weight"),
      "range"
    );

    this.filterer.addFilter(
      "unitPrice",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.unit-price"),
      "range"
    );

    this.filterer.addFilter(
      "unitPricePerWeight",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.unit-price-per-weight"),
      "range"
    );

    this.filterer.addFilter(
      "metalPrice",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.metal-price"),
      "range"
    );

    this.filterer.addFilter(
      "grossUnitPrice",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.gross-unit-price"),
      "range"
    );

    this.filterer.addFilter(
      "discountPercent",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.discount-percent"),
      "range"
    );

    this.filterer.addFilter(
      "discount",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.discount"),
      "range"
    );

    this.filterer.addFilter(
      "grossPriceAmount",
      this.translateService.instant("purchase-order-lines-list.datatable.columns.gross-price-amount"),
      "range"
    );
  }

  applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        PurchaseOrderLinesDetailComponent.LIST_ID
      ).subscribe()
    );
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  handleApiError(err: any): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant("purchase-order-lines-list.errors.update-purchase-order-line", {
      message: err.message,
    });
    this.messageService.warn(content, { title });
  }

  confirmUpdate(): void {
    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
  }

  computeSpecialColumnValue(line: PurchaseOrderLine, column: string): any {
    switch (column) {
      case "store": {
        const store = this.storesList.find(supplier => supplier.id === line.deliveryStoreId);
        return `${store.name} (${store.reference})`;
      }
      case "receivedQuantity":
        return line.receivedQuantity ?? 0;
      case "registeredQuantity":
        return line.registeredQuantity ?? 0;
      case "totalWeight":
        return line.weight * line.quantity;
      case "theoreticalMetalWeight":
        return PurchaseOrderUtil.getMetalWeight(line);
      case "grossUnitPrice":
        return PurchaseOrderUtil.computeLineUnitPrice(line, this.retailItemList);
      case "discountPercent":
        return line.percentDiscount ? line.percentDiscount : 0;
      case "discount":
        return -this.computeDiscount(line);
      case "grossPriceAmount":
        return PurchaseOrderUtil.computeLineTotalPrice(line, this.retailItemList);
      case "actions":
        return this.getMenuActions(line.canceled);
      default:
        return line[column];
    }
  }

  computeUnitPriceWithoutTax(line: PurchaseOrderLine): number {
    const lineItem = PurchaseOrderUtil.findItem(line, this.retailItemList);

    if (!(lineItem instanceof StandardItem)) {
      return RoundingUtil.roundLow(line.unitPrice);
    }

    const itemWeight = lineItem.theoreticalWeight ?? 0;
    const unitPrice = line.unitPrice ?? 0;
    const unitPricePerWeight = line.unitPricePerWeight ?? 0;

    let price = new Decimal(unitPrice ?? 0)
      .plus(new Decimal(unitPricePerWeight ?? 0).times(itemWeight ?? 0))
      .toNumber();
    if (line.purchaseType === PurchaseType.WITH_METAL_PRICE) {
      price = new Decimal(price ?? 0).plus(line?.metalPrice ?? 0).toNumber();
    }
    return RoundingUtil.roundLow(price);
  }

  computeDiscount(line: PurchaseOrderLine): number {
    const quantity = line.quantity;
    const percentDiscount = line.percentDiscount;
    const unitPriceWithoutTax = this.computeUnitPriceWithoutTax(line);
    return RoundingUtil.roundLow(
      new Decimal(quantity ?? 0)
        .times(percentDiscount ?? 0)
        .times(unitPriceWithoutTax ?? 0)
        .div(this.MAX_PERCENT)
        .toNumber()
    );
  }

  shouldDisplayReceivedQuantityTooltip(lineNumber: number): boolean {
    const currrentLine = this.purchaseOrder.lines.find(line => line.lineNumber === lineNumber);
    if (currrentLine !== undefined) {
      const totalLines = this.purchaseOrder.lines.filter(line => line.itemReference === currrentLine.itemReference);
      return totalLines.length > 1;
    }
    return false;
  }

  getLineByNumber(lineNumber: number): PurchaseOrderLine {
    return this.purchaseOrder.lines.find(line => line.lineNumber === lineNumber);
  }

  getQuantityForStore(itemReference: string, deliveryStoreId: string | number): number {
    if (itemReference in this.quantityMap) {
      return this.quantityMap[itemReference][deliveryStoreId];
    }
    return null;
  }

  getQuantityTotalForItem(itemReference: string): number {
    if (itemReference in this.quantityMap) {
      return this.quantityMap[itemReference].total;
    }
    return null;
  }

  getStoreName(lineNumber: number): number {
    const currentLine = this.getLineByNumber(lineNumber);
    return this.computeSpecialColumnValue(currentLine, "store");
  }

  shouldShowTotal(itemReference: string): boolean {
    if (itemReference in this.quantityMap) {
      const lengthLimit = 2;
      return Object.keys(this.quantityMap[itemReference]).length > lengthLimit;
    }
    return false;
  }

  getCommentToDisplay(line: PurchaseOrderLine): string {
    const comment = line.comment;
    if ((comment === null || comment === "") && line.engraving) {
      return this.getTooltipToDisplay(line);
    }
    return line.comment;
  }

  getTooltipToDisplay(line: PurchaseOrderLine): string {
    if (!line.engraving) {
      return line.comment;
    }
    const text = this.translateService.instant("engraving-popup.fields.text");
    const engravingText = line.engravingText ? line.engravingText : "";
    const font = this.translateService.instant("engraving-popup.fields.font");
    const engravingFont = line.engravingFont ? line.engravingFont : "";
    const location = this.translateService.instant("engraving-popup.fields.location");
    const engravingLocation = line.engravingLocation ? line.engravingLocation : "";
    const comment = this.translateService.instant("engraving-popup.fields.comment");
    const commentText = line.comment ?? "";
    if (line.engraving) {
      return `${text}: ${engravingText}\n${font}: ${engravingFont}\n${location}: ${engravingLocation}\n${comment}: ${commentText}`;
    }
    return null;
  }

  canCancel(row: PurchaseOrderLinesDetailRow): boolean {
    return (row.registeredQuantity ?? 0) === 0 && (row.receivedQuantity ?? 0) === 0;
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }

  private getQuantityClass(purchaseOrderLine: PurchaseOrderLine): string {
    const quantity = purchaseOrderLine.quantity;
    const receivedQuantity = purchaseOrderLine.receivedQuantity;
    if (!receivedQuantity || receivedQuantity === 0) {
      return "quantity-wrapper none";
    } else if (receivedQuantity < quantity) {
      return "quantity-wrapper partial";
    } else {
      return "quantity-wrapper full";
    }
  }
}
