<div class="popup-container">
  <ui-popup title="{{ 'multiple-location-selection-popup.title' | translate }}" (close)="closePopup()">
    <div content>
      <div class="recap-fields-container">
        <div class="recap-container">
          <div class="recap-title">{{ "multiple-location-selection-popup.recap.title" | translate }}</div>

          <section class="recap-items" *ngIf="stockEntryLocations.length > 0">
            <section class="location-name">
              <ui-info-field
                [label]="'multiple-location-selection-popup.recap.item-ref' | translate"
                [value]="stockEntryLocations[0].stockEntry.itemReference"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>

            <section class="supplier-name-reference">
              <ui-info-field
                [label]="'multiple-location-selection-popup.recap.supplier-ref' | translate"
                [value]="stockEntryLocations[0].stockEntry.supplierRef"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>

            <section *ngIf="stockEntryLocations[0].stockEntry?.stockType !== 'BULK'" class="stock-entry-id">
              <ui-info-field
                [label]="'multiple-location-selection-popup.recap.batch-number' | translate"
                [value]="stockEntryLocations[0].stockEntry.id"
                [sizeMode]="'small'"
              >
              </ui-info-field>
            </section>
          </section>
        </div>

        <div class="fields-container">
          <form class="manual-stock-movement-form" [formGroup]="popupForm" novalidate>
            <div class="grid-item dest-location">
              <ui-list
                [label]="'multiple-location-selection-popup.location' | translate"
                [options]="stockEntryLocationOptions"
                [search]="true"
                [mandatory]="true"
                formControlName="stockLocationId"
                [status]="popupForm.get('stockLocationId') | formStatus"
                [errorMessage]="popupForm.get('stockLocationId') | formErrorMessage | translate"
                (ngModelChange)="onChange()"
              >
              </ui-list>
            </div>

            <div class="grid-item quantity">
              <ui-input-text
                [type]="'text'"
                [label]="'multiple-location-selection-popup.quantity' | translate"
                [mandatory]="true"
                [maskPattern]="decimalDigitHigh"
                formControlName="quantity"
                [status]="popupForm.get('quantity') | formStatus"
                [errorMessage]="
                  popupForm.get('quantity')
                    | formErrorMessage
                      : {
                          customPrefix: 'multiple-location-selection-popup.errors.',
                          useCustomPrefixFor: ['isZeroValue', 'max']
                        }
                    | translate: { digit: HIGH_INTEGER }
                "
              >
              </ui-input-text>
            </div>
            <div class="grid-item uom" *ngIf="currentUom">
              {{ currentUom.longName }}
            </div>
          </form>
        </div>
      </div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              text="{{ 'create-stock-movement-popup.validate' | translate }}"
              customClass="fugu-button-save"
              [primary]="true"
              (click)="submitPopup()"
              [btnIcon]="faCheck"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
