<ng-container>
  <div class="header">
    <div class="title">{{ "item.nomenclatures.nomenclatures-list.title" | translate }}</div>

    <ui-button
      type="add"
      [primary]="false"
      text="{{ 'item.nomenclatures.nomenclatures-list.new-button-label' | translate }}"
      (click)="openPopup()"
    >
    </ui-button>
  </div>

  <div class="nomenclatures-list">
    <div>
      <ngx-datatable
        #table
        [rows]="nomenclaturesList"
        [trackByProp]="'id'"
        class="material"
        columnMode="flex"
        headerHeight="auto"
        rowHeight="50"
        (activate)="updateNomenclature($event)"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down',
        }"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        [sorts]="sorts"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
      >
        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclatures-list.datatable.column.name' | translate }}"
          prop="name"
          [flexGrow]="1"
          [resizeable]="false"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclatures-list.datatable.column.creation-date' | translate }}"
          prop="createdAt"
          [flexGrow]="1"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-date="row.createdAt">
            <span>{{ date | dateTimeFormat: false }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclatures-list.datatable.column.validity-date' | translate }}"
          prop="validityDate"
          [flexGrow]="1"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-date="row.validityDate">
            <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclatures-list.datatable.column.active' | translate }}"
          prop="archived"
          headerClass="center-align"
          cellClass="center-align top-align"
          [flexGrow]="0.25"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="green-zone" (click)="$event.stopPropagation()">
              <ui-switch customClass="no-margin" [ngModel]="!row.archived" (ngModelChange)="archiving($event, row.id)">
              </ui-switch>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'item.nomenclatures.nomenclatures-list.datatable.column.action' | translate }}"
          headerClass="center-align"
          cellClass="center-align top-align"
          [flexGrow]="0.25"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="green-zone" (click)="$event.stopPropagation()">
              <ui-menu-actions [menuActions]="this.menuAction" (actionCalled)="manageActions($event, row.id)">
              </ui-menu-actions>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>

  <app-retail-item-nomenclature-popup
    *ngIf="popupVisible"
    [(nomenclature)]="changingNomenclature"
    (close)="closePopup()"
    (validate)="validatePopup()"
  >
  </app-retail-item-nomenclature-popup>
</ng-container>
