<form class="forgotten-password-popup-form" [formGroup]="popupForm" novalidate>
  <div class="popup-container">
    <ui-popup title="{{ 'login-form.forgotten-password' | translate }}" (close)="closePopup()">
      <div content>
        <div class="grid-container">
          <div class="grid-item">
            <div class="text" [innerHTML]="'forgotten-password-popup.content' | translate"></div>
          </div>

          <div class="grid-item email">
            <ui-input-text
              type="text"
              mandatory="true"
              formControlName="email"
              errorMessage="{{ getEmailErrorMessage() | translate }}"
              label="{{ 'forgotten-password-popup.fields.email' | translate }}"
              status="{{ popupForm.controls['email'].valid || !popupForm.controls['email'].touched ? null : 'error' }}"
            >
            </ui-input-text>
          </div>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'forgotten-password-popup.buttons.validate' | translate }}"
                type="classic"
                [disabled]="buttonDisabled"
                [primary]="true"
                [btnIcon]="faArrow"
                (click)="submitPopup()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</form>
