import { Comparator } from "fugu-common";

export class MultiShipmentFormState extends Comparator {
  recipient: number;
  supplier: number;
  customer: number;
  store: number;
  shipmentFormIds: number[];
  forwardingReference: string;
  packageReference: string;
  packagingComment: string;

  constructor(values: any = {}) {
    super();
    this.recipient = values.recipient;
    this.supplier = values.supplier;
    this.customer = values.customer;
    this.store = values.store;
    this.shipmentFormIds = values.shipmentFormIds;
    this.forwardingReference = values.forwardingReference;
    this.packageReference = values.packageReference;
    this.packagingComment = values.packagingComment;

    if (values.shipmentFormIds) {
      this.shipmentFormIds = values.shipmentFormIds.map(id => id);
    }
  }
}
