<ng-container>
  <div>
    <ui-popup title="{{ 'stock-priorization.popup.title' | translate }}" (close)="canClosePopup()">
      <div content>
        <p class="popup-text">{{ "stock-priorization.popup.text" | translate }}</p>
        <div cdkDropList class="stock-list" (cdkDropListDropped)="drop($event)">
          <div #onDragCss class="stock-box" *ngFor="let location of rootLocations" cdkDrag>
            <div class="drag-handle" cdkDragHandle>
              {{ location.label }} <span #onDragIconCss><fa-icon class="grip-icon" [icon]="faGrip"></fa-icon></span>
            </div>
          </div>
        </div>
      </div>

      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <ui-button
              text="{{ 'stock-priorization.buttons.validate' | translate }}"
              (click)="submitStockPopup()"
              [primary]="true"
              type="validate"
            ></ui-button>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
