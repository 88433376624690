import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { IconDefinition, faWarning } from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: "app-invoice-date-warning-popup",
  templateUrl: "./invoice-date-warning-popup.component.html",
  styleUrls: ["./invoice-date-warning-popup.component.scss"],
})
export class InvoiceDateWarningPopupComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public faWarn: IconDefinition = faWarning;

  public popupForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.popupForm = this.fb.group({
      print: [false],
    });
  }

  validatePopup(): void {
    this.validate.emit(this.popupForm.value.print);
    this.closePopup();
  }

  closePopup(): void {
    this.popupForm.reset();
    this.close.emit();
  }
}
