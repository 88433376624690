<ng-container>
  <div class="main-container">
    <div class="filters field border-bottom-filters" *ngIf="filterer">
      <ui-dynamic-filters
        [filters]="filterer.getFilters()"
        [(filterValues)]="filterer.filterValues"
        (apply)="applyFilters()"
        label="{{ 'filters.label' | translate }}"
        minLabel="{{ 'filters.min' | translate }}"
        maxLabel="{{ 'filters.max' | translate }}"
        applyLabel="{{ 'filters.apply' | translate }}"
        deleteLabel="{{ 'filters.delete' | translate }}"
        placeholderPrefix="{{ 'filters.prefix' | translate }} "
      >
      </ui-dynamic-filters>
    </div>

    <div class="supplier-stores-table">
      <div class="top-table-buttons info top-buttons-marge">
        <ui-button
          [text]="'customer-stores-list.add-button' | translate"
          [type]="'add'"
          *hasPermission="['STORE_CREATE']"
          (click)="newStore()"
        ></ui-button>
      </div>
      <ngx-datatable
        #table
        class="material"
        rowHeight="40"
        headerHeight="auto"
        footerHeight="50"
        columnMode="force"
        [trackByProp]="'id'"
        [sorts]="sorts"
        [rows]="rows"
        [count]="pager.totalElements"
        [offset]="pager.number"
        [limit]="pager.size"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down'
        }"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
        (activate)="viewStore($event)"
      >
        <ngx-datatable-column
          name="{{ 'supplier-stores-list.datatable.columns.reference' | translate }}"
          prop="ref"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div class="store-row">{{ value }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'supplier-stores-list.datatable.columns.name' | translate }}"
          prop="name"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div class="store-row">{{ value }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'supplier-stores-list.datatable.columns.city' | translate }}"
          prop="city"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div class="store-row">{{ value }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'supplier-stores-list.datatable.columns.status' | translate }}"
          prop="status"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"></ui-status-label>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="rowCount">
              {{
                rowCount
                  | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                  | translate: { rowCount: rowCount }
              }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="changePage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</ng-container>
