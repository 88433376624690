<ng-container>
  <div class="main-container">
    <div class="top-table-buttons" *ngIf="canAdd">
      <ui-button text="{{ 'employees-list.datatable.add-btn-text' | translate }}" type="add" (click)="addUser($event)">
      </ui-button>
    </div>
    <div [formGroup]="tableControl">
      <ngx-datatable
        #table
        [rows]="rows"
        [trackByProp]="'id'"
        class="material"
        columnMode="flex"
        headerHeight="auto"
        footerHeight="50"
        rowHeight="40"
        [limit]="15"
        (activate)="updateUser($event)"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down'
        }"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        [sorts]="sorts"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
      >
        <ngx-datatable-column
          name="{{ 'employees-list.datatable.columns.last-name' | translate }}"
          prop="lastName"
          cellClass="lastName"
          [flexGrow]="1"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'employees-list.datatable.columns.first-name' | translate }}"
          prop="firstName"
          cellClass="firstName"
          [flexGrow]="1"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'employees-list.datatable.columns.user-number' | translate }}"
          prop="userNumber"
          cellClass="userNumber"
          [flexGrow]="1"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'employees-list.datatable.columns.jobs' | translate }}"
          prop="jobs"
          cellClass="jobs ellipsis-counter"
          [flexGrow]="1"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-jobs="row.jobs">
            <p class="ellipsis">{{ jobs.join(", ") }}</p>
            <div *ngIf="jobs.length >= 2">
              <div #categoryCounter>
                <ui-counter [number]="jobs.length"></ui-counter>
              </div>
              <ui-tooltip maxWidth="300px" [element]="categoryCounter">
                <span style="font-weight: normal !important; white-space: break-spaces">{{ jobs.join(", ") }}</span>
              </ui-tooltip>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'employees-list.datatable.columns.email' | translate }}"
          prop="email"
          cellClass="email"
          [flexGrow]="1"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'employees-list.datatable.columns.phone' | translate }}"
          prop="phone"
          cellClass="phone"
          [flexGrow]="1"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'employees-list.datatable.columns.mobile-phone' | translate }}"
          prop="mobilePhone"
          cellClass="mobilePhone"
          [flexGrow]="1"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'employees-list.datatable.columns.supervisor' | translate }}"
          prop="supervisor"
          cellClass="supervisor center-align"
          headerClass="double-line-center"
          [flexGrow]="1"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
            <div *ngIf="row.activated">
              <div class="green-zone" (click)="$event.stopPropagation()">
                <ui-checkbox
                  customClass="no-margin"
                  (change)="supervisorOnChanges(id)"
                  [formControl]="tableControl.get('supervisor_' + id)"
                >
                </ui-checkbox>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'employees-list.datatable.columns.activated' | translate }}"
          prop="activated"
          headerClass="center-align"
          cellClass="center-align"
          [flexGrow]="0.6"
          [resizeable]="false"
        >
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-id="row.id"
            let-rowIndex="rowIndex"
            let-rowProp="column.prop"
          >
            <div class="green-zone" (click)="$event.stopPropagation()">
              <ui-switch
                customClass="no-margin"
                (change)="checkboxOnChanges(id)"
                [formControl]="tableControl.get('activated_' + id)"
              >
              </ui-switch>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="rowCount">
              {{
                rowCount
                  | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                  | translate: { rowCount: rowCount }
              }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="table.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>

  <!-- Popup -->
  <app-employees-popup
    *ngIf="popupVisible"
    [user]="selectedUser"
    [storeId]="storeId"
    [storeName]="storeName"
    (validate)="onValidateUser($event)"
    (close)="onCloseUserPopup()"
  ></app-employees-popup>
</ng-container>
