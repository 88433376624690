import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { IconDefinition, faCircleArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  Inventory,
  Pagination,
  Store,
  CaraUserService,
  InventoryService,
  LightService,
  InventoryStatus,
  Currency,
  CurrencyService,
  CaraUser,
  Light,
  PaginatedList,
  Sort,
  AuthService,
  StoreService,
} from "center-services";
import { Filterer, FilterValue, Option, SearchFilter, SessionPagination, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-inventory-list",
  templateUrl: "./inventory-list.component.html",
  styleUrls: ["./inventory-list.component.scss"],
  providers: [SubscriptionService],
})
export class InventoryListComponent extends FilteredTableListComponent implements OnInit, OnDestroy {
  @ViewChild("inventoryFilters") inventoryFilters: any;
  @ViewChild("table") table: any;

  public defaultCurrency: Currency;

  public LIST_ID: string = "app-inventory-list.app-inventory-table";

  public inventoryList: Inventory[] = [];
  public dateFormat: string;
  public codeLanguage: string;
  public mainStore: Store;
  public contextStore: Light;
  public isConnectedToMainStore: boolean;
  public rows: any[] = [];
  public sorts: any[] = [{ prop: "statusLabel", dir: "asc" }];
  public storeOptions: Option[] = [];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public storeList: Store[] = [];
  public initiatorPopupVisible: boolean = false;
  faCircleArrowRight: IconDefinition = faCircleArrowRight;
  private initObservables: Observable<any>[] = [];
  private statusIndexes: string[] = [];
  private statusTranslations: { [key: string]: string } = {};
  private sessionPagination: SessionPagination;

  constructor(
    protected userService: CaraUserService,
    translateService: TranslateService,
    messageService: MessageService,
    private inventoryService: InventoryService,
    private lightService: LightService,
    private router: Router,
    private currencyService: CurrencyService,
    private authService: AuthService,
    private storeService: StoreService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
    this.statusIndexes = [
      this.translateService.instant("inventory-list.datatable.status.IN_PROGRESS"),
      this.translateService.instant("inventory-list.datatable.status.FINISHED"),
    ];

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      InventoryStatus,
      "inventory-list.datatable.status",
      this.statusTranslations
    );
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.codeLanguage = this.userService.connectedUser.value.codeLanguage;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }
    this.initObservables.push(this.fetchLightStores());
    this.initObservables.push(this.fetchDefaultCurrency());
    this.initObservables.push(this.fetchMainStore());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        const selectedStoreId = this.authService.getContextStoreId();
        this.contextStore = this.storeList.find((store: Light) => store.id === selectedStoreId);
        this.isConnectedToMainStore = this.contextStore.id === this.mainStore.id;
        this.initFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.computeSearchFilters();
        this.fetchInventory();
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      this.sessionPagination.saveToSession(this.LIST_ID);
    }
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.dateFormat = connectedUser.dateFormat;
        this.codeLanguage = connectedUser.codeLanguage;
      })
    );
  }

  fetchLightStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (stores: Store[]) => {
          this.storeList = stores;
          this.storeOptions = stores
            .filter((obj: Store) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Store) => new Option(obj.id, obj.name));
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-stores`, error.message);
        }
      )
    );
  }

  fetchDefaultCurrency(): Observable<Currency> {
    return this.currencyService.getDefault().pipe(
      tap(
        (currency: Currency) => {
          this.defaultCurrency = currency;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-stores`, error.message);
        }
      )
    );
  }

  fetchInventory(): void {
    this.subscriptionService.subs.push(
      this.inventoryService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<Inventory>) => {
          this.rows = [];
          this.inventoryList = result.data;
          this.pager = result.page;

          result.data.forEach((inventory: Inventory) => {
            this.addRow(inventory);
          });
          this.rows = [...this.rows];
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
        }
      )
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-main-store`, error.message);
        }
      )
    );
  }

  addRow(inventory: Inventory): void {
    const statusLabel = this.translateService.instant(`inventory-list.datatable.status.${inventory.status}`);

    const row = {
      inventoryNumber: inventory.id,
      internalCode: this.storeList.find(store => store.id === inventory.storeId).reference,
      name: inventory.name,
      perimeter: this.storeList.find(store => store.id === inventory.storeId).name,
      accessForbidden: this.isConnectedToMainStore && inventory.storeId !== this.mainStore.id,
      beginDate: inventory.beginDate,
      endDate: inventory.endDate,
      quantityInventory: inventory.totalInventoryQuantity,
      quantityStock: inventory.totalSnapshotQuantity,
      inventoryDifference: inventory.totalPriceDifference,
      status: inventory.status,
      statusClass: this.getStatusClass(inventory.status),
      statusIndex: this.statusIndexes.indexOf(statusLabel),
      statusLabel,
    };

    this.rows.push(row);
  }

  createInventory(): void {
    if (!this.userService.canDo("INVENTORY_CREATE")) {
      return;
    }
    this.initiatorPopupVisible = true;
  }

  closeInitiatorPopup(): void {
    this.initiatorPopupVisible = false;
  }

  public applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchInventory();
      })
    );
  }

  public changeSortSettings(prop: string, dir: string): void {
    switch (prop) {
      case "statusLabel":
        this.sorts = [{ prop: "statusLabel", dir }];
        break;
      default:
        this.sorts = [
          { prop: "statusLabel", dir: "desc" },
          { prop, dir },
        ];
        break;
    }

    this.fetchInventory();
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "statusLabel":
        return "status";
      case "perimeter":
        return "store.name";
      case "internalCode":
        return "store.reference";
      case "inventoryNumber":
        return "id";
      case "quantityInventory":
        return "totalInventoryQuantity";
      case "quantityStock":
        return "totalSnapshotQuantity";
      case "inventoryDifference":
        return "totalPriceDifference";
      default:
        return prop;
    }
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchInventory();
  }

  getTranslationPrefix(): string {
    return "inventory-list";
  }

  updateInventory(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("INVENTORY_UPDATE") || event.row.accessForbidden) {
        return;
      }
      const inventoryFound: Inventory = this.inventoryList.find(
        inventory => inventory.id === event.row.inventoryNumber
      );
      if (!inventoryFound) {
        console.error(`can't find inventory with id ${event.row.inventoryNumber}`);
        return;
      }
      if (inventoryFound.status === InventoryStatus.FINISHED) {
        return;
      }
      this.router.navigateByUrl(`/inventory/update/${event.row.inventoryNumber}`);
    }
  }

  getColumnTranslation(columnName: string): string {
    return `${this.getTranslationPrefix()}.datatable.columns.${columnName}`;
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  getRowClass(row: any): any {
    return { "not-clickable": row.status === InventoryStatus.FINISHED || row.accessForbidden };
  }

  protected computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  private initFilters(): void {
    if (this.filterer) {
      return;
    }

    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "store.reference",
      this.translateService.instant(this.getColumnTranslation("internal-code")),
      "string"
    );

    this.filterer.addFilter("name", this.translateService.instant(this.getColumnTranslation("name")), "string");

    this.filterer.addListFilter(
      "store.name",
      this.translateService.instant(this.getColumnTranslation("perimeter")),
      this.isConnectedToMainStore
        ? this.storeList
          .map((store: Store) => {
            return { value: store.name, displayValue: store.name };
          })
          .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
        : [{ value: this.contextStore.name, displayValue: this.contextStore.name }],
      true,
      false,
      this.isConnectedToMainStore ? [this.mainStore.name] : [this.contextStore.name],
      null,
      true
    );

    this.filterer.addFilter(
      "beginDate",
      this.translateService.instant(this.getColumnTranslation("begin-date")),
      "date"
    );

    this.filterer.addFilter("endDate", this.translateService.instant(this.getColumnTranslation("end-date")), "date");

    this.filterer.addListFilter(
      "status",
      this.translateService.instant("retail-item-list.datatable.columns.status"),
      Object.keys(InventoryStatus).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );

    this.filterer.addFilter(
      "totalInventoryQuantity",
      this.translateService.instant("inventory-list.datatable.columns.quantity-inventory"),
      "range"
    );

    this.filterer.addFilter(
      "totalSnapshotQuantity",
      this.translateService.instant("inventory-list.datatable.columns.quantity-stock"),
      "range"
    );

    this.filterer.addFilter(
      "totalPriceDifference",
      this.translateService.instant("inventory-list.datatable.columns.inventory-difference"),
      "range"
    );
  }

  private getStatusClass(status: InventoryStatus): string {
    return `status-${status.toLowerCase().replace("_", "-")}`;
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }
}
