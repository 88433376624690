import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faArrowCircleRight, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import {
  AbstractItem,
  AbstractReceivingLine,
  CaraUserService,
  Currency,
  CurrencyService,
  DeliveryLineStatus,
  DeliveryType,
  InvoiceCustomerBatch,
  InvoiceCustomerMultiple,
  InvoiceCustomerService,
  Light,
  LightService,
  PaginatedList,
  Pagination,
  PricingGroup,
  PricingGroupService,
  PurchaseOrder,
  PurchaseOrderLine,
  PurchaseOrderService,
  ReceivingForm,
  ReceivingFreeLine,
  ReceivingInternalLine,
  ReceivingPOLine,
  RetailItemService,
  StockEntry,
  StockEntryService,
  Store,
  StoreService,
} from "center-services";
import dayjs from "dayjs";
import { DayjsUtil, RoundingUtil, SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import { ToastMessageService } from "fugu-components";
import { Observable, combineLatest, from } from "rxjs";
import { concatMap, mergeMap, tap, toArray } from "rxjs/operators";
import { InvoiceCustomerCommonService } from "./../../../service/invoice-customer-common.service";

@Component({
  selector: "app-batch-generate-popup",
  templateUrl: "./batch-generate-popup.component.html",
  styleUrls: ["./batch-generate-popup.component.scss"],
  providers: [SubscriptionService],
})
export class BatchGeneratePopupComponent implements OnInit, OnDestroy {
  private static STORE_TRANSLATION_KEY: string = "batch-generate-popup.datatable.columns.store";
  private static CARASQL_EXCEPTION: string = "CaraSQLException";
  private static EXCEPTION_CONSTRAINT: string = "invoiceCustomerAnteriorDate";
  @Input() deliveryFormList: ReceivingForm[] = [];
  @Input() oneInvoicePerStore: boolean;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() selectedDeliveryFormsChange: EventEmitter<ReceivingForm[]> = new EventEmitter<ReceivingForm[]>();
  @Output() oneInvoicePerStoreChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("table") table: DatatableComponent;

  public sorts: any[] = [
    { prop: "customer", dir: "asc" },
    { prop: "store", dir: "asc" },
  ];

  public popupForm: UntypedFormGroup;
  public dateFormat: string;
  public locale: string;
  public totalGrossPrice: number;
  public rows: any[] = [];
  public storeList: Light[] = [];
  public customerList: Light[] = [];
  public stockEntryList: StockEntry[] = [];
  public retailItemList: AbstractItem[] = [];
  public purchaseOrderList: PurchaseOrder[];
  public pricingGroupList: PricingGroup[];
  public currentPricingGroupByStore: Map<number, PricingGroup> = new Map();
  public currency: Currency;
  public map: Map<number, Set<number>>;

  faChevronLeft: IconDefinition = faChevronLeft;
  faArrowCircleRight: IconDefinition = faArrowCircleRight;

  public initialInvoiceCustomerBatch: InvoiceCustomerBatch;
  public unsavedInvoiceCustomerBatch: InvoiceCustomerBatch;
  public selectedInvoiceCustomerBatch: InvoiceCustomerBatch;
  public shouldClose: boolean = false;

  private receiverIds: number[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private userService: CaraUserService,
    private lightService: LightService,
    private messageService: ToastMessageService,
    private translateService: TranslateService,
    private currencyService: CurrencyService,
    private invoiceCustomerService: InvoiceCustomerService,
    private stockEntryService: StockEntryService,
    private retailItemService: RetailItemService,
    private purchaseOrderService: PurchaseOrderService,
    private pricingGroupService: PricingGroupService,
    private storeService: StoreService,
    private commonService: InvoiceCustomerCommonService,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.locale = this.userService.connectedUser.value.codeLanguage;
    } else {
      this.subscriptionService.subs.push(
        this.userService.connectedUser.subscribe(user => {
          this.dateFormat = user.dateFormat;
          this.locale = user.codeLanguage;
        })
      );
    }
    this.map = new Map<number, Set<number>>();
    this.receiverIds = [];
    const data = this.fillIdsObj(this.deliveryFormList);
    this.deliveryFormList.forEach((deliveryForm: ReceivingForm) => {
      if (this.map.has(deliveryForm.receiverCustomerId)) {
        this.map.get(deliveryForm.receiverCustomerId).add(deliveryForm.receiverId);
      } else {
        const set = new Set<number>();
        set.add(deliveryForm.receiverId);
        this.map.set(deliveryForm.receiverCustomerId, set);
      }
      if (DeliveryType.EXTERNAL_SUPPLIER === deliveryForm.type && deliveryForm.receiverId) {
        this.receiverIds.push(deliveryForm.receiverId);
      }
    });
    const obs: Observable<any>[] = [];
    // case 1 : data BL (maintore -> store) & external customer (maintore -> customer)
    const hasInternalOrExternalCustomer = this.deliveryFormList.some(
      deliveryForm =>
        DeliveryType.INTERNAL === deliveryForm?.type || DeliveryType.EXTERNAL_CUSTOMER === deliveryForm?.type
    );
    if (hasInternalOrExternalCustomer) {
      obs.push(this.fetchStockEntries(data.stEIds));
    }
    // case 2 : data from POL/PM : external supplier (supplier -> store)
    const hasExternalSupplier = this.deliveryFormList.some(
      deliveryForm => DeliveryType.EXTERNAL_SUPPLIER === deliveryForm.type
    );
    if (hasExternalSupplier) {
      const { ...dataSupplier } = { ...data };
      obs.push(this.fetchLinkedItems(dataSupplier));
    }
    if (this.receiverIds.length > 0) {
      obs.push(this.fetchReceiver(this.receiverIds));
    }
    obs.push(this.fetchCustomers());
    obs.push(this.fetchStores());
    obs.push(this.fetchDefaultCurrency());

    this.subscriptionService.subs.push(
      combineLatest(obs).subscribe(() => {
        this.initializePopup();
      })
    );
  }

  ngOnDestroy(): void {
    this.selectedDeliveryFormsChange.emit(this.deliveryFormList);
    this.oneInvoicePerStoreChange.emit(this.oneInvoicePerStore);
  }

  public prepareForm(): void {
    this.popupForm = this.fb.group({
      date: [null, [Validators.required]],
      oneInvoicePerStore: [null],
      autoValidate: [false],
      print: [false],
    });
  }

  public initializePopup(): void {
    this.popupForm.controls.date.setValue(DayjsUtil.dayjsOrNull(new Date(), true));
    this.popupForm.controls.oneInvoicePerStore.setValue(this.oneInvoicePerStore);
    this.computeTotalGrossPrice();
    this.popupForm.controls.print.disable();
    this.buildRows();

    const date: Date = this.popupForm.value.date.toDate();

    this.selectedInvoiceCustomerBatch = new InvoiceCustomerBatch({
      deliveryFormIds: this.deliveryFormList.map(df => df.id),
      invoiceDate: date,
      onePerStore: this.oneInvoicePerStore,
      validation: this.popupForm.value.autoValidate,
    });

    this.initialInvoiceCustomerBatch = new InvoiceCustomerBatch(this.selectedInvoiceCustomerBatch);
  }

  public buildRows(): void {
    this.rows = [];
    this.map.forEach((value, key) => {
      const customer = this.customerList.find((elem: Light) => elem.id === key);
      if (this.oneInvoicePerStore) {
        value.forEach(v => {
          const store = this.storeList.find((elem: Light) => elem.id === v);
          const row = {
            customer: customer.name,
            store: store ? store.name : "",
            currencyIsoCode: this.currency.codeISO,
            grossPrice: this.computeRowGrossPrice(store ? store.id : customer.id, true),
          };
          this.rows.push(row);
        });
      } else {
        const row = {
          customer: customer.name,
          store: null,
          currencyIsoCode: this.currency.codeISO,
          grossPrice: this.computeRowGrossPrice(customer.id, false),
        };
        this.rows.push(row);
      }
    });
    this.rows = [...this.rows];
    this.computeTotalGrossPrice();
  }

  public onSwitchChange(): void {
    this.oneInvoicePerStore = this.popupForm.value.oneInvoicePerStore;
    this.buildRows();
  }

  public onCheckboxChange(): void {
    if (this.popupForm.value.autoValidate) {
      this.popupForm.controls.print.enable();
      return;
    }
    this.popupForm.controls.print.patchValue(false);
    this.popupForm.controls.print.disable();
  }

  public computeTotalGrossPrice(): void {
    let price = 0;
    this.rows.forEach(row => {
      price += row.grossPrice;
    });
    this.totalGrossPrice = RoundingUtil.roundLow(price);
  }

  public computeRowGrossPrice(id: number, storeLine: boolean): number {
    let price = 0;
    if (storeLine) {
      const deliveryFormsSelected = this.deliveryFormList.filter(
        (deliveryForm: ReceivingForm) => deliveryForm.receiverId === id
      );
      deliveryFormsSelected.forEach(df => {
        price += this.computeGrossPricePerDeliveryForm(df);
      });
    } else {
      const deliveryFormsSelected = this.deliveryFormList.filter(
        (deliveryForm: ReceivingForm) => deliveryForm.receiverCustomerId === id
      );
      deliveryFormsSelected.forEach(df => {
        price += this.computeGrossPricePerDeliveryForm(df);
      });
    }
    return RoundingUtil.roundLow(price);
  }

  public computeGrossPricePerDeliveryForm(deliveryForm: ReceivingForm): number {
    let rowGrossPrice = 0;

    deliveryForm.lines
      .filter(
        line =>
          !line.invoiced && line.status !== DeliveryLineStatus.REFUSED && line.status !== DeliveryLineStatus.PENDING
      )
      .forEach((line: AbstractReceivingLine) => {
        let stockEntry = null;

        if (this.stockEntryList?.length > 0 && line instanceof ReceivingInternalLine) {
          stockEntry = this.stockEntryList.find((se: StockEntry) => se?.id === line?.stockEntryId);
        }

        let retailItem = null;
        if (stockEntry && this.retailItemList?.length > 0) {
          retailItem = this.retailItemList.find((item: AbstractItem) => item?.id === stockEntry?.retailItemId);
        }

        let polForPm = null;
        // data from PO-> POL -> PM
        if (this.purchaseOrderList?.length > 0 && line instanceof ReceivingPOLine) {
          const purchaseOrder = this.purchaseOrderList.find((po: PurchaseOrder) =>
            po?.lines.find((pol: PurchaseOrderLine) => pol?.id === line?.purchaseOrderLineId)
          );
          if (purchaseOrder) {
            polForPm = purchaseOrder?.lines.find((pol: PurchaseOrderLine) => pol?.id === line?.purchaseOrderLineId);
            retailItem = this.getItemFromPM(polForPm?.purchaseModalityId);
          }
        }

        // data from PM
        if (line instanceof ReceivingFreeLine) {
          retailItem = this.getItemFromPM(line?.purchaseModalityId);
        }
        const unitPrice = this.commonService.computeMarginForUnitPrice(
          this.currentPricingGroupByStore.get(deliveryForm.receiverId),
          line,
          retailItem
        );
        rowGrossPrice += unitPrice * line.expectedQuantity;
      });
    return RoundingUtil.roundLow(rowGrossPrice);
  }

  public closePopup(event: boolean): void {
    this.applyModifications();

    if (
      this.unsavedInvoiceCustomerBatch &&
      !this.unsavedInvoiceCustomerBatch.equals(this.selectedInvoiceCustomerBatch)
    ) {
      this.shouldClose = false;
    }

    if (!this.initialInvoiceCustomerBatch.equals(this.selectedInvoiceCustomerBatch) && !this.shouldClose) {
      this.messageService.generateMessage("info", "global.errors.unsaved-title", "global.errors.unsaved-popin-content");

      this.unsavedInvoiceCustomerBatch = new InvoiceCustomerBatch(this.selectedInvoiceCustomerBatch);
      this.shouldClose = true;
    } else {
      this.close.emit(event);
    }
  }

  public getStoreLabel(): string {
    return this.oneInvoicePerStore ? BatchGeneratePopupComponent.STORE_TRANSLATION_KEY : "";
  }

  public applyModifications(): void {
    const date: Date = this.popupForm.value.date.toDate();
    this.selectedInvoiceCustomerBatch.invoiceDate = date;
    this.selectedInvoiceCustomerBatch.onePerStore = this.oneInvoicePerStore;
    this.selectedInvoiceCustomerBatch.validation = this.popupForm.value.autoValidate;
  }

  public submit(): void {
    this.applyModifications();

    this.subscriptionService.subs.push(
      this.submitBatch(this.selectedInvoiceCustomerBatch).subscribe(() => {
        this.messageService.generateMessage("success", "message.title.save-success", "message.content.save-success");
        this.close.emit(true);
      })
    );
  }

  public submitBatch(invoiceCustomerBatch: InvoiceCustomerBatch): Observable<InvoiceCustomerMultiple> {
    return this.invoiceCustomerService.batch(invoiceCustomerBatch).pipe(
      tap({
        next: (invoiceCustomerMultiple: InvoiceCustomerMultiple) => {
          if (this.popupForm.value.print) {
            this.handlePrintBatch(invoiceCustomerMultiple);
          }
        },
        error: error => {
          if (
            error.error.exception.name === BatchGeneratePopupComponent.CARASQL_EXCEPTION &&
            error.error.exception.parameters.constraint === BatchGeneratePopupComponent.EXCEPTION_CONSTRAINT
          ) {
            this.popupForm.markAllAsTouched();
            this.popupForm.controls.date.setErrors({ date: true });
            return;
          } else {
            this.messageService.generateMessage(
              "error",
              "message.title.data-errors",
              "batch-generate-popup.errors.batch"
            );
          }
          this.messageService.generateMessage(
            "error",
            "message.title.data-errors",
            "batch-generate-popup.errors.batch"
          );
        },
      })
    );
  }

  public handlePrintBatch(invoiceCustomerMultiple: InvoiceCustomerMultiple): void {
    this.subscriptionService.subs.push(
      this.invoiceCustomerService.batchPdf(invoiceCustomerMultiple).subscribe(
        (response: any) => {
          const documentPrefix = this.translateService.instant("batch-generate-popup.document-prefix");
          const currentDate = dayjs(new Date()).format(this.dateFormat).split(" ")[0];
          const blob = new Blob([response], {
            type: response.type === "application/pdf" ? "application/pdf" : "application/zip",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");

          // prepare file for download
          const documentName = `${documentPrefix}_${currentDate}`;
          a.download = documentName.replace(/[^A-Z0-9]+/gi, "_").toLowerCase();
          a.href = url;
          a.click();
        },
        () => {
          this.messageService.generateMessage("error", "message.title.data-errors", "global.server-errors.pdf");
        }
      )
    );
  }

  fetchCustomers(): Observable<Light[]> {
    return this.lightService.getCustomers().pipe(
      tap(
        (lightCustomers: Light[]) => {
          this.customerList = lightCustomers;
        },
        () => {
          this.messageService.generateMessage(
            "error",
            "message.title.data-errors",
            "batch-generate-popup.errors.get-customers"
          );
        }
      )
    );
  }

  fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeList = lightStores;
        },
        () => {
          this.messageService.generateMessage(
            "error",
            "message.title.data-errors",
            "batch-generate-popup.errors.get-stores"
          );
        }
      )
    );
  }

  fetchDefaultCurrency(): Observable<Currency> {
    return this.currencyService.getDefault().pipe(
      tap(
        (currency: Currency) => {
          this.currency = currency;
        },
        () => {
          this.messageService.generateMessage(
            "error",
            "message.title.data-errors",
            "batch-generate-popup.errors.get-currency"
          );
        }
      )
    );
  }

  fetchStockEntries(stockEntryIdList: Set<number>): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: stockEntryIdList.size,
      number: 0,
    });
    const filters = new SearchFilter("id", SearchFilterOperator.IN, [...stockEntryIdList]);

    return this.stockEntryService.getAll(pager, [], [filters]).pipe(
      tap({
        error: () => {
          this.messageService.generateMessage(
            "error",
            "message.title.data-errors",
            "batch-generate-popup.errors.get-stock-entries"
          );
        },
      }),
      mergeMap((result: PaginatedList<StockEntry>) => {
        result.data.forEach(stockEntry => {
          if (!this.stockEntryList.includes(stockEntry)) {
            this.stockEntryList.push(stockEntry);
          }
        });

        const retailItemIds = new Set<number>();
        this.stockEntryList.forEach((stockEntry: StockEntry) => {
          retailItemIds.add(stockEntry?.retailItemId);
        });
        return this.fetchItemsWithIds(retailItemIds, false);
      })
    );
  }

  fetchItemsWithIds(idList: Set<number>, havePm: boolean = true): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: idList.size,
      number: 0,
    });

    let filters = null;
    if (havePm) {
      filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, [...idList]);
    } else {
      filters = new SearchFilter("id", SearchFilterOperator.IN, [...idList]);
    }

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          result.data.forEach(item => {
            if (!this.retailItemList.includes(item)) {
              this.retailItemList.push(item);
            }
          });
        },
        () => {
          this.messageService.generateMessage(
            "error",
            "message.title.data-errors",
            "batch-generate-popup.errors.get-items"
          );
        }
      )
    );
  }

  fetchLinkedItems(idsObj: { pmIds: Set<number>; polIds: Set<number> }): Observable<PaginatedList<AbstractItem>> {
    const polIdList = idsObj.polIds;
    const pmIdList = idsObj.pmIds;

    if (polIdList.size > 0) {
      const filters = [new SearchFilter("lines.id", SearchFilterOperator.IN, [...polIdList])];
      const pager = new Pagination({
        size: polIdList.size,
        number: 0,
      });

      return this.purchaseOrderService.getAll(pager, [], filters).pipe(
        tap({
          error: () => {
            this.messageService.generateMessage(
              "error",
              "message.title.data-errors",
              "batch-generate-popup.errors.get-purchase-orders"
            );
          },
        }),
        mergeMap((result: PaginatedList<PurchaseOrder>) => {
          this.purchaseOrderList = result.data;
          this.purchaseOrderList.forEach((purchaseOrder: PurchaseOrder) => {
            purchaseOrder.lines.forEach((pol: PurchaseOrderLine) => {
              pmIdList.add(pol.purchaseModalityId);
            });
          });

          return this.fetchItemsWithIds(pmIdList);
        })
      );
    } else {
      return this.fetchItemsWithIds(pmIdList);
    }
  }

  public getItemFromPM(pmId: number): AbstractItem {
    for (const item of this.retailItemList) {
      if (item?.purchaseModalities) {
        for (const pm of item.purchaseModalities ?? []) {
          if (pm.id === pmId) {
            return item;
          }
        }
      }
    }
    return null;
  }

  fetchPricingGroups(store: Store): Observable<PricingGroup> {
    return this.pricingGroupService.get(store.pricingGroupId).pipe(
      tap(
        (pricingGroup: PricingGroup) => {
          this.currentPricingGroupByStore.set(store.id, pricingGroup);
        },
        () => {
          this.messageService.generateMessage(
            "error",
            "message.title.data-errors",
            "batch-generate-popup.errors.get-pricing-group"
          );
        }
      )
    );
  }
  fetchReceiver(receiverIds: number[]): Observable<PricingGroup[]> {
    return this.storeService
      .getAll(
        new Pagination({ number: 0, size: receiverIds.length }),
        [],
        [new SearchFilter("id", SearchFilterOperator.IN, receiverIds)]
      )
      .pipe(
        tap({
          error: () => {
            this.messageService.generateMessage(
              "error",
              "message.title.data-errors",
              "batch-generate-popup.errors.get-store"
            );
          },
        }),
        mergeMap((response: PaginatedList<Store>) => {
          return from(response.data).pipe(
            concatMap((store: Store) => this.fetchPricingGroups(store)),
            toArray()
          );
        })
      );
  }

  getRowClass: any = (): any => ({ "not-clickable": true });

  private fillIdsObj(receivingFormFounds: ReceivingForm[]): {
    pmIds: Set<number>;
    polIds: Set<number>;
    stEIds: Set<number>;
  } {
    const pmIds = new Set<number>();
    const polIds = new Set<number>();
    const stEIds = new Set<number>();

    if (receivingFormFounds?.length > 0) {
      receivingFormFounds.forEach((deliveryForm: ReceivingForm) => {
        deliveryForm.lines.forEach((deliveryLine: AbstractReceivingLine) => {
          if (deliveryLine instanceof ReceivingFreeLine) {
            pmIds.add(deliveryLine?.purchaseModalityId);
          } else if (deliveryLine instanceof ReceivingPOLine) {
            polIds.add(deliveryLine?.purchaseOrderLineId);
          } else if (deliveryLine instanceof ReceivingInternalLine) {
            stEIds.add(deliveryLine?.stockEntryId);
          }
        });
      });
    }
    return { pmIds, polIds, stEIds };
  }
}
