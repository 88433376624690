<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link text="{{ 'user-form.buttons.back-button' | translate }}" [linkIcon]="faChevronLeft" (click)="back()">
      </ui-link>
    </div>
    <div class="title">
      <ui-title titleText="{{ 'user-form.title.' + title | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="validate-button">
        <div class="button">
          <ui-button text="{{ 'global.validate' | translate }}" type="save" [primary]="true" (click)="submitUser()">
          </ui-button>
        </div>
      </div>
    </div>
    <div class="form-content">
      <form class="user-form" [formGroup]="form" novalidate>
        <ui-container>
          <div class="grid-container">
            <div class="grid-item profiles">
              <ui-list
                label="{{ 'user-form.fields.profile' | translate }}"
                selectLabel="{{ 'user-form.fields.profile' | translate }}"
                [options]="this.profileOptions"
                formControlName="profile"
                (ngModelChange)="managePasswordAndEmailFields()"
                status="{{ form.get('profile') | formStatus }}"
                errorMessage="{{ form.get('profile') | formErrorMessage | translate }}"
                mandatory="true"
              >
                >
              </ui-list>
            </div>

            <div class="grid-item last-name">
              <ui-input-text
                type="text"
                label="{{ 'user-form.fields.last-name' | translate }}"
                formControlName="lastName"
                status="{{ form.get('lastName') | formStatus }}"
                errorMessage="{{ form.get('lastName') | formErrorMessage | translate }}"
                mandatory="true"
                (keyup)="concatLogin()"
              >
              </ui-input-text>
            </div>
            <div class="grid-item first-name">
              <ui-input-text
                type="text"
                label="{{ 'user-form.fields.first-name' | translate }}"
                formControlName="firstName"
                status="{{ form.get('firstName') | formStatus }}"
                errorMessage="{{ form.get('firstName') | formErrorMessage | translate }}"
                mandatory="true"
                (keyup)="concatLogin()"
              >
              </ui-input-text>
            </div>
            <div class="grid-item user-number">
              <ui-input-text
                type="text"
                label="{{ 'user-form.fields.user-number' | translate }}"
                formControlName="userNumber"
                status="{{ form.get('userNumber') | formStatus }}"
                errorMessage="{{ form.get('userNumber') | formErrorMessage | translate }}"
                mandatory="true"
                infoLabel="{{ 'global.unique' | translate }}"
              >
              </ui-input-text>
            </div>
            <div class="grid-item jobs">
              <ui-list
                label="{{ 'user-form.fields.job' | translate }}"
                [options]="this.jobOptions"
                [search]="true"
                formControlName="jobs"
                [multiple]="true"
              >
              </ui-list>
            </div>

            <div class="grid-item email">
              <ui-input-text
                *ngIf="!this.isAdmin"
                type="text"
                label="{{ 'user-form.fields.email' | translate }}"
                formControlName="email"
                status="{{ form.get('email') | formStatus }}"
                errorMessage="{{ form.get('email') | formErrorMessage | translate }}"
                infoLabel="{{ 'global.unique' | translate }}"
              >
              </ui-input-text>

              <ui-input-text
                *ngIf="this.isAdmin"
                type="text"
                label="{{ 'user-form.fields.email' | translate }}"
                formControlName="email"
                status="{{ form.get('email') | formStatus }}"
                errorMessage="{{ form.get('email') | formErrorMessage | translate }}"
                mandatory="true"
              >
              </ui-input-text>
            </div>
            <div class="grid-item">
              <div class="double-col">
                <div class="phone">
                  <ui-input-text
                    type="text"
                    label="{{ 'user-form.fields.phone' | translate }}"
                    formControlName="phone"
                    maskPattern="PN NN NN NN NN NN"
                    [customPattern]="pattern"
                    [specialCharacters]="[' ', '+']"
                    [dropSpecialCharacters]="[' ']"
                  >
                  </ui-input-text>
                </div>
                <div class="mobile-phone">
                  <ui-input-text
                    type="text"
                    label="{{ 'user-form.fields.mobile-phone' | translate }}"
                    formControlName="mobilePhone"
                    maskPattern="PN NN NN NN NN NN"
                    [customPattern]="pattern"
                    [specialCharacters]="[' ', '+']"
                    [dropSpecialCharacters]="[' ']"
                  >
                  </ui-input-text>
                </div>
              </div>
            </div>

            <div class="grid-item">
              <div class="double-col">
                <div class="login">
                  <ui-input-text
                    type="text"
                    label="{{ 'user-form.fields.login' | translate }}"
                    formControlName="login"
                    status="{{ form.get('login') | formStatus }}"
                    errorMessage="{{ form.get('login') | formErrorMessage | translate }}"
                    mandatory="true"
                    (keyup)="switchOffLoginAutoComplete()"
                    infoLabel="{{ 'global.unique' | translate }}"
                    [customPattern]="pattern"
                    maskPattern="Q*"
                  >
                  </ui-input-text>
                </div>

                <div class="pin-code">
                  <ui-input-text
                    type="text"
                    label="{{ 'user-form.fields.pin-code' | translate }}"
                    [infoLabel]="pinInfoLabel"
                    type="password"
                    formControlName="pinCode"
                    status="{{ form.get('pinCode') | formStatus }}"
                    errorMessage="{{
                      form.get('pinCode')
                        | formErrorMessage
                          : { customPrefix: 'user-form.errors.', useCustomPrefixFor: ['minlength', 'maxlength'] }
                        | translate
                    }}"
                    maxLength="4"
                    autocomplete="new-password"
                    [mandatory]="this.mandatoryPasswordFields.includes('pinCode')"
                    [generateBtn]="true"
                    [generateBtnTitle]="'user-form.fields.generate-pin-code' | translate"
                    (generate)="generatePinCode(false)"
                  >
                  </ui-input-text>
                </div>
              </div>
            </div>
            <div class="grid-item">
              <div class="double-col">
                <div class="temp-password">
                  <ui-input-text
                    #passwordField
                    label="{{ 'user-form.fields.temp-password' | translate }}"
                    [infoLabel]="infoLabel"
                    type="password"
                    formControlName="tempPassword"
                    (change)="shouldCheckPassword()"
                    status="{{ form.get('tempPassword') | formStatus }}"
                    errorMessage="{{
                      form.get('tempPassword')
                        | formErrorMessage
                          : {
                              customPrefix: 'user-form.errors.',
                              useCustomPrefixFor: ['minlength', 'badPasswordFormat']
                            }
                        | translate: { symbols: symbols }
                    }}"
                    autocomplete="new-password"
                    [mandatory]="passwordMandatory()"
                    [generateBtn]="true"
                    [generateBtnTitle]="'user-form.fields.generate-password' | translate"
                    (generate)="generatePassword(false)"
                  >
                  </ui-input-text>
                  <ui-tooltip
                    *ngIf="password"
                    eventMode="focus"
                    placement="right"
                    fallbackPlacement="left"
                    maxWidth="300px"
                    [element]="password"
                  >
                    <span class="password-popper">
                      <div *ngFor="let item of passwordConstraints | keyvalue" [class]="getValidatorClass(item.value)">
                        <fa-icon [icon]="item.value ? faCheck : faTimes"></fa-icon>
                        {{ "user-form.password-validators." + item.key | translate }}
                      </div>
                    </span>
                  </ui-tooltip>
                </div>
                <div class="confirm-password">
                  <ui-input-text
                    label="{{ 'user-form.fields.confirm-password' | translate }}"
                    [infoLabel]="infoLabel"
                    type="password"
                    formControlName="confirmPassword"
                    (change)="shouldCheckPassword()"
                    status="{{ form.get('confirmPassword') | formStatus }}"
                    errorMessage="{{
                      form.get('confirmPassword') | formErrorMessage | translate: { symbols: symbols }
                    }}"
                    autocomplete="new-password"
                    [mandatory]="this.mandatoryPasswordFields.includes('confirmPassword')"
                  >
                  </ui-input-text>
                </div>
              </div>
            </div>

            <div class="grid-item date-format">
              <ui-list
                label="{{ 'user-form.fields.date-format' | translate }}"
                selectLabel="{{ 'user-form.form.date-format' | translate }}"
                [options]="this.dateFormatOptions"
                formControlName="dateFormat"
                status="{{ form.get('dateFormat') | formStatus }}"
                errorMessage="{{ form.get('dateFormat') | formErrorMessage | translate }}"
                mandatory="true"
              >
              </ui-list>
            </div>
            <div class="grid-item">
              <div class="double-col">
                <div class="weekly-work-time">
                  <ui-input-text
                    type="text"
                    maskPattern="0*.0*"
                    label="{{ 'user-form.fields.weekly-work-time' | translate }}"
                    formControlName="weeklyWorkTime"
                  >
                  </ui-input-text>
                </div>
              </div>
            </div>
            <div class="grid-item">
              <div class="double-col padded">
                <div class="code-language">
                  <div class="form-line form-radio">
                    <ui-country-radio-button
                      name="codeLanguage"
                      label="{{ 'employees-popup.fields.code-language' | translate }}"
                      mandatory="true"
                      [options]="codeLangOptions"
                      [flags]="countryFlags"
                      formControlName="codeLanguage"
                      customClass="no-margin"
                    >
                    </ui-country-radio-button>
                  </div>
                </div>
                <div class="safe-ability">
                  <ui-radio-button
                    label="{{ 'user-form.fields.safe-ability' | translate }}"
                    name="safeAbility"
                    formControlName="safeAbility"
                    [options]="this.safeAbilityOptions"
                    direction="horizontal"
                    customClass="no-margin"
                    status="{{ form.get('safeAbility') | formStatus }}"
                    errorMessage="{{ form.get('safeAbility') | formErrorMessage | translate }}"
                    mandatory="true"
                  >
                  </ui-radio-button>
                </div>
              </div>
            </div>
            <div class="grid-item">
              <div class="double-col">
                <div class="hire-date">
                  <ui-date-picker
                    label="{{ 'user-form.fields.hire-date' | translate }}"
                    [locale]="locale"
                    [format]="dateFormat"
                    formControlName="hireDate"
                    status="{{ form.get('hireDate') | formStatus }}"
                    errorMessage="{{ form.get('hireDate') | formErrorMessage | translate }}"
                    mandatory="true"
                  >
                  </ui-date-picker>
                </div>
                <div class="end-contract-date">
                  <ui-date-picker
                    label="{{ 'user-form.fields.end-contract-date' | translate }}"
                    [locale]="locale"
                    [format]="dateFormat"
                    formControlName="endContractDate"
                    status="{{ form.get('endContractDate') | formStatus }}"
                    errorMessage="{{
                      form.get('endContractDate')
                        | formErrorMessage: { customPrefix: 'user-form.errors.', useCustomPrefixFor: ['badEndDate'] }
                        | translate
                    }}"
                  >
                  </ui-date-picker>
                </div>
              </div>
            </div>
          </div>
        </ui-container>
      </form>
      <div class="user-store-table">
        <ui-container>
          <div [formGroup]="tableControl">
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material"
              columnMode="force"
              headerHeight="auto"
              footerHeight="50"
              rowHeight="40"
              [limit]="15"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down'
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [offset]="pageNumber"
              (page)="pageNumber = $event.offset"
              [rowClass]="getRowClass"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                name="{{ 'user-form.datatable.columns.store' | translate }}"
                cellClass="name"
                prop="name"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'user-form.datatable.columns.main-store' | translate }}"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-id="row.id">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="changeDefaultStore(id)"
                    [formControl]="tableControl.get('byDefault_' + id)"
                  >
                  </ui-checkbox>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'user-form.datatable.columns.activated' | translate }}"
                headerClass="center-align"
                cellClass="center-align"
                prop="activated"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-id="row.id">
                  <ui-switch
                    customClass="no-margin"
                    (change)="addUserToStore(id)"
                    [formControl]="tableControl.get('activated_' + id)"
                  >
                  </ui-switch>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="table.onFooterPage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </ui-container>
      </div>
    </div>
  </div>

  <!-- Popup -->
  <app-disable-store-warning-popup
    *ngIf="popupVisible"
    [storeName]="null"
    (validate)="validateWarningPopup()"
    (close)="closeWarningPopup()"
  ></app-disable-store-warning-popup>
</ng-container>
