import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { MessageService, Tag as TagComponent, ToastMessageService } from "fugu-components";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import {
  FilterValue,
  Filter,
  SearchFilter,
  Filterer,
  SessionPagination,
  PaginableComponent,
  SubscriptionService,
} from "fugu-common";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";
import {
  Address,
  Pagination,
  PaginatedList,
  Sort,
  CaraUserService,
  Supplier,
  SupplierService,
  Tag,
  TagService,
  AsynchronousTaskService,
  AsynchronousTaskCreation,
} from "center-services";
import { FilteredTableListComponent } from "generic-pages";
import { NotificationHandlerService } from "app/service/notification-handler.service";

@Component({
  selector: "app-supplier-list",
  templateUrl: "./supplier-list.component.html",
  styleUrls: ["./supplier-list.component.scss"],
  providers: [SubscriptionService],
})
export class SupplierListComponent extends FilteredTableListComponent implements OnInit, OnDestroy, PaginableComponent {
  @Input() canUpdate: boolean = true;
  @Input() canAdd: boolean = true;
  @ViewChild("table") table: { sorts: any[]; offset: number };

  public filterer: Filterer;
  public activeFilters: SearchFilter[] = [];
  public filters: Filter[];
  public LIST_ID: string = "app-supplier-list.suppliers-list";
  public selectedSupplier: Supplier = null;
  public tableControl: UntypedFormGroup;
  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "asc",
    },
    {
      prop: "name",
      dir: "asc",
    },
  ];
  public supplierList: Supplier[] = [];
  public activated: string[];
  public addresses: Address[] = [];
  public cities: string[] = [];
  public allSupplierTags: Tag[];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  private sessionPagination: SessionPagination;
  private initObservables: Observable<any>[] = [];

  constructor(
    private notificationHandlerService: NotificationHandlerService,
    private supplierService: SupplierService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private asynchronousTaskService: AsynchronousTaskService,
    private tagService: TagService,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
    this.tableControl = new UntypedFormGroup({});
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  ngOnInit(): void {
    this.initObservables.push(this.fetchAddressesList());
    this.initObservables.push(this.fetchAllSupplierTags());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.computeSearchFilters();
        this.fetchSupplierList();
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      this.sessionPagination.saveToSession(this.LIST_ID);
    }
  }

  addRow(supplier: Supplier): void {
    let address = new Address();
    address = supplier.address;

    let addressConcat = "";
    if (address) {
      if (address.lines) {
        addressConcat += `${address.lines.replace(/\n/g, "<br/>")}<br/>`;
      }
      if (address.cityCode) {
        addressConcat += `${address.cityCode} `;
      }
      if (address.city) {
        addressConcat += address.city;
      }
    }

    const tags = this.allSupplierTags
      .filter(tag => supplier.tagIds.includes(tag.id))
      .map((obj: Tag) => new TagComponent(obj.id, obj.name, obj.color));

    this.rows.push({
      id: supplier.id,
      name: supplier.name,
      reference: supplier.reference,
      color: supplier.color,
      address: addressConcat,
      phone: this.formatPhoneNumber(supplier.phone),
      email: supplier.email,
      website: supplier.website,
      tags,
      activated: !supplier.archived,
    });
  }

  fetchSupplierList(): void {
    this.subscriptionService.subs.push(
      this.supplierService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<Supplier>) => {
          this.rows = [];
          this.supplierList = result.data;
          this.pager = result.page;
          result.data.map((supplier: Supplier) => {
            // Archive management
            if (!this.tableControl.controls[`activated_${supplier.id}`]) {
              this.tableControl.addControl(
                `activated_${supplier.id}`,
                new UntypedFormControl({
                  value: !supplier.archived,
                  disabled: !this.userService.canDo("SUPPLIER_ARCHIVE"),
                })
              );
            } else {
              this.tableControl.controls[`activated_${supplier.id}`].patchValue(!supplier.archived);
            }

            this.addRow(supplier);
          });
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("suppliers-list.errors.get-suppliers", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        },
        () => {
          this.rows = [...this.rows];
          if (this.table) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
        }
      )
    );
  }

  fetchAddressesList(): Observable<Address[]> {
    return this.supplierService.addresses().pipe(
      tap(
        (addresses: Address[]) => {
          this.addresses = addresses;
          this.addresses.forEach(address => {
            if (address.city && this.cities.indexOf(address.city) === -1) {
              this.cities.push(address.city);
            }
          });
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("supplier-list.errors.get-addresses", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber ? phoneNumber.match(/.{1,2}/g).join(" ") : null;
  }

  exportSuppliers(): void {
    if (!this.userService.canDo("SUPPLIER_READ")) {
      return;
    }

    const asyncCreationTask = {
      type: "exportXLS",
      params: `supplier;${this.activeFilters?.map(filter => filter.toQuery()).join(",")};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  importSuppliers(): void {
    if (!this.userService.canDo("SUPPLIER_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/suppliers-list/import");
  }

  createSupplier(): void {
    if (!this.userService.canDo("SUPPLIER_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/supplier/add");
  }

  updateSupplier(event: { type: any; row: any }): void {
    if (event.type === "click") {
      if (!this.userService.canDo("SUPPLIER_UPDATE")) {
        return;
      }
      const filteredList = this.supplierList.filter(supplier => supplier.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find supplier with id ${event.row.id}`);
        return;
      }
      this.router.navigateByUrl(`/supplier/update/${event.row.id}`);
    }
  }

  onArchiveCheckboxChanges(id: number): void {
    if (!this.userService.canDo("SUPPLIER_ARCHIVE")) {
      return;
    }
    if (this.tableControl.controls[`activated_${id}`].value) {
      this.subscriptionService.subs.push(
        this.supplierService.unarchive(id).subscribe(
          () => this.fetchSupplierList(),
          () => {
            const title = this.translateService.instant("message.title.archive-errors");
            const content = this.translateService.instant("suppliers-list.errors.unarchive");
            this.messageService.warn(content, { title });
          }
        )
      );
    } else {
      this.subscriptionService.subs.push(
        this.supplierService.archive(id).subscribe(
          () => this.fetchSupplierList(),
          () => {
            const title = this.translateService.instant("message.title.archive-errors");
            const content = this.translateService.instant("suppliers-list.errors.archive");
            this.messageService.warn(content, { title });
          }
        )
      );
    }
  }

  changePage(pageInfo: { page: number }): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchSupplierList();
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "activated":
        return "archived";
      case "city":
        return "address.city";
      case "cityCode":
        return "address.cityCode";
      case "address":
        return "address.lines";
      default:
        return prop;
    }
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }

    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter("name", this.translateService.instant("suppliers-list.datatable.columns.name"), "string");

    this.filterer.addFilter(
      "reference",
      this.translateService.instant("suppliers-list.datatable.columns.reference"),
      "string"
    );

    this.filterer.addFilter("address.lines", this.translateService.instant("suppliers-list.filters.address"), "string");
    this.filterer.addListFilter(
      "address.city",
      this.translateService.instant("brands-list.filters.city"),
      this.cities.map(city => {
        return { value: city, displayValue: city };
      }),
      false,
      false,
      null,
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "address.cityCode",
      this.translateService.instant("suppliers-list.filters.city-code"),
      "string"
    );
    this.filterer.addFilter("phone", this.translateService.instant("suppliers-list.datatable.columns.phone"), "string");
    this.filterer.addFilter("email", this.translateService.instant("suppliers-list.datatable.columns.email"), "string");

    this.filterer.addFilter(
      "website",
      this.translateService.instant("suppliers-list.datatable.columns.website"),
      "string"
    );

    this.filterer.addListFilter(
      "tags.id",
      this.translateService.instant("brands-list.datatable.columns.tags"),
      this.allSupplierTags.map(tag => {
        return { value: tag.id.toString(), displayValue: tag.name };
      }),
      null,
      null,
      null,
      null,
      true
    );

    this.filterer.addBooleanFilter(
      "archived",
      this.translateService.instant("suppliers-list.datatable.columns.activated"),
      false,
      false,
      true,
      true
    );
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.activeFilters = [];

    this.computeSearchFilters();
    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchSupplierList();
      })
    );
  }

  public computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  changeSortSettings(prop: string, direction: any): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "asc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.fetchSupplierList();
  }

  private fetchAllSupplierTags(): Observable<Tag[]> {
    return this.tagService.getAllTagsOfCategoryByName("Supplier").pipe(
      tap(
        (tags: Tag[]) => {
          this.allSupplierTags = tags.filter((obj: Tag) => !obj.archived).sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          const res = this.translateService.instant("supplier-list.errors.get-tags", { message: error.message });
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }
}
