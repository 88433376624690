import { Directive, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { InvoiceFormFetcher } from "app/invoice/util/invoice-form-fetcher";
import {
  CaraUserService,
  Currency,
  DiscountType,
  DocumentType,
  InvoiceCustomer,
  InvoiceCustomerService,
  Light,
  LightCustomer,
  InvoiceStatus,
  PaginatedList,
  Pagination,
  getDiscountUnit,
} from "center-services";
import {
  FilterValue,
  Filterer,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
  RoundingUtil
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { Observable, of } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { IconDefinition, faChevronLeft, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { CreditNoteCustomerFront } from "../credit-note-customer-form/credit-note-customer-front";
import { CreditNoteCustomerTotals } from "../credit-note-customer-form/credit-note-customer-totals";
import { CreditNoteCustomerLine } from "../credit-note-customer-form/credit-note-customer-line";
import Decimal from "decimal.js";

@Directive({
  providers: [SubscriptionService],
})
export abstract class CreditNoteBase extends FilteredTableListComponent {
  protected static ASC: string = "asc";
  protected static ID: string = "id";
  protected static LINE_NUMBER: string = "lineNumber";
  private static DISCOUNT: string = "discount";
  private static UNIT_PRICE: string = "unitPrice";
  private static VAT_RATE: string = "vatRate";
  private static ITEM_SUPPLIER_REF: string = "itemSupplierRef";
  private static ITEM_REF: string = "itemRef";
  private static ITEM_NAME: string = "itemName";
  private static SERIAL_NUMBER: string = "serialNumber";
  private static BATCH_NUMBER: string = "batchNumber";
  private static QUANTITY: string = "quantity";
  private static SIZE_VALUE: string = "sizeValue";
  private static TOTAL_GROSS_PRICE: string = "totalGrossPrice";
  private static METAL_WEIGHT: string = "metalWeight";
  private static WEIGHT: string = "weight";
  private static TARE: string = "tare";
  private static DELIVERY_REF: string = "deliveryRef";
  private static STORE: string = "store";
  private static BRAND_NAME: string = "brandName";
  private static SUPPLIER_REF: string = "supplierRef";
  private static SUPPLIER_NAME: string = "supplierName";

  @ViewChild("tabHandler") tabHandler: any;

  public faWarning: IconDefinition = faWarning;
  public brandList: string[] = [];
  public sizeValueList: string[] = [];
  public deliveryRefList: string[] = [];
  public invoiceCustomerMap: Map<number, InvoiceCustomer> = new Map<number, InvoiceCustomer>();
  public selectedTabId: number;
  public currency: Currency;
  public creditNoteCustomer: CreditNoteCustomerFront;
  public sorts: any[] = [];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });
  public faChevronLeft: IconDefinition = faChevronLeft;
  public listId: string;
  public defaultCustomer: Light;
  public invoiceCustomers: InvoiceCustomer[] = [];
  protected sessionPagination: SessionPagination;
  private readonly invoiceDetailUrl: string = "/invoice-customer-detail/";
  private readonly invoiceFormUrl: string = "/invoice-customer/update/";
  private readonly HUNDRED: number = 100;
  private readonly backUrl: string = "/invoice-customer-list";

  constructor(
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected invoiceCustomerService: InvoiceCustomerService,
    public fetcher: InvoiceFormFetcher,
    protected router: Router,
    protected route: ActivatedRoute,
    protected subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  public getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  public getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  public setFilters(_listId: string, filters: FilterValue[]): void {
    const activeFilters = this.filterer.filterValues.map(fv => {
      const sessionValue = filters.find(filter => filter.filterId === fv.filterId);
      return { ...fv, ...sessionValue };
    });
    this.filterer.filterValues = [...activeFilters];
  }

  public setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  public changeSortSettings(prop: string, dir: string, tabId: number): void {
    this.sorts = [{ prop, dir }];
    this.sessionPagination.saveToSession(`${this.listId}-tab-${tabId}`);
  }

  public onTabClick(event?: any): void {
    if (event) {
      this.selectedTabId = event.id;
      if (this.tabHandler) {
        this.tabHandler.changeTab(event);
      }
    }
    this.buildOptions();
    this.initFilters();

    this.sessionPagination.loadFromSession(`${this.listId}-tab-${this.selectedTabId}`);
    this.applyFilters();
  }

  public applyFilters(): void {
    this.creditNoteCustomer.tabs.get(this.selectedTabId).filteredLines = this.filterer.filterList(
      this.creditNoteCustomer.lines.get(this.selectedTabId)
    );

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.listId
      ).subscribe(() => this.sessionPagination.saveToSession(`${this.listId}-tab-${this.selectedTabId}`))
    );
  }

  multipleUnit(lines: CreditNoteCustomerLine[]): boolean {
    const unique = [...new Set(lines.filter(line => line.unitName !== null).map(line => line.unitName))];
    return unique.length > 1;
  }

  getRowClass(row: any): any {
    return { inError: row.inError, "not-clickable": true };
  }

  totalMultipleUnit(): boolean {
    const unique = new Set();
    this.creditNoteCustomer.lines.forEach((lines: CreditNoteCustomerLine[]) => {
      const uniqueTab = [...new Set(lines.filter(row => row.unitName !== null).map(row => row?.unitName))];
      uniqueTab.forEach(elem => unique.add(elem));
    });
    return unique.size > 1;
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }

  displayDiscountUnit(type: DiscountType): string {
    return getDiscountUnit(type, this.currency.symbol);
  }

  transformSN(sN: string): string[] {
    if (!sN?.includes(",") || sN === null) {
      return [];
    }
    return sN.split(",");
  }

  initInvoiceCustomersVariables(invoiceCustomers: InvoiceCustomer[]): void {
    this.invoiceCustomers = this.invoiceCustomers.concat(invoiceCustomers);
    invoiceCustomers.forEach((invoiceCustomer: InvoiceCustomer) => {
      if (!this.invoiceCustomerMap.get(invoiceCustomer.id)) {
        this.invoiceCustomerMap.set(invoiceCustomer.id, invoiceCustomer);
      }
    });
  }

  backToPrevious(): void {
    this.router.navigateByUrl(this.backUrl);
  }

  initCreditNoteCustomerVariables(creditNoteCustomer: CreditNoteCustomerFront): void {
    this.creditNoteCustomer = new CreditNoteCustomerFront(creditNoteCustomer);

    this.initCurrency();
    this.initTabs();
  }

  initTabs(): void {
    if (Array.from(this.creditNoteCustomer.lines.keys()).length > 0) {
      this.selectedTabId = Array.from(this.creditNoteCustomer.lines.keys()).sort(
        (a: number, b: number) => a - b
      )[0] as number;

      this.computeTotals();
      this.onTabClick();
    } else {
      this.selectedTabId = 0;
    }
  }

  public computeTotals(): void {
    this.creditNoteCustomer.taxPrice = 0;
    this.creditNoteCustomer.totalPrice = 0;
    this.creditNoteCustomer.totalQuantity = 0;
    this.creditNoteCustomer.totalGrossPrice = 0;
    this.creditNoteCustomer.lines.forEach((lines: CreditNoteCustomerLine[], key: any) => {
      if (this.creditNoteCustomer.tabs.get(key).totals) {
        this.creditNoteCustomer.tabs.get(key).totals.totalQuantity = 0;
        this.creditNoteCustomer.tabs.get(key).totals.totalGrossPrice = 0;
      } else {
        this.creditNoteCustomer.tabs.get(key).totals = new CreditNoteCustomerTotals({
          totalQuantity: 0,
          totalGrossPrice: 0,
        });
      }

      lines.forEach((line: CreditNoteCustomerLine, index: number) => {
        // Quantity
        const quantity = line.quantity ? +line.quantity : 0;
        this.creditNoteCustomer.tabs.get(key).totals.totalQuantity += quantity;

        // Gross Price
        const discount = line.discount ?? 0;
        const discountType = line.discountType;
        const unitPrice = line.unitPrice ?? 0;
        let grossPrice = unitPrice;

        // Apply Discount
        if (discountType !== undefined && discountType !== null && grossPrice !== undefined && grossPrice !== null) {
          grossPrice = RoundingUtil.roundLow(
            new Decimal(grossPrice ?? 0)
              .minus(
                discountType === DiscountType.PERCENT
                  ? new Decimal(grossPrice ?? 0).times(new Decimal(discount ?? 0).dividedBy(this.HUNDRED)).toNumber()
                  : discount
              )
              .toNumber()
          );
          this.creditNoteCustomer.lines.get(key)[index].totalGrossPrice = RoundingUtil.roundLow(
            new Decimal(grossPrice ?? 0).times(quantity).toNumber()
          );
          this.creditNoteCustomer.tabs.get(key).totals.totalGrossPrice = new Decimal(
            this.creditNoteCustomer.tabs.get(key).totals.totalGrossPrice ?? 0
          )
            .plus(new Decimal(this.creditNoteCustomer.lines.get(key)[index].totalGrossPrice ?? 0))
            .toNumber();
        }

        // Apply VatRate
        const vatRate = line.vatRate;
        const vatRatePercent = new Decimal(vatRate ?? 0).dividedBy(this.HUNDRED).toNumber();
        this.creditNoteCustomer.taxPrice = RoundingUtil.roundLow(
          new Decimal(this.creditNoteCustomer.taxPrice ?? 0)
            .plus(
              new Decimal(this.creditNoteCustomer.lines.get(key)[index].totalGrossPrice ?? 0)
                .times(vatRatePercent)
                .toNumber()
            )
            .toNumber()
        );
      });

      this.creditNoteCustomer.totalQuantity = new Decimal(this.creditNoteCustomer.totalQuantity ?? 0)
        .plus(this.creditNoteCustomer.tabs.get(key).totals.totalQuantity)
        .toNumber();
      this.creditNoteCustomer.totalGrossPrice = new Decimal(this.creditNoteCustomer.totalGrossPrice ?? 0)
        .plus(this.creditNoteCustomer.tabs.get(key).totals.totalGrossPrice)
        .toNumber();
    });
    this.creditNoteCustomer.totalPrice = RoundingUtil.roundLow(
      new Decimal(this.creditNoteCustomer.totalGrossPrice ?? 0).plus(this.creditNoteCustomer.taxPrice).toNumber()
    );
  }

  setDefaultCustomer(): void {
    this.defaultCustomer = this.fetcher.customerList.find(
      (customer: LightCustomer) => this.invoiceCustomers[0].customerId === customer.id
    );
  }

  protected fetchCreditNoteCustomer(id: number): Observable<PaginatedList<InvoiceCustomer>> {
    return this.invoiceCustomerService.get(id).pipe(
      tap({
        next: (creditNote: InvoiceCustomer) => {
          if (
            creditNote.documentType === DocumentType.CREDIT_NOTE &&
            creditNote.invoiceStatus !== InvoiceStatus.DRAFT
          ) {
            this.router.navigateByUrl(`/credit-note-customer-detail/${id}`);
            return;
          }

          if (creditNote.documentType === DocumentType.INVOICE) {
            const url =
              creditNote.invoiceStatus === InvoiceStatus.DRAFT
                ? `${this.invoiceFormUrl}${id}`
                : `${this.invoiceDetailUrl}${id}`;
            this.router.navigateByUrl(url);
          }
        },
        error: error => {
          this.fetcher.sendErrorAlert("credit-note.credit-note-customer-form.errors.get-credit-note", error.message);
          this.router.navigateByUrl(this.backUrl);
        },
      }),
      mergeMap((invoiceCustomer: InvoiceCustomer) => {
        const creditNoteCustomer: CreditNoteCustomerFront = new CreditNoteCustomerFront(invoiceCustomer);
        this.initCreditNoteCustomerVariables(creditNoteCustomer);
        return this.fetchInvoiceCustomers();
      })
    );
  }

  protected initFilters(): void {
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.listId
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      CreditNoteBase.LINE_NUMBER,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.line-number"),
      "string"
    );

    this.filterer.addFilter(
      CreditNoteBase.ITEM_SUPPLIER_REF,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.item-supplier-ref"),
      "string"
    );

    this.filterer.addFilter(
      CreditNoteBase.ITEM_REF,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.item-reference"),
      "string"
    );

    this.filterer.addFilter(
      CreditNoteBase.SERIAL_NUMBER,
      this.translateService.instant("police-book-movements-list.datatable.columns.serial-number"),
      "string"
    );

    this.filterer.addFilter(
      CreditNoteBase.BATCH_NUMBER,
      this.translateService.instant("police-book-movements-list.datatable.columns.batch"),
      "string"
    );

    this.filterer.addFilter(
      CreditNoteBase.ITEM_NAME,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.item-name"),
      "string"
    );

    this.filterer.addFilter(
      CreditNoteBase.QUANTITY,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.quantity"),
      "range"
    );

    this.filterer.addListFilter(
      CreditNoteBase.SIZE_VALUE,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.size-value"),
      this.sizeValueList.map(sizeValue => {
        return { value: sizeValue, displayValue: sizeValue };
      })
    );

    this.filterer.addFilter(
      CreditNoteBase.UNIT_PRICE,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.unit-price"),
      "range"
    );

    this.filterer.addFilter(
      CreditNoteBase.DISCOUNT,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.discount"),
      "range"
    );

    this.filterer.addFilter(
      CreditNoteBase.TOTAL_GROSS_PRICE,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.total-gross-price"),
      "range"
    );

    this.filterer.addFilter(
      CreditNoteBase.VAT_RATE,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.vat-rate"),
      "range"
    );

    this.filterer.addFilter(
      CreditNoteBase.METAL_WEIGHT,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.metal-weight"),
      "range"
    );

    this.filterer.addFilter(
      CreditNoteBase.TARE,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.tare"),
      "range"
    );

    this.filterer.addFilter(
      CreditNoteBase.WEIGHT,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.weight"),
      "range"
    );

    this.filterer.addListFilter(
      CreditNoteBase.DELIVERY_REF,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.delivery-ref"),
      this.deliveryRefList.map(deliveryRef => {
        return { value: deliveryRef, displayValue: deliveryRef };
      })
    );

    this.filterer.addFilter(
      CreditNoteBase.STORE,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.store-name"),
      "string"
    );

    this.filterer.addListFilter(
      CreditNoteBase.BRAND_NAME,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.brand-name"),
      this.brandList.map(brand => {
        return { value: brand, displayValue: brand };
      })
    );

    this.filterer.addListFilter(
      CreditNoteBase.SUPPLIER_NAME,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.supplier-name"),
      this.fetcher.supplierLightList
        .map(supplier => supplier?.name)
        .map(supplierName => {
          return { value: supplierName, displayValue: supplierName };
        })
    );

    this.filterer.addFilter(
      CreditNoteBase.SUPPLIER_REF,
      this.translateService.instant("credit-note.credit-note-customer-form.datatable.columns.supplier-ref"),
      "string"
    );
  }

  protected buildOptions(): void {
    const numericSorter = new Intl.Collator(this.fetcher.locale, { numeric: true });

    const rawSizeValueList: string = this.creditNoteCustomer.lines
      .get(this.selectedTabId)
      .filter((obj: CreditNoteCustomerLine) => obj.sizeValue)
      .map((obj: CreditNoteCustomerLine) => obj.sizeValue);
    this.sizeValueList = [...new Set(rawSizeValueList)].sort((a: string, b: string) => numericSorter.compare(a, b));

    const rawBrandList: string = this.creditNoteCustomer.lines
      .get(this.selectedTabId)
      .filter((obj: CreditNoteCustomerLine) => obj.brandName)
      .map((obj: CreditNoteCustomerLine) => obj.brandName);
    this.brandList = [...new Set(rawBrandList)].sort((a: string, b: string) => a.localeCompare(b));

    const rawDeliveryRefList: string = this.creditNoteCustomer.lines
      .get(this.selectedTabId)
      .filter((obj: CreditNoteCustomerLine) => obj.deliveryRef)
      .map((obj: CreditNoteCustomerLine) => obj.deliveryRef);
    this.deliveryRefList = [...new Set(rawDeliveryRefList)].sort((a: string, b: string) => a.localeCompare(b));
  }

  private initCurrency(): void {
    if (this.fetcher.currencyList?.length > 0) {
      this.currency = this.fetcher.currencyList.find(cur => cur.id === this.creditNoteCustomer?.currencyId);
    }
  }

  private fetchInvoiceCustomers(): Observable<PaginatedList<InvoiceCustomer>> {
    let invoiceCustomerIds: number[];
    if (this.creditNoteCustomer.lines.size > 0) {
      invoiceCustomerIds = Array.from(this.creditNoteCustomer.lines.keys());
    }
    const filters: SearchFilter[] = [
      new SearchFilter(CreditNoteBase.ID, SearchFilterOperator.IN, [...invoiceCustomerIds]),
    ];
    const pager = new Pagination({
      size: invoiceCustomerIds.length,
      number: 0,
    });
    if (invoiceCustomerIds.length > 0) {
      return this.invoiceCustomerService.getAll(pager, [], filters).pipe(
        tap(
          (result: PaginatedList<InvoiceCustomer>) => {
            this.initInvoiceCustomersVariables(result.data);
            this.setDefaultCustomer();
          },
          error => {
            this.fetcher.sendErrorAlert("invoice-customer-form.errors.get-delivery-forms", error.message);
          }
        )
      );
    } else {
      return of(null);
    }
  }
}
