import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Nomenclature, StandardItem } from "center-services";

@Component({
  selector: "app-retail-item-nomenclature",
  templateUrl: "./retail-item-nomenclature.component.html",
  styleUrls: ["./retail-item-nomenclature.component.scss"],
})
export class RetailItemNomenclatureComponent implements OnInit, OnChanges {
  @Input() item: StandardItem;

  @Output() nomenclatureEvent: EventEmitter<Nomenclature> = new EventEmitter();

  public listVisible: boolean = false;
  public currentNomenclature: Nomenclature;

  ngOnInit(): void {
    this.currentNomenclature = this.item.nomenclatures.find(nom => !nom.archived);

    if (!this.currentNomenclature) {
      this.listVisible = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && this.currentNomenclature) {
      this.currentNomenclature = this.item.nomenclatures.find(nom => nom.id === this.currentNomenclature.id);
    }
  }

  public switch(): void {
    this.listVisible = !this.listVisible;
  }

  public changeCurrentNomenclature(event: { nomenclature: Nomenclature; toForm: boolean }): void {
    if (event.toForm) {
      if (!event.nomenclature.id) {
        this.nomenclatureEvent.emit(event.nomenclature);
      }
      this.currentNomenclature = event.nomenclature;
      this.switch();
      return;
    }

    this.nomenclatureEvent.emit(event.nomenclature);
  }
}
