<form class="password-form" [formGroup]="popupForm" novalidate>
  <div class="popup-container">
    <ui-popup title="{{ 'change-password.title' | translate }}" [cbIcon]="null">
      <div content>
        <div class="temp-password">
          <ui-input-text
            #passwordField
            label="{{ 'change-password.fields.new-password' | translate }}"
            type="password"
            formControlName="tempPassword"
            (change)="shouldCheckPassword()"
            status="{{ popupForm.get('tempPassword') | formStatus }}"
            errorMessage="{{
              popupForm.get('tempPassword')
                | formErrorMessage
                  : { customPrefix: 'user-form.errors.', useCustomPrefixFor: ['minlength', 'badPasswordFormat'] }
                | translate: { symbols: symbols }
            }}"
            [mandatory]="true"
          >
          </ui-input-text>
          <ui-tooltip
            *ngIf="password"
            eventMode="focus"
            placement="right"
            fallbackPlacement="left"
            maxWidth="300px"
            [element]="password"
          >
            <span style="font-weight: normal !important; font-size: 15px !important">
              <div
                *ngFor="let item of passwordConstraints | keyvalue"
                [class]="getValidatorClass(item.value)"
                style="padding-bottom: 2px !important"
              >
                <fa-icon [icon]="item.value ? faCheck : faTimes"></fa-icon>
                {{ "change-password.validators." + item.key | translate }}
              </div>
            </span>
          </ui-tooltip>
        </div>
        <div class="confirm-password">
          <ui-input-text
            label="{{ 'change-password.fields.confirm-password' | translate }}"
            type="password"
            formControlName="confirmPassword"
            (change)="shouldCheckPassword()"
            status="{{ popupForm.get('confirmPassword') | formStatus }}"
            errorMessage="{{
              popupForm.get('confirmPassword')
                | formErrorMessage: { customPrefix: 'user-form.errors.', useCustomPrefixFor: ['notEquivalent'] }
                | translate: { symbols: symbols }
            }}"
            [mandatory]="true"
          >
          </ui-input-text>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'global.validate' | translate }}"
                type="save"
                [primary]="true"
                (click)="submitPassword()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</form>
