<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'home-page.title' | translate }}"> </ui-title>
    </div>
    <div class="home-body">
      <div class="upper-part">
        <div class="up-left">
          <ui-container>
            <div class="subtitle">
              {{ "home-page.hello" | translate }} <span> {{ userName }}</span
              ><span> !</span>
            </div>
            <div class="image">
              <img src="{{ this.imageLink }}" />
            </div>
          </ui-container>
        </div>
        <div class="up-right">
          <ui-container>
            <div class="subtitle">
              {{ "home-page.video" | translate }}
            </div>
            <div class="video">
              <img class="img-login-window ml-40" [src]="loginImgScr" height="99%" width="99%" />
            </div>
          </ui-container>
        </div>
      </div>
      <div class="lower-part">
        <ui-container>
          <div class="bottom-container">
            <div class="bottom bottom-left">
              <fa-icon class="icon" [icon]="faCloudQuestion"></fa-icon>
              <div class="subtitle bottom-subtitle">{{ "home-page.help.subtitle" | translate }}</div>
              <span class="text" [innerHTML]="'home-page.help.text' | translate"> </span>
              <span>
                <ui-link text="{{ 'home-page.help.link' | translate }}" (click)="goToHelpSite()"></ui-link>
              </span>
            </div>

            <div class="bottom bottom-center">
              <fa-icon class="icon" [icon]="faCommentsQuestionCheck"></fa-icon>
              <div class="subtitle bottom-subtitle">{{ "home-page.support.subtitle" | translate }}</div>
              <span class="text" [innerHTML]="'home-page.support.text' | translate"> </span>
              <span>
                <ui-link text="{{ 'home-page.support.link' | translate }}" href="mailto:support@neo-logix.fr"></ui-link>
              </span>
            </div>
            <div class="bottom bottom-right">
              <fa-icon class="icon" [icon]="faNewspaper"></fa-icon>
              <div class="subtitle bottom-subtitle">{{ "home-page.news.subtitle" | translate }}</div>
              <span class="text" [innerHTML]="'home-page.news.text' | translate"> </span>
              <span>
                <ui-link text="{{ 'home-page.news.link' | translate }}" (click)="goToNewsSite()"></ui-link>
              </span>
              <span class="text" [innerHTML]="'home-page.news.text-bis' | translate"></span>
            </div>
          </div>
        </ui-container>
      </div>
    </div>
  </div>
</ng-container>

<!-- <ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'home-page.title' | translate }}"> </ui-title>
      <span class="home"
        >{{ "home-page.hello" | translate }}<span> {{ userName }}</span
        ><span> !</span></span
      >
    </div>
    <div class="home-body">
      <div class="parent">
        <div class="block1">
          <div class="kpi">
            <div class="icon">
              <fa-icon [icon]="faChartLine"></fa-icon>
            </div>
            <div class="value">1 576 959 €</div>
            <div class="title">de CA aujourd'hui</div>
          </div>
        </div>
        <div class="block2">
          <div class="kpi">
            <div class="icon">
              <fa-icon [icon]="faBagShopping"></fa-icon>
            </div>
            <div class="value">342</div>
            <div class="title">ventes aujourd'hui</div>
          </div>
        </div>
        <div class="block3">
          <div class="kpi">
            <div class="icon">
              <fa-icon [icon]="faFileInvoice"></fa-icon>
            </div>
            <div class="value">18</div>
            <div class="title">factures à régler avant échéance</div>
          </div>
        </div>
        <div class="block4">
          <div class="kpi">
            <div class="icon">
              <fa-icon [icon]="faPersonDolly"></fa-icon>
            </div>
            <div class="value">6</div>
            <div class="title">commandes à réceptionner</div>
          </div>
        </div>
        <div class="right-part">
          <h3 class="ml-20">Présentation rapide de l'application</h3>
          <img class="img-login-window ml-40" [src]="loginImgScr" height="80%" width="90%" />
        </div>
        <div class="lower-part">
          <div class="bottom-container">
            <div class="bottom bottom-left">
              <fa-icon class="icon" [icon]="faCloudQuestion"></fa-icon>
              <div class="subtitle bottom-subtitle">{{ "home-page.help.subtitle" | translate }}</div>
              <span class="text" [innerHTML]="'home-page.help.text' | translate"> </span>
              <span>
                <ui-link text="{{ 'home-page.help.link' | translate }}" (click)="goToHelpSite()"></ui-link>
              </span>
            </div>

            <div class="bottom bottom-center">
              <fa-icon class="icon" [icon]="faCommentsQuestionCheck"></fa-icon>
              <div class="subtitle bottom-subtitle">{{ "home-page.support.subtitle" | translate }}</div>
              <span class="text" [innerHTML]="'home-page.support.text' | translate"> </span>
              <span>
                <ui-link text="{{ 'home-page.support.link' | translate }}" href="mailto:support@neo-logix.fr"></ui-link>
              </span>
            </div>
            <div class="bottom bottom-right">
              <fa-icon class="icon" [icon]="faNewspaper"></fa-icon>
              <div class="subtitle bottom-subtitle">{{ "home-page.news.subtitle" | translate }}</div>
              <span class="text" [innerHTML]="'home-page.news.text' | translate"> </span>
              <span>
                <ui-link text="{{ 'home-page.news.link' | translate }}" (click)="goToNewsSite()"></ui-link>
              </span>
              <span class="text" [innerHTML]="'home-page.news.text-bis' | translate"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->
