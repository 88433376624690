<ng-container>
  <div class="customer-document-history">
    <div class="bottom-bordered">
      <div class="title">{{ "customer-document-history.customer-document.title" | translate }}</div>

      <app-customer-document-popup
        #customerDocumentPopup
        *ngIf="popupVisible"
        [(customerDocument)]="customerDocument"
        (validate)="customerDocumentUpdated($event)"
        (close)="onClosePopup()"
      >
      </app-customer-document-popup>
      <div class="align-buttons">
        <div class="image-icon firstDocument" *ngIf="documentsFiles[0]?.byteLength > 0">
          <img [src]="documentsFiles[0] | safeUrl" />
        </div>
        <div class="image-icon secondDocument" *ngIf="documentsFiles[1]?.byteLength > 0">
          <img [src]="documentsFiles[1] | safeUrl" />
        </div>
        <div class="customer-document-identity-info" *ngIf="customerDocument">
          <div class="type" *ngIf="customerDocument.type">{{ getDocumentType() }}</div>
          <div class="reference" *ngIf="customerDocument.reference">{{ customerDocument.reference }}</div>
          <div class="country" *ngIf="customerDocument.countryId">{{ getCountry() }}</div>
          <div class="expirationDate" *ngIf="customerDocument.expirationDate">
            {{ getDateLabel() }} {{ getDate() | dateTimeFormat: false }}
          </div>
        </div>
        <div class="modify-document">
          <div class="button">
            <ui-button
              text="{{ getAddDocumentButtonLabel() | translate }}"
              [primary]="false"
              (click)="openCustomerDocumentPopup()"
              [btnIcon]="this.customerDocument ? faPen : faPlus"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
