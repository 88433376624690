import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { IconDefinition, faInfoCircle, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { PurchaseModalityService } from "app/service/purchase-modality.service";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  PurchaseModality,
  CategoryType,
  AlloyComposition,
  Currency,
  Light,
  Uom,
  CurrencyService,
  LightService,
  CaraUserService,
  UomService,
  PurchaseType,
  CaraUser,
  MetalWeight,
  MetalPriceWeight,
} from "center-services";
import { Filter, Filterer, FilterValue, PaginableComponent, RoundingUtil, SessionPagination, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";

@Component({
  selector: "app-retail-item-purchase-modalities-list",
  templateUrl: "./retail-item-purchase-modalities-list.component.html",
  styleUrls: ["./retail-item-purchase-modalities-list.component.scss"],
  providers: [PurchaseModalityService, SubscriptionService],
})
export class RetailItemPurchaseModalitiesListComponent
  extends FilteredTableListComponent
  implements OnInit, OnChanges, PaginableComponent {
  public static LIST_ID: string = "app-retail-item-purchase-modalities-list.retail-item-purchase-modalities-table";

  @Input() purchaseModalitiesList: PurchaseModality[];
  @Input() itemCategoryType: CategoryType;
  @Input() itemComposition: AlloyComposition[];
  @Input() accountingBuyRef: string = "";
  @Input() itemTheoreticalWeight: number;
  @Input() itemWeight: number;
  @Input() mainUnitId: number;
  @Input() canBuy: boolean;

  @Output() purchaseModalitiesListChange: EventEmitter<PurchaseModality[]> = new EventEmitter<PurchaseModality[]>();
  @Output() accountingBuyRefChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() submitEvent: EventEmitter<PurchaseModality> = new EventEmitter<PurchaseModality>();

  @ViewChild("table") table: DatatableComponent;

  public tableControl: UntypedFormGroup;

  public rows: any[] = [];
  public allRows: any[] = [];

  public filters: Filter[] = [];
  public filterValues: FilterValue[] = [];
  public filterer: Filterer;
  public pageNumber: number = 0;
  public codeLanguage: string;
  public defaultCurrency: Currency;
  public currencies: Currency[] = [];
  public suppliers: Light[] = [];
  public purchaseUnits: Uom[] = [];
  public statusTranslations: { [key: string]: string } = {};
  public initObservables: Observable<any>[] = [];
  public accountingBuyRefControl: UntypedFormControl;
  public selectedPurchaseModalityIndex: number = null;
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "supplierName",
      dir: "asc",
    },
    {
      prop: "supplierRef",
      dir: "asc",
    },
  ];
  public popupVisible: boolean = false;
  faInfoCircle: IconDefinition = faInfoCircle;
  faTrash: IconDefinition = faTrashAlt;
  private sessionPagination: SessionPagination;

  constructor(
    private currencyService: CurrencyService,
    private lightService: LightService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private uomService: UomService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
    this.tableControl = new UntypedFormGroup({});

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      PurchaseType,
      "purchase-modalities-list.datatable.purchase-type",
      this.statusTranslations
    );
  }

  getPageNumber(_listId: string): number {
    return this.pageNumber;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
    this.applyFilters();
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pageNumber = pageNumber;
  }

  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(RetailItemPurchaseModalitiesListComponent.LIST_ID);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty("purchaseModalitiesList")) {
      changes.purchaseModalitiesList.firstChange ? this.fetchData() : this.resetRows();
      if (this.filterer) {
        this.applyFilters();
      }
    }
  }

  ngOnInit(): void {
    this.initFormControl();
    this.fetchData();
  }

  initFormControl(): void {
    this.accountingBuyRefControl = new UntypedFormControl(this.accountingBuyRef);
  }

  fetchData(): void {
    this.initObservables.push(this.fetchCodeLanguage());
    this.initObservables.push(this.fetchDefaultCurrency());
    this.initObservables.push(this.fetchUnitOfMeasure());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchCurrencies());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(RetailItemPurchaseModalitiesListComponent.LIST_ID);
        this.resetRows();
        this.applyFilters();
      })
    );
  }

  fetchDefaultCurrency(): Observable<Currency> {
    const observable = this.currencyService.defaultCurrency.pipe(filter(cur => cur !== undefined));
    this.subscriptionService.subs.push(
      observable.subscribe(currency => {
        this.defaultCurrency = currency;
      })
    );
    return observable;
  }

  fetchCodeLanguage(): Observable<CaraUser> {
    const observable = this.userService.connectedUser;
    this.subscriptionService.subs.push(
      observable.subscribe(connectedUser => {
        this.codeLanguage = connectedUser.codeLanguage;
      })
    );
    return observable;
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap({
        next: (currencies: Currency[]) => {
          this.currencies = currencies;
        },
        error: (err: Error) => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("currencies-list.errors.get-entities", {
            message: err.message,
          });
          this.messageService.warn(content, { title });
        },
      })
    );
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap({
        next: (suppliers: Light[]) => {
          this.suppliers = suppliers;
        },
        error: (err: Error) => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("suppliers-list.errors.get-entities", { message: err.message });
          this.messageService.warn(content, { title });
        },
      })
    );
  }

  fetchUnitOfMeasure(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap({
        next: (purchaseUnits: Uom[]) => {
          this.purchaseUnits = purchaseUnits;
        },
        error: (err: Error) => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("uoms-list.errors.get-entities", { message: err.message });
          this.messageService.warn(content, { title });
        },
      })
    );
  }

  resetRows(): void {
    this.manageRows(this.purchaseModalitiesList);
    this.rows = [...this.allRows];
  }

  manageRows(purchaseModList: PurchaseModality[]): void {
    this.allRows = [];
    if (purchaseModList) {
      purchaseModList.forEach((purchaseModality: PurchaseModality) => {
        this.allRows.push(this.initRowFields(purchaseModality));
      });
    }
  }

  initRowFields(purchaseModality: PurchaseModality): any {
    const index = this.purchaseModalitiesList.indexOf(purchaseModality);

    // Archive management
    if (!this.tableControl.controls[`activated_${purchaseModality.id}`]) {
      this.tableControl.addControl(
        `activated_${purchaseModality.id}`,
        new UntypedFormControl({
          value: !purchaseModality.archived,
          disabled: !this.userService.canDo("RETAIL_ITEM_UPDATE") || this.isReadOnlyRow(purchaseModality),
        })
      );
    } else {
      this.tableControl.controls[`activated_${purchaseModality.id}`].patchValue(!purchaseModality.archived);
    }

    return {
      index,
      id: purchaseModality.id,

      purchaseType: purchaseModality.purchaseType?.toString(),

      unitPrice: purchaseModality.unitPrice,
      unitPricePerWeight: purchaseModality.unitPricePerWeight,

      grossPriceDefaultCurrency: purchaseModality.defaultCurrencyComputedPrice,
      grossPrice: purchaseModality.computedPrice,

      from: purchaseModality.minQuantity,
      to: purchaseModality.maxQuantity,

      theoreticalMetalWeight: this.getTheoreticalMetalWeights(purchaseModality),
      metalPrice: this.getMetalPrices(purchaseModality),

      currency: this.getPurchaseCurrency(purchaseModality),

      supplierName: purchaseModality.supplierId ? this.getSupplier(purchaseModality).name : "",
      readOnly: this.isReadOnlyRow(purchaseModality),

      supplierId: purchaseModality.supplierId,
      supplierRef: purchaseModality.supplierRef,
      purchaseUnitId: purchaseModality.purchaseUnitId,
      purchaseUnitName: purchaseModality.purchaseUnitId ? this.getPurchaseUnit(purchaseModality).longName : "",

      discount: purchaseModality.discount,
      netPrice: purchaseModality.netPrice,
      extraFees: purchaseModality.extraFees,
      costPrice: purchaseModality.costPrice,
      activated: !purchaseModality.archived,
    };
  }

  isReadOnlyRow(purchaseModality: PurchaseModality): boolean {
    return purchaseModality.supplierId ? this.getSupplier(purchaseModality).archived : false;
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === RetailItemPurchaseModalitiesListComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addListFilter(
      "supplierName",
      this.getFilterLabel("supplier"),
      this.suppliers
        .map((supplier: Light) => {
          return { value: supplier.name, displayValue: supplier.name };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
    );

    this.filterer.addFilter("supplierRef", this.getFilterLabel("supplier-ref"), "string");
    this.filterer.addListFilter(
      "purchaseType",
      this.getFilterLabel("purchase-type"),
      Object.keys(PurchaseType).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );

    this.filterer.addFilter("grossPriceDefaultCurrency", this.getFilterLabel("gross-price-default-currency"), "range");
    this.filterer.addFilter("from", this.getFilterLabel("from"), "range");
    this.filterer.addFilter("to", this.getFilterLabel("to"), "range");

    this.filterer.addListFilter(
      "purchaseUnitName",
      this.getFilterLabel("purchase-unit"),
      this.purchaseUnits
        .map((purchaseUnit: Uom) => {
          return { value: purchaseUnit.longName, displayValue: purchaseUnit.longName };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
    );

    this.filterer.addBooleanFilter("activated", this.getFilterLabel("activated"), false, false, true);
  }

  applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        RetailItemPurchaseModalitiesListComponent.LIST_ID
      ).subscribe()
    );
  }

  onArchiveCheckboxChanges(id: number): void {
    const purchaseModality = this.purchaseModalitiesList.find((purchaseM: PurchaseModality) => purchaseM.id === id);
    purchaseModality.archived = !this.tableControl.controls[`activated_${id}`].value;
    this.purchaseModalitiesList = [...this.purchaseModalitiesList];
    this.resetRows();
    this.applyFilters();
  }

  getPurchaseCurrency(purchaseModality: PurchaseModality): Currency {
    return this.currencies.find((currency: Currency) => {
      return currency.id === purchaseModality.currencyId;
    });
  }

  getSupplier(purchaseModality: PurchaseModality): Light {
    return this.suppliers.find((supplier: Light) => {
      return supplier.id === purchaseModality.supplierId;
    });
  }

  getPurchaseUnit(purchaseModality: PurchaseModality): Uom {
    return this.purchaseUnits.find((purchaseUnit: Uom) => {
      return purchaseUnit.id === purchaseModality.purchaseUnitId;
    });
  }

  closePopup(): void {
    this.popupVisible = false;
    this.selectedPurchaseModalityIndex = null;
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  getFilterLabel(columnName: string): string {
    return this.translateService.instant(this.getColumnTranslation(columnName));
  }

  getTranslationPrefix(): string {
    return "purchase-modalities-list";
  }

  getColumnTranslation(columnName: string): string {
    return `${this.getTranslationPrefix()}.datatable.columns.${columnName}`;
  }

  updatePurchaseModality(event: any): void {
    // prevent to updating PM with archived supplier associated
    if (event.row.readOnly) {
      return;
    }
    this.savePaginationToSession();
    if (this.userService.canDo("RETAIL_ITEM_UPDATE") && event.type === "click") {
      this.selectedPurchaseModalityIndex = event.row.index;
      this.openPopup();
    }
  }

  openPopup(): void {
    this.popupVisible = true;
  }

  onPopupSubmitClick(validatedPurchaseModality: PurchaseModality): void {
    this.selectedPurchaseModalityIndex === null
      ? this.purchaseModalitiesList.push(validatedPurchaseModality)
      : (this.purchaseModalitiesList[this.selectedPurchaseModalityIndex] = validatedPurchaseModality);

    this.purchaseModalitiesList = [...this.purchaseModalitiesList];
    this.closePopup();

    this.purchaseModalitiesListChange.emit(this.purchaseModalitiesList);
    this.submitEvent.emit(validatedPurchaseModality);
  }

  onBlur(): void {
    this.accountingBuyRefChange.emit(this.accountingBuyRefControl.value);
  }

  getRowClass(row: any): any {
    return {
      disabled: row.readOnly,
    };
  }

  private getTheoreticalMetalWeights(purchaseModality: PurchaseModality): any {
    if (Array.isArray(purchaseModality.metalWeights)) {
      purchaseModality.metalWeights.sort((a, b) => a.metalName.localeCompare(b.metalName));
      return purchaseModality.metalWeights.map(
        (metalWeight: MetalWeight) =>
          `${metalWeight.metalName.toUpperCase()} : ${metalWeight.weight.toFixed(RoundingUtil.TWO_DIGITS)} g`
      );
    }
    return null;
  }

  private getMetalPrices(purchaseModality: PurchaseModality): any {
    if (Array.isArray(purchaseModality.metalPrices)) {
      purchaseModality.metalPrices.sort((a, b) => a.metalName.localeCompare(b.metalName));
      return purchaseModality.metalPrices.map(
        (metalPriceWeight: MetalPriceWeight) =>
          `${metalPriceWeight.metalName.toUpperCase()} : ${RoundingUtil.roundLow(metalPriceWeight.price).toFixed(
            RoundingUtil.TWO_DIGITS
          )} ${this.getPurchaseCurrency(purchaseModality).symbol}/g`
      );
    }
    return null;
  }
}
