<ng-container>
  <div class="main-container">
    <div class="filters border-bottom-filters" *ngIf="filterer">
      <ui-dynamic-filters
        label="{{ 'filters.label' | translate }}"
        [filters]="filterer.getFilters()"
        [(filterValues)]="filterer.filterValues"
        applyLabel="{{ 'filters.apply' | translate }}"
        (apply)="applyFilters()"
        dateFormat="{{ dateFormat }}"
        minLabel="{{ 'filters.min' | translate }}"
        maxLabel="{{ 'filters.max' | translate }}"
        deleteLabel="{{ 'filters.delete' | translate }}"
        placeholderPrefix="{{ 'filters.prefix' | translate }} "
      >
      </ui-dynamic-filters>
    </div>
    <div></div>
    <div class="brand-collection-table">
      <div class="top-table-buttons">
        <ui-button
          text="{{ 'brand-collection-list.add-button' | translate }}"
          type="add"
          *hasPermission="['BRAND_CREATE']"
          (click)="addBrandCollection()"
        ></ui-button>
      </div>
      <div [formGroup]="tableControl">
        <ngx-datatable
          #table
          [rows]="rows"
          [trackByProp]="'index'"
          class="material"
          columnMode="force"
          headerHeight="auto"
          footerHeight="50"
          rowHeight="40"
          [limit]="15"
          (activate)="updateBrandCollection($event)"
          [cssClasses]="{
            sortAscending: 'fas fa-chevron-up',
            sortDescending: 'fas fa-chevron-down'
          }"
          [messages]="{ emptyMessage: 'global.no-data' | translate }"
          [sorts]="sorts"
          [offset]="pageNumber"
          (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          (page)="pageNumber = $event.offset"
        >
          <ngx-datatable-column
            name="{{ 'brand-collection-list.datatable.columns.name' | translate }}"
            prop="name"
            cellClass="name"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'brand-collection-list.datatable.columns.date' | translate }}"
            prop="date"
            cellClass="date"
          >
            <ng-template ngx-datatable-cell-template let-row="row">
              <span>{{ row.date ? (row.date | dateTimeFormat: false) : null }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'brand-collection-list.datatable.columns.active' | translate }}"
            prop="activated"
            headerClass="center-align"
            cellClass="activated center-align"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-id="row.index">
              <div class="green-zone" (click)="$event.stopPropagation()">
                <ui-switch
                  customClass="no-margin"
                  (change)="activeCheckboxOnChanges(id)"
                  [formControl]="tableControl.get('activated_' + id)"
                >
                </ui-switch>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="rowCount > 0">
                {{
                  rowCount
                    | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                    | translate: { rowCount: rowCount }
                }}
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                [pagerRightArrowIcon]="'fas fa-chevron-right'"
                [pagerNextIcon]="'fas fa-chevron-double-right'"
                [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="table.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>

  <!-- Popup -->
  <brand-collection-popup
    *ngIf="popupVisible"
    [brandCollection]="selectedBrandCollection"
    (validate)="onValidateBrandCollection($event)"
    (close)="onClosePopup()"
  ></brand-collection-popup>
</ng-container>
