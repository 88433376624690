import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { IconDefinition, faCalculator, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  StockEntryLocation,
  StockMovement,
  Supplier,
  StockEntryService,
  CaraUserService,
  SupplierService,
  MovementType,
} from "center-services";
import { CommonValidatorsUtil, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { ErrorUtil, PrecisionUtil } from "generic-pages";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-out-of-stock-popup",
  templateUrl: "./out-of-stock-popup.component.html",
  styleUrls: ["./out-of-stock-popup.component.scss"],
  providers: [SubscriptionService],
})
export class OutOfStockPopupComponent implements OnInit {
  @Input() stockEntryLocation: StockEntryLocation;
  @Input() unit: Record<string, string>;
  @Output() close: EventEmitter<any> = new EventEmitter();

  public readonly decimalDigitHigh: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: number = PrecisionUtil.HIGH_INTEGER;

  public popupForm: UntypedFormGroup;

  public initialStockMovement: StockMovement;
  public unsavedStockMovement: StockMovement;
  public selectedStockMovement: StockMovement;

  public faCheck: IconDefinition = faCheck;
  public stockEntrySupplier: Supplier;

  public faCalculate: IconDefinition = faCalculator;

  constructor(
    private fb: UntypedFormBuilder,
    private stockEntryService: StockEntryService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: CaraUserService,
    private supplierService: SupplierService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.preparePopupForm();

    this.subscriptionService.subs.push(
      combineLatest([this.fetchSupplier(this.stockEntryLocation.stockEntry?.supplierId)]).subscribe(() =>
        this.initializePopup()
      )
    );
  }

  initializePopup(): void {
    this.initialStockMovement = this.getInitStockMovement();
    this.unsavedStockMovement = this.cloneStockMovement(this.initialStockMovement);
    this.selectedStockMovement = this.cloneStockMovement(this.initialStockMovement);
  }

  preparePopupForm(): void {
    const digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);

    this.popupForm = this.fb.group(
      {
        quantity: [null, [Validators.required, digitValidator, Validators.max(this.stockEntryLocation.quantity)]],
        weight: this.stockEntryLocation.weight
          ? [
            null,
            [Validators.required, digitValidator, Validators.min(0), Validators.max(this.stockEntryLocation.weight)],
          ]
          : null,
        tare: this.stockEntryLocation.tare
          ? [
            null,
            [Validators.required, digitValidator, Validators.min(0), Validators.max(this.stockEntryLocation.tare)],
          ]
          : null,
        comment: [null, [Validators.required]],
        policeBook: true,
      },
      { updateOn: "blur" }
    );

    this.popupForm.setValidators(CommonValidatorsUtil.tareSuperiorToWeightValidator());
  }

  getInitStockMovement(): StockMovement {
    return new StockMovement({
      stockEntryId: this.stockEntryLocation?.stockEntry?.id,
      type: MovementType.OUT,
      sourceLocationId: this.stockEntryLocation?.locationId,
    });
  }

  cloneStockMovement(stockMovement: StockMovement): StockMovement {
    return new StockMovement(stockMovement);
  }

  closePopup(): void {
    this.applyModifications();
    if (
      !this.selectedStockMovement.equals(this.initialStockMovement) &&
      !this.selectedStockMovement.equals(this.unsavedStockMovement)
    ) {
      this.unsavedStockMovement = this.cloneStockMovement(this.selectedStockMovement);
      this.triggerDataLossWarningMessage();
      return;
    }

    this.close.emit("close");
  }

  submitPopup(): void {
    if (this.isTripleZeroError()) {
      this.triggerTripleZeroErrorMessage();
      return;
    }

    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    if (!this.userService.canDo("STOCK_ENTRY_UPDATE")) {
      return;
    }

    this.applyModifications();

    this.subscriptionService.subs.push(
      this.stockEntryService.createStockMovement(this.selectedStockMovement).subscribe(
        () => {
          this.triggerSuccessfulSaveMessage();
        },
        error => {
          this.handleApiError(error);
        },
        () => {
          this.closePopup();
        }
      )
    );
  }

  isTripleZeroError(): boolean {
    const quantityControl = this.popupForm.controls.quantity;
    const weightControl = this.popupForm.controls.weight;
    const tareControl = this.popupForm.controls.tare;

    if (
      !quantityControl.value ||
      (!weightControl.value && this.stockEntryLocation?.weight) ||
      (!tareControl.value && this.stockEntryLocation?.tare)
    ) {
      return false;
    }

    if (+quantityControl.value === 0 && +weightControl.value === 0 && +tareControl.value === 0) {
      quantityControl.setErrors({ triple: true });
      weightControl.setErrors({ triple: true });
      tareControl.setErrors({ triple: true });
      return true;
    }
    quantityControl.setErrors({ triple: null });
    quantityControl.updateValueAndValidity();
    weightControl.setErrors({ triple: null });
    weightControl.updateValueAndValidity();
    tareControl.setErrors({ triple: null });
    tareControl.updateValueAndValidity();
    return false;
  }

  applyModifications(): void {
    this.unsavedStockMovement = this.cloneStockMovement(this.selectedStockMovement);

    const quantityFormValue = this.popupForm.get("quantity").value;
    const commentFormValue = this.popupForm.get("comment").value;
    const weightFormValue = this.popupForm.get("weight").value;
    const tareFormValue = this.popupForm.get("tare").value;
    const policeBookCheckboxValue = this.popupForm.get("policeBook").value;

    this.selectedStockMovement.quantity = quantityFormValue ? +quantityFormValue : null;
    this.selectedStockMovement.comment = commentFormValue ? `${this.getCommentSuffix()} ${commentFormValue}` : null;
    if ((this.stockEntryLocation.tare === 0 || this.stockEntryLocation.tare === null) && tareFormValue === null) {
      this.selectedStockMovement.tare = null;
      this.unsavedStockMovement.tare = null;
    } else {
      this.selectedStockMovement.tare = tareFormValue;
    }
    if ((this.stockEntryLocation.weight === 0 || this.stockEntryLocation.weight === null) && weightFormValue === null) {
      this.selectedStockMovement.weight = 0;
      this.unsavedStockMovement.weight = 0;
    } else {
      this.selectedStockMovement.weight = weightFormValue;
    }
    this.selectedStockMovement.policeBook = policeBookCheckboxValue ? policeBookCheckboxValue : false;
    this.initialStockMovement.policeBook = policeBookCheckboxValue ? policeBookCheckboxValue : false;
    this.unsavedStockMovement.policeBook = policeBookCheckboxValue ? policeBookCheckboxValue : false;
  }

  triggerDataLossWarningMessage(): void {
    const title = this.translateService.instant("global.errors.unsaved-title");
    const content = this.translateService.instant("global.errors.unsaved-popin-content");
    this.messageService.info(content, { title });
  }

  triggerSuccessfulSaveMessage(): void {
    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
  }

  triggerTripleZeroErrorMessage(): void {
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant("out-of-stock-popup.errors.triple-zero");
    this.messageService.error(content, { title });
  }

  getCommentSuffix(): string {
    return this.translateService.instant("out-of-stock-popup.comment-suffix");
  }

  getPopupTitle(): string {
    let title: string = this.translateService.instant("out-of-stock-popup.title");
    const subTitle: string = this.stockEntryLocation?.stockEntry?.itemReference;

    if (subTitle) {
      title += ` - ${subTitle}`;
    }
    return title;
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "out-of-stock-popup.errors.out-of-stock-popup",
    };

    let title = this.translateService.instant("message.title.api-errors");

    if ("exception" in error.error) {
      if (error.error.exception.parameters.constraint === "weightlowerthantare") {
        this.popupForm.markAllAsTouched();
        this.popupForm.get("weight").setErrors({ remainingTareTooBig: true });
        this.popupForm.get("tare").setErrors({ remainingTareTooBig: true });
        title = this.translateService.instant("message.title.form-errors");
      }
    }

    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  fetchSupplier(supplierId: number): Observable<Supplier> {
    return this.supplierService.get(supplierId).pipe(
      tap(
        (supplier: Supplier) => {
          this.stockEntrySupplier = supplier;
        },
        error => {
          this.sendErrorAlert(`purchase-modalities-popup.errors.get-supplier`, error.message);
        }
      )
    );
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  computeProrata(): void {
    if (
      this.popupForm.get("quantity").value &&
      this.popupForm.get("quantity").value <= this.stockEntryLocation.quantity
    ) {
      const prorata = this.stockEntryLocation.quantity / this.popupForm.get("quantity").value;
      if (this.stockEntryLocation.weight) {
        this.popupForm.get("weight").setValue((this.stockEntryLocation.weight / prorata).toString());
        this.popupForm.get("weight").markAsTouched();
      }
      if (this.stockEntryLocation.tare) {
        this.popupForm.get("tare").setValue((this.stockEntryLocation.tare / prorata).toString());
        this.popupForm.get("tare").markAsTouched();
      }
    }
  }
}
