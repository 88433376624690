<div class="popup-container">
  <ui-popup [title]="getPopupTitle()" (close)="closePopup()">
    <div content>
      <div class="multi-col">
        <div class="elem">
          <p class="title">{{ "split-delivery-line-popup.infos.line-number" | translate | uppercase }}</p>
          <p>{{ lineNumber }}</p>
        </div>
        <div class="elem">
          <p class="title">{{ "split-delivery-line-popup.infos.quantity" | translate | uppercase }}</p>
          <p>{{ line.receivedQuantity !== null ? line.receivedQuantity : line.expectedQuantity }} {{ purchaseUnit }}</p>
        </div>
      </div>
      <div class="elem">
        <p class="title">{{ "split-delivery-line-popup.infos.item" | translate | uppercase }}</p>
        <p class="item-name">{{ itemName }}</p>
      </div>

      <form class="split-delivery-line-form" [formGroup]="splitForm">
        <div class="line-number">
          <p>{{ "split-delivery-line-popup.form.add" | translate }}</p>
          <ui-input-text
            type="text"
            [maskPattern]="decimalDigitHigh"
            [mandatory]="true"
            formControlName="lines"
            [status]="splitForm.get('lines') | formStatus"
            [errorMessage]="splitForm.get('lines') | formErrorMessage | translate"
          >
          </ui-input-text>
          <p>{{ "split-delivery-line-popup.form.lines" | translate }}</p>
        </div>
        <ui-button
          [text]="'split-delivery-line-popup.form.button' | translate"
          customClass="fugu-button-save"
          [primary]="true"
          (click)="splitLines()"
          [btnIcon]="faCheck"
        >
        </ui-button>
      </form>
    </div>
  </ui-popup>
</div>
