import { ReceivingForm, ItemPurchaseModality, Currency } from "center-services";
import { Comparator } from "fugu-common";

export class InvoiceSupplierInitiatorOutput extends Comparator {
  deliveryForms: ReceivingForm[];
  itemPurchaseModalities: ItemPurchaseModality[];
  deliveryRef: string;
  currency: Currency;
  supplierId: number;

  constructor(values: any = {}) {
    super();
    this.deliveryRef = values.deliveryRef;
    this.supplierId = values.supplierId;

    if (values.deliveryForms) {
      this.deliveryForms = values.deliveryForms.map((deliveryForm: ReceivingForm) => {
        return new ReceivingForm(deliveryForm);
      });
    }

    if (values.itemPurchaseModalities) {
      this.itemPurchaseModalities = values.itemPurchaseModalities.map((itemPurchaseModality: ItemPurchaseModality) => {
        return new ItemPurchaseModality(itemPurchaseModality);
      });
    }

    if (values.currency) {
      this.currency = new Currency(values.currency);
    }
  }

  protected compareValues(attrName: string, thisValue: any[], otherValue: any[]): boolean {
    if (attrName === "deliveryForms") {
      const thisIds = thisValue.map((df: ReceivingForm) => df.id);
      const otherIds = otherValue.map((df: ReceivingForm) => df.id);
      return super.compareValues("deliveryFormIds", thisIds, otherIds);
    } else if (attrName === "itemPurchaseModalities") {
      const thisIds = thisValue.map((ipm: ItemPurchaseModality) => ipm.purchaseModalityId);
      const otherIds = otherValue.map((ipm: ItemPurchaseModality) => ipm.purchaseModalityId);
      return super.compareValues("itemPurchaseModalityIds", thisIds, otherIds);
    } else {
      return super.compareValues(attrName, thisValue, otherValue);
    }
  }
}
