import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { faBuilding, faStore, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { CaraUser, StoreLink, AuthService, CaraUserService } from "center-services";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "app-store-selection-popup",
  templateUrl: "./store-selection-popup.component.html",
  styleUrls: ["./store-selection-popup.component.scss"],
  providers: [SubscriptionService],
})
export class StoreSelectionPopupComponent implements OnInit {
  @Input() user: CaraUser;
  @Input() login: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter();

  filteredStores: StoreLink[];
  searchControl: UntypedFormControl = new UntypedFormControl();
  readonly faStore: IconDefinition = faStore;
  readonly faBuilding: IconDefinition = faBuilding;
  selectedStoreId: number;
  titleTrad: string;

  constructor(
    private authService: AuthService,
    private caraUserService: CaraUserService,
    private translateService: TranslateService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    if (this.login) {
      this.titleTrad = this.translateService.instant("store-selection.title.login");
    } else {
      this.titleTrad = this.translateService.instant("store-selection.title.logged");
    }
    this.selectedStoreId = this.authService.getContextStoreId();
    this.filteredStores = this.user.stores;
    this.subscriptionService.subs.push(
      this.searchControl.valueChanges.subscribe(value => {
        if (value) {
          this.search(value);
        } else {
          this.filteredStores = this.user.stores;
        }
      })
    );
  }

  search(search: string): void {
    this.filteredStores = this.user.stores.filter(
      (store: StoreLink) =>
        store.storeName?.toLowerCase().includes(search.toLowerCase()) ||
        store.reference?.toLowerCase().includes(search.toLowerCase())
    );
  }

  select(storeId: number): void {
    this.subscriptionService.subs.push(
      this.caraUserService.changeContext(storeId).subscribe(result => {
        this.authService.saveToken(result);
        this.selectedStoreId = this.authService.getContextStoreId();
        this.close.emit();
        this.router.navigateByUrl("/");
      })
    );
  }

  closePopup(): void {
    this.close.emit();
  }
}
