import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { IconDefinition, faCheck, faRandom, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  CaraUser,
  Job,
  Profile,
  JobService,
  ProfileService,
  UserService,
  JobType,
  StoreLink,
  PersonTitleType,
  DateFormatUtils,
} from "center-services";
import { DayjsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { CannotContainSpaceUtil, ErrorUtil, PasswordUtil } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-employees-popup",
  templateUrl: "./employees-popup.component.html",
  styleUrls: ["./employees-popup.component.scss"],
  providers: [SubscriptionService],
})
export class EmployeesPopupComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() user: CaraUser;
  @Input() storeId: number;
  @Input() storeName: string;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();
  public editedUser: CaraUser;
  public initialUser: CaraUser;
  public unsavedUser: CaraUser;
  public popupForm: UntypedFormGroup;
  public shouldClose: boolean = false;
  public symbols: string = PasswordUtil.symbols.join(", ");
  faRandom: IconDefinition = faRandom;
  faTimes: IconDefinition = faTimes;
  faCheck: IconDefinition = faCheck;
  public countryFlags: Map<string, string> = Object.create({ fr: "fr", en: "gb" });
  public jobs: Job[];
  public popupTitle: string;
  public switchLoginAutoComplete: boolean = false;
  public mandatoryPasswordFields: any[] = [];
  public locale: string;
  public dateFormat: string;
  public infoLabel: string = "";
  public jobOptions: Option[] = [];
  public dateFormatOptions: Option[] = [];
  public codeLangOptions: Option[] = [];
  public typeOptions: Option[] = [];
  public safeAbilityOptions: Option[] = [];
  public profileOptions: Option[] = [];
  public pattern: any = {
    P: {
      pattern: new RegExp("[+]|[0-9]"),
      optional: true,
    },
    N: {
      pattern: new RegExp("\\d"),
    },
    Q: {
      pattern: new RegExp("[^~]+"),
    },
  };
  public password: HTMLElement;
  public passwordConstraints: any;
  public isAdmin: boolean = false;
  public profiles: Profile[] = [];
  private dateFormats: string[];
  private initObservables: Observable<any>[];

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private jobService: JobService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private userService: UserService<CaraUser>,
    private cd: ChangeDetectorRef,
    private subscriptionService: SubscriptionService
  ) {
    this.codeLangOptions = [new Option(0, "fr"), new Option(1, "en")];
  }

  @ViewChild("passwordField", { read: ElementRef }) set setPasswordField(elem: ElementRef) {
    this.password = elem ? elem.nativeElement.getElementsByTagName("input")[0] : undefined;
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnChanges(): void {
    this.popupTitle = this.user ? "update" : "new";

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );

    if (!this.user) {
      this.createUser();
      this.switchLoginAutoComplete = true;
    } else {
      this.editedUser = new CaraUser(this.user);
      this.infoLabel = this.translateService.instant("user-form.infos.change-password");
    }
    if (this.initObservables !== undefined) {
      this.subscriptionService.subs.push(
        combineLatest(this.initObservables).subscribe(this.initializePopup.bind(this))
      );
    }
  }

  ngOnInit(): void {
    this.prepareForm();

    this.initObservables = [];

    this.passwordConstraints = PasswordUtil.getValidators(null, null);

    this.initObservables.push(this.fetchProfiles());
    this.initObservables.push(this.fetchJobs());

    this.subscriptionService.subs.push(combineLatest(this.initObservables).subscribe(this.initializePopup.bind(this)));

    this.buildTypeOptions();
    this.buildSafeAbilityOptions();
    this.applyPasswordValidators();
    this.fillDateFormatOptionList();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildTypeOptions();
        this.buildSafeAbilityOptions();
        this.fillDateFormatOptionList();
      })
    );
  }

  fetchJobs(): Observable<Job[]> {
    return this.jobService.getAllByType(JobType.EMPLOYEE).pipe(
      tap(
        (jobs: Job[]) => {
          this.jobs = jobs;
          this.jobOptions = jobs
            .filter((obj: Job) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Job) => new Option(obj.id, obj.name));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("jobs-list.errors.get-jobs", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchProfiles(): Observable<Profile[]> {
    return this.profileService.getAll().pipe(
      tap(
        (profiles: Profile[]) => {
          this.profiles = profiles;
          this.profileOptions = profiles
            .filter((obj: Profile) => !obj.archived && !obj.mainStoreAccess)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Profile) => new Option(obj.id, obj.name));
          if (this.initialUser) {
            this.setDefaultProfile();
          }
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("user-form.errors.get-profiles", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  prepareForm(): void {
    const length = 4;
    const passwordLength = 8;

    this.popupForm = this.fb.group({
      titleType: [null],
      lastName: [null, [Validators.required]],
      firstName: [null, [Validators.required, CannotContainSpaceUtil.cannotContainSpace]],
      userNumber: [null, [Validators.required]],
      login: [null, [Validators.required]],
      pinCode: [null, [Validators.minLength(length), Validators.maxLength(length), Validators.pattern("^[0-9]*$")]],
      profile: [null, [Validators.required]],
      email: [null, [Validators.email]],
      hireDate: [null, [Validators.required]],
      dateFormat: [null, [Validators.required]],
      codeLanguage: [null, [Validators.required]],
      jobs: [null],
      phone: [null],
      mobilePhone: [null],
      weeklyWorkTime: [null],
      endContractDate: [null],
      safeAbility: [false, [Validators.required]],
      confirmPassword: [null],
      tempPassword: [null, [Validators.minLength(passwordLength)]],
    });
    this.popupForm.setValidators([this.dateValidator()]);
    this.popupForm.setValidators([PasswordUtil.check("tempPassword", "confirmPassword"), this.dateValidator()]);
    if (!this.user) {
      this.popupForm.controls.tempPassword.setValidators([Validators.minLength(passwordLength), Validators.required]);
    }
    this.subscriptionService.subs.push(
      this.popupForm.controls.tempPassword.valueChanges.subscribe(value => {
        this.passwordConstraints = PasswordUtil.getValidators(value, passwordLength);
      })
    );
  }

  applyPasswordValidators(): void {
    if (!this.user) {
      this.mandatoryPasswordFields = ["pinCode", "tempPassword", "confirmPassword"];

      this.mandatoryPasswordFields.forEach(element => {
        this.popupForm.controls[element].validator
          ? this.popupForm.controls[element].setValidators([
            Validators.required,
            this.popupForm.controls[element].validator,
          ])
          : this.popupForm.controls[element].setValidators([Validators.required]);
      });
    }
  }

  createUser(): void {
    const user = this.userService.connectedUser;
    this.editedUser = new CaraUser({
      titleType: null,
      lastName: null,
      firstName: null,
      userNumber: null,
      login: null,
      pinCode: null,
      weeklyWorkTime: null,
      email: null,
      jobs: null,
      phone: null,
      mobilePhone: null,
      hireDate: null,
      endContractDate: null,
      safeAbility: false,
      dateFormat: user && user.value ? user.value.dateFormat : "DD/MM/YYYY HH:mm:ss",
      codeLanguage: user && user.value ? user.value.codeLanguage : "fr",
      profileId: null,
      profileName: null,
      archived: false,
      password: null,
      stores: [
        new StoreLink({
          byDefault: false,
          archived: false,
          storeName: this.storeName,
          storeId: this.storeId,
        }),
      ],
    });
  }

  // Manage the Forms Controls
  initializePopup(): void {
    this.initialUser = new CaraUser(this.editedUser);

    this.popupForm.controls.lastName.setValue(this.editedUser.lastName);
    this.popupForm.controls.firstName.setValue(this.editedUser.firstName);
    this.popupForm.controls.userNumber.setValue(this.editedUser.userNumber);
    this.popupForm.controls.login.setValue(this.editedUser.login);
    this.popupForm.controls.weeklyWorkTime.setValue(this.editedUser.weeklyWorkTime);
    this.popupForm.controls.email.setValue(this.editedUser.email);
    this.popupForm.controls.phone.setValue(this.editedUser.phone);
    this.popupForm.controls.mobilePhone.setValue(this.editedUser.mobilePhone);
    this.popupForm.controls.hireDate.setValue(DayjsUtil.dayjsOrNull(this.editedUser.hireDate, true));
    this.popupForm.controls.endContractDate.setValue(DayjsUtil.dayjsOrNull(this.editedUser.endContractDate, true));
    this.popupForm.controls.safeAbility.setValue(this.editedUser.safeAbility ? 0 : 1);

    // handle type
    if (this.editedUser.titleType) {
      const index = Object.keys(PersonTitleType).indexOf(this.editedUser.titleType);
      this.popupForm.controls.titleType.setValue(index);
    }

    // handle jobs
    if (this.editedUser.jobIds && this.jobs) {
      this.popupForm.controls.jobs.setValue(
        this.editedUser.jobIds.filter(jobId => !this.jobs.find(job => job.id === jobId).archived)
      );
    }

    if (!this.editedUser.id) {
      this.generatePinCode(true);
      this.generateUserNumber();
      this.generatePassword(true);
    }

    this.setDefaultProfile();
    this.setDefaultLanguage();
    this.setDefaultDateFormat();
    this.managePasswordAndEmailFields();
  }

  setDefaultProfile(): void {
    if (this.editedUser.profileName) {
      const userProfileOpt = this.profileOptions.find((profileOpt: Option) => {
        return profileOpt.label === this.editedUser.profileName;
      });
      if (userProfileOpt) {
        this.popupForm.controls.profile.setValue(userProfileOpt.id);
        this.initialUser.profileId = userProfileOpt.id as number;
      }
    }
  }

  setDefaultLanguage(): void {
    if (this.editedUser.codeLanguage) {
      this.popupForm.controls.codeLanguage.setValue(
        this.codeLangOptions.findIndex((option: Option) => {
          return option.label === this.editedUser.codeLanguage;
        })
      );
    }
  }

  setDefaultDateFormat(): void {
    if (this.editedUser.dateFormat) {
      if (this.dateFormat !== undefined && this.popupForm !== undefined) {
        this.popupForm.controls.dateFormat.setValue(DateFormatUtils.getIndex(this.initialUser.dateFormat));
      }
    }
  }

  fillDateFormatOptionList(): void {
    this.dateFormats = [
      this.translateService.instant("employees-popup.fields.date-format-options.option-1"),
      this.translateService.instant("employees-popup.fields.date-format-options.option-2"),
    ];
    this.dateFormatOptions = this.dateFormats.map((format, index) => new Option(index, format));
  }

  generateUserNumber(): void {
    this.subscriptionService.subs.push(
      this.userService.getUserNumber().subscribe((userNumber: string) => {
        this.initialUser.userNumber = userNumber;
        this.popupForm.controls.userNumber.setValue(userNumber);
      })
    );
  }

  buildTypeOptions(): void {
    this.typeOptions = Object.keys(PersonTitleType).map(
      (key, index) => new Option(index, this.translateService.instant(`employees-popup.fields.type-options.${key}`))
    );
  }

  buildSafeAbilityOptions(): void {
    this.safeAbilityOptions[0] = new Option(
      0,
      this.translateService.instant(`employees-popup.fields.safe-ability-options.YES`)
    );
    this.safeAbilityOptions[1] = new Option(
      1,
      this.translateService.instant(`employees-popup.fields.safe-ability-options.NO`)
    );
  }

  submitUser(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    this.applyModifications();

    if (this.editedUser.password) {
      this.editedUser.password = PasswordUtil.encrypt(this.editedUser.password);
    }

    const action = this.editedUser && this.editedUser.id ? "update" : "create";

    this.subscriptionService.subs.push(
      this.userService[action].call(this.userService, this.editedUser).subscribe(
        responseUser => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          this.validate.emit(responseUser);
          this.dateFormatOptions = [];
          this.fillDateFormatOptionList();
        },
        error => {
          this.handleApiError(error);
        }
      )
    );
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      login: "employees-popup.fields.login",
      email: "employees-popup.fields.email",
      userNumber: "employees-popup.fields.user-number",
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  applyModifications(): void {
    this.editedUser.titleType = Object.values(PersonTitleType)[this.popupForm.value.titleType];
    this.editedUser.lastName = this.popupForm.value.lastName;
    this.editedUser.firstName = this.popupForm.value.firstName;
    this.editedUser.userNumber = this.popupForm.value.userNumber;
    this.editedUser.login = this.popupForm.value.login;
    this.editedUser.weeklyWorkTime = this.popupForm.value.weeklyWorkTime;
    this.editedUser.profileId = this.popupForm.value.profile;
    this.editedUser.email = this.popupForm.value.email;
    this.editedUser.phone = this.popupForm.value.phone;
    this.editedUser.mobilePhone = this.popupForm.value.mobilePhone;

    if (this.popupForm.value.pinCode !== null) {
      this.editedUser.pinCode = this.popupForm.value.pinCode.toString();
    }

    this.editedUser.hireDate = this.popupForm.value.hireDate ? this.popupForm.value.hireDate.toDate() : null;
    this.editedUser.endContractDate = this.popupForm.value.endContractDate
      ? this.popupForm.value.endContractDate.toDate()
      : null;

    this.editedUser.safeAbility = this.popupForm.value.safeAbility === 0 ? true : false;
    this.editedUser.dateFormat = DateFormatUtils.getDateFormat(this.popupForm.value.dateFormat);
    this.editedUser.codeLanguage = this.popupForm.value.codeLanguage;

    this.editedUser.jobIds = null;
    if (this.popupForm.value.jobs) {
      this.editedUser.jobIds = this.popupForm.value.jobs;
    }

    if (this.codeLangOptions[this.popupForm.value.codeLanguage]) {
      this.editedUser.codeLanguage = this.codeLangOptions[this.popupForm.value.codeLanguage].label;
    }

    if (this.editedUser.profileId) {
      this.editedUser.profileName = this.profileOptions.find(option => option.id === this.editedUser.profileId).label;
    }

    if (this.popupForm.value.tempPassword) {
      this.editedUser.password = this.popupForm.value.tempPassword;
    } else {
      this.editedUser.password = "";
    }
  }

  // Close the popup
  closePopup(): void {
    this.applyModifications();
    if (this.unsavedUser && !this.unsavedUser.equals(this.editedUser)) {
      this.shouldClose = false;
    }

    if (!this.initialUser.equals(this.editedUser) && !this.shouldClose) {
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedUser = new CaraUser(this.editedUser);
    } else {
      this.close.emit();
      this.shouldClose = false;
      this.unsavedUser = null;
    }
  }

  dateValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const endContractDate = group.controls.endContractDate;
      const hireDate = group.controls.hireDate;

      if (!hireDate.value || !endContractDate.value) {
        return;
      }

      if (new Date(endContractDate.value) < new Date(hireDate.value)) {
        endContractDate.setErrors({ badEndDate: true });
      } else {
        endContractDate.setErrors(null);
      }
      return;
    };
  }

  generatePinCode(initialLoading: boolean): void {
    const first = 1000;
    const second = 9000;

    const pinCode = Math.floor(first + Math.random() * second);
    if (initialLoading) {
      this.initialUser.pinCode = pinCode.toString();
    }
    this.popupForm.controls.pinCode.setValue(pinCode);
  }

  generatePassword(initialLoading: boolean): void {
    const tempPassword = PasswordUtil.generate();

    if (initialLoading) {
      this.initialUser.password = tempPassword;
    }
    this.popupForm.controls.tempPassword.setValue(tempPassword);
    this.popupForm.controls.confirmPassword.setValue(tempPassword);
  }

  concatLogin(): void {
    if (this.switchLoginAutoComplete) {
      if (this.popupForm.controls.firstName.value !== null && this.popupForm.controls.lastName.value !== null) {
        let loginConcat = `${this.popupForm.controls.firstName.value}.${this.popupForm.controls.lastName.value}`;
        loginConcat = loginConcat.replace(/\s+|-+/g, "");
        loginConcat = loginConcat.toLowerCase();
        this.popupForm.controls.login.setValue(loginConcat);
      }
    }
  }

  switchOffLoginAutoComplete(): void {
    this.switchLoginAutoComplete = false;
  }

  managePasswordAndEmailFields(): void {
    const profileId = this.popupForm.controls.profile.value;
    if (profileId) {
      this.isAdmin = this.profiles.find(profile => profile.id === profileId).mainStoreAccess;

      // manage the email validator and add "required" property
      if (this.isAdmin) {
        this.popupForm.controls.email.setValidators([Validators.required, Validators.email]);
        this.popupForm.controls.email.updateValueAndValidity();
      } else {
        // reset the email validator with just "email" property
        this.popupForm.controls.email.clearValidators();
        this.popupForm.controls.email.setValidators([Validators.email]);
        this.popupForm.controls.email.updateValueAndValidity();
      }
    }
  }

  shouldCheckPassword(): void {
    if (!this.popupForm.controls.tempPassword.value && !this.popupForm.controls.confirmPassword.value) {
      this.popupForm.controls.confirmPassword.updateValueAndValidity();
      this.popupForm.controls.tempPassword.updateValueAndValidity();
    }
  }

  getValidatorClass(value: boolean): string {
    return value ? "valid" : "invalid";
  }

  passwordMandatory(): boolean {
    return this.user ? false : true;
  }
}
