<ng-container>
  <ui-right-pane
    *ngIf="paneVisible"
    [minWidth]="minWidth"
    [maxWidth]="maxWidth"
    [title]="this.title"
    (close)="this.closePane()"
  >
    <div class="flux-detail-container">
      <div class="flux-detail-content">
        <div class="grid-item">
          <span class="text-color label bolder">{{
            "history-list.datatable.columns.user" | translate | uppercase
          }}</span>
        </div>

        <div class="grid-item">
          {{ row.user }}
        </div>

        <div class="grid-item">
          <span class="text-color label bolder">{{
            "history-list.datatable.columns.action" | translate | uppercase
          }}</span>
        </div>

        <div class="grid-item">
          {{ row.action }}
        </div>

        <div class="grid-item">
          <span class="text-color label bolder">{{
            "history-list.datatable.columns.type" | translate | uppercase
          }}</span>
        </div>

        <div class="grid-item">
          {{ row.type }}
        </div>

        <div class="grid-item">
          <span class="text-color label bolder">{{
            "history-list.datatable.columns.reference" | translate | uppercase
          }}</span>
        </div>

        <div class="grid-item">
          <ui-link
            *ngIf="row.mainObjectLink && row.reference"
            [text]="row.reference"
            [linkIcon]="faUpRightFromSquare"
            (click)="openNewWindow(row.mainObjectLink)"
          ></ui-link>
        </div>
        <div class="grid-item">
          <span class="text-color label bolder">{{
            "history-list.datatable.columns.type-linked-object" | translate | uppercase
          }}</span>
        </div>

        <div class="grid-item">
          {{ row.typeLinkedObjectTranslate }}
        </div>
        <div class="grid-item">
          <span class="text-color label bolder">{{
            "history-list.datatable.columns.list-linked-ids" | translate | uppercase
          }}</span>
        </div>

        <div class="grid-item">
          <ul>
            <li *ngFor="let entry of row.mapLinkedObject | keyvalue">
              <ui-link
                [text]="entry.key"
                [linkIcon]="faUpRightFromSquare"
                (click)="openNewWindow(entry.value)"
              ></ui-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="flux-table">
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            applyLabel="{{ 'filters.apply' | translate }}"
            dateFormat="{{ dateFormat }}"
            (apply)="applyFilters()"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div>
          <ngx-datatable
            #table
            [rows]="rows"
            [rowClass]="rowClass"
            columnMode="force"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="auto"
            [limit]="10"
            [scrollbarH]="true"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
          >
            <ngx-datatable-column
              name="{{ 'flux-list.datatable.columns.type' | translate }}"
              prop="type"
              sortable="true"
              cellClass="top-align"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'flux-list.datatable.columns.object-linked-reference' | translate }}"
              prop="objectLinkedReference"
              sortable="true"
              cellClass="top-align"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'flux-list.datatable.columns.id' | translate }}"
              prop="id"
              sortable="true"
              cellClass="top-align"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'flux-list.datatable.columns.action' | translate }}"
              prop="action"
              sortable="true"
              cellClass="top-align"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'flux-list.datatable.columns.field' | translate }}"
              prop="field"
              sortable="true"
              cellClass="top-align"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'flux-list.datatable.columns.old-value' | translate }}"
              prop="oldValue"
              sortable="true"
              cellClass="top-align"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <ng-container *ngIf="isString(row.oldValue); else notString">
                  {{ row.oldValue }}
                </ng-container>
                <ng-template #notString>
                  {{ row.oldValue ? (row.oldValue | dateTimeFormat: true) : null }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'flux-list.datatable.columns.new-value' | translate }}"
              prop="newValue"
              sortable="true"
              cellClass="top-align"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <ng-container *ngIf="isString(row.newValue); else notString">
                  {{ row.newValue }}
                </ng-container>
                <ng-template #notString>
                  {{ row.newValue ? (row.newValue | dateTimeFormat: true) : null }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount > 0">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </ui-right-pane>
</ng-container>
