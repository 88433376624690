import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { OverrideSizeCategory, SizeCategory, SizeCategoryService, SizeValue, OverrideSizeValue } from "center-services";
import { SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";

@Component({
  selector: "override-size-category-popup",
  templateUrl: "./override-size-categories-popup.component.html",
  styleUrls: ["./override-size-categories-popup.component.scss"],
  providers: [SubscriptionService],
})
export class OverrideSizeCategoryPopupComponent implements OnInit {
  @ViewChild("table") table: DatatableComponent;

  @Input() overrideSizeCategory: OverrideSizeCategory;

  @Output() overrideSizeCategoryChange: EventEmitter<OverrideSizeCategory> = new EventEmitter<OverrideSizeCategory>();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public initialOverrideSizeCategory: OverrideSizeCategory;
  public unsavedOverrideSizeCategory: OverrideSizeCategory;

  public tableControl: UntypedFormGroup;
  public sizeCategory: SizeCategory;

  public shouldClose: boolean = false;

  public popupTitle: string;
  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "value",
      dir: "asc",
    },
  ];

  constructor(
    private sizeCategoryService: SizeCategoryService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) {
    this.tableControl = new UntypedFormGroup({});
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  ngOnInit(): void {
    this.populateRows();
  }

  populateRows(): void {
    this.subscriptionService.subs.push(
      this.sizeCategoryService.get(this.overrideSizeCategory.sizeCategoryId).subscribe((sizeCategory: SizeCategory) => {
        this.rows = [];
        this.sizeCategory = sizeCategory;
        this.popupTitle = this.translateService.instant("override-size-category-popup.title") + this.sizeCategory.name;

        this.sizeCategory.elements.forEach((sizeValue: SizeValue) => {
          this.addRow(sizeValue);
        });

        this.rows = [...this.rows];
        this.initialOverrideSizeCategory = new OverrideSizeCategory(this.overrideSizeCategory);
      })
    );
  }

  addRow(sizeValue: SizeValue): void {
    const overrideValue = this.overrideSizeCategory.elements.find(
      overrideCateg => overrideCateg.sizeValueId === sizeValue.id
    );
    const isOverrideValue = overrideValue !== undefined;

    this.rows.push({
      id: sizeValue.id,
      value: sizeValue.value,
      activated: isOverrideValue,
    });

    if (this.tableControl.controls[`activated_${sizeValue.id}`]) {
      this.tableControl.controls[`activated_${sizeValue.id}`].patchValue(isOverrideValue);
    } else {
      this.tableControl.addControl(`activated_${sizeValue.id}`, new UntypedFormControl(isOverrideValue));
    }
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  submitOverrideSizeCategory(): void {
    this.validate.emit(this.overrideSizeCategory);
  }

  activeCheckboxOnChanges(id: number): void {
    if (this.tableControl.controls[`activated_${id}`].value) {
      this.overrideSizeCategory.elements.push(this.transformSizeValue(id));
    } else {
      const overrideSizeIndex = this.overrideSizeCategory.elements.findIndex(
        overrideValue => overrideValue.sizeValueId === id
      );
      if (overrideSizeIndex >= 0) {
        this.overrideSizeCategory.elements.splice(overrideSizeIndex, 1);
      }
    }
    const rowIndex = this.rows.findIndex(row => row.id === id);
    this.rows[rowIndex].activated = this.tableControl.controls[`activated_${id}`].value;
    this.rows = [...this.rows];
  }

  transformSizeValue(id: number): OverrideSizeValue {
    if (this.sizeCategory.elements.length > 0) {
      for (const sizeValue of this.sizeCategory.elements) {
        if (sizeValue.id === id) {
          return new OverrideSizeValue(sizeValue);
        }
      }
    }
    return null;
  }

  // Close the popup
  closePopup(): void {
    if (this.unsavedOverrideSizeCategory && !this.unsavedOverrideSizeCategory.equals(this.overrideSizeCategory)) {
      this.shouldClose = false;
    }

    if (!this.initialOverrideSizeCategory.equals(this.overrideSizeCategory) && !this.shouldClose) {
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });

      this.unsavedOverrideSizeCategory = new OverrideSizeCategory(this.overrideSizeCategory);
    } else {
      this.close.emit();
      this.shouldClose = false;
      this.unsavedOverrideSizeCategory = null;
    }
  }
}
