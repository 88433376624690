import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { UserService, User } from "center-services";
import { SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";

@Component({
  selector: "gen-forgotten-password-popup",
  templateUrl: "./forgotten-password-popup.component.html",
  styleUrls: ["./forgotten-password-popup.component.scss"],
  providers: [SubscriptionService],
})
export class ForgottenPasswordPopupComponent implements AfterContentChecked {
  @Output() close: EventEmitter<any> = new EventEmitter();

  public popupForm: UntypedFormGroup = new UntypedFormGroup({});

  public buttonDisabled: boolean = false;

  faArrow: IconDefinition = faArrowCircleRight;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: UserService<User>,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  public closePopup(): void {
    this.close.emit();
  }

  public submitPopup(): void {
    if (!this.popupForm.valid) {
      this.popupForm.markAllAsTouched();
      return;
    }
    this.buttonDisabled = true;

    this.subscriptionService.subs.push(
      this.userService.resetPassword(this.popupForm.controls.email.value).subscribe(
        () => {
          const message = this.translateService.instant("forgotten-password-popup.email-send");
          this.messageService.success(message);
          this.close.emit();
        },
        () => {
          this.popupForm.controls.email.setErrors({ "not-found": true });
          this.popupForm.markAllAsTouched();
          this.buttonDisabled = false;
        }
      )
    );
  }

  getEmailErrorMessage(): string {
    const control = this.popupForm.controls.email;

    if (control.hasError("required")) {
      return "global.errors.empty";
    }
    if (control.hasError("not-found")) {
      return "forgotten-password-popup.errors.not-found";
    }
    if (control.hasError("email") || control.hasError("pattern")) {
      return "global.errors.pattern";
    }

    return "";
  }

  private prepareForm(): void {
    this.popupForm = this.fb.group(
      {
        email: [null, [Validators.required, Validators.email]],
      },
      { updateOn: "blur" }
    );
  }
}
