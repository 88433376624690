<ng-container>
  <div class="store-popup-container">
    <ui-popup [title]="titleTrad" (close)="closePopup()">
      <div content>
        <div *ngIf="login">
          {{ "store-selection.description.login" | translate }}
        </div>
        <div *ngIf="!login">
          {{ "store-selection.description.logged" | translate }}
        </div>
        <div class="search">
          <ui-input-text
            type="search"
            placeholder="{{ 'store-selection.input.search' | translate }}"
            [formControl]="searchControl"
          >
          </ui-input-text>
        </div>
        <div class="container">
          <ng-container *ngFor="let store of this.filteredStores">
            <div
              class="card"
              (click)="select(store.storeId)"
              [class.selected]="store.storeId === this.selectedStoreId"
              *ngIf="store.status !== 'INACTIVE' && !store.archived"
            >
              <ui-bubble
                [icon]="store.mainStore ? faBuilding : faStore"
                size="5xl"
                [darker]="store.storeId === this.selectedStoreId ? false : true"
                [subtitle]="store.mainStore ? '' : store.reference"
                customIconSize="2xl"
                (click)="select(store.storeId)"
              ></ui-bubble>
              <div class="name" [class.lighter]="store.storeId === this.selectedStoreId">
                {{ store.storeName | uppercase }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
