<ng-container>
  <div class="main-container" *ngIf="updatedReceivingForm">
    <div class="header">
      <div class="return-link">
        <ui-link
          text="{{ 'receiving-form.buttons.back-to-list' | translate }}"
          [linkIcon]="faChevronLeft"
          (click)="backToPrevious()"
        ></ui-link>
      </div>
      <div class="title">
        <ui-title
          titleText="{{ 'receiving-form.title.read-receipt' | translate: { ref: updatedReceivingForm.deliveryRef } }}"
        ></ui-title>
      </div>
    </div>
    <div class="top-buttons" *ngIf="editBtnVisible">
      <div class="button-edit">
        <ui-button
          text="{{ 'receiving-form.buttons.edit-reception' | translate }}"
          *hasPermission="{
            requireAllPermissions: false,
            permissions: ['RECEIVING_FORM_DIRECT_CREATE_UPDATE', 'RECEIVING_FORM_REGISTERING_CREATE_UPDATE']
          }"
          (click)="editReceivingForm()"
          [btnIcon]="faPen"
          [primary]="true"
        ></ui-button>
      </div>
    </div>
    <div class="receiving-form-body" *ngIf="isLoaded">
      <app-new-delivery-form-header
        [receivingForm]="updatedReceivingForm"
        [detailHeader]="true"
        *ngIf="updatedReceivingForm"
        [readOnly]="true"
        [receiver]="fetcher.receiver"
        [sender]="fetcher.sender"
      ></app-new-delivery-form-header>

      <ui-tab-handler
        #tabHandler
        [tabByUrl]="false"
        [independent]="false"
        *ngIf="fetcher.poList?.length"
        (tabClick)="onTabClick($event)"
      >
        <ui-tab
          [id]="i"
          [active]="i === 0"
          *ngFor="let po of fetcher.poList; let i = index"
          title="{{ 'new-delivery-form.orders.title' | translate: { orderRef: po.orderRef } }}"
        >
          <ng-template #tabTemplate>
            <app-new-delivery-lines
              [retailItemList]="this.fetcher.retailItemList"
              [receiveStatus]="updatedReceivingForm.receiveStatus"
              [(lines)]="updatedReceivingForm.lines"
              [stockLocations]="fetcher.flatStockLocations"
              [type]="updatedReceivingForm.type"
              [creationType]="updatedReceivingForm.creationType"
              [currency]="deliveryCurrency"
              [tabsNumber]="fetcher.poList.length"
              [rowIds]="getRowIds(po)"
              [columns]="lineColumns"
              [readOnly]="true"
              [rows]="rows"
              [index]="i"
            >
            </app-new-delivery-lines>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>

      <ui-container *ngIf="!fetcher.poList?.length">
        <app-new-delivery-lines
          [retailItemList]="this.fetcher.retailItemList"
          [(lines)]="updatedReceivingForm.lines"
          [stockLocations]="fetcher.flatStockLocations"
          [type]="updatedReceivingForm.type"
          [creationType]="updatedReceivingForm.creationType"
          [currency]="deliveryCurrency"
          [columns]="lineColumns"
          [readOnly]="true"
          [rows]="rows"
        >
        </app-new-delivery-lines>
      </ui-container>
    </div>
  </div>
</ng-container>
