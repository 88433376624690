<ng-container>
  <div *ngIf="this.activeStage" class="main-container">
    <div class="return-link">
      <ui-link
        [text]="'inventory-form.buttons.back-to-list' | translate"
        (click)="backToInventoryList()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
    </div>
    <div *ngIf="title" class="title">
      <ui-title [titleText]="title"></ui-title>
    </div>

    <div class="top-buttons" *ngIf="stages.length === 3">
      <div class="primary-action-button">
        <ui-button
          [primary]="true"
          [text]="'inventory-form.buttons.' + getPrimaryActionButtonText() | translate"
          [disabled]="validateBtnDisabled"
          [btnIcon]="activeStage.id === TWO ? faCheck : faArrowCircleRight"
          (click)="actionsManager()"
        ></ui-button>
      </div>
      <div *ngIf="activeStage.id !== ONE" class="save-button margin-left">
        <ui-button
          [text]="'global.validate' | translate"
          [type]="'save'"
          (click)="onSaveButtonClick()"
          [disabled]="false"
        ></ui-button>
      </div>
      <div *ngIf="activeStage.id !== ONE && activeStage.id !== TWO" class="button-print margin-left">
        <ui-button
          [text]="
            (this.inventoryResults?.activeFilters.length === 0
              ? 'inventory-form.buttons.print-list'
              : 'inventory-form.buttons.print-selection'
            ) | translate
          "
          (click)="printInventoryResults()"
          [btnIcon]="faPrint"
        >
        </ui-button>
      </div>
    </div>

    <div class="inventory-body">
      <div class="stages-container">
        <ui-container>
          <div class="align-stages">
            <div
              *ngFor="let stage of stages"
              class="{{ 'stage ' + getStageClass(stage) }}"
              [class.active]="stage.active"
              (click)="changeStage(stage)"
            >
              <div class="number-circle">{{ stage.index }}</div>
              <div class="panel-text">{{ stage.title }}</div>
            </div>
          </div>
        </ui-container>
      </div>
      <div class="body-container">
        <ui-container *ngIf="editedInventory && activeStage.id === ONE">
          <app-inventory-criteria
            #stockEntryLocations
            [(inventory)]="editedInventory"
            (isListEmptyChange)="validateBtnStatus($event)"
          ></app-inventory-criteria>
        </ui-container>

        <ui-container *ngIf="updatedInventory && activeStage.id === TWO">
          <app-inventory-entry
            #inventoryEntries
            [(inventory)]="updatedInventory"
            (submitValidationPopup)="switchToThirdStage()"
          ></app-inventory-entry>
        </ui-container>

        <ui-container *ngIf="updatedInventory && activeStage.id === THREE">
          <app-inventory-result #inventoryResults [inventory]="updatedInventory"></app-inventory-result>
        </ui-container>
      </div>
    </div>
  </div>

  <div class="popup-container">
    <ui-popup
      title="{{ 'inventory-form.popup.title' | translate }}"
      *ngIf="popupVisible"
      (close)="closePopup()"
      [cbIcon]="goToStep2 ? faTimesCircle : null"
    >
      <div content>
        <p class="first-paragraph">{{ "inventory-form.popup.label-first-row" | translate }}</p>
        <p>{{ "inventory-form.popup.label-second-row" | translate }}</p>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-add">
            <ui-button
              text="{{ 'inventory-form.popup.validate' | translate }}"
              [primary]="true"
              type="validate"
              (click)="submitPopup()"
              [disabled]="!goToStep2"
            ></ui-button>
          </div>
          <div class="button-popup-cancel">
            <ui-button
              text="{{ 'inventory-form.popup.cancel' | translate }}"
              [primary]="false"
              type="cancel"
              (click)="closePopup()"
              [disabled]="!goToStep2"
            ></ui-button>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
