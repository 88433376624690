import { TranslateService } from "@ngx-translate/core";

export class EnumTranslationUtil {
  public static buildTranslations(
    translateService: TranslateService,
    myEnum: any,
    rootTranslationString: string,
    translation: { [key: string]: string }
  ): void {
    Object.keys(myEnum).forEach(key => {
      translation[key] = translateService.instant(rootTranslationString.concat(".", key));
    });
  }
}
