<ng-container>
  <div class="main-container frozen-right">
    <div class="title">
      <ui-title titleText="{{ 'invoice-supplier-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-create">
        <ui-button
          text="{{ 'global.new' | translate }}"
          type="add"
          [primary]="true"
          *hasPermission="['INVOICE_SUPPLIER_CREATE']"
          (click)="createInvoiceSupplier()"
        ></ui-button>
      </div>
    </div>
    <div class="invoice-supplier-list">
      <ui-container>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            [locale]="locale"
            dateFormat="{{ dateFormat }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
            mandatoryErrorMessage="{{ 'invoice-supplier-list.errors.filters' | translate }}"
          >
          </ui-dynamic-filters>
        </div>

        <div class="invoice-supplier-table">
          <div>
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material"
              columnMode="force"
              headerHeight="auto"
              footerHeight="50"
              rowHeight="40"
              (activate)="checkInvoiceSupplierDetail($event)"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down'
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [externalSorting]="true"
              [externalPaging]="true"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              scrollbarH="true"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.date' | translate }}"
                prop="date"
                cellClass="date"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.date">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.reference' | translate }}"
                prop="reference"
                cellClass="reference"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.supplier-ref' | translate }}"
                prop="supplierRef"
                cellClass="supplier-ref"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.supplier-name' | translate }}"
                prop="supplierName"
                cellClass="supplier-name"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.quick-payment-date' | translate }}"
                prop="quickPaymentDate"
                cellClass="quick-payment-date"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.quickPaymentDate">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.quick-payment-price' | translate }}"
                prop="quickPaymentPrice"
                cellClass="quick-payment-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO:"symbol":"0.2-5":locale }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.max-payment-date' | translate }}"
                prop="maxPaymentDate"
                cellClass="max-payment-date"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.maxPaymentDate">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.total-gross-price' | translate }}"
                prop="totalGrossPrice"
                cellClass="total-gross-price"
                [resizeable]="false"
                [width]="120"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO:"symbol":"0.2-2":locale }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [sortable]="false"
                [canAutoResize]="false"
                [resizeable]="false"
                headerClass="narrow"
                [draggable]="false"
                [width]="50"
              >
                <ng-template ngx-datatable-header-template>
                  <div #info><fa-icon [icon]="faInfo" class="info-icon"></fa-icon></div>

                  <ui-tooltip maxWidth="300px" [element]="info">
                    <span style="font-weight: normal !important">
                      {{ "invoice-supplier-list.datatable.columns.info-total-gross-price" | translate }}
                    </span>
                  </ui-tooltip>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.tax-price' | translate }}"
                prop="taxPrice"
                cellClass="tax-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO:"symbol":"0.2-2":locale }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.total-price' | translate }}"
                prop="totalPrice"
                cellClass="total-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO:"symbol":"0.2-2":locale }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.total-additional-price' | translate }}"
                prop="totalAdditionalPrice"
                cellClass="total-additional-price"
                [width]="160"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: this.getCurrency(row).codeISO:"symbol":"0.2-2":locale }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [canAutoResize]="false"
                [resizeable]="false"
                headerClass="narrow"
                [width]="50"
                [draggable]="false"
              >
                <ng-template ngx-datatable-header-template>
                  <div #info><fa-icon [icon]="faInfo" class="info-icon"></fa-icon></div>

                  <ui-tooltip maxWidth="300px" [element]="info">
                    <span style="font-weight: normal !important">
                      {{ "invoice-supplier-list.datatable.columns.info-total-additional-price" | translate }}
                    </span>
                  </ui-tooltip>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.status' | translate }}"
                prop="statusLabel"
                cellClass="status frozen-style"
                headerClass="left-align"
                [width]="80"
                [resizeable]="false"
                [frozenRight]="true"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"></ui-status-label>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'invoice-supplier-list.datatable.columns.actions' | translate }}"
                headerClass="center-align"
                cellClass="center-align frozen-style"
                [resizeable]="false"
                [frozenRight]="true"
                [sortable]="false"
                [width]="80"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div *ngIf="row.actionnable" class="green-zone" (click)="$event.stopPropagation()">
                    <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)">
                    </ui-menu-actions>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>

      <ui-popup
        *ngIf="popupVisible"
        title="{{ 'invoice-supplier-list.popup.title' | translate }}"
        (close)="closePopup()"
      >
        <div content>
          <div class="popup-content" [innerHTML]="'invoice-supplier-list.popup.content' | translate"></div>
        </div>
        <div footer>
          <div class="popup-buttons">
            <div class="confirm-button">
              <ui-button
                text="{{ 'invoice-supplier-list.popup.buttons.validate' | translate }}"
                (click)="deleteLine()"
                [primary]="true"
                type="validate"
              ></ui-button>
            </div>

            <div class="cancel-button">
              <ui-button
                text="{{ 'invoice-supplier-list.popup.buttons.cancel' | translate }}"
                (click)="closePopup()"
                type="cancel"
              ></ui-button>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </div>
</ng-container>
