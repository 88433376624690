import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PurchaseOrderLine } from "center-services";
import { MessageService } from "fugu-components";

@Component({
  selector: "app-engraving-popup",
  templateUrl: "./engraving-popup.component.html",
  styleUrls: ["./engraving-popup.component.scss"],
})
export class EngravingPopupComponent implements OnInit, OnChanges {
  @Input() purchaseOrderLine: PurchaseOrderLine;
  @Input() maxEngravingLength: number;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public initialPurchaseOrderLine: PurchaseOrderLine;
  public unsavedPurchaseOrderLine: PurchaseOrderLine;
  public editedPurchaseOrderLine: PurchaseOrderLine;
  public popupForm: UntypedFormGroup;

  public shouldClose: boolean = false;

  public popupTitle: string;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnChanges(): void {
    this.prepareForm();
    this.editedPurchaseOrderLine = new PurchaseOrderLine(this.purchaseOrderLine);
    this.popupTitle = this.translateService.instant("engraving-popup.title");

    this.initialPurchaseOrderLine = new PurchaseOrderLine(this.editedPurchaseOrderLine);
    this.initializePopup();
  }

  ngOnInit(): void {
    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      comment: [null],
    });
    if (this.purchaseOrderLine && this.purchaseOrderLine.engraving) {
      this.popupForm.addControl("text", new UntypedFormControl(null, Validators.maxLength(this.maxEngravingLength)));
      this.popupForm.addControl("font", new UntypedFormControl(null));
      this.popupForm.addControl("location", new UntypedFormControl(null));
    }
  }

  initializePopup(): void {
    if (this.purchaseOrderLine && this.purchaseOrderLine.engraving) {
      this.popupForm.controls.text.setValue(this.editedPurchaseOrderLine.engravingText);
      this.popupForm.controls.font.setValue(this.editedPurchaseOrderLine.engravingFont);
      this.popupForm.controls.location.setValue(this.editedPurchaseOrderLine.engravingLocation);
    }
    this.popupForm.controls.comment.setValue(this.editedPurchaseOrderLine.comment);
  }

  applyModifications(): void {
    if (this.purchaseOrderLine && this.purchaseOrderLine.engraving) {
      this.editedPurchaseOrderLine.engravingText = this.popupForm.controls.text.value;
      this.editedPurchaseOrderLine.engravingFont = this.popupForm.controls.font.value;
      this.editedPurchaseOrderLine.engravingLocation = this.popupForm.controls.location.value;
    }
    this.editedPurchaseOrderLine.comment = this.popupForm.controls.comment.value;
  }

  submitEngravingPopup(): void {
    this.applyModifications();

    // stop here if form is invalid
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }
    this.validate.emit(this.editedPurchaseOrderLine);
  }

  closeEngravingPopup(): void {
    this.applyModifications();

    if (this.unsavedPurchaseOrderLine && !this.unsavedPurchaseOrderLine.equals(this.editedPurchaseOrderLine)) {
      this.shouldClose = false;
    }

    if (!this.initialPurchaseOrderLine.equals(this.editedPurchaseOrderLine) && !this.shouldClose) {
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedPurchaseOrderLine = new PurchaseOrderLine(this.editedPurchaseOrderLine);
    } else {
      this.close.emit();
      this.unsavedPurchaseOrderLine = null;
      this.shouldClose = false;
    }
  }
}
