import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { faInfoCircle, faPrint, faTrashAlt, faMessageDollar, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  CaraUser,
  CaraUserService,
  Currency,
  CurrencyService,
  InvoiceCustomer,
  InvoiceCustomerService,
  InvoiceStatus,
  LightService,
  LightStore,
  PaginatedList,
  Pagination,
  Sort,
  DocumentType,
  AuthService,
  AsynchronousTaskCreation,
  AsynchronousTaskService,
  AppConfig,
  AppConfigService,
  PaymentPeriodType,
  ProfessionalCustomer,
  CustomerService,
  AbstractCustomer,
} from "center-services";
import {
  FilterValue,
  Filterer,
  PaginableComponent,
  SearchFilter,
  SessionPagination,
  SearchFilterOperator,
  DayjsUtil,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService, ToastMessageService } from "fugu-components";
import { ErrorUtil, FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { Observable, combineLatest, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";

@Component({
  selector: "app-invoice-customer-list",
  templateUrl: "./invoice-customer-list.component.html",
  styleUrls: ["./invoice-customer-list.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceCustomerListComponent
  extends FilteredTableListComponent
  implements PaginableComponent, OnDestroy, OnInit {
  private static ALREADY_INVOICED_EXCEPTION: string = "AlreadyInvoicedException";
  private static STATUS_LABEL: string = "statusLabel";
  private static INVOICE_STATUS: string = "invoiceStatus";
  private static DOCUMENT_TYPE: string = "documentType";
  @ViewChild("table") table: any;
  public LIST_ID: string = "app-invoice-customer-list.invoice-list";
  public invoiceCustomerList: InvoiceCustomer[] = [];
  public tableControl: UntypedFormGroup;
  public rows: any[] = null;
  public sorts: any[] = [{ prop: "date", dir: "desc" }];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public currencyList: Currency[] = [];
  public locale: string;
  public dateFormat: string;
  public customerList: AbstractCustomer[];
  public deleteMenuActions: MenuAction[] = [];
  public validatedInvoiceMenuActions: MenuAction[] = [];
  public popupVisible: boolean = false;
  public idToDelete: number;
  public storeList: LightStore[] = [];
  public selectedInvoiceList: InvoiceCustomer[] = [];
  public hasPrintableStatus: boolean = false;
  public hasValidableStatus: boolean = false;
  public validatePopupVisible: boolean = false;
  public dateWarningPopupVisible: boolean = false;
  public isConform: boolean;
  public faTrashAlt: IconDefinition = faTrashAlt;
  public faMessageDollar: IconDefinition = faMessageDollar;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public faPrint: IconDefinition = faPrint;
  public showSubWarningMessage: boolean = false;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  private sessionPagination: SessionPagination;
  private statusTranslations: { [key: string]: string } = {};
  private documentTypeTranslations: { [key: string]: string } = {};
  private initObservables: Observable<any>[] = [];

  private readonly CREDIT_NOTE_ACTION_ID: number = 1;
  private readonly DELETE_ACTION_ID: number = 2;
  // eslint-disable-next-line no-magic-numbers
  private readonly DAY_LIMIT: number = 21;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private invoiceCustomerService: InvoiceCustomerService,
    private currencyService: CurrencyService,
    private router: Router,
    protected userService: CaraUserService,
    private lightService: LightService,
    private fb: UntypedFormBuilder,
    private asynchronousTaskService: AsynchronousTaskService,
    private authService: AuthService,
    private notificationHandlerService: NotificationHandlerService,
    private toastMessageService: ToastMessageService,
    private appConfigService: AppConfigService,
    private customerService: CustomerService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);

    this.sessionPagination = new SessionPagination(this);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      InvoiceStatus,
      "invoice-supplier-list.invoice-status-options",
      this.statusTranslations
    );

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      DocumentType,
      "invoice-customer-list.invoice-document-type-options",
      this.documentTypeTranslations
    );
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.initSelectFormControl();
    this.addMenuActions();
    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.addMenuActions();
      })
    );

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }
    this.initObservables.push(this.fetchCurrencies());
    this.initObservables.push(this.fetchCustomers());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchAppConfig());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters(true);
        this.computeSearchFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.applyFilters();
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      this.sessionPagination.saveToSession(this.LIST_ID);
    }
  }

  public allocateMenuActions(row: any): MenuAction[] {
    const invoice = this.invoiceCustomerList.find(invoice => invoice.id === row.id);

    if (this.canDeleteInvoice(invoice)) {
      return this.deleteMenuActions;
    }

    return invoice.canBeCreditNoted() ? this.validatedInvoiceMenuActions : null;
  }

  addMenuActions(): void {
    this.deleteMenuActions.push(
      new MenuAction(
        this.DELETE_ACTION_ID,
        this.translateService.instant("invoice-customer-list.actions.delete"),
        this.faTrashAlt
      )
    );

    this.validatedInvoiceMenuActions.push(
      new MenuAction(
        this.CREDIT_NOTE_ACTION_ID,
        this.translateService.instant("invoice-customer-list.actions.credit-note"),
        this.faMessageDollar
      )
    );
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case this.CREDIT_NOTE_ACTION_ID:
        this.createCreditNote(row.id);
        break;
      case this.DELETE_ACTION_ID:
        if (!this.userService.canDo("INVOICE_CUSTOMER_DELETE")) {
          return;
        }
        this.openPopup(row.id);
        break;
      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  public getRowControlName(id: string | number): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  updateHeaderPageCheckbox(): void {
    const currentPageCheckedRows = this.rows.filter(row => {
      return this.tableControl.get(this.getRowControlName(row.id))?.value;
    });
    const currentPageSelectableRows = this.rows.filter(row => {
      return this.tableControl.get(this.getRowControlName(row.id)) !== null;
    });
    const isSelected =
      currentPageSelectableRows.length > 0 && currentPageSelectableRows.length === currentPageCheckedRows.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
  }

  updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.rows.forEach(row => {
      controls[this.getRowControlName(row.id)]?.patchValue(isHeaderSelected);
    });
  }

  public updateSelection(): void {
    this.rows.forEach(row => {
      const rowControl = this.tableControl.controls[this.getRowControlName(row.id)];
      if (!rowControl) {
        return;
      }
      const rowChecked: boolean = rowControl.value;
      const invoice: InvoiceCustomer = this.invoiceCustomerList.find(elem => elem.id === row.id);
      const isShipmentFormSelected: boolean =
        this.selectedInvoiceList.findIndex(elem => elem.id === row.id) === -1 ? false : true;

      if (!isShipmentFormSelected && rowChecked) {
        this.selectedInvoiceList.push(invoice);
      } else if (isShipmentFormSelected && !rowChecked) {
        const poIndex = this.selectedInvoiceList.findIndex(elem => elem.id === invoice.id);
        this.selectedInvoiceList.splice(poIndex, 1);
      }
    });
    this.checkSelectedStatus();
  }

  checkSelectedStatus(): void {
    this.hasPrintableStatus = this.hasValidableStatus = false;
    const selectedStatus = new Set(this.selectedInvoiceList.map((invoice: InvoiceCustomer) => invoice.invoiceStatus));
    // if we have only draft invoices, we can validate them
    if (selectedStatus.has(InvoiceStatus.DRAFT) && selectedStatus.size === 1) {
      this.hasValidableStatus = true;
    }
    // if we have only exported/validated invoices, we can print them
    if (!selectedStatus.has(InvoiceStatus.DRAFT)) {
      this.hasPrintableStatus = true;
    }

    const selectedType = new Set(this.selectedInvoiceList.map((invoice: InvoiceCustomer) => invoice.documentType));
    if (selectedType.has(DocumentType.INVOICE) && !this.hasRights(["INVOICE_CUSTOMER_UPDATE"])) {
      this.hasValidableStatus = false;
    }

    if (selectedType.has(DocumentType.CREDIT_NOTE) && !this.hasRights(["CREDIT_NOTE_CUSTOMER_UPDATE"])) {
      this.hasValidableStatus = false;
    }
  }

  fetchInvoiceCustomerList(): void {
    this.subscriptionService.subs.push(
      this.invoiceCustomerService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<InvoiceCustomer>) => {
          this.rows = [];
          this.invoiceCustomerList = result.data;
          this.pager = result.page;
          result.data.forEach((invoiceCustomer: InvoiceCustomer) => {
            this.addRow(invoiceCustomer);
          });
        },
        error => {
          this.sendErrorAlert("invoice-customer-list.errors.get-invoice-customers", error.message);
        },
        () => {
          this.rows = [...this.rows];
          if (this.table) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
        }
      )
    );
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencyList = currencies;
        },
        error => {
          this.sendErrorAlert("invoice-supplier-list.errors.get-currencies", error.message);
        }
      )
    );
  }

  getCurrency(row: any): Currency {
    return this.currencyList.find(currency => currency.id === row.currencyId);
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  public changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];
    this.fetchInvoiceCustomerList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchInvoiceCustomerList();
  }

  propToDto(prop: string): string {
    switch (prop) {
      case InvoiceCustomerListComponent.STATUS_LABEL:
        return InvoiceCustomerListComponent.INVOICE_STATUS;
      default:
        return prop;
    }
  }

  buildDateDefaultValue(): any {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    return {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
  }

  baseFilter(): void {
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);
  }

  initFilters(firstFetch: boolean = false): void {
    if (this.filterer && !firstFetch) {
      return;
    }

    this.baseFilter();

    this.filterer.addListFilter(
      InvoiceCustomerListComponent.DOCUMENT_TYPE,
      this.translateService.instant("invoice-customer-list.datatable.columns.document-type"),
      Object.keys(DocumentType).map(key => ({ value: key, displayValue: this.documentTypeTranslations[key] }))
    );

    this.filterer.addFilter(
      "reference",
      this.translateService.instant("invoice-customer-list.datatable.columns.reference"),
      "string"
    );

    this.filterer.addListFilter(
      "customerId",
      this.translateService.instant("invoice-customer-list.datatable.columns.customer-ref"),
      [...this.customerList].map(customer => {
        return { value: customer.id.toString(), displayValue: customer.reference };
      }),
      false,
      false,
      null,
      null,
      true,
      false
    );

    this.filterer.addListFilter(
      "storeIds",
      this.translateService.instant("invoice-customer-list.datatable.columns.stores"),
      this.storeList.map(store => {
        return { value: store.id.toString(), displayValue: store.name };
      }),
      false,
      false,
      null,
      null,
      true,
      false,
      false,
      false,
      false,
      SearchFilterOperator.IN_JSONB
    );

    this.filterer.addFilter(
      "date",
      this.translateService.instant(`invoice-customer-list.datatable.columns.date`),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );

    this.filterer.addFilter(
      "maxPaymentDate",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.max-payment-date`),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );

    this.filterer.addFilter(
      "totalGrossPrice",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.total-gross-price`),
      "range"
    );
    this.filterer.addFilter(
      "taxPrice",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.tax-price`),
      "range"
    );
    this.filterer.addFilter(
      "totalPrice",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.total-price`),
      "range"
    );

    this.filterer.addFilter(
      "lines.newDeliveryLine.newDeliveryForm.deliveryRef",
      this.translateService.instant("invoice-customer-list.datatable.columns.deliveries"),
      "string"
    );

    this.filterer.addFilter(
      "lines.invoiceCustomerLine.invoiceCustomer.reference",
      this.translateService.instant("invoice-customer-list.datatable.columns.invoice-references"),
      "string"
    );

    this.filterer.addListFilter(
      InvoiceCustomerListComponent.INVOICE_STATUS,
      this.translateService.instant("purchase-order-list.datatable.columns.status"),
      Object.keys(InvoiceStatus).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchInvoiceCustomerList();
      })
    );
  }

  public createInvoiceCustomer(): void {
    if (!this.userService.canDo("INVOICE_CUSTOMER_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/invoice-customer/add");
  }

  public createCreditNote(invoiceId: number = null): void {
    if (!this.userService.canDo("CREDIT_NOTE_CUSTOMER_CREATE")) {
      return;
    }

    const param = invoiceId ? `?invoiceId=${invoiceId}` : "";
    this.router.navigateByUrl(`/credit-note-customer/create${param}`);
  }

  public checkInvoiceDetail(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("INVOICE_CUSTOMER_READ")) {
        return;
      }
      const selectedInvoice: InvoiceCustomer = this.invoiceCustomerList.find(invoice => invoice.id === event.row.id);
      if (!this.updateRights(selectedInvoice.documentType)) {
        return;
      }
      const filteredList = this.invoiceCustomerList.filter(invoice => invoice.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find invoice with id ${event.row.id}`);
        return;
      }
      if (filteredList[0].documentType === DocumentType.INVOICE) {
        if (filteredList[0].invoiceStatus === InvoiceStatus.DRAFT) {
          this.router.navigateByUrl(`/invoice-customer/update/${event.row.id}`);
        } else {
          this.router.navigateByUrl(`/invoice-customer-detail/${event.row.id}`);
        }
      } else {
        if (filteredList[0].invoiceStatus === InvoiceStatus.DRAFT) {
          this.router.navigateByUrl(`/credit-note-customer/update/${event.row.id}`);
        } else {
          this.router.navigateByUrl(`/credit-note-customer-detail/${event.row.id}`);
        }
      }
    }
  }

  public deleteLine(): void {
    this.subscriptionService.subs.push(
      this.invoiceCustomerService.delete(this.idToDelete).subscribe(
        () => {
          this.closePopup();
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("invoice-supplier-list.delete-success");
          this.messageService.success(content, { title });
          const selectedIdx = this.selectedInvoiceList.findIndex(inv => inv.id === this.idToDelete);
          // if the element was selected, remove it from the selection and reload the buttons' status
          if (selectedIdx >= 0) {
            this.selectedInvoiceList.splice(selectedIdx, 1);
            this.tableControl.removeControl(this.getRowControlName(this.idToDelete));
            this.checkSelectedStatus();
          }
          this.fetchInvoiceCustomerList();
        },
        () => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("invoice-supplier-list.errors.delete");
          this.messageService.error(content, { title });
        }
      )
    );
  }

  openPopup(id: number): void {
    this.popupVisible = true;
    this.idToDelete = id;
  }

  closePopup(): void {
    this.popupVisible = false;
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  public handlePrintBatch(): void {
    const selected = this.selectedInvoiceList.map((invoiceCustomer: InvoiceCustomer) => invoiceCustomer.id);
    const search = new SearchFilter("id", SearchFilterOperator.IN, selected).toQuery();
    const asyncCreationTask = {
      type: "generatePdf",
      params: `invoice-customer;${search};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  public printSelectedInvoices(): void {
    if (!this.selectedInvoiceList || this.selectedInvoiceList.length === 0) {
      return;
    }
    this.handlePrintBatch();
  }

  public validateSelectedInvoices(print: boolean): void {
    if (!this.selectedInvoiceList || this.selectedInvoiceList.length === 0) {
      return;
    }

    const sortedInvoices = this.selectedInvoiceList.sort((a, b) => a.date?.getTime() - b.date?.getTime());

    const invoiceValidationObservables = sortedInvoices.map(invoice => {
      if (
        this.isConform &&
        invoice.invoiceStatus === InvoiceStatus.DRAFT &&
        invoice.documentType === DocumentType.INVOICE
      ) {
        invoice = this.computeDates(invoice);
        return this.invoiceCustomerService.update(invoice).pipe(
          switchMap(updatedInvoice => this.invoiceCustomerService.validate(updatedInvoice.id)),
          catchError(error => {
            this.handleValidateApiError(error);
            return throwError(error);
          })
        );
      }

      return this.invoiceCustomerService.validate(invoice.id).pipe(
        catchError(error => {
          this.handleValidateApiError(error);
          return throwError(error);
        })
      );
    });
    // Use combineLatest to wait for all observables to emit their final values
    this.subscriptionService.subs.push(
      combineLatest(invoiceValidationObservables).subscribe(() => {
        const title = this.translateService.instant("message.title.save-success");
        const content = this.translateService.instant("invoice-customer-list.validate-success");
        this.messageService.success(content, { title });
        this.fetchInvoiceCustomerList();
        print ? this.printSelectedInvoices() : this.resetSelection();
      })
    );
  }

  openValidatePopup(): void {
    if (this.isConform) {
      this.dateWarningPopupVisible = true;
      return;
    }

    this.validatePopupVisible = true;
    const invoiceCustomers: InvoiceCustomer[] = this.selectedInvoiceList.filter((selectedInvoice: InvoiceCustomer) => {
      return selectedInvoice.date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
    });

    if (invoiceCustomers.length > 0) {
      this.showSubWarningMessage = true;
    }
  }

  closeInvoiceCustomerValidation(): void {
    this.validatePopupVisible = false;
    this.showSubWarningMessage = false;
  }

  closeDateWarningPopup(): void {
    this.dateWarningPopupVisible = false;
  }

  validateInvoiceCustomerEvent(print: boolean): void {
    this.validateSelectedInvoices(print);
  }

  public hasRights(rights: string[]): boolean {
    return this.authService.hasCombineRights([["GOD"], rights]);
  }

  protected computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  private initSelectFormControl(): void {
    this.tableControl = this.fb.group({
      headerCheckbox: new UntypedFormControl(false),
    });
  }

  private resetSelection(): void {
    this.selectedInvoiceList = [];
    for (const key in this.tableControl.controls) {
      if (this.tableControl.controls.hasOwnProperty(key)) {
        this.tableControl.controls[key].setValue(false);
      }
    }
  }

  private fetchCustomers(): Observable<PaginatedList<AbstractCustomer>> {
    return this.customerService.getAll(this.pager, [], []).pipe(
      tap(
        (result: PaginatedList<AbstractCustomer>) => {
          this.customerList = result.data;
        },
        error => {
          this.sendErrorAlert("invoice-supplier-list.errors.get-customers", error.message);
        }
      )
    );
  }

  private fetchStores(): Observable<LightStore[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: LightStore[]) => {
          this.storeList = lightStores;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("delivery-initiator-popup.errors.get-stores");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private canDeleteInvoice(invoice: InvoiceCustomer): boolean {
    return invoice.canBeDeleted() && this.deleteRights(invoice.documentType);
  }

  private addRow(invoiceCustomer: InvoiceCustomer): void {
    if (this.isConform && invoiceCustomer.invoiceStatus === InvoiceStatus.DRAFT) {
      invoiceCustomer = this.computeDates(invoiceCustomer);
    }
    this.rows.push({
      id: invoiceCustomer.id,
      reference: invoiceCustomer.reference,
      customerRef: invoiceCustomer.customerRef,
      storeNames: invoiceCustomer.storeNames,
      date: invoiceCustomer.date,
      maxPaymentDate: invoiceCustomer.documentType === DocumentType.INVOICE ? invoiceCustomer.maxPaymentDate : null,
      totalGrossPrice: invoiceCustomer.totalGrossPrice,
      taxPrice: invoiceCustomer.taxPrice,
      totalPrice: invoiceCustomer.totalPrice,
      deliveries: invoiceCustomer.deliveryReferences,
      currencyId: invoiceCustomer.currencyId,
      statusLabel: this.translateService.instant(
        `invoice-supplier-list.invoice-status-options.${invoiceCustomer.invoiceStatus}`
      ),
      statusClass: this.getStatusClass(invoiceCustomer.invoiceStatus),
      actionnable: this.canDeleteInvoice(invoiceCustomer) || invoiceCustomer.canBeCreditNoted(),
      documentType: this.translateService.instant(
        `invoice-customer-list.invoice-document-type-options.${invoiceCustomer.documentType}`
      ),
      invoiceReferences: invoiceCustomer.invoiceReferences,
    });
    this.tableControl.addControl(
      this.getRowControlName(invoiceCustomer?.id?.toString()),
      new UntypedFormControl(false)
    );
  }

  private getStatusClass(status: InvoiceStatus): string {
    return `status-${status.toLowerCase()}`;
  }

  private handleValidateApiError(error: any): void {
    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    let content = this.translateService.instant(result.message, result.params);

    if (
      error?.error?.exception &&
      error?.error?.exception?.name === InvoiceCustomerListComponent.ALREADY_INVOICED_EXCEPTION
    ) {
      const translationKey =
        error.error.exception.parameters.attribute.length > 1
          ? "invoice-customer-list.errors.already-invoiced-plural"
          : "invoice-customer-list.errors.already-invoiced-singular";

      content = this.translateService.instant(translationKey, {
        ref: error.error.exception.parameters.attribute.join(", "),
      });
    }
    this.messageService.error(content, { title });
  }

  private deleteRights(documentType: DocumentType): boolean {
    return documentType === DocumentType.INVOICE
      ? this.hasRights(["INVOICE_CUSTOMER_DELETE"])
      : this.hasRights(["CREDIT_NOTE_CUSTOMER_DELETE"]);
  }

  private updateRights(documentType: DocumentType): boolean {
    return documentType === DocumentType.INVOICE
      ? this.hasRights(["INVOICE_CUSTOMER_UPDATE"])
      : this.hasRights(["CREDIT_NOTE_CUSTOMER_UPDATE"]);
  }

  private fetchAppConfig(): Observable<AppConfig> {
    return this.appConfigService.appConfig.pipe(
      tap(appConfig => {
        this.isConform =
          appConfig?.conformityActivationDate !== null && appConfig?.conformityActivationDate !== undefined;
      })
    );
  }

  private computeDates(invoice: InvoiceCustomer): InvoiceCustomer {
    const customer = this.customerList.find(customer => customer.id === invoice.customerId);
    const quickPaymentType = this.getCommercialModalityWithAttribute(
      customer as ProfessionalCustomer,
      "quickPaymentDiscountType"
    );
    const quickPaymentPeriod = this.getCommercialModalityWithAttribute(
      customer as ProfessionalCustomer,
      "quickPaymentPeriod"
    );
    const maxPaymentType = this.getCommercialModalityWithAttribute(customer as ProfessionalCustomer, "maxPaymentType");
    const maxPaymentPeriod = this.getCommercialModalityWithAttribute(
      customer as ProfessionalCustomer,
      "maxPaymentPeriod"
    );

    invoice.date = new Date();
    invoice.maxPaymentDate = this.getPaymentDate(invoice.date, maxPaymentType, +maxPaymentPeriod)?.toDate();
    invoice.quickPaymentDate = this.getPaymentDate(invoice.date, quickPaymentType, +quickPaymentPeriod)?.toDate();

    return invoice;
  }

  private getPaymentDate(todayDate: Date, paymentType: string, paymentPeriod: number): dayjs.Dayjs {
    const invoiceDate: Date = todayDate ? todayDate : null;
    if (!invoiceDate) {
      return null;
    }
    const lastDay = new Date(invoiceDate.getFullYear(), invoiceDate.getMonth() + 1, 0);

    let date;

    switch (paymentType) {
      case PaymentPeriodType.INVOICE_DATE:
        date = DayjsUtil.addDays(invoiceDate, paymentPeriod);
        break;
      case PaymentPeriodType.END_OF_MONTH:
        date = DayjsUtil.addDays(lastDay, paymentPeriod);
        break;
      case PaymentPeriodType.PERIODIC:
        date = this.getDateFromPeriodicType(paymentPeriod, invoiceDate);
        break;
      case null:
        date = null;
        break;
      default:
        console.error(`Don't know how to handle payment period type : ${paymentType}`);
        break;
    }
    return DayjsUtil.dayjsOrNull(date, true);
  }

  private getDateFromPeriodicType(paymentPeriod: number, invoiceDate: Date): Date {
    if (invoiceDate.getDate() >= this.DAY_LIMIT) {
      const lastDay = new Date(invoiceDate.getFullYear(), invoiceDate.getMonth() + 1, 0);
      return lastDay.getDate() === invoiceDate.getDate()
        ? DayjsUtil.addDays(invoiceDate, paymentPeriod)
        : new Date(invoiceDate.getFullYear(), invoiceDate.getMonth() + 1, 0);
    }
    const dateToReturn = new Date(Number(invoiceDate));
    dateToReturn.setDate(paymentPeriod * (Math.trunc(invoiceDate.getDate() / paymentPeriod) + 1));
    return dateToReturn;
  }

  private getCommercialModalityWithAttribute(customer: ProfessionalCustomer, attribute: string): string {
    if (customer && customer.commercialModality && customer.commercialModality[attribute]) {
      return customer.commercialModality[attribute];
    }
    return null;
  }
}
