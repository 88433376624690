<ng-container>
  <div class="stock-entry-label-settings-popup-container" [formGroup]="popupForm" novalidate>
    <ui-popup title="{{ popupTitle | translate }}" (close)="closePopup()">
      <div content>
        <div class="body-message">{{ bodyMessage }}</div>

        <div class="slot-number-choice">
          <div class="index-input">
            <ui-input-text
              type="text"
              maskPattern="00"
              label="{{ 'stock-entry-label-list.settings-popup.first-label-index' | translate }}"
              formControlName="firstLabelIndex"
              mandatory="true"
              [status]="popupForm.get('firstLabelIndex') | formStatus"
              [errorMessage]="
                popupForm.get('firstLabelIndex')
                  | formErrorMessage
                    : { customPrefix: 'stock-entry-label-list.errors.', useCustomPrefixFor: ['outOfRange'] }
                  | translate
              "
              customClass="not-full"
            >
              <div tooltip class="tooltip">
                <span class="info-icon" #info>
                  <fa-icon [icon]="faInfoCircle"></fa-icon>
                </span>
                <span class="tooltip⁻container">
                  <ui-tooltip maxWidth="300px" maxHeight="45px" [element]="info">
                    <img src="../../../../assets/images/illustration etiquettes.png" />
                  </ui-tooltip>
                </span>
              </div>
            </ui-input-text>
          </div>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'stock-entry-label-list.settings-popup.validate' | translate }}"
                [primary]="true"
                type="validate"
                (click)="submitSettingsPopup()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
