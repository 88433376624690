<ng-container>
  <div class="main-container">
    <div class="order-brands-list">
      <div class="filters" *ngIf="filterer">
        <ui-dynamic-filters
          label="{{ 'filters.label' | translate }}"
          [filters]="filterer.getFilters()"
          [(filterValues)]="filterer.filterValues"
          (apply)="applyFilters()"
          applyLabel="{{ 'filters.apply' | translate }}"
          minLabel="{{ 'filters.min' | translate }}"
          maxLabel="{{ 'filters.max' | translate }}"
          deleteLabel="{{ 'filters.delete' | translate }}"
          placeholderPrefix="{{ 'filters.prefix' | translate }} "
        >
        </ui-dynamic-filters>
      </div>
      <div class="order-brands-table">
        <div [formGroup]="tableControl">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            class="material overflow"
            columnMode="flex"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [rowClass]="getRowClass"
            [limit]="15"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            [offset]="pageNumber"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            (page)="pageNumber = $event.offset"
          >
            <ngx-datatable-column
              name="{{ 'order-brands.datatable.columns.name' | translate }}"
              prop="name"
              cellClass="name"
              [flexGrow]="1"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'order-brands.datatable.columns.activated' | translate }}"
              prop="activated"
              headerClass="center-align"
              cellClass="center-align"
              [flexGrow]="0.6"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <ui-switch
                  customClass="no-margin"
                  (change)="checkboxOnChanges(row.id)"
                  [formControl]="tableControl.get('activated_' + row.id)"
                >
                </ui-switch>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</ng-container>
