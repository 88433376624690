import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { ThemeService } from "center-services";

@Component({
  selector: "app-technical-error-page",
  templateUrl: "./technical-error-page.component.html",
  styleUrls: ["./technical-error-page.component.scss"],
})
export class TechnicalErrorPageComponent implements OnInit {
  public imageLink: string = "";
  public faChevronLeft: IconDefinition = faChevronLeft;

  constructor(private location: Location, private themeService: ThemeService, private router: Router) {}

  ngOnInit(): void {
    const darkTheme = this.themeService.getValue() ? "" : "_dark";
    this.imageLink = `assets/images/illustration_500${darkTheme}.png`;
  }

  public backToLastPage(): void {
    this.location.back();
  }

  public backToHome(): void {
    this.router.navigateByUrl("");
  }

  public goToHelpSite(): void {
    window.open("https://monfugu.fr/");
  }
}
