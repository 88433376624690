<ng-container>
  <form class="customer-general" [formGroup]="customerForm" novalidate>
    <div class="grid-item">
      <div class="affiliate">
        <ui-info-field
          label="{{ 'customer.general-datas.customer-management.label' | translate }}"
          value="{{ 'customer.general-datas.customer-management.external.name' | translate }}"
        ></ui-info-field>
      </div>
    </div>
    <div class="title-type">
      <ui-radio-button
        label="{{ 'customer.general-datas.person-title' | translate }}"
        [options]="this.typeOptions"
        name="personTitle"
        direction="horizontal"
        formControlName="personTitle"
        customClass="no-margin"
      ></ui-radio-button>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'customer.general-datas.last-name' | translate }}"
        formControlName="lastName"
        [status]="customerForm.get('lastName') | formStatus"
        [errorMessage]="customerForm.get('lastName') | formErrorMessage | translate"
        [mandatory]="true"
      ></ui-input-text>
      <ui-input-text
        type="text"
        label="{{ 'customer.general-datas.first-name' | translate }}"
        formControlName="firstName"
        [status]="customerForm.get('firstName') | formStatus"
        [errorMessage]="customerForm.get('firstName') | formErrorMessage | translate"
        [mandatory]="true"
      ></ui-input-text>
      <div class="reference">
        <ui-info-field
          label="{{ 'customer.general-datas.customer-management.label' | translate }}"
          [value]="editedCustomer.reference"
        ></ui-info-field>
      </div>
      <div class="birth-date">
        <ui-date-picker
          label="{{ 'customer.general-datas.birth-date' | translate }}"
          [locale]="locale"
          [format]="dateFormat"
          formControlName="birthDate"
          [status]="customerForm.get('birthDate') | formStatus"
          [errorMessage]="customerForm.get('birthDate') | formErrorMessage | translate"
        >
        </ui-date-picker>
      </div>
    </div>

    <div class="grid-item">
      <ui-info-field
        label="{{ 'customer.general-datas.customer-type' | translate }}"
        value="{{ getCustomerTypeLabel() | translate }}"
      ></ui-info-field>
      <ui-input-text
        type="text"
        label="{{ 'customer.general-datas.email' | translate }}"
        formControlName="email"
        [status]="customerForm.get('email') | formStatus"
        [errorMessage]="customerForm.get('email') | formErrorMessage | translate"
        [mandatory]="mandatoryArray.has('email')"
      >
      </ui-input-text>
      <div class="double-col">
        <ui-input-text
          type="text"
          label="{{ 'customer.general-datas.phone-number' | translate }}"
          formControlName="phone"
          maskPattern="PN NN NN NN NN NN"
          [customPattern]="pattern"
          [specialCharacters]="[' ', '+']"
          [dropSpecialCharacters]="[' ']"
          [status]="customerForm.get('phone') | formStatus"
          [errorMessage]="customerForm.get('phone') | formErrorMessage | translate"
          [mandatory]="mandatoryArray.has('phone')"
        >
        </ui-input-text>
        <div class="mobile-phone">
          <ui-input-text
            type="text"
            label="{{ 'customer.general-datas.mobile-phone' | translate }}"
            formControlName="mobilePhone"
            maskPattern="PN NN NN NN NN NN"
            [customPattern]="pattern"
            [specialCharacters]="[' ', '+']"
            [dropSpecialCharacters]="[' ']"
            [status]="customerForm.get('mobilePhone') | formStatus"
            [errorMessage]="customerForm.get('mobilePhone') | formErrorMessage | translate"
            [mandatory]="mandatoryArray.has('mobilePhone')"
          >
          </ui-input-text>
        </div>
      </div>
      <ui-textarea
        label="{{ 'customer.general-datas.address-individual' | translate}}"
        formControlName="lines"
        [status]="customerForm.get('lines') | formStatus"
        [errorMessage]="customerForm.get('lines') | formErrorMessage | translate"
        [mandatory]="mandatoryArray.has('lines')"
      ></ui-textarea>
      <div class="multi-col">
        <div class="city-code">
          <ui-input-text
            type="text"
            label="{{ 'customer.general-datas.city-code' | translate }}"
            formControlName="cityCode"
            [status]="customerForm.get('cityCode') | formStatus"
            [errorMessage]="customerForm.get('cityCode') | formErrorMessage | translate"
            [mandatory]="mandatoryArray.has('lines')"
          ></ui-input-text>
        </div>
        <div class="city">
          <ui-input-text
            type="text"
            label="{{ 'customer.general-datas.city' | translate }}"
            formControlName="city"
            [status]="customerForm.get('city') | formStatus"
            [errorMessage]="customerForm.get('city') | formErrorMessage | translate"
            [mandatory]="mandatoryArray.has('lines')"
          ></ui-input-text>
        </div>
      </div>
      <ui-list
        label="{{ 'customer.general-datas.country' | translate}}"
        [options]="this.countryOptions"
        formControlName="country"
        [search]="true"
        [status]="customerForm.get('country') | formStatus"
        [errorMessage]="customerForm.get('country') | formErrorMessage | translate"
        [mandatory]="mandatoryArray.has('lines')"
      ></ui-list>
      <div class="stock-type">
        <ui-list
          label="{{ 'customer.general-datas.receipt-commercial-offers-label' | translate }}"
          [options]="this.receiptCommercialOfferOptions"
          formControlName="receiptCommercialOfferType"
          [multiple]="true"
        >
        </ui-list>
      </div>
    </div>

    <div class="grid-item">
      <div class="creation-store">
        <ui-info-field
          label="{{ 'customer.general-datas.creation-store' | translate }}"
          value="{{ getStoreNameLabel() }}"
        ></ui-info-field>
      </div>
      <div class="color">
        <ui-color-picker
          label="{{ 'customer.general-datas.color' | translate }}"
          format="hex"
          [formControl]="customerForm.get('color')"
        >
        </ui-color-picker>
      </div>
      <div class="comment">
        <ui-textarea label="{{ 'customer.general-datas.comment' | translate}}" formControlName="comment"></ui-textarea>
      </div>
      <ui-tag-handler
        label="{{ 'customer.general-datas.tag' | translate }}"
        [availableTags]="this.availableTags"
        [formControl]="customerForm.get('tags')"
      >
      </ui-tag-handler>
    </div>
  </form>
</ng-container>
