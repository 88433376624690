import { HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import { AsynchronousTask, AsynchronousTaskService } from "center-services";
import { Task, TaskStatusType, ToastMessageService } from "fugu-components";
import { FileSaverService } from "ngx-filesaver";

@Component({
  selector: "app-notification-handler",
  templateUrl: "./notification-handler.component.html",
  styleUrls: ["./notification-handler.component.scss"],
})
export class NotificationHandlerComponent implements OnInit {
  public tasks: Task[];
  public previousTasks: Task[];
  public taskNotificationTitle: string;
  public taskNotificationVisible: boolean = false;
  // eslint-disable-next-line no-magic-numbers
  protected REFRESH_DELAY: number = 1000;

  private refreshInterval: number;

  constructor(
    private taskService: AsynchronousTaskService,
    private translateService: TranslateService,
    private notificationHandlerService: NotificationHandlerService,
    private messageService: ToastMessageService,
    private fileSaverService: FileSaverService
  ) {}

  public ngOnInit(): void {
    this.fetchTasks();
    // if handler visibility is triggered by some user action
    this.notificationHandlerService.getHandlerVisibility().subscribe(visible => {
      if (visible) {
        this.taskNotificationVisible = true;
        this.fetchTasks();
      }
    });
  }

  public fetchTasks(): void {
    this.taskService.getAllCurrentTasks().subscribe((tasks: AsynchronousTask[]) => {
      if (tasks.length === 0) {
        return;
      }

      this.tasks = tasks
        .filter((task: AsynchronousTask) => task.type !== "import")
        .sort((a, b) => b.id - a.id)
        .map((task: AsynchronousTask) => {
          return new Task({
            id: task.id,
            label: this.getTaskLabel(task),
            progress: task.progress,
            status: task.status,
          });
        });

      const onGoing = this.tasks?.some(
        task => task.status === TaskStatusType.PENDING || task.status === TaskStatusType.RUNNING
      );
      this.taskNotificationTitle = onGoing
        ? this.translateService.instant("task-notification.title.on-going")
        : this.translateService.instant("task-notification.title.done");
      if (onGoing) {
        this.taskNotificationVisible = true;
        if (this.refreshInterval === null || this.refreshInterval === undefined) {
          this.refreshInterval = window.setInterval(() => {
            this.fetchTasks();
          }, this.REFRESH_DELAY);
        }
      } else if (!onGoing && this.refreshInterval) {
        clearInterval(this.refreshInterval);
        this.refreshInterval = undefined;
      }

      this.checkIfTasksHaveChanged();
    });
  }

  public checkIfTasksHaveChanged(): void {
    if (this.previousTasks) {
      this.tasks.forEach((task: Task) => {
        // check first if we have new tasks
        const taskFound = this.previousTasks.find((previousTask: Task) => task.id === previousTask.id);
        if (!taskFound) {
          this.sendToast(task);
          this.previousTasks.push(new Task(task));
        } else if (taskFound.status !== task.status) {
          this.sendToast(task);
          taskFound.status = task.status;
        }
      });
    } else {
      this.previousTasks = this.tasks.map((task: Task) => new Task(task));
    }
  }

  public sendToast(task: Task): void {
    switch (task.status) {
      case "DONE": {
        this.taskService.downloadTaskFile(+task.id).subscribe((response: HttpResponse<Blob>) => {
          const contentDisposition = response.headers.get("Content-Disposition");
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches !== null && matches[1]) {
            this.fileSaverService.save(response.body, matches[1].replace(/['"]/g, ""));
          }
        });
        break;
      }
      case "ERROR":
        this.messageService.generateMessage(
          "error",
          "task-notification.message.error-title",
          "task-notification.message.error-message"
        );
        break;
      default:
        break;
    }
  }

  public ngOnDestroy(): void {
    this.notificationHandlerService.removeHandler();
    clearInterval(this.refreshInterval);
  }

  public closeNotifications(): void {
    this.taskNotificationVisible = false;
  }

  private getTaskLabel(task: AsynchronousTask): string {
    const pdfTypes: string[] = [
      "purchase-order",
      "shipment-form",
      "invoice-customer",
      "stock-entry-label",
      "metal-account",
      "police-book-movement",
      "inventory",
    ];
    const xlsType: string[] = ["item", "store", "supplier", "brand", "user"];
    if (task.params) {
      switch (task.type) {
        case "receiveDeliveryForm":
          return this.translateService.instant("task-notification.label.receipt-validation");
        case "generatePdf":
          if (pdfTypes.indexOf(task.params.split(";")[0]) === -1) {
            break;
          }
          return this.translateService.instant(`exports.pdf.${task.params.split(";")[0]}`);
        case "exportXLS":
          if (xlsType.indexOf(task.params.split(";")[0]) === -1) {
            break;
          }
          return this.translateService.instant(`exports.excel.${task.params.split(";")[0]}`);
        default:
          break;
      }
    }
    return this.translateService.instant("task-notification.label.default");
  }
}
