import { Component, ViewChild } from "@angular/core";

@Component({
  selector: "app-metal-account-general",
  templateUrl: "./metal-account-general.component.html",
  styleUrls: ["./metal-account-general.component.scss"],
})
export class MetalAccountGeneralComponent {
  @ViewChild("tabHandler") tabHandler: any;
  @ViewChild("supplier") supplier: any;
  @ViewChild("customer") customer: any;

  onTabClick(tab: any): void {
    if (this.supplier) {
      this.supplier.savePaginationToSession();
    }
    if (this.customer) {
      this.customer.savePaginationToSession();
    }
    this.tabHandler?.changeTab(tab);
  }
}
