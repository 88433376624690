<div class="shipment-form-validation-popup">
  <ui-popup
    (close)="closeValidationPopup()"
    [title]="
      multiple
        ? ('shipment-form-validation-popup.title-plural' | translate)
        : ('shipment-form-validation-popup.title' | translate)
    "
  >
    <div content>
      <div class="info-message">
        <div class="igniter">
          {{
            multiple
              ? ("shipment-form-validation-popup.igniter-plural" | translate)
              : ("shipment-form-validation-popup.igniter" | translate)
          }}
        </div>
        <ul class="action-list">
          <li class="action">
            {{
              multiple
                ? ("shipment-form-validation-popup.status-plural" | translate)
                : ("shipment-form-validation-popup.status" | translate)
            }}
          </li>
          <li class="action">
            {{ "shipment-form-validation-popup.stock" | translate }}
          </li>
          <li class="action">
            {{ "shipment-form-validation-popup.police-book" | translate }}
          </li>
        </ul>
        <div class="print-shipment-form">
          <ui-checkbox
            customClass="no-margin"
            [formControl]="print"
            [labelPosition]="'right'"
            [label]="
              multiple
                ? ('shipment-form-validation-popup.print-label-plural' | translate)
                : ('shipment-form-validation-popup.print-label' | translate)
            "
          >
          </ui-checkbox>
        </div>
      </div>
      <div class="bold question">
        {{ "shipment-form-validation-popup.question" | translate }}
      </div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              [text]="'shipment-form-validation-popup.buttons.yes' | translate"
              [primary]="true"
              type="validate"
              (click)="validateShipmentForm()"
            ></ui-button>
          </div>
        </div>
        <div class="button-popup-cancel">
          <div class="button second-button">
            <ui-button
              [text]="'shipment-form-validation-popup.buttons.no' | translate"
              type="cancel"
              (click)="closeValidationPopup()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
