import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { Light, PurchaseOrderLine, Store } from "center-services";
import { CommonValidatorsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";

@Component({
  selector: "app-po-line-duplication-popup",
  templateUrl: "./po-line-duplication-popup.component.html",
  styleUrls: ["./po-line-duplication-popup.component.scss"],
  providers: [SubscriptionService],
})
export class PoLineDuplicationPopupComponent implements OnInit, OnChanges {
  @ViewChild("table") table: DatatableComponent;

  // add in parent
  @Input() storeList: Light[];
  @Input() sizeValueList: Option[];
  @Input() purchaseOrderLine: PurchaseOrderLine;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any[]> = new EventEmitter();

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;

  public popupForm: UntypedFormGroup;

  public canClosePopup: boolean = false;
  public selectedStores: number[] = [];

  public storeOptions: Option[] = [];
  public selectedSizeValueMap: Map<any, number> = new Map();

  public rows: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.loadRows();
    this.buildStoreOptions();
  }

  ngOnChanges(): void {
    this.prepareForm();
    this.refresh();
  }

  refresh(): void {
    // changes on store
    this.subscriptionService.subs.push(
      this.popupForm.get("stores").valueChanges.subscribe(val => {
        if (val !== null) {
          this.canClosePopup = false;
        }
      })
    );

    // changes on quantity for SizeValues ?
    if (this.sizeValueList !== undefined && this.sizeValueList.length > 0) {
      for (const sz of this.sizeValueList) {
        // at least one quantity value modified
        this.subscriptionService.subs.push(
          this.popupForm.get(`sizeValueQuantity_${sz.id}`).valueChanges.subscribe(val => {
            if (val !== "0") {
              this.canClosePopup = false;
            }
          })
        );
      }
    }
  }

  loadRows(): void {
    this.rows = [];

    if (this.sizeValueList && this.sizeValueList.length > 0) {
      this.sizeValueList.forEach((sz: Option) => {
        this.addRow(sz, 0);
      });
    }
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({});
    const digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);
    if (this.storeList.length > 0) {
      this.popupForm.addControl("stores", new UntypedFormControl(null));

      // data table for SizeValue and quantity
      if (this.sizeValueList !== undefined && this.sizeValueList.length > 0) {
        this.sizeValueList.forEach((sz: Option) => {
          this.popupForm.addControl(
            `sizeValueQuantity_${sz.id}`,
            new UntypedFormControl("0", [Validators.min(0), digitValidator])
          );
        });
      }
    }
  }

  addRow(sizeValue: Option, quantity: number): void {
    this.rows.push({
      id: sizeValue.id,
      value: sizeValue.label,
      quantity,
    });
  }

  getDynamicItemSubTitle(): string {
    if (!this.purchaseOrderLine) {
      return "";
    }
    return `${this.purchaseOrderLine.itemReference} - ${this.purchaseOrderLine.supplierRef} - ${this.purchaseOrderLine.itemName}`;
  }

  buildStoreOptions(): void {
    if (this.storeList !== undefined && this.storeList.length > 0) {
      this.storeList.forEach((store: Store) => {
        this.storeOptions.push(new Option(store.id, store.name));
      });
    }
  }

  submitDuplicationPopup(): void {
    // stop here if form is invalid
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    // 1) --- Save ---
    if (this.hasModification()) {
      // sizeValueList or not ?
      if (this.sizeValueList !== undefined && this.sizeValueList.length > 0) {
        for (const sz of this.sizeValueList) {
          // at least one quantity value modified
          if (
            this.popupForm.get(`sizeValueQuantity_${sz.id}`).value &&
            this.popupForm.get(`sizeValueQuantity_${sz.id}`).value !== "0"
          ) {
            this.selectedSizeValueMap.set(sz.id, parseFloat(this.popupForm.get(`sizeValueQuantity_${sz.id}`).value));
          }
        }
      }

      // store selected or not ?
      if (this.popupForm.value.stores) {
        this.selectedStores = this.popupForm.value.stores;
      } else {
        // no selected Store but sizeValue and quantity ? trigger defaultstore function
        if (this.selectedSizeValueMap !== undefined && this.selectedSizeValueMap.size > 0) {
          const byDeFaultStoreId = this.purchaseOrderLine.deliveryStoreId;
          this.selectedStores.push(byDeFaultStoreId);
          this.selectedStores = [...this.selectedStores];
        }
      }

      // emit the values with quantity of sizevalue or not
      this.selectedSizeValueMap !== undefined && this.selectedSizeValueMap.size > 0
        ? this.validate.emit([this.selectedStores, this.selectedSizeValueMap])
        : this.validate.emit([this.selectedStores]);
    } else {
      // 2) --- No changes so Close ---
      this.close.emit();
    }
  }

  closeDuplicationPopup(): void {
    // check and close if need it
    if (this.canClosePopup) {
      this.closeFunction(this.canClosePopup);
      return;
    }

    if (this.hasModification()) {
      this.generateUnsavedPopinError();
      this.canClosePopup = true;
    } else {
      this.close.emit();
    }
  }

  hasModification(): boolean {
    // --- Modifications ---
    // modification on store
    if (this.popupForm.controls.stores.value && this.popupForm.controls.stores.value.length > 0) {
      return true;
    }

    // modification for quantity (sizeValueList)
    if (this.sizeValueList !== undefined && this.sizeValueList.length > 0) {
      for (const sz of this.sizeValueList) {
        // at least one quantity value modified
        if (
          this.popupForm.get(`sizeValueQuantity_${sz.id}`).value &&
          this.popupForm.get(`sizeValueQuantity_${sz.id}`).value !== "0"
        ) {
          return true;
        }
      }
    }
    //  --- No modifications ---
    return false;
  }

  private generateUnsavedPopinError(): void {
    const title = this.translateService.instant("global.errors.unsaved-title");
    const content = this.translateService.instant("global.errors.unsaved-popin-content");
    this.messageService.info(content, { title });
  }

  private closeFunction(canClosePopup: boolean): void {
    if (canClosePopup) {
      this.close.emit();
    }
  }
}
