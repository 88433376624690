<ng-container>
  <form class="duplication-form" [formGroup]="popupForm" novalidate (keydown.enter)="$event.preventDefault()">
    <div class="duplicate-popup-container">
      <ui-popup title="{{ 'duplication-popup.title' | translate }}" (close)="closeDuplicationPopup()">
        <div content>
          <div class="duplicate-item">
            <span
              class="item-title"
              [innerHTML]="'duplication-popup.fields.duplicate-item' | translate: { item: getDynamicItemSubTitle() }"
            ></span>
          </div>
          <div class="store-list">
            <ui-list
              selectLabel="{{ 'duplication-popup.fields.store-list.search' | translate }}"
              label="{{ 'duplication-popup.fields.store-list.label' | translate }}"
              [options]="storeOptions"
              [search]="true"
              [multiple]="true"
              formControlName="stores"
            >
            </ui-list>
          </div>
          <span class="datatable-title" *ngIf="this.sizeValueList && this.sizeValueList.length > 0">
            {{ "duplication-popup.datatable.title" | translate }}</span
          >
          <div class="size-value-table" *ngIf="this.sizeValueList && this.sizeValueList.length > 0">
            <ngx-datatable
              #table
              [rows]="rows"
              rowHeight="40"
              width="200"
              class="material"
              footerHeight="0"
              columnMode="flex"
              [trackByProp]="'id'"
              headerHeight="auto"
              [limit]="100"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down'
              }"
              [sorts]="[{ prop: 'sizeValue', dir: 'desc' }]"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
            >
              <ngx-datatable-column
                name="{{ 'duplication-popup.datatable.columns.size-value' | translate }}"
                prop="value"
                [flexGrow]="1"
                [resizeable]="false"
                cellClass="modules"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="value">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'duplication-popup.datatable.columns.quantity' | translate }}"
                prop="quantity"
                [flexGrow]="1"
                cellClass="modules quantity"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-quantity="row.quantity">
                  <ui-input-text
                    class="input-fields"
                    customClass="datatable-row"
                    type="text"
                    [maskPattern]="decimalDigit"
                    [thousandSeparator]="' '"
                    [formControl]="popupForm.get('sizeValueQuantity_' + id)"
                    [status]="popupForm.get('sizeValueQuantity_' + id) | formStatus"
                    [errorMessage]="
                      popupForm.get('sizeValueQuantity_' + id)
                        | formErrorMessage
                          : { customPrefix: 'duplication-popup.datatable.errors.', useCustomPrefixFor: ['min'] }
                        | translate: { digit: HIGH_INTEGER }
                    "
                    sizeMode="small"
                  >
                  </ui-input-text>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
        <div footer>
          <div class="align-buttons">
            <div class="button-popup-validate">
              <div class="button">
                <ui-button
                  text="{{ 'duplication-popup.button' | translate }}"
                  [primary]="true"
                  type="validate"
                  (click)="submitDuplicationPopup()"
                ></ui-button>
              </div>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </form>
</ng-container>
