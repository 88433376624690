import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import { ComponentDirty, ErrorUtil } from "generic-pages";
import { Location } from "@angular/common";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import dayjs from "dayjs";
import { SessionPagination, SubscriptionService } from "fugu-common";
import { PurchaseModalitySelectionComponent } from "app/common/purchase-modality-selection/purchase-modality-selection.component";
import { PurchaseOrderLinesComponent } from "../purchase-order-lines/purchase-order-lines.component";
import {
  Stage,
  PurchaseOrder,
  CompanyConfig,
  PurchaseOrderService,
  CaraUserService,
  CompanyConfigService,
  PurchaseOrderStatus,
  CaraUser,
} from "center-services";

@Component({
  selector: "app-purchase-order-form",
  templateUrl: "./purchase-order-form.component.html",
  styleUrls: ["./purchase-order-form.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderFormComponent implements OnInit, ComponentDirty {
  @ViewChild("purchaseOrderHeader") purchaseOrderHeader: any;
  @ViewChild("purchaseOrderLines") purchaseOrderLines: any;
  @ViewChild("purchaseOrderSummary") purchaseOrderSummary: any;

  public title: string;
  public subTitle: string;
  public stages: Stage[] = [];
  public activeStage: Stage;
  public faChevronLeft: IconDefinition = faChevronLeft;
  public editedPurchaseOrder: PurchaseOrder;
  public updatedPurchaseOrder: PurchaseOrder;
  public unsavedPurchaseOrder: PurchaseOrder;
  public shouldClose: boolean = false;
  public printMessagePopupVisible: boolean = false;
  public locale: string;
  public dateFormat: string;
  public companyConfig: CompanyConfig;
  public hasError: boolean = false;
  public initObservables: Observable<any>[] = [];
  protected readonly ONE: number = 1;
  protected readonly TWO: number = 2;
  // eslint-disable-next-line no-magic-numbers
  protected readonly THREE: number = 3;
  private readonly backUrl: string = "/purchase-order-list";

  constructor(
    private purchaseOrderService: PurchaseOrderService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private messageService: MessageService,
    private userService: CaraUserService,
    private companyConfigService: CompanyConfigService,
    private subscriptionService: SubscriptionService
  ) {
    this.initStages();
    this.backUrl = this.router.getCurrentNavigation()?.extras?.state?.backUrl ?? "/purchase-order-list";
  }

  ngOnInit(): void {
    const purchaseOrderId = this.route.snapshot.params.id;
    this.title = purchaseOrderId ? "purchase-order.title.update" : "purchase-order.title.new";

    this.initObservables = [];
    this.initObservables.push(this.fetchConnectedUser());
    this.initObservables.push(this.fetchCompanyConfig());

    combineLatest(this.initObservables).subscribe(() => {
      if (purchaseOrderId) {
        this.purchaseOrderService.get(purchaseOrderId).subscribe(
          (purchaseOrder: PurchaseOrder) => {
            this.editedPurchaseOrder = purchaseOrder;
            this.updatedPurchaseOrder = new PurchaseOrder(this.editedPurchaseOrder);
            this.subTitle = purchaseOrder.orderRef;
          },
          () => {
            this.router.navigateByUrl("/purchase-order-list");
          }
        );
      } else {
        this.updatedPurchaseOrder = new PurchaseOrder({
          orderRef: null,
          comment: null,
          status: PurchaseOrderStatus.DRAFT,
          orderType: null,
          supplierId: null,
          currencyId: null,
          deliveryStoreId: null,
          deliveryAddressId: null,
        });
        this.updatedPurchaseOrder = this.setBillingProperties(this.updatedPurchaseOrder);
        this.editedPurchaseOrder = new PurchaseOrder(this.updatedPurchaseOrder);
      }

      this.translateService.onLangChange.subscribe(() => {
        this.initStages();
      });
    });
  }

  fetchConnectedUser(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  initStages(): void {
    this.stages = [];
    this.activeStage = new Stage(this.ONE, "1", this.translateService.instant("purchase-order.step-one"), true, false);
    this.stages.push(this.activeStage);
    this.stages.push(new Stage(this.TWO, "2", this.translateService.instant("purchase-order.step-two"), false, false));
    this.stages.push(
      new Stage(this.THREE, "3", this.translateService.instant("purchase-order.step-three"), false, false)
    );
  }

  submitPurchaseOrder(stage: Stage, validate: boolean): void {
    this.hasError = false;
    const fromStepTwoToOne = this.activeStage.id === this.TWO && stage?.id === this.ONE;

    if (this.purchaseOrderLines && !this.purchaseOrderLines.updatePurchaseOrder(fromStepTwoToOne)) {
      if (fromStepTwoToOne && this.shouldClose) {
        this.updatedPurchaseOrder = new PurchaseOrder(this.editedPurchaseOrder);
        this.shouldClose = false;
        this.activeSpecificStage(stage);
      } else if (fromStepTwoToOne && !this.shouldClose) {
        const title = this.translateService.instant("global.errors.unsaved-title");
        const res = this.translateService.instant("global.errors.unsaved-popin-content");
        this.messageService.info(res, { title });

        this.shouldClose = true;
      }
      return;
    }
    if (this.purchaseOrderHeader && !this.purchaseOrderHeader.updatePurchaseOrder()) {
      return;
    }
    // no purchaseOrderLine, no possibility to submit
    if (this.updatedPurchaseOrder.lines.length === 0 && stage.id === this.THREE) {
      return;
    }

    if (this.purchaseOrderSummary && !this.purchaseOrderSummary.updatePurchaseOrder(validate)) {
      return;
    }

    if (this.editedPurchaseOrder) {
      // if nothing has changed, no need to call the API to save the purchase order
      if (this.editedPurchaseOrder.equals(this.updatedPurchaseOrder)) {
        if (stage) {
          this.activeSpecificStage(stage);
        }
        if (this.activeStage.id === this.THREE && validate) {
          this.printMessagePopupVisible = true;
          return;
        }
        return;
      }
    }
    this.callApi(stage, validate);
  }

  callApi(stage: Stage, validate: boolean): void {
    if (!this.updatedPurchaseOrder) {
      return;
    }

    // when form is valid, create or update supplier
    const action = this.updatedPurchaseOrder && this.updatedPurchaseOrder.id ? "update" : "create";
    this.savePurchaseOrder(action, stage, validate);
  }

  savePurchaseOrder(action: string, stage: Stage, validate: boolean): void {
    this.subscriptionService.subs.push(
      this.purchaseOrderService[action].call(this.purchaseOrderService, this.updatedPurchaseOrder).subscribe(
        responsePurchaseOrder => {
          this.finishSave(stage, responsePurchaseOrder, validate);
        },
        error => {
          this.hasError = true;
          this.handleApiError(error);
        }
      )
    );
  }

  submitPurchaseOrderAndAction(): void {
    if (this.activeStage.id === this.ONE) {
      this.changeStage(this.stages.find(stage => stage.id === this.TWO));
    } else if (this.activeStage.id === this.TWO) {
      this.changeStage(this.stages.find(stage => stage.id === this.THREE));
    } else {
      this.submitPurchaseOrder(
        this.stages.find(stage => stage.id === this.THREE),
        true
      );
    }
  }

  getBackButtonTranslation(): string {
    if (this.activeStage.id !== this.ONE) {
      return "purchase-order.buttons.back-to-step-button";
    }
    if (this.backUrl && this.backUrl.startsWith("/purchase-order-detail")) {
      return "purchase-order.buttons.back-to-details-button";
    }
    return "purchase-order.buttons.back-to-list-button";
  }

  backToPrevious(): void {
    switch (this.activeStage.id) {
      case this.ONE:
        if (this.backUrl === "/purchase-order-list") {
          SessionPagination.clear(PurchaseModalitySelectionComponent.LIST_ID);
          SessionPagination.clear(PurchaseOrderLinesComponent.LIST_ID);
        }

        this.router.navigateByUrl(this.backUrl);
        break;
      case this.TWO:
        this.location.replaceState(`/purchase-order/update/${this.editedPurchaseOrder.id}`);
        this.changeStage(this.stages.find(stage => stage.id === this.ONE));
        break;
      case this.THREE:
        this.changeStage(this.stages.find(stage => stage.id === this.TWO));
        break;
      default:
        break;
    }
  }

  changeStage(stage: Stage): void {
    if (this.purchaseOrderLines) {
      this.purchaseOrderLines.savePaginationToSession();
    }
    if (this.activeStage.id === this.ONE && !this.stages.find(elem => elem.id === this.TWO).validated) {
      // Should open popup here instead of order-line component
      // open popup
    }

    // create or update PO
    this.submitPurchaseOrder(stage, false);
  }

  activeSpecificStage(stage: Stage): void {
    if (!this.route.snapshot.params.id) {
      // replace url with reponse and Location instead of navigateByUrl to prevent reloading component
      this.location.replaceState(`/purchase-order/update/${this.editedPurchaseOrder.id}`);
      this.title = "purchase-order.title.update";
      // set subtitle using response
      this.subTitle = this.editedPurchaseOrder.orderRef;
    }

    this.stages.find(elem => elem.id === this.activeStage.id).validated = true;
    this.stages.forEach(element => (element.active = false));
    stage.active = true;
    this.activeStage = stage;
  }

  finishSave(stage: Stage, responsePurchaseOrder: PurchaseOrder, validate: boolean): void {
    this.editedPurchaseOrder = responsePurchaseOrder;
    this.updatedPurchaseOrder = new PurchaseOrder(this.editedPurchaseOrder);

    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });

    if (this.activeStage.id === this.THREE && validate) {
      this.printMessagePopupVisible = true;
      return;
    }
    if (this.stages) {
      this.activeSpecificStage(stage);
    }
  }

  isDirty(): boolean {
    if (!this.updatedPurchaseOrder) {
      return false;
    }

    if (this.purchaseOrderLines) {
      this.purchaseOrderLines.applyModifications();
    }

    if (this.purchaseOrderSummary) {
      this.purchaseOrderSummary.applyModifications();
    }
    if (this.unsavedPurchaseOrder && !this.unsavedPurchaseOrder.equals(this.updatedPurchaseOrder)) {
      this.shouldClose = false;
    }

    if (this.editedPurchaseOrder && !this.editedPurchaseOrder.equals(this.updatedPurchaseOrder) && !this.shouldClose) {
      this.recordDatas();
      return true;
    } else if (!this.editedPurchaseOrder && !this.shouldClose) {
      this.recordDatas();
      return true;
    }

    this.unsavedPurchaseOrder = null;
    this.shouldClose = false;
    return false;
  }

  recordDatas(): void {
    this.unsavedPurchaseOrder = new PurchaseOrder(this.updatedPurchaseOrder);
    this.shouldClose = true;
  }

  getSaveBtnTranslation(): string {
    return this.activeStage.id === this.TWO
      ? "purchase-order.buttons.save-button"
      : "purchase-order.buttons.final-validate-button";
  }

  getSaveAndActionBtnTranslation(): string {
    return this.activeStage.id === this.TWO
      ? "purchase-order.buttons.toward-summary-button"
      : "purchase-order.buttons.select-items-button";
  }

  getSaveAndActionBtnClass(): string {
    return this.activeStage.id === this.TWO ? "validate-button" : null;
  }

  getStageClass(stage: Stage): string {
    if (
      stage.id === this.THREE &&
      this.updatedPurchaseOrder !== undefined &&
      this.updatedPurchaseOrder.lines.length === 0
    ) {
      return "stage not-clickable";
    }

    if (stage.validated) {
      return "stage validated";
    }
    if (
      this.activeStage.id === this.ONE &&
      stage.id === this.THREE &&
      !this.stages.find(elem => elem.id === this.TWO).validated
    ) {
      return "stage not-clickable";
    }
    return "stage";
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "purchase-order.supplier.reference",
    };

    const title = this.translateService.instant("message.title.form-errors");

    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  closePrintMessagePopup(redirect: any): void {
    this.printMessagePopupVisible = false;
    if (redirect) {
      this.updatedPurchaseOrder = new PurchaseOrder(this.editedPurchaseOrder);
      if (this.backUrl === "/purchase-order-list") {
        SessionPagination.clear(PurchaseModalitySelectionComponent.LIST_ID);
        SessionPagination.clear(PurchaseOrderLinesComponent.LIST_ID);
      }
      this.router.navigateByUrl(this.backUrl);
    }
  }

  validatePrintMessagePopup(): void {
    this.printMessagePopupVisible = false;

    this.purchaseOrderService.getPdf(this.editedPurchaseOrder).subscribe(
      response => {
        const documentPrefix = this.translateService.instant("purchase-order.title.pdf");
        const currentDate = dayjs(new Date()).format(this.dateFormat).split(" ")[0];
        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        // prepare file for download
        const documentName = `${documentPrefix}_CD${this.editedPurchaseOrder.id}_${currentDate}`;
        a.download = documentName.replace(/[^A-Z0-9]+/gi, "_").toLowerCase();
        a.href = url;
        a.click();
      },
      error => {
        this.translateService.get("global.server-errors.pdf", { message: error.message }).subscribe((res: string) => {
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        });
      },
      () => {
        this.updatedPurchaseOrder = new PurchaseOrder(this.editedPurchaseOrder);
        if (this.backUrl === "/purchase-order-list") {
          SessionPagination.clear(PurchaseModalitySelectionComponent.LIST_ID);
          SessionPagination.clear(PurchaseOrderLinesComponent.LIST_ID);
        }
        this.router.navigateByUrl(this.backUrl);
      }
    );
  }

  private fetchCompanyConfig(): Observable<CompanyConfig> {
    return this.companyConfigService.get().pipe(
      tap((companyConfig: CompanyConfig) => {
        this.companyConfig = companyConfig;
      })
    );
  }

  private setBillingProperties(purchaseOrder: PurchaseOrder): PurchaseOrder {
    purchaseOrder.billingCompanyId = this.companyConfig?.id;
    purchaseOrder.billingAddressId = this.companyConfig?.address?.id;
    return purchaseOrder;
  }
}
