import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService, Tag as TagComponent, ToastMessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import {
  Brand,
  Pagination,
  Light,
  BrandService,
  CaraUserService,
  LightService,
  Address,
  PaginatedList,
  Sort,
  Tag,
  TagService,
  AsynchronousTaskCreation,
  AsynchronousTaskService,
} from "center-services";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { NotificationHandlerService } from "app/service/notification-handler.service";

@Component({
  selector: "app-brand-list",
  templateUrl: "./brand-list.component.html",
  styleUrls: ["./brand-list.component.scss"],
  providers: [SubscriptionService],
})
export class BrandListComponent extends FilteredTableListComponent implements OnInit, OnDestroy, PaginableComponent {
  @Input() canUpdate: boolean = true;
  @Input() canAdd: boolean = true;

  @ViewChild("table") table: DatatableComponent;

  public LIST_ID: string = "app-brand-list.brands-table";
  public selectedBrand: Brand = null;
  public tableControl: UntypedFormGroup;

  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "asc",
    },
    {
      prop: "name",
      dir: "asc",
    },
  ];
  public brandList: Brand[] = [];
  public filterer: Filterer;
  public activeFilters: SearchFilter[] = [];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public activated: string[] = [];
  public allBrandSuppliers: Light[] = [];
  public addresses: Address[] = [];
  public cities: string[] = [];
  public allBrandTags: Tag[];
  private sessionPagination: SessionPagination;
  private initObservables: Observable<any>[] = [];

  constructor(
    private brandService: BrandService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private router: Router,
    protected userService: CaraUserService,
    private asynchronousTaskService: AsynchronousTaskService,
    private lightService: LightService,
    private tagService: TagService,
    private notificationHandlerService: NotificationHandlerService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.tableControl = new UntypedFormGroup({});
    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    if (this.filterer) {
      this.filterer.filterValues = [...filters];
    }
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.sessionPagination.loadFromSession(this.LIST_ID);

    this.initObservables.push(this.fetchSupplierList());
    this.initObservables.push(this.fetchAddressesList());
    this.initObservables.push(this.fetchAllBrandTags());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.computeSearchFilters();
        this.fetchBrandList();
      })
    );
  }

  public ngOnDestroy(): void {
    this.sessionPagination.saveToSession(this.LIST_ID);
  }

  addRow(brand: Brand, suppliers: string[]): void {
    let address = new Address();
    address = brand.address;

    let addressConcat = "";
    if (address) {
      if (address.lines) {
        addressConcat += `${address.lines.replace(/\n/g, "<br/>")}<br/>`;
      }
      if (address.cityCode) {
        addressConcat += `${address.cityCode} `;
      }
      if (address.city) {
        addressConcat += address.city;
      }
    }

    const tags = this.allBrandTags
      .filter(tag => brand.tagIds.includes(tag.id))
      .map((obj: Tag) => new TagComponent(obj.id, obj.name, obj.color));

    this.rows.push({
      id: brand.id,
      name: brand.name,
      reference: brand.reference,
      color: brand.color,
      address: addressConcat,
      phone: this.formatPhoneNumber(brand.phone),
      email: brand.email,
      suppliers,
      activated: !brand.archived,
      tags,
    });
  }

  fetchBrandList(): void {
    this.subscriptionService.subs.push(
      this.brandService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<Brand>) => {
          this.brandList = result.data;
          this.rows = [];
          this.pager = result.page;
          result.data.map((brand: Brand) => {
            // Archive management
            if (!this.tableControl.controls[`activated_${brand.id}`]) {
              this.tableControl.addControl(
                `activated_${brand.id}`,
                new UntypedFormControl({
                  value: !brand.archived,
                  disabled: !this.userService.canDo("BRAND_ARCHIVE"),
                })
              );
            } else {
              this.tableControl.controls[`activated_${brand.id}`].patchValue(!brand.archived);
            }
            const suppliers = this.allBrandSuppliers
              .filter(supplier => brand.linkedSupplierIds.includes(supplier.id))
              .map(supplier => supplier.name);
            this.addRow(brand, suppliers);
          });
          this.rows = [...this.rows];
          if (this.table) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("brands-list.errors.get-brands", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "activated":
        return "archived";
      default:
        return prop;
    }
  }

  formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber ? phoneNumber.match(/.{1,2}/g).join(" ") : null;
  }

  exportBrands(): void {
    if (!this.userService.canDo("BRAND_READ")) {
      return;
    }

    const asyncCreationTask = {
      type: "exportXLS",
      params: `brand;${this.activeFilters?.map(filter => filter.toQuery()).join(",")};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  importBrands(): void {
    if (!this.userService.canDo("BRAND_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/brands-list/import");
  }

  createBrand(): void {
    if (!this.userService.canDo("BRAND_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/brand/add");
  }

  updateBrand(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("BRAND_UPDATE")) {
        return;
      }
      const filteredList = this.brandList.filter(brand => brand.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find brand with id ${event.row.id}`);
        return;
      }
      this.router.navigateByUrl(`/brand/update/${event.row.id}`);
    }
  }

  fetchSupplierList(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (suppliers: Light[]) => {
          this.allBrandSuppliers = suppliers;
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("brands-list.errors.get-suppliers", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchAddressesList(): Observable<Address[]> {
    return this.brandService.addresses().pipe(
      tap(
        (addresses: Address[]) => {
          this.addresses = addresses;
          this.addresses.forEach(address => {
            if (address.city && this.cities.indexOf(address.city) === -1) {
              this.cities.push(address.city);
            }
          });
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("brands-list.errors.get-addresses", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  onArchiveCheckboxChanges(id: number): void {
    if (!this.userService.canDo("BRAND_ARCHIVE")) {
      return;
    }
    if (this.tableControl.controls[`activated_${id}`].value) {
      this.subscriptionService.subs.push(
        this.brandService.unarchive(id).subscribe(
          () => this.fetchBrandList(),
          () => {
            const title = this.translateService.instant("message.title.archive-errors");
            const content = this.translateService.instant("brands-list.errors.unarchive");
            this.messageService.warn(content, { title });
          }
        )
      );
    } else {
      this.subscriptionService.subs.push(
        this.brandService.archive(id).subscribe(
          () => this.fetchBrandList(),
          () => {
            const title = this.translateService.instant("message.title.archive-errors");
            const content = this.translateService.instant("brands-list.errors.archive");
            this.messageService.warn(content, { title });
          }
        )
      );
    }
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter("name", this.translateService.instant("brands-list.datatable.columns.name"), "string");
    this.filterer.addFilter(
      "reference",
      this.translateService.instant("brands-list.datatable.columns.reference"),
      "string"
    );
    this.filterer.addFilter("address.lines", this.translateService.instant("brands-list.filters.address"), "string");
    this.filterer.addListFilter(
      "address.city",
      this.translateService.instant("brands-list.filters.city"),
      this.cities.map(city => {
        return { value: city, displayValue: city };
      }),
      false,
      false,
      null,
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "address.cityCode",
      this.translateService.instant("brands-list.filters.city-code"),
      "string"
    );
    this.filterer.addFilter("phone", this.translateService.instant("brands-list.datatable.columns.phone"), "string");
    this.filterer.addFilter("email", this.translateService.instant("brands-list.datatable.columns.email"), "string");
    this.filterer.addListFilter(
      "linkedSuppliers.supplier.id",
      this.translateService.instant("brands-list.datatable.columns.suppliers"),
      this.allBrandSuppliers
        .map(supplier => {
          return { value: supplier.id.toString(), displayValue: supplier.name };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      false,
      false,
      null,
      null,
      true,
      false
    );

    this.filterer.addListFilter(
      "tags.id",
      this.translateService.instant("brands-list.datatable.columns.tags"),
      this.allBrandTags.map(tag => {
        return { value: tag.id.toString(), displayValue: tag.name };
      }),
      null,
      null,
      null,
      null,
      true
    );

    this.filterer.addBooleanFilter(
      "archived",
      this.translateService.instant("brands-list.datatable.columns.activated"),
      false,
      false,
      true,
      true
    );
  }

  applyFilters(): void {
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchBrandList();
      })
    );
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "asc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.fetchBrandList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchBrandList();
  }

  private fetchAllBrandTags(): Observable<Tag[]> {
    return this.tagService.getAllTagsOfCategoryByName("Brand").pipe(
      tap(
        (tags: Tag[]) => {
          this.allBrandTags = tags.filter((obj: Tag) => !obj.archived).sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          const res = this.translateService.instant("brands-list.errors.get-tags", { message: error.message });
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }
}
