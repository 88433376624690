<ng-container>
  <div class="status-popup-container" [formGroup]="popupForm" novalidate>
    <ui-popup title="{{ 'purchase-order.status-popup.title' | translate }}" (close)="closeStatusPopup()">
      <div content>
        <div class="status-question">{{ "shipment-form-list.status-popin.question" | translate }}</div>
        <div class="status-choice">
          <ui-radio-button
            label="{{ 'purchase-order.status-popup.options.label' | translate }}"
            [options]="this.statusOptions"
            name="status"
            formControlName="status"
            mandatory="true"
            [status]="popupForm.get('status') | formStatus"
            [errorMessage]="popupForm.get('status') | formErrorMessage | translate"
            mandatory="true"
          ></ui-radio-button>
        </div>
        <div
          class="warning-messages"
          *ngIf="
            (this.initialStatus === 'DRAFT' && this.popupForm.value.status === 3) ||
            (this.initialStatus === 'SENT' && this.popupForm.value.status === 2)
          "
        >
          <fa-icon class="status-icon" [icon]="warnIcon"></fa-icon>
          <div class="status-message">
            <p>
              <strong>{{ "shipment-form-list.status-popin.warning" | translate }}</strong>
            </p>
            <p>
              {{ "shipment-form-list.status-popin.message" | translate }} "{{
                getStatusTranslated(this.popupForm.value.status)
              }}":
            </p>
            <ul>
              <li *ngIf="this.initialStatus === 'DRAFT' && this.popupForm.value.status === 3">
                {{ "shipment-form-list.status-popin.1-1" | translate }}
              </li>
              <li *ngIf="this.initialStatus === 'DRAFT' && this.popupForm.value.status === 3">
                {{ "shipment-form-list.status-popin.1-2" | translate }}
              </li>
              <li *ngIf="this.initialStatus === 'SENT' && this.popupForm.value.status === 2">
                {{ "shipment-form-list.status-popin.2-1" | translate }}
              </li>
              <li *ngIf="this.initialStatus === 'SENT' && this.popupForm.value.status === 2">
                {{ "shipment-form-list.status-popin.2-2" | translate }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'purchase-order.status-popup.validate' | translate }}"
                [primary]="true"
                type="validate"
                (click)="submitStatusPopup()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
