<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'metal-account-general.title' | translate }}"></ui-title>
    </div>
    <div class="body">
      <ui-tab-handler #tabHandler (tabClick)="onTabClick($event)" [independent]="false">
        <ui-tab title="{{ 'metal-account-general.tab.supplier' | translate }}" id="supplier" active="true">
          <ng-template #tabTemplate>
            <app-metal-account-tab #supplier [contactType]="'SUPPLIER'"> </app-metal-account-tab>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'metal-account-general.tab.customer' | translate }}" id="customer">
          <ng-template #tabTemplate>
            <app-metal-account-tab #customer [contactType]="'CUSTOMER'"> </app-metal-account-tab>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>
    </div>
  </div>
</ng-container>
