import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { CaraUserService, ThemeService, CaraUser } from "center-services";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
  providers: [SubscriptionService],
})
export class PageNotFoundComponent implements OnInit {
  public imageLink: string = "";
  public faChevronLeft: IconDefinition = faChevronLeft;

  constructor(
    private userService: CaraUserService,
    private location: Location,
    private themeService: ThemeService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.setImageLink(this.userService.connectedUser.value.codeLanguage);
    } else {
      this.fetchConnectedUser();
    }
  }

  public backToLastPage(): void {
    this.location.back();
  }

  public backToHome(): void {
    this.router.navigateByUrl("");
  }

  public goToHelpSite(): void {
    window.open("https://monfugu.fr/");
  }

  private fetchConnectedUser(): void {
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe((connectedUser: CaraUser) => {
        if (connectedUser) {
          this.setImageLink(connectedUser.codeLanguage);
        }
      })
    );
  }

  private setImageLink(languageCode: string): void {
    const darkTheme = this.themeService.getValue() ? "" : "_dark";
    this.imageLink = `assets/images/illustration_404_${languageCode.toUpperCase()}${darkTheme}.png`;
  }
}
