import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import { ReceivingForm, DeliveryLineStatus, Action, CaraUserService } from "center-services";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";

@Component({
  selector: "app-pending-delivery-choice-popup",
  templateUrl: "./pending-delivery-choice-popup.component.html",
  styleUrls: ["./pending-delivery-choice-popup.component.scss"],
  providers: [SubscriptionService],
})
export class PendingDeliveryChoicePopupComponent implements OnInit {
  protected static readonly ZERO: number = 0;
  protected static readonly ONE: number = 1;
  protected static readonly TWO: number = 2;

  @Input() receivingForm: ReceivingForm;
  @Output() close: EventEmitter<any> = new EventEmitter();

  public popupForm: UntypedFormGroup;
  public submitted: boolean = false;
  public shouldClose: boolean = false;
  public choiceOptions: Option[];

  public initialChoice: number;
  public unsavedChoice: number;
  public selectedChoice: number;

  private actionTranslations: { [key: string]: string } = {};
  private statusOptionMap: Map<any, any> = new Map();

  constructor(
    private fb: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      Action,
      "pending-delivery-choice-popup.options",
      this.actionTranslations
    );

    this.statusOptionMap = new Map([
      [
        DeliveryLineStatus.PENDING,
        new Option(PendingDeliveryChoicePopupComponent.ZERO, this.actionTranslations.PREPARE),
      ],
      [
        DeliveryLineStatus.VALIDATED,
        new Option(PendingDeliveryChoicePopupComponent.ONE, this.actionTranslations.RECEIVE),
      ],
      [
        DeliveryLineStatus.RECEIVED,
        new Option(PendingDeliveryChoicePopupComponent.TWO, this.actionTranslations.UPDATE_RECEPTION),
      ],
    ]);
  }

  ngOnInit(): void {
    this.buildChoiceOptions();
    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildChoiceOptions();
      })
    );
    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      choice: [null, [Validators.required]],
    });
  }

  buildChoiceOptions(): void {
    let statuses: DeliveryLineStatus[] = this.receivingForm.getLinesUniqueStatus();
    statuses = statuses.filter(s => this.statusOptionMap.get(s));

    if (!this.userService.canDo("RECEIVING_FORM_REGISTERING_PREPARE")) {
      statuses = statuses.filter(s => s !== DeliveryLineStatus.PENDING);
    }

    this.choiceOptions = Array.from(statuses)
      .map((status: DeliveryLineStatus) => this.statusOptionMap.get(status))
      .sort((a, b) => a.id - b.id);
  }

  allowModification(): boolean {
    for (const line of this.receivingForm.lines) {
      if (line.getStatus() === DeliveryLineStatus.RECEIVED) {
        return true;
      }
    }
    return false;
  }

  submit(): void {
    this.submitted = true;

    if (this.popupForm.invalid) {
      return;
    }
    const option = this.choiceOptions.find(opt => opt.id === this.popupForm.controls.choice.value);
    const action = Object.keys(this.actionTranslations).find(key => this.actionTranslations[key] === option.label);

    this.router.navigate([`/receiving-form/update/${this.receivingForm.id}`], {
      queryParams: {
        action,
      },
    });
  }

  initializePopup(): void {
    this.popupForm.controls.choice.setValue(this.choiceOptions[0].id);

    this.selectedChoice = this.choiceOptions[0].id as number;
    this.initialChoice = this.choiceOptions[0].id as number;
  }

  closePopup(): void {
    this.selectedChoice = this.popupForm.controls.choice.value;

    if (this.unsavedChoice && this.unsavedChoice !== this.selectedChoice) {
      this.shouldClose = false;
    }

    if (this.initialChoice !== this.selectedChoice && !this.shouldClose) {
      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedChoice = this.selectedChoice;
      this.shouldClose = true;
    } else {
      this.close.emit();
    }
  }
}
