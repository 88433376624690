import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  ProfessionalCustomer,
  CustomerStoresRow,
  Pagination,
  CaraUserService,
  StoreStatusType,
  StoreCustomer,
} from "center-services";
import { Filterer, FilterValue, SearchFilter, SessionPagination, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";

@Component({
  selector: "app-customer-stores",
  templateUrl: "./customer-stores.component.html",
  styleUrls: ["./customer-stores.component.scss"],
  providers: [SubscriptionService],
})
export class CustomerStoresComponent extends FilteredTableListComponent implements OnInit {
  public static LIST_ID: string = "app-customer-stores.stores-table";

  @Input() customer: ProfessionalCustomer;
  @ViewChild("table") table: DatatableComponent;

  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public rows: CustomerStoresRow[] = [];
  public allRows: CustomerStoresRow[] = [];

  submitted: boolean = false;

  public sorts: any[] = [
    {
      prop: "name",
      dir: "asc",
    },
  ];

  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });

  private statusTranslations: { [key: string]: string } = {};
  private sessionPagination: SessionPagination;

  constructor(
    translateService: TranslateService,
    messageService: MessageService,
    userService: CaraUserService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      StoreStatusType,
      "customer-stores-list.status-options",
      this.statusTranslations
    );

    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
    this.applyFilters();
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.customer.affiliatedStores.forEach(storeCustomer => this.addRow(storeCustomer));
    this.allRows = this.rows;
    this.initFilters();
    if (this.filterer.filterValues.length > 0) {
      this.applyFilters();
    }
    if (this.table) {
      this.table.sorts = this.sorts;
    }
    this.sessionPagination.loadFromSession(CustomerStoresComponent.LIST_ID);
  }

  changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];
  }

  addRow(storeCustomer: StoreCustomer): void {
    const statusLabel = this.translateService.instant(
      `store.general-datas.status-options.${storeCustomer.status.toUpperCase()}`
    );

    this.rows.push(
      new CustomerStoresRow({
        statusLabel,
        id: storeCustomer.id,
        name: storeCustomer.name,
        status: storeCustomer.status,
        ref: storeCustomer.reference,
        city: storeCustomer.city,
        statusClass: storeCustomer.status ? this.getStatusClass(storeCustomer.status) : null,
      })
    );
  }

  newStore(): void {
    if (!this.userService.canDo("STORE_CREATE")) {
      return;
    }
    this.router.navigate(["/store/add"], {
      queryParams: { defaultCustomerId: this.customer.id, defaultPricingGroupId: this.customer.pricingGroupId },
    });
  }

  viewStore(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("STORE_UPDATE")) {
        return;
      }
      this.router.navigateByUrl(`/store/update/${event.row.id}`);
    }
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === CustomerStoresComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "ref",
      this.translateService.instant("customer-stores-list.datatable.columns.reference"),
      "string"
    );

    this.filterer.addFilter(
      "name",
      this.translateService.instant("customer-stores-list.datatable.columns.name"),
      "string"
    );

    this.filterer.addFilter(
      "city",
      this.translateService.instant("customer-stores-list.datatable.columns.city"),
      "string"
    );

    this.filterer.addListFilter(
      "status",
      this.translateService.instant("customer-stores-list.datatable.columns.status"),
      Object.keys(StoreStatusType).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );
  }

  applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);
    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        CustomerStoresComponent.LIST_ID
      ).subscribe()
    );
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }

  private getStatusClass(status: StoreStatusType): string {
    return `status-${status.toLowerCase()}`;
  }
}
