import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AbstractItem, Currency, CurrencyService, CategoryType, StandardItem } from "center-services";
import { CommonValidatorsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";

@Component({
  selector: "app-retail-item-sale-terms",
  templateUrl: "./retail-item-sale-terms.component.html",
  styleUrls: ["./retail-item-sale-terms.component.scss"],
  providers: [SubscriptionService],
})
export class RetailItemSaleTermsComponent implements OnChanges, AfterViewChecked {
  @Input() editedItem: AbstractItem;

  public readonly decimalDigitLow: string = `separator.${PrecisionUtil.LOW_DECIMAL}`;
  public readonly decimalDigitHigh: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public LOW_INTEGER: PrecisionUtil = PrecisionUtil.LOW_INTEGER;
  public saleTermsForm: UntypedFormGroup;
  public currencies: Currency[] = [];
  public currencyOptions: Option[] = [];
  public faInfoCircle: IconDefinition = faInfoCircle;
  private readonly MAX_PERCENTAGE: number = 100;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private currencyService: CurrencyService,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.editedItem) {
      if (changes.editedItem.firstChange) {
        if (this.editedItem.type === CategoryType.STANDARD) {
          this.saleTermsForm.addControl("tph", new UntypedFormControl(false, [Validators.required]));
        }
        this.fetchCurrencies();
      } else {
        this.loadEditedData();
      }
    }
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  prepareForm(): void {
    this.saleTermsForm = this.fb.group({
      reference: [null],
      sellPrice: [
        null,
        [Validators.required, CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER)],
      ],
      sellCurrency: [null, [Validators.required]],
      salesRate: [
        null,
        [
          CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.LOW_INTEGER),
          Validators.min(0),
          Validators.max(this.MAX_PERCENTAGE),
        ],
      ],
    });
  }

  fetchCurrencies(): void {
    this.subscriptionService.subs.push(
      this.currencyService.getAll().subscribe(
        (currencies: Currency[]) => {
          this.currencies = currencies;
          this.currencyOptions = currencies
            .filter((obj: Currency) => !obj.archived)
            .map((obj: Currency) => new Option(obj.id, obj.symbol));

          this.loadEditedData();
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("currencies-list.errors.get-entities");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  loadEditedData(): void {
    this.saleTermsForm.controls.reference.setValue(this.editedItem.accountingSellRef);
    this.saleTermsForm.controls.sellPrice.setValue(this.editedItem.sellPrice);
    this.saleTermsForm.controls.salesRate.setValue(this.editedItem.salesRate);
    if (this.editedItem?.type === CategoryType.STANDARD) {
      this.saleTermsForm.controls.tph.setValue((this.editedItem as StandardItem).tphActivated);
    }
    if (this.editedItem.sellCurrencyId) {
      this.saleTermsForm.controls.sellCurrency.setValue(this.editedItem.sellCurrencyId);
    }

    if (!this.editedItem.canSold) {
      this.saleTermsForm.controls.reference.disable();
      if (this.saleTermsForm.controls.sellPrice.value === null) {
        this.saleTermsForm.controls.sellPrice.patchValue(0);
      }
      this.saleTermsForm.controls.sellPrice.disable();
      this.saleTermsForm.controls.salesRate.disable();
      this.saleTermsForm.controls.sellCurrency.disable();

      if (this.editedItem.type === CategoryType.STANDARD) {
        this.saleTermsForm.controls.tph.disable();
      }
    }
  }

  applyModifications(): void {
    this.editedItem.accountingSellRef = this.saleTermsForm.value.reference;
    this.editedItem.sellPrice = this.saleTermsForm.value.sellPrice;
    this.editedItem.salesRate = this.saleTermsForm.value.salesRate;
    if (this.editedItem.type === CategoryType.STANDARD) {
      (this.editedItem as StandardItem).tphActivated = this.saleTermsForm.value.tph;
    }
    this.editedItem.sellCurrencyId = this.saleTermsForm.value.sellCurrency;
  }

  updateItem(): boolean {
    if (this.saleTermsForm.invalid) {
      this.saleTermsForm.markAllAsTouched();
      return false;
    }
    this.applyModifications();
    return true;
  }
}
