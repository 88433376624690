import { Component, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { CashRegister } from "center-services";
import { MessageService } from "fugu-components";

@Component({
  selector: "app-cash-registers-popup",
  templateUrl: "./cash-registers-popup.component.html",
  styleUrls: ["./cash-registers-popup.component.scss"],
})
export class CashRegistersPopupComponent implements OnChanges {
  @Input() cashRegister: CashRegister;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public unsavedCashRegister: CashRegister;
  public initialCashRegister: CashRegister;
  public editedCashRegister: CashRegister;
  public popupForm: UntypedFormGroup;

  public shouldClose: boolean = false;

  public popupTitle: string;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder
  ) {
    this.prepareForm();
  }

  ngOnChanges(): void {
    this.refresh();
  }

  refresh(): void {
    if (!this.cashRegister) {
      this.createCashRegister();
      this.popupTitle = this.translateService.instant("store.cash-register-popup.title.create");
    } else {
      this.editedCashRegister = new CashRegister(this.cashRegister);
      this.popupTitle = this.translateService.instant("store.cash-register-popup.title.update");
    }
    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      reference: [null, [Validators.required]],
    });
  }

  createCashRegister(): void {
    this.editedCashRegister = new CashRegister({
      reference: null,
      uniqueReference: null,
      archived: false,
    });
  }

  initializePopup(): void {
    this.popupForm.setValue({
      reference: this.editedCashRegister.reference,
    });
    this.initialCashRegister = new CashRegister(this.editedCashRegister);
  }

  applyModifications(): void {
    this.editedCashRegister.reference = this.popupForm.value.reference;
  }

  submitCashRegister(): void {
    this.applyModifications();

    // stop here if form is invalid
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }
    this.validate.emit(this.editedCashRegister);
  }

  closePopup(): void {
    this.applyModifications();
    if (this.unsavedCashRegister && !this.unsavedCashRegister.equals(this.editedCashRegister)) {
      this.shouldClose = false;
    }

    if (!this.initialCashRegister.equals(this.editedCashRegister) && !this.shouldClose) {
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedCashRegister = new CashRegister(this.editedCashRegister);
    } else {
      this.unsavedCashRegister = null;
      this.shouldClose = false;
      this.close.emit();
    }
  }
}
