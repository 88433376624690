<div class="update-receive-popup">
  <ui-popup (close)="closePopup()" title="{{ 'new-delivery-form-update-receive-popup.title' | translate }}">
    <div content>
      <div class="added-items" *ngIf="addedItems?.length > 0">
        <div class="item-title">
          <div class="icon-title">
            <fa-icon class="added-icon" [icon]="faCirclePlus"></fa-icon>
          </div>
          <div class="subtitle">{{ "new-delivery-form-update-receive-popup.added-items.title" | translate }} :</div>
        </div>
        <div class="text">
          <div class="icon">
            <fa-icon [icon]="faTags"></fa-icon>
          </div>
          <div class="tag-message">
            <div class="first-message">
              {{ "new-delivery-form-update-receive-popup.added-items.first-message" | translate }}
            </div>
            <div class="second-message">
              {{ "new-delivery-form-update-receive-popup.added-items.second-message" | translate }}
            </div>
          </div>
        </div>
        <ul class="item-list">
          <li class="item-info" *ngFor="let item of addedItems">
            {{ item.diff }} {{ item.uomName }} : {{ item.supplierRef ? item.supplierRef + "-" : "" }}
            {{ item.itemReference }} - {{ item.itemName }}
            <span *ngIf="item.sizeValue">
              - {{ "new-delivery-form-update-receive-popup.metrics" | translate: { sizeValue: item.sizeValue } }}</span
            >
          </li>
        </ul>
      </div>

      <div class="unreceived-items" *ngIf="unreceivedItems?.concat(unreceivedSNItems)?.length > 0">
        <div class="item-title">
          <div class="icon-title">
            <fa-icon class="unreceived-icon" [icon]="faCircleMinus"></fa-icon>
          </div>
          <div class="subtitle">{{ "new-delivery-form-update-receive-popup.unreceived-items.title" | translate }}:</div>
        </div>
        <div class="text">
          <div class="icon">
            <fa-icon [icon]="faHandHoldingBox"></fa-icon>
          </div>
          <div class="hold-message">
            <div class="first-message">
              {{ "new-delivery-form-update-receive-popup.unreceived-items.first-message" | translate }}
            </div>
            <div class="second-message">
              {{ "new-delivery-form-update-receive-popup.unreceived-items.second-message" | translate }}
            </div>
          </div>
        </div>

        <ul class="item-list" *ngIf="unreceivedItems?.length > 0">
          <li class="item-info" *ngFor="let item of unreceivedItems">
            {{ item.diff }} {{ item.uomName }} : {{ item.supplierRef ? item.supplierRef + "-" : "" }}
            {{ item.itemReference }} - {{ item.itemName }}
            <span *ngIf="item.sizeValue">
              - {{ "new-delivery-form-update-receive-popup.metrics" | translate: { sizeValue: item.sizeValue } }}</span
            >
          </li>
        </ul>

        <div class="sn-info" *ngIf="unreceivedSNItems?.length > 0">
          <div class="icon-title">
            <fa-icon class="info-icon" [icon]="faInfoCircle"></fa-icon>
          </div>
          <div class="info-message">
            {{ "new-delivery-form-update-receive-popup.unreceived-items.sn-info-message" | translate }}
          </div>
        </div>
        <div *ngIf="unreceivedSNItems?.length > 0 && form" [formGroup]="form">
          <ul class="item-list">
            <li class="item-info" *ngFor="let item of unreceivedSNItems">
              {{ item.diff }} {{ item.uomName }} : {{ item.supplierRef ? item.supplierRef + "-" : "" }}
              {{ item.itemReference }} - {{ item.itemName }}
              <span *ngIf="item.sizeValue">
                -
                {{ "new-delivery-form-update-receive-popup.metrics" | translate: { sizeValue: item.sizeValue } }}</span
              >
              <div class="stock-removal">
                <div class="stock-removal-message">
                  {{ "new-delivery-form-update-receive-popup.unreceived-items.stock-removal-message" | translate }}
                  <span>*</span>
                </div>
                <div class="stock-removal-list">
                  <ui-list
                    [errorMessage]="
                      form.get(item.id.toString())
                        | formErrorMessage
                          : {
                              customPrefix: 'new-delivery-form-update-receive-popup.unreceived-items.errors.',
                              useCustomPrefixFor: ['length']
                            }
                        | translate: { diff: item.diff }
                    "
                    [options]="snOptions[item.id]"
                    [search]="true"
                    [multiple]="true"
                    [mandatory]="true"
                    [formControl]="form.get(item.id.toString())"
                    [status]="form.get(item.id.toString()) | formStatus"
                    selectLabel="{{ 'new-delivery-form-update-receive-popup.unreceived-items.search' | translate }}"
                  >
                  </ui-list>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="modified-items" *ngIf="modifiedItems?.length > 0">
        <div class="item-title">
          <div class="icon-title">
            <fa-icon class="modified-icon" [icon]="faArrowsRotate"></fa-icon>
          </div>
          <div class="subtitle">{{ "new-delivery-form-update-receive-popup.modified-items.title" | translate }} :</div>
        </div>

        <div class="text">
          <div class="icon">
            <fa-icon [icon]="faTags"></fa-icon>
          </div>
          <div class="hold-message">
            <div class="first-message">
              {{ "new-delivery-form-update-receive-popup.modified-items.first-message" | translate }}
            </div>
            <div class="second-message">
              {{ "new-delivery-form-update-receive-popup.modified-items.second-message" | translate }}
            </div>
          </div>
        </div>
        <ul class="item-list">
          <li class="item-info" *ngFor="let item of modifiedItems">
            {{ item.diff }} {{ item.uomName }} : {{ item.supplierRef ? item.supplierRef + "-" : "" }}
            {{ item.itemReference }} -
            {{ item.itemName }}
            <span *ngIf="item.sizeValue">
              - {{ "new-delivery-form-update-receive-popup.metrics" | translate: { sizeValue: item.sizeValue } }}</span
            >
          </li>
        </ul>
      </div>

      <div class="modification-info">
        <div class="icon-title">
          <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
        </div>
        <div class="message">
          <div class="info-title">{{ "new-delivery-form-update-receive-popup.info-title" | translate }}</div>
          <div class="info-message">{{ "new-delivery-form-update-receive-popup.info-message" | translate }}</div>
        </div>
      </div>
    </div>
    <div footer>
      <div class="update-button">
        <div class="button">
          <ui-button
            text="{{ 'new-delivery-form-update-receive-popup.button' | translate }}"
            type="validate"
            [primary]="true"
            (click)="submitPopup()"
          ></ui-button>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
