export class PropsToFilter {
  static transform: Map<string, string> = new Map([
    ["date", "documentDate"],
    ["totalQuantity", "totalQuantityToInvoiced"],
    ["deliveryType", "type"],
  ]);

  static propsToFilter(props: string): string {
    const filter = this.transform.get(props);
    return filter ? filter : props;
  }
}
