<ng-container>
  <form class="finance-form" [formGroup]="financeForm" novalidate>
    <div class="first-container">
      <ui-container>
        <div class="revenue-disclosure-title">{{ "revenue-disclosure-param.title" | translate }}</div>

        <div class="revenue-grid-container">
          <ng-container class="grid-item">
            <ui-radio-button
              [options]="this.booleanOptions"
              name="activated"
              direction="horizontal"
              [formControlName]="'activated'"
            ></ui-radio-button>
          </ng-container>

          <ng-container *ngIf="!this.financeForm.get('activated').value">
            <div class="grid-item margin-top-10">
              <ui-radio-button
                label="{{ 'revenue-disclosure-param.yearly' | translate }}"
                [options]="this.booleanOptions"
                name="yearly"
                direction="horizontal"
                formControlName="yearly"
              ></ui-radio-button>
            </div>
            <div class="grid-item margin-top-10">
              <ui-radio-button
                label="{{ 'revenue-disclosure-param.monthly' | translate }}"
                [options]="this.booleanOptions"
                name="monthly"
                direction="horizontal"
                formControlName="monthly"
              ></ui-radio-button>
            </div>
            <div class="grid-item fake-field"></div>

            <div class="grid-item" style="padding-top: 7px">
              <ui-radio-button
                label="{{ 'revenue-disclosure-param.as' | translate }}"
                [options]="this.booleanOptions"
                name="withAS"
                direction="horizontal"
                formControlName="withAS"
              ></ui-radio-button>
            </div>
            <div class="grid-item margin-top-10">
              <div class="valuation-label">{{ "revenue-disclosure-param.valuation" | translate }}</div>
              <div class="valuation">
                <div class="without-tax">
                  <ui-checkbox type="checkbox" formControlName="withoutTax"></ui-checkbox>
                  <div class="label-text">{{ "revenue-disclosure-param.without-tax" | translate }}</div>
                </div>
                <div class="with-tax">
                  <ui-checkbox type="checkbox" formControlName="withTax"></ui-checkbox>
                  <div class="label-text">{{ "revenue-disclosure-param.with-tax" | translate }}</div>
                </div>
              </div>
            </div>

            <div class="grid-item fake-field"></div>

            <div class="grid-item">
              <ui-input-text
                type="text"
                [maskPattern]="decimalDigitLow"
                label="{{ 'revenue-disclosure-param.adjustment-percentage' | translate }}"
                formControlName="adjustmentPercentage"
                [status]="financeForm.get('adjustmentPercentage') | formStatus"
                [errorMessage]="
                  financeForm.get('adjustmentPercentage') | formErrorMessage | translate: { digit: LOW_INTEGER }
                "
                suffix=" %"
                [thousandSeparator]="' '"
              >
              </ui-input-text>
            </div>
            <div class="grid-item">
              <ui-list
                label="{{ 'revenue-disclosure-param.recipient' | translate }}"
                [options]="this.recipientOptions"
                [search]="true"
                formControlName="recipient"
              >
              </ui-list>
            </div>
          </ng-container>
        </div>
      </ui-container>
    </div>
    <ui-container>
      <div class="accounting-grid-container">
        <div class="grid-item">
          <ui-input-text type="text" label="{{ 'accounting-info.code' | translate }}" formControlName="code">
          </ui-input-text>
        </div>
        <div class="grid-item">
          <ui-input-text
            type="text"
            [maskPattern]="decimalDigit"
            label="{{ 'accounting-info.deposit-threshold' | translate: { unit: this.getCurrencySuffix() } }}"
            formControlName="depositThreshold"
            [status]="financeForm.get('depositThreshold') | formStatus"
            [errorMessage]="
              financeForm.get('depositThreshold')
                | formErrorMessage: { customPrefix: 'accounting-info.errors.', useCustomPrefixFor: ['min', 'integer'] }
                | translate: { digit: HIGH_INTEGER }
            "
            [suffix]="this.getCurrencySuffix()"
            [thousandSeparator]="' '"
          >
          </ui-input-text>
        </div>
        <div class="grid-item">
          <ui-input-text
            type="text"
            maskPattern="00000000"
            label="{{ 'accounting-info.deposit-interval' | translate: { unit: 'accounting-info.day' | translate } }}"
            formControlName="depositInterval"
            [status]="financeForm.get('depositInterval') | formStatus"
            [errorMessage]="
              financeForm.get('depositInterval')
                | formErrorMessage: { customPrefix: 'accounting-info.errors.', useCustomPrefixFor: ['min', 'integer'] }
                | translate
            "
            suffix="{{ 'accounting-info.day' | translate }}"
            [thousandSeparator]="' '"
          >
          </ui-input-text>

          <ui-input-text
            type="money"
            label="{{ 'accounting-info.bank-distance' | translate }}"
            formControlName="bankDistance"
            [status]="financeForm.get('bankDistance') | formStatus"
            [errorMessage]="
              financeForm.get('bankDistance')
                | formErrorMessage: { customPrefix: 'accounting-info.errors.', useCustomPrefixFor: ['min'] }
                | translate
            "
            suffix=" km"
            [thousandSeparator]="' '"
          >
          </ui-input-text>
        </div>
        <div class="grid-item">
          <ui-input-text type="text" label="{{ 'accounting-info.bank-name' | translate }}" formControlName="bankName">
          </ui-input-text>
        </div>
        <div class="grid-item">
          <ui-input-text
            type="text"
            label="{{ 'accounting-info.bank-account' | translate }}"
            formControlName="accountNumber"
          >
          </ui-input-text>
        </div>
        <div class="grid-item">
          <ui-textarea label="{{ 'accounting-info.address' | translate }}" formControlName="lines" height="169px">
          </ui-textarea>
        </div>
        <div class="grid-item">
          <div class="multi-col">
            <div class="city-code">
              <ui-input-text
                type="text"
                label="{{ 'accounting-info.city-code' | translate }}"
                formControlName="cityCode"
              >
              </ui-input-text>
            </div>
            <div class="city">
              <ui-input-text type="text" label="{{ 'accounting-info.city' | translate }}" formControlName="city">
              </ui-input-text>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <ui-list
            label="{{ 'accounting-info.country' | translate }}"
            [options]="this.countryOptions"
            formControlName="country"
          ></ui-list>
        </div>
      </div>
    </ui-container>
  </form>
</ng-container>
