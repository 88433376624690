import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ItemUniverse, ItemUniverseService, CaraUserService } from "center-services";
import { MessageService } from "fugu-components";
import { ErrorUtil, BaseListPopupComponent } from "generic-pages";

@Component({
  selector: "app-item-universes-list",
  templateUrl: "./item-universes-list.component.html",
  styleUrls: ["./item-universes-list.component.scss"],
})
export class ItemUniversesListComponent extends BaseListPopupComponent<ItemUniverse> implements OnInit {
  constructor(
    itemUniverseService: ItemUniverseService,
    translateService: TranslateService,
    messageService: MessageService,
    private fb: UntypedFormBuilder,
    private userService: CaraUserService
  ) {
    super(itemUniverseService, translateService, messageService);
  }

  ngOnInit(): void {
    this.sorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "name",
        dir: "asc",
      },
    ];
    this.prepareForm();
    super.ngOnInit();
  }

  onNewEntityClick(): any {
    this.selectedEntity = new ItemUniverse({
      name: null,
      archived: false,
    });
    this.popupTitle = `${this.getTranslationPrefix()}.popup.title-new-item-universe`;
    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      name: [null, [Validators.required]],
    });
  }

  onTableActivate(event: any): any {
    if (event.type === "click") {
      if (!this.userService.canDo("ITEM_UNIVERSE_UPDATE")) {
        return;
      }
      const filteredList = this.entityList.filter(itemUni => itemUni.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find itemUniverse with id ${event.row.id}`);
        return;
      }
      this.selectedEntity = this.cloneEntity(filteredList[0]);
      this.popupTitle = `${this.getTranslationPrefix()}.popup.title-update-item-universe`;
      this.initializePopup();
    }
  }

  initializePopup(): void {
    super.initializePopup();
    this.popupForm.controls.name.setValue(this.selectedEntity.name);
  }

  applyModifications(): void {
    this.selectedEntity.name = this.popupForm.value.name;
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: `${this.getTranslationPrefix()}.datatable.columns.name`,
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);

    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  protected cloneEntity(entity: ItemUniverse): ItemUniverse {
    return new ItemUniverse(entity);
  }

  protected addRow(itemUniverse: ItemUniverse): any {
    this.rows.push({
      id: itemUniverse.id,
      name: itemUniverse.name,
      activated: !itemUniverse.archived,
    });
    this.rows = [...this.rows];
  }

  protected canArchive(): boolean {
    return this.userService.canDo("ITEM_UNIVERSE_ARCHIVE");
  }

  protected getTranslationPrefix(): string {
    return "item-universes-list";
  }

  protected preparePopupForm(): any {
    this.popupForm = this.fb.group({
      name: [null, [Validators.required]],
    });
    this.popupForm.controls.name.setValue(this.selectedEntity.name);
  }
}
