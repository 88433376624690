<div class="popup-container">
  <ui-popup title="{{ 'disable-store-warning-popup.title' | translate }}" (close)="closePopup()">
    <div content>
      <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
      <div class="first-block">
        <strong>{{ "disable-store-warning-popup.warning-label" | translate }}</strong>
        <div
          class="first-row"
          *ngIf="this.storeName"
          [innerHTML]="'disable-store-warning-popup.label-first-row-store' | translate: { storeName: this.storeName }"
        ></div>
        <div
          class="first-row"
          *ngIf="!this.storeName"
          [innerHTML]="'disable-store-warning-popup.label-first-row-user' | translate"
        ></div>
      </div>
      <p>
        <strong>{{ "disable-store-warning-popup.label-second-row" | translate }}</strong>
      </p>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-add">
          <ui-button
            text="{{ 'disable-store-warning-popup.validate' | translate }}"
            [primary]="true"
            type="save"
            (click)="submitPopup()"
          ></ui-button>
        </div>
        <div class="button-popup-cancel">
          <ui-button
            text="{{ 'disable-store-warning-popup.cancel' | translate }}"
            [primary]="false"
            type="cancel"
            (click)="closePopup()"
          ></ui-button>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
