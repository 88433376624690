import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  MetalAccount,
  MetalAccountMovement,
  UserService,
  MetalAccountMovementService,
  LightService,
  SupplierService,
  CustomerService,
  MovementType,
  Light,
  User,
  ContactType,
} from "center-services";
import { CommonValidatorsUtil, DayjsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";

@Component({
  selector: "app-metal-account-popup",
  templateUrl: "./metal-account-popup.component.html",
  styleUrls: ["./metal-account-popup.component.scss"],
  providers: [SubscriptionService],
})
export class MetalAccountPopupComponent implements OnInit, AfterViewChecked {
  @Input() contactId: number;
  @Input() metalAccountId: number;
  @Input() contactMetalAccountList: MetalAccount[];
  @Input() contactType: ContactType;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;

  public metalAccountMovement: MetalAccountMovement;
  public initialMetalAccountMovement: MetalAccountMovement;
  public unsavedMetalAccountMovement: MetalAccountMovement;
  public initialType: number;
  public unsavedType: number;
  public popupForm: UntypedFormGroup;
  public validateBtnDisabled: boolean = false;

  public popupTitle: string;
  public shouldClose: boolean = false;

  public locale: string;
  public dateFormat: string;

  public typeOptions: Option[] = [];
  public metalAccountOptions: Option[] = [];

  public options: Option[] = [];
  public recipientErrorMessage: string = null;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: UserService<User>,
    private metalAccountMovementService: MetalAccountMovementService,
    private lightService: LightService,
    private supplierService: SupplierService,
    private customerService: CustomerService,
    private changeDetector: ChangeDetectorRef,
    private subscriptionService: SubscriptionService
  ) {}

  public ngOnInit(): void {
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );

    this.buildTypeOptions();
    this.buildMetalAccountOptions();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildTypeOptions();
      })
    );

    this.createMovement();
    this.popupTitle = this.translateService.instant("metal-account-movement.title");
    this.initializePopup();
  }

  public ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  public internalSelected(type: number): boolean {
    return Object.values(MovementType)[type] === "INTERNAL";
  }

  public submitMovement(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }
    this.validateBtnDisabled = true;
    this.applyModifications();

    this.subscriptionService.subs.push(
      this.metalAccountMovementService.create(this.metalAccountMovement).subscribe(
        () => {
          if (this.internalSelected(this.popupForm.value.type)) {
            this.createRecipientMove();
            return;
          }
          this.validateSuccess();
        },
        () => {
          this.validateBtnDisabled = false;
          const res = this.translateService.instant("metal-account-movement.errors.create");
          const title = this.translateService.instant("message.title.api-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  public canClosePopup(): void {
    this.applyModifications();
    const unsavedTypeChange = this.unsavedType !== this.popupForm.value.type;
    const initialTypeChange = this.initialType !== this.popupForm.value.type;

    if (
      this.unsavedMetalAccountMovement &&
      (!this.unsavedMetalAccountMovement.equals(this.metalAccountMovement) || unsavedTypeChange)
    ) {
      this.shouldClose = false;
    }

    if (
      (!this.initialMetalAccountMovement.equals(this.metalAccountMovement) || initialTypeChange) &&
      !this.shouldClose
    ) {
      const res = this.translateService.instant("global.errors.unsaved-popin-content");
      const title = this.translateService.instant("global.errors.unsaved-title");
      this.messageService.info(res, { title });
      this.unsavedMetalAccountMovement = new MetalAccountMovement(this.metalAccountMovement);
      this.unsavedType = this.popupForm.value.type;
      this.shouldClose = true;
    } else {
      this.closePopup();
    }
  }

  public closePopup(): void {
    this.shouldClose = false;
    this.unsavedMetalAccountMovement = null;
    this.unsavedType = null;
    this.close.emit();
  }

  public handleControlChange(type: number, metalAccountId: number): void {
    if (this.internalSelected(type) && metalAccountId) {
      const metalId = this.findMetalId(metalAccountId);
      this.fetchContext(metalId);
      this.popupForm.addControl("recipient", new UntypedFormControl(null));
      this.popupForm.controls.recipient.setValidators([Validators.required]);
    } else {
      this.options = [];
      this.popupForm.removeControl("recipient");
    }
  }

  private findMetalId(metalAccountId: number): number {
    return this.contactMetalAccountList.find(metalAccount => metalAccount.id === metalAccountId).metalId;
  }

  private buildTypeOptions(): void {
    this.typeOptions = Object.keys(MovementType)
      .filter(key => key !== MovementType.TRANSIT)
      .map(
        (key, index) =>
          new Option(index, this.translateService.instant(`metal-account-movement.fields.type-options.${key}`))
      );
  }

  private buildMetalAccountOptions(): void {
    this.metalAccountOptions = this.contactMetalAccountList
      .sort((a, b) => a.metalName.localeCompare(b.metalName))
      .map((obj: MetalAccount) => new Option(obj.id, obj.metalName));
  }

  private createMovement(): void {
    const event = new Date(Date.now());
    this.metalAccountMovement = new MetalAccountMovement({
      quantity: null,
      date: event,
      comment: null,
      orderNumber: null,
      documentNumber: null,
      metalAccountId: this.metalAccountId,
    });
  }

  private fetchSuppliers(metalId: number): void {
    this.subscriptionService.subs.push(
      this.lightService.getSuppliers(metalId).subscribe(
        lightSuppliers => {
          this.options = lightSuppliers
            .filter((supplier: Light) => supplier.id !== this.contactId)
            .filter((supplier: Light) => !supplier.archived)
            .map((obj: Light) => new Option(obj.id, obj.name));
        },
        () => {
          const res = this.translateService.instant("metal-account-movement.errors.get-suppliers");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private fetchCustomers(metalId: number): void {
    this.subscriptionService.subs.push(
      this.lightService.getCustomers(metalId).subscribe(
        lightCustomers => {
          this.options = lightCustomers
            .filter((customer: Light) => customer.id !== this.contactId)
            .filter((customer: Light) => !customer.archived)
            .map((obj: Light) => new Option(obj.id, obj.name));
        },
        () => {
          const res = this.translateService.instant("metal-account-movement.errors.get-customers");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private fetchContext(metalId: number): void {
    switch (this.contactType) {
      case ContactType.SUPPLIER:
        this.fetchSuppliers(metalId);
        break;
      case ContactType.CUSTOMER:
        this.fetchCustomers(metalId);
        break;
      default:
        console.error("Unknown contact type");
        break;
    }
  }

  private initializePopup(): void {
    this.preparePopupForm();
    this.metalAccountMovement.date = this.popupForm.value.date.toDate();
    this.initialMetalAccountMovement = new MetalAccountMovement(this.metalAccountMovement);
    this.initialType = this.popupForm.value.type;
  }

  private preparePopupForm(): void {
    this.popupForm = this.fb.group({
      quantity: [
        null,
        [
          Validators.required,
          CommonValidatorsUtil.positiveNumberValidator(),
          CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER),
        ],
      ],
      date: [DayjsUtil.dayjsOrNull(this.metalAccountMovement.date, true), [Validators.required]],
      comment: [null],
      orderNumber: [null],
      documentNumber: [null],
      metalAccountId: [this.metalAccountId, [Validators.required]],
      type: [0],
    });
    // listen to movement type change
    this.subscriptionService.subs.push(
      this.popupForm.controls.type.valueChanges.subscribe(type => {
        this.handleControlChange(type, this.popupForm.value.metalAccountId);
      })
    );

    // listen to metal account change
    this.subscriptionService.subs.push(
      this.popupForm.controls.metalAccountId.valueChanges.subscribe(metalAccountId => {
        this.handleControlChange(this.popupForm.value.type, metalAccountId);
      })
    );
  }

  private createRecipientMove(): void {
    const metalId = this.findMetalId(this.popupForm.value.metalAccountId);

    this.createContextMove(metalId);
  }

  private createSupplierMove(metalId: number): void {
    this.subscriptionService.subs.push(
      this.supplierService.get(this.popupForm.value.recipient).subscribe(
        supplier => {
          const recipientMetalAccountId = supplier.metalAccounts.find(ma => ma.metalId === metalId).id;

          const recipientMove = new MetalAccountMovement({
            quantity: parseFloat(this.popupForm.value.quantity),
            date: this.popupForm.value.date.toDate(),
            comment: this.popupForm.value.comment,
            orderNumber: this.popupForm.value.orderNumber,
            documentNumber: this.popupForm.value.documentNumber,
            metalAccountId: recipientMetalAccountId,
          });

          this.subscriptionService.subs.push(
            this.metalAccountMovementService.create(recipientMove).subscribe(
              () => {
                this.validateSuccess();
              },
              () => {
                const res = this.translateService.instant("metal-account-movement.errors.internal-supplier", {
                  supplier: supplier.name,
                });
                const title = this.translateService.instant("message.title.api-errors");
                this.messageService.warn(res, { title });
                // close popup
                this.validate.emit();
              }
            )
          );
        },
        () => {
          const res = this.translateService.instant("metal-account-movement.errors.get-supplier");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private createCustomerMove(metalId: number): void {
    this.subscriptionService.subs.push(
      this.customerService.get(this.popupForm.value.recipient).subscribe(
        customer => {
          const recipientMetalAccountId = customer.metalAccounts.find(ma => ma.metalId === metalId).id;

          const recipientMove = new MetalAccountMovement({
            quantity: parseFloat(this.popupForm.value.quantity),
            date: this.popupForm.value.date.toDate(),
            comment: this.popupForm.value.comment,
            orderNumber: this.popupForm.value.orderNumber,
            documentNumber: this.popupForm.value.documentNumber,
            metalAccountId: recipientMetalAccountId,
          });

          this.subscriptionService.subs.push(
            this.metalAccountMovementService.create(recipientMove).subscribe(
              () => {
                this.validateSuccess();
              },
              () => {
                const res = this.translateService.instant("metal-account-movement.errors.internal-customer", {
                  customer: customer.name,
                });
                const title = this.translateService.instant("message.title.api-errors");
                this.messageService.warn(res, { title });
                // close popup
                this.validate.emit();
              }
            )
          );
        },
        () => {
          const res = this.translateService.instant("metal-account-movement.errors.get-customer");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private createContextMove(metalId: number): void {
    switch (this.contactType) {
      case ContactType.SUPPLIER:
        this.createSupplierMove(metalId);
        break;
      case ContactType.CUSTOMER:
        this.createCustomerMove(metalId);
        break;
      default:
        console.error("Unknown contact type");
        break;
    }
  }

  private validateSuccess(): void {
    this.validate.emit();
    const res = this.translateService.instant("message.content.save-success");
    const title = this.translateService.instant("message.title.save-success");
    this.messageService.success(res, { title });
  }

  private applyModifications(): void {
    if (this.popupForm.value.quantity) {
      switch (Object.values(MovementType)[this.popupForm.value.type]) {
        case "IN":
          this.metalAccountMovement.quantity = parseFloat(this.popupForm.value.quantity);
          break;
        case "OUT":
        case "INTERNAL":
          this.metalAccountMovement.quantity = parseFloat(this.popupForm.value.quantity) * -1;
          break;
        default:
          break;
      }
    }

    this.metalAccountMovement.comment = this.popupForm.value.comment;
    this.metalAccountMovement.orderNumber = this.popupForm.value.orderNumber;
    this.metalAccountMovement.documentNumber = this.popupForm.value.documentNumber;
    this.metalAccountMovement.metalAccountId = this.popupForm.value.metalAccountId;

    // handle date
    const event = this.popupForm.value.date.toDate();
    this.metalAccountMovement.date = event;
  }
}
