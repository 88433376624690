<ng-container>
  <div class="invoice-customer-selector-popup-container">
    <ui-popup [title]="titleLabel" (close)="closePopup()">
      <div content [formGroup]="form" novalidate>
        <ui-list
          #customer
          label="{{ 'invoice-customer-selector-popup.customer' | translate }}"
          [options]="this.customerOptions"
          [mandatory]="true"
          formControlName="customer"
          [status]="form.get('customer') | formStatus"
          [errorMessage]="form.get('customer') | formErrorMessage | translate"
          [search]="!isCustomerLocked"
          [disabled]="isCustomerLocked"
          [selectLabel]="defaultCustomerLabel"
          (ngModelChange)="selectCustomer()"
          [customClass]="'less-bottom-space'"
        >
        </ui-list>

        <div class="concerned-invoices-label">
          <ui-info-field
            label="{{ 'invoice-customer-selector-popup.invoice-selection-label' | translate }}"
            customClass="mandatory"
          >
          </ui-info-field>
          <span>*</span>
        </div>

        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            [locale]="locale"
            dateFormat="{{ dateFormat }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>

        <div class="invoice-customer-selector-table" [formGroup]="tableControl">
          <ngx-datatable
            #table
            [rows]="rows"
            trackByProp="id"
            class="material"
            columnMode="standard"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [externalSorting]="true"
            [externalPaging]="true"
            [count]="pager.totalElements"
            [offset]="pager.number"
            [limit]="pager.size"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              [sortable]="false"
              [draggable]="false"
              [resizeable]="false"
              [canAutoResize]="false"
              cellClass="center-align"
              [width]="55"
            >
              <ng-template ngx-datatable-header-template>
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="onHeaderCheckboxChange()"
                    formControlName="headerCheckbox"
                  >
                  </ui-checkbox>
                </div>
              </ng-template>

              <ng-template ngx-datatable-cell-template let-id="row.id" let-row="row">
                <div class="adjust green-zone" (click)="$event.stopPropagation()">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="onRowCheckboxChange()"
                    [formControlName]="getRowControlName(id)"
                  >
                  </ui-checkbox>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'invoice-customer-selector-popup.datatable.columns.reference' | translate }}"
              prop="reference"
              cellClass="reference"
              [resizeable]="false"
              [width]="180"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'invoice-customer-selector-popup.datatable.columns.customer-ref' | translate }}"
              prop="customerRef"
              cellClass="customerRef"
              [sortable]="false"
              [resizeable]="false"
              [width]="150"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'invoice-customer-selector-popup.datatable.columns.stores' | translate }}"
              cellClass="stores top-align ellipsis-counter"
              [resizeable]="false"
              prop="storeIds"
              [width]="210"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-stores="row.storeNames">
                <p class="ellipsis" [title]="stores.join(', ')">{{ stores.join(", ") }}</p>
                <div *ngIf="stores.length >= 2">
                  <div #storeCounter>
                    <ui-counter [number]="stores.length"></ui-counter>
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="storeCounter">
                    <span style="font-weight: normal !important">{{ stores.join(", ") }}</span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'invoice-customer-selector-popup.datatable.columns.date' | translate }}"
              prop="date"
              cellClass="date"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-date="row.date">
                <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'invoice-customer-selector-popup.datatable.columns.total-gross-price' | translate }}"
              prop="totalGrossPrice"
              cellClass="total-gross-price"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                <div class="adjust">
                  {{ value | formatCurrency: this.getCurrency(row).codeISO:"symbol":"0.2-2":locale }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count">
                  <span
                    [innerHTML]="
                      selectedInvoices.length === 0
                        ? ('global.datatable.no-items' | translate)
                        : (selectedInvoices.length
                          | i18nPlural
                            : {
                                '=1': 'global.datatable.n-selected-items',
                                other: 'global.datatable.n-selected-items_plural'
                              }
                          | translate: { selectedElements: selectedInvoices.length })
                    "
                  >
                  </span>
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="changePage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
      <div footer>
        <div class="button-popup-to-invoice">
          <ui-button
            #validateButton
            [text]="submitButtonLabel"
            [primary]="true"
            (click)="submit()"
            [btnIcon]="faArrowCircleRight"
            [disabled]="true"
          ></ui-button>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
