<ng-container>
  <div class="popup-container">
    <ui-popup title="{{ 'item.nomenclatures.item-selector.title' | translate }}" (close)="closePopup()">
      <div content>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            [locale]="locale"
            dateFormat="{{ dateFormat }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
            mandatoryErrorMessage="{{ 'invoice-supplier-list.errors.filters' | translate }}"
          >
          </ui-dynamic-filters>
        </div>

        <div class="items-table" [formGroup]="tableControl">
          <ngx-datatable
            #table
            [rows]="itemList"
            [rowClass]="getRowClass"
            [trackByProp]="'id'"
            class="material"
            columnMode="flex"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            [externalSorting]="true"
            [externalPaging]="true"
            [count]="pager.totalElements"
            [offset]="pager.number"
            [limit]="pager.size"
          >
            <ngx-datatable-column
              [width]="60"
              [sortable]="false"
              [draggable]="false"
              [resizeable]="false"
              [canAutoResize]="false"
              [frozenLeft]="true"
              cellClass="center-align frozen-style"
            >
              <ng-template ngx-datatable-header-template>
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="onHeaderCheckboxChange()"
                    [formControlName]="'headerCheckbox'"
                  >
                  </ui-checkbox>
                </div>
              </ng-template>

              <ng-template ngx-datatable-cell-template let-id="row.id">
                <div class="adjust green-zone" (click)="$event.stopPropagation()">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="onRowCheckboxChange()"
                    [formControlName]="getRowControlName(id)"
                  >
                  </ui-checkbox>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item.nomenclatures.item-selector.datatable.column.item-category-name' | translate }}"
              prop="categoryName"
              [flexGrow]="1"
              [resizeable]="false"
              cellClass="item-category-cell"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div *ngIf="isItemNotCompatible(row)">
                  <div #info><fa-icon [icon]="faInfoCircle" class="info-icon"></fa-icon></div>

                  <ui-tooltip maxWidth="300px" [element]="info">
                    <span style="font-weight: normal !important">
                      {{ "item.nomenclatures.item-selector.already-in-nom-warning" | translate }}
                    </span>
                  </ui-tooltip>
                </div>

                <span>{{ row.categoryName }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item.nomenclatures.item-selector.datatable.column.item-reference' | translate }}"
              prop="reference"
              [flexGrow]="1"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item.nomenclatures.item-selector.datatable.column.item-name' | translate }}"
              prop="name"
              [flexGrow]="1.5"
              [resizeable]="false"
              cellClass="ellipsis-counter"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item.nomenclatures.item-selector.datatable.column.item-suppliers-reference' | translate }}"
              [flexGrow]="1"
              [resizeable]="false"
              [sortable]="false"
              cellClass="ellipsis-counter"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-pms="row.purchaseModalities">
                <ng-container
                  *ngTemplateOutlet="listColumn; context: { row: row, list: getItemSupplierRefs(pms) }"
                ></ng-container>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item.nomenclatures.item-selector.datatable.column.item-alloys' | translate }}"
              [flexGrow]="1"
              [resizeable]="false"
              [sortable]="false"
              cellClass="ellipsis-counter"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-compositions="row.composition">
                <ng-container
                  *ngTemplateOutlet="listColumn; context: { row: row, list: getItemAlloys(compositions) }"
                ></ng-container>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item.nomenclatures.item-selector.datatable.column.item-brand-name' | translate }}"
              prop="brandName"
              [flexGrow]="1"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item.nomenclatures.item-selector.datatable.column.item-collection-name' | translate }}"
              prop="brandCollectionName"
              [flexGrow]="1"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count">
                  <span
                    [innerHTML]="
                      itemOutput.selectedItems.length === 0
                        ? ('global.datatable.no-items' | translate)
                        : (itemOutput.selectedItems.length
                          | i18nPlural
                            : {
                                '=1': 'global.datatable.n-selected-items',
                                other: 'global.datatable.n-selected-items_plural'
                              }
                          | translate: { selectedElements: itemOutput.selectedItems.length })
                    "
                  >
                  </span>
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="changePage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>

        <div class="info-message">
          <fa-icon class="info-icon" [icon]="faInfoCircle"></fa-icon>
          <span>{{ "item.nomenclatures.item-selector.items-message" | translate }}</span>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                [text]="confirmButtonLabel"
                type="validate"
                [primary]="true"
                [disabled]="isThereNoItemSelected()"
                (click)="validatePopup()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>

<ng-template ngx-datatable-cell-template #listColumn let-row="row" let-list="list">
  <span class="ellipsis" title="{{ list.join(', ') }}">{{ list.join(", ") }}</span>
  <div *ngIf="list.length >= 2" title="{{ row.counter }}">
    <div #referencesCounter>
      <ui-counter [number]="list.length"></ui-counter>
    </div>
    <ui-tooltip maxWidth="300px" [element]="referencesCounter">
      <span style="font-weight: normal !important">{{ list.join(", ") }}</span>
    </ui-tooltip>
  </div>
</ng-template>
