import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { IconDefinition, faCircleArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  Inventory,
  Store,
  CaraUserService,
  StoreService,
  InventoryService,
  InventoryStatus,
  CaraUser,
} from "center-services";
import { CommonValidatorsUtil, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-inventory-initiator-popup",
  templateUrl: "./inventory-initiator-popup.component.html",
  styleUrls: ["./inventory-initiator-popup.component.scss"],
  providers: [SubscriptionService],
})
export class InventoryInitiatorPopupComponent implements OnInit, AfterViewChecked {
  @Output() close: EventEmitter<any> = new EventEmitter();

  public initialInventory: Inventory;
  public unsavedInventory: Inventory;
  public editedInventory: Inventory;
  public popupForm: UntypedFormGroup;

  public shouldClose: boolean = false;

  public popupTitle: string;
  public mainStore: Store;
  public dateFormat: string;
  public locale: string;
  faCircleArrowRight: IconDefinition = faCircleArrowRight;
  private initObservables: Observable<any>[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: CaraUserService,
    private storeService: StoreService,
    private inventoryService: InventoryService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.prepareForm();
    this.popupTitle = this.translateService.instant("inventory-initiator-popup.title");

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUser());
    }
    this.initObservables.push(this.fetchMainStore());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.editedInventory = new Inventory({
          status: InventoryStatus.IN_PROGRESS,
        });
        this.initialInventory = new Inventory(this.editedInventory);
      })
    );
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  fetchConnectedUser(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("inventory-initiator-popup.errors.get-main-store", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      name: [null, [Validators.required]],
      beginDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
    });

    this.popupForm.setValidators([
      CommonValidatorsUtil.dateValidator(this.popupForm.controls.beginDate, this.popupForm.controls.endDate),
    ]);
  }

  applyModifications(): void {
    this.editedInventory.name = this.popupForm.value.name ? this.popupForm.value.name : null;
    this.editedInventory.beginDate = this.popupForm.value.beginDate ? this.popupForm.value.beginDate.toDate() : null;
    this.editedInventory.endDate = this.popupForm.value.endDate ? this.popupForm.value.endDate.toDate() : null;
  }

  submitInventoryInitiatorPopup(): void {
    this.popupForm.markAllAsTouched();
    this.applyModifications();
    if (this.popupForm.invalid) {
      return;
    }

    // create and redirect
    this.subscriptionService.subs.push(
      this.inventoryService.create(this.editedInventory).subscribe(
        response => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });

          this.router.navigateByUrl(`/inventory/update/${response.id}`);
        },
        error => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("inventory-initiator-popup.errors.create", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  closeInventoryInitiatorPopup(): void {
    this.applyModifications();

    if (this.unsavedInventory && !this.unsavedInventory.equals(this.editedInventory)) {
      this.shouldClose = false;
    }

    if (!this.initialInventory.equals(this.editedInventory) && !this.shouldClose) {
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedInventory = new Inventory(this.editedInventory);
    } else {
      this.close.emit();
      this.unsavedInventory = null;
      this.shouldClose = false;
    }
  }
}
