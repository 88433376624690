import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import { ComponentDirty } from "generic-pages";

@Injectable({
  providedIn: "root",
})
export class UnsavedChangesGuard implements CanDeactivate<ComponentDirty> {
  constructor(private messageService: MessageService, private translateService: TranslateService) {}

  canDeactivate(component: ComponentDirty): boolean {
    if (component.isDirty()) {
      const title = this.translateService.instant("global.errors.unsaved-title");
      const res = this.translateService.instant("global.errors.unsaved-page-content");
      this.messageService.info(res, { title });
      return false;
    }
    return true;
  }
}
