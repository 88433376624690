import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: "[min]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }],
})
export class MinValidatorDirective implements Validator {
  @Input("min") min: number;

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value < this.min) {
      return { min: true };
    } else {
      return null;
    }
  }
}
