import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { IconDefinition, faArrowRight, faPen, faPlus, faPrint } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import {
  PoliceBookMovement,
  Alloy,
  Store,
  Pagination,
  CaraUserService,
  PoliceBookMovementService,
  LightService,
  StoreService,
  CaraUser,
  PaginatedList,
  StockType,
  Sort,
  Light,
  AuthService,
  AsynchronousTaskService,
  AsynchronousTaskCreation,
} from "center-services";
import {
  Filterer,
  FilterValue,
  Option,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService, ToastMessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { combineLatest, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import hash from "string-hash";

@Component({
  selector: "app-police-book-movements-list",
  templateUrl: "./police-book-movements-list.component.html",
  styleUrls: ["./police-book-movements-list.component.scss"],
  providers: [SubscriptionService],
})
export class PoliceBookMovementsListComponent
  extends FilteredTableListComponent
  implements OnInit, PaginableComponent, AfterViewInit {
  @ViewChild("movementFilters") movementFilters: any;
  @ViewChild("table") table: any;

  public LIST_ID: string = "app-police-book-movements-list.police-book-table";

  public policeBookMovementList: PoliceBookMovement[] = [];
  public alloys: Alloy[] = [];
  public menuActions: MenuAction[] = [];
  public policeBookMovementId: number;
  public storeList: Store[] = [];
  public mainStore: Store;
  public storeOptions: Option[] = [];
  public popupForm: UntypedFormGroup;
  public popupVisible: boolean = false;
  public submitted: boolean = false;
  public popupTitle: string;
  public shouldClose: boolean = false;
  public initialPoliceBookMovement: PoliceBookMovement;
  public unsavedPoliceBookMovement: PoliceBookMovement;
  public selectedPoliceBookMovement: PoliceBookMovement;

  public inLabel: string = "";
  public outLabel: string = "";

  public isConnectedToMainStore: boolean = false;
  public contextStore: Store = null;
  faPlus: IconDefinition = faPlus;
  faPrint: IconDefinition = faPrint;
  faArrow: IconDefinition = faArrowRight;
  faPen: IconDefinition = faPen;
  public codeLanguage: string;
  public rows: any[] = [];
  public sorts: any[] = [
    { prop: "date", dir: "desc" },
    { prop: "id", dir: "desc" },
  ];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public activeFilters: SearchFilter[] = [];
  public dateFormat: string;
  public filterer: Filterer;
  private readonly REVERT_ACTION_ID: number = 0;
  private isLoaded: Subject<never> = new Subject();

  constructor(
    private notificationHandlerService: NotificationHandlerService,
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    private policeBookMovementService: PoliceBookMovementService,
    protected messageService: MessageService,
    private storeServiceLight: LightService,
    private storeService: StoreService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private asynchronousTaskService: AsynchronousTaskService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  ngOnInit(): void {
    this.popupForm = this.fb.group({
      comment: [null],
    });
    this.addMenuActions();
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe((user: CaraUser) => {
        this.codeLanguage = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );

    this.inLabel = this.translateService.instant("police-book-movements-list.movement-type.in");
    this.outLabel = this.translateService.instant("police-book-movements-list.movement-type.out");

    this.subscriptionService.subs.push(
      combineLatest([this.fetchStoreLight(), this.fetchMainStore(), this.fetchAlloys()]).subscribe(() => {
        const selectedStoreId = this.authService.getContextStoreId();
        this.contextStore = this.storeList.find((store: Light) => store.id === selectedStoreId);
        this.isConnectedToMainStore = this.contextStore.id === this.mainStore.id;

        if (this.mainStore) {
          this.initFilters();
          this.computeSearchFilters();
        }
        this.fetchPoliceBookMovement();
      })
    );
  }

  public ngAfterViewInit(): void {
    this.table.sorts = this.sorts;
    this.table.offset = this.pager.number;
  }

  addRow(policebookMovement: PoliceBookMovement): void {
    let tag;
    if (policebookMovement.weight >= 0) {
      tag = { label: this.inLabel, className: "in" };
    } else {
      tag = { label: this.outLabel, className: "out" };
    }

    this.rows.push({
      id: policebookMovement.id,
      policeId: policebookMovement.policeId,
      date: policebookMovement.date,
      invoiceNumber: policebookMovement.invoiceNumber,
      documentNumber: policebookMovement.documentNumber,
      weightIn: policebookMovement.weight >= 0 ? policebookMovement.weight : null,
      weightOut: policebookMovement.weight < 0 ? policebookMovement.weight : null,
      alloyName: policebookMovement.alloyName,
      itemName: policebookMovement.itemName,
      serialNumber: policebookMovement.stockType === StockType.SERIAL_NUMBER ? policebookMovement.stockEntryId : null,
      batchNumber: [StockType.BATCH, StockType.BULK].includes(policebookMovement.stockType)
        ? policebookMovement.stockEntryId
        : null,
      quantity: policebookMovement.quantity,
      weight: policebookMovement.weight,
      stockEntryUOMShortName: policebookMovement.stockEntryUOMShortName,
      contactDestName: policebookMovement.contactDestName ?? this.translateService.instant("global.none"),
      contactSourceName: policebookMovement.contactSourceName,
      tag,
      comment: policebookMovement.comment,
      supplierRef: policebookMovement.supplierRef,
    });
  }

  removeMinusSign(num: any): number {
    return Math.abs(parseFloat(num));
  }

  addMenuActions(): void {
    this.menuActions = [];

    this.menuActions.push(
      new MenuAction(
        this.REVERT_ACTION_ID,
        this.translateService.instant("police-book-movements-list.actions.revert"),
        this.faPlus
      )
    );
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case this.REVERT_ACTION_ID:
        this.policeBookMovementId = row.id;
        // revert a movement
        this.subscriptionService.subs.push(
          this.policeBookMovementService.revert(this.policeBookMovementId).subscribe({
            complete: () => {
              const title = this.translateService.instant("message.title.save-success");
              const content = this.translateService.instant("message.content.save-success");
              this.messageService.success(content, { title });
              this.changePage({ page: 1 });
            },
          })
        );
        break;
      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  isAlreadyReverted(id: number): boolean {
    const policeBookMovement: PoliceBookMovement = this.policeBookMovementList.find(pbm => pbm.id === id);
    return policeBookMovement && policeBookMovement.reverseMovementId !== null;
  }

  openCommentPopup(id: number): void {
    if (!this.userService.canDo("POLICE_BOOK_MOVEMENT_UPDATE")) {
      return;
    }
    const filteredList = this.policeBookMovementList.filter(pbm => pbm.id === id);
    if (filteredList.length <= 0) {
      console.error(`can't find police book movement with id ${id}`);
      return;
    }
    this.selectedPoliceBookMovement = new PoliceBookMovement(filteredList[0]);
    this.popupTitle = `${this.getTranslationPrefix()}.popup.title`;
    this.initializePopup();
  }

  initializePopup(): void {
    this.popupForm = this.fb.group({
      comment: [null],
    });
    this.initialPoliceBookMovement = new PoliceBookMovement(this.selectedPoliceBookMovement);
    this.popupVisible = true;
    this.popupForm.controls.comment.setValue(this.selectedPoliceBookMovement.comment);
  }

  submitEntity(): void {
    this.submitted = true;

    this.selectedPoliceBookMovement.comment = this.popupForm.value.comment;

    this.subscriptionService.subs.push(
      this.policeBookMovementService
        .update(this.selectedPoliceBookMovement.id, this.popupForm.value.comment)
        .subscribe({
          error: error => {
            this.handleApiError(error);
          },
          complete: () => {
            this.fetchPoliceBookMovement();
            this.closePopup();
          },
        })
    );
  }

  handleApiError(err: any): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant("police-book-movements-list.errors.update-comment", {
      message: err.message,
    });
    this.messageService.warn(content, { title });
  }

  canClosePopup(): void {
    this.selectedPoliceBookMovement.comment = this.popupForm.value.comment;
    if (this.unsavedPoliceBookMovement && !this.unsavedPoliceBookMovement.equals(this.selectedPoliceBookMovement)) {
      this.shouldClose = false;
    }

    if (!this.initialPoliceBookMovement.equals(this.selectedPoliceBookMovement) && !this.shouldClose) {
      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedPoliceBookMovement = new PoliceBookMovement(this.selectedPoliceBookMovement);
      this.shouldClose = true;
    } else {
      this.closePopup();
    }
  }

  // close the popup
  closePopup(): void {
    this.popupVisible = false;
    this.shouldClose = false;
    this.unsavedPoliceBookMovement = null;
    this.submitted = false;
  }

  changeSortSettings(prop: string, direction: string): void {
    switch (prop) {
      case "date":
        this.sorts = [
          {
            prop: "date",
            dir: direction,
          },
          {
            prop: "id",
            dir: direction,
          },
        ];
        break;

      default:
        this.sorts = [
          {
            prop,
            dir: direction,
          },
        ];
    }
    this.changePage({ page: 1 });
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "type":
      case "weightIn":
      case "weightOut":
        return "weight";
      case "serialNumber":
        return "stockEntryId";
      case "batchNumber":
        return "stockEntryId";
      case "itemName":
        return "stockEntry.retailItem.name";
      case "alloyName":
        return "alloy.techName";
      default:
        return prop;
    }
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchPoliceBookMovement();
  }

  fetchStoreLight(): Observable<Light[]> {
    return this.storeServiceLight.getStores().pipe(
      tap(
        (stores: Store[]) => {
          this.storeList = stores;
          this.storeOptions = stores
            .filter((obj: Store) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Store) => new Option(obj.id, obj.name));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("police-book-movements-list.errors.get-stores", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("police-book-movements-list.errors.get-main-store", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  buildDateDefaultValue(): any {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    return {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
  }

  buildMovementTypeOptions(): any {
    const options = [];

    const one = 1;
    const two = 2;

    options.push({ value: one, displayValue: this.inLabel });
    options.push({ value: two, displayValue: this.outLabel });

    return options;
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addListFilter(
      "contextContactId",
      this.translateService.instant("police-book-movements-list.datatable.columns.perimeter"),
      this.isConnectedToMainStore
        ? this.storeList
          .map(store => {
            return { value: store.id.toString(), displayValue: store.name };
          })
          .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
        : [{ value: this.contextStore.id.toString(), displayValue: this.contextStore.name }],
      true,
      true,
      this.isConnectedToMainStore ? [this.mainStore.id.toString()] : [this.contextStore.id.toString()],
      null,
      true,
      true
    );

    this.filterer.addFilter(
      "date",
      this.translateService.instant("police-book-movements-list.datatable.columns.date"),
      "date",
      true,
      true,
      this.buildDateDefaultValue()
    );

    this.filterer.addListFilter(
      "movementType",
      this.translateService.instant("police-book-movements-list.datatable.columns.movement-type"),
      this.buildMovementTypeOptions()
    );

    this.filterer.addFilter(
      "policeId",
      this.translateService.instant("police-book-movements-list.datatable.columns.movement-id"),
      "string"
    );

    this.filterer.addFilter(
      "contactSourceName",
      this.translateService.instant("police-book-movements-list.datatable.columns.contact-source-name"),
      "string"
    );

    this.filterer.addFilter(
      "contactDestName",
      this.translateService.instant("police-book-movements-list.datatable.columns.contact-dest-name"),
      "string"
    );

    this.filterer.addFilter(
      "stockEntry.retailItem.name",
      this.translateService.instant("police-book-movements-list.datatable.columns.item-name"),
      "string"
    );

    this.filterer.addFilter(
      "serial.stockEntry.id",
      this.translateService.instant("police-book-movements-list.datatable.columns.serial-number"),
      "string"
    );

    this.filterer.addFilter(
      "batch.stockEntry.id",
      this.translateService.instant("police-book-movements-list.datatable.columns.batch"),
      "string"
    );

    this.filterer.addFilter(
      "supplierRef",
      this.translateService.instant("police-book-movements-list.datatable.columns.supplier-ref"),
      "string"
    );

    this.filterer.addFilter(
      "quantity",
      this.translateService.instant("police-book-movements-list.datatable.columns.quantity"),
      "range"
    );

    this.filterer.addListFilter(
      "alloyId",
      this.translateService.instant("police-book-movements-list.datatable.columns.alloy-name"),
      this.alloys.map(alloy => {
        return { value: alloy.id.toString(), displayValue: alloy.techName };
      })
    );

    this.filterer.addFilter(
      "weight.in",
      this.translateService.instant("police-book-movements-list.datatable.columns.weight-in"),
      "range"
    );

    this.filterer.addFilter(
      "weight.out",
      this.translateService.instant("police-book-movements-list.datatable.columns.weight-out"),
      "range"
    );

    this.filterer.addFilter(
      "documentNumber",
      this.translateService.instant("police-book-movements-list.datatable.columns.document-number"),
      "string"
    );

    this.filterer.addFilter(
      "invoiceNumber",
      this.translateService.instant("police-book-movements-list.datatable.columns.invoice-number"),
      "string"
    );

    this.filterer.addFilter(
      "comment",
      this.translateService.instant("police-book-movements-list.datatable.columns.comment"),
      "string"
    );
  }

  applyFilters(): void {
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.changePage({ page: 1 });
      })
    );
  }

  public printPoliceBookMovements(): void {
    const asyncCreationTask = {
      type: "generatePdf",
      params: `police-book-movement;${this.filterer
        .getSearchFilters()
        .map(filter => filter.toQuery())
        .join(",")};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  getTranslationPrefix(): string {
    return "police-book-movements-list";
  }

  getColumnTranslation(columnName: any): string {
    return `${this.getTranslationPrefix()}.datatable.columns.${columnName}`;
  }

  protected computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
    this.activeFilters.forEach((sf, index) => {
      const filterValue = this.filterer.filterValues.find(fv => fv.filterId === hash(sf.key));
      switch (sf.key) {
        case "serial.stockEntry.id": {
          this.applyStockType(filterValue, StockType.SERIAL_NUMBER, index);
          break;
        }

        case "batch.stockEntry.id": {
          this.applyStockType(filterValue, [StockType.BATCH, StockType.BULK], index);
          break;
        }

        case "movementType": {
          this.applyMovementTypeFilter(filterValue, index);
          break;
        }

        case "weight.in": {
          this.applyPositiveWeightFilter(filterValue, index);
          break;
        }

        case "weight.out": {
          this.applyNegativeWeightFilter(filterValue, index);
          break;
        }

        default:
          console.warn(`${sf.key} is an unknown filter id!`);
          break;
      }
    });
  }

  private fetchPoliceBookMovement(): void {
    this.subscriptionService.subs.push(
      this.policeBookMovementService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe({
        next: (result: PaginatedList<PoliceBookMovement>) => {
          this.rows = [];
          this.policeBookMovementList = result.data;
          this.pager = result.page;

          result.data.forEach((policebookMovement: PoliceBookMovement) => {
            this.addRow(policebookMovement);
          });
          this.rows = [...this.rows];
        },
        error: error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("police-book-movements-list.errors.get-entities", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        },
        complete: () => {
          this.isLoaded.complete();
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
        },
      })
    );
  }

  private fetchAlloys(): Observable<Alloy[]> {
    return this.policeBookMovementService.getAlloys().pipe(
      tap(
        (alloys: Alloy[]) => {
          this.alloys = alloys;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("police-book-movements-list.errors.get-alloys");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private applyPositiveWeightFilter(filterValue: FilterValue, index: number): void {
    // prevent to fetch quantity for weight out data
    if (filterValue.min === null || filterValue.min < 0) {
      filterValue.min = 0;
    }
    if (filterValue.max < 0) {
      filterValue.max = 0;
    }

    const sfMin = new SearchFilter(
      "weight",
      SearchFilterOperator.GREATER_EQUAL_THAN,
      filterValue.min.toString(),
      filterValue.filterId
    );
    this.activeFilters.splice(index, 1, sfMin);

    if (filterValue.max !== null) {
      const sfMax = new SearchFilter(
        "weight",
        SearchFilterOperator.LOWER_EQUAL_THAN,
        filterValue.max.toString(),
        filterValue.filterId
      );
      this.activeFilters.splice(index + 1, 1, sfMax);
    }
  }

  private applyNegativeWeightFilter(filterValue: FilterValue, index: number): void {
    if (filterValue.min === null || filterValue.min < 0) {
      filterValue.min = 0;
    }
    if (filterValue.max < 0) {
      filterValue.max = 0;
    }

    // invert comparators to allow a query with negative values
    filterValue.min = -Math.abs(filterValue.min);
    const sfMin = new SearchFilter(
      "weight",
      SearchFilterOperator.LOWER_EQUAL_THAN,
      filterValue.min.toString(),
      filterValue.filterId
    );
    this.activeFilters.splice(index, 1, sfMin);

    if (filterValue.max !== null) {
      filterValue.max = -Math.abs(filterValue.max);
      const sfMax = new SearchFilter(
        "weight",
        SearchFilterOperator.GREATER_EQUAL_THAN,
        filterValue.max.toString(),
        filterValue.filterId
      );
      this.activeFilters.splice(index + 1, 1, sfMax);
    }
  }

  private applyMovementTypeFilter(filterValue: FilterValue, index: number): void {
    let sf;
    if ((filterValue.equal as number[])?.length === 1) {
      const operator = filterValue.equal[0] === 1 ? SearchFilterOperator.LOWER_THAN : SearchFilterOperator.GREATER_THAN;
      sf = new SearchFilter("weight", operator, "0", filterValue.filterId);
    } else {
      sf = new SearchFilter("weight", SearchFilterOperator.LIKE, "", filterValue.filterId);
    }
    this.activeFilters.splice(index, 1, sf);
  }

  private applyStockType(filterValue: FilterValue, stockType: any, index: number): void {
    let sf;
    if (filterValue.equal === null) {
      return;
    }
    // return no line if user types letters
    if (isNaN(+filterValue.equal)) {
      filterValue.equal = "0";
    }

    if (filterValue.equal !== "") {
      sf = new SearchFilter(
        "stockEntry.id",
        SearchFilterOperator.LIKE,
        filterValue.equal.toString(),
        filterValue.filterId
      );
      this.activeFilters.splice(index, 1, sf);

      this.activeFilters.push(
        new SearchFilter("stockEntry.stockType", SearchFilterOperator.IN, stockType, filterValue.filterId)
      );
    }
  }
}
