import { Decimal } from "decimal.js";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import {
  IconDefinition,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faScannerGun,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  ShipmentLine,
  PricingGroup,
  ItemCategory,
  Uom,
  Currency,
  StockEntryLocation,
  StockEntry,
  StockLocation,
  PricingGroupService,
  ItemCategoryService,
  StockEntryService,
  StockLocationService,
  AuthService,
  CurrencyService,
  CaraUserService,
  UomService,
  PaginatedList,
  Pagination,
  StockType,
  PricingGroupCategoryLink,
  CaraUser,
  MovementType,
} from "center-services";
import {
  CommonValidatorsUtil,
  FilterValue,
  Filterer,
  RoundingUtil,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { ErrorUtil, FilteredTableListComponent, PrecisionUtil } from "generic-pages";
import { Observable, combineLatest, merge, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { StockEntryLocationSelectionOutput } from "../multiple-location-selection-popup/stock-entry-location-selection-output";
import { ShipmentStockEntrySelectionPopupComponent } from "../shipment-stock-entry-selection-popup/shipment-stock-entry-selection-popup.component";
import { StockEntryLocationLine } from "../shipment-stock-entry-selection-popup/stock-entry-location-line";

@Component({
  selector: "app-shipment-form-lines",
  templateUrl: "./shipment-form-lines.component.html",
  styleUrls: ["./shipment-form-lines.component.scss"],
  providers: [SubscriptionService],
})
export class ShipmentFormLinesComponent extends FilteredTableListComponent implements OnInit, OnChanges {
  @ViewChild("stockEntryIdElement", { read: ElementRef }) stockEntryIdElement: ElementRef;

  @Input() pricingGroupId: number;
  @Input() lines: ShipmentLine[];
  @Input() receiverId: number;
  @Input() senderId: number;
  @Input() type: string;
  @Input() readOnly: boolean = false;
  @Input() deliveryRef: string;

  @Output() submitShipmentForm: EventEmitter<any> = new EventEmitter();

  // icons
  public faWarn: IconDefinition = faExclamationTriangle;
  public faScannerGun: IconDefinition = faScannerGun;
  public faInfo: IconDefinition = faInfoCircle;
  public faError: IconDefinition = faExclamationCircle;

  // lines forms
  public rowsForm: UntypedFormGroup = new UntypedFormGroup({});
  public selectionForm: UntypedFormGroup;
  public scanForm: UntypedFormGroup;

  // datatable elements
  public selectedRows: any[] = [];
  public allRows: any[] = [];
  public rows: any[] = [];

  public sorts: any[] = [
    {
      prop: "lineNumber",
      dir: "asc",
    },
  ];
  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public LIST_ID: string = "app-shipment-form.shipment-form-table";
  public READ_LIST_ID: string = "app-shipment-form.shipment-form-table-detail";
  public listId: string;
  public canReadPrices: boolean = true;
  public isEditable: boolean = false;
  public menuActions: MenuAction[];
  public defaultCurrency: Currency;
  public dateFormat: string;
  public filterer: Filterer;
  public locale: string;
  // Popup
  public shipmentStockEntrySelectionPopupVisible: boolean = false;
  public disabledLines: StockEntryLocationLine[] = [];
  public stockEntryLocationSelection: StockEntryLocation = null;
  public stockEntryLocations: StockEntryLocation[] = [];
  public originalSELList: StockEntryLocation[] = [];
  public multipleLocationSelectionPopupVisible: boolean = false;
  public stockEntryList: StockEntry[] = [];
  public stockLocationList: StockLocation[] = [];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });
  // Others
  private digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);
  private currentPricingGroup: PricingGroup = null;
  private readonly LABEL_PREFIX: string = "SE";
  private readonly PERCENT_VALUE: number = 100;
  private itemCategories: ItemCategory[];
  private purchaseUnits: Uom[] = [];
  private hiddenValue: string = "";

  constructor(
    private pricingGroupService: PricingGroupService,
    private itemCategoryService: ItemCategoryService,
    private stockEntryService: StockEntryService,
    private stockLocationService: StockLocationService,
    private authService: AuthService,
    protected translateService: TranslateService,
    private currencyService: CurrencyService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private uomService: UomService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.addMenuActions();
    this.prepareForms();
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pricingGroupId && this.pricingGroupId !== null) {
      this.subscriptionService.subs.push(
        this.fetchPricingGroup().subscribe(() => {
          this.recomputeLinePrices();
        })
      );
    }
  }

  public ngOnInit(): void {
    this.listId = this.readOnly ? this.READ_LIST_ID : this.LIST_ID;

    SessionPagination.clear(ShipmentStockEntrySelectionPopupComponent.LIST_ID);

    this.subscriptionService.subs.push(
      this.fetchInitialDatas().subscribe(() => {
        this.allRows = [];
        this.lines.forEach((line: ShipmentLine) => {
          const row = this.buildReadOnlyRow(line);
          if (!this.readOnly) {
            const sel = this.originalSELList.find(
              (stockEntryLocation: StockEntryLocation) =>
                line.stockEntryId === stockEntryLocation.stockEntry.id &&
                line.stockLocationId === stockEntryLocation.locationId
            );
            if (sel) {
              row.maxWeight = sel.weight !== null ? +sel.weight : sel.weight;
              row.id = `${sel.stockEntry.id.toString()}_${sel.id.toString()}`;
              row.maxTare = sel.tare !== null ? +sel.tare : sel.tare;
              row.maxQuantity = sel.quantity;
              row.shipmentRefs = sel.shipmentRefs
                ? sel.shipmentRefs.filter(sr => sr !== this.deliveryRef).map(sr => ` ${sr}`)
                : [];
              row.location = sel.locationName;
            } else {
              row.id = `${line.stockEntryId.toString()}_${line.stockLocationId.toString()}`;
              row.noSEL = true;
            }
            this.addRowInForms(row);
            row.stockDepleted = line.stockDepleted;
          }
          this.allRows.push(row);

          this.allRows = [...this.allRows];
          this.rows = [...this.allRows];
        });
        this.checkInErrorRows(true);
        this.initFilters();
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener("window:keypress", ["$event"])
  public addItemByScanner(event: KeyboardEvent): void {
    if (isNaN(Number(event.key))) {
      switch (event.key) {
        case "Enter":
          this.scanForm.get("stockEntryId").markAsTouched();
          this.addShipmentLine();
          this.hiddenValue = "";
          break;
        case "E":
          if (this.hiddenValue !== "S") {
            return;
          }
          this.hiddenValue += event.key;
          break;
        case "S":
          if (this.hiddenValue !== "") {
            return;
          }
          this.hiddenValue += event.key;
          break;
        default:
          return;
      }
    } else if (this.hiddenValue.startsWith(this.LABEL_PREFIX)) {
      this.hiddenValue += event.key;
    }
  }

  fetchStockEntries(stockEntryIdList: number[]): Observable<PaginatedList<StockEntry>> {
    const pager = new Pagination({
      size: stockEntryIdList.length,
      number: 0,
    });
    const filters = new SearchFilter("id", SearchFilterOperator.IN, [...stockEntryIdList]);

    return this.stockEntryService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<StockEntry>) => {
          result.data.forEach(stockEntry => {
            if (!this.stockEntryList.includes(stockEntry)) {
              this.stockEntryList.push(stockEntry);
            }
          });
        },
        (error: any) => this.handleApiError(error)
      )
    );
  }

  buildReadOnlyRow(line: ShipmentLine): any {
    const stockEntry = this.stockEntryList.find((se: StockEntry) => line.stockEntryId === se.id);

    const row: any = {
      purchaseUnit: this.purchaseUnits.find((uom: Uom) => uom.id === stockEntry.uomId)?.longName,
      metalWeight: !line.weight && !line.tare ? 0 : line.weight - line.tare,
      itemSupplierRef: stockEntry.itemSupplierRef ? stockEntry.itemSupplierRef : "",
      itemCategoryId: this.itemCategories.find((category: ItemCategory) => {
        return category.name === stockEntry.itemCategoryName;
      }).id,
      supplierName: stockEntry.supplierName ? stockEntry.supplierName : "",
      supplierRef: stockEntry.supplierRef ? stockEntry.supplierRef : "",
      itemRef: stockEntry.itemReference ? stockEntry.itemReference : "",
      sizeValue: line.expectedSizeValue ? line.expectedSizeValue : "",
      itemName: stockEntry.itemName ? stockEntry.itemName : "",
      basePrice: stockEntry.computedUnitPrice,
      totalPrice: RoundingUtil.roundHigh(new Decimal(line.unitPrice ?? 0).times(line.quantity ?? 0).toNumber()),
      stockLocationId: line.stockLocationId,
      brandName: stockEntry.itemBrandName,
      lineNumber: line.lineNumber ? line.lineNumber : this.allRows.length + 1,
      stockEntryId: line.stockEntryId,
      type: stockEntry.stockType,
      location: this.stockLocationList.find((stockLocation: StockLocation) => stockLocation.id === line.stockLocationId)
        .name,
      unitPrice: line.unitPrice,
      quantity: line.quantity,
      weight: !line.weight ? 0 : line.weight,
      serialNumber: "",
      batchNumber: "",
      tare: !line.tare ? 0 : line.tare,
    };

    switch (stockEntry.stockType) {
      case StockType.SERIAL_NUMBER:
        row.serialNumber = stockEntry.id;
        break;
      case StockType.BATCH:
      case StockType.BULK:
        row.batchNumber = stockEntry.id;
        break;
      default:
        break;
    }
    return row;
  }

  public addShipmentLine(): void {
    let stockEntryId = this.hiddenValue || this.scanForm.get("stockEntryId").value;
    if (stockEntryId) {
      if (stockEntryId.startsWith(this.LABEL_PREFIX)) {
        this.scanForm.get("stockEntryId").setValue(stockEntryId.substring(this.LABEL_PREFIX.length));
        stockEntryId = stockEntryId.substring(this.LABEL_PREFIX.length);
      }
      this.subscriptionService.subs.push(
        this.fetchStockEntryLocations(stockEntryId, true).subscribe({
          next: (data: StockEntryLocation[]) => {
            if (data.length && data.length >= 1) {
              this.stockEntryLocations = data.filter(sel => !this.findShipmentLine(sel));
              if (this.stockEntryLocations.length === 0) {
                this.scanForm.get("stockEntryId").setErrors({ alreadyAdded: true });
              } else if (data.length === 1) {
                this.manageShipmentLine(data[0], true);
              } else if (data.length > 1) {
                this.openMultipleLocationSelectionPopup();
              }
            } else {
              this.scanForm.get("stockEntryId").setErrors({ badProduct: true });
            }
            this.stockEntryIdElement.nativeElement.getElementsByTagName("input")[0].blur();
            this.updateFilters();
            this.applyFilters();
          },
        })
      );
    }
  }

  openMultipleLocationSelectionPopup(): void {
    this.stockEntryLocationSelection = null;
    this.multipleLocationSelectionPopupVisible = true;
  }

  setSelectedLocation(stockEntryLocationSelectionOutput: StockEntryLocationSelectionOutput): void {
    this.stockEntryLocationSelection = this.stockEntryLocations.find(
      stockEntryLocation => stockEntryLocation.id === stockEntryLocationSelectionOutput.stockLocationId
    );
    this.manageShipmentLine(this.stockEntryLocationSelection, true, stockEntryLocationSelectionOutput.quantity);
    this.updateFilters();
    this.applyFilters();
    this.closeMultipleLocationSelectionPopup();
  }

  closeMultipleLocationSelectionPopup(): void {
    this.multipleLocationSelectionPopupVisible = false;
    this.stockEntryLocations = [];
    this.stockEntryLocationSelection = null;
  }

  public applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.listId
      ).subscribe()
    );
  }

  public applyModifications(): void {
    if (this.rowsForm.invalid) {
      return;
    }

    this.lines.forEach((line: ShipmentLine) => {
      const lineRow: any = this.allRows.find((row: any) => {
        return row.stockEntryId === line.stockEntryId && row.stockLocationId === line.stockLocationId;
      });
      if (!lineRow || !this.rowsForm.get(lineRow.id)) {
        return;
      }
      const rowForm = this.rowsForm.get(lineRow.id);

      line.quantity = +rowForm.get("quantity").value;
      line.weight = +rowForm.get("weight").value;
      line.tare = +rowForm.get("tare").value;
      line.lineNumber = lineRow.lineNumber;
    });
  }

  public getRowClass(row: any): any {
    return { inError: row.inError, "not-clickable": true };
  }

  // Method used to get the totalQuantity for the price summary
  public getTotalPrice(): number {
    let totalPrice = 0;

    this.allRows.forEach(row => {
      totalPrice = new Decimal(totalPrice ?? 0).add(new Decimal(row.totalPrice ?? 0)).toNumber();
    });
    return RoundingUtil.roundLow(totalPrice);
  }

  public getTotalQuantity(): number {
    let totalQuantity = 0;

    this.allRows.forEach(row => {
      if (this.rowsForm.get(row.id)) {
        totalQuantity = new Decimal(totalQuantity ?? 0)
          .add(new Decimal(this.rowsForm.get(`${row.id}.quantity`).value ?? 0))
          .toNumber();
        return;
      }
      totalQuantity += row.quantity;
    });
    return totalQuantity;
  }

  public hasSameQuantityUnit(): boolean {
    let currentUnit = null;
    let sameUnit = true;

    this.allRows.forEach((row: any) => {
      if (!currentUnit) {
        currentUnit = row.purchaseUnit;
      }
      if (currentUnit !== row.purchaseUnit) {
        sameUnit = false;
        return;
      }
    });
    return sameUnit;
  }

  public manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case 0:
        this.deleteLine(row);
        break;
      default:
        break;
    }
  }

  public onHeaderCheckboxChange(): void {
    const headerValue = this.selectionForm.get("header").value;
    this.rows.forEach((row: any) => {
      this.selectionForm.get(row.id).patchValue(headerValue);
    });
    this.selectedRows = headerValue ? [...this.rows] : [];
  }

  public onRowCheckboxChange(): void {
    this.selectedRows = this.rows.filter((row: any) => this.selectionForm.get(row.id).value);
    this.selectionForm.get("header").patchValue(this.selectedRows.length === this.rows.length);
  }

  public removeLines(): void {
    this.selectedRows.forEach((row: any, index: number) =>
      this.deleteLine(row, index === this.selectedRows.length - 1)
    );
    this.resetSelectedRows();
  }

  public updateShipmentLines(): boolean {
    if (this.checkInErrorRows(true)) {
      return false;
    }

    if (this.rowsForm.invalid) {
      this.rowsForm.markAllAsTouched();
      return false;
    }
    this.applyModifications();
    return true;
  }

  openShipmentStockEntrySelectionPopup(): void {
    this.disabledLines = [];
    if (this.rows.length > 0) {
      this.disabledLines = this.rows.map(
        row =>
          new StockEntryLocationLine({
            stockEntryId: row.stockLocationId,
            stockLocationId: row.stockEntryId,
          })
      );
    }
    this.shipmentStockEntrySelectionPopupVisible = true;
  }

  closeShipmentStockEntrySelectionPopup(): void {
    this.shipmentStockEntrySelectionPopupVisible = false;
  }

  submitShipmentStockEntrySelectionPopup(newLines: StockEntryLocation[]): void {
    newLines.forEach((newLine, i) => {
      this.manageShipmentLine(newLine, true, null, i === newLines.length - 1);
    });
    this.updateFilters();
    this.applyFilters();
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }

  private addMenuActions(): void {
    this.menuActions = [new MenuAction(0, this.translateService.instant("shipment-form.buttons.delete"), faTrash)];
  }

  private addRowInForms(row: any): void {
    this.selectionForm.addControl(row.id, new UntypedFormControl(false));
    if (row.type === StockType.SERIAL_NUMBER || this.readOnly || row.noSEL) {
      return;
    }
    this.isEditable = true;

    this.rowsForm.addControl(
      row.id,
      new UntypedFormGroup({
        quantity: new UntypedFormControl(row.quantity, [
          Validators.max(row.maxQuantity),
          CommonValidatorsUtil.zeroValueValidator(),
          this.digitValidator,
          Validators.required,
        ]),
        weight: new UntypedFormControl(row.weight, [Validators.max(row.maxWeight), this.digitValidator]),
        tare: new UntypedFormControl(row.tare, [Validators.max(row.maxTare), this.digitValidator]),
        metalWeight: new UntypedFormControl(row.metalWeight, [this.digitValidator]),
      })
    );

    this.subscriptionService.subs.push(
      this.rowsForm.get(`${row.id}.quantity`).valueChanges.subscribe((value: number) => {
        row.totalPrice = RoundingUtil.roundHigh(new Decimal(row.unitPrice ?? 0).times(value ?? 0).toNumber());
      })
    );

    this.subscriptionService.subs.push(
      this.rowsForm.get(`${row.id}.weight`).valueChanges.subscribe(() => {
        const weight = this.rowsForm.get(`${row.id}.weight`).value;
        const tare = this.rowsForm.get(`${row.id}.tare`).value;
        if (tare === null || tare === "" || weight === null || weight === "") {
          return;
        }

        if (tare && weight && +tare >= +weight) {
          this.rowsForm.get(`${row.id}.weight`).setErrors({ badWeight: true });
          return;
        }
        this.rowsForm.get(`${row.id}.metalWeight`).patchValue(+weight - +tare, { emitEvent: false });
      })
    );

    this.subscriptionService.subs.push(
      merge(
        this.rowsForm.get(`${row.id}.metalWeight`).valueChanges,
        this.rowsForm.get(`${row.id}.tare`).valueChanges
      ).subscribe(() => {
        const metalWeight = this.rowsForm.get(`${row.id}.metalWeight`).value;
        const tare = this.rowsForm.get(`${row.id}.tare`).value;
        if (tare === null || tare === "" || metalWeight === null || metalWeight === "") {
          return;
        }

        this.rowsForm.get(`${row.id}.weight`).markAsTouched();
        this.rowsForm.get(`${row.id}.weight`).patchValue(+metalWeight + +tare, { emitEvent: false });
      })
    );
  }

  private buildRow(sel: StockEntryLocation): any {
    const line: ShipmentLine = this.findShipmentLine(sel);
    const stockEntry: StockEntry = sel.stockEntry;
    if (!this.stockEntryList.includes(stockEntry)) {
      this.stockEntryList.push(stockEntry);
    }
    const row = this.buildReadOnlyRow(line);

    row.maxWeight = sel.weight !== null ? +sel.weight : sel.weight;
    row.id = `${stockEntry.id.toString()}_${sel.id.toString()}`;
    row.maxTare = sel.tare !== null ? +sel.tare : sel.tare;
    row.maxQuantity = sel.quantity;
    row.shipmentRefs = sel.shipmentRefs
      ? sel.shipmentRefs.filter(sr => sr !== this.deliveryRef).map(sr => ` ${sr}`)
      : [];
    row.location = sel.locationName;
    row.stockDepleted = line.stockDepleted;

    line.lineNumber = row.lineNumber;

    return row;
  }

  private createShipmentLine(sel: StockEntryLocation, shipmentLineQuantity: number = null): void {
    let unitPrice = sel.stockEntry.computedUnitPrice;
    if (
      (this.type === "STORE" || this.type === "CUSTOMER") &&
      this.currentPricingGroup &&
      this.currentPricingGroup.pricingGroupCategoryLinks &&
      this.currentPricingGroup.pricingGroupCategoryLinks
    ) {
      const margin = this.currentPricingGroup.pricingGroupCategoryLinks.find((obj: PricingGroupCategoryLink) => {
        return (
          obj.itemCategoryId ===
          this.itemCategories.find(category => category.name === sel.stockEntry.itemCategoryName).id
        );
      })?.marginPercent;
      unitPrice = margin ? unitPrice + unitPrice * (margin / this.PERCENT_VALUE) : unitPrice;
      unitPrice = RoundingUtil.roundLow(unitPrice);
    }

    this.lines.push(
      new ShipmentLine({
        expectedSizeValue: sel.stockEntry.sizeValue,
        stockEntryId: sel.stockEntry.id,
        stockLocationId: sel.locationId,
        quantity: shipmentLineQuantity !== null ? shipmentLineQuantity : sel.quantity,
        weight: sel.weight,
        tare: sel.tare,
        unitPrice,
      })
    );
  }

  private deleteLine(row: any, withUpdate: boolean = true): void {
    this.lines.splice(this.lines.indexOf(this.findShipmentLine(null, row)), 1);
    this.allRows.splice(this.allRows.indexOf(row), 1);
    this.selectionForm.removeControl(row.id);
    this.rowsForm.removeControl(row.id);
    this.allRows = [...this.allRows];
    this.resetRowsLineNumber();

    this.updateFilters();
    this.applyFilters();
    if (!this.checkInErrorRows(false) && withUpdate) {
      this.submitShipmentForm.emit();
    }
  }

  private fetchInitialDatas(): Observable<any[]> {
    const observables: Observable<any>[] = [];
    const stockEntryIdList = this.lines.map((line: ShipmentLine) => line.stockEntryId);

    observables.push(this.fetchConnectedUserDetails());
    observables.push(this.fetchDefaultCurrency());
    if (this.pricingGroupId !== null) {
      observables.push(this.fetchPricingGroup());
    }
    observables.push(this.fetchItemCategories());
    observables.push(this.fetchUoms());
    observables.push(this.fetchStockLocations());
    if (stockEntryIdList.length > 0) {
      observables.push(this.fetchStockEntries(stockEntryIdList));
    }
    observables.push(this.fetchStockEntryLocations(null, true));

    return combineLatest(observables);
  }

  private fetchPricingGroup(): Observable<PricingGroup> {
    return this.pricingGroupService.get(this.pricingGroupId).pipe(
      tap(
        (pricingGroup: PricingGroup) => (this.currentPricingGroup = pricingGroup),
        (error: any) => this.handleApiError(error)
      )
    );
  }

  private fetchDefaultCurrency(): Observable<Currency> {
    return this.currencyService.fetchDefaultCurrency().pipe(
      tap(
        (currency: Currency) => (this.defaultCurrency = currency),
        (error: any) => this.handleApiError(error)
      )
    );
  }

  private fetchConnectedUserDetails(): Observable<CaraUser> {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.canReadPrices = this.userService.canDo("PURCHASING_PRICE");
      return of(this.userService.connectedUser.value);
    }
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
        this.canReadPrices = this.userService.canDo("PURCHASING_PRICE");
      })
    );
  }

  private fetchItemCategories(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (categories: ItemCategory[]) => (this.itemCategories = categories),
        (error: any) => this.handleApiError(error)
      )
    );
  }

  private fetchStockLocations(): Observable<StockLocation[]> {
    return this.stockLocationService.getAll(["withChildren"]).pipe(
      tap(
        (stockLocations: StockLocation[]) => {
          this.stockLocationList = this.stockLocationService.getFlattenedLocations(stockLocations);
        },
        (error: any) => this.handleApiError(error)
      )
    );
  }

  private fetchUoms(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => (this.purchaseUnits = uoms),
        (error: any) => this.handleApiError(error)
      )
    );
  }

  private fetchStockEntryLocations(
    stockEntryId: string = null,
    withShipmentRefs: boolean = false
  ): Observable<StockEntryLocation[]> {
    if (!stockEntryId && (!this.lines || !this.lines.length)) {
      return of([]);
    }

    const selPager = new Pagination({ number: 0, size: 1000 });
    const selFilter = [
      new SearchFilter("contextContactId", SearchFilterOperator.EQUAL, [this.authService.getContextStoreId()]),
      new SearchFilter(
        "stockEntry.id",
        SearchFilterOperator.IN,
        stockEntryId ? [stockEntryId] : this.lines.map((line: ShipmentLine) => line.stockEntryId)
      ),
      new SearchFilter("movementType", SearchFilterOperator.NOT_EQUAL, [MovementType.TRANSIT]),
    ];
    return this.stockEntryService.getStockEntryLocations(selPager, null, [], selFilter, withShipmentRefs).pipe(
      tap(
        (result: PaginatedList<StockEntryLocation>) =>
          (this.originalSELList = this.originalSELList.length > 0 ? this.originalSELList : result.data)
      ),
      map((result: PaginatedList<StockEntryLocation>) => result.data),
      catchError((error: any) => {
        this.handleApiError(error);
        return of([]);
      })
    );
  }

  private findShipmentLine(sel: StockEntryLocation = null, row: any = null): ShipmentLine {
    const stockLocationId = sel ? sel.locationId : row.stockLocationId;
    const stockEntryId = sel ? sel.stockEntry.id : row.stockEntryId;

    return this.lines.find((line: ShipmentLine) => {
      return stockEntryId === line.stockEntryId && stockLocationId === line.stockLocationId;
    });
  }

  private handleApiError(error: any): void {
    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const message = this.translateService.instant(result.message, result.params);
    const title = this.translateService.instant("message.title.form-errors");
    this.messageService.error(message, { title });
  }

  private initFilters(): void {
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.listId
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "itemSupplierRef",
      this.translateService.instant("shipment-form.datatable.columns.item-supplier-ref"),
      "string"
    );
    this.filterer.addListFilter(
      "supplierName",
      this.translateService.instant("shipment-form.datatable.columns.supplier-name"),
      this.allRows
        .map(row => row.supplierName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(name => {
          return { value: name, displayValue: name };
        }),
      null,
      null,
      null,
      null,
      true,
      null,
      true
    );
    this.filterer.addFilter(
      "serialNumber",
      this.translateService.instant("shipment-form.datatable.columns.serial-number"),
      "string"
    );
    this.filterer.addFilter(
      "batchNumber",
      this.translateService.instant("shipment-form.datatable.columns.batch-number"),
      "string"
    );
    if (this.readOnly) {
      this.filterer.addFilter(
        "quantity",
        this.translateService.instant("shipment-form.datatable.columns.quantity"),
        "range"
      );
    }
    this.filterer.addFilter(
      "sizeValue",
      this.translateService.instant("shipment-form.datatable.columns.size-value"),
      "string"
    );
    if (this.readOnly) {
      this.filterer.addFilter(
        "metalWeight",
        this.translateService.instant("shipment-form.datatable.columns.metal-weight"),
        "range"
      );
      this.filterer.addFilter("tare", this.translateService.instant("shipment-form.datatable.columns.tare"), "range");
      this.filterer.addFilter(
        "weight",
        this.translateService.instant("shipment-form.datatable.columns.total-weight"),
        "range"
      );
    }

    this.filterer.addListFilter(
      "location",
      this.readOnly
        ? this.translateService.instant("shipment-form.datatable.columns.origin-location")
        : this.translateService.instant("shipment-form.datatable.columns.location"),
      this.allRows
        .map(row => row.location)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(name => {
          return { value: name, displayValue: name };
        }),
      null,
      null,
      null,
      null,
      true,
      null,
      true
    );
    this.filterer.addFilter(
      "itemRef",
      this.translateService.instant("shipment-form.datatable.columns.item-ref"),
      "string"
    );
    this.filterer.addFilter(
      "itemName",
      this.translateService.instant("shipment-form.datatable.columns.item-name"),
      "string"
    );
    this.filterer.addListFilter(
      "brandName",
      this.translateService.instant("shipment-form.datatable.columns.brand-name"),
      this.allRows
        .map(row => row.brandName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(name => {
          return { value: name, displayValue: name };
        }),
      null,
      null,
      null,
      null,
      true,
      null,
      true
    );
    this.filterer.addFilter(
      "supplierRef",
      this.translateService.instant("shipment-form.datatable.columns.supplier-ref"),
      "string"
    );
  }

  private manageShipmentLine(
    sel: StockEntryLocation,
    isNew: boolean = false,
    shipmentLineQuantity: number = null,
    save: boolean = true
  ): void {
    if (isNew) {
      this.createShipmentLine(sel, shipmentLineQuantity);
    }
    const row = this.buildRow(sel);
    this.addRowInForms(row);
    this.allRows.push(row);

    this.allRows = [...this.allRows];
    this.rows = [...this.allRows];

    // save here and call the parent each time a row is added
    if (save && !this.checkInErrorRows(false)) {
      this.submitShipmentForm.emit();
    }
  }

  private checkInErrorRows(messageWanted: boolean): boolean {
    let inError = false;
    this.allRows.forEach(row => {
      if ((row.stockDepleted && row.serialNumber) || row.noSEL) {
        row.inError = true;
        inError = true;
      }
      if (this.rowsForm.controls[row.id]) {
        const rowControls = this.rowsForm.controls[row.id] as UntypedFormGroup;
        row.inError = rowControls?.invalid;
        if (row.inError) {
          inError = true;
          rowControls.markAllAsTouched();
        }
      }
    });
    if (inError && messageWanted) {
      const title = this.translateService.instant("message.title.form-errors");
      const content = this.translateService.instant(
        "multi-shipment-form.shipment-container.errors.shipment-form-error"
      );
      this.messageService.error(content, { title });
    }

    this.allRows = [...this.allRows];
    this.rows = [...this.rows];
    return inError;
  }

  private prepareForms(): void {
    this.selectionForm = new UntypedFormGroup({ header: new UntypedFormControl(false) });
    this.scanForm = new UntypedFormGroup({ stockEntryId: new UntypedFormControl(null) });
    this.rowsForm = new UntypedFormGroup({});
  }

  private recomputeLinePrices(): void {
    if (!this.rows.length || !this.lines.length || !this.currentPricingGroup) {
      return;
    }

    this.allRows.forEach((row: any) => {
      const quantity: number = this.rowsForm.get(`${row.id}.quantity`)
        ? +this.rowsForm.get(`${row.id}.quantity`).value
        : 1;
      const line: ShipmentLine = this.findShipmentLine(null, row);
      let margin = 0;

      if (
        this.currentPricingGroup.pricingGroupCategoryLinks &&
        this.currentPricingGroup.pricingGroupCategoryLinks.length
      ) {
        margin = this.currentPricingGroup.pricingGroupCategoryLinks.find((obj: PricingGroupCategoryLink) => {
          return obj.itemCategoryId === row.itemCategoryId;
        })?.marginPercent;
      }
      margin = margin ? margin : 0;
      line.unitPrice = RoundingUtil.roundLow(
        new Decimal(row.basePrice ?? 0)
          .plus(new Decimal(row.basePrice ?? 0).times(new Decimal(margin ?? 0).dividedBy(this.PERCENT_VALUE)))
          .toNumber()
      );
      row.totalPrice = RoundingUtil.roundHigh(new Decimal(quantity ?? 0).times(line.unitPrice ?? 0).toNumber());
      row.unitPrice = line.unitPrice;
    });
    this.allRows = [...this.allRows];
  }

  private resetRowsLineNumber(): void {
    this.allRows.forEach((row: any, index: number) => (row.lineNumber = index + 1));
    if (!this.rowsForm.controls.length) {
      this.isEditable = false;
    }
  }

  private resetSelectedRows(): void {
    Object.keys(this.selectionForm.controls).forEach((c: string) => {
      this.selectionForm.get(c).patchValue(false);
    });
    this.onHeaderCheckboxChange();
  }

  private updateFilters(): void {
    this.filterer.updateListFilterOptions(
      "supplierName",
      this.allRows
        .map(row => row.supplierName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(name => {
          return { value: name, displayValue: name };
        })
    );
    this.filterer.updateListFilterOptions(
      "location",
      this.allRows
        .map(row => row.location)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(name => {
          return { value: name, displayValue: name };
        })
    );
    this.filterer.updateListFilterOptions(
      "brandName",
      this.allRows
        .map(row => row.brandName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(name => {
          return { value: name, displayValue: name };
        })
    );
  }
}
