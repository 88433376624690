/* eslint-disable no-magic-numbers */
import { Injectable, OnDestroy } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, timer, Subscription, throwError } from "rxjs";
import { LoaderService } from "./service/loader.service";
import { finalize, catchError, tap } from "rxjs/operators";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor, OnDestroy {
  // Counter of active requests
  private static ACTIVE_REQUESTS: number = 0;
  // Time where the loader is displayed
  private static START_POPUP_TIME: number;
  private static POPUP_TIMER: number = 750;
  private static TIME_BEFORE_POPUP: number = 1500;
  private httpMethod: string;
  private subscriptions: Subscription[] = [];

  constructor(private loaderService: LoaderService) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isUselessRequest(request)) {
      LoaderInterceptor.ACTIVE_REQUESTS++;
    }

    if (LoaderInterceptor.ACTIVE_REQUESTS === 1) {
      this.httpMethod = request.method;
    }

    // If it is the first request, turn on the loader with a delay of 1500 ms
    if (LoaderInterceptor.ACTIVE_REQUESTS >= 1) {
      this.subscriptions.push(this.displayLoader());
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (this.isUselessRequest(request)) {
          return;
        }
        if (LoaderInterceptor.ACTIVE_REQUESTS > 0) {
          LoaderInterceptor.ACTIVE_REQUESTS--;
        }
        if (LoaderInterceptor.ACTIVE_REQUESTS === 0) {
          const elapsedPopupTime = new Date().getTime() - LoaderInterceptor.START_POPUP_TIME;
          // Display at least 750 ms the loader
          if (elapsedPopupTime < LoaderInterceptor.POPUP_TIMER) {
            this.subscriptions.push(
              timer(LoaderInterceptor.POPUP_TIMER - elapsedPopupTime).subscribe(() => {
                this.loaderService.hideLoader();
              })
            );
          } else {
            this.loaderService.hideLoader();
          }
        }
      }),
      catchError(error => {
        if (this.isUselessRequest(request)) {
          return throwError(error);
        }
        if (LoaderInterceptor.ACTIVE_REQUESTS > 0) {
          LoaderInterceptor.ACTIVE_REQUESTS--;
        }
        if (LoaderInterceptor.ACTIVE_REQUESTS === 0) {
          this.loaderService.hideLoader();
        }
        return throwError(error);
      })
    );
  }

  // Display the loader only after 1500 ms
  private displayLoader(): Subscription {
    return timer(LoaderInterceptor.TIME_BEFORE_POPUP)
      .pipe(
        tap(() => {
          if (LoaderInterceptor.ACTIVE_REQUESTS > 0) {
            this.loaderService.showLoader(this.httpMethod);
            LoaderInterceptor.START_POPUP_TIME = new Date().getTime();
          }
        })
      )
      .subscribe();
  }

  private isUselessRequest(request: HttpRequest<any>): boolean {
    return request.url.includes("/task") || request.url.includes("/get-current-tasks");
  }
}
