<ng-container>
  <div class="main-container">
    <div class="body">
      <div class="general-part">
        <div class="general-table">
          <ngx-datatable
            #generalTable
            [rows]="generalRow"
            [trackByProp]="'id'"
            class="material"
            columnMode="flex"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [rowClass]="getRowClass"
            [limit]="15"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
          >
            <ngx-datatable-column
              name="{{ 'metal-account-tab.general-datatable.columns.entity' | translate }}"
              prop="entity"
              cellClass="entity"
              [flexGrow]="1"
              [resizeable]="false"
              [sortable]="false"
            >
            </ngx-datatable-column>

            <ng-container *ngFor="let column of metals">
              <ngx-datatable-column
                name="{{ 'metal-account-tab.general-datatable.columns.balance' | translate: { metal: column.name } }}"
                [prop]="column.name"
                [cellClass]="column.name"
                [flexGrow]="1"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="{{ this.getCellClass(row, column.name) }}">
                    {{
                      row[column.name]
                        ? (row[column.name] | number: "1.0-3":this.locale) + "g"
                        : (null | number: "1.0-3":this.locale)
                    }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>

            <ngx-datatable-column [resizeable]="false" [flexGrow]="1"> </ngx-datatable-column>
            <ngx-datatable-footer> </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>

      <div class="data-part">
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div class="data-table">
          <ngx-datatable
            #dataTable
            [rows]="rows"
            [trackByProp]="'id'"
            class="material"
            columnMode="flex"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [limit]="15"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            (activate)="viewContactMetalAccount($event)"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            [offset]="pageNumber"
            (page)="pageNumber = $event.offset"
          >
            <ngx-datatable-column
              name="{{ this.datatableTitle }}"
              prop="contactName"
              cellClass="contactName"
              [flexGrow]="1"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ng-container *ngFor="let column of metals">
              <ngx-datatable-column
                [name]="column.name"
                [prop]="column.name"
                [cellClass]="column.name"
                [flexGrow]="1"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="{{ this.getCellClass(row, column.name) }}">
                    {{
                      row[column.name] || (!row[column.name] && row[column.name + "_hasMovements"])
                        ? (row[column.name] | number: "1.0-3":this.locale) + "g"
                        : ""
                    }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>

            <ngx-datatable-column
              name="{{ 'metal-account-tab.datatable.columns.action' | translate }}"
              headerClass="center-align"
              [sortable]="false"
              cellClass="center-align"
              [resizeable]="false"
              [flexGrow]="1"
              *ngIf="canCreate"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="green-zone menu-icon" (click)="$event.stopPropagation()">
                  <ui-menu-actions
                    [menuActions]="menuActions"
                    (actionCalled)="manageActions($event, row)"
                  ></ui-menu-actions>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="dataTable.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <!-- Popup -->
    <app-metal-account-popup
      *ngIf="popupVisible"
      [contactId]="contactId"
      [contactType]="contactType"
      [contactMetalAccountList]="contactMetalAccountList"
      (validate)="onValidatePopup()"
      (close)="onClosePopup()"
    ></app-metal-account-popup>
  </div>
</ng-container>
