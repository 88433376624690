<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'promotions-list.title' | translate }}"></ui-title>
    </div>
    <div class="button">
      <ui-button
        text="{{ 'global.new' | translate }}"
        type="add"
        [primary]="true"
        *hasPermission="['PROMOTION_PERIOD_CREATE']"
        (click)="onNewEntityClick()"
      ></ui-button>
    </div>

    <div class="promotions-table">
      <ui-container>
        <div [formGroup]="tableControl">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            class="material"
            columnMode="force"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [limit]="15"
            (activate)="onTableActivate($event)"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'promotions-list.datatable.columns.name' | translate }}"
              prop="name"
              cellClass="name"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'promotions-list.datatable.columns.begin-date' | translate }}"
              prop="beginDate"
              cellClass="begin-date"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <span>{{ row.beginDate | dateTimeFormat: false }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'promotions-list.datatable.columns.end-date' | translate }}"
              prop="endDate"
              cellClass="end-date"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <span>{{ row.endDate | dateTimeFormat: false }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'promotions-list.datatable.columns.stores' | translate }}"
              prop="stores"
              cellClass="ellipsis-counter"
              [flexGrow]="2"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-stores="row.stores">
                <p class="ellipsis">{{ stores.join(", ") }}</p>
                <div *ngIf="stores.length >= 2">
                  <div #storesCounter>
                    <ui-counter [number]="stores.length"></ui-counter>
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="storesCounter">
                    <span style="font-weight: normal !important; white-space: break-spaces">{{
                      stores.join(", ")
                    }}</span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'promotions-list.datatable.columns.activated' | translate }}"
              prop="activated"
              headerClass="center-align"
              cellClass="center-align"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-switch
                    customClass="no-margin"
                    (change)="changeEntityActivation(id)"
                    [formControl]="tableControl.get('activated_' + id)"
                  >
                  </ui-switch>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>
    <form class="promotions-form" [formGroup]="popupForm" novalidate (keydown.enter)="$event.preventDefault()">
      <div class="popup-container">
        <ui-popup title="{{ popupTitle | translate }}" *ngIf="popupVisible" (close)="canClosePopup()">
          <div content>
            <div class="grid-container">
              <div class="grid-item">
                <div class="name">
                  <ui-input-text
                    type="text"
                    label="{{ 'promotions-list.datatable.columns.name' | translate }}"
                    formControlName="name"
                    [status]="popupForm.get('name') | formStatus"
                    [errorMessage]="popupForm.get('name') | formErrorMessage | translate"
                    mandatory="true"
                    infoLabel="{{ 'global.unique' | translate }}"
                  >
                  </ui-input-text>
                </div>
              </div>

              <div class="grid-item date-picker">
                <div class="multi-col">
                  <div class="begin-date">
                    <ui-date-picker
                      label="{{ 'promotions-list.datatable.columns.begin-date' | translate }}"
                      [locale]="locale"
                      [format]="dateFormat"
                      formControlName="beginDate"
                      mandatory="true"
                      [status]="popupForm.get('beginDate') | formStatus"
                      [errorMessage]="popupForm.get('beginDate') | formErrorMessage | translate"
                    >
                    </ui-date-picker>
                  </div>
                  <div class="end-date">
                    <ui-date-picker
                      label="{{ 'promotions-list.datatable.columns.end-date' | translate }}"
                      [locale]="locale"
                      [format]="dateFormat"
                      formControlName="endDate"
                      mandatory="true"
                      [status]="popupForm.get('endDate') | formStatus"
                      [errorMessage]="
                        popupForm.get('endDate')
                          | formErrorMessage
                            : { customPrefix: 'promotions-list.errors.', useCustomPrefixFor: ['badEndDate'] }
                          | translate
                      "
                    >
                    </ui-date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-item">
              <ui-dynamic-filters
                label="{{ 'filters.label' | translate }}"
                [filters]="filterer.getFilters()"
                [(filterValues)]="filterer.filterValues"
                (apply)="applyFilters()"
                applyLabel="{{ 'filters.apply' | translate }}"
                deleteLabel="{{ 'filters.delete' | translate }}"
                placeholderPrefix="{{ 'filters.prefix' | translate }} "
              >
              </ui-dynamic-filters>
            </div>
            <div class="grid-item popup-table">
              <ngx-datatable
                #storesTable
                [rows]="storeRows"
                [trackByProp]="'storeId'"
                [rowClass]="getRowClass"
                class="material"
                columnMode="force"
                headerHeight="auto"
                footerHeight="50"
                rowHeight="40"
                [messages]="{ emptyMessage: 'global.no-data' | translate }"
                [cssClasses]="{
                  sortAscending: 'fas fa-chevron-up',
                  sortDescending: 'fas fa-chevron-down'
                }"
                [externalSorting]="true"
                [sorts]="storeSorts"
                (sort)="changeStoreSortSettings($event.column.prop, $event.newValue)"
                [externalPaging]="true"
                [count]="storePager.totalElements"
                [offset]="storePager.number"
                [limit]="storePager.size"
              >
                <ngx-datatable-column
                  [width]="60"
                  [sortable]="false"
                  [canAutoResize]="false"
                  [draggable]="false"
                  [resizeable]="false"
                >
                  <ng-template ngx-datatable-header-template>
                    <ui-checkbox customClass="no-margin" [formControl]="popupForm.get('storeHeader')"> </ui-checkbox>
                  </ng-template>
                  <ng-template ngx-datatable-cell-template let-link="row">
                    <ui-checkbox
                      customClass="no-margin"
                      [formControl]="popupForm.get('activatedStores').get('activated_' + link.storeId)"
                    >
                    </ui-checkbox>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'promotions-list.datatable.columns.reference' | translate }}"
                  prop="reference"
                  [width]="100"
                >
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'promotions-list.datatable.columns.stores' | translate }}"
                  prop="storeName"
                  [width]="160"
                >
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'promotions-list.datatable.columns.city' | translate }}"
                  prop="city"
                  [width]="120"
                >
                </ngx-datatable-column>

                <ngx-datatable-footer>
                  <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-curPage="curPage"
                    let-offset="offset"
                    let-isVisible="isVisible"
                  >
                    <div class="page-count" *ngIf="rowCount">
                      {{
                        rowCount
                          | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                          | translate: { rowCount: rowCount }
                      }}
                    </div>
                    <datatable-pager
                      [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                      [pagerRightArrowIcon]="'fas fa-chevron-right'"
                      [pagerNextIcon]="'fas fa-chevron-double-right'"
                      [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                      [page]="curPage"
                      [size]="pageSize"
                      [count]="rowCount"
                      [hidden]="!(rowCount / pageSize > 1)"
                      (change)="changePage($event)"
                    >
                    </datatable-pager>
                  </ng-template>
                </ngx-datatable-footer>
              </ngx-datatable>
            </div>
          </div>
          <div footer>
            <div class="align-buttons">
              <div class="button-popup-validate">
                <div class="button">
                  <ui-button
                    text="{{ 'global.validate' | translate }}"
                    type="save"
                    [primary]="true"
                    (click)="submitEntity()"
                  ></ui-button>
                </div>
              </div>
            </div>
          </div>
        </ui-popup>
      </div>
    </form>
  </div>
</ng-container>
