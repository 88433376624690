import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { RetailItemSelectionComponent } from "app/common/retail-item-selection/retail-item-selection.component";
import { BaseStoreListComponent } from "app/store/util/base-store-list-component";
import {
  ItemUniverse,
  StoreModule,
  AbstractItem,
  Pagination,
  StoreModuleEntry,
  ItemGroupService,
  ItemUniverseService,
  RetailItemService,
  StoreModuleService,
  StandardItem,
  ItemStoreModuleLink,
  ItemStatusType,
  PaginatedList,
  Sort,
  ItemGroup,
} from "center-services";
import {
  CommonValidatorsUtil,
  Option,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { ComponentDirty, ErrorUtil, PrecisionUtil } from "generic-pages";
import { EMPTY, NEVER, Observable, of, throwError, zip } from "rxjs";
import { flatMap, tap } from "rxjs/operators";

@Component({
  selector: "app-item-groups-form",
  templateUrl: "./item-groups-form.component.html",
  styleUrls: ["./item-groups-form.component.scss"],
  providers: [SubscriptionService],
})
export class ItemGroupFormComponent implements OnInit, ComponentDirty {
  @ViewChild("table") table: any;

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "status",
      dir: "asc",
    },
    {
      prop: "reference",
      dir: "asc",
    },
  ];
  public title: string;
  public faChevronLeft: IconDefinition = faChevronLeft;
  public form: UntypedFormGroup;
  public itemUniverseOptions: Option[] = [];
  public itemsUniverse: ItemUniverse[] = [];
  public storeModules: StoreModule[] = [];
  public retailItemList: AbstractItem[] = [];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public initialItemGroup: ItemGroup;
  public itemGroup: ItemGroup;
  public shouldClose: boolean = false;
  public showItemSelection: boolean = false;
  public showStoreModuleEntries: boolean = false;
  public itemsInitialSelection: number[] = [];
  public itemsSelection: number[] = [];
  public storeModuleEntries: StoreModuleEntry[] = [];
  public storeModuleInitialEntries: StoreModuleEntry[] = [];
  // Retail item selection columns
  columns: any[] = [
    {
      name: "code",
      flexGrow: 1,
      customClass: "top-align",
    },
    {
      name: "designation",
      flexGrow: 1,
      customClass: "top-align",
    },
    {
      name: "brand",
      flexGrow: 1,
      customClass: "top-align",
    },
    {
      name: "brand-collection",
      flexGrow: 1,
      customClass: "top-align",
    },
    {
      name: "status",
      flexGrow: 1,
      customClass: "top-align",
      uiComponent: "status-label",
    },
  ];
  private digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translateService: TranslateService,
    private itemGroupService: ItemGroupService,
    private itemUniverseService: ItemUniverseService,
    private retailItemService: RetailItemService,
    private storeModuleService: StoreModuleService,
    private location: Location,
    private subscriptionService: SubscriptionService
  ) {}

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  ngOnInit(): void {
    this.title = this.route.snapshot.params.id ? "update" : "new";

    this.prepareForm();

    this.subscriptionService.subs.push(
      zip(
        this.fetchItemUniverse(),
        this.fetchStoreModule(),
        this.fetchItemGroup().pipe(flatMap(() => this.fetchRetailItemList()))
      ).subscribe({
        complete: () => {
          this.initSelectedItems();
          this.initStoreModuleEntries();
          this.initFormData();

          this.resetTableRows();
        },
      })
    );
  }

  prepareForm(): void {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      universe: [null],
    });
  }

  isDirty(): boolean {
    if (!this.itemGroup) {
      return false;
    }

    if (this.shouldClose) {
      return false;
    }

    this.applyModifications();

    if (!this.initialItemGroup.equals(this.itemGroup)) {
      this.shouldClose = true;

      return true;
    }

    return false;
  }

  // manage create or update of an item group
  fetchItemGroup(): Observable<ItemGroup> {
    if (this.route.snapshot.params.id) {
      return this.itemGroupService.get(this.route.snapshot.params.id).pipe(
        tap(
          (itemGr: ItemGroup) => {
            this.itemGroup = new ItemGroup(itemGr);
          },
          () => this.router.navigateByUrl("/settings/item-group-list")
        )
      );
    }

    this.initItemGroup();

    return of(this.itemGroup);
  }

  initItemGroup(): void {
    this.itemGroup = new ItemGroup({
      name: null,
      universeId: null,
      itemLinks: [],
      storeModules: [],
    });
  }

  addRow(retailItem: AbstractItem): void {
    const status = this.translateService.instant(`retail-item-list.datatable.status.${retailItem.status}`);
    const statusClass = this.getStatusClass(retailItem.status);

    const index = this.retailItemList.indexOf(retailItem);
    this.rows.push({
      index,
      id: retailItem.id,
      reference: retailItem.reference,
      name: retailItem.name,
      brand: retailItem.brandName,
      collection: retailItem instanceof StandardItem ? retailItem.brandCollectionName : null,
      status,
      statusClass,
    });
  }

  initFormData(): void {
    this.form.controls.name.setValue(this.itemGroup.name);

    const opt = this.itemUniverseOptions.find((universeOpt: Option) => {
      return universeOpt.id === this.itemGroup.universeId;
    });
    if (opt) {
      this.form.controls.universe.setValue(this.itemGroup.universeId);
    }

    const itemLinksForm = new UntypedFormGroup({});
    this.itemGroup.itemLinks.forEach((itemStoreModuleLink: ItemStoreModuleLink) => {
      itemLinksForm.addControl(
        `${itemStoreModuleLink.itemId}_${itemStoreModuleLink.storeModuleId}`,
        new UntypedFormControl(itemStoreModuleLink.optimalQuantity)
      );
      itemLinksForm.controls[`${itemStoreModuleLink.itemId}_${itemStoreModuleLink.storeModuleId}`].setValidators(
        this.digitValidator
      );
    });

    this.form.setControl("itemLinks", itemLinksForm);

    this.initialItemGroup = new ItemGroup(this.itemGroup);

    this.subscriptionService.subs.push(
      this.form.valueChanges.subscribe(() => {
        this.shouldClose = false;
      })
    );
  }

  initSelectedItems(): void {
    this.itemsSelection = [];
    this.itemGroup.itemLinks.forEach(itemLink => {
      if (!this.itemsSelection.includes(itemLink.itemId)) {
        this.itemsSelection.push(itemLink.itemId);
      }
    });
    this.itemsInitialSelection = [...this.itemsSelection];
  }

  initStoreModuleEntries(): void {
    this.storeModuleEntries = [];
    this.itemGroup.storeModules.forEach(entry => {
      this.storeModuleEntries.push(new StoreModuleEntry(entry));
    });
    this.storeModuleInitialEntries = [...this.storeModuleEntries];
  }

  resetTableRows(): void {
    this.rows = [];
    this.retailItemList.forEach((item: AbstractItem) => {
      this.addRow(item);
    });
    this.rows = [...this.rows];
  }

  applyModifications(): void {
    this.itemGroup.name = this.form.value.name;
    this.itemGroup.universeId = this.form.value.universe;

    this.itemGroup.itemLinks.forEach((itemStoreModuleLink: ItemStoreModuleLink) => {
      const control: UntypedFormControl = (this.form.controls.itemLinks as UntypedFormGroup).controls[
        `${itemStoreModuleLink.itemId}_${itemStoreModuleLink.storeModuleId}`
      ] as UntypedFormControl;
      itemStoreModuleLink.optimalQuantity = control.value ?? 0;
    });
  }

  submitItemGroup(): Observable<ItemGroup> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      throwError(null);
    }

    this.applyModifications();

    if (this.initialItemGroup.equals(this.itemGroup)) {
      return NEVER;
    }

    const action = this.itemGroup && this.itemGroup.id ? "update" : "create";
    return this.itemGroupService[action].call(this.itemGroupService, this.itemGroup).pipe(
      tap(
        (responseGroup: ItemGroup) => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          if (action === "create") {
            this.itemGroup.id = responseGroup.id;
            this.location.replaceState(`/settings/item-group/update/${responseGroup.id}`);
          }

          this.initialItemGroup = new ItemGroup(this.itemGroup);
        },
        error => {
          this.handleApiError(error);
        }
      )
    );
  }

  getStatusClass(status: ItemStatusType): string {
    return `status-${status.toLowerCase()}`;
  }

  fetchItemUniverse(): Observable<ItemUniverse[]> {
    return this.itemUniverseService.getAll().pipe(
      tap(
        (itemsUniverse: ItemUniverse[]) => {
          this.itemsUniverse = itemsUniverse;
          this.itemUniverseOptions = itemsUniverse
            .filter((obj: ItemUniverse) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: ItemUniverse) => new Option(obj.id, obj.name));
          if (this.itemGroup && this.itemGroup.universeId) {
            this.form.controls.universe.setValue(
              this.itemsUniverse.filter((obj: ItemUniverse) => obj.id === this.itemGroup.universeId && !obj.archived)
            );
          }
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("item-group-form.errors.get-universe", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchStoreModule(): Observable<StoreModule[]> {
    return this.storeModuleService.getAll().pipe(
      tap(
        (storeModules: StoreModule[]) => {
          this.storeModules = storeModules.sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("item-group-form.errors.get-store-module", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchRetailItemList(): Observable<PaginatedList<AbstractItem>> {
    const ids: number[] = [];
    for (const link of this.itemGroup.itemLinks) {
      if (ids.indexOf(link.itemId) === -1) {
        ids.push(link.itemId);
      }
    }

    if (!ids.length) {
      return of(null);
    }

    return this.retailItemService
      .getAll(this.pager, this.getSorter(), [new SearchFilter("id", SearchFilterOperator.IN, ids)])
      .pipe(
        tap(
          (result: PaginatedList<AbstractItem>) => {
            this.retailItemList = result.data;
            this.pager = result.page;
          },
          error => {
            const title = this.translateService.instant("message.title.data-errors");
            const content = this.translateService.instant("item-group-form.errors.get-retail-items", {
              message: error.message,
            });
            this.messageService.warn(content, { title });
          },
          () => {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
        )
      );
  }

  handleApiError(error: any): void {
    this.enlightDuplicate(error);
    const attributeTranslations = {
      name: "item-group-form.fields.name",
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  enlightDuplicate(error: any): void {
    if (!error || !error.error || !error.error.exception) {
      return;
    }

    const exception = error.error.exception;

    if (!exception.name || !exception.parameters || !exception.parameters.attribute) {
      return;
    }

    if (exception.name === "DuplicateEntityException" && exception.parameters.attribute === "name") {
      this.form.controls.name.setErrors({
        duplicate: true,
      });
    }
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  back(): void {
    SessionPagination.clear(RetailItemSelectionComponent.LIST_ID);
    SessionPagination.clear(BaseStoreListComponent.LIST_ID);
    this.router.navigateByUrl("/settings/item-groups-list");
  }

  showItemSelectionMethod(): void {
    this.subscriptionService.subs.push(
      this.createItemAndShowPopup().subscribe({
        complete: () => (this.showItemSelection = true),
      })
    );
  }

  showStoreModuleEntriesMethod(): void {
    this.subscriptionService.subs.push(
      this.createItemAndShowPopup().subscribe({
        complete: () => (this.showStoreModuleEntries = true),
      })
    );
  }

  createItemAndShowPopup(): Observable<never> {
    if (this.itemGroup.id) {
      // nothing to check if item group exists
      return EMPTY;
    }

    if (!this.form.controls.name.valid) {
      // show errors if we cannot create
      throwError(null);
    }

    return this.submitItemGroup().pipe(flatMap(() => EMPTY));
  }

  // add/remove product but do not update main table data yet
  validateItemSelection(): void {
    if (!this.itemGroup.id) {
      console.error("Cannot be called on not existing item group");
      return;
    }
    if (
      this.itemsInitialSelection.length === this.itemsSelection.length &&
      [...this.itemsInitialSelection].every(i => this.itemsSelection.includes(i))
    ) {
      return;
    }

    // clear remove items from both itemGroup and initialItemGroup
    // keep form control so that value may no be lost if product is added back
    this.itemGroup.itemLinks = this.itemGroup.itemLinks.filter(i => this.itemsSelection.includes(i.itemId));
    this.initialItemGroup.itemLinks = this.initialItemGroup.itemLinks.filter(i =>
      this.itemsSelection.includes(i.itemId)
    );

    // add new in both itemGroup, initialItemGroup and form control if not existing yet
    this.itemsSelection
      .filter(i => !this.itemsInitialSelection.includes(i))
      .forEach(retailItemId => {
        this.storeModules.forEach(module => {
          const itemLink = new ItemStoreModuleLink();

          // recover optimal quantiy if item was deleted in the same composent lifetime
          let optimalQuantity = 0;
          if ((this.form.controls.itemLinks as UntypedFormGroup).controls[`${retailItemId}_${module.id}`]) {
            optimalQuantity = (this.form.controls.itemLinks as UntypedFormGroup).controls[
              `${retailItemId}_${module.id}`
            ].value;
          }

          itemLink.storeModuleId = module.id;
          itemLink.itemId = retailItemId;
          itemLink.optimalQuantity = optimalQuantity;

          this.itemGroup.itemLinks.push(
            new ItemStoreModuleLink({
              storeModuleId: module.id,
              itemId: retailItemId,
              optimalQuantity,
            })
          );
          this.initialItemGroup.itemLinks.push(
            new ItemStoreModuleLink({
              storeModuleId: module.id,
              itemId: retailItemId,
              optimalQuantity,
            })
          );

          if (!(this.form.controls.itemLinks as UntypedFormGroup).controls[`${retailItemId}_${module.id}`]) {
            (this.form.controls.itemLinks as UntypedFormGroup).addControl(
              `${retailItemId}_${module.id}`,
              new UntypedFormControl(0)
            );
            (this.form.controls.itemLinks as UntypedFormGroup).controls[`${retailItemId}_${module.id}`].setValidators(
              this.digitValidator
            );
          }
        });
      });

    this.subscriptionService.subs.push(
      this.itemGroupService.update.call(this.itemGroupService, this.initialItemGroup).subscribe({
        complete: () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });

          this.itemsInitialSelection = [...this.itemsSelection];
          this.showItemSelection = false;

          this.changePage(1);
        },
        error: error => {
          this.handleApiError(error);
        },
      })
    );
  }

  // add/remove module but do not update main table data yet
  validateStoreModuleEntries(): void {
    if (!this.itemGroup.id) {
      console.error("Cannot be called on not existing item group");
      return;
    }
    if (
      this.storeModuleInitialEntries.length === this.storeModuleEntries.length &&
      [...this.storeModuleInitialEntries].every(i => this.storeModuleEntries.includes(i))
    ) {
      return;
    }

    // only update storeModuleEntries
    this.itemGroup.storeModules = [...this.storeModuleEntries];
    this.initialItemGroup.storeModules = [...this.storeModuleEntries];

    this.subscriptionService.subs.push(
      this.itemGroupService.update.call(this.itemGroupService, this.initialItemGroup).subscribe({
        complete: () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });

          this.storeModuleInitialEntries = [...this.storeModuleEntries];
          this.showStoreModuleEntries = false;
        },
        error: error => {
          this.handleApiError(error);
        },
      })
    );
  }

  closeRetailSelection(): void {
    this.showItemSelection = false;
  }

  closeStoreModulesPopup(): void {
    this.showStoreModuleEntries = false;
  }

  changeSortSettings(prop: string, direction: string): void {
    switch (prop) {
      case "supplier":
        // not implemented yet
        return;
      case "status":
        this.sorts = [
          {
            prop: "status",
            dir: direction,
          },
          {
            prop: "reference",
            dir: direction,
          },
        ];
        break;
      default:
        this.sorts = [
          {
            prop: "status",
            dir: "asc",
          },
          {
            prop,
            dir: direction,
          },
        ];
        break;
    }

    this.changePage({ page: 1 });
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "designation":
        return "name";
      case "code":
        return "reference";
      case "brand":
        return "brandName";
      case "collection":
        return "brandCollectionName";
      default:
        return prop;
    }
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.subscriptionService.subs.push(
      this.fetchRetailItemList().subscribe({
        complete: () => {
          this.resetTableRows();
        },
      })
    );
  }
}
