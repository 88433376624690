<ng-container>
  <form class="sale-terms" [formGroup]="saleTermsForm" novalidate>
    <div class="grid-item info">
      <div *ngIf="this.editedItem && !this.editedItem.canSold">
        <span class="info-icon" #info><fa-icon [icon]="faInfoCircle"></fa-icon></span>
        <ui-tooltip maxWidth="100px" [element]="info"
          ><span>{{ "item.sale-terms.info" | translate }}</span></ui-tooltip
        >
      </div>
      <div class="field">
        <ui-input-text type="text" label="{{ 'item.sale-terms.reference' | translate }}" formControlName="reference">
        </ui-input-text>
      </div>
    </div>
    <div class="grid-item" *ngIf="this.editedItem?.type === 'STANDARD'">
      <ui-switch label="{{ 'item.sale-terms.tph' | translate }}" formControlName="tph" mandatory="true"> </ui-switch>
    </div>
    <div class="grid-item">
      <div class="multi-col">
        <div class="price-and-currency">
          <div class="price">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigitHigh"
              label="{{ 'item.sale-terms.sell-price' | translate }}"
              [thousandSeparator]="' '"
              formControlName="sellPrice"
              [status]="saleTermsForm.get('sellPrice') | formStatus"
              [errorMessage]="saleTermsForm.get('sellPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
              mandatory="true"
            >
            </ui-input-text>
          </div>
          <div class="currency">
            <ui-list
              [options]="this.currencyOptions"
              formControlName="sellCurrency"
              [status]="saleTermsForm.get('sellCurrency') | formStatus"
              [errorMessage]="saleTermsForm.get('sellCurrency') | formErrorMessage | translate"
              mandatory="true"
            >
            </ui-list>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        [maskPattern]="decimalDigitLow"
        label="{{ 'item.sale-terms.sales-rate' | translate }}"
        formControlName="salesRate"
        suffix=" %"
        [thousandSeparator]="' '"
        [status]="saleTermsForm.get('salesRate') | formStatus"
        [errorMessage]="
          saleTermsForm.get('salesRate')
            | formErrorMessage: { customPrefix: 'item.sale-terms.errors.', useCustomPrefixFor: ['min', 'max'] }
            | translate: { digit: LOW_INTEGER }
        "
      >
      </ui-input-text>
    </div>
  </form>
</ng-container>
