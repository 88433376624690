import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import { tap } from "rxjs/operators";
import { combineLatest, Observable, of } from "rxjs";
import {
  StockEntryLocation,
  StockEntry,
  Uom,
  StockEntryService,
  UomService,
  StockMovement,
  MovementType,
} from "center-services";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "stock-entry-detail-popup",
  templateUrl: "./stock-entry-detail.component.html",
  styleUrls: ["./stock-entry-detail.component.scss"],
  providers: [SubscriptionService],
})
export class StockEntryDetailComponent implements OnChanges, OnInit {
  @Input() minWidth: string = "45vw";
  @Input() maxWidth: string = "50vw";
  @Input() paneVisible: boolean = false;
  @Input() selectedStockEntryLocation: StockEntryLocation;

  @Output() close: EventEmitter<any> = new EventEmitter();

  @ViewChild("table") table: DatatableComponent;

  public loadedStockEntry: StockEntry;
  public tableControl: UntypedFormGroup;
  public units: Uom[] = [];
  public uomName: string = "";
  public stockForm: UntypedFormControl;
  public dateFormat: string;
  public locale: string;
  public title: string;
  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "date",
      dir: "desc",
    },
  ];
  private showAllMovements: boolean = false;
  private initObservables: Observable<any>[] = [];

  constructor(
    private stockEntryService: StockEntryService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private uomService: UomService,
    private subscriptionService: SubscriptionService
  ) {
    this.stockForm = new UntypedFormControl(false);
    this.tableControl = new UntypedFormGroup({});
  }

  public ngOnInit(): void {
    this.prepareStockForm();
  }

  public ngOnChanges(): void {
    this.initObservables = [];
    this.initObservables.push(this.fetchUnitOfMeasure());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.populateRows();
      })
    );
  }

  fetchUnitOfMeasure(): Observable<Uom> {
    if (this.selectedStockEntryLocation !== null) {
      return this.uomService.get(this.selectedStockEntryLocation.stockEntry.uomId).pipe(
        tap(
          (unit: Uom) => {
            this.uomName = unit.shortName;
          },
          error => {
            const title = this.translateService.instant("message.title.data-errors");
            const content = this.translateService.instant("uoms-list.errors.get-entities", { message: error.message });
            this.messageService.warn(content, { title });
          }
        )
      );
    }
    return of(null);
  }

  getRowClass(row: any): any {
    return { "not-clickable": true, disabled: row.isCounterparty };
  }

  changeSortSettings(prop: string, direction: string): void {
    this.sorts = [
      {
        prop,
        dir: direction,
      },
    ];

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  closePane(): void {
    this.close.emit("close");
    this.paneVisible = false;
    this.loadedStockEntry = null;
    this.selectedStockEntryLocation = null;
  }

  private populateRows(): void {
    if (this.selectedStockEntryLocation) {
      this.subscriptionService.subs.push(
        this.stockEntryService.get(this.selectedStockEntryLocation.stockEntry.id).subscribe(
          (stockEntry: StockEntry) => {
            this.rows = [];
            this.loadedStockEntry = stockEntry;

            if (this.loadedStockEntry.stockType === "SERIAL_NUMBER") {
              this.title = this.translateService.instant("stock.stock-entry-detail.serialNumber", {
                stockEntryId: this.loadedStockEntry.id,
              });
            } else if (this.loadedStockEntry.stockType === "BATCH") {
              this.title = this.translateService.instant("stock.stock-entry-detail.batchNumber", {
                stockEntryId: this.loadedStockEntry.id,
              });
            } else {
              this.title = this.translateService.instant("stock.stock-entry-detail.bulk");
            }

            if (this.loadedStockEntry.movements) {
              this.loadedStockEntry.movements.forEach(movement => {
                this.addRow(movement);
              });
            }
          },
          error => {
            this.translateService
              .get("brands-list.errors.get-brands", { message: error.message })
              .subscribe((res: string) => {
                const title = this.translateService.instant("message.title.data-errors");
                this.messageService.warn(res, { title });
              });
          },
          () => {
            this.rows = [...this.rows];
          }
        )
      );
    }
  }

  private prepareStockForm(): void {
    this.subscriptionService.subs.push(
      this.stockForm.valueChanges.subscribe(value => {
        this.showAllMovements = value;
        this.populateRows();
      })
    );
  }

  private addRow(movement: StockMovement): void {
    const isCounterparty = this.isCounterpartyMovement(movement);
    if (
      movement.sourceLocationId === this.selectedStockEntryLocation.locationId ||
      movement.destLocationId === this.selectedStockEntryLocation.locationId
    ) {
      if (isCounterparty && !this.showAllMovements) {
        return;
      }

      const typeClass = this.getMovementTypeClass(movement.type);
      let quantity = movement.quantity;
      let quantityClass = "";

      if (
        movement.type === MovementType.OUT ||
        (movement.type === MovementType.INTERNAL &&
          movement.sourceLocationId === this.selectedStockEntryLocation.locationId)
      ) {
        if (!isCounterparty) {
          quantityClass = "red";
        }
        quantity *= -1;
      }

      const row = {
        quantity,
        typeClass,
        quantityClass,
        isCounterparty,
        id: movement.id,
        date: movement.date,
        comment: movement.comment,
        movementType: movement.type,
        operatorCode: movement.creatorUserNumber,
        sourceLocation: movement.sourceLocationName,
        destinationLocation: movement.destLocationName,
        weight: movement.weight,
        tare: movement.tare,
      };

      this.rows.push(row);
    }
  }

  private isCounterpartyMovement(movement: StockMovement): boolean {
    return (
      (movement.type === MovementType.IN && movement.sourceLocationId === this.selectedStockEntryLocation.locationId) ||
      (movement.type === MovementType.OUT && movement.destLocationId === this.selectedStockEntryLocation.locationId)
    );
  }

  private getMovementTypeClass(stockMovementType: string): string {
    switch (stockMovementType) {
      case MovementType.IN:
        return "in";
      case MovementType.OUT:
        return "out";
      case MovementType.INTERNAL:
        return "internal";
      default:
        console.error(`${stockMovementType} is an unknown movement type !`);
        return "#FFF";
    }
  }
}
