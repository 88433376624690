<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'stores-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-create">
        <ui-button
          type="add"
          text="{{ 'global.new' | translate }}"
          *hasPermission="['STORE_CREATE']"
          [primary]="true"
          (click)="createStore()"
        ></ui-button>
      </div>
      <div class="button-export">
        <ui-button
          type="export"
          text="{{ (activeFilters.length === 0 ? 'global.export.all' : 'global.export.select') | translate }}"
          *hasPermission="['STORE_READ']"
          (click)="exportStores()"
        ></ui-button>
      </div>
      <div class="button-import">
        <ui-button
          type="import"
          text="{{ 'global.import' | translate }}"
          *hasPermission="['STORE_CREATE']"
          (click)="importStores()"
        ></ui-button>
      </div>
    </div>
    <div class="stores-list">
      <ui-container>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            applyLabel="{{ 'filters.apply' | translate }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div class="stores-table">
          <div [formGroup]="tableControl">
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material overflow"
              columnMode="flex"
              headerHeight="auto"
              footerHeight="50"
              rowHeight="40"
              (activate)="updateStore($event)"
              [externalSorting]="true"
              [externalPaging]="true"
              [count]="pager.totalElements"
              [offset]="pager.number"
              (page)="pager.number = $event.offset"
              [limit]="pager.size"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down'
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                name="{{ 'stores-list.datatable.columns.reference' | translate }}"
                prop="reference"
                cellClass="reference"
                [flexGrow]="1"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stores-list.datatable.columns.name' | translate }}"
                prop="name"
                cellClass="name"
                [flexGrow]="1"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stores-list.datatable.columns.city' | translate }}"
                prop="city"
                [flexGrow]="1"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-city="row.city">
                  <div class="city">{{ city }}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stores-list.datatable.columns.pricing-groups' | translate }}"
                prop="pricingGroup.name"
                [flexGrow]="1"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-pricingGroup="row.pricingGroup">
                  <div class="pricing-group">{{ pricingGroup.name }}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stores-list.datatable.columns.status' | translate }}"
                prop="statusIndex"
                [flexGrow]="1"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-status="row.status">
                  <ui-status-label
                    [label]="'store.general-datas.status-options.' + status | translate"
                    [customClass]="'status-' + (status | lowercase)"
                  >
                  </ui-status-label>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stores-list.datatable.columns.main-store' | translate }}"
                prop="mainStore"
                cellClass="main-store center-align"
                [flexGrow]="0.5"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template ngx-datatable-cell-template let-id="row.id">
                  <div class="green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onRowCheckBoxChange(id)"
                      [formControl]="tableControl.get('activated_' + id)"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stores-list.datatable.columns.tags' | translate }}"
                cellClass="ellipsis-counter"
                [flexGrow]="1"
                [resizeable]="false"
                [sortable]="false"
                prop="tags"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-tags="row.tags">
                  <div class="tags-content">
                    <div *ngFor="let tag of tags">
                      <ui-tag [label]="tag.label" [color]="tag.color" customClass="blackLabel"></ui-tag>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [flexGrow]="0.5" [resizeable]="false" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row" let-tags="row.tags">
                  <div *ngIf="tags?.length >= 2">
                    <div #tagsCounter>
                      <ui-counter [number]="tags.length"></ui-counter>
                    </div>
                    <ui-tooltip [element]="tagsCounter">
                      <span *ngFor="let tag of tags" style="font-weight: normal !important"
                        ><ui-tag [label]="tag.label" [color]="tag.color" customClass="blackLabel"></ui-tag
                      ></span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>
    </div>
  </div>
</ng-container>
