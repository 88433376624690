<ui-popup title="{{ 'credit-note-validation-popup.title' | translate }}" (close)="closePopup()">
  <div content>
    <div class="warning-content">
      <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
      <div>
        <strong>{{ "disable-store-warning-popup.warning-label" | translate }}</strong>
        <div>{{ "credit-note-validation-popup.warning" | translate }}</div>
      </div>
    </div>
  </div>
  <div class="strong-content" content>
    <strong>{{ "credit-note-validation-popup.question" | translate }}</strong>
  </div>
  <div footer>
    <div class="align-buttons">
      <div class="button-popup-validate">
        <div class="button">
          <ui-button
            text="{{ 'credit-note-validation-popup.yes' | translate }}"
            [primary]="true"
            type="validate"
            (click)="validateCreditNote()"
          ></ui-button>
        </div>
      </div>
      <div class="button-popup-refuse">
        <div class="button">
          <ui-button
            text="{{ 'credit-note-validation-popup.no' | translate }}"
            type="cancel"
            (click)="closePopup()"
          ></ui-button>
        </div>
      </div>
    </div>
  </div>
</ui-popup>
