<form class="metal-account-form" [formGroup]="popupForm" novalidate>
  <div class="popup-container">
    <ui-popup title="{{ popupTitle | translate }}" (close)="canClosePopup()">
      <div content>
        <div class="grid-container">
          <div class="grid-item">
            <ui-list
              label="{{ 'metal-account-movement.fields.metal' | translate }}"
              [options]="this.metalAccountOptions"
              formControlName="metalAccountId"
              [status]="popupForm.get('metalAccountId') | formStatus"
              [errorMessage]="popupForm.get('metalAccountId') | formErrorMessage | translate"
              mandatory="true"
            >
            </ui-list>
          </div>
          <div class="grid-item">
            <ui-date-picker
              label="{{ 'metal-account-movement.fields.date' | translate }}"
              [locale]="locale"
              [format]="dateFormat"
              formControlName="date"
              mandatory="true"
              [status]="popupForm.get('date') | formStatus"
              [errorMessage]="popupForm.get('date') | formErrorMessage | translate"
            >
            </ui-date-picker>
          </div>
          <div class="grid-item adjust">
            <ui-radio-button
              label="{{ 'metal-account-movement.fields.type' | translate }}"
              [options]="this.typeOptions"
              name="type"
              direction="horizontal"
              formControlName="type"
            ></ui-radio-button>
          </div>

          <div
            class="grid-item"
            *ngIf="this.popupForm.value.metalAccountId !== null && internalSelected(this.popupForm.value.type)"
          >
            <ui-list
              label="{{ 'metal-account-movement.fields.recipient' | translate }}"
              [status]="popupForm.get('recipient') | formStatus"
              [errorMessage]="popupForm.get('recipient') | formErrorMessage | translate"
              formControlName="recipient"
              [options]="options"
              [search]="true"
              mandatory="true"
            ></ui-list>
          </div>

          <div class="grid-item">
            <ui-input-text
              type="text"
              label="{{ 'metal-account-movement.fields.order-number' | translate }}"
              formControlName="orderNumber"
            >
            </ui-input-text>
          </div>
          <div class="grid-item">
            <ui-input-text
              type="text"
              label="{{ 'metal-account-movement.fields.document-number' | translate }}"
              formControlName="documentNumber"
            >
            </ui-input-text>
          </div>
          <div class="grid-item">
            <ui-input-text
              suffix=" g"
              type="text"
              [maskPattern]="decimalDigit"
              [mandatory]="true"
              thousandSeparator=" "
              formControlName="quantity"
              label="{{ 'metal-account-movement.fields.quantity' | translate }} (g)"
              [status]="popupForm.get('quantity') | formStatus"
              [errorMessage]="popupForm.get('quantity') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
            ></ui-input-text>
          </div>
          <div class="grid-item">
            <ui-textarea
              label="{{ 'metal-account-movement.fields.comment' | translate }}"
              formControlName="comment"
              customClass="no-margin"
              maxLength="500"
            >
            </ui-textarea>
          </div>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'global.validate' | translate }}"
                [disabled]="validateBtnDisabled"
                type="save"
                [primary]="true"
                (click)="submitMovement()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</form>
