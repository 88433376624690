<ng-container>
  <div class="invoice-supplier-initiator-popup-container" [formGroup]="popupForm" novalidate>
    <ui-popup title="{{ popupTitle | translate }}" (close)="closePopup()">
      <div content>
        <div *ngIf="!this.activeTab">
          <div class="supplier" *ngIf="!this.supplierId && !this.currency">
            <ui-list
              label="{{ 'invoice-supplier-initiator-popup.labels.supplier' | translate }}"
              [status]="popupForm.get('supplierId') | formStatus"
              [errorMessage]="popupForm.get('supplierId') | formErrorMessage | translate"
              [options]="supplierOptions"
              formControlName="supplierId"
              [mandatory]="true"
              [search]="true"
            ></ui-list>
          </div>

          <div class="currency" *ngIf="!this.supplierId && !this.currency">
            <ui-list
              label="{{ 'invoice-supplier-initiator-popup.labels.currency' | translate }}"
              [status]="popupForm.get('currencyId') | formStatus"
              [errorMessage]="popupForm.get('currencyId') | formErrorMessage | translate"
              [options]="currencyOptions"
              formControlName="currencyId"
              [mandatory]="true"
            >
            </ui-list>
          </div>

          <div class="data-type-label">
            {{ "invoice-supplier-initiator-popup.labels.data-type" | translate }}<span> *</span>
          </div>

          <div class="data-type">
            <ui-radio-button
              [options]="this.dataTypeOptions"
              name="dataType"
              formControlName="dataType"
              mandatory="true"
            ></ui-radio-button>
          </div>

          <app-invoice-supplier-delivery-selection
            *ngIf="this.popupForm.controls.dataType.value === 0"
            [supplierId]="this.supplierId ? this.supplierId : this.popupForm.controls.supplierId.value"
            [currency]="getCurrencyToPass()"
            [supplierList]="this.supplierList"
            [deliveryFormIds]="this.deliveryFormIds"
            [(selectedDeliveryForms)]="this.selectedDeliveryForms"
            (selectedDeliveryFormsChange)="onSelectedDFChange(selectedDeliveryForms)"
          ></app-invoice-supplier-delivery-selection>
        </div>

        <div class="pm-selection" *ngIf="this.popupForm.controls.dataType.value === 1">
          <div class="delivery-ref">
            <ui-input-text
              type="text"
              label="{{ 'invoice-supplier-initiator-popup.labels.delivery-ref' | translate }}"
              formControlName="deliveryRef"
              [status]="popupForm.get('deliveryRef') | formStatus"
              [errorMessage]="
                popupForm.get('deliveryRef')
                  | formErrorMessage
                    : { customPrefix: 'invoice-supplier-initiator-popup.errors.', useCustomPrefixFor: ['unique'] }
                  | translate
              "
              mandatory="true"
              infoLabel="{{ 'global.unique' | translate }}"
            >
            </ui-input-text>
          </div>
        </div>

        <app-invoice-supplier-pm-selection
          *ngIf="this.popupForm.controls.dataType.value === 1 || this.activeTab"
          [supplierId]="this.supplierId ? this.supplierId : this.popupForm.controls.supplierId.value"
          [currency]="getCurrencyToPass()"
          [(selectedPMs)]="this.selectedPMs"
          (selectedPMsChange)="onSelectedPMChange(selectedPMs)"
        ></app-invoice-supplier-pm-selection>
      </div>
      <div footer>
        <div class="button-popup-to-invoice">
          <ui-button
            text="{{ this.getButtonText() }}"
            [primary]="true"
            (click)="submit()"
            [btnIcon]="faArrowCircleRight"
          ></ui-button>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
