import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { Filterer, FilterValue, PaginableComponent, SessionPagination, SubscriptionService } from "fugu-common";
import { Light, LightService, CaraUserService } from "center-services";
import { DatatableComponent } from "@siemens/ngx-datatable";

@Component({
  selector: "app-brand-suppliers",
  templateUrl: "./brand-suppliers.component.html",
  styleUrls: ["./brand-suppliers.component.scss"],
  providers: [SubscriptionService],
})
export class BrandSuppliersComponent
  extends FilteredTableListComponent
  implements OnInit, OnChanges, AfterViewChecked, PaginableComponent {
  public static LIST_ID: string = "app-brand-suppliers.suppliers-table";

  @Input() canUpdate: boolean = true;
  @Input() canAdd: boolean = true;

  @ViewChild("table") table: DatatableComponent;
  @Input() supplierIds: number[];

  @Output() supplierIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  public tableControl: UntypedFormGroup;
  public rows: any[] = [];
  public allRows: any[] = [];
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "name",
      dir: "asc",
    },
  ];

  public pageNumber: number = 0;

  public supplierList: Light[] = [];
  public filterer: Filterer;
  private sessionPagination: SessionPagination;

  constructor(
    translateService: TranslateService,
    private lightService: LightService,
    messageService: MessageService,
    userService: CaraUserService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pageNumber;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pageNumber = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
    this.applyFilters();
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(BrandSuppliersComponent.LIST_ID);
  }

  ngOnInit(): void {
    if (!this.tableControl) {
      this.tableControl = new UntypedFormGroup({});
    }
    this.initFilters();
    this.fetchSupplierList();
    this.sessionPagination.loadFromSession(BrandSuppliersComponent.LIST_ID);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.supplierIds && !changes.supplierIds.firstChange) {
      this.fetchSupplierList();
      this.applyFilters();
    }
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  addRow(supplier: Light): void {
    const activated = this.supplierIds.includes(supplier.id);

    this.allRows.push({
      id: supplier.id,
      name: supplier.name,
      activated,
    });

    if (this.tableControl.controls[`activated_${supplier.id}`]) {
      this.tableControl.controls[`activated_${supplier.id}`].patchValue(activated);
    } else {
      this.tableControl.addControl(
        `activated_${supplier.id}`,
        new UntypedFormControl({ value: activated, disabled: !this.canUpdate })
      );
    }
  }

  fetchSupplierList(): void {
    this.allRows = [];
    this.rows = [];

    this.subscriptionService.subs.push(
      this.lightService.getSuppliers().subscribe(
        (supplierList: Light[]) => {
          supplierList.forEach((supplier: Light) => {
            if (supplier.archived && this.supplierIds.includes(supplier.id)) {
              this.supplierIds.splice(this.supplierIds.indexOf(supplier.id), 1);
              this.supplierIds = [...this.supplierIds];
              this.supplierIdsChange.emit(this.supplierIds);
            }
          });

          this.supplierList = supplierList.filter(supplier => !supplier.archived);
          this.rows = [];

          this.supplierList.forEach(supplier => {
            this.addRow(supplier);
          });
          this.rows = [...this.allRows];
          if (this.filterer.filterValues.length > 0) {
            this.applyFilters();
          }
        },
        error => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("brand-suppliers-list.errors.get-suppliers", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  checkboxOnChanges(index: number): void {
    if (!this.canUpdate) {
      return;
    }
    const rowIndex = this.supplierIds.findIndex(supplierId => supplierId === index);
    const activated = this.tableControl.controls[`activated_${index}`].value;

    if (!activated && rowIndex !== -1) {
      this.supplierIds.splice(rowIndex, 1);
    }
    if (activated && rowIndex === -1) {
      this.supplierIds.push(index);
    }
    this.supplierIds = [...this.supplierIds];
    this.supplierIdsChange.emit(this.supplierIds);
  }

  viewSupplier(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("SUPPLIER_UPDATE")) {
        return;
      }
      const filteredList = this.supplierList.filter(supplier => supplier.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find supplier with id ${event.row.id}`);
        return;
      }
      this.router.navigateByUrl(`/supplier/update/${event.row.id}`);
    }
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === BrandSuppliersComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "name",
      this.translateService.instant("brand-suppliers-list.datatable.columns.name"),
      "string"
    );

    this.filterer.addBooleanFilter(
      "activated",
      this.translateService.instant("brand-suppliers-list.datatable.columns.activated"),
      false,
      false,
      true
    );
  }

  applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);
    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        BrandSuppliersComponent.LIST_ID
      ).subscribe()
    );
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }
    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }
}
