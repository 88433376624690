import { Flux } from "center-services";

export class FluxRow {
  type: string;
  objectLinkedReference: string;
  id: number;
  action: string;
  field: string;
  oldValue: string | Date;
  newValue: string | Date;

  constructor(flux: Flux) {
    this.type = flux?.type;
    this.action = flux?.action;
    this.field = flux?.data;
    this.newValue = this.parseDate(flux?.newValue);
    this.oldValue = this.parseDate(flux?.oldValue);
    this.id = flux?.objectId;
    this.objectLinkedReference = flux?.objectLinkedReference;
  }

  private parseDate(value: string): string | Date {
    const dateFormat = /^[A-Z][a-z]{2} [A-Z][a-z]{2} \d{2} \d{2}:\d{2}:\d{2} UTC \d{4}$/;
    if (dateFormat.test(value)) {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    return value;
  }
}
