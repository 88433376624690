<section>
  <div *ngIf="showSidebar" class="sidebar-container">
    <ui-menu-v2
      [logoMiniSrc]="appMiniLogoSrc"
      [groupTitleTemplates]="{ userSettingsGroupTitle: userSettingsGroupTitle }"
      [logoSrc]="appLogoSrc"
      (logoClick)="onLogoClick()"
      [storeTemplate]="store"
      [footerTemplate]="footer"
      [context]="menuContext"
      [items]="menuItems"
      [miniMenu]="false"
    >
      <ng-template #footer let-miniMenu="miniMenu">
        <div class="footer">
          <span *ngIf="!miniMenu" class="footer-name">Neo-Retail</span>
          <ng-container *ngIf="!miniMenu">&nbsp;</ng-container>
          <span class="footer-version">v{{ appVersion }}</span>
        </div>
      </ng-template>

      <ng-template #store let-miniMenu="miniMenu">
        <div
          #selectedStoreLabel
          *ngIf="currentUser"
          [class]="
            'menu-store user-menu-title' +
            (miniMenu ? ' shop-menu-mini' : '') +
            (this.filteredStores.length > 1 ? ' pointer' : '')
          "
          (click)="openStoreSelectionPopup()"
        >
          <ui-bubble
            [class.menu-bubble]="!miniMenu"
            [icon]="selectedStore?.mainStore ? faBuilding : faStore"
            size="xs"
            [darker]="true"
            (click)="openStoreSelectionPopup()"
          ></ui-bubble>
          <div *ngIf="!miniMenu && selectedStore" class="user-name">
            {{ selectedStore.reference }} - {{ selectedStore.storeName }}
            <ui-tooltip maxWidth="300px" [element]="selectedStoreLabel">
              {{ selectedStore.reference }} - {{ selectedStore.storeName }}
            </ui-tooltip>
          </div>
          <div [class.pen]="!miniMenu" *ngIf="this.filteredStores.length > 1">
            <fa-icon class="center-icon" [icon]="faPen" size="xs"></fa-icon>
          </div>
        </div>
        <app-store-selection-popup
          *ngIf="storeSelectionPopupVisible"
          [user]="currentUser"
          (close)="onCloseStoreSelectionPopup()"
        ></app-store-selection-popup>
      </ng-template>

      <ng-template #userSettingsGroupTitle let-miniMenu="miniMenu">
        <div [class]="'user-menu-title' + (miniMenu ? ' user-menu-title-mini' : '')">
          <ui-bubble
            [class.menu-bubble]="!miniMenu"
            [char]="currentUser.lastName.charAt(0).toUpperCase()"
            size="xs"
          ></ui-bubble>
          <div *ngIf="!miniMenu" class="user-name">{{ currentUser.lastName }} {{ currentUser.firstName }}</div>
        </div>
      </ng-template>
    </ui-menu-v2>
  </div>

  <div [class]="'page-container ' + (!showSidebar ? 'full' : '')">
    <router-outlet></router-outlet>
  </div>
</section>

<app-loader></app-loader>

<div class="message-and-notification">
  <ui-message-handler></ui-message-handler>
  <div class="notification"><app-notification-handler></app-notification-handler></div>
</div>

<app-contact-support-popup
  *ngIf="showContactSupportPopup"
  (closePopupEvent)="showHideContactSupportPopup()"
></app-contact-support-popup>
