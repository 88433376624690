<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'profile-management.title' | translate }}"></ui-title>
    </div>

    <!-- part 1: theme -->
    <ui-container>
      <div class="theme-content">
        <div class="bottom-bordered">
          <div class="item-title">{{ "profile-management.theme.title" | translate }}</div>
          <div class="description">{{ "profile-management.theme.description" | translate }}</div>
          <div class="item-data">
            <div class="theme-buttons">
              <ui-button
                text="{{ 'profile-management.buttons.button-light-theme' | translate }}"
                [btnIcon]="faSunBright"
                [primary]="isLightTheme()"
                (click)="changeTheme('light')"
                customClass="light-theme-button"
              >
              </ui-button>

              <ui-button
                text="{{ 'profile-management.buttons.button-dark-theme' | translate }}"
                [primary]="!isLightTheme()"
                [btnIcon]="faMoon"
                (click)="changeTheme('dark')"
                customClass="dark-theme-button"
              >
              </ui-button>
            </div>
          </div>
        </div>
      </div>

      <!-- part 2: password -->
      <div class="password-management-content">
        <form class="password-form" [formGroup]="passwordForm" novalidate>
          <div class="bottom-bordered">
            <div class="password-content">
              <div class="item-title">{{ "profile-management.password.title" | translate }}</div>
              <div class="description">{{ "profile-management.password.description" | translate }}</div>

              <div class="current-password">
                <ui-input-text
                  label="{{ 'profile-management.password.input-label.current-password' | translate }}"
                  [infoLabel]="infoLabel"
                  type="password"
                  formControlName="currentPassword"
                  status="{{ passwordForm.get('currentPassword') | formStatus }}"
                  errorMessage="{{
                    passwordForm.get('currentPassword')
                      | formErrorMessage
                        : {
                            customPrefix: 'profile-management.password.error.',
                            useCustomPrefixFor: ['incorrectCurrentPassword']
                          }
                      | translate: { symbols: symbols }
                  }}"
                  autocomplete="new-password"
                  [mandatory]="true"
                >
                </ui-input-text>
              </div>

              <div class="new-password">
                <ui-input-text
                  #passwordField
                  label="{{ 'profile-management.password.input-label.new-password' | translate }}"
                  [infoLabel]="infoLabel"
                  type="password"
                  formControlName="newPassword"
                  (change)="shouldCheckNewPassword()"
                  status="{{ passwordForm.get('newPassword') | formStatus }}"
                  errorMessage="{{
                    passwordForm.get('newPassword')
                      | formErrorMessage
                        : { customPrefix: 'user-form.errors.', useCustomPrefixFor: ['minlength', 'badPasswordFormat'] }
                      | translate: { symbols: symbols }
                  }}"
                  autocomplete="new-password"
                  [mandatory]="true"
                >
                </ui-input-text>
                <ui-tooltip
                  *ngIf="newPassword"
                  eventMode="focus"
                  placement="right"
                  fallbackPlacement="left"
                  maxWidth="300px"
                  [element]="newPassword"
                >
                  <span class="password-popper">
                    <div *ngFor="let item of passwordConstraints | keyvalue" [class]="getValidatorClass(item.value)">
                      <fa-icon [icon]="item.value ? faCheck : faTimes"> </fa-icon>
                      {{ "user-form.password-validators." + item.key | translate }}
                    </div>
                  </span>
                </ui-tooltip>
              </div>

              <div class="new-password-to-confirm">
                <ui-input-text
                  label="{{ 'profile-management.password.input-label.new-password-to-confirm' | translate }}"
                  [infoLabel]="infoLabel"
                  type="password"
                  formControlName="newPasswordConfirmed"
                  (change)="shouldCheckNewPassword()"
                  status="{{ passwordForm.get('newPasswordConfirmed') | formStatus }}"
                  errorMessage="{{
                    passwordForm.get('newPasswordConfirmed')
                      | formErrorMessage
                        : { customPrefix: 'user-form.errors.', useCustomPrefixFor: ['minlength', 'notEquivalent'] }
                      | translate: { symbols: symbols }
                  }}"
                  autocomplete="new-password"
                  [mandatory]="true"
                >
                </ui-input-text>
              </div>

              <div class="validate-button">
                <div class="button-password">
                  <ui-button
                    text="{{ 'profile-management.buttons.password-message' | translate }}"
                    customClass="password-color"
                    [primary]="false"
                    [btnIcon]="faUserLock"
                    (click)="submitPassword()"
                  ></ui-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- part 3: language -->
      <div class="language-management-content">
        <form class="language-form" [formGroup]="languageForm" novalidate>
          <div class="bottom-bordered">
            <div class="language-content">
              <div class="item-title">{{ "profile-management.language.title" | translate }}</div>

              <div class="description">{{ "profile-management.language.flag.description" | translate }}</div>

              <div class="code-language">
                <div class="form-line form-radio">
                  <ui-country-radio-button
                    name="codeLanguage"
                    label="{{ 'employees-popup.fields.code-language' | translate }}"
                    mandatory="true"
                    [options]="codeLangOptions"
                    [flags]="countryFlags"
                    formControlName="codeLanguage"
                    customClass="no-margin"
                  >
                  </ui-country-radio-button>
                </div>
              </div>

              <div class="date-format">
                <div class="date-format-description">
                  {{ "profile-management.language.date-format.description" | translate }}
                </div>
                <div class="date-format-list">
                  <ui-list
                    label="{{ 'user-form.fields.date-format' | translate }}"
                    selectLabel="{{ 'user-form.form.date-format' | translate }}"
                    [options]="this.dateFormatOptions"
                    formControlName="dateFormat"
                    status="{{ languageForm.get('dateFormat') | formStatus }}"
                    errorMessage="{{ languageForm.get('dateFormat') | formErrorMessage | translate }}"
                    mandatory="true"
                  >
                  </ui-list>
                </div>
              </div>
              <div class="language-validate-button">
                <div class="button">
                  <ui-button
                    text="{{ 'profile-management.buttons.language-message' | translate }}"
                    type="validate"
                    [primary]="false"
                    [btnIcon]="faUserLock"
                    (click)="submitLanguage()"
                  ></ui-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ui-container>
  </div>
</ng-container>
