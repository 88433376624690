import { ReceptionType } from "center-services";
import { Comparator } from "fugu-common";

export class AppConfigForm extends Comparator {
  receptionType: ReceptionType;
  stockEntryLabelId: number;
  conformityActivationDate: Date;

  constructor(values: any = {}) {
    super();
    this.receptionType = values.receptionType;
    this.stockEntryLabelId = values.stockEntryLabelId;
    this.conformityActivationDate = values.conformityActivationDate
      ? new Date(values.conformityActivationDate)
      : undefined;
  }

  public isConform(): boolean {
    return this.conformityActivationDate !== undefined;
  }
}
