import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { IconDefinition, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  ReceivingForm,
  AbstractReceivingLine,
  PurchaseOrderLine,
  Supplier,
  Store,
  Alloy,
  Metal,
  SupplierService,
  StoreService,
  RetailItemService,
  AlloyService,
  MetalService,
  DeliveryType,
  AbstractItem,
  StandardItem,
  AlloyComposition,
  MetalComposition,
  Pagination,
  PaginatedList,
  MetalAccount,
  DeliveryLineStatus,
  ReceiveStatus,
} from "center-services";
import { SearchFilter, SearchFilterOperator } from "fugu-common";
import { MessageService } from "fugu-components";
import { combineLatest, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
  selector: "app-new-delivery-form-validation-popup",
  templateUrl: "./new-delivery-form-validation-popup.component.html",
  styleUrls: ["./new-delivery-form-validation-popup.component.scss"],
})
export class NewDeliveryFormValidationPopupComponent implements OnInit, OnChanges {
  @Input() btnLabel: string;
  @Input() newReceivingForm: ReceivingForm;
  // value needed for value changes in other components: component remains up-to-date
  @Input() lines: AbstractReceivingLine[];
  // ReceptionType.direct or ReceptionType.withRegistering : in 1 or 2 steps
  @Input() withPreparation: boolean;
  // store name
  @Input() receiverName: string;
  // only for the case of line.type is ReceivingPOLine
  @Input() purchaseOrderlines: PurchaseOrderLine[];
  // ReceptionType.withRegistering : validation of preparation or validation of receipt
  @Input() isValidationForPreparation: boolean;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public faWarn: IconDefinition = faExclamationTriangle;
  public title: string;
  public supplierMetalAccountWarn: string;
  public metalAccountWarnMessage: string;
  public sender: Supplier | Store;
  public alloyList: Alloy[];
  public metalList: Metal[];
  public checkMetalAccountFlag: boolean = true;
  public initObservables: Observable<any>[];
  public uniquePMIds: number[] = [];
  public lineType: string;
  public isFreeLine: boolean = false;
  public internalMovement: boolean = false;
  public igniter: string;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private supplierService: SupplierService,
    private storeService: StoreService,
    private retailItemService: RetailItemService,
    private alloyService: AlloyService,
    private metalService: MetalService
  ) {}

  public ngOnInit(): void {
    if (this.newReceivingForm !== undefined) {
      this.getLineType();
      this.areAllLinesStatusPending();
      this.isOneLineStatusPending();
      this.isOneLineStatusRefused();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lines && changes.lines.currentValue) {
      this.getLineType();
      // manage the case of the metalAccount for supplier
      if (this.lines.length > 0) {
        this.initObservables = [];
        this.initObservables.push(this.fetchAlloys());
        this.initObservables.push(this.fetchMetals());
        this.initObservables.push(this.fetchSender(this.newReceivingForm?.senderId));
        combineLatest(this.initObservables).subscribe(() => {
          this.checkMetalAccounts().subscribe();
          this.isMAMessageDisplayed();
        });
      }
    }
  }

  closeValidationPopup(): void {
    this.close.emit("close");
  }

  validateReceipt(): void {
    this.validate.emit();
  }

  public fetchSender(senderId: number): Observable<Supplier | Store> {
    if (this.newReceivingForm.type === DeliveryType.INTERNAL) {
      return this.storeService.get(senderId).pipe(
        tap(
          (store: Store) => {
            this.sender = store;
          },
          error => {
            this.sendErrorAlert("receiving-form.errors.get-receiver", error.message);
          }
        )
      );
    } else {
      return this.supplierService.get(senderId).pipe(
        tap(
          (supplier: Supplier) => {
            this.sender = supplier;
          },
          error => {
            this.sendErrorAlert("purchase-modalities-popup.errors.get-supplier", error.message);
          }
        )
      );
    }
  }

  // used to display a message for the supplier who does not have a metal account for the relative metal
  public checkMetalAccounts(): Observable<boolean> {
    switch (this.lineType) {
      case "ReceivingInternalLine":
        this.internalMovement = true;
        break;
      case "ReceivingPOLine":
        this.getPmfromPOL();
        break;
      case "ReceivingFreeLine":
        this.isFreeLine = true;
        this.getPmfromFreeLines();
        break;
      default:
        console.warn("error line type doesn't exist");
    }

    if (this.uniquePMIds.length === 0) {
      return of(true);
    }
    if (this.internalMovement) {
      return of(true);
    } else {
      return this.fetchItems(this.uniquePMIds).pipe(
        map(
          (items: AbstractItem[]) => {
            const metalNameList = this.getMetalsWithoutMetalAccount(items);
            if (metalNameList.length > 0 && this.checkMetalAccountFlag) {
              // use it to display or not
              this.checkMetalAccountFlag = false;
              this.translateService
                .get("new-delivery-form-validation-popup.message.title-metal-account-missing")
                .subscribe(() => {
                  this.supplierMetalAccountWarn = this.translateService.instant(
                    "new-delivery-form-validation-popup.message.metal-account-missing",
                    {
                      metalNameList,
                    }
                  );
                });
              return false;
            }
            return true;
          },
          error => {
            this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
            return false;
          }
        )
      );
    }
  }

  public getAlloy(alloyId: number): Alloy {
    return this.alloyList.find(alloy => alloy.id === alloyId);
  }

  public getMetal(metalId: number): Metal {
    return this.metalList.find(metal => metal.id === metalId);
  }

  public fetchItems(purchaseModalityIds: any[]): Observable<AbstractItem[]> {
    const pager = new Pagination({
      size: purchaseModalityIds.length,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, purchaseModalityIds);

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      map(
        (result: PaginatedList<AbstractItem>) => {
          // remove duplicates from item list
          const ids = result.data.map(obj => obj.id);
          return result.data.filter(({ id }, index) => !ids.includes(id, index + 1));
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  public getSupplierMetalIds(): number[] {
    return this.sender
      ? this.sender.metalAccounts.filter((ma: MetalAccount) => ma.activated).map((ma: MetalAccount) => ma.metalId)
      : [];
  }

  public getSupplierMetalsWithoutMetalAccount(supplierMetalIds: number[], itemMetals: Metal[]): string[] {
    return itemMetals
      .filter((metal: Metal) => !supplierMetalIds?.includes(metal.id))
      .map((metal: Metal) => ` ${metal.name}`);
  }

  public getPopupTitle(): string {
    if (this.isRefused()) {
      return (this.title = this.translateService.instant(
        "new-delivery-form-validation-popup.preparation-refused.title"
      ));
    }
    if (this.validationOfPreparation()) {
      return (this.title = this.translateService.instant("new-delivery-form-validation-popup.with-preparation.title"));
    } else {
      return (this.title = this.translateService.instant(
        "new-delivery-form-validation-popup.without-preparation.title"
      ));
    }
  }

  public getValidateTextButton(): string {
    if (this.isRefused()) {
      return this.translateService.instant("new-delivery-form-validation-popup.buttons.refuse-preparation");
    }

    if (this.validationOfPreparation()) {
      return this.translateService.instant("new-delivery-form-validation-popup.buttons.validate-preparation");
    } else {
      return this.translateService.instant("new-delivery-form-validation-popup.buttons.validate-receiving");
    }
  }

  // used to know if the we are on the validation of the preparation or the validation of receipt
  public validationOfPreparation(): boolean {
    // IN 2 STEPS:
    // step 1 -> preparation
    if (this.withPreparation) {
      // the "pending" or "receiving" case is not fully operational because a ticket is missing which has not yet been mad
      if (this.isValidationForPreparation) {
        if (
          this.newReceivingForm?.lines.filter(line => line.status === DeliveryLineStatus.PENDING).length >= 1 ||
          this.newReceivingForm?.lines.filter(line => line.status === DeliveryLineStatus.REFUSED).length >= 1 ||
          this.newReceivingForm?.receiveStatus === ReceiveStatus.PENDING
        ) {
          return true;
        }
        return true;
      } else {
        // step 2 -> receiving
        return false;
      }
    } else {
      // IN ONLY 1 STEP -> just receiving
      return false;
    }
  }

  getQuestionContent(): string {
    // validation of preparation
    if (this.validationOfPreparation() && !this.isRefused()) {
      return this.translateService.instant(
        "new-delivery-form-validation-popup.with-preparation.preparation-validation.question"
      );
    }
    // validation of reception
    if (!this.validationOfPreparation() && !this.isRefused()) {
      return this.translateService.instant("new-delivery-form-validation-popup.with-preparation.reception.question");
    }
    // refused case
    if (this.isRefused()) {
      return this.translateService.instant("new-delivery-form-validation-popup.preparation-refused.question");
    }
    return null;
  }

  getIgniter(): string {
    if (!this.isRefused() && this.validationOfPreparation()) {
      return this.translateService.instant("new-delivery-form-validation-popup.with-preparation.igniter");
    }
    if (!this.isRefused() && !this.validationOfPreparation()) {
      return this.translateService.instant("new-delivery-form-validation-popup.without-preparation.igniter");
    }
    return null;
  }

  isWarningMessageDisplayed(): boolean {
    return this.getWarningText().length > 0;
  }

  getWarningText(): string[] {
    const arrayOfMessageText: string[] = [];

    if (this.standardWarningMessage()) {
      arrayOfMessageText.push(
        this.translateService.instant(
          "new-delivery-form-validation-popup.with-preparation.preparation-validation.not-validated"
        )
      );
    }
    if (this.isFreeLine && !this.validationOfPreparation() && this.withPreparation) {
      arrayOfMessageText.push(
        this.translateService.instant(
          "new-delivery-form-validation-popup.with-preparation.reception.free-registery.warning-message"
        )
      );
    }
    if (this.isRefused()) {
      arrayOfMessageText.push(
        this.translateService.instant("new-delivery-form-validation-popup.preparation-refused.warning-message", {
          storeName: this.receiverName,
        })
      );
    }
    if (this.withPreparation && this.validationOfPreparation() && !this.isFreeLine && !this.isRefused()) {
      arrayOfMessageText.push(
        this.translateService.instant("new-delivery-form-validation-popup.with-preparation.warning-message")
      );
    }

    if (this.isMAMessageDisplayed()) {
      arrayOfMessageText.push(this.supplierMetalAccountWarn);
    }

    return arrayOfMessageText;
  }

  getStockMessage(): string {
    return this.translateService.instant("new-delivery-form-validation-popup.reception-validation.stock");
  }

  getMetalAccountMessage(): string {
    return this.translateService.instant(
      "new-delivery-form-validation-popup.with-preparation.preparation-validation.lines-validated.metal-account"
    );
  }

  getPoliceBookMessage(): string {
    return this.translateService.instant(
      "new-delivery-form-validation-popup.reception-validation.police-book-movement"
    );
  }

  getValidationOfReceptionLabels(): string {
    return this.translateService.instant("new-delivery-form-validation-popup.reception-validation.labels");
  }

  // all lines are refused
  public isRefused(): boolean {
    if (this.isFreeLine) {
      return false;
    }
    let refused = false;
    this.newReceivingForm?.lines.filter(line => {
      return line.status === DeliveryLineStatus.REFUSED;
    }).length === this.newReceivingForm?.lines.length
      ? (refused = true)
      : (refused = false);
    return refused;
  }

  public standardWarningMessage(): boolean {
    if (this.isRefused() || !this.isValidationForPreparation) {
      return false;
    } else {
      return this.isOneLineStatusRefused() || this.isOneLineStatusPending() || this.areAllLinesStatusPending();
    }
  }

  // at least one line have a refused status
  public isOneLineStatusRefused(): boolean {
    if (this.isFreeLine) {
      return false;
    }
    return this.newReceivingForm?.lines.filter(line => line.status === DeliveryLineStatus.REFUSED).length >= 1
      ? true
      : false;
  }

  // at least one line have a pending status
  public isOneLineStatusPending(): boolean {
    if (this.isFreeLine) {
      return false;
    }
    return this.newReceivingForm?.lines.filter(line => line.status === DeliveryLineStatus.PENDING).length >= 1
      ? true
      : false;
  }

  public areAllLinesStatusPending(): boolean {
    if (this.isFreeLine) {
      return false;
    }
    let statusChanged = false;
    this.newReceivingForm?.lines.filter(line => {
      return line.status === DeliveryLineStatus.PENDING;
    }).length === this.newReceivingForm?.lines.length
      ? (statusChanged = true)
      : (statusChanged = false);
    return statusChanged;
  }

  // supplier metal account warning message
  public isMAMessageDisplayed(): boolean {
    if (this.supplierMetalAccountWarn !== undefined && !this.isRefused()) {
      return this.validationOfPreparation() || !this.withPreparation;
    }
    return false;
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }

  private getLineType(): void {
    this.lineType = this.newReceivingForm?.lines[0].type;
  }

  private getLinesWithMetalAccount(): AbstractReceivingLine[] {
    const linesWithMetalAccount: AbstractReceivingLine[] = this.newReceivingForm.getLinesWithMetalAccount();
    linesWithMetalAccount.length > 0 ? (this.checkMetalAccountFlag = true) : (this.checkMetalAccountFlag = false);
    return linesWithMetalAccount;
  }

  private getPurchasePOLIds(linesWithMA: AbstractReceivingLine[]): number[] {
    const polIDFromLWMA: number[] = [];
    linesWithMA.forEach(line => {
      polIDFromLWMA.push(line.getIdentifier());
    });
    return polIDFromLWMA;
  }

  private getPmfromPOL(): void {
    // step 1 : filter lines with only metalAccount
    const linesWithMA = this.getLinesWithMetalAccount();

    // step 2: get the POLid
    const polIDFromLWMA = this.getPurchasePOLIds(linesWithMA);

    // step 3: get the POL from POLid
    const pOLs: PurchaseOrderLine[] = [];
    this.purchaseOrderlines.forEach(pol => {
      polIDFromLWMA.forEach(pl => {
        if (pol.id === pl) {
          pOLs.push(pol);
        }
      });
    });

    // step 4: set this.uniquePMIds
    this.getPMIds(pOLs);
  }

  private getPmfromFreeLines(): void {
    // step 1 : filter lines with only metalAccount
    const linesWithMA = this.getLinesWithMetalAccount();

    // step 2: get the PMids
    const pmIds: number[] = [];
    linesWithMA.forEach(line => {
      pmIds.push(line.getIdentifier());
    });
    this.uniquePMIds = [...new Set(pmIds)];
  }

  private getPMIds(pOLs: any[]): void {
    const pmIdList: number[] = [];
    pOLs.forEach(pol => {
      pmIdList.push(pol.purchaseModalityId);
    });
    this.uniquePMIds = [...new Set(pmIdList)];
  }

  private getMetalsWithoutMetalAccount(items: AbstractItem[]): string[] {
    const concatMetalList: Set<Metal> = new Set();
    items.forEach((item: AbstractItem) => {
      this.getItemMetals(item).forEach((metal: Metal) => {
        // here for add space between
        concatMetalList.add(metal);
      });
    });
    return this.getSupplierMetalsWithoutMetalAccount(this.getSupplierMetalIds(), Array.from(concatMetalList));
  }

  private getItemMetals(item: AbstractItem): Metal[] {
    const metals: Set<Metal> = new Set();
    if (!(item instanceof StandardItem) || !item.composition) {
      return [...metals];
    }
    (item as StandardItem).composition.forEach((alloyC: AlloyComposition) => {
      this.getAlloy(alloyC.alloyId).composition.forEach((mc: MetalComposition) =>
        metals.add(this.getMetal(mc.metalId))
      );
    });
    return [...metals];
  }

  private fetchAlloys(): Observable<Alloy[]> {
    return this.alloyService.getAll().pipe(
      tap(
        (alloys: Alloy[]) => {
          this.alloyList = [];
          this.alloyList = alloys;
        },
        error => {
          this.sendErrorAlert("alloy-composition-list.list.errors.get-alloys", error.message);
        }
      )
    );
  }

  private fetchMetals(): Observable<Metal[]> {
    return this.metalService.getAll().pipe(
      tap(
        (metals: Metal[]) => {
          this.metalList = [];
          this.metalList = metals;
        },
        error => {
          this.sendErrorAlert("metals-list.errors.get-metals", error.message);
        }
      )
    );
  }
}
