import {
  Action,
  CaraUserService,
  CreationDeliveryType,
  DeliveryLineColumn,
  DeliveryType,
  ReceiveStatus,
  ReceivingForm,
  ReceptionType,
} from "center-services";

export class ReceivingFormColumnBuilder {
  /**
   * Right that indicate the user can show the purchasing prices (direct and 2 steps)
   */
  private canShowPrices: boolean;
  /**
   * Right that indicate the user can edit the prices
   *  - direct => after the reception
   *  - 2 steps => after the registering
   */
  private canEditPrices: boolean;
  /**
   * Right that indicate the user can edit the weights after the registering (2 steps)
   */
  private canEditWeight: boolean;
  /**
   * Right that indicate the user can edit the registering values (expectedQuantity, expectedSizeValue) after the registering (2 steps)
   */
  private canEditExpectedAfterValidation: boolean;
  /**
   * Right that indicate the user can create and update receiving forms (direct)
   */
  private canCreateAndUpdateDirect: boolean;
  /**
   * Right that indicate the user can update receiving forms after the registering (2 steps)
   */
  private canCreateAndUpdateWithRegistering: boolean;
  /**
   * Right that indicate the user can create/update receiving forms in registering mode (2 steps)
   */
  private canPrepareReceiving: boolean;

  constructor(
    private receivingForm: ReceivingForm,
    private overridenAction: Action,
    private receptionType: ReceptionType,
    private readOnly: boolean,
    private userContextStoreId: number,
    private mainStoreId: number,
    userService: CaraUserService
  ) {
    this.canCreateAndUpdateDirect = userService.canDo("RECEIVING_FORM_DIRECT_CREATE_UPDATE");
    this.canCreateAndUpdateWithRegistering = userService.canDo("RECEIVING_FORM_REGISTERING_CREATE_UPDATE");
    this.canShowPrices = userService.canDo("PURCHASING_PRICE");
    this.canEditPrices = userService.canDo("RECEIVING_FORM_UPDATE_PRICE");
    this.canEditWeight = userService.canDo("RECEIVING_FORM_REGISTERING_UPDATE_WEIGHT");

    this.canPrepareReceiving = userService.canDo("RECEIVING_FORM_REGISTERING_PREPARE");
    this.canEditExpectedAfterValidation = userService.canDo("RECEIVING_FORM_REGISTERING_UPDATE_PREPARATION");
  }

  // eslint-disable-next-line complexity
  public buildColumns(): DeliveryLineColumn[] {
    // Boolean conditions used to manage the editable/visible state of columns
    const inProgress: boolean = this.receivingForm.receiveStatus === ReceiveStatus.PREPARATION_IN_PROGRESS;
    const receiveInProgress: boolean = this.receivingForm.receiveStatus === ReceiveStatus.RECEIVE_IN_PROGRESS;
    const toProcess: boolean = this.receivingForm.receiveStatus === ReceiveStatus.TO_PROCESS;
    const toReceive: boolean = this.receivingForm.receiveStatus === ReceiveStatus.TO_RECEIVE;
    const isFree: boolean = this.receivingForm.creationType === CreationDeliveryType.FREE;
    const received: boolean = this.receivingForm.receiveStatus === ReceiveStatus.RECEIVED;
    const pending: boolean = this.receivingForm.receiveStatus === ReceiveStatus.PENDING;
    const internal: boolean = this.receivingForm.type === DeliveryType.INTERNAL;
    const updateRecection: boolean = this.overridenAction === Action.UPDATE_RECEPTION;
    const inPreparation: boolean = this.overridenAction === Action.PREPARE;
    const receive: boolean = this.overridenAction === Action.RECEIVE;

    const editeExpectedSizeValueAndMetrics: boolean =
      this.isWithRegistering() &&
      // 2 steps with the right to prepare on expected SV & metrics
      ((this.canPrepareReceiving && (toProcess || inProgress || (pending && inPreparation))) ||
        //  2 steps with the right to update on expected SV & metrics
        (this.canEditExpectedAfterValidation &&
          ((pending && (updateRecection || receive)) || toReceive || receiveInProgress)));

    const orderedHidden = isFree || (received && this.isWithRegistering()) || internal || (pending && updateRecection);
    const toPrepare = toProcess || pending || inProgress;
    const checkableAction =
      this.isNullOrUndefinedOrEmpty(this.overridenAction) || this.overridenAction === Action.PREPARE;
    const centralUserNotAsReceiver =
      this.receivingForm.receiverId !== this.userContextStoreId && this.userContextStoreId === this.mainStoreId;
    const lineColumns = [];

    // Build columns for prices and weights
    lineColumns.push(...this.buildWeightsColumns(isFree, toPrepare, received, centralUserNotAsReceiver));
    lineColumns.push(...this.buildPricesColumns(isFree, toPrepare, centralUserNotAsReceiver));

    // Manage checkbox/status columns
    if (!this.readOnly) {
      lineColumns.push(
        new DeliveryLineColumn({
          visible:
            (toPrepare || receiveInProgress) &&
            (this.isWithRegistering() || isFree) &&
            checkableAction &&
            !internal &&
            this.editAllowedAsMainStore() &&
            true,
          property: "checkbox",
          editable: true,
          width: 50,
          order: 0,
        })
      );
      lineColumns.push(
        new DeliveryLineColumn({
          property: "delete",
          editable: isFree,
          width: 0,
          order: 0,
        })
      );
      lineColumns.push(
        new DeliveryLineColumn({
          visible:
            !isFree &&
            !internal &&
            ((!this.isWithRegistering() && (toPrepare || receiveInProgress)) ||
              (this.isWithRegistering() && (inPreparation || inProgress || toProcess))),
          property: "actions",
          width: 80,
          order: 42,
        })
      );
    }

    lineColumns.push(
      new DeliveryLineColumn({
        visible: this.isWithRegistering() && !isFree && !internal,
        editable: toPrepare,
        property: "status",
        width: 80,
        order: 41,
        filterType: "list",
      })
    );

    // Manage size value columns
    lineColumns.push(
      new DeliveryLineColumn({
        property: "orderedSizeValue",
        visible: !orderedHidden,
        width: 110,
        order: 4,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "sizeValueAnomaly",
        visible: !orderedHidden,
        width: 30,
        order: 5,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "expectedSizeValue",
        visible: this.isWithRegistering() || internal,
        editable:
          !received &&
          !internal &&
          !(pending && this.overridenAction === Action.UPDATE_RECEPTION) &&
          editeExpectedSizeValueAndMetrics,
        width: 90,
        order: 6,
        highLight:
          !isFree &&
          !received &&
          !internal &&
          this.isWithRegistering() &&
          (inProgress || toProcess || (pending && inPreparation)),
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        visible:
          (this.isWithRegistering() && !toPrepare) ||
          (pending && this.overridenAction === Action.UPDATE_RECEPTION && !internal),
        property: "expectedSizeValueAnomaly",
        width: 30,
        order: 7,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        visible:
          (!toPrepare ||
            !this.isWithRegistering() ||
            (pending &&
              (this.overridenAction === Action.RECEIVE || this.overridenAction === Action.UPDATE_RECEPTION))) &&
          !internal,
        property: "receivedSizeValue",
        // prevent direct received case: no edit
        editable:
          !this.isWithRegistering() ||
          (this.isWithRegistering() &&
            this.canCreateAndUpdateWithRegistering &&
            !centralUserNotAsReceiver &&
            !internal),
        width: 90,
        order: 8,
        highLight: !isFree && (!this.isWithRegistering() || (this.isWithRegistering() && !inProgress)),
      })
    );

    // Manage quantity columns
    lineColumns.push(
      new DeliveryLineColumn({
        property: "orderedQuantity",
        visible: !orderedHidden,
        width: 110,
        order: 9,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "unreceivedQuantity",
        visible: !orderedHidden,
        width: 125,
        order: 10,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "quantityAnomaly",
        visible: !orderedHidden && !received,
        width: 30,
        order: 11,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        width: +(orderedHidden && (received || !isFree) ? "110" : "70"),
        visible: this.isWithRegistering() || internal || (pending && this.overridenAction === Action.UPDATE_RECEPTION),
        property: "expectedQuantity",
        editable:
          !received &&
          !internal &&
          !(pending && this.overridenAction === Action.UPDATE_RECEPTION) &&
          editeExpectedSizeValueAndMetrics,
        order: 12,
        highLight:
          !isFree &&
          !received &&
          !internal &&
          this.isWithRegistering() &&
          (inProgress || toProcess || (pending && inPreparation)),
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        visible: this.isWithRegistering() && isFree && !received,
        property: "expectedQuantityUnit",
        editable: false,
        width: 90,
        order: 13,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        visible:
          (this.isWithRegistering() && !toPrepare) || (pending && this.overridenAction === Action.UPDATE_RECEPTION),
        property: "expectedQuantityAnomaly",
        width: 30,
        order: 14,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        visible:
          !toPrepare ||
          !this.isWithRegistering() ||
          (pending && (this.overridenAction === Action.RECEIVE || this.overridenAction === Action.UPDATE_RECEPTION)) ||
          internal,
        property: "receivedQuantity",
        editable:
          !this.isWithRegistering() ||
          (this.isWithRegistering() && this.canCreateAndUpdateWithRegistering && !centralUserNotAsReceiver),
        width: 70,
        order: 15,
        highLight: !isFree && (!this.isWithRegistering() || (this.isWithRegistering() && !inProgress)),
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        visible:
          (!this.isWithRegistering() && isFree) ||
          internal ||
          (pending && this.overridenAction === Action.UPDATE_RECEPTION),
        property: "receivedQuantityUnit",
        editable: false,
        width: 90,
        order: 16,
      })
    );

    lineColumns.push(
      new DeliveryLineColumn({
        property: "lineNumber",
        visible: true,
        width: 70,
        order: 1,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "supplierRef",
        visible: true,
        width: 150,
        order: 2,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "supplierTraceabilityNumber",
        editable: !received && !internal && !(pending && this.overridenAction === Action.UPDATE_RECEPTION),
        visible: true,
        width: 140,
        order: 3,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "supplierName",
        visible: true,
        width: 150,
        order: 41,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "destLocationId",
        editable: !received && !(pending && this.overridenAction === Action.UPDATE_RECEPTION),
        visible: true,
        width: 180,
        order: 37,
        filterType: "list",
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "itemRef",
        visible: true,
        width: 140,
        order: 38,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "itemName",
        visible: true,
        width: 220,
        order: 39,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "serialNumber",
        visible: internal,
        width: 100,
        order: 4,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "batchNumber",
        visible: internal,
        width: 100,
        order: 4,
      })
    );
    lineColumns.push(
      new DeliveryLineColumn({
        property: "brandName",
        visible: true,
        width: 140,
        order: 40,
        filterType: "list",
      })
    );
    if (this.readOnly) {
      lineColumns.forEach(column => (column.editable = false));
    }
    return lineColumns;
  }

  // Method used to build DeliveryLineColumn linked to weights to manage the editable/visible state of columns
  private buildWeightsColumns(
    isFree: boolean,
    toPrepare: boolean,
    received: boolean,
    centralUserNotAsReceiver: boolean = false
  ): DeliveryLineColumn[] {
    const internal: boolean = this.receivingForm.type === DeliveryType.INTERNAL;
    const pending: boolean = this.receivingForm.receiveStatus === ReceiveStatus.PENDING;

    let editWeight = toPrepare || this.canEditWeight || this.canCreateAndUpdateDirect;

    // we can't edit weights on an internal DF
    // we can't edit weights on an already received DF
    if (internal || received) {
      editWeight = false;
    }

    const highLight =
      !isFree && this.canEditWeight && !received && !(pending && this.overridenAction === Action.UPDATE_RECEPTION);
    /* eslint-disable no-magic-numbers */
    return [
      this.createWeightColumn("orderedMetalWeight", !isFree && !internal, 160, 21),
      this.createWeightColumn("metalWeightAnomaly", !isFree && !internal, 30, 22),
      this.createEditableWeightColumn(
        "realMetalWeight",
        editWeight,
        pending,
        centralUserNotAsReceiver,
        120,
        23,
        highLight
      ),
      this.createWeightColumn("realMetalWeightInfo", true, 23, 24),
      this.createEditableWeightColumn("tare", editWeight, pending, centralUserNotAsReceiver, 120, 25, false),
      this.createEditableWeightColumn("totalWeight", editWeight, pending, centralUserNotAsReceiver, 120, 26, false),
    ];
    /* eslint-enable no-magic-numbers */
  }

  private createWeightColumn(property: string, visible: boolean, width: number, order: number): DeliveryLineColumn {
    return new DeliveryLineColumn({
      property,
      visible,
      width,
      order,
    });
  }

  private createEditableWeightColumn(
    property: string,
    editWeight: boolean,
    pending: boolean,
    centralUserNotAsReceiver: boolean,
    width: number,
    order: number,
    highLight: boolean
  ): DeliveryLineColumn {
    return new DeliveryLineColumn({
      property,
      editable: this.isEditableWeightColumn(editWeight, pending, centralUserNotAsReceiver),
      visible: true,
      width,
      order,
      highLight: highLight,
    });
  }

  private isEditableWeightColumn(editWeight: boolean, pending: boolean, centralUserNotAsReceiver: boolean): boolean {
    return (
      editWeight &&
      !(pending && this.overridenAction === Action.UPDATE_RECEPTION) &&
      !(pending && this.overridenAction === Action.RECEIVE && centralUserNotAsReceiver)
    );
  }

  // Method used to build DeliveryLineColumn linked to prices to manage the editable/visible state of columns
  private buildPricesColumns(
    isFree: boolean,
    toPrepare: boolean,
    centralUserNotAsReceiver: boolean
  ): DeliveryLineColumn[] {
    const internal: boolean = this.receivingForm.type === DeliveryType.INTERNAL;

    const receiveInProgress = this.receivingForm.receiveStatus === ReceiveStatus.RECEIVE_IN_PROGRESS;
    const toProcess = this.receivingForm.receiveStatus === ReceiveStatus.TO_PROCESS;
    const preparationInProgress = this.receivingForm.receiveStatus === ReceiveStatus.PREPARATION_IN_PROGRESS;
    const pending = this.receivingForm.receiveStatus === ReceiveStatus.PENDING;
    const toReceive = this.receivingForm.receiveStatus === ReceiveStatus.TO_RECEIVE;
    const received = this.receivingForm.receiveStatus === ReceiveStatus.RECEIVED;

    const readPrices: boolean = this.shouldReadPrices(toPrepare, internal);
    // handle the case of creation in 2 steps (this.isWithRegistering())
    const editPrices: boolean = this.shouldEditPrices(
      toPrepare,
      centralUserNotAsReceiver,
      receiveInProgress,
      toProcess,
      preparationInProgress,
      pending,
      toReceive,
      received,
      internal
    );

    const orderedPricesVisible: boolean = !isFree && readPrices && !internal;

    return this.createLineColumns(orderedPricesVisible, readPrices, editPrices, isFree, internal);
  }

  private shouldReadPrices(toPrepare: boolean, internal: boolean): boolean {
    return (toPrepare || (this.canShowPrices && this.isWithRegistering()) || !this.isWithRegistering()) && !internal;
  }

  /* eslint-disable no-magic-numbers */
  private shouldEditPrices(
    toPrepare: boolean,
    centralUserNotAsReceiver: boolean,
    receiveInProgress: boolean,
    toProcess: boolean,
    preparationInProgress: boolean,
    pending: boolean,
    toReceive: boolean,
    received: boolean,
    internal: boolean
  ): boolean {
    return (
      this.canEditInPrepareState(toPrepare, centralUserNotAsReceiver, internal) ||
      this.canEditInReceiveState(receiveInProgress) ||
      this.canEditInTwoStepProcess(toProcess, preparationInProgress, pending, centralUserNotAsReceiver) ||
      this.canEditInReceptionState(toReceive, receiveInProgress, received)
    );
  }

  private canEditInPrepareState(toPrepare: boolean, centralUserNotAsReceiver: boolean, internal: boolean): boolean {
    return (toPrepare || this.canEditPrices) && !internal && this.canCreateAndUpdateDirect && !centralUserNotAsReceiver;
  }

  private canEditInReceiveState(receiveInProgress: boolean): boolean {
    return receiveInProgress && this.canCreateAndUpdateDirect;
  }

  private canEditInTwoStepProcess(
    toProcess: boolean,
    preparationInProgress: boolean,
    pending: boolean,
    centralUserNotAsReceiver: boolean
  ): boolean {
    return (
      (toProcess && this.isWithRegistering()) ||
      (preparationInProgress && this.isWithRegistering()) ||
      (pending && this.isWithRegistering() && !centralUserNotAsReceiver)
    );
  }

  private canEditInReceptionState(toReceive: boolean, receiveInProgress: boolean, received: boolean): boolean {
    return (
      (this.canEditPrices && toReceive && this.isWithRegistering()) ||
      (this.canEditPrices && receiveInProgress && this.isWithRegistering()) ||
      (this.canEditPrices && received && this.isWithRegistering())
    );
  }

  private createLineColumns(
    orderedPricesVisible: boolean,
    readPrices: boolean,
    editPrices: boolean,
    isFree: boolean,
    internal: boolean
  ): DeliveryLineColumn[] {
    return [
      this.createColumn("orderedUnitPrice", orderedPricesVisible, false, 140, 17),
      this.createColumn("realUnitPrice", readPrices, editPrices, 100, 18, !isFree && editPrices),
      this.createColumn("orderedUnitPricePerWeight", orderedPricesVisible, false, 160, 19),
      this.createColumn("realUnitPricePerWeight", readPrices, editPrices, 130, 20, !isFree && editPrices),
      this.createColumn("orderedMetalPrice", orderedPricesVisible, false, 120, 27),
      this.createColumn("realMetalPrice", readPrices, editPrices, 110, 28, !isFree && editPrices),
      this.createColumn("orderedDiscount", orderedPricesVisible, false, 120, 29, false, "range"),
      this.createColumn(
        "realDiscount",
        readPrices,
        editPrices,
        180,
        30,
        !isFree &&
          editPrices &&
          !this.isWithRegistering() &&
          this.receivingForm.receiveStatus !== ReceiveStatus.RECEIVED,
        "range"
      ),
      this.createColumn("orderedTotalUnitPrice", orderedPricesVisible, false, 110, 31),
      this.createColumn("totalUnitPriceInfo", orderedPricesVisible, false, 23, 32),
      this.createColumn("totalUnitPriceAnomaly", orderedPricesVisible, false, 30, 33),
      this.createColumn("realTotalUnitPrice", readPrices || internal, false, 110, 34),
      this.createColumn("totalPrice", readPrices || internal, false, 110, 35),
      this.createColumn("onMetalAccount", readPrices, editPrices, 190, 36),
    ];
  }

  /* eslint-enable no-magic-numbers */

  private createColumn(
    property: string,
    visible: boolean,
    editable: boolean,
    width: number,
    order: number,
    highLight: boolean = false,
    filterType: string = ""
  ): DeliveryLineColumn {
    return new DeliveryLineColumn({
      property,
      visible,
      editable,
      width,
      order,
      highLight,
      filterType,
    });
  }

  private isNullOrUndefinedOrEmpty(value: string): boolean {
    return !value || value === "";
  }

  private editAllowedAsMainStore(): boolean {
    const selectedStoreId = this.userContextStoreId;
    const storeForbiddenToModify =
      selectedStoreId === this.mainStoreId && selectedStoreId !== this.receivingForm.receiverId;
    const receivingStatus = this.receivingForm.receiveStatus;
    const inProgress = receivingStatus === ReceiveStatus.RECEIVE_IN_PROGRESS;
    const toReceive = receivingStatus === ReceiveStatus.TO_RECEIVE;
    const received = receivingStatus === ReceiveStatus.RECEIVED;
    return !((inProgress || toReceive || received) && storeForbiddenToModify);
  }

  /**
   * @returns True if the reception configuration is with registering (2 steps), false if it's without (direct)
   */
  private isWithRegistering(): boolean {
    return this.receptionType === ReceptionType.WITH_REGISTERING;
  }
}
