import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faPlus, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { Renovation, CaraUserService } from "center-services";
import { DayjsUtil, SubscriptionService } from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";

@Component({
  selector: "app-renovation",
  templateUrl: "./renovation.component.html",
  styleUrls: ["./renovation.component.scss"],
  providers: [SubscriptionService],
})
export class RenovationComponent implements OnInit, OnChanges {
  @Input() renovationList: Renovation[];
  @Output() renovationListChange: EventEmitter<Renovation[]> = new EventEmitter<Renovation[]>();
  @ViewChild("table") table: DatatableComponent;

  faPlus: IconDefinition = faPlus;
  faTrash: IconDefinition = faTrashAlt;
  public rows: any[] = [];

  public popupTitle: any;
  public popupVisible: boolean = false;

  public locale: string;
  public dateFormat: string;

  public shouldClose: boolean = false;
  public menuActions: MenuAction[] = [];

  public renovationIndex: number;
  public renovationForm: UntypedFormGroup;
  public unsavedRenovation: Renovation;
  public initialRenovation: Renovation;
  public selectedRenovation: Renovation;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.addMenuActions();
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.renovationList && !changes.renovationList.firstChange) {
      this.refresh();
    }
  }

  refresh(): void {
    this.prepareForm();
    this.fetchRenovationList();

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );
  }

  fetchRenovationList(): void {
    this.rows = [];

    this.renovationList.forEach((renovation: Renovation) => {
      const index = this.renovationList.indexOf(renovation);
      this.rows.push({
        index,
        date: renovation.date,
        comment: renovation.comment,
      });
    });
  }

  addMenuActions(): void {
    this.menuActions = [];

    this.menuActions.push(new MenuAction(0, this.translateService.instant("renovation.actions.remove"), faTrashAlt));
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case 0:
        this.removeRenovation(row);
        break;
      default:
        break;
    }
  }

  addRenovation(): void {
    this.selectedRenovation = new Renovation({
      comment: null,
      date: null,
    });

    this.popupTitle = "renovation.popup.title-new-renovation";
    this.renovationIndex = null;
    this.initializePopup();
  }

  updateRenovation(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("STORE_UPDATE")) {
        return;
      }

      this.selectedRenovation = new Renovation(this.renovationList[event.row.index]);
      this.popupTitle = "renovation.popup.title-update-renovation";
      this.renovationIndex = event.row.index;
      this.initializePopup();
    }
  }

  removeRenovation(row: any): void {
    this.renovationList.splice(row.index, 1);
    this.fetchRenovationList();
    this.renovationListChange.emit(this.renovationList);
  }

  initializePopup(): void {
    this.renovationForm.controls.comment.setValue(this.selectedRenovation.comment);
    this.renovationForm.controls.date.setValue(DayjsUtil.dayjsOrNull(this.selectedRenovation.date, true));
    this.initialRenovation = new Renovation(this.selectedRenovation);
    // Show popup after all data are ready
    this.popupVisible = true;
  }

  closePopup(): void {
    this.unsavedRenovation = null;
    this.popupVisible = false;
    this.shouldClose = false;
    this.renovationForm.markAsUntouched();
  }

  canClose(): void {
    this.applyModifications();
    if (this.unsavedRenovation && !this.unsavedRenovation.equals(this.selectedRenovation)) {
      this.shouldClose = false;
    }

    if (!this.initialRenovation.equals(this.selectedRenovation) && !this.shouldClose) {
      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedRenovation = new Renovation(this.selectedRenovation);
      this.shouldClose = true;
    } else {
      this.closePopup();
    }
  }

  prepareForm(): void {
    this.renovationForm = this.fb.group({
      comment: [null, [Validators.required]],
      date: [null, [Validators.required]],
    });
  }

  applyModifications(): void {
    this.selectedRenovation.date = this.renovationForm.value.date ? this.renovationForm.value.date.toDate() : null;
    this.selectedRenovation.comment = this.renovationForm.value.comment;
  }

  submitRenovation(): void {
    if (this.renovationForm.invalid) {
      this.renovationForm.markAllAsTouched();
      return;
    }

    this.applyModifications();

    if (this.renovationIndex !== null) {
      this.renovationList[this.renovationIndex] = this.selectedRenovation;
    } else {
      this.renovationList.push(this.selectedRenovation);
    }

    this.fetchRenovationList();
    this.renovationListChange.emit(this.renovationList);
    this.closePopup();
  }
}
