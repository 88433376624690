<ng-container>
  <div class="main-container">
    <ui-container>
      <div *ngIf="!readOnly" [formGroup]="headerForm" class="delivery-header-form">
        <div class="grid-item medium">
          <ui-input-text
            type="text"
            mandatory="true"
            *ngIf="!detailHeader"
            formControlName="deliveryRef"
            status="{{ headerForm.get('deliveryRef') | formStatus }}"
            errorMessage="{{ headerForm.get('deliveryRef') | formErrorMessage | translate }}"
            label="{{ 'new-delivery-form.header.fields.delivery-ref' | translate }}"
          >
          </ui-input-text>

          <ui-info-field
            *ngIf="detailHeader"
            [value]="receivingForm.deliveryRef"
            [label]="'new-delivery-form.header.fields.delivery-ref' | translate"
          >
          </ui-info-field>
        </div>

        <div class="grid-item small">
          <ui-date-picker
            mandatory="true"
            [locale]="locale"
            [format]="dateFormat"
            *ngIf="!detailHeader"
            formControlName="documentDate"
            status="{{ headerForm.get('documentDate') | formStatus }}"
            errorMessage="{{ headerForm.get('documentDate') | formErrorMessage | translate }}"
            label="{{ 'new-delivery-form.header.fields.document-date' | translate }}"
          >
          </ui-date-picker>

          <ui-info-field
            *ngIf="detailHeader"
            [label]="'new-delivery-form.header.fields.document-date' | translate"
            [value]="receivingForm.documentDate ? (receivingForm.documentDate | dateTimeFormat: false) : null"
          >
          </ui-info-field>
        </div>

        <div class="grid-item small">
          <ui-date-picker
            [locale]="locale"
            [format]="dateFormat"
            *ngIf="!detailHeader"
            formControlName="deliveryDate"
            status="{{ headerForm.get('deliveryDate') | formStatus }}"
            errorMessage="{{ headerForm.get('deliveryDate') | formErrorMessage | translate }}"
            label="{{ 'new-delivery-form.header.fields.delivery-date' | translate }}"
          >
          </ui-date-picker>

          <ui-info-field
            *ngIf="detailHeader"
            [label]="'new-delivery-form.header.fields.delivery-date' | translate"
            [value]="
              receivingForm.expectedDeliveryDate ? (receivingForm.expectedDeliveryDate | dateTimeFormat: false) : null
            "
          >
          </ui-info-field>
        </div>

        <div class="grid-item small">
          <ui-info-field
            [label]="'new-delivery-form.header.fields.sender' | translate"
            [value]="sender.reference + ' - ' + sender.name"
            *ngIf="sender"
          >
          </ui-info-field>
        </div>

        <div class="grid-item small">
          <ui-info-field
            [label]="'new-delivery-form.header.fields.receiver' | translate"
            [value]="receiver.reference + ' - ' + receiver.name"
            *ngIf="receiver"
          >
          </ui-info-field>
        </div>

        <div class="grid-item medium comment">
          <div class="label">{{ "new-delivery-form.header.fields.comment" | translate }}</div>

          <div class="value">
            <div *ngIf="!headerForm.controls.comment.value && !detailHeader">
              <fa-icon [icon]="faPen" class="comment-icon" (click)="openCommentPopup()"></fa-icon>
            </div>

            <div *ngIf="headerForm.controls.comment.value">
              <div class="ellipsis-with-icon">
                <p class="ellipsis" #commentText>{{ receivingForm.comment }}</p>
                <fa-icon class="icon" [icon]="faPen" *ngIf="!detailHeader" (click)="openCommentPopup()"></fa-icon>

                <ui-tooltip maxWidth="300px" [element]="commentText">
                  <span style="font-weight: normal !important; white-space: pre-wrap !important">
                    {{ receivingForm.comment }}
                  </span>
                </ui-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="readOnly" class="delivery-header-form">
        <div class="grid-item large">
          <div class="multi-col">
            <p class="title">{{ "new-delivery-form.header.fields.document-ref" | translate | uppercase }}</p>
            <p>{{ receivingForm.deliveryRef }}</p>
          </div>

          <div class="multi-col">
            <p class="title">{{ "new-delivery-form.header.fields.sender" | translate | uppercase }}</p>
            <ng-container *ngIf="!sender">
              <p>{{ "new-delivery-form.header.fields.undefined" | translate }}</p>
            </ng-container>
            <ng-container *ngIf="sender">
              <p class="ellipsis" #commentText>{{ sender.reference + " - " + sender.name }}</p>
              <ui-tooltip maxWidth="300px" [element]="commentText">
                {{ sender.reference + " - " + sender.name }}
              </ui-tooltip>
            </ng-container>
          </div>
        </div>

        <div class="grid-item large">
          <div class="multi-col">
            <p class="title">{{ "new-delivery-form.header.fields.document-date" | translate | uppercase }}</p>
            <p>{{ receivingForm.documentDate | dateTimeFormat: false }}</p>
          </div>

          <div class="multi-col">
            <p class="title">{{ "new-delivery-form.header.fields.receiver" | translate | uppercase }}</p>
            <ng-container *ngIf="!receiver">
              <p>{{ "new-delivery-form.header.fields.undefined" | translate }}</p>
            </ng-container>
            <ng-container *ngIf="receiver">
              <p class="ellipsis" #commentText>{{ receiver.reference + " - " + receiver.name }}</p>
              <ui-tooltip maxWidth="300px" [element]="commentText">
                {{ receiver.reference + " - " + receiver.name }}
              </ui-tooltip>
            </ng-container>
          </div>
        </div>

        <div class="grid-item large">
          <div class="multi-col">
            <p class="title">{{ "new-delivery-form.header.fields.delivery-date" | translate | uppercase }}</p>
            <p>
              {{
                receivingForm.expectedDeliveryDate
                  ? (receivingForm.expectedDeliveryDate | dateTimeFormat: false)
                  : ("new-delivery-form.header.fields.undefined" | translate)
              }}
            </p>
          </div>

          <div class="multi-col">
            <p class="title">{{ "new-delivery-form.header.fields.comment" | translate | uppercase }}</p>
            <ng-container *ngIf="!receivingForm.comment">
              <p>{{ "new-delivery-form.header.fields.undefined" | translate }}</p>
            </ng-container>
            <ng-container *ngIf="receivingForm.comment">
              <p class="ellipsis" #commentText>{{ receivingForm.comment }}</p>
              <ui-tooltip maxWidth="300px" [element]="commentText">
                {{ receivingForm.comment }}
              </ui-tooltip>
            </ng-container>
          </div>
        </div>
      </div>
    </ui-container>
  </div>

  <div class="popup-container">
    <ui-popup
      title="{{ 'new-delivery-form.header.popup.title' | translate }}"
      *ngIf="popupVisible"
      (close)="closePopup()"
    >
      <div content>
        <form [formGroup]="popupForm" class="delivery-header-form">
          <ui-textarea
            label="{{ 'new-delivery-form.header.fields.comment' | translate }}"
            formControlName="comment"
            maxLength="1024"
          ></ui-textarea>
        </form>
      </div>

      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <ui-button
              text="{{ 'global.validate' | translate }}"
              (click)="saveComment()"
              [primary]="true"
              type="save"
            ></ui-button>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
