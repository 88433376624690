import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: "[max]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true }],
})
export class MaxValidatorDirective implements Validator {
  @Input("max") max: number;

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value > this.max) {
      return { max: true };
    } else {
      return null;
    }
  }
}
