import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  StoreService,
  PricingGroupService,
  CaraUserService,
  TagService,
  Store,
  StoreStatusType,
  PricingGroup,
  AsynchronousTaskService,
  AsynchronousTaskCreation,
} from "center-services";
import { MessageService, Tag as TagComponent, ToastMessageService } from "fugu-components";
import { Observable, of } from "rxjs";
import { BaseStoreListComponent } from "../util/base-store-list-component";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "app-store-list",
  templateUrl: "./store-list.component.html",
  styleUrls: ["./store-list.component.scss"],
  providers: [SubscriptionService],
})
export class StoreListComponent extends BaseStoreListComponent implements OnInit, AfterViewChecked {
  @Input() canUpdate: boolean = true;
  @Input() canAdd: boolean = true;

  // override  LIST_ID
  public LIST_ID: string = "base-store-list.store-table";

  public sorts: any[] = [
    {
      prop: "mainStore",
      dir: "desc",
    },
    {
      prop: "statusIndex",
      dir: "asc",
    },
    {
      prop: "name",
      dir: "asc",
    },
  ];

  public tableControl: UntypedFormGroup;

  constructor(
    storeService: StoreService,
    translateService: TranslateService,
    messageService: MessageService,
    pricingGroupService: PricingGroupService,
    private cdRef: ChangeDetectorRef,
    private asynchronousTaskService: AsynchronousTaskService,
    userService: CaraUserService,
    tagService: TagService,
    private router: Router,
    private notificationHandlerService: NotificationHandlerService,
    private toastMessageService: ToastMessageService,
    protected subscriptionService: SubscriptionService
  ) {
    super(
      storeService,
      translateService,
      messageService,
      pricingGroupService,
      userService,
      tagService,
      subscriptionService
    );

    if (!this.tableControl) {
      this.tableControl = new UntypedFormGroup({});
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  addRow(store: Store, tags: TagComponent[]): void {
    const statusLabel = this.translateService.instant(
      `store.general-datas.status-options.${store.status.toUpperCase()}`
    );

    this.rows.push({
      id: store.id,
      name: store.name,
      reference: store.reference,
      status: store.status,
      city: store.address.city,
      pricingGroup: this.allPricingGroups.find(group => group.id === store.pricingGroupId),
      activated: !store.archived,
      tags,
      statusIndex: Object.keys(this.statusTranslations).indexOf(statusLabel),
      mainStore: store.mainStore,
    });

    const isDisabled = !this.userService.canDo("GOD") || store.status !== StoreStatusType.ACTIVE || store.mainStore;
    if (this.tableControl.controls[`activated_${store.id}`]) {
      this.tableControl.controls[`activated_${store.id}`].patchValue(store.mainStore);
      if (isDisabled) {
        this.tableControl.controls[`activated_${store.id}`].disable();
      } else {
        this.tableControl.controls[`activated_${store.id}`].enable();
      }
    } else {
      this.tableControl.addControl(
        `activated_${store.id}`,
        new UntypedFormControl({ value: store.mainStore, disabled: isDisabled })
      );
    }
  }

  exportStores(): void {
    if (!this.userService.canDo("STORE_READ")) {
      return;
    }
    const asyncCreationTask = {
      type: "exportXLS",
      params: `store;${this.activeFilters?.map(filter => filter.toQuery()).join(",")};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  importStores(): void {
    if (!this.userService.canDo("STORE_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/stores-list/import");
  }

  createStore(): void {
    if (!this.userService.canDo("STORE_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/store/add");
  }

  updateStore(event: { type: string; row: { id: number } }): void {
    if (event.type === "click") {
      if (!this.userService.canDo("STORE_UPDATE")) {
        return;
      }
      const filteredList = this.storeList.filter(store => store.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find store with id ${event.row.id}`);
        return;
      }
      this.sessionPagination.saveToSession(this.LIST_ID);
      this.router.navigateByUrl(`/store/update/${event.row.id}`);
    }
  }

  onRowCheckBoxChange(id: number): void {
    const storeStatus = this.storeList.find((store: Store) => store.id === id).status;
    if (!this.userService.canDo("GOD") || storeStatus !== StoreStatusType.ACTIVE) {
      return;
    }

    this.subscriptionService.subs.push(
      this.storeService.setMain(id).subscribe({
        error: error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("item-group-form.errors.get-store-module", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        },
        complete: () => {
          this.fetchStoreList();
        },
      })
    );
  }

  initFilters(): void {
    super.initFilters();

    this.filterer.addListFilter(
      "pricingGroup.id",
      this.translateService.instant("stores-list.datatable.columns.pricing-groups"),
      this.allPricingGroups.map((pricingGroup: PricingGroup) => {
        return { value: pricingGroup.id.toString(), displayValue: pricingGroup.name };
      })
    );
  }

  changeSortSettings(prop: any, direction: any): void {
    switch (prop) {
      case "statusIndex":
        this.sorts = [
          {
            prop: "mainStore",
            dir: "desc",
          },
          {
            prop: "statusIndex",
            dir: direction,
          },
        ];
        break;

      case "type":
        this.sorts = [
          {
            prop: "mainStore",
            dir: "desc",
          },
          {
            prop: "statusIndex",
            dir: "asc",
          },
          {
            prop,
            dir: direction,
          },
        ];
        break;

      default:
        this.sorts = [
          {
            prop: "mainStore",
            dir: "desc",
          },
          {
            prop: "statusIndex",
            dir: "asc",
          },
          {
            prop,
            dir: direction,
          },
        ];
        break;
    }

    this.fetchStoreList();
  }

  protected fetchOtherEntities(): Observable<any> {
    return of({});
  }
}
