import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { NgxPermissionsModule } from "ngx-permissions";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { IModuleTranslationOptions, ModuleTranslateLoader } from "@larscom/ngx-translate-module-loader";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { AuthInterceptor, DownServerInterceptor, GenericPagesModule } from "generic-pages";
import { ItemCategoriesListComponent } from "./settings/item-categories-list/item-categories-list.component";
import { StoreFormComponent } from "./store/store-form/store-form.component";
import { SizeCategoriesListComponent } from "./settings/size-categories-list/size-categories-list.component";
import { ClassificationsListComponent } from "./settings/classifications-list/classifications-list.component";
import { PricingGroupsListComponent } from "./settings/pricing-groups-list/pricing-groups-list.component";
import { StoreBrandComponent } from "./settings/store-brands-list/store-brands.list.component";
import { GeneralDatasComponent } from "./store/general-datas/general-datas.component";
import { EmployeesListComponent } from "./store/employees/employees-list/employees-list.component";
import { EmployeesPopupComponent } from "./store/employees/employees-popup/employees-popup.component";

import { ComponentsModule } from "fugu-components";
import { NgxDatatableModule } from "@siemens/ngx-datatable";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxFileDropModule } from "ngx-file-drop";
import { DragDropModule } from "@angular/cdk/drag-drop";

import localeFr from "@angular/common/locales/fr"; // tslint:disable-line
import { registerLocaleData } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FinanceComponent } from "./store/finance/finance.component";
import { StoreListComponent } from "./store/store-list/store-list.component";
import { PromotionsListComponent } from "./settings/promotion-list/promotions-list.component";
import { UserFormComponent } from "./settings/user-form/user-form.component";
import { CaraUsersListComponent } from "./settings/cara-users-list/cara-users-list.component";
import { SupplierFormComponent } from "./supplier/supplier-form/supplier-form.component";
import { SupplierGeneralComponent } from "./supplier/supplier-general/supplier-general.component";
import { CharacteristicsComponent } from "./store/characteristics/characteristics.component";
import { OpeningTimesComponent } from "./store/opening-times/opening-times.component";
import { RenovationComponent } from "./store/renovation/renovation.component";
import { SupplierListComponent } from "./supplier/supplier-list/supplier-list.component";
import { BrandFormComponent } from "./brand/brand-form/brand-form.component";
import { BrandGeneralComponent } from "./brand/brand-general/brand-general.component";
import { OverrideSizeCategoryPopupComponent } from "./common/size-category/override-size-category-popup/override-size-categories-popup.component";
import { OverrideSizeCategoriesListComponent } from "./common/size-category/override-size-category-list/override-size-categories-list.component";
import { BrandListComponent } from "./brand/brand-list/brand-list.component";
import { OrderBrandsComponent } from "./store/order-brands/order-brands.component";
import { BrandSuppliersComponent } from "./brand/brand-suppliers/brand-suppliers.component";
import { SupplierBrandsComponent } from "./supplier/supplier-brands/supplier-brands.component";
import { BrandCollectionListComponent } from "./brand/brand-collection/brand-collection-list/brand-collection-list.component";
import { BrandCollectionPopupComponent } from "./brand/brand-collection/brand-collection-popup/brand-collection-popup.component";
import { ItemUniversesListComponent } from "./settings/item-universes-list/item-universes-list.component";
import { SupplierStoresComponent } from "./supplier/supplier-stores/supplier-stores.component";
import { RetailItemFormComponent } from "./retail-item/retail-item-form/retail-item-form.component";
import { RetailItemGeneralComponent } from "./retail-item/retail-item-general/retail-item-general.component";
import { RetailItemListComponent } from "./retail-item/retail-item-list/retail-item-list.component";
import { RetailItemSelectionComponent } from "./common/retail-item-selection/retail-item-selection.component";
import { RetailItemClassificationsComponent } from "./retail-item/retail-item-classifications/retail-item-classifications.component";
import { RetailItemSaleTermsComponent } from "./retail-item/retail-item-sale-terms/retail-item-sale-terms.component";
import { ItemGroupsListComponent } from "./settings/item-groups/item-groups-list/item-groups-list.component";
import { ItemGroupFormComponent } from "./settings/item-groups/item-groups-form/item-groups-form.component";
import { ComparableSurfaceComponent } from "./store/comparable-surface/comparable-surface.component";
import { RetailItemPurchaseModalitiesListComponent } from "./retail-item/retail-item-purchase-modalities/retail-item-purchase-modalities-list/retail-item-purchase-modalities-list.component";
import { RetailItemGroupListComponent } from "./retail-item/retail-item-group-list/retail-item-group-list.component";
import { StoreModuleEntryPopupComponent } from "./settings/item-groups/store-module-entry-popup/store-module-entry-popup.component";
import { RetailItemPurchaseModalitiesPopupComponent } from "./retail-item/retail-item-purchase-modalities/retail-item-purchase-modalities-popup/retail-item-purchase-modalities-popup.component";
import { RetailItemStockComponent } from "./retail-item/retail-item-stock/retail-item-stock.component";
import { CommercialModalityComponent } from "./common/commercial-modality/commercial-modality.component";
import { MetalAccountTabComponent } from "./metal-account/metal-account-tab/metal-account-tab.component";
import { StockEntryListComponent } from "./retail-item/stock-entry-list/stock-entry-list.component";
import { StockEntryDetailComponent } from "./stock/stock-entry-detail/stock-entry-detail.component";
import { MetalAccountPopupComponent } from "./metal-account/metal-account-popup/metal-account-popup.component";
import { MetalAccountDetailComponent } from "./metal-account/metal-account-detail/metal-account-detail.component";
import { PoliceBookMovementsListComponent } from "./police-book-movements-list/police-book-movements-list.component";
import { PurchaseModalitySelectionComponent } from "./common/purchase-modality-selection/purchase-modality-selection.component";

import { AlloyCompositionComponent } from "./common/alloy-composition/alloy-composition.component";
import { EncodeHttpParamsInterceptor } from "./app.interceptor";
import { PurchaseOrderFormComponent } from "./purchase-order/purchase-order-form/purchase-order-form.component";
import { PurchaseOrderHeaderComponent } from "./purchase-order/purchase-order-header/purchase-order-header.component";
import { PurchaseOrderLinesComponent } from "./purchase-order/purchase-order-lines/purchase-order-lines.component";
import { EngravingPopupComponent } from "./purchase-order/engraving-popup/engraving-popup.component";
import { PurchaseOrderSummaryComponent } from "./purchase-order/purchase-order-summary/purchase-order-summary.component";
import { PurchaseOrderSummaryListComponent } from "./purchase-order/purchase-order-summary-list/purchase-order-summary-list.component";
import { StockListComponent } from "./stock/stock-list/stock-list.component";
import { InventoryListComponent } from "./inventory/inventory-list/inventory-list.component";
import { InventoryCriteriaComponent } from "./inventory/inventory-criteria/inventory-criteria.component";
import { PurchaseOrderListComponent } from "./purchase-order/purchase-order-list/purchase-order-list.component";
import { PurchaseOrderDetailComponent } from "./purchase-order/purchase-order-detail/purchase-order-detail.component";
import { PurchaseOrderLinesDetailComponent } from "./purchase-order/purchase-order-lines-detail/purchase-order-lines-detail.component";
import { PurchaseOrderHeaderDetailComponent } from "./purchase-order/purchase-order-header-detail/purchase-order-header-detail.component";
import { PoLineDuplicationPopupComponent } from "./purchase-order/po-line-duplication-popup/po-line-duplication-popup.component";
import { PurchaseOrderStatusPopupComponent } from "./purchase-order/purchase-order-status-popup/purchase-order-status-popup.component";
import { DeliveryInitiatorPopupComponent } from "./new-receipt/delivery-initiator-popup/delivery-initiator-popup.component";
import { StockPriorizationComponent } from "./store/stock-priorization/stock-priorization.component";
import { CreateStockMovementPopupComponent } from "./stock/create-stock-movement-popup/create-stock-movement-popup.component";
import { ManualStockEntryFormComponent } from "./manual-stock-entry/manual-stock-entry-form/manual-stock-entry-form.component";
import { ItemListComponent } from "./common/item-list/item-list.component";
import { OutOfStockPopupComponent } from "./stock/out-of-stock-popup/out-of-stock-popup.component";
import { ManualStockEntryDataComponent } from "./manual-stock-entry/manual-stock-entry-data/manual-stock-entry-data.component";
import { InventoryFormComponent } from "./inventory/inventory-form/inventory-form.component";
import { InventoryInitiatorPopupComponent } from "./inventory/inventory-initiator-popup/inventory-initiator-popup.component";
import { StockEntryLabelListComponent } from "./stock-entry-label/stock-entry-label-list/stock-entry-label-list.component";
import { StockEntryLabelPrintSettingsPopupComponent } from "./stock-entry-label/stock-entry-label-print-settings-popup/stock-entry-label-print-settings-popup.component";
import { StockEntryLabelDeletePopupComponent } from "./stock-entry-label/stock-entry-label-delete-popup/stock-entry-label-delete-popup.component";
import { PageNotFoundComponent } from "./common/page-not-found/page-not-found.component";
import { ReceiptValidationPopupComponent } from "./new-receipt/receipt-validation-popup/receipt-validation-popup.component";
import { HomePageComponent } from "./common/home-page/home-page.component";
import { InvoiceSupplierInitiatorPopupComponent } from "./invoice/invoice-supplier/invoice-supplier-initiator-popup/invoice-supplier-initiator-popup.component";
import { InventoryEntryComponent } from "./inventory/inventory-entry/inventory-entry.component";
import { InvoiceSupplierDeliverySelectionComponent } from "./invoice/invoice-supplier/invoice-supplier-delivery-selection/invoice-supplier-delivery-selection.component";
import { TechnicalErrorPageComponent } from "./common/technical-error-page/technical-error-page.component";
import { InvoiceSupplierFormComponent } from "./invoice/invoice-supplier/invoice-supplier-form/invoice-supplier-form.component";
import { InvoiceFormHeaderComponent } from "./invoice/invoice-form-header/invoice-form-header.component";
import { InvoiceSupplierListComponent } from "./invoice/invoice-supplier/invoice-supplier-list/invoice-supplier-list.component";
import { AppConfigComponent } from "./settings/app-config/app-config.component";
import { InvoiceSupplierPmSelectionComponent } from "./invoice/invoice-supplier/invoice-supplier-pm-selection/invoice-supplier-pm-selection.component";
import { InvoiceCustomerInitiatorPopupComponent } from "./invoice/invoice-customer/invoice-customer-initiator-popup/invoice-customer-initiator-popup.component";
import { InvoiceCustomerDeliverySelectionComponent } from "./invoice/invoice-customer/invoice-customer-delivery-selection/invoice-customer-delivery-selection.component";
import { InventoryResultComponent } from "./inventory/inventory-result/inventory-result.component";
import { ProfileManagementComponent } from "./menu-footer/profile-management/profile-management.component";
import { ImportFileComponent } from "./common/import-file/import-file.component";
import { NewReceiptListComponent } from "./new-receipt/new-receipt-list/new-receipt-list.component";
import { ReceivingFormComponent } from "./new-receipt/receiving-form/receiving-form.component";
import { NewDeliveryLinesComponent } from "./new-receipt/new-delivery-lines/new-delivery-lines.component";
import { NewDeliveryFormHeaderComponent } from "./new-receipt/new-delivery-form-header/new-delivery-form-header.component";
import { CashRegistersListComponent } from "./store/cash-registers/cash-registers-list/cash-registers-list.component";
import { CashRegistersPopupComponent } from "./store/cash-registers/cash-registers-popup/cash-registers-popup.component";
import { ShipmentFormListComponent } from "./shipment-form/shipment-form-list/shipment-form-list/shipment-form-list.component";
import { ShipmentFormStatusPopupComponent } from "./shipment-form/shipment-form-list/shipment-form-status-popup/shipment-form-status-popup.component";
import { ShipmentFormHeaderComponent } from "./shipment-form/shipment-form-header/shipment-form-header.component";
import { ShipmentFormInfoPopupComponent } from "./shipment-form/shipment-form-list/shipment-form-info-popup/shipment-form-info-popup.component";
import { ShipmentInitiatorPopupComponent } from "./shipment-form/shipment-initiator-popup/shipment-initiator-popup.component";
import { ShipmentFormComponent } from "./shipment-form/shipment-form/shipment-form.component";
import { ShipmentFormLinesComponent } from "./shipment-form/shipment-form-lines/shipment-form-lines.component";
import { FuguCommonModule } from "fugu-common";
import { PendingDeliveryChoicePopupComponent } from "./new-receipt/pending-delivery-choice-popup/pending-delivery-choice-popup.component";
import { NewDeliveryFormValidationPopupComponent } from "./new-receipt/new-delivery-form-validation-popup/new-delivery-form-validation-popup.component";
import { MultipleLocationSelectionPopupComponent } from "./shipment-form/multiple-location-selection-popup/multiple-location-selection-popup.component";
import { ShipmentFormValidationPopupComponent } from "./shipment-form/shipment-form-validation-popup/shipment-form-validation-popup.component";
import { ShipmentStockEntrySelectionPopupComponent } from "./shipment-form/shipment-stock-entry-selection-popup/shipment-stock-entry-selection-popup.component";
import { NewDeliveryFormUpdateReceivePopupComponent } from "./new-receipt/new-delivery-form-update-receive-popup/new-delivery-form-update-receive-popup.component";
import { EditStockPopupComponent } from "./stock/edit-stock-popup/edit-stock-popup.component";
import { MultiShipmentFormComponent } from "./shipment-form/multi-shipment-form/multi-shipment-form.component";
import { SplitDeliveryLinePopupComponent } from "./new-receipt/split-delivery-line-popup/split-delivery-line-popup.component";
import { StoreSelectionPopupComponent } from "./menu-footer/store-selection-popup/store-selection-popup.component";
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { ForgottenPasswordPopupComponent } from "./login/forgotten-password-popup/forgotten-password-popup.component";
import { ChangePasswordPopupComponent } from "./login/change-password-popup/change-password-popup.component";
import { CustomerFormComponent } from "./customer/customer-form/customer-form.component";
import { CustomerListComponent } from "./customer/customer-list/customer-list.component";
import { CustomizationComponent } from "./customization/customization.component";
import { CustomerStoresComponent } from "./customer/customer-stores/customer-stores.component";
import { InvoiceCustomerListComponent } from "./invoice/invoice-customer/invoice-customer-list/invoice-customer-list.component";
import { DisableStoreWarningPopupComponent } from "./common/disable-store-warning-popup/disable-store-warning-popup.component";
import { CenterServicesModule, UserService, CustomTranslateService, CaraUserService } from "center-services";
import { InvoiceCustomerFormComponent } from "./invoice/invoice-customer/invoice-customer-form/invoice-customer-form.component";
import { FreeInvoiceCustomerInitiatorPopupComponent } from "./invoice/invoice-customer/free-invoice-customer-initiator-popup/free-invoice-customer-initiator-popup.component";
import { LoaderComponent } from "./loader/loader.component";
import { LottieModule } from "ngx-lottie";
import { LoaderInterceptor } from "./loader.interceptor";
import { InvoiceCustomerValidationComponent } from "./invoice/invoice-customer/invoice-customer-validation/invoice-customer-validation.component";
import { BatchGeneratePopupComponent } from "./invoice/invoice-customer/batch-generate-popup/batch-generate-popup.component";
import { InvoiceCustomerSelectorPopupComponent } from "./credit-note/invoice-customer-selector-popup/invoice-customer-selector-popup.component";
import { CustomerDocumentPopupComponent } from "./customer/customer-document-popup/customer-document-popup.component";
import { CustomerDocumentHistoryComponent } from "./customer/customer-document-history/customer-document-history.component";
import { HistorizationListComponent } from "./historization/historization-list/historization-list.component";
import { FluxPopupComponent } from "./historization/flux-popup/flux-popup.component";
import { CustomerIndividualGeneralDatasComponent } from "./customer/customer-individual-general-datas/customer-individual-general-datas";
import { CustomerProfessionalGeneralDatasComponent } from "./customer/customer-professional-general-datas/customer-professional-general-datas";
import { ReceivingDetailsComponent } from "./new-receipt/receiving-details/receiving-details.component";
import { NotificationHandlerComponent } from "./common/notification-handler/notification-handler.component";
import { InvoiceCustomerListValidationComponent } from "./invoice/invoice-customer/invoice-customer-list-validation/invoice-customer-list-validation.component";
import { CreditNoteCustomerFormComponent } from "./credit-note/credit-note-customer-form/credit-note-customer-form.component";
import { MaxValidatorDirective } from "./credit-note/util/max-validator.directive";
import { CreditNoteCustomerDetailsComponent } from "./credit-note/credit-note-customer-details/credit-note-customer-details.component";
import { CreditNoteValidationComponent } from "./credit-note/credit-note-validation-popup/credit-note-validation.component";
import { MinValidatorDirective } from "./credit-note/util/min-validator.directive";
import { FileSaverModule } from "ngx-filesaver";
import { MetalAccountGeneralComponent } from "./metal-account/metal-account-general/metal-account-general.component";
import { RetailItemNomenclatureComponent } from "./retail-item/retail-item-nomenclatures/retail-item-nomenclature/retail-item-nomenclature.component";
import { RetailItemNomenclaturePopupComponent } from "./retail-item/retail-item-nomenclatures/retail-item-nomenclature-popup/retail-item-nomenclature-popup.component";
import { RetailItemNomenclatureItemSelectorComponent } from "./retail-item/retail-item-nomenclatures/retail-item-nomenclature-item-selector/retail-item-nomenclature-item-selector.component";
import { InvoiceDateWarningPopupComponent } from "./invoice/invoice-customer/invoice-date-warning-popup/invoice-date-warning-popup.component";
import { ContactSupportPopupComponent } from "./help-menu/contact-support-popup/contact-support-popup.component";
import { RetailItemNomenclatureLinesFormComponent } from "./retail-item/retail-item-nomenclatures/retail-item-nomenclature-lines-form/retail-item-nomenclature-lines-form.component";
import { RetailItemNomenclaturesListComponent } from "./retail-item/retail-item-nomenclatures/retail-item-nomenclatures-list/retail-item-nomenclatures-list.component";

registerLocaleData(localeFr);

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient): ModuleTranslateLoader {
  const options: IModuleTranslationOptions = {
    modules: [{ baseTranslateUrl: "./generic-assets/i18n/" }, { baseTranslateUrl: "./assets/i18n" }],
  };

  return new ModuleTranslateLoader(http, options);
}

// Method used to preload app translations
export function appInitializerFactory(translate: TranslateService) {
  return (): Promise<any> => {
    translate.setDefaultLang("fr");
    return translate.use("fr").toPromise();
  };
}

export function playerFactory(): any {
  return import("lottie-web");
}

@NgModule({
  declarations: [
    AppComponent,
    ItemCategoriesListComponent,
    SizeCategoriesListComponent,
    ClassificationsListComponent,
    PricingGroupsListComponent,
    StoreFormComponent,
    StoreBrandComponent,
    GeneralDatasComponent,
    EmployeesListComponent,
    EmployeesPopupComponent,
    FinanceComponent,
    StoreListComponent,
    PromotionsListComponent,
    UserFormComponent,
    CaraUsersListComponent,
    SupplierFormComponent,
    SupplierGeneralComponent,
    CharacteristicsComponent,
    OpeningTimesComponent,
    RenovationComponent,
    ComparableSurfaceComponent,
    SupplierListComponent,
    OverrideSizeCategoriesListComponent,
    OverrideSizeCategoryPopupComponent,
    BrandFormComponent,
    BrandGeneralComponent,
    BrandListComponent,
    OrderBrandsComponent,
    BrandSuppliersComponent,
    SupplierBrandsComponent,
    BrandCollectionListComponent,
    BrandCollectionPopupComponent,
    ItemUniversesListComponent,
    SupplierStoresComponent,
    RetailItemFormComponent,
    RetailItemGeneralComponent,
    RetailItemListComponent,
    RetailItemSelectionComponent,
    PageNotFoundComponent,
    RetailItemClassificationsComponent,
    RetailItemSaleTermsComponent,
    ItemGroupsListComponent,
    ItemGroupFormComponent,
    RetailItemPurchaseModalitiesListComponent,
    StoreModuleEntryPopupComponent,
    RetailItemPurchaseModalitiesPopupComponent,
    RetailItemGroupListComponent,
    RetailItemStockComponent,
    RetailItemNomenclatureComponent,
    RetailItemNomenclaturesListComponent,
    RetailItemNomenclaturePopupComponent,
    RetailItemNomenclatureItemSelectorComponent,
    RetailItemNomenclatureLinesFormComponent,
    CommercialModalityComponent,
    MetalAccountTabComponent,
    StockEntryListComponent,
    StockEntryDetailComponent,
    MetalAccountPopupComponent,
    MetalAccountDetailComponent,
    PoliceBookMovementsListComponent,
    AlloyCompositionComponent,
    PurchaseOrderFormComponent,
    PurchaseOrderHeaderComponent,
    PurchaseOrderLinesComponent,
    EngravingPopupComponent,
    PurchaseModalitySelectionComponent,
    PurchaseOrderSummaryComponent,
    PurchaseOrderSummaryListComponent,
    StockListComponent,
    InventoryListComponent,
    InventoryCriteriaComponent,
    PurchaseOrderListComponent,
    PurchaseOrderDetailComponent,
    PurchaseOrderLinesDetailComponent,
    PurchaseOrderHeaderDetailComponent,
    PoLineDuplicationPopupComponent,
    PurchaseOrderStatusPopupComponent,
    DeliveryInitiatorPopupComponent,
    StockPriorizationComponent,
    CreateStockMovementPopupComponent,
    ManualStockEntryFormComponent,
    ItemListComponent,
    OutOfStockPopupComponent,
    ManualStockEntryDataComponent,
    InventoryFormComponent,
    InventoryInitiatorPopupComponent,
    StockEntryLabelListComponent,
    StockEntryLabelPrintSettingsPopupComponent,
    StockEntryLabelDeletePopupComponent,
    ReceiptValidationPopupComponent,
    HomePageComponent,
    InvoiceSupplierInitiatorPopupComponent,
    InventoryEntryComponent,
    InvoiceSupplierDeliverySelectionComponent,
    TechnicalErrorPageComponent,
    InvoiceSupplierFormComponent,
    InvoiceFormHeaderComponent,
    InvoiceSupplierListComponent,
    InvoiceSupplierPmSelectionComponent,
    InvoiceCustomerInitiatorPopupComponent,
    InvoiceCustomerDeliverySelectionComponent,
    InvoiceCustomerSelectorPopupComponent,
    AppConfigComponent,
    InventoryResultComponent,
    ProfileManagementComponent,
    ImportFileComponent,
    NewReceiptListComponent,
    ReceivingFormComponent,
    NewDeliveryLinesComponent,
    NewDeliveryFormHeaderComponent,
    CashRegistersListComponent,
    CashRegistersPopupComponent,
    ShipmentFormListComponent,
    ShipmentFormStatusPopupComponent,
    ShipmentFormHeaderComponent,
    ShipmentFormInfoPopupComponent,
    ShipmentInitiatorPopupComponent,
    ShipmentFormComponent,
    ShipmentFormLinesComponent,
    PendingDeliveryChoicePopupComponent,
    NewDeliveryFormValidationPopupComponent,
    MultipleLocationSelectionPopupComponent,
    ShipmentFormValidationPopupComponent,
    ShipmentStockEntrySelectionPopupComponent,
    NewDeliveryFormUpdateReceivePopupComponent,
    EditStockPopupComponent,
    MultiShipmentFormComponent,
    SplitDeliveryLinePopupComponent,
    StoreSelectionPopupComponent,
    LoginFormComponent,
    ForgottenPasswordPopupComponent,
    ChangePasswordPopupComponent,
    CustomerFormComponent,
    CustomerProfessionalGeneralDatasComponent,
    CustomerIndividualGeneralDatasComponent,
    CustomerListComponent,
    CustomizationComponent,
    CustomerStoresComponent,
    InvoiceCustomerListComponent,
    DisableStoreWarningPopupComponent,
    InvoiceCustomerFormComponent,
    FreeInvoiceCustomerInitiatorPopupComponent,
    LoaderComponent,
    InvoiceCustomerValidationComponent,
    BatchGeneratePopupComponent,
    CustomerDocumentPopupComponent,
    CustomerDocumentHistoryComponent,
    HistorizationListComponent,
    FluxPopupComponent,
    ReceivingDetailsComponent,
    NotificationHandlerComponent,
    InvoiceCustomerListValidationComponent,
    CreditNoteCustomerFormComponent,
    MaxValidatorDirective,
    MinValidatorDirective,
    CreditNoteCustomerDetailsComponent,
    CreditNoteValidationComponent,
    MetalAccountGeneralComponent,
    InvoiceDateWarningPopupComponent,
    ContactSupportPopupComponent,
  ],
  imports: [
    ComponentsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DragDropModule,
    GenericPagesModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxFileDropModule,
    FuguCommonModule,
    CenterServicesModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LottieModule.forRoot({ player: playerFactory }),
    FileSaverModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DownServerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    { provide: UserService, useExisting: CaraUserService },
    { provide: TranslateService, useExisting: CustomTranslateService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
