<div class="pane">
  <form class="order-header" [formGroup]="headerForm" novalidate>
    <div class="grid-item">
      <div class="supplier">
        <div class="supplier-list" *ngIf="!this.isUpdated">
          <ui-list
            label="{{ 'purchase-order.header.supplier.label' | translate }}"
            [status]="headerForm.get('supplier') | formStatus"
            [errorMessage]="headerForm.get('supplier') | formErrorMessage | translate"
            [options]="supplierOptions"
            formControlName="supplier"
            [mandatory]="true"
            [search]="true"
          ></ui-list>
        </div>
        <div class="supplier-info-field" *ngIf="this.isUpdated">
          <ui-info-field
            [label]="'purchase-order.header.supplier.label' | translate"
            [value]="this.supplier?.reference + ' - ' + this.supplier?.name"
          >
          </ui-info-field>
        </div>
      </div>

      <div class="order-type">
        <ui-list
          label="{{ 'purchase-order.header.order-type' | translate }}"
          [status]="headerForm.get('orderType') | formStatus"
          [errorMessage]="headerForm.get('orderType') | formErrorMessage | translate"
          [options]="orderTypeOptions"
          formControlName="orderType"
          [mandatory]="true"
        ></ui-list>
      </div>
      <div class="currency">
        <div class="currency-list" *ngIf="!this.isUpdated">
          <ui-list
            label="{{ 'purchase-order.header.currency' | translate }}"
            [status]="headerForm.get('currency') | formStatus"
            [errorMessage]="headerForm.get('currency') | formErrorMessage | translate"
            [options]="currencyOptions"
            formControlName="currency"
            [mandatory]="true"
          ></ui-list>
        </div>
        <div class="currency-info-field" *ngIf="this.isUpdated">
          <ui-info-field [label]="'purchase-order.header.currency' | translate" [value]="this.selectedCurrency?.symbol">
          </ui-info-field>
        </div>
      </div>

      <div class="status">
        <ui-info-field
          [label]="'purchase-order.header.status' | translate"
          [value]="'purchase-order.header.order-status-options.' + this.editedPurchaseOrder?.status | translate"
        >
        </ui-info-field>
      </div>
    </div>

    <div class="grid-item">
      <div class="delivery-store">
        <ui-list
          label="{{ 'purchase-order.header.delivery-store' | translate }}"
          [status]="headerForm.get('deliveryStore') | formStatus"
          [errorMessage]="headerForm.get('deliveryStore') | formErrorMessage | translate"
          formControlName="deliveryStore"
          [options]="storeOptions"
          [mandatory]="true"
        ></ui-list>
      </div>
      <div class="delivery-address">
        <ui-list
          label="{{ 'purchase-order.header.delivery-address' | translate }}"
          [status]="headerForm.get('deliveryAddress') | formStatus"
          [errorMessage]="headerForm.get('deliveryAddress') | formErrorMessage | translate"
          formControlName="deliveryAddress"
          [options]="storeAddressOptions"
          [mandatory]="true"
        ></ui-list>
      </div>
      <div class="billing-company">
        <ui-info-field [label]="'purchase-order.header.billing-company' | translate" [value]="this.companyConfig?.name">
        </ui-info-field>
      </div>
      <div class="billing-address">
        <ui-info-field
          [label]="'purchase-order.header.billing-address' | translate"
          [value]="
            this.companyConfig?.address?.lines +
            ', ' +
            this.companyConfig?.address?.cityCode +
            ' ' +
            this.companyConfig?.address?.city
          "
        >
        </ui-info-field>
      </div>
    </div>

    <div class="grid-item">
      <div class="min-order-price" *ngIf="supplier">
        <ui-info-field
          [label]="'purchase-order.header.min-order-price' | translate"
          value="{{
            minOrderPrice !== null && selectedCurrency
              ? (minOrderPrice | formatCurrency: selectedCurrency.codeISO:'symbol':'0.2-5':codeLanguage)
              : ('purchase-order.header.undefined' | translate)
          }}"
        >
        </ui-info-field>
      </div>

      <div class="max-payment" *ngIf="supplier">
        <ui-info-field [label]="'purchase-order.header.max-payment' | translate" [value]="maxPayment"> </ui-info-field>
      </div>

      <div class="shipping-fee-price" *ngIf="supplier && shippingFeeType === 'PRICE'">
        <ui-info-field
          [label]="'purchase-order.header.shipping-fee-price' | translate"
          value="{{
            shippingFeePrice !== null && selectedCurrency
              ? (shippingFeePrice | formatCurrency: selectedCurrency.codeISO:'symbol':'0.2-5':codeLanguage)
              : ('purchase-order.header.undefined' | translate)
          }}"
        >
        </ui-info-field>
      </div>

      <div class="shipping-fee-price" *ngIf="supplier && shippingFeeType !== 'PRICE'">
        <ui-info-field
          [label]="'purchase-order.header.shipping-fee-price' | translate"
          value="{{
            shippingFeePrice !== null && shippingFeeType !== null
              ? ('purchase-order.header.shipping-fee-type.' + shippingFeeType
                | translate: { threshold: shippingFeePrice })
              : ('purchase-order.header.undefined' | translate)
          }}"
        >
        </ui-info-field>
      </div>

      <div class="order-type">
        <ui-textarea label="{{ 'purchase-order.header.comment' | translate }}" formControlName="comment"> </ui-textarea>
      </div>
    </div>
  </form>
</div>
