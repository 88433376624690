<ng-container>
  <form class="store-general" [formGroup]="storeForm" novalidate>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'store.general-datas.store-name' | translate }}"
        formControlName="name"
        [status]="storeForm.get('name') | formStatus"
        [errorMessage]="storeForm.get('name') | formErrorMessage | translate"
        mandatory="true"
        infoLabel="{{ 'global.unique' | translate }}"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'store.general-datas.reference' | translate }}"
        formControlName="reference"
        [status]="storeForm.get('reference') | formStatus"
        [errorMessage]="storeForm.get('reference') | formErrorMessage | translate"
        mandatory="true"
        infoLabel="{{ 'global.unique' | translate }}"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <ui-textarea
        label="{{ 'store.general-datas.address' | translate }}"
        formControlName="lines"
        [status]="storeForm.get('lines') | formStatus"
        [errorMessage]="storeForm.get('lines') | formErrorMessage | translate"
        mandatory="true"
      >
      </ui-textarea>
    </div>
    <div class="grid-item">
      <div class="multi-col">
        <div class="city-code">
          <ui-input-text
            type="text"
            label="{{ 'store.general-datas.city-code' | translate }}"
            formControlName="cityCode"
            [status]="storeForm.get('cityCode') | formStatus"
            [errorMessage]="storeForm.get('cityCode') | formErrorMessage | translate"
            mandatory="true"
          >
          </ui-input-text>
        </div>
        <div class="city">
          <ui-input-text
            type="text"
            label="{{ 'store.general-datas.city' | translate }}"
            formControlName="city"
            [status]="storeForm.get('city') | formStatus"
            [errorMessage]="storeForm.get('city') | formErrorMessage | translate"
            mandatory="true"
          >
          </ui-input-text>
        </div>
      </div>
    </div>
    <div class="grid-item">
      <ui-list
        label="{{ 'store.general-datas.country' | translate }}"
        [options]="this.countryOptions"
        formControlName="country"
        [search]="true"
        [status]="storeForm.get('country') | formStatus"
        [errorMessage]="storeForm.get('country') | formErrorMessage | translate"
        mandatory="true"
      ></ui-list>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'store.general-datas.coordinates' | translate }}"
        formControlName="coordinates"
      >
      </ui-input-text>
    </div>
    <div class="grid-item">
      <div class="double-col">
        <div class="phone-number">
          <ui-input-text
            type="text"
            label="{{ 'store.general-datas.phone-number' | translate }}"
            formControlName="phone"
            maskPattern="PN NN NN NN NN NN"
            [customPattern]="pattern"
            [specialCharacters]="[' ', '+']"
            [dropSpecialCharacters]="[' ']"
          >
          </ui-input-text>
        </div>
        <div class="email">
          <ui-input-text
            type="text"
            label="{{ 'store.general-datas.email' | translate }}"
            formControlName="email"
            [status]="storeForm.get('email') | formStatus"
            [errorMessage]="storeForm.get('email') | formErrorMessage | translate"
          >
          </ui-input-text>
        </div>
      </div>
    </div>

    <div class="grid-item">
      <ui-list
        label="{{ 'store.general-datas.store-brand' | translate }}"
        [options]="this.storeBrandOptions"
        formControlName="storeBrand"
        [status]="storeForm.get('storeBrand') | formStatus"
        [errorMessage]="storeForm.get('storeBrand') | formErrorMessage | translate"
        mandatory="true"
      ></ui-list>
    </div>
    <div class="grid-item">
      <ui-list
        label="{{ 'store.general-datas.customer-name' | translate }}"
        [options]="this.customerOptions"
        formControlName="customerId"
        [status]="storeForm.get('customerId') | formStatus"
        [errorMessage]="storeForm.get('customerId') | formErrorMessage | translate"
        mandatory="true"
      ></ui-list>
    </div>
    <div class="grid-item">
      <ui-list
        label="{{ 'store.general-datas.status' | translate }}"
        [options]="this.storeStatusTypeOptions"
        formControlName="status"
        [status]="storeForm.get('status') | formStatus"
        [errorMessage]="storeForm.get('status') | formErrorMessage | translate"
        mandatory="true"
      ></ui-list>
    </div>
    <div class="grid-item">
      <ui-list
        label="{{ 'store.general-datas.pricing-group' | translate }}"
        [options]="this.pricingGroupOptions"
        formControlName="pricingGroup"
        [status]="storeForm.get('pricingGroup') | formStatus"
        [errorMessage]="storeForm.get('pricingGroup') | formErrorMessage | translate"
        mandatory="true"
      ></ui-list>
    </div>
    <div class="grid-item">
      <ui-input-text
        type="text"
        label="{{ 'store.general-datas.siret' | translate }}"
        formControlName="siret"
        [status]="storeForm.get('siret') | formStatus"
        [errorMessage]="storeForm.get('siret') | formErrorMessage | translate"
        mandatory="true"
        infoLabel="{{ 'global.unique' | translate }}"
      >
      </ui-input-text>
    </div>

    <div class="grid-item">
      <ui-list
        selectLabel="{{ 'store.general-datas.supervisor.search' | translate }}"
        label="{{ 'store.general-datas.supervisor.label' | translate }}"
        [status]="storeForm.get('sectorSupervisorId') | formStatus"
        [errorMessage]="storeForm.get('sectorSupervisorId') | formErrorMessage | translate"
        formControlName="sectorSupervisorId"
        [options]="sectorSupervisorOptions"
        [search]="true"
      ></ui-list>
    </div>

    <div class="grid-item">
      <ui-textarea label="{{ 'store.general-datas.comment' | translate }}" formControlName="comment"> </ui-textarea>
    </div>

    <div class="grid-item">
      <ui-tag-handler
        label="{{ 'store.general-datas.tag' | translate }}"
        [availableTags]="this.availableTags"
        formControlName="tags"
      >
      </ui-tag-handler>
    </div>
  </form>
</ng-container>
