<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ 'store.buttons.back-button' | translate }}"
        (click)="backToStoreList()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
    </div>
    <div class="title">
      <ui-title titleText="{{ title | translate }}" subTitleText="{{ subTitle ? subTitle : undefined }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="validate-button">
        <div class="button">
          <ui-button
            text="{{ 'global.validate' | translate }}"
            type="save"
            [primary]="true"
            (click)="submitStore()"
          ></ui-button>
        </div>
      </div>
    </div>
    <div class="store-body">
      <ui-tab-handler #tabHandler (tabClick)="onTabClick($event)" [independent]="false">
        <ui-tab title="{{ 'store.general-datas.tab-title' | translate }}" id="general-datas" active="true">
          <ng-template #tabTemplate>
            <app-general-datas #generalDatas *ngIf="this.updatedStore" [editedStore]="this.updatedStore">
            </app-general-datas>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'store.delivery-addresses.tab-title' | translate }}" id="delivery-addresses">
          <ng-template #tabTemplate>
            <gen-addresses-list
              *ngIf="this.updatedStore"
              [(addressesList)]="this.updatedStore.deliveryAddresses"
              (submitEvent)="catchEvent('deliveryAddresses', $event)"
            >
            </gen-addresses-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'store.bank-datas.tab-title' | translate }}" id="bank-datas">
          <ng-template #tabTemplate>
            <gen-banks-list
              *ngIf="this.updatedStore"
              [(bankList)]="this.updatedStore.banks"
              (submitEvent)="catchEvent('banks', $event)"
              [showSepa]="false"
              [showAddress]="true"
              [showAccountNumber]="true"
              [mandatoryFields]="['name', 'bic', 'country', 'iban']"
            >
            </gen-banks-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'store.phone-book.tab-title' | translate }}" id="phone-book">
          <ng-template #tabTemplate>
            <gen-persons-list
              *ngIf="this.updatedStore"
              [jobType]="this.jobTypeExternalContact"
              [(personsList)]="this.updatedStore.persons"
              (submitEvent)="catchEvent('persons', $event)"
            ></gen-persons-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'store.characteristics.tab-title' | translate }}" id="characteristics">
          <ng-template #tabTemplate>
            <app-characteristics
              #characteristics
              *ngIf="this.updatedStore"
              [(selectedFile)]="selectedFile"
              [editedStore]="this.updatedStore"
            ></app-characteristics>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'store.brands.tab-title' | translate }}" id="brands">
          <ng-template #tabTemplate>
            <app-order-brands
              #orderBrands
              *ngIf="this.updatedStore"
              [(orderBrandsList)]="this.updatedStore.orderBrandIds"
            ></app-order-brands>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'store.finance.tab-title' | translate }}" id="finance">
          <ng-template #tabTemplate>
            <app-finance #finance *ngIf="this.updatedStore" [editedStore]="this.updatedStore"></app-finance>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'store.teams.tab-title' | translate }}" id="teams">
          <ng-template #tabTemplate>
            <app-employees-list
              *ngIf="this.updatedStore"
              [(employeesList)]="this.updatedStore.employees"
              [(supervisorId)]="this.updatedStore.supervisorId"
              [storeId]="this.updatedStore.id"
              [storeName]="this.updatedStore.name"
            >
            </app-employees-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'store.cash-registers-list.tab-title' | translate }}" id="cash-registers-list" *ngIf="isGod">
          <ng-template #tabTemplate>
            <app-cash-registers-list
              *ngIf="this.updatedStore"
              [(cashRegistersList)]="this.updatedStore.cashRegisters"
              (submitEvent)="catchEvent('cashRegisters', $event)"
            >
            </app-cash-registers-list>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>
    </div>
  </div>

  <!-- Popup -->
  <app-disable-store-warning-popup
    *ngIf="popupVisible"
    [storeName]="editedStore.name"
    (validate)="validateWarningPopup()"
    (close)="closeWarningPopup()"
  ></app-disable-store-warning-popup>
</ng-container>
