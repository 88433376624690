<ng-container>
  <div class="main-container frozen-left">
    <div class="title">
      <ui-title titleText="{{ 'stock-entry-label-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <!-- button manual print -->
    </div>
    <div class="stock-entry-label-list">
      <ui-container>
        <div class="filters" *ngIf="availableFilters">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="availableFilters"
            [(filterValues)]="filterValues"
            (apply)="applyFilters()"
            [locale]="locale"
            dateFormat="{{ dateFormat }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div class="headband">
          <div class="selected-count">
            <span
              [innerHTML]="
                selectedRows.length === 0
                  ? ('global.datatable.no-items' | translate)
                  : (selectedRows.length
                    | i18nPlural
                      : {
                          '=1': 'global.datatable.n-selected-items',
                          other: 'global.datatable.n-selected-items_plural'
                        }
                    | translate: { selectedElements: selectedRows.length })
              "
            >
            </span>
          </div>
          <div class="print-button">
            <ui-button
              text="{{ 'stock-entry-label-list.datatable.buttons.print' | translate }}"
              (click)="showPrintSELPopup()"
              [disabled]="!selectedRows.length"
              [btnIcon]="faPrint"
              [primary]="true"
            >
            </ui-button>
          </div>
          <div class="buttons">
            <div class="delete-button">
              <ui-button
                text="{{ 'stock-entry-label-list.datatable.buttons.delete' | translate }}"
                (click)="showDeleteSELPopup()"
                [disabled]="!selectedRows.length"
                customClass="fugu-button-delete"
                [btnIcon]="faTrashAlt"
              >
              </ui-button>
            </div>
          </div>
        </div>

        <div class="separator">
          <div class="pointer">
            <div class="arrow"></div>
          </div>
          <div class="line"></div>
        </div>

        <div [formGroup]="tableControl" class="stock-entry-label-table">
          <div>
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material"
              columnMode="force"
              headerHeight="auto"
              footerHeight="50"
              rowHeight="40"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down'
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [externalSorting]="true"
              [externalPaging]="true"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              scrollbarH="true"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
              [rowClass]="getRowClass"
            >
              <ngx-datatable-column
                width="50"
                [sortable]="false"
                [frozenLeft]="true"
                [draggable]="false"
                [resizeable]="false"
                [canAutoResize]="false"
                cellClass="center-align frozen-style"
              >
                <ng-template ngx-datatable-header-template>
                  <div class="green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onHeaderCheckboxChange()"
                      [formControlName]="'headerCheckbox'"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-id="row.id">
                  <div class="adjust green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onRowCheckboxChange()"
                      [formControlName]="getRowControlName(id)"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.item-ref' | translate }}"
                prop="itemReference"
                cellClass="item-ref"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.item-name' | translate }}"
                prop="itemName"
                cellClass="item-name"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.delivery-ref' | translate }}"
                prop="deliveryRef"
                cellClass="delivery-ref"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.receive-date' | translate }}"
                prop="receiveDate"
                cellClass="receive-date"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.receiveDate">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.location-name' | translate }}"
                prop="locationName"
                cellClass="location-name"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.supplier-ref' | translate }}"
                prop="itemSupplierRef"
                cellClass="supplier-ref"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.supplier-name' | translate }}"
                prop="supplierName"
                cellClass="supplier-name"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.quantity' | translate }}"
                prop="quantity"
                cellClass="quantity"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.size-value' | translate }}"
                prop="sizeValue"
                cellClass="size-value"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.serial-number' | translate }}"
                prop="serialNumber"
                cellClass="serial-number"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.batch-number' | translate }}"
                prop="batchNumber"
                cellClass="batch-number"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'stock-entry-label-list.datatable.columns.brand' | translate }}"
                prop="brandName"
                cellClass="brand-name"
                [resizeable]="false"
              >
              </ngx-datatable-column>
              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>
    </div>
  </div>

  <!--Chose slot number popup -->
  <app-stock-entry-label-print-settings-popup
    *ngIf="printSettingsPopupVisible"
    [stockEntryLabelList]="selectedRows"
    (validate)="validateChangeSettingsPopup($event)"
    (close)="closeSettingsPopup()"
  ></app-stock-entry-label-print-settings-popup>

  <!--delete selected lines -->
  <app-stock-entry-label-delete-popup
    *ngIf="deletePopupVisible"
    [stockEntryLabelList]="selectedRows"
    [afterPrint]="afterPrint"
    (validate)="validateDeletePopup($event)"
    (close)="closeDeletePopup()"
  ></app-stock-entry-label-delete-popup>
</ng-container>
