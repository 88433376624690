import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faCheck, faMoon, faSunBright, faTimes, faUserLock } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { CaraUser, CaraUserService, ThemeService, DateFormatUtils, NewPassword } from "center-services";
import { Option, SubscriptionService, Comparator } from "fugu-common";
import { MessageService } from "fugu-components";
import { PasswordUtil } from "generic-pages";

class ProfileInfo extends Comparator {
  public currentPassword: string = "";
  public newPassword: string = "";
  public newPasswordConfirmed: string = "";
  public dateFormat: string = "";
  public locale: string = "";

  constructor(values: any = {}) {
    super();
    this.currentPassword = values.currentPassword;
    this.newPassword = values.newPassword;
    this.newPasswordConfirmed = values.newPasswordConfirmed;
    this.dateFormat = values.dateFormat;
    this.locale = values.locale;
  }
}

@Component({
  selector: "app-profile-management",
  templateUrl: "./profile-management.component.html",
  styleUrls: ["./profile-management.component.scss"],
  providers: [SubscriptionService],
})
export class ProfileManagementComponent implements OnInit, AfterViewChecked {
  public newPassword: HTMLElement;
  public passwordForm: UntypedFormGroup;
  public languageForm: UntypedFormGroup;
  public passwordConstraints: any;
  public symbols: string = PasswordUtil.symbols.join(", ");
  public shouldClose: boolean = false;
  public faSunBright: IconDefinition = faSunBright;
  public faMoon: IconDefinition = faMoon;
  public faUserLock: IconDefinition = faUserLock;
  public faTimes: IconDefinition = faTimes;
  public faCheck: IconDefinition = faCheck;
  public dateFormat: string;
  public locale: string;
  public infoLabel: string = "";
  public currentPass: string;
  public newPass: string;
  public newPassConfirmed: string;
  public codeLangOptions: Option[];
  public countryFlags: Map<string, string> = Object.create({ fr: "fr", en: "gb" });
  public dateFormatOptions: Option[] = [];
  // from DB
  public initialUser: CaraUser;
  // updated with form values
  public updatedUser: CaraUser;
  public isPasswordSaved: boolean = false;
  // values from DB
  public initialProfileInfo: ProfileInfo;
  // values created for IsDirty
  public unsavedProfileInfo: ProfileInfo;
  // values updated in forms
  public updatedProfileInfo: ProfileInfo;
  private dateFormats: string[];

  constructor(
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    protected userService: CaraUserService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private themeService: ThemeService,
    private subscriptionService: SubscriptionService
  ) {}

  @ViewChild("passwordField", { read: ElementRef }) set setPasswordField(elem: ElementRef) {
    this.newPassword = elem ? elem.nativeElement.getElementsByTagName("input")[0] : undefined;
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.prepareData(
        this.userService.connectedUser.value,
        this.userService.connectedUser.value.dateFormat,
        this.userService.connectedUser.value.codeLanguage
      );
    } else {
      this.subscriptionService.subs.push(
        this.userService.connectedUser.subscribe(user => {
          if (user) {
            this.prepareData(user, user.dateFormat, user.codeLanguage);
          }
        })
      );
    }

    // form language option lists
    this.codeLangOptions = [new Option(0, "fr"), new Option(1, "en")];
    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.fillDateFormatOptionList();
      })
    );
    this.fillDateFormatOptionList();

    this.passwordConstraints = PasswordUtil.getValidators(null, null);

    this.prepareForms();

    this.initUserLanguagePreferences();
    this.initUserDateFormatPreferences();
  }

  prepareData(user: CaraUser, dateFormat: string, local: string): void {
    this.initialUser = user;
    this.dateFormat = dateFormat;
    this.locale = local;
    this.initProfileInfo(user);
    this.initUserLanguagePreferences();
    this.initUserDateFormatPreferences();
    // initialize user to set
    this.updatedUser = this.cloneEntity(this.initialUser);
  }

  prepareForms(): void {
    const passwordLength = 8;

    // password part
    this.passwordForm = this.fb.group({
      currentPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required, Validators.minLength(passwordLength)]],
      newPasswordConfirmed: [null, [Validators.required, Validators.minLength(passwordLength)]],
    });
    this.subscriptionService.subs.push(
      this.passwordForm.controls.newPassword.valueChanges.subscribe(value => {
        this.passwordConstraints = PasswordUtil.getValidators(value, passwordLength);
      })
    );
    this.passwordForm.setValidators([PasswordUtil.check("newPassword", "newPasswordConfirmed")]);

    // language part
    this.languageForm = this.fb.group({
      codeLanguage: [null, [Validators.required]],
      dateFormat: [null, [Validators.required]],
    });
  }

  fillDateFormatOptionList(): void {
    this.dateFormats = [
      this.translateService.instant("profile-management.language.date-format.option-1"),
      this.translateService.instant("profile-management.language.date-format.option-2"),
    ];
    this.dateFormatOptions = this.dateFormats.map((format, index) => new Option(index, format));
  }

  isLightTheme(): boolean {
    return this.themeService.getValue();
  }

  changeTheme(newTheme: string): void {
    this.updatedUser.theme = newTheme;
    this.userService.saveTheme(newTheme);

    if (this.updatedUser.theme === this.initialUser.theme) {
      return;
    }

    this.subscriptionService.subs.push(
      this.userService.update(this.updatedUser).subscribe({
        complete: () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          // update the user from DB
          this.initialUser = this.cloneEntity(this.updatedUser);
        },
        error: error => {
          this.handleApiError(error);
        },
      })
    );
  }

  initUserLanguagePreferences(): void {
    if (this.locale !== undefined && this.languageForm !== undefined) {
      const languageNumber = this.locale === "fr" ? 0 : 1;
      this.languageForm.controls.codeLanguage.setValue(languageNumber);
      this.translateService.use(this.locale);
    }
  }

  initUserDateFormatPreferences(): void {
    if (this.dateFormat !== undefined && this.languageForm !== undefined) {
      this.languageForm.controls.dateFormat.setValue(DateFormatUtils.getIndex(this.initialUser.dateFormat));
    }
  }

  submitPassword(): void {
    if (this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    }

    this.applyModifications();

    // prevent error from back : don't change the password with the same password
    const currentPassValue = this.passwordForm.controls.currentPassword.value;
    if (
      currentPassValue === this.passwordForm.controls.newPassword.value &&
      currentPassValue === this.passwordForm.controls.newPasswordConfirmed.value
    ) {
      return;
    }

    // create object with passwords for API call
    if (
      this.passwordForm.controls.currentPassword.value &&
      this.passwordForm.controls.newPassword.value === this.passwordForm.controls.newPasswordConfirmed.value
    ) {
      const newPassW = new NewPassword();
      if (this.passwordForm.controls.currentPassword.value && this.passwordForm.controls.newPassword.value) {
        newPassW.currentPassword = PasswordUtil.encrypt(this.passwordForm.controls.currentPassword.value).toString();
        newPassW.newPassword = PasswordUtil.encrypt(this.passwordForm.controls.newPassword.value).toString();
      }

      this.subscriptionService.subs.push(
        this.userService.updatePassword(newPassW).subscribe(
          () => {
            const title = this.translateService.instant("message.title.save-success");
            const content = this.translateService.instant("profile-management.message.password-success");
            this.messageService.success(content, { title });
            // clear fields, reset form value
            this.passwordForm.reset();
            this.passwordForm.updateValueAndValidity();
            this.unsavedProfileInfo = null;
            this.shouldClose = false;
          },
          error => {
            this.handleApiError(error);
          }
        )
      );
    }
  }

  submitLanguage(): void {
    if (this.languageForm.invalid) {
      this.languageForm.markAllAsTouched();
      return;
    }

    this.applyModifications();

    if (this.initialUser.equals(this.updatedUser)) {
      return;
    }

    this.subscriptionService.subs.push(
      this.userService.update(this.updatedUser).subscribe({
        complete: () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("profile-management.message.language-success");
          this.messageService.success(content, { title });

          // update the user from DB
          this.initialUser = this.cloneEntity(this.updatedUser);
          //  update the profileInfo from DB
          this.initialProfileInfo = new ProfileInfo({
            dateFormat: this.updatedProfileInfo?.dateFormat,
            locale: this.updatedProfileInfo?.locale,
          });

          this.dateFormatOptions = [];
          this.fillDateFormatOptionList();
          this.languageForm.updateValueAndValidity();
          this.unsavedProfileInfo = null;
          this.shouldClose = false;
        },
        error: error => {
          this.handleApiError(error);
        },
      })
    );
  }

  applyModifications(): void {
    // --- user object updated with form values ---
    this.updatedUser.codeLanguage = this.codeLangOptions[this.languageForm.value.codeLanguage].label;
    this.updatedUser.dateFormat = DateFormatUtils.getDateFormat(this.languageForm.value.dateFormat);

    // --- used for isDirty ---
    this.updatedProfileInfo = new ProfileInfo();
    // password form
    this.updatedProfileInfo.currentPassword = this.passwordForm.value.currentPassword;
    this.updatedProfileInfo.newPassword = this.passwordForm.value.newPassword;
    this.updatedProfileInfo.newPasswordConfirmed = this.passwordForm.value.newPasswordConfirmed;

    // language form
    this.updatedProfileInfo.dateFormat = this.updatedUser.dateFormat;
    this.updatedProfileInfo.locale = this.updatedUser.codeLanguage;
  }

  isDirty(): boolean {
    this.applyModifications();

    if (this.unsavedProfileInfo && !this.unsavedProfileInfo.equals(this.updatedProfileInfo)) {
      this.shouldClose = false;
    }

    // toast message displayed only once
    if (!this.initialProfileInfo.equals(this.updatedProfileInfo) && !this.shouldClose) {
      this.unsavedProfileInfo = new ProfileInfo(this.updatedProfileInfo);

      this.shouldClose = true;
      return true;
    }
    // toast message already shown once
    this.unsavedProfileInfo = null;
    this.shouldClose = false;
    return false;
  }

  initProfileInfo(user?: CaraUser): void {
    // used for isDirty
    this.initialProfileInfo = new ProfileInfo();
    if (user) {
      this.initialProfileInfo.dateFormat = user.dateFormat;
      this.initialProfileInfo.locale = user.codeLanguage;
    } else {
      this.initialProfileInfo.dateFormat = this.initialUser.dateFormat;
      this.initialProfileInfo.locale = this.initialUser.codeLanguage;
    }
  }

  getValidatorClass(value: boolean): string {
    return value ? "valid" : "invalid";
  }

  shouldCheckNewPassword(): void {
    if (!this.passwordForm.controls.newPassword.value && !this.passwordForm.controls.newPasswordConfirmed.value) {
      this.passwordForm.controls.newPassword.updateValueAndValidity();
      this.passwordForm.controls.newPasswordConfirmed.updateValueAndValidity();
    }
  }

  handleApiError(error: any): void {
    // set an error on currentPassword input
    const unauthorized = 401;

    if (error.status === unauthorized) {
      this.passwordForm.controls.currentPassword.setErrors({ incorrectCurrentPassword: true });
      this.passwordForm.markAllAsTouched();
    }
  }

  user(): CaraUser {
    return this.userService.connectedUser.value;
  }

  protected cloneEntity(caraUser: CaraUser): CaraUser {
    return new CaraUser(caraUser);
  }
}
