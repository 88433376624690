import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { faPen, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { ReceivingForm, Store, Supplier, CaraUserService, CaraUser } from "center-services";
import { DayjsUtil, SubscriptionService } from "fugu-common";

@Component({
  selector: "app-new-delivery-form-header",
  templateUrl: "./new-delivery-form-header.component.html",
  styleUrls: ["./new-delivery-form-header.component.scss"],
  providers: [SubscriptionService],
})
export class NewDeliveryFormHeaderComponent implements OnInit {
  @Output() receivingFormChange: EventEmitter<ReceivingForm> = new EventEmitter();

  @Input() detailHeader: boolean = false;
  @Input() receivingForm: ReceivingForm;
  @Input() readOnly: boolean = false;
  @Input() receiver: Store;
  @Input() sender: Supplier | Store;

  public faPen: IconDefinition = faPen;
  public popupVisible: boolean = false;

  public headerForm: UntypedFormGroup;
  public popupForm: UntypedFormGroup;

  public dateFormat: string;
  public locale: string;

  constructor(
    private userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForms();
  }

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.locale = this.userService.connectedUser.value.codeLanguage;
    } else {
      this.subscriptionService.subs.push(
        this.userService.connectedUser.subscribe((user: CaraUser) => {
          this.dateFormat = user.dateFormat;
          this.locale = user.codeLanguage;
        })
      );
    }

    if (this.detailHeader === undefined || this.detailHeader === null) {
      this.detailHeader = true;
    }
    this.loadEditedData();
  }

  public closePopup(): void {
    this.popupForm.controls.comment.patchValue("");
    this.popupVisible = false;
  }

  public isFormValid(): boolean {
    if (!(this.headerForm.valid || this.detailHeader)) {
      this.headerForm.markAllAsTouched();
      return false;
    }

    return true;
  }

  public openCommentPopup(): void {
    this.popupForm.controls.comment.patchValue(this.headerForm.controls.comment.value);
    this.popupVisible = true;
  }

  public saveComment(): void {
    this.headerForm.controls.comment.patchValue(this.popupForm.controls.comment.value);
    this.closePopup();
  }

  private applyModifications(): void {
    this.receivingForm.comment = this.headerForm.value.comment;
    this.receivingForm.deliveryRef = this.headerForm.value.deliveryRef;
    this.receivingForm.expectedDeliveryDate = this.headerForm.value.deliveryDate
      ? this.headerForm.value.deliveryDate.toDate()
      : null;
    this.receivingForm.documentDate = this.headerForm.value.documentDate
      ? this.headerForm.value.documentDate.toDate()
      : null;
  }

  private loadEditedData(): void {
    this.headerForm.controls.comment.setValue(this.receivingForm.comment, { emitEvent: false });
    this.headerForm.controls.deliveryRef.patchValue(this.receivingForm.deliveryRef, { emitEvent: false });
    this.headerForm.controls.documentDate.patchValue(DayjsUtil.dayjsOrNull(this.receivingForm.documentDate, true), {
      emitEvent: false,
    });
    this.headerForm.controls.deliveryDate.patchValue(
      DayjsUtil.dayjsOrNull(this.receivingForm.expectedDeliveryDate, true),
      {
        emitEvent: false,
      }
    );
  }

  private prepareForms(): void {
    this.popupForm = this.fb.group({ comment: [null] });

    this.headerForm = this.fb.group({
      comment: [null],
      deliveryDate: [null],
      deliveryRef: [null, [Validators.required]],
      documentDate: [null, [Validators.required]],
    });

    this.subscriptionService.subs.push(
      this.headerForm.valueChanges.subscribe(() => {
        this.applyModifications();
      })
    );
  }
}
