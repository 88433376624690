<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ getBackButtonTranslation() | translate }}"
        (click)="backToPrevious()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
    </div>
    <div class="title">
      <ui-title titleText="{{ title | translate }}" subTitleText="{{ subTitle ? subTitle : undefined }}"></ui-title>
    </div>

    <div class="top-buttons">
      <div class="{{ getSaveAndActionBtnClass() }}" *ngIf="activeStage.id === 1 || activeStage.id === 2">
        <ui-button
          [primary]="true"
          text="{{ getSaveAndActionBtnTranslation() | translate }}"
          type="save-and-action"
          [disabled]="
            this.updatedPurchaseOrder !== undefined &&
            this.updatedPurchaseOrder.lines.length === 0 &&
            activeStage.id === 2
          "
          (click)="submitPurchaseOrderAndAction()"
        ></ui-button>
      </div>
      <div class="save-button" *ngIf="activeStage.id === 2">
        <ui-button
          text="{{ getSaveBtnTranslation() | translate }}"
          type="save"
          (click)="submitPurchaseOrder(stages[activeStage.id - 1], true)"
        ></ui-button>
      </div>
      <div class="validate-button" *ngIf="activeStage.id === 3">
        <ui-button
          text="{{ getSaveBtnTranslation() | translate }}"
          [primary]="activeStage.id === 3"
          type="validate"
          (click)="submitPurchaseOrder(stages[activeStage.id - 1], true)"
        ></ui-button>
      </div>
    </div>

    <div class="purchase-order-body">
      <div class="stages-container">
        <ui-container>
          <div class="align-stages">
            <div
              *ngFor="let stage of stages"
              class="{{ getStageClass(stage) }}"
              [class.active]="stage.active"
              (click)="changeStage(stage)"
            >
              <div class="number-circle">{{ stage.index }}</div>
              <div class="panel-text">{{ stage.title }}</div>
            </div>
          </div>
        </ui-container>
      </div>
      <div class="body-container">
        <ui-container *ngIf="this.updatedPurchaseOrder && activeStage.id === 1">
          <app-purchase-order-header
            #purchaseOrderHeader
            [(editedPurchaseOrder)]="this.updatedPurchaseOrder"
            [companyConfig]="this.companyConfig"
          >
          </app-purchase-order-header>
        </ui-container>

        <ui-container *ngIf="this.updatedPurchaseOrder && activeStage.id === 2">
          <app-purchase-order-lines #purchaseOrderLines [(editedPurchaseOrder)]="this.updatedPurchaseOrder">
          </app-purchase-order-lines>
        </ui-container>

        <ui-container *ngIf="this.updatedPurchaseOrder && activeStage.id === 3">
          <app-purchase-order-summary #purchaseOrderSummary [(editedPurchaseOrder)]="this.updatedPurchaseOrder">
          </app-purchase-order-summary>
        </ui-container>
      </div>
    </div>
  </div>
</ng-container>

<ui-popup
  *ngIf="printMessagePopupVisible"
  title="{{ 'purchase-order.summary.print-message-popup.title' | translate }}"
  (close)="closePrintMessagePopup(false)"
>
  <div content>
    <p class="print-message-text">{{ "purchase-order.summary.print-message-popup.text" | translate }}</p>
  </div>
  <div footer>
    <div class="align-buttons">
      <div class="button-popup-validate">
        <div class="button">
          <ui-button
            text="{{ 'purchase-order.summary.print-message-popup.yes' | translate }}"
            [primary]="true"
            type="validate"
            (click)="validatePrintMessagePopup()"
          ></ui-button>
        </div>
      </div>
      <div class="button-popup-cancel">
        <div class="button second-button">
          <ui-button
            text="{{ 'purchase-order.summary.print-message-popup.no' | translate }}"
            type="cancel"
            (click)="closePrintMessagePopup(true)"
          ></ui-button>
        </div>
      </div>
    </div>
  </div>
</ui-popup>
