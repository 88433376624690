<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'size-categories-list.title' | translate }}"></ui-title>
    </div>
    <div class="button">
      <ui-button
        text="{{ 'global.new' | translate }}"
        type="add"
        [primary]="true"
        (click)="onNewEntityClick()"
        *hasPermission="['SIZE_CATEGORY_CREATE']"
      ></ui-button>
    </div>

    <div class="size-categories-table">
      <ui-container>
        <div [formGroup]="tableControl">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            class="material"
            columnMode="flex"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [limit]="15"
            (activate)="onTableActivate($event)"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'size-categories-list.datatable.columns.name' | translate }}"
              prop="name"
              cellClass="name"
              [flexGrow]="1"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'size-categories-list.datatable.columns.sizeValue' | translate }}"
              prop="values"
              cellClass="ellipsis-counter"
              [flexGrow]="2"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-values="row.values">
                <p class="ellipsis">{{ values.join(", ") }}</p>
                <div *ngIf="values.length >= 2">
                  <div #valuesCounter>
                    <ui-counter [number]="values.length"></ui-counter>
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="valuesCounter">
                    <span style="font-weight: normal !important; white-space: break-spaces">{{
                      values.join(", ")
                    }}</span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'size-categories-list.datatable.columns.activated' | translate }}"
              prop="activated"
              headerClass="center-align"
              cellClass="center-align"
              [flexGrow]="1"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-switch
                    customClass="no-margin"
                    (change)="changeEntityActivation(id)"
                    [formControl]="tableControl.get('activated_' + id)"
                  >
                  </ui-switch>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>

    <div class="popup-container">
      <form
        class="size-categories-form"
        [formGroup]="popupForm"
        novalidate
        (keydown.enter)="$event.preventDefault()"
        *ngIf="popupVisible"
      >
        <ui-popup title="{{ popupTitle | translate }}" *ngIf="popupVisible" (close)="canClosePopup()">
          <div content>
            <div class="grid-container">
              <div class="grid-item">
                <ui-input-text
                  type="text"
                  label="{{ 'size-categories-list.datatable.columns.name' | translate }}"
                  formControlName="name"
                  [status]="popupForm.get('name') | formStatus"
                  [errorMessage]="popupForm.get('name') | formErrorMessage | translate"
                  mandatory="true"
                  infoLabel="{{ 'global.unique' | translate }}"
                >
                </ui-input-text>
              </div>
              <span class="mandatory" *ngIf="this.sizeValuesRows.length > 0"
                >{{ "size-categories-list.popup.size-values" | translate }}<span class="highlight">*</span></span
              >
              <div class="grid-item" *ngFor="let element of this.sizeValuesRows; index as i">
                <div class="multi-col">
                  <div class="size-value">
                    <ui-input-text
                      class="left"
                      type="text"
                      [formControl]="sizeValueForm.controls[i]"
                      [status]="popupForm.get('sizeValues.' + i) | formStatus"
                      [errorMessage]="popupForm.get('sizeValues.' + i) | formErrorMessage | translate"
                    >
                    </ui-input-text>
                  </div>
                  <div class="dump-icon-div" (click)="removeSizeValue(element, i)">
                    <fa-icon class="dump-icon" [icon]="faTrashAlt"></fa-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="button-popup-add-size-values">
              <ui-button
                text="{{ 'size-categories-list.popup.add-btn-text' | translate }}"
                type="add"
                (click)="addSizeValue()"
              >
              </ui-button>
            </div>
          </div>
          <div footer>
            <div class="align-buttons">
              <div class="button-popup-validate">
                <div class="button">
                  <ui-button
                    text="{{ 'global.validate' | translate }}"
                    type="save"
                    [primary]="true"
                    (click)="submitEntity()"
                  ></ui-button>
                </div>
              </div>
            </div>
          </div>
        </ui-popup>
      </form>
    </div>
  </div>
</ng-container>
