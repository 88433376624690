import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { BrandCollection, CaraUserService } from "center-services";
import { DayjsUtil, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";

@Component({
  selector: "brand-collection-popup",
  templateUrl: "./brand-collection-popup.component.html",
  styleUrls: ["./brand-collection-popup.component.scss"],
  providers: [SubscriptionService],
})
export class BrandCollectionPopupComponent implements OnChanges, AfterViewChecked {
  @ViewChild("table") table: DatatableComponent;

  @Input() brandCollection: BrandCollection;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public editedBrandCollection: BrandCollection;
  public popupTitle: string;

  public popupForm: UntypedFormGroup;
  public locale: string;
  public dateFormat: string;

  public initialBrandCollection: BrandCollection;
  public unsavedBrandCollection: BrandCollection;
  public shouldClose: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private userService: CaraUserService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnChanges(): void {
    if (!this.brandCollection) {
      this.createBrandCollection();
      this.popupTitle = this.translateService.instant("brand-collection-popup.title.new");
    } else {
      this.editedBrandCollection = new BrandCollection(this.brandCollection);
      this.popupTitle = this.translateService.instant("brand-collection-popup.title.update");
    }

    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      name: [null, [Validators.required]],
      date: [null],
    });

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );
  }

  createBrandCollection(): void {
    this.editedBrandCollection = new BrandCollection({
      name: null,
      date: null,
      archived: false,
    });
  }

  // Manage the Forms Controls
  initializePopup(): void {
    const date = this.editedBrandCollection.date ? this.editedBrandCollection.date : new Date();
    this.popupForm.controls.name.setValue(this.editedBrandCollection.name);
    this.popupForm.controls.date.setValue(DayjsUtil.dayjsOrNull(date, true));

    this.initialBrandCollection = new BrandCollection(this.editedBrandCollection);
    this.initialBrandCollection.date = new Date(this.initialBrandCollection.date);
  }

  submitBrandCollection(): void {
    // stop here if form is invalid
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    this.applyModifications();

    this.validate.emit(this.editedBrandCollection);
  }

  // Close the popup
  closePopup(): void {
    this.applyModifications();
    if (this.unsavedBrandCollection && !this.unsavedBrandCollection.equals(this.editedBrandCollection)) {
      this.shouldClose = false;
    }

    if (!this.initialBrandCollection.equals(this.editedBrandCollection) && !this.shouldClose) {
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });

      this.unsavedBrandCollection = new BrandCollection(this.editedBrandCollection);
    } else {
      this.close.emit();
      this.shouldClose = false;
      this.unsavedBrandCollection = null;
    }
  }

  applyModifications(): void {
    this.editedBrandCollection.name = this.popupForm.value.name;
    this.editedBrandCollection.date = this.popupForm.value.date ? new Date(this.popupForm.value.date) : null;
  }
}
