<ng-container>
  <form class="new-nomenclature-form" [formGroup]="popupForm" novalidate>
    <div class="popup-container">
      <ui-popup title="{{ popupTitle }}" (close)="closePopup()">
        <div content>
          <div class="supplier-ref">
            <ui-input-text
              id="supplier-ref-id"
              [mandatory]="true"
              type="text"
              [label]="'item.nomenclatures.nomenclature-popup.name' | translate"
              formControlName="name"
              [status]="popupForm.get('name') | formStatus"
              [errorMessage]="popupForm.get('name') | formErrorMessage | translate"
            >
            </ui-input-text>
          </div>

          <div [class]="'date end-date'">
            <ui-date-picker
              [locale]="locale"
              [format]="dateFormat"
              [label]="'item.nomenclatures.nomenclature-popup.validity-date' | translate"
              [formControlName]="'validityDate'"
              [status]="popupForm.get('validityDate') | formStatus"
              [errorMessage]="popupForm.get('validityDate') | formErrorMessage | translate"
            >
            </ui-date-picker>
          </div>
        </div>
        <div footer>
          <div class="align-buttons">
            <div class="button-popup-validate">
              <div class="button">
                <ui-button
                  text="{{ buttonLabel }}"
                  type="validate"
                  [primary]="true"
                  (click)="validateNomenclature()"
                ></ui-button>
              </div>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </form>
</ng-container>
