import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import {
  Currency,
  CurrencyService,
  InvoiceCustomer,
  InvoiceCustomerService,
  Light,
  LightService,
  PaginatedList,
  Pagination,
  InvoiceStatus,
  Sort,
  CaraUserService,
  CaraUser,
  DocumentType,
} from "center-services";
import {
  FilterValue,
  Filterer,
  Option,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { ButtonComponent, ListComponent, MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-invoice-customer-selector-popup",
  templateUrl: "./invoice-customer-selector-popup.component.html",
  styleUrls: ["./invoice-customer-selector-popup.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceCustomerSelectorPopupComponent extends FilteredTableListComponent implements OnInit, OnDestroy {
  public static LIST_ID: string = "app-invoice-customer-selector-popup.invoice-selection";
  @Input() defaultCustomer: Light;
  @Input() alreadySelectedInvoices: InvoiceCustomer[] = [];
  @Output() validate: EventEmitter<InvoiceCustomer[]> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @ViewChild("table") table: DatatableComponent;
  @ViewChild("customer") customerUIList: ListComponent;
  @ViewChild("validateButton") validateButton: ButtonComponent;
  public defaultCustomerLabel: string;
  public isCustomerLocked: boolean;
  public currencyList: Currency[] = [];
  public customerList: Light[] = [];
  public storeList: Light[] = [];
  public customerOptions: Option[] = [];
  public invoiceCustomerList: InvoiceCustomer[] = [];
  public selectedInvoices: InvoiceCustomer[] = [];
  public rows: any[] = [];
  public sorts: any[] = [{ prop: "date", dir: "desc" }];
  public filterer: Filterer;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 7,
  });
  public form: UntypedFormGroup;
  public tableControl: UntypedFormGroup;

  public locale: string;
  public dateFormat: string;
  public titleLabel: string;
  public submitButtonLabel: string;
  faArrowCircleRight: IconDefinition = faArrowCircleRight;
  private initObservables: Observable<any>[] = [];
  private sessionPagination: SessionPagination;
  private selectedCustomer: Light;
  private activeFilters: SearchFilter[] = [];

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private invoiceCustomerService: InvoiceCustomerService,
    private currencyService: CurrencyService,
    private lightService: LightService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  ngOnInit(): void {
    this.tableControl = this.fb.group({
      headerCheckbox: new UntypedFormControl(false),
    });

    this.form = this.fb.group({
      customer: [null, [Validators.required]],
    });

    this.titleLabel = this.defaultCustomer
      ? this.translateService.instant("invoice-customer-selector-popup.add-title")
      : this.translateService.instant("invoice-customer-selector-popup.title");
    this.submitButtonLabel = this.defaultCustomer
      ? this.translateService.instant("invoice-customer-selector-popup.add-validate")
      : this.translateService.instant("invoice-customer-selector-popup.validate");

    this.initObservables.push(this.fetchConnectedUserDetails());
    this.initObservables.push(this.fetchCurrencies());
    this.initObservables.push(this.fetchCustomers());
    this.initObservables.push(this.fetchStores());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(InvoiceCustomerSelectorPopupComponent.LIST_ID);

        this.defaultCustomer ? this.disableCustomerFilter() : this.computeCustomerOptions();

        if (this.defaultCustomer) {
          this.selectedCustomer = this.defaultCustomer;
          this.computeSearchFilters();
          this.fetchInvoiceCustomerList();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.sessionPagination.saveToSession(InvoiceCustomerSelectorPopupComponent.LIST_ID);
  }

  public selectCustomer(): void {
    const customerId = this.form.get("customer").value;

    if (this.selectedCustomer && customerId === this.selectedCustomer.id) {
      return;
    }

    this.selectedCustomer = this.customerList.find(cus => cus.id === customerId);
    this.selectedInvoices = [];
    this.unselectHeaderCheckbox();

    this.computeSearchFilters();
    this.fetchInvoiceCustomerList();
  }

  public submit(): void {
    if (this.form.invalid && !this.defaultCustomer) {
      this.form.get("customer").markAsTouched();
      return;
    }

    if (!this.isInvoicesSelected()) {
      return;
    }

    this.validate.emit(this.selectedInvoices);
  }

  public closePopup(): void {
    this.close.emit();
  }

  public getRowControlName(id: string | number): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateSelection();
    this.updateHeaderPageCheckbox();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  public updateSelection(): void {
    this.rows
      .filter(row => !this.tableControl?.controls[this.getRowControlName(row.id)].disabled)
      .forEach(row => {
        const rowControl = this.tableControl.controls[this.getRowControlName(row.id)];
        if (!rowControl) {
          return;
        }
        const rowChecked: boolean = rowControl.value;
        const invoice: InvoiceCustomer = this.invoiceCustomerList.find(elem => elem.id === row.id);
        const isInvoiceSelected: boolean =
          this.selectedInvoices.findIndex(elem => elem.id === row.id) === -1 ? false : true;

        if (!isInvoiceSelected && rowChecked) {
          this.selectedInvoices.push(invoice);
        } else if (isInvoiceSelected && !rowChecked) {
          const invoiceIndex = this.selectedInvoices.findIndex(elem => elem.id === invoice.id);
          this.selectedInvoices.splice(invoiceIndex, 1);
        }

        this.isCustomerLocked = !!this.defaultCustomer || this.selectedInvoices.length !== 0;
        this.validateButton.disabled = this.selectedInvoices.length === 0;
      });
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchInvoiceCustomerList();
  }

  public getCurrency(row: any): Currency {
    return this.currencyList.find(currency => currency.id === row.currencyId);
  }

  public changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];

    if (this.selectedCustomer) {
      this.fetchInvoiceCustomerList();
    }
  }

  public applyFilters(): void {
    if (!this.selectedCustomer) {
      return;
    }

    this.computeSearchFilters();
    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        InvoiceCustomerSelectorPopupComponent.LIST_ID
      ).subscribe(() => {
        this.fetchInvoiceCustomerList();
      })
    );
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  private fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  private fetchCustomers(): Observable<Light[]> {
    return this.lightService.getCustomers().pipe(
      tap(
        (lightCustomers: Light[]) => {
          this.customerList = lightCustomers;
        },
        error => {
          this.sendErrorAlert("invoice-customer-selector-popup.errors.get-customers", error.message);
        }
      )
    );
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeList = lightStores;
        },
        error => {
          this.sendErrorAlert("invoice-customer-selector-popup.errors.get-stores", error.message);
        }
      )
    );
  }

  private fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencyList = currencies;
        },
        error => {
          this.sendErrorAlert("invoice-customer-selector-popup.errors.get-currencies", error.message);
        }
      )
    );
  }

  private fetchInvoiceCustomerList(): void {
    this.subscriptionService.subs.push(
      this.invoiceCustomerService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<InvoiceCustomer>) => {
          this.rows = [];
          this.invoiceCustomerList = result.data;
          this.pager = result.page;
          result.data.forEach((invoiceCustomer: InvoiceCustomer) => {
            this.addRow(invoiceCustomer);
          });
        },
        error => {
          this.sendErrorAlert("invoice-customer-selector-popup.errors.get-invoice-customers", error.message);
        },
        () => {
          this.rows = [...this.rows];
          if (this.table) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
          this.onRowCheckboxChange();
        }
      )
    );
  }

  private addRow(invoiceCustomer: InvoiceCustomer): void {
    this.rows.push({
      id: invoiceCustomer.id,
      reference: invoiceCustomer.reference,
      customerRef: invoiceCustomer.customerRef,
      storeNames: invoiceCustomer.storeNames,
      date: invoiceCustomer.date,
      totalGrossPrice: invoiceCustomer.totalGrossPrice,
      currencyId: invoiceCustomer.currencyId,
    });

    if (this.alreadySelectedInvoices && this.alreadySelectedInvoices.length > 0) {
      const isControlDisabled: boolean =
        this.alreadySelectedInvoices.findIndex(invoice => invoice.id === invoiceCustomer.id) > -1;
      this.tableControl.addControl(
        this.getRowControlName(invoiceCustomer.id.toString()),
        new UntypedFormControl({ value: isControlDisabled, disabled: isControlDisabled })
      );
    } else {
      this.tableControl.addControl(
        this.getRowControlName(invoiceCustomer?.id?.toString()),
        new UntypedFormControl(false)
      );
    }
  }

  private initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === InvoiceCustomerSelectorPopupComponent.LIST_ID
    );

    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "reference",
      this.translateService.instant("invoice-customer-selector-popup.datatable.columns.reference"),
      "string"
    );

    this.filterer.addListFilter(
      "storeIds",
      this.translateService.instant("invoice-customer-selector-popup.datatable.columns.stores"),
      this.storeList.map(store => {
        return { value: store.id.toString(), displayValue: store.name };
      }),
      false,
      false,
      null,
      null,
      true,
      false,
      false,
      false,
      false,
      SearchFilterOperator.IN_JSONB
    );

    this.filterer.addFilter(
      "date",
      this.translateService.instant(`invoice-customer-selector-popup.datatable.columns.date`),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );

    this.filterer.addFilter(
      "totalGrossPrice",
      this.translateService.instant(`invoice-customer-selector-popup.datatable.columns.total-gross-price`),
      "range"
    );
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();

    if (this.selectedCustomer) {
      this.activeFilters.push(
        new SearchFilter("customerId", SearchFilterOperator.EQUAL, this.selectedCustomer.id.toString())
      );
    }

    this.activeFilters.push(new SearchFilter("fullyCreditNoted", SearchFilterOperator.EQUAL, "false"));

    this.activeFilters.push(new SearchFilter("documentType", SearchFilterOperator.EQUAL, DocumentType.INVOICE));

    this.activeFilters.push(
      new SearchFilter("invoiceStatus", SearchFilterOperator.IN, [InvoiceStatus.VALIDATED, InvoiceStatus.EXPORTED])
    );
  }

  private disableCustomerFilter(): void {
    this.defaultCustomerLabel = `${this.defaultCustomer.name} - ${this.defaultCustomer.reference}`;
    this.isCustomerLocked = true;
  }

  private computeCustomerOptions(): void {
    this.customerList.forEach(customer => {
      this.customerOptions.push(new Option(customer.id, `${customer.name} - ${customer.reference}`));
    });
  }

  private isInvoicesSelected(): boolean {
    return this.selectedInvoices.length > 0;
  }

  private buildDateDefaultValue(): any {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    return {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(s.prop, s.dir));
    }
    return sorter;
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  private updateHeaderPageCheckbox(): void {
    const currentPageCheckedRows = this.rows.filter(row => {
      return this.tableControl.get(this.getRowControlName(row.id))?.value;
    });
    const currentPageSelectableRows = this.rows.filter(row => {
      return this.tableControl.get(this.getRowControlName(row.id)) !== null;
    });
    const isSelected =
      currentPageSelectableRows.length > 0 && currentPageSelectableRows.length === currentPageCheckedRows.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
  }

  private updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.rows.forEach(row => {
      if (controls[this.getRowControlName(row.id)].enabled) {
        controls[this.getRowControlName(row.id)]?.patchValue(isHeaderSelected);
      }
    });
  }

  private unselectHeaderCheckbox(): void {
    this.tableControl.controls.headerCheckbox.patchValue(false);
    this.updateRowsPageCheckbox();
  }
}
