import { combineLatest, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  AlloyComposition,
  ContactAlloy,
  TheoreticalMetalWeight,
  AbstractItem,
  MetalWeight,
  StandardItem,
  Supplier,
  PurchaseOrderUtil,
} from "center-services";
import { PurchaseModalityService } from "./purchase-modality.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PurchaseOrderUtilService {
  public initObservables: Observable<any>[] = [];
  protected readonly MINUS_ONE: number = -1;
  private metalWeightList: TheoreticalMetalWeight[] = [];

  constructor(private purchaseModalityService: PurchaseModalityService) {}

  public fetchWeightAccountPrediction(
    polTheoreticalWeight: number = 0,
    orderItem: AbstractItem,
    orderSupplier: Supplier
  ): Observable<MetalWeight[]> {
    this.initObservables.push(
      this.fetchMetalWeightList(
        polTheoreticalWeight,
        orderItem instanceof StandardItem ? orderItem.composition : null,
        orderSupplier.alloys
      )
    );

    return combineLatest(this.initObservables).pipe(
      map(() => {
        const supplierMetalIds: number[] = PurchaseOrderUtil.getSupplierMetalIds(orderSupplier);
        return this.metalWeightList
          .filter(itw => supplierMetalIds.includes(itw.metalId))
          .map(
            itw =>
              new MetalWeight({
                weight: itw.weight,
                id: itw.metalId,
                metalName: this.getMetalNameById(itw.metalId).toUpperCase(),
              })
          );
      })
    );
  }

  public getMergedMetalWeights(mwListToMergeInto: MetalWeight[], mwListToBeMerged: MetalWeight[]): MetalWeight[] {
    for (const currentMetalWeight of mwListToBeMerged) {
      const metalWeightIdx: number = mwListToMergeInto.findIndex(
        (mw: MetalWeight) => mw.metalId === currentMetalWeight.metalId
      );
      if (metalWeightIdx === this.MINUS_ONE) {
        mwListToMergeInto.push(
          new MetalWeight({
            weight: currentMetalWeight.weight,
            id: currentMetalWeight.metalId,
            metalName: this.getMetalNameById(currentMetalWeight.metalId)?.toUpperCase(),
          })
        );
      } else {
        mwListToMergeInto[metalWeightIdx].weight += currentMetalWeight.weight;
      }
    }
    return mwListToMergeInto;
  }

  private fetchMetalWeightList(
    polTheoreticalWeight: number,
    itemComposition: AlloyComposition[],
    supplierPurchaseAlloys: ContactAlloy[]
  ): Observable<TheoreticalMetalWeight[]> {
    return this.purchaseModalityService
      .getTheoreticalMetalWeight(polTheoreticalWeight, itemComposition, supplierPurchaseAlloys)
      .pipe(
        tap((theoreticalMetalWeights: TheoreticalMetalWeight[]) => {
          this.metalWeightList = [...theoreticalMetalWeights];
        })
      );
  }

  private getMetalNameById(id: number): string {
    return this.purchaseModalityService.metalsList.find(ml => ml.id === id)?.name;
  }
}
