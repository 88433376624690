<div class="popup-container" [style.width]="popupWidth">
  <ui-popup
    *ngIf="popupVisible"
    [title]="getTranslationPrefix('selection') + '.title' | translate"
    (close)="canClosePopup()"
  >
    <div content>
      <div class="filters" *ngIf="filterer">
        <ui-dynamic-filters
          label="{{ 'filters.label' | translate }}"
          [filters]="filterer.getFilters()"
          [(filterValues)]="filterer.filterValues"
          (apply)="applyFilters()"
          applyLabel="{{ 'filters.apply' | translate }}"
          minLabel="{{ 'filters.min' | translate }}"
          maxLabel="{{ 'filters.max' | translate }}"
          deleteLabel="{{ 'filters.delete' | translate }}"
          placeholderPrefix="{{ 'filters.prefix' | translate }} "
        >
        </ui-dynamic-filters>
      </div>
      <div class="retail-item-table">
        <div [formGroup]="form">
          <ngx-datatable
            #table
            [class]="'material'"
            [rows]="rows"
            [trackByProp]="'id'"
            [columnMode]="'flex'"
            [headerHeight]="'auto'"
            [footerHeight]="50"
            [rowHeight]="40"
            [rowClass]="getRowClass"
            [offset]="offset"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [externalSorting]="true"
            [sorts]="sorts"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            [externalPaging]="true"
            [count]="pager.totalElements"
            [offset]="pager.number"
            [limit]="pager.size"
          >
            <ngx-datatable-column
              [width]="70"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-header-template>
                <ui-checkbox
                  customClass="no-margin"
                  (change)="onHeaderCheckboxChange()"
                  [formControlName]="'headerCheckbox'"
                >
                </ui-checkbox>
              </ng-template>
              <ng-template ngx-datatable-cell-template let-id="row.id">
                <ui-checkbox
                  customClass="no-margin"
                  (change)="onRowCheckBoxChange(id)"
                  [formControlName]="getRowControlName(id)"
                >
                </ui-checkbox>
              </ng-template>
            </ngx-datatable-column>

            <ng-container *ngFor="let column of columns">
              <ngx-datatable-column
                [ngSwitch]="column.name"
                [name]="getTranslationPrefix('list') + '.datatable.columns.' + column.name | translate"
                [prop]="column.name === 'status' ? 'statusIndex' : toCamelCase(column.name)"
                [cellClass]="column.name + ' ' + column.hasOwnProperty('customClass') ? column.customClass : ''"
                [flexGrow]="column.flexGrow"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                  <div [ngSwitch]="column.uiComponent">
                    <span *ngSwitchCase="'status-label'">
                      <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"> </ui-status-label>
                    </span>

                    <span *ngSwitchCase="'counter-tooltip'">
                      <p class="ellipsis inline">{{ row[toCamelCase(column.name)].join(", ") }}</p>

                      <div *ngIf="row[toCamelCase(column.name)].length >= 2" class="inline">
                        <div #valuesCounter>
                          <ui-counter [number]="row[toCamelCase(column.name)].length"></ui-counter>
                        </div>
                        <ui-tooltip maxWidth="300px" [element]="valuesCounter">
                          <span style="font-weight: normal !important; white-space: break-spaces">{{
                            row[toCamelCase(column.name)].join(", ")
                          }}</span>
                        </ui-tooltip>
                      </div>
                    </span>

                    <span *ngSwitchDefault>{{ row[toCamelCase(column.name)] }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="changePage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <div footer>
      <div class="top-table-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              text="{{ getTranslationPrefix('selection') + '.title-update-retail-item' | translate }}"
              type="save"
              [primary]="true"
              (click)="submitSelections()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
