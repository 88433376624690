<ng-container>
  <div class="main-container frozen-right">
    <div class="fields-wrapper">
      <div class="accounting-buy-ref-input field">
        <ui-input-text
          [type]="'text'"
          [label]="getTranslationPrefix() + '.accounting-buy-ref' | translate"
          [formControl]="accountingBuyRefControl"
          (blur)="onBlur()"
          maskPattern="0*"
        >
        </ui-input-text>
      </div>

      <div class="filters field border-bottom-filters" *ngIf="filterer">
        <ui-dynamic-filters
          [filters]="filterer.getFilters()"
          [(filterValues)]="filterer.filterValues"
          (apply)="applyFilters()"
          [locale]="codeLanguage"
          [label]="'filters.label' | translate"
          minLabel="{{ 'filters.min' | translate }}"
          maxLabel="{{ 'filters.max' | translate }}"
          applyLabel="{{ 'filters.apply' | translate }}"
          deleteLabel="{{ 'filters.delete' | translate }}"
          placeholderPrefix="{{ 'filters.prefix' | translate }} "
        >
        </ui-dynamic-filters>
      </div>
    </div>
    <div class="retail-item-purchase-modalities-table">
      <div class="top-table-buttons info">
        <ui-button
          [text]="getTranslationPrefix() + '.add-button' | translate"
          [type]="'add'"
          *hasPermission="['RETAIL_ITEM_CREATE']"
          (click)="openPopup()"
          [disabled]="!canBuy"
        ></ui-button>
        <div class="tooltip" *ngIf="!canBuy">
          <span class="info-icon" #info><fa-icon [icon]="faInfoCircle"></fa-icon></span>
          <ui-tooltip maxWidth="100px" [element]="info" placement="right"
            ><span>{{ getTranslationPrefix() + ".info" | translate }}</span></ui-tooltip
          >
        </div>
      </div>
      <div [formGroup]="tableControl">
        <ngx-datatable
          #table
          [class]="'material'"
          [rows]="rows"
          [trackByProp]="'id'"
          [columnMode]="'force'"
          [rowClass]="getRowClass"
          [headerHeight]="'auto'"
          [footerHeight]="50"
          [rowHeight]="40"
          [limit]="15"
          [scrollbarH]="true"
          [sorts]="sorts"
          [cssClasses]="{
            sortAscending: 'fas fa-chevron-up',
            sortDescending: 'fas fa-chevron-down'
          }"
          [messages]="{ emptyMessage: 'global.no-data' | translate }"
          (activate)="updatePurchaseModality($event)"
          [offset]="pageNumber"
          (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          (page)="pageNumber = $event.offset"
        >
          <ngx-datatable-column
            [name]="getColumnTranslation('supplier') | translate"
            [prop]="'supplierName'"
            [cellClass]="'supplier'"
            [resizeable]="false"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('supplier-ref') | translate"
            [prop]="'supplierRef'"
            [cellClass]="'supplier-ref'"
            [resizeable]="false"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('purchase-type') | translate"
            [prop]="'purchaseType'"
            [cellClass]="'purchase-type'"
            [resizeable]="false"
          >
            <ng-template let-purchaseType="row.purchaseType" ngx-datatable-cell-template>
              <span>{{ getTranslationPrefix() + ".datatable.purchase-type." + purchaseType | translate }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('unit-price') | translate"
            [cellClass]="'unit-price'"
            [resizeable]="false"
            [sortable]="false"
          >
            <ng-template let-unitPrice="row.unitPrice" let-purchaseCurrency="row.currency" ngx-datatable-cell-template>
              <div *ngIf="unitPrice !== null">
                {{ unitPrice | formatCurrency: purchaseCurrency.codeISO:"symbol":"0.2-5":codeLanguage }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('unit-price-per-weight') | translate"
            [cellClass]="'unit-price-per-weight'"
            [resizeable]="false"
            [sortable]="false"
          >
            <ng-template
              let-unitPricePerWeight="row.unitPricePerWeight"
              let-purchaseCurrency="row.currency"
              ngx-datatable-cell-template
            >
              <div *ngIf="unitPricePerWeight !== null">
                {{ unitPricePerWeight | formatCurrency: purchaseCurrency.codeISO:"symbol":"0.2-5":codeLanguage }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('theoretical-metal-weight') | translate"
            [cellClass]="'theoretical-metal-weight ellipsis-counter'"
            [resizeable]="false"
            [prop]="'theoreticalMetalWeight'"
            [sortable]="false"
          >
            <ng-template
              let-theoreticalMetalWeight="row.theoreticalMetalWeight"
              let-purchaseType="row.purchaseType"
              let-row="row"
              ngx-datatable-cell-template
            >
              <ng-container *ngIf="purchaseType === 'WITH_METAL_PRICE' && theoreticalMetalWeight">
                <p class="ellipsis composition">{{ theoreticalMetalWeight.join(", ") }}</p>
                <div *ngIf="theoreticalMetalWeight.length >= 2" title="{{ row.counter }}">
                  <div #compositionCounter>
                    <ui-counter [number]="theoreticalMetalWeight.length"></ui-counter>
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="compositionCounter">
                    <span style="font-weight: normal !important; white-space: break-spaces">{{
                      theoreticalMetalWeight.join(", ")
                    }}</span>
                  </ui-tooltip>
                </div>
              </ng-container>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('metal-price') | translate"
            [cellClass]="' metal-price ellipsis-counter'"
            [resizeable]="false"
            [sortable]="false"
          >
            <ng-template
              let-metalPrice="row.metalPrice"
              let-row="row"
              let-purchaseType="row.purchaseType"
              ngx-datatable-cell-template
            >
              <ng-container *ngIf="purchaseType === 'WITH_METAL_PRICE' && metalPrice">
                <p class="ellipsis composition">{{ metalPrice.join(", ") }}</p>
                <div *ngIf="metalPrice.length >= 2" title="{{ row.counter }}">
                  <div #compositionCounter>
                    <ui-counter [number]="metalPrice.length"></ui-counter>
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="compositionCounter">
                    <span style="font-weight: normal !important; white-space: break-spaces">{{
                      metalPrice.join(", ")
                    }}</span>
                  </ui-tooltip>
                </div>
              </ng-container>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('gross-price-default-currency') | translate"
            [prop]="'grossPriceDefaultCurrency'"
            [cellClass]="' gross-price-default-currency'"
            [resizeable]="false"
          >
            <ng-template let-grossPriceDefaultCurrency="row.grossPriceDefaultCurrency" ngx-datatable-cell-template>
              <div *ngIf="grossPriceDefaultCurrency !== null">
                {{ grossPriceDefaultCurrency | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-5":codeLanguage }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('gross-price') | translate"
            [cellClass]="' gross-price'"
            [resizeable]="false"
            [sortable]="false"
          >
            <ng-template
              let-grossPrice="row.grossPrice"
              let-purchaseCurrency="row.currency"
              ngx-datatable-cell-template
            >
              <div *ngIf="grossPrice !== null">
                {{ grossPrice | formatCurrency: purchaseCurrency.codeISO:"symbol":"0.2-5":codeLanguage }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('discount') | translate"
            [cellClass]="' discount'"
            [resizeable]="false"
            [sortable]="false"
          >
            <ng-template let-discount="row.discount" ngx-datatable-cell-template>
              <div *ngIf="discount !== null">
                {{ discount | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-5":codeLanguage }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('net-price') | translate"
            [cellClass]="' net-price'"
            [resizeable]="false"
            [sortable]="false"
          >
            <ng-template let-netPrice="row.netPrice" ngx-datatable-cell-template>
              <div *ngIf="netPrice !== null">
                {{ netPrice | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-5":codeLanguage }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('extra-fees') | translate"
            [cellClass]="' extra-fees'"
            [resizeable]="false"
            [sortable]="false"
          >
            <ng-template let-extraFees="row.extraFees" ngx-datatable-cell-template>
              <div *ngIf="extraFees !== null">
                {{ extraFees | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-5":codeLanguage }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('cost-price') | translate"
            [cellClass]="' cost-price'"
            [resizeable]="false"
            [sortable]="false"
          >
            <ng-template let-costPrice="row.costPrice" ngx-datatable-cell-template>
              <div *ngIf="costPrice !== null">
                {{ costPrice | formatCurrency: defaultCurrency.codeISO:"symbol":"0.2-5":codeLanguage }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('from') | translate"
            [prop]="'from'"
            [cellClass]="' from'"
            [resizeable]="false"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('to') | translate"
            [prop]="'to'"
            [cellClass]="' to'"
            [resizeable]="false"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('purchase-unit') | translate"
            [prop]="'purchaseUnitName'"
            [cellClass]="' purchase-unit'"
            [resizeable]="false"
            [sortable]="false"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('activated') | translate"
            [cellClass]="'center-align frozen-style'"
            [headerClass]="'center-align'"
            [prop]="'activated'"
            [frozenRight]="true"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
              <div class="green-zone" (click)="$event.stopPropagation()">
                <ui-switch
                  customClass="no-margin"
                  (change)="onArchiveCheckboxChanges(id)"
                  [formControl]="tableControl.get('activated_' + id)"
                >
                </ui-switch>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="rowCount">
                {{
                  rowCount
                    | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                    | translate: { rowCount: rowCount }
                }}
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                [pagerRightArrowIcon]="'fas fa-chevron-right'"
                [pagerNextIcon]="'fas fa-chevron-double-right'"
                [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="table.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>

  <!-- Popup -->
  <app-retail-item-purchase-modalities-popup
    *ngIf="popupVisible"
    [purchaseModality]="purchaseModalitiesList[selectedPurchaseModalityIndex]"
    [itemTheoreticalWeight]="itemTheoreticalWeight"
    (validate)="onPopupSubmitClick($event)"
    [itemComposition]="itemComposition"
    [mainUnitId]="mainUnitId"
    [itemWeight]="itemWeight"
    (close)="closePopup()"
    [itemCategoryType]="itemCategoryType"
  ></app-retail-item-purchase-modalities-popup>
</ng-container>
