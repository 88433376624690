import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { faInfoCircle, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-stock-entry-label-print-settings-popup",
  templateUrl: "./stock-entry-label-print-settings-popup.component.html",
  styleUrls: ["./stock-entry-label-print-settings-popup.component.scss"],
})
export class StockEntryLabelPrintSettingsPopupComponent implements OnInit, OnChanges {
  @Input() stockEntryLabelList: any[];

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public popupForm: UntypedFormGroup;
  public firstLabelIndex: number = 1;

  public shouldClose: boolean = false;
  public popupTitle: string;
  public bodyMessage: string;

  public faInfoCircle: IconDefinition = faInfoCircle;
  private readonly MIN_RANGE: number = 1;
  // eslint-disable-next-line no-magic-numbers
  private readonly MAX_RANGE: number = 33;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService
  ) {}

  ngOnChanges(): void {
    this.prepareForm();

    this.popupTitle = this.translateService.instant("stock-entry-label-list.settings-popup.title");
    this.bodyMessage = this.translateService.instant("stock-entry-label-list.settings-popup.body-message");

    this.refresh();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      firstLabelIndex: [0, [Validators.required, this.rangeNumberValidator()]],
    });
  }

  rangeNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      control.value >= this.MIN_RANGE && control.value <= this.MAX_RANGE ? null : { outOfRange: control.value };
  }

  initializePopup(): void {
    if (this.stockEntryLabelList && this.stockEntryLabelList.length > 0) {
      this.popupForm.controls.firstLabelIndex.setValue(1);
    }
  }

  closePopup(): void {
    this.firstLabelIndex = this.popupForm.value.firstLabelIndex;
    this.close.emit();
  }

  submitSettingsPopup(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    this.validate.emit(this.popupForm.value.firstLabelIndex);
  }
}
