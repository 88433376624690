<div class="receipt-validation-popup">
  <ui-popup
    (close)="closeValidationPopup()"
    [title]="
      multiple ? ('receipt-validation-popup.title-plural' | translate) : ('receipt-validation-popup.title' | translate)
    "
  >
    <div content>
      <div class="info-message">
        <div class="igniter">
          {{
            multiple
              ? ("receipt-validation-popup.igniter-plural" | translate)
              : ("receipt-validation-popup.igniter" | translate)
          }}
        </div>

        <ul class="action-list">
          <li class="action">
            {{ "receipt-validation-popup.stock" | translate }}
          </li>
          <li class="action">
            {{ "receipt-validation-popup.police-book" | translate }}
          </li>
          <li class="action">
            {{ "receipt-validation-popup.labels" | translate }}
          </li>
          <li class="action" *ngIf="!withRegistering">
            {{ "receipt-validation-popup.metal-account" | translate }}
          </li>
        </ul>
      </div>
      <div class="warning-message" *ngIf="warn">
        <div class="block icon">
          <fa-icon class="warn-icon fa-lg" [icon]="faWarn"></fa-icon>
        </div>
        <div class="block">
          <div class="bold warning warning-title">
            {{ "receipt-validation-popup.warning-title" | translate }}
          </div>
          <div class="warning warning-text">
            {{ "receipt-validation-popup.warning-message" | translate }}
          </div>
        </div>
      </div>

      <div class="bold question">
        {{
          multiple
            ? ("receipt-validation-popup.question-plural" | translate)
            : ("receipt-validation-popup.question" | translate)
        }}
      </div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              [text]="'receipt-validation-popup.yes' | translate"
              [primary]="true"
              type="validate"
              (click)="validateReceipt()"
            ></ui-button>
          </div>
        </div>
        <div class="button-popup-cancel">
          <div class="button second-button">
            <ui-button [text]="btnLabel | translate" type="cancel" (click)="closeValidationPopup()"></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
