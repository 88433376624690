<form class="override-size-category-form" [formGroup]="tableControl" novalidate>
  <div class="popup-container">
    <ui-popup title="{{ popupTitle }}" (close)="closePopup()">
      <div content>
        <div class="size-values-table">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            class="material"
            columnMode="flex"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [limit]="7"
            [rowClass]="getRowClass"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'override-size-category-popup.datatable.columns.values' | translate }}"
              prop="value"
              [flexGrow]="6"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'override-size-category-popup.datatable.columns.active' | translate }}"
              prop="activated"
              headerClass="center-align"
              cellClass="center-align"
              [flexGrow]="1"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <ui-switch
                  customClass="no-margin"
                  (change)="activeCheckboxOnChanges(id)"
                  [formControl]="tableControl.get('activated_' + id)"
                >
                </ui-switch>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount > 0">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'global.validate' | translate }}"
                type="save"
                [primary]="true"
                (click)="submitOverrideSizeCategory()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</form>
