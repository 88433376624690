/* eslint-disable no-magic-numbers */
import { AfterContentChecked, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faPen } from "@fortawesome/pro-solid-svg-icons";
import { AbstractInvoice, CaraUserService, PaymentPeriodType } from "center-services";
import { DayjsUtil, SubscriptionService } from "fugu-common";
import dayjs from "dayjs";
import { Subject } from "rxjs";

@Component({
  selector: "app-invoice-form-header",
  templateUrl: "./invoice-form-header.component.html",
  styleUrls: ["./invoice-form-header.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceFormHeaderComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() quickPaymentType: PaymentPeriodType;
  @Input() quickPaymentPeriod: number;
  @Input() maxPaymentType: PaymentPeriodType;
  @Input() maxPaymentPeriod: number;
  @Input() editedInvoice: AbstractInvoice;
  @Input() readOnly: boolean;
  @Input() showReference: boolean;
  @Input() showComment: boolean;
  @Input() modeIsConform: boolean;

  public invoiceHeaderForm: UntypedFormGroup;
  public popupForm: UntypedFormGroup;
  public popupVisible: boolean = false;

  public dateFormat: string;
  public locale: string;
  public faPen: IconDefinition = faPen;
  onChanges: Subject<SimpleChanges> = new Subject<SimpleChanges>();
  private readonly DAY_LIMIT: number = 21;

  constructor(
    private userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {}

  ngAfterContentChecked(): void {
    if (this.editedInvoice) {
      this.subscriptionService.subs.push(
        this.invoiceHeaderForm.get("date").valueChanges.subscribe(() => {
          if (this.invoiceHeaderForm.controls.date.value) {
            this.invoiceHeaderForm.controls.maxPaymentDate.patchValue(
              this.getPaymentDate(this.maxPaymentType, this.maxPaymentPeriod)
            );
            this.invoiceHeaderForm.controls.quickPaymentDate.patchValue(
              this.getPaymentDate(this.quickPaymentType, this.quickPaymentPeriod)
            );
          }
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.locale = this.userService.connectedUser.value.codeLanguage;
    } else {
      this.subscriptionService.subs.push(
        this.userService.connectedUser.subscribe(user => {
          this.dateFormat = user.dateFormat;
          this.locale = user.codeLanguage;
        })
      );
    }
    this.prepareForms();
    if (!this.editedInvoice) {
      return;
    }
    this.loadEditedData();
  }

  public updateInvoiceHeader(): boolean {
    if (this.invoiceHeaderForm.valid) {
      this.applyModifications();
      return true;
    }
    this.invoiceHeaderForm.markAllAsTouched();
    return false;
  }

  public applyModifications(): void {
    if (this.showReference) {
      this.editedInvoice.reference = this.invoiceHeaderForm.value.invoiceRef;
    }
    if (this.showComment) {
      this.editedInvoice.setComment(this.invoiceHeaderForm.value.comment);
    }

    const quickValue = this.invoiceHeaderForm.value.quickPaymentDate;
    const maxValue = this.invoiceHeaderForm.value.maxPaymentDate;

    const quickPaymentDate = quickValue instanceof Date || !quickValue ? quickValue : quickValue.toDate();

    const maxPaymentDate = maxValue instanceof Date || !maxValue ? maxValue : maxValue.toDate();

    const date: Date = this.invoiceHeaderForm.value.date ? this.invoiceHeaderForm.value.date.toDate() : null;
    // tslint:disable-next-line:no-magic-numbers
    date?.setUTCHours(12, 0, 0, 0);
    this.editedInvoice.date = date;
    this.editedInvoice.quickPaymentDate = quickValue ? quickPaymentDate : null;
    this.editedInvoice.maxPaymentDate = maxValue ? maxPaymentDate : null;
  }

  getPaymentDate(paymentType: PaymentPeriodType, paymentPeriod: number): dayjs.Dayjs {
    const invoiceDate: Date = this.invoiceHeaderForm.controls.date.value
      ? this.invoiceHeaderForm.controls.date.value.toDate()
      : null;
    if (!invoiceDate) {
      return null;
    }
    const lastDay = new Date(invoiceDate.getFullYear(), invoiceDate.getMonth() + 1, 0);

    let date;

    switch (paymentType) {
      case PaymentPeriodType.INVOICE_DATE:
        date = DayjsUtil.addDays(invoiceDate, paymentPeriod);
        break;
      case PaymentPeriodType.END_OF_MONTH:
        date = DayjsUtil.addDays(lastDay, paymentPeriod);
        break;
      case PaymentPeriodType.PERIODIC:
        date = this.getDateFromPeriodicType(paymentPeriod, invoiceDate);
        break;
      case null:
        date = null;
        break;
      default:
        console.error(`Don't know how to handle payment period type : ${paymentType}`);
        break;
    }
    return DayjsUtil.dayjsOrNull(date, true);
  }

  getDateFromPeriodicType(paymentPeriod: number, invoiceDate: Date): Date {
    if (invoiceDate.getDate() >= this.DAY_LIMIT) {
      const lastDay = new Date(invoiceDate.getFullYear(), invoiceDate.getMonth() + 1, 0);
      return lastDay.getDate() === invoiceDate.getDate()
        ? DayjsUtil.addDays(invoiceDate, paymentPeriod)
        : new Date(invoiceDate.getFullYear(), invoiceDate.getMonth() + 1, 0);
    }
    const dateToReturn = new Date(Number(invoiceDate));
    dateToReturn.setDate(paymentPeriod * (Math.trunc(invoiceDate.getDate() / paymentPeriod) + 1));
    return dateToReturn;
  }

  public openCommentPopup(): void {
    this.popupForm.controls.comment.patchValue(this.invoiceHeaderForm.controls.comment.value);
    this.popupVisible = true;
  }

  public closePopup(): void {
    this.popupForm.controls.comment.patchValue("");
    this.popupVisible = false;
  }

  // handle comment popup

  public saveComment(): void {
    this.invoiceHeaderForm.controls.comment.patchValue(this.popupForm.controls.comment.value);
    this.closePopup();
  }

  private prepareForms(): void {
    this.invoiceHeaderForm = this.fb.group({
      date: [null, [Validators.required]],
      quickPaymentDate: [null],
      maxPaymentDate: [null],
    });

    if (this.showReference) {
      this.invoiceHeaderForm.addControl("invoiceRef", new UntypedFormControl(null, Validators.required));
    }

    if (this.showComment) {
      this.popupForm = this.fb.group({ comment: [null] });
      this.invoiceHeaderForm.addControl("comment", new UntypedFormControl(null));
    }

    this.subscriptionService.subs.push(
      this.invoiceHeaderForm.valueChanges.subscribe(() => {
        if (this.showComment) {
          this.editedInvoice.setComment(this.invoiceHeaderForm.value.comment);
        }
      })
    );
  }

  private loadEditedData(): void {
    if (this.showReference) {
      this.invoiceHeaderForm.controls.invoiceRef.patchValue(this.editedInvoice.reference);
    }
    if (this.showComment) {
      this.invoiceHeaderForm.controls.comment.patchValue(this.editedInvoice.getComment());
    }

    if (this.modeIsConform) {
      this.invoiceHeaderForm.controls.date.patchValue(DayjsUtil.dayjsOrNull(new Date()));
      this.invoiceHeaderForm.controls.maxPaymentDate.patchValue(
        this.getPaymentDate(this.maxPaymentType, this.maxPaymentPeriod)
      );
      this.invoiceHeaderForm.controls.quickPaymentDate.patchValue(
        this.getPaymentDate(this.quickPaymentType, this.quickPaymentPeriod)
      );
      return;
    }

    this.invoiceHeaderForm.controls.date.patchValue(
      this.editedInvoice.date ? DayjsUtil.dayjsOrNull(this.editedInvoice.date) : DayjsUtil.dayjsOrNull(new Date())
    );
    this.invoiceHeaderForm.controls.maxPaymentDate.patchValue(
      this.editedInvoice.maxPaymentDate
        ? DayjsUtil.dayjsOrNull(this.editedInvoice.maxPaymentDate)
        : this.getPaymentDate(this.maxPaymentType, this.maxPaymentPeriod)
    );
    this.invoiceHeaderForm.controls.quickPaymentDate.patchValue(
      this.editedInvoice.quickPaymentDate
        ? DayjsUtil.dayjsOrNull(this.editedInvoice.quickPaymentDate)
        : this.getPaymentDate(this.quickPaymentType, this.quickPaymentPeriod)
    );
  }
}
