import { Comparator } from "fugu-common";

export class ShipmentPopup extends Comparator {
  recipient: number;
  supplier: number;
  customer: number;
  store: number;

  constructor(values: any = {}) {
    super();
    this.recipient = values.recipient;
    this.supplier = values.supplier;
    this.customer = values.customer;
    this.store = values.store;
  }
}
