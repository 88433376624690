import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  Inventory,
  Currency,
  Store,
  StockLocation,
  Pagination,
  StockEntryService,
  CurrencyService,
  ItemCategoryService,
  LightService,
  StockLocationService,
  StoreService,
  CaraUserService,
  ItemCategory,
  CategoryType,
  Light,
  MovementType,
  PaginatedList,
  StockEntryLocation,
  StockType,
  Sort,
} from "center-services";
import {
  FilterValue,
  Filterer,
  Option,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import hash from "string-hash";

@Component({
  selector: "app-inventory-criteria",
  templateUrl: "./inventory-criteria.component.html",
  styleUrls: ["./inventory-criteria.component.scss"],
  providers: [SubscriptionService],
})
export class InventoryCriteriaComponent
  extends FilteredTableListComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  @Input() inventory: Inventory;

  @Output() isListEmptyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("table") table: any;

  public LIST_ID: string = "app-inventory-criteria.app-inventory-criteria-table";

  public stockEntryLocationsList: StockEntryLocation[] = [];
  public sorts: any[] = [];
  public rows: any[] = [];
  public defaultCurrency: Currency;
  public dateFormat: string;
  public codeLanguage: string;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  private itemCategoryOptions: Option[] = [];
  private supplierOptions: Option[] = [];
  private locationOptions: Option[] = [];
  private inventoryStore: Store;
  private rootLocation: StockLocation;
  private flattenedStockLocations: StockLocation[] = [];
  private sessionPagination: SessionPagination;

  private initObservables: Observable<any>[];

  constructor(
    private stockEntryService: StockEntryService,
    translateService: TranslateService,
    private currencyService: CurrencyService,
    messageService: MessageService,
    private itemCategoryService: ItemCategoryService,
    private lightService: LightService,
    private cdRef: ChangeDetectorRef,
    private stockLocationService: StockLocationService,
    private storeService: StoreService,
    userService: CaraUserService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  public ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.codeLanguage = this.userService.connectedUser.value.codeLanguage;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.dateFormat = user.dateFormat;
        this.codeLanguage = user.codeLanguage;
      })
    );

    this.initObservables = [];
    this.initObservables.push(this.fetchDefaultCurrency());
    this.initObservables.push(this.fetchItemCategoryList());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchInventoryStore());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.inventoryStore) {
          this.initFilters();
          this.sessionPagination.loadFromSession(this.LIST_ID);
          this.computeSearchFilters();
          this.fetchStockEntryLocations();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      SessionPagination.clear(this.LIST_ID);
    }
  }

  fetchItemCategoryList(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (itemCategories: ItemCategory[]) => {
          this.itemCategoryOptions = itemCategories
            .filter((obj: ItemCategory) => !obj.archived && obj.type === CategoryType.STANDARD)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: ItemCategory) => new Option(obj.id, obj.name));
        },
        error => {
          this.sendErrorAlert(`inventory-criteria.errors.get-item-categories`, error.message);
        }
      )
    );
  }

  fetchInventoryStore(): Observable<StockLocation[]> {
    return this.storeService.get(this.inventory.storeId).pipe(
      tap(
        (inventoryStore: Store) => {
          this.inventoryStore = inventoryStore;
        },
        error => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("inventory-criteria.errors.get-inventory-store", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      ),
      switchMap((inventoryStore: Store) => {
        return this.fetchStockLocations(inventoryStore);
      })
    );
  }

  fetchDefaultCurrency(): Observable<Currency> {
    const observable = this.currencyService.fetchDefaultCurrency().pipe(filter(cur => cur !== undefined));
    this.subscriptionService.subs.push(
      observable.subscribe(currency => {
        this.defaultCurrency = currency;
      })
    );
    return observable;
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchStockEntryLocations();
      })
    );
  }

  getChildrenLocations(parentIds: number[]): number[] {
    const parentWithChildrenIds: number[] = [];
    if (parentIds !== undefined) {
      for (const parentId of parentIds) {
        const parent: StockLocation = this.flattenedStockLocations.find(sl => sl.id === parentId);
        parentWithChildrenIds.push(...this.getChildrenWithParentIds(parent));
      }
      return parentWithChildrenIds;
    }
    return parentWithChildrenIds;
  }

  getChildrenWithParentIds(parent: StockLocation): number[] {
    const childrenWithParentIds: number[] = [parent.id];
    this.flattenedStockLocations
      .filter(fsl => fsl.level === parent.level + 1 && fsl.parentId === parent.id)
      .forEach(fsl => {
        childrenWithParentIds.push(...this.getChildrenWithParentIds(fsl));
      });
    return childrenWithParentIds;
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "batchNumber") {
      this.sorts = [
        {
          prop: "stockEntry.stockType",
          dir: "asc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else if (prop === "serialNumber") {
      this.sorts = [
        {
          prop: "stockEntry.stockType",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop,
          dir: direction,
        },
      ];
    }
    this.fetchStockEntryLocations();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchStockEntryLocations();
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  getRowClass: any = (): any => ({ "not-clickable": true });

  public getActiveFilters(): SearchFilter[] {
    return this.activeFilters;
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierOptions = lightSuppliers
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Light) => {
              return new Option(obj.id, obj.name);
            });
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("inventory-criteria.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchStockLocations(inventoryStore: Store): Observable<StockLocation[]> {
    return this.stockLocationService.getAll(["withChildren", "onlyOrigin"], inventoryStore.id).pipe(
      tap(
        (stockLocations: StockLocation[]) => {
          this.flattenedStockLocations = this.stockLocationService.getFlattenedLocations(stockLocations);
          this.rootLocation = this.flattenedStockLocations.find(
            stockLocation => stockLocation.id === this.inventoryStore.originLocationId
          );
          this.locationOptions = this.flattenedStockLocations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: StockLocation) => new Option(obj.id, obj.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("inventory-criteria.errors.get-stock-locations");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchStockEntryLocations(): void {
    const cloneActiveFilters = [...this.activeFilters];
    cloneActiveFilters.push(new SearchFilter("movementType", SearchFilterOperator.NOT_EQUAL, [MovementType.TRANSIT]));

    this.subscriptionService.subs.push(
      this.stockEntryService.getStockEntryLocations(this.pager, null, this.getSorter(), cloneActiveFilters).subscribe(
        (result: PaginatedList<StockEntryLocation>) => {
          this.stockEntryLocationsList = result.data;
          this.pager = result.page;
          this.rows = [];

          if (this.stockEntryLocationsList.length === 0) {
            this.isListEmptyChange.emit(true);
          } else {
            this.isListEmptyChange.emit(false);
          }
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("inventory-criteria.errors.get-stock-entries", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        },
        () => {
          // if (!initialFetch) {
          this.stockEntryLocationsList.forEach((stockEntryLocation: StockEntryLocation) =>
            this.addRow(stockEntryLocation)
          );
          this.rows = [...this.rows];
          // }
          // this.initFilters();

          // this.isLoaded.complete();
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
        }
      )
    );
  }

  private addRow(stockEntryLocation: StockEntryLocation): void {
    const row = {
      id: stockEntryLocation.id,
      itemCategory: stockEntryLocation.stockEntry.itemCategoryName,
      itemInternalCode: stockEntryLocation.stockEntry.itemReference,
      itemSupplierRef: stockEntryLocation.stockEntry.itemSupplierRef,
      name: stockEntryLocation.stockEntry.itemName,
      serialNumber: null,
      batchNumber: null,
      supplier: stockEntryLocation.stockEntry.supplierName,
      universe: stockEntryLocation.stockEntry.universeNames,
      itemGroupName: stockEntryLocation.stockEntry.itemGroupNames,
      sellPrice: stockEntryLocation.stockEntry.sellPrice,
      location: stockEntryLocation.locationName,
      lastInventoryDate: stockEntryLocation.lastInventoryDate,
    };

    switch (stockEntryLocation.stockEntry.stockType) {
      case StockType.SERIAL_NUMBER:
        row.serialNumber = stockEntryLocation.stockEntry.id;
        break;
      case StockType.BATCH:
      case StockType.BULK:
        row.batchNumber = stockEntryLocation.stockEntry.id;
        break;
      default:
        console.error(`${stockEntryLocation.stockEntry.stockType} is an unknown stock type !`);
    }

    this.rows.push(row);
  }

  private initFilters(): void {
    if (this.filterer) {
      return;
    }

    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );

    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addListFilter(
      this.propToDto("itemCategory"),
      this.translateService.instant("inventory-criteria.datatable.columns.item-category"),
      this.itemCategoryOptions
        .map((option: Option) => {
          return { value: option.label, displayValue: option.label };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
    );

    this.filterer.addFilter(
      this.propToDto("itemInternalCode"),
      this.translateService.instant("inventory-criteria.datatable.columns.item-internal-code"),
      "string"
    );

    this.filterer.addFilter(
      this.propToDto("itemSupplierRef"),
      this.translateService.instant("inventory-criteria.datatable.columns.item-supplier-reference"),
      "string"
    );

    this.filterer.addFilter(
      this.propToDto("name"),
      this.translateService.instant("inventory-criteria.datatable.columns.name"),
      "string"
    );

    this.filterer.addFilter(
      "serial.stockEntry.id",
      this.translateService.instant("inventory-criteria.datatable.columns.serial-number"),
      "string"
    );

    this.filterer.addFilter(
      "batch.stockEntry.id",
      this.translateService.instant("inventory-criteria.datatable.columns.batch-number"),
      "string"
    );

    this.filterer.addListFilter(
      this.propToDto("supplier"),
      this.translateService.instant("inventory-criteria.datatable.columns.supplier"),
      this.supplierOptions
        .map((option: Option) => {
          return { value: option.label, displayValue: option.label };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
    );

    this.filterer.addFilter(
      this.propToDto("sellPrice"),
      this.translateService.instant("inventory-criteria.datatable.columns.sell-price"),
      "range"
    );

    this.filterer.addFilter(
      this.propToDto("lastInventoryDate"),
      this.translateService.instant("inventory-criteria.datatable.columns.last-inventory-date"),
      "date"
    );

    if (this.rootLocation) {
      this.filterer.addListFilter(
        this.propToDto("location"),
        this.translateService.instant("inventory-criteria.location-filter"),
        this.locationOptions
          .map((option: Option) => {
            return { value: option.label, displayValue: option.label };
          })
          .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
        true,
        true,
        [this.rootLocation.name],
        this.translateService.instant("inventory-criteria.location-filter-tooltip"),
        true
      );
    }
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
    this.activeFilters.forEach((sf, index) => {
      if (sf.key === "serial.stockEntry.id") {
        const filterValue = this.filterer.filterValues.find(fv => fv.filterId === hash("serial.stockEntry.id"));
        this.applyStockType(filterValue, StockType.SERIAL_NUMBER, index);
      }
      if (sf.key === "batch.stockEntry.id") {
        const filterValue = this.filterer.filterValues.find(fv => fv.filterId === hash("batch.stockEntry.id"));
        this.applyStockType(filterValue, [StockType.BATCH, StockType.BULK], index);
      }
      if (sf.key === "stockLocation.name") {
        this.applyStockLocation(sf, index);
      }
    });
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  private applyStockType(filterValue: FilterValue, stockType: any, index: number): void {
    let sf;
    if (filterValue.equal === null) {
      return;
    }
    // return no line if user types letters
    if (isNaN(+filterValue.equal)) {
      filterValue.equal = "0";
    }

    if (filterValue.equal !== "") {
      sf = new SearchFilter(
        "stockEntry.id",
        SearchFilterOperator.LIKE,
        filterValue.equal.toString(),
        filterValue.filterId
      );
      this.activeFilters.splice(index, 1, sf);

      this.activeFilters.push(
        new SearchFilter("stockEntry.stockType", SearchFilterOperator.IN, stockType, filterValue.filterId)
      );
    }
  }

  private applyStockLocation(searchFilter: SearchFilter, index: number): void {
    if (searchFilter === null) {
      return;
    }

    const selectedLocations: string[] = searchFilter.value.split("~");

    const selectedLocationIds: number[] = selectedLocations.map(location => {
      return this.locationOptions.find(option => option.label === location)?.id as number;
    });

    this.activeFilters.splice(index, 1);

    this.activeFilters.push(
      new SearchFilter("stockLocation.id", SearchFilterOperator.IN, this.getChildrenLocations(selectedLocationIds))
    );
  }

  private propToDto(prop: string): string {
    switch (prop) {
      case "itemInternalCode":
        return "stockEntry.retailItem.reference";
      case "itemCategory":
        return "stockEntry.retailItem.category.name";
      case "itemSupplierRef":
        return "stockEntry.itemSupplierRef";
      case "name":
        return "stockEntry.retailItem.name";
      case "supplier":
        return "stockEntry.supplier.name";
      case "sellPrice":
        return "stockEntry.sellPrice";
      case "location":
        return "stockLocation.name";
      case "batchNumber":
        return "stockEntry.id";
      case "serialNumber":
        return "stockEntry.id";
      default:
        return prop;
    }
  }
}
