export class StringUtils {
  public static toCamelCase(name: string): string {
    if (!name) {
      return null;
    }
    return name.replace(/-([a-z])/g, g => g[1].toUpperCase());
  }

  public static toSnakeCase(name: string): string {
    if (!name) {
      return null;
    }
    return name.replace(/[A-Z]/g, (letter, index) => (index === 0 ? letter.toLowerCase() : `-${letter.toLowerCase()}`));
  }

  public static isNullOrUndefinedOrEmpty(value: string): boolean {
    return value === null || value === undefined || value === "";
  }
}
