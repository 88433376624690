import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { faCommentsQuestionCheck, faHeadset, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-contact-support-popup",
  templateUrl: "./contact-support-popup.component.html",
  styleUrls: ["./contact-support-popup.component.scss"],
})
export class ContactSupportPopupComponent implements OnInit {
  @Output() closePopupEvent: EventEmitter<any> = new EventEmitter();

  public popupTitle: string;
  public freshdesk: string;

  public faCommentsQuestionCheck: IconDefinition = faCommentsQuestionCheck;
  public faHeadset: IconDefinition = faHeadset;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.get("contact-support-popup.title").subscribe((res: string) => {
      this.popupTitle = res;
    });
    this.translateService.get("contact-support-popup.freshdesk").subscribe((res: string) => {
      this.freshdesk = res;
    });
  }

  closePopup(): void {
    this.closePopupEvent.emit();
  }

  openFreshdeskWindow(): void {
    window.open("https://neofugu.freshdesk.com/support/login", "_blank");
  }
}
