import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { IconDefinition, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { UserLink, CaraUser, Job, StoreService, JobService, JobType } from "center-services";
import { SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";

@Component({
  selector: "app-employees-list",
  templateUrl: "./employees-list.component.html",
  styleUrls: ["./employees-list.component.scss"],
  providers: [SubscriptionService],
})
export class EmployeesListComponent implements OnInit, OnChanges {
  @Input() canUpdate: boolean = true;
  @Input() canAdd: boolean = true;

  @Input() employeesList: UserLink[];
  @Input() storeId: number;
  @Input() supervisorId: number;
  @Input() storeName: string;

  @Output() employeesListChange: EventEmitter<UserLink[]> = new EventEmitter<UserLink[]>();
  @Output() supervisorIdChange: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild("table") table: DatatableComponent;

  public selectedUser: CaraUser = null;
  public popupVisible: boolean = false;

  public tableControl: UntypedFormGroup;
  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "lastName",
      dir: "asc",
    },
  ];
  public userList: CaraUser[] = [];
  faPlus: IconDefinition = faPlus;
  private jobs: Job[];

  constructor(
    private storeService: StoreService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private jobService: JobService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employeesList && !changes.employeesList.firstChange) {
      this.refresh();
    }
  }

  refresh(): void {
    if (!this.tableControl) {
      this.tableControl = new UntypedFormGroup({});
    }
    this.fetchJobs();
  }

  fetchJobs(): void {
    this.subscriptionService.subs.push(
      this.jobService.getAllByType(JobType.EMPLOYEE).subscribe(
        (jobs: Job[]) => {
          this.jobs = jobs;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("jobs-list.errors.get-jobs");
          this.messageService.warn(content, { title });
        },
        () => {
          this.fetchUserList();
        }
      )
    );
  }

  addRow(user: CaraUser): void {
    const row = this.convertUserToRow(user);
    this.rows.push(row);
    this.rows = [...this.rows];
  }

  convertUserToRow(user: CaraUser): any {
    const employee = this.employeesList.find(e => e.userId === user.id);
    return {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      userNumber: user.userNumber,
      lastName: user.lastName,
      phone: this.formatPhoneNumber(user.phone),
      mobilePhone: this.formatPhoneNumber(user.mobilePhone),
      jobs: user.jobIds && this.jobs ? user.jobIds.map(jobId => this.jobs.find(job => job.id === jobId).name) : [],
      login: user.login,
      rights: user.rights,
      profileId: user.profileId,
      password: user.password,
      dateFormat: user.dateFormat,
      hourlyWage: user.hourlyWage,
      profileName: user.profileName,
      codeLanguage: user.codeLanguage,
      adminLicense: user.adminLicense,
      pinCode: user.pinCode,
      hireDate: user.hireDate,
      endContractDate: user.endContractDate,
      safeAbility: user.safeAbility,
      weeklyWorkTime: user.weeklyWorkTime,
      comment: user.comment,
      activated: !employee.archived,
    };
  }

  formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber ? phoneNumber.match(/.{1,2}/g).join(" ") : null;
  }

  fetchUserList(): void {
    if (this.employeesList && this.employeesList.length > 0) {
      this.subscriptionService.subs.push(
        this.storeService.getEmployees(this.storeId).subscribe((userList: CaraUser[]) => {
          this.userList = userList;

          if (this.employeesList.length !== this.userList.length) {
            return;
          }

          userList.forEach((user: CaraUser) => {
            if (user.archived) {
              const isArchived = this.employeesList.findIndex((userLink: UserLink) => userLink.userId === user.id);
              if (isArchived >= 0) {
                this.employeesList.splice(isArchived, 1);
                this.employeesList = [...this.employeesList];
              }
            }
          });

          this.employeesListChange.emit(this.employeesList);
          this.rows = [];
          this.userList
            .filter((user: CaraUser) => !user.archived)
            .forEach(user => {
              this.addRow(user);

              const employee = this.employeesList.find(e => e.userId === user.id);
              if (this.tableControl.controls[`activated_${user.id}`]) {
                this.tableControl.controls[`activated_${user.id}`].patchValue(!employee.archived);
              } else {
                this.tableControl.addControl(
                  `activated_${user.id}`,
                  new UntypedFormControl({ value: !employee.archived, disabled: !this.canUpdate })
                );
              }

              // set supervisor form controls
              const isSupervisor = this.supervisorId === user.id;
              if (this.tableControl.controls[`supervisor_${user.id}`]) {
                this.tableControl.controls[`supervisor_${user.id}`].patchValue(isSupervisor);
              } else {
                this.tableControl.addControl(
                  `supervisor_${user.id}`,
                  new UntypedFormControl({ value: isSupervisor, disabled: !this.canUpdate })
                );
              }
            });
        })
      );
    }
  }

  checkboxOnChanges(index: number): void {
    if (!this.canUpdate) {
      return;
    }
    const rowIndex = this.employeesList.findIndex(employee => employee.userId === index);
    this.employeesList[rowIndex].archived = !this.tableControl.controls[`activated_${index}`].value;
    this.rows[rowIndex].activated = this.tableControl.controls[`activated_${index}`].value;
    this.rows = [...this.rows];
    this.employeesListChange.emit(this.employeesList);

    // handle supervisor
    if (
      !this.tableControl.controls[`activated_${index}`].value &&
      this.tableControl.controls[`supervisor_${index}`].value
    ) {
      this.tableControl.controls[`supervisor_${index}`].patchValue(
        this.tableControl.controls[`activated_${index}`].value
      );
      this.supervisorIdChange.emit(null);
    }
  }

  supervisorOnChanges(id: number): void {
    if (!this.canUpdate) {
      return;
    }
    if (this.tableControl.controls[`supervisor_${id}`].value) {
      this.rows.forEach((row: any) => {
        const value = row.id === id;
        this.tableControl.controls[`supervisor_${row.id}`].patchValue(value);
      });
      this.supervisorIdChange.emit(id);
    } else {
      this.supervisorIdChange.emit(null);
    }
  }

  addUser(event: any): void {
    if (!this.canAdd) {
      return;
    }
    event.stopPropagation();
    this.popupVisible = true;
  }

  updateUser(event: any): void {
    if (event.type === "click") {
      if (!this.canUpdate) {
        return;
      }
      const updatedUser = this.userList.find(user => user.id === event.row.id);
      if (updatedUser.adminLicense) {
        const title = this.translateService.instant("employees-list.errors.update-title");
        const content = this.translateService.instant("employees-list.errors.update-admin");
        this.messageService.info(content, { title });
        return;
      }
      this.popupVisible = true;
      this.selectedUser = updatedUser;
    }
  }

  onCloseUserPopup(): void {
    this.popupVisible = false;
    this.selectedUser = null;
  }

  onValidateUser(validatedUser: CaraUser): void {
    const userIndex = this.userList.findIndex(user => user.id === validatedUser.id);

    if (userIndex >= 0) {
      this.userList[userIndex] = validatedUser;
      const rowIndex = this.rows.findIndex(user => user.id === validatedUser.id);
      this.rows[rowIndex] = this.convertUserToRow(validatedUser);
      this.rows = [...this.rows];
    } else {
      this.employeesList.push(new UserLink({ userId: validatedUser.id, archived: false }));
      this.userList.push(validatedUser);
      this.addRow(validatedUser);
      this.tableControl.addControl(
        `activated_${validatedUser.id}`,
        new UntypedFormControl({ value: true, disabled: !this.canUpdate })
      );

      this.tableControl.addControl(
        `supervisor_${validatedUser.id}`,
        new UntypedFormControl({ value: false, disabled: !this.canUpdate })
      );
    }
    this.onCloseUserPopup();
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }
}
