import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { CaraUser, CaraUserService, Nomenclature } from "center-services";
import { DayjsUtil, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-retail-item-nomenclature-popup",
  templateUrl: "./retail-item-nomenclature-popup.component.html",
  styleUrls: ["./retail-item-nomenclature-popup.component.scss"],
  providers: [SubscriptionService],
})
export class RetailItemNomenclaturePopupComponent implements OnInit {
  @Input() nomenclature: Nomenclature;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public popupTitle: string;
  public popupForm: UntypedFormGroup;

  public buttonLabel: string;

  public dateFormat: string;
  public locale: string;

  private shouldClose: boolean = false;
  private unsavedNomenclature: Nomenclature;
  private editedNomenclature: Nomenclature;

  constructor(
    private fb: UntypedFormBuilder,
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.assignLabels();
    this.prepareForm();
    this.editedNomenclature = new Nomenclature(this.nomenclature);

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.subscriptionService.subs.push(this.fetchConnectedUserDetails().subscribe());
    }
  }

  public closePopup(): void {
    this.editedNomenclature.name = this.popupForm.value.name;
    this.editedNomenclature.validityDate = this.popupForm.value.validityDate?.toDate();

    if (this.unsavedNomenclature && !this.unsavedNomenclature.equals(this.editedNomenclature)) {
      this.shouldClose = false;
    }

    if (!this.nomenclature.equals(this.editedNomenclature) && !this.shouldClose) {
      this.shouldClose = true;

      const message = this.translateService.instant("global.errors.unsaved-popin-content");
      const title = this.translateService.instant("global.errors.unsaved-title");
      this.messageService.info(message, { title });

      this.unsavedNomenclature = new Nomenclature(this.editedNomenclature);
    } else {
      this.close.emit();
      this.shouldClose = false;
    }
  }

  public validateNomenclature(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    this.nomenclature.name = this.popupForm.value.name;
    this.nomenclature.validityDate = this.popupForm.value.validityDate?.toDate();
    this.validate.emit();
  }

  private assignLabels(): void {
    const prefix: string = this.isNewNomenclature() ? "create" : "update";
    this.popupTitle = this.translateService.instant(`item.nomenclatures.nomenclature-popup.${prefix}-title`);
    this.buttonLabel = this.translateService.instant(`item.nomenclatures.nomenclature-popup.${prefix}-button-label`);
  }

  private isNewNomenclature(): boolean {
    return !this.nomenclature.name;
  }

  private prepareForm(): void {
    this.popupForm = this.fb.group({
      name: [this.nomenclature.name, [Validators.required]],
      validityDate: [DayjsUtil.dayjsOrNull(this.nomenclature.validityDate, false), []],
    });
  }

  private fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }
}
