import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DayjsUtil, Option, SubscriptionService } from "fugu-common";
import { TranslateService } from "@ngx-translate/core";
import { Observable, combineLatest } from "rxjs";
import { MessageService, Tag as TagComponent } from "fugu-components";
import { tap } from "rxjs/operators";
import {
  AbstractCustomer,
  Country,
  Currency,
  PricingGroup,
  CountryService,
  CurrencyService,
  PricingGroupService,
  DocumentService,
  TagService,
  ProfessionalCustomer,
  Address,
  Tag,
  ReceiptCommercialOfferType,
  Light,
  LightService,
  StoreService,
  Store,
  PersonTitleType,
  CaraUser,
  UserService,
} from "center-services";

@Component({
  selector: "app-customer-professional-general",
  templateUrl: "./customer-professional-general-datas.html",
  styleUrls: ["./customer-professional-general-datas.scss"],
  providers: [SubscriptionService],
})
export class CustomerProfessionalGeneralDatasComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() editedCustomer: AbstractCustomer;
  public pattern: any = {
    P: {
      pattern: new RegExp("[+]|[0-9]"),
      optional: true,
    },
    N: {
      pattern: new RegExp("\\d"),
    },
    Q: {
      pattern: new RegExp("[^~]+"),
    },
  };
  public affiliateOptions: Option[] = [];
  public countries: Country[];
  public countryOptions: Option[] = [];
  public currency: Currency;
  public pricingGroups: PricingGroup[];
  public pricingGroupOptions: Option[] = [];
  public receiptCommercialOfferOptions: Option[] = [];
  public storeList: Light[] = [];
  public typeOptions: Option[] = [];
  public allCustomerTags: Tag[];
  public availableTags: TagComponent[] = [];
  public customerForm: UntypedFormGroup;
  public selectedFile: any;
  public previousFile: any;
  public isInit: boolean = false;
  public mandatoryArray: Set<any> = new Set();
  public mainStore: Store;
  public locale: string;
  public dateFormat: string;
  public appendTo: any = document.body;
  private EXTERNAL_OPTION_ID: number = 0;
  private INTERNAL_OPTION_ID: number = 1;
  private initObservables: Observable<any>[];

  constructor(
    private translateService: TranslateService,
    private countryService: CountryService,
    private currencyService: CurrencyService,
    private pricingGroupService: PricingGroupService,
    private messageService: MessageService,
    private documentService: DocumentService,
    private tagService: TagService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private storeService: StoreService,
    private lightService: LightService,
    private userService: UserService<CaraUser>,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    this.buildAffiliateOptions();
    this.buildReceiptCommercialOfferOptions();
    this.buildTypeOptions();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildReceiptCommercialOfferOptions();
        this.buildTypeOptions();
      })
    );

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildAffiliateOptions();
      })
    );

    this.initObservables = [];
    this.initObservables.push(this.fetchAllCustomerTags());
    this.initObservables.push(this.fetchCountries());
    this.initObservables.push(this.fetchCurrency());
    this.initObservables.push(this.fetchPricingGroups());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchMainStore());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedCustomer) {
          this.loadEditedData();
        }
        if (this.editedCustomer.id) {
          this.isInit = true;
        }
      })
    );
  }

  ngOnChanges(): void {
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedCustomer) {
          this.loadEditedData();
          if (this.editedCustomer.id) {
            this.isInit = true;
          }
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  prepareForm(): void {
    this.customerForm = this.fb.group({
      affiliate: [0, [Validators.required]],
      name: [null, [Validators.required]],
      reference: [null, [Validators.required]],
      lines: [null, [Validators.required]],
      cityCode: [null, [Validators.required]],
      city: [null, [Validators.required]],
      country: [null, [Validators.required]],
      siren: [null, [Validators.required, Validators.pattern("[0-9]{9}")]],
      email: [null, [Validators.email]],
      phone: [null],
      mobilePhone: [null],
      website: [null],
      codeRCS: [null],
      pricingGroup: [null, [Validators.required]],
      accountingNumber: [null],
      accountingNumberBis: [null],
      codeVAT: [null],
      color: [null],
      logo: [null],
      logoFile: [[]],
      comment: [null],
      tags: [[]],
      customerType: [null],
      receiptCommercialOfferType: [null],
      personTitle: [null],
      firstName: [null],
      lastName: [null],
      birthDate: [null],
    });

    this.mandatoryArray.add("pricingGroup");
    this.mandatoryArray.add("lines");

    // listener on file change
    this.subscriptionService.subs.push(
      this.customerForm.controls.logoFile.valueChanges.subscribe(logoFile => {
        this.selectFile(logoFile);
      })
    );
    this.subscriptionService.subs.push(
      this.customerForm.controls.receiptCommercialOfferType.valueChanges.subscribe(value => {
        this.editedCustomer.receiptCommercialOffers = value.map(
          (index: number) => Object.values(ReceiptCommercialOfferType)[index]
        );
        this.manageCommercialOffersValidators();
      })
    );
  }

  buildAffiliateOptions(): void {
    this.affiliateOptions[this.EXTERNAL_OPTION_ID] = new Option(
      this.EXTERNAL_OPTION_ID,
      this.translateService.instant("customer.general-datas.customer-management.external.name"),
      false,
      this.translateService.instant("customer.general-datas.customer-management.external.info-label")
    );
    this.affiliateOptions[this.INTERNAL_OPTION_ID] = new Option(
      this.INTERNAL_OPTION_ID,
      this.translateService.instant("customer.general-datas.customer-management.internal.name"),
      false,
      this.translateService.instant("customer.general-datas.customer-management.internal.info-label")
    );
  }

  buildReceiptCommercialOfferOptions(): void {
    this.receiptCommercialOfferOptions = Object.keys(ReceiptCommercialOfferType).map(
      (key, index) => new Option(index, this.translateService.instant(`receipt-commercial-offer-options.${key}`))
    );
  }

  buildTypeOptions(): void {
    this.typeOptions = Object.keys(PersonTitleType).map(
      (key, index) => new Option(index, this.translateService.instant(`employees-popup.fields.type-options.${key}`))
    );
  }

  fetchAllCustomerTags(): Observable<Tag[]> {
    return this.tagService.getAllTagsOfCategoryByName("Customer").pipe(
      tap(
        (tags: Tag[]) => {
          this.allCustomerTags = tags;
          this.availableTags = tags
            .filter((obj: Tag) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Tag) => new TagComponent(obj.id, obj.name, obj.color));
        },
        () => {
          const content = this.translateService.instant("tags-list.errors.get-tags");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCountries(): Observable<Country[]> {
    return this.countryService.getAll().pipe(
      tap(
        (countries: Country[]) => {
          this.countries = countries;
          countries.forEach((country: Country) => {
            if (country.byDefault && (!this.editedCustomer || !this.editedCustomer.id)) {
              this.customerForm.controls.country.setValue(country.id);
            }
          });
          this.countryOptions = countries
            .filter((obj: Country) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Country) => new Option(obj.id, obj.name));
        },
        () => {
          const content = this.translateService.instant("countries-list.errors.get-countries");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCurrency(): Observable<Currency> {
    return this.currencyService.get(this.editedCustomer.currencyId).pipe(
      tap(
        (currency: Currency) => {
          this.currency = currency;
        },
        () => {
          const content = this.translateService.instant("currencies-list.errors.get-currencies");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchPricingGroups(): Observable<PricingGroup[]> {
    return this.pricingGroupService.getAll().pipe(
      tap(
        (pricingGroups: PricingGroup[]) => {
          this.pricingGroups = pricingGroups;
          this.pricingGroupOptions = pricingGroups
            .filter((obj: PricingGroup) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: PricingGroup) => new Option(obj.id, obj.name));
        },
        () => {
          const content = this.translateService.instant("store-brands-list.errors.get-pricing-groups");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-main-store", error.message);
        }
      )
    );
  }

  getLogo(): void {
    // display the current logo
    this.subscriptionService.subs.push(
      this.documentService.downloadFile(this.editedCustomer.logoId).subscribe(
        data => {
          if (data.byteLength === 0) {
            this.previousFile = null;
            this.selectedFile = null;
          } else {
            this.previousFile = data;
            this.selectedFile = data;
            this.customerForm.value.logoFile[0] = data;
          }
        },
        error => {
          console.error(error.message);
        }
      )
    );
  }

  selectFile(event: any): void {
    this.selectedFile = event[0];
  }

  hasFileChanged(): boolean {
    if (!this.selectedFile && !this.previousFile) {
      return false;
    }
    if ((!this.selectedFile && this.previousFile) || (!this.previousFile && this.selectedFile)) {
      return true;
    }
    return this.previousFile.name !== this.selectedFile.name;
  }

  getFile(): any {
    return this.selectedFile;
  }

  loadEditedData(): void {
    this.customerForm.patchValue({
      name: this.editedCustomer.name,
      reference: this.editedCustomer.reference,
      email: this.editedCustomer.email,
      phone: this.editedCustomer.phone,
      mobilePhone: this.editedCustomer.mobilePhone,
      website: this.editedCustomer.website,
      codeRCS: this.editedCustomer.codeRCS,
      accountingNumber: this.editedCustomer.accountingNumber,
      accountingNumberBis: this.editedCustomer.accountingNumberBis,
      codeVAT: this.editedCustomer.codeVAT,
      color: this.editedCustomer.color,
      comment: this.editedCustomer.comment,
      logo: this.editedCustomer.logoId,
      affiliate: (this.editedCustomer as ProfessionalCustomer).affiliate ? 1 : 0,
      siren: (this.editedCustomer as ProfessionalCustomer).siren,
      tags: this.editedCustomer.tagIds,
      customerType: this.editedCustomer.customerType,
      firstName: this.editedCustomer.firstName,
      lastName: this.editedCustomer.lastName,
      birthDate: DayjsUtil.dayjsOrNull(this.editedCustomer.birthDate, true),
    });

    // handle file
    if (this.editedCustomer.id && this.editedCustomer.logoId) {
      this.getLogo();
    } else {
      this.selectedFile = null;
      this.previousFile = null;
    }

    if (this.editedCustomer.address) {
      this.customerForm.patchValue({
        lines: this.editedCustomer.address.lines,
        cityCode: this.editedCustomer.address.cityCode,
        city: this.editedCustomer.address.city,
      });
    }

    if (this.editedCustomer.address?.countryId) {
      this.customerForm.patchValue({ country: this.editedCustomer.address.countryId });
    }

    const selectedPricingGroup = this.pricingGroups.find(
      pricingGroup => pricingGroup.id === (this.editedCustomer as ProfessionalCustomer).pricingGroupId
    );
    if (selectedPricingGroup && !selectedPricingGroup.archived) {
      this.customerForm.controls.pricingGroup.setValue(selectedPricingGroup.id);
    }

    if (this.editedCustomer.receiptCommercialOffers.length > 0) {
      const indexArray = this.editedCustomer.receiptCommercialOffers.map(receipt =>
        Object.keys(ReceiptCommercialOfferType).indexOf(receipt)
      );
      this.customerForm.controls.receiptCommercialOfferType.setValue(indexArray);
    }

    if (
      this.editedCustomer.customerType === "PROFESSIONAL" &&
      this.editedCustomer.createdByStoreId !== this.mainStore.id
    ) {
      this.clearAndUpdateValidator("pricingGroup");
      this.mandatoryArray.delete("pricingGroup");
    }

    // handle type
    if (this.editedCustomer.personTitle) {
      const index = Object.keys(PersonTitleType).indexOf(this.editedCustomer.personTitle);
      this.customerForm.controls.personTitle.setValue(index);
    }
  }

  applyModifications(): void {
    if (this.customerForm.pristine) {
      return;
    }

    const cloneAddress = new Address(this.editedCustomer.address);
    cloneAddress.lines = this.customerForm.controls.lines.value;
    cloneAddress.city = this.customerForm.controls.city.value;
    cloneAddress.cityCode = this.customerForm.controls.cityCode.value;
    cloneAddress.countryId = this.customerForm.controls.country.value;
    cloneAddress.byDefault = true;

    this.editedCustomer.name = this.customerForm.controls.name.value;
    this.editedCustomer.reference = this.customerForm.controls.reference.value;
    this.editedCustomer.address = cloneAddress;
    this.editedCustomer.email = this.customerForm.controls.email.value;
    this.editedCustomer.phone = this.customerForm.controls.phone.value;
    this.editedCustomer.mobilePhone = this.customerForm.controls.mobilePhone.value;
    this.editedCustomer.website = this.customerForm.controls.website.value;
    this.editedCustomer.codeRCS = this.customerForm.controls.codeRCS.value;
    this.editedCustomer.accountingNumber = this.customerForm.controls.accountingNumber.value;
    this.editedCustomer.accountingNumberBis = this.customerForm.controls.accountingNumberBis.value;
    this.editedCustomer.codeVAT = this.customerForm.controls.codeVAT.value;
    this.editedCustomer.color = this.customerForm.controls.color.value;
    this.editedCustomer.comment = this.customerForm.controls.comment.value;
    this.editedCustomer.logoId = this.customerForm.controls.logo.value;
    this.editedCustomer.tagIds = this.customerForm.controls.tags.value;
    this.editedCustomer.personTitle = Object.values(PersonTitleType)[this.customerForm.value.personTitle];
    this.editedCustomer.firstName = this.customerForm.controls.firstName.value;
    this.editedCustomer.lastName = this.customerForm.controls.lastName.value;
    this.editedCustomer.birthDate = this.customerForm.value.birthDate
      ? this.customerForm.value.birthDate.toDate()
      : null;

    (this.editedCustomer as ProfessionalCustomer).affiliate =
      this.customerForm.controls.affiliate.value === 0 ? false : true;
    (this.editedCustomer as ProfessionalCustomer).siren = this.customerForm.controls.siren.value;
    (this.editedCustomer as ProfessionalCustomer).pricingGroupId = this.customerForm.controls.pricingGroup.value;
  }

  updateCustomer(): string {
    // classic fields
    if (this.customerForm.invalid) {
      this.customerForm.markAllAsTouched();
      if (this.findOffersRequiredField()) {
        return "commercialOffersErrors";
      }
      return "defaultErrors";
    }

    this.applyModifications();
    return "valid";
  }

  getSelectedAffiliate(): Option {
    return this.affiliateOptions.find(
      option => option.id === ((this.editedCustomer as ProfessionalCustomer).affiliate ? 1 : 0)
    );
  }

  getCustomerTypeLabel(): string {
    return `customer.general-datas.customer-management.${this.editedCustomer.customerType}`;
  }

  getStoreNameLabel(): string {
    let storeNameLabel =
      this.storeList && this.storeList.length > 0
        ? this.storeList.find(store => store.id === this.editedCustomer.createdByStoreId)?.name
        : "";
    if (storeNameLabel === undefined) {
      storeNameLabel = this.mainStore?.name;
    }
    return storeNameLabel;
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  setAndUpdateValidator(controlName: string, validators: any): void {
    this.customerForm.controls[controlName].setValidators(validators);
    this.customerForm.controls[controlName].updateValueAndValidity();
  }

  clearAndUpdateValidator(controlName: string): void {
    this.customerForm.controls[controlName].clearValidators();
    this.customerForm.controls[controlName].updateValueAndValidity();
  }

  findOffersRequiredField(): boolean {
    let hasValidator = false;
    this.mandatoryArray.forEach((controlName: string) => {
      if (controlName === "pricingGroup") {
        return;
      }
      if (controlName === "lines" && this.editedCustomer.customerType === "PROFESSIONAL") {
        return;
      }
      if (this.hasValidatorRequired(controlName)) {
        hasValidator = true;
      }
    });
    return hasValidator;
  }

  hasValidatorRequired(controlName: string): boolean {
    return this.customerForm.controls[controlName]?.errors?.required ? true : false;
  }

  // Add or remove controlName from 'mandatoryArray' depending of selected commercial offers
  manageCommercialOffersValidators(): void {
    this.manageAddressValidator();

    if (this.editedCustomer.receiptCommercialOffers.includes(ReceiptCommercialOfferType.BY_EMAIL)) {
      this.setAndUpdateValidator("email", [Validators.email, Validators.required]);
      this.mandatoryArray.add("email");
    } else {
      this.setAndUpdateValidator("email", Validators.email);
      if (this.mandatoryArray.has("email")) {
        this.mandatoryArray.delete("email");
      }
    }

    if (this.editedCustomer.receiptCommercialOffers.includes(ReceiptCommercialOfferType.BY_SMS)) {
      this.setAndUpdateValidator("mobilePhone", Validators.required);
      this.mandatoryArray.add("mobilePhone");
    } else {
      this.clearAndUpdateValidator("mobilePhone");
      if (this.mandatoryArray.has("mobilePhone")) {
        this.mandatoryArray.delete("mobilePhone");
      }
    }

    if (this.editedCustomer.receiptCommercialOffers.includes(ReceiptCommercialOfferType.BY_PHONE)) {
      this.setAndUpdateValidator("phone", Validators.required);
      this.mandatoryArray.add("phone");
    } else {
      this.clearAndUpdateValidator("phone");
      if (this.mandatoryArray.has("phone")) {
        this.mandatoryArray.delete("phone");
      }
    }
  }

  manageAddressValidator(): void {
    if (this.editedCustomer.customerType === "INDIVIDUAL") {
      if (this.editedCustomer.receiptCommercialOffers.includes(ReceiptCommercialOfferType.BY_MAIL)) {
        this.setAndUpdateValidator("lines", Validators.required);
        this.setAndUpdateValidator("cityCode", Validators.required);
        this.setAndUpdateValidator("city", Validators.required);
        this.setAndUpdateValidator("country", Validators.required);
        this.mandatoryArray.add("lines");
      } else {
        this.clearAndUpdateValidator("lines");
        this.clearAndUpdateValidator("cityCode");
        this.clearAndUpdateValidator("city");
        this.clearAndUpdateValidator("country");
        if (this.mandatoryArray.has("lines")) {
          this.mandatoryArray.delete("lines");
        }
      }
    }
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeList = lightStores;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("delivery-initiator-popup.errors.get-stores");
          this.messageService.warn(content, { title });
        }
      )
    );
  }
}
