<ng-container>
  <div class="status-popup-container" [formGroup]="popupForm" novalidate>
    <ui-popup title="{{ popupTitle | translate }}" (close)="closeStatusPopup()">
      <div content>
        <div class="status-question">{{ statusQuestion }}</div>

        <div class="status-choice">
          <ui-radio-button
            label="{{ 'purchase-order.status-popup.options.label' | translate }}"
            [options]="this.statusOptions"
            name="status"
            formControlName="status"
            mandatory="true"
            [status]="popupForm.get('status') | formStatus"
            [errorMessage]="popupForm.get('status') | formErrorMessage | translate"
            mandatory="true"
          ></ui-radio-button>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'purchase-order.status-popup.validate' | translate }}"
                [primary]="true"
                type="validate"
                (click)="submitStatusPopup()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
