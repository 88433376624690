import { Comparator } from "fugu-common";

export class StockEntryLocationSelectionOutput extends Comparator {
  quantity: number;
  stockLocationId: number;

  constructor(values: any = {}) {
    super();
    this.quantity = values.quantity;
    this.stockLocationId = values.stockLocationId;
  }
}
