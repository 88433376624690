<ng-container>
  <div
    class="delivery-initiator-popup-container frozen-left frozen-right"
    [formGroup]="popupForm"
    [ngClass]="
      (this.popupForm.controls.orderLinked.value === 0 && this.popupForm.controls.orderKnown.value === 1) ||
      this.popupForm.controls.orderLinked.value === 1
        ? 'with-table'
        : 'without-table'
    "
    novalidate
  >
    <ui-popup title="{{ popupTitle | translate }}" (close)="closePopup()">
      <div content>
        <div class="delivery-type-label">{{ deliveryTypeLabel | translate }}<span> *</span></div>
        <div class="{{ this.popupForm.controls.orderLinked.value === 0 ? 'delivery-type' : '' }}">
          <ui-radio-button
            [options]="this.orderLinkedOptions"
            name="orderLinked"
            formControlName="orderLinked"
            mandatory="true"
            customClass="{{ this.popupForm.controls.orderLinked.value === 0 ? 'order-linked' : '' }}"
            [status]="popupForm.get('orderLinked') | formStatus"
            [errorMessage]="popupForm.get('orderLinked') | formErrorMessage | translate"
            mandatory="true"
          ></ui-radio-button>

          <ui-radio-button
            *ngIf="this.popupForm.controls.orderLinked.value === 0"
            [options]="this.orderKnownOptions"
            name="orderKnown"
            formControlName="orderKnown"
            mandatory="true"
            customClass="order-known"
            [status]="popupForm.get('orderKnown') | formStatus"
            [errorMessage]="popupForm.get('orderKnown') | formErrorMessage | translate"
            mandatory="true"
          ></ui-radio-button>
        </div>

        <div
          class="search-purchase-order-label"
          *ngIf="this.popupForm.controls.orderLinked.value === 0 && this.popupForm.controls.orderKnown.value === 1"
        >
          {{ "delivery-initiator-popup.labels.search-purchase-order" | translate | uppercase }}
        </div>

        <div class="sender">
          <ui-list
            [status]="popupForm.get('sender') | formStatus"
            [errorMessage]="popupForm.get('sender') | formErrorMessage | translate"
            label="{{ 'delivery-initiator-popup.labels.sender' | translate }}"
            [options]="senderOptions"
            formControlName="sender"
            [mandatory]="true"
            [search]="true"
          ></ui-list>
        </div>

        <div class="receiver">
          <ui-list
            [status]="popupForm.get('receiver') | formStatus"
            [errorMessage]="popupForm.get('receiver') | formErrorMessage | translate"
            label="{{ 'delivery-initiator-popup.labels.receiver' | translate }}"
            [options]="receiverOptions"
            formControlName="receiver"
            [mandatory]="true"
            [search]="true"
          ></ui-list>
        </div>

        <div class="currency">
          <ui-list
            label="{{ 'delivery-initiator-popup.labels.currency' | translate }}"
            [status]="popupForm.get('currency') | formStatus"
            [errorMessage]="popupForm.get('currency') | formErrorMessage | translate"
            [options]="currencyOptions"
            formControlName="currency"
            [mandatory]="true"
          >
          </ui-list>
        </div>

        <div
          class="order-ref"
          *ngIf="this.popupForm.controls.orderLinked.value === 0 && this.popupForm.controls.orderKnown.value === 0"
        >
          <div class="order-ref-label">
            {{ "delivery-initiator-popup.labels.order-ref" | translate }}<span> *</span>
          </div>

          <div class="order-ref-line" *ngFor="let element of this.orderRefForm.controls; index as i">
            <div class="order-ref-input">
              <ui-input-text
                type="text"
                placeholder="{{ (i === 0 ? 'delivery-initiator-popup.labels.order-ref-placeholder' : '') | translate }}"
                [formControl]="orderRefForm.controls[i]"
                [status]="popupForm.get('refs.' + i) | formStatus"
                [errorMessage]="
                  popupForm.get('refs.' + i)
                    | formErrorMessage
                      : {
                          customPrefix: 'delivery-initiator-popup.errors.',
                          useCustomPrefixFor: [
                            'notFound',
                            'wrongStatus',
                            'wrongSender',
                            'wrongReceiver',
                            'wrongCurrency'
                          ]
                        }
                    | translate
                "
              >
              </ui-input-text>
            </div>

            <div *ngIf="i > 0" class="dump-icon-div" (click)="removeOrderRef(i)">
              <fa-icon class="dump-icon" [icon]="faTrashAlt"></fa-icon>
            </div>
          </div>

          <div class="button-popup-add-order-ref">
            <ui-button
              text="{{ 'delivery-initiator-popup.buttons.add-order-ref' | translate }}"
              [primary]="false"
              type="add"
              (click)="addOrderRef()"
            ></ui-button>
          </div>
        </div>

        <div
          class="delivery-initiator-popup-table"
          *ngIf="this.popupForm.controls.orderLinked.value === 0 && this.popupForm.controls.orderKnown.value === 1"
        >
          <ui-container>
            <div class="filters" *ngIf="filterer">
              <ui-dynamic-filters
                label="{{ 'delivery-initiator-popup.datatable.filter-title' | translate }}"
                [filters]="filterer.getFilters()"
                [(filterValues)]="filterer.filterValues"
                (apply)="applyFilters()"
                [locale]="locale"
                dateFormat="{{ dateFormat }}"
                applyLabel="{{ 'delivery-initiator-popup.datatable.filter-label' | translate }}"
                minLabel="{{ 'filters.min' | translate }}"
                maxLabel="{{ 'filters.max' | translate }}"
                deleteLabel="{{ 'filters.delete' | translate }}"
                placeholderPrefix="{{ 'filters.prefix' | translate }} "
                [btnIcon]="faSearch"
                btnType=""
              >
              </ui-dynamic-filters>
            </div>

            <div [formGroup]="form" class="purchase-order-table">
              <div>
                <ngx-datatable
                  #table
                  [rows]="rows"
                  [trackByProp]="'id'"
                  class="material"
                  columnMode="force"
                  headerHeight="auto"
                  footerHeight="50"
                  rowHeight="40"
                  [cssClasses]="{
                    sortAscending: 'fas fa-chevron-up',
                    sortDescending: 'fas fa-chevron-down'
                  }"
                  [messages]="{ emptyMessage: 'global.no-data' | translate }"
                  [externalSorting]="true"
                  [externalPaging]="true"
                  [count]="pager.totalElements"
                  [offset]="pager.number"
                  [limit]="pager.size"
                  scrollbarH="true"
                  [sorts]="sorts"
                  (sort)="changeSortSettings($event.column.prop, $event.newValue)"
                  [rowClass]="getRowClass"
                >
                  <ngx-datatable-column
                    width="50"
                    [sortable]="false"
                    [frozenLeft]="true"
                    [draggable]="false"
                    [resizeable]="false"
                    [canAutoResize]="false"
                    cellClass="center-align frozen-style"
                  >
                    <ng-template ngx-datatable-header-template>
                      <div class="green-zone" (click)="$event.stopPropagation()">
                        <ui-checkbox
                          customClass="no-margin"
                          (change)="onHeaderCheckboxChange()"
                          [formControlName]="'headerCheckbox'"
                        >
                        </ui-checkbox>
                      </div>
                    </ng-template>

                    <ng-template ngx-datatable-cell-template let-id="row.id">
                      <div class="adjust green-zone" (click)="$event.stopPropagation()">
                        <ui-checkbox
                          customClass="no-margin"
                          (change)="onRowCheckboxChange()"
                          [formControlName]="getRowControlName(id)"
                        >
                        </ui-checkbox>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.order-ref' | translate }}"
                    prop="orderRef"
                    cellClass="order-ref"
                    [resizeable]="false"
                  >
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.supplier-ref' | translate }}"
                    prop="supplierRef"
                    cellClass="supplier-ref"
                    [resizeable]="false"
                  >
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.supplier-name' | translate }}"
                    prop="supplierName"
                    cellClass="supplier-name"
                    [resizeable]="false"
                  >
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.store-ref' | translate }}"
                    prop="storeRefs"
                    cellClass="store-ref ellipsis-counter"
                    [resizeable]="false"
                    [sortable]="false"
                  >
                    <ng-template
                      ngx-datatable-cell-template
                      let-row="row"
                      let-id="row.id"
                      let-storeRefs="row.storeRefs"
                    >
                      <p class="ellipsis">{{ storeRefs.join(", ") }}</p>
                      <div *ngIf="storeRefs.length >= 2">
                        <div #storeRefsCounter>
                          <ui-counter [number]="storeRefs.length"></ui-counter>
                        </div>
                        <ui-tooltip maxWidth="300px" [element]="storeRefsCounter">
                          <span style="font-weight: normal !important">{{ storeRefs.join(", ") }}</span>
                        </ui-tooltip>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.brand-ref' | translate }}"
                    prop="brandRefs"
                    cellClass="brand-ref ellipsis-counter"
                    [resizeable]="false"
                    [sortable]="false"
                  >
                    <ng-template
                      ngx-datatable-cell-template
                      let-row="row"
                      let-id="row.id"
                      let-brandRefs="row.brandRefs"
                    >
                      <p class="ellipsis">{{ brandRefs.join(", ") }}</p>
                      <div *ngIf="brandRefs.length >= 2">
                        <div #brandRefsCounter>
                          <ui-counter [number]="brandRefs.length"></ui-counter>
                        </div>
                        <ui-tooltip maxWidth="300px" [element]="brandRefsCounter">
                          <span style="font-weight: normal !important">{{ brandRefs.join(", ") }}</span>
                        </ui-tooltip>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.order-type' | translate }}"
                    prop="orderType"
                    cellClass="order-type"
                    [resizeable]="false"
                    [width]="200"
                  >
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.created-at' | translate }}"
                    prop="createdAt"
                    cellClass="created-at "
                    [resizeable]="false"
                  >
                    <ng-template ngx-datatable-cell-template let-date="row.createdAt">
                      <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.quantity' | translate }}"
                    cellClass="quantity "
                    [resizeable]="false"
                    [sortable]="false"
                    [width]="200"
                  >
                    <ng-template
                      ngx-datatable-cell-template
                      let-row="row"
                      let-receivedQuantity="row.receivedQuantity"
                      let-quantity="row.quantity"
                    >
                      <div class="quantity-wrapper" *ngIf="quantity > 0">
                        <div class="{{ row.quantityClass }}">{{ receivedQuantity }}/{{ quantity }}</div>
                        <ui-progress-bar value="{{ (receivedQuantity / quantity) * 100 }}" max="100"></ui-progress-bar>
                        <div *ngIf="receivedQuantity > quantity" class="warning">
                          <fa-icon [icon]="faExclamationCircle"></fa-icon>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.delivery-date' | translate }}"
                    prop="deliveryDate"
                    cellClass="delivery-date "
                    [resizeable]="false"
                  >
                    <ng-template ngx-datatable-cell-template let-date="row.deliveryDate">
                      <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'delivery-initiator-popup.datatable.columns.status' | translate }}"
                    prop="statusLabel"
                    cellClass="status frozen-style"
                    headerClass="left-align"
                    [resizeable]="false"
                    [frozenRight]="true"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"></ui-status-label>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-footer>
                    <ng-template
                      ngx-datatable-footer-template
                      let-rowCount="rowCount"
                      let-pageSize="pageSize"
                      let-selectedCount="selectedCount"
                      let-curPage="curPage"
                      let-offset="offset"
                      let-isVisible="isVisible"
                    >
                      <div class="page-count">
                        <span
                          [innerHTML]="
                            selectedPurchaseOrderList.length === 0
                              ? ('global.datatable.no-items' | translate)
                              : (selectedPurchaseOrderList.length
                                | i18nPlural
                                  : {
                                      '=1': 'global.datatable.n-selected-items',
                                      other: 'global.datatable.n-selected-items_plural'
                                    }
                                | translate: { selectedElements: selectedPurchaseOrderList.length })
                          "
                        >
                        </span>
                      </div>
                      <datatable-pager
                        [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                        [pagerRightArrowIcon]="'fas fa-chevron-right'"
                        [pagerNextIcon]="'fas fa-chevron-double-right'"
                        [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                        [page]="curPage"
                        [size]="pageSize"
                        [count]="rowCount"
                        [hidden]="!(rowCount / pageSize > 1)"
                        (change)="changePage($event)"
                      >
                      </datatable-pager>
                    </ng-template>
                  </ngx-datatable-footer>
                </ngx-datatable>
              </div>
            </div>
          </ui-container>
        </div>

        <div class="delivery-initiator-popup-table" *ngIf="this.popupForm.controls.orderLinked.value === 1">
          <ui-container>
            <div class="filters" *ngIf="freeFilterer">
              <label class="filter-label">
                <b>{{ "delivery-initiator-popup.labels.article-search" | translate }} </b><span>*</span>
              </label>

              <ui-dynamic-filters
                [filters]="freeFilterer.getFilters()"
                [(filterValues)]="freeFilterer.filterValues"
                (apply)="applyFreeFilters()"
                label="{{ 'filters.label-secondary' | translate }}"
                minLabel="{{ 'filters.min' | translate }}"
                maxLabel="{{ 'filters.max' | translate }}"
                applyLabel="{{ 'filters.apply' | translate }}"
                deleteLabel="{{ 'filters.delete' | translate }}"
                placeholderPrefix="{{ 'filters.prefix' | translate }} "
                [btnIcon]="faSearch"
                btnType=""
                customClass="margin-top"
              >
              </ui-dynamic-filters>
            </div>

            <div class="purchase-modality-table">
              <div [formGroup]="form">
                <ngx-datatable
                  #freeTable
                  [rows]="freeRows"
                  rowHeight="40"
                  class="material"
                  footerHeight="50"
                  scrollbarH="true"
                  columnMode="force"
                  trackByProp="pmId"
                  headerHeight="auto"
                  [limit]="freePager.size"
                  [offset]="freePager.number"
                  [externalPaging]="true"
                  [externalSorting]="true"
                  [count]="freePager.totalElements"
                  [cssClasses]="{
                    sortAscending: 'fas fa-chevron-up',
                    sortDescending: 'fas fa-chevron-down'
                  }"
                  [sorts]="freeSorts"
                  (sort)="changeFreeSortSettings($event.column.prop, $event.newValue)"
                  [messages]="{ emptyMessage: 'purchase-modality-selection.datatable.no-data' | translate }"
                  [rowClass]="getRowClass"
                >
                  <ngx-datatable-column
                    width="50"
                    [sortable]="false"
                    [draggable]="false"
                    [resizeable]="false"
                    [frozenLeft]="true"
                    [canAutoResize]="false"
                    cellClass="center-align frozen-style"
                  >
                    <ng-template ngx-datatable-header-template>
                      <ui-checkbox
                        customClass="no-margin"
                        (change)="onFreeHeaderCheckboxChange()"
                        [formControlName]="'headerCheckbox'"
                      >
                      </ui-checkbox>
                    </ng-template>

                    <ng-template ngx-datatable-cell-template let-id="row.pmId">
                      <ui-checkbox
                        customClass="no-margin"
                        (change)="onFreeRowCheckboxChange()"
                        [formControlName]="getFreeRowControlName(id)"
                      >
                      </ui-checkbox>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="itemCategory"
                    [draggable]="false"
                    [resizeable]="false"
                    cellClass="item-category"
                    name="{{ 'purchase-modality-selection.datatable.columns.item-category' | translate }}"
                  >
                    <ng-template
                      let-supplier="row.supplier"
                      ngx-datatable-cell-template
                      let-itemCategory="row.itemCategory"
                    >
                      <span>{{ itemCategory }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="itemReference"
                    [resizeable]="false"
                    cellClass="item-reference"
                    name="{{ 'purchase-modality-selection.datatable.columns.item-reference' | translate }}"
                  >
                    <ng-template
                      let-supplier="row.supplier"
                      ngx-datatable-cell-template
                      let-itemReference="row.itemReference"
                    >
                      <span>{{ itemReference }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="supplier"
                    [resizeable]="false"
                    cellClass="supplier"
                    name="{{ 'purchase-modality-selection.datatable.columns.supplier' | translate }}"
                  >
                    <ng-template let-supplier="row.supplier" ngx-datatable-cell-template>
                      <span>{{ supplier }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="supplierRef"
                    [resizeable]="false"
                    cellClass="supplier-ref"
                    name="{{ 'purchase-modality-selection.datatable.columns.supplier-ref' | translate }}"
                  >
                    <ng-template
                      let-supplier="row.supplier"
                      ngx-datatable-cell-template
                      let-supplierRef="row.supplierRef"
                    >
                      <span>{{ supplierRef }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="itemName"
                    [resizeable]="false"
                    cellClass="item-name"
                    name="{{ 'purchase-modality-selection.datatable.columns.item-name' | translate }}"
                  >
                    <ng-template let-itemName="row.itemName" let-supplier="row.supplier" ngx-datatable-cell-template>
                      <span>{{ itemName }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="purchaseType"
                    [resizeable]="false"
                    cellClass="purchase-type"
                    name="{{ 'purchase-modality-selection.datatable.columns.purchase-type' | translate }}"
                  >
                    <ng-template let-purchaseType="row.purchaseType" ngx-datatable-cell-template>
                      <span>{{
                        "purchase-modality-selection.datatable.purchase-type." + purchaseType | translate
                      }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="brand"
                    [resizeable]="false"
                    cellClass="brand"
                    name="{{ 'purchase-modality-selection.datatable.columns.brand' | translate }}"
                  >
                    <ng-template let-brand="row.brand" let-supplier="row.supplier" ngx-datatable-cell-template>
                      <span>{{ brand }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="minQuantity"
                    [resizeable]="false"
                    cellClass="min-quantity"
                    name="{{ 'purchase-modality-selection.datatable.columns.min-quantity' | translate }}"
                  >
                    <ng-template
                      let-supplier="row.supplier"
                      ngx-datatable-cell-template
                      let-minQuantity="row.minQuantity"
                    >
                      <span>{{ minQuantity }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="maxQuantity"
                    [resizeable]="false"
                    cellClass="max-quantity"
                    name="{{ 'purchase-modality-selection.datatable.columns.max-quantity' | translate }}"
                  >
                    <ng-template
                      let-supplier="row.supplier"
                      ngx-datatable-cell-template
                      let-maxQuantity="row.maxQuantity"
                    >
                      <span>{{ maxQuantity }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    [sortable]="false"
                    [resizeable]="false"
                    prop="computedPrice"
                    cellClass="computed-price"
                    name="{{ 'purchase-modality-selection.datatable.columns.price' | translate }}"
                  >
                    <ng-template
                      let-currencyIsoCode="row.currencyIsoCode"
                      let-supplier="row.supplier"
                      ngx-datatable-cell-template
                      let-computedPrice="row.computedPrice"
                    >
                      <span *ngIf="computedPrice !== null">
                        {{ computedPrice | formatCurrency: currencyIsoCode:"symbol":"0.2-2":codeLanguage }}
                      </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="statusLabel"
                    [draggable]="false"
                    [resizeable]="false"
                    [frozenRight]="true"
                    [canAutoResize]="false"
                    headerClass="center-align"
                    cellClass="status center-align frozen-style"
                    name="{{ 'purchase-modality-selection.datatable.columns.status' | translate }}"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"> </ui-status-label>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-footer>
                    <ng-template
                      ngx-datatable-footer-template
                      let-offset="offset"
                      let-curPage="curPage"
                      let-rowCount="rowCount"
                      let-pageSize="pageSize"
                      let-isVisible="isVisible"
                      let-selectedCount="selectedCount"
                    >
                      <div class="page-count">
                        <span
                          [innerHTML]="
                            selectedElementsNumber === 0
                              ? ('global.datatable.no-items' | translate)
                              : (selectedElementsNumber
                                | i18nPlural
                                  : {
                                      '=1': 'global.datatable.n-selected-items',
                                      other: 'global.datatable.n-selected-items_plural'
                                    }
                                | translate: { selectedElements: selectedElementsNumber })
                          "
                        >
                        </span>
                      </div>
                      <datatable-pager
                        [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                        [pagerNextIcon]="'fas fa-chevron-double-right'"
                        [pagerRightArrowIcon]="'fas fa-chevron-right'"
                        [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                        [hidden]="!(rowCount / pageSize > 1)"
                        (change)="changeFreePage($event)"
                        [count]="rowCount"
                        [size]="pageSize"
                        [page]="curPage"
                      >
                      </datatable-pager>
                    </ng-template>
                  </ngx-datatable-footer>
                </ngx-datatable>
              </div>
            </div>
          </ui-container>
        </div>
      </div>
      <div footer>
        <div class="button-popup-to-delivery">
          <ui-button
            text="{{ buttonLabel | translate }}"
            [primary]="true"
            (click)="submit()"
            [btnIcon]="faArrowCircleRight"
          ></ui-button>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
