import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { IconDefinition, faWarning } from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: "app-disable-store-warning-popup",
  templateUrl: "./disable-store-warning-popup.component.html",
  styleUrls: ["./disable-store-warning-popup.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DisableStoreWarningPopupComponent {
  @Input() storeName: string = null;
  @Output() validate: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  public faWarn: IconDefinition = faWarning;

  submitPopup(): void {
    this.validate.emit();
  }

  closePopup(): void {
    this.close.emit();
  }
}
