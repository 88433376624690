import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  AppConfig,
  AppConfigService,
  CaraUser,
  CaraUserService,
  Currency,
  CurrencyService,
  Light,
  LightCustomer,
  LightService,
  Store,
  StoreService,
} from "center-services";
import { Option } from "fugu-common";
import { MessageService } from "fugu-components";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class InvoiceFormFetcher {
  public locale: string;
  public dateFormat: string;
  public currencyList: Currency[] = [];
  public supplierLightList: Light[] = [];
  public storeList: Light[] = [];
  public customerList: LightCustomer[] = [];
  public customerOptions: Option[] = [];
  public mainStore: Store;
  public isConform: boolean;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: CaraUserService,
    private currencyService: CurrencyService,
    private storeService: StoreService,
    private lightService: LightService,
    private appConfigService: AppConfigService
  ) {}

  public fetchInitialData(): Observable<any> {
    const observables = [];
    observables.push(this.fetchConnectedUserDetails());
    observables.push(this.fetchCurrencies());
    observables.push(this.fetchSuppliers());
    observables.push(this.fetchStores());
    observables.push(this.fetchCustomers());
    observables.push(this.fetchMainStore());
    observables.push(this.fetchAppConfig());
    return combineLatest(observables);
  }

  public sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }

  // FETCHES

  public fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (suppliers: Light[]) => {
          this.supplierLightList = suppliers;
        },
        error => {
          this.sendErrorAlert("invoice-customer-form.errors.get-suppliers", error.message);
        }
      )
    );
  }

  private fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser?.codeLanguage;
        this.dateFormat = connectedUser?.dateFormat;
      })
    );
  }

  private fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencyList = currencies;
        },
        error => {
          this.sendErrorAlert("invoice-customer-form.errors.get-currencies", error.message);
        }
      )
    );
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeList = lightStores;
        },
        error => {
          this.sendErrorAlert("invoice-customer-form.errors.get-customers", error.message);
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-customer-initiator-popup.errors.get-stores");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchCustomers(): Observable<Light[]> {
    return this.lightService.getCustomers().pipe(
      tap(
        (lightCustomers: LightCustomer[]) => {
          this.customerList = lightCustomers;

          this.customerOptions = lightCustomers
            .filter(obj => !obj.archived)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id.toString(), `${obj.reference} - ${obj.name}`));
        },
        error => {
          this.sendErrorAlert("invoice-customer-form.errors.get-customers", error.message);
        }
      )
    );
  }

  private fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("invoice-customer-form.errors.get-main-store", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchAppConfig(): Observable<AppConfig> {
    return this.appConfigService.appConfig.pipe(
      tap(appConfig => {
        this.isConform =
          appConfig?.conformityActivationDate !== null && appConfig?.conformityActivationDate !== undefined;
      })
    );
  }
}
