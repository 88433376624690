<ng-container>
  <div class="stock-entry-label-delete-popup-container">
    <ui-popup title="{{ popupTitle | translate }}" (close)="closePopup()">
      <div content>
        <div class="body-message">{{ bodyMessage }}</div>
        <div class="body-info" *ngIf="infoMessage">
          <fa-icon [icon]="faInfoCircle" class="info-icon"></fa-icon>
          <div class="info-message">{{ infoMessage }}</div>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ validateButton }}"
                [primary]="true"
                customClass="fugu-button-save"
                (click)="submitDeletePopup(true)"
                [btnIcon]="faTrash"
              ></ui-button>
            </div>
          </div>
          <div class="button-popup-refuse">
            <div class="button">
              <ui-button text="{{ refuseButton }}" type="close" (click)="submitDeletePopup(false)"></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
