<ui-popup title="{{ 'invoice-customer-list-validation-popup.title' | translate }}" (close)="closePopup()">
  <div content>
    <div class="warning-content">
      <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
      <div>
        <strong>{{ "disable-store-warning-popup.warning-label" | translate }}</strong>
        <div *ngIf="warningMessage && !subWarningMessage">{{ warningMessage }}</div>
        <div class="doubleWarningDiv">
          <ul *ngIf="subWarningMessage">
            <li *ngIf="warningMessage">{{ warningMessage }}</li>
            <li *ngIf="subWarningMessage">{{ subWarningMessage }}</li>
          </ul>
        </div>
      </div>
    </div>
    <form class="brand-collection-form" [formGroup]="popupForm" novalidate>
      <ui-checkbox
        [labelPosition]="'right'"
        [label]="'invoice-customer-list-validation-popup.label' | translate"
        [formControlName]="'print'"
      >
      </ui-checkbox>
    </form>
    <div class="choice-label">{{ "invoice-customer-list-validation-popup.choice" | translate }}</div>
  </div>
  <div footer>
    <div class="align-buttons">
      <div class="button-popup-validate">
        <div class="button">
          <ui-button
            text="{{ 'invoice-customer-list-validation-popup.yes' | translate }}"
            [primary]="true"
            type="validate"
            (click)="validateInvoice()"
          ></ui-button>
        </div>
      </div>
      <div class="button-popup-refuse">
        <div class="button">
          <ui-button
            text="{{ 'invoice-customer-list-validation-popup.no' | translate }}"
            type="cancel"
            (click)="closePopup()"
          ></ui-button>
        </div>
      </div>
    </div>
  </div>
</ui-popup>
