<ng-container>
  <form class="employees-form" [formGroup]="popupForm" novalidate>
    <div class="popup-container">
      <ui-popup title="{{ 'employees-popup.title.' + popupTitle | translate }}" (close)="closePopup()">
        <div content>
          <div class="title-type">
            <ui-radio-button
              [options]="this.typeOptions"
              name="titleType"
              direction="horizontal"
              formControlName="titleType"
              customClass="no-margin"
              mandatory="true"
            ></ui-radio-button>
          </div>

          <div class="grid-container">
            <div class="grid-item profiles">
              <ui-list
                label="{{ 'employees-popup.fields.profile' | translate }}"
                selectLabel="{{ 'employees-popup.fields.profile' | translate }}"
                [options]="this.profileOptions"
                formControlName="profile"
                [status]="popupForm.get('profile') | formStatus"
                [errorMessage]="popupForm.get('profile') | formErrorMessage | translate"
                mandatory="true"
                (ngModelChange)="managePasswordAndEmailFields()"
              >
              </ui-list>
            </div>
            <div class="grid-item last-name">
              <ui-input-text
                type="text"
                label="{{ 'employees-popup.fields.last-name' | translate }}"
                formControlName="lastName"
                [status]="popupForm.get('lastName') | formStatus"
                [errorMessage]="popupForm.get('lastName') | formErrorMessage | translate"
                mandatory="true"
                (keyup)="concatLogin()"
              >
              </ui-input-text>
            </div>
            <div class="grid-item first-name">
              <ui-input-text
                type="text"
                label="{{ 'employees-popup.fields.first-name' | translate }}"
                formControlName="firstName"
                [status]="popupForm.get('firstName') | formStatus"
                [errorMessage]="popupForm.get('firstName') | formErrorMessage | translate"
                mandatory="true"
                (keyup)="concatLogin()"
              >
              </ui-input-text>
            </div>
            <div class="grid-item user-number">
              <ui-input-text
                type="text"
                label="{{ 'employees-popup.fields.user-number' | translate }}"
                formControlName="userNumber"
                [status]="popupForm.get('userNumber') | formStatus"
                [errorMessage]="popupForm.get('userNumber') | formErrorMessage | translate"
                mandatory="true"
                autocomplete="new-user-number"
                infoLabel="{{ 'global.unique' | translate }}"
              >
              </ui-input-text>
            </div>
            <div class="grid-item job">
              <ui-list
                label="{{ 'employees-popup.fields.job' | translate }}"
                [options]="this.jobOptions"
                [search]="true"
                formControlName="jobs"
                [multiple]="true"
              >
              </ui-list>
            </div>

            <div class="grid-item email">
              <ui-input-text
                type="text"
                label="{{ 'employees-popup.fields.email' | translate }}"
                formControlName="email"
                [status]="popupForm.get('email') | formStatus"
                [errorMessage]="popupForm.get('email') | formErrorMessage | translate"
                infoLabel="{{ 'global.unique' | translate }}"
              >
              </ui-input-text>
            </div>
            <div class="grid-item">
              <div class="double-col">
                <div class="phone">
                  <ui-input-text
                    type="text"
                    label="{{ 'employees-popup.fields.phone' | translate }}"
                    formControlName="phone"
                    maskPattern="PN NN NN NN NN NN"
                    [customPattern]="pattern"
                    [specialCharacters]="[' ', '+']"
                    [dropSpecialCharacters]="[' ']"
                  >
                  </ui-input-text>
                </div>
                <div class="mobile-phone">
                  <ui-input-text
                    type="text"
                    label="{{ 'employees-popup.fields.mobile-phone' | translate }}"
                    formControlName="mobilePhone"
                    maskPattern="PN NN NN NN NN NN"
                    [customPattern]="pattern"
                    [specialCharacters]="[' ', '+']"
                    [dropSpecialCharacters]="[' ']"
                  >
                  </ui-input-text>
                </div>
              </div>
            </div>
            <div class="grid-item">
              <div class="double-col">
                <div class="login">
                  <ui-input-text
                    type="text"
                    label="{{ 'employees-popup.fields.login' | translate }}"
                    formControlName="login"
                    [status]="popupForm.get('login') | formStatus"
                    [errorMessage]="popupForm.get('login') | formErrorMessage | translate"
                    mandatory="true"
                    (keyup)="switchOffLoginAutoComplete()"
                    infoLabel="{{ 'global.unique' | translate }}"
                    [customPattern]="pattern"
                    maskPattern="Q*"
                  >
                  </ui-input-text>
                </div>
                <div class="pin-code">
                  <ui-input-text
                    type="text"
                    label="{{ 'employees-popup.fields.pin-code' | translate }}"
                    [infoLabel]="infoLabel"
                    type="password"
                    formControlName="pinCode"
                    [status]="popupForm.get('pinCode') | formStatus"
                    [errorMessage]="
                      popupForm.get('pinCode')
                        | formErrorMessage
                          : { customPrefix: 'user-form.errors.', useCustomPrefixFor: ['minlength', 'maxlength'] }
                        | translate
                    "
                    maxLength="4"
                    autocomplete="new-password"
                    [mandatory]="this.mandatoryPasswordFields.includes('pinCode')"
                    [generateBtn]="true"
                    [generateBtnTitle]="'user-form.fields.generate-pin-code' | translate"
                    (generate)="generatePinCode(false)"
                  >
                  </ui-input-text>
                </div>
              </div>
            </div>
            <div class="grid-item">
              <div class="double-col">
                <div class="temp-password">
                  <ui-input-text
                    #passwordField
                    label="{{ 'user-form.fields.temp-password' | translate }}"
                    [infoLabel]="infoLabel"
                    type="password"
                    formControlName="tempPassword"
                    [status]="popupForm.get('tempPassword') | formStatus"
                    [errorMessage]="
                      popupForm.get('tempPassword')
                        | formErrorMessage
                          : {
                              customPrefix: 'user-form.errors.',
                              useCustomPrefixFor: ['minlength', 'badPasswordFormat']
                            }
                        | translate: { symbols: symbols }
                    "
                    (change)="shouldCheckPassword()"
                    autocomplete="new-password"
                    [mandatory]="passwordMandatory()"
                    [generateBtn]="true"
                    [generateBtnTitle]="'user-form.fields.generate-password' | translate"
                    (generate)="generatePassword(false)"
                  >
                  </ui-input-text>
                  <ui-tooltip
                    *ngIf="password"
                    eventMode="focus"
                    placement="right"
                    fallbackPlacement="left"
                    maxWidth="300px"
                    [element]="password"
                  >
                    <span class="password-popper">
                      <div *ngFor="let item of passwordConstraints | keyvalue" [class]="getValidatorClass(item.value)">
                        <fa-icon [icon]="item.value ? faCheck : faTimes"></fa-icon>
                        {{ "user-form.password-validators." + item.key | translate }}
                      </div>
                    </span>
                  </ui-tooltip>
                </div>
                <div class="confirm-password">
                  <ui-input-text
                    label="{{ 'user-form.fields.confirm-password' | translate }}"
                    [infoLabel]="infoLabel"
                    type="password"
                    formControlName="confirmPassword"
                    (change)="shouldCheckPassword()"
                    [status]="popupForm.get('confirmPassword') | formStatus"
                    [errorMessage]="popupForm.get('confirmPassword') | formErrorMessage | translate"
                    autocomplete="new-password"
                    [mandatory]="this.mandatoryPasswordFields.includes('confirmPassword')"
                  >
                  </ui-input-text>
                </div>
              </div>
            </div>

            <div class="grid-item date-format">
              <ui-list
                label="{{ 'employees-popup.fields.date-format' | translate }}"
                selectLabel="{{ 'employees-popup.form.date-format' | translate }}"
                [options]="this.dateFormatOptions"
                formControlName="dateFormat"
                [status]="popupForm.get('dateFormat') | formStatus"
                [errorMessage]="popupForm.get('dateFormat') | formErrorMessage | translate"
                mandatory="true"
              >
              </ui-list>
            </div>

            <div class="grid-item">
              <div class="double-col">
                <div class="weekly-work-time">
                  <ui-input-text
                    type="text"
                    maskPattern="0*.0*"
                    label="{{ 'employees-popup.fields.weekly-work-time' | translate }}"
                    formControlName="weeklyWorkTime"
                  >
                  </ui-input-text>
                </div>
              </div>
            </div>
            <div class="grid-item">
              <div class="double-col padded">
                <div class="code-language">
                  <div class="form-line form-radio">
                    <ui-country-radio-button
                      name="codeLanguage"
                      label="{{ 'employees-popup.fields.code-language' | translate }}"
                      mandatory="true"
                      [options]="codeLangOptions"
                      [flags]="countryFlags"
                      formControlName="codeLanguage"
                      customClass="no-margin"
                    >
                    </ui-country-radio-button>
                  </div>
                </div>
                <div class="safe-ability">
                  <ui-radio-button
                    label="{{ 'employees-popup.fields.safe-ability' | translate }}"
                    name="safeAbility"
                    formControlName="safeAbility"
                    [options]="this.safeAbilityOptions"
                    direction="horizontal"
                    customClass="no-margin"
                    [status]="popupForm.get('safeAbility') | formStatus"
                    [errorMessage]="popupForm.get('safeAbility') | formErrorMessage | translate"
                    mandatory="true"
                  >
                  </ui-radio-button>
                </div>
              </div>
            </div>
            <div class="grid-item">
              <div class="double-col">
                <div class="hire-date">
                  <ui-date-picker
                    label="{{ 'employees-popup.fields.hire-date' | translate }}"
                    [locale]="locale"
                    [format]="dateFormat"
                    formControlName="hireDate"
                    [status]="popupForm.get('hireDate') | formStatus"
                    [errorMessage]="popupForm.get('hireDate') | formErrorMessage | translate"
                    mandatory="true"
                  >
                  </ui-date-picker>
                </div>
                <div class="end-contract-date">
                  <ui-date-picker
                    label="{{ 'employees-popup.fields.end-contract-date' | translate }}"
                    [locale]="locale"
                    [format]="dateFormat"
                    formControlName="endContractDate"
                    [status]="popupForm.get('endContractDate') | formStatus"
                    [errorMessage]="
                      popupForm.get('endContractDate')
                        | formErrorMessage: { customPrefix: 'user-form.errors.', useCustomPrefixFor: ['badEndDate'] }
                        | translate
                    "
                  >
                  </ui-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div footer>
          <div class="align-buttons">
            <div class="button-popup-validate">
              <div class="button">
                <ui-button
                  text="{{ 'global.validate' | translate }}"
                  [primary]="true"
                  type="save"
                  (click)="submitUser()"
                ></ui-button>
              </div>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </form>
</ng-container>
