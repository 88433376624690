<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ 'supplier.buttons.back-button' | translate }}"
        (click)="backToSupplierList()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
    </div>
    <div class="title">
      <ui-title titleText="{{ title | translate }}" subTitleText="{{ subTitle ? subTitle : undefined }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="validate-button">
        <div class="button">
          <ui-button
            text="{{ 'global.validate' | translate }}"
            type="save"
            [primary]="true"
            (click)="submitSupplier()"
          ></ui-button>
        </div>
      </div>
    </div>
    <div class="supplier-body">
      <ui-tab-handler #tabHandler (tabClick)="onTabClick($event)" [independent]="false">
        <ui-tab title="{{ 'supplier.general-datas.tab-title' | translate }}" id="general-datas" active="true">
          <ng-template #tabTemplate>
            <app-supplier-general #generalDatas *ngIf="this.updatedSupplier" [editedSupplier]="this.updatedSupplier">
            </app-supplier-general>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'supplier.delivery-addresses.tab-title' | translate }}" id="delivery-addresses">
          <ng-template #tabTemplate>
            <gen-addresses-list
              *ngIf="this.updatedSupplier"
              [(addressesList)]="this.updatedSupplier.deliveryAddresses"
              (submitEvent)="catchEvent('deliveryAddresses', $event)"
            >
            </gen-addresses-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'supplier.bank-datas.tab-title' | translate }}" id="bank-datas">
          <ng-template #tabTemplate>
            <gen-banks-list
              *ngIf="this.updatedSupplier"
              [(bankList)]="this.updatedSupplier.banks"
              (submitEvent)="catchEvent('banks', $event)"
              [showSepa]="false"
              [showAddress]="true"
              [showAccountNumber]="true"
              [mandatoryFields]="['name', 'bic', 'country']"
            >
            </gen-banks-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'supplier.phone-book.tab-title' | translate }}" id="phone-book">
          <ng-template #tabTemplate>
            <gen-persons-list
              *ngIf="this.updatedSupplier"
              [jobType]="this.jobTypeExternalContact"
              [(personsList)]="this.updatedSupplier.persons"
              (submitEvent)="catchEvent('persons', $event)"
            ></gen-persons-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'supplier.metal-management.tab-title' | translate }}" id="metal-management">
          <ng-template #tabTemplate>
            <div class="metal-container">
              <div class="alloy-side">
                <gen-alloy-override-list
                  *ngIf="this.updatedSupplier"
                  [(contactAlloys)]="this.updatedSupplier.alloys"
                  (submitEvent)="catchEvent('alloys', $event)"
                ></gen-alloy-override-list>
              </div>
              <div class="separator"></div>
              <div class="metal-account-side">
                <gen-metal-account-activation
                  #metalAccountActivation
                  *ngIf="this.updatedSupplier"
                  [contactId]="this.updatedSupplier.id"
                  [contactDisabled]="this.updatedSupplier.archived"
                  [(metalAccountsList)]="this.updatedSupplier.metalAccounts"
                ></gen-metal-account-activation>
              </div>
            </div>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'supplier.brands.tab-title' | translate }}" id="brands">
          <ng-template #tabTemplate>
            <app-supplier-brands
              #supplierBrands
              *ngIf="this.updatedSupplier"
              [(brandIds)]="this.updatedSupplier.linkedBrandIds"
            ></app-supplier-brands>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'supplier.commercial-modality.tab-title' | translate }}" id="commercial-modality">
          <ng-template #tabTemplate>
            <app-commercial-modality
              #commercialModality
              *ngIf="updatedSupplier"
              [(commercialModality)]="updatedSupplier.commercialModality"
              [address]="updatedSupplier.address"
              [contacts]="updatedSupplier.persons"
              [currencyId]="updatedSupplier.currencyId"
            >
            </app-commercial-modality>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'supplier.size-categories.tab-title' | translate }}" id="size-categories">
          <ng-template #tabTemplate>
            <override-size-categories-list
              *ngIf="this.updatedSupplier"
              [(overrideSizeCategories)]="this.updatedSupplier.sizeCategories"
              (submitEvent)="catchEvent('sizeCategories', $event)"
            ></override-size-categories-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'supplier.stores.tab-title' | translate }}" id="stores">
          <ng-template #tabTemplate>
            <app-supplier-stores
              #supplierStores
              *ngIf="this.updatedSupplier"
              [(stores)]="this.updatedSupplier.stores"
              [supplierId]="this.updatedSupplier.id"
            >
            </app-supplier-stores>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>
    </div>
  </div>
</ng-container>
