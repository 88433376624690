<ui-popup title="{{ 'date-warning-popup.title' | translate }}" (close)="closePopup()">
  <div content>
    <div class="warning-content">
      <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
      <div>
        <strong>{{ "date-warning-popup.warning-label" | translate }}</strong>
        <div class="doubleWarningDiv">
          <ul>
            <li>{{ "date-warning-popup.first-msg" | translate }}</li>
            <li>{{ "date-warning-popup.second-msg" | translate }}</li>
            <li>{{ "date-warning-popup.third-msg" | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
    <form [formGroup]="popupForm" novalidate>
      <ui-checkbox
        [labelPosition]="'right'"
        [label]="'date-warning-popup.label' | translate"
        [formControlName]="'print'"
      >
      </ui-checkbox>
    </form>
    <div class="choice-label">{{ "date-warning-popup.choice" | translate }}</div>
  </div>
  <div footer>
    <div class="align-buttons">
      <div class="button-popup-validate">
        <div class="button">
          <ui-button
            text="{{ 'date-warning-popup.yes' | translate }}"
            [primary]="true"
            type="validate"
            (click)="validatePopup()"
          ></ui-button>
        </div>
      </div>
      <div class="button-popup-refuse">
        <div class="button">
          <ui-button text="{{ 'date-warning-popup.no' | translate }}" type="cancel" (click)="closePopup()"></ui-button>
        </div>
      </div>
    </div>
  </div>
</ui-popup>
