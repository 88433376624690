<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'users-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-create">
        <ui-button
          text="{{ 'global.new' | translate }}"
          type="add"
          [primary]="true"
          (click)="createUser()"
          *hasPermission="['USER_CREATE']"
        ></ui-button>
      </div>
      <div class="button-export">
        <ui-button
          type="export"
          text="{{
            (usersRows.length === usersList.length ? 'global.export.all' : 'global.export.select') | translate
          }}"
          *hasPermission="['USER_READ']"
          (click)="exportUsers()"
        ></ui-button>
      </div>
      <div class="button-import">
        <ui-button
          type="import"
          text="{{ 'global.import' | translate }}"
          *hasPermission="['USER_CREATE']"
          (click)="importUsers()"
        ></ui-button>
      </div>
    </div>

    <div class="licenses-table" *hasPermission="['GOD']">
      <ui-container>
        <div>
          <ngx-datatable
            #table
            [rows]="licensesRows"
            [trackByProp]="'id'"
            class="material"
            columnMode="flex"
            headerHeight="auto"
            rowHeight="40"
            [rowClass]="getRowClass"
            [limit]="2"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
          >
            <ngx-datatable-column
              name="{{ 'users-list.datatable.columns.license_type.title' | translate }}"
              prop="license_type"
              cellClass="top-align"
              [flexGrow]="1"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-color="row.license_type">
                <span>{{ "users-list.datatable.columns.license_type." + row.license_type | translate }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'users-list.datatable.columns.license_owned' | translate }}"
              prop="owned"
              cellClass="top-align"
              [flexGrow]="1"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'users-list.datatable.columns.license_used' | translate }}"
              prop="used"
              cellClass="top-align"
              [flexGrow]="1"
              [resizeable]="false"
            >
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>

    <div class="users-table">
      <ui-container>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            applyLabel="{{ 'cara-users-list.filters.apply' | translate }}"
            label="{{ 'cara-users-list.filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            dateFormat="{{ dateFormat }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div [formGroup]="tableControl">
          <ngx-datatable
            #tableUsers
            [rows]="usersRows"
            [trackByProp]="'id'"
            class="material"
            columnMode="flex"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [limit]="15"
            (activate)="updateUser($event)"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ng-container>
              <ngx-datatable-column
                *ngFor="let column of columns"
                name="{{ column.name | translate }}"
                [flexGrow]="column.flexGrow"
                [prop]="column.propName"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <!-- Display type text -->
                  <span *ngIf="!column.displayType || column.displayType === 'text'" [title]="row[column.propName]">{{
                    row[column.propName]
                  }}</span>

                  <!-- Display type text to translate -->
                  <span *ngIf="column.displayType === 'textToTranslate'" [title]="row[column.propName] | translate">{{
                    row[column.propName] | translate
                  }}</span>

                  <!-- Display type date -->
                  <span *ngIf="column.displayType === 'date'" [title]="row[column.propName] | dateTimeFormat">{{
                    row[column.propName] | dateTimeFormat
                  }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'cara-users-list.datatable.columns.stores' | translate }}"
                cellClass="ellipsis-counter"
                [flexGrow]="1.4"
                prop="stores"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-stores="row.stores">
                  <p class="ellipsis">{{ stores.join(", ") }}</p>
                  <div *ngIf="stores.length >= 2">
                    <div #storeCounter>
                      <ui-counter [number]="stores.length"></ui-counter>
                    </div>
                    <ui-tooltip maxWidth="300px" [element]="storeCounter">
                      <span style="font-weight: normal !important">{{ stores.join(", ") }}</span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'cara-users-list.datatable.columns.activated' | translate }}"
                prop="activated"
                [flexGrow]="1"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                  <div class="green-zone" (click)="$event.stopPropagation()">
                    <ui-switch
                      customClass="no-margin"
                      (change)="activeCheckboxOnChanges(id)"
                      [formControl]="tableControl.get('activated_' + id)"
                    >
                    </ui-switch>
                  </div>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="tableUsers.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>
  </div>
</ng-container>
