<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ 'technical-error-page.back-link' | translate }}"
        (click)="backToLastPage()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
    </div>

    <div class="content">
      <ui-container>
        <div style="text-align: center">
          <div class="title">
            {{ "technical-error-page.title" | translate }}
          </div>
          <div class="status">
            {{ "technical-error-page.status" | translate }}
          </div>
          <img src="{{ this.imageLink }}" />
          <div class="text" [innerHTML]="'technical-error-page.text' | translate"></div>
        </div>

        <div class="bottom-buttons">
          <div class="button">
            <ui-button
              text="{{ 'technical-error-page.back-home' | translate }}"
              type="return"
              [primary]="true"
              (click)="backToHome()"
            ></ui-button>
          </div>
          <div class="link">
            <ui-link text="{{ 'technical-error-page.help-site' | translate }}" (click)="goToHelpSite()"></ui-link>
          </div>
        </div>
      </ui-container>
    </div>
  </div>
</ng-container>
