<ng-container>
  <div
    [formGroup]="formGroup"
    [class]="'main-container'"
    [ngClass]="formGroup.get('stockCorrection').value ? ' frozen-right' : ''"
  >
    <div *ngIf="availableFilters" [class]="'filters'">
      <ui-dynamic-filters
        [filters]="availableFilters"
        [(filterValues)]="filterValues"
        [label]="'filters.label' | translate"
        [applyLabel]="'filters.apply' | translate"
        (apply)="applyFilters()"
        [minLabel]="'filters.min' | translate"
        [maxLabel]="'filters.max' | translate"
        [deleteLabel]="'filters.delete' | translate"
        [placeholderPrefix]="('filters.prefix' | translate) + ' '"
      >
      </ui-dynamic-filters>
    </div>

    <div [class]="'aligned-children'">
      <div [class]="'quantity-difference'">
        <ui-switch
          [label]="'inventory-result.fields.quantity-difference-switch' | translate"
          [customClass]="'quantity-difference-switch'"
          [formControlName]="'nonZeroDeltaQuantity'"
        >
        </ui-switch>
      </div>

      <div [class]="'stocks-correction'">
        <ui-switch
          [label]="'inventory-result.fields.stocks-correction-switch' | translate"
          [customClass]="'stocks-correction-switch'"
          [formControlName]="'stockCorrection'"
        >
        </ui-switch>
      </div>
    </div>

    <div [class]="'inventory-result-table'">
      <ngx-datatable
        #table
        [rows]="rows"
        [trackByProp]="'id'"
        [class]="'material'"
        [columnMode]="'force'"
        [headerHeight]="'auto'"
        [footerHeight]="50"
        [rowHeight]="40"
        [scrollbarH]="true"
        [rowClass]="getRowClass"
        [externalSorting]="true"
        [externalPaging]="true"
        [count]="pager.totalElements"
        [offset]="pager.number"
        [limit]="pager.size"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        [sorts]="sorts"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down'
        }"
      >
        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.item-category' | translate"
          [cellClass]="'item-category'"
          [prop]="'itemCategory'"
          [resizeable]="false"
          [draggable]="false"
          [width]="100"
        >
          <ng-template ngx-datatable-cell-template let-itemCategory="row.itemCategory">
            <span [class]="'ellipsis'" [title]="itemCategory">{{ itemCategory }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.item-reference' | translate"
          [prop]="'itemRef'"
          [cellClass]="'item-reference'"
          [resizeable]="false"
          [draggable]="false"
          [width]="100"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.item-supplier-reference' | translate"
          [prop]="'itemSupplierRef'"
          [cellClass]="'item-supplier-reference'"
          [resizeable]="false"
          [draggable]="false"
          [width]="100"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.item-name' | translate"
          [cellClass]="'item-name'"
          [prop]="'itemName'"
          [resizeable]="false"
          [draggable]="false"
          [width]="100"
        >
          <ng-template ngx-datatable-cell-template let-itemName="row.itemName">
            <span [class]="'ellipsis'" [title]="itemName">{{ itemName }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.serial-number' | translate"
          [prop]="'serialNumber'"
          [cellClass]="'serial-number'"
          [resizeable]="false"
          [draggable]="false"
          [width]="100"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.batch-number' | translate"
          [prop]="'batchNumber'"
          [cellClass]="'batch-number'"
          [resizeable]="false"
          [draggable]="false"
          [width]="100"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.initial-quantity' | translate"
          [prop]="'initialQuantity'"
          [cellClass]="'initial-quantity'"
          [resizeable]="false"
          [draggable]="false"
          [width]="100"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.inventory-quantity' | translate"
          [cellClass]="'quantity-inventory center-align'"
          [sortable]="false"
          [resizeable]="false"
          [draggable]="false"
          [width]="100"
        >
          <ng-template ngx-datatable-cell-template let-rowId="row.id" let-inventoryQuantity="row.inventoryQuantity">
            <div *ngIf="formGroup.get('stockCorrection').value; else static" class="aligned-children">
              <ui-input-text
                [type]="'text'"
                [maskPattern]="decimalDigit"
                [class]="'input-fields'"
                [formControlName]="'final_quantity_' + rowId"
                [sizeMode]="'small'"
                [status]="formGroup.get('final_quantity_' + rowId) | formStatus"
                [errorMessage]="
                  formGroup.get('final_quantity_' + rowId)
                    | formErrorMessage
                      : { customPrefix: 'inventory-entry.errors.', useCustomPrefixFor: ['addSerialNumberStockEntry'] }
                    | translate: { digit: HIGH_INTEGER }
                "
              >
              </ui-input-text>
              <div
                *ngIf="+formGroup.get('final_quantity_' + rowId).value !== inventoryQuantity"
                #itemElement
                [class]="'circle-check'"
              >
                <fa-icon [icon]="faCircleCheck"></fa-icon>
                <ui-tooltip [element]="itemElement" *ngIf="itemElement">
                  <span style="font-weight: normal !important; white-space: break-spaces">
                    {{ "inventory-result.fields.quantity-updated" | translate }}
                  </span>
                </ui-tooltip>
              </div>
            </div>
            <ng-template #static>
              <span
                *ngIf="!formGroup.get('stockCorrection').value"
                [class]="'ellipsis'"
                [title]="formGroup.get('final_quantity_' + rowId).value"
              >
                {{ formGroup.get("final_quantity_" + rowId).value }}</span
              >
            </ng-template>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.quantity-difference' | translate"
          prop="quantityDifference"
          [cellClass]="'quantity-difference'"
          [resizeable]="false"
          [draggable]="false"
          [sortable]="false"
          [width]="70"
        >
          <ng-template ngx-datatable-cell-template let-quantityDifference="row.quantityDifference">
            <span
              [class]="quantityDifference > 0 ? 'positive-quantity' : quantityDifference < 0 ? 'negative-quantity' : ''"
            >
              {{ quantityDifference }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          *hasPermission="['INVENTORY_PRICE_DIFF_READ']"
          prop="priceDifference"
          [name]="'inventory-result.datatable.columns.price-difference' | translate"
          [cellClass]="'price-difference center-align'"
          [resizeable]="false"
          [draggable]="false"
          [sortable]="true"
          [width]="70"
        >
          <ng-template ngx-datatable-cell-template let-priceDifference="row.priceDifference">
            <span [class]="priceDifference > 0 ? 'positive-quantity' : priceDifference < 0 ? 'negative-quantity' : ''">
              {{ priceDifference > 0 ? "+" : ""
              }}{{ priceDifference | formatCurrency: this.defaultCurrency?.symbol:"symbol":"0.2-5":locale }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'inventory-result.datatable.columns.location' | translate"
          [cellClass]="'location'"
          [prop]="'locationName'"
          [resizeable]="false"
          [draggable]="false"
          [width]="100"
        >
          <ng-template ngx-datatable-cell-template let-location="row.locationName">
            <span [class]="'location-name ellipsis'" [title]="location">{{ location }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          *ngIf="formGroup.get('stockCorrection').value"
          [name]="'inventory-result.datatable.columns.actions' | translate"
          [headerClass]="'center-align'"
          [cellClass]="'center-align frozen-style'"
          [resizeable]="false"
          [frozenRight]="true"
          [sortable]="false"
          [width]="50"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-inventoryQuantity="row.inventoryQuantity">
            <div
              *ngIf="+formGroup.get('final_quantity_' + row.id).value !== inventoryQuantity"
              class="green-zone center"
              (click)="$event.stopPropagation()"
            >
              <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)">
              </ui-menu-actions>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div [class]="'page-count'" *ngIf="rowCount">
              {{
                rowCount
                  | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                  | translate: { rowCount: rowCount }
              }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="changePage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</ng-container>

<div *ngIf="popupVisible" [class]="'validation-popup-container'">
  <ui-popup [title]="'inventory-result.validation-popup.title' | translate" (close)="closePopup()">
    <div content>
      <div>
        {{ "inventory-result.validation-popup.warning-message" | translate }}
      </div>

      <div class="location-changes-popup-content">
        <div *ngIf="this.outMovements.length" class="out-changes location-changes-container">
          <div class="out-title title">
            <fa-icon class="out-title-icon fa-sm" [icon]="faCircleMinus"></fa-icon>
            <span class="title-text">{{
              this.outMovements.length === 1
                ? ("inventory-result.validation-popup.out-changes-title-singular" | translate | uppercase)
                : ("inventory-result.validation-popup.out-changes-title-plural" | translate | uppercase)
            }}</span>
          </div>
          <div class="out-content content">
            <div>
              {{
                outMovements.length === 1
                  ? ("inventory-result.validation-popup.out-changes-message-singular" | translate)
                  : ("inventory-result.validation-popup.out-changes-message-plural" | translate)
              }}
            </div>
            <div *ngFor="let flm of outMovements" class="location-change-item">
              <li>
                {{ flm.itemName + " - " + flm.stockEntryId }}
                <fa-icon class="title-icon fa-sm" [icon]="faArrowRight"></fa-icon>

                {{
                  ("inventory-result.validation-popup.out-location" | translate) +
                    flm.srcLocationName +
                    " (" +
                    flm.srcStoreName +
                    ")"
                }}
              </li>
            </div>
          </div>
        </div>

        <div *ngIf="this.inMovements.length" class="in-changes location-changes-container">
          <div class="in-title title">
            <fa-icon class="in-title-icon fa-sm" [icon]="faCirclePlus"></fa-icon>
            <span class="title-text">{{
              this.inMovements.length === 1
                ? ("inventory-result.validation-popup.in-changes-title-singular" | translate | uppercase)
                : ("inventory-result.validation-popup.in-changes-title-plural" | translate | uppercase)
            }}</span>
          </div>
          <div class="in-content content">
            <div>
              {{
                inMovements.length === 1
                  ? ("inventory-result.validation-popup.in-changes-message-singular" | translate)
                  : ("inventory-result.validation-popup.in-changes-message-plural" | translate)
              }}
            </div>
            <div *ngFor="let flm of inMovements" class="location-change-item">
              <li>
                {{ flm.itemName + " - " + flm.stockEntryId }}
                <fa-icon class="title-icon fa-sm" [icon]="faArrowRight"></fa-icon>

                {{
                  ("inventory-result.validation-popup.in-location" | translate) +
                    flm.destLocationName +
                    " (" +
                    flm.destStoreName +
                    ")"
                }}
              </li>
            </div>
          </div>
        </div>

        <div *ngIf="this.outInMovements.length" class="in-out-changes location-changes-container">
          <div class="in-out-title title">
            <fa-icon class="in-out-title-icon fa-sm" [icon]="faArrowsRotate"></fa-icon>
            <span class="title-text">{{
              outInMovements.length === 1
                ? ("inventory-result.validation-popup.in-out-changes-title-singular" | translate | uppercase)
                : ("inventory-result.validation-popup.in-out-changes-title-plural" | translate | uppercase)
            }}</span>
          </div>
          <div class="in-out-content content">
            <div>
              {{
                outInMovements.length === 1
                  ? ("inventory-result.validation-popup.out-in-changes-message-singular" | translate)
                  : ("inventory-result.validation-popup.out-in-changes-message-plural" | translate)
              }}
            </div>
            <div *ngFor="let flm of outInMovements" class="location-change-item">
              <li>
                {{
                  flm.itemName + " - " + flm.stockEntryId + " - " + flm.srcLocationName + " (" + flm.srcStoreName + ")"
                }}
                <fa-icon class="title-icon fa-sm" [icon]="faArrowRight"></fa-icon>

                {{
                  ("inventory-result.validation-popup.in-location" | translate) +
                    flm.destLocationName +
                    " (" +
                    flm.destStoreName +
                    ")"
                }}
              </li>
            </div>
          </div>
        </div>
      </div>

      <div class="bold question">
        {{ "inventory-result.validation-popup.question" | translate }}
      </div>
    </div>
    <div footer>
      <div [class]="'align-buttons'">
        <div [class]="'button-popup-validate'">
          <div [class]="'button'">
            <ui-button
              [text]="'inventory-result.validation-popup.yes' | translate"
              [primary]="true"
              [type]="'validate'"
              (click)="finalizeInventory()"
              [disabled]="finalizeBtnDisabled"
            ></ui-button>
          </div>
        </div>
        <div [class]="'button-popup-cancel'">
          <div [class]="'button second-button'">
            <ui-button
              [text]="'inventory-result.validation-popup.no' | translate"
              [type]="'cancel'"
              (click)="closePopup()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
