import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { IconDefinition, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AbstractReceivingLine } from "center-services";
import { CommonValidatorsUtil } from "fugu-common";
import { PrecisionUtil } from "generic-pages";

@Component({
  selector: "app-split-delivery-line-popup",
  templateUrl: "./split-delivery-line-popup.component.html",
  styleUrls: ["./split-delivery-line-popup.component.scss"],
})
export class SplitDeliveryLinePopupComponent implements OnInit {
  @Input() line: AbstractReceivingLine;
  @Input() itemName: string;
  @Input() purchaseUnit: string;
  @Input() lineNumber: number;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() splittedLines: EventEmitter<any> = new EventEmitter();

  public readonly decimalDigitHigh: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public splitForm: UntypedFormGroup;
  public faCheck: IconDefinition = faCheck;
  public quantityToDisplay: number;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.preparePopupForm();
  }

  preparePopupForm(): void {
    const digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);

    this.splitForm = this.fb.group({
      lines: [1, [Validators.required, digitValidator, Validators.min(1)]],
    });
  }

  getPopupTitle(): string {
    return this.translateService.instant("split-delivery-line-popup.title");
  }

  closePopup(): void {
    this.close.emit("close");
  }

  splitLines(): void {
    if (this.splitForm.invalid) {
      this.splitForm.markAllAsTouched();
      return;
    }
    const linesCount = this.splitForm.get("lines").value;
    const line = { ...this.line, receivedQuantity: null, expectedQuantity: 0, supplierRef: null, id: null };
    const lines = Array.from({ length: linesCount }, () => ({ ...line }));
    this.splittedLines.emit(lines);
    this.close.emit("close");
  }
}
