import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  StoreModuleEntry,
  Store,
  StoreModule,
  StoreService,
  PricingGroupService,
  CaraUserService,
  TagService,
  StoreModuleService,
} from "center-services";
import { Option, SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { Tag as TagComponent } from "fugu-components/lib/util/tag";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { BaseStoreListComponent } from "../../../store/util/base-store-list-component";

@Component({
  selector: "app-store-module-entry-popup",
  templateUrl: "./store-module-entry-popup.component.html",
  styleUrls: ["./store-module-entry-popup.component.scss"],
  providers: [SubscriptionService],
})
export class StoreModuleEntryPopupComponent extends BaseStoreListComponent implements OnInit, AfterViewInit {
  @Input() storeModuleEntries: StoreModuleEntry[] = [];
  @Output() storeModuleEntriesChange: EventEmitter<StoreModuleEntry[]> = new EventEmitter<StoreModuleEntry[]>();
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Input() itemGroupId: number;

  // override  LIST_ID
  public LIST_ID: string = "base-store-list-store.module-entry-popup-table";

  public storeModuleOptions: Option[] = [];
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public popupVisible: boolean = true;
  public allRows: Store[] = [];
  public allStoreModules: StoreModule[];
  public unsavedStoreModuleEntries: StoreModuleEntry[];
  public initialStoreModuleEntries: StoreModuleEntry[];
  public selectedStoreModuleEntries: StoreModuleEntry[];

  constructor(
    storeService: StoreService,
    translateService: TranslateService,
    messageService: MessageService,
    pricingGroupService: PricingGroupService,
    userService: CaraUserService,
    tagService: TagService,
    private storeModuleService: StoreModuleService,
    private formBuilder: UntypedFormBuilder,
    protected subscriptionService: SubscriptionService
  ) {
    super(
      storeService,
      translateService,
      messageService,
      pricingGroupService,
      userService,
      tagService,
      subscriptionService
    );
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.initialStoreModuleEntries = this.storeModuleEntries;
    this.unsavedStoreModuleEntries = this.initialStoreModuleEntries.map(entry => new StoreModuleEntry(entry));
    this.selectedStoreModuleEntries = this.initialStoreModuleEntries.map(entry => new StoreModuleEntry(entry));
  }

  initStoreModuleOptions(): void {
    this.storeModuleOptions = [];
    this.storeModuleOptions.push(
      new Option(null, this.translateService.instant(`${this.getTranslationPrefix()}.none-option`))
    );
    this.allStoreModules.forEach(sm => {
      this.storeModuleOptions.push(new Option(sm.id, sm.name));
    });
  }

  afterStoresFetch(): void {
    super.afterStoresFetch();
    this.allRows = this.rows;
  }

  getStoreModuleId(storeId: number): number {
    const storeModuleEntry = this.storeModuleEntries.filter(sme => sme.storeId === storeId);
    return storeModuleEntry.length ? storeModuleEntry[0].storeModuleId : null;
  }

  getFormStoreModuleId(storeId: number): number {
    return this.form.get(this.getRowControlName(storeId)).value;
  }

  addRowFormControl(storeId: number, storeModuleId: number): void {
    const storeModuleControl = new UntypedFormControl(storeModuleId);
    this.subscriptionService.subs.push(
      storeModuleControl.valueChanges.subscribe(newValue => {
        let entry = this.selectedStoreModuleEntries.find(e => e.storeId === storeId);
        if (!entry) {
          entry = new StoreModuleEntry({ storeModuleId: newValue, storeId });
          this.selectedStoreModuleEntries.push(entry);
        } else {
          entry.storeModuleId = newValue;
        }
      })
    );
    this.form.addControl(this.getRowControlName(storeId), storeModuleControl);
  }

  getRowControlName(id: number): string {
    return `row_${id}`;
  }

  initFilters(): void {
    super.initFilters();
    // observable isn't call when we try to build the filter
    this.filterer.addListFilter(
      "storeModules.storeModule.id",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.module`),
      this.getStoreModuleOptions(),
      null,
      null,
      ["NULL"]
    );
  }

  getStoreModuleOptions(): any[] {
    let options = [];

    options.push({
      value: "NULL",
      displayValue: this.translateService.instant(`${this.getTranslationPrefix()}.none-option`),
    });

    options = options.concat(
      this.allStoreModules.map(sm => {
        return { value: sm.id.toString(), displayValue: sm.name };
      })
    );

    return options;
  }

  submitStoreModuleEntries(): void {
    this.storeModuleEntriesChange.emit(
      this.selectedStoreModuleEntries.sort((sme1, sme2) => sme1.storeId - sme2.storeId)
    );
    this.close();
  }

  canClosePopup(): void {
    if (
      this.isStoreModuleEntriesList(this.selectedStoreModuleEntries, this.initialStoreModuleEntries) ||
      this.isStoreModuleEntriesList(this.selectedStoreModuleEntries, this.unsavedStoreModuleEntries)
    ) {
      this.close();
    } else {
      this.generateUnsavedPopinError();
    }

    this.unsavedStoreModuleEntries = this.selectedStoreModuleEntries.map(entry => new StoreModuleEntry(entry));
  }

  computeSearchFilters(): void {
    super.computeSearchFilters();

    if (this.itemGroupId) {
      this.activeFilters.push(
        new SearchFilter("storeModules.itemGroup.id", SearchFilterOperator.EQUAL, this.itemGroupId?.toString())
      );
    }
  }

  // close the popup
  close(): void {
    this.popupVisible = false;
    this.closePopup.emit();
  }

  generateUnsavedPopinError(): void {
    const title = this.translateService.instant("global.errors.unsaved-title");
    const content = this.translateService.instant("global.errors.unsaved-popin-content");
    this.messageService.info(content, { title });
  }

  isStoreModuleEntriesList(sme1: StoreModuleEntry[], sme2: StoreModuleEntry[]): boolean {
    return [...sme1].every(sm1 => sme2.some(sm2 => sm2.equals(sm1)));
  }

  getTranslationPrefix(): string {
    return "store-module-entry";
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  protected fetchOtherEntities(): Observable<StoreModule[]> {
    return this.fetchAllStoreModules();
  }

  protected addRow(store: Store, tags: TagComponent[]): void {
    const storeModuleId = this.getStoreModuleId(store.id);
    this.rows.push({
      id: store.id,
      name: store.name,
      reference: store.reference,
      city: store.address.city,
      status: store.status,
      tags,
    });

    this.addRowFormControl(store.id, storeModuleId);
  }

  private fetchAllStoreModules(): Observable<StoreModule[]> {
    return this.storeModuleService.getAll().pipe(
      tap(
        (storeModules: StoreModule[]) => {
          this.allStoreModules = storeModules;
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant(`${this.getTranslationPrefix()}.errors.get-entities`, {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        },
        () => {
          this.initStoreModuleOptions();
        }
      )
    );
  }
}
