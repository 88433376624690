<ui-popup
title="{{'invoice-customer-validation-popup.title' | translate}}"
(close)="closePopup()"
>
<div content>
    <div class="warning-content">
        <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon> 
        <div>
            <strong>{{ "disable-store-warning-popup.warning-label" | translate }}</strong>
            <div>{{'invoice-customer-validation-popup.warning' | translate}}</div>
        </div>
    </div>
    <form class="brand-collection-form" [formGroup]="popupForm" novalidate>
        <ui-checkbox
        [labelPosition]="'right'"
        [label]="'invoice-customer-validation-popup.label' | translate"
        [formControlName]="'print'"
      >
      </ui-checkbox>
    </form>
</div>
<div footer>
  <div class="align-buttons">
    <div class="button-popup-validate">
      <div class="button">
        <ui-button
          text="{{ 'invoice-customer-validation-popup.yes' | translate }}"
          [primary]="true"
          type="validate"
          (click)="validateInvoice()"
        ></ui-button>
      </div>
    </div>
    <div class="button-popup-refuse">
      <div class="button">
        <ui-button
          text="{{ 'invoice-customer-validation-popup.no' | translate }}"
          type="cancel"
          (click)="closePopup()"
        ></ui-button>
      </div>
    </div>
  </div>
</div>
</ui-popup>