<ng-container>
  <div class="shipment-initiator-popup-container" [formGroup]="popupForm" novalidate>
    <ui-popup title="{{ 'shipment-initiator-popup.title' | translate }}" (close)="closePopup()">
      <div content>
        <div class="recipient-label">{{ "shipment-initiator-popup.labels.recipient" | translate }}<span> *</span></div>
        <ui-radio-button
          [options]="this.recipientOptions"
          name="recipient"
          formControlName="recipient"
          mandatory="true"
          [status]="popupForm.get('recipient') | formStatus"
          [errorMessage]="popupForm.get('recipient') | formErrorMessage | translate"
        ></ui-radio-button>

        <div class="supplier" *ngIf="this.popupForm.controls.recipient.value === 0">
          <ui-list
            label="{{ 'shipment-initiator-popup.options.supplier' | translate }}"
            [status]="popupForm.get('supplier') | formStatus"
            [errorMessage]="popupForm.get('supplier') | formErrorMessage | translate"
            [options]="supplierOptions"
            formControlName="supplier"
            [mandatory]="true"
            [search]="true"
          ></ui-list>
        </div>

        <div class="customer" *ngIf="this.popupForm.controls.recipient.value === 1">
          <ui-list
            label="{{ 'shipment-initiator-popup.options.customer' | translate }}"
            [status]="popupForm.get('customer') | formStatus"
            [errorMessage]="popupForm.get('customer') | formErrorMessage | translate"
            [options]="customerOptions"
            formControlName="customer"
            [mandatory]="true"
            [search]="true"
          ></ui-list>
        </div>

        <div class="store" *ngIf="this.popupForm.controls.recipient.value === 2">
          <ui-list
            label="{{ 'shipment-initiator-popup.options.store' | translate }}"
            [status]="popupForm.get('store') | formStatus"
            [errorMessage]="popupForm.get('store') | formErrorMessage | translate"
            [options]="storeOptions"
            formControlName="store"
            [mandatory]="true"
            [search]="true"
          ></ui-list>
        </div>
      </div>
      <div footer>
        <div class="button-popup-to-shipment">
          <ui-button
            text="{{ 'shipment-initiator-popup.labels.button' | translate }}"
            [primary]="true"
            (click)="submit()"
            [btnIcon]="faArrowCircleRight"
            [disabled]="popupForm.invalid"
          ></ui-button>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
