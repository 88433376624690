<ng-container>
  <form class="characteristics-form" [formGroup]="characteristicsForm" novalidate>
    <div class="container">
      <div class="grid-item">
        <div class="multi-col">
          <div class="stock-locations">
            <ui-list
              label="{{ 'characteristics.stock-location' | translate }}"
              [options]="this.stockOptions"
              formControlName="stockLocations"
              [multiple]="true"
              [search]="true"
            >
            </ui-list>
          </div>
          <div class="stock-button">
            <ui-button
              text="{{ 'characteristics.prioritize' | translate }}"
              (click)="openStockPopup()"
              [btnIcon]="faSort"
            ></ui-button>
          </div>
        </div>
      </div>

      <div class="grid-item mall-name">
        <ui-input-text type="text" label="{{ 'characteristics.mall-name' | translate }}" formControlName="mallName">
        </ui-input-text>
      </div>

      <div class="grid-item main-store-name">
        <ui-input-text
          type="text"
          label="{{ 'characteristics.main-store-name' | translate }}"
          formControlName="mainStoreName"
        >
        </ui-input-text>
      </div>

      <div class="grid-item">
        <div class="double-col">
          <div class="total-area">
            <ui-input-text
              type="money"
              label="{{ 'characteristics.total-area' | translate }}"
              formControlName="totalArea"
              [status]="characteristicsForm.get('totalArea') | formStatus"
              [errorMessage]="
                characteristicsForm.get('totalArea')
                  | formErrorMessage: { customPrefix: 'characteristics.errors.' }
                  | translate
              "
              suffix=" m2"
              [thousandSeparator]="' '"
            >
            </ui-input-text>
          </div>
          <div class="sale-area">
            <ui-input-text
              type="money"
              label="{{ 'characteristics.sale-area' | translate }}"
              formControlName="saleArea"
              [status]="characteristicsForm.get('saleArea') | formStatus"
              [errorMessage]="
                characteristicsForm.get('saleArea')
                  | formErrorMessage: { customPrefix: 'characteristics.errors.' }
                  | translate
              "
              suffix=" m2"
              [thousandSeparator]="' '"
            >
            </ui-input-text>
          </div>
        </div>
      </div>

      <div class="grid-item">
        <div class="double-col">
          <div class="store-count">
            <ui-input-text
              type="text"
              maskPattern="0*"
              label="{{ 'characteristics.store-count' | translate }}"
              formControlName="storeCount"
              [status]="characteristicsForm.get('storeCount') | formStatus"
              [errorMessage]="
                characteristicsForm.get('storeCount')
                  | formErrorMessage: { customPrefix: 'characteristics.errors.' }
                  | translate
              "
            >
            </ui-input-text>
          </div>
          <div class="competitor-count">
            <ui-input-text
              type="text"
              maskPattern="0*"
              label="{{ 'characteristics.competitor-count' | translate }}"
              formControlName="competitorCount"
              [status]="characteristicsForm.get('competitorCount') | formStatus"
              [errorMessage]="
                characteristicsForm.get('competitorCount')
                  | formErrorMessage: { customPrefix: 'characteristics.errors.' }
                  | translate
              "
            >
            </ui-input-text>
          </div>
        </div>
      </div>

      <div class="grid-item">
        <div class="double-col">
          <div class="opening-date">
            <ui-date-picker
              label="{{ 'characteristics.opening-date' | translate }}"
              [locale]="locale"
              [format]="dateFormat"
              formControlName="openingDate"
            >
            </ui-date-picker>
          </div>
          <div class="delivery-delay">
            <ui-input-text
              type="text"
              label="{{ 'characteristics.delivery-delay' | translate }}"
              formControlName="deliveryDelay"
              maskPattern="separator.0"
              [status]="characteristicsForm.get('deliveryDelay') | formStatus"
              [errorMessage]="
                characteristicsForm.get('deliveryDelay')
                  | formErrorMessage: { customPrefix: 'characteristics.errors.' }
                  | translate
              "
              suffix=" {{ 'unit.day' | translate }}"
              [thousandSeparator]="' '"
            >
            </ui-input-text>
          </div>
        </div>
      </div>

      <div class="grid-item">
        <div class="promotion-options">
          <ui-list
            label="{{ 'characteristics.promotion-period' | translate }}"
            [options]="this.promotionOptions"
            formControlName="promotionPeriods"
            [search]="true"
            [multiple]="true"
          >
          </ui-list>
        </div>
      </div>

      <div class="grid-item opening-times" *ngIf="operatingTimeDays">
        <app-opening-times #openingTimes [(openingTimesList)]="operatingTimeDays"></app-opening-times>
      </div>

      <div class="grid-item">
        <div class="comparable-surface">
          <div class="comparable-surface-switch">
            <ui-switch
              label="{{ 'comparable-surface.switch.title' | translate }}"
              class="eco-switch"
              customClass="no-margin"
              formControlName="comparableSurfaceActivated"
              (change)="comparableSurfaceOnChanges()"
            >
            </ui-switch>
          </div>
          <div
            *ngIf="this.characteristicsForm.controls.comparableSurfaceActivated.value"
            class="comparable-surface-list"
          >
            <app-comparable-surface
              [(periodList)]="this.editedStore.comparablePeriods"
              (submitEvent)="submitEvent.emit({ source: 'comparablePeriods', object: $event })"
            ></app-comparable-surface>
          </div>
        </div>

        <div class="renovation">
          <app-renovation
            [(renovationList)]="this.editedStore.renovations"
            (submitEvent)="submitEvent.emit({ source: 'renovations', object: $event })"
          ></app-renovation>
        </div>
      </div>

      <div class="grid-item specification-comment">
        <ui-textarea
          label="{{ 'characteristics.specifications-comment' | translate }}"
          formControlName="specificationComment"
          height="169px"
          maxLength="1024"
        >
        </ui-textarea>
      </div>

      <div class="grid-item showcase-comment">
        <ui-textarea
          label="{{ 'characteristics.showcase-comment' | translate }}"
          formControlName="showcaseComment"
          height="169px"
          maxLength="1024"
        >
        </ui-textarea>
      </div>

      <div class="grid-item photo-general">
        <ui-input-file
          [id]="'file'"
          buttonText="{{ 'global.input-file.btn' | translate }}"
          descriptionText="{{ 'global.input-file.drag-drop' | translate }}"
          accept=".png, .jpeg, .jpg, .JPG, .JPEG, .PNG"
          label="{{ 'characteristics.label' | translate }}"
          formControlName="photoFile"
        >
        </ui-input-file>
      </div>
    </div>

    <app-stock-priorization
      *ngIf="stockPopupVisible"
      (close)="closeStockPopup()"
      [rootLocationOptions]="stockOptions"
      (validate)="validateStockPopup($event)"
      [rootLocationIds]="characteristicsForm.value.stockLocations"
    >
    </app-stock-priorization>
  </form>
</ng-container>
