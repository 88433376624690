<div class="main-container">
  <label class="list-label ng-star-inserted" style="padding-bottom: 4px">
    <b>{{ "comparable-surface.title" | translate }}</b>
  </label>
  <div class="comparable-surfaces-table">
    <div class="top-table-buttons">
      <ui-button text="{{ 'comparable-surface.datatable.add-btn-text' | translate }}" type="add" (click)="addPeriod()">
      </ui-button>
    </div>
    <ngx-datatable
      #table
      [rows]="rows"
      [limit]="7"
      [trackByProp]="'index'"
      class="material"
      columnMode="flex"
      headerHeight="auto"
      footerHeight="auto"
      rowHeight="40"
      [messages]="{ emptyMessage: 'global.no-data' | translate }"
      [cssClasses]="{
        sortDescending: 'fas fa-chevron-down',
        sortAscending: 'fas fa-chevron-up'
      }"
      [sorts]="[{ prop: 'date', dir: 'asc' }]"
      (activate)="updatePeriod($event)"
    >
      <ngx-datatable-column
        name="{{ 'comparable-surface.datatable.columns.beginDate' | translate }}"
        cellClass="top-align"
        [flexGrow]="0.55"
        prop="beginDate"
        [resizeable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row">
          <span>{{ row.beginDate }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'comparable-surface.datatable.columns.endDate' | translate }}"
        cellClass="top-align"
        [flexGrow]="0.55"
        prop="endDate"
        [resizeable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row">
          <span>{{ row.endDate }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'comparable-surface.datatable.columns.comment' | translate }}"
        cellClass="top-align"
        [flexGrow]="0.8"
        prop="comment"
        [resizeable]="false"
      >
        <ng-template ngx-datatable-cell-template let-comment="row.comment">
          <span [class]="'ellipsis'" [title]="comment">{{ comment }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'comparable-surface.datatable.columns.actions' | translate }}"
        cellClass="center-align icon-cell"
        [flexGrow]="0.25"
        [resizeable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="green-zone" (click)="$event.stopPropagation()">
            <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)"></ui-menu-actions>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
          let-isVisible="isVisible"
        >
          <datatable-pager
            [pagerLeftArrowIcon]="'fas fa-chevron-left'"
            [pagerRightArrowIcon]="'fas fa-chevron-right'"
            [pagerNextIcon]="'fas fa-chevron-double-right'"
            [pagerPreviousIcon]="'fas fa-chevron-double-left'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!(rowCount / pageSize > 1)"
            (change)="table.onFooterPage($event)"
          >
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>

  <form class="comparable-surface-form" [formGroup]="periodForm" novalidate (ngSubmit)="submitPeriod()">
    <div class="popup-container">
      <ui-popup title="{{ popupTitle | translate }}" *ngIf="popupVisible" (close)="canClose()">
        <div content>
          <div class="grid-container">
            <div class="grid-item begin-date">
              <ui-date-picker
                mandatory="true"
                [locale]="locale"
                [format]="dateFormat"
                [mode]="'month'"
                showMonthName="true"
                formControlName="beginDate"
                label="{{ 'comparable-surface.datatable.columns.beginDate' | translate }}"
                [status]="periodForm.get('beginDate') | formStatus"
                [errorMessage]="periodForm.get('beginDate') | formErrorMessage | translate"
              >
              </ui-date-picker>
            </div>

            <div class="grid-item end-date">
              <ui-date-picker
                mandatory="true"
                [locale]="locale"
                [format]="dateFormat"
                [mode]="'month'"
                showMonthName="true"
                formControlName="endDate"
                label="{{ 'comparable-surface.datatable.columns.endDate' | translate }}"
                [status]="periodForm.get('endDate') | formStatus"
                [errorMessage]="
                  periodForm.get('endDate')
                    | formErrorMessage
                      : { customPrefix: 'store.errors.characteristics.', useCustomPrefixFor: ['badEndDate'] }
                    | translate
                "
              >
              </ui-date-picker>
            </div>
          </div>
          <ui-textarea
            label="{{ 'comparable-surface.datatable.columns.comment' | translate }}"
            formControlName="comment"
          >
          </ui-textarea>
        </div>
        <div footer>
          <div class="align-buttons">
            <div class="button-popup-add">
              <ui-button text="{{ 'global.add-update' | translate }}" [primary]="true" type="add"></ui-button>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </form>
</div>
