import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import {
  NetworkLicenseService,
  CaraUserService,
  CaraUser,
  License,
  StoreLink,
  AsynchronousTaskService,
  AsynchronousTaskCreation,
} from "center-services";
import {
  Filter,
  Filterer,
  FilterValue,
  Option,
  PaginableComponent,
  SearchFilter,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService, ToastMessageService } from "fugu-components";
import { ColumnDefinition, FilteredTableListComponent } from "generic-pages";

class CaraUsersRow {
  id: number;
  mail: string;
  login: string;
  lastName: string;
  firstName: string;
  userNumber: string;
  profile: string;
  createdAt: Date;
  licenseType: string;
  activated: boolean;
}

@Component({
  selector: "app-cara-users-list",
  templateUrl: "./cara-users-list.component.html",
  styleUrls: ["./cara-users-list.component.scss"],
  providers: [SubscriptionService],
})
export class CaraUsersListComponent extends FilteredTableListComponent implements OnInit, PaginableComponent {
  public static LIST_ID: string = "app-cara-users-list.cara-users-table";

  @ViewChild("table") table: DatatableComponent;
  @ViewChild("tableUsers") tableUsers: any;

  public activeFilters: SearchFilter[] = [];
  public allUsersRows: CaraUsersRow[] = [];
  public usersRows: CaraUsersRow[] = [];
  public userStoreMap: Map<number, string[]> = new Map();
  public columns: ColumnDefinition[];
  public errorMessage: string;
  public controls: any = {};
  public licenceTypes: string[] = [];
  public usersList: CaraUser[] = [];
  public profiles: string[] = [];
  public licensesRows: any[] = [];
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "lastName",
      dir: "asc",
    },
  ];
  public stores: string[] = [];
  public dateFormat: string;
  public filters: Filter[];
  public filterValues: FilterValue[] = [];
  public tableControl: UntypedFormGroup;
  public storesOptions: Option[] = [];
  public filterer: Filterer;
  public pageNumber: number = 0;
  private sessionPagination: SessionPagination;

  constructor(
    private notificationHandlerService: NotificationHandlerService,
    private licenseService: NetworkLicenseService,
    translateService: TranslateService,
    messageService: MessageService,
    userService: CaraUserService,
    private router: Router,
    private asynchronousTaskService: AsynchronousTaskService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pageNumber;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pageNumber = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
    this.applyFilters();
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(CaraUsersListComponent.LIST_ID);
  }

  ngOnInit(): void {
    this.tableControl = new UntypedFormGroup({});
    this.fetchLicenseList();
    this.fetchUserList();

    this.setColumnDefinitions();

    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.dateFormat = user.dateFormat;
      })
    );
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }
    this.usersRows = [...this.usersRows];
    this.tableUsers.sorts = this.sorts;
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  fetchLicenseList(): void {
    this.subscriptionService.subs.push(
      this.licenseService.getLicensesUsage().subscribe((license: License) => {
        this.licensesRows = [
          {
            license_type: "admin",
            used: license.usedAdmin,
            owned: license.totalAdmin,
          },
          {
            license_type: "workshop",
            used: license.usedWorkshop,
            owned: license.totalWorkshop,
          },
        ];
      })
    );
  }

  fetchUserList(): void {
    this.allUsersRows = [];
    this.usersRows = [];

    this.subscriptionService.subs.push(
      this.userService.getAll().subscribe(
        (users: any[]) => {
          this.usersList = users;
          users.forEach((user: CaraUser) => {
            user.stores
              .filter(storeLink => !storeLink.archived)
              .forEach((storeLink: StoreLink) => {
                if (this.stores.indexOf(storeLink.storeName) < 0) {
                  this.stores.push(storeLink.storeName);
                  this.stores = [...this.stores];
                }
              });
            this.allUsersRows.push(this.userToRow(user));
          });
          this.usersRows = [...this.allUsersRows];
          this.initFilters();
          if (this.filterer.filterValues.length > 0) {
            this.applyFilters();
          }
          this.sessionPagination.loadFromSession(CaraUsersListComponent.LIST_ID);
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(error.message, { title });
        }
      )
    );
  }

  createUser(): void {
    this.savePaginationToSession();
    this.router.navigateByUrl("/settings/user/add");
  }

  updateUser(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("USER_UPDATE")) {
        return;
      }
      this.savePaginationToSession();
      this.router.navigateByUrl(`/settings/user/update/${event.row.id}`);
    }
  }

  activeCheckboxOnChanges(id: number): void {
    if (!this.userService.canDo("USER_ARCHIVE")) {
      return;
    }

    if (this.tableControl.controls[`activated_${id}`].value) {
      // unarchive
      this.subscriptionService.subs.push(
        this.userService.unarchive(id).subscribe(
          () => {
            this.fetchUserList();
          },
          () => {
            const title = this.translateService.instant("message.title.archive-errors");
            const content = this.translateService.instant("cara-users-list.errors.unarchive");
            this.messageService.warn(content, { title });
          }
        )
      );
    } else {
      // archive
      this.subscriptionService.subs.push(
        this.userService.archive(id).subscribe(
          () => {
            this.fetchUserList();
          },
          () => {
            const title = this.translateService.instant("message.title.archive-errors");
            const content = this.translateService.instant("cara-users-list.errors.archive");
            this.messageService.warn(content, { title });
          }
        )
      );
    }
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }

    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === CaraUsersListComponent.LIST_ID
    );

    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addBooleanFilter(
      "activated",
      this.translateService.instant("cara-users-list.datatable.columns.activated"),
      false,
      false,
      true
    );

    this.filterer.addFilter(
      "firstName",
      this.translateService.instant("users-list.datatable.columns.firstname"),
      "string"
    );

    this.filterer.addFilter(
      "userNumber",
      this.translateService.instant("users-list.datatable.columns.usernumber"),
      "string"
    );

    this.filterer.addFilter(
      "createdAt",
      this.translateService.instant("users-list.datatable.columns.creation_date"),
      "date"
    );

    this.filterer.addFilter("lastName", this.translateService.instant("users-list.datatable.columns.name"), "string");

    this.filterer.addFilter("login", this.translateService.instant("users-list.datatable.columns.login"), "string");

    this.filterer.addFilter("mail", this.translateService.instant("users-list.datatable.columns.mail"), "string");
    this.initLicenseTypeFilter();

    this.profiles = [...new Set(this.usersList.map(user => user.profileName))];
    this.filterer.addListFilter(
      "profile",
      this.translateService.instant("users-list.datatable.columns.profile"),
      this.profiles.map(user => {
        return { value: user, displayValue: user };
      })
    );

    this.filterer.addListFilter(
      "stores",
      this.translateService.instant("cara-users-list.datatable.columns.stores"),
      this.stores
        .map(store => {
          return { value: store, displayValue: store };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      false,
      false,
      [],
      null,
      true,
      false
    );
  }

  initLicenseTypeFilter(): void {
    this.licenceTypes = [
      ...new Set(
        this.usersList.map(user =>
          !user.adminLicense
            ? "users-list.datatable.columns.license_type.workshop"
            : "users-list.datatable.columns.license_type.admin"
        )
      ),
    ];
    this.filterer.addListFilter(
      "licenseType",
      this.translateService.instant("users-list.datatable.columns.license_type.title"),
      this.licenceTypes.map(licence => {
        return { value: licence, displayValue: this.translateService.instant(licence) };
      })
    );
  }

  applyFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
    this.usersRows = this.filterer.filterList(this.allUsersRows);

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        CaraUsersListComponent.LIST_ID
      ).subscribe()
    );
  }

  exportUsers(): void {
    if (!this.userService.canDo("USER_READ")) {
      return;
    }
    const asyncCreationTask = {
      type: "exportXLS",
      params: `user;${this.activeFilters?.map(filter => filter.toQuery()).join(",")};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  importUsers(): void {
    if (!this.userService.canDo("USER_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/users-list/import");
  }

  protected setColumnDefinitions(): void {
    this.columns = [
      new ColumnDefinition({
        name: "users-list.datatable.columns.name",
        propName: "lastName",
        flexGrow: 0.8,
        displayType: "text",
      }),
      new ColumnDefinition({
        name: "users-list.datatable.columns.firstname",
        propName: "firstName",
        flexGrow: 1,
        displayType: "text",
      }),
      new ColumnDefinition({
        name: "users-list.datatable.columns.usernumber",
        propName: "userNumber",
        flexGrow: 1,
        displayType: "text",
      }),
      new ColumnDefinition({
        name: "users-list.datatable.columns.login",
        propName: "login",
        flexGrow: 1,
        displayType: "text",
      }),
      new ColumnDefinition({
        name: "users-list.datatable.columns.mail",
        propName: "mail",
        flexGrow: 1.8,
        displayType: "text",
      }),
      new ColumnDefinition({
        name: "users-list.datatable.columns.profile",
        propName: "profile",
        flexGrow: 1,
        displayType: "text",
      }),
      new ColumnDefinition({
        name: "users-list.datatable.columns.license_type.title",
        propName: "licenseType",
        flexGrow: 1.2,
        displayType: "textToTranslate",
      }),
      new ColumnDefinition({
        name: "users-list.datatable.columns.creation_date",
        propName: "createdAt",
        flexGrow: 1.4,
        displayType: "date",
      }),
    ];
  }

  protected userToRow(user: CaraUser): any {
    if (this.tableControl.controls[`activated_${user.id}`]) {
      this.tableControl.controls[`activated_${user.id}`].patchValue(!user.archived);
    } else {
      this.tableControl.addControl(
        `activated_${user.id}`,
        new UntypedFormControl({
          value: !user.archived,
          disabled: !this.userService.canDo("USER_ARCHIVE"),
        })
      );
    }

    return {
      id: user.id,
      mail: user.email,
      login: user.login,
      lastName: user.lastName,
      activated: !user.archived,
      firstName: user.firstName,
      userNumber: user.userNumber,
      profile: user.profileName,
      createdAt: user.createdAt,
      licenseType: !user.adminLicense
        ? "users-list.datatable.columns.license_type.workshop"
        : "users-list.datatable.columns.license_type.admin",
      stores: user.stores.filter(storeLink => !storeLink.archived).map(storeLink => storeLink.storeName),
    };
  }
}
