import { UntypedFormControl, Validators, UntypedFormGroup } from "@angular/forms";
import { MessageService } from "fugu-components";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  IconDefinition,
  faArrowsRotate,
  faCircleMinus,
  faCirclePlus,
  faHandHoldingBox,
  faInfoCircle,
  faTags,
  faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import { ErrorUtil } from "generic-pages";
import { CommonValidatorsUtil, Option, SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { ItemOutput } from "./item-output";
import {
  ReceivingForm,
  AbstractItem,
  PurchaseOrderLine,
  Uom,
  RetailItemService,
  ReceivingFormService,
  CreationDeliveryType,
  PaginatedList,
  Pagination,
  PurchaseModality,
  ReceivingFreeLine,
  ReceivingPOLine,
  StandardItem,
  AbstractReceivingLine,
  UpdatedReceivingLine,
  UpdatedReceivingForm,
} from "center-services";

@Component({
  selector: "app-new-delivery-form-update-receive-popup",
  templateUrl: "./new-delivery-form-update-receive-popup.component.html",
  styleUrls: ["./new-delivery-form-update-receive-popup.component.scss"],
  providers: [SubscriptionService],
})
export class NewDeliveryFormUpdateReceivePopupComponent implements OnInit {
  @Input() updatedReceivingForm: ReceivingForm;
  @Input() editedReceivingForm: ReceivingForm;
  @Input() unsavedReceivingForm: ReceivingForm;
  @Input() retailItemList: AbstractItem[];
  @Input() poLines: PurchaseOrderLine[];
  @Input() purchaseUnitList: Uom[];
  @Input() locale: string;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() updateReceive: EventEmitter<any> = new EventEmitter();

  public addedItems: ItemOutput[];
  public unreceivedItems: ItemOutput[];
  public unreceivedSNItems: ItemOutput[];
  public modifiedItems: ItemOutput[];

  public faWarn: IconDefinition = faWarning;
  public faTags: IconDefinition = faTags;
  public faHandHoldingBox: IconDefinition = faHandHoldingBox;
  public faArrowsRotate: IconDefinition = faArrowsRotate;
  public faCirclePlus: IconDefinition = faCirclePlus;
  public faCircleMinus: IconDefinition = faCircleMinus;
  public faInfoCircle: IconDefinition = faInfoCircle;

  public snOptions: any = {};
  public form: UntypedFormGroup;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private retailItemService: RetailItemService,
    private receivingFormService: ReceivingFormService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({});
    if (this.updatedReceivingForm.creationType === CreationDeliveryType.MANUAL) {
      const pmIdSet = new Set<number>();
      this.poLines.forEach((line: PurchaseOrderLine) => {
        pmIdSet.add(line.purchaseModalityId);
      });
      this.subscriptionService.subs.push(
        this.fetchItems(pmIdSet).subscribe(() => {
          this.checkDifferences();
        })
      );
    } else {
      this.checkDifferences();
    }
  }

  fetchItems(pmIdSet: Set<number>): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: pmIdSet.size,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, [...pmIdSet]);

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          result.data.forEach(item => {
            if (!this.retailItemList.includes(item)) {
              this.retailItemList.push(item);
            }
          });
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  checkDifferences(): void {
    this.addedItems = [];
    this.unreceivedItems = [];
    this.unreceivedSNItems = [];
    this.modifiedItems = [];
    this.updatedReceivingForm.lines.forEach(updatedDeliveryLine => {
      const editedDeliveryLine = this.editedReceivingForm.lines.find(line => line.id === updatedDeliveryLine.id);

      let pol: PurchaseOrderLine = null;
      let retailItem: AbstractItem = null;
      let pm: PurchaseModality = null;

      switch (updatedDeliveryLine.type) {
        case "ReceivingFreeLine":
          retailItem = this.getItem((updatedDeliveryLine as ReceivingFreeLine).purchaseModalityId);
          pm = this.getPurchaseModality((updatedDeliveryLine as ReceivingFreeLine).purchaseModalityId, retailItem);
          break;
        case "ReceivingPOLine":
          pol = this.poLines.find(
            (purchaseOrderLine: PurchaseOrderLine) =>
              purchaseOrderLine.id === (updatedDeliveryLine as ReceivingPOLine).purchaseOrderLineId
          );
          retailItem = this.getItem(pol.purchaseModalityId);
          break;
        default:
          break;
      }

      const item = new ItemOutput({
        id: updatedDeliveryLine.id,
        diff: Math.abs(updatedDeliveryLine.receivedQuantity - editedDeliveryLine.receivedQuantity),
        supplierRef: pol ? pol.supplierRef : pm.supplierRef,
        itemReference: retailItem.reference,
        uomName: pol
          ? this.purchaseUnitList.find((unit: Uom) => unit.id === pol.purchaseUnitId)?.longName
          : this.purchaseUnitList.find((unit: Uom) => unit.id === pm.purchaseUnitId)?.longName,
        itemName: retailItem.name,
        sizeValue: updatedDeliveryLine.receivedSizeValue,
        stockType: retailItem instanceof StandardItem ? retailItem.stockType : null,
      });

      // more items received
      if (updatedDeliveryLine.receivedQuantity > editedDeliveryLine.receivedQuantity) {
        this.addedItems.push(item);
        if (
          this.hasModifications(updatedDeliveryLine, editedDeliveryLine) &&
          editedDeliveryLine.receivedQuantity !== 0
        ) {
          const modifiedItem = new ItemOutput({ ...item });
          modifiedItem.diff = editedDeliveryLine.receivedQuantity;
          this.modifiedItems.push(modifiedItem);
        }
      }

      // less item received
      if (updatedDeliveryLine.receivedQuantity < editedDeliveryLine.receivedQuantity) {
        item.stockType === "SERIAL_NUMBER" ? this.unreceivedSNItems.push(item) : this.unreceivedItems.push(item);
        if (this.hasModifications(updatedDeliveryLine, editedDeliveryLine)) {
          const modifiedItem = new ItemOutput({ ...item });
          modifiedItem.diff = updatedDeliveryLine.receivedQuantity;
          if (modifiedItem.diff > 0) {
            this.modifiedItems.push(modifiedItem);
          }
        }
      }

      // same number of items but different size value or prices
      if (
        updatedDeliveryLine.receivedQuantity === editedDeliveryLine.receivedQuantity &&
        this.hasModifications(updatedDeliveryLine, editedDeliveryLine)
      ) {
        item.diff = editedDeliveryLine.receivedQuantity;
        this.modifiedItems.push(item);
      }
    });
    this.unreceivedSNItems.forEach(item => this.buildChoiceList(item));
  }

  buildChoiceList(item: any): void {
    const lineFound = this.updatedReceivingForm.lines.find(line => line.id === item.id);

    const serialNumbers = lineFound.getGeneratedStockEntryIds();

    const numericSorter = new Intl.Collator(this.locale, { numeric: true });

    this.snOptions[item.id] = serialNumbers
      .map(obj => new Option(obj, obj.toString()))
      .sort((a, b) => numericSorter.compare(a.label, b.label));

    this.form.addControl(
      item.id.toString(),
      new UntypedFormControl(null, [Validators.required, CommonValidatorsUtil.lengthValidator(item.diff)])
    );
  }

  hasModifications(updatedDeliveryLine: AbstractReceivingLine, editedDeliveryLine: AbstractReceivingLine): boolean {
    let checkDiscount = false;
    if (
      (updatedDeliveryLine instanceof ReceivingFreeLine || updatedDeliveryLine instanceof ReceivingPOLine) &&
      (editedDeliveryLine instanceof ReceivingFreeLine || editedDeliveryLine instanceof ReceivingPOLine)
    ) {
      checkDiscount =
        updatedDeliveryLine.discountType !== editedDeliveryLine.discountType ||
        updatedDeliveryLine.discount !== editedDeliveryLine.discount;
    }

    return (
      updatedDeliveryLine.receivedSizeValue !== editedDeliveryLine.receivedSizeValue ||
      updatedDeliveryLine.unitPricePerWeight !== editedDeliveryLine.unitPricePerWeight ||
      updatedDeliveryLine.metalPrice !== editedDeliveryLine.metalPrice ||
      updatedDeliveryLine.unitPrice !== editedDeliveryLine.unitPrice ||
      checkDiscount
    );
  }

  submitPopup(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const updatedReceivingLines: UpdatedReceivingLine[] = [];
    this.updatedReceivingForm.lines.forEach((line: AbstractReceivingLine) => {
      const editedLine = this.editedReceivingForm.lines.find(obj => obj.id === line.id);
      if (!editedLine.equals(line)) {
        const updatedReceivingLine = new UpdatedReceivingLine({
          id: line.id,
          quantity: line.receivedQuantity,
          sizeValue: line.receivedSizeValue,
          unitPrice: line.unitPrice,
          unitPricePerWeight: line.unitPricePerWeight,
          metalPrice: line.metalPrice,
          stockEntriesToMoveOut: this.form?.get(line.id.toString())?.value,
        });
        if (line instanceof ReceivingFreeLine || line instanceof ReceivingPOLine) {
          updatedReceivingLine.discountType = line.discountType;
          updatedReceivingLine.discount = line.discount;
        }
        updatedReceivingLines.push(updatedReceivingLine);
      }
    });

    const updatedReceivingForm = new UpdatedReceivingForm({
      id: this.updatedReceivingForm.id,
      lines: updatedReceivingLines,
    });

    this.subscriptionService.subs.push(
      this.receivingFormService.updateReceive(updatedReceivingForm).subscribe(
        () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          this.updateReceive.emit();
        },
        error => {
          this.handleApiError(error);
        }
      )
    );
  }

  handleApiError(error: any): void {
    const title = this.translateService.instant("message.title.form-errors");

    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  closePopup(): void {
    this.close.emit();
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }

  private getItem(pmId: number): AbstractItem {
    for (const item of this.retailItemList) {
      if (pmId) {
        for (const pm of item.purchaseModalities) {
          if (pm.id === pmId) {
            return item;
          }
        }
      }
    }
    return null;
  }

  private getPurchaseModality(pmId: number, item: AbstractItem): PurchaseModality {
    return item.purchaseModalities.find(pm => pm.id === pmId);
  }
}
