import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PurchaseModalityService } from "app/service/purchase-modality.service";
import {
  AlloyComposition,
  PurchaseModality,
  CategoryType,
  Supplier,
  Light,
  Currency,
  Uom,
  PurchaseType,
  SupplierService,
  CurrencyService,
  UomService,
  LightService,
  CaraUserService,
  CaraUser,
} from "center-services";
import { CommonValidatorsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";
import { Observable, combineLatest, merge, of } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-retail-item-purchase-modalities-popup",
  templateUrl: "./retail-item-purchase-modalities-popup.component.html",
  styleUrls: ["./retail-item-purchase-modalities-popup.component.scss"],
  providers: [SubscriptionService],
})
export class RetailItemPurchaseModalitiesPopupComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() itemComposition: AlloyComposition[];
  @Input() purchaseModality: PurchaseModality;
  @Input() itemTheoreticalWeight: number;
  @Input() itemWeight: number;
  @Input() mainUnitId: number;
  @Input() itemCategoryType: CategoryType;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public initialPurchaseModality: PurchaseModality;
  public unsavedPurchaseModality: PurchaseModality;
  public editedPurchaseModality: PurchaseModality;
  public popupForm: UntypedFormGroup;

  public shouldClose: boolean = false;

  public cacheSuppliers: Supplier[] = [];
  public lightSuppliers: Light[];
  public suppliersOptions: Option[] = [];
  suppliersListStatus: string;
  public currencies: Currency[];
  public currenciesOptions: Option[] = [];
  currencyListStatus: string;
  public uoms: Uom[];
  public uomsOptions: Option[] = [];
  uomsListStatus: string;
  public purchaseType: PurchaseType;
  public purchaseTypeOptions: Option[] = [];
  public popupTitle: string;
  public codeLanguage: string;
  public totalPrice: number = 0;
  public defaultCurrency: Currency;
  public mainUnit: Uom;
  private selectedSupplier: Supplier;
  private initObservables: Observable<any>[];

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private supplierService: SupplierService,
    private currencyService: CurrencyService,
    private uomService: UomService,
    private translateService: TranslateService,
    private purchaseModalityService: PurchaseModalityService,
    private messageService: MessageService,
    private lightService: LightService,
    private userService: CaraUserService,
    private subscriptionService: SubscriptionService
  ) {}

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    this.defaultCurrency = this.currencyService.defaultCurrency.value;

    this.prepareForm();
    if (!this.purchaseModality) {
      this.createPurchaseModality();
      this.popupTitle = this.translateService.instant("purchase-modalities-popup.title.new");
    } else {
      this.editedPurchaseModality = new PurchaseModality(this.purchaseModality);
      this.popupTitle = this.translateService.instant("purchase-modalities-popup.title.update");
    }

    this.initialPurchaseModality = new PurchaseModality(this.editedPurchaseModality);
  }

  ngOnInit(): void {
    this.initObservables = [];
    this.initObservables.push(this.fetchCodeLanguage());
    this.initObservables.push(this.fetchCurrencies());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchUnitOfMeasure());

    this.subscriptionService.subs.push(combineLatest(this.initObservables).subscribe(this.initializePopup.bind(this)));

    this.buildPurchaseTypeOptions();
  }

  fetchCodeLanguage(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.codeLanguage = connectedUser.codeLanguage;
      })
    );
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencies = currencies;
          currencies.forEach((currency: Currency) => {
            if (currency.byDefault && !this.editedPurchaseModality.currencyId) {
              this.editedPurchaseModality.currencyId = currency.id;
              this.initialPurchaseModality.currencyId = currency.id;
              this.popupForm.controls.currencyId.setValue(currency.id);
            }
          });
          this.currenciesOptions = currencies
            .filter((obj: Currency) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Currency) => new Option(obj.id, obj.symbol));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("purchase-modalities-popup.errors.get-currencies");
          this.messageService.warn(content, { title });
          this.currencyListStatus = "error";
        }
      )
    );
  }

  fetchSupplier(supplierId: number): Observable<Supplier> {
    if (supplierId === null) {
      this.selectedSupplier = null;
      return of(null);
    }
    const foundSupplier = this.cacheSuppliers.find(supplier => supplier.id === supplierId);
    if (foundSupplier) {
      this.selectedSupplier = foundSupplier;
      return of(foundSupplier);
    }
    return this.supplierService.get(supplierId).pipe(
      tap(
        (supplier: Supplier) => {
          this.cacheSuppliers.push(supplier);
          this.selectedSupplier = supplier;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("purchase-modalities-popup.errors.get-supplier");
          this.messageService.warn(content, { title });
          this.currencyListStatus = "error";
        }
      )
    );
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (suppliers: Light[]) => {
          this.lightSuppliers = suppliers;

          // no supplier selected option
          this.suppliersOptions.push(new Option(null, "-"));

          suppliers
            .filter((supplier: Light) => !supplier.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach((supplier: Light) => {
              this.suppliersOptions.push(new Option(supplier.id, supplier.name));
            });
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("purchase-modalities-popup.errors.get-suppliers");
          this.messageService.warn(content, { title });
          this.currencyListStatus = "error";
        }
      )
    );
  }

  fetchUnitOfMeasure(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.uoms = uoms;

          // no uom selected option
          this.uomsOptions.push(new Option(null, "-"));

          this.uomsOptions = uoms
            .filter((obj: Uom) => !obj.archived)
            .sort((a, b) => a.longName.localeCompare(b.longName))
            .map((obj: Uom) => new Option(obj.id, obj.longName));

          if (this.mainUnitId) {
            this.mainUnit = this.uoms.find((uom: Uom) => {
              return uom.id === this.mainUnitId;
            });
          }
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("purchase-modalities-popup.errors.get-uoms");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  prepareForm(): void {
    const digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);
    this.popupForm = this.fb.group({
      purchaseType: [0, [Validators.required]],
      supplierRef: [null],
      minQuantity: [null, [Validators.min(0), digitValidator]],
      maxQuantity: [null, [Validators.min(0), digitValidator]],
      currencyId: [this.defaultCurrency.id, [Validators.required]],
      supplierId: [null],
      purchaseUnitId: [null, [Validators.required]],
      conversionFactor: [null, [Validators.required, Validators.min(0), digitValidator]],
      unitPrice: [null, [Validators.required, digitValidator]],
      unitPriceCurrency: [null, [Validators.required, digitValidator]],
      unitPricePerWeight: [null, [digitValidator]],
      unitPricePerWeightCurrency: [null, [digitValidator]],
    });

    this.popupForm.setValidators(this.rangeValidator());

    // listen to currency changes
    this.subscriptionService.subs.push(
      this.popupForm.controls.currencyId.valueChanges.subscribe(() => {
        this.onCurrencyChange();
      })
    );

    // listen to unit price of selected currency changes
    this.subscriptionService.subs.push(
      this.popupForm.get("unitPrice").valueChanges.subscribe(() => {
        this.defaultToCurrency("unitPrice");
      })
    );
    this.subscriptionService.subs.push(
      this.popupForm.get("unitPriceCurrency").valueChanges.subscribe(() => {
        this.currencyToDefault("unitPrice");
      })
    );
    this.subscriptionService.subs.push(
      this.popupForm.get("unitPricePerWeight").valueChanges.subscribe(() => {
        this.defaultToCurrency("unitPricePerWeight");
      })
    );
    this.subscriptionService.subs.push(
      this.popupForm.get("unitPricePerWeightCurrency").valueChanges.subscribe(() => {
        this.currencyToDefault("unitPricePerWeight");
      })
    );

    // listen to suppliers changes
    this.subscriptionService.subs.push(
      this.popupForm.get("supplierId").valueChanges.subscribe(() => {
        this.onSupplierChange();
      })
    );

    // listen to purchase Units changes
    this.subscriptionService.subs.push(
      this.popupForm.get("purchaseUnitId").valueChanges.subscribe(() => {
        this.onPurchaseUnitChange();
      })
    );

    const controls = this.popupForm.controls;
    this.subscriptionService.subs.push(
      merge(
        controls.unitPrice.valueChanges,
        controls.unitPriceCurrency.valueChanges,
        controls.unitPricePerWeight.valueChanges,
        controls.unitPricePerWeightCurrency.valueChanges,
        controls.purchaseType.valueChanges,
        controls.currencyId.valueChanges
      ).subscribe(() => {
        this.applyModifications();
        this.computeTotalPrice();
      })
    );
  }

  rangeValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const from = group.controls.minQuantity;
      const to = group.controls.maxQuantity;
      const toErrorsSize = Object.keys({ ...to.errors }).length;

      if (from.value === null || to.value === null) {
        return;
      }

      if (Number(to.value) < Number(from.value)) {
        to.setErrors({ ...to.errors, badMax: true });
        return;
      }
      if (toErrorsSize > 0) {
        to.setErrors({ ...to.errors });
        return;
      }
      to.setErrors(null);
    };
  }

  createPurchaseModality(): void {
    this.editedPurchaseModality = new PurchaseModality({
      purchaseType: PurchaseType.BASIC,
      unitPricePerWeight: null,
      supplierRef: null,
      unitPrice: null,
      minQuantity: null,
      maxQuantity: null,
      currencyId: this.defaultCurrency.id,
      purchaseUnitId: null,
      uomId: null,
      conversionFactor: null,
      archived: false,
    });
  }

  // Manage the Forms Controls
  initializePopup(): void {
    this.popupForm.controls.supplierRef.setValue(this.initialPurchaseModality.supplierRef);
    this.popupForm.controls.minQuantity.setValue(this.initialPurchaseModality.minQuantity);
    this.popupForm.controls.maxQuantity.setValue(this.initialPurchaseModality.maxQuantity);
    this.popupForm.controls.purchaseUnitId.setValue(this.initialPurchaseModality.purchaseUnitId);
    this.popupForm.controls.conversionFactor.setValue(this.initialPurchaseModality.conversionFactor);

    // Doesn't emit event to prevent currency change on popup initialization
    this.popupForm.controls.supplierId.setValue(this.initialPurchaseModality.supplierId, { emitEvent: false });

    this.popupForm.controls.currencyId.setValue(this.initialPurchaseModality.currencyId);

    if (this.initialPurchaseModality.purchaseType) {
      const index = Object.keys(PurchaseType).indexOf(this.initialPurchaseModality.purchaseType);
      this.popupForm.controls.purchaseType.setValue(index);

      // Compute purchase modality price on initialize popup
      if (this.initialPurchaseModality.supplierId) {
        this.subscriptionService.subs.push(
          this.fetchSupplier(this.initialPurchaseModality.supplierId).subscribe(() => {
            this.computeTotalPrice();
          })
        );
      } else {
        this.computeTotalPrice();
      }

      if (this.initialPurchaseModality.currencyId === this.defaultCurrency.id) {
        this.popupForm.get("unitPrice").setValue(this.initialPurchaseModality.unitPrice);
      } else {
        this.popupForm.get("unitPriceCurrency").setValue(this.initialPurchaseModality.unitPrice);
        this.currencyToDefault("unitPrice");
      }

      if (this.initialPurchaseModality.currencyId === this.defaultCurrency.id) {
        this.popupForm.get("unitPricePerWeight").setValue(this.initialPurchaseModality.unitPricePerWeight);
      } else {
        this.popupForm.get("unitPricePerWeightCurrency").setValue(this.initialPurchaseModality.unitPricePerWeight);
        this.currencyToDefault("unitPricePerWeight");
      }
    }
  }

  onCurrencyChange(): void {
    if (this.isDefaultCurrency()) {
      this.popupForm.get("unitPriceCurrency").disable({ emitEvent: false });
      this.popupForm.get("unitPricePerWeightCurrency").disable({ emitEvent: false });
    } else {
      this.popupForm.get("unitPriceCurrency").enable({ emitEvent: false });
      this.popupForm.get("unitPricePerWeightCurrency").enable({ emitEvent: false });
      this.defaultToCurrency("unitPrice");
      this.defaultToCurrency("unitPricePerWeight");
    }
  }

  checkSelectedPurchaseType(type: PurchaseType): boolean {
    return this.popupForm.controls.purchaseType.value === Object.keys(PurchaseType).indexOf(type);
  }

  applyModifications(): void {
    this.editedPurchaseModality.purchaseType = Object.values(PurchaseType)[this.popupForm.controls.purchaseType.value];

    this.editedPurchaseModality.supplierRef = this.popupForm.controls.supplierRef.value;
    this.editedPurchaseModality.currencyId = this.popupForm.controls.currencyId.value;
    this.editedPurchaseModality.supplierId = this.popupForm.controls.supplierId.value;
    this.editedPurchaseModality.purchaseUnitId = this.popupForm.controls.purchaseUnitId.value;

    if (this.popupForm.controls.minQuantity.value) {
      this.editedPurchaseModality.minQuantity = this.popupForm.controls.minQuantity.value;
    } else {
      this.editedPurchaseModality.minQuantity = null;
    }

    if (this.popupForm.controls.maxQuantity.value) {
      this.editedPurchaseModality.maxQuantity = this.popupForm.controls.maxQuantity.value;
    } else {
      this.editedPurchaseModality.maxQuantity = null;
    }

    if (this.popupForm.controls.conversionFactor.value) {
      this.editedPurchaseModality.conversionFactor = this.popupForm.controls.conversionFactor.value;
    } else {
      this.editedPurchaseModality.conversionFactor = null;
    }

    let unitPrice = null;
    if (this.isDefaultCurrency()) {
      unitPrice = this.popupForm.get("unitPrice").value;
    } else {
      unitPrice = this.popupForm.get("unitPriceCurrency").value;
    }
    if (unitPrice === null || unitPrice === undefined || unitPrice === "") {
      this.editedPurchaseModality.unitPrice = null;
    } else {
      this.editedPurchaseModality.unitPrice = this.currencyService.roundCurrencyValue(
        unitPrice,
        this.getSelectedCurrency()
      );
    }

    let unitPricePerWeight = null;
    if (this.isDefaultCurrency()) {
      unitPricePerWeight = this.popupForm.get("unitPricePerWeight").value;
    } else {
      unitPricePerWeight = this.popupForm.get("unitPricePerWeightCurrency").value;
    }

    if (unitPricePerWeight === null || unitPricePerWeight === undefined || unitPricePerWeight === "") {
      this.editedPurchaseModality.unitPricePerWeight = null;
    } else {
      this.editedPurchaseModality.unitPricePerWeight = this.currencyService.roundCurrencyValue(
        unitPricePerWeight,
        this.getSelectedCurrency()
      );
    }
  }

  submitPurchaseModality(): void {
    this.applyModifications();

    // stop here if form is invalid
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }
    this.validate.emit(this.editedPurchaseModality);
  }

  buildPurchaseTypeOptions(): void {
    this.purchaseTypeOptions = [];
    this.purchaseTypeOptions.push(
      new Option(
        Object.keys(PurchaseType).indexOf(PurchaseType.BASIC),
        this.translateService.instant("purchase-modalities-popup.fields.purchase-type.BASIC")
      )
    );
    this.purchaseTypeOptions.push(
      new Option(
        Object.keys(PurchaseType).indexOf(PurchaseType.WITH_METAL_ACCOUNT),
        this.translateService.instant("purchase-modalities-popup.fields.purchase-type.WITH_METAL_ACCOUNT")
      )
    );
    this.purchaseTypeOptions.push(
      new Option(
        Object.keys(PurchaseType).indexOf(PurchaseType.WITH_METAL_PRICE),
        this.translateService.instant("purchase-modalities-popup.fields.purchase-type.WITH_METAL_PRICE")
      )
    );
  }

  // Close the popup
  closePopup(): void {
    this.applyModifications();

    if (this.unsavedPurchaseModality && !this.unsavedPurchaseModality.equals(this.editedPurchaseModality)) {
      this.shouldClose = false;
    }

    if (!this.initialPurchaseModality.equals(this.editedPurchaseModality) && !this.shouldClose) {
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedPurchaseModality = new PurchaseModality(this.editedPurchaseModality);
    } else {
      this.close.emit();
      this.unsavedPurchaseModality = null;
      this.shouldClose = false;
    }
  }

  getSelectedCurrency(): Currency {
    const currencyId = this.popupForm.get("currencyId").value;
    if (this.currencies && currencyId !== null) {
      return this.currencies.find((currency: Currency) => {
        return currency.id === currencyId;
      });
    }
    return null;
  }

  isDefaultCurrency(): boolean {
    const selectedCurrencyId = this.popupForm.get("currencyId").value;
    return selectedCurrencyId === this.defaultCurrency.id;
  }

  currencyConversion(value: any, sourceCurrencyId: number, destCurrencyId: number): number {
    if (value === null || value === "") {
      return null;
    }
    const convertedValue = this.currencyService.computeDeviseConversion(value, sourceCurrencyId, destCurrencyId);

    if (!this.currencies) {
      return convertedValue;
    }

    const destCurrency = this.currencies.find((currency: Currency) => {
      return currency.id === destCurrencyId;
    });
    if (destCurrency) {
      return this.currencyService.roundCurrencyValue(convertedValue, destCurrency);
    }
    return convertedValue;
  }

  defaultToCurrency(formPrefix: string): void {
    if (this.popupForm.get(`${formPrefix}Currency`).disabled) {
      return;
    }
    this.popupForm
      .get(`${formPrefix}Currency`)
      .setValue(
        this.currencyConversion(
          this.popupForm.get(formPrefix).value,
          this.defaultCurrency.id,
          this.popupForm.controls.currencyId.value
        ),
        { emitEvent: false }
      );
  }

  currencyToDefault(formPrefix: string): void {
    if (this.popupForm.get(`${formPrefix}Currency`).disabled) {
      return;
    }
    this.popupForm
      .get(formPrefix)
      .setValue(
        this.currencyConversion(
          this.popupForm.get(`${formPrefix}Currency`).value,
          this.popupForm.controls.currencyId.value,
          this.defaultCurrency.id
        ),
        { emitEvent: false }
      );
  }

  computeTotalPrice(): void {
    this.subscriptionService.subs.push(
      this.purchaseModalityService
        .getTheoreticalMetalWeight(
          this.itemTheoreticalWeight,
          this.itemComposition,
          this.selectedSupplier ? this.selectedSupplier.alloys : []
        )
        .subscribe(itemTheoreticalWeights => {
          this.totalPrice = this.purchaseModalityService.computeModalityPrice(
            this.editedPurchaseModality,
            this.itemTheoreticalWeight,
            itemTheoreticalWeights
          );
        })
    );
  }

  onSupplierChange(): void {
    this.subscriptionService.subs.push(
      this.fetchSupplier(this.popupForm.get("supplierId").value).subscribe(changedSupplier => {
        if (changedSupplier) {
          this.popupForm.get("currencyId").setValue(changedSupplier.currencyId);
        }
        this.computeTotalPrice();
      })
    );
  }

  onPurchaseUnitChange(): void {
    const changedPurchaseUnit = this.uoms.find((purchaseUnit: Uom) => {
      return purchaseUnit.id === this.popupForm.get("purchaseUnitId").value;
    });
    if (changedPurchaseUnit) {
      if (changedPurchaseUnit.id === this.mainUnitId) {
        this.popupForm.get("conversionFactor").setValue(1);
        this.popupForm.get("conversionFactor").disable();
      } else {
        this.popupForm.get("conversionFactor").enable();
        changedPurchaseUnit.conversions.find(conversion => {
          if (conversion.unitDestId === this.mainUnit.id) {
            this.popupForm.get("conversionFactor").setValue(conversion.factor);
          }
        });
      }
    }
  }
}
