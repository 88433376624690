<div [formGroup]="topFieldsFormGroup">
  <div [class]="'first-row'">
    <div [class]="'date begin-date margin-right'">
      <ui-date-picker
        [mandatory]="true"
        [locale]="locale"
        [format]="dateFormat"
        [formControlName]="'beginDate'"
        [status]="topFieldsFormGroup.get('beginDate') | formStatus"
        [errorMessage]="topFieldsFormGroup.get('beginDate') | formErrorMessage | translate"
        [label]="'inventory-entry.fields.begin-date' | translate"
      >
      </ui-date-picker>
    </div>

    <div [class]="'date end-date'">
      <ui-date-picker
        [mandatory]="true"
        [locale]="locale"
        [format]="dateFormat"
        [formControlName]="'endDate'"
        [status]="topFieldsFormGroup.get('endDate') | formStatus"
        [errorMessage]="
          topFieldsFormGroup.get('endDate')
            | formErrorMessage: { customPrefix: 'inventory-entry.errors.', useCustomPrefixFor: ['badEndDate'] }
            | translate
        "
        [label]="'inventory-entry.fields.end-date' | translate"
      >
      </ui-date-picker>
    </div>
  </div>

  <div [class]="'second-row'">
    <div [class]="'margin-right location'">
      <ui-list
        [label]="'inventory-entry.fields.location' | translate"
        [mandatory]="true"
        [options]="locationOptions"
        [search]="true"
        [status]="topFieldsFormGroup.get('location') | formStatus"
        [errorMessage]="topFieldsFormGroup.get('location') | formErrorMessage | translate"
        [formControlName]="'location'"
      ></ui-list>
    </div>

    <div class="margin-right">
      <ui-input-text
        [type]="'text'"
        [maskPattern]="decimalDigit"
        [label]="'inventory-entry.fields.quantity' | translate"
        [formControlName]="'quantity'"
        [status]="topFieldsFormGroup.get('quantity') | formStatus"
        [errorMessage]="
          topFieldsFormGroup.get('quantity')
            | formErrorMessage
              : { customPrefix: 'inventory-entry.errors.', useCustomPrefixFor: ['addSerialNumberStockEntry'] }
            | translate
        "
        [mandatory]="true"
      >
      </ui-input-text>
    </div>

    <div [class]="'margin-right unique-number'">
      <ui-input-text
        #stockEntryIdElement
        [mandatory]="true"
        [icon]="faScannerGun"
        [type]="'text'"
        [placeholder]="'inventory-entry.fields.unique-number-placeholder' | translate"
        [maskPattern]="decimalDigit"
        [label]="'inventory-entry.fields.unique-number' | translate"
        [formControlName]="'stockEntryId'"
        [status]="topFieldsFormGroup.get('stockEntryId') | formStatus"
        [errorMessage]="
          topFieldsFormGroup.get('stockEntryId')
            | formErrorMessage
              : {
                  customPrefix: 'inventory-entry.errors.',
                  useCustomPrefixFor: ['addUnknownReference', 'badStockEntryLocation', 'alreadyAddedInventoryEntry']
                }
            | translate
        "
        (keydown.enter)="createInventoryEntry()"
      >
      </ui-input-text>
    </div>

    <div [class]="'margin-right add-item-button'">
      <ui-button
        [type]="'add'"
        [text]="'inventory-entry.buttons.add-item' | translate"
        (click)="createInventoryEntry()"
        [disabled]="waitEntryCreation"
      ></ui-button>
    </div>
  </div>
</div>

<div [formGroup]="datatableFormGroup" class="inventory-entries-table">
  <div class="filters-field" *ngIf="filterer">
    <ui-dynamic-filters
      [filters]="filterer.getFilters()"
      [(filterValues)]="filterer.filterValues"
      (apply)="applyFilters()"
      dateFormat="{{ dateFormat }}"
      [label]="'filters.label' | translate"
      minLabel="{{ 'filters.min' | translate }}"
      maxLabel="{{ 'filters.max' | translate }}"
      applyLabel="{{ 'filters.apply' | translate }}"
      deleteLabel="{{ 'filters.delete' | translate }}"
      placeholderPrefix="{{ 'filters.prefix' | translate }} "
    >
    </ui-dynamic-filters>
  </div>

  <ngx-datatable
    #table
    [rows]="rows"
    [trackByProp]="'id'"
    [class]="'material'"
    [columnMode]="'force'"
    [headerHeight]="'auto'"
    [footerHeight]="50"
    [rowHeight]="40"
    [scrollbarH]="true"
    [rowClass]="getRowClass"
    [externalSorting]="true"
    [externalPaging]="true"
    [count]="pager.totalElements"
    [offset]="pager.number"
    [limit]="pager.size"
    [messages]="{ emptyMessage: 'global.no-data' | translate }"
    [sorts]="sorts"
    (sort)="changeSortSettings($event.column.prop, $event.newValue)"
    [cssClasses]="{
      sortAscending: 'fas fa-chevron-up',
      sortDescending: 'fas fa-chevron-down'
    }"
  >
    <ngx-datatable-column
      [name]="'inventory-entry.datatable.columns.item-category' | translate"
      [prop]="'itemCategory'"
      [cellClass]="'item-category'"
      [resizeable]="false"
      [draggable]="false"
      [width]="90"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="'inventory-entry.datatable.columns.item-reference' | translate"
      [prop]="'itemReference'"
      [cellClass]="'item-reference'"
      [resizeable]="false"
      [draggable]="false"
      [width]="100"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="'inventory-entry.datatable.columns.item-supplier-reference' | translate"
      [prop]="'itemSupplierRef'"
      [cellClass]="'item-supplier-reference'"
      [resizeable]="false"
      [draggable]="false"
      [width]="100"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="'inventory-entry.datatable.columns.item-name' | translate"
      [prop]="'itemName'"
      [cellClass]="'item-name'"
      [resizeable]="false"
      [draggable]="false"
      [width]="130"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="'inventory-entry.datatable.columns.serial-number' | translate"
      [prop]="'serialNumber'"
      [cellClass]="'serial-number'"
      [resizeable]="false"
      [width]="50"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="'inventory-entry.datatable.columns.batch-number' | translate"
      [prop]="'batchNumber'"
      [cellClass]="'batch-number'"
      [resizeable]="false"
      [draggable]="false"
      [width]="50"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="'inventory-entry.datatable.columns.quantity-inventory' | translate"
      [cellClass]="'quantity-inventory center-align'"
      [sortable]="false"
      [resizeable]="false"
      [draggable]="false"
      [width]="70"
    >
      <ng-template ngx-datatable-cell-template let-rowId="row.id">
        <ui-input-text
          *ngIf="datatableFormGroup.contains('quantity_' + rowId)"
          [type]="'text'"
          [maskPattern]="decimalDigit"
          [class]="'input-fields'"
          [formControlName]="'quantity_' + rowId"
          [sizeMode]="'small'"
          [status]="datatableFormGroup.get('quantity_' + rowId) | formStatus"
          [errorMessage]="
            datatableFormGroup.get('quantity_' + rowId)
              | formErrorMessage
                : { customPrefix: 'inventory-entry.errors.', useCustomPrefixFor: ['addSerialNumberStockEntry'] }
              | translate
          "
        >
        </ui-input-text>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="'inventory-entry.datatable.columns.supplier' | translate"
      [prop]="'supplier'"
      [cellClass]="'supplier'"
      [resizeable]="false"
      [draggable]="false"
      [width]="100"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="'inventory-entry.datatable.columns.location' | translate"
      [cellClass]="'location'"
      [sortable]="false"
      [resizeable]="false"
      [draggable]="false"
      [width]="120"
    >
      <ng-template ngx-datatable-cell-template let-rowId="row.id">
        <ui-list
          *ngIf="datatableFormGroup.contains('location_' + rowId)"
          [class]="'input-fields location-colum-width'"
          [options]="locationOptions"
          [formControlName]="'location_' + rowId"
          [sizeMode]="'small'"
          [status]="datatableFormGroup.get('location_' + rowId) | formStatus"
          [errorMessage]="datatableFormGroup.get('location_' + rowId) | formErrorMessage | translate"
        >
        </ui-list>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div [class]="'page-count'" *ngIf="rowCount">
          {{
            rowCount
              | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
              | translate: { rowCount: rowCount }
          }}
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'fas fa-chevron-left'"
          [pagerRightArrowIcon]="'fas fa-chevron-right'"
          [pagerNextIcon]="'fas fa-chevron-double-right'"
          [pagerPreviousIcon]="'fas fa-chevron-double-left'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="changePage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<div *ngIf="validationPopupVisible" [class]="'validation-popup-container'">
  <ui-popup [title]="'inventory-entry.validation-popup.title' | translate" (close)="closeValidationPopup()">
    <div content>
      <div class="warning warning-text">
        {{ "inventory-entry.validation-popup.warning-message" | translate }}
      </div>
      <div class="question">{{ "inventory-entry.validation-popup.question" | translate }}</div>
    </div>
    <div footer>
      <div [class]="'align-buttons'">
        <div [class]="'button-popup-validate'">
          <div [class]="'button'">
            <ui-button
              [text]="'inventory-entry.validation-popup.yes' | translate"
              [primary]="true"
              [type]="'validate'"
              (click)="validateSecondStage()"
            ></ui-button>
          </div>
        </div>
        <div [class]="'button-popup-cancel'">
          <div [class]="'button second-button'">
            <ui-button
              [text]="'inventory-entry.validation-popup.no' | translate"
              [type]="'cancel'"
              (click)="closeValidationPopup()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
