<form class="classification-form" [formGroup]="form" novalidate>
  <div *ngFor="let option of classificationOptions | keyvalue" class="grid-item">
    <ui-list
      formControlName="{{ option.key }}"
      (ngModelChange)="updateValue()"
      label="{{ option.key }}"
      [options]="option.value"
      [search]="true"
      [multiple]="false"
    >
    </ui-list>
  </div>
</form>
