import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { IconDefinition, faCheck, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  ItemPurchaseModality,
  Pagination,
  Currency,
  Light,
  ItemCategory,
  ItemPurchaseModalityService,
  ItemCategoryService,
  UserService,
  CaraUser,
  CurrencyService,
  LightService,
  ItemStatusType,
  PurchaseType,
  Sort,
  PaginatedList,
} from "center-services";
import {
  Filter,
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-purchase-modality-selection",
  templateUrl: "./purchase-modality-selection.component.html",
  styleUrls: ["./purchase-modality-selection.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseModalitySelectionComponent
  extends FilteredTableListComponent
  implements OnInit, AfterViewInit, PaginableComponent {
  public static LIST_ID: string = "app-purchase-modality-selection.purchase-modality-selection-table";

  @Output() selectedPurchaseModalities: EventEmitter<Map<number, number[]>> = new EventEmitter<Map<number, number[]>>();
  @Output() close: EventEmitter<any> = new EventEmitter();

  @ViewChild("table") table: DatatableComponent;

  @Input() currencyId: number;
  @Input() supplierId: number;

  public itemPurchaseModalityList: ItemPurchaseModality[] = [];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public activeFilters: SearchFilter[];
  public selectedElementsNumber: number = 0;
  public orderCurrency: Currency;
  public codeLanguage: string;
  public sorts: any[] = [
    {
      prop: "statusLabel",
      dir: "asc",
    },
    {
      prop: "supplierRef",
      dir: "asc",
    },
  ];
  public warnIcon: IconDefinition = faExclamationTriangle;
  public warningMessage: string;
  public warningTitle: string;
  public filters: Filter[];
  public faCheck: IconDefinition = faCheck;
  public rows: any[] = [];
  public form: UntypedFormGroup;
  public filterer: Filterer;
  private updatedSelectedMap: Map<number, number[]> = new Map();
  private initialSelectedMap: Map<number, number[]> = new Map();
  private selectedMap: Map<number, number[]> = new Map();
  private isLoaded: Subject<never> = new Subject();
  private initObservable: Observable<any>[];
  private currentRowsId: number[] = [];
  private lightSupplierList: Light[];
  private lightBrandList: Light[];
  private categoryList: ItemCategory[];
  private statusTranslations: { [key: string]: string } = {};
  private purchaseTypeTranslations: { [key: string]: string } = {};
  private defaultSupplier: Light;
  private sessionPagination: SessionPagination;

  constructor(
    private itemPurchaseModalityService: ItemPurchaseModalityService,
    private itemCategoryService: ItemCategoryService,
    protected translateService: TranslateService,
    protected userService: UserService<CaraUser>,
    private currencyService: CurrencyService,
    protected messageService: MessageService,
    private lightService: LightService,
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      ItemStatusType,
      "purchase-modality-selection.status-options",
      this.statusTranslations
    );

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      PurchaseType,
      "purchase-modality-selection.datatable.purchase-type",
      this.purchaseTypeTranslations
    );

    this.sessionPagination = new SessionPagination(this);
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(PurchaseModalitySelectionComponent.LIST_ID);
  }

  public ngOnInit(): void {
    this.warningTitle = "purchase-modality-selection.warning.title";
    this.initSelectFormControl();

    this.initObservable = [];
    this.initObservable.push(this.fetchBrands());
    this.initObservable.push(this.fetchSuppliers());
    this.initObservable.push(this.fetchCategories());
    this.initObservable.push(this.fetchCodeLanguage());
    this.initObservable.push(this.fetchOrderCurrency());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservable).subscribe(() => {
        this.updateWarningMessage();
        // this.setDefaultFilters(true);
        this.isLoaded.complete();
      })
    );
  }

  public ngAfterViewInit(): void {
    this.subscriptionService.subs.push(
      this.isLoaded.subscribe({
        complete: () => {
          this.initFilters();
          this.sessionPagination.loadFromSession(PurchaseModalitySelectionComponent.LIST_ID);
          this.computeSearchFilters();
          this.fetchItemPurchaseModalities();
        },
      })
    );
    this.cdRef.detectChanges();
  }

  public canClosePopup(): void {
    if (
      this.compareMaps(this.initialSelectedMap, this.selectedMap) ||
      this.compareMaps(this.selectedMap, this.updatedSelectedMap)
    ) {
      this.close.emit();
    } else {
      this.generateUnsavedPopinError();
    }
    this.updatedSelectedMap = new Map(this.selectedMap);
  }

  public changeSortSettings(prop: string, dir: string): void {
    switch (prop) {
      case "statusLabel":
        this.sorts = [
          {
            prop: "statusLabel",
            dir,
          },
        ];
        break;
      default:
        this.sorts = [
          {
            dir: "asc",
            prop: "statusLabel",
          },
          {
            prop,
            dir,
          },
        ];
        break;
    }
    this.fetchItemPurchaseModalities();
  }

  public applyFilters(): void {
    this.updateWarningMessage();
    // this.setDefaultFilters(false);

    this.activeFilters = [];
    // reset current page
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        PurchaseModalitySelectionComponent.LIST_ID
      ).subscribe(() => {
        this.fetchItemPurchaseModalities();
      })
    );
  }

  public getRowControlName(id: number): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  public getLineClass(supplierName: string): boolean {
    if (supplierName !== this.defaultSupplier.name) {
      return false;
    }
    return this.defaultSupplier.archived;
  }

  public getStatusClass(status: ItemStatusType): string {
    return `status-${status.toLowerCase()}`;
  }

  public submitSelection(): void {
    this.selectedPurchaseModalities.emit(this.selectedMap);
    this.close.emit();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchItemPurchaseModalities();
  }

  private getSorter(): Sort[] {
    const sorter: Sort[] = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  private fetchBrands(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Light[]) => {
          this.lightBrandList = brands
            .filter((light: Light) => !light.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("purchase-modality-selection.errors.get-brands");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchCategories(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (categories: ItemCategory[]) => {
          this.categoryList = categories
            .filter((obj: ItemCategory) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("purchase-modality-selection.errors.get-categories");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchCodeLanguage(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.codeLanguage = connectedUser.codeLanguage;
      })
    );
  }

  private fetchOrderCurrency(): Observable<Currency> {
    return this.currencyService.get(this.currencyId).pipe(
      tap(
        (currency: Currency) => {
          this.orderCurrency = currency;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("purchase-modality-selection.errors.get-order-currency");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.lightSupplierList = lightSuppliers.filter((light: Light) => {
            if (light.id === this.supplierId) {
              this.defaultSupplier = light;
            }
            return !light.archived;
          });
          if (this.defaultSupplier.archived) {
            this.lightSupplierList.push(this.defaultSupplier);
          }

          this.lightSupplierList.sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("purchase-modality-selection.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchItemPurchaseModalities(): void {
    this.subscriptionService.subs.push(
      this.itemPurchaseModalityService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<ItemPurchaseModality>) => {
          this.rows = [];
          this.pager = result.page;
          this.itemPurchaseModalityList = result.data;

          this.currentRowsId = result.data.map((itemPM: ItemPurchaseModality) => itemPM.purchaseModalityId);
          result.data.forEach((itemPM: ItemPurchaseModality) => {
            this.addRow(itemPM);
          });
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant(
            "purchase-modality-selection.errors.get-item-purchase-modalities"
          );
          this.messageService.warn(content, { title });
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;

          this.updateSelection();
          this.updateHeaderPageCheckbox();
        }
      )
    );
  }

  private initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === PurchaseModalitySelectionComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addListFilter(
      "supplier.id",
      this.translateService.instant("purchase-modality-selection.datatable.columns.supplier"),
      this.lightSupplierList.map(supplier => {
        return { value: supplier.id.toString(), displayValue: supplier.name };
      }),
      true,
      false,
      [this.supplierId.toString()],
      null,
      true
    );

    this.filterer.addListFilter(
      "retailItem.categoryName",
      this.translateService.instant("purchase-modality-selection.datatable.columns.item-category"),
      this.categoryList.map(cat => {
        return { value: cat.name, displayValue: cat.name };
      })
    );

    this.filterer.addListFilter(
      "retailItem.brand.name",
      this.translateService.instant("purchase-modality-selection.datatable.columns.brand"),
      this.lightBrandList.map(brand => {
        return { value: brand.name, displayValue: brand.name };
      }),
      null,
      null,
      null,
      null,
      true
    );

    this.filterer.addListFilter(
      "retailItem.status",
      this.translateService.instant("purchase-modality-selection.datatable.columns.status"),
      Object.keys(ItemStatusType).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );

    this.filterer.addListFilter(
      "purchaseType",
      this.translateService.instant("purchase-modality-selection.datatable.columns.purchase-type"),
      Object.keys(PurchaseType).map(key => ({ value: key, displayValue: this.purchaseTypeTranslations[key] }))
    );

    this.filterer.addFilter(
      "retailItem.reference",
      this.translateService.instant("purchase-modality-selection.datatable.columns.item-reference"),
      "string"
    );

    this.filterer.addFilter(
      "supplierRef",
      this.translateService.instant("purchase-modality-selection.datatable.columns.supplier-ref"),
      "string"
    );

    this.filterer.addFilter(
      "retailItem.name",
      this.translateService.instant("purchase-modality-selection.datatable.columns.item-name"),
      "string"
    );

    this.filterer.addFilter(
      "minQuantity",
      this.translateService.instant("purchase-modality-selection.datatable.columns.min-quantity"),
      "range"
    );

    this.filterer.addFilter(
      "maxQuantity",
      this.translateService.instant("purchase-modality-selection.datatable.columns.max-quantity"),
      "range"
    );
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();

    this.activeFilters.push(new SearchFilter("currency.id", SearchFilterOperator.EQUAL, this.currencyId.toString()));
    this.activeFilters.push(new SearchFilter("archived", SearchFilterOperator.EQUAL, "false"));
    this.activeFilters.push(new SearchFilter("retailItem.canBuy", SearchFilterOperator.EQUAL, "true"));
  }

  private addRow(itemPM: ItemPurchaseModality): void {
    const statusLabel = this.translateService.instant(
      `purchase-modality-selection.status-options.${itemPM.itemStatus}`
    );
    const statusClass = this.getStatusClass(itemPM.itemStatus);

    this.rows.push({
      itemCategory: itemPM.itemCategoryName,
      supplierRef: itemPM.itemSupplierRef,
      purchaseType: itemPM.purchaseType,
      itemReference: itemPM.itemReference,
      computedPrice: itemPM.computedPrice,
      pmId: itemPM.purchaseModalityId,
      minQuantity: itemPM.minQuantity,
      maxQuantity: itemPM.maxQuantity,
      supplier: itemPM.supplierName,
      itemName: itemPM.itemName,
      brand: itemPM.brandName,
      itemId: itemPM.itemId,
      statusLabel,
      statusClass,
    });

    const isDefaultSupplier = itemPM.supplierName === this.defaultSupplier.name;
    this.initRowFormControl(itemPM.purchaseModalityId, isDefaultSupplier);
  }

  private initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.form = this.fb.group({
      headerCheckbox: control,
    });
  }

  private initRowFormControl(id: number, isDefaultSupplier: boolean): void {
    this.form.addControl(
      this.getRowControlName(id),
      !isDefaultSupplier
        ? new UntypedFormControl(false)
        : new UntypedFormControl({
          value: false,
          disabled: this.defaultSupplier.archived,
        })
    );
  }

  private propToDto(propName: string): string {
    switch (propName) {
      case "brand":
        return "retailItem.brand.name";
      case "itemReference":
        return "retailItem.reference";
      case "itemCategory":
        return "retailItem.category";
      case "statusLabel":
        return "retailItem.status";
      case "itemName":
        return "retailItem.name";
      case "supplier":
        return "supplier.name";
      default:
        return propName;
    }
  }

  private computeSelectedElementsNumber(): void {
    this.selectedElementsNumber = 0;
    for (const value of this.selectedMap.values()) {
      this.selectedElementsNumber += value.length;
    }
  }

  private compareMaps(map1: Map<number, number[]>, map2: Map<number, number[]>): boolean {
    if (!map1 || !map2 || map1.size !== map2.size) {
      return false;
    }
    for (const [key, val] of map1) {
      const currValue = map2.get(key);
      if (currValue !== val || (currValue === undefined && !map2.has(key))) {
        return false;
      }
    }
    return true;
  }

  private updateSelection(): void {
    this.rows.forEach((row: any) => {
      const pmChecked: boolean = this.form.controls[this.getRowControlName(row.pmId)].value;
      const itemValues: number[] = this.selectedMap.get(row.itemId);

      if (!itemValues && pmChecked) {
        this.selectedMap.set(row.itemId, [row.pmId]);
      } else if (itemValues) {
        // If the item doesn't  have the current purchase modality  and  the pm is checked add it
        if (!itemValues.includes(row.pmId) && pmChecked) {
          itemValues.push(row.pmId);
          // If the item does have the current purchase modality and the pm isn't checked remove it
        } else if (itemValues.includes(row.pmId) && !pmChecked) {
          const pmIndex = itemValues.findIndex(value => value === row.pmId);
          itemValues.splice(pmIndex, 1);

          // If the item doesn't have any purchase modality delete it
          if (itemValues.length === 0) {
            this.selectedMap.delete(row.itemId);
          }
        }
      }
    });
    this.computeSelectedElementsNumber();
  }

  private updateHeaderPageCheckbox(): void {
    const currentPageRowsDisabledIds = this.currentRowsId.filter(id => {
      return this.form.get(this.getRowControlName(id)).disabled && !this.form.get(this.getRowControlName(id)).value;
    });
    if (this.currentRowsId.length === currentPageRowsDisabledIds.length) {
      this.form.controls.headerCheckbox.patchValue(false);
      if (!this.form.controls.headerCheckbox.disabled) {
        this.form.controls.headerCheckbox.disable();
      }
      return;
    }
    this.form.controls.headerCheckbox.enable();

    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.form.get(this.getRowControlName(id)).value || currentPageRowsDisabledIds.includes(id);
    });
    const isSelected = this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.form.controls.headerCheckbox.patchValue(isSelected);
  }

  private updateRowsPageCheckbox(): void {
    const controls = this.form.controls;
    const isHeaderSelected = this.form.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      if (!controls[this.getRowControlName(id)].disabled) {
        controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
      }
    });
  }

  private updateWarningMessage(): void {
    this.warningMessage = null;

    if (this.defaultSupplier && this.defaultSupplier.archived) {
      this.warningMessage = "purchase-modality-selection.warning.archived-supplier";
    }
  }

  private generateUnsavedPopinError(): void {
    const title = this.translateService.instant("global.errors.unsaved-title");
    const content = this.translateService.instant("global.errors.unsaved-popin-content");
    this.messageService.info(content, { title });
  }
}
