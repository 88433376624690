import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  Currency,
  InvoiceSupplierTab,
  Light,
  ReceivingForm,
  ItemPurchaseModality,
  CurrencyService,
  LightService,
  SupplierService,
  Supplier,
} from "center-services";
import { CommonValidatorsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { InvoiceSupplierInitiatorOutput } from "./invoice-supplier-initiator-output";

@Component({
  selector: "app-invoice-supplier-initiator-popup",
  templateUrl: "./invoice-supplier-initiator-popup.component.html",
  styleUrls: ["./invoice-supplier-initiator-popup.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceSupplierInitiatorPopupComponent implements OnInit {
  @Input() supplierId: number;
  @Input() currency: Currency;
  @Input() deliveryFormIds: number[] = [];
  @Input() tabNameList: string[] = [];
  @Input() activeTab: InvoiceSupplierTab = null;

  @Output() validate: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  public popupTitle: string;
  public popupForm: UntypedFormGroup;
  public supplierList: Light[] = [];
  public supplierOptions: Option[] = [];
  public currencyList: Currency[];
  public currencyOptions: Option[];
  public dataTypeOptions: Option[] = [];
  public shouldClose: boolean = false;

  public selectedDeliveryForms: ReceivingForm[] = [];
  public selectedPMs: ItemPurchaseModality[] = [];

  public initialInvoiceSupplierInitiatorOutput: InvoiceSupplierInitiatorOutput;
  public unsavedInvoiceSupplierInitiatorOutput: InvoiceSupplierInitiatorOutput;
  public selectedInvoiceSupplierInitiatorOutput: InvoiceSupplierInitiatorOutput;
  faArrowCircleRight: IconDefinition = faArrowCircleRight;
  private initObservables: Observable<any>[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private currencyService: CurrencyService,
    private lightService: LightService,
    private supplierService: SupplierService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.popupForm = this.fb.group({
      dataType: [null, [Validators.required]],
    });
    this.initObservables.push(this.fetchSuppliers());
    const deliveryRef = new UntypedFormControl(null, {
      validators: [Validators.required, CommonValidatorsUtil.uniqueValidator(this.tabNameList)],
    });

    if (!this.supplierId && !this.currency) {
      this.initObservables.push(this.fetchTitle("invoice-supplier-initiator-popup.title.new"));
      this.initObservables.push(this.fetchCurrencies());
      const supplierId = new UntypedFormControl(null, {
        validators: [Validators.required],
      });
      const currencyId = new UntypedFormControl(null, {
        validators: [Validators.required],
      });
      this.popupForm.addControl("supplierId", supplierId);
      this.popupForm.addControl("currencyId", currencyId);

      this.subscriptionService.subs.push(
        this.popupForm.controls.supplierId.valueChanges.subscribe(() => {
          // set the supplier's currency when the supplier changes and the currency hasn't been set yet
          if (this.popupForm.controls.supplierId.value !== null && this.popupForm.controls.currencyId.value === null) {
            this.subscriptionService.subs.push(
              this.supplierService.get(this.popupForm.controls.supplierId.value).subscribe((supplier: Supplier) => {
                this.popupForm.controls.currencyId.setValue(supplier.currencyId);
              })
            );
          }
        })
      );

      this.subscriptionService.subs.push(
        this.popupForm.controls.dataType.valueChanges.subscribe(() => {
          if (this.popupForm.controls.dataType.value === 1) {
            this.selectedDeliveryForms = [];
            this.popupForm.controls.supplierId.enable({ emitEvent: false });
            this.popupForm.controls.currencyId.enable({ emitEvent: false });
            this.popupForm.addControl("deliveryRef", deliveryRef);
          } else {
            this.popupForm.removeControl("deliveryRef");
          }
        })
      );
    } else {
      this.initObservables.push(this.fetchTitle("invoice-supplier-initiator-popup.title.update"));
      this.subscriptionService.subs.push(
        this.popupForm.controls.dataType.valueChanges.subscribe(() => {
          if (this.popupForm.controls.dataType.value === 1) {
            this.popupForm.addControl("deliveryRef", deliveryRef);
          } else {
            this.popupForm.removeControl("deliveryRef");
          }
        })
      );
    }

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.buildDataTypeOptions();
        this.subscriptionService.subs.push(
          this.translateService.onLangChange.subscribe(() => {
            this.buildDataTypeOptions();
          })
        );
        this.initializePopup();
      })
    );
  }

  buildDataTypeOptions(): void {
    this.dataTypeOptions = [];
    this.dataTypeOptions.push(
      new Option(0, this.translateService.instant("invoice-supplier-initiator-popup.options.delivery-form"))
    );
    this.dataTypeOptions.push(
      new Option(1, this.translateService.instant("invoice-supplier-initiator-popup.options.purchase-modality"))
    );
  }

  initializePopup(): void {
    this.popupForm.controls.dataType.setValue(0);

    this.selectedInvoiceSupplierInitiatorOutput = new InvoiceSupplierInitiatorOutput({
      deliveryRef: null,
      supplierId: this.supplierId,
      currency: this.currency,
      deliveryForms: [],
      itemPurchaseModalities: [],
    });

    this.initialInvoiceSupplierInitiatorOutput = new InvoiceSupplierInitiatorOutput(
      this.selectedInvoiceSupplierInitiatorOutput
    );
  }

  closePopup(): void {
    this.applyModifications();

    if (
      this.unsavedInvoiceSupplierInitiatorOutput &&
      !this.unsavedInvoiceSupplierInitiatorOutput.equals(this.selectedInvoiceSupplierInitiatorOutput)
    ) {
      this.shouldClose = false;
    }

    if (
      !this.initialInvoiceSupplierInitiatorOutput.equals(this.selectedInvoiceSupplierInitiatorOutput) &&
      !this.shouldClose
    ) {
      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedInvoiceSupplierInitiatorOutput = new InvoiceSupplierInitiatorOutput(
        this.selectedInvoiceSupplierInitiatorOutput
      );
      this.shouldClose = true;
    } else {
      this.close.emit();
    }
  }

  submit(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    // check at least one line has been selected on creation
    if (
      !this.supplierId &&
      !this.currency &&
      this.selectedDeliveryForms.length === 0 &&
      this.selectedPMs.length === 0
    ) {
      const title = this.translateService.instant("invoice-supplier-initiator-popup.errors.no-line-selected-title");
      const content = this.translateService.instant(
        "invoice-supplier-initiator-popup.errors.no-line-selected-delivery-form"
      );
      this.messageService.error(content, { title });
      return;
    }

    this.applyModifications();
    this.validate.emit([this.selectedInvoiceSupplierInitiatorOutput, this.activeTab]);
  }

  getButtonText(): string {
    if (this.activeTab) {
      return this.translateService.instant("invoice-supplier-form.popup.button");
    } else if (!this.supplierId && !this.currency && !this.activeTab) {
      return this.translateService.instant("invoice-supplier-initiator-popup.buttons.to-invoice");
    } else {
      return this.translateService.instant("invoice-supplier-initiator-popup.buttons.doc-add");
    }
  }

  applyModifications(): void {
    this.selectedInvoiceSupplierInitiatorOutput.supplierId = this.supplierId
      ? this.supplierId
      : this.popupForm.controls.supplierId.value;
    this.selectedInvoiceSupplierInitiatorOutput.currency = this.currency
      ? this.currency
      : this.currencyList.find(currency => currency.id === this.popupForm.controls.currencyId.value);
    this.selectedInvoiceSupplierInitiatorOutput.deliveryForms = this.selectedDeliveryForms;
    this.selectedInvoiceSupplierInitiatorOutput.itemPurchaseModalities = this.selectedPMs;
    this.selectedInvoiceSupplierInitiatorOutput.deliveryRef = this.popupForm.get("deliveryRef")
      ? this.popupForm.controls.deliveryRef.value
      : null;
  }

  onSelectedDFChange(selectedDeliveryForms: ReceivingForm[]): void {
    if (this.supplierId && this.currency) {
      return;
    }
    if (selectedDeliveryForms.length === 0) {
      this.popupForm.controls.supplierId.enable({ emitEvent: false });
      this.popupForm.controls.currencyId.enable({ emitEvent: false });
    } else {
      this.popupForm.controls.supplierId.disable({ emitEvent: false });
      this.popupForm.controls.currencyId.disable({ emitEvent: false });
    }
  }

  onSelectedPMChange(selectedPMs: ItemPurchaseModality[]): void {
    if (this.supplierId && this.currency) {
      return;
    }
    if (selectedPMs.length === 0) {
      this.popupForm.controls.currencyId.enable({ emitEvent: false });
    } else {
      this.popupForm.controls.currencyId.disable({ emitEvent: false });
    }
  }

  getCurrencyToPass(): Currency {
    if (this.currency) {
      return this.currency;
    } else {
      return this.popupForm.controls.currencyId
        ? this.currencyList.find(currency => currency.id === this.popupForm.controls.currencyId.value)
        : null;
    }
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierList = lightSuppliers;

          this.supplierOptions = lightSuppliers
            .filter(obj => !obj.archived)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-initiator-popup.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencyList = currencies;

          this.currencyOptions = currencies
            .filter(obj => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(obj => new Option(obj.id, obj.symbol));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-initiator-popup.errors.get-currencies");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchTitle(translationKey: string): Observable<string> {
    return this.translateService.get(translationKey).pipe(
      tap((res: string) => {
        this.popupTitle = res;
      })
    );
  }
}
