<ng-container>
  <div class="main-container">
    <div class="top-table-buttons">
      <ui-button
        text="{{ 'store.cash-registers-list.add-btn-text' | translate }}"
        type="add"
        (click)="addCashRegister($event)"
      >
      </ui-button>
    </div>
    <div [formGroup]="tableControl">
      <ngx-datatable
        #table
        [rows]="rows"
        [trackByProp]="'id'"
        class="material"
        columnMode="flex"
        headerHeight="auto"
        footerHeight="50"
        rowHeight="40"
        [count]="pager.totalElements"
        [offset]="pager.number"
        [limit]="pager.size"
        (activate)="updateCashRegister($event)"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down'
        }"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        [sorts]="sorts"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
      >
        <ngx-datatable-column
          name="{{ 'store.cash-registers-list.datatable.columns.unique-reference' | translate }}"
          prop="uniqueReference"
          cellClass="unique-reference"
          [flexGrow]="1"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'store.cash-registers-list.datatable.columns.reference' | translate }}"
          prop="reference"
          cellClass="reference"
          [flexGrow]="2"
          [resizeable]="false"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'store.cash-registers-list.datatable.columns.activated' | translate }}"
          prop="activated"
          headerClass="center-align"
          cellClass="center-align"
          [flexGrow]="0.6"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-id="row.id">
            <div class="green-zone" (click)="$event.stopPropagation()">
              <ui-switch
                customClass="no-margin"
                (change)="checkboxOnChanges(id)"
                [formControl]="tableControl.get('activated_' + id)"
              >
              </ui-switch>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="rowCount">
              {{
                rowCount
                  | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                  | translate: { rowCount: rowCount }
              }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="changePage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>

  <!-- Popup -->
  <app-cash-registers-popup
    *ngIf="popupVisible"
    [cashRegister]="selectedCashRegister"
    (validate)="onValidateCashRegister($event)"
    (close)="onCloseCashRegisterPopup()"
  ></app-cash-registers-popup>
</ng-container>
