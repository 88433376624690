import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  SupplierService,
  CaraUserService,
  CustomerService,
  StoreService,
  CountryService,
  Address,
  LightService,
  AuthService,
  ShipmentForm,
  Country,
  Contact,
  Store,
  Light,
  CaraUser,
  ProfessionalCustomer,
} from "center-services";
import { DayjsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { combineLatest, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-shipment-form-header",
  templateUrl: "./shipment-form-header.component.html",
  styleUrls: ["./shipment-form-header.component.scss"],
  providers: [SubscriptionService],
})
export class ShipmentFormHeaderComponent implements OnInit {
  private static ADDRESS_DELIMITER: string = "~";
  @ViewChild("tabHandler") tabHandler: any;
  @Output() pricingGroupIdChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() shipmentForm: ShipmentForm;
  @Input() readonly: boolean = false;
  @Input() pricingGroupId: number;
  @Input() type: string;
  public headerForm: UntypedFormGroup;
  public dateFormat: string;
  public locale: string;
  public receiversOptions: Option[];
  public allReceivers: Light[];
  public addressesOptions: Option[];
  public personsOptions: Option[];
  public countries: Country[];
  private initObservables: Observable<any>[];
  private senderId: number;
  private receiverService: any;
  private dataLoaded: boolean = false;
  private receiverLightMethod: string;
  private receiverAddresses: Address[] = [];

  constructor(
    private translateService: TranslateService,
    private supplierService: SupplierService,
    private userService: CaraUserService,
    private customerService: CustomerService,
    private storeService: StoreService,
    private countryService: CountryService,
    private lightService: LightService,
    private messageService: MessageService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareHeaderForm();
  }

  public ngOnInit(): void {
    this.senderId = this.authService.getContextStoreId();
    this.initObservables = [];
    this.manageReceiverType();
    this.initObservables.push(this.fetchReceivers());
    this.initObservables.push(this.fetchCountries());
    this.initObservables.push(this.fetchConnectedUser());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (!this.readonly) {
          this.loadEditedData();
        }
      })
    );
  }

  public formatAddressData(address: string): string {
    address = this.removeDelimiter(address);
    if (!address) {
      return this.translateService.instant("shipment-form.header.fields.undefined");
    }
    const splitAddress: string[] = address.split(", ");
    const addressCity = splitAddress.pop();
    const addressLines = splitAddress.join(", ");
    return `${addressLines}<br/>${addressCity}`;
  }

  public getReceiverLabel(receiverId: number): string {
    if (!this.allReceivers) {
      return this.translateService.instant("shipment-form.header.fields.undefined");
    }
    const receiverSelected: Light = this.allReceivers.find((receiver: Light) => receiver.id === receiverId);
    return `${receiverSelected?.reference} - ${receiverSelected?.name}`;
  }

  public isFormValid(): boolean {
    if (this.headerForm.invalid) {
      this.headerForm.markAllAsTouched();
    }
    return this.headerForm.valid;
  }

  public onTabClick(event: any): void {
    if (!event || !this.tabHandler) {
      return;
    }
    this.tabHandler.changeTab(event);
  }

  fetchCountries(): Observable<Country[]> {
    return this.countryService.getAll().pipe(
      tap(
        (countries: Country[]) => {
          this.countries = countries;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("countries-list.errors.get-countries");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private applyModifications(): void {
    let receiverPersonName = null;
    let receiverAddress = null;

    // apply modifications with adress and tild format
    if (this.headerForm.get("receiverAddress").value !== null && this.addressesOptions.length > 0) {
      receiverAddress = this.receiverAddresses
        .filter((add: Address) => add.id === this.headerForm.get("receiverAddress").value)
        .map((address: Address) =>
          [
            `${address.lines}, `,
            address.cityCode,
            address.city,
            this.countries.find(country => country.id === address.countryId).name,
          ].join(ShipmentFormHeaderComponent.ADDRESS_DELIMITER)
        )
        .pop();
    }
    this.shipmentForm.receiverAddress = receiverAddress;

    if (this.headerForm.get("receiverPerson").value !== null) {
      receiverPersonName = this.personsOptions.find((option: Option) => {
        return option.id === this.headerForm.get("receiverPerson").value;
      }).label;
    }
    this.shipmentForm.receiverPersonName = receiverPersonName;
    this.shipmentForm.receiverId = this.headerForm.get("receiver").value;
    this.shipmentForm.packagingComment = this.headerForm.get("comment").value;
    this.shipmentForm.expectedDeliveryDate = this.headerForm.get("expectedDeliveryDate").value
      ? this.headerForm.get("expectedDeliveryDate").value.toDate()
      : null;
    this.shipmentForm.packageReference = this.headerForm.get("packageReference").value;
    this.shipmentForm.forwardingReference = this.headerForm.get("forwardingReference").value;
  }

  private computeShipmentDate(receiver: Contact): void {
    // Don't compute the expectedDeliveryDate for the STORE type
    if (this.headerForm.get("expectedDeliveryDate").value) {
      return;
    }
    const currDate = new Date();
    let delay;
    if (this.type === "SUPPLIER" || this.type === "CUSTOMER") {
      delay = receiver.commercialModality ? receiver.commercialModality.deliveryDelay : 0;
    }
    if (this.type === "STORE") {
      if (this.shipmentForm.receiverId === this.senderId) {
        const sender: Store = this.receiverService.get(this.shipmentForm.senderId);
        delay = sender.deliveryDelay ? sender.deliveryDelay : 0;
      } else {
        delay = (receiver as Store).deliveryDelay ? (receiver as Store).deliveryDelay : 0;
      }
    }
    currDate.setDate(currDate.getDate() + delay);
    this.headerForm.get("expectedDeliveryDate").patchValue(DayjsUtil.dayjsOrNull(currDate, true));
  }

  private fetchReceivers(): Observable<Light[]> {
    return this.lightService[this.receiverLightMethod]().pipe(
      tap((lightEntities: Light[]) => {
        this.allReceivers = [...lightEntities];
        this.receiversOptions = lightEntities
          .filter(obj => !obj.archived && obj.id !== this.senderId)
          .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`))
          .sort((a, b) => a.label.localeCompare(b.label));
      })
    );
  }

  private fetchConnectedUser(): Observable<CaraUser> {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.locale = this.userService.connectedUser.value.codeLanguage;
      return of(this.userService.connectedUser.value);
    }
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.dateFormat = connectedUser.dateFormat;
        this.locale = connectedUser.codeLanguage;
      })
    );
  }

  private fetchSelectedReceiver(receiverId: number): void {
    this.addressesOptions = [];
    this.personsOptions = [];

    this.subscriptionService.subs.push(
      this.receiverService.get(receiverId).subscribe((receiver: Contact) => {
        if (this.type === "STORE") {
          this.pricingGroupIdChange.emit((receiver as Store).pricingGroupId);
        } else if (this.type === "CUSTOMER") {
          this.pricingGroupIdChange.emit((receiver as ProfessionalCustomer).pricingGroupId);
        }

        this.personsOptions = !receiver.persons
          ? []
          : receiver.persons
            .filter(obj => !obj.archived)
            .map(obj => new Option(obj.id, `${obj.firstName} ${obj.lastName}`));

        this.receiverAddresses = [...receiver.deliveryAddresses];

        this.receiverAddresses.forEach((address: Address) => {
          address.lines = this.removeDelimiter(address.lines);
          address.city = this.removeDelimiter(address.city);
        });

        this.addressesOptions = !this.receiverAddresses
          ? []
          : receiver.deliveryAddresses
            .filter(obj => !obj.archived)
            .map(
              obj =>
                new Option(
                  obj.id,
                  `${obj.lines}, ${obj.cityCode} ${obj.city} ${
                    this.countries.find(country => country.id === obj.countryId).name
                  }`
                )
            );

        // remove the visible "/n" form the placeholder in the list
        this.addressesOptions.forEach((option: Option) => {
          option.label = option.label.replace(/\n/g, " ");
        });

        const defaultAddress = !this.receiverAddresses
          ? null
          : receiver.deliveryAddresses.find(address => address.byDefault);

        if (this.shipmentForm.receiverPersonName) {
          this.getReceiverPerson();
        }

        if (this.shipmentForm.receiverAddress) {
          this.getReceiverAddress();
        } else if (defaultAddress !== null && defaultAddress !== undefined) {
          this.headerForm.get("receiverAddress").patchValue(defaultAddress.id);
        }

        this.addressesOptions.sort((a, b) => a.label.localeCompare(b.label));
        this.personsOptions.sort((a, b) => a.label.localeCompare(b.label));
        this.personsOptions.splice(0, 0, new Option(null, "-"));
        if (!this.dataLoaded) {
          this.dataLoaded = true;
        }
        this.computeShipmentDate(receiver);
      })
    );
  }

  private getReceiverPerson(): void {
    const person = this.personsOptions.find((option: Option) => {
      return option.label === this.shipmentForm.receiverPersonName;
    });
    if (!person) {
      this.headerForm.get("receiverPerson").patchValue(0, { emitEvent: false });
      this.personsOptions.push(new Option(0, this.shipmentForm.receiverPersonName));
    } else {
      this.headerForm.get("receiverPerson").patchValue(person.id, { emitEvent: false });
    }
  }

  private getReceiverAddress(): void {
    const address = this.addressesOptions.find((option: Option) => {
      // remove "," and "~", to be able to compare the strings
      return option.label.replace(/,/g, "") === this.removeDelimiter(this.shipmentForm.receiverAddress);
    });
    if (!address) {
      this.headerForm.get("receiverAddress").patchValue(0, { emitEvent: false });
      this.addressesOptions.push(new Option(0, this.removeDelimiter(this.shipmentForm.receiverAddress)));
    } else {
      this.headerForm.get("receiverAddress").patchValue(address.id, { emitEvent: false });
    }
  }

  private removeDelimiter(str: string): string {
    return str ? str.replace(new RegExp(`${ShipmentFormHeaderComponent.ADDRESS_DELIMITER}`, "g"), " ") : null;
  }

  private loadEditedData(): void {
    this.headerForm.controls.expectedDeliveryDate.patchValue(
      DayjsUtil.dayjsOrNull(this.shipmentForm.expectedDeliveryDate, true),
      {
        emitEvent: false,
      }
    );
    this.headerForm.controls.packageReference.patchValue(this.shipmentForm.packageReference, { emitEvent: false });
    this.headerForm.controls.comment.patchValue(this.shipmentForm.packagingComment, { emitEvent: false });
    this.headerForm.controls.receiver.patchValue(this.shipmentForm.receiverId, { emitEvent: false });
    this.headerForm.controls.forwardingReference.patchValue(this.shipmentForm.forwardingReference, {
      emitEvent: false,
    });

    this.fetchSelectedReceiver(this.shipmentForm.receiverId);
  }

  private manageReceiverType(): void {
    switch (this.type) {
      case "STORE":
        this.receiverLightMethod = "getStores";
        this.receiverService = this.storeService;
        break;
      case "CUSTOMER":
        this.receiverLightMethod = "getCustomers";
        this.receiverService = this.customerService;
        break;
      case "SUPPLIER":
        this.receiverLightMethod = "getSuppliers";
        this.receiverService = this.supplierService;
        break;
      default:
        console.error("Unknown receiver type, it must be SUPPLIER or STORE");
        break;
    }
  }

  private prepareHeaderForm(): void {
    if (this.readonly) {
      return;
    }

    this.headerForm = this.fb.group({
      comment: [null],
      receiverPerson: [null],
      receiverAddress: [null, [Validators.required]],
      packageReference: [null],
      forwardingReference: [null],
      expectedDeliveryDate: [null],
      receiver: [null, [Validators.required]],
    });

    this.subscriptionService.subs.push(
      this.headerForm.valueChanges.subscribe(() => {
        this.applyModifications();
      })
    );

    this.subscriptionService.subs.push(
      this.headerForm.get("receiver").valueChanges.subscribe((value: number) => {
        this.headerForm.get("expectedDeliveryDate").patchValue(null, { emitEvent: false });
        this.headerForm.get("receiverAddress").patchValue(null, { emitEvent: false });
        this.headerForm.get("receiverPerson").patchValue(null, { emitEvent: false });
        this.applyModifications();

        if (value !== null) {
          this.fetchSelectedReceiver(value);
        }
      })
    );
  }
}
