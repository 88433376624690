import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { IconDefinition, faPen, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { CaraUser, CaraUserService, Country, CountryService, CustomerDocument, DocumentService } from "center-services";
import { SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-customer-document-history",
  templateUrl: "./customer-document-history.component.html",
  styleUrls: ["./customer-document-history.component.scss"],
  providers: [SubscriptionService],
})
export class CustomerDocumentHistoryComponent implements OnInit {
  private static buttonDocumentAddTradKey: string = "customer-document-history.customer-document.button-document.add";
  private static buttonDocumentUpdateTradKey: string =
    "customer-document-history.customer-document.button-document.update";
  @Input() customerDocument: CustomerDocument;
  @Output() customerDocumentChange: EventEmitter<CustomerDocument> = new EventEmitter<CustomerDocument>();
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild("customerDocumentPopup") customerDocumentPopup: any;
  popupVisible: boolean = false;
  countriesList: Country[];
  public documentsFiles: any[] = [];
  public faPen: IconDefinition = faPen;
  public faPlus: IconDefinition = faPlus;
  public dateFormat: string;
  private initObservables: Observable<any>[] = [];

  constructor(
    private translateService: TranslateService,
    private countryService: CountryService,
    private messageService: MessageService,
    private documentService: DocumentService,
    protected userService: CaraUserService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }
    this.initObservables.push(this.fetchCountries());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.customerDocument) {
          this.getDocumentImage(0);
        }
      })
    );
  }

  openCustomerDocumentPopup(): void {
    this.popupVisible = true;
  }

  customerDocumentUpdated(customerDocumentUpdated: CustomerDocument): void {
    this.customerDocumentChange.emit(customerDocumentUpdated);
    this.submitEvent.emit();
  }

  isValid(): boolean {
    if (this.customerDocumentPopup && !this.customerDocumentPopup.isValid()) {
      return false;
    }
    return true;
  }

  onClosePopup(editedCustomerDocument?: CustomerDocument): void {
    this.popupVisible = false;
    if (editedCustomerDocument) {
      this.customerDocument = editedCustomerDocument;
    }
    if (this.customerDocument && this.customerDocument.documentIds.length > 0) {
      this.getDocumentImage(0);
    }
  }

  getAddDocumentButtonLabel(): string {
    return !this.customerDocument
      ? CustomerDocumentHistoryComponent.buttonDocumentAddTradKey
      : CustomerDocumentHistoryComponent.buttonDocumentUpdateTradKey;
  }

  fetchCountries(): Observable<Country[]> {
    return this.countryService.getAll().pipe(
      tap(
        (countries: Country[]) => {
          countries.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          this.countriesList = countries.filter(country => !country.archived);
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("countries-list.errors.get-countries", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  getCustomerDocumentInfo(): string {
    let stringInfo = "";
    if (this.customerDocument.type) {
      stringInfo += `${this.customerDocument.type}\n`;
    }
    if (this.customerDocument.reference) {
      stringInfo += `${this.customerDocument.reference}\n`;
    }
    if (this.customerDocument.countryId) {
      stringInfo += `${this.customerDocument.countryId}\n`;
    }
    if (this.customerDocument.expirationDate) {
      stringInfo += this.customerDocument.expirationDate.toDateString();
    }
    return stringInfo;
  }

  getDocumentType(): string {
    return this.translateService.instant(`customer-document.document-type.${this.customerDocument.type}`);
  }

  getCountry(): string {
    let countryInfoLabel = this.translateService.instant(
      `customer-document-history.customer-document.popup-info.country`
    );
    return (countryInfoLabel += this.countriesList?.filter(country => country.id === this.customerDocument.countryId)[0]
      ?.name);
  }

  getDateLabel(): string {
    return this.translateService.instant(`customer-document-history.customer-document.popup-info.date`);
  }

  getDate(): Date {
    return this.customerDocument.expirationDate;
  }

  getDocumentImage(fileNumber: number): void {
    this.subscriptionService.subs.push(
      this.documentService.downloadFile(this.customerDocument.documentIds[fileNumber]).subscribe(
        data => {
          data.byteLength === 0 ? (this.documentsFiles[fileNumber] = null) : (this.documentsFiles[fileNumber] = data);
          if (fileNumber === 0) {
            this.getDocumentImage(1);
          }
        },
        error => {
          console.error(error.message);
        }
      )
    );
  }

  getDocumentButtonIcon(): string {
    return this.customerDocument ? "faPen" : "faPlus";
  }
}
