<div class="stock-entry-table">
  <ngx-datatable
    #table
    [rows]="rows"
    [trackByProp]="'id'"
    [class]="'material'"
    [columnMode]="'force'"
    [headerHeight]="'auto'"
    [footerHeight]="50"
    [rowHeight]="40"
    [rowClass]="getRowClass"
    [cssClasses]="{
      sortAscending: 'fas fa-chevron-up',
      sortDescending: 'fas fa-chevron-down'
    }"
    [messages]="{ emptyMessage: 'global.no-data' | translate }"
    [externalSorting]="true"
    [sorts]="sorts"
    (sort)="changeSortSettings($event.column.prop, $event.newValue)"
    [externalPaging]="true"
    [count]="pager.totalElements"
    [offset]="pager.number"
    [limit]="pager.size"
  >
    <ngx-datatable-column
      name="{{ 'stock-entry-list.datatable.columns.serialNumber' | translate }}"
      prop="serialNumber"
      [resizeable]="false"
      [cellClass]="'serial-number'"
      [draggable]="false"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'stock-entry-list.datatable.columns.batchNumber' | translate }}"
      prop="batchNumber"
      [resizeable]="false"
      [cellClass]="'batch-number'"
      [draggable]="false"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'stock-entry-list.datatable.columns.quantity' | translate }}"
      prop="quantity"
      [resizeable]="false"
      [cellClass]="'quantity'"
      [draggable]="false"
    >
      <ng-template ngx-datatable-cell-template let-quantity="row.quantity" let-uom="row.uom">
        {{ quantity + " " + uom }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'stock-entry-list.datatable.columns.size-value' | translate }}"
      prop="sizeValue"
      [resizeable]="false"
      [cellClass]="'size-value'"
      [draggable]="false"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'stock-entry-list.datatable.columns.stock-entry-date' | translate }}"
      prop="stockEntryDate"
      [resizeable]="false"
      [cellClass]="'stock-entry-date'"
      [draggable]="false"
    >
      <ng-template ngx-datatable-cell-template let-stockEntryDate="row.stockEntryDate">
        <span *ngIf="stockEntryDate !== null">{{ stockEntryDate | dateTimeFormat: false }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'stock-entry-list.datatable.columns.last-movement-type' | translate }}"
      prop="lastMovementType"
      [resizeable]="false"
      [cellClass]="'last-movement-type'"
      [draggable]="false"
    >
      <ng-template ngx-datatable-cell-template let-lastMovementType="row.lastMovementType">
        <ui-tag
          *ngIf="lastMovementType"
          [customClass]="getMovementTypeClass(lastMovementType)"
          [label]="'stock-entry-list.datatable.last-movement-type.' + lastMovementType | translate"
        >
        </ui-tag>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'stock-entry-list.datatable.columns.last-movement-date' | translate }}"
      prop="lastMovementDate"
      [resizeable]="false"
      [cellClass]="'last-movement-date'"
      [draggable]="false"
    >
      <ng-template ngx-datatable-cell-template let-lastMovementDate="row.lastMovementDate">
        <span *ngIf="lastMovementDate !== null">{{ lastMovementDate | dateTimeFormat: false }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'stock-entry-list.datatable.columns.location' | translate }}"
      prop="location"
      [resizeable]="false"
      [cellClass]="'location'"
      [draggable]="false"
    >
      <ng-template ngx-datatable-cell-template let-value="value">
        <div #locationElement class="ellipsis">
          {{ value }}

          <ui-tooltip [element]="locationElement" *ngIf="locationElement">
            <span style="font-weight: normal !important; white-space: break-spaces">
              {{ value }}
            </span>
          </ui-tooltip>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count" *ngIf="rowCount">
          {{
            rowCount
              | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
              | translate: { rowCount: rowCount }
          }}
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'fas fa-chevron-left'"
          [pagerRightArrowIcon]="'fas fa-chevron-right'"
          [pagerNextIcon]="'fas fa-chevron-double-right'"
          [pagerPreviousIcon]="'fas fa-chevron-double-left'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="changePage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>
