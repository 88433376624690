import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { AuthService, User, UserService } from "center-services";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  private static readonly TOKEN_QUERY_PARAM: string = "idToken";
  private static readonly EXP_DATE_QUERY_PARAM: string = "expirationDate";

  public connectedUser: User;
  public requireAllPermissions: boolean = false;

  constructor(public authService: AuthService, public userService: UserService<User>, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if a token has been passed by the URL, save it
    if (
      next &&
      next.queryParamMap &&
      next.queryParamMap.has(AuthGuard.TOKEN_QUERY_PARAM) &&
      next.queryParamMap.has(AuthGuard.EXP_DATE_QUERY_PARAM)
    ) {
      this.authService.saveToken({
        idToken: next.queryParamMap.get(AuthGuard.TOKEN_QUERY_PARAM),
        expirationDate: Number(next.queryParamMap.get(AuthGuard.EXP_DATE_QUERY_PARAM)),
      });
      return true;
    }

    // if no token or the token has expired, redirect to login page
    if (!this.authService.isLoggedIn()) {
      this.userService.connectedUser.next(undefined);
      this.router.navigateByUrl("/login");
      return false;
    }

    if (
      this.authService.getAuthorities().length === 1 &&
      this.authService.getAuthorities()[0] === "USER_PASSWORD_PIN_CODE_UPDATE"
    ) {
      this.router.navigateByUrl("/login");
      return false;
    }

    // needed authorizations for the target page
    const neededAuthorizations = next.data.authorizations;
    // if no connected user, return an observable to wait for the connected user to be fetched
    if (!this.userService.connectedUser.value && neededAuthorizations) {
      return this.userService.connectedUser.asObservable().pipe(
        filter(user => user !== undefined),
        map(() => {
          return this.checkRights(neededAuthorizations);
        })
      );
    }

    // check user authorizations
    if (neededAuthorizations) {
      if (!this.checkRights(neededAuthorizations)) {
        return false;
      }
    }
    return true;
  }

  checkRights(authorizations: any): boolean {
    if (typeof authorizations === "object" && authorizations.hasOwnProperty("requireAllPermissions")) {
      this.requireAllPermissions = authorizations.requireAllPermissions;
    }

    const permissions = authorizations.permissions ? authorizations.permissions : authorizations;
    for (const checkPermission of permissions) {
      if (!this.userService.canDo(checkPermission)) {
        // If 'requireAllPermissions' is true, return false on the first failure
        if (this.requireAllPermissions) {
          this.router.navigate(["/"]);
          return false;
        }
      } else {
        // If 'requireAllPermissions' is false, return true on the first success
        if (!this.requireAllPermissions) {
          return true;
        }
      }
    }

    // If 'requireAllPermissions' is true, return true as all permissions passed
    return this.requireAllPermissions;
  }
}
