import { OnInit, Component } from "@angular/core";
import { IconDefinition, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormArray } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BaseListPopupComponent, ErrorUtil } from "generic-pages";
import { MessageService } from "fugu-components";
import { SizeCategory, SizeValue, SizeCategoryService, CaraUserService } from "center-services";

@Component({
  selector: "app-size-categories-list",
  templateUrl: "./size-categories-list.component.html",
  styleUrls: ["./size-categories-list.component.scss"],
})
export class SizeCategoriesListComponent extends BaseListPopupComponent<SizeCategory> implements OnInit {
  faTrashAlt: IconDefinition = faTrashAlt;

  public sizeValuesRows: SizeValue[] = [];
  public sizeValueForm: UntypedFormArray;
  public cptIdNewSizeValues: number = 0;

  protected readonly VALUES: string = "values";

  constructor(
    sizeCategoryService: SizeCategoryService,
    translateService: TranslateService,
    messageService: MessageService,
    private fb: UntypedFormBuilder,
    private userService: CaraUserService
  ) {
    super(sizeCategoryService, translateService, messageService);
  }

  ngOnInit(): void {
    this.sorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "name",
        dir: "asc",
      },
    ];
    super.ngOnInit();
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  onNewEntityClick(): void {
    this.selectedEntity = new SizeCategory({
      name: null,
      elements: [],
      archived: false,
    });
    this.popupTitle = "size-categories-list.popup.title-new-size-categories";
    this.initializePopup();
  }

  onTableActivate(event: any): any {
    if (event.type === "click") {
      if (!this.userService.canDo("SIZE_CATEGORY_UPDATE")) {
        return;
      }
      const filteredList = this.entityList.filter(sizeCategory => sizeCategory.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`Can't find size category with ID: ${event.row.id}`);
        return;
      }
      this.selectedEntity = new SizeCategory(filteredList[0]);
      this.popupTitle = "size-categories-list.popup.title-update-size-categories";
      this.initializePopup();
    }
  }

  initializePopup(): void {
    super.initializePopup();
    this.popupForm.controls.name.setValue(this.selectedEntity.name);

    this.sizeValueForm = new UntypedFormArray([]);

    if (this.selectedEntity.elements) {
      // populate size value list
      this.sizeValuesRows = [];

      this.selectedEntity.elements.forEach((element: SizeValue) => {
        this.sizeValuesRows.push(element);
      });

      // create controls for form
      this.sizeValuesRows.forEach((row: any) => {
        this.sizeValueForm.push(new UntypedFormControl(row.value, Validators.required));
      });
    }

    this.popupForm.addControl("sizeValues", this.sizeValueForm);
    this.fetchEntities();
  }

  close(): void {
    this.sizeValuesRows = [];
  }

  applyModifications(): void {
    this.selectedEntity.name = this.popupForm.value.name;
    this.selectedEntity.elements = [];

    if (this.sizeValuesRows.length >= 1) {
      // set the SizeValue's value with the formcontrol's value
      for (let i = 0; i < this.sizeValuesRows.length; i++) {
        this.sizeValuesRows[i].value = this.sizeValueForm.at(i).value;
      }
      this.selectedEntity.elements = this.sizeValuesRows;
    }
  }

  addSizeValue(): void {
    // add row in datatable
    this.sizeValuesRows.push(
      new SizeValue({
        id: null,
        value: "",
      })
    );

    this.sizeValueForm.push(new UntypedFormControl("", Validators.required));
    this.cptIdNewSizeValues++;
  }

  removeSizeValue(selectedRow: SizeValue, rowIndex: number): void {
    // remove row from datatable
    this.sizeValuesRows = this.sizeValuesRows.filter(row => row !== selectedRow);
    this.sizeValueForm.removeAt(rowIndex);
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "size-categories-list.datatable.columns.name",
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  protected addRow(sizeCategory: SizeCategory): void {
    this.rows.push({
      id: sizeCategory.id,
      name: sizeCategory.name,
      values: sizeCategory.elements.map(e => e.value),
      activated: !sizeCategory.archived,
    });
  }

  protected cloneEntity(sizeCategory: SizeCategory): SizeCategory {
    return new SizeCategory(sizeCategory);
  }

  protected canArchive(): boolean {
    return this.userService.canDo("SIZE_CATEGORY_ARCHIVE");
  }

  protected preparePopupForm(): void {
    this.sizeValueForm = null;
    this.popupForm = this.fb.group({
      name: [null, [Validators.required]],
    });
  }

  protected getTranslationPrefix(): string {
    return "size-categories-list";
  }
}
