import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  Pagination,
  Sort,
  PaginatedList,
  Address,
  CaraUserService,
  Store,
  StoreService,
  StoreStatusType,
  StoreSupplierLink,
  SupplierService,
} from "center-services";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-supplier-stores",
  templateUrl: "./supplier-stores.component.html",
  styleUrls: ["./supplier-stores.component.scss"],
  providers: [SubscriptionService],
})
export class SupplierStoresComponent
  extends FilteredTableListComponent
  implements OnInit, AfterViewChecked, PaginableComponent {
  public static LIST_ID: string = "app-supplier-stores.stores-table";

  @Output() storesChange: EventEmitter<StoreSupplierLink[]> = new EventEmitter<StoreSupplierLink[]>();
  @Input() stores: StoreSupplierLink[];
  @Input() supplierId: number;
  @Input() canUpdate: boolean = true;
  @ViewChild("table") table: { sorts: any[]; offset: number };

  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public cities: string[];
  public status: string[];
  public allCities: string[] = [];
  public tableControl: UntypedFormGroup;
  public storeList: Store[] = [];
  public rows: any[] = [];
  submitted: boolean = false;
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "asc",
    },
    {
      prop: "name",
      dir: "asc",
    },
  ];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  private initObservables: Observable<any>[];
  private statusTranslations: { [key: string]: string } = {};
  private sessionPagination: SessionPagination;

  constructor(
    translateService: TranslateService,
    messageService: MessageService,
    userService: CaraUserService,
    private storeService: StoreService,
    private supplierService: SupplierService,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      StoreStatusType,
      "supplier-stores-list.status-options",
      this.statusTranslations
    );

    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(SupplierStoresComponent.LIST_ID);
  }

  ngOnInit(): void {
    this.tableControl = new UntypedFormGroup({});
    this.initObservables = [];
    this.initObservables.push(this.fetchCities());
    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(SupplierStoresComponent.LIST_ID);
        this.computeSearchFilters();
        this.fetchStoreList();
      })
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  changeSortSettings(prop: string, dir: string): void {
    if (prop !== "activated") {
      this.sorts = [
        { prop: "activated", dir: "asc" },
        { prop, dir },
      ];
    } else {
      this.sorts = [{ prop, dir }];
    }

    this.changePage({ page: 1 });
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "tags":
        return "tagsId";
      case "activated":
        return "archived";
      case "city":
        return "store.address.city";
      case "ref":
        return "store.reference";
      case "name":
        return "store.name";
      default:
        return prop;
    }
  }

  fetchStoreList(): void {
    this.subscriptionService.subs.push(
      this.supplierService.getAllStores(this.supplierId, this.pager, this.getSorter(), this.activeFilters).subscribe(
        (storeList: PaginatedList<Store>) => {
          this.storeList = storeList.data;
          this.pager = storeList.page;
          this.rows = [];
          this.storeList.forEach((store: Store) => {
            this.addRow(store);
          });
          this.rows = [...this.rows];
          if (this.table) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
        },
        error => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("supplier-stores-list.errors.get-stores", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  addRow(store: Store): void {
    const link = this.stores.find((storeLink: StoreSupplierLink) => storeLink.storeId === store.id);
    let activated = false;
    let storeRef = null;

    const statusLabel = this.translateService.instant(
      `store.general-datas.status-options.${store.status.toUpperCase()}`
    );

    if (link) {
      activated = !link.archived;
      storeRef = link.storeRef;
    }

    this.rows.push({
      statusLabel,
      storeRef,
      activated,
      id: store.id,
      name: store.name,
      status: store.status,
      ref: store.reference,
      city: store.address ? store.address.city : null,
      statusClass: store.status ? this.getStatusClass(store.status) : null,
    });

    const rowForm = this.fb.group({
      store_ref: new UntypedFormControl({ value: storeRef, disabled: !activated }),
      activated: new UntypedFormControl({ value: activated, disabled: !this.canUpdate }),
    });
    this.tableControl.addControl(store.id.toString(), rowForm);
  }

  refreshInput(id: number, activated: boolean): void {
    const control = this.tableControl.controls[id].get("store_ref");
    const emptyValue = activated ? null : "";

    control.setValidators(activated ? [Validators.required] : null);
    control.patchValue(control.value ? control.value : emptyValue);
    activated ? control.enable() : control.disable();
    control.updateValueAndValidity();
    this.onBlur(`${id}.store_ref`);
  }

  checkboxOnChanges(id: number): void {
    if (!this.canUpdate) {
      return;
    }

    const activated = this.tableControl.controls[id].value.activated;
    const storeRef = this.tableControl.controls[id].value.store_ref;
    let linkId = this.stores.findIndex(link => link.storeId === id);
    this.refreshInput(id, activated);

    if (!activated && linkId >= 0 && !this.stores[linkId].supplierId) {
      this.stores.splice(linkId, 1);
      linkId = -1;
    }
    if (activated && linkId < 0) {
      this.stores.push(
        new StoreSupplierLink({
          storeId: id,
          archived: !activated,
          storeRef,
        })
      );
    }

    if (linkId >= 0) {
      this.stores[linkId].archived = !activated;
    }
    this.storesChange.emit(this.stores);
  }

  saveInputValue(controlName: string): void {
    const storeId = Number(controlName.split(".")[0]);
    const inputValue = this.tableControl.get(controlName).value;
    const linkId = this.stores.findIndex(link => link.storeId === storeId);
    if (linkId >= 0) {
      this.stores[linkId].storeRef = inputValue;
      this.storesChange.emit(this.stores);
    }
  }

  onBlur(controlName: string): void {
    if (controlName.includes("store_ref")) {
      this.saveInputValue(controlName);
    }
  }

  viewStore(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("STORE_UPDATE")) {
        return;
      }

      const filteredList = this.storeList.filter(store => store.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find store with id ${event.row.id}`);
        return;
      }
      this.router.navigateByUrl(`/store/update/${event.row.id}`);
    }
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === SupplierStoresComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "store.reference",
      this.translateService.instant("supplier-stores-list.datatable.columns.reference"),
      "string"
    );

    this.filterer.addFilter(
      "store.name",
      this.translateService.instant("supplier-stores-list.datatable.columns.name"),
      "string"
    );

    this.filterer.addListFilter(
      "store.address.city",
      this.translateService.instant("supplier-stores-list.datatable.columns.city"),
      this.allCities.map(city => {
        return { value: city, displayValue: city };
      }),
      null,
      null,
      null,
      null,
      true
    );

    this.filterer.addListFilter(
      "store.status",
      this.translateService.instant("supplier-stores-list.datatable.columns.status"),
      Object.keys(StoreStatusType).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );

    this.filterer.addFilter(
      "storeRef",
      this.translateService.instant("supplier-stores-list.datatable.columns.store-ref"),
      "string"
    );

    this.filterer.addBooleanFilter(
      "archived",
      this.translateService.instant("supplier-stores-list.datatable.columns.activated"),
      false,
      false,
      true,
      true
    );
  }

  fetchCities(): Observable<Address[]> {
    return this.storeService.getAddresses().pipe(
      tap(
        (addresses: Address[]) => {
          addresses.forEach((address: Address) => {
            if (address.city && this.allCities.indexOf(address.city) === -1) {
              this.allCities.push(address.city);
            }
          });
        },
        error => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("supplier-stores-list.errors.get-cities", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.activeFilters = [];

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        SupplierStoresComponent.LIST_ID
      ).subscribe()
    );
    this.changePage({ page: 1 });
  }

  changePage(pageInfo: { page: any }): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchStoreList();
  }

  private getStatusClass(status: StoreStatusType): string {
    return `status-${status.toLowerCase()}`;
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }
}
