import { Component, Input, OnInit } from "@angular/core";
import {
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { IconDefinition, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  StockEntryCreation,
  StandardItem,
  SizeCategory,
  Currency,
  Uom,
  StockLocationService,
  SizeCategoryService,
  CurrencyService,
  LightService,
  StoreService,
  UomService,
  Store,
  Light,
  StockLocation,
  OverrideSizeValue,
  SizeValue,
  AuthService,
  CaraUserService,
} from "center-services";
import Decimal from "decimal.js";
import { CommonValidatorsUtil, Option, RoundingUtil, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-manual-stock-entry-data",
  templateUrl: "./manual-stock-entry-data.component.html",
  styleUrls: ["./manual-stock-entry-data.component.scss"],
  providers: [SubscriptionService],
})
export class ManualStockEntryDataComponent implements OnInit {
  @Input() editedManualStockEntry: StockEntryCreation;
  @Input() selectedItem: StandardItem;

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;

  public storeList: Store[] = [];
  public supplierOptions: Option[] = [];
  public stockOptions: Option[] = [];
  public defaultCurrency: Currency;
  public stockForm: UntypedFormGroup;
  public mainStore: Store;
  public defaultUnit: Uom;
  public weightUnit: Uom;
  public tareUnit: Uom;
  public mainUnit: Uom;
  public allowCheckbox: boolean = false;
  public faWarn: IconDefinition = faWarning;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public isConnectedToMainStore: boolean = false;
  public contextStore: Store = null;
  public locale: string;
  private initObservables: Observable<any>[];
  private sizeCategories: SizeCategory[];

  constructor(
    private stockLocationService: StockLocationService,
    private sizeCategoryService: SizeCategoryService,
    private translateService: TranslateService,
    private currencyService: CurrencyService,
    private messageService: MessageService,
    private lightService: LightService,
    private storeService: StoreService,
    private uomService: UomService,
    private authService: AuthService,
    private userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.editedManualStockEntry.itemId = this.selectedItem.id;
    this.initObservables = [];
    this.prepareForm();

    this.initObservables.push(this.fetchDefaultCurrency());
    this.initObservables.push(this.fetchSizeCategories());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchContextStore(this.authService.getContextStoreId()));
    this.initObservables.push(this.fetchUoms());

    this.locale = this.userService.connectedUser.value.codeLanguage;

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.setSizeValueFormValue();
        this.fetchStockLocations(this.contextStore);
      })
    );
  }

  isNullOrEmptyOrZero(value: string | number): boolean {
    return value === null || value === undefined || value === "" || value === 0 || value === "0";
  }

  public updateManualStockEntryForm(): boolean {
    if (this.stockForm.valid) {
      this.applyModifications();
      return true;
    }
    this.stockForm.markAllAsTouched();
    return false;
  }

  public applyModifications(): void {
    this.editedManualStockEntry.tare = this.stockForm.value.tare;
    this.editedManualStockEntry.weight = this.stockForm.value.weight;
    this.editedManualStockEntry.comment = this.stockForm.value.comment;
    this.editedManualStockEntry.supplierRef = this.stockForm.value.supplierRef;
    this.editedManualStockEntry.quantity = this.stockForm.value.quantity;
    this.editedManualStockEntry.sizeValue = this.stockForm.value.sizeValue;
    this.editedManualStockEntry.supplierId = this.stockForm.value.supplierId;
    this.editedManualStockEntry.purchaseUnitPrice = this.stockForm.value.price;
    this.editedManualStockEntry.destLocationId = this.stockForm.value.stockLocationId;
    if (this.isNullOrEmptyOrZero(this.stockForm.value.weight)) {
      this.editedManualStockEntry.policeBook = false;
    } else {
      this.editedManualStockEntry.policeBook = this.stockForm.value.policeBook
        ? this.stockForm.value.policeBook
        : false;
    }
  }

  hasSameTareAndWeight(): boolean {
    return (
      this.stockForm.get("tare").value &&
      this.stockForm.get("weight").value &&
      this.stockForm.get("tare").value === this.stockForm.get("weight").value
    );
  }

  public getTotalPrice(): number {
    return RoundingUtil.roundLow(
      new Decimal(this.stockForm.value.quantity ?? 0).times(this.stockForm.value.price ?? 0).toNumber()
    );
  }

  private fetchDefaultCurrency(): any {
    return this.currencyService.getDefault().pipe(
      tap(
        (currency: Currency) => {
          this.defaultCurrency = currency;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("manual-stock-entry.errors.get-currencies");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchContextStore(id: number): Observable<Store> {
    return this.storeService.get(id).pipe(
      tap(
        (store: Store) => {
          this.contextStore = store;
          this.isConnectedToMainStore = store.mainStore;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("manual-stock-entry.errors.get-store");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchUoms(): any {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.mainUnit = uoms.find(obj => obj.id === this.selectedItem.mainUnitId);
          this.defaultUnit = uoms.find(uom => uom.shortName === "g");

          this.stockForm.controls.tare.setValue(
            this.uomService.convertWeightTo(
              uoms,
              this.stockForm.controls.tare.value,
              this.selectedItem.tareUnitId,
              this.defaultUnit.id
            )
          );
          this.stockForm.controls.weight.setValue(
            this.uomService.convertWeightTo(
              uoms,
              this.stockForm.controls.weight.value,
              this.selectedItem.weightUnitId,
              this.defaultUnit.id
            )
          );
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("manual-stock-entry.errors.get-uoms");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchSuppliers(): any {
    return this.lightService.getSuppliers().pipe(
      tap(
        (suppliers: Light[]) => {
          this.supplierOptions = suppliers
            .filter((obj: Light) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Light) => new Option(obj.id, obj.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("manual-stock-entry.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchSizeCategories(): any {
    return this.sizeCategoryService.getAll().pipe(
      tap(
        (categories: SizeCategory[]) => {
          this.sizeCategories = categories;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("manual-stock-entry.errors.get-size-categories");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchStockLocations(contextStore: Store): void {
    this.subscriptionService.subs.push(
      this.stockLocationService
        .getAll(["withChildren", "onlyOrigin"], contextStore.id)
        .subscribe((stockLocations: StockLocation[]) => {
          const locations: StockLocation[] = this.stockLocationService.getFlattenedLocations(stockLocations);
          this.stockOptions = locations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(
              (obj: StockLocation) =>
                new Option(
                  obj.id,
                  obj.name + (obj.id !== contextStore.originLocationId ? ` (${contextStore.name})` : "")
                )
            );
        })
    );
  }

  private prepareForm(): void {
    const digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);

    this.stockForm = this.fb.group(
      {
        sizeValue: [null],
        supplierRef: [null],
        comment: [null, [Validators.required]],
        supplierId: [null, [Validators.required]],
        stockLocationId: [this.selectedItem.defaultLocationId, [Validators.required]],
        price: [0, [Validators.required, Validators.min(0), digitValidator]],
        tare: [this.selectedItem.tare, [Validators.min(0), digitValidator]],
        quantity: [null, [Validators.required, Validators.min(0), digitValidator]],
        weight: new UntypedFormControl(this.selectedItem.weight, {
          updateOn: "change",
          validators: [Validators.min(0), digitValidator],
        }),
        policeBook: true,
      },
      {
        updateOn: "blur",
      } as AbstractControlOptions
    );

    this.stockForm.setValidators(
      CommonValidatorsUtil.weightAboveTareValidator(
        this.stockForm.get("weight"),
        this.stockForm.get("tare"),
        this.selectedItem.weight
      )
    );
    if (this.selectedItem.weight) {
      this.stockForm.controls.weight.setValidators(Validators.required);
    }
    if (this.selectedItem.tare) {
      this.stockForm.controls.tare.setValidators(Validators.required);
    }
    this.subscriptionService.subs.push(
      this.stockForm.controls.weight.valueChanges.subscribe(weight => {
        this.allowCheckbox = !this.isNullOrEmptyOrZero(weight);
      })
    );
  }

  private setSizeValueFormValue(): void {
    if (this.selectedItem.sizeCategory) {
      const sizeValue = this.selectedItem.sizeCategory.elements.find((element: OverrideSizeValue) => element.byDefault);
      if (sizeValue) {
        this.stockForm.controls.sizeValue.setValue(
          this.sizeCategories
            .find((category: SizeCategory) => category.id === this.selectedItem.sizeCategory.sizeCategoryId)
            .elements.find((value: SizeValue) => value.id === sizeValue.sizeValueId).value
        );
      }
    }
  }
}
