/* eslint-disable no-magic-numbers */
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faChevronLeft, faLongArrowAltRight, faPrint, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import {
  AbstractCustomer,
  Pagination,
  MetalAccountMovement,
  MetalAccount,
  Supplier,
  MetalAccountMovementService,
  SupplierService,
  CaraUserService,
  CustomTranslateService,
  CaraUser,
  PaginatedList,
  Sort,
  AsynchronousTaskService,
  CustomerService,
  AsynchronousTaskCreation,
  ContactType,
} from "center-services";
import {
  Filter,
  FilterPreference,
  FilterValue,
  Option,
  SearchFilter,
  SearchFilterOperator,
  SubscriptionService,
} from "fugu-common";
import { MessageService, ToastMessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { combineLatest, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-metal-account-detail",
  templateUrl: "./metal-account-detail.component.html",
  styleUrls: ["./metal-account-detail.component.scss"],
  providers: [SubscriptionService],
})
export class MetalAccountDetailComponent extends FilteredTableListComponent implements OnInit, AfterViewInit {
  [x: string]: any;
  public static SUPPLIER_LIST_ID: string = "app-metal-account-detail.supplier-metal-account-detail-table";
  public static CUSTOMER_LIST_ID: string = "app-metal-account-detail.customer-metal-account-detail-table";

  @ViewChild("movementTable") movementTable: any;
  @ViewChild("table") table: DatatableComponent;

  public listId: string;

  public faLongArrowRight: IconDefinition = faLongArrowAltRight;
  public faPrint: IconDefinition = faPrint;
  public accountForm: UntypedFormControl;

  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public filterValues: FilterValue[] = [];
  public sorts: any[] = [
    { prop: "date", dir: "desc" },
    { prop: "id", dir: "desc" },
  ];
  public metalMovements: MetalAccountMovement[] = [];
  public activeMetalAccounts: MetalAccount[] = [];
  public metalAccountList: MetalAccount[] = [];
  public selectedMetalAccount: MetalAccount;
  public popupVisible: boolean = false;
  public faChevronLeft: IconDefinition = faChevronLeft;
  public filters: Filter[];
  public subTitle: string = "";
  public movementRows: any[];
  public accountRows: any[];
  public dateFormat: string;
  public locale: string;
  public contact: Supplier | AbstractCustomer;
  public contactType: ContactType;

  private activeFilters: SearchFilter[] = [];
  private readonly DATE_FILTER_ID: number = 0;
  private readonly TYPE_FILTER_ID: number = 1;
  private readonly ORDER_FILTER_ID: number = 3;
  private readonly WEIGHT_FILTER_ID: number = 2;
  private readonly DOCUMENT_FILTER_ID: number = 4;
  private readonly COMMENT_FILTER_ID: number = 5;

  private isLoaded: Subject<never> = new Subject();
  private initObservables: Observable<any>[] = [];
  private contactId: number;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private metalMouvementService: MetalAccountMovementService,
    private supplierService: SupplierService,
    private customerService: CustomerService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private asynchronousTaskService: AsynchronousTaskService,
    private notificationHandlerService: NotificationHandlerService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  public ngOnInit(): void {
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
      })
    );
    this.contactId = this.route.snapshot.queryParams.contactId;
    this.contactType = this.route.snapshot.queryParams.contactType;

    this.contactType === ContactType.SUPPLIER
      ? this.initObservables.push(this.fetchSupplierData())
      : this.initObservables.push(this.fetchCustomerData());

    this.listId =
      this.contactType === ContactType.SUPPLIER
        ? MetalAccountDetailComponent.SUPPLIER_LIST_ID
        : MetalAccountDetailComponent.CUSTOMER_LIST_ID;

    this.initObservables.push(this.fetchDateFormat());
    this.initObservables.push(this.fetchTranslations());
    this.accountForm = new UntypedFormControl({});

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.contact.archived) {
          this.router.navigateByUrl("metal-account-general");
        }
        this.subTitle = this.contact.name;
        this.prepareAccountForm();
        this.addAccountRows();

        this.isLoaded.complete();
      })
    );
  }

  fillActivatedFilter(filterValue: FilterValue): void {
    switch (filterValue.filterId) {
      case this.DOCUMENT_FILTER_ID:
        this.activeFilters.push(new SearchFilter("documentNumber", SearchFilterOperator.LIKE, filterValue.equal));
        break;

      case this.ORDER_FILTER_ID:
        this.activeFilters.push(new SearchFilter("orderNumber", SearchFilterOperator.LIKE, filterValue.equal));
        break;

      case this.COMMENT_FILTER_ID:
        this.activeFilters.push(new SearchFilter("comment", SearchFilterOperator.LIKE, filterValue.equal));
        break;

      case this.WEIGHT_FILTER_ID:
        this.applyQuantityFilter(filterValue);
        break;

      case this.TYPE_FILTER_ID:
        this.applyTypeFilter(filterValue);
        break;

      case this.DATE_FILTER_ID:
        this.applyDateFilter(filterValue);
        break;

      default:
        break;
    }
  }

  public ngAfterViewInit(): void {
    this.subscriptionService.subs.push(
      this.isLoaded.subscribe({
        complete: () => {
          this.accountForm.setValue(0);
          this.computeSearchFilters();
          this.fetchMetalAccountMovementsData();
        },
      })
    );
    this.cdRef.detectChanges();
  }

  public applyFilters(): void {
    this.resetActivatedFilters();
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterValues.map(fv => fv.filterId),
        this.listId
      ).subscribe(() => {
        this.fetchMetalAccountMovementsData();
      })
    );
  }

  public backToGeneralMetalAccounts(): void {
    this.contactType === ContactType.SUPPLIER
      ? this.router.navigateByUrl("/metal-account-general/supplier")
      : this.router.navigateByUrl("/metal-account-general/customer");
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchMetalAccountMovementsData();
  }

  public changeSortSettings(prop: string, direction: string): void {
    switch (prop) {
      case "date":
        this.sorts = [
          { prop: "date", dir: direction },
          { prop: "id", dir: direction },
        ];
        break;
      case "type":
        this.sorts = [{ prop: "quantity", dir: direction }];
        break;
      default:
        this.sorts = [{ prop, dir: direction }];
        break;
    }
    this.fetchMetalAccountMovementsData();
  }

  public getCellClass(activated: boolean, quantity: number = null): string {
    if (!activated) {
      return "deactivated";
    }
    if (quantity && quantity < 0) {
      return "negative";
    }
    return "";
  }

  public goToContactMetalManagement(): void {
    if (
      !this.contactId ||
      (this.contactType === ContactType.SUPPLIER && !this.userService.canDo("SUPPLIER_UPDATE")) ||
      (this.contactType === ContactType.CUSTOMER && !this.userService.canDo("CUSTOMER_UPDATE"))
    ) {
      return;
    }
    const contact = this.contactType === ContactType.SUPPLIER ? "supplier" : "customer";
    this.router.navigateByUrl(`/${contact}/update/${this.contactId}/metal-management`);
  }

  public printMetalAccountMovements(): void {
    const asyncCreationTask = {
      type: "generatePdf",
      params: `metal-account;${this.activeFilters.map(filter => filter.toQuery()).join(",")};${
        this.selectedMetalAccount.id
      };`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  public onClosePopup(): void {
    this.popupVisible = false;
  }

  public onValidatePopup(): void {
    if (this.contactType === ContactType.SUPPLIER) {
      this.subscriptionService.subs.push(
        this.fetchSupplierData().subscribe({
          next: () => {
            this.initMovementList();
            this.addAccountRows();
          },
          complete: () => {
            this.fetchMetalAccountMovementsData();
            this.onClosePopup();
          },
        })
      );
    } else {
      this.subscriptionService.subs.push(
        this.fetchCustomerData().subscribe({
          next: () => {
            this.initMovementList();
            this.addAccountRows();
          },
          complete: () => {
            this.fetchMetalAccountMovementsData();
            this.onClosePopup();
          },
        })
      );
    }
  }

  public openMetalAccountPopup(): void {
    if (this.selectedMetalAccount.activated) {
      this.popupVisible = true;
    }
  }

  public selectMetalAccount(event: any): void {
    if (event.type === "click") {
      this.accountForm.setValue(event.row.index);
    }
  }

  public getButtonText(): string {
    return this.contactType === ContactType.SUPPLIER
      ? "metal-account-detail.buttons.metal-management-supplier"
      : "metal-account-detail.buttons.metal-management-customer";
  }

  private computeSearchFilters(): void {
    for (const filterValue of this.filterValues) {
      this.fillActivatedFilter(filterValue);
    }
  }

  private fetchTranslations(): Observable<boolean> {
    return (this.translateService as CustomTranslateService).translationsLoaded().pipe(
      tap(() => {
        this.initFilters();
        this.initFilterValues();
      })
    );
  }

  private addAccountRows(): void {
    this.fillMetalAccountList();
    this.accountRows = [];

    this.metalAccountList.forEach((metalAccount: MetalAccount, index: number) => {
      const selectOption = [new Option(index, null)];
      const accountRow: any = {
        index,
        metalAccount,
        selectOption,
      };
      this.accountRows.push(accountRow);
    });
    this.accountRows = [...this.accountRows];
    this.activeMetalAccounts = this.metalAccountList.filter((metalAccount: MetalAccount) => metalAccount.activated);
  }

  private addMovementRows(): void {
    this.movementRows = [];

    this.metalMovements.forEach((metalAccountMovement: MetalAccountMovement) => {
      const type = this.getTypeElement(metalAccountMovement.quantity);

      const movementRow: any = {
        type,
        id: metalAccountMovement.id,
        date: metalAccountMovement.date,
        balance: metalAccountMovement.balance,
        comment: metalAccountMovement.comment,
        quantity: metalAccountMovement.quantity,
        orderNumber: metalAccountMovement.orderNumber,
        documentNumber: metalAccountMovement.documentNumber,
      };
      this.movementRows.push(movementRow);
    });
    this.movementRows = [...this.movementRows];
    if (this.table) {
      this.table.sorts = this.sorts;
      this.table.offset = this.pager.number;
    }
  }

  private applyDateFilter(filterValue: FilterValue): void {
    if (filterValue.to) {
      const toDate = dayjs(filterValue.to);
      this.activeFilters.push(
        new SearchFilter("date", SearchFilterOperator.LOWER_EQUAL_THAN, toDate.add(1, "d").valueOf().toString())
      );
    }
    if (filterValue.from) {
      this.activeFilters.push(
        new SearchFilter("date", SearchFilterOperator.GREATER_EQUAL_THAN, filterValue.from.valueOf().toString())
      );
    }
  }

  private applyQuantityFilter(filterValue: FilterValue): void {
    if (filterValue.min !== null) {
      this.activeFilters.push(
        new SearchFilter("quantity", SearchFilterOperator.GREATER_EQUAL_THAN, filterValue.min.toString())
      );
    }
    if (filterValue.max !== null) {
      this.activeFilters.push(
        new SearchFilter("quantity", SearchFilterOperator.LOWER_EQUAL_THAN, filterValue.max.toString())
      );
    }
  }

  private applyTypeFilter(filterValue: FilterValue): void {
    if (filterValue.equal && (filterValue.equal as number[]).length === 1) {
      const operator = filterValue.equal[0] === 0 ? "GREATER_THAN" : "LOWER_THAN";
      this.activeFilters.push(new SearchFilter("quantity", SearchFilterOperator[operator], "0"));
    }
  }

  private fetchDateFormat(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap((user: CaraUser) => {
        this.dateFormat = user.dateFormat;
      })
    );
  }

  private fetchMetalAccountMovementsData(): void {
    this.subscriptionService.subs.push(
      this.metalMouvementService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<MetalAccountMovement>) => {
          this.metalMovements = result.data;
          this.pager = result.page;
          this.addMovementRows();
        },
        () => {
          const res = this.translateService.instant("metal-account-detail.errors.get-metal-movements");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private fetchSupplierData(): Observable<Supplier> {
    return this.supplierService.get(this.contactId).pipe(
      tap(
        (supplier: Supplier) => {
          this.contact = supplier;
        },
        () => {
          const res = this.translateService.instant("metal-account-detail.errors.get-supplier");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private fetchCustomerData(): Observable<AbstractCustomer> {
    return this.customerService.get(this.contactId).pipe(
      tap(
        (customer: AbstractCustomer) => {
          this.contact = customer;
        },
        () => {
          const res = this.translateService.instant("metal-account-detail.errors.get-customer");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  private getFilterLabel(name: string): string {
    return this.translateService.instant(`metal-account-detail.movement-datatable.columns.${name}`);
  }

  private fillMetalAccountList(): void {
    this.metalAccountList = this.contact.metalAccounts.filter((metalAccount: MetalAccount) => {
      return metalAccount.activated || metalAccount.hasMovements;
    });
  }

  private getSorter(): any {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(s.prop, s.dir));
    }
    return sorter;
  }

  private getTypeElement(quantity: number): any {
    if (quantity >= 0) {
      return {
        label: this.translateService.instant("metal-account-detail.type.credit"),
        className: "in",
      };
    } else {
      return {
        label: this.translateService.instant("metal-account-detail.type.debit"),
        className: "out",
      };
    }
  }

  private initDateFilter(): Filter {
    return new Filter(this.DATE_FILTER_ID, this.getFilterLabel("date"), "date", null, null, true);
  }

  private initTypeFilter(): Filter {
    const options = [
      new Option(0, this.translateService.instant("metal-account-detail.type.credit")),
      new Option(1, this.translateService.instant("metal-account-detail.type.debit")),
    ];
    return new Filter(
      this.TYPE_FILTER_ID,
      this.getFilterLabel("type"),
      "list",
      null,
      options,
      false,
      this.isFilterSaved(this.listId, this.TYPE_FILTER_ID)
    );
  }

  private initFilterValues(): void {
    this.filterValues = [];

    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);

    this.filterValues.push(FilterValue.buildDateValue(this.DATE_FILTER_ID, dayjs(firstDay), dayjs(lastDay)));

    const componentFilterPref = this.userPreferences?.filterComponents?.find(
      filterPrefComponent => filterPrefComponent.component === this.listId
    );

    componentFilterPref?.filters
      ?.filter(pref => pref.id !== 0)
      .forEach((filter: FilterPreference) => {
        if (filter.id === this.WEIGHT_FILTER_ID) {
          this.filterValues.push(FilterValue.buildRangeValue(filter.id, null, null));
        } else {
          this.filterValues.push(FilterValue.buildEqualValue(filter.id, null));
        }
      });
  }

  private initFilters(): void {
    this.filters = [];
    this.filters.push(this.initDateFilter());
    this.filters.push(this.initTypeFilter());
    this.filters.push(
      new Filter(
        this.WEIGHT_FILTER_ID,
        this.getFilterLabel("quantity"),
        "range",
        null,
        null,
        false,
        this.isFilterSaved(this.listId, this.WEIGHT_FILTER_ID)
      )
    );
    this.filters.push(
      new Filter(
        this.ORDER_FILTER_ID,
        this.getFilterLabel("order-number"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.listId, this.ORDER_FILTER_ID)
      )
    );
    this.filters.push(
      new Filter(
        this.DOCUMENT_FILTER_ID,
        this.getFilterLabel("document-number"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.listId, this.DOCUMENT_FILTER_ID)
      )
    );
    this.filters.push(
      new Filter(
        this.COMMENT_FILTER_ID,
        this.getFilterLabel("comment"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.listId, this.COMMENT_FILTER_ID)
      )
    );
  }

  private initMovementList(): void {
    this.selectedMetalAccount = this.metalAccountList[this.accountForm.value];
    this.resetActivatedFilters();
    this.resetSortSettings();
    this.pager.number = 0;
    this.applyFilters();
  }

  private prepareAccountForm(): void {
    this.subscriptionService.subs.push(
      this.accountForm.valueChanges.subscribe((index: number) => {
        if (!this.selectedMetalAccount || index !== this.contact.metalAccounts.indexOf(this.selectedMetalAccount)) {
          this.initMovementList();
        }
      })
    );
  }

  private resetActivatedFilters(): void {
    // remove all active filters for metalAccountMovements and add a filter to only get the movements of
    // the selected metalAccount
    this.activeFilters = [];

    this.activeFilters.push(
      new SearchFilter("metalAccountId", SearchFilterOperator.EQUAL, this.selectedMetalAccount.id.toString())
    );
  }

  private resetSortSettings(): void {
    this.sorts = [
      { prop: "date", dir: "desc" },
      { prop: "id", dir: "desc" },
    ];
    this.movementTable.sorts = [];
  }
}
