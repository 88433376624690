import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { IconDefinition, faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { Light, LightService, AuthService, ShipmentType } from "center-services";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ShipmentPopup } from "./shipment-popup";

@Component({
  selector: "app-shipment-initiator-popup",
  templateUrl: "./shipment-initiator-popup.component.html",
  styleUrls: ["./shipment-initiator-popup.component.scss"],
  providers: [SubscriptionService],
})
export class ShipmentInitiatorPopupComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();

  public deliveryTypeLabel: string;

  public popupForm: UntypedFormGroup;
  public shouldClose: boolean = false;
  public recipientOptions: Option[] = [];
  public supplierOptions: Option[];
  public customerOptions: Option[];
  public storeOptions: Option[];
  public supplierList: Light[] = [];
  public customerList: Light[] = [];
  public storeList: Light[] = [];

  public initialShipmentPopup: ShipmentPopup;
  public unsavedShipmentPopup: ShipmentPopup;
  public selectedShipmentPopup: ShipmentPopup;
  faArrowCircleRight: IconDefinition = faArrowCircleRight;
  private initObservables: Observable<any>[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private lightService: LightService,
    private authService: AuthService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchCustomers());
    this.initObservables.push(this.fetchStores());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.buildRecipientOptions();
        this.subscriptionService.subs.push(
          this.translateService.onLangChange.subscribe(() => {
            this.buildRecipientOptions();
          })
        );
        this.initializePopup();
      })
    );
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      recipient: [0, [Validators.required]],
      supplier: [null, [Validators.required]],
    });

    this.subscriptionService.subs.push(
      this.popupForm.controls.recipient.valueChanges.subscribe(() => {
        switch (this.popupForm.controls.recipient.value) {
          case ShipmentType.SUPPLIER:
            this.popupForm.addControl("supplier", new UntypedFormControl(null, Validators.required));
            this.popupForm.removeControl("customer");
            this.popupForm.removeControl("store");
            break;
          case ShipmentType.CUSTOMER:
            this.popupForm.addControl("customer", new UntypedFormControl(null, Validators.required));
            this.popupForm.removeControl("supplier");
            this.popupForm.removeControl("store");
            break;
          case ShipmentType.STORE:
            this.popupForm.addControl("store", new UntypedFormControl(null, Validators.required));
            this.popupForm.removeControl("supplier");
            this.popupForm.removeControl("customer");
            break;
          default:
            break;
        }
      })
    );
  }

  buildRecipientOptions(): void {
    this.recipientOptions = [];
    this.recipientOptions.push(
      new Option(ShipmentType.SUPPLIER, this.translateService.instant("shipment-initiator-popup.options.supplier"))
    );
    this.recipientOptions.push(
      new Option(ShipmentType.CUSTOMER, this.translateService.instant("shipment-initiator-popup.options.customer"))
    );
    this.recipientOptions.push(
      new Option(ShipmentType.STORE, this.translateService.instant("shipment-initiator-popup.options.store"))
    );
  }

  submit(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }
    switch (this.popupForm.controls.recipient.value) {
      case ShipmentType.SUPPLIER:
        this.submitNavigate("SUPPLIER", this.popupForm.controls.supplier.value);
        break;
      case ShipmentType.CUSTOMER:
        this.submitNavigate("CUSTOMER", this.popupForm.controls.customer.value);
        break;
      case ShipmentType.STORE:
        this.submitNavigate("STORE", this.popupForm.controls.store.value);
        break;
      default:
        break;
    }
  }

  submitNavigate(type: string, recipientId: number): void {
    this.router.navigate(["/shipment-form/add"], {
      queryParams: {
        type,
        recipientId,
      },
    });
  }

  initializePopup(): void {
    this.popupForm.controls.recipient.setValue(ShipmentType.SUPPLIER);

    this.selectedShipmentPopup = new ShipmentPopup({
      recipient: 0,
    });
    this.initialShipmentPopup = new ShipmentPopup(this.selectedShipmentPopup);
  }

  closePopup(): void {
    this.selectedShipmentPopup.recipient = this.popupForm.controls.recipient.value;
    this.selectedShipmentPopup.supplier = this.popupForm.controls.supplier?.value
      ? this.popupForm.controls.supplier.value
      : null;
    this.selectedShipmentPopup.customer = this.popupForm.controls.customer?.value
      ? this.popupForm.controls.customer.value
      : null;
    this.selectedShipmentPopup.store = this.popupForm.controls.store?.value
      ? this.popupForm.controls.store.value
      : null;

    if (this.unsavedShipmentPopup && !this.unsavedShipmentPopup.equals(this.selectedShipmentPopup)) {
      this.shouldClose = false;
    }

    if (!this.initialShipmentPopup.equals(this.selectedShipmentPopup) && !this.shouldClose) {
      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedShipmentPopup = new ShipmentPopup(this.selectedShipmentPopup);
      this.shouldClose = true;
    } else {
      this.close.emit();
    }
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierOptions = lightSuppliers
            .filter(obj => !obj.archived)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.supplierList = lightSuppliers;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-initiator-popup.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchCustomers(): Observable<Light[]> {
    return this.lightService.getCustomers().pipe(
      tap(
        (lightCustomers: Light[]) => {
          this.customerOptions = lightCustomers
            .filter(obj => !obj.archived)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.customerList = lightCustomers;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-initiator-popup.errors.get-customers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private fetchStores(): Observable<Light[]> {
    const selectedStoreId = this.authService.getContextStoreId();
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeOptions = lightStores
            .filter(obj => !obj.archived && obj.id !== selectedStoreId)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.storeList = lightStores;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-initiator-popup.errors.get-stores");
          this.messageService.warn(content, { title });
        }
      )
    );
  }
}
