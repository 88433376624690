import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { IconDefinition, faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { Filterer } from "fugu-common";
import { FluxRow } from "../flux-row";
import { HistorizationRow } from "../historization-row";

@Component({
  selector: "app-flux-popup",
  templateUrl: "./flux-popup.component.html",
  styleUrls: ["./flux-popup.component.scss"],
})
export class FluxPopupComponent implements OnInit, OnChanges {
  @Input() minWidth: string = "60vw";
  @Input() maxWidth: string = "60vw";
  @Input() paneVisible: boolean = false;
  @Input() row: HistorizationRow;
  @Input() types: any[];
  @Input() actions: any[];
  @Input() fields: any[];

  @Output() close: EventEmitter<any> = new EventEmitter();

  public filterer: Filterer;
  public dateFormat: string;
  public allRows: FluxRow[] = [];
  public rows: FluxRow[] = [];
  public title: string;
  public faUpRightFromSquare: IconDefinition = faUpRightFromSquare;
  public sorts: any[] = [
    {
      prop: "date",
      dir: "desc",
    },
  ];

  constructor(private translateService: TranslateService, public router: Router) {}

  public rowClass: any = () => {
    return { "not-clickable": true };
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.row) {
      this.title = this.translateService.instant("history.history-detail.title") + this.row.date;
      this.allRows = changes.row.currentValue?.flux;
      this.rows = [...this.allRows];
    }
  }

  ngOnInit(): void {
    this.title = this.translateService.instant("history.history-detail.title") + this.row.date;
    this.initFilters();
  }

  closePane(): void {
    this.close.emit("close");
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    this.filterer = new Filterer();
    this.filterer.addListFilter(
      "type",
      this.translateService.instant("flux-list.datatable.columns.type"),
      this.types,
      false,
      false,
      [],
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "objectLinkedReference",
      this.translateService.instant("flux-list.datatable.columns.object-linked-reference"),
      "string",
      false,
      false
    );
    this.filterer.addFilter(
      "id",
      this.translateService.instant("flux-list.datatable.columns.id"),
      "string",
      false,
      false
    );
    this.filterer.addListFilter(
      "field",
      this.translateService.instant("flux-list.datatable.columns.field"),
      this.fields,
      false,
      false,
      [],
      null,
      true,
      false
    );
    this.filterer.addListFilter(
      "action",
      this.translateService.instant("flux-list.datatable.columns.action"),
      this.actions,
      false,
      false,
      [],
      null,
      true,
      false
    );
    this.filterer.addFilter(
      "oldValue",
      this.translateService.instant("flux-list.datatable.columns.old-value"),
      "string",
      false,
      false
    );
    this.filterer.addFilter(
      "newValue",
      this.translateService.instant("flux-list.datatable.columns.new-value"),
      "string",
      false,
      false
    );
  }

  applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);
  }

  openNewWindow(uri: string): void {
    const url: string = this.router.serializeUrl(this.router.createUrlTree([uri]));

    window.open(url, "_blank");
  }

  isString(object: any): boolean {
    return typeof object === "string";
  }
}
