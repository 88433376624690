import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { IconDefinition, faScannerGun } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  Inventory,
  InventoryEntry,
  Pagination,
  ItemCategory,
  Light,
  CaraUserService,
  StockLocationService,
  InventoryService,
  StockEntryService,
  ItemCategoryService,
  LightService,
  StockEntry,
  StockType,
  CaraUser,
  StockLocation,
  PaginatedList,
  Sort,
  StockEntryLocation,
  MovementType,
} from "center-services";
import {
  CommonValidatorsUtil,
  DayjsUtil,
  FilterValue,
  Filterer,
  Option,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent, PrecisionUtil } from "generic-pages";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";
import hash from "string-hash";

@Component({
  selector: "app-inventory-entry",
  templateUrl: "./inventory-entry.component.html",
  styleUrls: ["./inventory-entry.component.scss"],
  providers: [SubscriptionService],
})
export class InventoryEntryComponent extends FilteredTableListComponent implements OnInit, PaginableComponent {
  @ViewChild("stockEntryIdElement", { read: ElementRef }) stockEntryIdElement: ElementRef;
  @ViewChild("table") table: any;
  @Input() inventory: Inventory;
  @Output() inventoryChange: EventEmitter<Inventory> = new EventEmitter<Inventory>();
  @Output() submitValidationPopup: EventEmitter<any> = new EventEmitter();

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public faScannerGun: IconDefinition = faScannerGun;
  public locationOptions: Option[] = [];
  public stockLocations: StockLocation[] = [];
  public originLocationId: number;
  public hiddenValue: string = "";
  public dateFormat: string;
  public locale: string;
  public validationPopupVisible: boolean = false;
  public rows: any = [];
  public invalidBulkRowsMap: Map<any, any> = new Map<any, any>();
  public invalidNoBulkRowsMap: Map<any, any> = new Map<any, any>();
  public displayedInventoryEntriesList: InventoryEntry[] = [];
  public submitted: any = {};

  public topFieldsFormGroup: UntypedFormGroup;
  public datatableFormGroup: UntypedFormGroup;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public sorts: any[] = [{ prop: "id", dir: "desc" }];
  public pageNumber: number = 0;
  public LIST_ID: string = "app-inventory-entry.inventory-entry-table";
  public filterer: Filterer;
  public activeFilters: SearchFilter[] = [];
  public waitEntryCreation: boolean = false;
  public activeStockEntryLocationFilters: SearchFilter[] = [];
  public stockEntryLocationPager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  private readonly LABEL_PREFIX: string = "SE";
  private digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);
  private initObservables: Observable<any>[] = [];
  private sessionPagination: SessionPagination;
  private itemCategoryList: ItemCategory[] = [];
  private supplierList: Light[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    private stockLocationService: StockLocationService,
    private inventoryService: InventoryService,
    private stockEntryService: StockEntryService,
    protected messageService: MessageService,
    private itemCategoryService: ItemCategoryService,
    private lightService: LightService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
    this.datatableFormGroup = new UntypedFormGroup({});
  }

  getPageNumber(_listId: string): number {
    return this.pageNumber;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pageNumber = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.prepareForm();

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }
    this.initObservables.push(this.fetchInventoryEntries());
    this.initObservables.push(this.fetchStockLocations());
    this.initObservables.push(this.fetchItemCategoryList());
    this.initObservables.push(this.fetchSuppliers());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.computeSearchFilters();
      })
    );
  }

  refresh(): void {
    this.subscriptionService.subs.push(
      this.fetchInventoryEntries().subscribe(() => {
        this.datatableFormGroup.markAllAsTouched();
      })
    );
  }

  addRow(inventoryEntry: InventoryEntry): void {
    const row = {
      id: inventoryEntry.id,
      locationId: inventoryEntry.locationId,
      stockEntryId: inventoryEntry.stockEntryId,
      quantity: inventoryEntry.quantity,
    };
    this.rows.push(row);

    this.loadRowStockEntryRelatedData(row);
  }

  getNewInventoryEntry(): InventoryEntry {
    const newInventoryEntry: InventoryEntry = new InventoryEntry();
    newInventoryEntry.id = null;
    newInventoryEntry.quantity = +this.topFieldsFormGroup.get("quantity").value;
    newInventoryEntry.locationId = +this.topFieldsFormGroup.get("location").value;
    newInventoryEntry.stockEntryId = +this.topFieldsFormGroup.get("stockEntryId").value;
    return newInventoryEntry;
  }

  createInventoryEntry(): void {
    this.topFieldsFormGroup.markAllAsTouched();
    this.topFieldsFormGroup.get("quantity").setErrors(null);
    const quantityControl = this.topFieldsFormGroup.get("quantity");
    const stockEntryIdControl = this.topFieldsFormGroup.get("stockEntryId");

    if (!this.isTolerableFormState("add-item")) {
      return;
    }

    // prevent adding inventory entries with quantity gt 1 with serial number stock entries
    this.subscriptionService.subs.push(
      this.fetchStockEntry(+stockEntryIdControl.value).subscribe((stockEntry: StockEntry) => {
        if (stockEntry.stockType === StockType.SERIAL_NUMBER && +quantityControl.value > 1) {
          // trigger quantity field error
          quantityControl.setErrors({ addSerialNumberStockEntry: true });
        } else {
          this.subscriptionService.subs.push(
            this.fetchStockEntryLocations(stockEntryIdControl.value).subscribe(
              (result1: PaginatedList<StockEntryLocation>) => {
                if (result1.data.length === 0) {
                  this.updateInventory();
                } else {
                  let numberOfMatches = 0;
                  // is the new inventory entry within the scope of the store in which the inventory is being taken?
                  result1.data.forEach((sel: StockEntryLocation) => {
                    if (sel.lastMovementType !== MovementType.TRANSIT && this.isLocationIdInPerimeter(sel.locationId)) {
                      numberOfMatches++;
                    }
                  });
                  if (numberOfMatches > 0) {
                    this.updateInventory();
                  } else {
                    stockEntryIdControl.setErrors({ badStockEntryLocation: true });
                  }
                }
              }
            )
          );
        }
      })
    );
  }

  updateInventory(
    withNewInventoryEntry: boolean = true,
    withInventoryDates: boolean = false,
    shouldOpenPopup: boolean = false,
    emit: boolean = false
  ): void {
    if (this.topFieldsFormGroup.get("beginDate").invalid || this.topFieldsFormGroup.get("endDate").invalid) {
      return;
    }

    const inventoryEntries = withNewInventoryEntry ? [this.getNewInventoryEntry()] : this.getEditedInventoryEntries();

    const isUnchangedForm =
      inventoryEntries.length === 0 &&
      this.inventory.beginDate.valueOf() === this.topFieldsFormGroup.get("beginDate").value?.valueOf() &&
      this.inventory.endDate.valueOf() === this.topFieldsFormGroup.get("endDate").value?.valueOf() &&
      !this.inventory.validationDate;

    if (!shouldOpenPopup && isUnchangedForm) {
      return;
    }
    this.inventory.entries = inventoryEntries;
    if (withInventoryDates) {
      this.inventory.beginDate = new Date(this.topFieldsFormGroup.get("beginDate").value);
      this.inventory.endDate = new Date(this.topFieldsFormGroup.get("endDate").value);
    }

    if (this.waitEntryCreation) {
      return;
    }

    this.waitEntryCreation = true;
    this.subscriptionService.subs.push(
      this.inventoryService.update(this.inventory).subscribe({
        next: () => {
          if (!isUnchangedForm) {
            this.displaySuccessMessage();
          }
          if (shouldOpenPopup) {
            this.validationPopupVisible = true;
          }
        },
        error: error => {
          if (error.error?.exception?.name === "DuplicateInventoryEntryForSerialNumberException") {
            this.topFieldsFormGroup.get("stockEntryId").setErrors({ alreadyAddedInventoryEntry: true });
          } else {
            this.displayErrorMessage("inventory-entry.errors.add-inventory-entry", "", false);
          }
          this.waitEntryCreation = false;
        },
        complete: () => {
          if (withNewInventoryEntry) {
            this.sorts = [{ prop: "id", dir: "desc" }];
            this.pager.number = 0;
            this.refresh();
            if (this.topFieldsFormGroup.valid) {
              this.topFieldsFormGroup.get("quantity").setValue(1);
            }
            this.stockEntryIdElement.nativeElement.getElementsByTagName("input")[0].focus();
          }
          if (emit) {
            this.submitValidationPopup.emit();
          }
          this.waitEntryCreation = false;
        },
      })
    );
  }

  fetchInventoryEntries(): Observable<PaginatedList<InventoryEntry>> {
    return this.inventoryService
      .getInventoryEntries(this.inventory.id, this.pager, this.getSorter(), this.activeFilters)
      .pipe(
        tap(
          (result: PaginatedList<InventoryEntry>) => {
            this.rows = [];
            result.data.forEach(ie => {
              this.updateDisplayedEntriesList(ie);
              this.addRow(ie);
            });
            this.pager = result.page;
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
            this.rows = [...this.rows];
          },
          error => {
            this.displayErrorMessage("inventory-entry.errors.get-inventory-entries", error.message);
          }
        )
      );
  }

  fetchItemCategoryList(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (itemCategories: ItemCategory[]) => {
          this.itemCategoryList = itemCategories
            .filter((obj: ItemCategory) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          this.displayErrorMessage("inventory-entry.errors.get-item-categories", error.message);
        }
      )
    );
  }

  validateSecondStage(): void {
    this.inventory.validationDate = new Date();
    this.saveEditedInventory(false, true);
    this.closeValidationPopup();
  }

  closeValidationPopup(): void {
    this.validationPopupVisible = false;
  }

  openValidationPopup(): void {
    this.saveEditedInventory(true);
  }

  isTolerableFormState(action: string): boolean {
    // pre-check the form before api calls
    switch (action) {
      case "add-item":
        return (
          this.topFieldsFormGroup.get("location").valid &&
          (this.topFieldsFormGroup.get("quantity").valid ||
            this.topFieldsFormGroup.get("quantity").hasError("badQuantity")) &&
          (this.topFieldsFormGroup.get("stockEntryId").valid ||
            this.topFieldsFormGroup.get("stockEntryId").hasError("alreadyScanned") ||
            this.topFieldsFormGroup.get("stockEntryId").hasError("unknownStockEntry"))
        );
      case "save-stage":
        return this.datatableFormGroup.valid && !this.topFieldsFormGroup.get("endDate").hasError("badEndDate");
      default:
        return false;
    }
  }

  forbiddenQuantityValidator(stockType: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = +control.value > 1 && stockType === StockType.SERIAL_NUMBER;
      return forbidden ? { addSerialNumberStockEntry: true } : null;
    };
  }

  addRowFormControls(row: any): void {
    if (!this.datatableFormGroup.contains(`quantity_${row.id}`)) {
      this.datatableFormGroup.addControl(
        `quantity_${row.id}`,
        new UntypedFormControl(row.quantity, [
          Validators.required,
          this.digitValidator,
          this.forbiddenQuantityValidator(row.stockType),
        ])
      );
    }

    if (!this.datatableFormGroup.contains(`location_${row.id}`)) {
      this.datatableFormGroup.addControl(
        `location_${row.id}`,
        new UntypedFormControl(row.locationId, Validators.required)
      );
    }

    const quantityControl = this.datatableFormGroup.get(`quantity_${row.id}`);

    this.subscriptionService.subs.push(
      quantityControl.valueChanges.subscribe(() => {
        if (quantityControl.invalid) {
          row.stockType !== StockType.BULK
            ? this.invalidNoBulkRowsMap.set(row.id, { seId: row.stockEntryId })
            : this.invalidBulkRowsMap.set(row.id, { itemRef: row.itemReference });
        } else {
          row.stockType !== StockType.BULK
            ? this.invalidNoBulkRowsMap.delete(row.id)
            : this.invalidBulkRowsMap.delete(row.id);
        }
      })
    );
    this.rows = [...this.rows];
  }

  changeSortSettings(prop: string, direction: string): void {
    this.sorts = [];
    if (prop === "batchNumber") {
      this.sorts.push({
        prop: "stockEntry.stockType",
        dir: "asc",
      });
    } else if (prop === "serialNumber") {
      this.sorts.push({
        prop: "stockEntry.stockType",
        dir: "desc",
      });
    }
    this.sorts.push({
      prop,
      dir: direction,
    });
    this.refresh();
  }

  getRowClass(row: any): any {
    return { inError: row.inError, "not-clickable": true };
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.refresh();
  }

  getEditedInventoryEntries(isDirtyUtility: boolean = false): InventoryEntry[] {
    const editedInventoryEntries = [];
    const inventoryEntriesList: InventoryEntry[] = isDirtyUtility
      ? this.displayedInventoryEntriesList.map(ie => new InventoryEntry(ie))
      : this.displayedInventoryEntriesList;
    for (const oldInventoryEntry of inventoryEntriesList) {
      const rowQuantityValue = +this.datatableFormGroup.get(`quantity_${oldInventoryEntry.id}`).value;
      const rowLocationValue = +this.datatableFormGroup.get(`location_${oldInventoryEntry.id}`).value;

      if (rowQuantityValue !== oldInventoryEntry.quantity || rowLocationValue !== oldInventoryEntry.locationId) {
        oldInventoryEntry.quantity = rowQuantityValue;
        oldInventoryEntry.locationId = rowLocationValue;
        editedInventoryEntries.push(oldInventoryEntry);
      }
    }
    return editedInventoryEntries;
  }

  saveEditedInventory(shouldOpenPopup: boolean = false, emit: boolean = false): void {
    const idList = [];
    this.rows.forEach(row => {
      row.inError = false;
      Object.keys(this.datatableFormGroup.controls).forEach((c: string) => {
        if (this.datatableFormGroup.get(c)?.invalid) {
          idList.push(+c.split("_")[1]);
        }
      });
      if (idList.includes(row.id)) {
        row.inError = true;
      }
    });
    this.rows = [...this.rows];
    if (!this.isTolerableFormState("save-stage")) {
      // get serial number/ bulk se ids
      const noBulkSEIds: Set<number> = new Set<number>();
      [...this.invalidNoBulkRowsMap.values()].forEach(value => noBulkSEIds.add(value.seId));

      if (noBulkSEIds.size > 0) {
        this.displayErrorMessage(
          "inventory-entry.errors.default-invalid-datatable-form",
          { seIds: [...noBulkSEIds].join(", ") },
          false
        );
      }
      // bulk case
      const bulkItemRefs: Set<string> = new Set<string>();
      [...this.invalidBulkRowsMap.values()].forEach(value => bulkItemRefs.add(value.itemRef));

      if (this.invalidBulkRowsMap.size > 0) {
        this.displayErrorMessage(
          "inventory-entry.errors.bulk-invalid-datatable-form",
          { bulkItemRefs: [...bulkItemRefs].join(", ") },
          false
        );
      }
      return;
    }
    this.updateInventory(false, true, shouldOpenPopup, emit);
  }

  displayErrorMessage(errorType: string, message?: any, isWarning: boolean = true): void {
    const title = isWarning
      ? this.translateService.instant("message.title.data-errors")
      : this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(errorType, message);
    isWarning ? this.messageService.warn(content, { title }) : this.messageService.error(content, { title });
  }

  displaySuccessMessage(): void {
    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("inventory-entry.success-message-content");
    this.messageService.success(content, { title });
  }

  updateUpdatedInventory(): void {
    this.inventory.beginDate = this.topFieldsFormGroup.get("beginDate").value
      ? new Date(this.topFieldsFormGroup.get("beginDate").value)
      : null;
    this.inventory.endDate = this.topFieldsFormGroup.get("endDate").value
      ? new Date(this.topFieldsFormGroup.get("endDate").value)
      : null;
    this.inventory.entries = this.getEditedInventoryEntries(true);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener("window:keypress", ["$event"])
  addItemByScanner(event: KeyboardEvent): void {
    if (isNaN(Number(event.key))) {
      switch (event.key) {
        case "Enter":
          if (!this.hiddenValue.startsWith(this.LABEL_PREFIX)) {
            return;
          }
          this.topFieldsFormGroup.get("stockEntryId").setValue(this.hiddenValue.substring(this.LABEL_PREFIX.length));

          if (this.topFieldsFormGroup.valid) {
            this.topFieldsFormGroup.get("quantity").setValue(1);
          }
          this.stockEntryIdElement.nativeElement.getElementsByTagName("input")[0].focus();

          this.hiddenValue = "";
          this.createInventoryEntry();
          break;
        case "E":
          if (this.hiddenValue !== "S") {
            return;
          }
          this.hiddenValue += event.key;
          break;
        case "S":
          if (this.hiddenValue !== "") {
            return;
          }
          this.hiddenValue += event.key;
          break;
        default:
          return;
      }
    } else if (this.hiddenValue.startsWith(this.LABEL_PREFIX)) {
      this.hiddenValue += event.key;
    }
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addListFilter(
      "stockEntry.retailItem.category.name",
      this.translateService.instant("inventory-entry.datatable.columns.item-category"),
      this.itemCategoryList
        .map(cat => cat.name)
        .map(itemCategoryName => {
          return { value: itemCategoryName, displayValue: itemCategoryName };
        })
    );

    this.filterer.addFilter(
      "stockEntry.retailItem.reference",
      this.translateService.instant("inventory-entry.datatable.columns.item-reference"),
      "string"
    );
    this.filterer.addFilter(
      "stockEntry.itemSupplierRef",
      this.translateService.instant("inventory-entry.datatable.columns.item-supplier-reference"),
      "string"
    );
    this.filterer.addFilter(
      "stockEntry.retailItem.name",
      this.translateService.instant("inventory-entry.datatable.columns.item-name"),
      "string"
    );

    this.filterer.addFilter(
      "serial.stockEntry.id",
      this.translateService.instant("inventory-entry.datatable.columns.serial-number"),
      "string"
    );

    this.filterer.addFilter(
      "batch.stockEntry.id",
      this.translateService.instant("inventory-entry.datatable.columns.batch-number"),
      "string"
    );

    this.filterer.addListFilter(
      "stockEntry.supplierName",
      this.translateService.instant("inventory-entry.datatable.columns.supplier"),
      this.supplierList
        .map(sup => sup.name)
        .map(supplierName => {
          return { value: supplierName, displayValue: supplierName };
        })
    );
  }

  applyFilters(): void {
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.refresh();
      })
    );
  }

  private prepareForm(): void {
    this.topFieldsFormGroup = this.fb.group({
      beginDate: [DayjsUtil.dayjsOrNull(this.inventory.beginDate, true), [Validators.required]],
      endDate: [DayjsUtil.dayjsOrNull(this.inventory.endDate, true), [Validators.required]],
      location: [null, [Validators.required]],
      quantity: [1, [Validators.required, this.digitValidator]],
      stockEntryId: [null, [Validators.required]],
    });
    this.topFieldsFormGroup.setValidators([
      CommonValidatorsUtil.dateValidator(
        this.topFieldsFormGroup.controls.beginDate,
        this.topFieldsFormGroup.controls.endDate
      ),
    ]);
  }

  private fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  private fetchStockLocations(): Observable<StockLocation[]> {
    return this.stockLocationService.getAll(["withChildren", "onlyOrigin"], this.inventory.storeId).pipe(
      tap(
        (stockLocations: StockLocation[]) => {
          this.stockLocations = this.stockLocationService.getFlattenedLocations(stockLocations);

          this.locationOptions = this.stockLocations
            .filter(stockLocation => this.inventory.selectedLocationIds.includes(stockLocation.id))
            .map((obj: StockLocation) => new Option(obj.id, obj.name));

          if (this.locationOptions.length > 0) {
            this.originLocationId = this.locationOptions[0].id as number;
            this.topFieldsFormGroup.get("location").setValue(this.originLocationId);
          }
          this.locationOptions.sort((a, b) => a.label.localeCompare(b.label));
        },
        error => {
          this.displayErrorMessage("inventory-entry.errors.get-stock-locations", error.message);
        }
      )
    );
  }

  private isLocationIdInPerimeter(locationId: number): boolean {
    if (this.stockLocations.some((sl: StockLocation) => sl.id === locationId)) {
      return true;
    }
    return false;
  }

  private fetchStockEntryLocations(stockEntryId: number): Observable<PaginatedList<StockEntryLocation>> {
    const filters: SearchFilter[] = [
      new SearchFilter("stockEntry.id", SearchFilterOperator.EQUAL, stockEntryId.toString()),
    ];

    this.activeStockEntryLocationFilters = filters.filter(filter => filter !== null);

    return this.stockEntryService.getStockEntryLocations(
      this.stockEntryLocationPager,
      null,
      null,
      this.activeStockEntryLocationFilters
    );
  }

  private loadRowStockEntryRelatedData(row: any): void {
    this.subscriptionService.subs.push(
      this.fetchStockEntry(row.stockEntryId).subscribe((stockEntry: StockEntry) => {
        row.itemCategory = stockEntry.itemCategoryName;
        row.itemReference = stockEntry.itemReference;
        row.itemSupplierRef = stockEntry.itemSupplierRef;
        row.supplier = stockEntry.supplierName;
        row.itemName = stockEntry.itemName;
        row.serialNumber = null;
        row.batchNumber = null;
        row.stockType = stockEntry.stockType;
        switch (stockEntry.stockType) {
          case StockType.SERIAL_NUMBER:
            row.serialNumber = stockEntry.id;
            break;
          case StockType.BATCH:
          case StockType.BULK:
            row.batchNumber = stockEntry.id;
            break;
          default:
            console.error(`${stockEntry.stockType} is an unknown stock type !`);
        }

        this.addRowFormControls(row);
        this.rows = [...this.rows];
      })
    );
  }

  private fetchStockEntry(stockEntryId: number): Observable<StockEntry> {
    return this.stockEntryService.get(stockEntryId).pipe(
      tap(
        () => {
          /* tslint:disable:no-empty */
        },
        () => {
          this.topFieldsFormGroup.get("stockEntryId").setErrors({ addUnknownReference: true });
          this.stockEntryIdElement.nativeElement.getElementsByTagName("input")[0].blur();
        }
      )
    );
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierList = lightSuppliers
            .filter((light: Light) => !light.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("inventory-entry.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  private propToDto(prop: string): string {
    switch (prop) {
      case "supplier":
        return "stockEntry.supplierName";
      case "serialNumber":
      case "batchNumber":
        return "stockEntry.id";
      case "itemCategory":
        return "stockEntry.retailItem.category.name";
      case "itemReference":
        return "stockEntry.retailItem.reference";
      case "itemSupplierRef":
        return "stockEntry.itemSupplierRef";
      case "itemName":
        return "stockEntry.retailItem.name";
      default:
        return prop;
    }
  }

  private updateDisplayedEntriesList(ie: InventoryEntry): void {
    if (this.displayedInventoryEntriesList.every(inventoryEntry => inventoryEntry.id !== ie.id)) {
      this.displayedInventoryEntriesList.push(ie);
    }
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
    this.activeFilters.forEach((sf, index) => {
      if (sf.key === "serial.stockEntry.id") {
        const filterValue = this.filterer.filterValues.find(fv => fv.filterId === hash("serial.stockEntry.id"));
        this.applyStockType(filterValue, StockType.SERIAL_NUMBER, index);
      }
      if (sf.key === "batch.stockEntry.id") {
        const filterValue = this.filterer.filterValues.find(fv => fv.filterId === hash("batch.stockEntry.id"));
        this.applyStockType(filterValue, [StockType.BATCH, StockType.BULK], index);
      }
    });
  }

  private applyStockType(filterValue: FilterValue, stockType: any, index: number): void {
    let sf;
    if (filterValue.equal === null) {
      return;
    }
    // return no line if user types letters
    if (isNaN(+filterValue.equal)) {
      filterValue.equal = "0";
    }

    if (filterValue.equal !== "") {
      sf = new SearchFilter(
        "stockEntry.id",
        SearchFilterOperator.LIKE,
        filterValue.equal.toString(),
        filterValue.filterId
      );
      this.activeFilters.splice(index, 1, sf);

      this.activeFilters.push(
        new SearchFilter("stockEntry.stockType", SearchFilterOperator.IN, stockType, filterValue.filterId)
      );
    }
  }
}
