import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  Country,
  CountryService,
  CustomerDocument,
  CaraUserService,
  CustomerDocumentType,
  DocumentService,
} from "center-services";
import { MessageService } from "fugu-components";
import { DayjsUtil, Option, SubscriptionService } from "fugu-common";

@Component({
  selector: "app-customer-document-popup",
  templateUrl: "./customer-document-popup.component.html",
  styleUrls: ["./customer-document-popup.component.scss"],
  providers: [SubscriptionService],
})
export class CustomerDocumentPopupComponent implements OnInit {
  @Input() customerDocument: CustomerDocument;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public unsavedCustomerDocument: CustomerDocument;
  public initialCustomerDocument: CustomerDocument;
  public editedCustomerDocument: CustomerDocument;
  public popupForm: UntypedFormGroup;

  public locale: string;
  public dateFormat: string;

  public shouldClose: boolean = false;

  public countryOptions: Option[];
  countryListStatus: string;
  public popupTitle: string;
  public customerDocumentTypeOptions: Option[] = [];

  public selectedFiles: any[] = [];
  public previousFiles: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private countryService: CountryService,
    private messageService: MessageService,
    private userService: CaraUserService,
    private documentService: DocumentService,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    this.fetchCountries();
    this.buildCustomerDocumentTypeOptions();
    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildCustomerDocumentTypeOptions();
      })
    );
    if (!this.customerDocument) {
      this.createCustomerDocument();
      this.popupTitle = this.translateService.instant("customer-document.title.create");
    } else {
      this.editedCustomerDocument = new CustomerDocument(this.customerDocument);
      this.popupTitle = this.translateService.instant("customer-document.title.update");
    }
    this.initializePopup();
  }

  prepareForm(): void {
    this.popupForm = this.fb.group({
      type: [null],
      country: [null],
      reference: [null],
      expirationDate: [null],
      firstDocumentFile: [[]],
      secondDocumentFile: [[]],
    });

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );
    this.subscriptionService.subs.push(
      this.popupForm.controls.firstDocumentFile.valueChanges.subscribe(firstDocumentFile => {
        this.selectFile(firstDocumentFile, 0);
      })
    );
    this.subscriptionService.subs.push(
      this.popupForm.controls.secondDocumentFile.valueChanges.subscribe(secondDocumentFile => {
        this.selectFile(secondDocumentFile, 1);
      })
    );
  }

  createCustomerDocument(): void {
    this.editedCustomerDocument = new CustomerDocument({
      type: null,
      countryId: null,
      reference: null,
      expirationDate: null,
      documentIds: [],
    });
  }

  // Manage the Forms Controls
  initializePopup(): void {
    this.popupForm.controls.type.setValue(Object.keys(CustomerDocumentType).indexOf(this.editedCustomerDocument.type));
    this.popupForm.controls.country.setValue(this.editedCustomerDocument.countryId);
    this.popupForm.controls.reference.setValue(this.editedCustomerDocument.reference);
    this.popupForm.controls.expirationDate.setValue(
      DayjsUtil.dayjsOrNull(this.editedCustomerDocument.expirationDate, true)
    );

    // handle file
    if (this.editedCustomerDocument.id && this.editedCustomerDocument.documentIds.length > 0) {
      this.getDocumentImage(0);
    } else if (this.selectedFiles.length > 0) {
      this.previousFiles = this.selectedFiles;
      this.popupForm.value.firstDocumentFile[0] = this.selectedFiles[0];
      this.popupForm.value.secondDocumentFile[0] = this.selectedFiles[1];
    } else {
      this.selectedFiles = [];
      this.previousFiles = [];
    }
    this.initialCustomerDocument = new CustomerDocument(this.editedCustomerDocument);
  }

  fetchCountries(): void {
    this.subscriptionService.subs.push(
      this.countryService.getAll().subscribe(
        (countries: Country[]) => {
          this.countryOptions = [];
          countries.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          countries
            .filter(country => !country.archived)
            .forEach((country: Country) => {
              this.countryOptions.push(new Option(country.id, country.name));
              if (country.byDefault && !this.editedCustomerDocument.countryId) {
                this.editedCustomerDocument.countryId = country.id;
                this.popupForm.controls.country.patchValue(country.id);
                this.initialCustomerDocument.countryId = country.id;
              }
            });
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("countries-list.errors.get-countries", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
          this.countryListStatus = "error";
        }
      )
    );
  }

  buildCustomerDocumentTypeOptions(): void {
    this.customerDocumentTypeOptions = Object.keys(CustomerDocumentType).map(
      (key, index) => new Option(index, this.translateService.instant(`customer-document.document-type.${key}`))
    );
  }

  applyModifications(): void {
    this.editedCustomerDocument.type = Object.values(CustomerDocumentType)[this.popupForm.value.type];
    this.editedCustomerDocument.countryId = this.popupForm.value.country;
    this.editedCustomerDocument.reference = this.popupForm.value.reference;
    this.editedCustomerDocument.expirationDate = this.popupForm.value.expirationDate
      ? this.popupForm.value.expirationDate.toDate()
      : null;
  }

  submitCustomerDocuments(): void {
    this.applyModifications();
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }
    this.validate.emit(this.editedCustomerDocument);
  }

  // Close the popup
  closePopup(): void {
    this.applyModifications();
    if (this.unsavedCustomerDocument && !this.unsavedCustomerDocument.equals(this.editedCustomerDocument)) {
      this.shouldClose = false;
    }

    if (!this.initialCustomerDocument.equals(this.editedCustomerDocument) && !this.shouldClose) {
      this.shouldClose = true;

      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      const title = this.translateService.instant("global.errors.unsaved-title");
      this.messageService.info(content, { title });

      this.unsavedCustomerDocument = new CustomerDocument(this.editedCustomerDocument);
    } else {
      this.unsavedCustomerDocument = null;
      this.shouldClose = false;
      this.close.emit();
    }
  }

  selectFile(event: any[], fileNumber: number): void {
    this.selectedFiles[fileNumber] = event[0];
  }

  hasFileChanged(): boolean {
    if (this.noFileComparaison(0) && this.noFileComparaison(1)) {
      return false;
    }
    if (this.differentFileComparaison(0) || this.differentFileComparaison(1)) {
      return true;
    }
    return this.nameComparaison(0) || this.nameComparaison(1);
  }

  getFiles(): any[] {
    return this.selectedFiles;
  }

  noFileComparaison(fileNumber: number): boolean {
    return !this.selectedFiles[fileNumber] && !this.previousFiles[fileNumber];
  }

  differentFileComparaison(fileNumber: number): boolean {
    let comparaison =
      (!this.selectedFiles[fileNumber] && this.previousFiles[fileNumber]) ||
      (!this.previousFiles[fileNumber] && this.selectedFiles[fileNumber]);
    if (comparaison === null) {
      comparaison = false;
    }
    return comparaison;
  }

  nameComparaison(fileNumber: number): boolean {
    return this.previousFiles[fileNumber]?.name !== this.selectedFiles[fileNumber]?.name;
  }

  compareFiles(
    file1: any,
    file2: any,
    operatorBetweenFile: any,
    operatorBetweenExpression: any,
    iteration: number
  ): string {
    let concatenedExpression = "";
    for (let i = 0; i <= iteration; i++) {
      concatenedExpression += `(${file1[i]} ${operatorBetweenFile} ${file2})`;
      if (i !== iteration) {
        concatenedExpression += ` ${operatorBetweenExpression} `;
      }
    }
    return concatenedExpression;
  }

  getDocumentImage(fileNumber: number): void {
    // display the current logo
    this.subscriptionService.subs.push(
      this.documentService.downloadFile(this.editedCustomerDocument.documentIds[fileNumber]).subscribe(
        data => {
          if (data.byteLength === 0) {
            this.previousFiles[fileNumber] = null;
            this.selectedFiles[fileNumber] = null;
            if (fileNumber === 0) {
              this.getDocumentImage(1);
            }
          } else {
            this.previousFiles[fileNumber] = data;
            this.selectedFiles[fileNumber] = data;
            if (fileNumber === 0) {
              this.popupForm.value.firstDocumentFile[0] = data;
              this.getDocumentImage(1);
            } else {
              this.popupForm.value.secondDocumentFile[0] = data;
            }
          }
        },
        error => {
          console.error(error.message);
        }
      )
    );
  }

  showSecondFile(): boolean {
    return this.selectedFiles && this.selectedFiles[0] ? this.selectedFiles[0].byteLength > 0 : false;
  }
}
