<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'history-list.title' | translate }}"></ui-title>
    </div>
    <div class="history-list">
      <ui-container>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            applyLabel="{{ 'filters.apply' | translate }}"
            dateFormat="{{ dateFormat }}"
            (apply)="applyFilters()"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div class="history-table">
          <ngx-datatable
            #table
            [rows]="rows"
            class="material"
            columnMode="flex"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [externalSorting]="true"
            [externalPaging]="true"
            [count]="pager.totalElements"
            [offset]="pager.number"
            [limit]="pager.size"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down',
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue, $event)"
            (activate)="showDetails($event)"
          >
            <ngx-datatable-column
              name="{{ 'history-list.datatable.columns.date' | translate }}"
              prop="date"
              headerClass="left-align"
              cellClass="left-align"
              [flexGrow]="1"
            >
              <ng-template ngx-datatable-cell-template let-value="value">
                <div #ellipsis class="ellipsis">
                  {{ value }}

                  <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ value }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'history-list.datatable.columns.store' | translate }}"
              prop="store"
              headerClass="left-align"
              cellClass="left-align"
              [flexGrow]="1"
              [sortable]="false"
            >
              <ng-template ngx-datatable-cell-template let-value="value">
                <div #ellipsis class="ellipsis">
                  {{ value }}

                  <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ value }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'history-list.datatable.columns.type' | translate }}"
              prop="type"
              headerClass="left-align"
              cellClass="left-align"
              [flexGrow]="1"
              [sortable]="false"
            >
              <ng-template ngx-datatable-cell-template let-value="value">
                <div #ellipsis class="ellipsis">
                  {{ value }}

                  <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ value }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'history-list.datatable.columns.reference' | translate }}"
              headerClass="left-align"
              cellClass="left-align"
              [flexGrow]="1"
              prop="mainObject.id"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div #ellipsis class="ellipsis">
                  <div *ngIf="row?.mainObjectLink && row?.reference">
                    <ui-link
                      [text]="row.reference"
                      [linkIcon]="faUpRightFromSquare"
                      (click)="openNewWindow(row.mainObjectLink)"
                      [ellipsis]="true"
                    ></ui-link>
                  </div>

                  <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ row.reference }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'history-list.datatable.columns.action' | translate }}"
              prop="action"
              headerClass="left-align"
              cellClass="left-align"
              [flexGrow]="1"
              [sortable]="false"
            >
              <ng-template ngx-datatable-cell-template let-value="value">
                <div #ellipsis class="ellipsis">
                  {{ value }}

                  <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ value }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'history-list.datatable.columns.user' | translate }}"
              prop="user"
              headerClass="left-align"
              cellClass="left-align"
              [flexGrow]="1"
              [sortable]="false"
            >
              <ng-template ngx-datatable-cell-template let-value="value">
                <div #ellipsis class="ellipsis">
                  {{ value }}

                  <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ value }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'history-list.datatable.columns.type-linked-object' | translate }}"
              prop="typeLinkedObjectTranslate"
              headerClass="left-align"
              cellClass="left-align"
              [flexGrow]="1"
              [sortable]="false"
            >
              <ng-template ngx-datatable-cell-template let-value="value">
                <div #ellipsis class="ellipsis">
                  {{ value }}

                  <ui-tooltip [element]="ellipsis" *ngIf="ellipsis">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ value }}
                    </span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'history-list.datatable.columns.list-linked-ids' | translate }}"
              headerClass="left-align"
              cellClass="left-align ellipsis-counter"
              [flexGrow]="1"
              prop="linkedObjects.reference"
              [sortable]="false"
            >
              <ng-template
                ngx-datatable-cell-template
                let-row="row"
                let-id="row.id"
                let-listLinkedIds="row.listLinkedIds"
              >
                <div class="all">
                  <div class="elements-content">
                    <span class="mr-5 ellipsis">{{ listLinkedIds.join(", ") }}</span>
                  </div>
                  <div class="elements-counter" *ngIf="listLinkedIds.length >= 2">
                    <div #listLinkedIdsCounter>
                      <ui-counter [number]="listLinkedIds.length"></ui-counter>
                    </div>
                    <ui-tooltip [element]="listLinkedIdsCounter">
                      <span *ngFor="let elem of listLinkedIds" class="elements-font">
                        {{ elem }}
                      </span>
                    </ui-tooltip>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="changePage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>
  </div>
</ng-container>
<app-flux-popup
  *ngIf="selectedRow"
  [paneVisible]="showPanel"
  [row]="selectedRow"
  [fields]="fields"
  [types]="types"
  [actions]="actions"
  (close)="closePanel($event)"
>
</app-flux-popup>
