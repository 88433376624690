import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
  AfterViewChecked,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { OverrideSizeCategory, SizeCategory, SizeCategoryService, OverrideSizeValue } from "center-services";
import { SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";

@Component({
  selector: "override-size-categories-list",
  templateUrl: "./override-size-categories-list.component.html",
  styleUrls: ["./override-size-categories-list.component.scss"],
  providers: [SubscriptionService],
})
export class OverrideSizeCategoriesListComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild("table") table: DatatableComponent;

  @Input() overrideSizeCategories: OverrideSizeCategory[];

  @Output() submitEvent: EventEmitter<OverrideSizeCategory> = new EventEmitter<OverrideSizeCategory>();
  @Output() overrideSizeCategoriesChange: EventEmitter<OverrideSizeCategory[]> = new EventEmitter<
    OverrideSizeCategory[]
  >();

  public rows: any[] = [];
  public sorts: any[] = [
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "name",
      dir: "asc",
    },
  ];

  public tableControl: UntypedFormGroup;
  public sizeCategories: SizeCategory[];
  public popupVisible: boolean = false;
  public selectedSizeCategory: OverrideSizeCategory;
  public selectedIdx: number;

  constructor(
    private sizeCategoryService: SizeCategoryService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) {
    this.tableControl = new UntypedFormGroup({});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.overrideSizeCategories && !changes.overrideSizeCategories.firstChange) {
      this.fetchSizeCategories();
    }
  }

  ngOnInit(): void {
    this.fetchSizeCategories();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  goToSizeCategories(): void {
    this.router.navigateByUrl("/settings/size-categories-list");
  }

  addRow(sizeCategory: SizeCategory): void {
    const overrideCateg = this.overrideSizeCategories.find(oC => oC.sizeCategoryId === sizeCategory.id);
    const isOverrideCateg = overrideCateg !== undefined;

    const values = overrideCateg ? this.concatenateValues(overrideCateg) : sizeCategory.elements.map(e => e.value);

    // add row only if it's overriden or if size category is not archived
    if (isOverrideCateg || !sizeCategory.archived) {
      this.rows.push({
        index: this.getIndex(sizeCategory),
        id: sizeCategory.id,
        name: sizeCategory.name,
        values,
        activated: isOverrideCateg,
      });

      if (this.tableControl.controls[`activated_${sizeCategory.id}`]) {
        this.tableControl.controls[`activated_${sizeCategory.id}`].patchValue(isOverrideCateg);
      } else {
        this.tableControl.addControl(`activated_${sizeCategory.id}`, new UntypedFormControl(isOverrideCateg));
      }
    }
  }

  getIndex(sizeCategory: SizeCategory | any): number {
    const id = sizeCategory instanceof SizeCategory ? sizeCategory.id : sizeCategory.sizeCategoryId;
    return this.overrideSizeCategories.findIndex(overrideCateg => overrideCateg.sizeCategoryId === id);
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  getSizeCategoryName(sizeCategoryId: number): string {
    return this.sizeCategories.find((sizeCategory: SizeCategory) => sizeCategory.id === sizeCategoryId).name;
  }

  fetchSizeCategories(): void {
    this.subscriptionService.subs.push(
      this.sizeCategoryService.getAll().subscribe(
        (sizeCategoryList: SizeCategory[]) => {
          this.rows = [];
          this.sizeCategories = sizeCategoryList;

          sizeCategoryList.forEach(categ => {
            this.addRow(categ);
          });
          this.rows = [...this.rows];
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("override-size-category-list.errors.get-size-categories", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  transformSizeCategory(id: number): OverrideSizeCategory {
    if (this.sizeCategories.length > 0) {
      for (const sizeCat of this.sizeCategories) {
        if (sizeCat.id === id) {
          return new OverrideSizeCategory(sizeCat);
        }
      }
    }
    return null;
  }

  updateOverrideSizeCategory(event: any): void {
    if (event.type === "click") {
      if (typeof this.overrideSizeCategories[event.row.index] !== "undefined") {
        this.selectedSizeCategory = new OverrideSizeCategory(this.overrideSizeCategories[event.row.index]);
        this.selectedIdx = event.row.index;
        this.popupVisible = true;
      }
    }
  }

  onValidateSizeCategory(validatedSizeCategory: OverrideSizeCategory): void {
    if (this.selectedIdx !== null) {
      this.overrideSizeCategories[this.selectedIdx] = validatedSizeCategory;
    }

    this.overrideSizeCategories = [...this.overrideSizeCategories];
    this.onClosePopup();

    this.overrideSizeCategoriesChange.emit(this.overrideSizeCategories);
    this.submitEvent.emit(validatedSizeCategory);
  }

  concatenateValues(sizeCategory: OverrideSizeCategory | any): string {
    return sizeCategory.elements.map(e => {
      if (e instanceof OverrideSizeValue) {
        return this.sizeCategories
          .find(sizeCat => sizeCat.id === sizeCategory.sizeCategoryId)
          .elements.find(sizeValue => sizeValue.id === e.sizeValueId).value;
      }
      return e.value;
    });
  }

  onClosePopup(): void {
    this.popupVisible = false;
    this.selectedSizeCategory = null;
  }

  activeCheckboxOnChanges(id: number): void {
    if (this.tableControl.controls[`activated_${id}`].value) {
      this.overrideSizeCategories.push(this.transformSizeCategory(id));
    } else {
      const overrideSizeIndex = this.overrideSizeCategories.findIndex(
        overrideCateg => overrideCateg.sizeCategoryId === id
      );
      if (overrideSizeIndex >= 0) {
        this.overrideSizeCategories.splice(overrideSizeIndex, 1);
      }
    }

    this.overrideSizeCategoriesChange.emit(this.overrideSizeCategories);
    this.fetchSizeCategories();
  }

  getRowClass: any = (row): any => ({ "not-clickable": !this.tableControl.get(`activated_${row.id}`).value });
}
