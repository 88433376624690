import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { InvoiceFormFetcher } from "app/invoice/util/invoice-form-fetcher";
import { CaraUserService, InvoiceCustomerService } from "center-services";
import { MessageService } from "fugu-components";
import { ActivatedRoute, Router } from "@angular/router";
import { CreditNoteBase } from "../util/credit-note-base.directive";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "app-credit-note-customer-details",
  templateUrl: "./credit-note-customer-details.component.html",
  styleUrls: ["./credit-note-customer-details.component.scss"],
  providers: [InvoiceFormFetcher, SubscriptionService],
})
export class CreditNoteCustomerDetailsComponent extends CreditNoteBase implements OnInit {
  public LIST_ID: string = "credit-note.credit-note-customer-details";

  constructor(
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected invoiceCustomerService: InvoiceCustomerService,
    protected router: Router,
    protected route: ActivatedRoute,
    public fetcher: InvoiceFormFetcher,
    protected subscriptionService: SubscriptionService
  ) {
    super(
      userService,
      translateService,
      messageService,
      invoiceCustomerService,
      fetcher,
      router,
      route,
      subscriptionService
    );
    this.listId = this.LIST_ID;
  }

  ngOnInit(): void {
    this.sorts = [
      {
        prop: CreditNoteBase.LINE_NUMBER,
        dir: CreditNoteBase.ASC,
      },
    ];

    const creditNoteCustomerId = this.route.snapshot?.params?.id;

    this.fetcher.fetchInitialData().subscribe(() => {
      this.subscriptionService.subs.push(
        this.fetchCreditNoteCustomer(creditNoteCustomerId).subscribe(() => {
          if (this.creditNoteCustomer.invoiceStatus === "DRAFT") {
            this.backToPrevious();
          }
        })
      );
    });
  }
}
