import { DragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Injectable } from "@angular/core";
import { NgxDatatableDragger } from "./ngx-datatable-dragger";
import { TreeEntity } from "center-services";

@Injectable({
  providedIn: "root",
})
export class NgxTreeDatatableDragger<T extends TreeEntity> extends NgxDatatableDragger<T> {
  private underLevels: Map<number, T[]> = new Map();

  private readonly DISABLED_STATE: string = "disabled";
  private readonly COLLAPSED_STATE: string = "collapsed";

  private readonly DATATABLE_BODY_ROW_SELECTOR: string = "datatable-body-row";
  private readonly LEVEL_0_SELECTOR: string = "level-0";

  public constructor(protected dragDropService: DragDrop) {
    super(dragDropService);
  }

  protected setDragNDropBehavior(): void {
    this.dropListRef.beforeStarted.subscribe(() => this.prepareTreeForDnd());
    this.dropListRef.dropped.subscribe(event => {
      event.previousIndex === event.currentIndex
        ? this.reInjectUnderLevels()
        : this.updatePosition(event.previousIndex, event.currentIndex);
    });
  }

  protected updatePosition(currentIndex: number, newIndex: number): void {
    moveItemInArray(this.referenceItemList, currentIndex, newIndex);

    this.reInjectUnderLevels();

    // update items positions
    this.referenceItemList.filter((item: T) => item.level === 0).forEach((item, step) => (item.position = step + 1));

    this.refreshRows();
  }

  protected customForbiddenElement(_element: HTMLElement): boolean {
    return !_element.querySelector(this.DATATABLE_BODY_ROW_SELECTOR)?.classList.contains(this.LEVEL_0_SELECTOR);
  }

  private reInjectUnderLevels(): void {
    this.underLevels.forEach((values, parentId) => {
      const parentItemIndex: number = this.referenceItemList.findIndex((elt: T) => elt.elementId === parentId);
      values.forEach((underItem: T, step: number) => {
        this.referenceItemList.splice(parentItemIndex + 1 + step, 0, underItem);
      });
    });
  }

  private prepareTreeForDnd(): void {
    this.underLevels = new Map();
    this.referenceItemList.forEach((item: T) => {
      this.collapseItem(item);
      this.collectItemUnderLevels(item);
    });
    this.refreshRows();
  }

  private collapseItem(item: T): void {
    item.treeStatus = item.treeStatus === this.DISABLED_STATE ? this.DISABLED_STATE : this.COLLAPSED_STATE;
  }

  private collectItemUnderLevels(item: T): void {
    if (item.level !== 0) {
      return;
    }

    this.underLevels.set(
      item.elementId,
      this.referenceItemList
        .filter((component: T) => component.parentId === item.elementId)
        .flatMap(child => this.extractSubTree(child))
    );
  }

  private extractSubTree(elt: T): T[] {
    this.referenceItemList.splice(this.referenceItemList.indexOf(elt), 1);
    return [elt].concat(
      this.referenceItemList
        .filter((item: T) => item.parentId === elt.elementId)
        .flatMap(child => this.extractSubTree(child))
    );
  }
}
