import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  Country,
  Currency,
  TagService,
  Tag,
  CountryService,
  CurrencyService,
  DocumentService,
  Address,
  Supplier,
} from "center-services";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService, Tag as TagComponent } from "fugu-components";
import { PrecisionUtil } from "generic-pages";
import { Observable, combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-supplier-general",
  templateUrl: "./supplier-general.component.html",
  styleUrls: ["./supplier-general.component.scss"],
  providers: [SubscriptionService],
})
export class SupplierGeneralComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() editedSupplier: Supplier;

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public allSupplierTags: Tag[];
  public availableTags: TagComponent[] = [];

  public countries: Country[];
  public countryOptions: Option[] = [];

  public currencies: Currency[];
  public currencyOptions: Option[] = [];

  faPlus: IconDefinition = faPlus;

  public whiteLabelOptions: Option[] = [];

  public supplierForm: UntypedFormGroup;
  public selectedFile: any;
  public previousFile: any;

  public pattern: any = {
    P: {
      pattern: new RegExp("[+]|[0-9]"),
      optional: true,
    },
    N: {
      pattern: new RegExp("\\d"),
    },
  };

  private initObservables: Observable<any>[];

  constructor(
    private translateService: TranslateService,
    private tagService: TagService,
    private countryService: CountryService,
    private currencyService: CurrencyService,
    private messageService: MessageService,
    private documentService: DocumentService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    this.initObservables = [];

    this.initObservables.push(this.fetchAllSupplierTags());
    this.initObservables.push(this.fetchCountries());
    this.initObservables.push(this.fetchCurrencies());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.buildWhiteLabelOptions();
        this.subscriptionService.subs.push(
          this.translateService.onLangChange.subscribe(() => {
            this.buildWhiteLabelOptions();
          })
        );

        if (this.editedSupplier) {
          this.loadEditedData();
        }
      })
    );
  }

  ngOnChanges(): void {
    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedSupplier) {
          this.loadEditedData();
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  prepareForm(): void {
    this.supplierForm = this.fb.group({
      name: [null, [Validators.required]],
      reference: [null, [Validators.required]],
      lines: [null],
      cityCode: [null],
      city: [null],
      country: [null, [Validators.required]],
      currency: [null, [Validators.required]],
      email: [null, [Validators.email]],
      phone: [null],
      website: [null],
      intranet: [null],
      codeRCS: [null],
      accountingNumber: [null],
      accountingNumberBis: [null],
      codeVAT: [null],
      whiteLabel: [false, [Validators.required]],
      color: [null],
      logo: [null],
      comment: [null],
      tags: [[]],
      logoFile: [[]],
      manageRemainder: [false, [Validators.required]],
    });
    this.subscriptionService.subs.push(
      this.supplierForm.controls.logoFile.valueChanges.subscribe(logoFile => {
        this.selectFile(logoFile);
      })
    );
  }

  fetchAllSupplierTags(): Observable<Tag[]> {
    return this.tagService.getAllTagsOfCategoryByName("Supplier").pipe(
      tap(
        (tags: Tag[]) => {
          this.allSupplierTags = tags;
          this.availableTags = tags
            .filter((obj: Tag) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Tag) => new TagComponent(obj.id, obj.name, obj.color));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("tags-list.errors.get-tags");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCountries(): Observable<Country[]> {
    return this.countryService.getAll().pipe(
      tap(
        (countries: Country[]) => {
          this.countries = countries;
          countries.forEach((country: Country) => {
            if (country.byDefault && (!this.editedSupplier || !this.editedSupplier.id)) {
              this.supplierForm.controls.country.setValue(country.id);
            }
          });
          this.countryOptions = countries
            .filter((obj: Country) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Country) => new Option(obj.id, obj.name));
          if (this.editedSupplier && this.editedSupplier.address && this.editedSupplier.address.countryId) {
            this.supplierForm.controls.country.setValue(this.editedSupplier.address.countryId);
          }
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("countries-list.errors.get-countries");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencies = currencies;
          currencies.forEach((currency: Currency) => {
            if (currency.byDefault && (!this.editedSupplier || !this.editedSupplier.id)) {
              this.supplierForm.controls.currency.setValue(currency.id);
            }
          });
          this.currencyOptions = currencies
            .filter((obj: Currency) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Currency) => new Option(obj.id, obj.symbol));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("currencies-list.errors.get-currencies");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  buildWhiteLabelOptions(): void {
    this.whiteLabelOptions[0] = new Option(
      0,
      this.translateService.instant("supplier.general-datas.white-label-options.YES")
    );
    this.whiteLabelOptions[1] = new Option(
      1,
      this.translateService.instant("supplier.general-datas.white-label-options.NO")
    );
  }

  loadEditedData(): void {
    this.supplierForm.controls.name.setValue(this.editedSupplier.name);
    this.supplierForm.controls.reference.setValue(this.editedSupplier.reference);
    if (this.editedSupplier.address) {
      this.supplierForm.controls.lines.setValue(this.editedSupplier.address.lines);
      this.supplierForm.controls.city.setValue(this.editedSupplier.address.city);
      this.supplierForm.controls.cityCode.setValue(this.editedSupplier.address.cityCode);
    }
    this.supplierForm.controls.email.setValue(this.editedSupplier.email);
    this.supplierForm.controls.phone.setValue(this.editedSupplier.phone);
    this.supplierForm.controls.website.setValue(this.editedSupplier.website);
    this.supplierForm.controls.intranet.setValue(this.editedSupplier.intranet);
    this.supplierForm.controls.codeRCS.setValue(this.editedSupplier.codeRCS);
    this.supplierForm.controls.accountingNumber.setValue(this.editedSupplier.accountingNumber);
    this.supplierForm.controls.accountingNumberBis.setValue(this.editedSupplier.accountingNumberBis);
    this.supplierForm.controls.codeVAT.setValue(this.editedSupplier.codeVAT);
    this.supplierForm.controls.color.setValue(this.editedSupplier.color);
    this.supplierForm.controls.logo.setValue(this.editedSupplier.logoId);
    this.supplierForm.controls.comment.setValue(this.editedSupplier.comment);
    this.supplierForm.controls.whiteLabel.setValue(this.editedSupplier.whiteLabel ? 0 : 1);
    this.supplierForm.controls.manageRemainder.setValue(this.editedSupplier.manageRemainder);

    this.supplierForm.controls.tags.setValue([...this.editedSupplier.tagIds]);

    // handle file
    if (this.editedSupplier.id && this.editedSupplier.logoId) {
      this.getLogo();
    } else {
      this.selectedFile = null;
      this.previousFile = null;
    }

    if (this.editedSupplier && this.editedSupplier.currencyId) {
      const editedCurrency = this.currencies.find(
        (currency: Currency) => currency.id === this.editedSupplier.currencyId && !currency.archived
      );
      if (editedCurrency) {
        this.supplierForm.controls.currency.setValue(this.editedSupplier.currencyId);
      } else {
        this.supplierForm.controls.currency.setValue(null);
      }
    }
  }

  getLogo(): void {
    // display the current logo
    this.subscriptionService.subs.push(
      this.documentService.downloadFile(this.editedSupplier.logoId).subscribe(
        data => {
          if (data.byteLength === 0) {
            this.previousFile = null;
            this.selectedFile = null;
          } else {
            this.previousFile = data;
            this.selectedFile = data;
            this.supplierForm.value.logoFile[0] = data;
          }
        },
        error => {
          console.error(error.message);
        }
      )
    );
  }

  selectFile(event: any[]): void {
    this.selectedFile = event[0];
  }

  hasFileChanged(): boolean {
    if (!this.selectedFile && !this.previousFile) {
      return false;
    }
    if ((!this.selectedFile && this.previousFile) || (!this.previousFile && this.selectedFile)) {
      return true;
    }
    return this.previousFile.name !== this.selectedFile.name;
  }

  getFile(): any {
    return this.selectedFile;
  }

  applyModifications(): void {
    // edit supplier with form controls
    const cloneAddress = new Address(this.editedSupplier.address);
    cloneAddress.lines = this.supplierForm.value.lines;
    cloneAddress.city = this.supplierForm.value.city;
    cloneAddress.cityCode = this.supplierForm.value.cityCode;
    cloneAddress.countryId = this.supplierForm.value.country;
    cloneAddress.byDefault = true;

    this.editedSupplier.name = this.supplierForm.value.name;
    this.editedSupplier.reference = this.supplierForm.value.reference;
    this.editedSupplier.email = this.supplierForm.value.email;
    this.editedSupplier.phone = this.supplierForm.value.phone;
    this.editedSupplier.website = this.supplierForm.value.website;
    this.editedSupplier.intranet = this.supplierForm.value.intranet;
    this.editedSupplier.codeRCS = this.supplierForm.value.codeRCS;
    this.editedSupplier.accountingNumber = this.supplierForm.value.accountingNumber;
    this.editedSupplier.accountingNumberBis = this.supplierForm.value.accountingNumberBis;
    this.editedSupplier.codeVAT = this.supplierForm.value.codeVAT;
    this.editedSupplier.color = this.supplierForm.value.color;
    this.editedSupplier.logoId = this.supplierForm.value.logo;
    this.editedSupplier.comment = this.supplierForm.value.comment;
    this.editedSupplier.address = cloneAddress;
    this.editedSupplier.whiteLabel = this.supplierForm.value.whiteLabel === 0;
    this.editedSupplier.manageRemainder = this.supplierForm.value.manageRemainder;
    this.editedSupplier.tagIds = this.supplierForm.value.tags;
    this.editedSupplier.currencyId = this.supplierForm.value.currency;
  }

  updateSupplier(): boolean {
    if (this.supplierForm.invalid) {
      this.supplierForm.markAllAsTouched();
      return false;
    }

    this.applyModifications();

    return true;
  }
}
