<form class="cash-register-form" [formGroup]="popupForm" novalidate>
  <div class="popup-container">
    <ui-popup title="{{ popupTitle | translate }}" (close)="closePopup()">
      <div content>
        <ui-input-text
          type="text"
          label="{{ 'store.cash-register-popup.reference' | translate }}"
          formControlName="reference"
          [status]="popupForm.get('reference') | formStatus"
          [errorMessage]="popupForm.get('reference') | formErrorMessage | translate"
          mandatory="true"
          infoLabel="{{ 'global.unique' | translate }}"
        >
        </ui-input-text>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'global.validate' | translate }}"
                type="save"
                [primary]="true"
                (click)="submitCashRegister()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</form>
