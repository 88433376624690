<ng-container>
  <div class="main-container">
    <!-- image at the left -->
    <div>
      <img class="img-login-window" [src]="loginImgScr" />
    </div>
    <!-- vertical line -->
    <div class="vertical"></div>
    <!-- content at the right -->
    <div class="side-content-container">
      <form class="login-form" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
        <h1 class="title">{{ "login-form.title" | translate }}</h1>
        <span class="subtitle-message">{{ "login-form.subtitle" | translate }}</span>
        <div class="forms-content">
          <div class="first-input">
            <ui-input-text
              label="{{ 'login-form.input.user' | translate }}"
              id="id_user"
              placeholder="{{ 'login-form.input.user' | translate }}"
              formControlName="user"
              status="{{ this.getStatus('user') }}"
              errorMessage="{{ this.getErrorMessage('user') | translate }}"
              (blur)="onBlur('user')"
              (focus)="onFocus('user')"
              customClass="login-input-connection"
              [customPattern]="pattern"
              maskPattern="Q*"
            >
            </ui-input-text>
          </div>
          <div class="second-input">
            <ui-input-text
              label="{{ 'login-form.input.password' | translate }}"
              id="id_password"
              placeholder="{{ 'login-form.input.password' | translate }}"
              formControlName="password"
              type="password"
              status="{{ this.getStatus('password') }}"
              errorMessage="{{ this.getErrorMessage('password') | translate }}"
              (blur)="onBlur('password')"
              (focus)="onFocus('password')"
              customClass="login-input-connection"
            >
            </ui-input-text>
          </div>
          <div class="redirect-container">
            <ui-link
              text="{{ 'login-form.forgotten-password' | translate }}"
              (click)="openForgottenPasswordPopup()"
            ></ui-link>
          </div>
          <div class="buttons">
            <ui-button text="{{ 'login-form.button.login' | translate }}" [primary]="true"></ui-button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- Popup -->
  <gen-change-password-popup *ngIf="popupVisible" (validate)="onValidatePassword()"></gen-change-password-popup>

  <gen-forgotten-password-popup
    *ngIf="forgottenPasswordPopupVisible"
    (close)="onCloseForgottenPasswordPopup()"
  ></gen-forgotten-password-popup>

  <app-store-selection-popup
    *ngIf="storeSelectionPopupVisible"
    [user]="currentUser"
    [login]="true"
    (close)="onCloseStoreSelectionPopup()"
  ></app-store-selection-popup>
</ng-container>
