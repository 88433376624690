/* eslint-disable no-magic-numbers */
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  Promotion,
  Pagination,
  Store,
  StoreLink,
  PromotionService,
  StoreService,
  CaraUserService,
  PaginatedList,
  Sort,
} from "center-services";
import {
  Filterer,
  DayjsUtil,
  SearchFilter,
  SearchFilterOperator,
  CommonValidatorsUtil,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { ErrorUtil, FilteredPopupListComponent } from "generic-pages";
import { filter, map, pairwise, startWith, tap } from "rxjs/operators";

@Component({
  selector: "app-promotions-list",
  templateUrl: "./promotions-list.component.html",
  styleUrls: ["./promotions-list.component.scss"],
  providers: [SubscriptionService],
})
export class PromotionsListComponent extends FilteredPopupListComponent<Promotion> implements OnInit, AfterViewChecked {
  @ViewChild("storesTable") storesTable: any;

  public LIST_ID: string = "app-promotions-list.app-promotions-table";

  public pager: Pagination = new Pagination({
    number: 0,
    size: 7,
  });
  public filterer: Filterer;
  public storeList: Store[];
  public storeRows: any[] = [];
  public currentRows: any[] = [];
  public storeSelection: StoreLink[] = [];
  public filteredStoreRows: any[] = [];
  public offset: number = 0;
  public limit: number = 10;
  public locale: string;
  public dateFormat: string;
  public storeSorts: any[] = [];
  public storePager: Pagination;
  public activeFilters: SearchFilter[] = [];
  private readonly DEFAULT_PAGE_SIZE: number = 7;
  private defaultFilters: any[] = [new SearchFilter("archived", SearchFilterOperator.EQUAL, "false")];

  constructor(
    promotionService: PromotionService,
    translateService: TranslateService,
    messageService: MessageService,
    private storeService: StoreService,
    userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private subscriptionService: SubscriptionService
  ) {
    super(promotionService, userService, translateService, messageService);
  }

  ngOnInit(): void {
    this.sorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "name",
        dir: "asc",
      },
    ];
    this.preparePopupForm();
    super.ngOnInit();

    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  fetchStoreList(): void {
    this.subscriptionService.subs.push(
      this.storeService
        .getAll(this.storePager, this.getSorter(), this.activeFilters)
        .pipe(
          tap(
            (stores: PaginatedList<Store>) => {
              this.storeList = stores.data;
              this.storePager = stores.page;
            },
            error => {
              const title = this.translateService.instant("message.title.data-errors");
              const content = this.translateService.instant("stores-list.errors.get-stores", {
                message: error.message,
              });
              this.messageService.warn(content, { title });
            },
            () => {
              this.storeRows = [];
              this.filteredStoreRows = [];
              this.storeList.forEach((store: Store) => {
                this.addStoreRow(store);
              });

              this.storeRows = [...this.storeRows];

              this.popupForm.controls.storeHeader.setValue(this.areAllCheckboxesChecked(), { emitEvent: false });
            }
          )
        )
        .subscribe()
    );
  }

  onNewEntityClick(): void {
    this.selectedEntity = new Promotion({
      name: null,
      archived: false,
      beginDate: null,
      endDate: null,
      stores: [],
    });
    this.popupTitle = "promotions-list.popup.title-new-promotion";
    this.initializePopup();
  }

  onTableActivate(event: any): any {
    if (event.type !== "click") {
      return;
    }

    if (!this.userService.canDo("PROMOTION_PERIOD_UPDATE")) {
      return;
    }
    const filteredList = this.entityList.filter(promotion => promotion.id === event.row.id);
    if (filteredList.length <= 0) {
      console.error(`can't find promotion with id ${event.row.id}`);
      return;
    }
    this.selectedEntity = new Promotion(filteredList[0]);
    this.popupTitle = `${this.getTranslationPrefix()}.popup.title-update-promotion`;
    this.initializePopup();
  }

  initializePopup(): void {
    this.activeFilters = [];
    this.storeSorts = [
      {
        prop: "storeName",
        dir: "asc",
      },
    ];
    this.activeFilters = [...this.defaultFilters];
    this.storePager = new Pagination({
      size: this.DEFAULT_PAGE_SIZE,
      number: 0,
    });
    this.popupForm.setControl("activatedStores", this.fb.group({}));
    this.initialEntity = new Promotion(this.selectedEntity);
    this.offset = 0;
    this.popupForm.controls.name.setValue(this.selectedEntity.name);
    this.popupForm.controls.beginDate.setValue(DayjsUtil.dayjsOrNull(this.selectedEntity.beginDate, true));
    this.popupForm.controls.endDate.setValue(DayjsUtil.dayjsOrNull(this.selectedEntity.endDate, true));

    this.popupVisible = true;

    this.storeSelection = this.selectedEntity.stores;

    this.initFilters();
    this.computeSearchFilters();

    this.fetchStoreList();
  }

  addStoreRow(store: Store): void {
    const isActivated = this.selectedEntity.stores.some(activatedSL => activatedSL.storeId === store.id);
    const storeRow = {
      storeId: store.id,
      storeName: store.name,
      reference: store.reference,
      city: store.address.city,
      archived: !isActivated,
    };

    this.storeRows.push(storeRow);

    if ((this.popupForm.controls.activatedStores as UntypedFormGroup).controls[`activated_${store.id}`]) {
      (this.popupForm.controls.activatedStores as UntypedFormGroup).controls[`activated_${store.id}`].patchValue(
        isActivated
      );
    } else {
      const control: UntypedFormControl = new UntypedFormControl(isActivated);
      (this.popupForm.controls.activatedStores as UntypedFormGroup).addControl(`activated_${store.id}`, control);

      this.subscriptionService.subs.push(
        control.valueChanges
          .pipe(
            startWith(isActivated),
            pairwise<boolean>(),
            filter(values => values[0] !== values[1]),
            map(values => values[1])
          )
          .subscribe((checked: boolean) => {
            const link: StoreLink = new StoreLink({
              storeId: storeRow.storeId,
              storeName: storeRow.storeName,
              archived: storeRow.archived,
            });
            if (checked) {
              this.storeSelection.push(new StoreLink({ ...link, archived: false }));
              // must sort in order canClosePopup to work
              this.storeSelection.sort((a, b) => a.storeId - b.storeId);
            } else {
              this.storeSelection.splice(
                this.storeSelection.findIndex(sl => sl.storeId === link.storeId),
                1
              );
            }

            this.popupForm.controls.storeHeader.setValue(this.areAllCheckboxesChecked(), { emitEvent: false });
            this.storeSelection = [...this.storeSelection];
          })
      );
    }
  }

  changePage(pageInfo: any): void {
    this.storePager.number = pageInfo.page - 1;

    this.applyModifications();

    this.fetchStoreList();
  }

  changeStoreSortSettings(prop: string, direction: string): void {
    this.storeSorts = [
      {
        prop,
        dir: direction,
      },
    ];

    this.changePage({ page: 1 });
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.storeSorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "storeName":
        return "name";
      case "city":
        return "address.city";
      default:
        return prop;
    }
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.changePage({ page: 1 });
      })
    );
  }

  areAllCheckboxesChecked(): boolean {
    return (
      this.storeList.length ===
      this.storeList.filter((store: Store) =>
        this.storeSelection.some((link: StoreLink) => link.storeId === store.id && !link.archived)
      ).length
    );
  }

  applyModifications(): void {
    this.selectedEntity.beginDate = this.popupForm.get("beginDate").value
      ? new Date(this.popupForm.get("beginDate").value)
      : null;
    this.selectedEntity.endDate = this.popupForm.get("endDate").value
      ? new Date(this.popupForm.get("endDate").value)
      : null;
    this.selectedEntity.name = this.popupForm.value.name;
    this.selectedEntity.stores = this.storeSelection;
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: `${this.getTranslationPrefix()}.datatable.columns.name`,
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  protected cloneEntity(entity: Promotion): Promotion {
    return new Promotion(entity);
  }

  protected canArchive(): boolean {
    return this.userService.canDo("PROMOTION_PERIOD_ARCHIVE");
  }

  protected getTranslationPrefix(): string {
    return "promotions-list";
  }

  protected preparePopupForm(): any {
    this.popupForm = this.fb.group({
      name: [null, [Validators.required]],
      beginDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      storeHeader: [null],
      activatedStores: [null],
    });
    this.popupForm.setValidators([
      CommonValidatorsUtil.dateValidator(this.popupForm.controls.beginDate, this.popupForm.controls.endDate),
    ]);
    this.popupForm.addControl("activatedStores", this.fb.group({}));

    this.subscriptionService.subs.push(
      this.popupForm.controls.storeHeader.valueChanges.subscribe(checked => {
        this.storeList.forEach((store: Store) => {
          (this.popupForm.controls.activatedStores as UntypedFormGroup).controls[`activated_${store.id}`].setValue(
            checked
          );
        });
      })
    );
  }

  protected addRow(promotion: Promotion): any {
    this.rows.push({
      id: promotion.id,
      name: promotion.name,
      activated: !promotion.archived,
      beginDate: promotion.beginDate,
      endDate: promotion.endDate,
      stores: promotion.stores.map(store => store.storeName).sort((a, b) => a.localeCompare(b)),
    });
    this.rows = [...this.rows];
  }

  private initFilters(): void {
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );

    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "name",
      this.translateService.instant("promotions-list.datatable.columns.stores"),
      "string"
    );

    this.filterer.addFilter(
      "reference",
      this.translateService.instant("promotions-list.datatable.columns.reference"),
      "string"
    );

    this.filterer.addFilter(
      "address.city",
      this.translateService.instant("promotions-list.datatable.columns.city"),
      "string"
    );
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }
}
