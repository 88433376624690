import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { IconDefinition, faCalculator, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { StockEntryLocation, Uom, UomService } from "center-services";
import { CommonValidatorsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";
import { StockEntryLocationSelectionOutput } from "./stock-entry-location-selection-output";

@Component({
  selector: "app-multiple-location-selection-popup",
  templateUrl: "./multiple-location-selection-popup.component.html",
  styleUrls: ["./multiple-location-selection-popup.component.scss"],
  providers: [SubscriptionService],
})
export class MultipleLocationSelectionPopupComponent implements OnInit {
  @Input() stockEntryLocations: StockEntryLocation[];
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() stockEntryLocationSelectionOutputE: EventEmitter<any> = new EventEmitter();

  public readonly decimalDigitHigh: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;

  public popupForm: UntypedFormGroup;

  public faCheck: IconDefinition = faCheck;

  public stockEntryLocationOptions: Option[] = [];

  public faCalculate: IconDefinition = faCalculator;

  public currentStockEntryLocation: StockEntryLocation;

  public initialStockEntryLocationSelection: StockEntryLocationSelectionOutput;
  public unsavedStockEntryLocationSelection: StockEntryLocationSelectionOutput;
  public selectedStockEntryLocationSelection: StockEntryLocationSelectionOutput;
  uomList: Uom[];
  currentUom: Uom;

  digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService,
    private uomService: UomService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.subscriptionService.subs.push(
      this.uomService.getAll().subscribe(
        (uomList: Uom[]) => {
          this.uomList = uomList;
          this.stockEntryLocationOptions = this.stockEntryLocations.map(
            stockEntryLocation =>
              new Option(
                stockEntryLocation.id,
                `${stockEntryLocation.locationName} (${stockEntryLocation.quantity} ${
                  this.getUom(stockEntryLocation.stockEntry.uomId).longName
                })`
              )
          );
          this.currentUom = this.getUom(this.stockEntryLocations[0].stockEntry.uomId);
          this.initializePopup();
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-form.errors.get-uoms");
          this.messageService.warn(content, { title });
        }
      )
    );
    this.preparePopupForm();
  }

  public initializePopup(): void {
    this.initialStockEntryLocationSelection = new StockEntryLocationSelectionOutput({
      quantity: null,
      stockLocationId: this.stockEntryLocations[0].id,
    });
    this.unsavedStockEntryLocationSelection = this.cloneStockEntryLocationSelectionOutput(
      this.initialStockEntryLocationSelection
    );
    this.selectedStockEntryLocationSelection = this.cloneStockEntryLocationSelectionOutput(
      this.initialStockEntryLocationSelection
    );
  }

  getUom(uomId: number): Uom {
    return this.uomList.find(uom => uom.id === uomId);
  }

  preparePopupForm(): void {
    this.currentStockEntryLocation = this.stockEntryLocations[0];

    this.popupForm = this.fb.group({
      quantity: [
        null,
        [
          Validators.required,
          this.digitValidator,
          Validators.max(this.currentStockEntryLocation.quantity),
          CommonValidatorsUtil.zeroValueValidator(),
        ],
      ],
      stockLocationId: [this.stockEntryLocations[0].id, [Validators.required]],
    });
  }

  closePopup(): void {
    this.selectedStockEntryLocationSelection = new StockEntryLocationSelectionOutput(this.popupForm.value);
    if (
      !this.selectedStockEntryLocationSelection.equals(this.initialStockEntryLocationSelection) &&
      !this.selectedStockEntryLocationSelection.equals(this.unsavedStockEntryLocationSelection)
    ) {
      this.unsavedStockEntryLocationSelection = this.cloneStockEntryLocationSelectionOutput(
        this.selectedStockEntryLocationSelection
      );
      this.triggerDataLossWarningMessage();
      return;
    }

    this.close.emit("close");
  }

  submitPopup(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    this.emitSubmit();

    this.closePopup();
  }

  emitSubmit(): void {
    this.selectedStockEntryLocationSelection = new StockEntryLocationSelectionOutput(this.popupForm.value);
    this.unsavedStockEntryLocationSelection = this.cloneStockEntryLocationSelectionOutput(
      this.selectedStockEntryLocationSelection
    );
    this.stockEntryLocationSelectionOutputE.emit(this.selectedStockEntryLocationSelection);
  }

  cloneStockEntryLocationSelectionOutput(
    stockEntryLocationSelectionOutput: StockEntryLocationSelectionOutput
  ): StockEntryLocationSelectionOutput {
    return new StockEntryLocationSelectionOutput(stockEntryLocationSelectionOutput);
  }

  onChange(): void {
    if (this.popupForm.value.stockLocationId) {
      this.currentStockEntryLocation = this.stockEntryLocations.find(
        stockEntryLocations => stockEntryLocations.id === this.popupForm.value.stockLocationId
      );
      this.popupForm.controls.quantity.setValidators([
        Validators.required,
        this.digitValidator,
        CommonValidatorsUtil.zeroValueValidator(),
        Validators.max(this.currentStockEntryLocation.quantity),
      ]);
      this.popupForm.controls.quantity.updateValueAndValidity();
      this.currentUom = this.uomList.find(uom => uom.id === this.currentStockEntryLocation.stockEntry.uomId);
    }
  }

  triggerDataLossWarningMessage(): void {
    const title = this.translateService.instant("global.errors.unsaved-title");
    const content = this.translateService.instant("global.errors.unsaved-popin-content");
    this.messageService.info(content, { title });
  }
}
