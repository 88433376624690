<ng-container>
  <div class="main-container">
    <div class="title">{{ "alloy-composition-list.title" | translate }}</div>
    <!-- global list of alloys without override Alloys -->
    <div class="alloy-composition-list">
      <div class="alloys-list">
        <ui-list
          label="{{ 'alloy-composition-list.list.title' | translate }}"
          selectLabel="{{ 'alloy-composition-list.list.select-label' | translate }}"
          [options]="this.alloyOptions"
          [search]="true"
          [formControl]="alloysListControl"
        ></ui-list>
      </div>

      <div class="button-list">
        <ui-button text="{{ 'global.add' | translate }}" type="add" (click)="addAlloy()"></ui-button>
      </div>
    </div>

    <!-- table of alloys -->
    <div class="composition-table">
      <div [formGroup]="tableControl">
        <ngx-datatable
          #table
          [rows]="rows"
          [trackByProp]="'id'"
          class="material"
          columnMode="flex"
          headerHeight="auto"
          footerHeight="50"
          rowHeight="40"
          [limit]="15"
          [rowClass]="getRowClass"
          [cssClasses]="{
            sortAscending: 'fas fa-chevron-up',
            sortDescending: 'fas fa-chevron-down'
          }"
          [messages]="{ emptyMessage: 'global.no-data' | translate }"
        >
          <!-- techname -->
          <ngx-datatable-column
            name="{{ 'alloy-composition-list.datatable.columns.techName' | translate }}"
            prop="techName"
            [flexGrow]="1"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
              <div class="composition-row">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>

          <!-- settings loss rates -->
          <ngx-datatable-column
            name="{{ 'alloy-composition-list.datatable.columns.settings-loss-rates' | translate }}"
            prop="settingsLossRate"
            cellClass="ellipsis-counter"
            [flexGrow]="1"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-lostRate="row.settingsLossRate">
              <p class="ellipsis settingsLostRate" title="{{ lostRate.join(', ') }}">{{ lostRate.join(", ") }}</p>
              <div *ngIf="lostRate.length >= 2" title="{{ row.counter }}">
                <div #lostRateCounter>
                  <ui-counter [number]="lostRate.length"></ui-counter>
                </div>
                <ui-tooltip maxWidth="300px" [element]="lostRateCounter">
                  <span style="font-weight: normal !important; white-space: break-spaces">{{
                    lostRate.join(", ")
                  }}</span>
                </ui-tooltip>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- suppliers loss rates -->
          <ngx-datatable-column
            name="{{ 'alloy-composition-list.datatable.columns.suppliers-loss-rates' | translate }}"
            prop="ssupplierssLossRate"
            cellClass="ellipsis-counter"
            [flexGrow]="1"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-lostRate="row.suppliersLossRate">
              <p class="ellipsis suppliersLostRate" title="{{ lostRate.join(' | ') }}">{{ lostRate.join(" | ") }}</p>
              <div *ngIf="lostRate.length >= 2" title="{{ row.counter }}">
                <div #lostRateCounter>
                  <ui-counter [number]="lostRate.length"></ui-counter>
                </div>
                <ui-tooltip maxWidth="300px" [element]="lostRateCounter">
                  <span style="font-weight: normal !important; white-space: break-spaces">{{
                    lostRate.join("\n")
                  }}</span>
                </ui-tooltip>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- lossRate -->
          <ngx-datatable-column
            name="{{ 'alloy-composition-list.datatable.columns.lossRate' | translate }}"
            prop="lossRate"
            [flexGrow]="1"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-lossRate="row.lossRate" let-id="row.alloyId">
              <ui-input-text
                class="input-fields loss-rate"
                type="text"
                [maskPattern]="lowDecimalDigit"
                suffix=" %"
                [thousandSeparator]="' '"
                customClass="datatable-row"
                [formControl]="tableControl.get(id + '.lossRate')"
                [status]="tableControl.get(id + '.lossRate') | formStatus"
                [errorMessage]="
                  tableControl.get(id + '.lossRate')
                    | formErrorMessage
                      : {
                          customPrefix: 'alloy-composition-list.datatable.errors.lossRate.',
                          useCustomPrefixFor: ['min', 'max']
                        }
                    | translate
                "
                sizeMode="small"
                (blur)="saveInputValue(id + '.lossRate')"
              >
              </ui-input-text>
            </ng-template>
          </ngx-datatable-column>

          <!-- composition -->
          <ngx-datatable-column
            name="{{ 'alloy-composition-list.datatable.columns.composition' | translate }}"
            prop="composition"
            [flexGrow]="1"
          >
            <ng-template
              ngx-datatable-cell-template
              let-row="row"
              let-composition="row.composition"
              let-id="row.alloyId"
            >
              <ui-input-text
                class="input-fields rate"
                type="text"
                [maskPattern]="lowDecimalDigit"
                suffix=" %"
                [thousandSeparator]="' '"
                customClass="datatable-row"
                [formControl]="tableControl.get(id + '.rate')"
                [status]="tableControl.get(id + '.rate') | formStatus"
                [errorMessage]="
                  tableControl.get(id + '.rate')
                    | formErrorMessage
                      : {
                          customPrefix: 'alloy-composition-list.datatable.errors.rate.',
                          useCustomPrefixFor: ['min', 'max']
                        }
                    | translate
                "
                sizeMode="small"
                (blur)="saveInputValue(id + '.rate')"
              >
              </ui-input-text>
            </ng-template>
          </ngx-datatable-column>

          <!-- dump alloy -->
          <ngx-datatable-column
            name="{{ 'alloy-composition-list.datatable.columns.actions' | translate }}"
            headerClass="center-align"
            cellClass="center-align"
            [flexGrow]="0.5"
          >
            <ng-template ngx-datatable-cell-template let-row="row">
              <ui-menu-actions
                [menuActions]="menuActions"
                (actionCalled)="manageActions($event, row)"
              ></ui-menu-actions>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="rowCount">
                {{
                  rowCount
                    | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                    | translate: { rowCount: rowCount }
                }}
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                [pagerRightArrowIcon]="'fas fa-chevron-right'"
                [pagerNextIcon]="'fas fa-chevron-double-right'"
                [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="table.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</ng-container>
