import { ReceivingForm } from "center-services";
import { Comparator } from "fugu-common";

export class InvoiceCustomerInitiatorOutput extends Comparator {
  deliveryForms: ReceivingForm[];
  multipleStoreSelection: boolean;
  batch: boolean;

  constructor(values: any = {}) {
    super();
    this.multipleStoreSelection = values?.multipleStoreSelection;
    this.batch = values.batch;

    this.deliveryForms = values?.deliveryForms?.map((deliveryForm: ReceivingForm) => {
      return new ReceivingForm(deliveryForm);
    });
  }
}
