/* eslint-disable no-magic-numbers */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import {
  IconDefinition,
  faBan,
  faExclamationTriangle,
  faInfoCircle,
  faPause,
  faSplit,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  AbstractReceivingLine,
  DeliveryLineColumn,
  StockLocation,
  AbstractItem,
  DeliveryLineRow,
  ReceiveStatus,
  DeliveryType,
  CreationDeliveryType,
  Currency,
  CaraUserService,
  CaraUser,
  ReceivingPOLine,
  ReceivingFreeLine,
  DeliveryLineStatus,
  DiscountType,
  PurchaseType,
  Action,
  Pagination,
} from "center-services";
import Decimal from "decimal.js";
import {
  CommonValidatorsUtil,
  FilterValue,
  Filterer,
  Option,
  PaginableComponent,
  RoundingUtil,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { FilteredTableListComponent, PrecisionUtil } from "generic-pages";
import { merge, of } from "rxjs";
import { filter, first } from "rxjs/operators";

@Component({
  selector: "app-new-delivery-lines",
  templateUrl: "./new-delivery-lines.component.html",
  styleUrls: ["./new-delivery-lines.component.scss"],
  providers: [SubscriptionService],
})
export class NewDeliveryLinesComponent
  extends FilteredTableListComponent
  implements PaginableComponent, OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Output() linesChange: EventEmitter<AbstractReceivingLine[]> = new EventEmitter();
  @Output() lineSplit: EventEmitter<AbstractReceivingLine[]> = new EventEmitter();
  @Output() openSelectionPopupOutput: EventEmitter<any> = new EventEmitter();
  @Output() rowDeleted: EventEmitter<any> = new EventEmitter();

  @Input() columns: DeliveryLineColumn[] = [];
  @Input() stockLocations: StockLocation[];
  @Input() lines: AbstractReceivingLine[];
  @Input() retailItemList: AbstractItem[];
  // tslint:disable-next-line:no-input-rename
  @Input("rows") globalRows: DeliveryLineRow[] = [];
  @Input() receiveStatus: ReceiveStatus;
  @Input() rowIds: number[] = [];
  @Input() type: DeliveryType;
  @Input() creationType: CreationDeliveryType;
  @Input() readOnly: boolean = false;
  @Input() currency: Currency;
  @Input() action: Action;

  @Input() tabsNumber: number;
  @Input() parentIsDestoy: any;
  @Input() index: number;
  // Summary templates
  @ViewChild("quantitySummary") quantitySummary: any;
  @ViewChild("quantityExpectedSummary") quantityExpectedSummary: any;
  @ViewChild("priceSummary") priceSummary: any;

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public quantityType: string;
  public locale: string;
  public showLineFrozenInfo: boolean = false;
  // icons used in HTML
  public readonly faWarn: IconDefinition = faExclamationTriangle;
  public readonly faInfo: IconDefinition = faInfoCircle;
  public readonly faPause: IconDefinition = faPause;
  public readonly faBan: IconDefinition = faBan;
  public readonly faSplit: IconDefinition = faSplit;
  // forms
  public selectionForm: UntypedFormGroup = new UntypedFormGroup({});
  public locationsOptions: Option[] = [];
  public discountOptions: Option[] = [];
  // rows
  public selectedRows: DeliveryLineRow[] = [];
  public readonly highPrecision: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  // filters
  public filterer: Filterer;
  public allRows: DeliveryLineRow[];
  public rows: DeliveryLineRow[];
  public dateFormat: string;
  public listId: string;
  public sorts: any[] = [
    {
      prop: "lineNumber",
      dir: "asc",
    },
  ];
  public dataSave: boolean = false;
  public saveSessionId: string;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });
  public fieldsNotExistInServiceItem: string[] = [
    "orderedSizeValue",
    "sizeValueAnomaly",
    "expectedSizeValue",
    "expectedSizeValueAnomaly",
    "receivedSizeValue",
    "realUnitPricePerWeight",
    "metalWeightAnomaly",
    "realMetalWeight",
    "realMetalWeightInfo",
    "tare",
    "totalWeight",
    "orderedMetalPrice",
    "supplierTraceabilityNumber",
    "destLocationId",
  ];
  public menuActions: MenuAction[] = [];
  public menuActionsDetail: MenuAction[] = [];
  public splitPopupVisible: boolean = false;
  public selectedLine: AbstractReceivingLine = null;
  public selectedPurchaseUnit: string = null;
  public selectedLineNumber: number = null;
  public selectedItemName: string = null;
  protected sessionPagination: SessionPagination;
  private PERCENT_VALUE: number = 100;
  // anomalies margin
  private readonly priceWeightMargin: number = 5;
  private readonly quantityMargin: number = 10;
  private readonly SPLIT_ACTION_ID: number = 0;
  private readonly DELETE_ACTION_ID: number = 1;
  private digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);
  private minimalValidators: any = [Validators.min(0), this.digitValidator];

  constructor(
    protected translateService: TranslateService,
    protected userService: CaraUserService,
    protected messageService: MessageService,
    protected router: Router,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  public getColumnName(propertyName: string): string {
    return this.translateService.instant(
      `new-delivery-lines.datatable.columns.${this.convertPropertyName(propertyName)}`
    );
  }

  public ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
    } else {
      this.subscriptionService.subs.push(
        this.userService.connectedUser.subscribe((user: CaraUser) => {
          this.locale = user.codeLanguage;
        })
      );
    }
    this.refresh();
  }

  public ngOnDestroy(): void {
    this.applyModifications();
  }

  refresh(): void {
    // Filter lines to get rows linked to PO or free lines
    if (this.rowIds.length) {
      this.initRows(this.globalRows.filter(row => this.rowIds.some(rowId => rowId === row.polId)));
      this.dataSave = true;
      this.listId = "new-delivery-lines.po";
    } else {
      this.initRows(this.globalRows);
      this.dataSave = true;
      this.listId = "new-delivery-lines.free";
    }
    if (this.readOnly) {
      this.listId += ".details";
    }
    this.saveSessionId = this.listId;
    if (this.index !== undefined) {
      this.saveSessionId += `.${this.index}`;
    }

    this.quantityType = this.canSee("receivedQuantity") ? "receivedQuantity" : "expectedQuantity";
    this.showLineFrozenInfo = false;
    this.buildLocationsOptions();
    this.buildDiscountOptions();
    this.prepareSelectionForm();
    this.prepareRowsForm();
    this.checkRowsError();

    if (!this.filterer) {
      this.initFilterer();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Only free line can have a change in number of lines
    if (this.isFree()) {
      this.refresh();
    }
    // if the rows has changed, reload
    if (changes.globalRows && !changes.globalRows.firstChange) {
      if (this.rowIds.length) {
        this.initRows(changes.globalRows.currentValue.filter(row => this.rowIds.some(rowId => rowId === row.polId)));
      } else {
        this.initRows(changes.globalRows.currentValue);
      }
      this.prepareSelectionForm();
      this.prepareRowsForm();
      this.applyFilters();
    }
  }

  public ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  // Method used to save data from rowForms (must be called by receiving-form component)
  public applyModifications(): void {
    this.lines.forEach((line: AbstractReceivingLine) => {
      this.applyModificationOnLine(line);
    });
  }

  public applyModificationOnLine(line: AbstractReceivingLine): void {
    const lineRow = this.globalRows.find(row => row.id === line.id);

    const rowForm = lineRow?.rowForm;
    if (!rowForm) {
      return;
    }

    if (
      rowForm.get("realMetalPrice")?.value &&
      lineRow.purchaseType !== "BASIC" &&
      !rowForm.get("onMetalAccount")?.value &&
      !this.isInternal()
    ) {
      line.metalPrice = this.fixParseInt(rowForm.get("realMetalPrice")?.value);
    }
    if (rowForm.get("realDiscount") && rowForm.get("discountType") && !this.isInternal()) {
      (line as ReceivingPOLine | ReceivingFreeLine).discount = this.fixParseInt(rowForm.get("realDiscount")?.value);

      (line as ReceivingPOLine | ReceivingFreeLine).discountType = this.parseToDiscountType(
        rowForm.get("discountType").value
      );
    }
    if (rowForm.get("realUnitPrice")) {
      line.unitPricePerWeight = this.fixParseInt(rowForm.get("realUnitPricePerWeight")?.value);
      line.useMetalAccount = rowForm.get("onMetalAccount")?.value;
      line.unitPrice = this.fixParseInt(rowForm.get("realUnitPrice")?.value);
    }
    if (rowForm.get("totalWeight")) {
      line.weight = this.fixParseInt(rowForm.get("totalWeight")?.value);
      line.tare = this.fixParseInt(rowForm.get("tare")?.value);
    }
    if (rowForm.get("supplierTraceabilityNumber")) {
      line.supplierRef = rowForm.get("supplierTraceabilityNumber")?.value;
    }
    if (rowForm.get("expectedSizeValue")) {
      line.expectedSizeValue = rowForm.get("expectedSizeValue")?.value;
    }
    if (rowForm.get("receivedSizeValue")) {
      line.receivedSizeValue = rowForm.get("receivedSizeValue")?.value;
    }
    if (rowForm.get("expectedQuantity")) {
      line.expectedQuantity = this.fixParseInt(rowForm.get("expectedQuantity")?.value);
    }
    if (rowForm.get("receivedQuantity")) {
      line.receivedQuantity = this.fixParseInt(rowForm.get("receivedQuantity")?.value);
    }
    if (rowForm.get("destLocationId")) {
      line.destLocationId = rowForm.get("destLocationId")?.value;
    }
    line.status = lineRow.status;
    line.lineNumber = lineRow.lineNumber;
  }

  public canSee(propertyName: string): boolean {
    return this.columns.find(column => column.property === propertyName)?.visible ?? false;
  }

  public canEdit(propertyName: string): boolean {
    return this.columns.find(column => column.property === propertyName)?.editable ?? false;
  }

  public deleteSelectedLines(): void {
    this.selectedRows.forEach(selectedRow => this.removeLine(selectedRow, this.globalRows));
    this.rowDeleted.emit();
    this.rows = [...this.globalRows];
    this.resetSelectedRows();
  }

  public getRowClass(row: DeliveryLineRow): any {
    return {
      strikethrough: row.status === DeliveryLineStatus.REFUSED,
      "not-clickable": true,
      inError: row.inError,
    };
  }

  public changeRegisterStatus(status: string): void {
    this.selectedRows.forEach(selectedRow => {
      this.globalRows.forEach(row => {
        if (selectedRow.id === row.id) {
          row.status = DeliveryLineStatus[status];
          if (row.status === DeliveryLineStatus.REFUSED) {
            row.rowForm.disable();
          } else {
            row.rowForm.enable();
          }
        }
      });
    });
    this.rows = [...this.rows];
    this.resetSelectedRows();
  }

  public convertPropertyName(propertyName: string): string {
    return propertyName.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
  }

  // Method used to get cellClass based on propertyName
  public getCellClass(column: DeliveryLineColumn): string {
    let className = "";
    if (column.highLight) {
      className += "highlight ";
    }
    if (!["supplierRef", "itemRef", "itemName", "brandName", "destLocationId"].includes(column.property)) {
      className += "no-strikethrough ";
    }

    return className;
  }

  // Method used to manage/display rows anomalies
  public hasAnomaly(rowId: string | number, propertyName: string): boolean {
    const row = this.rows.find(displayedRow => displayedRow.id === rowId);
    const rowForm = row.rowForm;
    let refValue: string | number = "";
    let value: string | number = "";

    switch (propertyName) {
      case "sizeValueAnomaly":
        value = row.orderedSizeValue;
        refValue = rowForm.get("expectedSizeValue")
          ? rowForm.get("expectedSizeValue")?.value
          : rowForm.get("receivedSizeValue")?.value;
        if (!refValue) {
          refValue = row.expectedSizeValue;
        }
        return !this.checkAnomalies(refValue, value);
      case "quantityAnomaly":
        value = row.unreceivedQuantity;
        refValue = rowForm.get("expectedQuantity")
          ? rowForm.get("expectedQuantity")?.value
          : rowForm.get("receivedQuantity")?.value;
        if (!refValue) {
          refValue = row.expectedQuantity;
        }
        return !this.checkAnomalies(refValue, value, null, this.quantityMargin);
      case "expectedSizeValueAnomaly":
        refValue = rowForm.get("receivedSizeValue") ? rowForm.get("receivedSizeValue").value : row.receivedSizeValue;
        value = this.canEdit("expectedSizeValue") ? rowForm.get("expectedSizeValue").value : row.expectedSizeValue;
        if (value && (refValue === null || refValue === undefined)) {
          return true;
        }
        return !this.checkAnomalies(refValue, value);
      case "expectedQuantityAnomaly":
        refValue = rowForm.get("receivedQuantity") ? rowForm.get("receivedQuantity").value : row.receivedQuantity;
        value = this.canEdit("expectedQuantity") ? rowForm.get("expectedQuantity").value : row.expectedQuantity;
        return !this.checkAnomalies(+refValue, +value, null, this.quantityMargin);
      case "metalWeightAnomaly":
        refValue = rowForm.get("realMetalWeight") ? rowForm.get("realMetalWeight").value : row.realMetalWeight;

        return !this.checkAnomalies(+refValue, row.orderedMetalWeight, this.priceWeightMargin, this.priceWeightMargin);
      case "totalUnitPriceAnomaly":
        return !this.checkAnomalies(
          row.realTotalUnitPrice,
          row.orderedTotalUnitPrice,
          this.priceWeightMargin,
          this.priceWeightMargin
        );
      default:
        break;
    }
    return false;
  }

  public getFormControl(rowId: string | number, property: string): AbstractControl {
    const row = this.rows.find(displayedRow => displayedRow.id.toString() === rowId.toString());
    return row.rowForm?.get(property);
  }

  // Method used to get input field maskPattern based on propertyName
  public getMaskPattern(propertyName: string): string {
    if (
      [
        "realUnitPricePerWeight",
        "realUnitPrice",
        "realMetalPrice",
        "realMetalWeight",
        "totalWeight",
        "tare",
        "expectedQuantity",
        "receivedQuantity",
        "realDiscount",
      ].includes(propertyName)
    ) {
      return this.decimalDigit;
    }
    return null;
  }

  // Method used to get input field suffix based on propertyName
  public getSeparator(propertyName: string): string {
    if (["realUnitPricePerWeight", "realUnitPrice", "realMetalPrice"].includes(propertyName)) {
      return " ";
    }
    return "";
  }

  // Method used to get status class
  public getStatusClass(status: DeliveryLineStatus): string {
    return `status-${status.toLowerCase().replace("_", "-")}`;
  }

  // Method used to get input field suffix based on propertyName
  public getSuffix(propertyName: string): string {
    if (["realUnitPricePerWeight", "realUnitPrice", "realMetalPrice"].includes(propertyName)) {
      return ` ${this.currency.symbol}`;
    } else if (["realMetalWeight", "totalWeight", "tare"].includes(propertyName)) {
      return " g";
    }
    return "";
  }

  // Method used to get the correct summay template based on propertyName
  public getSummary(propertyName: string): ViewChild {
    if (propertyName === this.quantityType) {
      return this.quantitySummary;
    } else if (propertyName === "totalPrice") {
      return this.priceSummary;
    } else if (propertyName === "expectedQuantity") {
      return this.quantityExpectedSummary;
    } else {
      return null;
    }
  }

  // Method used to get the totalPrice for the price summary
  public getTotalPrice(): number {
    let totalPrice = 0;

    this.rows.forEach(row => {
      totalPrice = new Decimal(totalPrice ?? 0).plus(row.totalPrice ?? 0).toNumber();
    });
    return RoundingUtil.roundLow(totalPrice);
  }

  // Method used to get the totalQuantity for the price summary
  public getTotalQuantity(): number {
    let totalQuantity = 0;

    this.rows.forEach(row => {
      totalQuantity += row.rowForm?.get(this.quantityType)
        ? +row.rowForm.get(this.quantityType).value
        : row[this.quantityType];
    });
    return totalQuantity;
  }

  public getTotalExpectedQuantity(): number {
    let totalExpectedQuantity = 0;

    this.rows.forEach(row => {
      totalExpectedQuantity += row.expectedQuantity;
    });
    return totalExpectedQuantity;
  }

  /**
   * To be called in the child class if an ngOnInit is already done by doing super.ngOnInit().
   * It allows to get the date format of the user, to delete the information in session storage
   * if we change page, and to initialize the filters.
   */
  initFilterer(): void {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.dateFormat = user.dateFormat;
      })
    );
    this.subscriptionService.subs.push(
      this.router.events
        .pipe(
          filter((e): e is NavigationEnd => e instanceof NavigationEnd),
          first()
        )
        .subscribe((nav: NavigationEnd) => {
          if (!nav.url.includes("update") && !nav.url.includes("detail")) {
            this.deleteSessionStorage();
          }
        })
    );
    this.initFilters();
  }

  public onHeaderCheckboxChange(): void {
    this.selectedRows = [];
    const headerValue = this.selectionForm.controls.header.value;
    this.rows.forEach((row: DeliveryLineRow) => {
      if (headerValue) {
        this.selectedRows.push(row);
      }
      this.selectionForm.get(row.id.toString()).patchValue(headerValue);
    });
  }

  public onRowCheckboxChange(): void {
    this.selectedRows = [];
    this.rows.forEach((row: DeliveryLineRow) => {
      if (this.selectionForm.get(row.id.toString()).value) {
        this.selectedRows.push(row);
      }
    });
    this.selectionForm.controls.header.patchValue(this.selectedRows.length === this.globalRows.length);
  }

  // Method used to order DeliveryLineColumn on order property
  public sortByOrder(columns: DeliveryLineColumn[]): DeliveryLineColumn[] {
    return columns.sort((a, b) => a.order - b.order);
  }

  public changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];
    this.savePaginationToSession();
  }

  /**
   * Save all filter values in the user preferences.
   * After that if your component must save his data
   * we will save in session storage (f12 > application > session storage).
   * All filter values with by key the saveSessionId.
   * After we will filter allrows with your filter.
   */
  public applyFilters(): void {
    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.listId
      ).subscribe(() => {
        if (this.dataSave) {
          this.savePaginationToSession();
        }
        this.rows = this.filterer.filterList(this.allRows);
      })
    );
  }

  tareGreaterThanTotalWeightValidator(rowForm: UntypedFormGroup): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return +rowForm.controls.totalWeight.value < +rowForm.controls.tare.value
        ? { tareGreaterThanTotalWeightError: true }
        : null;
    };
  }

  public getDestLocationName(locationId: number): string {
    return this.stockLocations.find(location => location.id === locationId)?.name;
  }

  openSelectionPopup(): void {
    this.openSelectionPopupOutput.emit();
  }

  isFree(): boolean {
    return this.type === DeliveryType.EXTERNAL_SUPPLIER && this.creationType === CreationDeliveryType.FREE;
  }

  isInternal(): boolean {
    return this.type === DeliveryType.INTERNAL;
  }

  /**
   * Allows to delete my filter information in session storage
   */
  deleteSessionStorage(): void {
    let otherId: string;
    if (this.listId.includes("details")) {
      otherId = this.listId.replace(".details", "");
    } else {
      otherId = `${this.listId}.details`;
    }
    SessionPagination.clear(this.listId);
    SessionPagination.clear(otherId);
    if (!this.tabsNumber) {
      return;
    }

    for (let i = 0; i < this.tabsNumber; i++) {
      SessionPagination.clear(`${this.listId}.${i}`);
      SessionPagination.clear(`${otherId}.${i}`);
    }
  }

  /**
   * Permet d'initiliser rows
   * @param rows all rows
   */
  initRows(rows: DeliveryLineRow[]): void {
    this.allRows = [...rows];
    this.rows = rows;
    this.addMenuActions();
    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.addMenuActions();
      })
    );
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  /**
   * call when we call this.sessionPagination.loadFromSession(this.saveSessionId);
   */
  setFilters(_listId: string, _filterValues: FilterValue[]): void {
    const generalFiltersString = sessionStorage.getItem(`${this.listId}.filters`);
    if (!generalFiltersString) {
      this.filterer.filterValues = [..._filterValues];
      return;
    }
    const generalFilters = JSON.parse(generalFiltersString);
    this.filterer.filterValues = [...this.getFiltersValue(_filterValues, generalFilters)];
  }

  /**
   * call when we call this.sessionPagination.loadFromSession(this.saveSessionId);
   */ setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  /**
   * call when we call this.sessionPagination.loadFromSession(this.saveSessionId);
   */ setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  /**
   * Allows to save the filters in the session storage for listId being the key to keep
   * the general state (useful in case of tab for example),
   * and saveSessionId keeping the filters for the particular filter.
   */
  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(this.listId);
    this.sessionPagination.saveToSession(this.saveSessionId);
  }

  public getDiscountUnit(type: DiscountType): string {
    switch (type) {
      case "VALUE":
        return this.currency.symbol;
      case "PERCENT":
        return "%";
      default:
        return "%";
    }
  }

  manageActions(actionId: number, row: DeliveryLineRow): void {
    if (actionId === this.SPLIT_ACTION_ID) {
      this.openSplitPopup(row);
    }
    if (actionId === this.DELETE_ACTION_ID) {
      this.removeLine(row, this.allRows);
      this.rowDeleted.emit();
      this.rows = [...this.allRows];
    }
  }

  addMenuActions(): void {
    this.menuActions = [];

    this.menuActions.push(
      new MenuAction(this.SPLIT_ACTION_ID, this.translateService.instant("new-delivery-lines.actions.split"), faSplit)
    );

    this.menuActionsDetail = [];

    this.menuActionsDetail.push(
      new MenuAction(this.SPLIT_ACTION_ID, this.translateService.instant("new-delivery-lines.actions.split"), faSplit)
    );
    this.menuActionsDetail.push(
      new MenuAction(
        this.DELETE_ACTION_ID,
        this.translateService.instant("new-delivery-lines.actions.delete"),
        faTrashAlt
      )
    );
  }

  openSplitPopup(row: DeliveryLineRow): void {
    this.lines.forEach((line: AbstractReceivingLine) => {
      this.applyModificationOnLine(line);
    });
    this.selectedLine = this.lines.find((line: ReceivingPOLine) => line.id === row.id);
    this.selectedItemName = row.itemName;
    this.selectedPurchaseUnit = row.purchaseUnit;
    this.selectedLineNumber = row.lineNumber;
    this.splitPopupVisible = true;
  }

  // FILTERS

  closePopup(popupName: string): void {
    if (popupName === "split") {
      this.splitPopupVisible = false;
    }
  }

  isStatusValid(status: DeliveryLineStatus): boolean {
    return status !== DeliveryLineStatus.REFUSED;
  }

  public handleSplit(lines: AbstractReceivingLine[]): void {
    this.lineSplit.emit(lines);
  }

  public isDetailled(selectedRow: DeliveryLineRow): boolean {
    return this.allRows.filter(row => row.lineNumber === selectedRow.lineNumber)?.length > 1;
  }

  public isFirstOrOnlyRow(selectedRow: DeliveryLineRow): boolean {
    if (!this.isDetailled(selectedRow)) {
      return true;
    }

    return this.allRows.filter(row => row.lineNumber === selectedRow.lineNumber)?.indexOf(selectedRow) === 0;
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }

  private buildLocationsOptions(): void {
    this.locationsOptions = this.stockLocations
      .filter((obj: StockLocation) => !obj.archived)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((obj: StockLocation) => new Option(obj.id, obj.name));
  }

  private buildDiscountOptions(): void {
    this.discountOptions = [];
    this.discountOptions.push(new Option(Object.keys(DiscountType).indexOf(DiscountType.PERCENT), "%"));
    this.discountOptions.push(new Option(Object.keys(DiscountType).indexOf(DiscountType.VALUE), this.currency.symbol));
  }

  private buildFilterBuilder(): void {
    const componentFilterPref = this.userPreferences?.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.listId
    );
    this.filterer = new Filterer(
      componentFilterPref?.filters,
      this.translateService,
      "new-delivery-lines.datatable.columns"
    );
    const allDestLocationId = new Set(this.allRows.filter(r => r.destLocationId).map(r => r.destLocationId));
    const allDestLocationOptions: any[] = [];
    allDestLocationId.forEach(destId => {
      allDestLocationOptions.push(
        this.stockLocations
          .filter(dest => dest !== undefined && dest?.id === destId)
          .map(dest => ({ value: dest?.id, displayValue: dest?.name }))[0]
      );
    });
    this.columns
      .filter(
        column =>
          column.visible &&
          !column.editable &&
          !column.property?.endsWith("Info") &&
          !column.property?.endsWith("Anomaly") &&
          !column.property?.endsWith("expectedQuantityUnit") &&
          !column.property?.endsWith("receivedQuantityUnit")
      )
      .sort((a, b) => a.order - b.order)
      .forEach(column => {
        switch (column.property) {
          case "destLocationId":
            column.filter.property = "destLocationId";
            column.filter.options = allDestLocationOptions;
            column.filter.searchable = true;
            break;
          case "status":
            column.filter.options = Object.keys(ReceiveStatus).map(item => ({
              value: item,
              displayValue: this.translateService.instant(`new-receipt-list.status-options.${item}`),
            }));
            break;
          case "brandName": {
            const allBrandNames = Array.from(new Set(this.allRows.map(r => r.brandName)));
            column.filter.options = allBrandNames.map(brandName => ({ value: brandName, displayValue: brandName }));
            break;
          }
          default:
            break;
        }
        this.filterer.addItem(column?.filter);
      });
  }

  private checkRowsError(): void {
    this.rows.forEach(row => {
      row.inError = row.rowForm.invalid;
    });
  }

  // Method used to compute the realPrice of a give row
  // eslint-disable-next-line complexity
  private computeRealPrice(row: DeliveryLineRow): void {
    if (!row) {
      return;
    }
    const rowForm = row.rowForm;

    const realUnitPricePerWeight = rowForm.get("realUnitPricePerWeight")
      ? rowForm.get("realUnitPricePerWeight").value
      : row.realUnitPricePerWeight;
    const discountType = rowForm.get("discountType")
      ? Object.values(DiscountType)[rowForm.get("discountType").value]
      : row.discountType;
    const realDiscount = rowForm.get("realDiscount") ? rowForm.get("realDiscount").value : row.realDiscount;
    const onMetalAccount = rowForm.get("onMetalAccount") ? rowForm.get("onMetalAccount").value : row.useMetalAccount;
    const realMetalPrice = rowForm.get("realMetalPrice") ? rowForm.get("realMetalPrice").value : row.realMetalPrice;
    const realUnitPrice = rowForm.get("realUnitPrice") ? rowForm.get("realUnitPrice").value : row.realUnitPrice;
    const quantity = rowForm.get(this.quantityType) ? rowForm.get(this.quantityType).value : row[this.quantityType];
    const realMetalWeight = rowForm.get("realMetalWeight") ? rowForm.get("realMetalWeight").value : row.realMetalWeight;
    let totalUnitPrice =
      +quantity === 0 || this.isNullOrUndefinedOrEmpty(realMetalWeight)
        ? +realUnitPrice
        : new Decimal(realUnitPrice ?? 0)
          .plus(new Decimal(realUnitPricePerWeight ?? 0).times(realMetalWeight / quantity))
          .toNumber();

    if (row.purchaseType !== PurchaseType.BASIC && !onMetalAccount) {
      totalUnitPrice = new Decimal(totalUnitPrice ?? 0).plus(+realMetalPrice ?? 0).toNumber();
    }
    row.realPriceWithoutDiscount = RoundingUtil.roundLow(totalUnitPrice);
    this.updateDiscountMaxValidator(row, rowForm);
    if (discountType !== undefined && discountType !== null && realDiscount !== undefined && realDiscount !== null) {
      totalUnitPrice =
        discountType === DiscountType.PERCENT
          ? new Decimal(totalUnitPrice ?? 0)
            .minus(new Decimal(totalUnitPrice ?? 0).times(realDiscount ?? 0).div(this.PERCENT_VALUE))
            .toNumber()
          : new Decimal(totalUnitPrice ?? 0).minus(realDiscount ?? 0).toNumber();
      row.totalPrice = RoundingUtil.roundLow(new Decimal(totalUnitPrice ?? 0).times(quantity ?? 0).toNumber());
      row.realTotalUnitPrice = RoundingUtil.roundLow(totalUnitPrice);
    }
  }

  // Method used to load data using rows in rowForm
  private loadEditedData(row: DeliveryLineRow): void {
    const rowForm = row.rowForm;

    Object.keys(rowForm.controls).forEach((c: string) => {
      let value = row[c];
      if (c === "destLocationId") {
        value = row.destLocationId;
      }
      if (c === "onMetalAccount") {
        value = row.useMetalAccount;
      }
      if (c === "discountType") {
        value = Object.keys(DiscountType).indexOf(row.discountType);
      }

      rowForm.get(c).patchValue(value, { emitEvent: false });
    });
    if (!this.isNullOrUndefinedOrEmpty(this.action)) {
      this.handleRowFormAbility(row, rowForm);
    }

    if (this.isNullOrUndefinedOrEmpty(rowForm.get("realMetalWeight")?.value)) {
      const proportionalMetalWeight = (row.orderedMetalWeight * row.unreceivedQuantity) / row.orderedQuantity;
      if (!Number.isNaN(proportionalMetalWeight)) {
        rowForm.get("realMetalWeight")?.patchValue(proportionalMetalWeight, { emitEvent: false });
      }
    }

    if (!row.isStandardItem) {
      this.fieldsNotExistInServiceItem.forEach(field => {
        if (rowForm.get(field)) {
          rowForm.get(field).setValidators(null);
          rowForm.get(field).patchValue(null, { emitEvent: false });
        }
      });
    }
  }

  private handleRowFormAbility(row: DeliveryLineRow, rowForm: UntypedFormGroup): void {
    if (
      this.action === Action.PREPARE ||
      (this.action === Action.RECEIVE && row.containStatus(DeliveryLineStatus.VALIDATED))
    ) {
      return;
    }
    if (this.action === Action.UPDATE_RECEPTION && row.containStatus(DeliveryLineStatus.RECEIVED)) {
      const controlListToEnable = [
        "realUnitPrice",
        "realUnitPricePerWeight",
        "realMetalPrice",
        "expectedSizeValue",
        "expectedQuantity",
        "receivedQuantity",
        "receivedSizeValue",
        "realDiscount",
        "discountType",
      ];
      Object.keys(rowForm.controls).forEach(key => {
        if (!controlListToEnable.includes(key)) {
          rowForm.controls[key].disable();
        }
      });
      return;
    }
    rowForm.disable();
  }

  // Add validators to rowForm
  private addValidators(row: DeliveryLineRow, rowForm: UntypedFormGroup, expectedQuantity: number = null): void {
    const onMetalAccount = rowForm.get("onMetalAccount")?.value;
    if (rowForm.get("realUnitPrice")) {
      rowForm.get("realUnitPrice").setValidators(this.minimalValidators.concat(Validators.required));
    }

    if (rowForm.get("realUnitPricePerWeight")) {
      rowForm.get("realUnitPricePerWeight").setValidators(this.minimalValidators);
    }

    if (rowForm.get("totalWeight")) {
      rowForm
        .get("totalWeight")
        .setValidators(
          onMetalAccount
            ? this.minimalValidators
              .concat(Validators.required)
              .concat(this.tareGreaterThanTotalWeightValidator(rowForm))
            : this.minimalValidators
        );
    }
    if (rowForm.get("expectedQuantity")) {
      rowForm.get("expectedQuantity").setValidators(this.minimalValidators.concat(Validators.required));
    }

    if (rowForm.get("receivedQuantity")) {
      if (this.type === DeliveryType.INTERNAL && expectedQuantity !== null) {
        rowForm
          .get("receivedQuantity")
          .setValidators([
            ...this.minimalValidators,
            this.maxReceivedQuantityValidator(rowForm, expectedQuantity),
            Validators.required,
          ]);
      } else {
        rowForm.get("receivedQuantity").setValidators(this.minimalValidators.concat(Validators.required));
      }
    }

    if (rowForm.get("tare")) {
      onMetalAccount
        ? rowForm.get("tare").setValidators(this.minimalValidators.concat(Validators.required))
        : rowForm.get("tare").setValidators(this.minimalValidators);
    }

    if (rowForm.get("realMetalPrice")) {
      rowForm.get("realMetalPrice").setValidators(this.minimalValidators);
    }
    if (rowForm.get("realMetalWeight")) {
      rowForm
        .get("realMetalWeight")
        .setValidators(
          onMetalAccount
            ? this.minimalValidators.concat(this.realMetalWeightRequiredValidator(rowForm))
            : this.minimalValidators
        );
    }

    if (rowForm.get("destLocationId") && row.isStandardItem) {
      rowForm.get("destLocationId").setValidators(Validators.required);
    }
    if (rowForm.get("discountType")) {
      rowForm.get("discountType").setValidators(Validators.required);
    }
  }

  // Prepare form to save data from rows
  private prepareRowsForm(): void {
    const formConfig = {};
    this.columns
      .filter(column => (column.editable || column.property === "onMetalAccount") && column.visible)
      .forEach(column => {
        if (column.property === "realDiscount") {
          Object.assign(formConfig, { discountType: "" });
        }
        if (column.property === "checkbox" || column.property === "delete") {
          return;
        }
        // disable onMetalAccount switch for detail page
        if (!column.editable && column.property === "onMetalAccount") {
          formConfig[column.property] = { value: "", disabled: true };
        } else {
          formConfig[column.property] = "";
        }
      });
    this.globalRows.forEach(row => {
      if (!row.rowForm) {
        row.rowForm = this.fb.group(formConfig);
        this.updateDiscountMaxValidator(row, row.rowForm);
        if (row.status === DeliveryLineStatus.REFUSED) {
          row.rowForm.disable();
        } else {
          this.manageRowFormChange(row.rowForm, row);
        }
        this.loadEditedData(row);
        this.computeRealPrice(row);

        this.addValidators(row, row.rowForm, row.expectedQuantity);
        const controls = ["realMetalWeight", "totalWeight", "tare"];
        this.initForms(row.rowForm, controls);
      }

      if (row.frozen || row.hasInvoice) {
        this.showLineFrozenInfo = true;
        row.rowForm.disable();
      }
    });
  }

  // Prepare form for checkbox to change status or delete rows
  private prepareSelectionForm(): void {
    if (!this.canSee("checkbox") || !this.canEdit("checkbox")) {
      return;
    }
    this.selectedRows = [];
    this.selectionForm = this.fb.group({
      header: [false],
    });
    this.rows.forEach((row: DeliveryLineRow) => {
      this.selectionForm.addControl(row.id.toString(), new UntypedFormControl(false));
    });
  }

  private removeLine(selectedRow: DeliveryLineRow, rowList: DeliveryLineRow[]): void {
    this.lines.forEach((line: AbstractReceivingLine) => {
      this.applyModificationOnLine(line);
    });
    rowList.forEach(row => {
      if (selectedRow.id === row.id) {
        const rowIndex = rowList.indexOf(selectedRow);
        rowList.splice(rowIndex, 1);
        const lineIndex = this.lines.indexOf(this.lines.find(line => line.id === row.id));
        this.lines.splice(lineIndex, 1);
      }
    });
  }

  private resetSelectedRows(): void {
    Object.keys(this.selectionForm.controls).forEach((c: string) => {
      this.selectionForm.get(c).patchValue(false);
    });
    this.onHeaderCheckboxChange();
  }

  private isNullOrUndefinedOrEmpty(value: string): boolean {
    return value === undefined || value === null || value === "";
  }

  // Method used to check if there is an anomaly based on various values
  private checkAnomalies(
    value: string | number,
    refValue: string | number,
    lowMargin: number = 0,
    highMargin: number = 0
  ): boolean {
    if (typeof value === "string" && typeof refValue === "string") {
      return value === refValue;
    }

    refValue = refValue as number;
    value = value as number;

    if (highMargin !== null) {
      const highValue = refValue + refValue * (highMargin / this.PERCENT_VALUE);
      if (value > highValue) {
        return false;
      }
    }
    if (lowMargin !== null) {
      const lowValue = refValue - refValue * (lowMargin / this.PERCENT_VALUE);
      if (value < lowValue) {
        return false;
      }
    }
    return true;
  }

  /**
   * Allows to merge the general status with the specific filters.
   * @param specificFilters specific filters
   * @param generalFilters general filters
   * @returns filters merged
   */
  private getFiltersValue(specificFilters: any, generalFilters: any): any[] {
    if (specificFilters.length === 0) {
      return generalFilters.map(generalFilter => ({ filterId: generalFilter.filterId, equal: null }));
    } else if (specificFilters.length < generalFilters.length) {
      const allSpecificIds = specificFilters.map(specificFilter => specificFilter.filterId);
      const filtersToAdd = generalFilters
        .filter(generalFilter => !allSpecificIds.includes(generalFilter.filterId))
        .map(generalFilter => ({ filterId: generalFilter.filterId, equal: null }));
      return specificFilters.concat(filtersToAdd);
    }
    const allGeneralIds = generalFilters.map(generalFilter => generalFilter.filterId);
    return specificFilters.filter(specificFilter => allGeneralIds.includes(specificFilter.filterId));
  }

  /**
   * Allows to build the build from the builder. If dataSave === true the filters
   * are loaded from the session.
   */
  private initFilters(): void {
    if (this.filterer) {
      return;
    }
    this.buildFilterBuilder();
    if (this.dataSave) {
      this.loadFromSession();
      this.applyFilters();
    }
  }

  /**
   * Allows to retrieve filters from the session with the key saveSessionId
   */
  private loadFromSession(): void {
    this.sessionPagination.loadFromSession(this.saveSessionId);
  }

  private manageRowFormChange(rowForm: UntypedFormGroup, row: DeliveryLineRow): void {
    this.subscriptionService.subs.push(
      (rowForm.get("totalWeight") ? rowForm.get("totalWeight").valueChanges : of()).subscribe(() => {
        if (rowForm.get("totalWeight").invalid || rowForm.get("tare").invalid) {
          return;
        }

        if (
          this.isNullOrUndefinedOrEmpty(rowForm.get("tare").value) &&
          this.isNullOrUndefinedOrEmpty(rowForm.get("totalWeight").value)
        ) {
          rowForm.get("realMetalWeight").patchValue(null, { emitEvent: false });
          return;
        }
        const metalWeight = +rowForm.get("totalWeight").value - +rowForm.get("tare").value;
        rowForm.get("realMetalWeight").patchValue(metalWeight, { emitEvent: false });
      })
    );

    // reset all weights validators depending on metal account activation
    this.subscriptionService.subs.push(
      (rowForm.get("onMetalAccount") ? rowForm.get("onMetalAccount").valueChanges : of()).subscribe(() => {
        const onMetalAccount = rowForm.get("onMetalAccount").value;

        rowForm
          .get("totalWeight")
          ?.setValidators(
            onMetalAccount
              ? this.minimalValidators
                .concat(Validators.required)
                .concat(this.tareGreaterThanTotalWeightValidator(rowForm))
              : this.minimalValidators
          );

        rowForm
          .get("realMetalWeight")
          ?.setValidators(
            onMetalAccount
              ? this.minimalValidators.concat(this.realMetalWeightRequiredValidator(rowForm))
              : this.minimalValidators
          );
        onMetalAccount
          ? rowForm.get("tare")?.setValidators(this.minimalValidators.concat(Validators.required))
          : rowForm.get("tare")?.setValidators(this.minimalValidators);
        this.initForms(rowForm, controls);
        controls.forEach((controlStr: string) => {
          if (rowForm.get(controlStr)) {
            rowForm.get(controlStr).updateValueAndValidity();
          }
        });
      })
    );

    this.subscriptionService.subs.push(
      merge(
        rowForm.get("realMetalWeight") ? rowForm.get("realMetalWeight").valueChanges : of(),
        rowForm.get("tare") ? rowForm.get("tare").valueChanges : of()
      ).subscribe(() => {
        if (rowForm.get("realMetalWeight").invalid || rowForm.get("tare").invalid) {
          return;
        }

        if (
          this.isNullOrUndefinedOrEmpty(rowForm.get("tare").value) &&
          this.isNullOrUndefinedOrEmpty(rowForm.get("realMetalWeight").value)
        ) {
          rowForm.get("totalWeight").patchValue(null, { emitEvent: false });
          return;
        }
        const realMetalWeight = this.isNullOrUndefinedOrEmpty(rowForm.get("realMetalWeight").value)
          ? 0
          : rowForm.get("realMetalWeight").value;
        const totalWeight = +realMetalWeight + +rowForm.get("tare").value;
        rowForm.get("totalWeight").patchValue(totalWeight, { emitEvent: false });
      })
    );

    this.subscriptionService.subs.push(
      (rowForm.get("discountType") ? rowForm.get("discountType").valueChanges : of()).subscribe(() => {
        this.updateDiscountMaxValidator(row, rowForm);
        rowForm.get("realDiscount").markAsTouched();
      })
    );

    this.subscriptionService.subs.push(
      merge(
        rowForm.get("tare") ? rowForm.get("tare").valueChanges : of(),
        rowForm.get("totalWeight") ? rowForm.get("totalWeight").valueChanges : of(),
        rowForm.get("discountType") ? rowForm.get("discountType").valueChanges : of(),
        rowForm.get("realDiscount") ? rowForm.get("realDiscount").valueChanges : of(),
        rowForm.get("realUnitPrice") ? rowForm.get("realUnitPrice").valueChanges : of(),
        rowForm.get("onMetalAccount") ? rowForm.get("onMetalAccount").valueChanges : of(),
        rowForm.get("realMetalPrice") ? rowForm.get("realMetalPrice").valueChanges : of(),
        rowForm.get(this.quantityType) ? rowForm.get(this.quantityType).valueChanges : of(),
        rowForm.get("realMetalWeight") ? rowForm.get("realMetalWeight").valueChanges : of(),
        rowForm.get("realUnitPricePerWeight") ? rowForm.get("realUnitPricePerWeight").valueChanges : of()
      ).subscribe(() => {
        if (
          rowForm.get("totalWeight")?.invalid ||
          rowForm.get("realUnitPrice")?.invalid ||
          rowForm.get("realMetalPrice")?.invalid ||
          rowForm.get(this.quantityType)?.invalid ||
          rowForm.get("realUnitPricePerWeight")?.invalid
        ) {
          return;
        }
        this.computeRealPrice(row);
      })
    );

    const controls = ["totalWeight", "realMetalWeight", "tare"];
    if (rowForm.get(this.quantityType)) {
      this.subscriptionService.subs.push(
        rowForm.get(this.quantityType).valueChanges.subscribe(() => {
          rowForm.get("realMetalWeight")?.updateValueAndValidity();
          rowForm.get("realMetalWeight")?.markAsTouched();
        })
      );
    }
  }

  private updateDiscountMaxValidator(row: DeliveryLineRow, rowForm: UntypedFormGroup): void {
    if (!rowForm.get("discountType") || !rowForm.get("realDiscount")) {
      return;
    }

    let discountType = this.parseToDiscountType(rowForm.get("discountType").value);
    discountType = discountType ? discountType : row.discountType;
    rowForm.get("realDiscount").clearValidators();
    rowForm.get("realDiscount").setErrors({});

    switch (discountType) {
      case DiscountType.VALUE:
        rowForm
          .get("realDiscount")
          .setValidators(
            this.minimalValidators.concat(
              Validators.required,
              this.maxRealDiscountValidator(rowForm, row.realPriceWithoutDiscount)
            )
          );
        break;
      case DiscountType.PERCENT:
        rowForm
          .get("realDiscount")
          .setValidators(
            this.minimalValidators.concat(
              Validators.required,
              this.maxRealDiscountValidator(rowForm, this.PERCENT_VALUE)
            )
          );
        break;
      default:
        break;
    }
    rowForm.get("realDiscount").updateValueAndValidity({ emitEvent: false });
  }

  private maxRealDiscountValidator(rowForm: UntypedFormGroup, maximum: number): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return +rowForm.controls.realDiscount?.value > maximum ? { discountGreaterThanPriceOrPercent: true } : null;
    };
  }

  private maxReceivedQuantityValidator(rowForm: UntypedFormGroup, maximum: number): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return +rowForm.controls.receivedQuantity?.value > maximum ? { receivedQuantityGreaterThanExpected: true } : null;
    };
  }

  private realMetalWeightRequiredValidator(rowForm: UntypedFormGroup): ValidatorFn {
    return (): { [key: string]: any } | null => {
      if (+rowForm.controls[this.quantityType]?.value === 0 || rowForm.controls[this.quantityType]?.value === "") {
        return null;
      }

      return +rowForm.controls.realMetalWeight.value !== null && +rowForm.controls.realMetalWeight.value !== undefined
        ? null
        : { realMetalWeightRequired: true };
    };
  }

  private parseToDiscountType(index: number): DiscountType {
    const discountType = Object.values(DiscountType)[index];
    switch (discountType) {
      case "PERCENT":
        return DiscountType.PERCENT;
      case "VALUE":
        return DiscountType.VALUE;
      default:
        return undefined;
    }
  }

  private initForms(formGroup: UntypedFormGroup, names: string[]): void {
    names.forEach((name: string) => this.initValueIfRequired(formGroup.get(name), formGroup, name));
    formGroup.updateValueAndValidity();
  }

  private initValueIfRequired(form: AbstractControl, _formGroup: UntypedFormGroup, _name: string): void {
    if (form?.hasValidator(Validators.required) && !form?.value) {
      form.setValue(0);
    }
  }

  private fixParseInt(value: string | number): number {
    if (value || value === 0) {
      return +value;
    }
    return value as number;
  }
}
