import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { IconDefinition, faInfoCircle, faPen, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  InvoiceSupplier,
  Currency,
  Light,
  Pagination,
  InvoiceSupplierService,
  CurrencyService,
  LightService,
  CaraUserService,
  InvoiceStatus,
  PaginatedList,
  CaraUser,
  Sort,
} from "center-services";
import {
  Filterer,
  FilterValue,
  Option,
  PaginableComponent,
  SearchFilter,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-invoice-supplier-list",
  templateUrl: "./invoice-supplier-list.component.html",
  styleUrls: ["./invoice-supplier-list.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceSupplierListComponent
  extends FilteredTableListComponent
  implements OnInit, OnDestroy, PaginableComponent {
  @ViewChild("table") table: any;

  public LIST_ID: string = "app-invoice-supplier-list.invoice-list";
  public invoiceSupplierList: InvoiceSupplier[] = [];
  public supplierRefOptions: Option[] = [];

  public rows: any[] = [];
  public sorts: any[] = [{ prop: "date", dir: "desc" }];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public currencyList: Currency[] = [];
  public locale: string;
  public dateFormat: string;
  public supplierList: Light[] = [];
  public menuActions: MenuAction[] = [];
  public popupVisible: boolean = false;
  public idToDelete: number;
  public faPen: IconDefinition = faPen;
  public faTrashAlt: IconDefinition = faTrashAlt;
  public faInfo: IconDefinition = faInfoCircle;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  private sessionPagination: SessionPagination;
  private statusTranslations: { [key: string]: string } = {};
  private initObservables: Observable<any>[] = [];
  private readonly EDIT_ACTION_ID: number = 0;
  private readonly DELETE_ACTION_ID: number = 1;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private invoiceSupplierService: InvoiceSupplierService,
    private currencyService: CurrencyService,
    private lightService: LightService,
    private router: Router,
    protected userService: CaraUserService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);

    this.sessionPagination = new SessionPagination(this);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      InvoiceStatus,
      "invoice-supplier-list.invoice-status-options",
      this.statusTranslations
    );
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.addMenuActions();
    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.addMenuActions();
      })
    );

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchCurrencies());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.computeSearchFilters();
        this.fetchInvoiceSupplierList();
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      this.sessionPagination.saveToSession(this.LIST_ID);
    }
  }

  addMenuActions(): void {
    this.menuActions = [];
    this.menuActions.push(
      new MenuAction(
        this.EDIT_ACTION_ID,
        this.translateService.instant("invoice-supplier-list.actions.edit"),
        this.faPen
      )
    );
    this.menuActions.push(
      new MenuAction(
        this.DELETE_ACTION_ID,
        this.translateService.instant("invoice-supplier-list.actions.delete"),
        this.faTrashAlt
      )
    );
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case this.EDIT_ACTION_ID:
        if (!this.userService.canDo("INVOICE_SUPPLIER_UPDATE")) {
          return;
        }
        this.router.navigateByUrl(`/invoice-supplier/update/${row.id}`);
        break;
      case this.DELETE_ACTION_ID:
        if (!this.userService.canDo("INVOICE_SUPPLIER_DELETE")) {
          return;
        }
        this.openPopup(row.id);
        break;
      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  fetchInvoiceSupplierList(): void {
    this.subscriptionService.subs.push(
      this.invoiceSupplierService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<InvoiceSupplier>) => {
          this.rows = [];
          this.invoiceSupplierList = result.data;
          this.pager = result.page;
          result.data.forEach((invoiceSupplier: InvoiceSupplier) => {
            this.addRow(invoiceSupplier);
          });
        },
        error => {
          this.sendErrorAlert("invoice-supplier-list.errors.get-invoice-suppliers", error.message);
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
        }
      )
    );
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencyList = currencies;
        },
        error => {
          this.sendErrorAlert("invoice-supplier-list.errors.get-currencies", error.message);
        }
      )
    );
  }

  getCurrency(row: any): Currency {
    return this.currencyList.find(currency => currency.id === row.currencyId);
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierList = lightSuppliers;
        },
        error => {
          this.sendErrorAlert("invoice-supplier-list.errors.get-suppliers", error.message);
        }
      )
    );
  }

  addRow(invoiceSupplier: InvoiceSupplier): void {
    const statusLabel = this.translateService.instant(
      `invoice-supplier-list.invoice-status-options.${invoiceSupplier.invoiceStatus}`
    );
    const statusClass = this.getStatusClass(invoiceSupplier.invoiceStatus);

    this.rows.push({
      id: invoiceSupplier.id,
      date: invoiceSupplier.date,
      reference: invoiceSupplier.reference,
      supplierRef: this.supplierList.find(supplier => supplier.id === invoiceSupplier.supplierId).reference,
      supplierName: this.supplierList.find(supplier => supplier.id === invoiceSupplier.supplierId).name,
      quickPaymentDate: invoiceSupplier.quickPaymentDate,
      quickPaymentPrice: invoiceSupplier.quickPaymentPrice,
      maxPaymentDate: invoiceSupplier.maxPaymentDate,
      totalGrossPrice: invoiceSupplier.totalGrossPrice,
      totalExtraPrice: invoiceSupplier.totalExtraPrice,
      taxPrice: invoiceSupplier.taxPrice,
      totalPrice: invoiceSupplier.totalPrice,
      totalAdditionalPrice: invoiceSupplier.totalAdditionalPrice,

      currencyId: invoiceSupplier.currencyId,
      statusLabel,
      statusClass,
      actionnable:
        invoiceSupplier.invoiceStatus === InvoiceStatus.DRAFT ||
        invoiceSupplier.invoiceStatus === InvoiceStatus.VALIDATED,
    });
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  public changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];
    this.fetchInvoiceSupplierList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchInvoiceSupplierList();
  }

  // todo
  propToDto(prop: string): string {
    switch (prop) {
      case "statusLabel":
        return "invoiceStatus";
      case "supplierRef":
        return "contact.reference";
      case "supplierName":
        return "contact.name";
      default:
        return prop;
    }
  }

  buildDateDefaultValue(): any {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    return {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "date",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.date`),
      "date",
      true,
      true,
      this.buildDateDefaultValue()
    );

    this.filterer.addFilter(
      "reference",
      this.translateService.instant("invoice-supplier-list.datatable.columns.reference"),
      "string"
    );

    this.filterer.addListFilter(
      "contactId",
      this.translateService.instant("invoice-supplier-list.datatable.columns.supplier-ref"),
      this.supplierList.map(supplier => {
        return { value: supplier.id.toString(), displayValue: `${supplier.reference} - ${supplier.name}` };
      }),
      false,
      false,
      null,
      null,
      true,
      false
    );

    this.filterer.addFilter(
      "quickPaymentDate",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.quick-payment-date`),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );

    this.filterer.addFilter(
      "quickPaymentPrice",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.quick-payment-price`),
      "range"
    );

    this.filterer.addFilter(
      "maxPaymentDate",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.max-payment-date`),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );

    this.filterer.addFilter(
      "totalGrossPrice",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.total-gross-price`),
      "range"
    );

    this.filterer.addFilter(
      "taxPrice",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.tax-price`),
      "range"
    );

    this.filterer.addFilter(
      "totalPrice",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.total-price`),
      "range"
    );

    this.filterer.addFilter(
      "totalAdditionalPrice",
      this.translateService.instant(`invoice-supplier-list.datatable.columns.total-additional-price`),
      "range"
    );

    this.filterer.addListFilter(
      "invoiceStatus",
      this.translateService.instant("purchase-order-list.datatable.columns.status"),
      Object.keys(InvoiceStatus).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchInvoiceSupplierList();
      })
    );
  }

  public createInvoiceSupplier(): void {
    if (!this.userService.canDo("INVOICE_SUPPLIER_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/invoice-supplier/add");
  }

  public checkInvoiceSupplierDetail(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("INVOICE_SUPPLIER_READ")) {
        return;
      }
      const filteredList = this.invoiceSupplierList.filter(invoice => invoice.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find invoice with id ${event.row.id}`);
        return;
      }
      if (filteredList[0].invoiceStatus === InvoiceStatus.DRAFT) {
        this.router.navigateByUrl(`/invoice-supplier/update/${event.row.id}`);
      } else {
        this.router.navigateByUrl(`/invoice-supplier-detail/${event.row.id}`);
      }
    }
  }

  public deleteLine(): void {
    this.subscriptionService.subs.push(
      this.invoiceSupplierService.delete(this.idToDelete).subscribe(
        () => {
          this.closePopup();
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          this.fetchInvoiceSupplierList();
        },
        () => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("invoice-list.errors.delete");
          this.messageService.error(content, { title });
        }
      )
    );
  }

  openPopup(id: number): void {
    this.popupVisible = true;
    this.idToDelete = id;
  }

  closePopup(): void {
    this.popupVisible = false;
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  protected computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  private getStatusClass(status: InvoiceStatus): string {
    return `status-${status.toLowerCase()}`;
  }
}
