import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  PurchaseOrder,
  Currency,
  CompanyConfig,
  Country,
  Uom,
  Light,
  Brand,
  Supplier,
  ItemCategory,
  AbstractItem,
  BrandService,
  LightService,
  SupplierService,
  CurrencyService,
  RetailItemService,
  CompanyConfigService,
  CountryService,
  ItemCategoryService,
  CaraUserService,
  UomService,
  PurchaseOrderLine,
  PaymentType,
  PurchaseOrderStatus,
  Person,
  UomCategory,
  PaginatedList,
  Pagination,
  CaraUser,
  ShippingFeeType,
  StandardItem,
  PurchaseOrderUtil,
} from "center-services";
import Decimal from "decimal.js";
import { CommonValidatorsUtil, Option, RoundingUtil, SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";
import { Observable, combineLatest, of } from "rxjs";
import { mergeMap, switchMap, tap } from "rxjs/operators";

@Component({
  selector: "app-purchase-order-summary",
  templateUrl: "./purchase-order-summary.component.html",
  styleUrls: ["./purchase-order-summary.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderSummaryComponent implements OnInit {
  @Input() editedPurchaseOrder: PurchaseOrder;
  @Output() editedPurchaseOrderChange: EventEmitter<PurchaseOrder> = new EventEmitter<PurchaseOrder>();

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public summaryForm: UntypedFormGroup;

  public purchaseOrderCurrency: Currency;
  public purchaseOrderCompanyConfig: CompanyConfig;
  public supplierCountry: Country;
  public uniqueBrandCountry: Country;

  public weightUnits: Uom[] = [];

  public paymentTypeOptions: Option[];
  public forwardingAgentOptions: Option[];
  public brands: Light[];
  public uniqueBrand: Brand;
  public orderSupplier: Supplier;
  public categories: ItemCategory[];

  public purchaseModalityIdList: number[] = [];
  public retailItemList: AbstractItem[] = [];
  public sumGrossPrice: number;
  public totalPrice: number;
  public locale: string;
  public confirmationPopupVisible: boolean = false;
  public initObservables: Observable<any>[] = [];
  private readonly MAX_PERCENT: number = 100;
  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private brandService: BrandService,
    private lightService: LightService,
    private supplierService: SupplierService,
    private currencyService: CurrencyService,
    private retailItemService: RetailItemService,
    private companyConfigService: CompanyConfigService,
    private countryService: CountryService,
    private itemCategoryService: ItemCategoryService,
    private userService: CaraUserService,
    private uomService: UomService,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    this.initObservables = [];

    this.editedPurchaseOrder.lines.forEach((line: PurchaseOrderLine) => {
      // get all the purchase modality ids from each line
      this.purchaseModalityIdList.push(line.purchaseModalityId);
    });

    this.initObservables.push(this.fetchBrands());
    this.initObservables.push(this.fetchSupplier(this.editedPurchaseOrder.supplierId));
    this.initObservables.push(this.fetchCategories());
    this.initObservables.push(this.fetchWeightCategories());
    this.initObservables.push(this.initLanguage());
    this.initObservables.push(this.fetchPurchaseOrderCompanyConfig());
    this.initObservables.push(this.fetchPurchaseOrderCurrency(this.editedPurchaseOrder.currencyId));
    if (this.purchaseModalityIdList.length > 0) {
      this.initObservables.push(this.fetchItems(this.purchaseModalityIdList));
    }

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildPaymentTypeOptions();
      })
    );

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.buildPaymentTypeOptions();
        this.buildForwardingAgentOptions();

        const obs: Observable<any>[] = [];
        obs.push(this.fetchCountry(this.orderSupplier.address.countryId));
        if (this.uniqueBrand) {
          obs.push(this.fetchCountry(this.uniqueBrand.address.countryId, false));
        }

        this.subscriptionService.subs.push(
          combineLatest(obs).subscribe(() => {
            this.sumGrossPrice = PurchaseOrderUtil.getTotalPrice(this.editedPurchaseOrder.lines, this.retailItemList);
            this.computeTotalPrice();

            this.subscriptionService.subs.push(
              this.summaryForm.valueChanges.subscribe(() => {
                this.computeTotalPrice();
              })
            );

            if (this.isNeedConfirmation()) {
              this.confirmationPopupVisible = true;
            } else {
              this.initialLoad();
              this.applyModifications();
            }
          })
        );
      })
    );
  }

  prepareForm(): void {
    const digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);

    this.summaryForm = this.fb.group({
      paymentType: [null],
      deliveryDelay: [null],
      forwardingPersonName: [null],
      downPaymentPrice: [null, digitValidator],
      shippingFeePrice: [null, digitValidator],
      forwardingPrice: [null, digitValidator],
      customsFeePrice: [null, digitValidator],
      taxPrice: [null, digitValidator],
      hbjoatTaxPrice: [null, digitValidator],
      extraPrice: [null, digitValidator],
    });
  }

  isNeedConfirmation(): boolean {
    return (
      this.editedPurchaseOrder &&
      (this.editedPurchaseOrder.paymentType !== null ||
        this.editedPurchaseOrder.deliveryDelay !== null ||
        this.editedPurchaseOrder.forwardingPersonName !== null ||
        this.editedPurchaseOrder.downPaymentPrice !== null ||
        this.editedPurchaseOrder.shippingFeePrice !== null ||
        this.editedPurchaseOrder.forwardingPrice !== null ||
        this.editedPurchaseOrder.customsFeePrice !== null ||
        this.editedPurchaseOrder.taxPrice !== null ||
        this.editedPurchaseOrder.hbjoatTaxPrice !== null ||
        this.editedPurchaseOrder.extraPrice !== null)
    );
  }

  closeConfirmationPopup(): void {
    this.confirmationPopupVisible = false;
    this.loadDataFromPO();
  }

  validateConfirmationPopup(): void {
    this.confirmationPopupVisible = false;
    this.initialLoad();
    this.applyModifications();
  }

  // Load datas of fields from the correct commercial modality and various formula
  initialLoad(): void {
    this.summaryForm.controls.deliveryDelay.setValue(this.getCommercialModalityWithAttribute("deliveryDelay"));

    const index = Object.keys(PaymentType).indexOf(this.getCommercialModalityWithAttribute("paymentType"));
    this.summaryForm.controls.paymentType.setValue(index);

    this.summaryForm.controls.forwardingPersonName.setValue(
      this.getCommercialModalityContact().commercialModality?.forwardingPersonId
    );
    const downPaymentPrice = RoundingUtil.roundLow(
      new Decimal(this.sumGrossPrice ?? 0)
        .times(this.getCommercialModalityWithAttribute("downPaymentPercent") ?? 0)
        .div(this.MAX_PERCENT)
        .toNumber()
    );
    this.summaryForm.controls.downPaymentPrice.setValue(downPaymentPrice);

    // shippingFeePrice :  convert if it's necessary
    const shippingFeePrice = this.getShippingFeePrice();
    const convertShippingFeePrice = this.currencyService.computeDeviseConversion(
      shippingFeePrice,
      this.orderSupplier.currencyId,
      this.purchaseOrderCurrency.id
    );
    this.summaryForm.controls.shippingFeePrice.setValue(convertShippingFeePrice);

    if (this.orderSupplier.commercialModality.importation) {
      let forwardingPrice = this.getForwardingPrice();
      // forwardingPrice :  convert if it's necessary
      if (this.orderSupplier.commercialModality.forwardingPriceCurrencyId) {
        forwardingPrice = this.currencyService.computeDeviseConversion(
          forwardingPrice,
          this.orderSupplier.commercialModality.forwardingPriceCurrencyId,
          this.purchaseOrderCurrency.id
        );
      }
      this.summaryForm.controls.forwardingPrice.setValue(forwardingPrice);

      // custom fee price
      if (!this.supplierCountry.europeanUnion) {
        const customsFeePrice = this.getCustomsFeePriceSum();
        this.summaryForm.controls.customsFeePrice.setValue(customsFeePrice);
      }
    }

    // tax rate
    if (this.supplierCountry.codeISO === "FRA") {
      let taxPrice = 0;
      if (this.uniqueBrand?.commercialModality?.vatActivated || this.orderSupplier.commercialModality.vatActivated) {
        taxPrice = this.computeItemTaxRate();
      }
      this.summaryForm.controls.taxPrice.setValue(taxPrice);
    }

    // hbjoat price
    if (!this.supplierCountry.europeanUnion) {
      let hbjoatTaxPrice = 0;
      const country = this.getCountryWithAttribute("tph");
      if (country) {
        hbjoatTaxPrice = RoundingUtil.roundLow(
          new Decimal(this.sumGrossPrice ?? 0)
            .times(country.tph ?? 0)
            .div(this.MAX_PERCENT)
            .toNumber()
        );
      }
      this.summaryForm.controls.hbjoatTaxPrice.setValue(hbjoatTaxPrice);
    }
  }

  computeItemTaxRate(): number {
    let taxSum = 0;
    this.editedPurchaseOrder.lines.forEach(line => {
      taxSum = new Decimal(taxSum ?? 0)
        .plus(PurchaseOrderUtil.computeLineTotalItemTax(line, this.retailItemList) ?? 0)
        ?.toNumber();
    });
    return RoundingUtil.roundLow(taxSum);
  }

  loadDataFromPO(): void {
    Object.keys(this.summaryForm.value).forEach(key => this.loadEditedDataSet(key));

    if (this.editedPurchaseOrder.paymentType) {
      const index = Object.keys(PaymentType).indexOf(this.editedPurchaseOrder.paymentType);
      this.summaryForm.controls.paymentType.setValue(index);
    }
    if (this.editedPurchaseOrder.forwardingPersonName) {
      const personId = this.forwardingAgentOptions.find(
        opt => opt.label === this.editedPurchaseOrder.forwardingPersonName
      ).id;
      this.summaryForm.controls.forwardingPersonName.setValue(personId);
    } else {
      this.summaryForm.controls.forwardingPersonName.setValue(null);
    }
  }

  loadEditedDataSet(attribute: string): void {
    this.summaryForm.controls[attribute].setValue(this.editedPurchaseOrder[attribute]);
  }

  applyModifications(): void {
    this.editedPurchaseOrder.paymentType = Object.values(PaymentType)[this.summaryForm.value.paymentType];
    this.editedPurchaseOrder.deliveryDelay = this.summaryForm.value.deliveryDelay;
    this.editedPurchaseOrder.forwardingPersonName = this.summaryForm.value.forwardingPersonName
      ? this.forwardingAgentOptions.find(opt => opt.id === this.summaryForm.value.forwardingPersonName).label
      : null;

    const taxesArr = [
      "downPaymentPrice",
      "shippingFeePrice",
      "forwardingPrice",
      "customsFeePrice",
      "taxPrice",
      "hbjoatTaxPrice",
      "extraPrice",
    ];

    taxesArr.forEach(tax => this.applyModificationsTaxes(tax));
  }

  applyModificationsTaxes(attribute: string): void {
    this.editedPurchaseOrder[attribute] = this.summaryForm.value[attribute];
  }

  public updatePurchaseOrder(validate: boolean): boolean {
    if (this.summaryForm.invalid) {
      this.summaryForm.markAllAsTouched();
      return false;
    }
    let moqSupplier = this.orderSupplier.commercialModality.minOrderPrice;
    if (this.orderSupplier.currencyId !== this.purchaseOrderCurrency.id) {
      moqSupplier = this.currencyService.computeDeviseConversion(
        moqSupplier,
        this.orderSupplier.currencyId,
        this.purchaseOrderCurrency.id
      );
    }
    if (validate && this.sumGrossPrice < moqSupplier) {
      const title = this.translateService.instant("message.title.data-errors");
      const res = this.translateService.instant(`${this.getTranslationPrefix()}.errors.grossAmountInferiorToMoq`, {
        value: RoundingUtil.roundLow(moqSupplier),
        currency: this.purchaseOrderCurrency.symbol,
      });
      this.messageService.warn(res, { title });
      return false;
    }
    this.applyModifications();
    if (validate) {
      this.editedPurchaseOrder.status = PurchaseOrderStatus.CONFIRMED;
    }
    return true;
  }

  buildPaymentTypeOptions(): void {
    this.paymentTypeOptions = Object.keys(PaymentType).map(
      (key, index) =>
        new Option(
          index,
          this.translateService.instant(`${this.getTranslationPrefix()}.payment-type-list.${key.toLowerCase()}`)
        )
    );
  }

  buildForwardingAgentOptions(): void {
    // contacts & forwardingAgentOptions manage the forwarding agent in contacts from the supplier's directory
    this.forwardingAgentOptions = [];
    this.forwardingAgentOptions.push(new Option(null, "-"));

    const commercialModalityContact = this.getCommercialModalityContact();
    if (commercialModalityContact) {
      const personsList = commercialModalityContact.persons;
      personsList.sort((a, b) => {
        return a.lastName.localeCompare(b.lastName);
      });

      personsList.forEach((person: Person) => {
        this.forwardingAgentOptions.push(new Option(person.id, `${person.firstName} ${person.lastName}`));
      });
    }
  }

  fetchBrands(): any {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Light[]) => {
          this.brands = brands;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-brands`, error.message);
        }
      ),
      switchMap(() => {
        return this.getUniqueBrand();
      })
    );
  }

  fetchSupplier(supplierId: number): Observable<Supplier> {
    return this.supplierService.get(supplierId).pipe(
      tap(
        (supplier: Supplier) => {
          this.orderSupplier = supplier;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-supplier`, error.message);
        }
      )
    );
  }

  fetchWeightCategories(): Observable<Uom[]> {
    return this.uomService.getAllUomCategory().pipe(
      tap({
        error: error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-uom-category`, error.message);
        },
      }),
      mergeMap((uomCats: any) => {
        const weightCategoryId = uomCats.find((cat: UomCategory) => cat.name === "poids").id;
        return this.fetchWeightUnits(weightCategoryId);
      })
    );
  }

  fetchWeightUnits(weightCategoryId: number): Observable<Uom[]> {
    return this.uomService.getAllUomsOfCategory(weightCategoryId).pipe(
      tap(
        (uoms: Uom[]) => {
          this.weightUnits = uoms;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-uoms`, error.message);
        }
      )
    );
  }

  fetchCategories(): Observable<ItemCategory[]> {
    const observable = this.itemCategoryService.getAll();

    this.subscriptionService.subs.push(
      observable.subscribe(
        (categories: ItemCategory[]) => {
          this.categories = categories;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-categories`, error.message);
        }
      )
    );
    return observable;
  }

  fetchItems(purchaseModalityIds: any[]): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: purchaseModalityIds.length,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, purchaseModalityIds);

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          // remove duplicates from item list
          const ids = result.data.map(obj => obj.id);
          this.retailItemList = result.data.filter(({ id }, index) => !ids.includes(id, index + 1));
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-items`, error.message);
        }
      )
    );
  }

  fetchPurchaseOrderCurrency(id: number): Observable<Currency> {
    return this.currencyService.get(id).pipe(
      tap(
        (currency: Currency) => {
          this.purchaseOrderCurrency = currency;
        },
        error => {
          this.sendErrorAlert("purchase-order.lines.datatable.errors.get-currency", error.message);
        }
      )
    );
  }

  fetchPurchaseOrderCompanyConfig(): Observable<CompanyConfig> {
    return this.companyConfigService.get().pipe(
      tap(
        (companyConfig: CompanyConfig) => {
          this.purchaseOrderCompanyConfig = companyConfig;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-company-config`, error.message);
        }
      )
    );
  }

  fetchCountry(id: number, isSupplier: boolean = true): Observable<Country> {
    return this.countryService.get(id).pipe(
      tap(
        (country: Country) => {
          if (isSupplier) {
            this.supplierCountry = country;
          } else {
            this.uniqueBrandCountry = country;
          }
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-country`, error.message);
        }
      )
    );
  }

  initLanguage(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap((user: CaraUser) => {
        this.locale = user.codeLanguage;
      })
    );
  }

  // Fetched data are from Brand or Supplier depending on the situation
  // Line contains only one Brand and this brand contains commercialModality -> Brand
  // if field is empty, take supplier
  // Multiple Brand -> Supplier
  getCommercialModalityWithAttribute(attribute: string): any {
    if (this.uniqueBrand && this.uniqueBrand.commercialModality && this.uniqueBrand.commercialModality[attribute]) {
      return this.uniqueBrand.commercialModality[attribute];
    }

    const supplier = this.orderSupplier;
    if (supplier && supplier.commercialModality && supplier.commercialModality[attribute]) {
      return supplier.commercialModality[attribute];
    }
    return null;
  }

  getCountryWithAttribute(attribute: string): Country | null {
    if (this.uniqueBrand && this.uniqueBrandCountry[attribute] !== 0) {
      return this.uniqueBrandCountry;
    }
    if (this.supplierCountry[attribute] !== 0) {
      return this.supplierCountry;
    }
    return null;
  }

  isEmptyArray(value: any): boolean {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return false;
  }

  getCommercialModalityContact(): Brand | Supplier {
    if (this.uniqueBrand) {
      return this.uniqueBrand;
    }
    if (this.orderSupplier) {
      return this.orderSupplier;
    }
    console.error("should always find a contact");
    return null;
  }

  getUniqueBrand(): Observable<Brand> {
    const unique = [...new Set(this.editedPurchaseOrder.lines.map(line => line.brandName))];
    if (unique.length === 1) {
      const brandName = this.editedPurchaseOrder.lines[0].brandName;
      const brandFound = this.brands.find((brand: Light) => brand.name === brandName);
      if (brandFound) {
        return this.brandService.get(brandFound.id).pipe(
          tap(
            (brand: Brand) => {
              this.uniqueBrand = brand;
            },
            error => {
              this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-brand`, error.message);
            }
          )
        );
      }
    }
    return of(null);
  }

  // compute the amount with taxes
  computeTotalPrice(): void {
    const shippingFeePrice = +this.summaryForm.value.shippingFeePrice;
    const taxPrice = +this.summaryForm.value.taxPrice;
    const hbjoatTaxPrice = +this.summaryForm.value.hbjoatTaxPrice;
    const extraPrice = +this.summaryForm.value.extraPrice;

    this.totalPrice = RoundingUtil.roundLow(
      new Decimal(this.sumGrossPrice ?? 0)
        .plus(shippingFeePrice ?? 0)
        .plus(taxPrice ?? 0)
        .plus(hbjoatTaxPrice ?? 0)
        .plus(extraPrice ?? 0)
        .toNumber()
    );
  }

  // Calculate CustomsFeePrice for each commercial modality depending of his items category
  getCustomsFeePriceSum(): number {
    const countryForJewel = this.getCountryWithAttribute("jeweleryCustomsFee");
    const countryForWatch = this.getCountryWithAttribute("watchCustomsFee");
    let sumCustomsFee = 0;
    const jewelCategoryId = this.categories.find((category: ItemCategory) => category.name === "Bijoux").id;
    const watchCategoryId = this.categories.find((category: ItemCategory) => category.name === "Montres").id;

    this.editedPurchaseOrder.lines.forEach((line: PurchaseOrderLine) => {
      switch (this.getCategoryFromLine(line).id) {
        case jewelCategoryId:
          if (countryForJewel) {
            sumCustomsFee = new Decimal(sumCustomsFee ?? 0)
              .plus(
                new Decimal(PurchaseOrderUtil.computeLineTotalPrice(line, this.retailItemList) ?? 0)
                  .times(new Decimal(countryForJewel.jeweleryCustomsFee ?? 0))
                  .div(this.MAX_PERCENT)
                  .toNumber()
              )
              .toNumber();
          }
          break;
        case watchCategoryId:
          if (countryForWatch) {
            sumCustomsFee = new Decimal(sumCustomsFee ?? 0)
              .plus(
                new Decimal(PurchaseOrderUtil.computeLineTotalPrice(line, this.retailItemList) ?? 0)
                  .times(new Decimal(countryForWatch?.watchCustomsFee) ?? 0)
                  .div(this.MAX_PERCENT)
                  .toNumber()
              )
              .toNumber();
          }
          break;
        default:
          break;
      }
    });
    return RoundingUtil.roundLow(sumCustomsFee);
  }

  getCategoryFromLine(line: PurchaseOrderLine): ItemCategory {
    const item = this.getItem(line.purchaseModalityId);
    if (!item) {
      return null;
    }
    return this.getCategoryFromId(item.categoryId);
  }

  getItem(pmId: number): AbstractItem {
    for (const item of this.retailItemList) {
      if (item.purchaseModalities) {
        for (const pm of item.purchaseModalities) {
          if (pm.id === pmId) {
            return item;
          }
        }
      }
    }
    return null;
  }

  getCategoryFromId(id: number): ItemCategory {
    return this.categories.filter((category: ItemCategory) => category.id === id)[0];
  }

  // get the correct shippingFeePrice, depending of ShippingFeeType and the thresholds
  getShippingFeePrice(): number {
    let shippingFees = null;
    let shippingFeeType: ShippingFeeType = null;
    if (
      this.uniqueBrand &&
      this.uniqueBrand.commercialModality &&
      this.uniqueBrand.commercialModality.shippingFeeRanges &&
      !this.isEmptyArray(this.uniqueBrand.commercialModality.shippingFeeRanges)
    ) {
      shippingFeeType = this.uniqueBrand.commercialModality.shippingFeeType;
      shippingFees = this.uniqueBrand.commercialModality.shippingFeeRanges;
    } else {
      shippingFeeType = this.orderSupplier.commercialModality.shippingFeeType;
      shippingFees = this.orderSupplier.commercialModality.shippingFeeRanges;
    }

    switch (shippingFeeType) {
      case ShippingFeeType.QUANTITY: {
        return this.findShippingFeePrice(
          PurchaseOrderUtil.getTotalQuantity(this.editedPurchaseOrder.lines),
          shippingFees
        );
      }
      // Shipping threshold of Weight is in KG, MetalWeight is in gram, a conversion is needed
      case ShippingFeeType.WEIGHT: {
        return this.findShippingFeePrice(this.getTotalWeight(this.editedPurchaseOrder.lines), shippingFees);
      }
      case ShippingFeeType.PRICE: {
        return this.findShippingFeePrice(this.sumGrossPrice, shippingFees);
      }
      default: {
        return null;
      }
    }
  }

  getTotalWeight(purchaseOrderLines: PurchaseOrderLine[]): number {
    const kgUnit = this.weightUnits.find(uom => uom.shortName === "kg");
    if (!kgUnit) {
      console.error("Can't find kg uom in the unit list");
      return 0;
    }
    return +purchaseOrderLines.reduce((sum, current) => {
      const item: AbstractItem = PurchaseOrderUtil.findItem(current, this.retailItemList);
      if (!item) {
        return sum;
      }
      if (item instanceof StandardItem) {
        return (
          sum +
          this.uomService.convertWeightTo(this.weightUnits, item.weight, item.weightUnitId, kgUnit.id) *
            current.quantity
        );
      } else {
        return sum;
      }
    }, 0);
  }

  // If forwardingPriceCurrencyId of commercialModality is null, it means its a %, else its € or $
  getForwardingPrice(): number {
    if (!this.getCommercialModalityWithAttribute("forwardingPriceCurrencyId")) {
      return RoundingUtil.roundLow(
        new Decimal(this.sumGrossPrice ?? 0)
          .times(this.getCommercialModalityWithAttribute("forwardingPrice") ?? 0)
          .div(this.MAX_PERCENT)
          .toNumber()
      );
    }
    return RoundingUtil.roundLow(this.getCommercialModalityWithAttribute("forwardingPrice"));
  }

  findShippingFeePrice(value: number, shippingFeeRanges: any[]): number {
    shippingFeeRanges.sort((a, b) => b.threshold - a.threshold);
    for (const shippingFee of shippingFeeRanges) {
      if (value > shippingFee.threshold) {
        return RoundingUtil.roundHigh(shippingFee.price);
      }
    }
    return 0;
  }

  getTranslationPrefix(): string {
    return "purchase-order.summary";
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }
}
