import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faPlus, faSortCircle } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  Store,
  PromotionPeriod,
  StockLocation,
  OperatingTimeDay,
  PromotionPeriodService,
  CaraUserService,
  DocumentService,
  StockLocationService,
  ContactRootLocation,
} from "center-services";
import { CommonValidatorsUtil, DayjsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import dayjs from "dayjs";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-characteristics",
  templateUrl: "./characteristics.component.html",
  styleUrls: ["./characteristics.component.scss"],
  providers: [SubscriptionService],
})
export class CharacteristicsComponent implements OnInit, AfterViewChecked {
  @ViewChild("openingTimes") openingTimes: any;

  @Input() editedStore: Store;

  // document photo
  @Input() selectedFile: any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedFileChange: EventEmitter<any> = new EventEmitter<any>();
  public stockPopupVisible: boolean = false;
  // for detecting file changed
  public previousFile: any;

  // fontawesome icon to add photo
  faPlus: IconDefinition = faPlus;

  // fontawesome for stock location sort
  faSort: IconDefinition = faSortCircle;

  public characteristicsForm: UntypedFormGroup;

  public promotionPeriods: PromotionPeriod[] = [];
  public promotionOptions: Option[] = [];

  public stockLocations: StockLocation[] = [];
  public stockOptions: Option[] = [];

  public operatingTimeDays: OperatingTimeDay[];

  public dateFormat: string;
  public locale: string;

  private initObservables: Observable<any>[];

  constructor(
    private fb: UntypedFormBuilder,
    private promotionperiodService: PromotionPeriodService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: CaraUserService,
    private documentService: DocumentService,
    private cd: ChangeDetectorRef,
    private stockLocationService: StockLocationService,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.locale = this.userService.connectedUser.value.codeLanguage;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.dateFormat = user.dateFormat;
        this.locale = user.codeLanguage;
      })
    );

    this.initObservables = [];

    this.initObservables.push(this.fetchPromotionPeriods());
    this.initObservables.push(this.fetchStockLocations());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedStore) {
          this.loadEditedData();
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  fetchPromotionPeriods(): Observable<PromotionPeriod[]> {
    return this.promotionperiodService.getAll().pipe(
      tap(
        (promotionPeriods: PromotionPeriod[]) => {
          this.promotionPeriods = promotionPeriods;
          this.promotionOptions = promotionPeriods
            .filter((obj: PromotionPeriod) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: PromotionPeriod) => {
              return new Option(obj.id, this.formatPromotionPeriod(obj));
            });
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("characteristics-list.errors.get-characteristics");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchStockLocations(): Observable<StockLocation[]> {
    return this.stockLocationService.getAll().pipe(
      tap(
        (stockLocations: StockLocation[]) => {
          this.stockLocations = stockLocations;
          this.stockOptions = stockLocations
            .filter((obj: StockLocation) => !obj.parentId)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: StockLocation) => {
              if (obj.id === this.editedStore.originLocationId) {
                return new Option(obj.id, obj.name, true);
              } else {
                return new Option(obj.id, obj.name);
              }
            });
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("characteristics-list.errors.get-stock-locations");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  prepareForm(): void {
    this.characteristicsForm = this.fb.group({
      mallName: [null],
      mainStoreName: [null],
      storeCount: [null, [Validators.min(0), CommonValidatorsUtil.integerValidator()]],
      competitorCount: [null, [Validators.min(0), CommonValidatorsUtil.integerValidator()]],
      totalArea: [null, [Validators.min(0)]],
      saleArea: [null, [Validators.min(0)]],
      deliveryDelay: [null, [Validators.min(0), CommonValidatorsUtil.integerValidator()]],
      openingDate: [null],
      specificationComment: [null],
      showcaseComment: [null],
      promotionPeriods: [null],
      photoFile: [[]],
      comparableSurfaceActivated: [false],
      stockLocations: [null],
    });
    this.subscriptionService.subs.push(
      this.characteristicsForm.controls.photoFile.valueChanges.subscribe(photoFile => {
        this.selectFile(photoFile);
      })
    );
  }

  openStockPopup(): void {
    this.stockPopupVisible = true;
  }

  closeStockPopup(): void {
    this.stockPopupVisible = false;
  }

  validateStockPopup(event: any): void {
    this.characteristicsForm.controls.stockLocations.patchValue(event);
    this.closeStockPopup();
  }

  // display data
  loadEditedData(): void {
    this.characteristicsForm.controls.mallName.setValue(this.editedStore.mallName);
    this.characteristicsForm.controls.mainStoreName.setValue(this.editedStore.mainStoreName);
    this.characteristicsForm.controls.storeCount.setValue(this.editedStore.storeCount);
    this.characteristicsForm.controls.competitorCount.setValue(this.editedStore.competitorCount);
    this.characteristicsForm.controls.totalArea.setValue(this.editedStore.totalArea);
    this.characteristicsForm.controls.saleArea.setValue(this.editedStore.saleArea);
    this.characteristicsForm.controls.openingDate.setValue(DayjsUtil.dayjsOrNull(this.editedStore.openingDate, true));
    this.characteristicsForm.controls.specificationComment.setValue(this.editedStore.specificationComment);
    this.characteristicsForm.controls.showcaseComment.setValue(this.editedStore.showcaseComment);
    this.characteristicsForm.controls.deliveryDelay.setValue(this.editedStore.deliveryDelay);
    this.characteristicsForm.controls.comparableSurfaceActivated.setValue(this.editedStore.comparableArea);

    if (this.editedStore.promotionPeriodIds) {
      this.characteristicsForm.controls.promotionPeriods.setValue(
        this.editedStore.promotionPeriodIds.filter(id => !this.promotionPeriods.find(pp => pp.id === id).archived)
      );
    }

    if (this.editedStore.rootLocations) {
      this.characteristicsForm.controls.stockLocations.setValue(
        this.editedStore.rootLocations.map(rootLocation => rootLocation.stockLocationId)
      );
    }

    if (this.editedStore.operatingTimeDays) {
      this.operatingTimeDays = this.editedStore.operatingTimeDays.map(ot => new OperatingTimeDay(ot));
    }
    // download existing photo in update case
    if (this.editedStore.id && this.editedStore.photoId) {
      this.getPhoto();
    } else {
      this.selectedFile = null;
      this.previousFile = null;
    }
  }

  applyModifications(): void {
    // edit store with form controls
    this.editedStore.mallName = this.characteristicsForm.value.mallName;
    this.editedStore.mainStoreName = this.characteristicsForm.value.mainStoreName;
    if (this.characteristicsForm.value.storeCount) {
      this.editedStore.storeCount = parseInt(this.characteristicsForm.value.storeCount, 10);
    } else {
      this.editedStore.storeCount = null;
    }
    if (this.characteristicsForm.value.competitorCount) {
      this.editedStore.competitorCount = parseInt(this.characteristicsForm.value.competitorCount, 10);
    } else {
      this.editedStore.competitorCount = null;
    }
    if (this.characteristicsForm.value.totalArea) {
      this.editedStore.totalArea = parseFloat(this.characteristicsForm.value.totalArea);
    } else {
      this.editedStore.totalArea = null;
    }
    if (this.characteristicsForm.value.saleArea) {
      this.editedStore.saleArea = parseFloat(this.characteristicsForm.value.saleArea);
    } else {
      this.editedStore.saleArea = null;
    }
    if (this.characteristicsForm.value.deliveryDelay) {
      this.editedStore.deliveryDelay = parseInt(this.characteristicsForm.value.deliveryDelay, 10);
    } else {
      this.editedStore.deliveryDelay = null;
    }
    this.editedStore.comparableArea = this.characteristicsForm.value.comparableSurfaceActivated;

    this.editedStore.openingDate = this.characteristicsForm.value.openingDate
      ? this.characteristicsForm.value.openingDate.toDate()
      : null;

    this.editedStore.specificationComment = this.characteristicsForm.value.specificationComment;
    this.editedStore.showcaseComment = this.characteristicsForm.value.showcaseComment;

    if (this.characteristicsForm.value.promotionPeriods) {
      this.editedStore.promotionPeriodIds = this.characteristicsForm.value.promotionPeriods;
    }

    if (this.operatingTimeDays) {
      this.editedStore.operatingTimeDays = this.operatingTimeDays;
    }

    if (this.characteristicsForm.value.stockLocations) {
      const locationArray = [];

      this.characteristicsForm.value.stockLocations.forEach((id: number, index: number) => {
        let location = this.editedStore.rootLocations
          ? this.editedStore.rootLocations.find(rootLocation => rootLocation.stockLocationId === id)
          : null;
        if (!location) {
          location = new ContactRootLocation({
            stockLocationId: id,
            contactId: null,
          });
        }
        location.priority = index;
        locationArray.push(location);
      });
      this.editedStore.rootLocations = locationArray;
    }
  }

  comparableSurfaceOnChanges(): void {
    // switch off, false disable input money
    if (!this.characteristicsForm.controls.comparableSurfaceActivated.value) {
      // set value to null or false, clean
      this.editedStore.comparableArea = false;
    }
    this.characteristicsForm.controls.comparableSurfaceActivated.updateValueAndValidity();
  }

  updateStore(): boolean {
    // if second option is for manage validation for openingTimesComponent
    if (this.characteristicsForm.invalid || !this.openingTimes.isFormValid()) {
      this.characteristicsForm.markAllAsTouched();
      return false;
    }

    this.applyModifications();

    return true;
  }

  formatPromotionPeriod(promotionPeriod: PromotionPeriod): string {
    const dayFormat = this.dateFormat.split(" ")[0];
    return `${promotionPeriod.name} [  ${dayjs(promotionPeriod.beginDate).format(dayFormat)} - ${dayjs(
      promotionPeriod.endDate
    ).format(dayFormat)} ]`;
  }

  selectFile(event: any): void {
    this.selectedFile = event[0];
    this.selectedFileChange.emit(this.selectedFile);
  }

  getPhoto(): void {
    // display the current photo
    this.subscriptionService.subs.push(
      this.documentService.downloadFile(this.editedStore.photoId).subscribe(
        data => {
          if (data.byteLength === 0) {
            this.previousFile = null;
            this.selectedFile = null;
          } else {
            this.previousFile = data;
            this.selectedFile = data;
            this.characteristicsForm.value.photoFile[0] = data;
          }
        },
        error => {
          console.error(error.message);
        }
      )
    );
  }

  // manage photo
  hasFileChanged(): boolean {
    if (!this.selectedFile && !this.previousFile) {
      return false;
    }
    if ((!this.selectedFile && this.previousFile) || (!this.previousFile && this.selectedFile)) {
      return true;
    }
    return this.previousFile.name !== this.selectedFile.name;
  }
}
