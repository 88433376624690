import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  faArrowCircleRight,
  faCheck,
  faChevronLeft,
  faList,
  faPrint,
  faTimesCircle,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { MessageService, ToastMessageService } from "fugu-components";
import { ErrorUtil } from "generic-pages";
import {
  Stage,
  Store,
  Inventory,
  CaraUserService,
  InventoryService,
  StoreService,
  CaraUser,
  AsynchronousTaskCreation,
  AsynchronousTaskService,
} from "center-services";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "app-inventory-form",
  templateUrl: "./inventory-form.component.html",
  styleUrls: ["./inventory-form.component.scss"],
  providers: [SubscriptionService],
})
export class InventoryFormComponent implements OnInit, AfterViewChecked {
  @ViewChild("stockEntryLocations") stockEntryLocations: any;
  @ViewChild("inventoryEntries") inventoryEntries: any;
  @ViewChild("inventoryResults") inventoryResults: any;

  public stages: Stage[] = [];
  public activeStage: Stage;
  public selectedStore: Store = null;

  readonly faChevronLeft: IconDefinition = faChevronLeft;
  readonly faPrint: IconDefinition = faPrint;
  readonly faArrowCircleRight: IconDefinition = faArrowCircleRight;
  readonly faCheck: IconDefinition = faCheck;
  readonly faList: IconDefinition = faList;
  readonly faTimesCircle: IconDefinition = faTimesCircle;

  readonly ONE: number = 1;
  readonly TWO: number = 2;
  // eslint-disable-next-line no-magic-numbers
  readonly THREE: number = 3;
  public title: string;
  public editedInventory: Inventory;
  public updatedInventory: Inventory;
  public unsavedInventory: Inventory;
  public popupVisible: boolean = false;
  public initObservables: Observable<any>[] = [];
  public validateBtnDisabled: boolean = false;
  public goToStep2: boolean = true;
  public dateFormat: string;
  protected readonly INVENTORY_LIST_URL: string = "/inventory-list";

  constructor(
    protected userService: CaraUserService,
    private translateService: TranslateService,
    private inventoryService: InventoryService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private storeService: StoreService,
    private asynchronousTaskService: AsynchronousTaskService,
    private notificationHandlerService: NotificationHandlerService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.subscriptionService.subs.push(
      this.fetchInventory()
        .pipe(mergeMap(() => this.fetchSelectedStore()))
        .subscribe(() => {
          this.title = this.editedInventory.name;
          this.initStages();
          this.initInventorySnapshots();
        })
    );
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe((user: CaraUser) => {
        this.dateFormat = user.dateFormat;
      })
    );
  }

  fetchSelectedStore(): Observable<Store> {
    return this.storeService.get(this.editedInventory.storeId).pipe(
      tap(
        (store: Store) => {
          this.selectedStore = store;
        },
        error => {
          this.sendErrorAlert("inventory-list.errors.get-stores", error.message);
        }
      )
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  initStages(): void {
    this.stages = [];
    this.stages.push(this.getStage(this.ONE, "1", "one"));
    this.stages.push(this.getStage(this.TWO, "2", "two"));
    this.stages.push(this.getStage(this.THREE, "3", "three"));
    this.setActiveStage();
  }

  setActiveStage(): void {
    let activeStageIdx = "3";
    if (!this.editedInventory.hasSnapshot) {
      activeStageIdx = "1";
    } else if (!this.editedInventory.validationDate) {
      activeStageIdx = "2";
    }
    this.activeStage = this.stages.find(stage => stage.index === activeStageIdx);
    this.activeStage.active = true;
  }

  backToInventoryList(): void {
    this.router.navigateByUrl(this.INVENTORY_LIST_URL);
  }

  isDirty(): boolean {
    if (this.activeStage.id === this.THREE) {
      return this.inventoryResults.isDirty();
    }
    this.applyModifications();
    return !this.updatedInventory.equals(this.editedInventory) && !this.unsavedInventory.equals(this.updatedInventory);
  }

  changeStage(stage: Stage, afterPopupValidation: boolean = false): void {
    if (stage.id === this.TWO) {
      this.openPopup();
      return;
    } else if (stage.id === this.THREE && !afterPopupValidation) {
      this.inventoryEntries.openValidationPopup();
      return;
    }

    this.stages.find(elem => elem.id === this.activeStage.id).validated = true;
    this.stages.forEach(element => (element.active = false));

    stage.active = true;
    this.activeStage = stage;
  }

  openPopup(): void {
    this.popupVisible = true;
  }

  submitPopup(): void {
    this.submitStockEntryLocations();
  }

  closePopup(): void {
    this.popupVisible = false;
    this.stages.find(elem => elem.id === this.ONE).active = true;
  }

  getPrimaryActionButtonText(): string {
    switch (this.activeStage.id) {
      case this.ONE:
        return "go-to-inventory";
      case this.TWO:
        return "inventory-result";
      case this.THREE:
        return "finalize-inventory";
      default:
        console.error(`Unknown stage with id: ${this.activeStage.id}`);
        return undefined;
    }
  }

  actionsManager(): void {
    switch (this.activeStage.id) {
      case this.ONE:
        this.openPopup();
        break;
      case this.TWO:
        this.inventoryEntries.openValidationPopup();
        break;
      case this.THREE:
        if (this.inventoryResults.saveEditedInventory()) {
          this.inventoryResults.openValidationPopup();
        }
        break;
      default:
        console.error(`Unknown stage with id: ${this.activeStage.id}`);
        break;
    }
  }

  submitStockEntryLocations(): void {
    if (this.stockEntryLocations) {
      this.goToStep2 = false;

      this.subscriptionService.subs.push(
        this.inventoryService.takeSnapshot(this.editedInventory, this.stockEntryLocations.getActiveFilters()).subscribe(
          result => {
            this.editedInventory = new Inventory(result);
            this.initInventorySnapshots();
            this.visitStep2();
          },
          error => {
            this.handleApiError(error);
          }
        )
      );
    } else {
      this.visitStep2();
    }
  }

  handleApiError(error: any): void {
    const title = this.translateService.instant("message.title.form-errors");
    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  getStageClass(stage: Stage): string {
    return stage.id - this.activeStage.id !== 1 ? "not-clickable" : "";
  }

  getStage(id: number, idx: string, stageTranslationSuffix: string): Stage {
    return new Stage(
      id,
      idx,
      this.translateService.instant(`inventory-form.step-${stageTranslationSuffix}`),
      false,
      false
    );
  }

  public printInventoryResults(): void {
    const asyncCreationTask = {
      type: "generatePdf",
      params: `inventory;${this.inventoryResults.activeFilters.map(filter => filter.toQuery()).join(",")};${
        this.editedInventory.id
      };`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  onSaveButtonClick(): void {
    switch (this.activeStage.id) {
      case this.TWO:
        this.inventoryEntries.saveEditedInventory();
        break;
      case this.THREE:
        this.inventoryResults.saveEditedInventory();
        break;
      default:
        break;
    }
  }

  switchToThirdStage(): void {
    this.changeStage(
      this.stages.find(elem => elem.id === this.THREE),
      true
    );
  }

  validateBtnStatus(event: any): void {
    this.validateBtnDisabled = event;
  }

  visitStep2(): void {
    this.goToStep2 = true;
    this.activeStage = this.stages.find(elem => elem.id === this.TWO);
    this.stages.find(elem => elem.id === this.ONE).active = false;
    this.activeStage.active = true;
    this.popupVisible = false;
  }

  private fetchInventory(): Observable<Inventory> {
    return this.inventoryService.get(this.route.snapshot.params.id).pipe(
      tap(
        (inventory: Inventory) => {
          this.editedInventory = inventory;
        },
        error => {
          this.sendErrorAlert("inventory-form.errors.get-inventory", error.message);
        }
      )
    );
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }

  private applyModifications(): void {
    this.unsavedInventory = new Inventory(this.updatedInventory);
    if (this.activeStage.id === this.TWO) {
      this.inventoryEntries.updateUpdatedInventory();
    }
  }

  private initInventorySnapshots(): void {
    this.updatedInventory = new Inventory(this.editedInventory);
    this.unsavedInventory = new Inventory(this.editedInventory);
  }
}
