<div class="receipt-validation-popup">
  <ui-popup (close)="closeValidationPopup()" [title]="getPopupTitle()">
    <div content>
      <div class="info-message">
        <div class="igniter">
          {{ this.getIgniter() }}
        </div>

        <!----------------------------------- WITH PREPARATION ----------------------------------->
        <div class="with-preparation" *ngIf="withPreparation && !isRefused() && !this.internalMovement">
          <!-- case 1: preparation -->
          <div class="preparation" *ngIf="validationOfPreparation()">
            <ul class="action-list">
              <li class="action" *ngIf="!isFreeLine">
                {{
                  "new-delivery-form-validation-popup.with-preparation.preparation-validation.lines-validated.to-process"
                    | translate
                }}
              </li>
              <li class="action" *ngIf="!isFreeLine">
                {{
                  "new-delivery-form-validation-popup.with-preparation.preparation-validation.lines-validated.po-validated"
                    | translate
                }}
              </li>
              <li class="action">
                {{ this.getMetalAccountMessage() }}
              </li>
            </ul>
          </div>
          <!-- case 1: preparation -->

          <!-- case 2: validation -->
          <div class="validation" *ngIf="!validationOfPreparation() && !isRefused() && !this.internalMovement">
            <ul class="action-list">
              <li class="action">
                {{ this.getStockMessage() }}
              </li>
              <li class="action">
                {{ this.getPoliceBookMessage() }}
              </li>
              <li class="action">
                {{ this.getValidationOfReceptionLabels() }}
              </li>
            </ul>
          </div>
          <!-- case 2: validation -->
        </div>
        <!----------------------------------- WITH PREPARATION ----------------------------------->

        <!----------------------------------- WITHOUT PREPARATION ----------------------------------->
        <div class="without-preparation" *ngIf="!withPreparation && !isRefused() && !this.internalMovement">
          <ul class="action-list">
            <li class="action">
              {{ this.getMetalAccountMessage() }}
            </li>
            <li class="action">
              {{ this.getStockMessage() }}
            </li>
            <li class="action">
              {{ this.getPoliceBookMessage() }}
            </li>
            <li class="action">
              {{ this.getValidationOfReceptionLabels() }}
            </li>
          </ul>
        </div>
        <!----------------------------------- WITHOUT PREPARATION ----------------------------------->

        <!----------------------------------- WITHOUT PREPARATION internalMovement ----------------------------------->
        <div class="internal-preparation" *ngIf="this.internalMovement && !isRefused()">
          <ul class="action-list">
            <li class="action">
              {{ this.getStockMessage() }}
            </li>
            <li class="action">
              {{ this.getPoliceBookMessage() }}
            </li>
          </ul>
        </div>
      </div>
      <!----------------------------------- WITHOUT PREPARATION internalMovement----------------------------------->

      <!----------------------------------- WARN MESSAGES ----------------------------------->
      <div class="warn">
        <ng-container *ngFor="let message of getWarningText()">
          <div class="warning-message" *ngIf="this.isWarningMessageDisplayed()">
            <div class="block icon">
              <fa-icon class="warn-icon fa-lg" [icon]="faWarn"></fa-icon>
            </div>
            <div class="block">
              <div class="bold warning warning-title">
                {{ "new-delivery-form-validation-popup.warning-title" | translate }}
              </div>
              <div class="warning warning-text not-validated" [innerHtml]="message"></div>
            </div>
          </div>
        </ng-container>
      </div>
      <!----------------------------------- WARN MESSAGES ----------------------------------->

      <!----------------------------------- QUESTIONS ----------------------------------->
      <div class="bold question">
        {{ this.getQuestionContent() }}
      </div>
      <!----------------------------------- QUESTIONS ----------------------------------->

      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button [text]="getValidateTextButton()" [primary]="true" type="validate" (click)="validateReceipt()">
              </ui-button>
            </div>
          </div>
          <div class="button-popup-cancel">
            <div class="button second-button">
              <ui-button
                [text]="'new-delivery-form-validation-popup.buttons.cancel' | translate"
                type="cancel"
                (click)="closeValidationPopup()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
