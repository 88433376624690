import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IconDefinition,
  faChevronLeft,
  faEye,
  faFileExcel,
  faInfoCircle,
  faPaperclip,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AsynchronousTask, AsynchronousTaskService, ExportService, IImportService, TaskStatus } from "center-services";
import dayjs from "dayjs";
import { SubscriptionService } from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { FileSaverService } from "ngx-filesaver";

class ImportError {
  tab: string;
  line: string;
  cell: string;
  cause: string;

  constructor(message: string) {
    this.cause = message;
  }
}

class ImportRow {
  id: number;
  date: Date;
  file: string;
  progress: number;
  status: TaskStatus;
  errors: ImportError[];

  constructor(importTask: AsynchronousTask, defaultErrorMessage: string) {
    if (!importTask.result) {
      this.errors = null;
    } else {
      try {
        this.errors = JSON.parse(importTask.result.result)?.map((error: ImportError) => error);
      } catch (e) {
        this.errors = [new ImportError(defaultErrorMessage)];
      }
    }

    this.file = importTask?.params.split(";")[1].replace("/tmp/", "");
    this.progress = importTask.progress;
    this.date = importTask.createdAt;
    this.status = importTask.status;
    this.id = importTask.id;
  }
}

@Component({
  selector: "app-import-file",
  templateUrl: "./import-file.component.html",
  styleUrls: ["./import-file.component.scss"],
  providers: [SubscriptionService],
})
export class ImportFileComponent implements OnInit, OnDestroy {
  public faChevronLeft: IconDefinition = faChevronLeft;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public faPaperclip: IconDefinition = faPaperclip;
  public faFileExcel: IconDefinition = faFileExcel;
  public faTrashAlt: IconDefinition = faTrashAlt;
  public popupVisible: boolean = false;
  public menuActions: MenuAction[];
  public rows: ImportRow[] = [];
  public errors: ImportError[];
  public importForm: UntypedFormGroup;
  public sorts: any[] = [
    {
      prop: "date",
      dir: "desc",
    },
  ];
  public target: string;
  public file: File;
  // eslint-disable-next-line no-magic-numbers
  protected REFRESH_DELAY: number = 5000; // refresh rate in millisecond for imports list
  // eslint-disable-next-line no-magic-numbers
  protected readonly PAYLOAD_TOO_LARGE: number = 413;
  private refreshInterval: number;
  private service: IImportService;
  private backUrl: string;
  private taskAlreadyInProgress: boolean = false;

  constructor(
    private taskService: AsynchronousTaskService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private exportService: ExportService,
    private fileSaverService: FileSaverService,
    private route: ActivatedRoute,
    private injector: Injector,
    private fb: UntypedFormBuilder,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {
    this.service = this.injector.get<any>(this.route.snapshot.data.service);
    this.backUrl = this.route.snapshot.data.backUrl;
    this.target = this.route.snapshot.data.target;
    this.fetchImportsOfTheWeek();
  }

  public getRowClass: any = (): any => ({ "not-clickable": true });

  public ngOnInit(): void {
    this.refreshInterval = window.setInterval(() => this.fetchImportsOfTheWeek(), this.REFRESH_DELAY);
    this.addMenuActions();
    this.prepareForm();
    this.file = null;
  }

  public ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  public backToList(): void {
    this.router.navigateByUrl(this.backUrl);
  }

  public closeErrorsPopup(): void {
    this.popupVisible = false;
    this.errors = null;
  }

  public deleteFile(): void {
    this.importForm.controls.importFile.setValue([]);
    this.file = null;
  }

  public downloadFile(file: File | Blob, name: string): void {
    this.fileSaverService.save(file, name);
  }

  public getExportFile(): void {
    if (this.target && this.service) {
      this.exportService.exportTemplate(this.service, this.target);
    } else {
      this.catchAPIError("export", null);
    }
  }

  public getImportedFile(taskId: number): void {
    this.subscriptionService.subs.push(
      this.taskService.downloadTaskFile(taskId).subscribe(
        (response: HttpResponse<Blob>) => this.downloadFile(response.body, `import_${this.target}.xlsx`),
        (error: HttpErrorResponse) => this.catchAPIError("export", error)
      )
    );
  }

  public getStatusClass(status: TaskStatus): string {
    return `status-${status.toLowerCase()}`;
  }

  public manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case 0:
        this.openErrorsPopup(row.errors);
        break;
      default:
        break;
    }
  }

  public openErrorsPopup(errors: any): void {
    this.popupVisible = true;
    this.errors = errors;
  }

  private addMenuActions(): void {
    this.menuActions = [];
    this.menuActions.push(
      new MenuAction(0, this.translateService.instant("import-page.datatable.actions.view"), faEye)
    );
  }

  private catchAPIError(errorKey: string, error: HttpErrorResponse): void {
    const res =
      error?.status === this.PAYLOAD_TOO_LARGE
        ? this.translateService.instant("global.form.error.payload-too-large")
        : this.translateService.instant(`global.server-errors.${errorKey}`);
    const title = this.translateService.instant("message.title.api-errors");
    this.messageService.warn(res, { title });
  }

  private fetchImportsOfTheWeek(): void {
    const weekStart = dayjs().startOf("week").toDate().setUTCHours(0, 0, 0, 0);
    const weekEnd = dayjs().endOf("week").toDate().setUTCHours(0, 0, 0, 0);
    if (this.taskAlreadyInProgress) {
      return;
    }
    this.taskAlreadyInProgress = true;
    this.subscriptionService.subs.push(
      this.taskService.getTasks("import", weekStart, weekEnd).subscribe(
        (tasks: AsynchronousTask[]) => this.getImportRows(tasks),
        () => {
          const content = this.translateService.instant("import-page.errors.get-tasks");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private getImportRows(tasks: AsynchronousTask[]): void {
    this.rows = tasks
      .filter((task: AsynchronousTask) => task.params.split(";")[0] === this.target)
      .map((task: AsynchronousTask) => {
        return new ImportRow(task, this.translateService.instant("import-page.errors.pourri"));
      });
    this.rows = [...this.rows];
    this.taskAlreadyInProgress = false;
  }

  private onFileSelected(file: File): void {
    const formData = new FormData();
    formData.set("file", file);
    this.file = file;

    this.subscriptionService.subs.push(
      this.service.importFile(formData).subscribe({
        error: (error: HttpErrorResponse) => {
          this.catchAPIError("import", error);
        },
      })
    );
  }

  private prepareForm(): void {
    this.importForm = this.fb.group({ importFile: [[]] });

    this.subscriptionService.subs.push(
      this.importForm.get("importFile").valueChanges.subscribe((files: File[]) => {
        if (files.length > 0) {
          this.onFileSelected(files[0]);
        } else {
          this.file = null;
        }
      })
    );
  }
}
