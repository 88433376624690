<ng-container>
  <div class="contact-support-popup-container">
    <ui-popup title="{{ popupTitle | translate }}" (close)="closePopup()">
      <div content>
        <div class="sub-title">
          <span class="text" [innerHTML]="'contact-support-popup.sub-title' | translate"></span>
        </div>
        <div class="body">
          <span class="text" [innerHTML]="'contact-support-popup.body' | translate"></span>
        </div>
      </div>

      <div content class="team-listen">
        <fa-icon [icon]="faCommentsQuestionCheck" class="info-icon"></fa-icon>
        <div class="content-text">
          <div class="content-title">
            <span class="text" [innerHTML]="'contact-support-popup.team-listen-title' | translate"></span>
          </div>
          <div class="content-body">
            <span class="text" [innerHTML]="'contact-support-popup.team-listen-body' | translate"></span>
            <span>
              <ui-link
                text="{{ 'contact-support-popup.team-listen-mail' | translate }}"
                href="mailto:support@neo-logix.fr"
              ></ui-link>
            </span>
          </div>
        </div>
      </div>

      <div content class="freshdesk">
        <fa-icon [icon]="faHeadset" class="info-icon"></fa-icon>
        <div class="content-text">
          <div class="content-title">
            <span class="text" [innerHTML]="'contact-support-popup.freshdesk' | translate"></span>
          </div>
          <div class="content-body">
            <span class="text" [innerHTML]="'contact-support-popup.freshdesk-body' | translate"></span>
          </div>
        </div>
      </div>
      <div footer class="button-freshdesk">
        <div class="button">
          <ui-button
            text="{{ freshdesk | translate }}"
            [primary]="true"
            type="new-tab"
            (click)="openFreshdeskWindow()"
          ></ui-button>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
