import { CreditNoteCustomerLine } from "./credit-note-customer-line";
import { CreditNoteCustomerTotals } from "./credit-note-customer-totals";

export class CreditNoteCustomerTab {
  filteredLines: CreditNoteCustomerLine[] = [];
  totals: CreditNoteCustomerTotals;
  valid: boolean;
  headerCheck: boolean = false;

  constructor(values: any = {}) {
    if (values.filteredLines) {
      this.filteredLines = values.filteredLines.map(
        (filteredLine: CreditNoteCustomerLine) => new CreditNoteCustomerLine(filteredLine)
      );
    }
    this.totals = values.totals;
    this.valid = values.valid;
    this.headerCheck = values.headerCheck;
  }
}
