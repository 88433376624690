import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import { Option, SubscriptionService } from "fugu-common";
import { ErrorUtil } from "generic-pages";
import { filter } from "rxjs/operators";
import {
  AppConfigService,
  ReceptionType,
  PdfGeneratorTemplateService,
  PdfGeneratorTemplate,
  AppConfig,
  AuthService,
} from "center-services";
import { faExclamationTriangle, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { AppConfigForm } from "./app-config-form";

@Component({
  selector: "app-app-config",
  templateUrl: "./app-config.component.html",
  styleUrls: ["./app-config.component.scss"],
  providers: [SubscriptionService],
})
export class AppConfigComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted: boolean = false;
  public receptionTypeOptions: Option[] = [];
  public updatedAppConfig: AppConfigForm;
  public unsavedAppConfig: AppConfigForm;
  public editedAppConfig: AppConfigForm;

  public shouldClose: boolean = false;
  public faWarn: IconDefinition = faExclamationTriangle;
  public stockEntryLabelOptions: Option[];
  public conformityPopupVisible: boolean = false;

  public rights: string[];
  constructor(
    private fb: UntypedFormBuilder,
    private appConfigService: AppConfigService,
    private pdfGeneratorTemplateService: PdfGeneratorTemplateService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.rights = this.authService.getAuthorities();
    this.prepareForm();
    this.buildReceptionTypeOptions();

    if (this.appConfigService.appConfig.value) {
      this.editedAppConfig = new AppConfigForm({
        receptionType: this.appConfigService.appConfig.value.receptionType,
        conformityActivationDate: this.appConfigService.appConfig.value.conformityActivationDate,
      });
      this.loadData();
    } else {
      this.subscriptionService.subs.push(
        this.appConfigService.appConfig
          .pipe(filter(config => config !== null && config !== undefined))
          .subscribe(result => {
            this.editedAppConfig = new AppConfigForm({ receptionType: result.receptionType });
            this.loadData();
          })
      );
    }
  }

  loadData(): void {
    this.subscriptionService.subs.push(
      this.pdfGeneratorTemplateService.getAllByType("STOCKENTRYLABEL").subscribe({
        next: (templates: PdfGeneratorTemplate[]) => {
          this.stockEntryLabelOptions = templates
            .sort((a, b) => a.templateName.localeCompare(b.templateName))
            .map((obj: PdfGeneratorTemplate) => new Option(obj.id, obj.templateName));
          this.editedAppConfig.stockEntryLabelId = templates.filter(
            (template: PdfGeneratorTemplate) => template.activated
          )[0]?.id;
          this.updatedAppConfig = this.editedAppConfig
            ? new AppConfigForm(this.editedAppConfig)
            : new AppConfigForm({
              receptionType: ReceptionType.DIRECT,
              stockEntryLabelId: this.editedAppConfig.stockEntryLabelId,
            });

          const index = this.updatedAppConfig.receptionType
            ? Object.keys(ReceptionType).indexOf(this.editedAppConfig.receptionType)
            : 0;
          this.form.controls.receptionType.setValue(index);
          if (this.editedAppConfig.stockEntryLabelId) {
            this.form.controls.stockEntryLabel.setValue(this.updatedAppConfig.stockEntryLabelId);
          }
          this.form.controls.conformity.setValue(this.updatedAppConfig.isConform());
          if (this.updatedAppConfig.isConform()) {
            this.form.controls.conformity.disable();
          }
        },
      })
    );
  }

  buildReceptionTypeOptions(): void {
    this.receptionTypeOptions = [];
    this.receptionTypeOptions.push(
      new Option(
        0,
        this.translateService.instant("app-config.reception-type.direct"),
        false,
        this.translateService.instant("app-config.reception-type.direct-tooltip")
      )
    );
    this.receptionTypeOptions.push(
      new Option(
        1,
        this.translateService.instant("app-config.reception-type.with-registering"),
        false,
        this.translateService.instant("app-config.reception-type.with-registering-tooltip")
      )
    );
  }

  prepareForm(): void {
    this.form = this.fb.group({
      receptionType: [null, [Validators.required]],
      stockEntryLabel: [null, [Validators.required]],
      conformity: [false],
    });
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.applyModifications();

    if (this.editedAppConfig && this.editedAppConfig.equals(this.updatedAppConfig)) {
      return;
    }
    if (
      this.editedAppConfig.receptionType !== this.updatedAppConfig.receptionType ||
      this.editedAppConfig.conformityActivationDate !== this.updatedAppConfig.conformityActivationDate
    ) {
      let appConfig: AppConfig;
      if (this.rights.includes("GOD")) {
        appConfig = new AppConfig({
          receptionType: this.updatedAppConfig.receptionType,
          conformityActivationDate: this.updatedAppConfig.conformityActivationDate,
        });
      } else {
        appConfig = new AppConfig({
          data: null,
          conformityActivationDate: this.updatedAppConfig.conformityActivationDate,
        });
      }

      this.subscriptionService.subs.push(
        this.appConfigService.update(appConfig).subscribe(
          response => {
            this.editedAppConfig.receptionType = response.receptionType;
            this.editedAppConfig.conformityActivationDate = response.conformityActivationDate;
            const title = this.translateService.instant("message.title.save-success");
            const content = this.translateService.instant("message.content.save-success");
            this.messageService.success(content, { title });
          },
          error => {
            this.handleApiError(error);
          }
        )
      );
    }
    if (this.editedAppConfig.stockEntryLabelId !== this.updatedAppConfig.stockEntryLabelId) {
      this.subscriptionService.subs.push(
        this.pdfGeneratorTemplateService.activate(this.updatedAppConfig.stockEntryLabelId).subscribe(
          () => {
            this.editedAppConfig.stockEntryLabelId = this.updatedAppConfig.stockEntryLabelId;
            const title = this.translateService.instant("message.title.save-success");
            const res = this.translateService.instant("message.content.save-success");
            this.messageService.success(res, { title });
          },
          error => {
            this.handleApiError(error);
          }
        )
      );
    }
  }

  handleApiError(error: any): void {
    const result = ErrorUtil.getTranslationKey(error.error);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message);
    this.messageService.error(content, { title });
  }

  isDirty(): boolean {
    if (!this.updatedAppConfig) {
      return false;
    }
    this.applyModifications();
    if (this.shouldClose && !this.unsavedAppConfig.equals(this.updatedAppConfig)) {
      this.shouldClose = false;
    }

    if (this.editedAppConfig && !this.editedAppConfig.equals(this.updatedAppConfig) && !this.shouldClose) {
      this.shouldClose = true;
      this.unsavedAppConfig = new AppConfigForm(this.updatedAppConfig);

      return true;
    }
    this.shouldClose = false;
    return false;
  }

  applyModifications(): void {
    this.updatedAppConfig.receptionType = Object.values(ReceptionType)[this.form.value.receptionType];
    this.updatedAppConfig.stockEntryLabelId = this.form.value.stockEntryLabel;
    if (!this.updatedAppConfig.conformityActivationDate) {
      this.updatedAppConfig.conformityActivationDate =
        !this.editedAppConfig.isConform() && this.form.get("conformity").value === true ? new Date() : undefined;
    }
  }

  open(value: boolean): void {
    this.conformityPopupVisible = value;
  }

  closeConformityPopup(): void {
    this.conformityPopupVisible = false;
    this.form.controls.conformity.setValue(true);
    this.form.controls.conformity.disable();
  }

  cancelConformityPopup(): void {
    this.conformityPopupVisible = false;
    this.form.controls.conformity.setValue(this.updatedAppConfig.isConform());
  }
}
