import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { faInfoCircle, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import {
  CategoryType,
  PaginatedList,
  Pagination,
  RetailItemService,
  StandardItem,
  Sort,
  PurchaseModality,
  Alloy,
  AlloyService,
  AlloyComposition,
  CaraUserService,
  CaraUser,
  ItemCategory,
  ItemCategoryService,
  Light,
  LightService,
  BrandCollection,
  BrandService,
  ItemStatusType,
  OverrideSizeCategory,
  NomenclatureItem,
} from "center-services";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { NomenclatureItemSelectionOutput } from "./nomenclature-item-selection-output";

@Component({
  selector: "app-retail-item-nomenclature-item-selector",
  templateUrl: "./retail-item-nomenclature-item-selector.component.html",
  styleUrls: ["./retail-item-nomenclature-item-selector.component.scss"],
})
export class RetailItemNomenclatureItemSelectorComponent
  extends FilteredTableListComponent
  implements OnInit, PaginableComponent {
  @Input() rootItemSizeCategory: OverrideSizeCategory;
  @Input() rootItemId: number;
  @Input() nomenclatureItems: NomenclatureItem[] = [];

  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() validate: EventEmitter<StandardItem[]> = new EventEmitter();

  @ViewChild("table") table: DatatableComponent;

  public faInfoCircle: IconDefinition = faInfoCircle;

  public readonly LIST_ID: string = "app-retail-item-nomenclature-item-selector.items-list";

  public readonly confirmButtonOneLabel: string;
  public readonly confirmButtonManyLabel: string;
  public confirmButtonLabel: string;

  public tableControl: FormGroup;

  public sorts: any[] = [];
  public itemList: StandardItem[] = [];

  public itemOutput: NomenclatureItemSelectionOutput;

  public filterer: Filterer;
  public activeFilters: SearchFilter[] = [];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 7,
  });

  public locale: string;
  public dateFormat: string;

  private alloyList: Alloy[] = [];
  private itemCategoryList: ItemCategory[] = [];
  private brandList: Light[] = [];
  private brandCollectionList: BrandCollection[] = [];
  private initObservables: Observable<any>[] = [];

  private shouldClose: boolean = false;
  private initialOutput: NomenclatureItemSelectionOutput;
  private editedOutput: NomenclatureItemSelectionOutput;
  private unsavedOutput: NomenclatureItemSelectionOutput;

  private sessionPagination: SessionPagination;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private retailItemService: RetailItemService,
    private alloyService: AlloyService,
    private itemCategoryService: ItemCategoryService,
    private lightService: LightService,
    private brandService: BrandService
  ) {
    super(userService, translateService, messageService);
    this.confirmButtonOneLabel = this.translateService.instant("item.nomenclatures.item-selector.confirm-button-one");
    this.confirmButtonManyLabel = this.translateService.instant("item.nomenclatures.item-selector.confirm-button-many");
    this.confirmButtonLabel = this.confirmButtonOneLabel;

    this.sessionPagination = new SessionPagination(this);
  }

  ngOnInit(): void {
    this.initialOutput = new NomenclatureItemSelectionOutput();
    this.itemOutput = new NomenclatureItemSelectionOutput();
    this.editedOutput = new NomenclatureItemSelectionOutput();
    this.initSelectFormControl();

    this.initObservables.push(this.fetchItemCategoryList());
    this.initObservables.push(this.fetchBrandCollectionList());
    this.initObservables.push(this.fetchBrandList());
    this.initObservables.push(this.fetchAlloys());
    this.initObservables.push(this.fetchConnectedUserDetails());

    combineLatest(this.initObservables).subscribe(() => {
      this.initFilters();
      this.sessionPagination.loadFromSession(this.LIST_ID);
      this.fetchCompatibleItems();
    });
  }
  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  public getItemSupplierRefs(pms: PurchaseModality[]): string[] {
    return pms.filter(pm => !!pm.supplierRef).map(pm => pm.supplierRef);
  }

  public getItemAlloys(compositions: AlloyComposition[]): string[] {
    return compositions.map(comp => this.alloyList.find(alloy => alloy.id === comp.alloyId).businessName);
  }

  public applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.updatePreferences(
      this.filterer.filterValues.map(fv => fv.filterId),
      this.LIST_ID
    ).subscribe(() => {
      this.fetchCompatibleItems();
    });
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public getRowControlName(id: number): string {
    return `checked_${id}`;
  }

  public getRowClass: object = (row: StandardItem) => {
    return {
      disabled: this.isItemNotCompatible(row),
    };
  };

  public changeSortSettings(prop: string, direction: any): void {
    this.sorts = [{ prop: prop, dir: direction }];
    this.fetchCompatibleItems();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchCompatibleItems();
  }

  public isThereNoItemSelected(): boolean {
    return this.itemOutput.selectedItems.length === 0;
  }

  public closePopup(): void {
    this.editedOutput = new NomenclatureItemSelectionOutput(this.itemOutput);

    if (this.unsavedOutput && !this.unsavedOutput.equals(this.editedOutput)) {
      this.shouldClose = false;
    }

    if (!this.initialOutput.equals(this.editedOutput) && !this.shouldClose) {
      this.shouldClose = true;

      const message = this.translateService.instant("global.errors.unsaved-popin-content");
      const title = this.translateService.instant("global.errors.unsaved-title");
      this.messageService.info(message, { title });

      this.unsavedOutput = new NomenclatureItemSelectionOutput(this.editedOutput);
    } else {
      this.close.emit();
      this.shouldClose = false;
    }
  }

  public validatePopup(): void {
    this.validate.emit(this.itemOutput.selectedItems);
  }

  public isItemNotCompatible(item: StandardItem): boolean {
    return item.isInNomenclature || item.id === this.rootItemId;
  }

  public initFilters(): void {
    if (this.filterer) {
      return;
    }
    const translatePrefix = "item.nomenclatures.item-selector.datatable.column.";
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addListFilter(
      "category.id",
      this.translateService.instant(`${translatePrefix}item-category-name`),
      this.itemCategoryList
        .filter(cat => cat.type === CategoryType.STANDARD)
        .map(categ => {
          return { value: categ.id.toString(), displayValue: categ.name };
        })
    );

    this.filterer.addFilter("reference", this.translateService.instant(`${translatePrefix}item-reference`), "string");

    this.filterer.addFilter("name", this.translateService.instant(`${translatePrefix}item-name`), "string");

    this.filterer.addFilter(
      "purchaseModalities.supplierRef",
      this.translateService.instant(`${translatePrefix}item-suppliers-reference`),
      "string"
    );

    this.filterer.addFilter(
      "composition.alloy.businessName",
      this.translateService.instant(`${translatePrefix}item-alloys`),
      "string"
    );

    this.filterer.addListFilter(
      "brand.id",
      this.translateService.instant(`${translatePrefix}item-brand-name`),
      this.brandList
        .map(brand => {
          return { value: brand.id.toString(), displayValue: brand.name };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      null,
      null,
      null,
      null,
      true
    );

    this.filterer.addListFilter(
      "brandCollection.id",
      this.translateService.instant(`${translatePrefix}item-collection-name`),
      this.brandCollectionList
        .map(brandCollection => {
          return { value: brandCollection.id.toString(), displayValue: brandCollection.name };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      null,
      null,
      null,
      null,
      true
    );
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  private updateHeaderPageCheckbox(): void {
    const itemsChecked = this.itemList.filter(item => {
      return this.tableControl.get(this.getRowControlName(item.id)).value;
    });
    const isSelected = this.itemList.length > 0 && this.itemList.length === itemsChecked.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
    this.confirmButtonLabel = itemsChecked.length > 1 ? this.confirmButtonManyLabel : this.confirmButtonOneLabel;
  }

  private updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.itemList.forEach(item => {
      if (this.isItemNotCompatible(item)) {
        return;
      }
      controls[this.getRowControlName(item.id)].patchValue(isHeaderSelected);
    });
    this.confirmButtonLabel = isHeaderSelected ? this.confirmButtonManyLabel : this.confirmButtonOneLabel;
  }

  private updateSelection(): void {
    this.itemList.forEach(item => {
      const rowChecked: boolean = this.tableControl.controls[this.getRowControlName(item.id)].value;
      const isSelected: boolean = this.itemOutput.selectedItems.some(elem => elem.id === item.id);

      if (!isSelected && rowChecked) {
        this.itemOutput.selectedItems.push(item);
      } else if (isSelected && !rowChecked) {
        const index = this.itemOutput.selectedItems.findIndex(elem => elem.id === item.id);
        this.itemOutput.selectedItems.splice(index, 1);
      }
    });
  }

  private initRowFormControl(item: StandardItem): void {
    const control = new FormControl(false);
    if (this.isItemNotCompatible(item)) {
      control.disable();
    }
    this.tableControl.addControl(this.getRowControlName(item.id), control);
  }

  private initSelectFormControl(): void {
    this.tableControl = new FormGroup({
      headerCheckbox: new FormControl(false),
    });
  }

  private fetchCompatibleItems(): void {
    const itemFilters = [...this.activeFilters];

    this.nomenclatureItems.forEach((ni: NomenclatureItem) => {
      itemFilters.push(new SearchFilter("id", SearchFilterOperator.NOT_EQUAL, ni.itemId.toString()));
    });
    itemFilters.push(new SearchFilter("status", SearchFilterOperator.EQUAL, ItemStatusType.ACTIVE));
    itemFilters.push(new SearchFilter("category.type", SearchFilterOperator.EQUAL, CategoryType.STANDARD));
    itemFilters.push(new SearchFilter("id", SearchFilterOperator.NOT_EQUAL, this.rootItemId.toString()));
    itemFilters.push(
      new SearchFilter("sizeCategory.sizeCategory.id", SearchFilterOperator.IN, [
        "NULL",
        this.rootItemSizeCategory?.sizeCategoryId.toString(),
      ])
    );

    this.retailItemService.getAll(this.pager, this.getSorter(), itemFilters, this.rootItemId).subscribe(
      (result: PaginatedList<StandardItem>) => {
        this.pager = result.page;
        this.itemList = result.data;
        this.itemList.forEach(item => this.initRowFormControl(item));
        this.sessionPagination.saveToSession(this.LIST_ID);

        this.updateSelection();
        this.updateHeaderPageCheckbox();
      },
      error => {
        this.sendErrorAlert("item.nomenclatures.item-selector.errors.get-retail-items", error.message);
      },
      () => {
        this.table.sorts = this.sorts;
        this.table.offset = this.pager.number;
      }
    );
  }

  private fetchAlloys(): Observable<Alloy[]> {
    return this.alloyService.getAll().pipe(
      tap(
        (alloys: Alloy[]) => {
          this.alloyList = alloys;
        },
        error => {
          this.sendErrorAlert("item.nomenclatures.item-selector.errors.get-alloys", error.message);
        }
      )
    );
  }

  private fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap((connectedUser: CaraUser) => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  private fetchItemCategoryList(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll(["withRetailItem"]).pipe(
      tap(
        (itemCategories: ItemCategory[]) => (this.itemCategoryList = itemCategories),
        error => {
          this.sendErrorAlert("item.nomenclatures.item-selector.errors.get-item-categories", error.message);
        }
      )
    );
  }

  private fetchBrandList(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (lightBrands: Light[]) => (this.brandList = lightBrands),
        error => {
          this.sendErrorAlert("item.nomenclatures.item-selector.errors.get-brands", error.message);
        }
      )
    );
  }

  private fetchBrandCollectionList(): Observable<BrandCollection[]> {
    return this.brandService.getAllCollections().pipe(
      tap(
        (brandCollections: BrandCollection[]) => {
          this.brandCollectionList = brandCollections.filter((obj: BrandCollection) => !obj.archived);
        },
        error => {
          this.sendErrorAlert(`item.nomenclatures.item-selector.errors.get-brand-collections`, error.message);
        }
      )
    );
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(s.prop, s.dir));
    }
    return sorter;
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }
}
