<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'item-universes-list.title' | translate }}"></ui-title>
    </div>
    <div class="button">
      <ui-button
        text="{{ 'global.new' | translate }}"
        type="add"
        [primary]="true"
        (click)="onNewEntityClick()"
        *hasPermission="['ITEM_UNIVERSE_CREATE']"
      >
      </ui-button>
    </div>
    <div class="item-universes-table">
      <ui-container>
        <div [formGroup]="tableControl">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            class="material"
            columnMode="force"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [limit]="15"
            (activate)="onTableActivate($event)"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'item-universes-list.datatable.columns.name' | translate }}"
              prop="name"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item-universes-list.datatable.columns.activated' | translate }}"
              prop="activated"
              headerClass="center-align"
              cellClass="center-align"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-switch
                    customClass="no-margin"
                    (change)="changeEntityActivation(id)"
                    [formControl]="tableControl.get('activated_' + id)"
                  >
                  </ui-switch>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>

    <form class="item-universe-form" [formGroup]="popupForm" novalidate>
      <div class="popup-container">
        <ui-popup title="{{ popupTitle | translate }}" *ngIf="popupVisible" (close)="canClosePopup()">
          <div content>
            <ui-input-text
              type="text"
              label="{{ 'item-universes-list.datatable.columns.name' | translate }}"
              formControlName="name"
              [status]="popupForm.get('name') | formStatus"
              [errorMessage]="popupForm.get('name') | formErrorMessage | translate"
              mandatory="true"
              infoLabel="{{ 'global.unique' | translate }}"
            >
            </ui-input-text>
          </div>
          <div footer>
            <div class="align-buttons">
              <div class="button-popup-validate">
                <div class="button">
                  <ui-button
                    text="{{ 'global.validate' | translate }}"
                    type="save"
                    [primary]="true"
                    (click)="submitEntity()"
                  ></ui-button>
                </div>
              </div>
            </div>
          </div>
        </ui-popup>
      </div>
    </form>
  </div>
</ng-container>
