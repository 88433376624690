import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { IconDefinition, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  Currency,
  ItemPurchaseModality,
  Light,
  ItemCategory,
  Pagination,
  CaraUserService,
  ItemCategoryService,
  LightService,
  ItemPurchaseModalityService,
  ItemStatusType,
  PurchaseType,
  CaraUser,
  PaginatedList,
  Sort,
} from "center-services";

@Component({
  selector: "app-invoice-supplier-pm-selection",
  templateUrl: "./invoice-supplier-pm-selection.component.html",
  styleUrls: ["./invoice-supplier-pm-selection.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceSupplierPmSelectionComponent
  extends FilteredTableListComponent
  implements OnInit, OnChanges, PaginableComponent {
  public static LIST_ID: string = "app-invoice-supplier-pm-selection.pm-selection";
  @Input() supplierId: number;
  @Input() currency: Currency;
  @Input() selectedPMs: ItemPurchaseModality[] = [];
  @Output() selectedPMsChange: EventEmitter<ItemPurchaseModality[]> = new EventEmitter<ItemPurchaseModality[]>();

  @ViewChild("table") table: DatatableComponent;
  public rows: any[] = [];
  public tableControl: UntypedFormGroup;
  public itemPurchaseModalityList: ItemPurchaseModality[] = [];
  public lightSupplierList: Light[];
  public itemCategoryList: ItemCategory[] = [];
  public brandList: Light[] = [];
  public defaultSupplier: Light;
  public activeFilters: SearchFilter[] = [];
  public currentRowsId: number[] = [];
  public locale: string;
  public dateFormat: string;
  public initObservables: Observable<any>[] = [];
  public itemStatus: Map<number, string> = new Map();
  public isLoaded: Subject<never> = new Subject();
  public faSearch: IconDefinition = faSearch;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 7,
  });
  public sorts: any[] = [
    {
      prop: "statusLabel",
      dir: "asc",
    },
    {
      prop: "supplierRef",
      dir: "asc",
    },
  ];
  public filterer: Filterer;
  private statusTranslations: { [key: string]: string } = {};
  private purchaseTypeTranslations: { [key: string]: string } = {};
  private sessionPagination: SessionPagination;

  constructor(
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private itemCategoryService: ItemCategoryService,
    private lightService: LightService,
    private itemPurchaseModalityService: ItemPurchaseModalityService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      ItemStatusType,
      "invoice-supplier-pm-selection.status-options",
      this.statusTranslations
    );

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      PurchaseType,
      "invoice-supplier-pm-selection.datatable.purchase-type",
      this.purchaseTypeTranslations
    );

    this.sessionPagination = new SessionPagination(this);
  }

  ngOnInit(): void {
    this.initSelectFormControl();
    this.selectedPMs = [];

    this.initObservables = [];
    this.initObservables.push(this.fetchBrands());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchCategories());

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(InvoiceSupplierPmSelectionComponent.LIST_ID);
        this.computeSearchFilters();
        if (this.lightSupplierList && this.currency) {
          this.fetchItemPurchaseModalities();
        }
      })
    );
  }

  ngOnChanges(): void {
    if (this.lightSupplierList && this.currency) {
      this.fetchItemPurchaseModalities();
    }
  }

  initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.tableControl = this.fb.group({
      headerCheckbox: control,
    });
  }

  addRow(itemPM: ItemPurchaseModality): void {
    const statusLabel = this.translateService.instant(
      `invoice-supplier-pm-selection.status-options.${itemPM.itemStatus}`
    );
    const statusClass = this.getStatusClass(itemPM.itemStatus);

    this.rows.push({
      currencyIsoCode: this.currency.codeISO,
      itemCategory: itemPM.itemCategoryName,
      supplierRef: itemPM.itemSupplierRef,
      purchaseType: itemPM.purchaseType,
      itemRef: itemPM.itemReference,
      computedPrice: itemPM.computedPrice,
      pmId: itemPM.purchaseModalityId,
      minQuantity: itemPM.minQuantity,
      maxQuantity: itemPM.maxQuantity,
      supplierName: itemPM.supplierName,
      itemName: itemPM.itemName,
      brandName: itemPM.brandName,
      itemId: itemPM.itemId,
      statusLabel,
      statusClass,
    });

    this.initRowFormControl(itemPM.purchaseModalityId);
  }

  initRowFormControl(id: number): void {
    this.tableControl.addControl(this.getRowControlName(id), new UntypedFormControl(false));
  }

  onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  updateHeaderPageCheckbox(): void {
    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.tableControl.get(this.getRowControlName(id)).value;
    });
    const isSelected = this.currentRowsId.length > 0 && this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
  }

  updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
    });
  }

  updateSelection(): void {
    this.rows.forEach(row => {
      const rowChecked: boolean = this.tableControl.controls[this.getRowControlName(row.pmId)].value;
      const itemPurchaseModality: ItemPurchaseModality = this.itemPurchaseModalityList.find(
        elem => elem.purchaseModalityId === row.pmId
      );
      const isSelected: boolean =
        this.selectedPMs.findIndex(elem => elem.purchaseModalityId === row.pmId) === -1 ? false : true;

      if (!isSelected && rowChecked) {
        this.selectedPMs.push(itemPurchaseModality);
      } else if (isSelected && !rowChecked) {
        const index = this.selectedPMs.findIndex(
          elem => elem.purchaseModalityId === itemPurchaseModality.purchaseModalityId
        );
        this.selectedPMs.splice(index, 1);
      }
    });
    this.sessionPagination.saveToSession(InvoiceSupplierPmSelectionComponent.LIST_ID);
    this.selectedPMsChange.emit(this.selectedPMs);
  }

  getRowControlName(id: number): string {
    return `checked_${id}`;
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchItemPurchaseModalities();
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  fetchItemPurchaseModalities(): void {
    const cloneActiveFilters = [...this.activeFilters];
    cloneActiveFilters.push(new SearchFilter("currency.id", SearchFilterOperator.EQUAL, this.currency.id.toString()));
    cloneActiveFilters.push(new SearchFilter("archived", SearchFilterOperator.EQUAL, "false"));
    cloneActiveFilters.push(new SearchFilter("retailItem.canBuy", SearchFilterOperator.EQUAL, "true"));

    if (this.supplierId && this.activeFilters.find(sFilter => sFilter.key === "supplier.id") === undefined) {
      const suppliersId = [...[this.supplierId.toString()], ...["NULL"]];
      cloneActiveFilters.push(new SearchFilter("supplier.id", SearchFilterOperator.IN, suppliersId));
    }

    this.subscriptionService.subs.push(
      this.itemPurchaseModalityService.getAll(this.pager, this.getSorter(), cloneActiveFilters).subscribe(
        (result: PaginatedList<ItemPurchaseModality>) => {
          this.rows = [];
          this.pager = result.page;
          this.itemPurchaseModalityList = result.data;

          this.currentRowsId = result.data.map((itemPM: ItemPurchaseModality) => itemPM.purchaseModalityId);
          result.data.forEach((itemPM: ItemPurchaseModality) => {
            this.addRow(itemPM);
          });
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant(
            "invoice-supplier-pm-selection.errors.get-item-purchase-modalities"
          );
          this.messageService.warn(content, { title });
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;

          this.updateSelection();
          this.updateHeaderPageCheckbox();
        }
      )
    );
  }

  fetchBrands(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Light[]) => {
          this.brandList = brands
            .filter((light: Light) => !light.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-pm-selection.errors.get-brands");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchCategories(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (categories: ItemCategory[]) => {
          this.itemCategoryList = categories
            .filter((obj: ItemCategory) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-pm-selection.errors.get-categories");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.lightSupplierList = lightSuppliers
            .filter(obj => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("invoice-supplier-pm-selection.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  getStatusClass(status: ItemStatusType): string {
    return `status-${status.toLowerCase()}`;
  }

  getRowClass: any = (): any => ({ "not-clickable": true });

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  getSorter(): Sort[] {
    const sorter: Sort[] = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  changeSortSettings(prop: string, dir: string): void {
    switch (prop) {
      case "statusLabel":
        this.sorts = [
          {
            prop: "statusLabel",
            dir,
          },
        ];
        break;
      default:
        this.sorts = [
          {
            dir: "asc",
            prop: "statusLabel",
          },
          {
            prop,
            dir,
          },
        ];
        break;
    }
    this.fetchItemPurchaseModalities();
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === InvoiceSupplierPmSelectionComponent.LIST_ID
    );

    this.filterer = new Filterer(componentFilterPref?.filters);
    const suppliersList = [{ value: "NULL", displayValue: this.translateService.instant("global.none") }];
    this.lightSupplierList.forEach((supplier: Light) => {
      suppliersList.push({ value: supplier.id.toString(), displayValue: `${supplier.reference} - ${supplier.name}` });
    });
    this.filterer.addListFilter(
      "supplier.id",
      this.getColumnTranslation("other-supplier-names"),
      suppliersList,
      false,
      false,
      [],
      null,
      true,
      false
    );

    this.filterer.addListFilter(
      "retailItem.category.name",
      this.getColumnTranslation("item-category"),
      this.itemCategoryList.map((category: ItemCategory) => {
        return { value: category.name, displayValue: category.name };
      }),
      false,
      false,
      [],
      null,
      true,
      false
    );

    this.filterer.addFilter("retailItem.reference", this.getColumnTranslation("item-ref"), "string");

    this.filterer.addFilter("supplierRef", this.getColumnTranslation("supplier-ref"), "string");

    this.filterer.addFilter("retailItem.name", this.getColumnTranslation("item-name"), "string");

    this.filterer.addListFilter(
      "purchaseType",
      this.getColumnTranslation("purchase-type"),
      Object.keys(PurchaseType).map(key => ({ value: key, displayValue: this.purchaseTypeTranslations[key] })),
      false,
      false
    );

    this.filterer.addListFilter(
      "retailItem.brand.name",
      this.getColumnTranslation("brand-name"),
      this.brandList.map((brand: Light) => {
        return { value: brand.name, displayValue: brand.name };
      }),
      false,
      false,
      [],
      null,
      true,
      false
    );

    this.filterer.addFilter("minQuantity", this.getColumnTranslation("min-quantity"), "range");

    this.filterer.addFilter("maxQuantity", this.getColumnTranslation("max-quantity"), "range");

    this.filterer.addListFilter(
      "retailItem.status",
      this.getColumnTranslation("status"),
      Object.keys(ItemStatusType).map(key => ({ value: key, displayValue: this.statusTranslations[key] }))
    );
  }

  getColumnTranslation(columnName: string): string {
    return this.translateService.instant(`invoice-supplier-pm-selection.datatable.columns.${columnName}`);
  }

  applyFilters(): void {
    this.activeFilters = [];
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        InvoiceSupplierPmSelectionComponent.LIST_ID
      ).subscribe(() => {
        this.fetchItemPurchaseModalities();
      })
    );
  }

  private propToDto(propName: string): string {
    switch (propName) {
      case "brandName":
        return "retailItem.brand.name";
      case "itemRef":
        return "retailItem.reference";
      case "itemCategory":
        return "retailItem.category";
      case "statusLabel":
        return "retailItem.status";
      case "itemName":
        return "retailItem.name";
      case "supplierName":
        return "supplier.name";
      default:
        return propName;
    }
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }
}
