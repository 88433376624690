import { Comparator } from "fugu-common";

export class DeliveryPopup extends Comparator {
  orderLinked: number;
  orderKnown: number;
  senderId: number;
  receiverId: number;
  refs: string[];

  constructor(values: any = {}) {
    super();
    this.orderLinked = values.orderLinked;
    this.orderKnown = values.orderKnown;
    this.senderId = values.senderId;
    this.receiverId = values.receiverId;
    this.refs = values.refs;

    if (values.refs) {
      this.refs = values.refs.map(elem => elem);
    }
  }
}
