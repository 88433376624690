<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'item-groups-list.title' | translate }}"></ui-title>
    </div>
    <div class="button">
      <ui-button
        text="{{ 'item-groups-list.new' | translate }}"
        type="add"
        [primary]="true"
        (click)="onNewEntityClick()"
        *hasPermission="['ITEM_GROUP_CREATE']"
      >
      </ui-button>
    </div>
    <div class="item-groups-table">
      <ui-container>
        <div [formGroup]="tableControl">
          <ngx-datatable
            #table
            [rows]="rows"
            class="material"
            [trackByProp]="'id'"
            columnMode="force"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            [limit]="15"
            (activate)="onTableActivate($event)"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down'
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'item-groups-list.datatable.columns.name' | translate }}"
              prop="name"
              [resizeable]="false"
              cellClass="name"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item-groups-list.datatable.columns.universe' | translate }}"
              prop="universe"
              cellClass="universe"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'item-groups-list.datatable.columns.activated' | translate }}"
              prop="activated"
              headerClass="center-align"
              cellClass="center-align"
              [resizeable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-switch
                    customClass="no-margin"
                    (change)="changeEntityActivation(id)"
                    [formControl]="tableControl.get('activated_' + id)"
                  >
                  </ui-switch>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>
  </div>
</ng-container>
