/* eslint-disable no-magic-numbers */
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Option, SubscriptionService, RoundingUtil } from "fugu-common";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { Location } from "@angular/common";
import { tap } from "rxjs/operators";
import {
  PurchaseOrder,
  CompanyConfig,
  Currency,
  Supplier,
  ShippingFeeType,
  CurrencyService,
  SupplierService,
  CaraUserService,
  StoreService,
  LightService,
  PurchaseOrderType,
  CaraUser,
  Light,
  ShippingFeeRange,
  Store,
} from "center-services";

@Component({
  selector: "app-purchase-order-header",
  templateUrl: "./purchase-order-header.component.html",
  styleUrls: ["./purchase-order-header.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderHeaderComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() editedPurchaseOrder: PurchaseOrder;
  @Input() companyConfig: CompanyConfig;

  @Output() submitEvent: EventEmitter<PurchaseOrder> = new EventEmitter<PurchaseOrder>();
  @Output() editedPurchaseOrderChange: EventEmitter<PurchaseOrder> = new EventEmitter<PurchaseOrder>();
  public headerForm: UntypedFormGroup = new UntypedFormGroup({});
  public supplier: Supplier = null;
  public companyAddressOptions: Option[] = [];
  public storeAddressOptions: Option[] = [];
  public shippingFeeType: ShippingFeeType;
  public orderTypeOptions: Option[];
  public statusOptions: Option[];
  public selectedCurrency: Currency;
  public currencyOptions: Option[];
  public supplierOptions: Option[];
  public shippingFeePrice: number;
  public storeOptions: Option[];
  public minOrderPrice: number;
  public codeLanguage: string;
  public maxPayment: string;
  public isUpdated: boolean;
  private readonly DRAFT_OPTION_ID: number = 1;
  private readonly CONFIRMED_OPTION_ID: number = 5;
  private initObservables: Observable<any>[] = [];
  private currencies: Currency[] = [];

  constructor(
    private translateService: TranslateService,
    private currencyService: CurrencyService,
    private supplierService: SupplierService,
    private userService: CaraUserService,
    private storeService: StoreService,
    private lightService: LightService,
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private location: Location,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  public ngOnChanges(): void {
    this.isUpdated = this.location.path().includes("/purchase-order/update/") ? true : false;
  }

  public ngOnInit(): void {
    this.initObservables = [];

    this.initObservables.push(this.fetchCodeLanguage());
    this.initObservables.push(this.fetchCurrencies());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchStores());
    this.buildOrderTypeOptions();
    this.buildStatusOptions();

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildOrderTypeOptions();
        this.buildStatusOptions();
      })
    );

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedPurchaseOrder) {
          this.loadEditedData();
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  public updatePurchaseOrder(): boolean {
    if (this.headerForm.invalid) {
      this.headerForm.markAllAsTouched();
      return false;
    }
    this.applyModifications();
    return true;
  }

  public applyModifications(): void {
    // classic fields
    this.editedPurchaseOrder.comment = this.headerForm.value.comment;
    this.editedPurchaseOrder.currencyId = this.headerForm.value.currency;
    this.editedPurchaseOrder.supplierId = this.headerForm.value.supplier;
    this.editedPurchaseOrder.deliveryStoreId = this.headerForm.value.deliveryStore;
    this.editedPurchaseOrder.deliveryAddressId = this.headerForm.value.deliveryAddress;

    // order type
    this.editedPurchaseOrder.orderType = Object.values(PurchaseOrderType)[this.headerForm.value.orderType];
  }

  public loadEditedData(): void {
    this.headerForm.controls.comment.setValue(this.editedPurchaseOrder.comment);
    // handle status
    if (this.editedPurchaseOrder.orderType) {
      const index = Object.keys(PurchaseOrderType).indexOf(this.editedPurchaseOrder.orderType);
      this.headerForm.controls.orderType.setValue(index);
    }

    if (this.editedPurchaseOrder.supplierId) {
      this.headerForm.controls.supplier.setValue(this.editedPurchaseOrder.supplierId);
    }

    if (this.editedPurchaseOrder.deliveryStoreId) {
      this.headerForm.controls.deliveryStore.setValue(this.editedPurchaseOrder.deliveryStoreId);
    }

    if (this.editedPurchaseOrder.deliveryAddressId) {
      this.headerForm.controls.deliveryAddress.setValue(this.editedPurchaseOrder.deliveryAddressId);
    }

    if (this.editedPurchaseOrder.currencyId) {
      this.headerForm.controls.currency.setValue(this.editedPurchaseOrder.currencyId);
    }

    this.subscriptionService.subs.push(
      this.headerForm.statusChanges.subscribe(() => {
        this.editedPurchaseOrderChange.emit({
          ...this.editedPurchaseOrder,
          ...{
            comment: this.headerForm.value.comment,
            currencyId: this.headerForm.getRawValue().currency, // RawValue to get value of disabled controls
            supplierId: this.headerForm.getRawValue().supplier,
            deliveryStoreId: this.headerForm.value.deliveryStore,
            deliveryAddressId: this.headerForm.value.deliveryAddress,
            orderType: Object.values(PurchaseOrderType)[this.headerForm.value.orderType],
          },
        } as PurchaseOrder);
      })
    );
  }

  private buildOrderTypeOptions(): void {
    this.orderTypeOptions = Object.keys(PurchaseOrderType).map(
      (key, index) => new Option(index, this.translateService.instant(`purchase-order.header.order-options.${key}`))
    );
  }

  private buildStatusOptions(): void {
    this.statusOptions = [];
    this.statusOptions.push(
      new Option(
        this.DRAFT_OPTION_ID,
        this.translateService.instant("purchase-order.header.order-status-options.DRAFT")
      )
    );
    this.statusOptions.push(
      new Option(
        this.CONFIRMED_OPTION_ID,
        this.translateService.instant("purchase-order.header.order-status-options.CONFIRMED")
      )
    );
  }

  private convertCommercialModalityData(): void {
    if (this.supplier && this.selectedCurrency && this.supplier.currencyId === this.selectedCurrency.id) {
      this.getCommercialModalitiesData();
      return;
    }
    if (this.shippingFeeType === ShippingFeeType.PRICE && this.shippingFeePrice) {
      this.shippingFeePrice = this.currencyService.computeDeviseConversion(
        this.shippingFeePrice,
        this.supplier.currencyId,
        this.selectedCurrency.id
      );
    }
    if (this.minOrderPrice) {
      this.minOrderPrice = this.currencyService.computeDeviseConversion(
        this.minOrderPrice,
        this.supplier.currencyId,
        this.selectedCurrency.id
      );
    }
  }

  private fetchCodeLanguage(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.codeLanguage = connectedUser.codeLanguage;
      })
    );
  }

  private fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap((currencies: Currency[]) => {
        const defaultCurrency = currencies.find((currency: Currency) => currency.byDefault);
        if (defaultCurrency && (!this.editedPurchaseOrder || !this.editedPurchaseOrder.id)) {
          this.headerForm.get("currency").setValue(defaultCurrency.id);
          this.selectedCurrency = defaultCurrency;
        }
        this.currencies = currencies;

        this.currencyOptions = currencies
          .filter(obj => !obj.archived)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(obj => new Option(obj.id, obj.symbol));

        if (this.editedPurchaseOrder) {
          const editedCurrency = currencies.find(currency => currency.id === this.editedPurchaseOrder.currencyId);

          if (editedCurrency && editedCurrency.archived) {
            this.currencyOptions.push(new Option(editedCurrency.id, editedCurrency.symbol));
            const title = this.translateService.instant("purchase-order.header.message.warning-title");
            const content = this.translateService.instant("purchase-order.header.message.currency-archived");
            this.messageService.warn(content, { title });
          }
        }
      })
    );
  }

  private fetchSelectedSupplier(supplierId: number): void {
    if (supplierId === null || supplierId === undefined) {
      this.supplier = null;
      return;
    }
    this.subscriptionService.subs.push(
      this.supplierService.get(supplierId).subscribe((supplier: Supplier) => {
        this.supplier = supplier;
        this.setSupplierCurrency();
        this.getCommercialModalitiesData();
        this.convertCommercialModalityData();
      })
    );
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap((lightStores: Light[]) => {
        this.storeOptions = lightStores
          .filter(obj => !obj.archived)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(obj => new Option(obj.id, obj.name));

        if (this.editedPurchaseOrder) {
          const editedStore = lightStores.find(store => store.id === this.editedPurchaseOrder.deliveryStoreId);

          if (editedStore && editedStore.archived) {
            this.storeOptions.push(new Option(editedStore.id, editedStore.name));
            const title = this.translateService.instant("purchase-order.header.message.warning-title");
            const content = this.translateService.instant("purchase-order.header.message.store-archived");
            this.messageService.warn(content, { title });
          }
        }
      })
    );
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap((lightSuppliers: Light[]) => {
        this.supplierOptions = lightSuppliers
          .filter(obj => !obj.archived)
          .sort((a, b) => a.reference.localeCompare(b.reference))
          .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));

        if (this.editedPurchaseOrder) {
          const editedSupplier = lightSuppliers.find(supplier => supplier.id === this.editedPurchaseOrder.supplierId);

          if (editedSupplier && editedSupplier.archived) {
            this.supplierOptions.push(
              new Option(editedSupplier.id, `${editedSupplier.reference} - ${editedSupplier.name}`)
            );
            const title = this.translateService.instant("purchase-order.header.message.warning-title");
            const content = this.translateService.instant("purchase-order.header.message.supplier-archived");
            this.messageService.warn(content, { title });
          }
        }
      })
    );
  }

  private getCommercialModalitiesData(): void {
    this.maxPayment = this.translateService.instant("purchase-order.header.undefined");
    this.shippingFeePrice = null;
    this.shippingFeeType = null;
    this.minOrderPrice = null;

    if (!this.supplier.commercialModality) {
      return;
    }

    const shippingFee = this.supplier.commercialModality.shippingFeeRanges.find(
      (shippingFeeRange: ShippingFeeRange) => {
        return shippingFeeRange.price === 0;
      }
    );
    if (shippingFee) {
      this.shippingFeeType = this.supplier.commercialModality.shippingFeeType;
      this.shippingFeePrice = shippingFee.threshold;
    }

    if (this.supplier.commercialModality.maxPaymentType && this.supplier.commercialModality.maxPaymentPeriod) {
      this.maxPayment = this.translateService.instant(
        `purchase-order.header.maxPayment.${this.supplier.commercialModality.maxPaymentType}`,
        { period: this.supplier.commercialModality.maxPaymentPeriod }
      );
    }

    this.minOrderPrice = RoundingUtil.roundHigh(this.supplier.commercialModality.minOrderPrice);
  }

  private getSelectedStoreAdresses(storeId: number): void {
    if (storeId === null || storeId === undefined) {
      return;
    }
    this.subscriptionService.subs.push(
      this.storeService.get(storeId).subscribe((store: Store) => {
        const defaultStoreAddress = store.deliveryAddresses.find(address => address.byDefault);
        const editedStoreAddress = store.deliveryAddresses.find(
          address => address.id === this.editedPurchaseOrder.deliveryAddressId
        );
        if (editedStoreAddress) {
          this.headerForm.get("deliveryAddress").setValue(editedStoreAddress.id);
        } else if (defaultStoreAddress) {
          this.headerForm.get("deliveryAddress").setValue(defaultStoreAddress.id);
        }

        this.storeAddressOptions = store.deliveryAddresses
          .filter(obj => !obj.archived)
          .map(obj => new Option(obj.id, `${obj.lines}, ${obj.cityCode} ${obj.city}`))
          .sort((a, b) => a.label.localeCompare(b.label));

        if (editedStoreAddress && editedStoreAddress.archived) {
          this.storeAddressOptions.push(
            new Option(
              editedStoreAddress.id,
              `${editedStoreAddress.lines}, ${editedStoreAddress.cityCode} ${editedStoreAddress.city}`
            )
          );

          const title = this.translateService.instant("purchase-order.header.message.warning-title");
          const content = this.translateService.instant("purchase-order.header.message.store-address-archived");
          this.messageService.warn(content, { title });
        }
      })
    );
  }

  private onCurrencyChange(currencyId: number): void {
    this.selectedCurrency = this.currencies.find((currency: Currency) => {
      return currency.id === currencyId;
    });
    if (this.selectedCurrency) {
      this.convertCommercialModalityData();
    }
  }

  private prepareForm(): void {
    this.headerForm = this.fb.group({
      comment: [null],
      supplier: [null, [Validators.required]],
      currency: [null, [Validators.required]],
      orderType: [null, [Validators.required]],
      deliveryStore: [null, [Validators.required]],
      deliveryAddress: [null, [Validators.required]],
    });

    this.subscriptionService.subs.push(
      this.headerForm.get("currency").valueChanges.subscribe((value: number) => {
        this.onCurrencyChange(value);
      })
    );
    this.subscriptionService.subs.push(
      this.headerForm.get("supplier").valueChanges.subscribe((value: number) => {
        this.fetchSelectedSupplier(value);
      })
    );
    this.subscriptionService.subs.push(
      this.headerForm.get("deliveryStore").valueChanges.subscribe((value: number) => {
        this.getSelectedStoreAdresses(value);
      })
    );
  }

  private setSupplierCurrency(): void {
    if (this.editedPurchaseOrder && !this.editedPurchaseOrder.id && this.supplier.currencyId) {
      const editedCurrency = this.currencies.find(
        (currency: Currency) => currency.id === this.supplier.currencyId && !currency.archived
      );
      if (editedCurrency) {
        this.headerForm.controls.currency.setValue(editedCurrency.id);
      }
    }
  }
}
