<ng-container>
  <div class="main-container">
    <div class="parameters-button">
      <ui-button
        *hasPermission="['SIZE_CATEGORY_READ']"
        text="{{ 'override-size-category-list.metric-parameters' | translate }}"
        type="settings"
        (click)="goToSizeCategories()"
      >
      </ui-button>
    </div>

    <div class="size-category-table">
      <div [formGroup]="tableControl">
        <ngx-datatable
          #table
          [rows]="rows"
          [trackByProp]="'id'"
          class="material"
          columnMode="force"
          headerHeight="auto"
          footerHeight="50"
          rowHeight="40"
          [rowClass]="getRowClass"
          [limit]="15"
          (activate)="updateOverrideSizeCategory($event)"
          [cssClasses]="{
            sortAscending: 'fas fa-chevron-up',
            sortDescending: 'fas fa-chevron-down'
          }"
          [messages]="{ emptyMessage: 'global.no-data' | translate }"
          [sorts]="sorts"
          (sort)="changeSortSettings($event.column.prop, $event.newValue)"
        >
          <ngx-datatable-column
            name="{{ 'size-categories-list.datatable.columns.name' | translate }}"
            prop="name"
            cellClass="name"
            [resizeable]="false"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'size-categories-list.datatable.columns.sizeValue' | translate }}"
            prop="values"
            cellClass="values ellipsis-counter"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-values="row.values">
              <p class="ellipsis">{{ values.join(", ") }}</p>
              <div *ngIf="values.length >= 2">
                <div #valuesCounter>
                  <ui-counter [number]="values.length"></ui-counter>
                </div>
                <ui-tooltip maxWidth="300px" [element]="valuesCounter">
                  <span style="font-weight: normal !important; white-space: break-spaces">{{ values.join(", ") }}</span>
                </ui-tooltip>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'size-categories-list.datatable.columns.activated' | translate }}"
            prop="activated"
            headerClass="center-align"
            cellClass="activated center-align"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
              <div class="green-zone" (click)="$event.stopPropagation()">
                <ui-switch
                  customClass="no-margin"
                  (change)="activeCheckboxOnChanges(id)"
                  [formControl]="tableControl.get('activated_' + id)"
                >
                </ui-switch>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="rowCount > 0">
                {{
                  rowCount
                    | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                    | translate: { rowCount: rowCount }
                }}
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                [pagerRightArrowIcon]="'fas fa-chevron-right'"
                [pagerNextIcon]="'fas fa-chevron-double-right'"
                [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="table.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>

  <!-- Popup -->
  <override-size-category-popup
    *ngIf="popupVisible"
    [overrideSizeCategory]="selectedSizeCategory"
    (validate)="onValidateSizeCategory($event)"
    (close)="onClosePopup()"
  ></override-size-category-popup>
</ng-container>
