<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ 'page-not-found.back-link' | translate }}"
        (click)="backToLastPage()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
    </div>

    <div class="content">
      <ui-container>
        <div style="text-align: center">
          <img src="{{ this.imageLink }}" />
        </div>

        <div class="bottom-buttons">
          <div class="button">
            <ui-button
              text="{{ 'page-not-found.back-home' | translate }}"
              type="return"
              [primary]="true"
              (click)="backToHome()"
            ></ui-button>
          </div>
          <div class="link">
            <ui-link text="{{ 'page-not-found.help-site' | translate }}" (click)="goToHelpSite()"></ui-link>
          </div>
        </div>
      </ui-container>
    </div>
  </div>
</ng-container>
