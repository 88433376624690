<div class="main-container">
  <label class="list-label ng-star-inserted" style="padding-bottom: 4px">
    <b>{{ "renovation.title" | translate }}</b>
  </label>
  <div class="renovations-table">
    <div class="top-table-buttons">
      <ui-button text="{{ 'renovation.datatable.add-btn-text' | translate }}" type="add" (click)="addRenovation()">
      </ui-button>
    </div>
    <ngx-datatable
      #table
      [rows]="rows"
      [limit]="7"
      [trackByProp]="'index'"
      class="material"
      columnMode="flex"
      headerHeight="auto"
      footerHeight="auto"
      rowHeight="40"
      [messages]="{ emptyMessage: 'global.no-data' | translate }"
      [cssClasses]="{
        sortDescending: 'fas fa-chevron-down',
        sortAscending: 'fas fa-chevron-up'
      }"
      [sorts]="[{ prop: 'date', dir: 'asc' }]"
      (activate)="updateRenovation($event)"
    >
      <ngx-datatable-column
        name="{{ 'renovation.datatable.columns.date' | translate }}"
        cellClass="top-align"
        [flexGrow]="0.85"
        prop="date"
        [resizeable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row">
          <span>{{ row.date | dateTimeFormat: false }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'renovation.datatable.columns.comment' | translate }}"
        cellClass="top-align"
        [flexGrow]="1"
        prop="comment"
        [resizeable]="false"
      >
        <ng-template ngx-datatable-cell-template let-comment="row.comment">
          <span [class]="'ellipsis'" [title]="comment">{{ comment }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'renovation.datatable.columns.actions' | translate }}"
        cellClass="center-align icon-cell"
        [flexGrow]="0.25"
        [resizeable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="green-zone" (click)="$event.stopPropagation()">
            <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)"></ui-menu-actions>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
          let-isVisible="isVisible"
        >
          <datatable-pager
            [pagerLeftArrowIcon]="'fas fa-chevron-left'"
            [pagerRightArrowIcon]="'fas fa-chevron-right'"
            [pagerNextIcon]="'fas fa-chevron-double-right'"
            [pagerPreviousIcon]="'fas fa-chevron-double-left'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!(rowCount / pageSize > 1)"
            (change)="table.onFooterPage($event)"
          >
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>

  <form class="renovation-form" [formGroup]="renovationForm" novalidate (ngSubmit)="submitRenovation()">
    <div class="popup-container">
      <ui-popup title="{{ popupTitle | translate }}" *ngIf="popupVisible" (close)="canClose()">
        <div content>
          <ui-date-picker
            mandatory="true"
            [locale]="locale"
            [format]="dateFormat"
            formControlName="date"
            label="{{ 'renovation.datatable.columns.date' | translate }}"
            [status]="renovationForm.get('date') | formStatus"
            [errorMessage]="renovationForm.get('date') | formErrorMessage | translate"
          >
          </ui-date-picker>

          <ui-textarea
            mandatory="true"
            maxlength="1024"
            formControlName="comment"
            label="{{ 'renovation.datatable.columns.comment' | translate }}"
            [status]="renovationForm.get('comment') | formStatus"
            [errorMessage]="renovationForm.get('comment') | formErrorMessage | translate"
          >
          </ui-textarea>
        </div>
        <div footer>
          <div class="align-buttons">
            <div class="button-popup-add">
              <ui-button text="{{ 'global.add-update' | translate }}" [primary]="true" type="add"></ui-button>
            </div>
          </div>
        </div>
      </ui-popup>
    </div>
  </form>
</div>
