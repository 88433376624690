<ng-container>
  <div class="main-container">
    <ui-container>
      <div *ngIf="!readOnly" class="invoice-header-form editable" [formGroup]="invoiceHeaderForm">
        <div class="grid-item medium" *ngIf="showReference">
          <ui-input-text
            *ngIf="!readOnly"
            type="text"
            mandatory="true"
            formControlName="invoiceRef"
            [status]="invoiceHeaderForm.get('invoiceRef') | formStatus"
            [errorMessage]="invoiceHeaderForm.get('invoiceRef') | formErrorMessage | translate"
            label="{{ 'invoice-form-header.fields.invoice-ref' | translate }}"
          >
          </ui-input-text>
        </div>
        <div class="grid-item small">
          <ui-date-picker
            *ngIf="!readOnly && !this.modeIsConform"
            [locale]="locale"
            [format]="dateFormat"
            formControlName="date"
            mandatory="true"
            [status]="invoiceHeaderForm.get('date') | formStatus"
            [errorMessage]="invoiceHeaderForm.get('date') | formErrorMessage | translate"
            label="{{ 'invoice-form-header.fields.date' | translate }}"
          >
          </ui-date-picker>
          <ui-info-field
            *ngIf="!readOnly && this.modeIsConform"
            [label]="'invoice-form-header.fields.date' | translate"
            [class]="'date'"
            [value]="this.invoiceHeaderForm.controls.date.value | dateTimeFormat: false"
          >
          </ui-info-field>
        </div>

        <div class="grid-item small">
          <ui-date-picker
            *ngIf="!readOnly && !this.modeIsConform"
            [locale]="locale"
            [format]="dateFormat"
            formControlName="quickPaymentDate"
            label="{{ 'invoice-form-header.fields.quick-payment-date' | translate }}"
          >
          </ui-date-picker>
          <ui-info-field
            *ngIf="!readOnly && this.modeIsConform"
            [label]="'invoice-form-header.fields.quick-payment-date' | translate"
            [class]="'date'"
            value="{{
              this.invoiceHeaderForm.controls.quickPaymentDate.value
                ? (this.invoiceHeaderForm.controls.quickPaymentDate.value | dateTimeFormat: false)
                : ''
            }}"
          >
          </ui-info-field>
        </div>

        <div class="grid-item small">
          <ui-date-picker
            *ngIf="!readOnly && !this.modeIsConform"
            [locale]="locale"
            [format]="dateFormat"
            formControlName="maxPaymentDate"
            label="{{ 'invoice-form-header.fields.max-payment-date' | translate }}"
          >
          </ui-date-picker>
          <ui-info-field
            *ngIf="!readOnly && this.modeIsConform"
            [label]="'invoice-form-header.fields.max-payment-date' | translate"
            [class]="'date'"
            value="{{
              this.invoiceHeaderForm.controls.maxPaymentDate.value
                ? (this.invoiceHeaderForm.controls.maxPaymentDate.value | dateTimeFormat: false)
                : ''
            }}"
          >
          </ui-info-field>
        </div>

        <div class="grid-item medium comment" *ngIf="showComment">
          <div class="label">{{ "invoice-form-header.fields.comment" | translate }}</div>

          <div class="value">
            <div *ngIf="!invoiceHeaderForm.controls.comment?.value && !readOnly">
              <fa-icon [icon]="faPen" class="comment-icon" (click)="openCommentPopup()"></fa-icon>
            </div>

            <div *ngIf="invoiceHeaderForm.controls.comment?.value">
              <div class="ellipsis-with-icon">
                <p class="ellipsis" #commentText>{{ this.editedInvoice.getComment() }}</p>
                <fa-icon class="icon" [icon]="faPen" *ngIf="!readOnly" (click)="openCommentPopup()"></fa-icon>

                <ui-tooltip maxWidth="300px" [element]="commentText">
                  <span style="font-weight: normal !important; white-space: pre-wrap !important">
                    {{ this.editedInvoice.getComment() }}
                  </span>
                </ui-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="invoice-header-form" *ngIf="readOnly">
        <div class="grid-item large">
          <div class="multi-col">
            <p class="title">{{ "invoice-form-header.fields.date" | translate | uppercase }}</p>
            <p>
              {{
                this.editedInvoice.date
                  ? (this.editedInvoice.date | dateTimeFormat: false)
                  : ("invoice-form-header.fields.undefined" | translate)
              }}
            </p>
          </div>

          <div *ngIf="showComment" class="multi-col">
            <p class="title">{{ "invoice-form-header.fields.comment" | translate | uppercase }}</p>
            <ng-container *ngIf="!this.editedInvoice.getComment()">
              <p>{{ "invoice-form-header.fields.undefined" | translate }}</p>
            </ng-container>
            <ng-container *ngIf="this.editedInvoice.getComment()">
              <p class="ellipsis" #commentText>{{ this.editedInvoice.getComment() }}</p>
              <ui-tooltip maxWidth="300px" [element]="commentText">
                {{ this.editedInvoice.getComment() }}
              </ui-tooltip>
            </ng-container>
          </div>
        </div>

        <div class="grid-item large">
          <div class="multi-col">
            <p class="title">{{ "invoice-form-header.fields.quick-payment-date" | translate | uppercase }}</p>
            <p>
              {{
                this.editedInvoice.quickPaymentDate
                  ? (this.editedInvoice.quickPaymentDate | dateTimeFormat: false)
                  : ("invoice-form-header.fields.undefined" | translate)
              }}
            </p>
          </div>
        </div>

        <div class="grid-item large">
          <div class="multi-col">
            <p class="title">{{ "invoice-form-header.fields.max-payment-date" | translate | uppercase }}</p>
            <p>
              {{
                this.editedInvoice.maxPaymentDate
                  ? (this.editedInvoice.maxPaymentDate | dateTimeFormat: false)
                  : ("invoice-form-header.fields.undefined" | translate)
              }}
            </p>
          </div>
        </div>
      </div>
    </ui-container>
  </div>

  <div class="popup-container">
    <ui-popup title="{{ 'invoice-form-header.popup.title' | translate }}" *ngIf="popupVisible" (close)="closePopup()">
      <div content>
        <form [formGroup]="popupForm" class="invoice-header-form">
          <ui-textarea
            label="{{ 'invoice-form-header.fields.comment' | translate }}"
            formControlName="comment"
            maxLength="1024"
          ></ui-textarea>
        </form>
      </div>

      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <ui-button
              text="{{ 'global.validate' | translate }}"
              (click)="saveComment()"
              [primary]="true"
              type="save"
            ></ui-button>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
