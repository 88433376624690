import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BatchShip, ShipmentForm, ShipmentFormInfo, ShipmentFormService } from "center-services";
import { Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";

@Component({
  selector: "app-shipment-form-info-popup",
  templateUrl: "./shipment-form-info-popup.component.html",
  styleUrls: ["./shipment-form-info-popup.component.scss"],
  providers: [SubscriptionService],
})
export class ShipmentFormInfoPopupComponent implements OnInit {
  @Input() shipmentFormList: ShipmentForm[];

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();

  public popupForm: UntypedFormGroup;

  public shouldClose: boolean = false;

  public statusOptions: Option[] = [];

  public initialInfos: ShipmentFormInfo;
  public unsavedInfos: ShipmentFormInfo;
  public editedInfos: ShipmentFormInfo;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService,
    private shipmentFormService: ShipmentFormService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.popupForm = this.fb.group({
      forwardingReference: [null],
      packageReference: [null],
      packagingComment: [null],
    });
    this.initialInfos = new ShipmentFormInfo(this.popupForm.value);
    this.editedInfos = new ShipmentFormInfo(this.popupForm.value);
    this.unsavedInfos = new ShipmentFormInfo(this.popupForm.value);
  }

  closeInfosPopup(): void {
    this.editedInfos = new ShipmentFormInfo(this.popupForm.value);
    if (this.unsavedInfos) {
      const hasChangedForUnsaved = this.editedInfos.equals(this.unsavedInfos) ? false : true;
      if (hasChangedForUnsaved) {
        this.shouldClose = false;
      }
    }

    const hasChangedForInitial = this.editedInfos.equals(this.initialInfos) ? false : true;

    if (hasChangedForInitial && !this.shouldClose) {
      this.unsavedInfos = new ShipmentFormInfo({});
      this.shouldClose = true;

      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
      this.unsavedInfos = this.editedInfos;
    } else {
      this.close.emit();
      this.unsavedInfos = new ShipmentFormInfo({});
      this.shouldClose = false;
    }
  }

  submitInfosPopup(): void {
    this.editedInfos = new ShipmentFormInfo(this.popupForm.value);

    if (this.editedInfos.equals(this.initialInfos)) {
      this.close.emit();
      return;
    }

    const batchShip = new BatchShip({ shipmentFormIds: [] });
    batchShip.shipmentFormIds = this.shipmentFormList.map(shipmentForm => shipmentForm.id);
    batchShip.forwardingReference = this.editedInfos.forwardingReference;
    batchShip.packageReference = this.editedInfos.packageReference;
    batchShip.packagingComment = this.editedInfos.packagingComment;

    this.subscriptionService.subs.push(
      this.shipmentFormService.batchUpdateShipInfos(batchShip).subscribe(
        () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          this.validate.emit();
        },
        () => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("shipment-form-list.add-shipment-infos-popin.error-update");
          this.messageService.error(content, { title });
        }
      )
    );
  }
}
