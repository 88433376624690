<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'retail-item-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <ui-button-group
        [buttonsList]="buttons"
        dropdownLabel="{{ 'global.other' | translate }}"
        (buttonCalled)="buttonClick($event)"
      >
      </ui-button-group>
    </div>
    <div class="retail-item-list">
      <app-item-list
        (itemClick)="updateRetailItem($event)"
        [activeFilters]="this.activeFilters"
        (activeFiltersChange)="updateExportButton($event)"
        [selection]="false"
        [sorts]="sorts"
        [listId]="'app-item-list.item-table'"
      ></app-item-list>
    </div>
  </div>
</ng-container>
