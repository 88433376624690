import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BaseListPopupComponent, ErrorUtil } from "generic-pages";
import { IconDefinition, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { MessageService, ColorPickerComponent } from "fugu-components";
import {
  StoreBrand,
  AbstractFile,
  StoreBrandService,
  CaraUserService,
  DocumentService,
  Document,
} from "center-services";
import { HttpErrorResponse } from "@angular/common/http";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "app-store-brand",
  templateUrl: "./store-brands.list.component.html",
  styleUrls: ["./store-brands.list.component.scss"],
  providers: [SubscriptionService],
})
export class StoreBrandComponent extends BaseListPopupComponent<StoreBrand> implements OnInit {
  faPlus: IconDefinition = faPlus;

  public unsavedFile: any;
  public selectedFile: any;
  public previousFile: any;

  // eslint-disable-next-line no-magic-numbers
  protected readonly PAYLOAD_TOO_LARGE: number = 413;

  private parentDirectory: AbstractFile;

  constructor(
    messageService: MessageService,
    storeBrandService: StoreBrandService,
    translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private userService: CaraUserService,
    private documentService: DocumentService,
    private subscriptionService: SubscriptionService
  ) {
    super(storeBrandService, translateService, messageService);
  }

  ngOnInit(): void {
    this.sorts = [
      {
        prop: "activated",
        dir: "desc",
      },
      {
        prop: "name",
        dir: "asc",
      },
    ];
    this.preparePopupForm();
    this.fetchDirectory();
    super.ngOnInit();
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  onNewEntityClick(): void {
    this.selectedEntity = new StoreBrand({
      name: null,
      archived: false,
      documentId: null,
      color: ColorPickerComponent.DEFAULT_COLOR,
    });
    this.popupTitle = "store-brands-list.popup.title-new-store-brand";
    this.initializePopup();
  }

  onTableActivate(event: any): any {
    if (event.type === "click") {
      if (!this.userService.canDo("STORE_BRAND_UPDATE")) {
        return;
      }
      const filteredList = this.entityList.filter(storeBrand => storeBrand.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`Can't find store brand with ID: ${event.row.id}`);
        return;
      }
      this.selectedEntity = new StoreBrand(filteredList[0]);
      this.popupTitle = "store-brands-list.popup.title-update-store-brand";
      if (this.selectedEntity.documentId && this.selectedEntity.id) {
        this.getLogo();
      } else {
        this.selectedFile = null;
        this.previousFile = null;
      }
      this.initializePopup();
    }
  }

  initializePopup(): void {
    super.initializePopup();
    this.popupForm.controls.color.setValue(this.selectedEntity.color);
    this.popupForm.controls.name.setValue(this.selectedEntity.name);
    // reset value
    this.selectedFile = null;
    this.previousFile = null;
  }

  // Load abstractFile to get parentDirectory of the database in the datatable
  fetchDirectory(): void {
    this.subscriptionService.subs.push(
      this.documentService.get("root/store_brand").subscribe((abstractFile: AbstractFile) => {
        this.parentDirectory = abstractFile;
      })
    );
  }

  selectFile(event: any): void {
    this.selectedFile = event[0];
  }

  // use for update case in submit method
  getLogo(): void {
    // display the current logo
    this.subscriptionService.subs.push(
      this.documentService.downloadFile(this.selectedEntity.documentId).subscribe(
        data => {
          if (data.byteLength === 0) {
            this.selectedFile = null;
            this.previousFile = null;
          } else {
            this.selectedFile = data;
            this.previousFile = data;
            this.popupForm.value.logoFile[0] = data;
          }
        },
        error => {
          console.error(error.message);
        }
      )
    );
  }

  applyModifications(): void {
    this.selectedEntity.name = this.popupForm.value.name;
    this.selectedEntity.color = this.popupForm.value.color;
  }

  // Update or create a store-brand when the popup is validated
  submitStoreBrand(): void {
    // stop here if form is invalid
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    this.applyModifications();

    // create a doc when a storebrand is created
    if (!this.selectedEntity.documentId) {
      const doc = new Document({ name: `logo_${Math.round(new Date().getTime())}` });

      doc.extension = this.selectedFile ? this.selectedFile.name.split(".").pop() : null;
      doc.parentId = this.parentDirectory.id;
      doc.system = false;

      this.subscriptionService.subs.push(
        this.documentService.create(doc).subscribe((createdDoc: Document) => {
          this.selectedEntity.documentId = createdDoc.id;
          this.submitEntity();
        })
      );
    } else {
      this.submitEntity();
    }
  }

  // Update or Create a metal when the popup is validated
  submitEntity(): void {
    const formData = new FormData();
    formData.set("file", this.selectedFile);

    const action = this.selectedEntity && this.selectedEntity.id ? "update" : "create";
    this.subscriptionService.subs.push(
      this.entityService[action].call(this.entityService, this.selectedEntity).subscribe(
        () => {
          if (this.selectedFile instanceof File) {
            this.uploadFile(formData, this.selectedEntity.documentId);
          } else if (!this.selectedFile) {
            this.uploadFile(formData, this.selectedEntity.documentId);
          } else {
            super.fetchEntities();
            super.closePopup();
          }
        },
        error => {
          this.handleApiError(error);
        }
      )
    );
  }

  uploadFile(formData: FormData, documentId: number): void {
    this.subscriptionService.subs.push(
      this.documentService.uploadFile(formData, documentId).subscribe(
        () => {
          super.fetchEntities();
          super.closePopup();
        },
        (error: HttpErrorResponse) => {
          const res =
            error.status === this.PAYLOAD_TOO_LARGE
              ? this.translateService.instant("global.form.error.payload-too-large")
              : this.translateService.instant("store-brands-list.errors.logo");
          const title = this.translateService.instant("message.title.api-errors");
          this.messageService.warn(res, { title });
        }
      )
    );
  }

  // Errors
  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "store-brands-list.datatable.columns.name",
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  protected addRow(storeBrand: StoreBrand): void {
    this.rows.push({
      id: storeBrand.id,
      name: storeBrand.name,
      color: storeBrand.color,
      activated: !storeBrand.archived,
      documentId: storeBrand.documentId,
    });
  }

  protected cloneEntity(storeBrand: StoreBrand): StoreBrand {
    return new StoreBrand(storeBrand);
  }

  protected canArchive(): boolean {
    return this.userService.canDo("STORE_BRAND_ARCHIVE");
  }

  protected preparePopupForm(): void {
    this.popupForm = this.fb.group({
      logoFile: [[]],
      name: [null, [Validators.required]],
      color: [null, [Validators.required]],
    });
    this.subscriptionService.subs.push(
      this.popupForm.controls.logoFile.valueChanges.subscribe(logoFile => {
        this.selectFile(logoFile);
      })
    );
  }

  protected getTranslationPrefix(): string {
    return "store-brands-list";
  }
}
