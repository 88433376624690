import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import {
  AlloyComposition,
  PurchaseModality,
  Alloy,
  Metal,
  Supplier,
  AlloyService,
  MetalService,
  SupplierService,
  PaginatedList,
  Pagination,
  ContactAlloy,
} from "center-services";
import { Option, SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-alloy-composition",
  templateUrl: "./alloy-composition.component.html",
  styleUrls: ["./alloy-composition.component.scss"],
  providers: [SubscriptionService],
})
export class AlloyCompositionComponent implements OnInit, AfterViewChecked {
  @ViewChild("table") table: DatatableComponent;
  @Input() alloyCompositions: AlloyComposition[];
  @Input() purchaseModalities: PurchaseModality[];
  @Output() alloyCompositionsChange: EventEmitter<AlloyComposition[]> = new EventEmitter<AlloyComposition[]>();

  public readonly lowDecimalDigit: string = `separator.${PrecisionUtil.LOW_DECIMAL}`;

  public menuActions: MenuAction[] = [];
  public tableControl: UntypedFormGroup;
  public alloysList: Alloy[] = [];
  public metalList: Metal[] = [];
  public selectedAlloy: Alloy;
  public alloyOptions: Option[] = [];
  public alloysListControl: UntypedFormControl;
  public rows: any[] = [];
  public supplierIds: number[];
  public supplierList: Supplier[] = [];

  faTrashAlt: IconDefinition = faTrashAlt;

  protected readonly MAX_PERCENT: number = 100;
  // eslint-disable-next-line no-magic-numbers
  protected readonly MIN_PERCENT: number = 0.01;

  constructor(
    private alloyService: AlloyService,
    private metalService: MetalService,
    private supplierService: SupplierService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private subscriptionService: SubscriptionService
  ) {}

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  ngOnInit(): void {
    this.refresh();
    this.addMenuActions();
    this.alloysListControl = new UntypedFormControl(null);
  }

  refresh(): void {
    if (!this.tableControl) {
      this.tableControl = new UntypedFormGroup({});
    }
    this.createSupplierIdsList();
    this.subscriptionService.subs.push(
      combineLatest([this.fetchAlloyList(), this.fetchMetalList(), this.fetchSupplierList()]).subscribe(() => {
        // set alloys options
        this.alloyOptions = this.alloysList
          .filter(alloy => !alloy.archived)
          .sort((a, b) => a.techName.localeCompare(b.techName))
          .map((obj: Alloy) => new Option(obj.id, obj.techName));

        if (this.alloyCompositions.length > 0) {
          // add alloys to datatable
          this.alloyCompositions.forEach((alloyComposition: AlloyComposition) => {
            this.addRow(alloyComposition);

            // remove option from the list
            this.alloyOptions.forEach((option: Option) => {
              if (alloyComposition.alloyId === option.id) {
                this.alloyOptions.splice(this.alloyOptions.indexOf(option), 1);
              }
            });
          });
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  createSupplierIdsList(): void {
    this.supplierIds = [];
    this.purchaseModalities.forEach(modality => {
      if (modality.supplierId && !this.supplierIds.find(element => element === modality.supplierId)) {
        this.supplierIds.push(modality.supplierId);
      }
    });
  }

  fetchMetalList(): Observable<Metal[]> {
    return this.metalService.getAll().pipe(
      tap(
        (metals: Metal[]) => {
          this.metalList = metals;
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("metals-list.errors.get-metals", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchSupplierList(): Observable<PaginatedList<Supplier>> {
    const pager = new Pagination({
      size: this.supplierIds.length,
      number: 0,
    });
    const filters = new SearchFilter("id", SearchFilterOperator.IN, this.supplierIds);
    return this.supplierService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<Supplier>) => {
          this.supplierList = result.data;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("suppliers-list.errors.get-suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchAlloyList(): Observable<Alloy[]> {
    return this.alloyService.getAll().pipe(
      tap(
        (alloys: Alloy[]) => {
          this.rows = [];

          // get all unarchived alloys
          this.alloysList = alloys;
          this.alloysList
            .filter(alloy => !alloy.archived)
            .forEach((alloy: Alloy) => {
              alloy.composition.sort((a, b) => b.rate - a.rate);
            });
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("alloy-composition-list.list.errors.get-alloys", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  addRow(alloyComposition: AlloyComposition): void {
    const alloyFound = this.alloysList.find((alloy: Alloy) => alloy.id === alloyComposition.alloyId);
    const row = {
      techName: alloyFound.techName,
      id: alloyComposition.id,
      alloyId: alloyComposition.alloyId,
      settingsLossRate: alloyFound.archived ? [] : this.getLossRates(alloyFound),
      suppliersLossRate: this.getSuppliersLossRates(alloyFound),
      lossRate: alloyComposition.lossRate,
      composition: alloyComposition.rate,
    };
    this.rows.push(row);
    this.rows = [...this.rows];

    let rowForm = new UntypedFormGroup({});
    rowForm = this.fb.group({
      lossRate: [row.lossRate, [Validators.min(0), Validators.max(this.MAX_PERCENT)]],
      rate: [
        row.composition,
        [Validators.required, Validators.min(this.MIN_PERCENT), Validators.max(this.MAX_PERCENT)],
      ],
    });
    this.tableControl.addControl(alloyComposition.alloyId.toString(), rowForm);
  }

  getLossRates(alloy: Alloy | ContactAlloy): string[] {
    const strList = [];
    if (alloy.lostRate) {
      strList.push(`${alloy.lostRate} %`);
      return strList;
    }
    alloy.composition.forEach(compo => {
      const chosenMetal = this.metalList.find((metal: Metal) => metal.id === compo.metalId);
      strList.push(
        ` ${chosenMetal.name[0].toUpperCase() + chosenMetal.name.substr(1).toLowerCase()} ${compo.lostRate} %`
      );
    });
    return strList;
  }

  getSuppliersLossRates(alloy: Alloy): string[] {
    const strList = [];
    this.supplierList
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((supplier: Supplier) => {
        if (supplier.alloys.find(contactAlloy => contactAlloy.alloyId === alloy.id)) {
          strList.push(
            `${supplier.name}: ${this.getLossRates(
              supplier.alloys.find(contactAlloy => contactAlloy.alloyId === alloy.id)
            )}`
          );
        }
      });
    return strList;
  }

  addMenuActions(): void {
    this.menuActions = [];

    this.menuActions.push(
      new MenuAction(0, this.translateService.instant("alloy-composition-list.actions.remove"), faTrashAlt)
    );
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case 0:
        this.removeAlloy(row);
        break;
      default:
        break;
    }
  }

  addAlloy(): void {
    if (this.alloysListControl.value) {
      const alloyFound = this.alloysList.find((alloy: Alloy) => alloy.id === this.alloysListControl.value);
      this.addAlloyToTable(alloyFound);
    }
  }

  addAlloyToTable(alloyToAdd: Alloy): void {
    const alloyComposition = new AlloyComposition({
      alloyId: alloyToAdd.id,
      lossRate: null,
      rate: 1,
    });

    this.alloyCompositions.push(alloyComposition);
    this.addRow(alloyComposition);

    // remove alloy from option's list
    this.alloyOptions.forEach((option: Option) => {
      if (alloyToAdd.id === option.id) {
        this.alloyOptions.splice(this.alloyOptions.indexOf(option), 1);
      }
    });

    this.alloysListControl.setValue(null);

    this.alloyCompositionsChange.emit(this.alloyCompositions);
  }

  removeAlloy(selectedRow: any): void {
    // remove row from datatable & remove alloy from alloyCompositions
    this.rows.forEach(row => {
      if (selectedRow.techName === row.techName) {
        this.rows.splice(this.rows.indexOf(row), 1);
      }
    });
    this.rows = [...this.rows];

    this.alloyCompositions.forEach((cA: AlloyComposition) => {
      const cAlloyR = this.alloysList.find((alloy: Alloy) => alloy.id === cA.alloyId);
      if (cAlloyR.techName === selectedRow.techName) {
        this.alloyCompositions.splice(this.alloyCompositions.indexOf(cA), 1);
      }
    });

    // add alloy to option's list
    const alloyFound = this.alloysList
      .filter(alloy => !alloy.archived)
      .find((alloy: Alloy) => alloy.techName === selectedRow.techName);
    if (alloyFound) {
      const alloyOptionToAdd = new Option(alloyFound.id, selectedRow.techName);
      this.alloyOptions.push(alloyOptionToAdd);
      this.alloyOptions.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }

    // remove form group
    this.tableControl.removeControl(selectedRow.alloyId.toString());
    this.alloyCompositionsChange.emit(this.alloyCompositions);
  }

  saveInputValue(controlName: string): void {
    const alloyId = Number(controlName.split(".")[0]);
    const attribute = controlName.split(".")[1];
    const inputValue = this.tableControl.get(controlName).value;
    const alloyIdx = this.alloyCompositions.findIndex(compo => compo.alloyId === alloyId);

    if (alloyIdx >= 0 && inputValue !== null) {
      this.alloyCompositions[alloyIdx][attribute] = inputValue === "" ? null : parseFloat(inputValue);
      this.alloyCompositionsChange.emit(this.alloyCompositions);
    }
  }
}
