import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IconDefinition, faCheck, faChevronLeft, faRandom, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import {
  UserLink,
  Light,
  UserStoreLinkRow,
  Profile,
  Job,
  CaraUser,
  Store,
  JobService,
  LightService,
  CaraUserService,
  ProfileService,
  StoreService,
  DateFormatUtils,
  JobType,
  StoreLink,
} from "center-services";
import { CommonValidatorsUtil, DayjsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { CannotContainSpaceUtil, ComponentDirty, ErrorUtil, PasswordUtil } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.scss"],
  providers: [SubscriptionService],
})
export class UserFormComponent implements OnInit, ComponentDirty, AfterViewChecked {
  @ViewChild("table") table: DatatableComponent;
  public symbols: string = PasswordUtil.symbols.join(", ");
  public countryFlags: Map<string, string> = Object.create({ fr: "fr", en: "gb" });
  faRandom: IconDefinition = faRandom;
  faTimes: IconDefinition = faTimes;
  faCheck: IconDefinition = faCheck;
  public userStoreLinks: Map<number, UserLink> = new Map();
  public faChevronLeft: IconDefinition = faChevronLeft;
  public userStores: Light[] = [];
  public storeErrors: any[] = [];
  public password: HTMLElement;
  public rows: UserStoreLinkRow[] = [];
  public pattern: any = {
    P: {
      pattern: new RegExp("[+]|[0-9]"),
      optional: true,
    },
    N: {
      pattern: new RegExp("\\d"),
    },
    Q: {
      pattern: new RegExp("[^~]+"),
    },
  };
  public loginConcatSwitch: boolean = false;
  public mandatoryPasswordFields: any[] = [];
  public safeAbilityOptions: Option[] = [];
  public profileOptions: Option[] = [];
  public isAdmin: boolean = false;
  public dateFormatOptions: Option[] = [];
  public codeLangOptions: Option[];
  public jobOptions: Option[] = [];
  public profiles: Profile[] = [];
  public passwordConstraints: any;
  public jobs: Job[] = [];
  public profileListStatus: string;
  public title: string;
  public tableControl: UntypedFormGroup;
  public form: UntypedFormGroup;
  public dateFormat: string;
  public locale: string;
  public infoLabel: string = "";
  public pinInfoLabel: string = "";
  public shouldClose: boolean = false;
  public initialUser: CaraUser;
  public unsavedUser: CaraUser;
  public user: CaraUser;
  public userId: number;
  public mainStore: Store;
  public pageNumber: number = 0;
  public sorts: any[] = [
    {
      prop: "byDefault",
      dir: "desc",
    },
    {
      prop: "activated",
      dir: "desc",
    },
    {
      prop: "name",
      dir: "asc",
    },
  ];
  public popupVisible: boolean = false;
  private dateFormats: string[];
  private initObservables: Observable<any>[] = [];

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private jobService: JobService,
    private lightService: LightService,
    private userService: CaraUserService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private storeService: StoreService,
    private subscriptionService: SubscriptionService
  ) {}

  @ViewChild("passwordField", { read: ElementRef }) set setPasswordField(elem: ElementRef) {
    this.password = elem ? elem.nativeElement.getElementsByTagName("input")[0] : undefined;
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.initObservables = [];
    this.passwordConstraints = PasswordUtil.getValidators(null, null);
    this.codeLangOptions = [new Option(0, "fr"), new Option(1, "en")];
    this.userId = Number(this.route.snapshot.params.id);
    this.title = this.userId ? "update" : "new";

    if (!this.tableControl) {
      this.tableControl = new UntypedFormGroup({});
    }
    this.prepareForm();

    this.initObservables.push(this.fetchJobs());
    this.initObservables.push(this.fetchProfiles());
    this.initObservables.push(this.fetchMainStore());

    this.buildSafeAbilityOptions();

    if (this.userService.connectedUser.value) {
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
      this.locale = this.userService.connectedUser.value.codeLanguage;
    } else {
      this.subscriptionService.subs.push(
        this.userService.connectedUser.subscribe(user => {
          this.dateFormat = user.dateFormat;
          this.locale = user.codeLanguage;
        })
      );
    }

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (!isNaN(this.userId)) {
          this.subscriptionService.subs.push(
            this.userService.get(this.userId).subscribe(
              (caraUser: CaraUser) => {
                this.user = new CaraUser(caraUser);
                this.setUserData();
              },
              () => this.router.navigateByUrl("/settings/users-list")
            )
          );
          this.infoLabel = this.translateService.instant("user-form.infos.change-password");
          this.pinInfoLabel = this.translateService.instant("user-form.infos.change-pin");
        } else {
          const langId = this.codeLangOptions.findIndex((option: Option) => {
            return option.label === this.userService.connectedUser.value.codeLanguage;
          });
          this.form.controls.codeLanguage.setValue(langId ? langId : 0);
          this.form.controls.dateFormat.setValue(0);
          this.loginConcatSwitch = true;

          this.initUser();
          this.setUserData();

          this.pinInfoLabel = this.translateService.instant("user-form.infos.create-pin");

          this.generatePinCode(true);
          this.generatePassword(true);
          this.generateUserNumber();
        }
      })
    );

    // rebuild the options when the translation change
    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.fillDateFormatOptionList();
        this.buildSafeAbilityOptions();
      })
    );
    this.fillDateFormatOptionList();
  }

  isDirty(): boolean {
    if (!this.user || this.user.id === 1) {
      return false;
    }

    this.applyModifications();

    if (this.unsavedUser && !this.unsavedUser.equals(this.user)) {
      this.shouldClose = false;
    }

    if (!this.initialUser.equals(this.user) && !this.shouldClose) {
      this.unsavedUser = new CaraUser(this.user);
      this.shouldClose = true;
      return true;
    } else {
      this.shouldClose = false;
      this.unsavedUser = null;
      return false;
    }
  }

  fillDateFormatOptionList(): void {
    this.dateFormats = [
      this.translateService.instant("user-form.datatable.date-format.option-1"),
      this.translateService.instant("user-form.datatable.date-format.option-2"),
    ];
    this.dateFormatOptions = this.dateFormats.map((format, index) => new Option(index, format));
  }

  prepareForm(): void {
    const passwordLength = 8;
    const pinCodeLength = 4;

    this.form = this.fb.group({
      jobs: [null],
      phone: [null],
      mobilePhone: [null],
      weeklyWorkTime: [null],
      endContractDate: [null],
      confirmPassword: [null],
      email: [null, [Validators.email]],
      login: [null, [Validators.required]],
      profile: [null, [Validators.required]],
      hireDate: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      firstName: [null, [Validators.required, CannotContainSpaceUtil.cannotContainSpace]],
      userNumber: [null, [Validators.required]],
      dateFormat: [null, [Validators.required]],
      safeAbility: [false, [Validators.required]],
      codeLanguage: [null, [Validators.required]],
      tempPassword: [null, [Validators.minLength(passwordLength)]],
      pinCode: [
        null,
        [Validators.minLength(pinCodeLength), Validators.maxLength(pinCodeLength), Validators.pattern("^[0-9]*$")],
      ],
    });
    this.form.setValidators([
      PasswordUtil.check("tempPassword", "confirmPassword"),
      CommonValidatorsUtil.dateValidator(this.form.controls.hireDate, this.form.controls.endContractDate),
    ]);
    this.subscriptionService.subs.push(
      this.form.controls.tempPassword.valueChanges.subscribe(value => {
        this.passwordConstraints = PasswordUtil.getValidators(value, passwordLength);
      })
    );
    this.subscriptionService.subs.push(
      this.form.controls.profile.valueChanges.subscribe(value => {
        this.manageMainStoreSwitch(value);
      })
    );

    if (!this.userId) {
      this.mandatoryPasswordFields = ["pinCode", "tempPassword", "confirmPassword"];

      this.mandatoryPasswordFields.forEach(element => {
        this.form.controls[element].validator
          ? this.form.controls[element].setValidators([Validators.required, this.form.controls[element].validator])
          : this.form.controls[element].setValidators([Validators.required]);
      });
      this.form.controls.tempPassword.setValidators([Validators.minLength(passwordLength), Validators.required]);
    }
  }

  getValidatorClass(value: boolean): string {
    return value ? "valid" : "invalid";
  }

  initUser(): void {
    this.user = new CaraUser({
      jobs: [],
      stores: [],
      login: null,
      email: null,
      phone: null,
      pinCode: null,
      comment: null,
      password: null,
      lastName: null,
      hireDate: null,
      titleType: null,
      firstName: null,
      userNumber: null,
      profileId: null,
      archived: false,
      dateFormat: null,
      profileName: null,
      mobilePhone: null,
      safeAbility: false,
      codeLanguage: null,
      weeklyWorkTime: null,
      endContractDate: null,
    });
  }

  // the arrow function bellow is used to return the rows class
  getRowClass(): any {
    return { "not-clickable": true };
  }

  setUserData(): void {
    if (!this.user) {
      return;
    }

    if (this.user.id === 1) {
      this.router.navigateByUrl("/settings/users-list");
      return;
    }
    // initialize default user
    this.initialUser = this.cloneEntity(this.user);
    if (!this.initialUser.dateFormat) {
      this.initialUser.dateFormat = DateFormatUtils.getDateFormat(this.form.controls.dateFormat.value);
    }
    if (!this.initialUser.codeLanguage) {
      this.initialUser.codeLanguage = this.codeLangOptions.find(option => option.id === 0).label;
    }

    this.form.controls.login.setValue(this.user.login);
    this.form.controls.email.setValue(this.user.email);
    this.form.controls.phone.setValue(this.user.phone);
    this.form.controls.lastName.setValue(this.user.lastName);
    this.form.controls.firstName.setValue(this.user.firstName);
    this.form.controls.userNumber.setValue(this.user.userNumber);
    this.form.controls.mobilePhone.setValue(this.user.mobilePhone);
    this.form.controls.weeklyWorkTime.setValue(this.user.weeklyWorkTime);
    this.form.controls.safeAbility.setValue(this.user.safeAbility ? 0 : 1);
    this.form.controls.hireDate.setValue(DayjsUtil.dayjsOrNull(this.user.hireDate, true));
    this.form.controls.endContractDate.setValue(DayjsUtil.dayjsOrNull(this.user.endContractDate, true));

    if (this.user.profileName && this.profiles) {
      this.setDefaultProfile();
    }

    if (this.user.jobIds && this.jobs) {
      this.form.controls.jobs.setValue(
        this.user.jobIds.filter(jobId => !this.jobs.find(job => job.id === jobId).archived)
      );
    }

    if (this.user.dateFormat) {
      this.setDefaultDateFormat();
    }

    if (this.user.codeLanguage) {
      this.setDefaultLanguage();
    }

    this.managePasswordAndEmailFields();
    this.subscriptionService.subs.push(
      this.fetchStores().subscribe(() => {
        this.getStoresList();
        this.manageMainStoreSwitch(this.user.profileId);
      })
    );
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "activated":
        return "link.user.id";
      default:
        return prop;
    }
  }

  fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (stores: Light[]) => {
          this.userStores = stores.filter((store: Light) => !store.archived);
        },
        (error: any) => {
          const message = this.translateService.instant("user-form.errors.get-stores", { message: error.message });
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(message, { title });
        }
      )
    );
  }

  getStoresList(): void {
    this.rows = [];
    this.userStores.forEach((store: Store) => {
      const hasStoreLink = this.user.stores.find(storeLink => storeLink.storeId === store.id);
      let userStoreLink = this.userStoreLinks.get(store.id);
      if (userStoreLink === undefined) {
        userStoreLink = new UserLink({
          byDefault: hasStoreLink ? hasStoreLink.byDefault : false,
          archived: hasStoreLink ? hasStoreLink.archived : true,
          userId: this.userId,
        });
        this.userStoreLinks.set(store.id, userStoreLink);
      }
      const row: UserStoreLinkRow = {
        byDefault: userStoreLink.byDefault,
        activated: !userStoreLink.archived,
        name: store.name,
        id: store.id,
      };
      this.rows.push(row);
      this.rows = [...this.rows];

      if (this.tableControl.contains(`activated_${store.id}`)) {
        this.tableControl.controls[`activated_${store.id}`].enable();
        this.tableControl.controls[`activated_${store.id}`].value
          ? this.tableControl.controls[`byDefault_${store.id}`].enable()
          : this.tableControl.controls[`byDefault_${store.id}`].disable();

        this.tableControl.controls[`activated_${store.id}`].patchValue(!userStoreLink.archived);
        this.tableControl.controls[`byDefault_${store.id}`].patchValue(userStoreLink.byDefault);
      } else {
        this.tableControl.addControl(`activated_${store.id}`, new UntypedFormControl(!userStoreLink.archived));
        this.tableControl.addControl(
          `byDefault_${store.id}`,
          new UntypedFormControl({
            value: userStoreLink.byDefault,
            disabled: userStoreLink.archived,
          })
        );
      }
    });
  }

  fetchJobs(): Observable<Job[]> {
    return this.jobService.getAllByType(JobType.EMPLOYEE).pipe(
      tap(
        (jobs: Job[]) => {
          this.jobs = jobs;
          this.jobOptions = jobs
            .filter((obj: Job) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Job) => new Option(obj.id, obj.name));
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("user-form.errors.get-jobs", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchProfiles(): Observable<Profile[]> {
    return this.profileService.getAll().pipe(
      tap(
        (profiles: Profile[]) => {
          this.profiles = profiles;
          this.profileOptions = profiles
            .filter((obj: Profile) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Profile) => new Option(obj.id, obj.name));
          if (this.user) {
            this.setDefaultProfile();
          }
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("user-form.errors.get-profiles", { message: error.message });
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-main-store", error.message);
        }
      )
    );
  }

  setDefaultProfile(): void {
    if (this.user.profileName) {
      const userProfileOpt = this.profileOptions.find((profileOpt: Option) => {
        return profileOpt.label === this.user.profileName;
      });
      if (userProfileOpt) {
        this.form.controls.profile.setValue(userProfileOpt.id);
        this.initialUser.profileId = userProfileOpt.id as number;
      }
    }
  }

  setDefaultLanguage(): void {
    if (this.user.codeLanguage) {
      this.form.controls.codeLanguage.setValue(
        this.codeLangOptions.findIndex((option: Option) => {
          return option.label === this.user.codeLanguage;
        })
      );
    }
  }

  setDefaultDateFormat(): void {
    if (this.dateFormat !== undefined && this.form !== undefined) {
      this.form.controls.dateFormat.setValue(DateFormatUtils.getIndex(this.initialUser.dateFormat));
    }
  }

  buildSafeAbilityOptions(): void {
    this.safeAbilityOptions[0] = new Option(
      0,
      this.translateService.instant("user-form.fields.safe-ability-options.YES")
    );
    this.safeAbilityOptions[1] = new Option(
      1,
      this.translateService.instant("user-form.fields.safe-ability-options.NO")
    );
  }

  generatePassword(initialLoading: boolean): void {
    const tempPassword = PasswordUtil.generate();

    if (initialLoading) {
      this.initialUser.password = tempPassword;
    }

    this.form.controls.tempPassword.setValue(tempPassword);
    this.form.controls.confirmPassword.setValue(tempPassword);
  }

  generatePinCode(initialLoading: boolean): void {
    const first = 1000;
    const second = 9000;

    const pinCode = Math.floor(first + Math.random() * second);
    if (initialLoading) {
      this.initialUser.pinCode = pinCode.toString();
    }

    this.form.controls.pinCode.setValue(pinCode);
  }

  generateUserNumber(): void {
    this.subscriptionService.subs.push(
      this.userService.getUserNumber().subscribe((userNumber: string) => {
        this.initialUser.userNumber = userNumber;
        this.form.controls.userNumber.setValue(userNumber);
      })
    );
  }

  concatLogin(): void {
    if (
      this.loginConcatSwitch &&
      this.form.controls.firstName.value !== null &&
      this.form.controls.lastName.value !== null
    ) {
      const loginConcat = `${this.form.controls.firstName.value}.${this.form.controls.lastName.value}`;
      this.form.controls.login.setValue(loginConcat.replace(/\s+|-+/g, "").toLowerCase());
    }
  }

  addUserToStore(index: number): void {
    const employee = this.userStoreLinks.get(index);

    // Update employee and rows with new value
    employee.archived = !this.tableControl.controls[`activated_${index}`].value;
    this.getStoresList();
  }

  switchOffLoginAutoComplete(): void {
    this.loginConcatSwitch = false;
  }

  managePasswordAndEmailFields(): void {
    const profileId = this.form.controls.profile.value;
    if (profileId) {
      this.isAdmin = this.profiles.find(profile => profile.id === profileId).mainStoreAccess;

      // manage the email validator and add "required" property
      if (this.isAdmin) {
        this.form.controls.email.setValidators([Validators.required, Validators.email]);
        this.form.controls.email.updateValueAndValidity();
      } else {
        // reset the email validator with just "email" property
        this.form.controls.email.clearValidators();
        this.form.controls.email.setValidators([Validators.email]);
        this.form.controls.email.updateValueAndValidity();
      }
    }
  }

  shouldCheckPassword(): void {
    if (!this.form.controls.tempPassword.value && !this.form.controls.confirmPassword.value) {
      this.form.controls.confirmPassword.updateValueAndValidity();
      this.form.controls.tempPassword.updateValueAndValidity();
    }
  }

  applyModifications(): void {
    this.updateStoreLinks();

    this.user.login = this.form.value.login;
    this.user.email = this.form.value.email;
    this.user.phone = this.form.value.phone;
    this.user.profileId = this.form.value.profile;
    this.user.lastName = this.form.value.lastName;
    this.user.firstName = this.form.value.firstName;
    this.user.dateFormat = this.form.value.dateFormat;
    this.user.userNumber = this.form.value.userNumber;
    this.user.mobilePhone = this.form.value.mobilePhone;
    this.user.codeLanguage = this.form.value.codeLanguage;
    this.user.weeklyWorkTime = this.form.value.weeklyWorkTime;
    this.user.safeAbility = this.form.value.safeAbility === 0;

    if (this.form.value.pinCode) {
      this.user.pinCode = this.form.value.pinCode.toString();
    }
    this.user.hireDate = this.form.value.hireDate ? this.form.value.hireDate.toDate() : null;
    this.user.endContractDate = this.form.value.endContractDate ? this.form.value.endContractDate.toDate() : null;

    this.user.jobIds = null;
    if (this.form.value.jobs) {
      this.user.jobIds = this.form.value.jobs;
    }

    if (this.form.value.tempPassword) {
      this.user.password = this.form.value.tempPassword;
    } else {
      this.user.password = "";
    }

    this.user.dateFormat = DateFormatUtils.getDateFormat(this.form.value.dateFormat);
    if (this.codeLangOptions[this.form.value.codeLanguage]) {
      this.user.codeLanguage = this.codeLangOptions[this.form.value.codeLanguage].label;
    }
  }

  submitUser(): void {
    if (this.form.invalid || !this.isDirty()) {
      this.form.markAllAsTouched();
      return;
    }

    if ([...this.userStoreLinks.values()].every((userLink: UserLink) => userLink.archived)) {
      const title = this.translateService.instant("message.title.form-errors");
      const content = this.translateService.instant("user-form.errors.no-store");
      this.messageService.error(content, { title });
      return;
    }

    this.applyModifications();

    if (this.user.password) {
      this.user.password = PasswordUtil.encrypt(this.user.password);
    }

    const action = this.user && this.user.id ? "update" : "create";
    this.subscriptionService.subs.push(
      this.userService[action].call(this.userService, this.user).subscribe(
        responseUser => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("message.content.save-success");
          this.messageService.success(content, { title });
          if (!isNaN(this.userId)) {
            this.shouldClose = false;
          } else {
            this.shouldClose = true;
          }
          this.unsavedUser = null;
          this.initialUser = this.cloneEntity(this.user);
          this.initialUser.password = this.form.value.tempPassword;
          this.dateFormatOptions = [];
          this.fillDateFormatOptionList();
          this.router.navigateByUrl(`/settings/user/update/${responseUser.id}`);
        },
        error => {
          this.handleApiError(error);
        }
      )
    );
  }

  updateStoreLinks(): void {
    this.userStores.forEach((store: Store) => {
      const storeLinkIndex = this.user.stores.findIndex(storeLink => storeLink.storeId === store.id);
      const userStoreLink = this.userStoreLinks.get(store.id);

      if (storeLinkIndex > -1) {
        if (!this.popupVisible && !this.user.stores[storeLinkIndex].archived && userStoreLink.archived) {
          this.popupVisible = true;
          return;
        }
        this.user.stores[storeLinkIndex].byDefault = userStoreLink.byDefault;
        this.user.stores[storeLinkIndex].archived = userStoreLink.archived;
      } else if (!userStoreLink.archived) {
        this.user.stores.push(
          new StoreLink({
            byDefault: userStoreLink.byDefault,
            archived: userStoreLink.archived,
            storeName: store.name,
            storeId: store.id,
          })
        );
      }
    });
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      login: "user-form.fields.login",
      userNumber: "user-form.fields.user-number",
    };
    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  back(): void {
    this.router.navigateByUrl("/settings/users-list");
  }

  passwordMandatory(): boolean {
    return this.userId ? false : true;
  }

  changeDefaultStore(id: number): void {
    let oldByDefault: number = this.userStoreLinks.get(id).byDefault ? id : null;
    this.userStoreLinks.forEach((value: UserLink, key: number) => {
      if (key !== id && value.byDefault) {
        oldByDefault = key;
        value.byDefault = false;
      }
    });
    this.userStoreLinks.get(id).byDefault = !this.userStoreLinks.get(id).byDefault;
    this.tableControl.controls[`byDefault_${id}`].patchValue(this.userStoreLinks.get(id).byDefault);
    this.tableControl.controls[`activated_${id}`].disable();
    if (oldByDefault !== null || oldByDefault !== undefined) {
      this.tableControl.controls[`byDefault_${oldByDefault}`]?.patchValue(
        this.userStoreLinks.get(oldByDefault).byDefault
      );
      this.tableControl.controls[`activated_${oldByDefault}`]?.enable();
    }
    this.getStoresList();
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "byDefault") {
      this.sorts = [
        {
          prop: "byDefault",
          dir: direction,
        },
      ];
    } else if (prop === "activated") {
      this.sorts = [
        {
          prop: "activated",
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop: "byDefault",
          dir: "desc",
        },
        {
          prop: "activated",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  hasMainStoreAccess(profileId: number): boolean {
    return profileId ? this.profiles?.filter(profile => profile.id === profileId)[0].mainStoreAccess : false;
  }

  manageMainStoreSwitch(profileId: number): void {
    const mainStoreId = this.mainStore.id;
    if (this.tableControl.controls[`activated_${mainStoreId}`]) {
      const mainStoreAccess = this.hasMainStoreAccess(profileId);
      if (!mainStoreAccess) {
        if (this.userStoreLinks.get(mainStoreId).byDefault) {
          const title = this.translateService.instant("message.title.form-errors");
          const content = this.translateService.instant("user-form.errors.store-unselected");
          this.messageService.error(content, { title });
        }
        this.tableControl.get(`activated_${mainStoreId}`).patchValue(false);
        this.tableControl.get(`byDefault_${mainStoreId}`).patchValue(false);
        this.tableControl.controls[`activated_${mainStoreId}`].disable();
        this.tableControl.controls[`byDefault_${mainStoreId}`].disable();
        this.userStoreLinks.get(mainStoreId).byDefault = false;
        this.userStoreLinks.get(mainStoreId).archived = true;
        this.getStoresList();
      } else {
        this.tableControl.controls[`activated_${mainStoreId}`].enable();
      }
    }
  }

  validateWarningPopup(): void {
    this.submitUser();
    this.closeWarningPopup();
  }

  closeWarningPopup(): void {
    this.popupVisible = false;
  }

  protected cloneEntity(caraUser: CaraUser): CaraUser {
    return new CaraUser(caraUser);
  }
}
