/* eslint-disable no-magic-numbers */
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SearchFilter, SubscriptionService } from "fugu-common";
import { TranslateService } from "@ngx-translate/core";
import { faFileExport, faFileImport } from "@fortawesome/pro-solid-svg-icons";
import { ButtonItem, MessageService, ToastMessageService } from "fugu-components";
import { CaraUserService, AsynchronousTaskService, AsynchronousTaskCreation } from "center-services";
import { NotificationHandlerService } from "app/service/notification-handler.service";

@Component({
  selector: "app-retail-item-list",
  templateUrl: "./retail-item-list.component.html",
  styleUrls: ["./retail-item-list.component.scss"],
  providers: [SubscriptionService],
})
export class RetailItemListComponent implements OnInit {
  public static NEW_ITEM_BTN_ID: number = 0;
  public static NEW_SERVICE_BTN_ID: number = 1;
  public static EXPORT_BTN_ID: number = 2;
  public static IMPORT_BTN_ID: number = 3;
  @Input() canUpdate: boolean = true;
  @Input() canAdd: boolean = true;
  public sorts: any[] = [
    {
      prop: "status",
      dir: "asc",
    },
    {
      prop: "designation",
      dir: "asc",
    },
  ];
  public activeFilters: SearchFilter[] = [];
  public buttons: ButtonItem[];

  constructor(
    private router: Router,
    private userService: CaraUserService,
    private asynchronousTaskService: AsynchronousTaskService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private notificationHandlerService: NotificationHandlerService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    const buttons = [];

    if (this.userService.canDo("RETAIL_ITEM_CREATE")) {
      buttons.push(
        new ButtonItem({
          id: RetailItemListComponent.NEW_ITEM_BTN_ID,
          text: this.translateService.instant("retail-item-list.button.new-item"),
          primary: true,
          type: "add",
        }),
        new ButtonItem({
          id: RetailItemListComponent.NEW_SERVICE_BTN_ID,
          text: this.translateService.instant("retail-item-list.button.new-service"),
          type: "add",
        })
      );
    }
    if (this.userService.canDo("RETAIL_ITEM_READ")) {
      buttons.push(
        new ButtonItem({
          id: RetailItemListComponent.EXPORT_BTN_ID,
          text:
            this.activeFilters.length === 0
              ? this.translateService.instant("global.export.all")
              : this.translateService.instant("global.export.select"),
          btnIcon: faFileExport,
          customClass: "fugu-button-export",
          type: "export",
        })
      );
    }
    if (this.userService.canDo("RETAIL_ITEM_CREATE")) {
      buttons.push(
        new ButtonItem({
          id: RetailItemListComponent.IMPORT_BTN_ID,
          text: this.translateService.instant("global.import"),
          btnIcon: faFileImport,
          customClass: "fugu-button-import",
          type: "import",
        })
      );
    }
    this.buttons = [...buttons];
  }

  public createRetailItem(): void {
    if (!this.userService.canDo("RETAIL_ITEM_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/retail-item/add");
  }

  public createServiceItem(): void {
    if (!this.userService.canDo("RETAIL_ITEM_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/retail-service/add");
  }

  public exportRetailItems(): void {
    if (!this.userService.canDo("RETAIL_ITEM_READ")) {
      return;
    }

    const asyncCreationTask = {
      type: "exportXLS",
      params: `item;${this.activeFilters?.map(filter => filter.toQuery()).join(",")};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  importItems(): void {
    if (!this.userService.canDo("RETAIL_ITEM_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/retail-item/import");
  }

  public updateRetailItem(event: any): void {
    if (!this.userService.canDo("RETAIL_ITEM_UPDATE")) {
      return;
    }
    this.router.navigateByUrl(`/retail-item/update/${event.id}`);
  }

  public buttonClick(event: any): void {
    switch (event) {
      case RetailItemListComponent.NEW_ITEM_BTN_ID:
        this.createRetailItem();
        break;
      case RetailItemListComponent.NEW_SERVICE_BTN_ID:
        this.createServiceItem();
        break;
      case RetailItemListComponent.EXPORT_BTN_ID:
        this.exportRetailItems();
        break;
      case RetailItemListComponent.IMPORT_BTN_ID:
        this.importItems();
        break;
      default:
        console.error(`Can't handle button ID : ${event}.`);
        break;
    }
  }

  public updateExportButton(event: any): void {
    this.activeFilters = event;
    this.buttons[RetailItemListComponent.EXPORT_BTN_ID].text =
      this.activeFilters.length === 0
        ? this.translateService.instant("global.export.all")
        : this.translateService.instant("global.export.select");
    this.buttons = [...this.buttons];
  }
}
