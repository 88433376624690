<ng-container *ngIf="title" class="customer-form">
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ 'customer.buttons.back-button' | translate }}"
        (click)="backToCustomerList()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
    </div>
    <div class="title">
      <ui-title titleText="{{ title | translate }}" subTitleText="{{ subTitle ? subTitle : undefined }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="validate-button">
        <div class="button">
          <ui-button text="{{ 'global.validate' | translate }}" type="save" [primary]="true" (click)="submitCustomer()">
          </ui-button>
        </div>
      </div>
    </div>
    <div class="customer-body">
      <ui-tab-handler #tabHandler *ngIf="this.updatedCustomer" (tabClick)="onTabClick($event)" [independent]="false">
        <ui-tab title="{{ 'customer.tab-title.general-datas' | translate }}" id="general-datas" [active]="true">
          <ng-template #tabTemplate>
            <app-customer-professional-general
              #generalDatas
              *ngIf="!this.editedCustomer.isIndividual() && updatedCustomer"
              [(editedCustomer)]="this.updatedCustomer"
            >
            </app-customer-professional-general>
            <app-customer-individual-general
              #generalDatas
              *ngIf="this.editedCustomer.isIndividual() && updatedCustomer"
              [(editedCustomer)]="this.updatedCustomer"
            >
            </app-customer-individual-general>
          </ng-template>
        </ui-tab>
        <ui-tab
          *ngIf="!this.editedCustomer.isIndividual()"
          title="{{ 'supplier.delivery-addresses.tab-title' | translate }}"
          id="delivery-addresses"
        >
          <ng-template #tabTemplate>
            <gen-addresses-list
              *ngIf="this.updatedCustomer"
              [(addressesList)]="this.updatedCustomer.deliveryAddresses"
              (submitEvent)="catchEvent('deliveryAddresses', $event)"
            >
            </gen-addresses-list>
          </ng-template>
        </ui-tab>
        <ui-tab
          *ngIf="!this.editedCustomer.isIndividual()"
          title="{{ 'supplier.bank-datas.tab-title' | translate }}"
          id="bank-datas"
        >
          <ng-template #tabTemplate>
            <gen-banks-list
              *ngIf="this.updatedCustomer"
              [(bankList)]="this.updatedCustomer.banks"
              [showSepa]="false"
              [showAddress]="true"
              [showAccountNumber]="true"
              [mandatoryFields]="['name', 'bic', 'country']"
              (submitEvent)="catchEvent('banks', $event)"
            >
            </gen-banks-list>
          </ng-template>
        </ui-tab>
        <ui-tab
          *ngIf="!this.editedCustomer.isIndividual()"
          title="{{ 'supplier.phone-book.tab-title' | translate }}"
          id="phone-book"
        >
          <ng-template #tabTemplate>
            <gen-persons-list
              *ngIf="this.updatedCustomer"
              [jobType]="this.jobTypeExternalContact"
              [(personsList)]="this.updatedCustomer.persons"
              (submitEvent)="catchEvent('persons', $event)"
            ></gen-persons-list>
          </ng-template>
        </ui-tab>
        <ui-tab
          *ngIf="!this.editedCustomer.isIndividual()"
          title="{{ 'customer.tab-title.commercial-modality' | translate }}"
          id="commercial-modality"
        >
          <ng-template #tabTemplate>
            <app-commercial-modality
              #commercialModality
              *ngIf="updatedCustomer"
              [(commercialModality)]="updatedCustomer.commercialModality"
              [address]="updatedCustomer.address"
              [contacts]="updatedCustomer.persons"
              [currencyId]="updatedCustomer.currencyId"
              [displayImportation]="false"
            >
            </app-commercial-modality>
          </ng-template>
        </ui-tab>
        <ui-tab
          *ngIf="!this.editedCustomer.isIndividual()"
          title="{{ 'customer.tab-title.metal-management' | translate }}"
          id="metal-management"
        >
          <ng-template #tabTemplate>
            <div class="metal-container">
              <div class="metal-account-side">
                <gen-metal-account-activation
                  #metalAccountActivation
                  *ngIf="this.updatedCustomer"
                  [contactId]="this.updatedCustomer.id"
                  [contactDisabled]="this.updatedCustomer.archived"
                  [(metalAccountsList)]="this.updatedCustomer.metalAccounts"
                  [contactTypeIsCustomer]="true"
                ></gen-metal-account-activation>
              </div>
            </div>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'customer.tab-title.document-history' | translate }}" id="document-history">
          <ng-template #tabTemplate>
            <app-customer-document-history
              #customerDocumentView
              *ngIf="updatedCustomer"
              [(customerDocument)]="updatedCustomer.customerDocument"
              (submitEvent)="submitCustomer()"
            >
            </app-customer-document-history>
          </ng-template>
        </ui-tab>
        <ui-tab
          title="{{ 'customer.tab-title.customer-stores' | translate }}"
          id="customer-stores"
          *ngIf="isAffiliate()"
        >
          <ng-template #tabTemplate>
            <app-customer-stores *ngIf="updatedCustomer" [customer]="updatedCustomer"> </app-customer-stores>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>
    </div>
  </div>
</ng-container>
