import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { IconDefinition, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  CaraUser,
  CaraUserService,
  Currency,
  CustomerService,
  Light,
  LightCustomer,
  PaginatedList,
  Pagination,
  ReceiveStatus,
  ReceivingForm,
  ReceivingFormService,
  Sort,
  LightStore,
  StoreService,
} from "center-services";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import hash from "string-hash";
import { PropsToFilter } from "./props-to-filter";
import { DatatableComponent } from "@siemens/ngx-datatable";

@Component({
  selector: "app-invoice-customer-delivery-selection",
  templateUrl: "./invoice-customer-delivery-selection.component.html",
  styleUrls: ["./invoice-customer-delivery-selection.component.scss"],
  providers: [SubscriptionService],
})
export class InvoiceCustomerDeliverySelectionComponent
  extends FilteredTableListComponent
  implements OnInit, OnDestroy, PaginableComponent {
  public static LIST_ID: string = "app-invoice-customer-delivery-selection.delivery-selection";
  @Input() currency: Currency;
  @Input() storeCustomerLink: Map<number, number>;
  @Input() customerList: LightCustomer[] = [];
  @Input() storeList: LightStore[] = [];
  @Input() unModifiedStoreList: LightStore[] = [];
  @Input() supplierList: Light[] = [];
  @Input() selectedDeliveryForms: ReceivingForm[] = [];
  @Input() contactId: number = null;
  @Input() dfsAlreadySelected: string[] = [];
  @Input() isOneInvoicePerStoreEnabled: boolean;

  @Output() checkedOneInvoicePerStoreCheckboxChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedDeliveryFormsChange: EventEmitter<ReceivingForm[]> = new EventEmitter<ReceivingForm[]>();

  @ViewChild("table") table: DatatableComponent;

  public rows: any[] = [];
  public sorts: any[] = [{ prop: "date", dir: "desc" }];
  public tableControl: UntypedFormGroup;
  public formControl: UntypedFormGroup;
  public deliveryFormList: ReceivingForm[];
  public activeFilters: SearchFilter[] = [];
  public currentRowsId: number[] = [];
  public locale: string;
  public dateFormat: string;
  // tells if user selected DFs toward several stores
  public multipleStoreSelection: boolean = false;
  public filtersInitialized: boolean = false;
  public oneInvoicePerStore: boolean;
  public readonly STORE: string = "store";
  public readonly CUSTOMER: string = "customer";
  public readonly DOCUMENT_DATE: string = "documentDate";
  public pager: Pagination = new Pagination({
    number: 0,
    size: 7,
    totalElements: 0,
    totalPages: 0,
  });
  public filterer: Filterer;
  faSearch: IconDefinition = faSearch;
  private oldFilterValues: FilterValue[] = [];
  private storesPossibilities: LightStore[] = [];
  private sessionPagination: SessionPagination;

  constructor(
    private fb: UntypedFormBuilder,
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected storeService: StoreService,
    protected customerService: CustomerService,
    private receivingFormService: ReceivingFormService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  ngOnInit(): void {
    this.oneInvoicePerStore = this.isOneInvoicePerStoreEnabled;

    this.initSelectFormControl();

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.fetchConnectedUserDetails();
    }

    this.storesPossibilities = this.storeList;
    this.initFilters();
    if (!this.contactId) {
      this.sessionPagination.loadFromSession(InvoiceCustomerDeliverySelectionComponent.LIST_ID);
    }
    this.applyFilters();

    this.getDeliveryformList();
  }

  ngOnDestroy(): void {
    this.sessionPagination.saveToSession(InvoiceCustomerDeliverySelectionComponent.LIST_ID);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  getReceivingFormSenderName(id: number): string {
    let sender = this.supplierList?.find(supp => supp.id === id);

    if (sender === undefined) {
      sender = this.unModifiedStoreList?.find(store => store.id === id);
    }

    if (sender === undefined) {
      return id.toString();
    }

    return sender.name;
  }

  isFilterSelectedWithValue(key: string): boolean {
    return (
      this.activeFilters.some(filter => filter.key === key) &&
      this.activeFilters.find(fil => fil.key === key).value.length > 0
    );
  }

  isStoreLinkedToACustomer(customerId: number, storeId: number): boolean {
    return this.storeCustomerLink.has(storeId) && this.storeCustomerLink.get(storeId) === customerId;
  }

  isStoreLinkedToCustomers(customersIds: number[], storeId: number): boolean {
    return customersIds.some(id => this.isStoreLinkedToACustomer(id, storeId));
  }

  computeAllLinkedStoresInFilter(customers: LightCustomer[]): string {
    return customers
      ?.filter(customer => customer.affiliate)
      .flatMap(customer =>
        this.storeList
          ?.filter(store => this.isStoreLinkedToACustomer(customer.id, store.id))
          .map(store => store.id.toString())
      )
      .join("~");
  }

  computeAllCustomersAndStoresInFilter(): string {
    const symbol = "~";

    const customerFilter = this.customerList?.map(customer => customer.id.toString()).join(symbol);
    const storeFilter = this.storeList.map(store => store.id.toString()).join(symbol);

    return customerFilter.concat(symbol, storeFilter);
  }

  getStoreFilters(customers: LightCustomer[]): string {
    return this.isFilterSelectedWithValue(this.STORE)
      ? this.activeFilters.find(filter => filter.key === this.STORE).value
      : this.computeAllLinkedStoresInFilter(customers);
  }

  getCustomersFromIds(ids: string[]): LightCustomer[] {
    const customers = [];

    ids.forEach(id => {
      customers.push(this.customerList?.find(cus => cus.id.toString() === id));
    });

    return customers;
  }

  getDeliveryformList(): void {
    let filters = [];
    let customers;
    let receiverFilter;

    if (this.isFilterSelectedWithValue(this.CUSTOMER)) {
      receiverFilter = this.activeFilters.find(filter => filter.key === this.CUSTOMER).value;
      const customerIds = receiverFilter.split("~");
      customers = this.getCustomersFromIds(customerIds);
    } else {
      receiverFilter = this.isFilterSelectedWithValue(this.STORE) ? "" : this.computeAllCustomersAndStoresInFilter();
      customers = this.isFilterSelectedWithValue(this.STORE) ? [] : this.customerList;
    }

    const storesFilter = this.getStoreFilters(customers);
    if (storesFilter.length > 0) {
      receiverFilter = receiverFilter.length === 0 ? storesFilter : receiverFilter.concat("~", storesFilter);
    }

    filters.push(new SearchFilter("receiver.id", SearchFilterOperator.IN, receiverFilter));

    if (this.isFilterSelectedWithValue(this.DOCUMENT_DATE)) {
      filters = filters.concat(this.activeFilters.filter(filter => filter.key === this.DOCUMENT_DATE));
    }

    // filtres de base
    filters.push(
      new SearchFilter("receiveStatus", SearchFilterOperator.IN, [
        ReceiveStatus.RECEIVED,
        ReceiveStatus.TO_RECEIVE,
        ReceiveStatus.RECEIVE_IN_PROGRESS,
      ])
    );
    filters.push(new SearchFilter("fullyInvoiced", SearchFilterOperator.EQUAL, "false"));
    this.fetchDeliveryFormlist(filters);
  }

  fetchDeliveryFormlist(filters: any[]): void {
    this.subscriptionService.subs.push(
      this.receivingFormService.getAll(this.pager, this.getSorter(), filters).subscribe(
        (result: PaginatedList<ReceivingForm>) => {
          this.rows = [];
          this.deliveryFormList = result.data;
          this.pager = result.page;

          this.currentRowsId = this.deliveryFormList.map((delivery: ReceivingForm) => delivery.id);
          this.deliveryFormList?.forEach((deliveryForm: ReceivingForm) => {
            this.addRow(deliveryForm);
          });
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-delivery-forms", error.message);
        },
        () => {
          this.rows = [...this.rows];
          if (this.table) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }
          this.initializeSelectedDeliveryFormList();
          this.updateSelection();
          this.updateHeaderPageCheckbox();
        }
      )
    );
  }

  addRow(deliveryForm: ReceivingForm): void {
    const isStoreReceiver = this.storeList?.some(sto => sto.id === deliveryForm.receiverId);
    const store = this.storeList?.find(sto => sto.id === deliveryForm.receiverId);
    let customer;

    if (isStoreReceiver) {
      const customerId = this.storeCustomerLink.get(deliveryForm.receiverId);
      if (customerId) {
        customer = this.customerList?.find(cus => cus.id === customerId).name;
      }
    } else {
      customer = this.customerList?.find(cus => cus.id === deliveryForm.receiverId)?.name;
    }

    const type = this.translateService.instant(
      `invoice-customer-delivery-selection.datatable.datas.delivery-type-content.${deliveryForm.type}`
    );

    this.rows.push({
      id: deliveryForm.id,
      deliveryRef: deliveryForm.deliveryRef,
      date: deliveryForm.documentDate,
      deliveryType: type,
      sender: this.getReceivingFormSenderName(deliveryForm.senderId),
      customer,
      currencyIsoCode: this.currency.codeISO,
      store: isStoreReceiver
        ? store.name
        : this.translateService.instant("invoice-customer-delivery-selection.datatable.datas.no-store-receiver"),
      totalQuantity: deliveryForm.totalQuantityToInvoiced,
      totalPrice: deliveryForm.totalPrice,
      uom: this.getDeliveryFormUom(deliveryForm),
    });

    const isControlDisabled: boolean = this.dfsAlreadySelected.includes(deliveryForm.deliveryRef);

    this.tableControl?.addControl(
      this.getRowControlName(deliveryForm.id.toString()),
      new UntypedFormControl({ value: isControlDisabled, disabled: isControlDisabled })
    );
  }

  public getRowControlName(id: string | number): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  public onOneInvoicePerStoreCheckboxChange(): void {
    this.checkedOneInvoicePerStoreCheckboxChange.emit(this.formControl.controls.oneInvoicePerStoreCheckbox.value);
  }

  updateHeaderPageCheckbox(): void {
    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.tableControl?.get(this.getRowControlName(id)).value;
    });
    const isSelected = this.currentRowsId.length > 0 && this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.tableControl?.controls.headerCheckbox.patchValue(isSelected);
  }

  updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
    });
  }

  initializeSelectedDeliveryFormList(): void {
    if (this.selectedDeliveryForms.length > 0) {
      this.selectedDeliveryForms.forEach(df => {
        this.tableControl.controls[this.getRowControlName(df.id)]?.setValue(true);
      });
    }
  }

  getIdsFromSelectedDeliveryForms(): number[] {
    return this.selectedDeliveryForms.flatMap(df => df.receiverId).filter((id, i, array) => array.indexOf(id) === i);
  }

  getNumberOfStoresInSelectedDeliveryForm(ids: number[]): number {
    return ids.filter(id => this.storeList.some(store => store.id === id)).length;
  }

  isThereMoreThanOneStoreSelectedDeliveyForm(): boolean {
    return this.getNumberOfStoresInSelectedDeliveryForm(this.getIdsFromSelectedDeliveryForms()) > 1;
  }

  public updateSelection(): void {
    this.rows
      .filter(row => !this.tableControl?.controls[this.getRowControlName(row.id)].disabled)
      .forEach(row => {
        const rowChecked: boolean = this.tableControl?.controls[this.getRowControlName(row.id)].value;
        const deliveryForm: ReceivingForm = this.deliveryFormList.find(elem => elem.id === row.id);
        const isSelected: boolean = this.selectedDeliveryForms.findIndex(elem => elem.id === row.id) !== -1;
        if (!isSelected && rowChecked) {
          this.selectedDeliveryForms.push(deliveryForm);
        } else if (isSelected && !rowChecked) {
          const index = this.selectedDeliveryForms.findIndex(elem => elem.id === deliveryForm.id);
          this.selectedDeliveryForms.splice(index, 1);
        }
      });

    this.updateMultipleStoreSelection();
    this.selectedDeliveryFormsChange.emit(this.selectedDeliveryForms);
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(PropsToFilter.propsToFilter(s.prop), s.dir));
    }
    return sorter;
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "date":
        return this.DOCUMENT_DATE;
      default:
        return prop;
    }
  }

  changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];

    this.getDeliveryformList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.initializeSelectedDeliveryFormList();
    this.getDeliveryformList();
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  getRowClass: any = (): any => ({ "not-clickable": true });

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === InvoiceCustomerDeliverySelectionComponent.LIST_ID
    );

    const customersList = this.customerList?.map((customer: Light) => {
      return { value: customer.id.toString(), displayValue: `${customer.reference} - ${customer.name}` };
    });

    this.filterer = new Filterer(componentFilterPref?.filters);

    const customerIds = [...new Set(this.storeCustomerLink.values())].map(String);

    if (this.contactId && customerIds.length === 0) {
      customerIds.push(this.contactId.toString());
    }

    this.filterer.addListFilter(
      this.CUSTOMER,
      this.translateService.instant("invoice-customer-delivery-selection.datatable.columns.customer"),
      customersList,
      !!this.contactId,
      !!this.contactId,
      this.contactId ? customerIds : [],
      null,
      true,
      null,
      null,
      true,
      !!this.contactId
    );

    this.filterer.addListFilter(
      this.STORE,
      this.translateService.instant("invoice-customer-delivery-selection.datatable.columns.store"),
      this.storesPossibilities.map(store => {
        return { value: store.id.toString(), displayValue: store.name };
      }),
      false
    );

    this.filterer.addFilter(
      this.DOCUMENT_DATE,
      this.translateService.instant("invoice-customer-delivery-selection.datatable.columns.date"),
      "date",
      false,
      false,
      this.buildDateDefaultValue()
    );
  }

  buildDateDefaultValue(): any {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    return {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
  }

  updateStoreList(newFilterValues?: FilterValue[]): void {
    if (newFilterValues) {
      this.filterer.filterValues = newFilterValues;
    }

    const old = this.oldFilterValues
      ?.filter((f: FilterValue) => f.filterId === hash(this.CUSTOMER))
      .map((f: FilterValue) => f.equal)[0] as number[];
    const actual = this.filterer.filterValues
      ?.filter((f: FilterValue) => f.filterId === hash(this.CUSTOMER))
      .map((f: FilterValue) => f.equal)[0] as number[];

    if (old?.length !== actual?.length) {
      const ids = this.getAffiliateCustomersIds(actual);

      this.storesPossibilities =
        ids.length > 0 ? this.storeList.filter(store => this.isStoreLinkedToCustomers(ids, store.id)) : this.storeList;
      this.filterer.updateListFilterOptions(
        this.STORE,
        this.storesPossibilities.map(store => {
          return { value: store.id.toString(), displayValue: store.name };
        })
      );
    }
    this.oldFilterValues = JSON.parse(JSON.stringify(this.filterer.filterValues));
  }

  filterValueChange(newFilterValues: FilterValue[]): void {
    this.updateStoreList(newFilterValues);
  }

  applyFilters(): void {
    if (this.filtersInitialized) {
      this.pager.number = 0;
    } else {
      this.filtersInitialized = true;
    }
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        InvoiceCustomerDeliverySelectionComponent.LIST_ID
      ).subscribe(() => {
        this.getDeliveryformList();
      })
    );
  }

  protected fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  private getDeliveryFormUom(deliveryForm: ReceivingForm): any {
    if (deliveryForm.multipleUoms) {
      return {
        name: this.translateService.instant("invoice-customer-delivery-selection.datatable.datas.different-uoms"),
        class: "minified",
      };
    }

    return deliveryForm.lines.length === 0 ? { name: "", class: "" } : { name: deliveryForm.mainUnitName, class: "" };
  }

  private initSelectFormControl(): void {
    this.tableControl = this.fb.group({
      headerCheckbox: new UntypedFormControl(false),
    });

    this.formControl = this.fb.group({
      oneInvoicePerStoreCheckbox: new UntypedFormControl(this.oneInvoicePerStore),
    });

    this.formControl.addControl(
      "oneInvoicePerStoreCheckbox",
      new UntypedFormControl({ value: false, disabled: this.multipleStoreSelection })
    );
  }

  private updateMultipleStoreSelection(): void {
    this.multipleStoreSelection = this.isThereMoreThanOneStoreSelectedDeliveyForm();

    if (!this.multipleStoreSelection && this.formControl) {
      this.formControl.controls.oneInvoicePerStoreCheckbox.setValue(false);
    }
  }

  private computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  private getAffiliateCustomersIds(customerChoices: any[]): number[] {
    const ids = customerChoices?.map(id => +this.filterer.optionsMap.customer[id]);
    const affiliateCustomersIds = [];

    ids?.forEach(id => {
      if (this.customerList.find(cus => cus.id === id)?.affiliate) {
        affiliateCustomersIds.push(id);
      }
    });

    return affiliateCustomersIds;
  }
}
