export class MultiShipmentRow {
  id: number;
  lineNumber: number;
  deliveryRef: string;
  quantity: number;
  quantityUnit: string | boolean;
  price: number;
  errorMessage: string;
  inError: boolean;
}
