<ng-container>
  <div class="main-container">
    <div class="filters" *ngIf="filterer">
      <ui-dynamic-filters
        label="{{ 'filters.label' | translate }}"
        [filters]="filterer.getFilters()"
        [(filterValues)]="filterer.filterValues"
        (apply)="applyFilters()"
        minLabel="{{ 'filters.min' | translate }}"
        maxLabel="{{ 'filters.max' | translate }}"
        applyLabel="{{ 'filters.apply' | translate }}"
        deleteLabel="{{ 'filters.delete' | translate }}"
        placeholderPrefix="{{ 'filters.prefix' | translate }} "
      >
      </ui-dynamic-filters>
    </div>
    <div class="brand-suppliers-table">
      <div [formGroup]="tableControl">
        <ngx-datatable
          #table
          [rows]="rows"
          [trackByProp]="'id'"
          class="material"
          columnMode="force"
          headerHeight="auto"
          footerHeight="50"
          rowHeight="40"
          [limit]="15"
          (activate)="viewSupplier($event)"
          [cssClasses]="{
            sortAscending: 'fas fa-chevron-up',
            sortDescending: 'fas fa-chevron-down'
          }"
          [messages]="{ emptyMessage: 'global.no-data' | translate }"
          [sorts]="sorts"
          [offset]="pageNumber"
          (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          (page)="pageNumber = $event.offset"
        >
          <ngx-datatable-column
            name="{{ 'brand-suppliers-list.datatable.columns.name' | translate }}"
            prop="name"
            [resizeable]="false"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'brand-suppliers-list.datatable.columns.activated' | translate }}"
            headerClass="center-align"
            cellClass="center-align"
            prop="activated"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
              <div class="green-zone" (click)="$event.stopPropagation()">
                <ui-switch
                  customClass="no-margin"
                  (change)="checkboxOnChanges(id)"
                  [formControl]="tableControl.get('activated_' + id)"
                >
                </ui-switch>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="rowCount">
                {{
                  rowCount
                    | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                    | translate: { rowCount: rowCount }
                }}
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                [pagerRightArrowIcon]="'fas fa-chevron-right'"
                [pagerNextIcon]="'fas fa-chevron-double-right'"
                [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="table.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</ng-container>
