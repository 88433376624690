import { Component, OnInit } from "@angular/core";
import { LoaderService } from "app/service/loader.service";
import { SubscriptionService } from "fugu-common";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
  providers: [SubscriptionService],
})
export class LoaderComponent implements OnInit {
  public title: string;

  public options: AnimationOptions = {
    path: "assets/animation/loader.json",
    autoplay: true,
    loop: true,
  };

  constructor(
    public loaderService: LoaderService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.subscriptionService.subs.push(
      this.loaderService.httpMethod.subscribe((method: string) => {
        if (["POST", "PUT", "PATCH", "DELETE"].includes(method)) {
          this.title = "loader.title.saving";
        } else {
          this.title = "loader.title.loading";
        }
      })
    );
  }
}
