import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faArrowCircleRight, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { Light, LightCustomer, LightService, LightStore } from "center-services";
import { Option, SubscriptionService } from "fugu-common";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { FreeInvoiceCustomerInitiatorOutput } from "./free-invoice-customer-initiator-output";
import { ToastMessageService } from "fugu-components";

@Component({
  selector: "app-free-invoice-customer-initiator-popup",
  templateUrl: "./free-invoice-customer-initiator-popup.component.html",
  styleUrls: ["./free-invoice-customer-initiator-popup.component.scss"],
  providers: [SubscriptionService],
})
export class FreeInvoiceCustomerInitiatorPopupComponent implements OnInit {
  @Output() validate: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  public popupForm: UntypedFormGroup;
  public customerOptions: Option[] = [];
  public storeOptions: Option[] = [];
  public customerList: LightCustomer[] = [];
  public storeList: LightStore[] = [];
  public storeCustomerLink: Map<number, number> = new Map();

  public initialOutput: FreeInvoiceCustomerInitiatorOutput;
  public unsavedOutput: FreeInvoiceCustomerInitiatorOutput;
  public selectedOutput: FreeInvoiceCustomerInitiatorOutput;
  public shouldClose: boolean = false;
  faArrowCircleRight: IconDefinition = faArrowCircleRight;
  faChevronLeft: IconDefinition = faChevronLeft;
  private initObservables: Observable<any>[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private lightService: LightService,
    private messageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    this.initObservables.push(this.fetchCustomers());
    this.initObservables.push(this.fetchStores());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.selectedOutput = new FreeInvoiceCustomerInitiatorOutput({
          customerId: null,
          storeId: null,
        });

        this.initialOutput = new FreeInvoiceCustomerInitiatorOutput(this.selectedOutput);
      })
    );
  }

  public prepareForm(): void {
    this.popupForm = this.fb.group({
      customer: [null, [Validators.required]],
      store: [null],
    });

    this.subscriptionService.subs.push(
      this.popupForm.controls.customer.valueChanges.subscribe((customerOptId: number) => {
        this.updateStoreList(customerOptId);
      })
    );

    this.subscriptionService.subs.push(
      this.popupForm.controls.store.valueChanges.subscribe((storeOptId: number) => {
        if (!this.popupForm.controls.customer.value) {
          this.popupForm.controls.customer.patchValue(
            this.storeList.find(store => store.id === storeOptId)?.customerId
          );
        }
      })
    );
  }

  public updateStoreList(customerOptId: number): void {
    const selectedCustomer = this.customerList.find((customer: LightCustomer) => customer.id === customerOptId);
    if (!selectedCustomer.affiliate) {
      this.storeOptions = [];
    } else {
      this.storeOptions = this.storeList
        .filter(store => store.customerId === customerOptId)
        .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
    }
  }

  public closePopup(event: boolean): void {
    this.applyModifications();

    if (this.unsavedOutput && !this.unsavedOutput.equals(this.selectedOutput)) {
      this.shouldClose = false;
    }

    if (!this.initialOutput.equals(this.selectedOutput) && !this.shouldClose) {
      this.messageService.generateMessage("info", "global.errors.unsaved-title", "global.errors.unsaved-popin-content");

      this.unsavedOutput = new FreeInvoiceCustomerInitiatorOutput(this.selectedOutput);
      this.shouldClose = true;
    } else {
      this.close.emit(event);
    }
  }

  public submit(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }
    this.applyModifications();
    this.validate.emit([this.selectedOutput]);
  }

  applyModifications(): void {
    this.selectedOutput.customerId = this.popupForm.controls.customer.value;
    this.selectedOutput.storeId = this.popupForm.controls.store.value;
  }

  fetchCustomers(): Observable<Light[]> {
    return this.lightService.getCustomers().pipe(
      tap(
        (lightCustomers: LightCustomer[]) => {
          this.customerOptions = lightCustomers
            .filter(obj => !obj.archived)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.customerList = lightCustomers;
        },
        () => {
          this.messageService.generateMessage(
            "warn",
            "message.title.data-errors",
            "invoice-customer-initiator-popup.errors.get-customers"
          );
        }
      )
    );
  }

  fetchStores(): Observable<LightStore[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: LightStore[]) => {
          this.storeOptions = lightStores
            .filter(obj => !obj.archived && obj.customerId)
            .sort((a, b) => a.reference.localeCompare(b.reference))
            .map(obj => new Option(obj.id, `${obj.reference} - ${obj.name}`));
          this.storeList = lightStores.filter(obj => !obj.archived && obj.customerId);
        },
        () => {
          this.messageService.generateMessage(
            "warn",
            "message.title.data-errors",
            "invoice-customer-initiator-popup.errors.get-stores"
          );
        }
      )
    );
  }
}
