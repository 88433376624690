import { StockType } from "center-services";

export class ItemOutput {
  id: number;
  diff: number;
  supplierRef: string;
  itemReference: string;
  uomName: string;
  itemName: string;
  sizeValue: string;
  stockType: StockType;

  constructor(values: any = {}) {
    this.id = values.id;
    this.diff = values.diff;
    this.supplierRef = values.supplierRef;
    this.itemReference = values.itemReference;
    this.uomName = values.uomName;
    this.itemName = values.itemName;
    this.sizeValue = values.sizeValue;
    this.stockType = values.stockType;

    if (typeof values.stockType === "string") {
      this.parseStockType(values.stockType);
    }
  }

  parseStockType(stockTypeStr: string): StockType {
    switch (stockTypeStr) {
      case "SERIAL_NUMBER":
        return StockType.SERIAL_NUMBER;
      case "BATCH":
        return StockType.BATCH;
      case "BULK":
        return StockType.BULK;
      default:
        console.error(`Cannot handle StockType: ${stockTypeStr}`);
        return undefined;
    }
  }
}
