<ng-container>
  <div class="main-container frozen-right">
    <div [formGroup]="itemGroupsForm">
      <ngx-datatable
        #table
        [rows]="rows"
        [trackByProp]="'id'"
        class="material"
        columnMode="force"
        headerHeight="auto"
        footerHeight="50"
        rowHeight="40"
        [limit]="15"
        [scrollbarH]="true"
        [rowClass]="getRowClass"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down'
        }"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        [sorts]="sorts"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
      >
        <ngx-datatable-column
          name="{{ 'retail-item-group-list.datatable.columns.name' | translate }}"
          cellClass="name"
          prop="name"
          width="250"
          [resizeable]="false"
          [flexGrow]="6"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'retail-item-group-list.datatable.columns.universe' | translate }}"
          cellClass="universe"
          prop="universe"
          width="250"
          [resizeable]="false"
          [flexGrow]="6"
        >
        </ngx-datatable-column>

        <!-- Loop of store module column -->

        <ngx-datatable-column
          *ngFor="let storeModule of this.storeModules"
          name="{{ storeModule.name }}"
          cellClass="modules"
          width="40"
          [resizeable]="false"
          [flexGrow]="0.1"
        >
          <ng-template ngx-datatable-cell-template let-row="row" let-rowId="row.id">
            <ui-input-text
              id="{{ rowId + '_' + storeModule.name }}"
              customClass="datatable-row"
              class="input-fields"
              type="text"
              sizeMode="small"
              [formControl]="itemGroupsForm.get(rowId + '_' + storeModule.id)"
              [status]="itemGroupsForm.get(rowId + '_' + storeModule.id) | formStatus"
              [errorMessage]="
                itemGroupsForm.get(rowId + '_' + storeModule.id) | formErrorMessage | translate: { digit: HIGH_INTEGER }
              "
              [maskPattern]="decimalDigit"
              (blur)="saveInputValue(rowId + '_' + storeModule.id)"
            >
            </ui-input-text>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'retail-item-group-list.datatable.columns.actions' | translate"
          [headerClass]="'center-align'"
          [cellClass]="'actions center-align frozen-style'"
          [resizeable]="false"
          [frozenRight]="true"
          [sortable]="false"
          [width]="120"
        >
          <ng-template ngx-datatable-cell-template let-rowId="row.id">
            <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, rowId)">
            </ui-menu-actions>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="rowCount">
              {{
                rowCount
                  | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                  | translate: { rowCount: rowCount }
              }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="table.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</ng-container>
