import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Customization, CustomizationService } from "center-services";
import { SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { ComponentDirty, ErrorUtil } from "generic-pages";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-customization",
  templateUrl: "./customization.component.html",
  styleUrls: ["./customization.component.scss"],
  providers: [SubscriptionService],
})
export class CustomizationComponent implements OnInit, ComponentDirty {
  public form: UntypedFormGroup;
  // values from DB
  public initialCustomization: Customization;
  // values updated in forms
  public updateCustomization: Customization;
  // values created for IsDirty
  public unsavedCustomization: Customization;
  // for isDirty stuff
  public shouldClose: boolean = false;

  constructor(
    private customizationService: CustomizationService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareForm();
  }

  ngOnInit(): void {
    this.subscriptionService.subs.push(
      this.fetchCustomization().subscribe(
        () => {
          if (this.initialCustomization) {
            this.loadEditedData();
          }
        },
        error => {
          this.handleApiError(error);
        }
      )
    );
  }

  fetchCustomization(): Observable<Customization> {
    return this.customizationService.get().pipe(
      tap((customization: Customization) => {
        this.initialCustomization = customization;
        this.updateCustomization = this.cloneEntity(this.initialCustomization);
      })
    );
  }

  applyModifications(): void {
    this.updateCustomization.invoiceFooter = this.form.value.invoiceFooter;
  }

  isDirty(): boolean {
    if (!this.form.dirty) {
      return false;
    }

    this.applyModifications();

    if (!this.unsavedCustomization?.equals(this.updateCustomization)) {
      this.shouldClose = false;
    }

    if (!this.initialCustomization?.equals(this.updateCustomization) && !this.shouldClose) {
      this.recordDatas();
      this.shouldClose = true;
      return true;
    } else if (!this.initialCustomization && !this.shouldClose) {
      this.recordDatas();
      return true;
    }

    this.unsavedCustomization = null;
    this.shouldClose = false;
    return false;
  }

  recordDatas(): void {
    this.unsavedCustomization = this.cloneEntity(this.updateCustomization);
  }

  submit(): void {
    if (!this.form.dirty) {
      return;
    }

    this.applyModifications();

    this.subscriptionService.subs.push(
      this.customizationService.update(this.updateCustomization).subscribe((customization: Customization) => {
        this.initialCustomization = customization;
        this.updateCustomization = this.cloneEntity(this.initialCustomization);
        this.unsavedCustomization = null;

        const content = this.translateService.instant("message.content.save-success");
        const title = this.translateService.instant("message.title.save-success");
        this.messageService.success(content, { title });
      })
    );
  }

  handleApiError(error: any): void {
    const result = ErrorUtil.getTranslationKey(error.error);
    const content = this.translateService.instant(result.message);
    const title = this.translateService.instant("message.title.form-errors");
    this.messageService.error(content, { title });
  }

  protected cloneEntity(customization: Customization): Customization {
    return new Customization(customization);
  }

  private prepareForm(): void {
    this.form = this.fb.group({
      invoiceFooter: [null],
    });
  }

  private loadEditedData(): void {
    if (this.initialCustomization) {
      this.form.setValue({
        invoiceFooter: this.initialCustomization.invoiceFooter,
      });
    }
  }
}
