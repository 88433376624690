import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { IconDefinition, faCheck, faExclamationTriangle, faRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  StockEntryLocation,
  StockEntryLocationUpdate,
  CaraUserService,
  StockEntryService,
  StockType,
} from "center-services";
import { CommonValidatorsUtil, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { ErrorUtil, PrecisionUtil } from "generic-pages";
import { merge } from "rxjs";

@Component({
  selector: "app-edit-stock-popup",
  templateUrl: "./edit-stock-popup.component.html",
  styleUrls: ["./edit-stock-popup.component.scss"],
  providers: [SubscriptionService],
})
export class EditStockPopupComponent implements OnInit {
  @Input() stockEntryLocation: StockEntryLocation;
  @Input() unit: string;
  @Output() close: EventEmitter<any> = new EventEmitter();

  public readonly decimalDigitHigh: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public readonly integerDigitHigh: string = `${PrecisionUtil.HIGH_INTEGER}`;

  public popupForm: UntypedFormGroup;

  public initialStockEntryLocationUpdate: StockEntryLocationUpdate;
  public unsavedStockEntryLocationUpdate: StockEntryLocationUpdate;
  public selectedStockEntryLocationUpdate: StockEntryLocationUpdate;

  public warnIcon: IconDefinition = faExclamationTriangle;
  public faCheck: IconDefinition = faCheck;
  public faRotateLeft: IconDefinition = faRotateLeft;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private userService: CaraUserService,
    private messageService: MessageService,
    private stockEntryService: StockEntryService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.preparePopupForm();
    this.initializePopup();
  }

  preparePopupForm(): void {
    const digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);

    this.popupForm = this.fb.group({
      sizeValue: this.stockEntryLocation?.stockEntry.sizeValue ? [null, [Validators.required]] : null,
      weight: this.stockEntryLocation?.weight
        ? [null, [Validators.required, digitValidator, Validators.min(0)]]
        : [null, [digitValidator, Validators.min(0)]],
      tare: this.stockEntryLocation?.tare
        ? [null, [Validators.required, digitValidator, Validators.min(0)]]
        : [null, [digitValidator, Validators.min(0)]],
      comment: null,
    });

    this.popupForm.setValidators(CommonValidatorsUtil.tareSuperiorToWeightValidator());

    this.subscriptionService.subs.push(
      merge(this.popupForm.controls.weight.valueChanges, this.popupForm.controls.tare.valueChanges).subscribe(() => {
        const initialWeight = this.initialStockEntryLocationUpdate.weight;
        const initialTare = this.initialStockEntryLocationUpdate.tare;
        const formWeight =
          this.popupForm.controls.weight.value === "" || this.popupForm.controls.weight.value === null
            ? null
            : +this.popupForm.controls.weight.value;
        const formTare =
          this.popupForm.controls.tare.value === "" || this.popupForm.controls.tare.value === null
            ? null
            : +this.popupForm.controls.tare.value;
        if (
          (initialWeight !== formWeight || initialTare !== formTare) &&
          (this.popupForm.controls.comment.value === "" || this.popupForm.controls.comment.value === null)
        ) {
          this.popupForm.controls.comment.setValidators(Validators.required);
          this.popupForm.controls.comment.setErrors({ required: true });
        } else {
          this.popupForm.controls.comment.setValidators(null);
          this.popupForm.controls.comment.setErrors(null);
        }
      })
    );
  }

  initializePopup(): void {
    this.initialStockEntryLocationUpdate = this.getInitStockEntryLocationUpdate();
    this.unsavedStockEntryLocationUpdate = this.cloneStockEntryLocationUpdate(this.initialStockEntryLocationUpdate);
    this.selectedStockEntryLocationUpdate = this.cloneStockEntryLocationUpdate(this.initialStockEntryLocationUpdate);

    this.popupForm.controls.sizeValue.setValue(this.initialStockEntryLocationUpdate.sizeValue);
    this.popupForm.controls.weight.setValue(this.initialStockEntryLocationUpdate.weight);
    this.popupForm.controls.tare.setValue(this.initialStockEntryLocationUpdate.tare);
    this.popupForm.controls.comment.setValue(this.initialStockEntryLocationUpdate.comment);
  }

  getInitStockEntryLocationUpdate(): StockEntryLocationUpdate {
    return new StockEntryLocationUpdate({
      weight: this.stockEntryLocation?.weight,
      tare: this.stockEntryLocation?.tare,
      sizeValue: this.stockEntryLocation?.stockEntry?.sizeValue,
      comment: null,
    });
  }

  cloneStockEntryLocationUpdate(stockEntryLocationUpdate: StockEntryLocationUpdate): StockEntryLocationUpdate {
    return new StockEntryLocationUpdate(stockEntryLocationUpdate);
  }

  public getPopupTitle(): string {
    let title: string = this.translateService.instant("edit-stock-popup.title");
    const subTitle: string = this.stockEntryLocation?.stockEntry?.itemReference;

    if (subTitle) {
      title += ` - ${subTitle}`;
    }
    return title;
  }

  applyModifications(): void {
    this.unsavedStockEntryLocationUpdate = this.cloneStockEntryLocationUpdate(this.selectedStockEntryLocationUpdate);

    const sizeValueFormValue = this.popupForm.get("sizeValue").value;
    const weightFormValue = this.popupForm.get("weight").value;
    const tareFormValue = this.popupForm.get("tare").value;
    const commentFormValue = this.popupForm.get("comment").value;

    this.selectedStockEntryLocationUpdate.sizeValue = sizeValueFormValue;
    this.selectedStockEntryLocationUpdate.weight = weightFormValue;
    this.selectedStockEntryLocationUpdate.tare = tareFormValue;
    this.selectedStockEntryLocationUpdate.comment = commentFormValue;
  }

  sizeValueMandatory(): boolean {
    return !!this.initialStockEntryLocationUpdate.sizeValue;
  }

  weightMandatory(): boolean {
    const formTare = this.popupForm.controls.tare.value;
    let isTareEmpty = true;

    if (formTare !== "" && formTare !== null) {
      isTareEmpty = false;
    }

    return !!this.initialStockEntryLocationUpdate.weight || !isTareEmpty;
  }

  tareMandatory(): boolean {
    return !!this.initialStockEntryLocationUpdate.tare;
  }

  commentMandatory(): boolean {
    const initialWeight = this.initialStockEntryLocationUpdate.weight;
    const initialTare = this.initialStockEntryLocationUpdate.tare;
    const formWeight =
      this.popupForm.controls.weight.value === "" || this.popupForm.controls.weight.value === null
        ? null
        : +this.popupForm.controls.weight.value;
    const formTare =
      this.popupForm.controls.tare.value === "" || this.popupForm.controls.tare.value === null
        ? null
        : +this.popupForm.controls.tare.value;
    let isValid = false;
    if (initialWeight !== formWeight || initialTare !== formTare) {
      isValid = true;
    }
    return isValid;
  }

  isBatchOrBulk(): boolean {
    const stockType = this.stockEntryLocation?.stockEntry?.stockType;
    return stockType === StockType.BATCH || stockType === StockType.BULK;
  }

  restoreData(): void {
    this.unsavedStockEntryLocationUpdate = this.cloneStockEntryLocationUpdate(this.initialStockEntryLocationUpdate);

    this.popupForm.controls.sizeValue.setValue(this.initialStockEntryLocationUpdate.sizeValue);
    this.popupForm.controls.weight.setValue(this.initialStockEntryLocationUpdate.weight);
    this.popupForm.controls.tare.setValue(this.initialStockEntryLocationUpdate.tare);
    this.popupForm.controls.comment.setValue(this.initialStockEntryLocationUpdate.comment);
  }

  closePopup(): void {
    this.applyModifications();
    if (
      !this.selectedStockEntryLocationUpdate.equals(this.initialStockEntryLocationUpdate) &&
      !this.selectedStockEntryLocationUpdate.equals(this.unsavedStockEntryLocationUpdate)
    ) {
      this.unsavedStockEntryLocationUpdate = this.cloneStockEntryLocationUpdate(this.selectedStockEntryLocationUpdate);
      this.triggerDataLossWarningMessage();
      return;
    }
    this.close.emit("close");
  }

  submitPopup(): void {
    if (this.popupForm.invalid) {
      this.popupForm.markAllAsTouched();
      return;
    }

    if (!this.userService.canDo("STOCK_ENTRY_UPDATE")) {
      this.messageService.error(this.translateService.instant("global.errors.unauthorized"), {
        title: this.translateService.instant("message.title.error"),
      });
      return;
    }

    this.applyModifications();

    const initialSELClone = this.cloneAndCastSelUpdate(this.initialStockEntryLocationUpdate);
    const selectedSELClone = this.cloneAndCastSelUpdate(this.selectedStockEntryLocationUpdate);

    if (initialSELClone.equals(selectedSELClone)) {
      this.closePopup();
      return;
    }

    this.subscriptionService.subs.push(
      this.stockEntryService
        .updateStockEntryLocation(this.stockEntryLocation.id, this.selectedStockEntryLocationUpdate)
        .subscribe(
          () => {
            this.triggerSuccessfulSaveMessage();
          },
          error => {
            this.handleApiError(error);
          },
          () => {
            this.closePopup();
          }
        )
    );
  }

  // To be sure to have all StockEntryLocationUpdate in the same state in all case. Null/0/undefined were making it inconsistent
  cloneAndCastSelUpdate(selUpdate: StockEntryLocationUpdate): StockEntryLocationUpdate {
    const sELClone = this.cloneStockEntryLocationUpdate(selUpdate);
    sELClone.weight = +selUpdate.weight;
    sELClone.tare = +selUpdate.tare;
    return sELClone;
  }

  triggerDataLossWarningMessage(): void {
    const title = this.translateService.instant("global.errors.unsaved-title");
    const content = this.translateService.instant("global.errors.unsaved-popin-content");
    this.messageService.info(content, { title });
  }

  triggerSuccessfulSaveMessage(): void {
    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "edit-stock-popup.errors.edit-stock",
    };

    const title = this.translateService.instant("message.title.api-errors");

    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }
}
