import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  AbstractItem,
  Alloy,
  AlloyService,
  AppConfig,
  AppConfigService,
  CaraUser,
  CaraUserService,
  CreationDeliveryType,
  Currency,
  CurrencyService,
  DeliveryType,
  Light,
  LightService,
  Metal,
  MetalService,
  PaginatedList,
  Pagination,
  PurchaseOrder,
  PurchaseOrderService,
  ReceivingForm,
  ReceivingInternalLine,
  ReceptionType,
  RetailItemService,
  SizeCategory,
  SizeCategoryService,
  StockEntry,
  StockEntryService,
  StockLocation,
  StockLocationService,
  Store,
  StoreService,
  Supplier,
  SupplierService,
  Uom,
  UomService,
} from "center-services";
import { SearchFilter, SearchFilterOperator } from "fugu-common";
import { MessageService } from "fugu-components";
import { Observable, combineLatest, of } from "rxjs";
import { mergeMap, switchMap, tap } from "rxjs/operators";

@Injectable()
export class ReceivingFormFetcher {
  public locale: string;
  public dateFormat: string;

  public withPreparation: boolean;
  public receptionType: ReceptionType;

  public purchaseUnitList: Uom[] = [];
  public currencies: Currency[];
  public alloyList: Alloy[];
  public metalList: Metal[];
  public supplierList: Light[] = [];
  public storeList: Light[] = [];
  public mainStore: Store;
  public flatStockLocations: StockLocation[] = [];
  public sizeCategoryList: SizeCategory[];
  public retailItemList: AbstractItem[] = [];
  public stockEntries: StockEntry[] = [];
  public poList: PurchaseOrder[];

  public receiver: Store;
  public sender: Supplier | Store;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: CaraUserService,
    private purchaseOrderService: PurchaseOrderService,
    private sizeCategoryService: SizeCategoryService,
    private appConfigService: AppConfigService,
    private uomService: UomService,
    private currencyService: CurrencyService,
    private storeService: StoreService,
    private supplierService: SupplierService,
    private retailItemService: RetailItemService,
    private stockLocationService: StockLocationService,
    private alloyService: AlloyService,
    private metalService: MetalService,
    private lightService: LightService,
    private stockEntryService: StockEntryService
  ) {}

  public fetchInitialData(): Observable<any> {
    const observables = [];
    observables.push(this.fetchConnectedUserDetails());
    observables.push(this.fetchReceptionType());
    observables.push(this.fetchPurchaseUnits());
    observables.push(this.fetchCurrencies());
    observables.push(this.fetchAlloys());
    observables.push(this.fetchMetals());
    observables.push(this.fetchSuppliers());
    observables.push(this.fetchStores());
    observables.push(this.fetchMainStore());

    return combineLatest(observables);
  }

  public fetchReceivingFormData(receivingForm: ReceivingForm, purchaseModalityIds: number[]): Observable<any> {
    const observables = [];
    observables.push(this.fetchReceiverAndStockLocations(receivingForm));
    observables.push(this.fetchSender(receivingForm));
    observables.push(this.fetchSizeCategories(receivingForm));
    observables.push(this.fetchItems(receivingForm, purchaseModalityIds));
    observables.push(this.fetchPurchaseOrdersAndItem(receivingForm));
    observables.push(this.fetchStockEntriesAndItem(receivingForm));

    return combineLatest(observables);
  }

  private fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.asObservable().pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  private fetchReceptionType(): Observable<AppConfig> {
    return this.appConfigService.appConfig.asObservable().pipe(
      tap(appConfig => {
        this.withPreparation = appConfig?.receptionType === ReceptionType.WITH_REGISTERING;
        this.receptionType = appConfig?.receptionType;
      })
    );
  }

  private fetchPurchaseUnits(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.purchaseUnitList = uoms;
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-uoms", error.message);
        }
      )
    );
  }

  private fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencies = currencies;
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-currencies", error.message);
        }
      )
    );
  }

  private fetchAlloys(): Observable<Alloy[]> {
    return this.alloyService.getAll().pipe(
      tap(
        (alloys: Alloy[]) => {
          this.alloyList = alloys;
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-alloys", error.message);
        }
      )
    );
  }

  private fetchMetals(): Observable<Metal[]> {
    return this.metalService.getAll().pipe(
      tap(
        (metals: Metal[]) => {
          this.metalList = metals;
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-metals", error.message);
        }
      )
    );
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierList = lightSuppliers;
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-suppliers", error.message);
        }
      )
    );
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeList = lightStores;
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-stores", error.message);
        }
      )
    );
  }

  private fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-main-store", error.message);
        }
      )
    );
  }

  private fetchReceiverAndStockLocations(receivingForm: ReceivingForm): Observable<StockLocation[]> {
    return this.storeService.get(receivingForm.receiverId).pipe(
      tap(
        (store: Store) => {
          this.receiver = store;
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-receiver", error.message);
        }
      ),
      mergeMap((store: Store) => this.fetchStockLocations(store))
    );
  }

  private fetchStockLocations(store: Store): Observable<StockLocation[]> {
    return this.stockLocationService.getAll(["withChildren", "onlyOrigin"], store.id).pipe(
      tap(
        (stockLocations: StockLocation[]) => {
          this.fillFlatLocations(stockLocations);
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-stock-locations", error.message);
        }
      )
    );
  }

  private fillFlatLocations(stockLocations: StockLocation[]): void {
    stockLocations.forEach(stockLocation => {
      this.flatStockLocations.push(stockLocation);
      if (Array.isArray(stockLocation.children) && stockLocation.children.length > 0) {
        this.fillFlatLocations(stockLocation.children);
      }
    });
  }

  private fetchSender(receivingForm: ReceivingForm): Observable<any> {
    if (receivingForm.type === DeliveryType.EXTERNAL_SUPPLIER) {
      return this.supplierService.get(receivingForm.senderId).pipe(
        tap(
          (supplier: Supplier) => {
            this.sender = supplier;
          },
          error => {
            this.sendErrorAlert("receiving-form.errors.get-sender", error.message);
          }
        )
      );
    } else {
      return this.storeService.get(receivingForm.senderId).pipe(
        tap(
          (store: Store) => {
            this.sender = store;
          },
          error => {
            this.sendErrorAlert("receiving-form.errors.get-sender", error.message);
          }
        )
      );
    }
  }

  private fetchSizeCategories(receivingForm: ReceivingForm): Observable<SizeCategory[]> {
    if (receivingForm?.creationType !== CreationDeliveryType.FREE) {
      return of(null);
    }
    return this.sizeCategoryService.getAll().pipe(
      tap(
        (sizeCategories: SizeCategory[]) => {
          this.sizeCategoryList = sizeCategories;
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-size-categories", error.message);
        }
      )
    );
  }

  private fetchItems(
    receivingForm: ReceivingForm,
    purchaseModalityIds: number[]
  ): Observable<PaginatedList<AbstractItem>> {
    if (receivingForm.creationType !== CreationDeliveryType.FREE) {
      return of(null);
    }

    const pmIds =
      purchaseModalityIds.length === 0 ? receivingForm.lines.map(line => line.purchaseModalityId) : purchaseModalityIds;

    if (pmIds.length === 0) {
      return of(null);
    }

    const pager = new Pagination({
      size: purchaseModalityIds.length,
      number: 0,
    });
    const filters: SearchFilter[] = new Array(
      new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, pmIds)
    );

    return this.retailItemService.getAll(pager, [], filters).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          // remove duplicates from item list
          this.retailItemList = result.data.filter((item, index, list) => {
            return index === list.findIndex(item2 => item2.id === item.id);
          });
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-retail-items", error.message);
        }
      )
    );
  }

  private fetchPurchaseOrdersAndItem(receivingForm: ReceivingForm): Observable<PaginatedList<AbstractItem>> {
    if (receivingForm?.creationType !== CreationDeliveryType.MANUAL || receivingForm?.type === DeliveryType.INTERNAL) {
      return of(null);
    }
    if (this.poList !== null && this.poList !== undefined) {
      return this.fetchPurchaseOrderItems();
    }
    const filters = [
      new SearchFilter("id", SearchFilterOperator.IN, receivingForm?.orderReferences.replace(/CD/g, "").split("~")),
    ];
    const pager = new Pagination({ number: 0 });

    return this.purchaseOrderService.getAll(pager, [], filters).pipe(
      tap(
        result => {
          this.poList = result.data;
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-purchase-orders", error.message);
        }
      ),
      switchMap(() => {
        return this.fetchPurchaseOrderItems();
      })
    );
  }

  private fetchPurchaseOrderItems(): Observable<PaginatedList<AbstractItem>> {
    if (!this.poList) {
      return of(null);
    }
    let pmIds = this.poList.flatMap(po => po.lines.map(line => line.purchaseModalityId));
    // remove duplicates
    pmIds = pmIds.filter((id, index) => pmIds.indexOf(id) === index);
    const pager = new Pagination({
      size: pmIds.length,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, pmIds);

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          // remove duplicates from item list
          this.retailItemList = result.data.filter((item, index, list) => {
            return index === list.findIndex(item2 => item2.id === item.id);
          });
        },
        error => {
          this.sendErrorAlert("receiving-form.errors.get-retail-items", error.message);
        }
      )
    );
  }

  private fetchStockEntriesAndItem(receivingForm: ReceivingForm): Observable<PaginatedList<AbstractItem>> {
    if (receivingForm.type !== DeliveryType.INTERNAL) {
      return of(null);
    }
    const stockEntryIds = receivingForm.lines.map(line => (line as ReceivingInternalLine).stockEntryId);

    const pager = new Pagination({
      size: stockEntryIds.length,
      number: 0,
    });
    const filters = new SearchFilter("id", SearchFilterOperator.IN, stockEntryIds);

    return this.stockEntryService.getAll(pager, [], [filters]).pipe(
      tap(
        (stockEntries: PaginatedList<StockEntry>) => {
          this.stockEntries = stockEntries.data;
        },
        error => {
          this.sendErrorAlert("receivreceiving-form.errors.get-stock-entries", error.message);
        }
      ),
      switchMap(() => {
        return this.fetchStockEntryItems();
      })
    );
  }

  private fetchStockEntryItems(): Observable<PaginatedList<AbstractItem>> {
    if (!this.stockEntries) {
      return of(null);
    }
    let itemIds = this.stockEntries.map(se => se.retailItemId);
    // remove duplicates
    itemIds = itemIds.filter((id, index) => itemIds.indexOf(id) === index);
    const pager = new Pagination({
      size: itemIds.length,
      number: 0,
    });
    const filters = new SearchFilter("id", SearchFilterOperator.IN, itemIds);
    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap((items: PaginatedList<AbstractItem>) => {
        this.retailItemList = items.data.map(item => {
          item.convertDataTo(
            this.uomService,
            this.purchaseUnitList,
            this.purchaseUnitList.find(uom => "g" === uom.shortName).id
          );
          return item;
        });
      })
    );
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }
}
