<ng-container>
  <div class="main-container">
    <div class="return-link">
      <ui-link
        text="{{ 'brand.buttons.back-button' | translate }}"
        (click)="backToBrandList()"
        [linkIcon]="faChevronLeft"
      ></ui-link>
      <br />
    </div>
    <div class="title">
      <ui-title titleText="{{ title | translate }}" subTitleText="{{ subTitle ? subTitle : undefined }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="validate-button">
        <div class="button">
          <ui-button
            text="{{ 'global.validate' | translate }}"
            type="save"
            [primary]="true"
            (click)="submitBrand()"
          ></ui-button>
        </div>
      </div>
    </div>
    <div class="brand-body">
      <ui-tab-handler #tabHandler (tabClick)="onTabClick($event)" [independent]="false">
        <ui-tab title="{{ 'brand.general-datas.tab-title' | translate }}" id="general-datas" active="true">
          <ng-template #tabTemplate>
            <app-brand-general #generalDatas *ngIf="this.updatedBrand" [editedBrand]="this.updatedBrand">
            </app-brand-general>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'brand.phone-book.tab-title' | translate }}" id="phone-book">
          <ng-template #tabTemplate>
            <gen-persons-list
              *ngIf="this.updatedBrand"
              [jobType]="this.jobTypeExternalContact"
              [(personsList)]="this.updatedBrand.persons"
              (submitEvent)="catchEvent('persons', $event)"
            ></gen-persons-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'brand.suppliers.tab-title' | translate }}" id="suppliers">
          <ng-template #tabTemplate>
            <app-brand-suppliers
              #brandSuppliers
              *ngIf="this.updatedBrand"
              [(supplierIds)]="this.updatedBrand.linkedSupplierIds"
            ></app-brand-suppliers>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'brand.commercial-modality.tab-title' | translate }}" id="commercial-modality">
          <ng-template #tabTemplate>
            <app-commercial-modality
              #commercialModality
              *ngIf="updatedBrand"
              [(commercialModality)]="updatedBrand.commercialModality"
              [address]="updatedBrand.address"
              [contacts]="updatedBrand.persons"
              [currencyId]="updatedBrand.currencyId"
            >
            </app-commercial-modality>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'brand.collections.tab-title' | translate }}" id="collections">
          <ng-template #tabTemplate>
            <app-brand-collection-list
              #brandCollections
              *ngIf="this.updatedBrand"
              [(collections)]="this.updatedBrand.collections"
              (submitEvent)="catchEvent('collections', $event)"
            ></app-brand-collection-list>
          </ng-template>
        </ui-tab>
        <ui-tab title="{{ 'brand.as.tab-title' | translate }}" id="as">
          <ng-template #tabTemplate>Le SAV</ng-template>
        </ui-tab>
        <ui-tab title="{{ 'brand.size-categories.tab-title' | translate }}" id="size-categories">
          <ng-template #tabTemplate>
            <override-size-categories-list
              *ngIf="this.updatedBrand"
              (submitEvent)="catchEvent('sizeCategories', $event)"
              [(overrideSizeCategories)]="this.updatedBrand.sizeCategories"
            ></override-size-categories-list>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>
    </div>
  </div>
</ng-container>
