<ng-container>
  <div class="free-invoice-customer-initiator-popup-container" novalidate [formGroup]="popupForm">
    <ui-popup title="{{ 'free-invoice-customer-initiator-popup.title' | translate }}" (close)="closePopup(true)">
      <div content>
        <div class="return-link">
          <ui-link
            text="{{ 'free-invoice-customer-initiator-popup.buttons.back-button' | translate }}"
            (click)="closePopup(false)"
            [linkIcon]="faChevronLeft"
          ></ui-link>
        </div>

        <div class="customer">
          <ui-list
            label="{{ 'free-invoice-customer-initiator-popup.fields.customer' | translate }}"
            [options]="this.customerOptions"
            formControlName="customer"
            [status]="popupForm.get('customer') | formStatus"
            [errorMessage]="popupForm.get('customer') | formErrorMessage | translate"
            [mandatory]="true"
            [search]="true"
          ></ui-list>
        </div>

        <div class="store">
          <ui-list
            label="{{ 'free-invoice-customer-initiator-popup.fields.store' | translate }}"
            [options]="this.storeOptions"
            formControlName="store"
            [status]="popupForm.get('store') | formStatus"
            [errorMessage]="popupForm.get('store') | formErrorMessage | translate"
            [search]="true"
          ></ui-list>
        </div>
      </div>

      <div footer>
        <div class="button-popup-to-invoice">
          <ui-button
            text="{{ 'free-invoice-customer-initiator-popup.buttons.submit' | translate }}"
            [primary]="true"
            (click)="submit()"
            [btnIcon]="faArrowCircleRight"
          ></ui-button>
        </div>
      </div>
    </ui-popup>
  </div>
</ng-container>
