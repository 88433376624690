<ng-container>
  <div class="main-container" *ngIf="updatedReceivingForm">
    <div class="header">
      <div class="return-link">
        <ui-link
          text="{{ getBackButtonTranslation() | translate }}"
          [linkIcon]="faChevronLeft"
          (click)="backToPrevious()"
        ></ui-link>
      </div>
      <div class="title">
        <ui-title titleText="{{ title | translate: { ref: updatedReceivingForm.deliveryRef } }}"></ui-title>
      </div>
    </div>

    <div class="top-buttons">
      <div class="button-validate">
        <ui-button
          *ngIf="this.isValidateButtonDisplayed && this.title"
          text="{{ validateBtn | translate }}"
          [primary]="true"
          type="validate"
          (click)="!this.isUpdateButtonDisplayed ? openValidationPopup() : openUpdateReceivePopup()"
        >
        </ui-button>
      </div>
      <div class="button-save" *ngIf="this.displaySaveBtn">
        <ui-button text="{{ 'global.validate' | translate }}" (click)="submitReceivingForm()" type="save"></ui-button>
      </div>
    </div>
    <div class="receiving-form-body" *ngIf="isLoaded">
      <app-new-delivery-form-header
        #newDeliveryFormHeader
        [receivingForm]="updatedReceivingForm"
        [detailHeader]="detailHeader"
        *ngIf="updatedReceivingForm"
        [readOnly]="false"
        [receiver]="fetcher.receiver"
        [sender]="fetcher.sender"
      ></app-new-delivery-form-header>

      <ui-tab-handler
        #tabHandler
        [tabByUrl]="false"
        [independent]="false"
        *ngIf="fetcher.poList?.length"
        (tabClick)="onTabClick($event)"
      >
        <ui-tab
          [id]="i"
          [active]="i === 0"
          *ngFor="let po of fetcher.poList; let i = index"
          title="{{ 'new-delivery-form.orders.title' | translate: { orderRef: po.orderRef } }}"
        >
          <ng-template #tabTemplate>
            <app-new-delivery-lines
              #newDeliveryLines
              [retailItemList]="this.fetcher.retailItemList"
              [receiveStatus]="updatedReceivingForm.receiveStatus"
              [(lines)]="updatedReceivingForm.lines"
              (lineSplit)="detailLines($event)"
              [stockLocations]="fetcher.flatStockLocations"
              [type]="updatedReceivingForm.type"
              [creationType]="updatedReceivingForm.creationType"
              [currency]="deliveryCurrency"
              [tabsNumber]="fetcher.poList.length"
              [rowIds]="getRowIds(po)"
              [columns]="lineColumns"
              [readOnly]="false"
              [action]="action"
              [rows]="rows"
              [index]="i"
              (rowDeleted)="rowDeleted()"
            >
            </app-new-delivery-lines>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>

      <ui-container *ngIf="!fetcher.poList?.length">
        <app-new-delivery-lines
          #newDeliveryLines
          [retailItemList]="this.fetcher.retailItemList"
          [(lines)]="updatedReceivingForm.lines"
          [stockLocations]="fetcher.flatStockLocations"
          [type]="updatedReceivingForm.type"
          [creationType]="updatedReceivingForm.creationType"
          [currency]="deliveryCurrency"
          [columns]="lineColumns"
          [readOnly]="false"
          [rows]="rows"
          (openSelectionPopupOutput)="openSelectionPopup()"
          (rowDeleted)="rowDeleted()"
        >
        </app-new-delivery-lines>
      </ui-container>
    </div>

    <app-new-delivery-form-validation-popup
      *ngIf="validationPopupVisible && updatedReceivingForm"
      #newDeliveryFormValidation
      [btnLabel]="'receiving-form.receipt-validation-popup.edit-again'"
      [newReceivingForm]="updatedReceivingForm"
      [lines]="updatedReceivingForm.lines"
      [withPreparation]="fetcher.withPreparation"
      [isValidationForPreparation]="isValidationForPreparation"
      [receiverName]="fetcher.receiver?.name"
      [purchaseOrderlines]="poLines"
      (close)="closeValidationPopup()"
      (validate)="submitValidationPopup()"
    >
    </app-new-delivery-form-validation-popup>
  </div>

  <!-- selection popup -->
  <app-purchase-modality-selection
    #purchaseModalitySelection
    *ngIf="selectionPopupVisible"
    [currencyId]="currencyId"
    [supplierId]="senderId"
    (selectedPurchaseModalities)="submitSelectionPopup($event)"
    (close)="closeSelectionPopup()"
  ></app-purchase-modality-selection>

  <!-- update receive popup -->
  <app-new-delivery-form-update-receive-popup
    *ngIf="updateReceivePopupVisible"
    [updatedReceivingForm]="updatedReceivingForm"
    [editedReceivingForm]="editedReceivingForm"
    [unsavedReceivingForm]="unsavedReceivingForm"
    [retailItemList]="fetcher.retailItemList"
    [purchaseUnitList]="fetcher.purchaseUnitList"
    [poLines]="poLines"
    [locale]="fetcher.locale"
    (close)="closeUpdateReceivePopup()"
    (updateReceive)="updateReceive()"
  ></app-new-delivery-form-update-receive-popup>
</ng-container>
