import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { faInfoCircle, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  StandardItem,
  AbstractItem,
  StockLocation,
  StockEntry,
  Uom,
  Currency,
  StockLocationService,
  StoreService,
  CurrencyService,
  UomService,
  RetailItemService,
  StockType,
  Store,
  PurchaseModality,
  CaraUserService,
} from "center-services";
import { Option, SubscriptionService, RoundingUtil } from "fugu-common";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";

@Component({
  selector: "app-retail-item-stock",
  templateUrl: "./retail-item-stock.component.html",
  styleUrls: ["./retail-item-stock.component.scss"],
  providers: [SubscriptionService],
})
export class RetailItemStockComponent implements OnInit, AfterViewChecked {
  @Input() editedItem: StandardItem;
  @Output() editedItemChange: EventEmitter<AbstractItem> = new EventEmitter<AbstractItem>();

  public stockForm: UntypedFormGroup;
  public stockTypeOptions: Option[] = [];

  public stockLocationOptions: Option[] = [];
  public flatStockLocations: StockLocation[] = [];
  public stockLocationChildren: number[] = [];
  public stockEntryList: StockEntry[] = [];
  public totalQuantity: number = 0;
  public uoms: Uom[] = [];
  public uomOptions: Option[] = [];
  public faInfoCircle: IconDefinition = faInfoCircle;
  public currency: Currency;
  public wapValue: number;
  public locale: string;
  private initObservables: Observable<any>[];

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private stockLocationService: StockLocationService,
    private storeService: StoreService,
    private currencyService: CurrencyService,
    private uomService: UomService,
    private retailItemService: RetailItemService,
    private userService: CaraUserService,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {}

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.prepareForm();
    this.initObservables = [];
    this.initObservables.push(this.fetchMainStore());
    this.initObservables.push(this.fetchUnitOfMeasure());

    this.buildStockTypeOptions();

    this.locale = this.userService.connectedUser.value.codeLanguage;

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.buildStockTypeOptions();
      })
    );

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        if (this.editedItem) {
          this.loadEditedData();
          if (this.editedItem.waps && this.editedItem.waps.length > 0) {
            this.wapValue = RoundingUtil.roundHigh(this.editedItem.waps[0].value);
            this.fetchDefaultCurrency();
          }
          if (this.editedItem.hasStockEntries) {
            this.stockForm.controls.mainUnitId.disable();
          }
          this.fetchTotalQuantity();
        }
      })
    );
  }

  prepareForm(): void {
    this.stockForm = this.fb.group({
      stockType: [null, [Validators.required]],
      stockLocation: [null],
      mainUnitId: [null],
    });

    this.subscriptionService.subs.push(
      this.stockForm.controls.stockType.valueChanges.subscribe(value => {
        if (this.editedItem.hasStockEntries && this.editedItem.stockType !== Object.values(StockType)[value]) {
          const message = this.translateService.instant("item.stock.stock-type-warning");
          this.messageService.warn(message);
        }
        this.editedItem.stockType = Object.values(StockType)[value];
        this.editedItemChange.emit(this.editedItem);
      })
    );

    this.subscriptionService.subs.push(
      this.stockForm.controls.mainUnitId.valueChanges.subscribe(value => {
        this.onMainUnitChange();
        this.editedItem.mainUnitId = value;
        this.editedItemChange.emit(this.editedItem);
      })
    );

    this.subscriptionService.subs.push(
      this.stockForm.controls.stockLocation.valueChanges.subscribe(value => {
        this.editedItem.defaultLocationId = value;
        this.editedItemChange.emit(this.editedItem);
      })
    );
  }

  fetchMainStore(): Observable<StockLocation[]> {
    return this.storeService.getMain().pipe(
      tap({
        error: () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("stock-locations-list.errors.get");
          this.messageService.warn(content, { title });
        },
      }),
      mergeMap((mainStore: Store) => this.fetchStockLocations(mainStore.id, mainStore.name))
    );
  }

  fetchStockLocations(mainStoreId: number, storeName: string): Observable<StockLocation[]> {
    return this.stockLocationService.getAll(["withChildren", "onlyOrigin"], mainStoreId).pipe(
      tap(
        (stockLocations: StockLocation[]) => {
          this.fillFlatLocations(stockLocations, storeName);

          this.stockLocationOptions = this.flatStockLocations
            .filter((obj: StockLocation) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: StockLocation) => new Option(obj.id, obj.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("stock-locations-list.errors.get");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchUnitOfMeasure(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.uoms = uoms;

          this.uomOptions = uoms
            .filter((obj: Uom) => !obj.archived)
            .sort((a, b) => a.longName.localeCompare(b.longName))
            .map((obj: Uom) => new Option(obj.id, obj.longName));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("uoms-list.errors.get-entities");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fillFlatLocations(stockLocations: StockLocation[], storeName: string): void {
    stockLocations.forEach(stockLocation => {
      this.flatStockLocations.push(stockLocation);
      if (Array.isArray(stockLocation.children) && stockLocation.children.length > 0) {
        this.fillFlatLocations(stockLocation.children, storeName);
      }
      if (storeName && stockLocation.parentId) {
        stockLocation.name += ` (${storeName})`;
      }
    });
  }

  buildStockTypeOptions(): void {
    this.stockTypeOptions = Object.keys(StockType).map(
      (key, index) => new Option(index, this.translateService.instant(`stock-type-options.${key}`))
    );
  }

  loadEditedData(): void {
    if (this.editedItem.stockType) {
      const index = Object.keys(StockType).indexOf(this.editedItem.stockType);
      this.stockForm.controls.stockType.setValue(index);
    }
    this.stockForm.controls.stockLocation.setValue(this.editedItem.defaultLocationId);
    this.stockForm.controls.mainUnitId.setValue(this.editedItem.mainUnitId);
  }

  onMainUnitChange(): void {
    const changedMainUnit = this.uoms.find((mainUnit: Uom) => {
      return mainUnit.id === this.stockForm.get("mainUnitId").value;
    });

    if (changedMainUnit) {
      this.editedItem.purchaseModalities?.forEach((purchaseModality: PurchaseModality, index) => {
        // Same uom for Item and PurchaseModality -> conversion = 1
        if (changedMainUnit.id === purchaseModality.purchaseUnitId) {
          this.editedItem.purchaseModalities[index].conversionFactor = 1;
        } else {
          // Different uom for Item and PurchaseModality -> conversion = conversion factor of parameters
          const purchaseUnitCurr = this.uoms.find((purchaseUnit: Uom) => {
            return purchaseUnit.id === purchaseModality.purchaseUnitId;
          });

          purchaseUnitCurr.conversions.find(conversion => {
            if (conversion.unitDestId === changedMainUnit.id) {
              this.editedItem.purchaseModalities[index].conversionFactor = +conversion.factor;
            }
          });
        }
      });
    }
  }

  fetchTotalQuantity(): void {
    this.subscriptionService.subs.push(
      this.retailItemService.getTotalQuantityInStock(this.editedItem.id).subscribe((totalQuantity: number) => {
        this.totalQuantity = totalQuantity;
      })
    );
  }

  fetchDefaultCurrency(): void {
    this.subscriptionService.subs.push(
      this.currencyService.getDefault().subscribe(currency => {
        this.currency = currency;
      })
    );
  }

  getMainUnitName(id: number, short: any): string {
    const purchaseUnitCurr = this.uoms.find((purchaseUnit: Uom) => {
      return purchaseUnit.id === id;
    });

    if (purchaseUnitCurr) {
      return short ? purchaseUnitCurr.shortName : purchaseUnitCurr.longName;
    }

    return null;
  }
}
