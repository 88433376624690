export class CreditNoteCustomerTotals {
  totalGrossPrice: number;
  totalQuantity: number;

  constructor(values: any = {}) {
    if (!values) {
      return;
    }

    this.totalGrossPrice = values.totalGrossPrice;
    this.totalQuantity = values.totalQuantity;
  }
}
