<form class="customer-documents-form" [formGroup]="popupForm" novalidate>
  <div class="popup-container">
    <ui-popup title="{{ popupTitle }}" (close)="closePopup()">
      <div content>
        <div class="grid-container">
          <div class="grid-item">
            <ui-list
              label="{{ 'customer-document.fields.type' | translate }}"
              [options]="this.customerDocumentTypeOptions"
              formControlName="type"
            >
            </ui-list>
            <div class="country">
              <ui-list
                label="{{ 'customer-document.fields.country' | translate }}"
                [options]="this.countryOptions"
                [search]="true"
                formControlName="country"
                [status]="this.countryListStatus"
              ></ui-list>
            </div>

            <ui-input-text
              type="text"
              label="{{ 'customer-document.fields.reference' | translate }}"
              formControlName="reference"
              [status]="popupForm.get('reference') | formStatus"
              [errorMessage]="popupForm.get('reference') | formErrorMessage | translate"
            >
            </ui-input-text>

            <ui-date-picker
              label="{{ 'customer-document.fields.expiration-date' | translate }}"
              [locale]="locale"
              [format]="dateFormat"
              formControlName="expirationDate"
            >
            </ui-date-picker>
          </div>
          <div class="grid-item documents-container">
            <div class="first-document">
              <ui-input-file
                label="{{ 'customer-document.file-labels.first-document' | translate }}"
                id="'file'"
                accept=".png, .jpeg, .jpg, .PNG, .JPEG, .JPG"
                buttonText="{{ 'global.input-file.btn' | translate }}"
                descriptionText="{{ 'global.input-file.drag-drop' | translate }}"
                formControlName="firstDocumentFile"
                smallSize="true"
              ></ui-input-file>
            </div>
            <div class="second-document" *ngIf="selectedFiles[0] || selectedFiles[1]">
              <ui-input-file
                label="{{ 'customer-document.file-labels.second-document' | translate }}"
                id="'file'"
                accept=".png, .jpeg, .jpg, .PNG, .JPEG, .JPG"
                buttonText="{{ 'global.input-file.btn' | translate }}"
                descriptionText="{{ 'global.input-file.drag-drop' | translate }}"
                formControlName="secondDocumentFile"
                smallSize="true"
              ></ui-input-file>
            </div>
          </div>
        </div>
      </div>
      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <div class="button">
              <ui-button
                text="{{ 'global.validate' | translate }}"
                [primary]="true"
                type="save"
                (click)="submitCustomerDocuments()"
              ></ui-button>
            </div>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>
</form>
